import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import {
  EXTRA_STAT_DEFINITIONS,
  FORMATION_SUMMARY_STATS_CONFIG,
} from './TeamFormationSummary.constants';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { GET_TEAM_STAT_DEFINITIONS } from '../../../utils/hooks/apiQueries/teamStats/getTeamStatDefinitions';

/**
 * custom hook to get the definitions for the team stats displayed in Formation Summary.
 * some of the definitions come from the api and some are declared on the front end so
 * this hook combines the 2 lists and sorts them in the correct order.
 */
export const useTeamStatDefinitions = (isPersonnelMode) => {
  const { data, loading, error } = useQuery(GET_TEAM_STAT_DEFINITIONS, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_STAT_DEFINITIONS, error);
    return { error, loading: false };
  }

  const statDefinitions = data.teamStatDefinitions;

  // we are only interested in stats included in the config
  const filteredStatDefinitions = statDefinitions.filter(
    (definition) => FORMATION_SUMMARY_STATS_CONFIG[definition.name]
  );
  const extraStatDefinitions = isPersonnelMode
    ? EXTRA_STAT_DEFINITIONS
    : EXTRA_STAT_DEFINITIONS.filter((d) => d.name !== 'personnelPlayRate');
  // combine api and local stat definitions
  const definitions = [...extraStatDefinitions, ...filteredStatDefinitions];

  // sort definitions in the order we would like the stats to appear in the table
  const statsToDisplay = orderBy(
    definitions,
    [(d) => FORMATION_SUMMARY_STATS_CONFIG[d.name].order],
    [SORT_DIRECTIONS.ASCENDING]
  );

  return { data: statsToDisplay, loading };
};
