const drawPass = function (
  fieldDotZoneIn,
  selectedPlay,
  visPalette,
  overrides
) {
  const catcherFF = selectedPlay?.freezeFrames?.filter(
    (f) => f.player?.id === selectedPlay?.receiverPlayer?.id
  )[0];

  if (fieldDotZoneIn && selectedPlay) {
    fieldDotZoneIn.selectAll('circle').remove();
    fieldDotZoneIn.selectAll('line').remove();

    fieldDotZoneIn
      .append('circle')
      .attr('cx', overrides.pxPerYard * selectedPlay?.x)
      .attr('cy', overrides.pxPerYard * selectedPlay?.y)
      .attr('r', 6)
      .attr('fill', visPalette.objects.n1.main);
    fieldDotZoneIn
      .append('line')
      .attr('x1', overrides.pxPerYard * selectedPlay?.x)
      .attr('x2', overrides.pxPerYard * selectedPlay?.endX)
      .attr('y1', overrides.pxPerYard * selectedPlay?.y)
      .attr('y2', overrides.pxPerYard * selectedPlay?.endY)
      .attr('stroke', visPalette.objects.n1.main)
      .attr('stroke-width', 2);
    if (catcherFF != null) {
      fieldDotZoneIn
        .append('circle')
        .attr('cx', overrides.pxPerYard * catcherFF?.x)
        .attr('cy', overrides.pxPerYard * catcherFF?.y)
        .attr('r', 6)
        .attr('fill', visPalette.objects.n2.main);
      fieldDotZoneIn
        .append('line')
        .attr('x1', overrides.pxPerYard * catcherFF?.x)
        .attr('x2', overrides.pxPerYard * selectedPlay?.endX)
        .attr('y1', overrides.pxPerYard * catcherFF?.y)
        .attr('y2', overrides.pxPerYard * selectedPlay?.endY)
        .attr('stroke', visPalette.objects.n2.main)
        .attr('stroke-width', 2);
    }
  }
};

export { drawPass };
