import { styled } from 'styled-components';

export const StyledTab = styled.div`
  .ui.attached.tabular.menu {
    font-size: 0.875rem;
    border-color: ${({ theme }) => theme.colours.layering[2].main};
    border: 0;
    height: 3.125rem;

    .item {
      color: ${({ theme }) =>
        theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
      border: 0;
      border-bottom: 2px solid
        ${({ theme }) => theme.colours.canvas.tertiary.main};
    }

    .active.item {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
      color: ${({ theme }) => theme.colours.ink.primary.main};
      border: 0;
      border-bottom: 3px solid ${({ theme }) => theme.colours.interface.main};
    }
  }

  .ui.bottom.attached.segment.tab {
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    border: 0;
    padding: 1rem;
  }
`;
StyledTab.displayName = 'StyledTab';

export const TileInlineLoader = styled.div`
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
`;
TileInlineLoader.displayName = 'TileInlineLoader';
