import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog, ButtonCheck } from '@statsbomb/kitbag-components';
import { isEmpty, uniq } from 'lodash';
import {
  PositionGroup,
  PositionCategory,
  PositionModalContent,
  PositionModalBigWrapper,
} from './PositionFilter.styles';
import PositionIcon from '../PositionIcon/PositionIcon';
import { POSITION_ICON_SIZES } from '../PositionIcon/PositionIcon.constants';
import { ALL_POSITION_CODES } from './PositionFilter.constants';
import { POSITION_CATEGORIES } from '../../utils/constants/positions';

const PositionModal = ({
  isOpen,
  positionDefinitions,
  setDialogPositions,
  onConfirm,
  asColumn,
  isRosterPositions,
}) => {
  const resetPositions = () => {
    const selectedPositions = positionDefinitions
      .filter((p) => p.isSelected)
      .map((p) => p.apiCode);
    setDialogPositions(selectedPositions);
  };

  const dialogPositions = positionDefinitions
    .filter((p) => p.isSelectedDialog)
    .map((p) => p.apiCode);
  // disable the confirm button if nothing is selected
  const isDisabled = isEmpty(dialogPositions);

  const onCategoryClick = (categoryKey, isChecked) => {
    const catPositions = positionDefinitions
      .filter((p) => p.positionCategory === categoryKey)
      .map((p) => p.apiCode);
    if (isChecked) {
      const newSelection = uniq(dialogPositions.concat(catPositions));
      setDialogPositions(newSelection);
    } else {
      const updatedSelection = dialogPositions.filter(
        (d) => !catPositions.includes(d)
      );
      setDialogPositions(updatedSelection);
    }
  };

  const onPositionClick = (positionApiCode, isChecked) => {
    if (isChecked) {
      const newSelection = dialogPositions.concat(positionApiCode);
      setDialogPositions(newSelection);
    } else {
      const updatedSelection = dialogPositions.filter(
        (d) => d !== positionApiCode
      );
      setDialogPositions(updatedSelection);
    }
  };

  const positionCategories = Object.values(POSITION_CATEGORIES).map((cat) => {
    const catPositions = positionDefinitions.filter(
      (p) => p.positionCategory === cat.key
    );
    const allSelected = catPositions.every((p) => p.isSelectedDialog);
    const someSelected = catPositions.some((p) => p.isSelectedDialog);
    return { ...cat, positions: catPositions, allSelected, someSelected };
  });

  const allPositionsSelected = positionDefinitions.every(
    (p) => p.isSelectedDialog
  );
  const somePositionsSelected = positionDefinitions.some(
    (p) => p.isSelectedDialog
  );

  const onAllNoneClick = () => {
    if (allPositionsSelected) {
      setDialogPositions([]);
    } else {
      const updatedSelection = positionDefinitions.map((p) => p.apiCode);
      setDialogPositions(updatedSelection);
    }
  };

  const getPositionIcon = (position) => {
    if (isRosterPositions) {
      return (
        <PositionIcon
          rosterPosition={position.apiCode}
          size={POSITION_ICON_SIZES.SMALL}
        />
      );
    }
    return (
      <PositionIcon
        alignmentPosition={position.apiCode}
        size={POSITION_ICON_SIZES.SMALL}
      />
    );
  };

  return (
    <ConfirmationDialog
      id="select-positions-modal"
      title="Positions"
      isOpen={isOpen}
      cancelLabel="Undo all changes"
      confirmLabel="View results"
      onCancel={() => resetPositions()}
      onConfirm={() => onConfirm(dialogPositions)}
      onEscape={() => onConfirm(dialogPositions)}
      isDisabledConfirm={isDisabled}
      isDisabledEscape={isDisabled}
    >
      <PositionModalBigWrapper>
        <ButtonCheck
          id="all-or-none"
          label={allPositionsSelected ? 'Remove all' : 'Add all'}
          onChange={onAllNoneClick}
          checked={allPositionsSelected}
          indeterminate={somePositionsSelected}
        />
      </PositionModalBigWrapper>
      <PositionModalContent $asColumn={asColumn}>
        {positionCategories.map((category) => {
          const categoryId = `${category.key}-button`;
          const isOffense = category.key === POSITION_CATEGORIES.OFFENSE.key;

          return (
            <PositionCategory key={categoryId}>
              <ButtonCheck
                id={categoryId}
                label={category.name}
                onChange={(e) =>
                  onCategoryClick(category.key, e.target.checked)
                }
                checked={category.allSelected}
                indeterminate={category.someSelected}
              />
              <PositionGroup $wider={isOffense}>
                {category.positions.map((position) => (
                  <ButtonCheck
                    id={`${categoryId}-${position.apiCode}`}
                    key={`${categoryId}-${position.apiCode}`}
                    label={position.label}
                    title={position.tooltip} // kitbag component doesn't have this attribute yet but assume it will soon
                    onChange={(e) =>
                      onPositionClick(position.apiCode, e.target.checked)
                    }
                    checked={position.isSelectedDialog}
                    inline={false}
                    customCheckedIcon={getPositionIcon(position)}
                  />
                ))}
              </PositionGroup>
            </PositionCategory>
          );
        })}
      </PositionModalContent>
    </ConfirmationDialog>
  );
};

PositionModal.propTypes = {
  // whether the dialog is open or not
  isOpen: PropTypes.bool,
  // the positional stats and whether they're currently selected
  positionDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      apiCode: PropTypes.oneOf(ALL_POSITION_CODES),
      positionCategory: PropTypes.oneOf(Object.keys(POSITION_CATEGORIES)),
      label: PropTypes.string,
      tooltip: PropTypes.string,
      isSelected: PropTypes.bool,
      isSelectedDialog: PropTypes.bool,
    })
  ).isRequired,
  // update the dialog positions
  setDialogPositions: PropTypes.func.isRequired,
  // callback for the confirm button
  onConfirm: PropTypes.func.isRequired,
  // render as single column layout or horizontal
  asColumn: PropTypes.bool,
  // whether roster or alignment positions are being handled (affects button icons)
  isRosterPositions: PropTypes.bool,
};

PositionModal.defaultProps = {
  isOpen: false,
  asColumn: false,
  isRosterPositions: true,
};

export default PositionModal;
