import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { mf_LeagueLevel, mf_PlayerDetails } from '../../../apollo';
import PassingChart from '../../../visualisations/PassingChart/PassingChart';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { GET_PASS_EVENTS } from '../PlayerPassing/getPassEvents';
import { GET_CATCH_EVENTS } from '../PlayerPassing/getCatchEvents';
import { toChartSpace } from '../PlayerPassing/DataManipulation';
import { defaultFieldSettings } from '../../../utils/constants/charting';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { GRAPH_OPTIONS } from '../../../visualisations/PassingChart/PassingChart.constants';
import PassingKey from '../PlayerPassing/PassingKey/PassingKey';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const PlayerPassEndsTile = function ({ graphOptionDefault }) {
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const passesTo = mf_PlayerDetailsRV?.mostCommonPosition?.code === 'QB';
  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(passesTo ? GET_PASS_EVENTS : GET_CATCH_EVENTS);

  const [graphOption, setGraphOption] = useQueryString(
    'passEndsGraph',
    graphOptionDefault
  );
  const showKey = graphOption !== 'heatmap'; // key is for dot modes

  const chartData = data?.passEvents?.edges?.map(({ node }) => node);

  const formattedData =
    chartData &&
    chartData.map((m) => toChartSpace(m, defaultFieldSettings.pxPerYard));

  const competitionLevel = useReactiveVar(mf_LeagueLevel);

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Passing Chart</h3>
        </div>
        <div>
          {!hasError && !isLoading && formattedData?.length !== 0 && (
            <DropdownWrapper>
              <Dropdown
                id="heatmapDrop"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(selectedOption) =>
                  setGraphOption(selectedOption.value)
                }
                options={GRAPH_OPTIONS}
                value={GRAPH_OPTIONS.find((item) => item.value === graphOption)}
                labelPosition="none"
                label="Passing chart display options"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            href={`/player/passing/:leagues/:seasons/:teams/:players?graph=${graphOption}`}
            linkName="Open Passing Chart"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        <>
          {isLoading && <TileDimmer />}
          {formattedData?.length === 0 && (
            <TileDimmer>
              <p>No Data available</p>
            </TileDimmer>
          )}
          {hasError && !isLoading && (
            <SummaryError>
              <Icon size="small" variant="Warning" colour="primary.main" />
              There has been an error.
            </SummaryError>
          )}
          <div className="chartWrapper">
            <PassingChart
              data={formattedData}
              loading={isLoading}
              showInfo={false}
              displayPassPaths={graphOption === 'pass_paths'}
              displayHeatMap={graphOption === 'heatmap'}
              transparentBg
              competitionLevel={competitionLevel}
              isInteractive={false}
            />
            {showKey && <PassingKey />}
          </div>
        </>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

PlayerPassEndsTile.propTypes = {
  graphOptionDefault: PropTypes.string,
};

PlayerPassEndsTile.defaultProps = {
  graphOptionDefault: GRAPH_OPTIONS[0].value,
};

export default PlayerPassEndsTile;
