import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Icon } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { mf_PlayerDetails } from '../../../apollo';
import { useGetTemplateDefinitions } from '../PlayerRadar/PlayerRadar.hooks';
import BasicTable from '../../../components/Table/BasicTable';
import { OutcomeIndicator } from '../../Game/GamePlayByPlay/PlayByPlayAccordion/PlayByPlayAccordionTileBody.styles';
import { findClosestBucket } from '../PlayerComparison/PlayerComparison.dataManipulation';
import { PlayerComparisonIndicator } from '../PlayerComparison/PlayerComparison.styles';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import { PlayerComparisonTileWrapper } from './PlayerOverview.styles';
import { COMPARISON_SUMMARY_TABLE_HEADERS } from './PlayerComparison.Tile.constants';
import { numberOfMatchesInfo } from '../PlayerComparison/PlayerComparison.helpers';
import { usePlayerComparison } from '../PlayerComparison/usePlayerComparison.hook';
import {
  COMPARISON_DEFAULT_MIN_PLAYS,
  COMPARISON_DEFAULT_WEIGHT,
} from '../PlayerComparison/PlayerComparison.constants';
import { csValue } from '../../../utils/helpers/colorScales';

const PlayerComparisonTile = ({ margin, width, playerId }) => {
  const { isDark } = useTheme();
  // selected player
  const playerDetails = useReactiveVar(mf_PlayerDetails);
  const playerGeneralPosition =
    playerDetails?.mostCommonPosition?.generalPosition;

  // radar templates
  const {
    templates,
    loading: isConfigLoading,
    error: hasConfigError,
  } = useGetTemplateDefinitions();

  const selectedTemplate = templates?.find((t) =>
    t?.defaultFor.includes(playerGeneralPosition)
  );

  const {
    loading: isLoadingComparison,
    error: hasComparisonError,
    data: playerComparisonData,
  } = usePlayerComparison(
    [playerId],
    COMPARISON_DEFAULT_MIN_PLAYS,
    false, // onlyTransferPortal
    true, // allPlays
    COMPARISON_DEFAULT_WEIGHT,
    [], // alignmentPositionsApiCodes
    selectedTemplate,
    playerGeneralPosition
  );

  const tableData =
    playerComparisonData?.playerComparison?.comparativePlayers.map(
      (player) => ({
        player: (
          <ParsedLink
            href={`/player/overview/:leagues/:seasons/${player?.teamId}/${player?.playerId}`}
          >
            {player?.playerName}
          </ParsedLink>
        ),
        team: player?.teamName,
        similarity: (
          <PlayerComparisonIndicator style={{ justifyContent: 'normal' }}>
            <OutcomeIndicator
              $size="0.625rem"
              $colour={csValue(findClosestBucket(player?.similarity), isDark)}
            />
            {`${(player?.similarity * 100).toFixed(1)}%`}
          </PlayerComparisonIndicator>
        ),
      })
    );

  const isLoading = isConfigLoading || isLoadingComparison;
  const hasError = hasConfigError || hasComparisonError;

  return (
    <PlayerComparisonTileWrapper>
      <SummaryTile
        style={{ width: width || '100%', margin: `${margin}`, height: 'auto' }}
      >
        <SummaryTileHeader>
          <div>
            <h3>Player Comparison</h3>
          </div>
          <div>
            <GoTo
              href="/player/comparison/:leagues/:seasons/:teams/:players"
              linkName="Open player comparison"
              iconSize="small"
            />
          </div>
        </SummaryTileHeader>
        <SummaryTileBody style={{ minHeight: '194px' }}>
          {isLoading && <TileDimmer />}
          {!playerComparisonData && !isLoading && !hasError && (
            <TileDimmer active>
              <p>No comparison data available</p>
            </TileDimmer>
          )}
          {!isLoading && !hasError && (
            <BasicTable
              data={tableData}
              headers={COMPARISON_SUMMARY_TABLE_HEADERS}
              bodytdHeight="2rem"
            />
          )}
        </SummaryTileBody>
        <SummaryTileFooter style={{ position: 'relative' }}>
          <SummaryTileCol style={{ textAlign: 'left' }}>
            {isLoading && (
              <Loader active inline size="small" content="Loading Data" />
            )}
            {hasError && !isLoading && playerComparisonData && (
              <SummaryError>
                <Icon size="small" variant="Warning" colour="primary.main" />
                There has been an error.
              </SummaryError>
            )}
            {!isLoading && !hasError && (
              <span className="infoFooter">
                {playerComparisonData &&
                  numberOfMatchesInfo(
                    playerComparisonData?.playerComparison?.totalComparisons
                  )}
              </span>
            )}
          </SummaryTileCol>
        </SummaryTileFooter>
      </SummaryTile>
    </PlayerComparisonTileWrapper>
  );
};

PlayerComparisonTile.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
  playerId: PropTypes.number.isRequired,
};

PlayerComparisonTile.defaultProps = {
  margin: '0',
  width: '100%',
};

export default PlayerComparisonTile;
