import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { Button, Checkbox, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label, Tab } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import { POSITION_ICON_SIZES } from '../PositionIcon/PositionIcon.constants';
import {
  uiState,
  cf_PackagesOffense,
  cf_PackagesDefense,
  ui_personnelTab,
  cf_PackagesEnabled,
  cf_PositionsEnabled,
  cf_PositionsOL,
  cf_PositionsTE,
  cf_PositionsWR,
  cf_PositionsRB,
  cf_PositionsQB,
  cf_PositionsDL,
  cf_PositionsLB,
  cf_PositionsDB,
} from '../../apollo';
import PositionIcon from '../PositionIcon/PositionIcon';
import {
  StyledModal,
  StyledSlider,
  TriggerButtonContainer,
  SelectAllButtonContainer,
} from './ContextFilters.styles';
import { API_ROSTER_POSITION_KEYS } from '../../utils/constants/api';
import { enableFilter } from './Filters.helper';
import { useIsEligiblePage } from './ContextFilters.hooks';
import {
  OFFENSE_PACKAGES_CONFIG,
  DEFENSE_PACKAGES_CONFIG,
} from './ContextFilters.constants';

const PersonnelFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_ModalOpen, setModalOpen] = useState(false);
  const ui_personnelTabRV = useReactiveVar(ui_personnelTab);
  const [personnelTabIndex, setPersonnelTab] = useState(ui_personnelTabRV);
  // context filters in global state
  const cf_PackagesOffenseRV = useReactiveVar(cf_PackagesOffense);
  const cf_PackagesDefenseRV = useReactiveVar(cf_PackagesDefense);
  const cf_PackagesEnabledRV = useReactiveVar(cf_PackagesEnabled);
  const cf_PositionsEnabledRV = useReactiveVar(cf_PositionsEnabled);
  const positionDefaults = {
    te: [0, 2],
    ol: [6, 10],
    wr: [0, 5],
    rb: [0, 2],
    qb: [0, 1],
    dl: [0, 5],
    lb: [0, 2],
    db: [0, 5],
  };
  // context filters in local state
  const [offensePackages, setOffensePackages] = useState(cf_PackagesOffenseRV);
  const [defensePackages, setDefensePackages] = useState(cf_PackagesDefenseRV);
  const [stOL, setOL] = useState(cf_PositionsOL());
  const [stTE, setTE] = useState(cf_PositionsTE());
  const [stWR, setWR] = useState(cf_PositionsWR());
  const [stRB, setRB] = useState(cf_PositionsRB());
  const [stQB, setQB] = useState(cf_PositionsQB());
  const [stDL, setDL] = useState(cf_PositionsDL());
  const [stLB, setLB] = useState(cf_PositionsLB());
  const [stDB, setDB] = useState(cf_PositionsDB());
  const [stHover, setHover] = useState(false);

  // returns true of every item in the sublist exists in the list
  const getCheckedState = (list, sublist) =>
    sublist.every((value) => list.includes(value));

  const handlePackages = (value, isOffense) => {
    const packages = isOffense ? offensePackages : defensePackages;
    const setPackages = isOffense ? setOffensePackages : setDefensePackages;
    const isChecked = getCheckedState(packages, value);
    if (isChecked) {
      setPackages(packages.filter((item) => !value.includes(item)));
    } else {
      setPackages([...packages, ...value]);
    }
    enableFilter(cf_PackagesEnabledRV, cf_PackagesEnabled, 'packages');
  };

  const handlePositionSliders = (sliderValues, setFunction) => {
    setFunction(sliderValues);
  };

  const handleAfterChange = () => {
    enableFilter(cf_PositionsEnabledRV, cf_PositionsEnabled, 'positions');
  };

  const handleAllPackages = (condition) => {
    const allOffensePackages = OFFENSE_PACKAGES_CONFIG.flatMap((p) => p.value);
    const allDefensePackages = DEFENSE_PACKAGES_CONFIG.flatMap((p) => p.value);
    setOffensePackages(condition ? allOffensePackages : []);
    setDefensePackages(condition ? allDefensePackages : []);
    enableFilter(cf_PackagesEnabledRV, cf_PackagesEnabled, 'packages');
  };

  const handleTabChange = (e, { activeIndex }) => {
    setPersonnelTab(parseInt(activeIndex, 10));
  };

  const handleReset = () => {
    const { packages: packagesInState, positions: positionsInState } =
      uiState().contextFilters;

    cf_PackagesEnabled(false);
    setOffensePackages([]);
    setDefensePackages([]);
    cf_PositionsEnabled(false);
    setOL(positionDefaults.ol);
    setTE(positionDefaults.te);
    setWR(positionDefaults.wr);
    setRB(positionDefaults.rb);
    setQB(positionDefaults.qb);
    setDL(positionDefaults.dl);
    setLB(positionDefaults.lb);
    setDB(positionDefaults.db);

    packagesInState.enabled = false;
    packagesInState.offensePackage = [];
    packagesInState.defensePackage = [];
    positionsInState.enabled = false;
    positionsInState.ol = positionDefaults.ol;
    positionsInState.te = positionDefaults.te;
    positionsInState.wr = positionDefaults.wr;
    positionsInState.rb = positionDefaults.rb;
    positionsInState.qb = positionDefaults.qb;
    positionsInState.dl = positionDefaults.dl;
    positionsInState.lb = positionDefaults.lb;
    positionsInState.db = positionDefaults.db;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleModalClose = () => {
    const { contextFilters } = uiState();
    const { packages, positions } = contextFilters;

    ui_personnelTab(personnelTabIndex);
    uiState().ui.personnelTab = personnelTabIndex;
    cf_PackagesOffense(offensePackages);
    uiState().contextFilters.packages.offensePackage = offensePackages;
    cf_PackagesDefense(defensePackages);
    uiState().contextFilters.packages.defensePackage = defensePackages;
    cf_PackagesEnabled(cf_PackagesEnabledRV);
    packages.enabled = cf_PackagesEnabledRV;

    cf_PositionsTE([...stTE]);
    positions.te = [...stTE];
    cf_PositionsOL([...stOL]);
    positions.ol = [...stOL];
    cf_PositionsWR([...stWR]);
    positions.wr = [...stWR];
    cf_PositionsRB([...stRB]);
    positions.rb = [...stRB];
    cf_PositionsQB([...stQB]);
    positions.qb = [...stQB];
    cf_PositionsDL([...stDL]);
    positions.dl = [...stDL];
    cf_PositionsLB([...stLB]);
    positions.lb = [...stLB];
    cf_PositionsDB([...stDB]);
    positions.db = [...stDB];
    cf_PositionsEnabled(cf_PositionsEnabledRV);
    positions.enabled = cf_PositionsEnabledRV;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setModalOpen(false);
  };

  const filterCount = cf_PackagesEnabledRV || cf_PositionsEnabledRV ? 1 : 0;

  const offensivePackages = Object.values(
    groupBy(OFFENSE_PACKAGES_CONFIG, 'column')
  );
  const defensivePackages = Object.values(
    groupBy(DEFENSE_PACKAGES_CONFIG, 'column')
  );

  const isEligiblePage = useIsEligiblePage('PERSONNEL');
  const isEnabled = filterCount > 0 && isEligiblePage;

  const tabPane = [
    {
      menuItem: 'Packages',
      render: () => (
        <Tab.Pane>
          <Form className={classNames({ fade: stHover })}>
            <Form.Field>
              <Toggle
                id="packages-cf-toggle"
                label="Packages"
                onChange={() =>
                  handleEnabledToggle(
                    !cf_PackagesEnabledRV,
                    cf_PackagesEnabled,
                    'packages',
                    {
                      enabled: !cf_PackagesEnabledRV,
                    }
                  )
                }
                checked={cf_PackagesEnabledRV}
              />
              <b
                className={classNames({
                  grey: !cf_PackagesEnabledRV,
                })}
                style={{ position: 'absolute', top: '5px' }}
              />
            </Form.Field>
            <div className="half">
              <h3>Offense</h3>
              <Form.Group>
                {offensivePackages.map((col, index) => (
                  <div
                    className="packages"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`offense-packages-col-${index}`}
                  >
                    {col.map((item) => (
                      <Form.Field key={`offense-packages-${item.label}`}>
                        <Checkbox
                          id={`offense-packages-${item.label}-checkbox`}
                          label={item.label}
                          onChange={() => handlePackages(item.value, true)}
                          checked={getCheckedState(offensePackages, item.value)}
                        />
                      </Form.Field>
                    ))}
                  </div>
                ))}
              </Form.Group>
            </div>
            <div className="half">
              <h3>Defense</h3>
              <Form.Group>
                {defensivePackages.map((col, index) => (
                  <div
                    className="packages"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`defense-packages-col-${index}`}
                  >
                    {col.map((item) => (
                      <Form.Field key={`defense-packages-${item.label}`}>
                        <Checkbox
                          id={`defense-packages-${item.label}-checkbox`}
                          label={item.label}
                          onChange={() => handlePackages(item.value)}
                          checked={getCheckedState(defensePackages, item.value)}
                        />
                      </Form.Field>
                    ))}
                  </div>
                ))}
              </Form.Group>
            </div>
            <SelectAllButtonContainer>
              <Button size="small" onClick={() => handleAllPackages(true)}>
                Select All
              </Button>
              <Button size="small" onClick={() => handleAllPackages(false)}>
                Deselect All
              </Button>
            </SelectAllButtonContainer>
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Positions',
      render: () => (
        <Tab.Pane>
          <Form className={classNames({ fade: stHover })}>
            <Form.Field className="no-margin">
              <Toggle
                id="positions-cf-toggle"
                label="Positions"
                onChange={() =>
                  handleEnabledToggle(
                    !cf_PositionsEnabledRV,
                    cf_PositionsEnabled,
                    'positions',
                    {
                      enabled: !cf_PositionsEnabledRV,
                    }
                  )
                }
                checked={cf_PositionsEnabledRV}
              />
              <b
                className={classNames({
                  grey: !cf_PositionsEnabledRV,
                })}
              />
            </Form.Field>
            <div className="info">
              <h3>Offense</h3>

              <div className="tiles">
                <div
                  className="position ol te"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    TE
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.TIGHT_END}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderTE">{`${stTE[0]}${
                    stTE[1] !== stTE[0] ? ` — ${stTE[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stTE}
                      ariaLabel={['teMin', 'teMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setTE)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={5}
                      withTracks
                      step={1}
                      name="sliderTE"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </div>
                </div>

                <div
                  className="position ol"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    OL
                    <PositionIcon
                      rosterPosition={
                        API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN
                      }
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderOL">{`${stOL[0]}${
                    stOL[1] !== stOL[0] ? ` — ${stOL[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stOL}
                      ariaLabel={['olMin', 'olMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setOL)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={5}
                      max={10}
                      withTracks
                      step={1}
                      name="sliderOL"
                    />
                    <div className="track olLine">
                      <div>5</div>
                      <div>6</div>
                      <div>7</div>
                      <div>8</div>
                      <div>9</div>
                      <div>10</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tiles">
                <div
                  className="position wr"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    WR
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.WIDE_RECEIVER}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderWR">{`${stWR[0]}${
                    stWR[1] !== stWR[0] ? ` — ${stWR[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stWR}
                      ariaLabel={['wrMin', 'wrMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setWR)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={5}
                      withTracks
                      step={1}
                      name="sliderWR"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </div>
                </div>
                <div
                  className="position rb"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    RB
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.RUNNING_BACK}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderRB">{`${stRB[0]}${
                    stRB[1] !== stRB[0] ? ` — ${stRB[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stRB}
                      ariaLabel={['rbMin', 'rbMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setRB)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={5}
                      withTracks
                      step={1}
                      name="sliderRB"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </div>
                </div>

                <div
                  className="position qb"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    QB
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.QUARTERBACK}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderQB">{`${stQB[0]}${
                    stQB[1] !== stQB[0] ? ` — ${stQB[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stQB}
                      ariaLabel={['qbMin', 'qbMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setQB)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={2}
                      withTracks
                      step={1}
                      name="sliderQB"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                    </div>
                  </div>
                </div>
              </div>
              <h3>Defense</h3>
              <div className="tiles">
                <div
                  className="position dl"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    DL
                    <PositionIcon
                      rosterPosition={
                        API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN
                      }
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderDL">{`${stDL[0]}${
                    stDL[1] !== stDL[0] ? ` — ${stDL[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stDL}
                      ariaLabel={['dlMin', 'dlMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setDL)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={6}
                      withTracks
                      step={1}
                      name="sliderDL"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                      <div>6</div>
                    </div>
                  </div>
                </div>
                <div
                  className="position lb"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    LB
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.LINEBACKER}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderLB">{`${stLB[0]}${
                    stLB[1] !== stLB[0] ? ` — ${stLB[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stLB}
                      ariaLabel={['lbMin', 'lbMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setLB)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={6}
                      withTracks
                      step={1}
                      name="sliderLB"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                      <div>6</div>
                    </div>
                  </div>
                </div>
                <div
                  className="position db"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <p>
                    DB
                    <PositionIcon
                      rosterPosition={API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK}
                      size={POSITION_ICON_SIZES.X_SMALL}
                    />
                  </p>
                  <label htmlFor="sliderDB">{`${stDB[0]}${
                    stDB[1] !== stDB[0] ? ` — ${stDB[1]}` : ''
                  }`}</label>
                  <div className="drop">
                    <StyledSlider
                      className="two-handle-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={stDB}
                      ariaLabel={['dbMin', 'dbMax']}
                      onChange={(sliderValues) =>
                        handlePositionSliders(sliderValues, setDB)
                      }
                      onAfterChange={handleAfterChange}
                      pearling
                      minDistance={0}
                      min={0}
                      max={8}
                      withTracks
                      step={1}
                      name="sliderDB"
                    />
                    <div className="track olLine">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                      <div>6</div>
                      <div>7</div>
                      <div>8</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <StyledModal
      onClose={handleModalClose}
      onOpen={() => {
        setModalOpen(true);
      }}
      open={st_ModalOpen}
      className="personnel"
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Personnel
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size={personnelTabIndex === 0 ? 'small' : 'tiny'}
    >
      <Modal.Content>
        <h2>Personnel</h2>
        <Tab
          panes={tabPane}
          defaultActiveIndex={ui_personnelTabRV}
          onTabChange={handleTabChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleReset}>
          Reset
        </Button>
        <Button onClick={handleModalClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

PersonnelFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default PersonnelFilter;
