import PropTypes from 'prop-types';

const xyObject = PropTypes.shape({ x: PropTypes.number, y: PropTypes.number });
const xyObjectLOS = PropTypes.shape({
  xLoS: PropTypes.number,
  yLoS: PropTypes.number,
});

const alignmentPositionShape = PropTypes.shape({
  apiCode: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  color: PropTypes.func,
  shape: PropTypes.func,
  radiusScale: PropTypes.number,
  rotation: PropTypes.number,
  isOffense: PropTypes.bool,
  isSpecialTeam: PropTypes.bool,
  rosterPositionKey: PropTypes.string,
  displayOrder: PropTypes.number,
});
export const havocChartDatumShape = PropTypes.shape({
  // description in raw coordinates of snap location from play level details
  snap: xyObject,
  // event location field values
  xRaw: PropTypes.number,
  yRaw: PropTypes.number,
  // event location relative to snap
  xLoS: PropTypes.number,
  yLoS: PropTypes.number,
  // target event location relative to snap (pressure/run disruption ~ for tackles irrelevant)
  targetXRaw: PropTypes.number,
  targetYRaw: PropTypes.number,
  targetXLoS: PropTypes.number,
  targetYLoS: PropTypes.number,
  // if there is a target location, angle from havoc event to target
  angleLoS: PropTypes.number,
  // the API_EVENT_TYPE_KEY matching the havoc type
  // tackle is used for tackles for loss. Sacks override tackle for loss
  havocType: PropTypes.string,
  // time since snap of havoc event
  postSnapSeconds: PropTypes.number,
  // Player properties are the values relevant to chosen mode (offnese/defense)
  playerId: PropTypes.number,
  playerName: PropTypes.string,
  position: alignmentPositionShape,
  positionName: PropTypes.string,
  positionCode: PropTypes.string,
  // defender and target are true for events, player pulls one of these dependent on mode
  defenderName: PropTypes.string,
  defenderPosition: alignmentPositionShape,
  targetName: PropTypes.string,
  targetPosition: alignmentPositionShape,
  // which team is on offense/defense
  offenseTeamId: PropTypes.number,
  defenseTeamId: PropTypes.number,
  // gap the defender was in at snap (API Key)
  snapGap: PropTypes.string,
  // gap the defender moved through (API Key)
  eventGap: PropTypes.string,
  // nice gap names
  snapGapName: PropTypes.string,
  eventGapName: PropTypes.string,
  snapLinePosition: PropTypes.string,
  // array of relative to snap values detailing defenders movement thoughout the play
  defenderPath: PropTypes.arrayOf(xyObjectLOS),
  /* For selected event utility/details */
  eventUUID: PropTypes.string,
  playUUID: PropTypes.string,
  driveUUID: PropTypes.string,
  game_id: PropTypes.number,
  playName: PropTypes.string,
  driveName: PropTypes.string,
  gameName: PropTypes.string,
});

// An array of objects containing the data for the havoc chart
export const havocChartDataPropType = PropTypes.arrayOf(havocChartDatumShape);
