import { styled } from 'styled-components';

export const StatBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
`;

export const StatBlockName = styled.div`
  ${({ theme }) => theme.typography.bodySmall}
`;

export const StatBlockValue = styled.div`
  ${({ theme }) => theme.typography.headingRegular}
`;

StatBlockContainer.displayName = 'StatBlockContainer';
StatBlockName.displayName = 'StatBlockName';
StatBlockValue.displayName = 'StatBlockValue';
