import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { VideoProvider } from '@statsbomb/video-client-js';
import ApolloClientProvider from './apollo/ApolloClientProvider';
import Auth0ProviderWithHistory from './apollo/Auth0ProviderWithHistory';
import App from './components/app';
import './styles/index.scss';

ReactDOM.render(
  <VideoProvider baseUrlVideoApp={process.env.VIDEO_APP_BASE_URL}>
    <Helmet>
      <script async type="text/javascript">{`
        window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; var r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a); for (var n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]) }; heap.load("${process.env.HEAP_ID}"); 
    `}</script>
    </Helmet>
    <BrowserRouter>
      <Auth0ProviderWithHistory
        domain={process.env.REACT_APP_DOMAIN}
        clientId={process.env.REACT_APP_CLIENT_ID}
        audience={process.env.REACT_APP_AUDIENCE}
      >
        <ApolloClientProvider apiUri={process.env.REACT_APP_API_URI}>
          <App />
        </ApolloClientProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </VideoProvider>,
  document.getElementById('app')
);
