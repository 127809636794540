import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { CSVLink } from 'react-csv';
import { orderBy } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import Tile from '../../../../components/Tile/Tile';
import AccordionTable from '../../../../components/Tables/AccordionTable/AccordionTable';
import {
  Controls,
  FormationSummarySectionHeader,
} from '../../TeamFormationSummary/TeamFormationSummary.styles';
import FormationSummaryTableKey from '../../TeamFormationSummary/FormationSummaryTableKey';
import {
  getFormationDetailCsvData,
  rowsToDisplay,
} from './TraitAccordion.dataManipulation';
import { PLAY_COUNT } from '../../TeamFormationSummary/TeamFormationSummary.constants';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import {
  getStatHeaders,
  orderStats,
} from '../../../League/stats/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../../utils/constants/api';
import { useFormationUsages } from '../TeamFormationDetail.hooks';
import { FORMATIONS_CONFIG } from '../TeamFormationDetail.constants';
import { mf_TeamDetails } from '../../../../apollo';
import { useTeamFormationStats } from './TraitAccordion.hooks';
import { FORMATION_DETAIL_STICKY_COLUMNS } from './TraitAccordion.constants';

const { DESCENDING, ASCENDING } = SORT_DIRECTIONS;

const TraitAccordion = ({
  formationCode,
  isTeamStats,
  statDefinitions,
  statDistributions,
  formationPlayCountTeam,
}) => {
  // global state
  const teamDetails = useReactiveVar(mf_TeamDetails);

  const [sortColumn, setSortColumn] = useState('sortBy', PLAY_COUNT);
  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [visibleStats, setVisibleStats] = useState({});
  const [displayTableKey, setDisplayTableKey] = useState(true);

  const {
    data: formationUsagesLeague,
    loading: formationUsagesLeagueLoading,
    error: formationUsagesLeagueError,
  } = useFormationUsages();
  const formationPlayCountLeague = formationUsagesLeague?.find(
    (formationUsage) => formationUsage.formationCode === formationCode
  )?.playCount;

  /* Get all the team stats for the accordion */
  const {
    data: teamStats,
    loading: teamStatsLoading,
    error: teamStatsError,
  } = useTeamFormationStats(
    formationCode,
    isTeamStats,
    statDefinitions,
    statDistributions,
    formationPlayCountTeam,
    formationPlayCountLeague
  );

  const isLoading = formationUsagesLeagueLoading || teamStatsLoading;
  const hasError = !!formationUsagesLeagueError || !!teamStatsError;

  /* Get all the team stats for the accordion */
  const headers = getStatHeaders(statDefinitions);
  const headerIds = headers.map((h) => h.id);

  // sort rows
  const sortedStats = teamStats?.map((table) => ({
    ...table,
    rows: orderBy(table.rows, (row) => row[sortColumn]?.value, sortDirection),
  }));
  // order the columns
  const orderedColumns = sortedStats?.map((table) => ({
    ...table,
    rows: orderStats(table.rows, headerIds),
  }));
  // trim rows
  const trimmedStats = orderedColumns?.map((table, index) => ({
    ...table,
    rows: rowsToDisplay(table.rows, visibleStats[index]),
    expandButtonCondition:
      table.rows.length >
      table.rows.filter((row) => row.formationPlayRate.value >= 0.1).length,
  }));

  /* Handlers for Accordion interactions */
  const handleSort = (headerId) => {
    if (sortColumn === headerId) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(DESCENDING);
      setSortColumn(headerId);
    }
  };
  const handleDataVisibility = (index, status) =>
    setVisibleStats({
      ...visibleStats,
      [index]: status,
    });

  // auto set any section with a formationPlayRate >= 10% to partial visibility
  useEffect(() => {
    if (teamStats) {
      const visibility = teamStats.reduce(
        (acc, table, index) => ({
          ...acc,
          [index]: table.rows.filter(
            (row) => row.formationPlayRate.value >= 0.1
          ).length
            ? 'partial'
            : 'closed',
        }),
        {}
      );
      setVisibleStats(visibility);
    }
  }, [!!teamStats]);

  // csv formatting
  const fileName = `Formation Detail - ${teamDetails?.name} ${FORMATIONS_CONFIG[formationCode].name}`;
  const formattedCsvData = getFormationDetailCsvData(orderedColumns);

  return (
    <Tile>
      <Tile.Header>
        <FormationSummarySectionHeader>
          <div>Key Metrics</div>
          <Controls>
            <ButtonIcon
              size="small"
              variant="secondary"
              icon="Key"
              onClick={() => setDisplayTableKey(!displayTableKey)}
              off={!displayTableKey}
            >
              {`${displayTableKey ? 'hide' : 'display'} table key`}
            </ButtonIcon>
            <CSVLink data={formattedCsvData} filename={fileName}>
              <ButtonIcon
                size="small"
                variant="secondary"
                icon="Download"
                disabled={isLoading}
                title="Download Formation Detail Table"
              >
                download formation detail table
              </ButtonIcon>
            </CSVLink>
          </Controls>
        </FormationSummarySectionHeader>
      </Tile.Header>
      <Tile.Body className="section-body">
        {isLoading && (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        )}
        {hasError && (
          <Dimmer active inverted>
            Error loading Team Stats Data
          </Dimmer>
        )}
        {!isLoading && (
          <AccordionTable
            tables={trimmedStats}
            headers={headers}
            handleSort={handleSort}
            sortBy={sortColumn}
            sortDirection={sortDirection}
            handleDataVisibility={handleDataVisibility}
            visibleStats={visibleStats}
            stickyColumns={FORMATION_DETAIL_STICKY_COLUMNS}
            expandButtonSuffix="traits"
          />
        )}
        {displayTableKey && <FormationSummaryTableKey />}
      </Tile.Body>
    </Tile>
  );
};

TraitAccordion.propTypes = {
  // name of the formation type (API key value)
  formationCode: PropTypes.string.isRequired,
  // whether the stat values are the team values, or vs league average delta values
  isTeamStats: PropTypes.bool,
  // For each stat the details of it's display. Relevance here is just the units
  statDefinitions: PropTypes.arrayOf(
    PropTypes.shape({ units: PropTypes.oneOf(Object.values(API_STAT_UNITS)) })
  ),
  // whether the stat values are the team values, or vs league average delta values
  statDistributions: PropTypes.arrayOf(
    PropTypes.shape({
      formation: PropTypes.string,
      mean: PropTypes.number,
      stdev: PropTypes.number,
    })
  ),
  // how many plays the team used the selected formation
  formationPlayCountTeam: PropTypes.number,
};

TraitAccordion.defaultProps = {
  isTeamStats: true,
  statDefinitions: null,
  statDistributions: null,
  formationPlayCountTeam: 0,
};

export default TraitAccordion;
