import { keyColorDots } from '../../../../utils/visualisations/playSuccess';
import {
  COLORING_OPTIONS_TEAM,
  COLORING_OPTION_BY_PLAYER,
  COLORING_OPTION_BY_TARGET,
  COLORING_OPTION_PASS_SUCCESS,
  COLORING_OPTION_PASS_VELOCITY,
  COLORING_OPTION_PLAY_SUCCESS,
  COLORING_OPTION_UNDERTHOW_OVERTHROW,
  COLORING_OPTION_NONE,
} from '../../../../visualisations/PassingChart/PassingChart.constants';
import { colorPassers, colorTargets } from '../DataManipulation';
import { PASSING_KEY_SIZE } from './PassingKey.constants';

/* When coloring based on a player, add color value to data */
export const getPlayerColors = (
  coloringOption,
  passers,
  targets,
  visPalette
) => {
  if (coloringOption === COLORING_OPTION_BY_PLAYER.value) {
    const players = passers || [];
    return colorPassers(players, visPalette);
  }
  if (coloringOption === COLORING_OPTION_BY_TARGET.value) {
    const players = targets || [];
    return colorTargets(players, visPalette);
  }
  return null;
};

export const getColorDotsSetup = (coloringOption, visPalette, players) => {
  if (coloringOption === COLORING_OPTION_PASS_SUCCESS.value) {
    return [
      { color: visPalette.successFail.success.main, name: 'Completed Pass' },
      {
        color: visPalette.successFail.superSuccess.main,
        name: 'Touchdown Pass',
      },
      { color: visPalette.successFail.fail.main, name: 'Incomplete Pass' },
      { color: visPalette.successFail.superFail.main, name: 'Interception' },
    ];
  }
  if (coloringOption === COLORING_OPTION_UNDERTHOW_OVERTHROW.value) {
    return [
      { color: visPalette.successFail.neutral.main, name: 'Pass' },
      { color: visPalette.successFail.fail.main, name: 'Overthrow' },
      { color: visPalette.successFail.fail2.main, name: 'Underthrow' },
    ];
  }
  if (coloringOption === COLORING_OPTION_PLAY_SUCCESS.value) {
    return keyColorDots(visPalette);
  }
  if (
    coloringOption === COLORING_OPTION_BY_PLAYER.value ||
    coloringOption === COLORING_OPTION_BY_TARGET.value
  ) {
    return players.map((m) => ({ color: m.color, name: m.playerName }));
  }
  return [];
};

export const getFillDotsSetup = (coloringOption, visPalette) => {
  const fillColor =
    coloringOption === COLORING_OPTION_NONE.value
      ? visPalette.objects.n1.main
      : visPalette.objects.neutral.main;
  const values = [
    { color: fillColor, name: 'Pass in bounds' },
    {
      color: fillColor,
      name: 'Pass out of bounds',
      hollow: true,
    },
  ];
  return values;
};

export const colorOptionName = (coloringOption) => {
  const opt = COLORING_OPTIONS_TEAM.find((c) => c.value === coloringOption);
  return `Dots colored by ${opt.label}`;
};

export const getColorSectionHeight = (coloringOption, passers, targets) => {
  if (coloringOption === COLORING_OPTION_NONE.value) {
    return 0;
  }
  if (coloringOption === COLORING_OPTION_PASS_VELOCITY.value) {
    return (
      PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL +
      PASSING_KEY_SIZE.HEIGHT.GRADIENT_DOT_ROW +
      PASSING_KEY_SIZE.PADDING.SECTION
    );
  }
  if (
    coloringOption === COLORING_OPTION_PLAY_SUCCESS.value ||
    (coloringOption === COLORING_OPTION_BY_PLAYER.value &&
      passers?.length > PASSING_KEY_SIZE.DOTS_PER_ROW) ||
    (coloringOption === COLORING_OPTION_BY_TARGET.value &&
      targets?.length > PASSING_KEY_SIZE.DOTS_PER_ROW)
  ) {
    return (
      PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL +
      PASSING_KEY_SIZE.HEIGHT.DOUBLE_DOT_ROW +
      PASSING_KEY_SIZE.PADDING.SECTION
    );
  }
  return (
    PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL +
    PASSING_KEY_SIZE.HEIGHT.DOT_ROW +
    PASSING_KEY_SIZE.PADDING.SECTION
  );
};
