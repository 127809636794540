import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  from,
  useReactiveVar,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { ui_networkError, cache } from '.';

const ApolloClientProvider = ({ apiUri, children }) => {
  const { getAccessTokenSilently } = useKitbagAuth();
  const ui_networkErrorRV = useReactiveVar(ui_networkError);

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      fetch,
      uri: apiUri,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          )
        );
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        ui_networkError(true);
      } else if (ui_networkErrorRV) {
        ui_networkError(false);
      }
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache,
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ApolloClientProvider.propTypes = {
  apiUri: PropTypes.string,
  children: PropTypes.element,
};

ApolloClientProvider.defaultProps = {
  apiUri: '',
  children: null,
};

export default ApolloClientProvider;
