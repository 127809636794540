import { minBy } from 'lodash';
import {
  SAFE_COLORS_DARK_MODE,
  SAFE_COLORS_LIGHT_MODE,
  SAFE_GRAYS_DARK_MODE,
  SAFE_GRAYS_LIGHT_MODE,
  WHITELIST_COLORS,
} from '../constants/safeColors';

export const sixLengthHex = (hashColor) => {
  const hashlessHex = hashColor?.replace('#', '');
  const noAlphaHex = hashlessHex?.substring(0, 6);
  /* Full hex code */
  if (noAlphaHex?.length === 6) {
    return noAlphaHex;
  }
  /* Short (3 letter) code */
  if (noAlphaHex?.length === 3) {
    const shortHexPieces = noAlphaHex.match(/.{1}/g);
    return (
      `${shortHexPieces[0]}${shortHexPieces[0]}` +
      `${shortHexPieces[1]}${shortHexPieces[1]}` +
      `${shortHexPieces[2]}${shortHexPieces[2]}`
    );
  }
  /* Else something odd going on ~> paint it black */
  return '000000';
};

export const hexToRGB = (hashColor) => {
  const cleanHexCode = sixLengthHex(hashColor);
  /* Turn "00ff44" into ["00", "ff", "44"] */
  const rgbHexPieces = cleanHexCode.match(/.{1,2}/g);
  /* parse in base 16 */
  const R = parseInt(rgbHexPieces[0], 16); //
  const G = parseInt(rgbHexPieces[1], 16);
  const B = parseInt(rgbHexPieces[2], 16);
  return { R, G, B };
};

export const rgbToHex = (rgb) => {
  const rString = rgb.R.toString(16);
  const gString = rgb.G.toString(16);
  const bString = rgb.B.toString(16);
  const rHex = rString.length === 1 ? `0${rString}` : rString;
  const gHex = gString.length === 1 ? `0${gString}` : gString;
  const bHex = bString.length === 1 ? `0${bString}` : bString;
  return `#${rHex}${gHex}${bHex}`;
};

export const euclideanDistanceRGB = (
  { R: R1, G: G1, B: B1 },
  { R: R2, G: G2, B: B2 }
) => {
  const rDiff = (R2 - R1) ** 2;
  const gDiff = (G2 - G1) ** 2;
  const bDiff = (B2 - B1) ** 2;

  const distance = Math.sqrt(rDiff + gDiff + bDiff);
  const maxDistance = Math.sqrt(255 ** 2 * 3);
  const normalizedDistance = distance / maxDistance;
  return normalizedDistance;
};

/* 
  If the color coming in is grayscale keep in grayscale
  Stops dark/lights out of range for the palette jumping to random colors
*/
export const getSafePalette = (isGrayscale, isDarkMode) => {
  switch (true) {
    case isGrayscale && isDarkMode:
      return SAFE_GRAYS_DARK_MODE;
    case isGrayscale:
      return SAFE_GRAYS_LIGHT_MODE;
    case isDarkMode:
      return SAFE_COLORS_DARK_MODE;
    default:
      return SAFE_COLORS_LIGHT_MODE;
  }
};

/*
Handling visual perception of grayscale: 
https://www.notion.so/statsbomb/When-is-grey-not-grey-b28e071cfbb040b5b33aed5b6debcfab
*/
const maxGrayScaleDiff = 20;
const maxSmallestGrayScaleDiff = 6;
export const isGrayscale = ({ R, G, B }) => {
  const rg = Math.abs(R - G);
  const bg = Math.abs(B - G);
  const rb = Math.abs(R - B);
  return (
    rg <= maxGrayScaleDiff &&
    bg <= maxGrayScaleDiff &&
    rb <= maxGrayScaleDiff &&
    Math.min(rg, bg, rb) <= maxSmallestGrayScaleDiff
  );
};
const isBlack = ({ R, G, B }) => R < 5 && G < 5 && B < 5;
const isWhite = ({ R, G, B }) => R > 250 && G > 250 && B > 250;
const isBadGray = (rgb, isDarkMode) =>
  (isDarkMode && isBlack(rgb)) ||
  (!isDarkMode && isWhite(rgb)) ||
  (isGrayscale(rgb) && !isBlack(rgb) && !isWhite(rgb));

/* This function finds the nearest matching safe color according to palette */
export const getSafeColorFromHex = (hexCode, isDarkMode) => {
  const hex = hexCode || '#000000';
  const rgb = hexToRGB(hex);

  /* Special Case ~ for black in dark / white in light: 
    flip to other extreme (rather than shift to nearby gray)
  */
  if (isBlack(rgb) && isDarkMode) {
    return '#ffffff';
  }
  if (isWhite(rgb) && !isDarkMode) {
    return '#000000';
  }

  const safeColorList = getSafePalette(isGrayscale(rgb), isDarkMode);
  const rgbPalette = safeColorList.map((safeHex) => {
    const safeRGB = hexToRGB(safeHex);
    const distance = euclideanDistanceRGB(rgb, safeRGB);
    return { hex: safeHex, distance };
  });

  const bestMatch = minBy(rgbPalette, 'distance');
  return bestMatch.hex;
};

/* Purpose of this function is to eliminate grays where possible
  Blacks in light mode and Whites in dark mode are considered fine
  For conflicts or actual grays, use a tertiary color where possible
*/
export const getTeamColorPair = (teamColors, isDarkMode) => {
  const rgb1 = hexToRGB(teamColors?.primary);
  const rgb2 = hexToRGB(teamColors?.secondary);
  const rgb3 = hexToRGB(teamColors?.tertiary);

  /* Default pair */
  const pair = { primary: rgb1, secondary: rgb2 };

  /* 
  Can force specific colors to be used ~ dangerous
  Temporary measure until we improve the safe palette logic
  */
  const keepRGB1 = WHITELIST_COLORS.includes(teamColors?.primary);
  const keepRGB2 = WHITELIST_COLORS.includes(teamColors?.secondary);
  /* If either default is grayscale and tertiary isn't, switch it */
  if (!keepRGB1 && isBadGray(rgb1, isDarkMode) && !isGrayscale(rgb3)) {
    pair.primary = rgb3;
  } else if (!keepRGB2 && isBadGray(rgb2, isDarkMode) && !isGrayscale(rgb3)) {
    pair.secondary = rgb3;
  }

  /* Return best possible hex codes */
  const hexPair = {
    primary: rgbToHex(pair.primary),
    secondary: rgbToHex(pair.secondary),
  };
  return hexPair;
};
