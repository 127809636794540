import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { GET_PLAYER_BIO } from './getPlayerBio';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { API_COMPETITION } from '../../../utils/constants/api';
import PlayerBioNCAA from './PlayerBio/PlayerBioNCAA';
import PlayerBioNFL from './PlayerBio/PlayerBioNFL';
import { mf_Leagues } from '../../../apollo';

const PlayerBioTile = () => {
  const [displaySummaryOpen, setDisplaySummaryOpen] = useState(true);
  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_PLAYER_BIO);
  const competitionId = useReactiveVar(mf_Leagues);
  const isNFL = API_COMPETITION[competitionId] === 'NFL';
  const isNCAA =
    API_COMPETITION[competitionId] === 'NCAA' ||
    API_COMPETITION[competitionId] === 'NCAA2';
  const minTileHeight = isNCAA ? '146.5px' : '72.5px';

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Player Information</h3>
        </div>
        <div>
          <ButtonIcon
            onClick={() => setDisplaySummaryOpen(!displaySummaryOpen)}
            variant="secondary"
            shape="pill"
            size="small"
            icon={displaySummaryOpen ? 'ChevronUp' : 'ChevronDown'}
            isDisabled={isLoading}
            title="ChevronUp"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody
        style={{
          minHeight: displaySummaryOpen ? minTileHeight : '0',
          maxHeight: displaySummaryOpen ? '255px' : '0',
        }}
        $margin="0"
        $overflowY="hidden"
      >
        <>
          {data?.length === 0 && !isLoading && (
            <TileDimmer active>
              <p>No Data available</p>
            </TileDimmer>
          )}
          {hasError && !isLoading && (
            <TileDimmer active>
              <p>Error loading data</p>
            </TileDimmer>
          )}
          {isNCAA && <PlayerBioNCAA playerBioData={data?.playerBioOverview} />}
          {isNFL && <PlayerBioNFL playerBioData={data?.playerBioOverview} />}
        </>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerBioTile;
