import { appendText } from '../text';

const addColumn = (svgG, options) => {
  const x = options.x || 0;
  const y = options.y || 0;
  const w = options.w || 100;
  const h = options.h || 100;
  const fill = options.fill || 'pink';
  svgG
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', w)
    .attr('height', h)
    .attr('fill', fill);
};

const addZones = (svgG, palette, paletteName) => {
  addColumn(svgG, {
    x: 80,
    w: 50,
    h: '100%',
    fill: palette.zones.default,
  });
  addColumn(svgG, {
    x: 130,
    w: 50,
    h: '100%',
    fill: palette.zones.alternate,
  });
  addColumn(svgG, {
    x: 180,
    w: 50,
    h: '100%',
    fill: palette.zones.alternate2,
  });
  addColumn(svgG, {
    x: 230,
    w: 50,
    h: '100%',
    fill: palette.zones.important,
  });
  appendText(svgG, palette, { x: 10, y: 15, message: paletteName });
  appendText(svgG, palette, {
    x: 75,
    y: 30,
    message: 'Zones:',
    textAnchor: 'end',
  });
  appendText(svgG, palette, {
    x: 85,
    y: 30,
    message: 'Default',
  });
  appendText(svgG, palette, {
    x: 135,
    y: 30,
    message: 'Alt',
  });
  appendText(svgG, palette, {
    x: 185,
    y: 30,
    message: 'Alt2',
  });
  appendText(svgG, palette, {
    x: 235,
    y: 30,
    message: 'Important',
  });
};

export { addColumn, addZones };
