import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { Tabs } from '@statsbomb/kitbag-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import SortableTable from '../SortableTable/SortableTable';
import BasicTable from '../../Table/BasicTable';
import {
  sortableTableHeadersPropTypes,
  tableRowsPropTypes,
  sortableTableAlignmentPropTypes,
  sortableTableStickyColumnsPropTypes,
} from '../Tables.propTypes';
import { StyledTabbedTable, StyledPaneContainer } from './TabbedTable.styles';
import { StyledSearch } from '../../GlobalSearch/GlobalSearch.styles';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import Dimmer from '../../Dimmer/Dimmer';

const TabbedTable = ({
  tableTabsConfig,
  subTabs,
  selectedSubTab,
  onSubTabChange,
  isSearchable,
  isLoading,
  onTabChange,
  handleSort,
  sortDirection,
  searchValue,
  onSearchChange,
  sortBy,
  withPadding,
  withBorder,
  basicTable,
  isHeadSticky,
  highlightedColumn,
  hideParentTabs,
  nonTableHeight,
}) => {
  const [activeTableTab, setActiveTableTab] = useQueryString(
    'tab',
    tableTabsConfig[0]?.value || tableTabsConfig[0]?.id
  );
  const tabs = tableTabsConfig.map((tab) => ({
    label: tab.label,
    value: tab.id,
  }));
  const subTab = subTabs.map((sub) => ({
    label: sub,
    value: sub,
  }));
  const hasSubTabs = subTab.length > 0;

  const renderFeedback = (loading, results) => {
    if (loading && !basicTable) {
      return (
        <div style={{ display: 'relative' }}>
          <Dimmer active>
            <Loader content="Loading" data-testid="loading-tables" />
          </Dimmer>
        </div>
      );
    }
    if (!results?.length) {
      return <h3 className="no-results">No Results Found</h3>;
    }

    return null;
  };

  const panes = tableTabsConfig.map((tab) => ({
    render: () => (
      <StyledPaneContainer $withBorder={withBorder}>
        <div
          style={{
            minHeight:
              tab.tableConfig.rows?.length > 0 ? '0' : 'calc(100vh - 17rem)',
          }}
        >
          {renderFeedback(isLoading, tab.tableConfig.rows)}
          {tab.tableConfig.rows?.length > 0 &&
            (basicTable ? (
              <BasicTable
                data={tab.tableConfig.rows}
                headers={tab.tableConfig.headers}
                footer={tab.tableConfig.footer}
                showHeader
                maxHeight={tab.tableConfig.maxHeight}
                withBorder={withBorder}
              />
            ) : (
              <SortableTable
                stickyColumns={tab.tableConfig.stickyColumns}
                footerRows={tab.tableConfig.footer}
                headers={tab.tableConfig.headers}
                rows={tab.tableConfig.rows}
                name={`${tab.id}-tt`}
                alignment={tab.tableConfig.alignment}
                handleSort={handleSort}
                sortDirection={sortDirection}
                sortBy={sortBy}
                isHeadSticky={isHeadSticky}
                highlightedColumn={highlightedColumn}
                nonTableHeight={nonTableHeight}
              />
            ))}
        </div>
      </StyledPaneContainer>
    ),
  }));

  const handleTabChange = (e, tab) => {
    onTabChange(e, tab);
    setActiveTableTab(tab.value);
  };

  return (
    <StyledTabbedTable
      $withPadding={withPadding}
      $withBorder={withBorder}
      $smallText
      $isLoading={isLoading}
    >
      {isSearchable && (
        <StyledSearch
          input={{ icon: 'search', iconPosition: 'left' }}
          value={searchValue}
          onSearchChange={onSearchChange}
          showNoResults={false}
        />
      )}
      {!hideParentTabs && (
        <Tabs
          tabs={tabs}
          isParent={hasSubTabs}
          activeTab={activeTableTab}
          onTabChange={(e, tab) => handleTabChange(e, tab)}
        />
      )}
      {hasSubTabs && (
        <StyledPaneContainer $withBorder={withBorder}>
          <Tabs
            tabs={subTab}
            activeTab={selectedSubTab}
            onTabChange={onSubTabChange}
          />
        </StyledPaneContainer>
      )}
      {panes.length > 0 &&
        panes[tabs.findIndex((t) => t.value === activeTableTab)]?.render()}
    </StyledTabbedTable>
  );
};

TabbedTable.propTypes = {
  // the config driving the tables data and functionality
  tableTabsConfig: PropTypes.arrayOf(
    PropTypes.exact({
      // the value of the tab
      value: PropTypes.string.isRequired,
      // the id of the tab
      id: PropTypes.string.isRequired,
      // the displayed label for the tab
      label: PropTypes.string.isRequired,
      // the config driving the table a tab is rendering
      tableConfig: PropTypes.exact({
        // An array of objects containing the data for the table header rows
        headers: sortableTableHeadersPropTypes.isRequired,
        // footer, one row of data
        footer: tableRowsPropTypes,
        // max-height of table body invoking this will provide stick header and footer
        maxHeight: PropTypes.number,
        // the data driving the rows of the table
        rows: tableRowsPropTypes,
        // determines how each column should be aligned. can be either an alignment
        // string or a function using the index of the columns/headers
        alignment: sortableTableAlignmentPropTypes,
        // Which columns to keep on screen when the user scrolls right.
        // Can be a single number (1-based index) if only one sticky column is
        // required, otherwise needs to be an array of column objects containing the
        // column index and width.
        stickyColumns: sortableTableStickyColumnsPropTypes,
      }),
    })
  ).isRequired,
  // whether the tables can be filtered with a search bar
  isSearchable: PropTypes.bool,
  // callback function that triggers when the user changes tabs
  onTabChange: PropTypes.func,
  // whether or not the headers remain in place when the user scrolls down
  isHeadSticky: PropTypes.bool,
  // whether the table is loading
  isLoading: PropTypes.bool,
  // a list of sub tabs to render if needed
  subTabs: PropTypes.arrayOf(PropTypes.string),
  // keeps track of which sub tab is currently selected
  selectedSubTab: PropTypes.string,
  // function that is called when the user clicks on a sub tab
  onSubTabChange: PropTypes.func,
  // function that is called when a sortable header is clicked
  handleSort: PropTypes.func,
  // the state of the column sort
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  // by which column the table is sorted
  sortBy: PropTypes.string,
  // text to search the table for matching results
  searchValue: PropTypes.string,
  // callback function when typing in the search bar
  onSearchChange: PropTypes.func,
  // should we add padding to wrapping dive of the table?
  withPadding: PropTypes.bool,
  // styling: border effect
  withBorder: PropTypes.bool,
  // tab render Basic Table will fall back to Sortable Table
  basicTable: PropTypes.bool,
  // which column the table sorting is currently based on (1 based index)
  highlightedColumn: PropTypes.number,
  // hide the top tabs
  hideParentTabs: PropTypes.bool,
  // height of the non-table content
  nonTableHeight: PropTypes.string,
};

TabbedTable.defaultProps = {
  isHeadSticky: false,
  isSearchable: false,
  isLoading: false,
  onTabChange: () => {},
  subTabs: [],
  selectedSubTab: '',
  onSubTabChange: () => {},
  handleSort: () => {},
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  sortBy: '',
  searchValue: '',
  onSearchChange: () => {},
  withPadding: true,
  withBorder: true,
  basicTable: false,
  highlightedColumn: 0,
  hideParentTabs: false,
  nonTableHeight: '32.5rem',
};

export default TabbedTable;
