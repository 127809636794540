import React from 'react';
import { Outlet } from 'react-router-dom';
import { BasicPage } from '../../components/Layout/Layout.styles';

const Team = () => (
  <BasicPage>
    <Outlet />
  </BasicPage>
);

export default Team;
