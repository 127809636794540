import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const getTeamRadarStatsQuery = (selectedStatNames, isOffense) => {
  const joinedStatNames =
    (selectedStatNames && selectedStatNames.join('\n')) || '';
  const gqlString = `
  query getTeamStatsForRadar(
    $competitionId: Int!
    $seasonId: Int!
    $teamId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}
    teamStats(
      filters: {  
        competitionId: $competitionId
        seasonId: $seasonId
        offenseTeamIds: [${isOffense ? '$teamId' : ''}]
        defenseTeamIds: [${!isOffense ? '$teamId' : ''}]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      items {  
        groups {
          key
          value
        }
        playCount
        ${joinedStatNames}
        }
    }
  }
`;
  return gqlString;
};

export const getTeamRadarStatsLeagueAverageQuery = (selectedStatNames) => {
  const joinedStatNames =
    (selectedStatNames && selectedStatNames.join(',')) || '';
  const gqlString = `
  query getTeamStatsForRadar(
    $competitionId: Int!
    $seasonId: Int!
    $teamId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}
    teamStats(
      filters: {  
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
    ) {
      items {  
        groups {
          key
          value
        }
        playCount
        ${joinedStatNames}
        }
    }
  }
`;
  return gqlString;
};
