import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@statsbomb/kitbag-auth';
import { Loader } from 'semantic-ui-react';

export const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  component: null,
};

export default { ProtectedRoute };
