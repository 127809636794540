import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@statsbomb/kitbag-components';
import { POSITION_CATEGORIES } from '../../utils/constants/positions';
import { PositionChipWrapper } from './PositionFilter.styles';
import { ALL_POSITION_CODES } from './PositionFilter.constants';

const PositionChips = ({
  positionDefinitions,
  setSelectedPositions,
  openModal,
  title,
}) => {
  const positionGroups = Object.values(POSITION_CATEGORIES).map((cat) => {
    const catPositions = positionDefinitions.filter(
      (p) => p.positionCategory === cat.key
    );
    return {
      ...cat,
      positions: catPositions,
      allSelected: catPositions.every((c) => c.isSelected),
    };
  });

  const catsToChip = positionGroups.filter((c) => c.allSelected);
  const allSelectedCatKeys = catsToChip.map((m) => m.key);
  const posToChip = positionDefinitions.filter(
    (p) => p.isSelected && !allSelectedCatKeys.includes(p.positionCategory)
  );

  // figure out if there is only one chip being displayed and disable it
  const isDisabled = catsToChip.length + posToChip.length === 1;

  const categoryChipClicked = (categoryKey) => {
    const selectedNonCatPositions = positionDefinitions.filter(
      (p) => p.positionCategory !== categoryKey && p.isSelected
    );
    const selectedPositions = selectedNonCatPositions.map((p) => p.apiCode);
    setSelectedPositions(selectedPositions);
  };

  const positionChipClicked = (positionApiCode) => {
    const otherSelectedPositions = positionDefinitions.filter(
      (p) => p.apiCode !== positionApiCode && p.isSelected
    );
    const selectedPositions = otherSelectedPositions.map((p) => p.apiCode);
    setSelectedPositions(selectedPositions);
  };

  return (
    <div>
      {title && <h3>{title}</h3>}
      <PositionChipWrapper>
        {catsToChip.map((category) => (
          <Chip
            key={`${category.key}-chip`}
            label={category.name}
            onClick={() => categoryChipClicked(category.key)}
            disabled={isDisabled}
          />
        ))}
        {posToChip.map((position) => (
          <Chip
            key={`${position.apiCode}-chip`}
            label={position.label}
            onClick={() => positionChipClicked(position.apiCode)}
            disabled={isDisabled}
          />
        ))}
        <Chip variant="more" label="Select Positions" onClick={openModal} />
      </PositionChipWrapper>
    </div>
  );
};

PositionChips.propTypes = {
  // position definitions: an array of either Roster positions or alignment positions
  // these should have been supplemented with label and tooltip for chip displaying
  // they also need to have an isSelected boolean corresponding to the selectedPositions state object
  //    for which setSelectedPositions applies
  positionDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      apiCode: PropTypes.oneOf(ALL_POSITION_CODES),
      positionCategory: PropTypes.oneOf(Object.keys(POSITION_CATEGORIES)),
      label: PropTypes.string,
      tooltip: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ).isRequired,
  // clicking the more button opens the accompanying modal
  openModal: PropTypes.func.isRequired,
  // callback function when a position chip is clicked
  setSelectedPositions: PropTypes.func.isRequired,
  // optional section title
  title: PropTypes.string,
};

PositionChips.defaultProps = {
  title: null,
};

export default PositionChips;
