// user groups from
// https://admin.statsbomb.com/
const USER_GROUP = {
  STATSBOMB: 'statsbomb',
};

const USER_ROLES = {
  VIDEO_CUSTOMER: 'Video Customer',
};

const VIDEO_JWT_CLAIMS = 'https://video.statsbomb.com/jwt/claims';

export { USER_GROUP, USER_ROLES, VIDEO_JWT_CLAIMS };
