export const LINE_PRESSURE_TILE_OPTIONS = [
  {
    label: 'Line Pressures',
    value: false,
    variant: 'Analysis',
  },
  {
    label: 'Gap and Position Tables',
    value: true,
    variant: 'ListView',
  },
];
