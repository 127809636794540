import { styled } from 'styled-components';

export const TacklingDistributionTeamTile = styled.div`
  svg {
    height: 550px;
    margin: auto;
  }
  padding-bottom: 1.6rem;
`;

export const TacklingVizTeamTile = styled.div`
  svg {
    max-height: 470px;
    margin: auto;
  }
`;
