import { uniqBy, range, sortBy, maxBy, minBy } from 'lodash';
import { formatTimestamp } from '../../../utils/helpers/time';
import { getRosterPositionInfo } from '../../../utils/helpers/positions';

/* In non line battle data Pathing is added every ~0.4s 
  So the max "acceptable" gap is just less than this. 
  The min diff stops pathing events right before core events getting added
  */
const MAX_TIMELINE_DIFF = 0.35; // s
const MIN_TIMELINE_DIFF = 0.1; // s

const removeLineBattles = function (parsedEvents) {
  const engagementFirstStartIndex =
    parsedEvents.find((procEvt) => procEvt.name.includes('Engagement Start'))
      ?.eventIndex || 999; // if there are no line battles, doing < 999 will find everything (as is desired)
  const engagementLastEndIndex =
    parsedEvents.findLast((procEvt) => procEvt.name.includes('Engagement End'))
      ?.eventIndex || -1;

  /* For animating we only care about events with FF */
  const ffEvents = parsedEvents?.filter(
    (f) => f?.freezeFrames && f?.freezeFrames.length > 0
  );
  const lastFFTimestamp = maxBy(
    ffEvents,
    'relativeTimestamp'
  )?.relativeTimestamp;
  /* Core events ~ ditch all LB events, and all pathing within that block (for now) */
  const coreEvents = ffEvents?.filter(
    (f) =>
      !f?.name.includes('Engagement Start') &&
      !f?.name.includes('Engagement End') &&
      (!f?.name.includes('Pathing') ||
        f?.eventIndex < engagementFirstStartIndex ||
        f?.eventIndex > engagementLastEndIndex)
  );

  /* Could just return coreEvents, but we've essentially over trimmed the pathing 
    The following finds each gap between core events, estimates the number of missing pathings, 
    then tries to find and add them 
  */
  const ffPathEvents = ffEvents?.filter((f) => f?.name.includes('Pathing'));
  const animationEvents = [];
  for (let index = 0; index < coreEvents.length; index += 1) {
    const thisCoreEvent = coreEvents[index];
    animationEvents.push(thisCoreEvent);

    /* Now if in the LB section where we removed pathing, maybe put some back */
    const nextCoreEvent = coreEvents[index + 1];
    const nextCoreEventTimestamp =
      nextCoreEvent?.relativeTimestamp || lastFFTimestamp;
    if (
      nextCoreEventTimestamp - thisCoreEvent.relativeTimestamp >
      MAX_TIMELINE_DIFF
    ) {
      const missingInstanceCount = Math.ceil(
        (nextCoreEventTimestamp - thisCoreEvent.relativeTimestamp) /
          MAX_TIMELINE_DIFF
      );
      for (let j = 0; j < missingInstanceCount; j += 1) {
        const missingTimestamp =
          thisCoreEvent.relativeTimestamp + (j + 1) * MAX_TIMELINE_DIFF;
        const nextPathing = minBy(
          ffPathEvents.filter(
            (p) =>
              p.relativeTimestamp >= missingTimestamp &&
              p.relativeTimestamp + MIN_TIMELINE_DIFF < nextCoreEventTimestamp
          ),
          'relativeTimestamp'
        );
        animationEvents.push(nextPathing);
      }
    }
  }
  /* Any desired slots that failed to find / dupes get removed */
  const uniqEvents = animationEvents
    ? sortBy(
        uniqBy(animationEvents?.filter(Boolean), 'event_uuid'),
        'eventIndex'
      )
    : null;

  return uniqEvents;
};

const freezeFrameConverter = function (ff, playOffenseTeamId) {
  const onOffense =
    parseInt(ff.team?.id, 10) === parseInt(playOffenseTeamId, 10);
  const positionInfo = getRosterPositionInfo(
    ff.player?.teamPosition?.generalPosition,
    onOffense
  );
  const ffOut = { ...ff };
  ffOut.playOffenseTeamId = playOffenseTeamId;
  ffOut.teamId = parseInt(ff?.team?.id, 10);
  ffOut.playerId = parseInt(ff?.player?.id, 10);
  ffOut.isDefense = ff.playOffenseTeamId !== ff.teamId;
  ffOut.positionGroup = positionInfo;
  ffOut.isEligibleReceiver = !!positionInfo.isEligibleReceiver;
  return ffOut;
};

const dotsLinesRowConverter = function (d, playStartVideoTimestamp, index) {
  const convertedData = {};
  // strings to numbers
  convertedData.game_id = parseInt(d?.game?.id, 10);
  convertedData.event_uuid = d?.id;
  convertedData.id = d?.id;
  convertedData.play_uuid = d?.play?.id;
  convertedData.play_offense_team_id = d?.play?.offenseTeam?.id;

  convertedData.playerId = parseInt(d?.player?.id, 10);
  convertedData.teamId = parseInt(d?.team?.id, 10);
  convertedData.ffCount = 0;
  convertedData.ffAnonCount = 0;
  if (d.freezeFrames.length > 0) {
    convertedData.ffCount = d.freezeFrames.filter(
      (f) => f.playerIndex >= 0
    ).length;
    convertedData.ffAnonCount = d.freezeFrames.filter(
      (f) => f.playerIndex >= 0 && f.player == null
    ).length;
    convertedData.freezeFrames = d.freezeFrames.map((f) =>
      freezeFrameConverter(f, parseInt(convertedData.play_offense_team_id, 10))
    );
  }
  convertedData.event_x = parseFloat(d?.x);
  convertedData.event_y = parseFloat(d?.y);
  convertedData.videoTimestamp = parseFloat(d.videoTimestamp);
  convertedData.relativeTimestamp =
    convertedData.videoTimestamp - playStartVideoTimestamp;

  // eslint-disable-next-line max-len
  convertedData.playName = `${d.team.name} Play ${d.play.driveIndex} D/TG: ${d.play.down}/${d.play.yardsToGo} ${d.play.type}`;
  convertedData.lineOfScrimmage = parseFloat(d.play.yardLine);
  convertedData.lineTarget =
    parseFloat(d.play.yardsToGo) + convertedData.lineOfScrimmage;
  const onOffense =
    parseInt(d.team?.id, 10) ===
    parseInt(convertedData.play_offense_team_id, 10);
  convertedData.position = getRosterPositionInfo(
    d.player?.teamPosition?.generalPosition,
    onOffense
  );

  // convertedData.niceTimestamp = formatTimestamp(convertedData.videoTimestamp);
  convertedData.niceTimestamp = formatTimestamp(
    convertedData.relativeTimestamp
  );
  convertedData.eventNamed = `${convertedData.niceTimestamp}, ${d.types[0]}`;
  convertedData.eventName = d.name;
  convertedData.name = d.name;
  convertedData.isSnap = d.types.includes('SNAP');
  convertedData.eventIndex = index;
  return convertedData;
};

const setupPlayPaths = function (
  playerId,
  playerFFs,
  offenseTeamId,
  pxPerYard,
  asPaths = false
) {
  let path;
  const ff1 = playerFFs.shift();
  if (asPaths) {
    path = [];
    path.push([ff1?.x * pxPerYard, ff1?.y * pxPerYard]);
  } else {
    path = `M${(ff1?.x * pxPerYard).toString()},${(
      ff1?.y * pxPerYard
    ).toString()}`;
  }

  playerFFs.forEach((spd) => {
    if (asPaths) {
      path.push([spd?.x * pxPerYard, spd?.y * pxPerYard]);
    } else {
      path += ` L${(spd?.x * pxPerYard).toString()},${(
        spd?.y * pxPerYard
      ).toString()}`;
    }
  });

  // create the arrows
  const miniArrows = [];
  if (playerFFs.length > 1) {
    // eslint-disable-next-line array-callback-return
    range(playerFFs.length - 1).map((n) => {
      const dx = playerFFs[n + 1].x - playerFFs[n].x;
      const dy = playerFFs[n + 1].y - playerFFs[n].y;
      const thetaRad = Math.atan2(dy, dx);

      const arrowDatum = {
        playerId,
        teamId: parseInt(ff1?.team?.id, 10),
        play_offense_team_id: offenseTeamId,
        positionGroup: ff1?.positionGroup,
        n,
        x: playerFFs[n].x * pxPerYard,
        y: playerFFs[n].y * pxPerYard,
        theta: (thetaRad * 180) / Math.PI,
      };
      miniArrows.push(arrowDatum);
    });
  }

  const playerObj = {
    playerId,
    ffs: playerFFs,
    path,
    pathArrows: miniArrows,
    positionGroup: ff1?.positionGroup,
    isEligibleReceiver: ff1?.isEligibleReceiver,
    play_offense_team_id: offenseTeamId,
    teamId: parseInt(ff1?.team?.id, 10),
  };
  return playerObj;
};

const getPlayerPaths = function (
  players,
  playFFs,
  play_offense_team_id,
  pxPerYard,
  asPaths
) {
  const playerPaths = players.map((p) => {
    const playerId = parseInt(p.player?.id, 10);
    const playerFFs = playFFs.filter(
      (d) => d?.player && parseInt(d.player?.id, 10) === playerId
    );
    const playerObj = setupPlayPaths(
      playerId,
      playerFFs,
      play_offense_team_id,
      pxPerYard,
      asPaths
    );
    return playerObj;
  });
  return playerPaths;
};

const getBallPath = function (playFFs, play_offense_team_id, pxPerYard) {
  const ballObj = setupPlayPaths(
    0,
    playFFs,
    play_offense_team_id,
    pxPerYard,
    true
  );
  return ballObj;
};

const getFFPlayers = function (formattedEvents) {
  const ffsLoc = formattedEvents.map((d) => d.freezeFrames || []);
  // eslint-disable-next-line prefer-spread
  const flattenedFFs = [].concat.apply([], ffsLoc);
  const uniqPlayers = uniqBy(
    flattenedFFs.filter((f) => f.player),
    (f) => f.player.id
  );
  const players = sortBy(uniqPlayers, [
    'isDefense',
    'positionGroup.code',
    'player.name',
  ]).map((m) => {
    const playerDescription = `#${m.player.jerseyNumber}, ${m.positionGroup.code}, ${m.player.name} (${m.team.name})`;
    return { value: parseInt(m.player.id, 10), label: playerDescription };
  });
  const defaultValue = [{ value: 0, label: 'No Highlight' }];
  return defaultValue.concat(players);
};

export {
  getPlayerPaths,
  getBallPath,
  getFFPlayers,
  dotsLinesRowConverter,
  removeLineBattles,
};
