import { uiState } from '../../apollo';

export const getTimeToLabel = (data, limits) => {
  const [from, to] = data;
  const [lower, upper] = limits;

  if (from !== lower && to === upper) {
    return `from ${from} second${from !== 1 ? 's' : ''}`;
  }
  if (from === lower && to === upper) {
    return `all`;
  }
  return `${from} — ${to} second${to !== 1 ? 's' : ''}`;
};

export const enableFilter = (isEnabled, setIsEnabled, filterKey) => {
  if (!isEnabled) {
    setIsEnabled(true);
    uiState().contextFilters[filterKey].enabled = true;
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  }
};
