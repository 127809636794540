import React from 'react';
import {
  ButtonCheck,
  Checkbox,
  // NumberInput,
  RadioButton,
  RadioGroup,
} from '@statsbomb/kitbag-components';
import { StyledSlider } from '../../../../components/ContextFilters/ContextFilters.styles';
import {
  ConfigWidgetSelectSingle,
  ConfigWidgetSelectMultiple,
  ConfigWidgetSlider,
  ConfigWidgetBool,
} from './PlayFinderWidgets.styles';
import { WizardButtonSpacing } from '../../PlayFinderWizard/PlayFinderWizard.styles';
import { PLAY_FINDER_WIDGET_TYPES } from '../../../../utils/constants/api';

export const getPlayFiltersWidget = ({
  rangeValues,
  setRangeValues,
  filterId,
  filterLabel,
  widgetType,
  widgetArgs,
  handleCallback,
}) => {
  if (widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE) {
    return (
      <ConfigWidgetSlider>
        <StyledSlider
          thumbClassName="thumb"
          trackClassName="track"
          value={rangeValues}
          ariaLabel={[`${filterLabel}Min`, `${filterLabel}Max`]}
          onChange={(value) => {
            setRangeValues(value); // Update slider values in real-time
          }}
          onAfterChange={(value) => {
            handleCallback(filterId, value); // Trigger callback after sliding ends
          }}
          pearling
          min={widgetArgs.min}
          max={widgetArgs.max}
          withTracks
          step={widgetArgs.step}
        />

        <div className="track">
          <div className="track__min">{widgetArgs.min}</div>
          <div>to</div>
          <div className="track__max">{widgetArgs.max}</div>
        </div>
      </ConfigWidgetSlider>
    );
  }
  if (widgetType === PLAY_FINDER_WIDGET_TYPES.SELECT_MULTIPLE) {
    return (
      <ConfigWidgetSelectMultiple>
        {widgetArgs.options.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${option}-${index}`}>
            <Checkbox
              id={option}
              label={option}
              value={option}
              checked={widgetArgs?.selected?.some((f) => f === option) || false}
              onChange={() => handleCallback(filterId, [option])}
            />
          </div>
        ))}
      </ConfigWidgetSelectMultiple>
    );
  }
  if (widgetType === PLAY_FINDER_WIDGET_TYPES.BOOL) {
    return (
      <ConfigWidgetBool>
        <WizardButtonSpacing>
          <ButtonCheck
            id={`${filterId}-true`}
            label="True"
            onChange={() => handleCallback(filterId, true)}
            checked={widgetArgs.selected}
          />
          <ButtonCheck
            id={`${filterId}-false`}
            label="False"
            onChange={() => handleCallback(filterId, false)}
            checked={widgetArgs.selected === false}
          />
        </WizardButtonSpacing>
      </ConfigWidgetBool>
    );
  }
  if (widgetType === PLAY_FINDER_WIDGET_TYPES.SELECT_SINGLE) {
    return (
      <ConfigWidgetSelectSingle>
        <RadioGroup
          groupId={`radio-group-${widgetArgs.options[0]}`}
          groupTitle={filterLabel}
        >
          {widgetArgs.options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${option}-${index}`}>
              <RadioButton
                id={option}
                label={option}
                value={option}
                checked={widgetArgs.selected === option}
                onChange={() => handleCallback(filterId, option)}
              />
            </div>
          ))}
        </RadioGroup>
      </ConfigWidgetSelectSingle>
    );
  }
  // custom example
  // if (
  //   filterId === 'play_box_players' &&
  //   widgetType === PLAY_FINDER_WIDGET_TYPES.CUSTOM
  // ) {
  //   return (
  //     <NumberInput
  //       id={filterId}
  //       inputAriaLabel={filterLabel}
  //       inputDigits={3}
  //       max={100}
  //       min={0}
  //       onChange={() => {}}
  //       // value={30}
  //     />
  //   );
  // }
  return <br />;
};
