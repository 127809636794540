import { gql } from '@apollo/client';

export const GET_PLAYER_BIO = gql`
  query playerBio($competitionId: Int!, $seasonId: Int!, $playerId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedPlayerId @client @export(as: "playerId")
    selectedSeasonId @client @export(as: "seasonId")
    playerBioOverview(
      competitionId: $competitionId
      seasonId: $seasonId
      playerId: $playerId
    ) {
      name
      height
      weight
      collegeClass
      recruitingClass
      rankingComposite
      ranking247
      recruitingReranking
      homeTown
      homeState
      attended
      offered
      alignmentSnaps {
        position
        total
      }
      highSchool
    }
  }
`;
