import { useState, useEffect, useRef } from 'react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';

const useAccountConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [account, setAccount] = useState();
  const [eTag, setETag] = useState();
  const { getAccessTokenSilently } = useKitbagAuth();
  const isMountedRef = useRef(false);
  const apiUrl = process.env.ACCOUNT_CONFIG;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      if (response.ok) {
        const getResponseEtag = response.headers.get('eTag');
        setETag(getResponseEtag);
      }
      const data = await response.json().catch((err) => {
        if (isMountedRef.current) {
          // eslint-disable-next-line no-console
          console.error(err);
          setError(err);
          setIsLoading(false);
        }
      });

      if (isMountedRef.current) {
        setIsLoading(false);
        setAccount(data);
      }
    };

    if (!account) {
      fetchData();
    }
  }, [account]);

  const save = async (input) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();

    await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${token}`,
        'If-Match': eTag,
      },
      body: JSON.stringify({ ...input }),
    }).then(setIsLoading(false));
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { isLoading, error, account, save };
};

export default useAccountConfig;
