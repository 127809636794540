import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Drives from './Drives';
import { Plays } from './Plays';
import {
  mf_Games,
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Players,
  pf_Drive,
  pf_TeamPlay,
  pf_TeamPlayEvent,
} from '../../apollo';
import { replaceTokens } from '../../utils/helpers/general';
import { StyledPageFilters } from './PageFilters.styles';

const PageFilters = ({
  displayPassFilter,
  onDriveChange,
  onPlayChange,
  inline,
  wider,
  children,
  optionalAllDrives,
}) => {
  // reactive variables
  const rv = {
    games: parseInt(useReactiveVar(mf_Games), 10),
    drives: useReactiveVar(pf_Drive),
  };
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [pageInfo] = useOutletContext();

  const handleDriveChange = ({ value }) => {
    pf_Drive(value);
    pf_TeamPlayEvent(null);
    pf_TeamPlay(null);
    onDriveChange(value);

    const instantRV = {
      leagues: mf_Leagues(),
      players: mf_Players(),
      seasons: mf_Seasons(),
      teams: mf_Teams(),
      games: mf_Games(),
      drives: value,
      plays: null,
    };

    navigate(
      `/${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
        window.location.search
      }`,
      { replace: true }
    );
  };

  const handlePlayChange = ({ value }) => {
    pf_TeamPlayEvent(null);
    pf_TeamPlay(value);
    onPlayChange(value);

    const instantRV = {
      leagues: mf_Leagues(),
      players: mf_Players(),
      seasons: mf_Seasons(),
      teams: mf_Teams(),
      games: mf_Games(),
      drives: pf_Drive(),
      plays: value,
    };

    navigate(
      `/${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
        window.location.search
      }`
    );
  };

  const displayPlays = displayPassFilter && !!rv.drives;

  return (
    !!rv.games && (
      <StyledPageFilters $inline={inline} $wider={wider}>
        <Drives onChange={handleDriveChange} optionAll={optionalAllDrives} />
        {displayPlays && <Plays onChange={handlePlayChange} />}
        {children}
      </StyledPageFilters>
    )
  );
};

PageFilters.propTypes = {
  displayPassFilter: PropTypes.bool,
  onDriveChange: PropTypes.func,
  onPlayChange: PropTypes.func,
  inline: PropTypes.bool,
  wider: PropTypes.bool,
  children: PropTypes.element,
  optionalAllDrives: PropTypes.bool,
};

PageFilters.defaultProps = {
  displayPassFilter: true,
  onDriveChange: () => {},
  onPlayChange: () => {},
  inline: true,
  wider: false,
  optionalAllDrives: false,
  children: null,
};

export default PageFilters;
