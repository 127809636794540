import React, { useState } from 'react';
import {
  Grid,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DeleteModalContent, ExplanationBlurb } from '../Scout.styles';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import Tile from '../../../components/Tile/Tile';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import BasicTable from '../../../components/Table/BasicTable';
import {
  uiState,
  ui_scoutSetups,
  mf_Leagues,
  mf_Seasons,
} from '../../../apollo';
import { formatScoutSetupsSelectionTable } from './wizard.dataManipulation';
import { SCOUT_LANDING_TEXT, SCOUT_SETUP_HEADERS } from '../Scout.constants';
import { replaceHrefTokens } from '../../../utils/helpers/general';
import BannerError from '../../../components/Banners/Banner.Error';
import TemplateDetails from '../TemplateDetails/TemplateDetails';
import useUserConfig from '../../../utils/hooks/useUserConfig';

const ScoutLandingPage = () => {
  const savedSetups = useReactiveVar(ui_scoutSetups);
  const [templateId, setTemplateId] = useState(null);
  const linkVars = {
    leagues: useReactiveVar(mf_Leagues),
    seasons: useReactiveVar(mf_Seasons),
  };

  /* Page Display setup
  If a player has no history, hide history tab else...
  */
  const showHistory = !isEmpty(savedSetups);

  /*
    Temporarily hiding middle tile until wizard gets built
    Middle grid panel and the sizing below are what changes
   */
  // const tileSize = showHistory
  //   ? { xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }
  //   : { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
  const tileSize = showHistory
    ? { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }
    : { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

  /* Link Functions */
  const navigate = useNavigate();
  const openSetup = (tableSetup) => {
    const resultsURI = `scout/players/:leagues/:seasons?id=${tableSetup.id}`;
    navigate(`/${replaceHrefTokens(resultsURI, linkVars)}`);
  };

  /* Delete Functions */
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deleteSetup = (template) => {
    setTemplateId(template.id);
    setIsDeleteModalOpen(true);
  };
  const selectedTemplate = savedSetups?.find((s) => s.id === templateId) || {};
  const escapeDeleteModal = () => {
    setTemplateId(null);
    setIsDeleteModalOpen(false);
  };
  const { saveUnsafe, account } = useUserConfig();
  const deleteTemplate = () => {
    const otherSetups = isEmpty(savedSetups)
      ? []
      : savedSetups.filter((s) => s.id !== templateId);
    ui_scoutSetups(otherSetups);
    uiState().ui.scoutSetups = otherSetups;
    const updatedAccount = { ...account, scoutSetups: otherSetups };
    saveUnsafe(updatedAccount);
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setIsDeleteModalOpen(false);
  };

  /* Table */
  const tableSetups = formatScoutSetupsSelectionTable(savedSetups);
  const deleteButtonHeader = {
    key: 'deleteButton',
    label: '',
    buttonIcon: 'Delete',
    onButtonClick: deleteSetup,
    buttonTitle: 'Delete this setup',
  };
  const tableHeaders = SCOUT_SETUP_HEADERS.concat(deleteButtonHeader);

  return (
    <KitbagPageGridHolder id="nrj2">
      <Grid container={false} page>
        <Grid
          item
          xs={tileSize.xs}
          sm={tileSize.sm}
          md={tileSize.md}
          lg={tileSize.lg}
          xl={tileSize.xl}
        >
          <Tile border="none" full>
            <Tile.Header>New Template</Tile.Header>
            <Tile.Body>
              <ExplanationBlurb>
                <span>{SCOUT_LANDING_TEXT.QUICK_START_HELP}</span>
              </ExplanationBlurb>
              <ParsedLink href="/scout/players/:leagues/:seasons">
                <Button variant="primary" size="regular" isDisabled={false}>
                  Let&#39;s do this!
                </Button>
              </ParsedLink>
            </Tile.Body>
          </Tile>
        </Grid>
        {/* <Grid
          item
          xs={tileSize.xs}
          sm={tileSize.sm}
          md={tileSize.md}
          lg={tileSize.lg}
          xl={tileSize.xl}
        >
          <Tile border="none" full>
            <Tile.Header>Guided Scout</Tile.Header>
            <Tile.Body>
              <ExplanationBlurb>
                <span>{SCOUT_LANDING_TEXT.GUIDE_HELP}</span>
              </ExplanationBlurb>
              <ParsedLink href="/scout/guide/:leagues/:seasons">
                <Button variant="primary" size="regular" isDisabled>
                  Help me Obi-Wan you&#39;re my only hope!
                </Button>
              </ParsedLink>
            </Tile.Body>
          </Tile>
        </Grid> */}
        {showHistory && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Tile border="none" full>
              <Tile.Header>Saved Templates</Tile.Header>

              <Tile.Body>
                <ExplanationBlurb>
                  <span>{SCOUT_LANDING_TEXT.SAVED_TEMPLATE_HELP}</span>
                </ExplanationBlurb>
                <BasicTable
                  data={tableSetups}
                  headers={tableHeaders}
                  onRowSelect={openSetup}
                />
                <Modal id="delete-template-modal" isOpen={isDeleteModalOpen}>
                  <ModalHeader>{`Delete Template "${selectedTemplate?.name}"`}</ModalHeader>
                  <ModalBody>
                    <DeleteModalContent>
                      <TemplateDetails template={selectedTemplate} />
                      <BannerError
                        message={SCOUT_LANDING_TEXT.DELETE_WARNING}
                      />
                    </DeleteModalContent>
                  </ModalBody>
                  <ModalFooter
                    cancelLabel="Cancel without deleting"
                    confirmLabel="Delete template"
                    onCancel={escapeDeleteModal}
                    onConfirm={() => {
                      deleteTemplate();
                    }}
                    onEscape={escapeDeleteModal}
                    isDisabledConfirm={false}
                    isDisabledEscape={false}
                  />
                </Modal>
              </Tile.Body>
            </Tile>
          </Grid>
        )}
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default ScoutLandingPage;
