import { gql } from '@apollo/client';

export const GET_PLAYER_STAT_DEFINITIONS = gql`
  query GetPlayerStatDefinitions {
    playerStatDefinitions {
      abbrev
      description
      lowerIsBetter
      name
      rate
      prettyName
      units
    }
  }
`;
