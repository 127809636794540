import { useLocation } from 'react-router-dom';
import { CONTEXT_FILTERS_CONFIG } from './ContextFilters.constants';

export const useIsEligiblePage = (filter) => {
  const { pathname } = useLocation();

  return !CONTEXT_FILTERS_CONFIG[filter].some((page) =>
    pathname.includes(page)
  );
};
