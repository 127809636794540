import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@statsbomb/kitbag-components';
import { Option, SingleValue } from './FormationDropdown.components';

/**
 * Custom dropdown for selecting a team formation to analyze in the Formation Detail page
 */
const FormationDropdown = ({ options, value, onChange, isLoading }) => (
  <Dropdown
    options={options}
    value={value}
    isSearchable={false}
    isLoading={isLoading}
    id="formations-dropdown"
    onChange={onChange}
    labelPosition="none"
    label="Formation"
    size="small"
    variant="tertiary"
    components={{
      Option,
      SingleValue,
    }}
  />
);

FormationDropdown.propTypes = {
  /** Array of options to be provided to the dropdown. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      secondaryValue: PropTypes.number,
      label: PropTypes.string,
      secondaryLabel: PropTypes.string,
    })
  ),
  /** the currently selected value */
  value: PropTypes.shape({
    value: PropTypes.string,
    secondaryValue: PropTypes.number,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
  }),
  /** Whether or not the select is in loading state. */
  isLoading: PropTypes.bool,
  /** Function to call when the onChangeHandler is triggered.  */
  onChange: PropTypes.func.isRequired,
};

FormationDropdown.defaultProps = {
  options: [],
  value: null,
  isLoading: false,
};

export default FormationDropdown;
