/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  ButtonIcon,
  ButtonGroup,
  Dropdown,
  Icon,
} from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { GET_PASS_PLACEMENT_EVENTS } from '../PlayerPassPlacement/getPassPlacementEvents';
import { GET_CATCH_PLACEMENT_EVENTS } from '../PlayerPassPlacement/getCatchPlacementEvents';
import GoTo from '../../../components/buttons/GoTo';
import {
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_SCALES,
} from '../../../visualisations/PassPlacement/PassPlacement.constants';
import { ui_isDark, mf_PlayerDetails } from '../../../apollo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import useQueryString from '../../../utils/hooks/useQueryString';
import PassPlacement from '../../../visualisations/PassPlacement/PassPlacement';
import { passPlacementScalePropTypes } from '../../../visualisations/PassPlacement/PassPlacement.propTypes';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { PASS_PLACEMENT_BALL_DISPLAY_OPTIONS } from './PlayerPassPlacement.Tile.constants';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const PlayerPassPlacementTile = ({ selectedScale, colorMode }) => {
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const passesTo = mf_PlayerDetailsRV?.mostCommonPosition?.code === 'QB';

  const { loading, error, data } = useQuery(
    passesTo ? GET_PASS_PLACEMENT_EVENTS : GET_CATCH_PLACEMENT_EVENTS
  );
  const ui_isDarkRV = useReactiveVar(ui_isDark);

  const [scaleIndex, setScaleIndex] = useQueryString(
    'pass_scale',
    selectedScale.DEFAULT_PLUS_MINUS_X_INDEX
  );
  const [showBallSize, setShowBallSize] = useQueryString('ball_size', true);

  const increaseScale = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1);
    }
  };

  const decreaseScale = () => {
    if (scaleIndex < selectedScale.PLUS_MINUS_X_OPTIONS.length - 1) {
      setScaleIndex(scaleIndex + 1);
    }
  };

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Pass Placement</h3>
        </div>
        <div>
          {!loading && !error && data?.passEvents?.edges?.length !== 0 && (
            <ButtonGroup>
              <ButtonIcon
                id="zoom-button-out-tile"
                onClick={decreaseScale}
                variant="secondary"
                icon="ZoomOut"
                size="small"
                title="zoom out"
              />
              <ButtonIcon
                id="zoom-button-in-tile"
                onClick={increaseScale}
                variant="secondary"
                icon="ZoomIn"
                size="small"
                title="zoom in"
              />
              <ButtonIcon
                id="zoom-button-reset-tile"
                onClick={() =>
                  setScaleIndex(selectedScale.DEFAULT_PLUS_MINUS_X_INDEX)
                }
                variant="secondary"
                icon="ResetZoom"
                size="small"
                title="reset zoom"
              />
            </ButtonGroup>
          )}
          {!loading && !error && data?.passEvents?.edges?.length !== 0 && (
            <DropdownWrapper>
              <Dropdown
                id="passPlacementDrop"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(item) => setShowBallSize(item.value === 0)}
                options={PASS_PLACEMENT_BALL_DISPLAY_OPTIONS}
                value={
                  PASS_PLACEMENT_BALL_DISPLAY_OPTIONS[showBallSize ? 0 : 1]
                }
                labelPosition="none"
                label="Pass placement ball display"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            href="/player/placement/:leagues/:seasons/:teams/:players?ball=true&catcher=true&fails=true&mode=LENGTH"
            linkName="Open Pass Placement Chart"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {loading && <TileDimmer />}
        {data?.passEvents?.edges?.length === 0 && !loading && (
          <TileDimmer>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {error && !loading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div className="chartWrapper">
          <PassPlacement
            data={data}
            colorMode={colorMode}
            isDark={ui_isDarkRV}
            showBallSize={showBallSize}
            showCatcher
            showKey
            aspectRatioName={selectedScale.ASPECT_RATIO}
            plusMinusX={selectedScale.PLUS_MINUS_X_OPTIONS[scaleIndex]}
          />
        </div>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {loading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

PlayerPassPlacementTile.propTypes = {
  selectedScale: passPlacementScalePropTypes,
  colorMode: PropTypes.string,
};

PlayerPassPlacementTile.defaultProps = {
  selectedScale: PASS_PLACEMENT_SCALES[0],
  colorMode: PASS_PLACEMENT_COLOR_MODES.LENGTH,
};

export default PlayerPassPlacementTile;
