import { BARS } from './FormationSummaryChart.constants';

// calculates the height of a section in the bar chart
// (does not take into account the header bar of that section).
export const getSectionHeight = (data) => {
  if (!data) {
    return 0;
  }

  return BARS.BASE_HEIGHT * data.length + BARS.BAR_GAP * (data.length - 1);
};
