import { clamp, isNumber } from 'lodash';
import { API_STAT_UNITS } from '../../utils/constants/api';
import { isEven } from '../../utils/helpers/general';
import {
  RADAR_RING_COUNT,
  RADAR_RINGS,
  RADAR_RADIUS,
  RADAR_CHART_CLASS_NAMES,
  RADAR_CHART_CLIPPATHS,
  RADAR_RING_WIDTH,
  RADAR_AXIS_WIDTH,
  RADAR_CHART_DRAW_COMPARISON,
  RADAR_RADIUS_KEY,
} from './RadarChart.constants';
import { appendText } from '../text';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';

/*
Axis = a spoke on the radar (i.e. an individual metric)
Axes = multiple axis (plural)
*/

const addClipPaths = function (svg, radarId, path, pathAlt) {
  svg.selectAll('defs').remove();
  const svgDefs = svg.append('defs').attr('data-testid', 'radar-defs');

  const clipPathName = radarId + RADAR_CHART_CLIPPATHS.MAIN;
  const clipPathNameAlt = radarId + RADAR_CHART_CLIPPATHS.COMPARISON;
  const clipPathNameCombined = radarId + RADAR_CHART_CLIPPATHS.COMBINED;
  const clipPathCCName = radarId + RADAR_CHART_CLIPPATHS.CENTER_CIRCLE;

  const clippyMain = svgDefs
    .append('clipPath')
    .attr('id', clipPathName)
    .attr('data-testid', clipPathName);
  clippyMain.append('svg:path').attr('d', path);

  if (pathAlt) {
    const clippyAlt = svgDefs.append('clipPath').attr('id', clipPathNameAlt);
    clippyAlt.append('svg:path').attr('d', pathAlt);
  }

  const clippyCombined = svgDefs
    .append('clipPath')
    .attr('id', clipPathNameCombined)
    .attr('data-testid', clipPathNameCombined);
  clippyCombined.append('svg:path').attr('d', path);
  if (pathAlt) {
    clippyCombined.append('svg:path').attr('d', pathAlt);
  }

  const clippyCenterCircle = svgDefs
    .append('clipPath')
    .attr('id', clipPathCCName)
    .attr('data-testid', clipPathCCName);
  clippyCenterCircle
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', RADAR_RING_WIDTH);
};

const getNormalisedAxisValue = (axisConfig, baseStatValue) => {
  /* If a percentile stat, convert the value
  Axis are already converted */
  const statValue =
    axisConfig.units === API_STAT_UNITS.PERCENTAGE
      ? baseStatValue * 100
      : baseStatValue;

  const isReverseStat = axisConfig.max < axisConfig.min;
  const smallerValue = isReverseStat ? axisConfig.max : axisConfig.min;
  const largerValue = isReverseStat ? axisConfig.min : axisConfig.max;
  const axisDiff = largerValue - smallerValue;
  const clampedValue = clamp(statValue, smallerValue, largerValue);
  const normalisedValue = (clampedValue - smallerValue) / axisDiff;
  return isReverseStat ? 1 - normalisedValue : normalisedValue;
};

const getAxisY = function (axisConfig, statsDatum) {
  const isAxisValueNumber = isNumber(statsDatum?.[axisConfig.key]);
  const normalisedAxisValue = isAxisValueNumber
    ? getNormalisedAxisValue(axisConfig, statsDatum[axisConfig.key])
    : 0;

  /*
  Assuming the axis is vertical, then there is no x value, and y represents the proportion of the distance from
  the inner dot to the full radius (outermost ring)
  invert the value because svg's work top to bottom
  so y = -RADAR_RADIUS is the full distance up from the center point of the radar)
  */
  const y = -RADAR_RING_WIDTH - normalisedAxisValue * RADAR_AXIS_WIDTH;
  return y;
};

const createRadarPath = function (axesConfig, playerStats) {
  if (!playerStats) {
    // radar can show in loading state
    return 'M0 0 z';
  }
  const axesCount = axesConfig.length;
  const radarPolygonVertices = axesConfig.map((axis) => {
    const axisAngleRad = (axis.index / axesCount) * 2 * Math.PI;
    const axisY = getAxisY(axis, playerStats);
    const x0 = 0;
    // transform spot by rotation according to https://en.wikipedia.org/wiki/Rotation_of_axes
    const rotatedX =
      x0 * Math.cos(axisAngleRad) + axisY * Math.sin(axisAngleRad);
    const rotatedY =
      -1 * x0 * Math.sin(axisAngleRad) + axisY * Math.cos(axisAngleRad);

    // note the x coordinate has to be flipped because SVG's work upside down vs the standard xy coordinate system
    return `${-1 * rotatedX} ${rotatedY}`;
  });

  // merge the points into a single polygon
  const path = `M${radarPolygonVertices.join(` L`)} z`;

  return path;
};

const colourRing = function (n, visPalette, colorRing, colorRingAlternate) {
  if (n === 1) {
    // center dot is part of the background
    return visPalette.background.main;
  }
  if (isEven(n)) {
    return colorRing || visPalette.zones.alternate;
  }
  return colorRingAlternate || visPalette.zones.important;
};

const drawRings = function (
  svgG,
  visPalette,
  strokeOnly,
  colorRing,
  colorRingAlternate
) {
  svgG
    .selectAll('circle')
    .data(RADAR_RINGS)
    .enter()
    .append('circle')
    .attr('cx', RADAR_RADIUS)
    .attr('cy', RADAR_RADIUS)
    .attr('r', (d) => (d / (RADAR_RING_COUNT - 1)) * RADAR_RADIUS)
    .attr('fill', (d) =>
      strokeOnly
        ? 'transparent'
        : colourRing(d, visPalette, colorRing, colorRingAlternate)
    )
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', strokeOnly ? 1 : 0)
    .attr('stroke-dasharray', '2 5')
    .attr('stroke-opacity', strokeOnly ? 1 : 0)
    .attr('data-testid', (d) => `radar-ring-${d}-test`);
};

const addAxisInfo = function (templateMetric, axesCount) {
  // note the diff here is directional and can be negative
  const axisDiff = templateMetric.max - templateMetric.min;
  const axisAngleDeg = (templateMetric.index / axesCount) * 360;

  /* Number of decimal places to display stat to depends on the scale size
  Default is 1, but each number displayed should be different
  */
  let axisDecimalPlaces = 1;
  if (Math.abs(axisDiff) < 0.5) {
    axisDecimalPlaces = 2;
  }
  if (Math.abs(axisDiff) < 0.05) {
    axisDecimalPlaces = 3;
  }

  // add notches for every 20% of the axis (every 2 rings)
  // offset is a nudge to the y value for sitting the text on the ring nicely
  const notches = [
    {
      frac: 0,
      val: templateMetric.min,
      offset: -2,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
    {
      frac: 0.2,
      val: templateMetric.min + 0.2 * axisDiff,
      offset: 4,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
    {
      frac: 0.4,
      val: templateMetric.min + 0.4 * axisDiff,
      offset: 4,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
    {
      frac: 0.6,
      val: templateMetric.min + 0.6 * axisDiff,
      offset: 4,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
    {
      frac: 0.8,
      val: templateMetric.min + 0.8 * axisDiff,
      offset: 4,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
    {
      frac: 1,
      val: templateMetric.max,
      offset: 0,
      angleDeg: axisAngleDeg,
      axisDecimalPlaces,
    },
  ];
  const axis = {
    ...templateMetric,
    angleRad: (templateMetric.index / axesCount) * 2 * Math.PI,
    angleDeg: axisAngleDeg,
    notches,
  };
  return axis;
};

const adjustTextYByAngle = function (angle) {
  if (angle > 135 && angle < 225) {
    return 8;
  }
  if (angle > 45 && angle < 315) {
    return 4;
  }
  return 0;
};

const axisLabelSize = function (frac, axesCount) {
  if (frac === 0 && axesCount > 9) {
    return VISUALISATION_FONT_SETUPS.AXES_VALUES_SMALL.SIZE;
  }
  if (frac === 1) {
    return VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE;
  }
  return VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE;
};

const radarAxisLabelFormatter = (value, axisDecimalPlaces) => {
  const dps = axisDecimalPlaces || 1;
  const n = Number.isInteger(value) ? value : value.toFixed(dps);
  return n;
};

const addAxesLabels = function (
  svgG,
  isOverlay,
  visPalette,
  axesCount,
  axisLabelFontSizeOverride
) {
  const newG = svgG
    .append('g')
    .attr('id', (d) => `newG-${d.index}`)
    .attr('transform', (d) => `rotate(${d.angleDeg})`);

  // fraction of the distance center dot to edge each ring is
  const ringFrac = 1 / (RADAR_RING_COUNT - 1);

  const offWhite = '#f9f9f9'; // for text atop the shape, the palette mode (dark/light) is irrelevant
  newG
    .selectAll('g')
    .data((d) => d.notches)
    .enter()
    .append('g')
    .attr('id', (d) => `${d.index}-val-${d.frac}`)
    .attr(
      'transform',
      (d) =>
        `translate(0,${
          -1 * ringFrac * RADAR_RADIUS -
          d.frac * RADAR_RADIUS * (RADAR_RING_COUNT - 2) * ringFrac +
          d.offset
        })`
    )
    .append('text')
    .attr('x', 0)
    .attr('y', 0)
    .attr('fill', isOverlay ? offWhite : visPalette.text.label)
    .attr('font-size', (d) => axisLabelSize(d.frac, axesCount))
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .attr('opacity', isOverlay ? 0.75 : 1)
    .attr(
      'transform',
      (d) =>
        `rotate(${-1 * d.angleDeg}) translate(0,${adjustTextYByAngle(
          d.angleDeg
        )})`
    )
    // TODO: Make a more complex radar number formatter for handling very small values
    .text((d) => radarAxisLabelFormatter(d.val, d.axisDecimalPlaces));

  // Add the title of the axis
  const axisTopNotchToLabelSpacing = 10;
  const axisLabelFontSize =
    axisLabelFontSizeOverride || VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE;
  const axisLabelY =
    -1 * RADAR_RADIUS - axisLabelFontSize - axisTopNotchToLabelSpacing;
  newG
    .append('g')
    .attr('id', 'label-holder')
    .attr('transform', `translate(0,${axisLabelY})`)
    .append('text')
    .attr('x', 0)
    .attr('y', 0)
    .attr('fill', visPalette.text.header)
    .attr('font-size', axisLabelFontSize)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .attr('transform', (d) =>
      d.angleDeg > 90 && d.angleDeg < 270
        ? `rotate(180) translate(0,${axisTopNotchToLabelSpacing})`
        : ''
    )
    .text((d) => d.name)
    .append('title')
    .text((d) => `${d.prettyName}\n${d?.description}`);
};

const setupRadar = function (
  svgG,
  visPalette,
  axesConfig,
  radarId,
  drawComparisonMode,
  primaryColor,
  /*
  Secondary color: 
    for a single radar: the alternate color for rings
    for a comparison: the second shape color
  */
  secondaryColor,
  drawAxes = true,
  axisLabelFontSize
) {
  const isComparisonDisplayed =
    drawComparisonMode === RADAR_CHART_DRAW_COMPARISON.BOTH;

  svgG.selectAll('g').remove();

  const axisG = svgG
    .append('g')
    .attr('class', RADAR_CHART_CLASS_NAMES.AXES)
    .attr('data-testid', `${RADAR_CHART_CLASS_NAMES.AXES}-${radarId}-test`)
    .attr('transform', `translate(${RADAR_RADIUS},${RADAR_RADIUS})`);
  const shapeG = svgG
    .append('g')
    .attr('class', RADAR_CHART_CLASS_NAMES.SHAPES)
    .attr('data-testid', `${RADAR_CHART_CLASS_NAMES.SHAPES}-${radarId}-test`)
    .attr('transform', `translate(${RADAR_RADIUS},${RADAR_RADIUS})`);
  const numbersG = svgG
    .append('g')
    .attr('class', RADAR_CHART_CLASS_NAMES.LABELS)
    .attr('data-testid', `${RADAR_CHART_CLASS_NAMES.LABELS}-${radarId}-test`)
    .attr('transform', `translate(${RADAR_RADIUS},${RADAR_RADIUS})`);

  // DRAW THE AXES LINES
  const axesCount = axesConfig.length;
  if (drawAxes) {
    axisG
      .selectAll('line')
      .data(axesConfig)
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', -1 * RADAR_RADIUS)
      .attr('stroke', visPalette.background.main)
      .attr('stroke-width', 1)
      .attr('transform', (d) => `rotate(${d.angleDeg})`);

    // ADD THE BACKGROUND NUMBERS
    axisG
      .selectAll('g')
      .data(axesConfig, (d) => d.index)
      .join(
        (enter) => {
          addAxesLabels(enter, false, visPalette, axesCount, axisLabelFontSize);
        },
        (update) => update,
        (exit) => exit.remove()
      );
  }

  // RENDER THE SHAPES
  const clipPathName = radarId + RADAR_CHART_CLIPPATHS.MAIN;
  const clipPathNameAlt = radarId + RADAR_CHART_CLIPPATHS.COMPARISON;
  const clipPathNameCombined = radarId + RADAR_CHART_CLIPPATHS.COMBINED;
  const clipPathCCName = radarId + RADAR_CHART_CLIPPATHS.CENTER_CIRCLE;
  const centerToTopLeftCorner = `translate(-${RADAR_RADIUS},-${RADAR_RADIUS})`;

  /* 
  When comparing, layer comparison (solid) -> main (solid) -> comparison (translucent)
  This will get you the overlap shape + decent color density where not overlapped 
  */
  if (isComparisonDisplayed) {
    const clippedGAlt = shapeG
      .append('g')
      .attr('clip-path', `url(#${clipPathNameAlt})`);
    const adjustedGAltUnder = clippedGAlt
      .append('g')
      .attr('id', 'clipped-rings-alt-under')
      .attr('transform', centerToTopLeftCorner);
    // redraw the rings, but within the clip-path determined by the shape
    drawRings(
      adjustedGAltUnder,
      visPalette,
      false,
      secondaryColor,
      secondaryColor
    );
  }

  const clippedG = shapeG.append('g');
  if (drawComparisonMode === RADAR_CHART_DRAW_COMPARISON.COMPARISON) {
    clippedG.attr('clip-path', `url(#${clipPathNameAlt})`);
  } else {
    clippedG.attr('clip-path', `url(#${clipPathName})`);
  }
  const adjustedG = clippedG
    .append('g')
    .attr('id', 'clipped-rings')
    .attr('transform', centerToTopLeftCorner);
  // redraw the rings, but within the clip-path determined by the shape
  /*
    When drawing a solo shape, the rings alternate colors
    When comparing, the shape should be solid
  */
  const colorB = isComparisonDisplayed ? primaryColor : secondaryColor;
  drawRings(adjustedG, visPalette, false, primaryColor, colorB);

  // Render the Comparison Shape if included
  if (isComparisonDisplayed) {
    const clippedGAlt = shapeG
      .append('g')
      .attr('clip-path', `url(#${clipPathNameAlt})`);
    const adjustedGAlt = clippedGAlt
      .append('g')
      .attr('id', 'clipped-rings-alt')
      .attr('transform', centerToTopLeftCorner)
      .attr('opacity', 0.4);
    drawRings(adjustedGAlt, visPalette, false, secondaryColor, secondaryColor);

    // Add guidelines for the ring locations atop the shapes
    const clippedGGuides = shapeG
      .append('g')
      .attr('clip-path', `url(#${clipPathNameCombined})`);
    const adjustedGGuides = clippedGGuides
      .append('g')
      .attr('id', 'clipped-rings-alt')
      .attr('transform', centerToTopLeftCorner);
    drawRings(adjustedGGuides, visPalette, true);
  }

  // Add axes on top of shapes
  if (drawAxes) {
    const clippedGNumbers = numbersG
      .append('g')
      .attr('clip-path', `url(#${clipPathNameCombined})`);
    clippedGNumbers
      .selectAll('g')
      .data(axesConfig, (d) => d.index)
      .join(
        (enter) => {
          addAxesLabels(enter, true, visPalette, axesCount);
        },
        (update) => update,
        (exit) => exit.remove()
      );

    const clippedGNumbersCC = numbersG
      .append('g')
      .attr('clip-path', `url(#${clipPathCCName})`);
    clippedGNumbersCC
      .selectAll('g')
      .data(axesConfig, (d) => d.index)
      .join(
        (enter) => {
          addAxesLabels(enter, false, visPalette, axesCount);
        },
        (update) => update,
        (exit) => exit.remove()
      );
  }
};

// simple key: coloured circles and text
const drawSimpleKey = (
  keyG,
  visPalette,
  primaryColor,
  secondaryColor,
  fontScaler,
  subjectLabel = 'Player'
) => {
  const leftG = keyG.append('g');
  const keyItemG = leftG.append('g').attr('transform', `translate(10, 0)`);
  const keyScale = fontScaler + 0.3;
  keyItemG
    .append('circle')
    .attr('cx', 5)
    .attr('cy', 15)
    .attr('r', 5)
    .attr('fill', primaryColor)
    .attr('stroke', 'none');
  keyItemG
    .append('text')
    .attr('x', 20)
    .attr('y', 20)
    .attr('fill', visPalette.text.info)
    .attr('font-family', DEFAULT_FONT)
    .attr(
      'font-size',
      Math.round(keyScale * VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    )
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('text-anchor', 'start')
    .text(subjectLabel);
  keyItemG
    .append('circle')
    .attr('cx', 95)
    .attr('cy', 15)
    .attr('r', 5)
    .attr('fill', secondaryColor)
    .attr('stroke', 'none');
  keyItemG
    .append('text')
    .attr('x', 110)
    .attr('y', 20)
    .attr('fill', visPalette.text.info)
    .attr('font-family', DEFAULT_FONT)
    .attr(
      'font-size',
      Math.round(keyScale * VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    )
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('text-anchor', 'start')
    .text('League Average');
};

const drawKey = (
  keyG,
  mainWidth,
  visPalette,
  axesConfig,
  radarId,
  drawComparisonMode,
  primaryColor,
  secondaryColor,
  keyInfo
) => {
  const leftG = keyG.append('g');
  const rightG = keyG.append('g');
  const radiusRatio = RADAR_RADIUS_KEY / RADAR_RADIUS;
  leftG
    .append('circle')
    .attr('cx', RADAR_RADIUS_KEY)
    .attr('cy', RADAR_RADIUS_KEY)
    .attr('r', RADAR_RADIUS_KEY)
    .attr('fill', 'transparent')
    .attr('stroke-width', 2)
    .attr('stroke', visPalette.guides);
  const leftShapeG = leftG
    .append('g')
    .attr('transform', `translate(0,0) scale(${radiusRatio})`)
    .attr('width', '200px');
  setupRadar(
    leftShapeG,
    visPalette,
    axesConfig,
    radarId,
    RADAR_CHART_DRAW_COMPARISON.MAIN,
    primaryColor,
    primaryColor,
    false
  );
  const keyTextX = RADAR_RADIUS_KEY * 2 + 10; // 10px for some padding
  const keyTextYTop = VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE + 5;
  const keyTextYLine2 =
    keyTextYTop + VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE + 10;
  const keyTextYLine3 =
    keyTextYLine2 + VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE + 10;
  appendText(leftG, visPalette, {
    message: keyInfo?.main?.title,
    y: keyTextYTop,
    x: keyTextX,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  appendText(leftG, visPalette, {
    message: keyInfo?.main?.info1,
    y: keyTextYLine2,
    x: keyTextX,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  });
  appendText(leftG, visPalette, {
    message: keyInfo?.main?.info2,
    y: keyTextYLine3,
    x: keyTextX,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  });

  if (drawComparisonMode === RADAR_CHART_DRAW_COMPARISON.BOTH) {
    rightG
      .append('circle')
      .attr('cx', mainWidth - RADAR_RADIUS_KEY)
      .attr('cy', RADAR_RADIUS_KEY)
      .attr('r', RADAR_RADIUS_KEY)
      .attr('fill', 'transparent')
      .attr('stroke-width', 2)
      .attr('stroke', visPalette.guides);
    const rightShapeG = rightG
      .append('g')
      .attr(
        'transform',
        `translate(${mainWidth - RADAR_RADIUS_KEY * 2},0) scale(${radiusRatio})`
      );
    setupRadar(
      rightShapeG,
      visPalette,
      axesConfig,
      radarId,
      RADAR_CHART_DRAW_COMPARISON.COMPARISON,
      secondaryColor,
      secondaryColor,
      false
    );
    appendText(leftG, visPalette, {
      message: keyInfo?.comparison?.title,
      y: keyTextYTop,
      x: mainWidth - keyTextX,
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      textAnchor: 'end',
    });
    appendText(leftG, visPalette, {
      message: keyInfo?.comparison?.info1,
      y: keyTextYLine2,
      x: mainWidth - keyTextX,
      fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
      textAnchor: 'end',
    });
    appendText(leftG, visPalette, {
      message: keyInfo?.comparison?.info2,
      y: keyTextYLine3,
      x: mainWidth - keyTextX,
      fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
      textAnchor: 'end',
    });
  }
};

export {
  setupRadar,
  addAxisInfo,
  createRadarPath,
  addClipPaths,
  getAxisY,
  drawRings,
  drawKey,
  drawSimpleKey,
};
