import { styled } from 'styled-components';

export const StyledBoxScoreTable = styled.table`
  width: 100%;
  min-height: 40px;
  border-spacing: 0;
  border-radius: 10px;
  font-size: 0.75rem;

  thead {
    tr {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    }
  }

  th {
    min-width: 20px;
    padding: 0.625rem 0.5rem;
    font-size: 0.84375rem;
    color: ${({ theme }) => theme.colours.ink.primary.main};

    &.long-name {
      min-width: 100px;
      border-left: 0;
    }

    &.for {
      background-color: initial;
      border-bottom: 3px solid var(--forColor);
      color: ${({ theme }) => theme.colours.ink.primary.main};
    }

    &.against {
      background-color: initial;
      border-bottom: 3px solid var(--againstColor);
      color: ${({ theme }) => theme.colours.ink.primary.main};
    }

    &:first-child {
      border-radius: 10px 0px 0px 0px;
    }

    &:last-child {
      border-radius: 0;
    }

    &.space {
      min-width: 5px;
      height: 2px;
      border: none;
      font-size: 0px;
      padding: 0px 2px 5px 2px;
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
    }

    td.space,
    tr:nth-child(even) td.space {
      min-width: 5px;
      height: 2px;
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
      border: none;
      font-size: 0px;
      padding: 0px 2px 5px 2px;
    }

    td {
      padding: 2px 5px;
      text-align: right;
      border-right: 1px solid ${({ theme }) => theme.colours.layering[4].main};
      border-bottom: 1px solid ${({ theme }) => theme.colours.layering[4].main};
    }

    tr td:first-child {
      border-left: 1px solid ${({ theme }) => theme.colours.layering[4].main};
      text-align: left;
    }

    tr:last-child td.space {
      padding-bottom: 10px;
    }

    tr:last-child td:first-child {
      border-radius: 0px 0px 0px 10px;
    }

    tr:last-child td:last-child {
      border-radius: 0px 0px 10px 0px;
    }

    .metric-name,
    tr td.metric-name:first-child {
      text-align: right;
      border-left: none;
    }

    tr:hover td,
    tr:hover td.space {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
      color: ${({ theme }) => theme.colours.brand.ink};
    }

    tr:nth-child(even) {
      td {
        &.space {
          background-color: ${({ theme }) =>
            theme.colours.canvas.tertiary.main};
        }
      }
    }

    tr {
      &:hover {
        td {
          background-color: ${({ theme }) =>
            theme.applyOpacity(theme.colours.interface.main, 'highlight')};
          color: ${({ theme }) => theme.colours.ink.primary.main};

          &.space {
            background-color: ${({ theme }) =>
              theme.colours.canvas.tertiary.main};
          }
        }
      }

      td {
        padding: 0.5rem;
        border-top: 1px solid ${({ theme }) => theme.colours.layering[4].main};

        &.partition-cell {
          background-color: ${({ theme }) =>
            theme.colours.canvas.secondary.main};
          color: ${({ theme }) => theme.colours.ink.primary.main};
          font-weight: bold;
          font-size: 0.84375rem;
        }

        &.space {
          padding: 0.6875rem 0.5625rem;

          &:first-child {
            border: 0;
          }
        }

        &.space + .space {
          border: 0;
        }

        &.space + td {
          border-left: 1px solid
            ${({ theme }) => theme.colours.layering[4].main};
        }
      }
    }
  }
`;
