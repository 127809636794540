import { styled } from 'styled-components';

export const BannerErrorWrapper = styled.div`
  display: flex;
  line-height: 1.8;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colours.messaging.error.main};
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 0.8rem;
  white-space: pre-wrap;
  /*Icon matches but not text because not legibility safe in dark mode */
  svg {
    color: ${({ theme }) => theme.colours.messaging.error.main};
  }
`;
BannerErrorWrapper.displayName = 'BannerErrorWrapper';

export const BannerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.8;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colours.layering[0].weak};
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 0.4rem;
  text-align: left;
  margin: ${({ $margin }) => $margin || 0};
  max-width: 450px;

  h3 {
    ${({ theme }) => theme.typography.headingMedium}
  }
`;
BannerInfoWrapper.displayName = 'BannerInfoWrapper';
