import { scaleLinear } from 'd3';

/* Returns a function that can be used to scale untransformed data to a fixed (px) scale
Essentially just an implementation of d3.scaleLinear()
*/
const linearScaleBuilder = (
  domainMin = 0, // smallest value in the data to display
  domainMax = 1, // largest value in the data to display
  rangeMin = 0, // lowest value for display (in px, normally 0)
  rangeMax = 100 // highest value for display (in px, normally the width or height of display region)
) => scaleLinear().domain([domainMin, domainMax]).range([rangeMin, rangeMax]);

export { linearScaleBuilder };
