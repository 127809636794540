import React from 'react';
import { Button, ButtonGroup } from '@statsbomb/kitbag-components';
import PropTypes from 'prop-types';

export const TriButtonToggle = ({
  selectionState,
  setSelectionState,
  labels,
  values,
}) => {
  const toggleValues =
    values?.true?.length > 0
      ? values
      : {
          null: null,
          true: true,
          false: false,
        };
  /* Safety valve for undefined / other bad states */
  const trueLabel = labels?.true || 'Yes';
  const falseLabel = labels?.false || 'No';
  const nullLabel = labels?.null || 'Not set';

  return (
    <div>
      <ButtonGroup>
        <Button
          onClick={() => setSelectionState(toggleValues.null)}
          variant={selectionState === toggleValues.null ? 'primary' : 'ghost'}
          shape="pill"
          size="small"
        >
          {nullLabel}
        </Button>
        <Button
          onClick={() => setSelectionState(toggleValues.true)}
          variant={selectionState === toggleValues.true ? 'primary' : 'ghost'}
          shape="pill"
          size="small"
        >
          {trueLabel}
        </Button>
        <Button
          onClick={() => setSelectionState(toggleValues.false)}
          variant={selectionState === toggleValues.false ? 'primary' : 'ghost'}
          shape="pill"
          size="small"
        >
          {falseLabel}
        </Button>
      </ButtonGroup>
    </div>
  );
};

TriButtonToggle.propTypes = {
  selectionState: PropTypes.bool,
  setSelectionState: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    true: PropTypes.string,
    false: PropTypes.string,
    null: PropTypes.string,
  }),
  values: PropTypes.shape({
    null: PropTypes.string,
    true: PropTypes.string,
    false: PropTypes.string,
  }),
};

TriButtonToggle.defaultProps = {
  selectionState: null,
  labels: null,
  values: null,
};
