import { gql } from '@apollo/client';

export const GET_TEAM_FORMATION_LOCATIONS = gql`
  query GetTeamFormationLocations(
    $competitionId: Int!
    $seasonId: Int!
    $teamId: Int!
    $formation: FormationGroup!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    formationLocations(
      competitionId: $competitionId
      seasonId: $seasonId
      teamId: null
      formation: $formation
    ) {
      backfieldLocs {
        backfield
        totalPlays
        playerLocs {
          playerTag
          position
          locations {
            x
            y
          }
        }
      }
    }
  }
`;
