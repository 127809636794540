import { styled } from 'styled-components';

// Top-level filter panel
export const FilterLayout = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};

  > div:first-child {
    position: fixed;
    transition: left 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
    left: ${({ $left }) => $left || '0'};
    will-change: left;
    width: 320px;
    backface-visibility: hidden;
    height: calc(100vh - 52px); // less the page header height
    z-index: 99;
  }

  > div:last-child {
    flex: 1;
    transition: padding 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
    will-change: padding;
  }
`;
FilterLayout.displayName = 'FilterLayout';

export const FilterSelectedContent = styled.div`
  margin: ${({ $margin }) => $margin || '0'};
  // only direct descendants of headers class get special spacing treatment
  // NOT including popup content etc.
  .headers {
    > h3 {
      ${({ theme }) => theme.typography.headingMedium};
      margin-bottom: 1rem;
    }

    > h4 {
      ${({ theme }) => theme.typography.headingSmall};
      color: ${({ theme }) => `${theme.colours.ink.primary.main}60`};
      margin-bottom: 0.5rem;
    }
  }

  // section class for spacing between headers
  .section + .section {
    margin-top: 2rem;
  }

  .highlightButton {
    button {
      position: relative;
      width: 100%;
      border-radius: 6px;
      justify-content: left;
      ${({ theme }) => theme.typography.bodyRegular};
      padding: 1rem 2rem 1rem 1rem;
      height: 48px;
      margin-bottom: 0.5rem;

      span {
        width: 60%;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }

      span + span {
        width: 40%;
        text-align: right;
        font-weight: 600;

        svg {
          position: absolute;
          right: 8px;
          top: 16px;
        }
      }
    }
  }
`;
FilterSelectedContent.displayName = 'FilterSelectedContent';

// footer for "New Filter" button
export const FilterFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 0 1rem;
  height: 3.25rem;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  ${({ theme }) => theme.typography.headingRegular}
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
FilterFooter.displayName = 'FilterFooter';

export const FilterFlyout = styled.div`
  color: ${({ theme }) => theme.colours.ink.primary.main};
`;
FilterFlyout.displayName = 'FilterFlyout';
