function isEven(n) {
  return n % 2 === 0;
}
export const everyOther = (anyArray, onEven = true) =>
  anyArray.filter((_, i) => (onEven ? isEven(i) : !isEven(i)));

function isMultipleOfThree(n) {
  return n % 3 === 0;
}

function isMultipleOfFive(n) {
  return n % 5 === 0;
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function toTitleCase(str, lowerOtherCharacters = true) {
  if (!str) {
    return '';
  }
  const stringPieces = str.split(' ');
  const correctedPieces = lowerOtherCharacters
    ? stringPieces?.map(
        (w) => w[0].toUpperCase() + w.substring(1).toLowerCase()
      )
    : stringPieces?.map((w) => w[0].toUpperCase() + w.substring(1));
  return correctedPieces.join(' ');
}

// convert fixed array of Boolean ex: [true, false, true] into number sequence ex: [1,3]
function convertBooleanToIndex(boolArray) {
  const convertedArray = [];
  boolArray?.forEach((val, index) => {
    if (val) {
      convertedArray.push(index + 1);
    }
  });
  return convertedArray;
}

// convert fixed array of Boolean ex: [true, false, true] into value of supplied array, skip false,
// ex: [true, false, true], ['First', 'Second', 'Third'] == ['First', 'Third']
function convertBooleanToValues(boolArray, valuesArray) {
  const convertedArray = [];
  boolArray?.forEach((val, index) => {
    if (val) {
      convertedArray.push(valuesArray[index]);
    }
  });
  return convertedArray;
}

function returnReadableYardsLabel(yardsData, defaultLeft, defaultRight) {
  let text = '';
  let leftHandleActive = false;
  let rightHandleActive = false;

  if (yardsData[0] !== defaultLeft) {
    leftHandleActive = true;
  }

  if (yardsData[1] !== defaultRight) {
    rightHandleActive = true;
  }

  if (leftHandleActive && !rightHandleActive) {
    text = `from ${yardsData[0]} yard${yardsData[0] !== 1 ? 's' : ''}`;
  } else if (rightHandleActive && !leftHandleActive) {
    text = `up to ${yardsData[1]} yard${yardsData[1] !== 1 ? 's' : ''}`;
  } else if (!rightHandleActive && !leftHandleActive) {
    text = `all`;
  } else {
    text = `${yardsData[0]} to ${yardsData[1]} yards`;
  }

  return text;
}

function replaceHrefTokens(href, rv) {
  let replacedHref = href;

  Object.keys(rv).forEach((key) => {
    replacedHref = replacedHref.replaceAll(`:${key}`, rv[key]);
  });
  return replacedHref;
}

function replaceTokens(location, pageInfo, rv) {
  // length === 1, check for root page
  const baseLocationSplit = location.length !== 1 ? location.split('/') : [];
  // remove empty entries
  const baseLocation = baseLocationSplit.filter(Boolean);
  // slice all entries that contain:
  // numbers
  // strings that include '-' (assumed to be string IDs)
  // strings that include 'NaN' (from missing RVs)
  // strings that include 'null'
  // strings that include 'all', 'home', 'away' keyword used for multi IDs
  const alphaNumeric = baseLocation.filter((loc) => /[a-zA-Z]/.test(loc));
  const replacedHref = baseLocation
    .slice(
      0,
      alphaNumeric.filter(
        (loc) => !/-|NaN|null|all|undefined|home|away/.test(loc)
      ).length
    )
    .join('/')
    .concat('/')
    .concat(pageInfo.urlParams.join('/'));

  return replaceHrefTokens(replacedHref, rv);
}

export {
  isEven,
  isMultipleOfFive,
  isMultipleOfThree,
  convertBooleanToIndex,
  convertBooleanToValues,
  capitalizeFirstLetter,
  returnReadableYardsLabel,
  replaceTokens,
  replaceHrefTokens,
};
