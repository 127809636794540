import { styled } from 'styled-components';

export const StyledTile = styled.div`
  width: ${({ $width }) => $width || '100%'};
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
  margin: ${({ $margin }) => $margin || '0 0 1.25rem 0'};
  padding: ${({ $padding }) => $padding || '0'};
  color: ${({ theme }) => theme.colours.ink.primary.main};
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  border: ${({ $border, theme }) =>
    `${$border} solid ${theme.colours.canvas.primary.main}` || '0'};
  opacity: ${({ $opacity }) => $opacity};
  /* transition: opacity 0.3s ease;
  will-change: opacity;
  backface-visibility: hidden; */

  &.full {
    min-height: calc(100vh - 80px);
  }
`;
StyledTile.displayName = 'StyledTile';

export const StyledTileHeader = styled.div`
  position: relative;
  padding: ${({ $isSlim }) => ($isSlim ? '0.5rem' : '1rem')};
  padding-bottom: ${({ $noBottomPadding }) => $noBottomPadding && 0};
  min-height: 40px;
  margin-bottom: 0;
  box-shadow: none;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  position: relative;
  border-bottom: ${({ $border, theme }) =>
    `${$border} solid ${theme.colours.canvas.secondary.main}` || '0'};
  font-size: 0.875rem;

  h1,
  h2,
  h3 {
    ${({ theme }) => theme.typography.headingMedium}
    margin: 0;
  }

  h2 {
    font-size: ${({ $isSlim }) => $isSlim && '0.833125rem'};
  }

  h3 {
    line-height: 1.6;
  }

  .buttons {
    display: flex;
    position: absolute;
    top: ${({ $isSlim }) => ($isSlim ? '0.5rem' : '1rem')};
    right: ${({ $isSlim }) => ($isSlim ? '0.5rem' : '1rem')};
    gap: 0.5rem;
    height: 24px;

    .ui.button.clear {
      border: 0;

      .icon {
        font-size: 1.25rem;
      }
    }

    .ui.dropdown {
      svg {
        fill: ${({ theme }) => theme.colours.canvas.primary.ink} !important;
      }
    }

    .svg-fill,
    svg {
      fill: inherit;
    }
  }
`;
StyledTileHeader.displayName = 'StyledTileHeader';

export const StyledTileBody = styled.div`
  position: relative;
  padding: ${({ $padding }) => $padding || '1rem'};
  margin: ${({ $margin }) => $margin || '0'};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  gap: ${({ $gap }) => $gap || '1rem'};
  min-height: ${({ $minHeight }) => $minHeight || 'auto'};
  max-height: ${({ $maxHeight }) => $maxHeight || 'auto'};
  overflow-y: ${({ $overflowY }) => $overflowY || 'visible'};
  font-size: 0.8125rem;

  &.row {
    flex-direction: row;

    .ui.labeled.icon.button,
    .ui.labeled.icon.buttons .button {
      padding-left: 3.4em !important;
      padding-right: 1em !important;
    }
  }

  table {
    td {
      white-space: break-spaces;
      line-height: normal;
      font-size: 0.75rem;
    }
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.layering[0].main} !important;
  }

  .ui {
    &.small.text.loader {
      padding: 0 0 0 1.75rem;
      line-height: 1.7;
      width: 1rem;
      height: 1rem;
      z-index: 50;

      &:before,
      &:after {
        left: 0.625rem;
        top: 0.1875rem;
      }
    }
  }
`;
StyledTileBody.displayName = 'StyledTileBody';

export const StyledTileAccordionHeader = styled.div`
  box-shadow: none;
  margin: 0;
  font-size: 0.90625rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colours.ink.primary.main};
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};

  &:hover {
    background-color: ${({ theme }) => theme.colours.canvas.secondary.strong};
  }

  + div {
    box-shadow: none;
    background: ${({ theme }) => theme.colours.canvas.tertiary.main};
  }
`;
StyledTileAccordionHeader.displayName = 'StyledTileAccordionHeader';

export const StyledTileAccordionBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: none;
  margin: 0;
  font-size: 0.90625rem;
  padding: ${({ $padding }) => $padding || '1rem'};
  border-bottom: 0;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  color: ${({ theme }) => theme.colours.ink.primary.main};

  .table {
    tr {
      td {
        font-size: 0.75rem;
      }
    }
  }
`;
StyledTileAccordionBody.displayName = 'StyledTileAccordionBody';
