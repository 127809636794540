import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { csHeatmap, csValue } from '../../../utils/helpers/colorScales';
import {
  addGradientDots,
  addMeaningDots,
} from '../../../utils/visualisations/keys';
import { appendText } from '../../text';
import {
  HAVOC_COLOR_MODE_HEATMAP,
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_COLOR_MODE_LOCATIONS_GAPS,
  HAVOC_COLOR_MODE_LOCATIONS_PLAYERS,
  HAVOC_COLOR_MODE_LOCATIONS_POSITIONS,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_EVENT_TYPES,
} from '../HavocChart.constants';
import {
  getGapDots,
  HAVOC_KEY_VERTICAL_WIDTH,
} from './HavocChartKey.constants';

export const drawHavocKey = (
  keyG,
  visPalette,
  isDark,
  colorMode,
  players,
  width,
  positions,
  vertical
) => {
  const mainG = keyG.append('g');
  const radius = 6;
  const verticalShapeMargin = 4;
  const havocTypes = Object.values(HAVOC_EVENT_TYPES);
  const shapeLabelHeight = 20;

  if (colorMode !== HAVOC_COLOR_MODE_HEATMAP.value) {
    const shapeMessage =
      colorMode === HAVOC_COLOR_MODE_LOCATIONS.value
        ? 'Havoc Event type'
        : 'Shape: Havoc Event type';

    appendText(mainG, visPalette, {
      message: shapeMessage,
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    const shapesG = mainG
      .append('g')
      .attr('transform', `translate(${verticalShapeMargin},${28})`);
    const shapeTextBaselineY = VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE * 0.4;
    const shapeLabelX = 2 * (radius + verticalShapeMargin);
    const shapeLabelLength = 140;

    shapesG
      .selectAll('path')
      .data(havocTypes)
      .enter()
      .append('path')
      .attr('d', (d) => d.shape(radius))
      .attr('transform', (_, i) =>
        vertical
          ? `translate(${radius + verticalShapeMargin}, ${
              i * shapeLabelHeight
            })`
          : `translate(${radius + i * shapeLabelLength},0)`
      )
      .attr('stroke', 'none')
      .attr('fill', (d) =>
        colorMode === HAVOC_COLOR_MODE_LOCATIONS.value
          ? d.color(visPalette)
          : visPalette.objects.neutral.main
      );

    const shapeLabelsG = shapesG.append('g');
    shapeLabelsG
      .selectAll('text')
      .data(havocTypes)
      .enter()
      .append('text')
      .attr('x', (_, i) =>
        vertical ? shapeLabelX : shapeLabelX + i * shapeLabelLength
      )
      .attr('y', (_, i) =>
        vertical
          ? shapeTextBaselineY + i * shapeLabelHeight
          : shapeTextBaselineY
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE}px`)
      .attr('font-family', DEFAULT_FONT)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
      .attr('fill', visPalette.text.info)
      .attr('stroke', 'none')
      .text((d) => d.name);
  }

  const colorKeyG = mainG.append('g').attr(
    'transform',
    vertical
      ? `translate(0, ${havocTypes.length * shapeLabelHeight + 36})` // 20 for line height + 16 for spacing
      : `translate(0,${45})`
  );
  const colorKeyGradientDotsG = mainG
    .append('g')
    .attr(
      'transform',
      vertical
        ? `translate(${verticalShapeMargin}, ${
            havocTypes.length * shapeLabelHeight + 56
          })`
        : `translate(${verticalShapeMargin},${65})`
    );
  const gradientDotOverride = vertical
    ? {
        gradientValues: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        radius: 6,
      }
    : {};

  if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_TIME.value) {
    appendText(colorKeyG, visPalette, {
      message: 'Color: time since Snap',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    addGradientDots(
      colorKeyGradientDotsG,
      csValue,
      visPalette,
      isDark,
      vertical ? width - 2 * verticalShapeMargin : width / 2,
      'Short',
      'Long',
      null,
      gradientDotOverride
    );
  }

  if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_PLAYERS.value) {
    appendText(colorKeyG, visPalette, {
      message: 'Color: Player',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    const dotValues = players.splice(0, 8).map((player) => ({
      color: player.color,
      name: player.playerName,
    }));
    if (dotValues?.length < players?.length) {
      dotValues.push({
        color: visPalette.objects.neutral.main,
        name: 'Other player',
      });
    }
    addMeaningDots(
      colorKeyGradientDotsG,
      dotValues,
      visPalette,
      vertical ? HAVOC_KEY_VERTICAL_WIDTH : width,
      vertical ? { dotsPerLine: 1 } : { dotsPerLine: 4 }
    );
  }

  if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_POSITIONS.value) {
    appendText(colorKeyG, visPalette, {
      message: 'Color: Alignment Position',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    const dotValues =
      positions &&
      positions.map((pos) => ({
        color: pos.color(visPalette).main,
        name: `${pos.positionCode} (${pos.positionName})`,
      }));
    addMeaningDots(
      colorKeyGradientDotsG,
      dotValues,
      visPalette,
      vertical ? HAVOC_KEY_VERTICAL_WIDTH : width,
      vertical ? { dotsPerLine: 1 } : { dotsPerLine: 4 }
    );
  }

  if (colorMode === HAVOC_COLOR_MODE_HEATMAP.value) {
    appendText(mainG, visPalette, {
      message: 'Location Intensity',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    const gradDotG = mainG.append('g').attr('transform', `translate(0,${25})`);
    addGradientDots(
      gradDotG,
      csHeatmap,
      visPalette,
      isDark,
      vertical ? width - 2 * verticalShapeMargin : width / 2,
      'Less',
      'More',
      null,
      gradientDotOverride
    );
  }

  if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_GAPS.value) {
    appendText(colorKeyG, visPalette, {
      message: 'Color: Defender Gap at Snap',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
      y: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE * 0.8,
    });
    const gapDots = getGapDots(visPalette);
    addMeaningDots(
      colorKeyGradientDotsG,
      gapDots,
      visPalette,
      width,
      vertical ? { dotsPerLine: 1 } : { dotsPerLine: 5 }
    );
  }
};
