import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@statsbomb/kitbag-components';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import SortableTable from '../../../components/Tables/SortableTable/SortableTable';
import { FormationTable } from './FormationSummaryFormationTable.styles';
import { FORMATION_SUMMARY_STICKY_COLUMNS } from './TeamFormationSummary.constants';
import { ExpandMoreButton } from '../../../components/Tables/AccordionTable/AccordionTableBody.styles';

const FormationSummaryFormationTable = ({
  headers,
  rows,
  sortBy,
  handleSort,
  sortDirection,
  isExpanded,
  handleExpand,
  displayKey,
}) => {
  const rowsToDisplay = isExpanded ? rows : rows.slice(0, 10);
  const getCellAlignment = (index) => (index > 0 ? 'right' : 'left');

  return (
    <FormationTable $displayKey={displayKey}>
      <SortableTable
        headers={headers}
        rows={rowsToDisplay}
        name="formation-table"
        stickyColumns={FORMATION_SUMMARY_STICKY_COLUMNS}
        alignment={getCellAlignment}
        handleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        isHeadSticky
        dataCellSize="regular"
      />
      {rows.length > 10 && (
        <ExpandMoreButton onClick={handleExpand} $isFormationTable>
          <Icon variant={isExpanded ? 'Collapse' : 'Expand'} size="small" />
          <div>
            {isExpanded ? 'Show top 10 formations' : 'Show all formations'}
          </div>
        </ExpandMoreButton>
      )}
    </FormationTable>
  );
};

FormationSummaryFormationTable.propTypes = {
  // The headers of the table
  headers: PropTypes.arrayOf(PropTypes.object),
  // The personnel group
  rows: PropTypes.arrayOf(PropTypes.object),
  // function that is called when a sortable header is clicked
  handleSort: PropTypes.func.isRequired,
  // the state of the column sort
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  // by which column the table is sorted
  sortBy: PropTypes.string,
  // whether the table displays all rows
  isExpanded: PropTypes.bool,
  // callback function for when the expand button is clicked
  handleExpand: PropTypes.func,
  // whether the table has a key displayed with it
  displayKey: PropTypes.bool,
};

FormationSummaryFormationTable.defaultProps = {
  headers: [],
  rows: [],
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  sortBy: '',
  isExpanded: false,
  handleExpand: undefined,
  displayKey: false,
};

export default FormationSummaryFormationTable;
