import { styled } from 'styled-components';

export const StyledTreeSearch = styled.div`
  padding: 0 1rem 0 0;

  input {
    height: 2.375rem;
    padding: 0 0 0 2.5rem;
  }

  button {
    right: 0;
    left: 10px;
  }
`;
