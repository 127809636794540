export const scheduleTileHeaders = [
  {
    id: 'week',
    key: 'week',
    label: 'Week',
    description: 'Week the game was played',
  },
  {
    id: 'date',
    key: 'date',
    label: 'Date',
    description: 'Date the game was played',
  },
  {
    id: 'kickoff',
    key: 'kickoff',
    label: 'Time',
    description: 'Time the game was played',
  },
  {
    id: 'result',
    key: 'result',
    label: 'Result',
    description: 'Result of the game',
  },
  {
    id: 'isAway',
    key: 'isAway',
    label: '',
    description: '',
  },
  {
    id: 'opponent',
    key: 'opponent',
    label: 'Opponent',
    description: 'Game opponent',
  },
  {
    id: 'score',
    key: 'score',
    label: 'Score',
    description: 'Score',
  },
  {
    id: 'pbp',
    key: 'pbp',
    label: 'PBP',
    description: 'Play by Play',
  },
  {
    id: 'offensiveEpaPerDrive',
    key: 'offensiveEpaPerDrive',
    label: 'Off EPA/Drive',
    description: 'Offensive (Expected Points Added) per drive',
  },
  {
    id: 'defensiveEpaPerDrive',
    key: 'defensiveEpaPerDrive',
    label: 'Def EPA/Drive',
    description: 'Defensive (Expected Points Added) per drive',
  },
];
