const ROUTES_Y_MODES = {
  FIELD: 'field',
  SNAP: 'snap',
  ORIGIN: 'path orgin',
};
const ROUTES_Y_OPTIONS = [
  { value: ROUTES_Y_MODES.FIELD, label: 'Position on Field' },
  { value: ROUTES_Y_MODES.SNAP, label: 'Relative to Snap' },
  { value: ROUTES_Y_MODES.ORIGIN, label: 'Relative to Origin' },
];

// pushes info back from pathing into the snap array, for summary tables
const addRouteInfo = function (snapDatum, pathsData, selectedYMode) {
  const playerPlayDatum = pathsData.find(
    (m) => m.play?.id === snapDatum.play.id,
    selectedYMode
  );
  if (playerPlayDatum) {
    const routeOrigin = playerPlayDatum.movementPath[0];
    const routeEnd =
      playerPlayDatum.movementPath[playerPlayDatum.movementPath.length - 1];
    const snapOrigin = { x: snapDatum.x, y: snapDatum.y };

    let dx = 0;
    let dy = 0;
    if (selectedYMode === ROUTES_Y_MODES.ORIGIN) {
      dx = -routeOrigin.x;
      dy = -routeOrigin.y;
    }
    if (selectedYMode === ROUTES_Y_MODES.SNAP) {
      dx = -snapOrigin.x;
      dy = -snapOrigin.y;
    }
    if (selectedYMode === ROUTES_Y_MODES.FIELD) {
      dx = -snapOrigin.x;
    }
    const path = playerPlayDatum.movementPath.map((m) => ({
      x: m.x + dx,
      y: m.y + dy,
    }));

    const modifiedDatum = {
      ...snapDatum,
      routeRun: playerPlayDatum?.routeRun,
      target: playerPlayDatum?.target,
      path,
      dxRoute: routeEnd.x - routeOrigin.x,
      dyRoute: routeEnd.y - routeOrigin.y,
      dyRouteAbs: Math.abs(routeEnd.y - routeOrigin.y),
      snapPathX: snapDatum?.freezeFrames[0].x,
      snapPathY: snapDatum?.freezeFrames[0].y,
    };
    return modifiedDatum;
  }
  const modifiedDatum = {
    ...snapDatum,
    path: null,
  };
  return modifiedDatum;
};

export { ROUTES_Y_OPTIONS, ROUTES_Y_MODES, addRouteInfo };
