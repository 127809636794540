import { line, curveBasis } from 'd3';
import {
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
  ROTATIONS,
} from '../../../utils/constants/charting';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { drawBall } from '../../../utils/visualisations/shapes';
import {
  POSITION_COLOR_ATTRIBUTE_JERSEY,
  POSITION_COLOR_ATTRIBUTE_MAIN,
  POSITION_COLOR_ATTRIBUTE_BORDER,
} from '../../../utils/visualisations/visPalettes';
import { getRosterPositionColor } from '../../../utils/helpers/positions';

const PLAYER_DOT_WIDTH_YDS = 0.7;
const ROUTE_MODE_ALL = 'Show All Routes';
const ROUTE_MODE_ER = 'Show Eligible Receiver Routes';
const ROUTE_MODE_BALL = 'Show Ball Path Only';
const ROUTE_MODE_NONE = 'Hide Routes';
const COLOR_MODE_VS = 'Offense Red vs Defense Blue';
const COLOR_MODE_POS = 'Positional Coloring';

const COLOR_OBJ_DOT = 'dot';
const COLOR_OBJ_JERSEY = 'jersey';
const COLOR_OBJ_DOT_BORDER = 'border';
const COLOR_OBJ_ARROW = 'arrow';

const VIEWPORT_FIELD = {
  label: 'Full Field',
  value: 'FIELD',
  x: FIELD_X_YARDS,
  y: FIELD_Y_YARDS,
};
const VIEWPORTS_PLAY = {
  label: 'Play (Long)',
  value: 'PLAY',
  x: 60,
  y: FIELD_Y_YARDS,
  losOffset: 20, // don't want LoS middle of frame, shift by 10 (touchzone) + 10 (20:40 split)
};
const VIEWPORTS_SHORT_PLAY = {
  label: 'Play (Short)',
  value: 'SHORT',
  x: 30,
  y: FIELD_Y_YARDS,
  losOffset: 15, // don't want LoS middle of frame, shift by 10 (touchzone) + 5 (10:20 split)
};
const VIEWPORTS_LINE = {
  label: 'Line Battle',
  value: 'LINE',
  x: 30,
  y: 30,
  losOffset: 10, // want LoS middle of frame, shift by 10 (touchzone)
};
const VIEWPORT_OPTIONS = [
  VIEWPORT_FIELD,
  VIEWPORTS_PLAY,
  VIEWPORTS_SHORT_PLAY,
  VIEWPORTS_LINE,
];

const getColor = function (
  player,
  playOffenseTeamId,
  colorMode,
  selectedPlayerId,
  visPalette,
  colorObjectType
) {
  const playerIsSelected = player.playerId === selectedPlayerId;
  /* when a player is selected, want to invert the jersey and main colours
    , border should also switch to the main colour, but arrows remain as were.
  */
  let colorAttribute;
  if (colorObjectType === COLOR_OBJ_ARROW) {
    colorAttribute = POSITION_COLOR_ATTRIBUTE_MAIN;
  } else if (colorObjectType === COLOR_OBJ_DOT_BORDER) {
    colorAttribute = playerIsSelected
      ? POSITION_COLOR_ATTRIBUTE_MAIN
      : POSITION_COLOR_ATTRIBUTE_BORDER;
  } else if (colorObjectType === COLOR_OBJ_JERSEY) {
    colorAttribute = playerIsSelected
      ? POSITION_COLOR_ATTRIBUTE_MAIN
      : POSITION_COLOR_ATTRIBUTE_JERSEY;
  } else {
    colorAttribute = playerIsSelected
      ? POSITION_COLOR_ATTRIBUTE_JERSEY
      : POSITION_COLOR_ATTRIBUTE_MAIN;
  }

  if (colorMode === COLOR_MODE_VS) {
    return player.teamId === playOffenseTeamId
      ? visPalette.forAgainst.for[colorAttribute]
      : visPalette.forAgainst.against[colorAttribute];
  }
  const posColor = getRosterPositionColor(
    player?.positionGroup,
    visPalette,
    colorAttribute
  );
  return posColor;
};

const renderArrows = function (
  svgG,
  playOffenseTeamId,
  colorMode,
  highlightPlayerId,
  visPalette,
  id
) {
  svgG
    .selectAll('g')
    .data((d) => d.pathArrows)
    .enter()
    .append('g')
    .attr('id', (a) => `${id}-${a.playerId}`)
    .attr(
      'transform',
      (a) => `translate(${a.x},${a.y}) rotate(${a.theta},0,0) scale(0.25,0.25)`
    )
    .append('svg:path')
    .attr('fill', (d) =>
      getColor(
        d,
        playOffenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_ARROW
      )
    )
    .attr('d', 'M-9,-10 l18,9 l-18,9 l4,-9 z')
    .attr('stroke', 'none');
};

const drawPaths = function (
  svg,
  playPaths,
  ballPath,
  playOffenseTeamId,
  routeMode,
  colorMode,
  highlightPlayerId,
  visPalette,
  routeSmoothing,
  id
) {
  svg.select(`#${id}-player-paths-zone`).selectAll('path').remove();
  svg.select(`#${id}-ball-zone`).selectAll('g').remove();
  svg.select(`#${id}-player-arrows-zone`).selectAll('g').remove();
  svg.select(`#${id}-ball-paths-zone`).selectAll('path').remove();
  svg.select(`#${id}-ball-arrows-zone`).selectAll('g')?.remove();

  if (routeMode !== ROUTE_MODE_NONE) {
    const pathsToDraw =
      routeMode === ROUTE_MODE_ALL
        ? playPaths.filter((p) => !Number.isNaN(p.playerId))
        : playPaths.filter(
            (p) => p.isEligibleReceiver && !Number.isNaN(p.playerId)
          );

    if (routeMode !== ROUTE_MODE_BALL) {
      svg
        .select(`#${id}-player-paths-zone`)
        .selectAll('path')
        .data(pathsToDraw)
        .enter()
        .append('svg:path')
        .attr('d', (d) =>
          routeSmoothing ? line().curve(curveBasis)(d.path) : d.path
        )
        .attr('fill', 'transparent')
        .attr('stroke', (d) =>
          getColor(
            d,
            playOffenseTeamId,
            colorMode,
            highlightPlayerId,
            visPalette,
            COLOR_OBJ_ARROW
          )
        )
        .attr('stroke-width', routeSmoothing ? '1.5px' : '1px')
        .attr('id', (d) => `${id}-player${d.playerId}`)
        .attr('opacity', 0.6);

      // draw arrows if not smoothed
      if (!routeSmoothing) {
        svg
          .select(`#${id}-player-arrows-zone`)
          .selectAll('g')
          .data(pathsToDraw, (d) => d?.playerId)
          .join(
            (enter) => {
              const newG = enter
                .append('g')
                .attr('id', (d) => `${id}-newG-${d.playerId}`);
              renderArrows(
                newG,
                playOffenseTeamId,
                colorMode,
                highlightPlayerId,
                visPalette,
                id
              );
              return newG;
            },
            (update) => update,
            (exit) => exit.remove()
          );
      }
    }

    svg
      .select(`#${id}-ball-paths-zone`)
      .append('svg:path')
      .attr(
        'd',
        routeSmoothing
          ? line().curve(curveBasis)(ballPath.path)
          : line()(ballPath.path)
      )
      .attr('fill', 'transparent')
      .attr('stroke', visPalette.commonComponents.ballPath.stroke)
      .attr('stroke-width', routeSmoothing ? '1.5px' : '1px')
      .attr(
        'stroke-dasharray',
        visPalette.commonComponents.ballPath.strokeDashArray
      )
      .attr('id', 'ballPath')
      .attr('opacity', 0.6);

    // draw arrows if not smoothed
    if (!routeSmoothing) {
      svg
        .select(`#${id}-ball-arrows-zone`)
        ?.selectAll('g')
        .data(ballPath.pathArrows)
        .enter()
        .append('g')
        .attr(
          'transform',
          (a) =>
            `translate(${a.x},${a.y}) rotate(${a.theta},0,0) scale(0.25,0.25)`
        )
        .append('svg:path')
        .attr('fill', visPalette.commonComponents.ballPath.stroke)
        .attr('d', 'M-9 -10 l18 9 l-18 9 l4 -9 z')
        .attr('stroke', 'none')
        .attr('opacity', 0.6);
    }
  }
};

const drawTrackingPaths = function (svg, playPaths, ballPath, id) {
  svg.select(`#${id}-player-tracking-zone`).selectAll('path').remove();
  svg.select(`#${id}-ballProgressivePath`).remove();

  // break the each player path into sections
  playPaths.forEach((playerData) => {
    for (let i = 0; i < playerData.path.length; i += 1) {
      // this doesnt check all the path attribs [x, x] but catches a lot
      if (!Number.isNaN(playerData.path[i][0])) {
        svg
          .select(`#${id}-player-tracking-zone`)
          .append('svg:path')
          .attr(
            'd',
            line()([
              playerData.path[i],
              playerData.path[i < playerData.path.length - 1 ? i + 1 : i],
            ])
          )
          .attr('fill', 'transparent')
          .attr('id', `${id}-playerPath${playerData.playerId}_${i}`)
          .attr('opacity', 0);
      }
    }
  });

  // break the ball path into sections
  for (let i = 0; i < ballPath.path.length; i += 1) {
    svg
      .select(`#${id}-ball-paths-zone`)
      .append('svg:path')
      .attr(
        'd',
        line()([
          ballPath.path[i],
          ballPath.path[i < ballPath.path.length - 1 ? i + 1 : i],
        ])
      )
      .attr('fill', 'transparent')
      .attr('id', `${id}-ballPathPart${i}`)
      .attr('opacity', 0);
  }
};

const drawPlayers = function (
  svg,
  knownPlayerData,
  anonPlayerData,
  ballData,
  offenseTeamId,
  pxPerYard,
  orientation,
  colorMode,
  highlightPlayerId,
  visPalette,
  id
) {
  let textRotate = '0';
  if (orientation === ROTATIONS.VERTICAL_UP) {
    textRotate = '90';
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    textRotate = '-90';
  }
  svg
    .select(`#${id}-anon-players-zone`)
    .selectAll('circle')
    .data(anonPlayerData)
    .join(
      (enter) => enter.append('circle'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr('cx', (d) => d.x * pxPerYard)
    .attr('cy', (d) => d.y * pxPerYard)
    .attr('r', pxPerYard * PLAYER_DOT_WIDTH_YDS)
    .attr('fill', (d) =>
      getColor(
        d,
        offenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_DOT
      )
    )
    .attr('stroke', (d) =>
      getColor(
        d,
        offenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_DOT_BORDER
      )
    )
    .attr('stroke-width', '1px')
    .attr('opacity', 0.5);

  svg
    .select(`#${id}-players-zone`)
    .selectAll('circle')
    .data(knownPlayerData, (d) => d.playerId)
    .join(
      (enter) => enter.append('circle'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr(
      'transform',
      (d) => `translate(${d.x * pxPerYard},${d.y * pxPerYard})`
    )
    .attr('r', pxPerYard * PLAYER_DOT_WIDTH_YDS)
    .attr('fill', (d) =>
      getColor(
        d,
        offenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_DOT
      )
    )
    .attr('stroke', (d) =>
      getColor(
        d,
        offenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_DOT_BORDER
      )
    )
    .attr('stroke-width', '1px')
    .attr('id', (d) => `${id}-player${d.player.id}`)
    .attr('data-testid', (d) => `playerDotT-${d.player.id}`);

  // jersey numbers
  svg
    .select(`#${id}-jerseys-zone`)
    .selectAll('text')
    .data(knownPlayerData, (d) => d.playerId)
    .join(
      (enter) => {
        const t = enter
          .append('text')
          .attr('x', 0)
          .attr('y', 3)
          .attr('text-anchor', 'middle')
          .attr('font', DEFAULT_FONT)
          .attr('font-size', VISUALISATION_FONT_SETUPS.ICON_LABEL.SIZE)
          .attr('font-weight', VISUALISATION_FONT_SETUPS.ICON_LABEL.WEIGHT)
          .text((d) => d.player?.jerseyNumber);
        return t;
      },
      (update) => update,
      (exit) => exit.remove()
    )
    .attr(
      'transform',
      (d) =>
        `translate(${d.x * pxPerYard},${
          d.y * pxPerYard
        }) rotate(${textRotate},0,0)`
    )
    .attr('id', (d) => `${id}-playerNumber${d.playerId}`)
    .attr('fill', (d) =>
      getColor(
        d,
        offenseTeamId,
        colorMode,
        highlightPlayerId,
        visPalette,
        COLOR_OBJ_JERSEY
      )
    );

  if (ballData.length) {
    svg
      .select(`#${id}-ball-zone`)
      .selectAll('path')
      .data(ballData)
      .join(
        (enter) => enter.append('svg:path'),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr('d', drawBall(5))
      .attr('id', `${id}-theBall`)
      .attr(
        'transform',
        (d) => `translate(${d.x * pxPerYard}, ${d.y * pxPerYard})`
      )
      .attr('fill', visPalette.commonComponents.ball.fill)
      .attr('fill-opacity', visPalette.commonComponents.ball.fillOpacity)
      .attr('stroke', visPalette.commonComponents.ball.stroke)
      .attr('stroke-opacity', visPalette.commonComponents.ball.strokeOpacity)
      .attr('stroke-width', visPalette.commonComponents.ball.strokeWidth);
  }
};

const drawLineOfScrimmage = function (
  svg,
  lineOfScrimmage,
  lineTarget,
  pxPerYard,
  visPalette,
  id
) {
  svg.select(`#${id}-los-zone`).selectAll('line').remove();
  svg
    .select(`#${id}-los-zone`)
    .append('line')
    .attr('x1', lineTarget * pxPerYard)
    .attr('x2', lineTarget * pxPerYard)
    .attr('y1', 1)
    .attr('y2', FIELD_Y_YARDS * pxPerYard - 1)
    .attr('stroke', visPalette.commonComponents.targetLine.stroke)
    .attr('stroke-width', visPalette.commonComponents.targetLine.strokeWidth)
    .attr(
      'stroke-opacity',
      visPalette.commonComponents.targetLine.strokeOpacity
    )
    .attr(
      'stroke-dasharray',
      visPalette.commonComponents.targetLine.strokeDashArray
    );
  svg
    .select(`#${id}-los-zone`)
    .append('line')
    .attr('x1', lineOfScrimmage * pxPerYard)
    .attr('x2', lineOfScrimmage * pxPerYard)
    .attr('y1', 1)
    .attr('y2', FIELD_Y_YARDS * pxPerYard - 1)
    .attr('stroke', visPalette.commonComponents.lineOfScrimmage.stroke)
    .attr(
      'stroke-width',
      visPalette.commonComponents.lineOfScrimmage.strokeWidth
    )
    .attr(
      'stroke-opacity',
      visPalette.commonComponents.lineOfScrimmage.strokeOpacity
    )
    .attr(
      'stroke-dasharray',
      visPalette.commonComponents.lineOfScrimmage.strokeDashArray
    );
};

export {
  drawPaths,
  drawTrackingPaths,
  drawPlayers,
  drawLineOfScrimmage,
  ROUTE_MODE_ALL,
  ROUTE_MODE_ER,
  ROUTE_MODE_BALL,
  ROUTE_MODE_NONE,
  COLOR_MODE_VS,
  COLOR_MODE_POS,
  VIEWPORT_OPTIONS,
  VIEWPORT_FIELD,
  VIEWPORTS_PLAY,
  VIEWPORTS_LINE,
  VIEWPORTS_SHORT_PLAY,
};
