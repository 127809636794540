import React from 'react';
import PropTypes from 'prop-types';
import { BAR_INDICATOR, VIS_GAPS } from './FormationSummaryChart.constants';

// A triangle which appears at the end of a bar and indicates
// the bar is greater than the max domain allowed in the chart
export const BarIndicator = ({
  barY,
  barWidth,
  barHeight,
  isNegative,
  fill,
}) => {
  const POINT_A_X = isNegative
    ? BAR_INDICATOR.OFFSET - VIS_GAPS.LEFT
    : barWidth + BAR_INDICATOR.OFFSET;
  const POINT_B_X = isNegative
    ? BAR_INDICATOR.WIDTH - VIS_GAPS.LEFT
    : barWidth + BAR_INDICATOR.WIDTH;

  const POINT_A_Y = isNegative ? -barY : barY;
  const POINT_B_Y = isNegative ? -(barHeight / 2 + barY) : barHeight / 2 + barY;
  const POINT_C_Y = isNegative ? -(barHeight + barY) : barHeight + barY;

  // The points creating the triangle - each point consist of an x and y coordinates.
  const POINT_A = [POINT_A_X, POINT_A_Y];
  const POINT_B = [POINT_B_X, POINT_B_Y];
  const POINT_C = [POINT_A_X, POINT_C_Y];

  return (
    <polygon
      points={[POINT_A, POINT_B, POINT_C]}
      fill={fill}
      transform={isNegative ? 'rotate(180)' : ''}
    />
  );
};

BarIndicator.propTypes = {
  // y coordinate of the bar
  barY: PropTypes.number.isRequired,
  // the width of the bar
  barWidth: PropTypes.number.isRequired,
  // the height of the bar
  barHeight: PropTypes.number.isRequired,
  // whether the bar represents a negative value
  isNegative: PropTypes.bool,
  // the color of the indicator
  fill: PropTypes.string.isRequired,
};

BarIndicator.defaultProps = {
  isNegative: false,
};

export default BarIndicator;
