import { isEmpty } from 'lodash';
import { formatValue } from '../../../League/stats/stats.dataManipulation';

export const formatHavocRows = (havocGroupData, headers, isCSV) => {
  if (isEmpty(havocGroupData)) {
    return [];
  }
  const havocRows = havocGroupData?.map((row) => {
    const columns = headers.map((h) => {
      const formattedValue = formatValue(row[h.key], h.units);
      if (isCSV) {
        return { [h.key]: formattedValue };
      }

      const label = { label: formattedValue };
      const title = {};
      const metricObject = {
        [h.key]: { ...label, ...title },
      };
      return metricObject;
    });
    /* Merge object array of columns into single row object */
    return Object.assign(...columns);
  });
  return havocRows;
};
