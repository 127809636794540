import { styled } from 'styled-components';

const getColorWithOpacity = (hexColour, opacity) => {
  const hexOpacity = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');
  return `${hexColour}${hexOpacity}`;
};

const getTableHeight = ({ $numberOfTables }) => {
  if ($numberOfTables <= 3) {
    return `
      height: calc(100% - 2.8125rem);

      @media screen and (max-width: 1450px) {
        height: calc(100% - 2.5rem);
      }
    `;
  }
  return 'height: 9.5rem;';
};

export const DepthChartTableContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colours.canvas.secondary.main}`};
  height: 100%;

  a {
    text-decoration: underline;
  }

  ${({ $selectableRows }) =>
    $selectableRows
      ? `
        th:first-of-type {
          width: 2rem;
        }

        th:nth-of-type(n+3) {
          width: 3.75rem;
        }
      `
      : `
        th:not(:first-of-type) {
          width: 3.75rem;
        }
      `}

  td {
    position: relative;
  }

  tbody tr:nth-of-type(odd) td {
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  }

  tbody tr td:first-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6.25rem;
  }

  > div.scrollable {
    overflow-y: auto;
    ${getTableHeight}

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colours.layering[0].main};
    }

    ::-webkit-scrollbar-corner,
    ::-webkit-slider-track {
      background: transparent;
    }
  }
`;

export const StyledTableDataCell = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  background-color: ${({ $color, $opacity }) =>
    getColorWithOpacity($color, $opacity)};
  ${({ theme, $opacity }) =>
    theme.isDark &&
    `
    color: ${theme.colours.ink[$opacity > 0.8 ? 'inverse' : 'primary'].main}
  `}
`;

export const DepthChartTableHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  padding: 0.5rem;

  h3 {
    font-family: Inter;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    ${({ theme }) => theme.typography.headingSmall}
    ${({ theme }) => theme.colours.ink.primary.main};
    margin: 0;
  }
`;

DepthChartTableContainer.displayName = 'DepthChartTableContainer';
StyledTableDataCell.displayName = 'StyledTableDataCell';
DepthChartTableHeader.displayName = 'DepthChartTableHeader';
