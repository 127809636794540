import React, { useEffect } from 'react';
import { select } from 'd3';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { max } from 'lodash';
import { useD3 } from '../../../../../utils/hooks/useD3';
import {
  RUN_KEY_CLASSES,
  RUN_KEY_OBJECT_SIZES,
  RUN_KEY_SECTION_MARGIN,
  RUN_KEY_SECTION_WIDTH,
  RUN_KEY_SIZE_Y,
  RUN_KEY_TITLE_FONT,
  RUN_KEY_WIDTH,
} from './RunKey.constants';
import { appendText, fontBaselineY } from '../../../../../visualisations/text';
import {
  addGradientDots,
  addSizeScaleDots,
} from '../../../../../utils/visualisations/keys';
import { formatValue } from '../../../../League/stats/stats.dataManipulation';
import { RUN_TENDENCY_BUBBLE_SIZES } from '../RunTendencies.constants';
import { runTendencyAxisShape } from '../RunTendencies.propTypes';
import { drawLeagueLines, drawSolidFrameDots } from './RunKey.drawing';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const RunKey = ({
  chartId,
  title,
  isVertical,
  colorAxisSetup,
  scalingAxisSetup,
  linesMetricName,
}) => {
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;

  const titleHeight = title
    ? RUN_KEY_SIZE_Y.TITLE + RUN_KEY_SECTION_MARGIN.VERTICAL
    : 0;
  const showLeagueLines = !!linesMetricName;
  const linesHeight = showLeagueLines ? RUN_KEY_SIZE_Y.LINES : 0;
  const solidFrameHeight = scalingAxisSetup ? RUN_KEY_SIZE_Y.SOLID_FRAME : 0;
  const scalingHeight = scalingAxisSetup
    ? RUN_KEY_SIZE_Y.SCALING + RUN_KEY_SECTION_MARGIN.VERTICAL
    : 0;
  const colorHeight =
    scalingHeight || RUN_KEY_SIZE_Y.COLORING + RUN_KEY_SECTION_MARGIN.VERTICAL;
  const vbHeight = isVertical
    ? titleHeight + linesHeight + scalingHeight + colorHeight + solidFrameHeight
    : titleHeight +
      max([linesHeight, scalingHeight, colorHeight, solidFrameHeight]);

  const vbWidth = isVertical
    ? RUN_KEY_WIDTH.VERTICAL
    : RUN_KEY_WIDTH.HORIZONTAL;
  const viewBox = `0 0 ${vbWidth} ${vbHeight}`;

  const sectionWidth = isVertical
    ? RUN_KEY_WIDTH.VERTICAL
    : RUN_KEY_SECTION_WIDTH;
  const colorTransform = `translate(0, ${titleHeight})`;
  const scalingTransform = isVertical
    ? `translate(0, ${titleHeight + colorHeight})`
    : `translate(${
        sectionWidth + RUN_KEY_SECTION_MARGIN.HORIZONTAL
      }, ${titleHeight})`;
  const getLinesTransform = () => {
    if (isVertical && scalingAxisSetup) {
      return `translate(0, ${titleHeight + colorHeight + scalingHeight})`;
    }
    if (isVertical) {
      return `translate(0, ${titleHeight + colorHeight})`;
    }
    if (scalingAxisSetup) {
      return `translate(${
        (sectionWidth + RUN_KEY_SECTION_MARGIN.HORIZONTAL) * 2
      }, ${titleHeight})`;
    }
    /* If no scaling info, show to right of coloring */
    return `translate(${
      sectionWidth + RUN_KEY_SECTION_MARGIN.HORIZONTAL
    }, ${titleHeight})`;
  };
  const solidFrameTransform = isVertical
    ? `translate(0, ${titleHeight + colorHeight + scalingHeight + linesHeight})`
    : `translate(${
        (sectionWidth + RUN_KEY_SECTION_MARGIN.HORIZONTAL) * 3
      }, ${titleHeight})`;

  /* 
  Permanent elements 
    Create the structure of all parts, then useEffect() based on relevant 
    parameters to update those parts/their children as required
  */
  const ref = useD3((svg) => {
    svg.attr('id', chartId);
    svg.selectAll('*').remove();

    /* Add background */
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', RUN_KEY_CLASSES.BACKGROUND);

    svg.append('g').attr('class', RUN_KEY_CLASSES.TITLE);
    svg
      .append('g')
      .attr('class', RUN_KEY_CLASSES.COLORING)
      .attr('transform', colorTransform);
    svg
      .append('g')
      .attr('class', RUN_KEY_CLASSES.SCALING)
      .attr('transform', scalingTransform);
    svg
      .append('g')
      .attr('class', RUN_KEY_CLASSES.LINES)
      .attr('transform', getLinesTransform());
    svg
      .append('g')
      .attr('class', RUN_KEY_CLASSES.SOLID_FRAME)
      .attr('transform', solidFrameTransform);

    /* Add all elements from basic chart setup */
  }, []);

  /* Changing elements */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    svg
      .select(`.${RUN_KEY_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    const titleG = svg.select(`.${RUN_KEY_CLASSES.TITLE}`);
    titleG.selectAll('*').remove();
    if (title) {
      appendText(titleG, visPalette, {
        message: title,
        y: fontBaselineY(
          RUN_KEY_TITLE_FONT.SIZE,
          RUN_KEY_TITLE_FONT.LINE_HEIGHT
        ),
        fontSize: RUN_KEY_TITLE_FONT.SIZE,
        fontWeight: RUN_KEY_TITLE_FONT.WEIGHT,
      });
    }

    const colorsG = svg.select(`.${RUN_KEY_CLASSES.COLORING}`);
    colorsG.selectAll('*').remove();
    /* When no scaling setup, should be using poa axis values */
    const gapOrPOA = scalingAxisSetup ? 'gap' : 'poa';
    const coloringMin = formatValue(
      colorAxisSetup[gapOrPOA].DOMAIN[0],
      colorAxisSetup.unitType
    );
    const coloringMax = formatValue(
      colorAxisSetup[gapOrPOA].DOMAIN[1],
      colorAxisSetup.unitType
    );
    const coloringName = colorAxisSetup.label;
    /* When displaying horizontally, want text under dots to align, so force 
        gradient dots to take up extra padding (without enlarging the dots)
    */
    const gradientOverrides =
      scalingAxisSetup && !isVertical
        ? { circleY: RUN_KEY_OBJECT_SIZES.SCALE_BUBBLE_MAX_RADIUS }
        : {};
    addGradientDots(
      colorsG,
      colorAxisSetup.colorFunction,
      visPalette,
      isDark,
      sectionWidth,
      coloringMin,
      coloringMax,
      coloringName,
      gradientOverrides
    );

    const scalingG = svg.select(`.${RUN_KEY_CLASSES.SCALING}`);
    scalingG.selectAll('*').remove();
    if (scalingAxisSetup) {
      const scalingMin = formatValue(
        scalingAxisSetup.gap.DOMAIN[0],
        scalingAxisSetup.unitType
      );
      const scalingMax = formatValue(
        scalingAxisSetup.gap.DOMAIN[1],
        scalingAxisSetup.unitType
      );
      const scalingName = scalingAxisSetup.label;
      const scalingInputs = [0, 0.25, 0.5, 0.75, 1];
      addSizeScaleDots(
        scalingG,
        visPalette,
        sectionWidth,
        scalingMin,
        scalingMax,
        scalingName,
        {
          maxRadius: RUN_TENDENCY_BUBBLE_SIZES.maxRadius,
          minRadius: RUN_TENDENCY_BUBBLE_SIZES.minRadius,
          scaleValues: scalingInputs,
        }
      );
    }

    const linesG = svg.select(`.${RUN_KEY_CLASSES.LINES}`);
    linesG.selectAll('*').remove();
    if (showLeagueLines) {
      drawLeagueLines(linesG, visPalette, sectionWidth, linesMetricName);
    }

    const solidFrameG = svg.select(`.${RUN_KEY_CLASSES.SOLID_FRAME}`);
    solidFrameG.selectAll('*').remove();
    if (scalingAxisSetup) {
      // scaling means in dot mode not bar mode
      drawSolidFrameDots(solidFrameG, visPalette);
    }
  }, [
    colours,
    isDark,
    colorAxisSetup,
    scalingAxisSetup,
    showLeagueLines,
    linesMetricName,
  ]);

  return <svg ref={ref} />;
};

RunKey.propTypes = {
  chartId: PropTypes.string,
  title: PropTypes.string,
  isVertical: PropTypes.bool,
  colorAxisSetup: runTendencyAxisShape.isRequired,
  scalingAxisSetup: runTendencyAxisShape,
  linesMetricName: PropTypes.string,
};

RunKey.defaultProps = {
  chartId: 'run-point-of-attack-bars',
  title: null,
  isVertical: false,
  scalingAxisSetup: null,
  linesMetricName: null,
};

export default RunKey;
