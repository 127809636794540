import { styled } from 'styled-components';

export const SliderWrapper = styled.div`
  margin-top: 4px;
  height: 12px;
`;
SliderWrapper.displayName = 'SliderWrapper';
export const SliderDisplay = styled.div`
  position: relative;
  width: 100%;
  padding-left: ${({ $padding }) => `${$padding}%` || '0'};
  padding-right: ${({ $padding }) => `${$padding}%` || '0'};

  & > span {
    display: block;
    width: 50%;
    float: left;
    height: 1.6rem;
    text-align: center;
    font-size: 14px;
  }
  & > span:first-child {
    text-align: left;
    font-size: 12px;
    width: 25%;
  }
  & > span:last-child {
    text-align: right;
    font-size: 12px;
    width: 25%;
  }

  & > button {
    display: block;
    width: 50%;
    float: left;
  }

  & > span > button {
    display: block;
    width: 40%;
    float: left;
  }
  & > span > button:last-child {
    float: right;
  }
`;
SliderDisplay.displayName = 'SliderDisplay';
