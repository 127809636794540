import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const getRadarStatsQuery = (selectedStatNames) => {
  const joinedStatNames =
    (selectedStatNames && selectedStatNames.join('\n')) || '';
  const gqlString = `
  query getPlayerStatsForRadar(
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    $teamId: Int!
    $specificPositions: [SpecificPosition!]
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedPlayerId @client @export(as: "playerId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}
    playerStats(
      playFilters: {  
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
      aggregateMode: RATE
      playerStatFilters: {
        playerIds: [$playerId]
        teamIds: [$teamId]
        specificPositions: $specificPositions
        }
    ) {
      items {  
        groups {
          key
          value
        }
        playerName
        plays
        ${joinedStatNames}
        }
    }
  }
`;
  return gqlString;
};

export const getRadarStatsLeagueAverageQuery = (selectedStatNames) => {
  const joinedStatNames =
    (selectedStatNames && selectedStatNames.join(',')) || '';
  const gqlString = `
  query getPlayerStatsForRadarLA(
    $competitionId: Int!
    $seasonId: Int!
    $specificPositions: [SpecificPosition!]
    $rosterPositions: [GeneralPosition!]
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    ${getContextFilterClientExports()}
    playerStats(
      playFilters: {  
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
      aggregateMode: RATE
      playerStatFilters: {
        playerIds: []
        specificPositions: $specificPositions
        rosterPositions: $rosterPositions
        }
    ) {
      items {  
        groups {
          key
          value
        }
        playerName
        plays
        ${joinedStatNames}
        }
    }
  }
`;
  return gqlString;
};
