// takes an element and returns the requested dimension
const getWidth = (element) => {
  if (element.tagName === 'svg') {
    return element.viewBox.baseVal.width;
  }
  return element.clientWidth;
};

// takes a node and returns the calculated height and width to pass to the export.
export const getExportWidth = (node, columns) => {
  if (!node) {
    return 0;
  }

  // if it's an svg, we just need to return its viewBox dimensions
  if (node.tagName === 'svg') {
    return node.viewBox?.baseVal.width;
  }

  // if it's not an svg, then we are dealing with a multi element export
  const elements = Array.from(node.children);

  // group the elements into chunks of rows
  const rows = elements.reduce((acc, element, index) => {
    const rowIndex = Math.floor(index / columns);

    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }

    acc[rowIndex].push(element);

    return acc;
  }, []);

  // get the width value of the widest row
  const rowWidths = rows.map((row) =>
    row.reduce((acc, element) => acc + getWidth(element), 0)
  );
  const width = Math.max(...rowWidths);

  return width;
};
