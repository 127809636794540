import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import DownloadCSVButton from '../../../../components/buttons/DownloadCSV';
import Tile from '../../../../components/Tile/Tile';
import { TacklingTableContainer } from '../../TeamTackling/TacklingPlayerTable/TacklingPlayerTable.styles';
import {
  HAVOC_TABLE_AGGREGATION_MODE_TYPE,
  getHavocHeaders,
} from './HavocTable.constants';
import { formatHavocRows } from './HavocTable.dataManipulation';
import HavocTable from './HavocTable';
import SwitchVisTableButton from '../../../../components/buttons/SwitchVisTable/SwitchVisTable';
import { havocEventsToGroupedHavocPlays } from '../HavocPlays.dataManipulation';

const HavocTableTile = ({
  havocData,
  aggregationMode,
  playSummary,
  tileTitle,
  fileName,
  handleVisTableSwitch,
  isPlaysMode,
}) => {
  const { columnHeaders, defaultColumnKey } = getHavocHeaders(
    aggregationMode,
    isPlaysMode
  );
  const { groupData: bodyRows, totalsDatum } = havocEventsToGroupedHavocPlays({
    havocData,
    aggregationMode,
    playSummary,
  });
  const footerRows = [totalsDatum];

  const csvData = formatHavocRows(bodyRows, columnHeaders, true);

  return (
    <Tile border="0" margin="0 0 0.8rem 0">
      <Tile.Header>
        <h2 title={tileTitle}>{tileTitle}</h2>
        <div className="buttons">
          {isFunction(handleVisTableSwitch) && (
            <SwitchVisTableButton
              isShowingVis={false}
              handleClick={handleVisTableSwitch}
            />
          )}
          <DownloadCSVButton
            data={csvData}
            headers={columnHeaders}
            fileName={fileName}
            data-testid="havoc-table-download-button"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="0 1rem 1rem 1rem" data-testid="havoc-table-tile">
        <TacklingTableContainer>
          <HavocTable
            bodyRows={bodyRows}
            footerRows={footerRows}
            columnHeaders={columnHeaders}
            defaultColumnKey={defaultColumnKey}
          />
        </TacklingTableContainer>
      </Tile.Body>
    </Tile>
  );
};

HavocTableTile.propTypes = {
  havocData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  playSummary: PropTypes.shape({
    playCount: PropTypes.number,
    runRate: PropTypes.number,
    passPlays: PropTypes.number,
    runPlays: PropTypes.number,
  }),
  tileTitle: PropTypes.string.isRequired,
  aggregationMode: PropTypes.string,
  fileName: PropTypes.string,
  handleVisTableSwitch: PropTypes.func,
  isPlaysMode: PropTypes.bool,
};

HavocTableTile.defaultProps = {
  aggregationMode: HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP,
  fileName: 'table.csv',
  handleVisTableSwitch: null,
  playSummary: { playCount: 0, runRate: 0.5, passPlays: 0, runPlays: 0 },
  isPlaysMode: false,
};

export default HavocTableTile;
