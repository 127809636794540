import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Button, Checkbox, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import { groupBy, sortBy } from 'lodash';
import { GET_GAMES } from '../Header/getGames';
import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';
import { uiState, cf_GameIds, cf_GameIdsEnabled, mf_Teams } from '../../apollo';
import { enableFilter } from './Filters.helper';
import {
  StyledModal,
  TriggerButtonContainer,
  SelectAllButtonContainer,
} from './ContextFilters.styles';
import { PhaseContainer, CustomLabel } from './GamesFilter.styles';
import { useIsEligiblePage } from './ContextFilters.hooks';

const GamesFilter = ({ handleEnabledToggle }) => {
  // global state
  const teamId = useReactiveVar(mf_Teams);
  const cf_gamesEnabled = useReactiveVar(cf_GameIdsEnabled);
  const cf_gameIds = useReactiveVar(cf_GameIds);

  // local state
  const [gameIds, setGameIds] = useState(cf_gameIds);
  const [isOpen, setIsOpen] = useState(false);

  const { data, loading, error } = useQuery(GET_GAMES);
  if (error) {
    console.error(ERROR_CODES.GET_GAMES, error);
  }

  // sort the games from newest to to oldest and group them by phase
  const gamesData = data?.games.edges.map(({ node }) => node) || [];
  const sortedGames = sortBy(gamesData, (g) => new Date(g.date)).reverse();
  const groupedGames = groupBy(sortedGames, 'phaseName');
  const gamesEntries = Object.entries(groupedGames);

  const isEligiblePage = useIsEligiblePage('GAMES');
  const isEnabled = cf_gamesEnabled && isEligiblePage;

  // Any phase with more than 12 games will require us to use a larger modal
  const isSmallModal = gamesEntries?.some(([, games]) => games.length > 12);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    const { contextFilters } = uiState();

    contextFilters.gameIds.value = gameIds;
    cf_GameIds(gameIds);
    setIsOpen(false);
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleToggleGame = (id) => {
    if (gameIds.includes(id)) {
      setGameIds(gameIds.filter((g) => g !== id));
    } else {
      setGameIds([...gameIds, id]);
    }
    enableFilter(cf_gamesEnabled, cf_GameIdsEnabled, 'gameIds');
  };

  const handleSelectAll = (games) => {
    const ids = games.map((g) => g.id);
    setGameIds(ids);
    enableFilter(cf_gamesEnabled, cf_GameIdsEnabled, 'gameIds');
  };

  const handleDeselectAll = () => {
    setGameIds([]);
    enableFilter(cf_gamesEnabled, cf_GameIdsEnabled, 'gameIds');
  };

  return (
    <StyledModal
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage || loading}
          >
            Games
          </Button>
          {isEnabled && <Label floating>1</Label>}
        </TriggerButtonContainer>
      }
      size={isSmallModal ? 'small' : 'tiny'}
    >
      <Modal.Content>
        <h2>Games</h2>
        <Form className="types">
          <Form.Field>
            <Toggle
              id="game-type-toggle"
              label="Games"
              onChange={() =>
                handleEnabledToggle(
                  !cf_gamesEnabled,
                  cf_GameIdsEnabled,
                  'gameIds',
                  {
                    enabled: !cf_gamesEnabled,
                  }
                )
              }
              checked={cf_gamesEnabled}
            />
          </Form.Field>
          <div>
            {gamesData &&
              gamesEntries.map(([phase, games], index) => (
                <PhaseContainer key={phase} $numOfGames={games.length}>
                  <h3>{phase}</h3>
                  <Form.Group>
                    {games.map((game) => {
                      const { homeTeam, awayTeam } = game;
                      const formattedDate = new Date(game.date).toLocaleString(
                        'en-us',
                        { timeZone: 'UTC', day: 'numeric', month: 'short' }
                      );
                      // figure out which team is the selected team and which is the opponent
                      const isHomeTeam = homeTeam.id === teamId;
                      const selectedTeam = isHomeTeam ? homeTeam : awayTeam;
                      const opponentTeam = isHomeTeam ? awayTeam : homeTeam;

                      // use the data to assemble the checkbox label
                      const label = (
                        <CustomLabel htmlFor={game.id}>
                          <span className="round">{game.roundShortName}</span>
                          <span className="teams">
                            {`${selectedTeam.shortName} ${
                              isHomeTeam ? 'vs' : '@'
                            } ${opponentTeam.shortName}`}
                          </span>
                          <span className="date">{formattedDate}</span>
                        </CustomLabel>
                      );

                      return (
                        <Form.Field key={game.id}>
                          <Checkbox
                            id={game.id}
                            label={label}
                            onChange={() => handleToggleGame(game.id)}
                            checked={gameIds.includes(game.id)}
                          />
                        </Form.Field>
                      );
                    })}
                  </Form.Group>
                  <SelectAllButtonContainer>
                    <Button size="small" onClick={() => handleSelectAll(games)}>
                      Select All
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDeselectAll(games)}
                    >
                      Deselect All
                    </Button>
                  </SelectAllButtonContainer>
                  {gamesEntries.length !== index + 1 && <hr />}
                </PhaseContainer>
              ))}
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={() => setGameIds(cf_gameIds)}>
          Reset
        </Button>
        <Button onClick={handleClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

GamesFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default GamesFilter;
