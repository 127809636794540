import { scaleSqrt } from 'd3';
import { DEFAULT_FONT, VISUALISATION_FONT_SETUPS } from '../constants/visText';
import { appendText, fontBaselineY } from '../../visualisations/text';

/* */
const KEY_SPACING = { SLIM: 4, DEFAULT: 8, LARGE: 16 };

/* Add labels beneath */
const addDotLabels = (
  dotLabelsG,
  width,
  visPalette,
  labelMin,
  labelMax,
  labelAxis
) => {
  const baselineY = fontBaselineY(
    VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT
  );
  const textSetup = {
    y: baselineY,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  };
  appendText(dotLabelsG, visPalette, {
    ...textSetup,
    message: labelMin,
  });
  appendText(dotLabelsG, visPalette, {
    ...textSetup,
    x: width,
    textAnchor: 'end',
    message: labelMax,
  });
  if (labelAxis) {
    appendText(dotLabelsG, visPalette, {
      ...textSetup,
      x: width / 2,
      textAnchor: 'middle',
      message: labelAxis,
    });
  }
};

const DOT_GRADIENT_VALUES = [
  0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
  0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1,
];
export const addGradientDots = (
  keySectionG,
  coloringFunction,
  visPalette,
  isDark,
  width,
  labelMin,
  labelMax,
  labelAxis,
  overrides
) => {
  const radius = overrides?.radius || 5;
  const circleY = overrides?.circleY || radius;
  const strokeWidth = overrides?.strokeWidth || 0;
  const gradientValues = overrides?.gradientValues || DOT_GRADIENT_VALUES;
  /* Leftmost and Rightmost dots will sit centered on the edges of specified width
    adjust this by shrinking width by and adjusting right */
  const dotsG = keySectionG
    .append('g')
    .attr('transform', `translate(${radius},0)`);
  const dotLabelsG = keySectionG
    .append('g')
    .attr('transform', `translate(0,${circleY * 2 + KEY_SPACING.SLIM})`);

  const dotsWidth = width - radius * 2;
  /* Draw a dot for each gradient value, equally separated across the width */
  dotsG
    .selectAll('circle')
    .data(gradientValues)
    .enter()
    .append('circle')
    .attr('cx', (d) => d * dotsWidth)
    .attr('cy', circleY)
    .attr('r', radius)
    .attr('fill', (d) => coloringFunction(d, isDark))
    .attr('stroke', (d) => coloringFunction(d, isDark))
    .attr('stroke-width', strokeWidth);

  addDotLabels(dotLabelsG, width, visPalette, labelMin, labelMax, labelAxis);
};

const DOT_SIZE_SCALE_VALUES = [
  0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1,
];
export const addSizeScaleDots = (
  keySectionG,
  visPalette,
  width,
  labelMin,
  labelMax,
  labelAxis,
  overrides
) => {
  const minRadius = overrides?.minRadius || 1;
  const maxRadius = overrides?.maxRadius || 5;
  const strokeWidth = overrides?.strokeWidth || 0;
  const gradientValues = overrides?.scaleValues || DOT_SIZE_SCALE_VALUES;
  /* Leftmost and Rightmost dots will sit centered on the edges of specified width
    adjust this by shrinking width by and adjusting right */
  const dotsG = keySectionG
    .append('g')
    .attr('transform', `translate(${maxRadius},0)`);
  const dotLabelsG = keySectionG
    .append('g')
    .attr('transform', `translate(0,${maxRadius * 2 + KEY_SPACING.SLIM})`);
  const dotsWidth = width - maxRadius * 2;
  const radiusSizeScaler = scaleSqrt()
    .domain([0, 1])
    .range([minRadius, maxRadius]);
  /* Draw a dot for each gradient value, equally separated across the width */
  dotsG
    .selectAll('circle')
    .data(gradientValues)
    .enter()
    .append('circle')
    .attr('cx', (d) => d * dotsWidth)
    .attr('cy', maxRadius)
    .attr('r', (d) => radiusSizeScaler(d))
    .attr('fill', visPalette.contrast)
    .attr('stroke', visPalette.contrast)
    .attr('stroke-width', strokeWidth);

  addDotLabels(dotLabelsG, width, visPalette, labelMin, labelMax, labelAxis);
};

export const addMeaningDots = (
  keySectionG,
  dotValues,
  visPalette,
  width,
  overrides
) => {
  const font = overrides?.font || DEFAULT_FONT;
  const fontSize =
    overrides?.fontSize || VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE;
  const radius = overrides?.radius || 5;
  const strokeWidth = overrides?.strokeWidth || 1;
  const lineHeight = fontSize + 5; // add some spacing between lines
  const dotsPerLine = overrides?.dotsPerLine || 4;
  /* Leftmost and Rightmost dots will sit centered on the edges of specified width
    adjust this by shrinking width by and adjusting right */
  const dotsG = keySectionG
    .append('g')
    .attr('transform', `translate(${radius},0)`);
  const internalWidth = width - radius * 4;

  /* Functions that work out how many lines down / proportions across to place dot */
  const circleX = function (i) {
    return ((i % dotsPerLine) / dotsPerLine) * internalWidth;
  };
  const circleY = function (i) {
    return Math.floor(i / dotsPerLine) * lineHeight + radius;
  };

  dotsG
    .selectAll('circle')
    .data(dotValues)
    .enter()
    .append('circle')
    .attr('cx', (_, i) => circleX(i))
    .attr('cy', (_, i) => circleY(i))
    .attr('r', radius)
    .attr('fill', (d) => (d?.hollow ? 'transparent' : d.color))
    .attr('fill-opacity', (d) => d.opacity)
    .attr('stroke', (d) => d.stroke || d.color)
    .attr('stroke-width', strokeWidth);

  dotsG
    .selectAll('text')
    .data(dotValues)
    .enter()
    .append('text')
    .attr('x', (d, i) => circleX(i) + radius * 2)
    .attr('y', (d, i) => circleY(i) + radius) // align text to bottom of dot
    .attr('font-size', `${fontSize}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('font-family', font)
    .attr('text-anchor', 'start')
    .attr('fill', visPalette.text.info)
    .text((n) => n.name);
};
