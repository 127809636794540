import { every, isArray, isEmpty, isNumber } from 'lodash';

export const sortNumerically = (numberArray) => {
  if (
    isEmpty(numberArray) ||
    !isArray(numberArray) ||
    !every(numberArray, isNumber)
  ) {
    return numberArray;
  }
  return numberArray.sort((a, b) => a - b);
};
