import { useQuery, gql } from '@apollo/client';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import {
  ROUTE_TREE_ANY_ALIGNMENT,
  ROUTE_TREE_ANY_ALIGNMENT_CODES,
  ROUTE_TREE_METRICS,
  ROUTE_TREE_PLAYER_STAT_KEYS,
  ROUTE_TREE_ROLE_OPTIONS,
} from './PlayerRoute.constants';
import { getRouteStatsQuery } from './getRoutePlayerStats';
import {
  formatAPIRouteData,
  getRouteTotals,
} from './PlayerRoute.dataManipulation';
import { GET_PLAYER_STAT_DEFINITIONS } from '../../../utils/hooks/apiQueries/playerStats/getPlayerStatDefinitions';
import {
  API_ALIGNMENT_POSITION_KEYS,
  API_STAT_UNITS,
} from '../../../utils/constants/api';
import { GET_ROUTES_RUN } from './getRoutesRun';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';

/* Want to always return defs, even if ugly */
export const useGetRouteStatDefinitions = () => {
  const backupDefinitions = ROUTE_TREE_METRICS.map((metric) => ({
    ...metric,
    description: metric.key,
    prettyName: metric.key,
    units: API_STAT_UNITS.RATE,
  }));
  const { data, loading, error } = useQuery(GET_PLAYER_STAT_DEFINITIONS);

  if (loading) {
    return { loading, statDefinitions: backupDefinitions };
  }

  if (error) {
    console.error(ERROR_CODES.USE_GET_ROUTE_STAT_DEFINITIONS, error);
    return {
      loading: false,
      error,
      statDefinitions: backupDefinitions,
    };
  }

  // use definitions to upgrade metric info
  const statDefinitions = ROUTE_TREE_METRICS.map((metric) => {
    const apiStatDef = data.playerStatDefinitions.find(
      (statDefinition) => statDefinition.name === metric.key
    );
    if (apiStatDef) {
      return {
        ...metric,
        abbrev: apiStatDef.abbrev,
        description: apiStatDef.description,
        prettyName: apiStatDef.prettyName,
        units: apiStatDef.units,
      };
    }
    return metric;
  });

  return {
    loading: false,
    statDefinitions,
  };
};

export const useGetRoutePlayerStats = (apiPositionCodes) => {
  const playerStatsGQLString = getRouteStatsQuery(ROUTE_TREE_PLAYER_STAT_KEYS);

  const { data, loading, error } = useQuery(gql(playerStatsGQLString), {
    variables: {
      specificPositions: apiPositionCodes,
    },
  });

  if (loading) {
    return { loading, routesData: [] };
  }
  if (error) {
    console.error(ERROR_CODES.USE_GET_ROUTE_PLAYER_STATS, error);
    return {
      error,
      loading,
      routesData: [],
    };
  }

  const rawData = data?.playerStats?.items;
  const formattedData = formatAPIRouteData(rawData);
  return {
    routesData: formattedData,
    loading: false,
  };
};

export const useGetAlignmentPlaysDrop = () => {
  const routeTreeAlignmentOption = ROUTE_TREE_ROLE_OPTIONS;

  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(GET_ROUTES_RUN, {
    variables: {
      specificPositions: ROUTE_TREE_ANY_ALIGNMENT_CODES,
    },
  });

  if (isLoading) {
    return {
      loading: true,
    };
  }

  if (error) {
    console.error(ERROR_CODES.USE_GET_ROUTE_ALIGNMENT_PLAYS, error);
    return {
      loading: false,
      error,
    };
  }

  let updatedAlignmentOptions;
  if (data) {
    const dataMap = {
      [ROUTE_TREE_ANY_ALIGNMENT]: getRouteTotals(data?.playerStats?.items)
        .totalRoutes,
      [API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER]:
        data?.playerStats?.items.find((item) => item.playPosition === 'WR')
          ?.routesRun || 0,
      [API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER]:
        data?.playerStats?.items.find((item) => item.playPosition === 'SR')
          ?.routesRun || 0,
      [API_ALIGNMENT_POSITION_KEYS.TIGHT_END]:
        data?.playerStats?.items.find((item) => item.playPosition === 'TE')
          ?.routesRun || 0,
    };
    updatedAlignmentOptions = routeTreeAlignmentOption.map((opt) => ({
      value: opt.value,
      label: `${ALIGNMENT_POSITIONS[opt.value]?.name || 'All'} (${
        dataMap[opt.value]
      })`,
    }));
  }

  return {
    loading: false,
    data: updatedAlignmentOptions,
  };
};
