import { gql } from '@apollo/client';

export const GET_TEAM_STAT_CATEGORIES = gql`
  query GetTeamStatCategories {
    teamStatCategories {
      name
      subcategories {
        name
        stats
      }
    }
  }
`;
