export const TEAM_RADAR_CHART_OPTIONS = [
  {
    label: 'Team Radar',
    value: 0,
    variant: 'Profile',
  },
  {
    label: 'League Avg. Comparison',
    value: 1,
    variant: 'Players',
  },
];
