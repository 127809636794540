import { styled } from 'styled-components';

export const PhaseContainer = styled.div`
  h3 {
    ${({ theme }) => theme.typography.headingSmall}
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
    margin-bottom: 0.5rem;
  }

  .fields {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(
      ${({ $numOfGames }) => Math.min(6, $numOfGames)},
      1.3125rem
    );
    grid-auto-flow: column;
    gap: 0.5rem;
    max-height: 10.625rem;
  }

  hr {
    margin: 1.25rem 0.3125rem !important;
  }
`;

export const CustomLabel = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colours.ink.primary.main};

  > span {
    border: 0 !important;
    background: transparent !important;
  }

  .round {
    display: flex !important;
    justify-content: center;
    width: 2rem;
    ${({ theme }) => theme.typography.bodyMedium}
  }

  .teams {
    width: 5.3125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ theme }) => theme.typography.bodyMedium}
  }

  .date {
    width: 2.1875rem;
    ${({ theme }) => theme.typography.bodySmall}
  }
`;

PhaseContainer.displayName = 'PhaseContainer';
CustomLabel.displayName = 'CustomLabel';
