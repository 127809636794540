/* 
    This file describes the colors it is safe to use
    Each color is tagged as safe on dark, and safe on light
*/
export const SAFE_COLORS = [
  /* Grays  */
  { hex: '#000000', dark: false, light: true, grayscale: true },
  { hex: '#333333', dark: false, light: true, grayscale: true },
  { hex: '#666666', dark: true, light: true, grayscale: true },
  { hex: '#999999', dark: true, light: true, grayscale: true },
  { hex: '#aaaaaa', dark: true, light: true, grayscale: true },
  { hex: '#cccccc', dark: true, light: true, grayscale: true },
  { hex: '#eeeeee', dark: true, light: false, grayscale: true },
  { hex: '#ffffff', dark: true, light: false, grayscale: true },
  { hex: '#708090', dark: true, light: true, grayscale: false },
  { hex: '#869397', dark: true, light: true, grayscale: false }, // dallas cowboys off grey
  /* Reds  */
  { hex: '#800000', dark: true, light: true },
  { hex: '#841617', dark: true, light: true }, // Oklahoma Sooners
  { hex: '#9f203f', dark: true, light: true },
  { hex: '#b22222', dark: true, light: true },
  { hex: '#d50000', dark: true, light: true },
  { hex: '#ff0000', dark: true, light: true },
  { hex: '#e03a3e', dark: true, light: true }, // Maryland
  { hex: '#f08080', dark: true, light: true },
  { hex: '#650000', dark: true, light: true },
  { hex: '#5A1414', dark: true, light: true }, // Washington Commanders Maroon
  { hex: '#500000', dark: false, light: true }, // Texan A&M Maroon
  /* Oranges  */
  { hex: '#ff7f50', dark: true, light: true },
  { hex: '#ff6347', dark: true, light: true },
  { hex: '#ff6000', dark: true, light: true },
  { hex: '#ff8c00', dark: true, light: true },
  { hex: '#ffa500', dark: true, light: true },
  { hex: '#C83803', dark: true, light: true },
  /* Yellows  */
  { hex: '#FDF9D8', dark: true, light: true }, // Oklahoma Sooners
  { hex: '#fff897', dark: true, light: true },
  { hex: '#ffe141', dark: true, light: true },
  { hex: '#f7d000', dark: true, light: true },
  { hex: '#bf9108', dark: true, light: true },
  { hex: '#cebe18', dark: true, light: true },
  { hex: '#9e7e38', dark: true, light: true }, // wake forest gold
  /* Lime / Olive  */
  { hex: '#daa520', dark: true, light: true },
  { hex: '#9acd32', dark: true, light: true },
  { hex: '#808000', dark: true, light: true },
  { hex: '#556b2f', dark: true, light: true },
  /* Greens  */
  { hex: '#083c1f', dark: false, light: true },
  { hex: '#124d36', dark: true, light: true },
  { hex: '#006400', dark: true, light: true },
  { hex: '#008000', dark: true, light: true },
  { hex: '#009A44', dark: true, light: true },
  { hex: '#32cd32', dark: true, light: true },
  { hex: '#a1ee2c', dark: true, light: true },
  { hex: '#00f04f', dark: true, light: true },
  { hex: '#006341', dark: true, light: true },
  /* Cyan / Turquoise  */
  { hex: '#98fff5', dark: true, light: false },
  { hex: '#004c54', dark: true, light: true }, // philli eagles
  { hex: '#40e0d0', dark: true, light: true },
  { hex: '#00ced1', dark: true, light: true },
  { hex: '#66cdaa', dark: true, light: true },
  { hex: '#008080', dark: true, light: true },
  { hex: '#17375c', dark: true, light: true },
  /* Blues */
  { hex: '#0d2042', dark: false, light: true },
  { hex: '#12254d', dark: true, light: true },
  { hex: '#191970', dark: true, light: true },
  { hex: '#35228b', dark: true, light: true },
  { hex: '#22348b', dark: true, light: true },
  { hex: '#00008b', dark: true, light: true },
  { hex: '#0000cd', dark: true, light: true },
  { hex: '#1e90ff', dark: true, light: true },
  { hex: '#87ceeb', dark: true, light: true },
  { hex: '#a6e1e1', dark: true, light: true },
  { hex: '#80c4de', dark: true, light: true },
  { hex: '#6495ed', dark: true, light: true },
  { hex: '#4682b4', dark: true, light: true },
  { hex: '#0b539f', dark: true, light: true },
  /* Purples */
  { hex: '#9370db', dark: true, light: true },
  { hex: '#8a2be2', dark: true, light: true },
  { hex: '#9400d3', dark: true, light: true },
  { hex: '#800080', dark: true, light: true },
  { hex: '#4b0082', dark: false, light: true },
  { hex: '#c71585', dark: true, light: true },
  { hex: '#da70d6', dark: true, light: true },
  { hex: '#80003d', dark: true, light: true },
  /* Pinks */
  { hex: '#ff69b4', dark: true, light: true },
  /* Creams / Browns */
  { hex: '#deb887', dark: true, light: true },
  { hex: '#B3995D', dark: true, light: true },
  { hex: '#a0522d', dark: true, light: true },
  { hex: '#763b10', dark: true, light: true },
  { hex: '#50270a', dark: true, light: true },
  { hex: '#341601', dark: false, light: true },
];

export const SAFE_COLORS_DARK_MODE = SAFE_COLORS.filter(
  (d) => d.dark && !d.grayscale
).map((c) => c.hex);
export const SAFE_COLORS_LIGHT_MODE = SAFE_COLORS.filter(
  (d) => d.light && !d.grayscale
).map((c) => c.hex);
export const SAFE_GRAYS_DARK_MODE = SAFE_COLORS.filter(
  (d) => d.dark && d.grayscale
).map((c) => c.hex);
export const SAFE_GRAYS_LIGHT_MODE = SAFE_COLORS.filter(
  (d) => d.light && d.grayscale
).map((c) => c.hex);

export const WHITELIST_COLORS = [
  '#869397', // dallas cowboys grey
];
