import { useQuery } from '@apollo/client';
import { sumBy } from 'lodash';
import { GET_FORMATION_SUMMARY_TEAM_STATS } from './getFormationSummaryTeamStats';
import {
  FORMATION_SUMMARY_STAT_NAMES,
  FORMATION_SUMMARY_STATS_CONFIG,
  PLAY_COUNT,
} from './TeamFormationSummary.constants';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';

/**
 * custom hook to fetch the formation summary data and enrich it depending on grouping and comparison
 */
export const useFormationSummaryStats = (
  teamId,
  groupBy,
  statDistributions,
  isTeamMode,
  skip
) => {
  // team stats
  const {
    data: teamData,
    error: teamDataError,
    loading: teamDataLoading,
  } = useQuery(GET_FORMATION_SUMMARY_TEAM_STATS, {
    variables: {
      offenseTeamIds: [teamId],
      groupBy,
    },
    skip,
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  // league stats
  const {
    data: leagueData,
    error: leagueDataError,
    loading: leagueDataLoading,
  } = useQuery(GET_FORMATION_SUMMARY_TEAM_STATS, {
    variables: {
      groupBy,
    },
    skip,
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const loading = teamDataLoading || leagueDataLoading || !statDistributions;

  if (loading || skip) {
    return { loading };
  }

  const error = teamDataError || leagueDataError;
  if (error) {
    console.error(ERROR_CODES.USE_FORMATION_SUMMARY_STATS, error);
    return { error, loading: false };
  }

  const teamStats = teamData.teamStats.items;
  const leagueStats = leagueData.teamStats.items;

  const teamPlayCount = sumBy(teamStats, PLAY_COUNT);
  const leaguePlayCount = sumBy(leagueStats, PLAY_COUNT);

  // "POTHER" is just bad data, so we want to filter that out
  const filteredTeamData = teamStats.filter(
    (stat) => stat.offensePersonnel !== 'POTHER'
  );
  const filteredLeagueData = leagueStats.filter(
    (stat) => stat.offensePersonnel !== 'POTHER'
  );

  const enrichedTeamStats = filteredTeamData.map((teamStat) => {
    const leagueStat = filteredLeagueData.find(
      (s) =>
        s.formationName === teamStat.formationName &&
        s.offensePersonnel === teamStat.offensePersonnel
    );

    const teamPersonnelPlayCount = filteredTeamData
      .filter((s) => s.offensePersonnel === teamStat.offensePersonnel)
      .reduce((acc, s) => acc + s.playCount, 0);
    const leaguePersonnelPlayCount = filteredLeagueData
      .filter((s) => s.offensePersonnel === teamStat.offensePersonnel)
      .reduce((acc, s) => acc + s.playCount, 0);

    const teamPersonnelPlayRate = teamStat.playCount / teamPersonnelPlayCount;
    const leaguePersonnelPlayRate =
      leagueStat?.playCount / leaguePersonnelPlayCount;
    const personnelPlayRate = isTeamMode
      ? teamPersonnelPlayRate
      : teamPersonnelPlayRate - leaguePersonnelPlayRate;

    const teamTotalPlayRate = teamStat.playCount / teamPlayCount;
    const leagueTotalPlayRate = leagueStat?.playCount / leaguePlayCount;
    const totalPlayRate = isTeamMode
      ? teamTotalPlayRate
      : teamTotalPlayRate - leagueTotalPlayRate;

    const formattedStat = FORMATION_SUMMARY_STAT_NAMES.reduce((acc, name) => {
      const teamValue = teamStat[name];
      const leagueValue = statDistributions.find((s) => s.name === name)?.mean;
      const isValidStat =
        typeof teamValue === 'number' && typeof leagueValue === 'number';

      const isDeltaStat = FORMATION_SUMMARY_STATS_CONFIG[name]?.isDeltaStat;
      const deltaValue = isValidStat ? teamValue - leagueValue : null;

      const value = isTeamMode || !isDeltaStat ? teamValue : deltaValue;

      return {
        ...acc,
        [name]: value,
      };
    }, {});

    const withPersonnelPlayRate =
      groupBy.includes('FORMATION') && groupBy.includes('OFFENSE_PERSONNEL');

    return {
      ...formattedStat,
      // stats with no formation name are valid plays that could not be assigned to any known formation
      formationName: teamStat.formationName || 'Other',
      offensePersonnel: teamStat.offensePersonnel,
      personnelPlayRate: withPersonnelPlayRate ? personnelPlayRate : null,
      totalPlayRate,
    };
  });

  return { data: enrichedTeamStats, loading };
};
