import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import {
  PlayerComparisonTableHeader,
  PlayerComparisonTitleColumn,
  PlayerComparisonRadarHeader,
  PlayerComparisonIndicatorWrapper,
  PlayerComparisonScrollArea,
} from './PlayerComparison.styles';
import {
  COMPARISON_TABLE_COLS,
  COMPARISON_WEIGHT_OPTIONS,
  COMPARISON_NUMBER_OF_TILES,
  COMPARE_SIMILAR,
  COMPARE_TILE_DATA,
  COMPARE_SELECTED,
  COMPARE_TILE_ADD,
  COMPARE_TILE_EMPTY,
  RADAR_DISPLAY_MODE,
  COMPARISON_DEFAULT_MIN_PLAYS,
  COMPARE_TILE_ERROR,
  COMPARE_TILE_DEFAULT_STATE,
} from './PlayerComparison.constants';
import PlayerComparisonTile from './PlayerComparisonTile';
import BasicTable from '../../../components/Table/BasicTable';
import { getRadarComparisonData } from './PlayerComparison.dataManipulation';
import { RatioIndicator } from './RatioIndicator/RatioIndicator';
import Dimmer from '../../../components/Dimmer/Dimmer';
import BannerError from '../../../components/Banners/Banner.Error';
import BannerInfo from '../../../components/Banners/Banner.Info';
import PlayerComparisonTileHeader from './PlayerComparisonTileHeader';
import {
  fixArrayLength,
  getComparativeTileState,
} from './PlayerComparison.helpers';
import { mf_TeamDetails } from '../../../apollo';
import { radarTemplatePropTypes } from '../../../visualisations/RadarChart/RadarChart.PropTypes';
import { playerDetailsPropTypes } from '../../../apollo/index.data.propTypes';
import { playerComparisonPropTypes } from './getPlayerComparison.PropTypes';

const PlayerComparisonArea = ({
  playerDetails,
  comparisonData,
  templateConfigPerf,
  templateConfigTrait,
  radarWeight,
  hasError,
  isLoading,
  comparisonMode,
  displayMode,
  idSuffix,
  isInteractive,
  ratioLabelSuffix,
  comparisonPlayerIds,
  setComparisonPlayerIds,
  playerLinkComparison,
  minPlays,
  renderData,
}) => {
  // player details
  const playerName = playerDetails?.name;
  // team details
  const teamDetails = useReactiveVar(mf_TeamDetails);
  // display mode
  const { TRAIT, PERFORMANCE, BOTH } = RADAR_DISPLAY_MODE;
  const showTrait = displayMode === TRAIT || displayMode === BOTH;
  const showPerformance = displayMode === PERFORMANCE || displayMode === BOTH;
  // the state of the tiles in comparison mode
  const [comparativeTileState, setComparativeTileState] = useState(
    COMPARE_TILE_DEFAULT_STATE
  );
  // the display layer of comparisons
  const [displayTilesCollection, setDisplayTilesCollection] = useState(
    comparisonData?.playerComparison?.comparativePlayers || [0, 0, 0, 0, 0]
  );

  // sticky header scrolling
  const scrollingContent = useRef(null);
  const scrollingHeader = useRef(null);
  const handleScroll = (scrollTarget) => {
    requestAnimationFrame(() => {
      scrollingHeader.current.style.transform = `translateX(-${scrollTarget.target.scrollLeft}px)`;
    });
  };
  useEffect(() => {
    const scrollingDiv = scrollingContent.current;
    if (isInteractive) {
      scrollingDiv.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isInteractive) {
        scrollingDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (renderData) {
      if (comparisonMode === COMPARE_SELECTED) {
        if (comparativeTileState[0] === COMPARE_TILE_ADD && isInteractive) {
          // its a first load so ignore the data
          // !isInteractive is for exports
        } else {
          // load data, use empty array if data unavailable
          const comparativePlayersData = comparisonData?.playerComparison
            ?.comparativePlayers || [0, 0, 0, 0, 0];

          // preserve order added in the UI
          const sortedObjectsArray = [...comparativePlayersData].sort(
            (a, b) =>
              comparisonPlayerIds.indexOf(a.playerId) -
              comparisonPlayerIds.indexOf(b.playerId)
          );
          // if there are less than 5 (COMPARISON_NUMBER_OF_TILES) players, fill the data array with empty tiles
          if (
            comparativePlayersData.length <= COMPARISON_NUMBER_OF_TILES &&
            isInteractive
          ) {
            setDisplayTilesCollection(
              fixArrayLength(
                [...sortedObjectsArray],
                COMPARISON_NUMBER_OF_TILES
              )
            );
          } else {
            setDisplayTilesCollection(sortedObjectsArray);
          }
          // set display state, else fallback to default
          const comparativePlayersState =
            comparisonData?.playerComparison?.comparativePlayers?.map(
              (player) => {
                if (player?.playerId && player?.hasError) {
                  return COMPARE_TILE_ERROR;
                }
                if (player?.playerId) {
                  return COMPARE_TILE_DATA;
                }
                return COMPARE_TILE_EMPTY;
              }
            ) || COMPARE_TILE_DEFAULT_STATE;

          // ensure the first tile after the last data tile is an add tile
          const newArray = fixArrayLength(
            [...comparativePlayersState],
            COMPARISON_NUMBER_OF_TILES
          );
          if (comparativePlayersState.length <= COMPARISON_NUMBER_OF_TILES) {
            newArray[comparativePlayersState.length] = COMPARE_TILE_ADD;
          }
          setComparativeTileState(newArray);
        }
      } else {
        setDisplayTilesCollection(
          comparisonData?.playerComparison?.comparativePlayers || [
            COMPARE_TILE_DATA,
            COMPARE_TILE_DATA,
            COMPARE_TILE_DATA,
            COMPARE_TILE_DATA,
            COMPARE_TILE_DATA,
          ]
        );
      }
    }
  }, [comparisonData, renderData]);

  const onAdd = (index) => {
    const newArray = [...comparativeTileState];
    // don't allow adding if the previous tile is empty
    if (
      (index > 0 && newArray[index - 1] !== COMPARE_TILE_EMPTY) ||
      index === 0
    ) {
      newArray[index] = COMPARE_TILE_EMPTY;
      newArray[index + 1] = COMPARE_TILE_ADD;
    }
    setComparativeTileState(newArray);
  };

  const onClose = (index) => {
    // update state
    const newArray = [...comparativeTileState];
    newArray.splice(index, 1);
    setComparativeTileState(
      fixArrayLength(newArray, COMPARISON_NUMBER_OF_TILES)
    );
    // update IDs for query
    const copyPlayerIdsArray = [...comparisonPlayerIds];
    copyPlayerIdsArray.splice(index, 1);
    setComparisonPlayerIds(copyPlayerIdsArray);
  };

  // result handler from LocalSearch
  const onResult = (resultPlayerId, index) => {
    const copyPlayerIdsArray = [...comparisonPlayerIds];
    copyPlayerIdsArray[index] = resultPlayerId;
    setComparisonPlayerIds(copyPlayerIdsArray);
  };

  return (
    <>
      <PlayerComparisonTileHeader
        generalData={{
          plays: comparisonData?.playerComparison?.basePlayer?.plays,
          similarity: '',
        }}
        playerComparisonData={displayTilesCollection}
        playerDetails={playerDetails}
        teamDetails={teamDetails}
        scrollingHeaderRef={scrollingHeader}
        comparisonMode={comparisonMode}
        isInteractive={isInteractive}
        handleAdd={onAdd}
        handleClose={onClose}
        handleResult={onResult}
        comparativeTileState={comparativeTileState}
        playerLinkComparison={playerLinkComparison}
        displayMode={displayMode}
        minPlays={minPlays}
        radarWeight={radarWeight}
        renderData={renderData}
        comparisonPlayerIds={comparisonPlayerIds}
      />
      <PlayerComparisonScrollArea>
        <div>
          <PlayerComparisonTitleColumn>
            <div style={{ display: showPerformance ? 'block' : 'none' }}>
              <PlayerComparisonTableHeader>
                <PlayerComparisonIndicatorWrapper
                  style={{ position: isInteractive ? 'absolute' : 'relative' }}
                >
                  Performance
                  <RatioIndicator
                    ratio={
                      COMPARISON_WEIGHT_OPTIONS.find(
                        (val) => val.value === radarWeight
                      ).performance
                    }
                    labelSuffix={ratioLabelSuffix}
                  />
                </PlayerComparisonIndicatorWrapper>
              </PlayerComparisonTableHeader>
              <PlayerComparisonRadarHeader>Radar</PlayerComparisonRadarHeader>
              <BasicTable
                data={templateConfigPerf?.map((perf) => ({
                  statValue: perf.name,
                }))}
                headers={COMPARISON_TABLE_COLS}
                showHeader={false}
                bodytdHeight="2rem"
              />
            </div>
            <div style={{ display: showTrait ? 'block' : 'none' }}>
              <PlayerComparisonTableHeader>
                <PlayerComparisonIndicatorWrapper
                  style={{ position: isInteractive ? 'absolute' : 'relative' }}
                >
                  Trait
                  <RatioIndicator
                    ratio={
                      COMPARISON_WEIGHT_OPTIONS.find(
                        (val) => val.value === radarWeight
                      ).trait
                    }
                    labelSuffix={ratioLabelSuffix}
                  />
                </PlayerComparisonIndicatorWrapper>
              </PlayerComparisonTableHeader>
              <PlayerComparisonRadarHeader>Radar</PlayerComparisonRadarHeader>
              <BasicTable
                data={templateConfigTrait?.map((trait) => ({
                  statValue: trait.name,
                }))}
                headers={COMPARISON_TABLE_COLS}
                showHeader={false}
                bodytdHeight="2rem"
              />
            </div>
          </PlayerComparisonTitleColumn>

          <PlayerComparisonTile
            idSuffix={idSuffix}
            playerName={playerName}
            generalData={{
              plays: comparisonData?.playerComparison?.basePlayer?.plays,
              similarity: '',
            }}
            showTrait={showTrait}
            radarTraitData={{
              templateConfigTrait,
              radarTraitStats: getRadarComparisonData(
                comparisonData?.playerComparison?.basePlayer?.stats,
                templateConfigTrait
              ),
              teamColors: null,
            }}
            showPerformance={showPerformance}
            radarPerformanceData={{
              templateConfigPerf,
              radarPerfStats: getRadarComparisonData(
                comparisonData?.playerComparison?.basePlayer?.stats,
                templateConfigPerf
              ),
              teamColors: null,
            }}
            showTableHeader
            padding={0}
            highlight
            tileState={
              isLoading || hasError ? COMPARE_TILE_EMPTY : COMPARE_TILE_DATA
            }
            flipData
          />
        </div>
        <div
          ref={scrollingContent}
          style={{
            width: '100%',
            overflowX: isInteractive ? 'scroll' : 'hidden',
          }}
        >
          {hasError && !isLoading && (
            <Dimmer active>
              <BannerError
                margin={
                  showPerformance && showTrait
                    ? '-30rem -32rem 0 0'
                    : '-10rem -32rem 0 0'
                }
                message=" There has been an error. Please contact support."
              />
            </Dimmer>
          )}

          {comparisonMode === COMPARE_SIMILAR &&
            comparisonData?.playerComparison?.totalComparisons === 0 && (
              <Dimmer active>
                <BannerInfo
                  margin={
                    showPerformance && showTrait
                      ? '-30rem -32rem 0 0'
                      : '-10rem -32rem 0 0'
                  }
                  title="No similar players Found"
                  // eslint-disable-next-line max-len
                  message="Consider adjusting your filters or changing your position template for a broader range of results."
                />
              </Dimmer>
            )}

          {renderData &&
            displayTilesCollection.map((player, index) => (
              <PlayerComparisonTile
                key={player?.playerName || `blank-tile-${index}`}
                idSuffix={idSuffix}
                playerName={player?.playerName}
                generalData={{
                  plays: player?.plays,
                  similarity: player?.similarity,
                }}
                showTrait={showTrait}
                radarTraitData={{
                  templateConfigTrait,
                  radarTraitStats: getRadarComparisonData(
                    player?.stats,
                    templateConfigTrait
                  ),
                  teamColors: null,
                }}
                radarTraitComparisonData={getRadarComparisonData(
                  comparisonData?.playerComparison?.basePlayer?.stats,
                  templateConfigTrait
                )}
                showPerformance={showPerformance}
                radarPerformanceData={{
                  templateConfigPerf,
                  radarPerfStats: getRadarComparisonData(
                    player?.stats,
                    templateConfigPerf
                  ),
                  teamColors: null,
                }}
                radarPerformanceComparisonData={getRadarComparisonData(
                  comparisonData?.playerComparison?.basePlayer?.stats,
                  templateConfigPerf
                )}
                padding={0}
                tileState={getComparativeTileState(
                  comparisonMode,
                  displayTilesCollection,
                  comparativeTileState,
                  index
                )}
              />
            ))}
        </div>
      </PlayerComparisonScrollArea>
    </>
  );
};

PlayerComparisonArea.propTypes = {
  playerDetails: playerDetailsPropTypes,
  comparisonData: playerComparisonPropTypes,
  templateConfigPerf: radarTemplatePropTypes,
  templateConfigTrait: radarTemplatePropTypes,
  radarWeight: PropTypes.oneOf(
    COMPARISON_WEIGHT_OPTIONS.map((val) => val.value)
  ).isRequired,
  comparisonMode: PropTypes.oneOf([COMPARE_SIMILAR, COMPARE_SELECTED]),
  displayMode: PropTypes.oneOf([
    RADAR_DISPLAY_MODE.TRAIT,
    RADAR_DISPLAY_MODE.PERFORMANCE,
    RADAR_DISPLAY_MODE.BOTH,
  ]),
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  // add to radars to prevent id clashes
  idSuffix: PropTypes.string,
  // turn scrolling on/off (useful for export) and remove links
  isInteractive: PropTypes.bool,
  // prop drilled to RatioIndicator
  ratioLabelSuffix: PropTypes.string,
  // parent array
  comparisonPlayerIds: PropTypes.arrayOf(PropTypes.number),
  // parent function
  setComparisonPlayerIds: PropTypes.func,
  // link player to comparison page or not
  playerLinkComparison: PropTypes.bool,
  // minimum number of plays to compare
  minPlays: PropTypes.number,
  // ensure data is ready before rendering
  renderData: PropTypes.bool,
};

PlayerComparisonArea.defaultProps = {
  playerDetails: {},
  comparisonData: {},
  templateConfigPerf: [],
  templateConfigTrait: [],
  comparisonMode: COMPARE_SIMILAR,
  displayMode: RADAR_DISPLAY_MODE.BOTH,
  hasError: false,
  isLoading: false,
  idSuffix: '',
  isInteractive: true,
  ratioLabelSuffix: 'Weight',
  comparisonPlayerIds: [],
  setComparisonPlayerIds: () => {},
  playerLinkComparison: true,
  minPlays: COMPARISON_DEFAULT_MIN_PLAYS,
  renderData: false,
};

export default PlayerComparisonArea;
