import { clamp } from 'lodash';
import { addInfoZone } from './Chart.Info';
import { FORMATION_MEASURES_OFF } from './DataManipulation';
import { addEventFF } from './FFChart.drawing';

const getZoneWidth = function (playZoneSizes) {
  return (
    playZoneSizes.ff.margin.left +
    playZoneSizes.ff.margin.right +
    playZoneSizes.ff.width
  );
};
const getInfoZoneHeight = function (playZoneSizes) {
  return (
    playZoneSizes.info.margin.top +
    playZoneSizes.info.margin.bottom +
    playZoneSizes.info.height
  );
};
const getZoneHeight = function (playZoneSizes) {
  return (
    playZoneSizes.ff.margin.top +
    playZoneSizes.ff.margin.bottom +
    playZoneSizes.ff.height +
    getInfoZoneHeight(playZoneSizes)
  );
};
const getZoneSpacers = function (playZoneSizes, row) {
  return clamp(row, 0, 99) * playZoneSizes.spacer;
};

const addFFZone = function (
  parentG,
  ffsData,
  playZoneSizes,
  selectedEvent,
  setSelectedEvent,
  rotationOption,
  zoomOption,
  focusOptionX,
  focusOptionY,
  showJerseys,
  fieldOverrides,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  showBall,
  playInfo,
  visPalette,
  id,
  isInteractive
) {
  const VIEWER_CLIP_PATH_NAME = `${id}-ff-viewer-clip-path`;
  parentG.selectAll('g').remove();
  parentG.selectAll('defs').remove();

  const zoneWidth = getZoneWidth(playZoneSizes);
  const zoneHeight = getZoneHeight(playZoneSizes);
  const infoZoneHeight = getInfoZoneHeight(playZoneSizes);
  const ffZoneHeight = zoneHeight - infoZoneHeight;

  // add the clip path that all children will use to limit the field view
  parentG
    .append('defs')
    .append('clipPath')
    .attr('id', VIEWER_CLIP_PATH_NAME)
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', playZoneSizes.ff.width)
    .attr('height', playZoneSizes.ff.height);

  ffsData.forEach((ffEvent) => {
    const zoneG = parentG.append('g').attr('id', `${id}-${ffEvent.id}`);
    const spacersHeight = getZoneSpacers(playZoneSizes, ffEvent.rowCol.row);
    zoneG.attr(
      'transform',
      `translate(${ffEvent.rowCol.col * zoneWidth} ${
        ffEvent.rowCol.row * zoneHeight + spacersHeight
      })`
    );

    const infoG = zoneG
      .append('g')
      .attr(
        'transform',
        `translate(${playZoneSizes.info.margin.left},${playZoneSizes.info.margin.top})`
      );
    addInfoZone(infoG, ffEvent, playZoneSizes.info.width, playInfo, visPalette);

    if (ffEvent.id === selectedEvent) {
      const ffHighlightG = zoneG
        .append('g')
        .attr('transform', `translate(0,${getInfoZoneHeight(playZoneSizes)})`);
      ffHighlightG
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', zoneWidth)
        .attr(
          'height',
          playZoneSizes.ff.height +
            playZoneSizes.ff.margin.top +
            playZoneSizes.ff.margin.bottom
        )
        .attr('fill', visPalette.background.selected)
        .attr('stroke', visPalette.background.selectedBorder)
        .attr('stroke-width', 5);
    }
    const ffG = zoneG
      .append('g')
      .attr('id', `${id}-event-window-${ffEvent.id}`)
      .attr('transform', `translate(0,${infoZoneHeight})`);

    addEventFF(
      ffG,
      fieldOverrides,
      visPalette,
      ffEvent,
      playZoneSizes.ff,
      rotationOption,
      zoomOption,
      focusOptionX,
      focusOptionY,
      showJerseys,
      FORMATION_MEASURES_OFF,
      selectedOffensePlayerId,
      selectedDefensePlayerId,
      showBall,
      id
    );

    /* border box */
    ffG
      .append('rect')
      .attr('x', playZoneSizes.ff.margin.left - 1)
      .attr('y', playZoneSizes.ff.margin.top - 1)
      .attr('width', playZoneSizes.ff.width + 2)
      .attr('height', playZoneSizes.ff.height + 2)
      .attr('fill', 'none')
      .attr('stroke', fieldOverrides.visPalette.border)
      .attr('stroke-width', 1);

    /* invisible click area sits above the ff */
    zoneG
      .append('rect')
      .attr('x', 0)
      .attr('y', infoZoneHeight)
      .attr('width', zoneWidth)
      .attr('height', ffZoneHeight)
      .attr('class', 'clickable')
      .on('click', () => {
        if (isInteractive) {
          if (ffEvent.id === selectedEvent) {
            setSelectedEvent(null);
          } else {
            setSelectedEvent(ffEvent.id);
          }
        }
      });
  });
};

export { addFFZone, getZoneWidth, getZoneHeight, getZoneSpacers };
