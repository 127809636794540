import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getWidgetValue } from '../PlayFinderFiltersConfig.dataManipulation';

import WizardAccordionWrapper from './WizardAccordionWrapper';
import PopupFilterWrapper from './PopupFilterWrapper';
import { PLAY_FINDER_WIDGET_TYPES } from '../../../../utils/constants/api';

const PlayFinderFiltersWrapper = ({
  filter,
  onWidgetCallback,
  type,
  handleRemoveFilter,
}) => {
  const { label, units, widgetType, widgetArgs, filterId } = filter;
  const [rangeValues, setRangeValues] = useState([]);

  useEffect(() => {
    if (widgetArgs?.min !== undefined && widgetArgs?.max !== undefined) {
      setRangeValues([
        widgetArgs?.selected?.[0] ?? widgetArgs.min,
        widgetArgs?.selected?.[1] ?? widgetArgs.max,
      ]);
    }
  }, [widgetArgs]);

  const titleValue = getWidgetValue(units, widgetType, {
    ...widgetArgs,
    selected: rangeValues,
  });
  const title =
    widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE
      ? `${label}${titleValue}`
      : label;
  // Common widget props
  const widgetProps = {
    rangeValues,
    setRangeValues,
    filterId,
    label,
    widgetType,
    widgetArgs,
    handleCallback: onWidgetCallback,
  };
  const WrapperType = {
    SIDE_ACCORDION_FILTER: WizardAccordionWrapper,
    POPUP_FILTER: PopupFilterWrapper,
  };
  const WrapperComponent = WrapperType[type] || <></>;

  return (
    <WrapperComponent
      widgetProps={widgetProps}
      title={title}
      handleRemoveFilter={() => handleRemoveFilter(filter)}
    />
  );
};

export default PlayFinderFiltersWrapper;

PlayFinderFiltersWrapper.propTypes = {
  filter: PropTypes.object.isRequired,
  onWidgetCallback: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['SIDE_ACCORDION_FILTER', 'POPUP_FILTER']).isRequired,
  handleRemoveFilter: PropTypes.func,
};

PlayFinderFiltersWrapper.defaultProps = {
  handleRemoveFilter: () => {},
};
