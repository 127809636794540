// checks whether a given value is numeric (i.e '35.2' => true)
export const isNumericString = (value) => {
  // number are always considered numeric
  if (typeof value === 'number') {
    return true;
  }

  // if it's not a string, it can't be numeric
  if (typeof value !== 'string') {
    return false;
  }

  // the second condition covers empty strings and white space (i.e '' and '   ')
  return !Number.isNaN(Number(value)) && !Number.isNaN(parseFloat(value));
};

// make filenames safe for sensitive OSs
// removing periods and apostrophes
export const escapeDownloadString = (str) =>
  str ? str.replace(/\.|'|"/g, '') : '';

export const formatPackageLabel = (value) =>
  value.replace('P', '').replaceAll('_', '-');
