import React, { useState } from 'react';
import { orderBy } from 'lodash';
import Tile from '../../../components/Tile/Tile';
import TabbedTable from '../../../components/Tables/TabbedTable/TabbedTable';
import DATA_DICTIONARY_CONFIG from './DataDictionaryConfig/DataDictionary.config';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;

const DataDictionary = () => {
  const [sortDirection, setSortDirection] = useState(ASCENDING);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const handleSort = () => {
    setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
  };

  const sortedTableTabsConfig = DATA_DICTIONARY_CONFIG.map((table) => ({
    ...table,
    tableConfig: {
      ...table.tableConfig,
      rows: orderBy(table.tableConfig.rows, 'field', sortDirection),
    },
  }));

  const filterTable = (rows, field) =>
    searchValue
      ? rows?.filter((row) =>
          row[field].toLowerCase().includes(searchValue.toLowerCase())
        )
      : rows;

  const filteredTableTabsConfig = sortedTableTabsConfig.map((table) => ({
    ...table,
    tableConfig: {
      ...table.tableConfig,
      rows: filterTable(
        table.tableConfig.rows,
        table.tableConfig.headers[0].id
      ),
    },
  }));

  return (
    <Tile>
      <h2>Data Dictionary</h2>
      <TabbedTable
        tableTabsConfig={filteredTableTabsConfig}
        handleSort={handleSort}
        isSearchable
        sortBy="field"
        sortDirection={sortDirection}
        searchValue={searchValue}
        onSearchChange={handleSearchChange}
      />
    </Tile>
  );
};

export default DataDictionary;
