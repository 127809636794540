import { keyBy } from 'lodash';
import { API_OL_GAPS } from './api';
import { csIntensity } from '../helpers/colorScales';

const LINE_GAPS_ARRAY = [
  {
    apiCode: [API_OL_GAPS.LEFT_A],
    name: 'Left A',
    index: 0,
  },
  {
    apiCode: [API_OL_GAPS.LEFT_B],
    name: 'Left B',
    index: 1,
  },
  {
    apiCode: [API_OL_GAPS.LEFT_C_CLOSED],
    name: 'Left C Closed',
    index: 2,
  },
  {
    apiCode: [API_OL_GAPS.LEFT_C_OPEN],
    name: 'Left C Open',
    index: 3,
  },
  {
    apiCode: [API_OL_GAPS.LEFT_D],
    name: 'Left D',
    index: 4,
  },
  {
    apiCode: [API_OL_GAPS.RIGHT_A],
    name: 'Right A',
    index: 5,
  },
  {
    apiCode: [API_OL_GAPS.RIGHT_B],
    name: 'Right B',
    index: 6,
  },
  {
    apiCode: [API_OL_GAPS.RIGHT_C_CLOSED],
    name: 'Right C Closed',
    index: 7,
  },
  {
    apiCode: [API_OL_GAPS.RIGHT_C_OPEN],
    name: 'Right C Open',
    index: 8,
  },
  {
    apiCode: [API_OL_GAPS.RIGHT_D],
    name: 'Right D',
    index: 9,
  },
];

export const LINE_GAPS = keyBy(LINE_GAPS_ARRAY, 'apiCode');

export const gapColor = (gapAPIKey, visPalette, isBorder) => {
  if (
    isBorder &&
    (gapAPIKey === API_OL_GAPS.RIGHT_A ||
      gapAPIKey === API_OL_GAPS.RIGHT_B ||
      gapAPIKey === API_OL_GAPS.RIGHT_C_CLOSED ||
      gapAPIKey === API_OL_GAPS.RIGHT_C_OPEN ||
      gapAPIKey === API_OL_GAPS.RIGHT_D)
  ) {
    return visPalette.selectedObject;
  }
  if (gapAPIKey === API_OL_GAPS.LEFT_A || gapAPIKey === API_OL_GAPS.RIGHT_A) {
    return csIntensity(1);
  }

  if (gapAPIKey === API_OL_GAPS.LEFT_B || gapAPIKey === API_OL_GAPS.RIGHT_B) {
    return csIntensity(0.8);
  }

  if (
    gapAPIKey === API_OL_GAPS.LEFT_C_OPEN ||
    gapAPIKey === API_OL_GAPS.RIGHT_C_OPEN
  ) {
    return csIntensity(0.4);
  }

  if (
    gapAPIKey === API_OL_GAPS.LEFT_C_CLOSED ||
    gapAPIKey === API_OL_GAPS.RIGHT_C_CLOSED
  ) {
    return csIntensity(0.6);
  }

  if (gapAPIKey === API_OL_GAPS.LEFT_D || gapAPIKey === API_OL_GAPS.RIGHT_D) {
    return csIntensity(0.2);
  }

  return visPalette.objects.neutral.main;
};
