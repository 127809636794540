import PropTypes from 'prop-types';
import { SCOUT_BIOMETRIC_FILTERS } from './ScoutResults/ScoutResults.constants';
import { API_STAT_UNITS } from '../../utils/constants/api';

const statDefinitionPropTypesObject = {
  // the key will be a player stat id
  // this list is maintained by the API so cannot be fully declared here
  key: PropTypes.string,
  // what to display as the name of the metric
  name: PropTypes.string,
  // what the metric represents
  description: PropTypes.string,
  // shorter fixed lenght name of metric
  abbreviation: PropTypes.string,
  // when low numbers are better (i.e. fumbles / fouls / failures)
  lowerIsBetter: PropTypes.bool,
  // unit Type for formatting
  unitType: PropTypes.oneOf(Object.values(API_STAT_UNITS)),
};
/* 
  An object defining a player stat value
*/
export const statDefinitionProp = PropTypes.shape(
  statDefinitionPropTypesObject
);

/* 
  An object defining a player stat value
  + all data to draw and work the reversible slider
*/
export const playerMetricProp = PropTypes.shape({
  ...statDefinitionPropTypesObject,
  // slider min value (position Percentile 5 value)
  min: PropTypes.number,
  // slider min value (position Percentile 95 value)
  max: PropTypes.number,
  // value to apply as a filter condition
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  // the discrete value increment size of the slider. Will be the range / integer (normally 10)
  sliderStep: PropTypes.number,
});

/* 
An object defining a player biometric and it's selected value(s)
*/
export const biometricProp = PropTypes.shape({
  // which stat this corresponds to
  key: PropTypes.oneOf(Object.keys(SCOUT_BIOMETRIC_FILTERS)),
  // value currently selected
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
  ]),
  // value used in non numerics (i.e. high schools)
  tokens: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
});

/* 
  An object with the details of a filter search result (i.e. for school / city / team)
  Held as an array in the filter definition to populate chips
*/
export const biometricToken = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});
