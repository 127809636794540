import { DATA_DICTIONARY_EVENTS } from './DataDictionary.events';
import { DATA_DICTIONARY_TRACKING_FRAMES } from './DataDictionary.trackingFrames';
import { DATA_DICTIONARY_PLAYS } from './DataDictionary.plays';
import { DATA_DICTIONARY_LINE_BATTLE_EVENTS } from './DataDictionary.lineBattleEvents';
import { DATA_DICTIONARY_LINE_BATTLE_TRACKING_FRAMES } from './DataDictionary.lineBattleTrackingFrames';

const headers = [
  { id: 'field', label: 'Field', isSortable: true },
  { id: 'fieldType', label: 'Field Type' },
  { id: 'fieldValues', label: 'Field Values' },
  { id: 'fieldDescription', label: 'Field Description' },
];

const DATA_DICTIONARY_CONFIG = [
  {
    id: 'events',
    label: 'Events',
    tableConfig: {
      headers,
      rows: DATA_DICTIONARY_EVENTS,
      stickyColumns: 1,
    },
  },
  {
    id: 'tracking-frames',
    label: 'Tracking Frames',
    tableConfig: {
      headers,
      rows: DATA_DICTIONARY_TRACKING_FRAMES,
      stickyColumns: 1,
    },
  },
  {
    id: 'plays',
    label: 'Plays',
    tableConfig: {
      headers,
      rows: DATA_DICTIONARY_PLAYS,
      stickyColumns: 1,
    },
  },
  {
    id: 'line-battle-events',
    label: 'Line Battle Events',
    tableConfig: {
      headers,
      rows: DATA_DICTIONARY_LINE_BATTLE_EVENTS,
      stickyColumns: 1,
    },
  },
  {
    id: 'line-battle-tracking-frames',
    label: 'Line Battle Tracking Frames',
    tableConfig: {
      headers,
      rows: DATA_DICTIONARY_LINE_BATTLE_TRACKING_FRAMES,
      stickyColumns: 1,
    },
  },
];

export default DATA_DICTIONARY_CONFIG;
