import React from 'react';
import PropTypes from 'prop-types';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { Navigate } from 'react-router-dom';

export const GroupOnlyRoute = ({ group, children }) => {
  const { user } = useKitbagAuth();
  if (group && group === user['https://statsbomb.com/accountID']) {
    return children;
  }
  return <Navigate to="/" replace />;
};

GroupOnlyRoute.propTypes = {
  group: PropTypes.string,
  children: PropTypes.element,
};

GroupOnlyRoute.defaultProps = {
  group: '',
  children: null,
};

export default { GroupOnlyRoute };
