const generateErrorCode = ({ prefix, errorRef }) =>
  `${prefix}${`${errorRef}`.padStart(7, '0')}: `;

const createErrorCodesList = (list) =>
  Object.entries(list).reduce(
    (acc, [key, errorName]) => ({
      ...acc,
      [errorName]: generateErrorCode({
        prefix: 'IQAMF',
        errorRef: key,
      }),
    }),
    {}
  );

export const ERROR_CODES = createErrorCodesList({
  1: 'GET_PLAY_FINDER_COMPETITIONS',
  2: 'GET_PLAY_FINDER_FILTER_TREE',
  3: 'GET_TEAMS',
  4: 'GET_PLAYERS',
  5: 'GET_GAMES',
  6: 'GET_RADAR_STATS',
  7: 'SEARCH_METADATA',
  8: 'GET_FULL_RESULTS',
  9: 'EXPORT_VISUAL',
  10: 'USE_EXPORT_SEASON_INFO',
  11: 'USE_GET_PLAYER_STATS_CATEGORIES_DEFINITIONS',
  12: 'USE_GET_PLAYER_STATS_DEFINITIONS',
  13: 'USE_GET_PLAYER_STATS_TEAMS',
  14: 'DELETE_API_KEY',
  15: 'GENERATE_API_KEY',
  16: 'GET_PLAYER_POSITION_STATS',
  17: 'GET_RADAR_STATS_LA',
  18: 'USE_GET_RUN_GAP_DATA',
  19: 'USE_GET_RUN_POA_DATA',
  20: 'USE_GET_RUN_GAP_DATA_LA',
  21: 'USE_GET_RUN_POA_DATA_LA',
  22: 'USE_FORMATION_SUMMARY_STATS',
  23: 'USE_TEAM_STAT_DEFINITIONS',
  24: 'USE_TEAM_STAT_DISTRIBUTIONS',
  25: 'USE_TEAM_STAT_CATEGORIES',
  26: 'USE_TEAM_FORMATIONS',
  27: 'GET_TEAM_RADAR_OFFENSE_STATS',
  28: 'GET_TEAM_RADAR_DEFENSE_STATS',
  29: 'USE_GET_TEMPLATE_DEFINITIONS_TEAM',
  30: 'GET_TEAM_SCHEDULE',
  31: 'USE_TEAM_FORMATION_LOCATIONS',
  32: 'USE_GET_ROUTE_PLAYER_STATS',
  33: 'USE_GET_ROUTE_STAT_DEFINITIONS',
  34: 'USE_TEAM_FORMATION_STATS',
  35: 'USE_FORMATION_DETAIL_STAT_DEFINITIONS',
  36: 'USE_STAT_DISTRIBUTION',
  37: 'GET_PLAYER_COMPARISON',
  38: 'USE_PLAYER_SCOUT',
  39: 'USE_SEARCH_HIGH_SCHOOLS',
  40: 'USE_SEARCH_COLLEGES',
  41: 'USE_SEARCH_CITIES',
  42: 'USE_GET_ROUTE_ALIGNMENT_PLAYS',
  43: 'GET_PLAY_FINDER_SEASONS',
  44: 'GET_PLAY_FINDER_TEAMS',
  45: 'GET_PLAY_FINDER_RESULTS',
  46: 'GET_NAVIGATION_DATA',
  47: 'GET_HAVOC_TEAM_STATS',
  48: 'GET_HAVOC_EVENTS',
});
