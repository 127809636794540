import { PASS_OUTCOME_TYPES } from '../../pages/player/PlayerPassing/PlayerPassing.constants';

export const applyOutcomeColor = (outcome, visPalette) => {
  switch (outcome?.toUpperCase()) {
    case PASS_OUTCOME_TYPES.COMPLETE.value:
      return visPalette.successFail.superSuccess.main;
    case PASS_OUTCOME_TYPES.TOUCHDOWN.value:
      return visPalette.successFail.superSuccess.main;
    case PASS_OUTCOME_TYPES.INCOMPLETE.value:
      return visPalette.successFail.fail.main;
    case PASS_OUTCOME_TYPES.INTERCEPTION.value:
      return visPalette.successFail.superFail.main;
    default:
      return '';
  }
};
