import React, { useState } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import {
  Button,
  ButtonIcon,
  ButtonGroup,
  Slider,
  Toggle,
} from '@statsbomb/kitbag-components';
import Tile from '../../../components/Tile/Tile';
import {
  TeamPlusMinusPage,
  TeamPlusMinusHeader,
  TeamPlusMinusControls,
} from './TeamPlusMinus.styles';
import {
  PLUS_MINUS_CATEGORIES,
  PLUS_MINUS_CATEGORY_NAMES,
} from './TeamPlusMinus.constants';

const TeamPlusMinus = () => {
  const [offense, setOffense] = useState(true);
  const [category, setCategory] = useState(PLUS_MINUS_CATEGORIES.TOTAL);
  const [minPlays, setMinPlays] = useState(10);
  const [minimumSnaps, setMinimumSnaps] = useState(minPlays);
  const [onOffDifference, setOnOffDifference] = useState(true);
  const [colorMinMax, setColorMinMax] = useState(true);

  return (
    <TeamPlusMinusPage>
      <Tile widthLimit="75%" margin="0">
        <Tile.Header>
          <TeamPlusMinusHeader>
            <Breadcrumb size="huge">
              <Breadcrumb.Section>Team</Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>Player +/-</Breadcrumb.Section>
            </Breadcrumb>
            <CSVLink data={[]} filename="">
              <ButtonIcon
                size="small"
                variant="secondary"
                icon="Download"
                disabled={false}
                title="Download Player +/- Table"
              >
                download player +/- table
              </ButtonIcon>
            </CSVLink>
          </TeamPlusMinusHeader>
          <TeamPlusMinusControls>
            <ButtonGroup>
              <Button
                size="small"
                onClick={() => setOffense(true)}
                variant={offense ? 'primary' : 'ghost'}
              >
                Offense
              </Button>
              <Button
                size="small"
                onClick={() => setOffense(false)}
                variant={offense ? 'ghost' : 'primary'}
              >
                Defense
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              {Object.values(PLUS_MINUS_CATEGORIES).map((c) => (
                <Button
                  key={c}
                  size="small"
                  onClick={() => setCategory(c)}
                  variant={category === c ? 'primary' : 'ghost'}
                >
                  {PLUS_MINUS_CATEGORY_NAMES[c]}
                </Button>
              ))}
            </ButtonGroup>
          </TeamPlusMinusControls>
        </Tile.Header>
      </Tile>
      <Tile widthLimit="25%" margin="0">
        <Tile.Header>Display Options</Tile.Header>
        <Tile.Body>
          <div>
            <Slider
              ariaLabel="minimum-snaps-filter"
              max={200}
              min={0}
              step={10}
              title="Minimum Total Snaps"
              value={minimumSnaps}
              onAfterChange={setMinPlays}
              onChange={setMinimumSnaps}
              reverse
            />
          </div>
          <Toggle
            id="on-off-difference-toggle"
            label="On/Off Difference"
            checked={onOffDifference}
            onChange={() => setOnOffDifference(!onOffDifference)}
          />
          <Toggle
            id="export-logo-toggle"
            label="Color Gradient Team Min/Max"
            checked={colorMinMax}
            onChange={() => setColorMinMax(!colorMinMax)}
          />
        </Tile.Body>
      </Tile>
    </TeamPlusMinusPage>
  );
};

export default TeamPlusMinus;
