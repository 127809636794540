/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Checkbox, Table } from '@statsbomb/kitbag-components';
import { ALIGNMENT } from '../../../../utils/constants/alignment';
import {
  tableRowsPropTypes,
  sortableTableAlignmentPropTypes,
  sortableTableCellSize,
} from '../../Tables.propTypes';

/**
 *  SortableTableBody component
 *  This component is responsible for rendering the data rows of the table
 */
const SortableTableBody = ({
  rows,
  name,
  alignment,
  isFooter,
  dataCellSize,
  onChange,
}) => {
  const renderCell = (cell) => {
    if (cell?.link && cell?.hasOwnProperty('label')) {
      return <Link to={cell.link}>{cell.label}</Link>;
    }

    if (cell?.hasOwnProperty('label')) {
      return cell.label;
    }

    return cell;
  };

  const tableRows = rows.map((row, rowIndex) => (
    <Table.Row
      key={`${name}-table-row-${rowIndex}`}
      data-testid={`${name}-table-row-${rowIndex}`}
    >
      {onChange && (
        <Table.DataCell style={{ width: '32px' }}>
          <Checkbox
            checked={row?.checked}
            id={`select-row-${rowIndex}`}
            label={`select-row-${rowIndex}`}
            onChange={() => onChange(rowIndex)}
            withLabel={false}
            size="small"
          />
        </Table.DataCell>
      )}
      {Object.values(row).map((cell, cellIndex) => {
        const widthOverride =
          cell?.width?.length > 0
            ? {
                minWidth: cell.width,
                maxWidth: cell.width,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
            : {};
        const colorOverride =
          cell?.color?.length > 0
            ? { backgroundColor: cell?.color, color: cell?.ink }
            : {};
        // don't render id cell if it is a selectable row
        if (typeof cell !== 'object' && onChange) {
          return null;
        }
        return (
          <Table.DataCell
            $textAlign={
              typeof alignment === 'function' ? alignment(cellIndex) : alignment
            }
            key={`${name}-table-data-cell-${rowIndex}-${cellIndex}`}
            title={cell?.title || ''}
            $size={dataCellSize}
            style={{ ...widthOverride, ...colorOverride }}
          >
            {renderCell(cell)}
          </Table.DataCell>
        );
      })}
    </Table.Row>
  ));

  if (isFooter) {
    return (
      <Table.Foot data-testid={`${name}-table-foot`}>{tableRows}</Table.Foot>
    );
  }
  return (
    <Table.Body data-testid={`${name}-table-body`}>{tableRows}</Table.Body>
  );
};

SortableTableBody.propTypes = {
  // An array of objects containing the data for the table data rows
  rows: tableRowsPropTypes,
  // determines how each column should be aligned. can be a function that
  // uses the column index, or an alignment string which will apply to all columns
  alignment: sortableTableAlignmentPropTypes,
  // The name of the table - used as a prefix for keys
  name: PropTypes.string.isRequired,
  // Is this the footer section of the table
  isFooter: PropTypes.bool,
  // controls the height of the data cells
  dataCellSize: sortableTableCellSize,
  // function that is called when a row is clicked
  onChange: PropTypes.func,
};

SortableTableBody.defaultProps = {
  rows: [],
  alignment: ALIGNMENT.LEFT,
  isFooter: false,
  dataCellSize: 'small',
  onChange: null,
};

export default SortableTableBody;
