import { styled } from 'styled-components';
import { Search } from 'semantic-ui-react';

export const StyledLocalSearch = styled.div`
  .ui {
    .input > input {
      height: 2.4rem;
      border-radius: 99rem;
      padding: 0.65rem;
      background-color: ${({ theme }) =>
        theme.colours.canvas.secondary.main} !important;
    }

    span + span {
      margin-left: 0.8rem;
    }
  }
`;
StyledLocalSearch.displayName = 'StyledLocalSearch';

export const StyledLocalSearchElement = styled(Search)`
  input.prompt {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: none;
    background-color: ${({ theme }) =>
      theme.colours.layering[0].main} !important;
    color: ${({ theme }) => theme.colours.ink.primary.main} !important;

    :focus {
      background-color: ${({ theme }) => theme.colours.layering[0].main};
    }
  }

  input ~ i.icon {
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }

  .results {
    background-color: ${({ theme }) =>
      theme.colours.canvas.tertiary.main} !important;
    box-shadow: ${({ theme }) =>
      theme.isDark ? 'none' : theme.effects.dropShadow.regular};
    overflow-y: scroll;
    max-height: 300px;
    border: 0 !important;

    .result {
      line-height: 2;
      padding: 0 !important;
      border: 0 !important;
      display: flex !important;

      &:hover,
      &.active {
        background: ${({ theme }) =>
          theme.colours.canvas.secondary.strong} !important;
        color: ${({ theme }) => theme.colours.ink.primary.main} !important;
      }

      a,
      button {
        background: none;
        justify-content: flex-start;
        padding: 0.2rem 1.2rem;
        color: ${({ theme }) => theme.colours.ink.primary.main} !important;
        text-decoration: none;
        text-align: left;

        &:hover {
          color: ${({ theme }) => theme.colours.ink.primary.main} !important;
        }
      }
    }
  }
`;
StyledLocalSearchElement.displayName = 'StyledLocalSearchElement';
