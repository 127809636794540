const addTableHeaderRowCells = function (headerRow) {
  headerRow.append('th').text('Partition').attr('class', 'long-name');
  headerRow.append('th').text('Metric').attr('class', 'long-name');
  headerRow.append('th').text('Total').attr('class', 'for');
  headerRow.append('th').text('').attr('class', 'space');
  headerRow.append('th').text('1st Half').attr('class', 'for');
  headerRow.append('th').text('2nd Half').attr('class', 'for');
  headerRow.append('th').text('').attr('class', 'space');
  headerRow.append('th').text('Q1').attr('class', 'for');
  headerRow.append('th').text('Q2').attr('class', 'for');
  headerRow.append('th').text('Q3').attr('class', 'for');
  headerRow.append('th').text('Q4').attr('class', 'for');
  headerRow.append('th').text('').attr('class', 'space');
  headerRow.append('th').text('Total').attr('class', 'against');
  headerRow.append('th').text('').attr('class', 'space');
  headerRow.append('th').text('1st Half').attr('class', 'against');
  headerRow.append('th').text('2nd Half').attr('class', 'against');
  headerRow.append('th').text('').attr('class', 'space');
  headerRow.append('th').text('Q1').attr('class', 'against');
  headerRow.append('th').text('Q2').attr('class', 'against');
  headerRow.append('th').text('Q3').attr('class', 'against');
  headerRow.append('th').text('Q4').attr('class', 'against');
};

const addRowCells = function (row, forMetricObj, againstMetricObj, metricName) {
  row.append('td').text(metricName).attr('class', 'metric-name');
  row.append('td').text(forMetricObj.total);
  row.append('td').text('').attr('class', 'space');
  row.append('td').text(forMetricObj.half1);
  row.append('td').text(forMetricObj.half2);
  row.append('td').text('').attr('class', 'space');
  row.append('td').text(forMetricObj.quarter1);
  row.append('td').text(forMetricObj.quarter2);
  row.append('td').text(forMetricObj.quarter3);
  row.append('td').text(forMetricObj.quarter4);
  row.append('td').text('').attr('class', 'space');
  row.append('td').text(againstMetricObj.total);
  row.append('td').text('').attr('class', 'space');
  row.append('td').text(againstMetricObj.half1);
  row.append('td').text(againstMetricObj.half2);
  row.append('td').text('').attr('class', 'space');
  row.append('td').text(againstMetricObj.quarter1);
  row.append('td').text(againstMetricObj.quarter2);
  row.append('td').text(againstMetricObj.quarter3);
  row.append('td').text(againstMetricObj.quarter4);
};

const addTableRowGroup = function (
  tableBody,
  partitionName,
  rowObj,
  againstObj,
  showRunPerc
) {
  const row = tableBody.append('tr');
  const rowSpan = showRunPerc ? 4 : 3;
  row
    .append('td')
    .attr('rowspan', rowSpan)
    .attr('class', 'partition-cell')
    .text(partitionName);
  addRowCells(row, rowObj.plays, againstObj.plays, 'Plays');

  if (showRunPerc) {
    const rowRatio = tableBody.append('tr');
    addRowCells(rowRatio, rowObj.runPerc, againstObj.runPerc, 'Run % : Pass %');
  }

  const row2 = tableBody.append('tr');
  addRowCells(row2, rowObj.successPerc, againstObj.successPerc, 'Success %');

  const row3 = tableBody.append('tr');
  addRowCells(
    row3,
    rowObj.yardsGained,
    againstObj.yardsGained,
    'Yards Per Play'
  );
};

const addTableSpaceRow = function (tableBody) {
  const row = tableBody.append('tr');
  for (let i = 0; i < 21; i += 1) {
    row.append('td').text('').attr('class', 'space');
  }
};

export { addTableHeaderRowCells, addTableRowGroup, addTableSpaceRow };
