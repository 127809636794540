const NTH_NAMES = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  4: 'th',
  5: 'th',
  6: 'th',
  7: 'th',
  8: 'th',
  9: 'th',
  0: 'th',
};

/**
 * converts an integer to its corresponding ordinal version
 */
export const convertIntToNth = (int) => {
  if (!Number.isInteger(int)) {
    return '';
  }

  const intAsText = int.toString();
  const lastCharacter = intAsText.slice(-1);

  // the numbers 11 to 13 are special cases
  if (int > 10 && int < 14) {
    return `${intAsText}th`;
  }

  return intAsText + NTH_NAMES[lastCharacter];
};

/**
 * takes a date and converts it to a US date format
 * example output - "02/24/2023"
 */
export const toUsDate = (date) =>
  new Date(date).toLocaleString('en-us', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

/**
 * takes a js timestamp and returns that as local timestamp
 *  has a locale override which is required for testing, but in site should not be used
 */
export const toLocalDateTime = (jsTimestamp, locale) => {
  const jsDate = new Date(jsTimestamp);
  const localDateTime = jsDate.toLocaleString(locale);
  return `${localDateTime}`;
};

// display or table null value
export const TABLE_NULL = '-';

// format height in inches to feet and inches
export const inchesToFeetAndInches = (heightInInches) => {
  if (!heightInInches) {
    // yes this will convert 0 but a height of 0 is erroneous anyway
    return TABLE_NULL;
  }
  const inches = Math.round(heightInInches);
  const feet = Math.floor(inches / 12);
  const inchesRemainder = inches % 12;
  return `${feet}' ${inchesRemainder}"`;
};
