import { Table } from '@statsbomb/kitbag-components';
import styled from 'styled-components';

export const StyledAccordionTableBody = styled(Table.Body)`
  tr:first-of-type {
    td {
      ${({ $isOpen, theme }) =>
        $isOpen &&
        `border-bottom: 1px solid ${theme.colours.canvas.secondary.main}`}
    }

    &:hover {
      td {
        background-color: ${({ theme }) =>
          theme.applyOpacity(theme.colours.canvas.tertiary.strong, 'overlay')};
      }
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 2px solid
        ${({ theme }) => theme.colours.canvas.secondary.main};
    }
  }

  ${({ $hasExpandButton, theme }) =>
    $hasExpandButton &&
    `
    tr:last-of-type {
      &:hover {
        td {
          background-color: ${theme.applyOpacity(
            theme.colours.canvas.tertiary.strong,
            'overlay'
          )};

          button {
            color: ${theme.colours.canvas.tertiary.ink};
          }
        }
      }
    }
  `}
`;

export const AccordionTableButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  height: 4rem;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.colours.ink.primary.main};

  ${({ theme }) => theme.typography.headingMedium}
  svg {
    transition: transform 0.5s;
    ${({ $isOpen }) => $isOpen && `transform: rotate(180deg)`};
  }
`;

export const ExpandMoreButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  height: 3.2rem;
  color: ${({ theme }) =>
    theme.applyOpacity(theme.colours.canvas.tertiary.ink, 'secondary')};
  ${({ theme }) => theme.typography.headingSmall}

  ${({ theme, $isFormationTable }) =>
    $isFormationTable &&
    `
    padding: 0 0.8rem;
    &:hover {
      color: ${theme.colours.canvas.tertiary.ink};
      background-color: ${theme.applyOpacity(
        theme.colours.canvas.tertiary.strong,
        'secondary'
      )};
    }
  `}
`;

export const NumberIndicator = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.8rem;
  color: ${({ theme }) => theme.colours.ink.primary.main};
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  border-radius: ${({ theme }) => theme.borderRadius.secondary};
  ${({ theme }) => theme.typography.bodySmall}
`;

StyledAccordionTableBody.displayName = 'StyledAccordionTableBody';
AccordionTableButton.displayName = 'AccordionTableButton';
ExpandMoreButton.displayName = 'ExpandMoreButton';
NumberIndicator.displayName = 'NumberIndicator';
