// default values for ui state object
// values load/save from session/local storage
import { qbPocketLocation } from '../utils/constants/api';
import {
  COMPETITION_LEVEL,
  OFFENSE_FORMATIONS,
} from '../utils/constants/charting';

const uiDefault = {
  contextFilters: {
    distance: {
      enabled: false,
      value: [0, 10],
    },
    down: {
      enabled: false,
      value: [true, true, true, true, false],
    },
    fieldPosition: {
      enabled: false,
      value: [0, 100],
    },
    quarter: {
      enabled: false,
      value: [true, true, true, true, false],
    },
    clock: {
      enabled: false,
      value: [0, 900],
    },
    playType: {
      enabled: false,
      value: [true, true, false, false], // pass, run, QB kneel, QB Spike
    },
    qbDropDepth: {
      enabled: false,
      value: [0, 16],
    },
    qbTimeToThrow: {
      enabled: false,
      value: [0, 5.1],
    },
    qbPocketLocation: {
      enabled: false,
      value: [...new Array(Object.keys(qbPocketLocation).length).fill(true)], // In, Left, Right
    },
    yardsGained: {
      enabled: false,
      value: [-10, 25],
    },
    passAirYards: {
      enabled: false,
      value: [-5, 40],
    },
    packages: {
      enabled: false,
      offensePackage: [],
      defensePackage: [],
    },
    positions: {
      enabled: false,
      te: [0, 2],
      ol: [6, 10],
      wr: [0, 5],
      rb: [0, 2],
      qb: [0, 1],
      dl: [0, 5],
      lb: [0, 2],
      db: [0, 5],
    },
    formations: {
      enabled: false,
      offenseFormations: new Array(OFFENSE_FORMATIONS.length).fill(false),
    },
    gameIds: {
      enabled: false,
      value: [],
    },
  },
  mainFilters: {
    games: 1068335,
    leagues: 1446,
    leagueLevel: COMPETITION_LEVEL.NCAA,
    players: NaN,
    seasons: 1052, // 2024/2025
    teams: 0,
  },
  pageFilters: {
    team: {
      drive: null,
      play: null,
      playEvent: null,
    },
  },
  ui: {
    desktopMenuExpanded: false,
    mobileMenuExpanded: false,
    theme: {
      isDark: false,
      isDarkCharts: true,
    },
    networkError: false,
    personnelTab: 0,
  },
};

export { uiDefault as default };
