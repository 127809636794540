import { ApolloClient, InMemoryCache, HttpLink, makeVar } from '@apollo/client';
import fetch from 'cross-fetch';
import { merge } from 'lodash';
import uiDefault from './uiDefault';
import {
  convertBooleanToIndex,
  convertBooleanToValues,
} from '../utils/helpers/general';
import {
  playTypeOptionsKeys,
  qbPocketLocationKeys,
} from '../utils/constants/api';
import { OFFENSE_FORMATIONS } from '../utils/constants/charting';
import { DROPDOWN_SPECIAL_OPTIONS } from '../components/DropdownFilter/DropdownFilter.constants';

// uiState = defaults, will merge with accounts data when available
// get local data and merge with defaults
const uiLocal = JSON.parse(sessionStorage.getItem('uiStateLocal'));
const copyuiDefault = { ...uiDefault };
export const uiState = makeVar(
  merge(copyuiDefault, {
    ui: uiLocal?.ui,
    contextFilters: uiLocal?.contextFilters,
  })
);
// helper RV to allow customisation of @client based on location
export const currentLocationRV = makeVar('');

// general ui
const { ui } = uiState();
export const ui_desktopMenuExpanded = makeVar(ui.desktopMenuExpanded);
export const ui_mobileMenuExpanded = makeVar(ui.mobileMenuExpanded);
export const ui_isDark = makeVar(ui.theme.isDark);
export const ui_networkError = makeVar(ui.networkError);
export const ui_personnelTab = makeVar(ui.personnelTab);
export const ui_scoutSetups = makeVar(ui.scoutSetups);

// the id of the selected item to be used in a query
// example: mf_Seasons stores the selected season id
// null are now pulled form query string as a priority
const mf = uiState().mainFilters;
const { games, teams, players, leagues, seasons, leagueLevel } = mf;
export const mf_Teams = makeVar(teams);
export const mf_TeamDetails = makeVar({});
export const mf_Players = makeVar(players);
export const mf_PlayerDetails = makeVar({});
export const mf_Leagues = makeVar(leagues);
export const mf_LeagueLevel = makeVar(leagueLevel);
export const mf_Seasons = makeVar(seasons);
export const mf_AllSeasons = makeVar([]);
export const mf_Games = makeVar(games);
export const mf_GameDetails = makeVar({});

const cf = uiState().contextFilters;
const {
  down,
  distance,
  fieldPosition,
  quarter,
  clock,
  playType,
  qbDropDepth,
  qbTimeToThrow,
  qbPocketLocation,
  yardsGained,
  passAirYards,
  packages,
  positions,
  formations,
  gameIds,
} = cf;
export const cf_Down = makeVar(down.value);
export const cf_DownEnabled = makeVar(down.enabled);
export const cf_Distance = makeVar(distance.value);
export const cf_DistanceEnabled = makeVar(distance.enabled);
export const cf_FieldPosition = makeVar(fieldPosition.value);
export const cf_FieldPositionEnabled = makeVar(fieldPosition.enabled);
export const cf_Quarter = makeVar(quarter.value);
export const cf_QuarterEnabled = makeVar(quarter.enabled);
export const cf_Clock = makeVar(clock.value);
export const cf_ClockEnabled = makeVar(clock.enabled);
export const cf_PlayType = makeVar(playType.value);
export const cf_PlayTypeEnabled = makeVar(playType.enabled);
export const cf_QBDropDepth = makeVar(qbDropDepth.value);
export const cf_QBDropDepthEnabled = makeVar(qbDropDepth.enabled);
export const cf_QBTimeToThrow = makeVar(qbTimeToThrow.value);
export const cf_QBTimeToThrowEnabled = makeVar(qbTimeToThrow.enabled);
export const cf_QBPocketLocation = makeVar(qbPocketLocation.value);
export const cf_QBPocketLocationEnabled = makeVar(qbPocketLocation.enabled);
export const cf_YardsGained = makeVar(yardsGained.value);
export const cf_YardsGainedEnabled = makeVar(yardsGained.enabled);
export const cf_PassAirYardsEnabled = makeVar(passAirYards.enabled);
export const cf_PassAirYards = makeVar(passAirYards.value);
export const cf_PackagesOffense = makeVar(packages.offensePackage);
export const cf_PackagesDefense = makeVar(packages.defensePackage);
export const cf_PackagesEnabled = makeVar(packages.enabled);
export const cf_PositionsTE = makeVar(positions.te);
export const cf_PositionsOL = makeVar(positions.ol);
export const cf_PositionsWR = makeVar(positions.wr);
export const cf_PositionsRB = makeVar(positions.rb);
export const cf_PositionsQB = makeVar(positions.qb);
export const cf_PositionsDL = makeVar(positions.dl);
export const cf_PositionsLB = makeVar(positions.lb);
export const cf_PositionsDB = makeVar(positions.db);
export const cf_PositionsEnabled = makeVar(positions.enabled);
export const cf_FormationsEnabled = makeVar(formations.enabled);
export const cf_FormationsOffense = makeVar(formations.offenseFormations);
export const cf_GameIds = makeVar(gameIds.value);
export const cf_GameIdsEnabled = makeVar(gameIds.enabled);

const pf = uiState().pageFilters;
const { team } = pf;
// page filters for game/animation
export const pf_TeamPlay = makeVar(team.play); // plays variable used with pf_Drive
export const pf_TeamPlayEvent = makeVar(team.playEvent); // needs name refactor
export const pf_Drive = makeVar(team.drive);
export const pf_AllDrives = makeVar([]);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        contextFilterClockMax: {
          read() {
            const isEnabled = cf_ClockEnabled();
            return isEnabled ? +cf_Clock()?.[1] : null;
          },
        },
        contextFilterClockMin: {
          read() {
            const isEnabled = cf_ClockEnabled();
            return isEnabled ? +cf_Clock()?.[0] : null;
          },
        },
        contextFilterDowns: {
          read() {
            const isEnabled = cf_DownEnabled();
            return isEnabled
              ? convertBooleanToIndex(cf_Down().slice(0, -1))
              : null;
          },
        },
        contextFilterNoDowns: {
          read() {
            const isEnabled = cf_DownEnabled();
            const lastCheckbox = Array.isArray(cf_Down()) ? cf_Down()[4] : null;
            return isEnabled ? lastCheckbox : null;
          },
        },
        contextFilterFieldPositionMax: {
          read() {
            const isEnabled = cf_FieldPositionEnabled();
            return isEnabled ? +cf_FieldPosition()?.[1] : null;
          },
        },
        contextFilterFieldPositionMin: {
          read() {
            const isEnabled = cf_FieldPositionEnabled();
            return isEnabled ? +cf_FieldPosition()?.[0] : null;
          },
        },
        contextFilterQuarter: {
          read() {
            const isEnabled = cf_QuarterEnabled();
            return isEnabled ? convertBooleanToIndex(cf_Quarter()) : null;
          },
        },
        contextFilterYardsToGoMax: {
          read() {
            const isEnabled = cf_DistanceEnabled();
            const ydsToGoMax = +cf_Distance()?.[1];
            return isEnabled && ydsToGoMax < 21 ? ydsToGoMax : null;
          },
        },
        contextFilterYardsToGoMin: {
          read() {
            const isEnabled = cf_DistanceEnabled();
            const ydsToGoMin = +cf_Distance()?.[0];
            return isEnabled && ydsToGoMin > 0 ? ydsToGoMin : null;
          },
        },
        contextFilterPassAirYardsMax: {
          read() {
            const isEnabled = cf_PassAirYardsEnabled();
            const ydsToGoMax = +cf_PassAirYards()?.[1];
            return isEnabled && ydsToGoMax < 41 ? ydsToGoMax : null;
          },
        },
        contextFilterPassAirYardsMin: {
          read() {
            const isEnabled = cf_PassAirYardsEnabled();
            const ydsToGoMin = +cf_PassAirYards()?.[0];
            return isEnabled && ydsToGoMin > -6 ? ydsToGoMin : null;
          },
        },
        contextFilterPlayType: {
          read() {
            const isEnabled = cf_PlayTypeEnabled();
            const loc = currentLocationRV();
            if (isEnabled) {
              return convertBooleanToValues(cf_PlayType(), playTypeOptionsKeys);
            }
            // if disabled on certain pages return default values
            if (loc === '/team/box') {
              return ['PASS', 'RUSH'];
            }
            if (loc === '/game/event') {
              return ['PASS', 'RUSH', 'QUARTERBACK_KNEEL', 'QUARTERBACK_SPIKE'];
            }
            return null;
          },
        },
        contextFilterQBDropDepthMax: {
          read() {
            const isEnabled = cf_QBDropDepthEnabled();
            const dropDepthMax = +cf_QBDropDepth()?.[1];
            return isEnabled && dropDepthMax < 16 ? dropDepthMax : null;
          },
        },
        contextFilterQBDropDepthMin: {
          read() {
            const isEnabled = cf_QBDropDepthEnabled();
            const dropDepthMin = +cf_QBDropDepth()?.[0];
            return isEnabled ? dropDepthMin : null;
          },
        },
        contextFilterQBTimeToThrowMax: {
          read() {
            const isEnabled = cf_QBTimeToThrowEnabled();
            const timeToThrowMax = +cf_QBTimeToThrow()?.[1];
            return isEnabled && timeToThrowMax < 5.1 ? timeToThrowMax : null;
          },
        },
        contextFilterQBTimeToThrowMin: {
          read() {
            const isEnabled = cf_QBTimeToThrowEnabled();
            const timeToThrowMin = +cf_QBTimeToThrow()?.[0];
            return isEnabled ? timeToThrowMin : null;
          },
        },
        contextFilterQBPocketLocation: {
          read() {
            const isEnabled = cf_QBPocketLocationEnabled();
            return isEnabled
              ? convertBooleanToValues(
                  cf_QBPocketLocation(),
                  qbPocketLocationKeys
                )
              : [];
          },
        },
        contextFilterYardsGained: {
          read() {
            const isEnabled = cf_YardsGainedEnabled();
            return isEnabled ? convertBooleanToIndex(cf_PlayType()) : null;
          },
        },
        contextFilterYardsGainedMax: {
          read() {
            const isEnabled = cf_YardsGainedEnabled();
            const ydsGainedMax = +cf_YardsGained()?.[1];
            return isEnabled && ydsGainedMax < 26 ? ydsGainedMax : null;
          },
        },
        contextFilterGameIds: {
          read() {
            const loc = currentLocationRV();
            const isEnabled = cf_GameIdsEnabled();
            const cf_games = cf_GameIds();
            return isEnabled &&
              (loc.includes('/player/') || loc.includes('/team/'))
              ? cf_games
              : [];
          },
        },
        contextFilterYardsGainedMin: {
          read() {
            const isEnabled = cf_YardsGainedEnabled();
            const ydsGainedMin = +cf_YardsGained()?.[0];
            return isEnabled && ydsGainedMin > -11 ? ydsGainedMin : null;
          },
        },
        contextFilterPersonnel: {
          read() {
            const selectedPersonnelTab = +ui_personnelTab();
            const isPackagesEnabled = cf_PackagesEnabled();
            const offensePackages = cf_PackagesOffense();
            const defensePackages = cf_PackagesDefense();
            const packagesQuery = {
              offensePackage:
                offensePackages.length > 0 ? offensePackages : null,
              defensePackage:
                defensePackages.length > 0 ? defensePackages : null,
            };
            const isPositionsEnabled = cf_PositionsEnabled();
            const positionsQuery = {
              olMin: +cf_PositionsOL()?.[0],
              olMax: +cf_PositionsOL()?.[1],
              teMin: +cf_PositionsTE()?.[0],
              teMax: +cf_PositionsTE()?.[1],
              wrMin: +cf_PositionsWR()?.[0],
              wrMax: +cf_PositionsWR()?.[1],
              rbMin: +cf_PositionsRB()?.[0],
              rbMax: +cf_PositionsRB()?.[1],
              qbMin: +cf_PositionsQB()?.[0],
              qbMax: +cf_PositionsQB()?.[1],
              dlMin: +cf_PositionsDL()?.[0],
              dlMax: +cf_PositionsDL()?.[1],
              lbMin: +cf_PositionsLB()?.[0],
              lbMax: +cf_PositionsLB()?.[1],
              dbMin: +cf_PositionsDB()?.[0],
              dbMax: +cf_PositionsDB()?.[1],
            };
            let personnelQuery = {};
            if (selectedPersonnelTab === 0 && isPackagesEnabled) {
              personnelQuery = { ...packagesQuery };
            }
            if (selectedPersonnelTab === 1 && isPositionsEnabled) {
              personnelQuery = { ...positionsQuery };
            }
            return personnelQuery;
          },
        },
        contextFilterFormations: {
          read() {
            const isFormationsEnabled = cf_FormationsEnabled();
            const offenseFormationsValues = convertBooleanToValues(
              cf_FormationsOffense(),
              OFFENSE_FORMATIONS
            );

            return isFormationsEnabled
              ? { offenseFormation: offenseFormationsValues }
              : null;
          },
        },
        pageFilterDrive: {
          read() {
            return DROPDOWN_SPECIAL_OPTIONS.includes(pf_Drive())
              ? pf_AllDrives()
              : pf_Drive();
          },
        },
        pageFilterPlay: {
          read() {
            return pf_TeamPlay();
          },
        },
        players: {
          merge: false,
        },
        selectedCompetitionId: {
          read() {
            return +mf_Leagues();
          },
        },
        selectedGameId: {
          read() {
            return +mf_Games();
          },
        },
        selectedPlayerId: {
          read() {
            return +mf_Players();
          },
        },
        selectedSeasonId: {
          read() {
            return +mf_Seasons();
          },
        },
        selectedTeamId: {
          read() {
            return +mf_Teams();
          },
        },
        teams: {
          merge: false,
        },
      },
    },
  },
});

const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: true,
  link: new HttpLink({
    fetch,
    uri: process.env.REACT_APP_API_URI,
  }),
});

export default apolloClient;
