import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_PLAYER_PATHS = gql`
  query GetPlayerPaths(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedTeamId @client @export(as: "teamId")
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedPlayerId @client @export(as: "playerId")
    ${getContextFilterClientExports()}

    playerPlays(
      first: 1000
      playerIds: [$playerId]
      filters: {
        seasonId: $seasonId
        competitionId: $competitionId
        teamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      edges {
        node {
          play {
            id
          }
          player {
            id
            name
          }
          team {
            name
          }
          movementPath {
            x
            y
          }
          game {
            id
          }
          routeRun
          target
        }
      }
    }
  }
`;
