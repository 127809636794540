import { useQuery } from '@apollo/client';
import { sumBy, uniqBy } from 'lodash';
import {
  TACKLING_PLAY_TYPE_ANY,
  TACKLING_PLAY_TYPE_PASS,
  TACKLING_PLAY_TYPE_RUN,
  TACKLING_PLAY_TYPE_SACK,
} from '../../../visualisations/TackleLocation/TackleLocation.constants';
import { GET_DEFENSE_TACKLING_PLAYS } from './getDefenseTacklingPlays';
import { GET_OFFENSE_TACKLING_PLAYS } from './getOffenseTacklingPlays';
import tackleAttemptLeagueAverageCSV from '../../../assets/csvData/tackle_attempts_la.csv';
import tacklingBallCarrierLeagueAverageCSV from '../../../assets/csvData/tackling_ball_carrier_la.csv';
import tacklerLeagueAverageCSV from '../../../assets/csvData/tackler_la.csv';
import { API_PLAY_TYPE_KEYS } from '../../../utils/constants/api';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';
import { useGetPlayerStatPositionUsage } from '../../../utils/hooks/useGetPlayerStatPositionUsage';

const ALIGNMENT_POSITION_ARRAY = Object.entries(ALIGNMENT_POSITIONS).map(
  ([key, value]) => ({ key, value })
);

const getTacklingPlayType = (tacklingPlay) => {
  if (tacklingPlay.play.type === 'RUSH') {
    return TACKLING_PLAY_TYPE_RUN.value;
  }
  if (tacklingPlay.play.qbSacked) {
    return TACKLING_PLAY_TYPE_SACK.value;
  }
  return TACKLING_PLAY_TYPE_PASS.value;
};

const selectedPlayTypePlays = (tacklingPlays, tacklingPlayType) => {
  if (tacklingPlayType === TACKLING_PLAY_TYPE_ANY.value) {
    return tacklingPlays;
  }
  return tacklingPlays.filter((t) => t.tacklingPlayType === tacklingPlayType);
};
export const useGetTacklingPlays = (onDefense, tacklingPlayType) => {
  const tacklingQuery = onDefense
    ? GET_DEFENSE_TACKLING_PLAYS
    : GET_OFFENSE_TACKLING_PLAYS;
  const { data, loading, error } = useQuery(tacklingQuery);

  if (loading) {
    return { loading };
  }
  if (error) {
    return { error, loading };
  }

  const allTacklingPlays = data?.tackleFirstContact?.items;
  if (!allTacklingPlays?.length) {
    return { tacklingData: null };
  }

  /* 
  Safety against awkward API scenario ~> shouldn't be possible to get plays with no 
    tackle attempts (empty array), but safety valve required
  Without this no distances can be determined for a play
  (note old safety check against offensive tackling now handled by API correctly)
  */
  const safeTacklingPlays = allTacklingPlays.filter(
    (f) =>
      f.attempts?.length &&
      f.attempts.filter((a) => a.attemptX && a.attemptY)?.length
  );

  /* 
  Filter data for selected tackling play type(s)
  */
  const tacklingTypedPlays = safeTacklingPlays?.map((play) => ({
    ...play,
    tacklingPlayType: getTacklingPlayType(play),
  }));
  const tacklingPlays = selectedPlayTypePlays(
    tacklingTypedPlays,
    tacklingPlayType
  );

  return { tacklingData: tacklingPlays };
};

export const getTacklingLeagueAverages = (competitionId, tacklingPlayType) => {
  /* 
  League Average Data is coming from a CSV until API Built 
  This can respond to playType but no other information (context filtering)
  */
  if (tacklingPlayType !== TACKLING_PLAY_TYPE_ANY.value) {
    return tackleAttemptLeagueAverageCSV.filter(
      (f) =>
        f.competition_id === competitionId &&
        f.tackling_play_type === tacklingPlayType
    );
  }
  /* For all data need to merge counts */
  const buckets = uniqBy(tackleAttemptLeagueAverageCSV, 'bucketmin');
  const summedData = buckets.map((m) => {
    const bucketData = tackleAttemptLeagueAverageCSV.filter(
      (f) => f.bucketmin === m.bucketmin
    );
    const first_ta_x_los_count = sumBy(bucketData, 'first_ta_x_los_count');
    const final_x_los_count = sumBy(bucketData, 'final_x_los_count');
    const final_x_ta_count = sumBy(bucketData, 'final_x_ta_count');
    return { ...m, first_ta_x_los_count, final_x_los_count, final_x_ta_count };
  });
  return summedData;
};

/* Unlike most places, in this vis play type is used a little differently */
const getAPIPlayType = (tacklingPlayType) => {
  if (tacklingPlayType === TACKLING_PLAY_TYPE_RUN.value) {
    return [API_PLAY_TYPE_KEYS.RUSH];
  }
  if (tacklingPlayType === TACKLING_PLAY_TYPE_ANY.value) {
    /* Even in any mode, actually only want passes and runs */
    return [API_PLAY_TYPE_KEYS.RUSH, API_PLAY_TYPE_KEYS.PASS];
  }
  /* Sacks are passes in terms of play type */
  return [API_PLAY_TYPE_KEYS.PASS];
};
export const useGetPlayerStats = (
  selectedTeamId = 0,
  tacklingPlayType,
  showDefensive
) => {
  const alignmentPositions = ALIGNMENT_POSITION_ARRAY.filter(
    (pos) => !pos.value.isSpecialTeam && pos.value.isOffense === !showDefensive
  ).map((p) => p.key);
  const playTypes = getAPIPlayType(tacklingPlayType);
  const additionalPlayFilters =
    tacklingPlayType === TACKLING_PLAY_TYPE_SACK ? 'qbSacked: true' : '';

  const { playerStatsData, loading, error } = useGetPlayerStatPositionUsage({
    selectedTeamId,
    apiPlayTypes: playTypes,
    alignmentPositions,
    rosterPositions: [],
    additionalPlayFilters,
    useContextFilters: true,
  });

  if (loading) {
    return { loading };
  }
  if (error) {
    return { error, loading };
  }
  return { playerStatsData };
};

export const getBallCarrierLeagueAverages = (
  competitionId,
  tacklingPlayType
) => {
  /* 
  League Average Data is coming from a CSV until API Built 
  This can respond to playType but no other information (context filtering)
  */
  const relevantData = tacklingBallCarrierLeagueAverageCSV.filter(
    (f) =>
      f.competition_id === competitionId &&
      (tacklingPlayType === TACKLING_PLAY_TYPE_ANY.value ||
        f.tackling_play_type === tacklingPlayType)
  );

  /* May have multiple rows or single, but aggregate whatever */
  const touches = sumBy(relevantData, 'touches');
  const soloAttempts = sumBy(relevantData, 'soloattempts');
  const soloTackles = sumBy(relevantData, 'solotackles');
  const shortStops = sumBy(relevantData, 'shortstops');
  const brokenTackles = sumBy(relevantData, 'brokentackles');
  const yardsGained = sumBy(relevantData, 'yardsgained');
  const yardsBeforeAttempt = sumBy(relevantData, 'yardsbeforeattempt');
  const yardsAfterAttempt = sumBy(relevantData, 'yardsafterattempt');
  const tackleAttempts = sumBy(relevantData, 'tackleattempts');
  const assistTacklesSoloAttempt = sumBy(
    relevantData,
    'assisttacklessoloattempt'
  );
  const leagueAverage = {
    competitionId,
    tacklingPlayType,
    playerName: 'League Average',
    touches,
    soloAttempts,
    soloTackles,
    shortStops,
    brokenTackles,
    yardsGained,
    yardsBeforeAttempt,
    yardsAfterAttempt,
    tackleAttempts,
    assistTacklesSoloAttempt,
  };
  return leagueAverage;
};

export const getTacklerLeagueAverages = (competitionId, tacklingPlayType) => {
  /* 
  League Average Data is coming from a CSV until API Built 
  This can respond to playType but no other information (context filtering)
  */
  const relevantData = tacklerLeagueAverageCSV.filter(
    (f) =>
      f.competition_id === competitionId &&
      (tacklingPlayType === TACKLING_PLAY_TYPE_ANY.value ||
        f.tackling_play_type === tacklingPlayType)
  );

  /* May have multiple rows or single, but aggregate whatever */
  const soloAttempts = sumBy(relevantData, 'solo_attempts');
  const assistAttempts = sumBy(relevantData, 'assist_attempts');
  const soloTackles = sumBy(relevantData, 'solo_tackles');
  const assistTackles = sumBy(relevantData, 'assist_tackles');
  const allTackles = sumBy(relevantData, 'all_tackles');
  const trueTackles = sumBy(relevantData, 'true_tackles');
  const assistTacklesSoloAttempt = sumBy(
    relevantData,
    'assist_tackles_solo_attempt'
  );
  const shortStops = sumBy(relevantData, 'short_stops');
  const soloDepthOfTackle = sumBy(relevantData, 'solo_depth_of_tackle');
  const soloDepthOfTackleAttempt = sumBy(
    relevantData,
    'solo_depth_of_tackle_attempt'
  );
  const soloYardsAfterTackleAttempt = sumBy(
    relevantData,
    'solo_yards_after_tackle_attempt'
  );
  const plays = sumBy(relevantData, 'plays');
  const allAttempts = soloAttempts + assistAttempts;

  const leagueAverage = {
    competitionId,
    tacklingPlayType,
    playerName: 'League Average',
    soloAttempts,
    assistAttempts,
    soloTackles,
    assistTackles,
    allTackles,
    trueTackles,
    assistTacklesSoloAttempt,
    shortStops,
    soloDepthOfTackle,
    soloDepthOfTackleAttempt,
    soloYardsAfterTackleAttempt,
    plays,
    allAttempts,
  };
  return leagueAverage;
};
