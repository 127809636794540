/* eslint-disable max-len */
export const DATA_DICTIONARY_TRACKING_FRAMES = [
  {
    field: 'team_name',
    fieldType: 'string',
    fieldValues: 'New York Giants, Tampa Bay Buccaneers',
    fieldDescription: 'Team Name of the player whose FF-Row is recorded',
  },
  {
    field: 'event_uuid',
    fieldType: 'uuid',
    fieldValues: 'e1ec0da9-ac62-4f93-a8e2-be28f93d69a5',
    fieldDescription:
      'Unique identifier for the event whose FF is recorded Note: for a unique value on this table, you need to combine event_uuid with freeze_frame_player_index (think of this combination as the FF-Row ID)',
  },
  {
    field: 'freeze_frame_player_index',
    fieldType: 'integer',
    fieldValues: '0 - 21',
    fieldDescription:
      'Value for each player on the field at the time of the FF. Note 1: there will be up to 23 of these values for each FF with possible values ranging from -1 to 21 (0 to 21 are the most common values, which are tied to the 22 players on the field, the possible value of -1 is described in Note 2)\n\nNote 2: when the ball is in the air or loose, we interpolate the position of the ball and create a FF for it. In this case, the value of freeze_frame_player_index will be -1 and both team_id and player_id will be set to NULL',
  },
  {
    field: 'freeze_frame_ball_location',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Where the ball is on the FF. Note 1: there should only be 1 TRUE value for a particular event\n\nNote 2: when the ball is in the air or loose, we interpolate the position of the ball and create a FF for it. In this case, the value of freeze_frame_player_index will be -1 and both team_id and player_id will be set to NULL',
  },
  {
    field: 'team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription:
      'Unique team identifier for the player whose FF-Row is being recorded',
  },
  {
    field: 'player_id',
    fieldType: 'integer',
    fieldValues: '1024073',
    fieldDescription:
      'Unique player identifier for the player whose FF-Row is being recorded',
  },
  {
    field: 'freeze_frame_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the player location at the time of the FF (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'freeze_frame_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the player location at the time of the FF (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'game_id',
    fieldType: 'integer',
    fieldValues: '1069669',
    fieldDescription: 'Unique identifier for the game',
  },
];
