import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LinkedTableTile from '../../../components/TableTile/LinkedTableTile';

const PassPlayerTable = ({
  data,
  title,
  columnHeaders,
  hasError,
  isLoading,
  fileName,
  onSelected,
}) => {
  const [tableData, setTableData] = useState();
  const indeterminateRows = tableData?.some((r) => r.checked);
  const allRows = tableData?.every((r) => r.checked);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const toggleRow = (index) => {
    const tableClone = structuredClone(tableData);
    tableClone[index] = {
      ...tableData[index],
      checked: !tableData[index]?.checked,
    };
    onSelected(tableClone);
  };

  const toggleAll = () => {
    const tableRows = tableData.map((r) => ({
      ...r,
      checked: !indeterminateRows,
    }));
    onSelected(tableRows);
  };

  return (
    <LinkedTableTile
      data={data}
      tileTitle={title}
      columnHeaders={columnHeaders}
      error={hasError}
      loading={isLoading}
      fileName={fileName}
      onChange={toggleRow}
      onChangeAll={toggleAll}
      allIndeterminate={indeterminateRows}
      allChecked={allRows}
    />
  );
};

PassPlayerTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  fileName: PropTypes.string,
  onSelected: PropTypes.func,
};

PassPlayerTable.defaultProps = {
  data: undefined,
  columnHeaders: undefined,
  fileName: 'table.csv',
  hasError: false,
  isLoading: false,
  onSelected: () => {},
};

export default PassPlayerTable;
