import { gql } from '@apollo/client';

export const GET_PLAYER_STATS_CATEGORIES = gql`
  query GetPlayerStatsCategories {
    playerStatCategories {
      name
      subcategories {
        name
        stats
      }
    }
  }
`;
