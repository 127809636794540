import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { groupBy, orderBy, sumBy } from 'lodash';
import { ButtonIcon, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import Tile from '../../../components/Tile/Tile';
import FormationSummaryChart from '../../../visualisations/FormationSummaryChart/FormationSummaryChart';
import { mf_Teams } from '../../../apollo';
import { useFormationSummaryStats } from '../TeamFormationSummary/useFormationSummaryStats';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  FORMATION_SUMMARY_STAT_DISTRIBUTION,
  PLAY_COUNT,
} from '../TeamFormationSummary/TeamFormationSummary.constants';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { useBarChartDataByPersonnel } from '../TeamFormationSummary/TeamFormationSummary.hooks';
import FormationSummaryChartKey from '../../../visualisations/FormationSummaryChart/FormationSummaryChartKey';
import GoTo from '../../../components/buttons/GoTo';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { SummaryError } from '../../../components/Tile/TileSummary.styles';
import { TileInlineLoader } from './TeamOverview.styles';
import { useTeamStatDistributions } from '../../../utils/hooks/useTeamStatDistributions';

const TeamFormationTile = () => {
  const teamId = useReactiveVar(mf_Teams);
  const isTeamMode = true;
  const isPersonnelMode = false;
  const visibleStats = { 0: 'partial' };
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'formationKey',
    true
  );
  const tileHeight = 477; // the height of the grid row that this tile is in

  const {
    data: statDistributions,
    loading: isStatDistributionsLoading,
    error: statDistributionsError,
  } = useTeamStatDistributions(FORMATION_SUMMARY_STAT_DISTRIBUTION);

  const {
    data: teamStatsByPersonnel,
    loading: isTeamStatsByPersonnelLoading,
    error: teamStatsByPersonnelError,
  } = useFormationSummaryStats(
    teamId,
    ['OFFENSE_PERSONNEL'],
    statDistributions,
    isTeamMode,
    isPersonnelMode
  );

  const {
    data: teamStatsByFormationPersonnel,
    loading: isTeamStatsByFormationPersonnelLoading,
    error: teamStatsByFormationPersonnelError,
  } = useFormationSummaryStats(
    teamId,
    ['FORMATION', 'OFFENSE_PERSONNEL'],
    statDistributions,
    isTeamMode,
    isPersonnelMode
  );

  // Chart data by personnel
  const groupedTeamStatsByPersonnel = groupBy(
    teamStatsByFormationPersonnel,
    'offensePersonnel'
  );

  const orderedChartDataByPersonnel = orderBy(
    Object.values(groupedTeamStatsByPersonnel).map((group) =>
      orderBy(group, [PLAY_COUNT], [SORT_DIRECTIONS.DESCENDING])
    ),
    [(d) => sumBy(d, PLAY_COUNT)],
    [SORT_DIRECTIONS.DESCENDING]
  );

  const chartDataByPersonnel = useBarChartDataByPersonnel(
    orderedChartDataByPersonnel,
    teamStatsByPersonnel,
    isTeamMode,
    visibleStats
  );

  const isLoading =
    isStatDistributionsLoading ||
    isTeamStatsByPersonnelLoading ||
    isTeamStatsByFormationPersonnelLoading;

  const loadingMsg = () => {
    if (isStatDistributionsLoading) {
      return 'Loading Stat Distributions';
    }

    if (isTeamStatsByPersonnelLoading) {
      return 'Loading Team Stats By Personnel';
    }

    if (isTeamStatsByFormationPersonnelLoading) {
      return 'Loading Team Stats By Formation Personnel';
    }

    return '';
  };

  const hasError =
    statDistributionsError ||
    teamStatsByPersonnelError ||
    teamStatsByFormationPersonnelError;

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header $border="2px">
        <h3>Formation</h3>
        <div className="buttons">
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            href="/team/summary/:leagues/:seasons/:teams?personnelMode=true&teamMode=true"
            linkName="open team formation summary"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body
        $overflowY="scroll"
        $maxHeight={`${tileHeight}px`}
        $minHeight={`${tileHeight}px`}
        $padding={isLoading ? '0 0.5rem' : '1rem'}
        $margin="0 0.5rem 0.0625rem 0"
      >
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        {(isLoading || hasError) && (
          <Dimmer
            active
            style={{ height: `${tileHeight}px`, position: 'relative' }}
          />
        )}
        {!isLoading && (
          <div>
            <FormationSummaryChart
              id="formation-summary-chart-export"
              data={chartDataByPersonnel}
              isTeamMode={isTeamMode}
              isPersonnelMode={false}
            />
          </div>
        )}
        {!isLoading && displayChartKey && (
          <div style={{ padding: '0.5rem' }}>
            <FormationSummaryChartKey isTeamMode={isTeamMode} />
          </div>
        )}
        {isLoading && (
          <TileInlineLoader>
            <Loader active inline size="small" content={loadingMsg()} />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

export default TeamFormationTile;
