import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from './Bar';
import { Delta } from './FormationSummaryChart.styles';
import {
  DELTA_TEXT_OFFSET,
  BARS,
  FORMATION_SUMMARY_FONTS,
} from './FormationSummaryChart.constants';

// Draws the bars of the summary formation chart - bars, bar indicators, and delta indicators
export const HeaderBar = ({ section, xScale, barY, isTeamMode }) => {
  const maxDomain = xScale.domain()[1];
  const x0 = xScale(0);
  const x = xScale(section.value);

  const isNegative = section.value < 0;

  const barWidth = Math.abs(x - x0);
  const barHeight = BARS.LARGE_HEIGHT;

  const barX = Math.min(x, x0);

  return (
    <>
      <Bar
        barX={barX}
        barY={barY}
        color={section.color}
        value={section.value}
        barWidth={barWidth}
        barHeight={BARS.LARGE_HEIGHT}
        maxDomain={maxDomain}
      />
      {!isTeamMode && (
        <Delta
          y={barY + barHeight / 2 + FORMATION_SUMMARY_FONTS.NORMAL.SIZE / 2}
          x={isNegative ? x0 + DELTA_TEXT_OFFSET : x0 - DELTA_TEXT_OFFSET}
        >
          {`${isNegative ? '' : '+'}${(section.value * 100).toFixed(1)}%`}
        </Delta>
      )}
    </>
  );
};

HeaderBar.propTypes = {
  // the data that constructs the header bar
  section: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  // y position of the bar
  barY: PropTypes.number.isRequired,
  // d3 scale linear constructor instance
  xScale: PropTypes.func.isRequired,
  // whether the chart is on team mode
  isTeamMode: PropTypes.bool,
};

HeaderBar.defaultProps = {
  isTeamMode: true,
};

export default HeaderBar;
