import React from 'react';
import PropTypes from 'prop-types';
import SortableTable from './SortableTable';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { tableRowsPropTypes } from '../Tables.propTypes';

const SelectableSortableTable = ({
  name,
  headers,
  data,
  handleSort,
  sortDirection,
  sortKey,
  handleToggle,
}) => {
  const indeterminateRows = data?.some((r) => r.checked);
  const allRows = data?.every((r) => r.checked);
  const selectedRowIndexes = data
    ?.map((item, index) => (item.checked ? index + 1 : -1))
    .filter((index) => index !== -1);

  const toggleRow = (index) => {
    const tableClone = structuredClone(data);
    tableClone[index] = {
      ...data[index],
      checked: !data[index]?.checked,
    };
    handleToggle(tableClone);
  };

  const toggleAll = () => {
    const tableRows = data.map((r) => ({
      ...r,
      checked: !indeterminateRows,
    }));
    handleToggle(tableRows);
  };

  return (
    <SortableTable
      name={name}
      headers={headers}
      rows={data}
      selectedRows={selectedRowIndexes}
      handleSort={handleSort}
      sortDirection={sortDirection}
      sortBy={sortKey}
      rowHeight="2rem"
      withBorder
      onChange={toggleRow}
      onChangeAll={toggleAll}
      allIndeterminate={indeterminateRows}
      allChecked={allRows}
    />
  );
};

SelectableSortableTable.propTypes = {
  name: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
      align: PropTypes.string,
    })
  ),
  data: tableRowsPropTypes,
  handleSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  sortKey: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
};

SelectableSortableTable.defaultProps = {
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  headers: [],
  data: [],
  sortKey: '',
};

export default SelectableSortableTable;
