import { styled } from 'styled-components';

export const LeagueStatsContainer = styled.div`
  div:has(.breadcrumb) {
    padding-bottom: 0;
  }

  .active.section {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .async-csv {
    position: absolute;
  }

  // TODO: remove once the TextField's placeholder color has changed on RC
  .buttons input::placeholder {
    color: #808080;
  }
`;

export const PaginationContainer = styled.div`
  padding-bottom: 0.9375rem;
  padding-right: 0.9375rem;
`;

LeagueStatsContainer.displayName = 'LeagueStatsContainer';
PaginationContainer.displayName = 'PaginationContainer';
