import { reverse, sortBy } from 'lodash';

import {
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
  FIELD_BORDER_PADDING,
} from '../../utils/constants/charting';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import { ROTATE_SCALE_ZOOM_DEFAULTS } from '../../utils/visualisations/rotateScaleZoom';
import { getObjectColor } from '../../utils/visualisations/visPalettes';
import {
  HAVOC_FIELD_SIZES_X,
  HAVOC_Y_MODE_FIELD,
} from './HavocChart.constants';
import { API_EVENT_TYPE_KEYS } from '../../utils/constants/api';

const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;

const getFieldSize = (orientation, isFieldYRelative, fieldFocusMode) => {
  const horizontal = orientation === ROTATIONS.HORIZONTAL;
  const fieldYYds = isFieldYRelative
    ? FIELD_Y_YARDS_RELATIVEY
    : FIELD_Y_YARDS + FIELD_BORDER_PADDING * 2;

  const { LOS: fieldLoS, END: fieldPostLoS } =
    HAVOC_FIELD_SIZES_X[fieldFocusMode];
  const fieldXYds = fieldLoS + fieldPostLoS;

  const fieldSizeY = fieldYYds * pxPerYard;
  const fieldSizeX = fieldXYds * pxPerYard;
  const fieldArea = {
    width: horizontal ? fieldSizeX : fieldSizeY,
    height: horizontal ? fieldSizeY : fieldSizeX,
    fieldXYds,
    fieldLoS,
    fieldYYds,
    fieldSizeX,
    fieldSizeY,
  };

  return fieldArea;
};

/* Get settings for Rotate / Scale / Zoom function
 */
const getRotationSettings = (width, height, orientation) => {
  const vbw = orientation === ROTATIONS.HORIZONTAL ? width : height;
  const vbh = orientation === ROTATIONS.HORIZONTAL ? height : width;
  return {
    ...ROTATE_SCALE_ZOOM_DEFAULTS,
    viewPortWidth: vbw,
    viewPortHeight: vbh,
    fieldWidth: width,
    fieldHeight: height,
    targetFieldX: width / 2,
    targetFieldY: height / 2,
    orientation,
  };
};

const prepHavocData = (havocData, displayYMode) => {
  const scaledData = havocData.map((h) => {
    const x = h.xLoS * pxPerYard;
    const y =
      displayYMode === HAVOC_Y_MODE_FIELD.value
        ? h.yRaw * pxPerYard
        : h.yLoS * pxPerYard;

    const xTarget = h.targetXLoS * pxPerYard;
    const yTarget =
      displayYMode === HAVOC_Y_MODE_FIELD.value
        ? h.targetYRaw * pxPerYard
        : h.targetYLoS * pxPerYard;

    const defenderPath =
      h?.defenderPath?.map((point) => [
        point.xLoS * pxPerYard,
        point.yLoS * pxPerYard,
      ]) || [];
    /* 
    For tackles (inc. sacks) path often lacks the final location, so add it to the path 
      this should be the end of the path as it ends the play
    For pressures/disruptions the path can continue beyond so this adjustment must not be added
    */
    if (
      h.havocType === API_EVENT_TYPE_KEYS.SACK ||
      h.havocType === API_EVENT_TYPE_KEYS.TACKLE
    ) {
      defenderPath.push([x, y]);
    }
    return { ...h, x, y, xTarget, yTarget, defenderPath };
  });
  return scaledData;
};

const formatHeatmapData = (havocData, displayYMode, fieldArea) => {
  const sortedTackles = sortBy(havocData, 'y');
  const formattedTackles = sortedTackles.map((tackleDatum) => {
    const heatmapX = tackleDatum.x / pxPerYard + fieldArea.fieldLoS;

    const heatmapY =
      displayYMode === HAVOC_Y_MODE_FIELD.value
        ? tackleDatum.y / pxPerYard
        : tackleDatum.y / pxPerYard + FIELD_Y_YARDS_RELATIVEY / 2;
    return {
      heatmapX,
      heatmapY,
    };
  });
  return formattedTackles;
};

const addPlayerColor = (players, visPalette) => {
  const coloredPlayers = reverse(sortBy(players, 'freq')).map((c, i) => ({
    ...c,
    color: getObjectColor(visPalette, i),
  }));
  return coloredPlayers;
};

export {
  getFieldSize,
  getRotationSettings,
  prepHavocData,
  formatHeatmapData,
  addPlayerColor,
};
