import { styled } from 'styled-components';
import { DROPDOWN_CONTAINER_WIDTH } from './DropdownFilter.constants';

export const DropdownContainer = styled.div`
  position: relative;

  &.${DROPDOWN_CONTAINER_WIDTH.NARROW} {
    min-width: 8.125rem;
  }

  &.${DROPDOWN_CONTAINER_WIDTH.DEFAULT} {
    min-width: 13.75rem;
  }

  &.${DROPDOWN_CONTAINER_WIDTH.WIDE} {
    min-width: 18.75rem;
  }

  &.${DROPDOWN_CONTAINER_WIDTH.EXTRA_WIDE} {
    min-width: 28.125rem;
  }
`;

export const DropdownLabel = styled.div`
  position: absolute;
  top: -0.5625rem;
  z-index: 2;
  left: 8px;
  font-size: 0.6875rem;
  padding: 0 2px;
  color: ${({ theme }) => theme.colours.ink.primary.strong};
  text-transform: capitalize;
`;

export const StyledOption = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const customDropdownStyles = (
  theme,
  isStaticMenu,
  isMulti,
  containedMenu
) => {
  const { ink, canvas, graphics } = theme.colours;

  return {
    control: (base, state) => ({
      ...base,
      '&:active': {
        borderColor: theme.colours.interface.main,
      },
      '&:hover': {
        borderColor: theme.colours.interface.main,
        cursor: 'pointer',
      },
      height: !isMulti && '1.875rem',
      minHeight: '1.875rem',
      backgroundColor: state.menuIsOpen
        ? canvas.tertiary.main
        : canvas.secondary.main,
      borderColor: state.menuIsOpen ? canvas.primary.main : 'transparent',
      borderRadius: '2px',
      boxShadow: state.menuIsOpen ? theme.effects.dropShadow.regular : 'none',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '8px 2px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingLeft: '2px',
      color: graphics.primary.main,
      '&:hover': {
        color: graphics.primary.main,
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '28px',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    input: (base) => ({
      ...base,
      fontSize: '0.8125rem',
      gridArea: '1/1/3/3',
      color: ink.primary.strong,
    }),
    menu: (base) => ({
      ...base,
      marginTop: '1px',
      paddingTop: 0,
      zIndex: 4,
      fontSize: '0.83125rem',
      position: isStaticMenu ? 'static' : 'absolute',
      width: containedMenu && '100%',
      minWidth: !containedMenu && '100%',
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: canvas.tertiary.main,
    }),
    option: (base, state) => ({
      ...base,
      color: ink.primary.main,
      '&:active': {
        backgroundColor: canvas.secondary.weak,
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: canvas.secondary.strong,
      },
      backgroundColor: state.isSelected
        ? canvas.secondary.main
        : canvas.tertiary.main,
      height: '1.875rem',
      minHeight: '1.875rem',
      fontSize: '0.83125rem',
    }),
    placeholder: (base) => ({
      ...base,
      height: 'inherit',
      position: 'absolute',
      top: isMulti ? '0.5rem' : '0.3125rem',
    }),
    singleValue: (base) => ({
      ...base,
      height: 'inherit',
      position: 'absolute',
      top: '0.3125rem',
      fontSize: '0.83125rem',
      color: ink.primary.weak,
    }),
    multiValue: (base) => ({
      ...base,
      display: 'inline-flex',
      backgroundColor: theme.colours.interface.main,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: theme.colours.interface.ink,
      backgroundColor: theme.colours.interface.main,
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: theme.colours.interface.ink,
      backgroundColor: theme.colours.interface.main,
      ':hover': {
        backgroundColor: canvas.secondary.strong,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flow-root',
      height: !isMulti && '1.75rem',
      lineHeight: !isMulti && '1.6',
      fontSize: '0.83125rem',
    }),
  };
};
