import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@statsbomb/kitbag-components';
import { AccordionHeader, AccordionBody } from './Accordion.styles';
import useQueryString from '../../utils/hooks/useQueryString';

const AccordionTile = ({
  id,
  header,
  body,
  isExpandedDefault,
  isMount,
  saveState,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
  const [isExpandedQS, setIsExpandedQS] = useQueryString(id, isExpandedDefault);
  const expanded = saveState ? isExpandedQS : isExpanded;

  const bodyContent = isMount ? body : isExpanded && body;
  const handleClick = () => {
    if (saveState) {
      setIsExpandedQS(!isExpandedQS);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div data-testid="AccordianSegmentT">
      {header && (
        <AccordionHeader
          aria-expanded={expanded}
          $isExpanded={expanded}
          onClick={handleClick}
          data-testid="AccordianHeaderT"
        >
          <Icon variant="ChevronDown" colour="primary.main" />
          {header}
        </AccordionHeader>
      )}
      <AccordionBody $isExpanded={expanded} data-testid="AccordianBodyT">
        {bodyContent}
      </AccordionBody>
    </div>
  );
};

AccordionTile.propTypes = {
  id: PropTypes.string,
  header: PropTypes.node,
  body: PropTypes.node,
  // is the accordion expanded by default?
  isExpandedDefault: PropTypes.bool,
  // do we mount/load the content of the accordion once expanded or not?
  isMount: PropTypes.bool,
  saveState: PropTypes.bool,
};

AccordionTile.defaultProps = {
  id: 'accordion',
  header: null,
  body: <></>,
  isExpandedDefault: false,
  isMount: false,
  saveState: false,
};

export default AccordionTile;
