import { max, maxBy } from 'lodash';
import {
  COMPETITION_LEVEL,
  CENTER_FIELD_Y,
  LEFT_HASH_NCAA,
  LEFT_HASH_NFL,
  RIGHT_HASH_NCAA,
  RIGHT_HASH_NFL,
  FIELD_Y_YARDS_RELATIVEY,
  FIELD_Y_YARDS,
  getFieldRelativeYSplits,
  getFieldYSplits,
} from '../../../utils/constants/charting';

const HASH_BANDS = {
  leftHash: 'Left Hash Area',
  rightHash: 'Right Hash Area',
  leftCenter: 'Left of Center',
  rightCenter: 'Right of Center',
};

const OUT_OF_ZONE_DISPLAY = 0.25; // yds
const HEATMAP_Y_SPLITS = 50; // semi-arbitrary value ~ approximately 1 yd spacing

export const getBandedData = (data) => ({
  all: data,
  leftHash: data.filter((b) => b.hashBand === HASH_BANDS.leftHash),
  leftCenter: data.filter((b) => b.hashBand === HASH_BANDS.leftCenter),
  rightCenter: data.filter((b) => b.hashBand === HASH_BANDS.rightCenter),
  rightHash: data.filter((b) => b.hashBand === HASH_BANDS.rightHash),
});

const getHashBand = (y, competitionLevel) => {
  /* Work out which hashband the datum belongs to */
  const leftHash =
    competitionLevel === COMPETITION_LEVEL.NFL ? LEFT_HASH_NFL : LEFT_HASH_NCAA;
  const rightHash =
    competitionLevel === COMPETITION_LEVEL.NFL
      ? RIGHT_HASH_NFL
      : RIGHT_HASH_NCAA;
  let hashBand = 'Out of Bounds';
  if (y <= leftHash) {
    hashBand = HASH_BANDS.leftHash;
  }
  if (y >= rightHash) {
    hashBand = HASH_BANDS.rightHash;
  }
  if (y <= CENTER_FIELD_Y && y > leftHash) {
    hashBand = HASH_BANDS.leftCenter;
  }
  if (y > CENTER_FIELD_Y && y < rightHash) {
    hashBand = HASH_BANDS.rightCenter;
  }

  return hashBand;
};

const zoneClampX = (x, minX, maxX) => {
  if (x < minX) {
    // move any dots out of the range to "just" out of frame
    return minX - OUT_OF_ZONE_DISPLAY;
  }
  if (x > maxX) {
    // correct for bad collection data, x should never be beyond LoS
    return maxX;
  }
  return x;
};

const zoneClampY = (y, minY, maxY) => {
  if (y < minY) {
    // move any dots out of the range to "just" out of frame
    return minY - OUT_OF_ZONE_DISPLAY;
  }
  if (y > maxY) {
    // move any dots out of the range to "just" out of frame
    return maxY + OUT_OF_ZONE_DISPLAY;
  }
  return y;
};

const formatSnapData = (snapNode, competitionLevel) => {
  const snapDatum = snapNode.node;

  const hashBand = getHashBand(snapDatum.y, competitionLevel);

  const offensivePosition =
    !snapDatum?.freezeFrames ||
    snapDatum.freezeFrames?.count === 0 ||
    snapDatum.freezeFrames[0]?.player?.teamPosition?.offensive !== false;
  let modifiedDatum = {};
  if (!offensivePosition) {
    const rotatedY = FIELD_Y_YARDS - snapDatum.y;

    const snapFF = snapDatum.freezeFrames && snapDatum.freezeFrames[0];
    const modifiedFFs = [];
    if (snapFF) {
      const rotatedFFY = FIELD_Y_YARDS - snapFF.y;
      const rotatedFFX = 2 * snapDatum.x - snapFF.x; // yardline - distance from yardline
      modifiedFFs.push({ ...snapFF, y: rotatedFFY, x: rotatedFFX });
    }
    modifiedDatum = { ...snapDatum, y: rotatedY, freezeFrames: modifiedFFs };
  } else {
    modifiedDatum = { ...snapDatum };
  }

  const snapRelativeY = modifiedDatum.freezeFrames
    ? modifiedDatum.freezeFrames[0].y - modifiedDatum.y
    : 0;
  const snapRelativeX = modifiedDatum.freezeFrames
    ? modifiedDatum.freezeFrames[0].x - modifiedDatum.x
    : 0;
  const bandedDatum = {
    ...modifiedDatum,
    snapRelativeY,
    snapRelativeX,
    hashBand,
    offensivePosition,
  };
  return bandedDatum;
};

export const snapsFromZone = (
  snapData,
  isRelativeY,
  selectedZones,
  competitionLevel
) => {
  if (selectedZones && selectedZones.length > 0) {
    // first filter down to relevant hash band
    let snapZonedData = snapData;
    if (selectedZones[0].hashBand) {
      snapZonedData = snapData.filter(
        (p) => p.hashBand === selectedZones[0].hashBand
      );
    }
    // work out which splits in the column are relevant
    const selectedZoneIndexes = selectedZones.map((m) => m.zoneIndex);
    const ySplits = isRelativeY
      ? getFieldRelativeYSplits()
      : getFieldYSplits(competitionLevel);
    let selectedYSplits = ySplits.filter((a) =>
      selectedZoneIndexes.includes(a.zoneIndex)
    );
    // relative y splits are in yds from a baseline, adjust to relevant to Y0
    if (isRelativeY) {
      selectedYSplits = selectedYSplits.map((z) => ({
        ...z,
        y: z.y - FIELD_Y_YARDS_RELATIVEY / 2,
      }));
    }
    // get the snaps in each selectected split, then merge them to get full array of snaps
    const zonesOfSnapZonedData = selectedYSplits.map((z) => {
      const yMax = z.y + +z.height;
      const snapsInZone = isRelativeY
        ? snapZonedData.filter(
            (f) => f.snapRelativeY >= z.y && f.snapRelativeY <= yMax
          )
        : snapZonedData.filter(
            (f) => f.freezeFrames[0].y >= z.y && f.freezeFrames[0].y <= yMax
          );
      return snapsInZone;
    });
    // eslint-disable-next-line prefer-spread
    const zoneSnaps = [].concat.apply([], zonesOfSnapZonedData);
    return zoneSnaps;
  }
  return snapData;
};

export const getHeatmapSplits = (
  n,
  playerData,
  showDy,
  splitHeight,
  zoneMinY
) => {
  const splitY = zoneMinY + n * splitHeight;
  const searchR = 1.5; // yds radius to search within ()
  const split = { n, nFrac: n / HEATMAP_Y_SPLITS };

  if (showDy) {
    split.count = playerData.filter(
      (k) =>
        k.snapRelativeY >= splitY - searchR &&
        k.snapRelativeY <= splitY + searchR
    ).length;
  } else {
    split.count = playerData.filter(
      (k) =>
        k.freezeFrames[0].y >= splitY - searchR &&
        k.freezeFrames[0].y <= splitY + searchR
    ).length;
  }
  return split;
};

export const getHeatmapData = (data, showDy) => {
  const splitHeight =
    (showDy ? FIELD_Y_YARDS_RELATIVEY : FIELD_Y_YARDS) / HEATMAP_Y_SPLITS;
  const zoneMinY = showDy ? -0.5 * FIELD_Y_YARDS_RELATIVEY : 0;
  const splits = [...Array(HEATMAP_Y_SPLITS + 1).keys()]; // splits go from field min to field max
  const splitCounts = splits.map((f) =>
    getHeatmapSplits(f, data, showDy, splitHeight, zoneMinY)
  );
  const maxCount = maxBy(splitCounts, (o) => o.count).count;
  return { splitCounts, maxCount };
};

export const getBandedHeatmapData = (bandedData, showDy) => {
  // get the individual heatmap data for each band
  const a = getHeatmapData(bandedData.all, showDy);
  const lh = getHeatmapData(bandedData.leftHash, showDy);
  const lc = getHeatmapData(bandedData.leftCenter, showDy);
  const rc = getHeatmapData(bandedData.rightCenter, showDy);
  const rh = getHeatmapData(bandedData.rightHash, showDy);
  // find the highest maxCount amongst the non-total bands
  const subMax = max([lh.maxCount, lc.maxCount, rc.maxCount, rh.maxCount]);
  const maxes = { totMaxCount: a.maxCount, subMaxCount: subMax };
  // return the data merged with info about other totals to enable cross band scaling
  const bandedHeatmapData = {
    all: { ...a, ...maxes },
    leftHash: { ...lh, ...maxes },
    leftCenter: { ...lc, ...maxes },
    rightCenter: { ...rc, ...maxes },
    rightHash: { ...rh, ...maxes },
  };

  return bandedHeatmapData;
};

export {
  HASH_BANDS,
  getHashBand,
  formatSnapData,
  OUT_OF_ZONE_DISPLAY,
  zoneClampX,
  zoneClampY,
  HEATMAP_Y_SPLITS,
};
