import {
  ROUTE_TYPE_CONFIG,
  ROUTE_TYPE_UNCLASSIFIED,
  ROUTE_TREE_BRANCH_DIRECTION,
  ROUTE_TREE_COORDINATES,
  ROUTE_TREE_COORDINATES_MIRRORED,
} from './RouteTree.constants';
import { ROTATIONS } from '../../utils/constants/charting';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';
import { fontVerticalCenterY } from '../text';

export const getTreeBranches = (routeSummary, visPalette, inRight) => {
  const coordinates = inRight
    ? ROUTE_TREE_COORDINATES
    : ROUTE_TREE_COORDINATES_MIRRORED;
  /* For branches, do not draw link to unclassified */
  const classifiedCoordinates = coordinates.filter(
    (c) => c.routeType !== ROUTE_TYPE_UNCLASSIFIED.apiKey
  );
  const branchData = classifiedCoordinates.map((branch) => {
    const branchValues = routeSummary[branch.routeType].branch;
    const stroke = visPalette.selectedObject;
    const { strokeWidth } = branchValues;
    const { strokeDash } = branchValues;

    const branchInfo = {
      ...branch,
      stroke,
      strokeWidth,
      strokeDash,
    };
    return branchInfo;
  });
  return branchData;
};

export const getTreeFruit = (routeSummary, visPalette, inRight, isDarkMode) => {
  const coordinates = inRight
    ? ROUTE_TREE_COORDINATES
    : ROUTE_TREE_COORDINATES_MIRRORED;

  const fruitData = coordinates.map((branch) => {
    const fruitValues = routeSummary[branch.routeType].fruit;
    const stroke = visPalette.selectedObject;
    const strokeWidth = 1;
    const fill = fruitValues.fillFunction(fruitValues.fillValue, isDarkMode);

    const fruitInfo = {
      ...branch,
      stroke,
      strokeWidth,
      fill,
      opacity: 1, // when we add selection mode this will become variable
    };
    return fruitInfo;
  });
  return fruitData;
};

const getTextRotation = (orientation) => {
  if (orientation === ROTATIONS.VERTICAL_UP) {
    return 'rotate(90)';
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    return 'rotate(-90)';
  }
  return 'rotate(0)';
};
const getTextAnchor = (inOut, inRight) => {
  if (inOut === ROUTE_TREE_BRANCH_DIRECTION.UP) {
    return 'middle';
  }
  const isIn = inOut === ROUTE_TREE_BRANCH_DIRECTION.IN;
  return isIn === inRight ? 'start' : 'end';
};

const labelSize = VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE;
const centerLabel = fontVerticalCenterY(labelSize);
const getTextBaseline = (routeTypeKey, orientation) => {
  /* Go label is special */
  if (routeTypeKey === ROUTE_TYPE_CONFIG.go.apiKey) {
    if (orientation === ROTATIONS.HORIZONTAL) {
      return centerLabel;
    }
    if (orientation === ROTATIONS.VERTICAL_UP) {
      return 0;
    }
    return labelSize;
  }
  /* Other labels */
  if (orientation === ROTATIONS.VERTICAL_UP) {
    return centerLabel;
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    return -centerLabel;
  }
  return 0;
};

export const getTreeLabels = (
  visPalette,
  inRight,
  orientation,
  routesSummary
) => {
  const coordinates = inRight
    ? ROUTE_TREE_COORDINATES
    : ROUTE_TREE_COORDINATES_MIRRORED;

  const labelData = coordinates.map((branch) => {
    const config = ROUTE_TYPE_CONFIG[branch.routeType];
    const values = routesSummary[branch.routeType];

    const labelInfo = {
      ...branch,
      name: config.name,
      fill: visPalette.text.subHeader,
      rotation: getTextRotation(orientation),
      anchor: getTextAnchor(config.direction, inRight),
      y: getTextBaseline(config.apiKey, orientation),
      tooltip: values.tooltip,
    };
    return labelInfo;
  });
  return labelData;
};
