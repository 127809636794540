import React, { useState, useEffect } from 'react';
import { useReactiveVar, useQuery, gql } from '@apollo/client';
import { Message, Loader } from 'semantic-ui-react';
import {
  Dropdown,
  Checkbox,
  Toggle,
  Button,
  ButtonGroup,
  Grid,
  Icon,
} from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import TableTile from '../../../components/TableTile/TableTile';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import { mf_PlayerDetails, mf_TeamDetails } from '../../../apollo';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import Tile from '../../../components/Tile/Tile';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  getRadarStatsLeagueAverageQuery,
  getRadarStatsQuery,
} from './getPlayerRadarStats';
import { useGetTemplateDefinitions } from './PlayerRadar.hooks';
import {
  formatTemplateStatsAsRadarAxes,
  getKeyInfo,
  getTableData,
  getTemplateDropdownOptions,
} from './PlayerRadar.dataManipulation';
import {
  RadarContainer,
  TileToolbar,
  RadarContentLayout,
  TempRadarStyles,
  InlineMessage,
  WithDivider,
  RadarExportContainer,
} from './PlayerRadar.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import { useGetPlayerStatPositionUsage } from '../../../utils/hooks/useGetPlayerStatPositionUsage';
import PositionFilter from '../../../components/PositionFilter/PositionFilter';
import Tooltip from '../../../components/Tooltip/Tooltip';
import PageHeader from '../../../components/PageHeader/PageHeader';

const PlayerRadar = () => {
  // selected player
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const playerName = mf_PlayerDetailsRV?.name;
  const playerId = mf_PlayerDetailsRV?.id || 0;
  const seasonInfo = useExportSeasonInfo();

  // filtering
  const [showLeagueAverage, setShowLeagueAverage] = useQueryString(
    'average',
    false
  );
  const [displayKey, setDisplayKey] = useQueryString('key', true);
  const [showTable, setShowTable] = useQueryString('table', true);
  const [showTrait, setShowTrait] = useQueryString('trait', true);
  const [showPerf, setShowPerf] = useQueryString('perf', true);
  const [allPlays, setAllPlays] = useQueryString('all', true);

  // alignment position for queries etc.
  const [alignmentPositionsApiCodes, setAlignmentPositionsApiCodes] = useState(
    []
  );

  /* Position Usages ~ how many plays per position a player was in ~ for alignment filter */
  const { playerStatsData, loading: isPlayerPositionUsageLoading } =
    useGetPlayerStatPositionUsage({
      selectedPlayerId: playerId,
      useContextFilters: false,
    });
  const positionUsages =
    playerStatsData?.length > 0 ? playerStatsData[0].positions : [];

  const {
    templates,
    loading: isConfigLoading,
    error: configError,
  } = useGetTemplateDefinitions();

  /* variant formatting for the drop down list */
  const templateOptions = getTemplateDropdownOptions(templates);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const [showNoTemplateError, setShowNoTemplateError] = useState(false);
  const [useTeamColors, setUseTeamColors] = useQueryString('team', true);

  const teamColors = useTeamColors
    ? {
        primary: mf_TeamDetailsRV?.colorPrimary,
        secondary: mf_TeamDetailsRV?.colorSecondary,
        tertiary: mf_TeamDetailsRV?.colorTertiary,
      }
    : null;

  /* Can't set the default selectedTemplate until you have the player's details */
  useEffect(() => {
    const playerRole = mf_PlayerDetailsRV?.mostCommonPosition?.generalPosition;

    const defaultTemplate =
      playerRole && templates?.find((t) => t.defaultFor.includes(playerRole));
    if (defaultTemplate?.name) {
      setSelectedTemplateName(defaultTemplate?.name);
      setShowNoTemplateError(false);
    } else {
      setSelectedTemplateName('None');
      setShowNoTemplateError(true);
    }
  }, [mf_PlayerDetailsRV, isConfigLoading]);

  /* Selected template and stats */
  const selectedTemplate = templates?.find(
    (t) => t.name === selectedTemplateName
  );
  const templateNames = selectedTemplate?.stats
    ?.concat(selectedTemplate?.secondaryStats)
    ?.map((m) => m.name);

  // Player position chips
  const specificPositions = selectedTemplate?.specificPositions;
  const defaultRosterPositions = selectedTemplate?.defaultFor;
  useEffect(() => {
    if (!isEmpty(specificPositions)) {
      setAlignmentPositionsApiCodes(specificPositions);
    }
  }, [specificPositions]);

  /* From the stats, get the player and League Average values */
  const playerStatsGQLString = getRadarStatsQuery(templateNames);
  const playerStatsLAGQLString = getRadarStatsLeagueAverageQuery(templateNames);

  // Player Stats
  const {
    data: dataStats,
    loading: isStatsLoading,
    error: statsError,
  } = useQuery(gql(playerStatsGQLString), {
    variables: {
      specificPositions: allPlays ? [] : alignmentPositionsApiCodes,
    },
    headers: {
      'Accept-Version': 'v2023q2',
    },
  });
  if (statsError) {
    console.error(ERROR_CODES.GET_RADAR_STATS, statsError);
  }

  const isError = !!configError || !!statsError;
  const isLoading =
    isConfigLoading || isStatsLoading || isPlayerPositionUsageLoading;

  // League Average
  const {
    data: dataStatsLA,
    loading: isStatsLoadingLA,
    error: statsLAError,
  } = useQuery(gql(playerStatsLAGQLString), {
    skip: !showLeagueAverage,
    variables: {
      specificPositions: allPlays ? [] : alignmentPositionsApiCodes,
      rosterPositions: allPlays ? defaultRosterPositions : [],
    },
    headers: {
      'Accept-Version': 'v2023q2',
    },
  });
  if (statsLAError) {
    console.error(ERROR_CODES.GET_RADAR_STATS_LA, statsLAError);
  }

  const radarStats = dataStats?.playerStats?.items?.[0];
  const radarStatsLA = dataStatsLA?.playerStats?.items?.[0];

  // Extract trait and performance data
  const traitTemplate = selectedTemplate?.secondaryStats?.map((m) => m.name);
  const perfTemplate = selectedTemplate?.stats?.map((m) => m.name);
  const radarTraitStats = {};
  const radarPerfStats = {};
  const radarTraitStatsLA = {};
  const radarPerfStatsLA = {};

  // prepare player data separate from the long league average query
  if (dataStats && selectedTemplate) {
    traitTemplate.forEach((attr) => {
      radarTraitStats[attr] = radarStats[attr];
    });
    radarTraitStats.plays = radarStats?.plays;

    perfTemplate.forEach((attr) => {
      radarPerfStats[attr] = radarStats[attr];
    });
    radarPerfStats.plays = radarStats?.plays;
  }

  if (dataStatsLA && selectedTemplate) {
    traitTemplate.forEach((attr) => {
      radarTraitStatsLA[attr] = radarStatsLA[attr];
    });
    radarTraitStatsLA.plays = radarStatsLA?.plays;

    perfTemplate.forEach((attr) => {
      radarPerfStatsLA[attr] = radarStatsLA[attr];
    });
    radarPerfStatsLA.plays = radarStatsLA?.plays;
  }

  const radarTraitComparison = showLeagueAverage && radarTraitStatsLA;
  const radarPerfComparison = showLeagueAverage && radarPerfStatsLA;

  const templateConfigTrait = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.secondaryStats
  );
  const templateConfigPerf = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.stats
  );

  // Trait Tabular Information
  const {
    radarTableData: traitTableData,
    radarTableHeaders: traitTableHeaders,
  } = getTableData(
    templateConfigTrait,
    showLeagueAverage,
    radarTraitStats,
    radarTraitComparison
  );
  // Performance Tabular Information
  const { radarTableData: perfTableData, radarTableHeaders: perfTableHeaders } =
    getTableData(
      templateConfigPerf,
      showLeagueAverage,
      radarPerfStats,
      radarPerfComparison
    );

  // Trait Key Inf
  const traitKeyInfo = displayKey
    ? getKeyInfo(
        allPlays ? [] : alignmentPositionsApiCodes,
        allPlays ? defaultRosterPositions : [],
        playerName,
        mf_TeamDetailsRV?.name,
        radarTraitStats,
        showLeagueAverage ? radarTraitComparison : null
      )
    : null;
  // Performance Key Info
  const perfKeyInfo = displayKey
    ? getKeyInfo(
        allPlays ? [] : alignmentPositionsApiCodes,
        allPlays ? defaultRosterPositions : [],
        playerName,
        mf_TeamDetailsRV?.name,
        radarPerfStats,
        showLeagueAverage ? radarPerfComparison : null
      )
    : null;

  function info2Copy() {
    const posTypeName = allPlays ? 'Roster Positions' : 'Alignment Positions';
    if (showLeagueAverage) {
      return `Versus League Average, ${posTypeName}`;
    }
    return posTypeName;
  }

  const templateTooltipContent = (
    <>
      <p>
        The template controls which metrics are displayed on the radar spokes.
      </p>
      <p>
        To only show data when a player lined up at a certain position, use the
        &lsquo;By Alignment&rsquo; filter and select a position.
      </p>
    </>
  );
  const templateLabel = (
    <>
      <span>Template</span>
      <Tooltip
        content={templateTooltipContent}
        target={<Icon variant="Info" size="small" colour="primary.main" />}
      />
    </>
  );

  return (
    <div className="playerRadar">
      <TempRadarStyles>
        <KitbagPageGridHolder>
          <Grid container={false} page>
            <Grid item xs={12}>
              <SidebarRightLayout
                $sidebarWidth="268px"
                $gap="0.5rem"
                $padding="0"
              >
                <div>
                  <PageHeader
                    href="/player/overview/:leagues/:seasons/:teams/:players"
                    rootPage="Player"
                    activePage="Radar"
                  />
                  <Tile border="0" margin="0.5rem 0 0 0">
                    <Tile.Header>
                      <TileToolbar>
                        <>
                          {!isError && !isLoading && (
                            <KeyButton
                              showKey={displayKey}
                              handleShowKey={() => setDisplayKey(!displayKey)}
                            />
                          )}
                          <ExportModal
                            title={playerName}
                            secondaryTitle={selectedTemplateName}
                            info1={`${showTrait ? 'Trait' : ''} ${
                              showTrait && showPerf ? '/' : ''
                            } ${showPerf ? 'Performance' : ''} ${
                              showTrait && showPerf ? 'Radars' : 'Radar'
                            }`}
                            info2={info2Copy()}
                            info3={seasonInfo}
                            columns={showPerf && showTrait ? 2 : 1}
                            fileName={`${playerName}-${selectedTemplateName}-Radar`}
                            isDisabled={isLoading || isError}
                          >
                            <RadarExportContainer>
                              {showTrait && templateConfigTrait && (
                                <RadarChart
                                  radarId="radar-trait-export"
                                  templateConfig={templateConfigTrait}
                                  radarStatDatum={radarTraitStats}
                                  radarComparisonDatum={radarTraitComparison}
                                  teamColors={teamColors}
                                  keyInfo={traitKeyInfo}
                                />
                              )}
                              {showPerf && templateConfigPerf && (
                                <RadarChart
                                  radarId="radar-performance-export"
                                  templateConfig={templateConfigPerf}
                                  radarStatDatum={radarPerfStats}
                                  radarComparisonDatum={radarPerfComparison}
                                  teamColors={teamColors}
                                  keyInfo={perfKeyInfo}
                                />
                              )}
                            </RadarExportContainer>
                          </ExportModal>
                        </>
                      </TileToolbar>
                    </Tile.Header>
                    <Tile.Body $gap={0} $padding="0 1rem 1rem">
                      {isError && !isLoading && (
                        <Message negative>
                          There has been an error. Please contact support.
                        </Message>
                      )}
                      {showNoTemplateError && !isError && !isLoading && (
                        <Message negative>
                          There is currently no appropriate Radar Template for
                          players in this roster position.
                        </Message>
                      )}

                      <RadarContentLayout
                        $showDualRadar={showTrait && showPerf}
                      >
                        {templateConfigTrait && !isError && showTrait && (
                          <div>
                            <h3>{`${selectedTemplateName} / Trait`}</h3>
                            <RadarContainer $margin="auto">
                              <RadarChart
                                radarId="radar-trait"
                                templateConfig={templateConfigTrait}
                                radarStatDatum={radarTraitStats}
                                radarComparisonDatum={radarTraitComparison}
                                teamColors={teamColors}
                                keyInfo={traitKeyInfo}
                              />
                              {isStatsLoading && (
                                <Dimmer active style={{ maxWidth: '100%' }}>
                                  <Loader
                                    content="Loading Trait Data"
                                    inverted={false}
                                  />
                                </Dimmer>
                              )}
                              {showTable && (
                                <>
                                  <TableTile
                                    data={traitTableData}
                                    columnHeaders={traitTableHeaders}
                                    showColumnHeader
                                    fileName={`${playerName}-trait-data.csv`}
                                    iconSize="small"
                                    iconStyle={{
                                      position: 'absolute',
                                      right: 0,
                                      top: 0,
                                    }}
                                    tileTitle=" "
                                    withBorder
                                  />
                                  <br />
                                  <br />
                                </>
                              )}
                            </RadarContainer>
                          </div>
                        )}
                        {templateConfigPerf && !isError && showPerf && (
                          <div>
                            <WithDivider $showDualRadar={showTrait && showPerf}>
                              <h3>{`${selectedTemplateName} / Performance`}</h3>
                              <RadarContainer $margin="auto">
                                <RadarChart
                                  radarId="radar-performance"
                                  templateConfig={templateConfigPerf}
                                  radarStatDatum={radarPerfStats}
                                  radarComparisonDatum={radarPerfComparison}
                                  teamColors={teamColors}
                                  keyInfo={perfKeyInfo}
                                />
                                {isStatsLoading && (
                                  <Dimmer active style={{ maxWidth: '100%' }}>
                                    <Loader
                                      content="Loading Performance Data"
                                      inverted={false}
                                    />
                                  </Dimmer>
                                )}
                                {showTable && perfTableData && (
                                  <TableTile
                                    data={perfTableData}
                                    columnHeaders={perfTableHeaders}
                                    showColumnHeader
                                    fileName={`${playerName}-performance-data.csv`}
                                    iconSize="small"
                                    iconStyle={{
                                      position: 'absolute',
                                      right: 0,
                                      top: 0,
                                    }}
                                    tileTitle=" "
                                    withBorder
                                  />
                                )}
                              </RadarContainer>
                            </WithDivider>
                          </div>
                        )}
                      </RadarContentLayout>
                    </Tile.Body>
                  </Tile>
                </div>
                <div>
                  <FixedAside $width="304px">
                    {!isError && (
                      <AccordionTile
                        body={
                          <Tile.AccordionBody>
                            <Dropdown
                              id="template-choice-dropdown"
                              options={templateOptions}
                              label={templateLabel}
                              onChange={(selectedOption) => {
                                setShowNoTemplateError(false);
                                setSelectedTemplateName(selectedOption.value);
                              }}
                              value={templateOptions.find(
                                (opt) => opt.value === selectedTemplateName
                              )}
                              isSearchable={false}
                              isLoading={!templateOptions || isLoading}
                              menuPosition="static"
                            />
                            <Checkbox
                              id="show-trait-checkbox"
                              label="Trait"
                              onChange={() => setShowTrait(!showTrait)}
                              checked={showTrait}
                              disabled={!showPerf || isStatsLoading}
                            />
                            <Checkbox
                              id="show-performance-checkbox"
                              label="Performance"
                              onChange={() => setShowPerf(!showPerf)}
                              checked={showPerf}
                              disabled={!showTrait || isStatsLoading}
                            />
                          </Tile.AccordionBody>
                        }
                        isExpandedDefault
                        isMount
                      />
                    )}

                    <AccordionTile
                      header={
                        <Tile.AccordionHeader>Filters</Tile.AccordionHeader>
                      }
                      body={
                        <Tile.AccordionBody
                          style={{
                            minHeight: allPlays ? 'auto' : '120px',
                          }}
                        >
                          <>
                            <div>
                              <ButtonGroup>
                                <Button
                                  size="small"
                                  onClick={() => setAllPlays(true)}
                                  shape="pill"
                                  variant={allPlays ? 'primary' : 'ghost'}
                                  disabled={isLoading}
                                >
                                  All Plays
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => setAllPlays(false)}
                                  shape="pill"
                                  variant={!allPlays ? 'primary' : 'ghost'}
                                  disabled={isLoading}
                                >
                                  By Alignment
                                </Button>
                              </ButtonGroup>
                            </div>

                            {!allPlays && (
                              <PositionFilter
                                selectedPositions={alignmentPositionsApiCodes}
                                setSelectedPositions={
                                  setAlignmentPositionsApiCodes
                                }
                                isRosterPositions={false}
                                positionUsages={positionUsages}
                              />
                            )}
                          </>
                        </Tile.AccordionBody>
                      }
                      isExpandedDefault
                      isMount
                    />

                    <AccordionTile
                      header={
                        <Tile.AccordionHeader>Settings</Tile.AccordionHeader>
                      }
                      body={
                        <Tile.AccordionBody>
                          <Checkbox
                            id="show-table-checkbox"
                            label="Table"
                            onChange={() => setShowTable(!showTable)}
                            checked={showTable}
                          />
                          {isStatsLoadingLA ? (
                            <InlineMessage>
                              <Loader
                                active
                                size="small"
                                data-testid="league-average-spinner"
                                inline
                              />
                              Loading League Average
                            </InlineMessage>
                          ) : (
                            <Toggle
                              id="league-average-toggle"
                              label="Compare to League Average"
                              onChange={() =>
                                setShowLeagueAverage(!showLeagueAverage)
                              }
                              checked={showLeagueAverage}
                            />
                          )}
                          <Toggle
                            id="team-colours-toggle"
                            label="Use Team Colors"
                            onChange={() => setUseTeamColors(!useTeamColors)}
                            checked={useTeamColors}
                          />
                        </Tile.AccordionBody>
                      }
                      isExpandedDefault
                      isMount
                    />
                  </FixedAside>
                </div>
              </SidebarRightLayout>
            </Grid>
          </Grid>
        </KitbagPageGridHolder>
      </TempRadarStyles>
    </div>
  );
};

export default PlayerRadar;
