import { csIntensity, csValue } from '../../../utils/helpers/colorScales';
import {
  addGradientDots,
  addMeaningDots,
} from '../../../utils/visualisations/keys';
import { paletteIsDark } from '../../../utils/visualisations/visPalettes';
import { PASS_PLACEMENT_COLOR_MODES } from '../PassPlacement.constants';

export const drawPassPlacementKey = (
  keyG,
  coloringOption,
  visPalette,
  isDark,
  width,
  showFailsRing,
  showBallSize
) => {
  // Draw info block
  const valueDotsG = keyG.append('g');
  let values = [];
  if (!showFailsRing) {
    /* If not coloring */
    if (coloringOption === PASS_PLACEMENT_COLOR_MODES.LOCATION) {
      values = [
        {
          color: visPalette.objects.n1.main,
          name: 'Pass',
          opacity: showBallSize ? 0.4 : 1, // if not ball size dots are solid
        },
      ];
    }
    if (coloringOption === PASS_PLACEMENT_COLOR_MODES.SUCCESS) {
      values = [
        {
          color: visPalette.successFail.success.main,
          name: 'Complete Pass',
          opacity: showBallSize ? 0.4 : 1, // if not ball size dots are solid
        },
        {
          color: visPalette.successFail.fail.main,
          name: 'Incomplete Pass',
          opacity: showBallSize ? 0.4 : 1, // if not ball size dots are solid
        },
      ];
    }
  } else {
    // showing incomplete passes as hollow, so need distinction
    values = [
      {
        color:
          coloringOption === PASS_PLACEMENT_COLOR_MODES.SUCCESS
            ? visPalette.successFail.success.main
            : visPalette.objects.n1.main,
        name: 'Complete Pass',
        opacity: showBallSize ? 0.4 : 1, // if not ball size dots are solid
      },
      {
        color:
          coloringOption === PASS_PLACEMENT_COLOR_MODES.SUCCESS
            ? visPalette.successFail.fail.main
            : visPalette.objects.n1.main,
        name: 'Incomplete Pass',
        opacity: 0,
      },
    ];
  }

  addMeaningDots(valueDotsG, values, visPalette, width / 2, { dotsPerLine: 3 });

  /* If using a color gradient, add to key */
  const gradientSectionG = keyG
    .append('g')
    .attr('transform', `translate(${width / 2},0)`);
  if (coloringOption === PASS_PLACEMENT_COLOR_MODES.LENGTH) {
    addGradientDots(
      gradientSectionG,
      csIntensity,
      visPalette,
      isDark,
      width / 2,
      'Short',
      'Long'
    );
  }
  if (coloringOption === PASS_PLACEMENT_COLOR_MODES.PROXIMITY) {
    const isDarkMode = paletteIsDark(visPalette);
    const csValueNoMode = (v) => csValue(v, isDarkMode);
    addGradientDots(
      gradientSectionG,
      csValueNoMode,
      visPalette,
      isDark,
      width / 2,
      'Off Target',
      'On Target'
    );
  }
};
