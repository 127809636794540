import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { ClickableSVG } from '../../visualisation.styles';
import { useD3 } from '../../../utils/hooks/useD3';
import { RouteTreeStatDefinitionPropType } from '../RouteTree.propTypes';
import {
  ROUTE_TREE_KEY_SIZE,
  ROUTE_TREE_KEY_CLASSES,
} from './RouteTreeKey.constants';
import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import { drawBranchKey, drawFruitKey } from './RouteTreeKey.drawing';

const RouteTreeKey = ({ id, branchConfig, fruitConfig }) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const isDarkMode = theme.isDark;

  const vbWidth = ROUTE_TREE_KEY_SIZE.WIDTH;
  const vbHeight = ROUTE_TREE_KEY_SIZE.HEIGHT;
  const viewBox = `0 0 ${vbWidth} ${vbHeight}`;

  const ref = useD3((svg) => {
    svg.attr('width', '100%');
    svg.attr('id', id);
    svg.selectAll('*').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', ROUTE_TREE_KEY_CLASSES.BACKGROUND)
      .attr('fill', visPalette.background.main);

    const mainG = svg.append('g').attr('class', ROUTE_TREE_KEY_CLASSES.MAIN);
    const branchG = mainG
      .append('g')
      .attr('class', ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE);
    const fruitG = mainG
      .append('g')
      .attr('class', ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE)
      .attr(
        'transform',
        `translate(${
          ROUTE_TREE_KEY_SIZE.SECTION_WIDTH +
          ROUTE_TREE_KEY_SIZE.SECTION_SPACING
        },0)`
      );

    /* Branch Zone layers */
    branchG
      .append('g')
      .attr(
        'transform',
        `translate(0,${VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT})`
      )
      .attr('class', ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE_IMAGES);
    branchG
      .append('g')
      .attr(
        'transform',
        `translate(0,${
          VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT +
          ROUTE_TREE_KEY_SIZE.ICONS_SEGMENT
        })`
      )
      .attr('class', ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE_TEXT);

    /* Fruit Zone layers */
    fruitG
      .append('g')
      .attr(
        'transform',
        `translate(0,${VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT})`
      )
      .attr('class', ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE_IMAGES);
    fruitG
      .append('g')
      .attr(
        'transform',
        `translate(0,${
          VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT +
          ROUTE_TREE_KEY_SIZE.ICONS_SEGMENT
        })`
      )
      .attr('class', ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE_TEXT);
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    const backingRect = svg.select(`.${ROUTE_TREE_KEY_CLASSES.BACKGROUND}`);
    backingRect.attr('fill', visPalette.background.main);

    const branchG = svg.select(`.${ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE}`);
    drawBranchKey(branchG, visPalette, branchConfig);

    const fruitG = svg.select(`.${ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE}`);
    drawFruitKey(fruitG, visPalette, fruitConfig, isDarkMode);
  }, [isDarkMode, visPalette, branchConfig, fruitConfig]);

  return <ClickableSVG ref={ref} />;
};

RouteTreeKey.propTypes = {
  id: PropTypes.string,
  branchConfig: RouteTreeStatDefinitionPropType.isRequired,
  fruitConfig: RouteTreeStatDefinitionPropType.isRequired,
};

RouteTreeKey.defaultProps = {
  id: 'route-tree',
};

export default RouteTreeKey;
