const uiStructure = {
  // mainFilters: leagues, seasons, teams, players, games
  pages: [
    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons', 'teams'],
      urlParams: [':leagues', ':seasons', ':teams'],
      path: [''],
    },
    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons', 'teams', 'players'],
      urlParams: [':leagues', ':seasons', ':teams', ':players'],
      path: [
        'player',
        'player/overview',
        'player/snap',
        'player/passing',
        'player/placement',
        'player/radar',
      ],
    },
    {
      contextFilters: false,
      mainFilters: ['leagues', 'seasons', 'teams', 'players'],
      urlParams: [':leagues', ':seasons', ':teams', ':players'],
      path: ['player/comparison'],
    },

    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons', 'teams'],
      urlParams: [':leagues', ':seasons', ':teams'],
      path: ['team', 'team/box', 'team/passing'],
    },
    {
      contextFilters: false,
      mainFilters: ['leagues', 'seasons', 'teams'],
      urlParams: [':leagues', ':seasons', ':teams'],
      path: ['team/schedule'],
    },
    {
      contextFilters: false,
      mainFilters: [],
      urlParams: [],
      path: ['playfinder/wizard'],
    },
    {
      contextFilters: false,
      mainFilters: [],
      urlParams: [':leagues', ':seasons', ':teams'],
      path: ['playfinder/results'],
    },
    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons'],
      urlParams: [':leagues', ':seasons'],
      path: ['league', 'league/stats'],
    },
    {
      contextFilters: false,
      mainFilters: ['leagues', 'seasons'],
      urlParams: [':leagues', ':seasons'],
      path: ['scout', 'scout/players', 'scout/guide', 'scout/wizard'],
    },
    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons', 'teams', 'games'],
      urlParams: [
        ':leagues',
        ':seasons',
        ':teams',
        ':games',
        ':drives',
        ':plays',
      ],
      pageFilters: ['play', 'drive'],
      path: ['game/animation', 'game/event'],
    },
    {
      contextFilters: true,
      mainFilters: ['leagues', 'seasons', 'teams', 'games'],
      urlParams: [':leagues', ':seasons', ':teams', ':games', ':drives'],
      pageFilters: ['drive'],
      path: ['game/formations'],
    },
    {
      contextFilters: false,
      mainFilters: ['leagues', 'seasons', 'teams', 'games'],
      urlParams: [':leagues', ':seasons', ':teams', ':games'],
      path: ['game/byplay'],
    },
    {
      contextFilters: false,
      mainFilters: ['leagues', 'seasons'],
      urlParams: [':leagues', ':seasons'],
      path: ['safe', 'safe/palette'],
    },
    {
      contextFilters: false,
      mainFilters: [],
      urlParams: [],
      path: ['admin'],
    },
  ],
};

export { uiStructure as default };
