import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  ButtonIcon,
  TextField,
} from '@statsbomb/kitbag-components';
import { isFunction } from 'lodash';
import { InputRow, ValidatorRow } from './NumericInput.styles';
import { isStringValidNumber } from './NumericInput.dataManipulation';
import BannerError from '../Banners/Banner.Error';
import { NUMERIC_INPUT_ID } from './NumericInput.constants';

/*
This is the custom input option to combine with a slider where the fidelity is insufficient
It still expects a valid range
*/
const NumericInput = ({
  min,
  max,
  value,
  onSave,
  lowerIsBetter,
  formatter,
  valueFormatter,
  onCancel,
}) => {
  /* Displayed values should match user expectation */
  const displayMin = lowerIsBetter ? max : min;
  const displayMax = lowerIsBetter ? min : max;

  const [typedValue, setTypedValue] = useState(null);

  const stringFormatter = isFunction(formatter)
    ? formatter
    : (v) => v?.toString();
  const valueStringFormatter = isFunction(valueFormatter)
    ? valueFormatter
    : stringFormatter;
  const formattedValue = valueStringFormatter(value);

  useEffect(() => {
    setTypedValue(formattedValue);
  }, [value]);

  const { valid, message, numericValue } = isStringValidNumber(
    typedValue,
    min,
    max
  );

  /*
   Making it so when you are in the text field and hit enter you commit 
   If you escape you cancel
   Should be on textField but property isn't exposed in kitbag so done on wrapper
  */
  const textFieldKeyPress = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      if (valid) {
        onSave(numericValue);
      }
    }
    if (event.keyCode === 27) {
      onCancel();
    }
  };

  const saveButtonClick = () => {
    if (valid) {
      onSave(numericValue);
    }
  };

  return (
    <>
      <InputRow>
        <span>{stringFormatter(displayMin)}</span>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onKeyUp={textFieldKeyPress}>
          <ButtonGroup style={{ width: '100%' }}>
            <TextField
              id={NUMERIC_INPUT_ID}
              labelPosition="none"
              value={typedValue}
              onChange={(e) => setTypedValue(e.target.value)}
              placeholder={formattedValue}
              size="small"
            />
            <ButtonIcon
              onClick={saveButtonClick}
              variant="ghost"
              shape="pill"
              size="small"
              icon="TickDone"
              isDisabled={!valid}
              title="Save"
            />
            <ButtonIcon
              onClick={() => onCancel()}
              variant="ghost"
              shape="pill"
              size="small"
              icon="Close"
              title="Cancel"
            />
          </ButtonGroup>
        </div>
        <span>{stringFormatter(displayMax)}</span>
      </InputRow>
      {typedValue && !valid && (
        <ValidatorRow>
          <BannerError message={message} />
        </ValidatorRow>
      )}
    </>
  );
};

NumericInput.propTypes = {
  /* The smallest allowed value for the number (independent of whether smaller is "better") */
  min: PropTypes.number.isRequired,
  /* The largest allowed value for the number (independent of whether smaller is "better") */
  max: PropTypes.number.isRequired,
  /* The current value */
  value: PropTypes.number.isRequired,
  /* 
  For displaying the min and max of the range either side of the editable value.  
  When lowerIsBetter then the larger number (max) is displayed to the left
  */
  lowerIsBetter: PropTypes.bool,
  /* The function called when a valid number is saved. Passes back the value */
  onSave: PropTypes.func.isRequired,
  /* The function called when a valid number is saved. Passes back the value */
  onCancel: PropTypes.func.isRequired,
  /* 
  An optional function for formatting the supporting numeric values to strings 
  Falls back to toString()
  */
  formatter: PropTypes.func,
  /* 
  A secondary function for formatting the main numeric value to a string (sometimes want different fidelity)
  If not supplied, falls back to formatter
  */
  valueFormatter: PropTypes.func,
};

NumericInput.defaultProps = {
  lowerIsBetter: false,
  formatter: null,
  valueFormatter: null,
};

export default NumericInput;
