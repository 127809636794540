import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { csDivergent, csValue } from '../../../utils/helpers/colorScales';
import { appendText } from '../../text';

const KEY_WIDTH = 230;
const KEY_BAR_WIDTH = 40;
const KEY_BAR_HEIGHT = 120;
const KEY_BAR_PADDING = 10;
const KEY_AXIS_WIDTH = KEY_BAR_PADDING * 5 + KEY_BAR_WIDTH * 2;
const KEY_ARROW_X = KEY_AXIS_WIDTH + 40;
const CIRCLE_LABELS_VS_LEAGUE_AVERAGE = [
  'No Pressures',
  '50% the League Average',
  'League Average',
  '150% League Average',
  '200% League Average',
];

const barKey = (svgG, visPalette, compareTeamValues) => {
  appendText(svgG, visPalette, {
    message: 'Position/gap pressures over baseline',
    y: 10,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  const barKeyG = svgG.append('g').attr('transform', 'translate(0,20)');
  // filled bar
  barKeyG
    .append('rect')
    .attr('x', KEY_BAR_PADDING)
    .attr('y', 0)
    .attr('width', KEY_BAR_WIDTH)
    .attr('height', KEY_BAR_HEIGHT)
    .attr('fill', visPalette.zones.important);

  const teamBarX = KEY_BAR_PADDING * 4 + KEY_BAR_WIDTH;
  if (compareTeamValues) {
    barKeyG
      .append('rect')
      .attr('x', teamBarX)
      .attr('y', 0)
      .attr('width', KEY_BAR_WIDTH)
      .attr('height', KEY_BAR_HEIGHT)
      .attr('stroke', visPalette.contrast)
      .attr('stroke-width', 2)
      .attr('stroke-opacity', 0.8)
      .attr('fill', 'transparent');
  }

  // axis bar
  barKeyG
    .append('line')
    .attr('x1', '0')
    .attr('x2', KEY_AXIS_WIDTH)
    .attr('y1', '0')
    .attr('y2', '0')
    .attr('stroke', visPalette.axis)
    .attr('Stroke-width', 1);

  appendText(barKeyG, visPalette, {
    y: 135,
    x: 10,
    message: 'OL Filtered',
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  });
  if (compareTeamValues) {
    appendText(barKeyG, visPalette, {
      y: 135,
      x: teamBarX,
      message: 'Team Average',
      fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
    });
  }

  // descriptor Arrow
  barKeyG
    .append('line')
    .attr('x1', KEY_ARROW_X)
    .attr('x2', KEY_ARROW_X)
    .attr('y1', 0)
    .attr('y2', KEY_BAR_HEIGHT)
    .attr('stroke', visPalette.axis)
    .attr('Stroke-width', 1);
  barKeyG
    .append('path')
    .attr('d', `M${KEY_ARROW_X} ${KEY_BAR_HEIGHT} l-5 -10 l10 0 z`)
    .attr('fill', visPalette.axis)
    .attr('Stroke', 'none');
  appendText(barKeyG, visPalette, {
    transform: `translate(${
      KEY_ARROW_X + 20
    }, ${KEY_BAR_HEIGHT}) rotate(-90,0,0)`,
    message: 'More pressures conceded',
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL_SMALL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL_SMALL.WEIGHT,
  });
};

const colorKeyRelative = (svgG, visPalette, isDarkMode) => {
  appendText(svgG, visPalette, {
    message: 'Bar color',
    y: 10,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  const circlesG = svgG.append('g').attr('transform', 'translate(0,30)');
  const circleData = [0, 0.25, 0.5, 0.75, 1];
  circlesG
    .selectAll('circle')
    .data(circleData)
    .enter()
    .append('circle')
    .attr('cx', 20)
    .attr('cy', (d, i) => i * 20)
    .attr('r', 7)
    .attr('stroke', 'none')
    .attr('fill', (d) => csValue(1 - d, isDarkMode));

  circlesG
    .selectAll('text')
    .data(CIRCLE_LABELS_VS_LEAGUE_AVERAGE)
    .enter()
    .append('text')
    .attr('x', 35)
    .attr('y', (d, i) => i * 20 + 3)
    .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    .attr('font-family', DEFAULT_FONT)
    .attr('fill', visPalette.text.info)
    .text((d) => d);
};

const colorKeyDivergent = (svgG, visPalette, isDarkMode) => {
  appendText(svgG, visPalette, {
    message: 'Bar color',
    y: 10,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  const circlesG = svgG.append('g').attr('transform', 'translate(0,30)');
  const circleData = [0, 0.25, 0.5, 0.75, 1];
  circlesG
    .selectAll('circle')
    .data(circleData)
    .enter()
    .append('circle')
    .attr('cx', 20)
    .attr('cy', (d, i) => i * 20)
    .attr('r', 7)
    .attr('stroke', 'none')
    .attr('fill', (d) => csDivergent(d, isDarkMode));

  circlesG
    .selectAll('text')
    .data(CIRCLE_LABELS_VS_LEAGUE_AVERAGE)
    .enter()
    .append('text')
    .attr('x', 35)
    .attr('y', (d, i) => i * 20 + 3)
    .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    .attr('font-family', DEFAULT_FONT)
    .attr('fill', visPalette.text.info)
    .text((d) => d);
};

const colorKeyComparing = (svgG, visPalette) => {
  appendText(svgG, visPalette, {
    message: 'Bar color',
    y: 10,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  const circlesG = svgG.append('g').attr('transform', 'translate(0,30)');
  const circleData = [
    visPalette.successFail.superSuccess.main,
    visPalette.successFail.success.main,
    visPalette.successFail.neutral.main,
    visPalette.successFail.fail.main,
    visPalette.successFail.superFail.main,
  ];
  circlesG
    .selectAll('circle')
    .data(circleData)
    .enter()
    .append('circle')
    .attr('cx', 20)
    .attr('cy', (d, i) => i * 20)
    .attr('r', 7)
    .attr('stroke', 'none')
    .attr('fill', (d) => d);
  const circleLabels = [
    'Better than team and league average',
    'Better than team average',
    'Team Average',
    'Worse than team average',
    'Worse than team and League Average',
  ];
  circlesG
    .selectAll('text')
    .data(circleLabels)
    .enter()
    .append('text')
    .attr('x', 35)
    .attr('y', (d, i) => i * 20 + 3)
    .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    .attr('font-family', DEFAULT_FONT)
    .attr('fill', visPalette.text.info)
    .text((d) => d);
};

const sparklineKey = (svgG, visPalette) => {
  appendText(svgG, visPalette, {
    message: 'Pressures per 100 Plays (Pressure%)',
    y: 10,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });
  const circlesG = svgG.append('g').attr('transform', 'translate(0,30)');
  circlesG
    .append('circle')
    .attr('cx', 10)
    .attr('cy', 0)
    .attr('r', 5)
    .attr('stroke', 'none')
    .attr('fill', visPalette.positions.offense.ol.main);
  circlesG
    .append('circle')
    .attr('cx', 25)
    .attr('cy', 0)
    .attr('r', 5)
    .attr('stroke', 'none')
    .attr('fill', visPalette.positions.defense.dl.main);
  circlesG
    .append('text')
    .attr('x', 40)
    .attr('y', 3)
    .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('fill', visPalette.text.info)
    .text('League average for Position/Gap');
  circlesG
    .append('circle')
    .attr('cx', 10)
    .attr('cy', 20)
    .attr('r', 6)
    .attr('stroke', visPalette.contrast)
    .attr('stroke-width', 2)
    .attr('fill', 'transparent');
  circlesG
    .append('text')
    .attr('x', 40)
    .attr('y', 23)
    .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('fill', visPalette.text.info)
    .text('Position/Gap filtered Pressures');
};

export {
  KEY_WIDTH,
  barKey,
  colorKeyRelative,
  colorKeyDivergent,
  colorKeyComparing,
  sparklineKey,
};
