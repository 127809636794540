import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';
import { fontBaselineY } from '../text';

// general
export const BASE_VIS_WIDTH = 500;
export const BASE_BAR_HEIGHT = 16;
export const BAR_SIZE_RATIO = 1.5;
export const LARGE_BAR_HEIGHT = BASE_BAR_HEIGHT * BAR_SIZE_RATIO;

// axis top
export const AXIS_TOP = {
  GUIDE_GAP: 4,
  TICK_STEP: 0.05, // represents % value i.e 0.05 = 5%
};

// axis left
export const AXIS_LEFT = {
  TEXT_GAP: 20,
  TICK_OFFSET_Y: 4,
};

// bars
export const BARS = {
  BASE_HEIGHT: BASE_BAR_HEIGHT,
  LARGE_HEIGHT: LARGE_BAR_HEIGHT,
  BAR_GAP: 8,
  SECTION_GAP: 16,
  SECTION_Y_OFFSET: 6,
  OUTER_VERTICAL_PADDING: 8,
};

export const DELTA_TEXT_OFFSET = 24;

// bar indicator
export const BAR_INDICATOR = {
  WIDTH: 12,
  OFFSET: 3,
};

export const VIS_GAPS = {
  TOP: 12,
  BOTTOM: 16,
  LEFT: 130,
  RIGHT: BAR_INDICATOR.WIDTH + BAR_INDICATOR.OFFSET,
};

// fonts
export const FORMATION_SUMMARY_FONTS = {
  NORMAL: {
    SIZE: VISUALISATION_FONT_SETUPS.AXES_VALUES_SMALL.SIZE,
    WEIGHT: VISUALISATION_FONT_SETUPS.AXES_VALUES_SMALL.WEIGHT,
  },
  LARGE: {
    SIZE: VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE,
    WEIGHT: VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT,
  },
};

const CIRCLE_RADIUS = 5;
const CIRCLE_AMOUNT = 7;
const CIRCLE_X = CIRCLE_RADIUS * 2 + 4.5;
const CIRCLES_VERTICAL_PASSING = 5;
const LABELS_VERTICAL_PASSING = 15;
const KEY_HEADER_Y_OFFSET = fontBaselineY(
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
);

export const FORMATION_SUMMARY_KEY = {
  HEIGHT: 56,
  HEADER: {
    Y_OFFSET: KEY_HEADER_Y_OFFSET,
  },
  LABELS: {
    Y_OFFSET: fontBaselineY(
      VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
      VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT
    ),
    MAX_LABEL_X_OFFSET: CIRCLE_AMOUNT * CIRCLE_X - CIRCLE_RADIUS / 2,
    GROUP_Y_OFFSET:
      KEY_HEADER_Y_OFFSET + CIRCLE_RADIUS * 2 + LABELS_VERTICAL_PASSING,
  },
  CIRCLES: {
    RADIUS: CIRCLE_RADIUS,
    AMOUNT: CIRCLE_AMOUNT,
    STEP: 1 / (CIRCLE_AMOUNT - 1),
    Y: CIRCLE_RADIUS,
    X: CIRCLE_X,
    VERTICAL_PADDING: CIRCLES_VERTICAL_PASSING,
    GROUP_X_OFFSET: CIRCLE_RADIUS,
    GROUP_Y_OFFSET:
      CIRCLE_RADIUS + CIRCLES_VERTICAL_PASSING + KEY_HEADER_Y_OFFSET,
  },
};

export const SCALE_BOUNDS = {
  NCAA: {
    WORST: 0.17,
    BEST: 0.67,
  },
  NFL: {
    WORST: 0.37,
    BEST: 0.57,
  },
};
