import { styled } from 'styled-components';
import { CHART_MAX_WIDTH } from './TeamFormationDetail.constants';

export const FormationDetailPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

export const FormationDetailHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1.6rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};

  .title {
    display: flex;
    align-items: center;

    h1 {
      ${({ theme }) => theme.typography.headingLarge}
      color: ${({ theme }) => theme.colours.ink.primary.main};
      margin: 0;
      padding-right: 0.8rem;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.8rem;
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
    ${({ theme }) => theme.typography.bodyMedium}
  }

  .divider {
    height: 2.4rem;
    width: 1px;
    background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  }
`;

export const FormationDetailMainContent = styled.div`
  display: flex;
  gap: 0.8rem;
  width: 100%;

  // chart & table tiles
  > div {
    flex: 1;
    align-self: flex-start;
    margin: 0;
  }

  // chart tile
  > div:nth-of-type(1) {
    align-self: flex-start;
    min-width: 500px;
    max-width: min(45%, ${CHART_MAX_WIDTH}px);
  }

  // table tile
  > div:nth-of-type(2) {
    align-self: flex-start;
    min-width: 0;

    .section-body {
      padding: 0;
      gap: 0;
    }
  }

  .section-body {
    min-height: 30rem;
    border-top: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  }
`;

export const ChartFooter = styled.div`
  display: flex;
  gap: 0.8rem;
  width: 100%;
  max-height: 18.8rem;

  // depth chart table
  > div:last-of-type {
    height: auto;
    width: 60%;
  }
`;

export const StatBlockContainer = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
`;

export const Prompt = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};

  path {
    fill: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }

  .prompt-message {
    text-align: center;
    padding: 0 0.8rem;
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
    ${({ theme }) => theme.typography.bodySmall}
  }
`;

FormationDetailPage.displayName = 'FormationDetailPage';
FormationDetailHeader.displayName = 'FormationDetailHeader';
FormationDetailMainContent.displayName = 'FormationDetailMainContent';
StatBlockContainer.displayName = 'StatBlockContainer';
ChartFooter.displayName = 'ChartFooter';
Prompt.displayName = 'Prompt';
