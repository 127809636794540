import { styled } from 'styled-components';

export const OffensiveLinePositionDropdown = styled.div`
  width: 220px;
  float: left;
  margin-right: 25px;
  margin-bottom: 10px;
`;
OffensiveLinePositionDropdown.displayName = 'OffensiveLinePositionDropdown';

export const PassPressuresVisHolder = styled.div`
  min-width: 400px;
  width: 100%;
  max-width: 1210px;
  float: left;
  margin-bottom: 16px;
`;
PassPressuresVisHolder.displayName = 'PassPressuresVisHolder';

export const PassPressuresKeyHolder = styled.div`
  max-width: ${({ $maxWidth }) => $maxWidth || '220px'};
  margin-right: 0px;
  margin-left: ${({ $marginLeft }) => $marginLeft || 'auto'};
`;
PassPressuresKeyHolder.displayName = 'PassPressuresKeyHolder';

export const PassPressuresDropdowns = styled.div`
  min-height: 62px;
`;
PassPressuresDropdowns.displayName = 'PassPressuresDropdowns';

export const PassPressuresVisWrapper = styled.div`
  width: 100%;
  display: table;
  margin-top: 0.5rem;
`;
PassPressuresVisWrapper.displayName = 'PassPressuresVisWrapper';
