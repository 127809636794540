import { VISUALISATION_FONT_SETUPS } from '../../../../utils/constants/visText';

export const PASSING_KEY_SIZE = {
  WIDTH: 700,
  PADDING: { SECTION: 16 },
  HEIGHT: {
    SECTION_LABEL: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT + 4,
    DOT_ROW: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    DOUBLE_DOT_ROW: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE * 2 + 4,
    GRADIENT_DOT_ROW: 10 + VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT,
    HEATMAP_ROW: 10 + VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT,
  },
  DOTS_PER_ROW: 4,
  DOTS: { RADIUS_TOTAL: 5, STROKE: 1, RADIUS: 4.5 },
};

export const PASSING_KEY_CLASS_NAMES = {
  MAIN: 'pk-main',
  COLOR: 'pk-color',
  FILL: 'pk-fill',
  HEATMAP: 'pk-heat',
};
