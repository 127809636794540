import { gql } from '@apollo/client';

export const SEARCH_HIGH_SCHOOLS = gql`
  query SearchHighSchools($searchString: String!) {
    searchHighSchools(searchString: $searchString, maxResults: 10) {
      id
      name
      city
      state
      zip
      latitude
      longitude
      schoolLevel
    }
  }
`;
