import React from 'react';
import PropTypes from 'prop-types';
import { PlayFinderPopupContent } from './PlayFinderPopup.styles';

const PlayFinderPopup = ({ trigger, children, isOpen, top, offset }) => (
  <div>
    <div className="highlightButton">{trigger}</div>
    {isOpen && (
      <PlayFinderPopupContent top={top + offset}>
        {children}
      </PlayFinderPopupContent>
    )}
  </div>
);
PlayFinderPopup.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  top: PropTypes.number,
  offset: PropTypes.number,
};

PlayFinderPopup.defaultProps = {
  isOpen: false,
  top: 0,
  offset: 170,
};

export default PlayFinderPopup;
