import { styled } from 'styled-components';

export const PassEndsChartSVG = styled.svg`
  ${({ $displayHeatMap }) =>
    $displayHeatMap &&
    `
    #ten-yd-cells {
      rect {
        display: none;
      }

      line {
        display: none;
      }
    }
  `}

  .dot {
    &:hover {
      cursor: pointer;
    }
  }

  .drawable {
    cursor: crosshair;
  }
`;
