import { useQuery } from '@apollo/client';

import { GET_PLAYER_RADAR_TEMPLATES } from './getPlayerRadarTemplates';
import { GET_PLAYER_STAT_DEFINITIONS } from '../../../utils/hooks/apiQueries/playerStats/getPlayerStatDefinitions';

const useGetTemplateDefinitions = () => {
  const {
    data: templates,
    loading: tLoading,
    error: tError,
  } = useQuery(GET_PLAYER_RADAR_TEMPLATES, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  const {
    data: statDefinitions,
    loading: sdLoading,
    error: sdError,
  } = useQuery(GET_PLAYER_STAT_DEFINITIONS, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const loading = tLoading || sdLoading;
  const error = tError || sdError;
  if (loading) {
    return { loading };
  }
  if (error) {
    return { error, loading };
  }

  const getStatDef = (statName, statData) => {
    const statDef = statData.find((d) => statName === d.name) || {};
    return statDef;
  };

  const niceTemplates = templates?.playerRadarTemplates?.map((t) => {
    const niceTemplateStats = t?.stats?.map((s) => {
      const statDef = getStatDef(
        s.name,
        statDefinitions?.playerStatDefinitions
      );
      return { ...s, ...statDef };
    });

    const niceTemplateStatsAlt = t?.secondaryStats?.map((s) => {
      const statDef = getStatDef(
        s.name,
        statDefinitions?.playerStatDefinitions
      );
      return { ...s, ...statDef };
    });

    return {
      ...t,
      stats: niceTemplateStats,
      secondaryStats: niceTemplateStatsAlt,
    };
  });

  /** **
    This code whitelists certain template names
    It's potentially useful whilst new templates are in development, but currently all templates are live

    const allowedTemplateNames = [
      'Quarterback',
      'Receiver',
      'Rusher',
      'Offensive Line',
      'Defensive Line',
    ];
    const allowedTemplates = niceTemplates.filter((t) =>
      allowedTemplateNames.includes(t.name)
    );
    return { loading, error, templates: allowedTemplates };
  *** */
  return { loading, error, templates: niceTemplates };
};

export { useGetTemplateDefinitions };
