import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../errorCodes/errorCodes';
import { GET_PLAYER_STAT_DEFINITIONS } from './apiQueries/playerStats/getPlayerStatDefinitions';

export const useGetPlayerStatDefinitions = () => {
  const { data, loading, error } = useQuery(GET_PLAYER_STAT_DEFINITIONS);

  if (loading) {
    return { loading };
  }
  if (error) {
    console.error(ERROR_CODES.USE_GET_PLAYER_STAT_DEFINITIONS, error);
    return {
      loading: false,
      error,
    };
  }

  return {
    loading: false,
    statDefinitions: data.playerStatDefinitions,
  };
};
