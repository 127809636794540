import React, { useState } from 'react';
import { Breadcrumb, Loader, Message } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import {
  Dropdown,
  Grid,
  Toggle,
  ButtonIcon,
} from '@statsbomb/kitbag-components';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { max } from 'lodash';
import Dimmer from '../../../components/Dimmer/Dimmer';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import Tile from '../../../components/Tile/Tile';
import HavocChart from '../../../visualisations/HavocChart/HavocChart';
import { mf_TeamDetails } from '../../../apollo';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  HAVOC_COLOR_MODES,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_FOCUS_MODES,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_FOCUS_MODE_FIELD,
  HAVOC_PLAYER_OPTION_ANY,
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_TYPE_ALL,
  HAVOC_Y_MODE_SNAP,
  HAVOC_BLITZ_OPTION_ANY,
  HAVOC_DEFENSIVE_FRONT_OPTION_ANY,
  HAVOC_PLAY_TYPES,
} from '../../../visualisations/HavocChart/HavocChart.constants';
import {
  getExportDetails,
  getPlayers,
  getPositions,
  getBlitzTypes,
  getDefensiveFrontTypes,
  filterHavocData,
} from './TeamHavoc.DataManipulation';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import HavocChartKey from '../../../visualisations/HavocChart/HavocChartKey/HavocChartKey';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import HavocEvent from './HavocEvent/HavocEvent';
import {
  HAVOC_TABLE_AGGREGATION_MODES,
  HAVOC_TABLE_AGGREGATION_MODE_TYPE,
} from './HavocTable.jsx/HavocTable.constants';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useGetHavocEvents } from './TeamHavoc.hooks';
import { getHavocWidth } from './TeamHavoc.drawing';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import { HAVOC_KEY_WIDTH } from '../../../visualisations/HavocChart/HavocChartKey/HavocChartKey.constants';
import { HavocChartTile, HavocMainPage, HavocVideo } from './TeamHavoc.styles';
import HavocEventTile from './HavocEvent/HavocEventTile';
import { PageHeaderTile } from '../../../components/PageHeader/PageHeader.styles';
import { InfoBar } from '../../player/PlayerOverview/PlayerOverview.styles';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import HavocSelection from './HavocSelection';
import HavocTableTile from './HavocTable.jsx/HavocTableTile';
import HavocTableFilters from './HavocTable.jsx/HavocTableFilters';

const TeamHavoc = () => {
  // resize layout
  const windowWidth = useWindowWidth();
  const [displayTable, setDisplayTable] = useState(false);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const teamName = mf_TeamDetailsRV?.name;

  const [showDefensive, setShowDefensive] = useQueryString('defensive', true);
  const [havocPlayMode, setHavocPlayMode] = useQueryString(
    'havocPlayMode',
    HAVOC_PLAY_TYPES.ANY.value
  );
  const [havocMode, setHavocMode] = useQueryString(
    'havocmode',
    HAVOC_TYPE_ALL.value
  );
  const [selectedBlitzType, setSelectedBlitzType] = useQueryString(
    'blitz',
    HAVOC_BLITZ_OPTION_ANY.value
  );
  const [selectedDefensiveFront, setSelectedDefensiveFront] = useQueryString(
    'front',
    HAVOC_DEFENSIVE_FRONT_OPTION_ANY.value
  );
  const [aggregationMode, setAggregationMode] = useQueryString(
    'aggregationmode',
    HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP
  );
  const [selectedRotation, setSelectedRotation] = useQueryString(
    'rotation',
    ROTATIONS.VERTICAL_DOWN
  );
  const [fieldFocus, setFieldFocus] = useQueryString(
    'fieldFocused',
    HAVOC_FOCUS_MODE_BOX.value
  );
  const setRotation = (newRotation) => {
    if (newRotation === ROTATIONS.HORIZONTAL) {
      setFieldFocus(HAVOC_FOCUS_MODE_FIELD.value);
    } else {
      setFieldFocus(HAVOC_FOCUS_MODE_BOX.value);
    }
    setSelectedRotation(newRotation);
  };
  const [colorMode, setColorMode] = useQueryString(
    'color',
    HAVOC_COLOR_MODE_LOCATIONS_TIME.value
  );
  const [showPaths, setShowPaths] = useQueryString('showpaths', false);

  const [displayKey, setDisplayKey] = useQueryString('displayKey', true);

  const [selectedPlayerId, setSelectedPlayerId] = useQueryString(
    'player',
    HAVOC_PLAYER_OPTION_ANY.value
  );
  const [selectedPositionCode, setSelectedPositionCode] = useQueryString(
    'position',
    HAVOC_POSITION_OPTION_ANY.value
  );

  const [selectedEvent, setSelectedEvent] = useQueryString('event', null);

  const {
    loading: loadingHavoc,
    error: errorHavoc,
    teamHavocData,
  } = useGetHavocEvents(showDefensive, havocMode);
  const isError = !!errorHavoc;
  const isLoading = !!loadingHavoc;

  const selectedEventObj = teamHavocData?.find(
    (evt) => evt.eventUUID === selectedEvent
  );
  /* Define the list of players and positions to filter on */
  const relevantPlayers = getPlayers(teamHavocData);
  const positionFrequencies = getPositions(teamHavocData);
  const blitzTypes = getBlitzTypes(teamHavocData);
  const defensiveFronts = getDefensiveFrontTypes(teamHavocData);
  /* Finally filter on any selection (not disturbing player/position lists0 */
  const havocData = filterHavocData({
    teamHavocData,
    selectedPlayerId,
    selectedBlitzType,
    selectedDefensiveFront,
    selectedPositionCode,
  });

  const { title, secondaryTitle, info2, info3, fileName } = getExportDetails(
    havocData,
    teamName,
    havocMode,
    showDefensive,
    selectedPlayerId,
    selectedPositionCode,
    relevantPlayers,
    positionFrequencies
  );
  const info1 = useExportSeasonInfo();
  const aggregateModeName = HAVOC_TABLE_AGGREGATION_MODES.find(
    (f) => f.value === aggregationMode
  ).label;
  const exportWidth = max([
    HAVOC_KEY_WIDTH,
    getHavocWidth(selectedRotation, fieldFocus, true),
  ]);
  const havocTableFileName = `${teamName} havoc events ${aggregateModeName} summary`;
  return (
    <KitbagPageGridHolder>
      <PageHeaderTile style={{ margin: '0.5rem 0.5rem 0 0.5rem' }}>
        <InfoBar style={{ height: '4.8rem' }}>
          <div>
            <Breadcrumb size="huge">
              <Breadcrumb.Section>
                <ParsedLink href="/team/havoc/:leagues/:seasons/:teams">
                  Team
                </ParsedLink>
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>Havoc Events</Breadcrumb.Section>
            </Breadcrumb>
          </div>
          <div>
            <h3>{teamName}</h3>
          </div>
          <div>
            <ButtonIcon
              id="vis-or-table-button"
              size="small"
              title={displayTable ? 'Display Visualisation' : 'Display Table'}
              icon="Swap"
              variant="secondary"
              onClick={() => setDisplayTable(!displayTable)}
            />
            <KeyButton
              showKey={displayKey}
              handleShowKey={() => setDisplayKey(!displayKey)}
            />
          </div>
        </InfoBar>
      </PageHeaderTile>
      <HavocMainPage>
        {selectedEvent && (
          <HavocVideo>
            <Tile border="0" margin="0 0 0.8rem 0">
              <HavocEvent
                havocData={havocData}
                selectedEvent={selectedEvent}
                handleClose={() => setSelectedEvent(null)}
              />
              <HavocEventTile data={selectedEventObj} />
            </Tile>
          </HavocVideo>
        )}
        <div className={selectedEvent ? 'havoc-non-video' : 'havoc-no-video'}>
          <SidebarRightLayout
            $sidebarWidth={selectedEvent ? '0px' : '320px'}
            $gap="0.8rem"
            $padding="0 0 0 0"
          >
            <div>
              {!displayTable && (
                <>
                  <Tile border="0" margin="0 0 0.8rem 0">
                    <Tile.Header>
                      <h2 title="Visualisation displaying havoc event locations">
                        Havoc Events
                      </h2>
                      <div className="buttons">
                        <ExportModal
                          title={title}
                          secondaryTitle={secondaryTitle}
                          info1={info1}
                          info2={info2}
                          info3={info3}
                          fileName={fileName}
                          customWidth={exportWidth}
                          isDisabled={isLoading || !havocData?.length}
                        >
                          <HavocChart
                            id="export-havoc-chart"
                            data={havocData}
                            orientation={selectedRotation}
                            displayYMode={HAVOC_Y_MODE_SNAP.value}
                            colorMode={colorMode}
                            fieldFocusMode={fieldFocus}
                            players={relevantPlayers}
                            positions={positionFrequencies}
                            showPaths={showPaths}
                            selectedEvent={selectedEvent}
                            isFixedSize={
                              selectedRotation === ROTATIONS.HORIZONTAL
                            }
                          />
                          {displayKey && (
                            <HavocChartKey
                              colorMode={colorMode}
                              players={relevantPlayers}
                              positions={positionFrequencies}
                              isFixedSize
                            />
                          )}
                        </ExportModal>
                      </div>
                    </Tile.Header>
                    <Tile.Body>
                      {isError && !isLoading && (
                        <Message negative>
                          There has been an error. Please contact support.
                        </Message>
                      )}

                      <HavocChartTile>
                        {!isError && (
                          <div
                            className={
                              selectedEvent || !displayKey
                                ? 'havoc-chart-row'
                                : 'havoc-chart-holder'
                            }
                            style={{
                              maxWidth: getHavocWidth(
                                selectedRotation,
                                fieldFocus
                              ),
                            }}
                          >
                            <HavocChart
                              data={havocData}
                              orientation={selectedRotation}
                              displayYMode={HAVOC_Y_MODE_SNAP.value}
                              colorMode={colorMode}
                              fieldFocusMode={fieldFocus}
                              players={relevantPlayers}
                              showPaths={showPaths}
                              selectedEvent={selectedEvent}
                              setSelectedEvent={setSelectedEvent}
                            />
                            {isLoading && (
                              <Dimmer
                                active
                                style={{
                                  minHeight: '30vh',
                                  maxWidth: '100%',
                                }}
                              >
                                <Loader content="Loading Data" />
                              </Dimmer>
                            )}
                          </div>
                        )}
                        {!isError && displayKey && (
                          <div
                            className={
                              selectedEvent || !displayKey
                                ? 'havoc-chart-row'
                                : 'havoc-chart-key-holder'
                            }
                          >
                            <HavocChartKey
                              colorMode={colorMode}
                              players={relevantPlayers}
                              positions={positionFrequencies}
                              isFixedSize
                              vertical={windowWidth > 1422 && !selectedEvent}
                            />
                          </div>
                        )}
                      </HavocChartTile>
                    </Tile.Body>
                  </Tile>

                  {/* <Tile border="0" margin="0 0 0.8rem 0">
                    <Tile.Header>
                      <h2 title="Visualisation displaying havoc event transitions">
                        Havoc Summation
                      </h2>
                    </Tile.Header>
                    <Tile.Body>
                      {isError && !isLoading && (
                        <Message negative>
                          There has been an error. Please contact support.
                        </Message>
                      )}
                      <HavocChartTile>New Chart Goes Here</HavocChartTile>
                    </Tile.Body>
                  </Tile> */}
                </>
              )}

              {displayTable && (
                <HavocTableTile
                  havocData={havocData}
                  aggregationMode={aggregationMode}
                  tileTitle="Havoc Events"
                  fileName={havocTableFileName}
                />
              )}
            </div>
            <div>
              {!selectedEvent && (
                <>
                  <HavocSelection
                    showDefensive={showDefensive}
                    setShowDefensive={setShowDefensive}
                    havocPlayMode={havocPlayMode}
                    setHavocPlayMode={setHavocPlayMode}
                    havocMode={havocMode}
                    setHavocMode={setHavocMode}
                    selectedBlitzType={selectedBlitzType}
                    setSelectedBlitzType={setSelectedBlitzType}
                    selectedDefensiveFront={selectedDefensiveFront}
                    setSelectedDefensiveFront={setSelectedDefensiveFront}
                    selectedPlayerId={selectedPlayerId}
                    setSelectedPlayerId={setSelectedPlayerId}
                    selectedPositionCode={selectedPositionCode}
                    setSelectedPositionCode={setSelectedPositionCode}
                    relevantPlayers={relevantPlayers}
                    positionFrequencies={positionFrequencies}
                    blitzTypes={blitzTypes}
                    defensiveFronts={defensiveFronts}
                  />

                  {!displayTable && (
                    <AccordionTile
                      id="display-accordion"
                      header={
                        <Tile.AccordionHeader>
                          Havoc Location Chart Options
                        </Tile.AccordionHeader>
                      }
                      body={
                        <Tile.AccordionBody>
                          <Grid item xs={12}>
                            <Dropdown
                              id="havoc-color-dropdown"
                              options={HAVOC_COLOR_MODES}
                              label="Display Mode"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setColorMode(selectedOption.value)
                              }
                              value={HAVOC_COLOR_MODES.find(
                                (f) => f.value === colorMode
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Toggle
                              id="show-defender-paths-toggle"
                              label={
                                showPaths
                                  ? `Defender Paths On`
                                  : `Defender Paths Off`
                              }
                              onChange={() => {
                                setShowPaths(!showPaths);
                              }}
                              checked={showPaths}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              id="havoc-field-rotation-dropdown"
                              options={ROTATION_OPTIONS}
                              label="Field Rotation"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setRotation(selectedOption.value)
                              }
                              value={ROTATION_OPTIONS.find(
                                (f) => f.value === selectedRotation
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              id="havoc-focus-dropdown"
                              options={HAVOC_FOCUS_MODES}
                              label="Zoom Mode"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setFieldFocus(selectedOption.value)
                              }
                              value={HAVOC_FOCUS_MODES.find(
                                (f) => f.value === fieldFocus
                              )}
                            />
                          </Grid>
                        </Tile.AccordionBody>
                      }
                      isExpandedDefault
                      saveState
                    />
                  )}
                  {displayTable && (
                    <HavocTableFilters
                      aggregationMode={aggregationMode}
                      setAggregationMode={setAggregationMode}
                    />
                  )}
                </>
              )}
            </div>
          </SidebarRightLayout>
        </div>
      </HavocMainPage>
    </KitbagPageGridHolder>
  );
};

export default TeamHavoc;
