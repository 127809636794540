/* eslint-disable max-len */
export const DATA_DICTIONARY_LINE_BATTLE_EVENTS = [
  {
    field: 'game_id',
    fieldType: 'integer',
    fieldValues: '1069669',
    fieldDescription: 'Unique identifier for the game',
  },
  {
    field: 'game_date',
    fieldType: 'string',
    fieldValues: '2021-09-12, 2022-10-11',
    fieldDescription: 'Date that the game took place',
  },
  {
    field: 'game_away_team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription: 'Unique team identifier for the away team',
  },
  {
    field: 'away_team_name',
    fieldType: 'string',
    fieldValues: 'Jacksonville Jaguars',
    fieldDescription: 'Away team name',
  },
  {
    field: 'game_home_team_id',
    fieldType: 'integer',
    fieldValues: '1001399',
    fieldDescription: 'Unique team identifier for the home team',
  },
  {
    field: 'home_team_name',
    fieldType: 'string',
    fieldValues: 'Jacksonville Jaguars',
    fieldDescription: 'Home team name',
  },
  {
    field: 'event_uuid',
    fieldType: 'uuid',
    fieldValues: 'c8643c36-b00a-4e54-a156-842a1b79cda9',
    fieldDescription: 'Unique identifier for the event',
  },
  {
    field: 'play_uuid',
    fieldType: 'uuid',
    fieldValues: 'a2b88e4b-22ae-4d56-b895-ff23ab774afd',
    fieldDescription:
      'Unique identifier for the play (will be null for certain non-play event types like Quarter Start or Camera Paused)',
  },
  {
    field: 'game_quarter',
    fieldType: 'integer',
    fieldValues: '1, 2, 3, 4, 5',
    fieldDescription:
      'Quarter the event takes place (for NFL games, OT is 5; for college games, 1st OT is 5, 2nd OT is 6, etc.)',
  },
  {
    field: 'play_quarter_clock_remaining',
    fieldType: 'integer',
    fieldValues: '900000 (start of quarter), 752965',
    fieldDescription:
      'Remaining clock time left in the quarter in milliseconds (a value of 752965 equates to 12:33 left in quarter)',
  },
  {
    field: 'event_game_index',
    fieldType: 'integer',
    fieldValues: '7, 141',
    fieldDescription:
      'Ranked order of events over the series of a game (a value of 7 means that a event was the 7th collected event in that game)',
  },
  {
    field: 'event_video_timestamp',
    fieldType: 'decimal',
    fieldValues: '68.055408',
    fieldDescription:
      'Internal measure of when the event occurred relative to the video used to collect the game',
  },
  {
    field: 'event_types',
    fieldType: 'array',
    fieldValues: 'Engagement Start, Pathing',
    fieldDescription:
      'An event can have 1 or more event_types associated with it (see "definition: event_types" tab for full list)',
  },
  {
    field: 'team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription:
      'Unique team identifier for the player whose line battle is being recorded',
  },
  {
    field: 'team_name',
    fieldType: 'string',
    fieldValues: 'Jacksonville Jaguars',
    fieldDescription:
      'Team name for the player whose line battle is being recorded',
  },
  {
    field: 'player_id',
    fieldType: 'integer',
    fieldValues: '1024073',
    fieldDescription:
      'For events that describe an action [Catch, Pass, Pressure, Tackle], this is the person performing that action',
  },
  {
    field: 'player_name',
    fieldType: 'string',
    fieldValues: 'Shaquill Griffin',
    fieldDescription:
      'Player name for the player whose line battle is being recorded',
  },
  {
    field: 'jersey_number',
    fieldType: 'integer',
    fieldValues: '26',
    fieldDescription:
      'Jersey number for the player whose line battle is being recorded',
  },
  {
    field: 'team_position_code',
    fieldType: 'string',
    fieldValues: 'CB',
    fieldDescription:
      'Position for the player whose line battle is being recorded',
  },
  {
    field: 'event_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the line battle started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the line battled started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_end_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the line battle started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_end_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the line battle started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_duration',
    fieldType: 'decimal',
    fieldValues: '0.430874, 3.88031',
    fieldDescription:
      'Number of seconds from the start to the end of the line battle',
  },
  {
    field: 'event_success',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the event was successful for the player ID (eg on a Tackle Avoided event, this field would be TRUE for the ball carrier and this field would be FALSE for the corresponding Tackle event for the defensive player)',
  },
  {
    field: 'base_event_uuid',
    fieldType: 'uuid',
    fieldValues: '',
    fieldDescription: '',
  },
  {
    field: 'start_engagement_uuid',
    fieldType: 'uuid',
    fieldValues: '76a1277a-dd18-4c26-90b0-b5dd4f715c0c',
    fieldDescription:
      'For an Engagement End event, this is the event id of the corresponding Engagement Start event. It is otherwise null',
  },
  {
    field: 'end_engagement_uuid',
    fieldType: 'uuid',
    fieldValues: '3170c71b-0156-43cb-8c03-51ac770a57c3',
    fieldDescription:
      'For an Engagement Start event, this is the event id of the corresponding Engagement End event. It is otherwise null',
  },
  {
    field: 'event_catch_fail_type',
    fieldType: 'string',
    fieldValues: "Dropped', 'Missed', 'Broken Up', 'Out'",
    fieldDescription:
      "Values are only populated when an Event Type of Catch is recorded (is null otherwise). Field can have only 1 of the following values are: 'Dropped', 'Missed', 'Broken Up', 'Out'",
  },
  {
    field: 'event_kick_fail_type',
    fieldType: 'string',
    fieldValues: 'Blocked, Goalpost, Short, Wide Left, Wide Right',
    fieldDescription:
      'Values are only populated when an Event Type of unsuccessful Kick is recorded (is null otherwise). Field can have only 1 of the following values: Blocked, Goalpost, Short, Wide Left, Wide Right',
  },
  {
    field: 'event_kick_distance',
    fieldType: 'decimal',
    fieldValues: '22.96, 52.09',
    fieldDescription:
      'For all Kick Event Types, we collect the distance the kick traveled down the field (difference between event_x and event_end_x)',
  },
  {
    field: 'event_pass_time_to_throw',
    fieldType: 'decimal',
    fieldValues: '0.83207, 2.755212',
    fieldDescription:
      'Attached to pass event -- calculation of time from snap to pass',
  },
  {
    field: 'event_penalty_type',
    fieldType: 'text',
    fieldValues: 'holding, illegal-substitution',
    fieldDescription:
      'Description of the penalty (multiple words separated with a hyphen)',
  },
  {
    field: 'event_snap_under_center',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the QB took the snap directly under center',
  },
  {
    field: 'event_tackle_contact',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: '',
  },
  {
    field: 'event_lateral_technique',
    fieldType: 'string',
    fieldValues: 'Throw, Toss',
    fieldDescription:
      'Description of lateral technique. Only two possible values when present: Throw, Toss',
  },
  {
    field: 'event_pass_underthrow',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the pass was determined by the collector to be underthrown',
  },
  {
    field: 'event_pass_overthrow',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the pass was determined by the collector to be overthrown',
  },
  {
    field: 'event_pass_throwaway',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass was deliberately thrown away',
  },
  {
    field: 'event_pass_out_of_bounds',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass landed out of bounds',
  },
  {
    field: 'event_pass_placement_x',
    fieldType: 'decimal',
    fieldValues: '0.2167',
    fieldDescription:
      "horizontal distance in yards of where the ball landed on the receiver's body relative to his center of mass",
  },
  {
    field: 'event_pass_placement_y',
    fieldType: 'decimal',
    fieldValues: '1.2708',
    fieldDescription:
      "vertical distance in yards of where the ball landed on the receiver's body relative to his center of mass",
  },
];
