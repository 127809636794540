export const formatPlayerLabel = ({
  gamesRostered,
  name,
  mostCommonPosition,
  mostCommonNumber,
}) =>
  `${mostCommonNumber} - ${name} (${
    mostCommonPosition?.code || '-'
  }) [${gamesRostered}]`;

export const formatGameLabel = (game, selectedTeamId) => {
  const { homeTeam, awayTeam } = game;
  const formattedDate = new Date(game.date).toLocaleString('en-us', {
    day: 'numeric',
    month: 'numeric',
  });

  // figure out which team is the selected team and which is the opponent
  const isHomeTeam = homeTeam.id === selectedTeamId;
  const selectedTeam = isHomeTeam ? homeTeam : awayTeam;
  const opponentTeam = isHomeTeam ? awayTeam : homeTeam;

  // example - W9 11/27 ACN @ ABC
  const formattedLabel = `${game.roundShortName} ${formattedDate} ${
    selectedTeam.shortName
  } ${isHomeTeam ? 'vs' : '@'} ${opponentTeam.shortName}`;

  return formattedLabel;
};

// returns:
// token index within the path
// token value from the path
export const getTokenFromUrl = (path, pageInfo, token) => {
  const pathValues = path.split('/').splice(1).filter(Number);
  const tokenIndex = pageInfo?.urlParams?.indexOf(`:${token}`);
  return [tokenIndex, pathValues[tokenIndex]];
};

// order seasons by year descending
export const orderSeasons = (data) => {
  if (!data) {
    return [];
  }

  const formattedSeasons = data?.map((season) => ({
    value: season.id,
    label: season.name,
  }));

  // Sort the formatted seasons based on the second part of season name (year) in descending order
  formattedSeasons?.sort((a, b) => {
    const yearA = parseInt(a.label.split('/')[1], 10);
    const yearB = parseInt(b.label.split('/')[1], 10);
    return yearB - yearA;
  });

  return formattedSeasons;
};

export const playerToOption = ({ id, ...rest }) => ({
  label: formatPlayerLabel(rest),
  value: id,
});
