import { gql } from '@apollo/client';

export const GET_PLAY_FINDER_SEASONS = gql`
  query GetSeasons($competitionId: Int!) {
    seasons(competitionId: $competitionId) {
      id
      name
    }
  }
`;
