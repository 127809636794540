import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

const overrides = { playTypes: false };

export const GET_ROUTES_RUN = gql`
    query getRoutePlayerCount(
      $competitionId: Int!
      $seasonId: Int!
      $playerId: Int!
      $teamId: Int!
      $specificPositions: [SpecificPosition!]
      ${getContextQueryVariables(overrides)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      selectedPlayerId @client @export(as: "playerId")
      selectedTeamId @client @export(as: "teamId")
      ${getContextFilterClientExports(overrides)}
      playerStats(
        playFilters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          ${getContextFiltersQueryArguments(overrides)}
          playTypes: [PASS]
        }
        aggregateMode: RATE
        playerStatFilters: {
          playerIds: [$playerId]
          teamIds: [$teamId]
          specificPositions: $specificPositions
          }
        groupBy: [PLAYER, PLAY_POSITION]
      ) {
        items {  
          routesRun
          playPosition
          }
      }
    }
  `;
