import { area, curveCatmullRom, line } from 'd3';
import {
  csHeatmap,
  csIntensity,
  csValue,
} from '../../utils/helpers/colorScales';
import {
  addGradientDots,
  addMeaningDots,
  addSizeScaleDots,
} from '../../utils/visualisations/keys';
import {
  drawCircle,
  drawPentagon,
  drawSquare,
} from '../../utils/visualisations/shapes';
import { appendText } from '../text';
import {
  TACKLING_FIELD_DISPLAY_HEATMAP,
  TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE,
  TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_PLAY_NEUTRAL,
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLING_KEY_METRICS,
  TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_FIELD_DISPLAY_PATHS,
} from './TackleLocation.constants';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';
import { getDataDots } from './tackleLocation.key.dataManipulation';

const drawKey = (
  keyG,
  visPalette,
  keyWidth,
  colorMode,
  axesPadding,
  isDarkMode,
  scaleR,
  playersWithColor,
  fieldMode,
  fieldFocusOrigin,
  showHeatmapDots,
  selectedTacklerId,
  tackleData
) => {
  const dataDotValues = getDataDots(colorMode, visPalette, tackleData);
  const colorKeyG = keyG
    .append('g')
    .attr('transform', `translate(${axesPadding.left},10)`);
  const dotsG = colorKeyG.append('g').attr('transform', `translate(0,10)`);

  if (fieldMode === TACKLING_FIELD_DISPLAY_HEATMAP.value) {
    const message = fieldFocusOrigin
      ? 'Tackle Attempt Location Intensity'
      : 'Final Location Intensity';
    appendText(colorKeyG, visPalette, {
      message,
      y: 2,
    });

    const gradG = dotsG.append('g');
    const csModeHeatmap = (value) => csHeatmap(value, isDarkMode);
    addGradientDots(
      gradG,
      csModeHeatmap,
      visPalette,
      isDarkMode,
      keyWidth / 2,
      'Least',
      'Most'
    );

    if (showHeatmapDots) {
      const overlayDotG = dotsG
        .append('g')
        .attr('transform', `translate(0,40)`);
      const dotValues = [
        { color: visPalette.contrast, name: 'Final Ball Locations' },
      ];
      addMeaningDots(overlayDotG, dotValues, visPalette, keyWidth, {
        radius: 4,
      });

      if (scaleR) {
        const overlayDotScaleG = dotsG
          .append('g')
          .attr('transform', `translate(${(keyWidth * 3) / 4},0)`);
        appendText(colorKeyG, visPalette, {
          message: 'Yards Gained after first tackle attempt',
          y: 2,
          x: keyWidth,
          textAnchor: 'end',
        });
        addSizeScaleDots(
          overlayDotScaleG,
          visPalette,
          keyWidth / 4,
          '0 yards',
          '20+ yards'
        );
      }
    }
  }
  if (fieldMode === TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value) {
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME.value) {
      appendText(colorKeyG, visPalette, {
        message: 'Colored by best tackle outcome (plays)',
        y: 2,
      });
      addMeaningDots(dotsG, dataDotValues, visPalette, keyWidth, {
        radius: 4,
        dotsPerLine: 2,
      });
    }
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE.value) {
      appendText(colorKeyG, visPalette, {
        message: 'Colored by Play Type',
        y: 2,
      });
      addMeaningDots(dotsG, dataDotValues, visPalette, keyWidth, { radius: 4 });
    }
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME.value) {
      appendText(colorKeyG, visPalette, {
        message: 'Colored by Play Outcome',
        y: 2,
      });
      addMeaningDots(dotsG, dataDotValues, visPalette, keyWidth, { radius: 4 });
    }
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_NEUTRAL.value) {
      appendText(colorKeyG, visPalette, {
        message: 'No color information',
        y: 2,
      });
    }
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_DX.value) {
      appendText(colorKeyG, visPalette, {
        message: `Colored by: ${TACKLING_KEY_METRICS.delta}`,
        y: 2,
      });
      const dotValues = [
        {
          color: visPalette.objects.n2.main,
          name: '0 Yard Gain (pushed back)',
        },
      ];
      addMeaningDots(dotsG, dotValues, visPalette, keyWidth / 2, { radius: 4 });
      const gradG = dotsG.append('g').attr('transform', `translate(0,25)`);
      const csValueNoMode = (v) => csValue(v, isDarkMode);
      addGradientDots(
        gradG,
        csValueNoMode,
        visPalette,
        isDarkMode,
        keyWidth / 2,
        'Least (0 or less yards)',
        'Most (20+ yards)'
      );
    }
    if (colorMode === TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER.value) {
      appendText(colorKeyG, visPalette, {
        message: 'Colored by Ball Carrier',
        y: 2,
      });

      if (playersWithColor?.length) {
        const dotValues = playersWithColor.splice(0, 8).map((player) => ({
          color: player.color,
          name: player.playerName,
        }));
        if (dotValues?.length < playersWithColor?.length) {
          dotValues.push({
            color: visPalette.objects.neutral.main,
            name: 'Other Players',
          });
        }
        addMeaningDots(dotsG, dotValues, visPalette, keyWidth, { radius: 4 });
      }
    }
  }
  if (fieldMode === TACKLING_FIELD_DISPLAY_PATHS.value) {
    const pathG = keyG
      .append('g')
      .attr('transform', `translate(${axesPadding.left},0)`);
    const symbolsG = keyG
      .append('g')
      .attr('transform', `translate(${axesPadding.left},60)`);
    const pathLineG = pathG
      .append('g')
      .attr(
        'transform',
        `translate(130,${VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE * 0.5})`
      );
    const keyLabelSetup = {
      y: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE * 0.8,
      fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
      fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
    };
    appendText(colorKeyG, visPalette, {
      ...keyLabelSetup,
      message: 'Tackle Attempts Path',
    });

    pathLineG
      .append('path')
      .attr('d', 'M0 10 L40 0 L80 20 L120 10')
      .attr('fill', 'none')
      .attr('stroke', visPalette.objects.neutral.main)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '1 2');
    pathLineG
      .append('path')
      .attr('transform', `translate(120,10) rotate(45)`)
      .attr('d', drawSquare(2))
      .attr('fill', visPalette.objects.neutral.main)
      .attr('stroke', 'none');
    appendText(colorKeyG, visPalette, {
      ...keyLabelSetup,
      message: 'Final Ball Location',
      x: 255,
    });

    appendText(symbolsG, visPalette, {
      ...keyLabelSetup,
      message: 'Shape',
      y: -15,
    });
    symbolsG
      .append('path')
      .attr('transform', `translate(5,0)`)
      .attr('d', drawPentagon(4.5))
      .attr('fill', visPalette.successFail.neutral.main)
      .attr('fill-opacity', 1)
      .attr('stroke', visPalette.successFail.neutral.main)
      .attr('stroke-width', 1);
    appendText(symbolsG, visPalette, {
      ...keyLabelSetup,
      message: 'Solo Attempt',
      x: 12,
      y: 4,
    });
    symbolsG
      .append('path')
      .attr('transform', `translate(5,20) rotate(90)`)
      .attr('d', drawCircle(4))
      .attr('fill', visPalette.successFail.neutral.main)
      .attr('fill-opacity', 1)
      .attr('stroke', visPalette.successFail.neutral.main)
      .attr('stroke-width', 1);
    appendText(symbolsG, visPalette, {
      ...keyLabelSetup,
      message: 'Assist Attempt',
      x: 12,
      y: 4 + 20,
    });

    const fillG = symbolsG.append('g').attr('transform', 'translate(150,0)');
    appendText(fillG, visPalette, {
      ...keyLabelSetup,
      message: 'Solid/Outline',
      y: -15,
    });
    fillG
      .append('path')
      .attr('transform', `translate(5,0)`)
      .attr('d', drawCircle(4))
      .attr('fill', visPalette.successFail.neutral.main)
      .attr('fill-opacity', 1)
      .attr('stroke', visPalette.successFail.neutral.main)
      .attr('stroke-width', 1);
    appendText(fillG, visPalette, {
      ...keyLabelSetup,
      message: 'Solo Tackle',
      x: 12,
      y: 4,
    });
    fillG
      .append('path')
      .attr('transform', `translate(5,20) rotate(90)`)
      .attr('d', drawCircle(4))
      .attr('fill', visPalette.successFail.neutral.main)
      .attr('fill-opacity', 0)
      .attr('stroke', visPalette.successFail.neutral.main)
      .attr('stroke-width', 1);
    appendText(fillG, visPalette, {
      ...keyLabelSetup,
      message: 'Assist Tackle',
      x: 12,
      y: 4 + 20,
    });

    const colorsG = symbolsG.append('g').attr('transform', 'translate(300,0)');
    appendText(colorsG, visPalette, {
      ...keyLabelSetup,
      message: 'Color',
      y: -15,
    });
    colorsG
      .append('path')
      .attr('transform', `translate(5,0)`)
      .attr('d', drawCircle(4))
      .attr('fill', visPalette.successFail.success.main)
      .attr('fill-opacity', 1)
      .attr('stroke', visPalette.successFail.success.main)
      .attr('stroke-width', 1);
    appendText(colorsG, visPalette, {
      ...keyLabelSetup,
      message: 'Successful Tackle',
      x: 12,
      y: 4,
    });
    colorsG
      .append('path')
      .attr('transform', `translate(5,20) rotate(90)`)
      .attr('d', drawCircle(4))
      .attr('fill', visPalette.successFail.fail.main)
      .attr('fill-opacity', 1)
      .attr('stroke', visPalette.successFail.fail.main)
      .attr('stroke-width', 1);
    appendText(colorsG, visPalette, {
      ...keyLabelSetup,
      message: 'Missed Tackle',
      x: 12,
      y: 4 + 20,
    });

    if (selectedTacklerId) {
      const sizeG = symbolsG.append('g').attr('transform', 'translate(450,0)');
      appendText(sizeG, visPalette, {
        ...keyLabelSetup,
        message: 'Size',
        y: -15,
      });
      sizeG
        .append('path')
        .attr('transform', `translate(5,0)`)
        .attr('d', drawCircle(4))
        .attr('fill', visPalette.successFail.neutral.main)
        .attr('fill-opacity', 1)
        .attr('stroke', visPalette.successFail.neutral.main)
        .attr('stroke-width', 1);
      appendText(sizeG, visPalette, {
        ...keyLabelSetup,
        message: 'Selected Tackler',
        x: 12,
        y: 4,
      });
      sizeG
        .append('path')
        .attr('transform', `translate(5,20) rotate(90)`)
        .attr('d', drawCircle(2))
        .attr('fill', visPalette.successFail.neutral.main)
        .attr('fill-opacity', 1)
        .attr('stroke', visPalette.successFail.neutral.main)
        .attr('stroke-width', 1);
      appendText(sizeG, visPalette, {
        ...keyLabelSetup,
        message: 'Other Tacklers',
        x: 12,
        y: 4 + 20,
      });
    }
  }
};

const sampleCurvePoints = [
  [0, 0],
  [10, -1],
  [20, -5],
  [30, -20],
  [40, -15],
  [50, -7],
  [60, -10],
  [70, -5],
  [80, -0],
  [90, -3],
  [100, 0],
];
const sampleCurvePoints2 = [
  [0, 0],
  [10, -2],
  [20, -7],
  [30, -14],
  [40, -20],
  [50, -16],
  [60, -12],
  [70, -7],
  [80, -3],
  [90, -1],
  [100, 0],
];

const drawDistributionKey = (
  keyG,
  visPalette,
  distroAreaColorMode,
  keyWidth
) => {
  const areaHeight = 25;
  const areaText = VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT;
  const areaHistroKeyG = keyG
    .append('g')
    .attr('transform', `translate(0,${areaHeight})`);
  const areaLAHistroKeyG = keyG
    .append('g')
    .attr('transform', `translate(${keyWidth / 2},${areaHeight})`);
  const linesHeight =
    distroAreaColorMode === TACKLE_LOCATION_DISTRO_COLOR_WINNER.value
      ? areaText + 10
      : 5;
  const linesKeyG = keyG
    .append('g')
    .attr('transform', `translate(0,${areaHeight + areaText})`);
  const areaWinnerKeyG = keyG
    .append('g')
    .attr('transform', `translate(0,${areaHeight + areaText + linesHeight})`);

  /* Area Shape and LA curve  */
  areaHistroKeyG
    .append('path')
    .style('stroke', 'none')
    .style('fill', visPalette.objects.neutral.main)
    .attr('d', area().curve(curveCatmullRom)(sampleCurvePoints));
  appendText(areaHistroKeyG, visPalette, {
    message: `Frequency Distribution`,
    y: 12,
  });

  areaLAHistroKeyG
    .append('path')
    .style('stroke', visPalette.objects.n2.main)
    .style('fill', 'transparent')
    .attr('d', line().curve(curveCatmullRom)(sampleCurvePoints2));
  appendText(areaLAHistroKeyG, visPalette, {
    message: `League Average`,
    y: 12,
  });

  const areaBarColorHeight = 10;
  const areaBarColorTextY = areaBarColorHeight + 14;
  if (distroAreaColorMode === TACKLE_LOCATION_DISTRO_COLOR_WINNER.value) {
    /* Mean and LA Lines */
    linesKeyG
      .append('line')
      .attr('x1', 0)
      .attr('x2', 40)
      .attr('y1', 10)
      .attr('y2', 10)
      .attr('stroke', visPalette.objects.n1.main)
      .attr('stroke-dasharray', '2 2');
    appendText(linesKeyG, visPalette, { message: 'Mean', x: 45, y: 14 });

    linesKeyG
      .append('line')
      .attr('x1', keyWidth / 2)
      .attr('x2', keyWidth / 2 + 40)
      .attr('y1', 10)
      .attr('y2', 10)
      .attr('stroke', visPalette.objects.n2.main)
      .attr('stroke-dasharray', '2 2');
    appendText(linesKeyG, visPalette, {
      message: 'League Average Mean',
      x: keyWidth / 2 + 45,
      y: 14,
    });

    areaWinnerKeyG
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 90)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', visPalette.objects.neutral.main);
    areaWinnerKeyG
      .append('rect')
      .attr('x', 30)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', visPalette.objects.n1.main);
    appendText(areaWinnerKeyG, visPalette, {
      message: `More than League Average`,
      y: areaBarColorTextY,
      x: 0,
    });

    areaWinnerKeyG
      .append('rect')
      .attr('x', keyWidth / 2)
      .attr('y', 0)
      .attr('width', 90)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', visPalette.objects.neutral.main);
    areaWinnerKeyG
      .append('rect')
      .attr('x', keyWidth / 2 + 30)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', visPalette.objects.n2.main);
    appendText(areaWinnerKeyG, visPalette, {
      message: `Less than League Average`,
      y: areaBarColorTextY,
      x: keyWidth / 2,
    });
  } else {
    appendText(areaWinnerKeyG, visPalette, {
      message: `P0`,
      y: areaBarColorTextY,
    });
    appendText(areaWinnerKeyG, visPalette, {
      message: `P25`,
      y: areaBarColorTextY,
      x: keyWidth / 4,
      textAnchor: 'middle',
    });
    appendText(areaWinnerKeyG, visPalette, {
      message: `P50`,
      y: areaBarColorTextY,
      x: keyWidth / 2,
      textAnchor: 'middle',
    });
    appendText(areaWinnerKeyG, visPalette, {
      message: `P75`,
      y: areaBarColorTextY,
      x: (keyWidth * 3) / 4,
      textAnchor: 'middle',
    });
    appendText(areaWinnerKeyG, visPalette, {
      message: `P100`,
      y: areaBarColorTextY,
      x: keyWidth,
      textAnchor: 'end',
    });
    areaWinnerKeyG
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', keyWidth / 4)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', csIntensity(0));
    areaWinnerKeyG
      .append('rect')
      .attr('x', keyWidth / 4)
      .attr('y', 0)
      .attr('width', keyWidth / 4)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', csIntensity(0.333));
    areaWinnerKeyG
      .append('rect')
      .attr('x', keyWidth / 2)
      .attr('y', 0)
      .attr('width', keyWidth / 4)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', csIntensity(0.667));
    areaWinnerKeyG
      .append('rect')
      .attr('x', (keyWidth * 3) / 4)
      .attr('y', 0)
      .attr('width', keyWidth / 4)
      .attr('height', areaBarColorHeight)
      .attr('stroke', 'none')
      .attr('fill', csIntensity(1));
  }
};

export { drawKey, drawDistributionKey };
