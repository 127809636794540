import { isEmpty } from 'lodash';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../helpers/contextFilters';

export const getPlayersStatPositionUsage = (
  additionalPlayFilters,
  useContextFilters,
  playTypes
) => {
  const overrides = isEmpty(playTypes) ? {} : { playTypes: false };
  const contextQueryVariables = useContextFilters
    ? getContextQueryVariables(overrides)
    : '';
  const contextFilterClientExports = useContextFilters
    ? getContextFilterClientExports(overrides)
    : '';
  const contextFiltersQueryArguments = useContextFilters
    ? getContextFiltersQueryArguments(overrides)
    : '';
  const playTypeOverride = isEmpty(playTypes)
    ? ''
    : `playTypes: [${playTypes}]`;
  const extraPlayFilters =
    additionalPlayFilters?.length > 0 ? additionalPlayFilters : '';
  return `
    query GetPlayersStats(
      $competitionId: Int!
      $seasonId: Int!
      $teamId: Int!
      ${contextQueryVariables}
      $playerIds: [Int!]
      $rosterPositions: [GeneralPosition!]
      $alignmentPositions: [SpecificPosition!]
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      selectedTeamId @client @export(as: "teamId")
      ${contextFilterClientExports}
      playerStats(
        playFilters: {
          competitionId: $competitionId
          seasonId: $seasonId
          teamIds: [$teamId]
          ${contextFiltersQueryArguments}
          ${playTypeOverride}
          ${extraPlayFilters}
        }
        playerStatFilters: {
          playerIds: $playerIds
          rosterPositions: $rosterPositions
          specificPositions: $alignmentPositions
        }
        groupBy: [PLAYER, TEAM, PLAY_POSITION]
        aggregateMode: RATE
        orderBy: {name: "plays", descending: true}
      ) {
        totalCount
        items {
          groups {
            key
            value
          }
          playerName
          teamName
          teamShortName
          rosterPosition
          playPosition
          plays
        }
      }
    }
`;
};
