import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { sortBy } from 'lodash';
import ParsedLink from '../ParsedLink/ParsedLink';
import {
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Games,
  mf_Players,
} from '../../apollo';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import logo from '../../assets/images/logo.svg';
import { StyledHeader } from './Header.styles';
import { formatGameLabel, orderSeasons, playerToOption } from './Header.helper';
import { SORT_DIRECTIONS } from '../../utils/constants/sortDirections';
import { DROPDOWN_CONTAINER_WIDTH } from '../DropdownFilter/DropdownFilter.constants';
import GlobalSearch from '../GlobalSearch/GlobalSearch';

const Header = ({
  pageInfo,
  navigationData,
  isLoadingNavigationData: isLoading,
  handleChange,
  gamesData,
}) => {
  const { ASCENDING } = SORT_DIRECTIONS;
  const { competitions, seasons, teams, players } = navigationData || {};

  const formattedPlayersData = players
    ? sortBy(players, ['mostCommonNumber', 'name'], [ASCENDING, ASCENDING]).map(
        playerToOption
      )
    : [];

  const games = gamesData?.games?.edges.map(({ node }) => node) || [];
  const sortedGames = games.sort((a, b) => new Date(b.date) - new Date(a.date));
  const teamId = useReactiveVar(mf_Teams);
  const formattedGamesData = sortedGames.map((game) => ({
    label: formatGameLabel(game, teamId),
    value: game.id,
  }));

  // props passed to each filter
  const filters = {
    leagues: {
      value: useReactiveVar(mf_Leagues),
      options: competitions?.map((comp) => ({
        value: comp.id,
        label: comp.name,
        level: comp.level,
      })),
      containerWidth: DROPDOWN_CONTAINER_WIDTH.NARROW,
    },
    seasons: {
      value: useReactiveVar(mf_Seasons),
      options: orderSeasons(seasons),
      containerWidth: DROPDOWN_CONTAINER_WIDTH.NARROW,
    },
    games: {
      value: useReactiveVar(mf_Games),
      options: formattedGamesData,
    },
    teams: {
      value: useReactiveVar(mf_Teams),
      options: teams?.map((team) => ({
        value: team.id,
        label: `${team.name} [${team?.gameCount || 0}]`,
      })),
      containedMenu: false,
    },
    players: {
      value: useReactiveVar(mf_Players),
      options: formattedPlayersData,
      containerWidth: DROPDOWN_CONTAINER_WIDTH.WIDE,
    },
  };

  return (
    <StyledHeader role="banner">
      <ParsedLink href="/">
        <img src={logo} width="157" alt="Statsbomb IQ" />
      </ParsedLink>
      {pageInfo?.mainFilters?.map((filter) => {
        const thisFilter = filters[filter];
        const value =
          thisFilter.options?.find((item) => item.value === thisFilter.value) ||
          thisFilter.options?.[0];

        return (
          <DropdownFilter
            value={value}
            data={thisFilter.options}
            label={filter}
            key={filter}
            isLoading={isLoading}
            isDisabled={isLoading}
            containerWidth={thisFilter.containerWidth}
            containedMenu={thisFilter.containedMenu}
            handleChange={(selectedOption) =>
              handleChange(selectedOption, filter)
            }
          />
        );
      })}
      <GlobalSearch />
    </StyledHeader>
  );
};

Header.propTypes = {
  // pageInfo is the parsed from uiDefault.pages[]
  // contains details regarding the page setup
  // eslint-disable-next-line react/forbid-prop-types
  pageInfo: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  navigationData: PropTypes.object,
  isLoadingNavigationData: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  gamesData: PropTypes.object,
};

Header.defaultProps = {
  pageInfo: {},
  navigationData: {},
  isLoadingNavigationData: false,
  gamesData: {},
};

export default Header;
