import Fuse from 'fuse.js';

export const filterTree = (tree, query, threshold = 0.3) => {
  // If the query is empty, return the original tree
  if (!query || query.trim() === '') {
    return tree;
  }

  const options = {
    keys: ['label', 'children.label', 'children.children.label'],
    threshold,
    includeScore: true,
  };

  const filterNode = (node) => {
    const fuse = new Fuse([node], options);
    const result = fuse.search(query);

    let filteredChildren = [];
    if (node.children) {
      filteredChildren = node.children
        .map(filterNode)
        .filter((child) => child !== null);
    }

    if (result.length > 0 || filteredChildren.length > 0) {
      return {
        ...node,
        children: filteredChildren,
      };
    }
    return null;
  };

  return tree.map(filterNode).filter((node) => node !== null);
};
