import { select } from 'd3';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useD3 } from '../../../utils/hooks/useD3';
import { OLPRESSURE_WIDTHS, OLPRESSURE_HEIGHTS } from '../OLPressure.constants';
import {
  COLOR_DIVERGENT_SCALING,
  COLOR_GOOD_BAD,
  COLOR_RELATIVE_SCALING,
} from '../../../pages/team/lineBattles/PassPressures/PassPressures.constants';
import {
  barKey,
  colorKeyComparing,
  colorKeyDivergent,
  colorKeyRelative,
  KEY_WIDTH,
  sparklineKey,
} from './OLPressureKey.drawing';
import { marginPropType } from '../../../utils/constants/propTypes';
import { DEFAULT_VISUALISATION_MARGIN } from '../../../utils/constants/charting';

const OLPressureKey = function ({
  showRawDots,
  compareTeamValues,
  colorMode,
  horizontalKey,
  margin,
}) {
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;

  const visualisationWidth =
    OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_AXIS_WIDTH;
  const viewboxWidth = horizontalKey ? visualisationWidth : KEY_WIDTH;
  const viewboxHeight = horizontalKey
    ? OLPRESSURE_HEIGHTS.KEY_BARS
    : OLPRESSURE_HEIGHTS.KEY_BARS +
      OLPRESSURE_HEIGHTS.KEY_COLORS +
      OLPRESSURE_HEIGHTS.KEY_SPARKLINE +
      OLPRESSURE_HEIGHTS.KEY_VERTICAL_PADDING * 2;
  const viewBox = `0 0 ${viewboxWidth + margin.left + margin.right} ${
    viewboxHeight + margin.top + margin.bottom
  }`;

  // // this level declares anything static
  const ref = useD3((svg) => {
    svg.attr('id', 'amf-line-pressure').attr('width', '100%');
  }, []);

  // draw the key (3 sections)
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();
    // backing rect
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', visPalette.background.main);

    const marginTransform = `translate(${margin.left},${margin.top})`;
    const mainG = svg.append('g').attr('transform', marginTransform);

    /* First section explains sizing */
    const barKeyG = mainG.append('g');
    barKey(barKeyG, visPalette, compareTeamValues);

    /* Second section explains sizing */
    const colorGTransform = horizontalKey
      ? `translate(${visualisationWidth / 4},0)`
      : `translate(0,${
          OLPRESSURE_HEIGHTS.KEY_BARS + OLPRESSURE_HEIGHTS.KEY_VERTICAL_PADDING
        })`;
    const colorKeyG = mainG.append('g').attr('transform', colorGTransform);
    if (colorMode === COLOR_RELATIVE_SCALING.value) {
      colorKeyRelative(colorKeyG, visPalette, isDark);
    }
    if (colorMode === COLOR_DIVERGENT_SCALING.value) {
      colorKeyDivergent(colorKeyG, visPalette, isDark);
    }

    if (colorMode === COLOR_GOOD_BAD.value) {
      colorKeyComparing(colorKeyG, visPalette);
    }

    /* Third (if applicable) section explains sizing */
    if (showRawDots) {
      const sparkGTransform = horizontalKey
        ? `translate(${visualisationWidth / 2},0)`
        : `translate(0,${
            OLPRESSURE_HEIGHTS.KEY_BARS +
            OLPRESSURE_HEIGHTS.KEY_VERTICAL_PADDING * 2 +
            OLPRESSURE_HEIGHTS.KEY_COLORS
          })`;
      const sparklineKeyG = mainG
        .append('g')
        .attr('transform', sparkGTransform);
      sparklineKey(sparklineKeyG, visPalette);
    }
  }, [showRawDots, compareTeamValues, colorMode, visPalette, horizontalKey]);

  return <svg ref={ref} />;
};

OLPressureKey.propTypes = {
  /* Displaying the sparkline raw values as well as main vis? */
  showRawDots: PropTypes.bool,
  /* Showing the outlines as team comparison? */
  compareTeamValues: PropTypes.bool,
  /* How are the bars being colored */
  colorMode: PropTypes.string,
  horizontalKey: PropTypes.bool,
  // Object declaring margins if they need adding
  margin: marginPropType,
};

OLPressureKey.defaultProps = {
  showRawDots: true,
  compareTeamValues: false,
  colorMode: COLOR_DIVERGENT_SCALING.value,
  horizontalKey: false,
  margin: DEFAULT_VISUALISATION_MARGIN,
};

export { OLPressureKey };
