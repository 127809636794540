import { styled } from 'styled-components';

export const TabWrapper = styled.div`
  max-width: 100%;

  .ui.attached.tabular.menu {
    max-width: 100%;
    border-color: ${({ theme }) => `${theme.colours.canvas.primary.main}`};
    color: ${({ theme }) => theme.colours.ink.primary.main} !important;
    font-size: 0.8125rem !important;

    /*Tablet and Laptop Widths from Kitbag */
    @media (min-width: 1028px) {
      font-size: 1.2rem;
    }
    @media (min-width: 1440px) {
      font-size: 1.4rem;
    }

    .item {
      background-color: ${({ theme }) =>
        `${theme.colours.canvas.tertiary.main}`};
      color: ${({ theme }) => theme.colours.ink.primary.main} !important;
      border-color: transparent;
      max-width: ${({ $tabWidth }) => `${$tabWidth}%`};
      padding: 1rem;
    }

    .item.active {
      border-color: ${({ theme }) => `${theme.colours.canvas.primary.main}`};
      border-bottom-color: transparent;
    }
  }

  .ui.bottom.attached.segment.active.tab {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: ${({ theme }) => `${theme.colours.canvas.primary.main}`};

    background-color: ${({ theme }) => `${theme.colours.canvas.tertiary.main}`};
    color: ${({ theme }) => theme.colours.ink.primary.main} !important;
  }
`;
TabWrapper.displayName = 'TabWrapper';

export const SubTabWrapper = styled.div`
  display: table;
  border-bottom: none;
  padding-left: 8px;
  font-size: 0.8125rem !important;

  /*Tablet and Laptop Widths from Kitbag */
  @media (min-width: 1028px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1440px) {
    font-size: 1.4rem;
  }

  button {
    display: block;
    float: left;
    height: 24px;
    margin-right: 1rem;
    outline: none;
    border: none;

    &.selected {
      font-weight: 600;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        border: none;
        left: 0;
        bottom: -1px;
      }
    }
  }
`;
SubTabWrapper.displayName = 'SubTabWrapper';

export const TableWrapper = styled.div`
  min-height: 300px;
  margin-top: -16px;
  border: 1px solid #ccc;
  border-color: ${({ theme }) => `${theme.colours.canvas.primary.main}`};
  border-top: none;
`;
TableWrapper.displayName = 'TableWrapper';

export const DownloadButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
`;
DownloadButtonWrapper.displayName = 'DownloadButtonWrapper';

export const ScoutSearch = styled.div`
  position: absolute;
  right: 45px;
  z-index: 2;
`;
ScoutSearch.displayName = 'ScoutSearch';
