import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import { fontBaselineY } from '../../../visualisations/text';

export const KEY_HEIGHT = 72;
export const DOT_WIDTH = 25;
export const RADIUS = 5;
export const CIRCLE_AMOUNT = 7;
export const DOT_GAP = DOT_WIDTH - RADIUS * 2;
export const RUN_RATE_WIDTH = CIRCLE_AMOUNT * DOT_GAP;
export const MARGIN_LEFT = 20;
export const MARGIN_TOP = 8;
export const SECTION_GAP = 50;
export const COLOR_BUCKETS_Y_OFFSET =
  RUN_RATE_WIDTH + MARGIN_LEFT + SECTION_GAP;

export const LABEL_Y_OFFSET =
  fontBaselineY(
    VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT
  ) + 35;

export const HEADER_Y_OFFSET = fontBaselineY(
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
);
