import React from 'react';
import PropTypes from 'prop-types';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp, biometricToken } from '../../../Scout.proptypes';
import { SearchResultLi } from '../BiometricFilters.styles';

const BiometricListItem = ({
  selectedBiometrics,
  setSelectedBiometrics,
  biometricKey,
  currentTokens,
  searchResultToken,
  onSelected,
}) => {
  const currentIds = currentTokens.map((t) => t.id);

  const selectLi = () => {
    if (!currentIds.includes(searchResultToken.id)) {
      const otherBiometrics = selectedBiometrics.filter(
        (b) => b.key !== biometricKey
      );
      const bioFilter = {
        key: biometricKey,
        value: currentIds.concat(searchResultToken.id),
        tokens: currentTokens.concat(searchResultToken),
      };
      setSelectedBiometrics(otherBiometrics.concat(bioFilter));
    }
    // whether new or not kill search to return to main
    onSelected();
  };

  /* Making it so when you tab to a Li and hit enter it selects it */
  const enterHit = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      selectLi();
    }
  };
  return (
    <SearchResultLi
      key={searchResultToken.id}
      onClick={selectLi}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      onKeyUp={enterHit}
      title={searchResultToken.name}
    >
      {searchResultToken.name}
    </SearchResultLi>
  );
};

BiometricListItem.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
  // the bio metric stat key that is used in scout filtering
  biometricKey: PropTypes.oneOf(Object.keys(SCOUT_BIOMETRIC_FILTERS))
    .isRequired,
  // array of tokens that are the id/name pairs for whatever the search result are (schools / teams / cities)
  // shouldn't be null but can be an empty array
  currentTokens: PropTypes.arrayOf(biometricToken).isRequired,
  // equivalent to a displayed chip ~ the id / name pair for a search result
  searchResultToken: biometricToken.isRequired,
  // what to do when a Li is clicked (basically research the search)
  onSelected: PropTypes.func,
};

BiometricListItem.defaultProps = {
  onSelected: () => {},
};

export default BiometricListItem;
