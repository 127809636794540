import { clamp, concat } from 'lodash';
import { csHeatmap, csValue } from '../../../utils/helpers/colorScales';
import {
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  HASH_Y_NCAA,
  HASH_Y_NFL,
  HASH_MARK_WIDTH,
  COMPETITION_LEVEL,
  getFieldRelativeYSplits,
  getFieldYSplits,
  ROTATIONS,
} from '../../../utils/constants/charting';
import {
  zoneClampX,
  zoneClampY,
  HASH_BANDS,
} from './PlayerSnap.dataManipulation';
import {
  addCompetitionHashMarks,
  addFieldEdgeMarks,
  addOddEvenZones,
} from '../../../utils/helpers/field.drawing';
import { rotateScaleZoom } from '../../../utils/visualisations/rotateScaleZoom';
import {
  drawLOS,
  addHorizontalGuides,
  addNumberTopGuides,
  addEdgeNumbersVertical,
} from '../../../utils/helpers/fieldVariants.drawing';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { paletteIsDark } from '../../../utils/visualisations/visPalettes';
import {
  BAND_ZONE_LOS_X,
  BAND_ZONE_WIDTH,
  HASH_MARK_ZONE_PADDING,
  KEY_HEIGHT,
  SNAP_DISTRIBUTION_LAYER_CLASSES,
  HEATMAP_COLOR_MODE_TYPES,
  DOT_COLOR_MODE,
  SNAP_DISTRIBUTION_CONFIG,
  SNAP_BANDS,
} from './SnapDistributionChart.constants';

const getBandMeasures = (fieldSettings, showDy, isHorizontal) => {
  const TOTAL_OTHERS_SEPARATOR = showDy ? 2 : 0; // yds
  const BAND_ZONE_SEPARATOR = isHorizontal ? 1 : 3; // yds
  const BAND_ZONE_AFTER = isHorizontal ? 0 : 2; // yds
  const TOTAL_WIDTH =
    (BAND_ZONE_WIDTH * 5 +
      TOTAL_OTHERS_SEPARATOR +
      BAND_ZONE_SEPARATOR * 4 +
      BAND_ZONE_AFTER) *
    fieldSettings.pxPerYard;

  return {
    TOTAL_OTHERS_SEPARATOR,
    BAND_ZONE_SEPARATOR,
    BAND_ZONE_AFTER,
    TOTAL_WIDTH,
  };
};

const getHashBandFromClass = (className) => {
  if (className === SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_LEFT_HASH) {
    return HASH_BANDS.leftHash;
  }
  if (className === SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_LEFT_CENTER) {
    return HASH_BANDS.leftCenter;
  }
  if (className === SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_RIGHT_CENTER) {
    return HASH_BANDS.rightCenter;
  }
  if (className === SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_RIGHT_HASH) {
    return HASH_BANDS.rightHash;
  }
  return null;
};

const drawSummaryText = (
  svgZone,
  zoneName,
  snapCount,
  overrides,
  showDy,
  isDefensivePlayer
) => {
  const snapBand = SNAP_BANDS.find((band) => band.CLASS === zoneName);
  const snapLabel = `${snapBand.LABEL} (${snapCount})`;
  const yRotate = showDy ? 700 : 534;

  svgZone.selectAll('text').remove();
  const snapText = svgZone
    .append('text')
    .attr('fill', overrides.visPalette.text.info)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-size', VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AREA_TITLE.WEIGHT)
    .text(snapLabel);

  if (overrides.orientation === ROTATIONS.HORIZONTAL && isDefensivePlayer) {
    snapText
      .attr('x', 0)
      .attr('y', -4)
      .attr('text-anchor', 'start')
      .attr('transform', `translate(0, ${yRotate}) rotate(180)`);
  } else if (overrides.orientation === ROTATIONS.HORIZONTAL) {
    snapText.attr('x', 0).attr('y', -4).attr('text-anchor', 'end');
  } else if (
    (overrides.orientation === ROTATIONS.VERTICAL_UP && !isDefensivePlayer) ||
    (overrides.orientation === ROTATIONS.VERTICAL_DOWN && isDefensivePlayer)
  ) {
    snapText
      .attr('transform', `translate(100, 0) rotate(90)`) // 100px is: heatmap + snap symbol
      .attr('text-anchor', 'start');
  } else {
    snapText
      .attr('transform', `translate(-160, ${yRotate}) rotate(270)`)
      .attr('text-anchor', 'start');
  }
};

const heatmapStopColor = (split, heatmapData, colorMode, darkMode) => {
  let fraction =
    heatmapData.maxCount > 0 ? split.count / heatmapData.maxCount : 0;

  if (colorMode === HEATMAP_COLOR_MODE_TYPES.VS_TOTAL_MAX) {
    fraction =
      heatmapData.totMaxCount > 0 ? split.count / heatmapData.totMaxCount : 0;
  }
  if (colorMode === HEATMAP_COLOR_MODE_TYPES.VS_PARTITION_MAX) {
    fraction =
      heatmapData.subMaxCount > 0 ? split.count / heatmapData.subMaxCount : 0;
  }

  return `stop-color:${csHeatmap(fraction, darkMode)};stop-opacity:1`;
};

export const drawPlayerHeatmap = (
  svgZone,
  heatmapData,
  showDy,
  zoneKey,
  overrides,
  colorMode,
  xShift,
  id,
  isDarkMode
) => {
  const gradName = `${id}-${zoneKey}-dot-gradient`;
  const cropName = `${id}-${zoneKey}-rect-crop`;

  svgZone.selectAll('defs').remove();
  const svgDefs = svgZone.append('defs');
  const dotGradient = svgDefs
    .append('linearGradient')
    .attr('id', gradName)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%');
  dotGradient.selectAll('stop').remove();

  dotGradient
    .selectAll('stop')
    .data(heatmapData.splitCounts)
    .enter()
    .append('stop')
    .attr('style', (d) =>
      heatmapStopColor(d, heatmapData, colorMode, isDarkMode)
    )
    .attr('offset', (d) => d.nFrac);

  const clippy = svgDefs.append('clipPath').attr('id', cropName);
  clippy
    .append('rect')
    .attr('x', -5)
    .attr('y', 0.5)
    .attr('width', 20)
    .attr(
      'height',
      (showDy ? FIELD_Y_YARDS_RELATIVEY : FIELD_Y_YARDS) * overrides.pxPerYard -
        1
    );

  svgZone.selectAll('g').remove();
  const rectG = svgZone
    .append('g')
    .attr('transform', `translate(${xShift * overrides.pxPerYard} 0)`);
  rectG.selectAll('rect').remove();
  rectG
    .append('rect')
    .attr('id', `${id}-gradrecrt`)
    .attr('x', -5)
    .attr('y', 0)
    .attr('width', 2 * overrides.pxPerYard)
    .attr(
      'height',
      (showDy ? FIELD_Y_YARDS_RELATIVEY : FIELD_Y_YARDS) * overrides.pxPerYard
    )
    .attr('fill', `url(#${gradName})`)
    .attr('stroke', overrides.visPalette.axis)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '2 2')
    .attr('clip-path', `url(#${cropName})`);
};

export const colorDot = (datum, colorMode, visPalette, selected = false) => {
  if (selected) {
    return visPalette.selectedObject;
  }
  if (colorMode === DOT_COLOR_MODE.PLAY_TYPE) {
    if (datum.play.type === 'PASS') {
      return visPalette.objects.n1.main;
    }
    if (datum.play.type === 'RUSH') {
      return visPalette.objects.n2.main;
    }

    return visPalette.objects.n3.main;
  }
  if (colorMode === DOT_COLOR_MODE.GHOST) {
    return visPalette.contrast;
  }
  if (colorMode === DOT_COLOR_MODE.DOWN) {
    if (datum.play.down === 1) {
      return visPalette.objects.n1.main;
    }
    if (datum.play.down === 2) {
      return visPalette.objects.n2.main;
    }
    if (datum.play.down === 3) {
      return visPalette.objects.n3.main;
    }
    if (datum.play.down === 4) {
      return visPalette.objects.n4.main;
    }
    return visPalette.objects.n5.main;
  }
  if (colorMode === DOT_COLOR_MODE.ROUTE) {
    if (datum.target) {
      return visPalette.objects.n3.main;
    }
    if (datum.routeRun) {
      return visPalette.objects.n2.main;
    }
    return visPalette.objects.n1.main;
  }
  if (colorMode === DOT_COLOR_MODE.PLAY_SUCCESS) {
    if (datum.play.touchdownWon) {
      return visPalette.successFail.superSuccess.main;
    }
    if (datum.play.explosive) {
      return visPalette.successFail.success2.main;
    }
    if (datum.play.success) {
      return visPalette.successFail.success.main;
    }
    return visPalette.successFail.fail.main;
  }
  if (colorMode === DOT_COLOR_MODE.YARDS_GAINED) {
    // TODO: this is increasing value scale, possibly should be divergent scale
    const minYdsNet = -10; // yds
    const ydsNetScaleSize = 30; // so up to +20 yds
    const relativeYdsNet = datum.play.yardsNet - minYdsNet;
    const clampedYdsNet = clamp(relativeYdsNet, 0, ydsNetScaleSize);
    const isDarkMode = paletteIsDark(visPalette);
    return csValue(clampedYdsNet / ydsNetScaleSize, isDarkMode);
  }
  // fallback to default object
  return visPalette.objects.n1.main;
};

const drawPlayerDots = (
  svgZone,
  playerData,
  showDx,
  showDy,
  overrides,
  colorMode
) => {
  const halfFieldRelativeY = FIELD_Y_YARDS_RELATIVEY / 2;
  svgZone
    .selectAll('circle')
    .data(playerData, (snap) => snap.id)
    .join(
      (enter) => enter.append('circle'),
      (update) => update,
      (exit) => exit.remove()
    )
    .transition()
    .duration(1000)
    .attr('cx', (d) => {
      const dxYds = showDx ? d.snapRelativeX : 0;
      const clampedDx = zoneClampX(dxYds, -1 * BAND_ZONE_LOS_X, 0);
      return clampedDx * overrides.pxPerYard;
    })
    .attr(
      'cy',
      (d) =>
        (showDy
          ? zoneClampY(
              halfFieldRelativeY + d.snapRelativeY,
              0,
              FIELD_Y_YARDS_RELATIVEY
            )
          : d.freezeFrames[0].y) * overrides.pxPerYard
    )
    .attr('r', 2.5)
    .attr('fill', (d) =>
      d.inSelectedZone
        ? colorDot(d, colorMode, overrides.visPalette)
        : 'transparent'
    )
    .attr('stroke', (d) => colorDot(d, colorMode, overrides.visPalette))
    .attr('stroke-width', 1);
};

const addSnapZoneIcon = (
  svgZone,
  zoneClass,
  overrides,
  showDy,
  isDefensivePlayer
) => {
  const dyTransform = showDy
    ? `translate(0,${
        ((FIELD_Y_YARDS_RELATIVEY - FIELD_Y_YARDS) / 2) * overrides.pxPerYard
      })`
    : '';
  const boxLayer = svgZone.append('g').attr('transform', dyTransform);
  const strokeWidth = 2;
  const hashY =
    overrides.competitionLevel === COMPETITION_LEVEL.NFL
      ? HASH_Y_NFL
      : HASH_Y_NCAA;

  // the left most edge of the left hash mark
  const leftHashLeftEdge = hashY - HASH_MARK_WIDTH;
  // the right most edge of the right hash mark
  const leftHashRightEdge = FIELD_Y_YARDS - leftHashLeftEdge;
  // the right most edge of the left hash mark + zone-padding
  const paddedLeftHashRightEdge = hashY + HASH_MARK_ZONE_PADDING;
  // the the middle of the field
  const center = FIELD_Y_YARDS / 2;
  // the left most edge of the right hash mark + zone-padding
  const paddedRightHashLeftEdge =
    FIELD_Y_YARDS - hashY - HASH_MARK_ZONE_PADDING;
  // the right edge of the right hash
  const rightHashRightEdge = FIELD_Y_YARDS - hashY + HASH_MARK_WIDTH;

  let topY = 0;
  let botY = 0;
  // When displaying defensive players, we need to invert the location of the snap marker
  if (zoneClass === SNAP_DISTRIBUTION_CONFIG.TOTAL.CLASS) {
    topY = leftHashLeftEdge;
    botY = leftHashRightEdge;
  }
  if (zoneClass === SNAP_DISTRIBUTION_CONFIG.LEFT_HASH.CLASS) {
    topY = isDefensivePlayer ? paddedRightHashLeftEdge : leftHashLeftEdge;
    botY = isDefensivePlayer ? rightHashRightEdge : paddedLeftHashRightEdge;
  }
  if (zoneClass === SNAP_DISTRIBUTION_CONFIG.LEFT_CENTER.CLASS) {
    topY = isDefensivePlayer ? center : paddedLeftHashRightEdge;
    botY = isDefensivePlayer ? paddedRightHashLeftEdge : center;
  }
  if (zoneClass === SNAP_DISTRIBUTION_CONFIG.RIGHT_CENTER.CLASS) {
    topY = isDefensivePlayer ? paddedLeftHashRightEdge : center;
    botY = isDefensivePlayer ? center : paddedRightHashLeftEdge;
  }
  if (zoneClass === SNAP_DISTRIBUTION_CONFIG.RIGHT_HASH.CLASS) {
    topY = isDefensivePlayer ? leftHashLeftEdge : paddedRightHashLeftEdge;
    botY = isDefensivePlayer ? paddedLeftHashRightEdge : rightHashRightEdge;
  }
  topY *= overrides.pxPerYard;
  botY *= overrides.pxPerYard;

  // ] shape
  if (showDy) {
    boxLayer
      .append('line')
      .attr('x1', 35)
      .attr('x2', 50)
      .attr('y1', (FIELD_Y_YARDS / 2) * overrides.pxPerYard)
      .attr('y2', topY)
      .attr('stroke', overrides.visPalette.focus)
      .attr('stroke-width', strokeWidth)
      .attr('stroke-dasharray', '2,4');

    boxLayer
      .append('line')
      .attr('x1', 35)
      .attr('x2', 50)
      .attr('y1', (FIELD_Y_YARDS / 2) * overrides.pxPerYard)
      .attr('y2', botY)
      .attr('stroke', overrides.visPalette.focus)
      .attr('stroke-width', strokeWidth)
      .attr('stroke-dasharray', '2,4');
  }

  boxLayer
    .append('path')
    .attr('d', `M50,${topY} l5,0 L55,${botY} l-5,0`)
    .attr('stroke', overrides.visPalette.focus)
    .attr('stroke-width', strokeWidth)
    .attr('fill', 'transparent')
    .attr('transform', `translate(0 , ${0})`);

  // ball icon
  boxLayer
    .append('path')
    .attr('d', `M${74},${(topY + botY) / 2 - 6}, q0 12 -12 12, q0 -12 12 -12 z`)
    .attr('fill', overrides.visPalette.focus)
    .attr('stroke', overrides.visPalette.guides)
    .attr('stroke-width', 1);
};

const renderSnapBand = (
  svg,
  zoneName,
  playerData,
  showDy,
  showDx,
  overrides,
  colorMode,
  isDefensivePlayer
) => {
  const zone = svg.select(
    `.${zoneName + SNAP_DISTRIBUTION_LAYER_CLASSES.DOTS_HEAT_SUFFIX}`
  );

  drawSummaryText(
    zone,
    zoneName,
    playerData.length,
    overrides,
    showDy,
    isDefensivePlayer
  );
  drawPlayerDots(zone, playerData, showDx, showDy, overrides, colorMode);
};

export const renderSnapDistribution = (
  svg,
  bandedData,
  overrides,
  showDy,
  showDx,
  colorMode,
  showTotalOnly,
  isDefensivePlayer
) => {
  const snapBandsToRender = showTotalOnly ? [SNAP_BANDS[0]] : SNAP_BANDS;

  snapBandsToRender.forEach((snapBand) => {
    renderSnapBand(
      svg,
      snapBand.CLASS,
      bandedData[snapBand.BAND_DATA],
      showDy,
      showDx,
      overrides,
      colorMode,
      isDefensivePlayer
    );
  });
};

const renderSnapBandHeatmap = (
  svg,
  zoneName,
  heatmapData,
  showDy,
  overrides,
  colorMode,
  id
) => {
  const zone = svg.select(
    `.${zoneName + SNAP_DISTRIBUTION_LAYER_CLASSES.DOTS_HEAT_SUFFIX}`
  );
  const heatmapGClass = 'sd-heatmap-g';

  zone.select(`.${heatmapGClass}`).remove();
  const heatmapZone = zone.append('g').attr('class', heatmapGClass);
  drawPlayerHeatmap(
    heatmapZone,
    heatmapData,
    showDy,
    zoneName,
    overrides,
    colorMode,
    2,
    id
  );
};

export const renderSnapDistributionHeatmaps = (
  svg,
  bandedHeatmapData,
  overrides,
  showDy,
  colorMode,
  id
) => {
  SNAP_BANDS.forEach((snapBand) => {
    renderSnapBandHeatmap(
      svg,
      snapBand.CLASS,
      bandedHeatmapData[snapBand.BAND_DATA],
      showDy,
      overrides,
      colorMode,
      id
    );
  });
};

// creates a section in the snap distribution chart
const addSnapBandArea = (
  dotsLayer,
  zoneName,
  zoneX,
  FIELD_Y,
  yRotation,
  fieldSettings,
  showDy,
  clipPathId,
  isDefensivePlayer
) => {
  const { orientation, pxPerYard, visPalette } = fieldSettings;
  // whether the section should rotate to accommodate for orientation and player position
  const rotate =
    (orientation === ROTATIONS.VERTICAL_UP && isDefensivePlayer) ||
    (orientation === ROTATIONS.VERTICAL_DOWN && !isDefensivePlayer) ||
    (orientation === ROTATIONS.HORIZONTAL && isDefensivePlayer)
      ? ` rotate(180, -30, ${yRotation})`
      : '';
  const zone = dotsLayer.append('g').attr('class', zoneName);
  zone.attr('transform', `translate(${zoneX},0)${rotate}`);

  zone.selectAll('line').remove();
  zone.selectAll('rect').remove();
  zone.selectAll('g').remove();

  const fieldHeight = FIELD_Y * pxPerYard;
  const backgroundG = zone
    .append('g')
    .attr('clip-path', `url(#${clipPathId})`)
    .append('g')
    .attr('transform', `translate(${-200},0)`);

  /* Draw the field */
  addOddEvenZones(backgroundG, fieldSettings, FIELD_Y, 2, isDefensivePlayer);
  if (showDy) {
    addHorizontalGuides(backgroundG, fieldSettings, 200);
  } else {
    const hashesToDraw = 40;
    backgroundG.call(addCompetitionHashMarks, fieldSettings, hashesToDraw);
    backgroundG.call(addFieldEdgeMarks, fieldSettings, hashesToDraw);
    addNumberTopGuides(backgroundG, fieldSettings, 200);
  }
  backgroundG.call(drawLOS, { ...fieldSettings, LOS_X: 20 }, FIELD_Y);
  zone
    .append('rect')
    .attr('x', BAND_ZONE_LOS_X * -1 * pxPerYard)
    .attr('width', BAND_ZONE_WIDTH * pxPerYard)
    .attr('y', 0)
    .attr('height', fieldHeight)
    .attr('stroke', visPalette.border)
    .attr('stroke-width', 1)
    .attr('fill', 'transparent');

  zone.call(
    addSnapZoneIcon,
    zoneName,
    fieldSettings,
    showDy,
    isDefensivePlayer
  );

  zone
    .append('g')
    .attr('class', zoneName + SNAP_DISTRIBUTION_LAYER_CLASSES.DOTS_HEAT_SUFFIX);
  zone
    .append('g')
    .attr(
      'class',
      zoneName + SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_SELECTION_SUFFIX
    );
};

const addSelectionZones = (
  svg,
  zoneName,
  fieldSettings,
  showDy,
  selectedZones,
  setSelectedZones
) => {
  const selectionZone = svg.select(
    `.${zoneName + SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_SELECTION_SUFFIX}`
  );
  selectionZone.selectAll('rect').remove();

  const zones = showDy
    ? getFieldRelativeYSplits()
    : getFieldYSplits(fieldSettings.competitionLevel);

  const isSelectedZone = (z) => {
    const filteredZones =
      selectedZones &&
      selectedZones.filter(
        (s) => s.zoneIndex === z.zoneIndex && s.snapZone === zoneName
      );
    return filteredZones && filteredZones.length > 0;
  };

  const selectZone = (z) => {
    if (isSelectedZone(z)) {
      // remove this zones from the selection. Leave other selections in this band alone if there are any
      const otherSelectedZones = selectedZones.filter(
        (s) => s.zoneIndex !== z.zoneIndex
      );
      const newZones =
        otherSelectedZones.length > 0 ? otherSelectedZones : null;
      setSelectedZones(newZones);
    } else {
      // if already zones in this band, add to them, else start afresh with this zone
      const filteredZones =
        selectedZones && selectedZones.filter((s) => s.snapZone === zoneName);
      const additionalZone = [
        {
          snapZone: zoneName,
          zoneIndex: z.zoneIndex,
          hashBand: getHashBandFromClass(zoneName),
        },
      ];
      const newZones = filteredZones
        ? concat(filteredZones, additionalZone)
        : additionalZone;
      setSelectedZones(newZones);
    }
  };

  selectionZone
    .selectAll('rect')
    .data(zones)
    .enter()
    .append('rect')
    .attr('x', -150)
    .attr('y', (d) => d.y * fieldSettings.pxPerYard)
    .attr('width', 150)
    .attr('height', (d) => d.height * fieldSettings.pxPerYard)
    .attr('class', (d) =>
      isSelectedZone(d) ? 'selectable-zone selected' : 'selectable-zone'
    )
    .attr('fill-opacity', 0)
    .on('click', (_, d) => selectZone(d));
};

export const drawSnapDistributionKey = (
  svg,
  showKey,
  fieldSettings,
  colorMode,
  showDy,
  isDarkMode,
  showTotalOnly
) => {
  const { visPalette } = fieldSettings;
  const isHorizontal = fieldSettings.orientation === ROTATIONS.HORIZONTAL;
  const keyLayer = svg.select(`.${SNAP_DISTRIBUTION_LAYER_CLASSES.KEY}`);
  keyLayer.selectAll('rect').remove();
  keyLayer.selectAll('g').remove();

  if (showKey) {
    const fieldHeight = showDy
      ? FIELD_Y_YARDS_RELATIVEY * fieldSettings.pxPerYard
      : FIELD_Y_YARDS * fieldSettings.pxPerYard;
    const bandMeasures = getBandMeasures(fieldSettings, showDy, isHorizontal);

    let widthPx = isHorizontal ? bandMeasures.TOTAL_WIDTH : fieldHeight;
    widthPx = showTotalOnly ? widthPx * 0.25 : widthPx;

    const keyHead = keyLayer.append('g').attr('transform', `translate(0, 0)`);
    const addHeader = (label) => {
      keyHead
        .append('text')
        .attr('x', 0)
        .attr('y', showTotalOnly ? 31 : 11)
        .attr('fill', visPalette.text.info)
        .attr('font-family', DEFAULT_FONT)
        .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE)
        .attr(
          'font-weight',
          VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT
        )
        .attr('text-anchor', 'start')
        .text(label);
    };
    const keyDots = keyLayer
      .append('g')
      .attr('transform', `translate(0, ${showTotalOnly ? 30 : 10})`);
    const keyTextY = 19;
    const addLabelledCircle = (x, label, fill) => {
      const keyItemG = keyDots
        .append('g')
        .attr('transform', `translate(${x}, 0)`);
      keyItemG
        .append('circle')
        .attr('cx', 5)
        .attr('cy', 15)
        .attr('r', 5)
        .attr('fill', fill)
        .attr('stroke', 'none');
      keyItemG
        .append('text')
        .attr('x', 15)
        .attr('y', keyTextY)
        .attr('fill', visPalette.text.info)
        .attr('font-family', DEFAULT_FONT)
        .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
        .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
        .attr('text-anchor', 'start')
        .text(label);
    };
    /* Add dots relevant to color mode selection */
    if (colorMode === DOT_COLOR_MODE.PLAY_TYPE) {
      addHeader('Dot Color Denotes Play Type');
      addLabelledCircle(0, 'Pass', visPalette.objects.n1.main);
      addLabelledCircle(widthPx * 0.2, 'Run', visPalette.objects.n2.main);
      addLabelledCircle(widthPx * 0.4, 'Other', visPalette.objects.n3.main);
    }
    if (colorMode === DOT_COLOR_MODE.DEFAULT) {
      addHeader('Display Dots without Color Meaning');
      addLabelledCircle(0, 'All', visPalette.objects.n1.main);
    }
    if (colorMode === DOT_COLOR_MODE.DOWN) {
      addHeader('Dot Color Denotes Down');
      addLabelledCircle(0, '1st Down', visPalette.objects.n1.main);
      addLabelledCircle(widthPx * 0.2, '2nd Down', visPalette.objects.n2.main);
      addLabelledCircle(widthPx * 0.4, '3rd Down', visPalette.objects.n3.main);
      addLabelledCircle(widthPx * 0.6, '4th Down', visPalette.objects.n4.main);
      addLabelledCircle(widthPx * 0.8, 'Other', visPalette.objects.n5.main);
    }
    if (colorMode === DOT_COLOR_MODE.ROUTE) {
      addHeader('Color Denotes Offensive Routing');
      addLabelledCircle(0, 'Non Route Path', visPalette.objects.n1.main);
      addLabelledCircle(widthPx * 0.2, 'Route', visPalette.objects.n2.main);
      addLabelledCircle(widthPx * 0.4, 'Target', visPalette.objects.n3.main);
    }
    if (colorMode === DOT_COLOR_MODE.PLAY_SUCCESS) {
      addHeader('Dot Color Denotes Play Outcome');
      addLabelledCircle(
        0,
        'Touchdown Play',
        visPalette.successFail.superSuccess.main
      );
      addLabelledCircle(
        widthPx * 0.2,
        'Explosive Play',
        visPalette.successFail.success2.main
      );
      addLabelledCircle(
        widthPx * 0.4,
        'Successful Play',
        visPalette.successFail.success.main
      );
      addLabelledCircle(
        widthPx * 0.6,
        'Failed Play',
        visPalette.successFail.fail.main
      );
    }
    if (colorMode === DOT_COLOR_MODE.YARDS_GAINED) {
      addHeader('Dot Color Denotes Yards Gained');
      // more custom setup as continual scale
      const keyItemG = keyDots.append('g');
      const dotsStart = 100;
      const dotsSeparator = 12;
      const dotsToShow = 10;
      const dotsIndexes = [...Array(dotsToShow + 1).keys()];
      const dotsEnd = dotsStart + dotsToShow * dotsSeparator;
      keyItemG
        .append('text')
        .attr('x', dotsStart - 10)
        .attr('y', keyTextY)
        .attr('fill', visPalette.text.info)
        .attr('font-family', DEFAULT_FONT)
        .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
        .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
        .attr('text-anchor', 'end')
        .text('-10Yards or Less');

      keyItemG
        .selectAll('circle')
        .data(dotsIndexes)
        .enter()
        .append('circle')
        .attr('cx', (d) => dotsStart + d * dotsSeparator)
        .attr('cy', 15)
        .attr('r', 5)
        .attr('fill', (d) => csValue(d / dotsToShow, isDarkMode))
        .attr('stroke', 'none');

      keyItemG
        .append('text')
        .attr('x', dotsEnd + 10)
        .attr('y', keyTextY)
        .attr('fill', visPalette.text.info)
        .attr('font-family', DEFAULT_FONT)
        .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
        .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
        .attr('text-anchor', 'start')
        .text('+20Yards or More');
    }
  }
};

export const setupViewBoxAndLayers = (
  id,
  svg,
  fieldSettings,
  margin,
  showDy,
  showKey,
  showTotalOnly,
  isDefensivePlayer
) => {
  const isHorizontal = fieldSettings.orientation === ROTATIONS.HORIZONTAL;
  const BAND_ZONE_CLIP_NAME = `${id}-band-zone-clip-rect`;
  // bands are positioned on the LOS
  const bandMeasures = getBandMeasures(fieldSettings, showDy, isHorizontal);
  const BAND_LOCATIONS = isHorizontal
    ? {
        TOTAL:
          (bandMeasures.TOTAL_OTHERS_SEPARATOR + BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        LEFT_HASH:
          (BAND_ZONE_WIDTH +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        LEFT_CENTER:
          (BAND_ZONE_WIDTH * 2 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 2 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        RIGHT_CENTER:
          (BAND_ZONE_WIDTH * 3 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 3 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        RIGHT_HASH:
          (BAND_ZONE_WIDTH * 4 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 4 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
      }
    : {
        RIGHT_HASH:
          (bandMeasures.TOTAL_OTHERS_SEPARATOR + BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        RIGHT_CENTER:
          (BAND_ZONE_WIDTH +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        LEFT_CENTER:
          (BAND_ZONE_WIDTH * 2 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 2 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        LEFT_HASH:
          (BAND_ZONE_WIDTH * 3 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 3 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
        TOTAL:
          (BAND_ZONE_WIDTH * 4 +
            bandMeasures.TOTAL_OTHERS_SEPARATOR +
            bandMeasures.BAND_ZONE_SEPARATOR * 4 +
            BAND_ZONE_LOS_X) *
          fieldSettings.pxPerYard,
      };
  const DISPLAY_X = showTotalOnly
    ? (bandMeasures.TOTAL_OTHERS_SEPARATOR + BAND_ZONE_LOS_X) *
      fieldSettings.pxPerYard
    : bandMeasures.TOTAL_WIDTH;
  const FIELD_Y = showDy ? FIELD_Y_YARDS_RELATIVEY : FIELD_Y_YARDS;

  const fieldHeight = isHorizontal
    ? FIELD_Y * fieldSettings.pxPerYard
    : DISPLAY_X;
  const fieldWidth = isHorizontal
    ? DISPLAY_X
    : FIELD_Y * fieldSettings.pxPerYard;

  const keyHeight = showKey ? KEY_HEIGHT : 0;
  const viewBox = `0 0 ${fieldWidth + margin.left + margin.right} ${
    fieldHeight + margin.top + keyHeight + margin.bottom
  }`;

  svg.selectAll('rect').remove();

  // append the svg object to the body of the page
  svg.attr('viewBox', viewBox);

  svg.selectAll('g').remove();
  const pitchAreaOffset = `translate(${margin.left},${margin.top})`;

  const marginAdjustedSection = svg
    .append('g')
    .attr('class', SNAP_DISTRIBUTION_LAYER_CLASSES.MARGIN_ADJUSTED)
    .attr('transform', pitchAreaOffset);

  marginAdjustedSection
    .append('g')
    .attr('class', SNAP_DISTRIBUTION_LAYER_CLASSES.KEY)
    .attr('transform', `translate(0,${fieldHeight + margin.bottom / 2})`);

  const dataSection = marginAdjustedSection
    .append('g')
    .attr('class', SNAP_DISTRIBUTION_LAYER_CLASSES.DATA);

  const dotsLayerRSZG = rotateScaleZoom({
    baseG: dataSection,
    idPrefix: id,
    viewPortWidth: fieldWidth,
    viewPortHeight: fieldHeight,
    cropToViewport: false,
    // we don't want to pass VERTICAL_DOWN orientation because that would rotate the whole vis.
    // instead, when VERTICAL_DOWN is selected, we handle/rotate each section.
    orientation: isHorizontal ? ROTATIONS.HORIZONTAL : ROTATIONS.VERTICAL_UP,
    fieldWidth: DISPLAY_X,
    fieldHeight: FIELD_Y * fieldSettings.pxPerYard,
    targetFieldX: DISPLAY_X / 2,
    targetFieldY: (FIELD_Y * fieldSettings.pxPerYard) / 2,
    scaleFactor: 1,
    bindEdges: false,
    fieldBoundary: 0,
    addZoom: false,
    zoomableGId: `${id}-zoomable-g`,
    resetButtonId: null,
  });

  const backingTextLayer = dotsLayerRSZG
    .append('g')
    .attr('class', SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_TEXT)
    .attr(
      'transform',
      `translate(${
        (bandMeasures.TOTAL_OTHERS_SEPARATOR - 1) * fieldSettings.pxPerYard
      } 0)`
    );
  const dotsLayer = dotsLayerRSZG
    .append('g')
    .attr('class', SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS);

  // If in relative mode add field displacement values
  if (showDy) {
    backingTextLayer.call(addEdgeNumbersVertical, fieldSettings);
  }

  const dotsLayerDefs = dotsLayer.append('defs');
  const zoneClip = dotsLayerDefs
    .append('clipPath')
    .attr('id', BAND_ZONE_CLIP_NAME);
  zoneClip
    .append('rect')
    .attr('x', BAND_ZONE_LOS_X * -1 * fieldSettings.pxPerYard)
    .attr('y', 0)
    .attr('width', BAND_ZONE_WIDTH * fieldSettings.pxPerYard)
    .attr('height', FIELD_Y * fieldSettings.pxPerYard);

  const snapBands = Object.entries(SNAP_DISTRIBUTION_CONFIG);
  const snapBandsToRender = showTotalOnly ? [snapBands[0]] : snapBands;
  const yRotation = isHorizontal ? fieldHeight / 2 : fieldWidth / 2;

  snapBandsToRender.forEach(([key, snapBand]) => {
    dotsLayer.call(
      addSnapBandArea,
      snapBand.CLASS,
      BAND_LOCATIONS[key],
      FIELD_Y,
      yRotation,
      fieldSettings,
      showDy,
      BAND_ZONE_CLIP_NAME,
      isDefensivePlayer
    );
  });
};

export const setupSelectionZones = (
  svg,
  fieldSettings,
  showDy,
  selectedZones,
  setSelectedZones
) => {
  SNAP_BANDS.forEach((snapBand) => {
    svg.call(
      addSelectionZones,
      snapBand.CLASS,
      fieldSettings,
      showDy,
      selectedZones,
      setSelectedZones
    );
  });
};
