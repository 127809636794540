import { clamp, maxBy } from 'lodash';
import { contourDensity, geoPath } from 'd3';
import { csHeatmap, csIntensity } from '../../utils/helpers/colorScales';
import { playOutcomeColor } from '../../utils/visualisations/playSuccess';
import {
  COLORING_OPTION_UNDERTHOW_OVERTHROW,
  COLORING_OPTION_PASS_VELOCITY,
  COLORING_OPTION_NONE,
  COLORING_OPTION_MONOCHROME,
  COLORING_OPTION_BY_PLAYER,
  COLORING_OPTION_BY_TARGET,
  COLORING_OPTION_PLAY_SUCCESS,
  SVG_CLASS_NAMES,
} from './PassingChart.constants';
import { FIELD_Y_YARDS_RELATIVEY } from '../../utils/constants/charting';

/**
 * @param {Object} row
 * @param {Boolean} row.success
 * @param {Boolean} row.catchInterception
 * @param {Boolean} row.passUnderthrow
 * @param {Boolean} row.passOverthrow
 * @param {Number} row.passVelocity
 * @param {String} coloringOption //COLORING_OPTIONS.value
 * @return {string}
 */
export const applyColour = (
  {
    success,
    catchInterception,
    passUnderthrow,
    passOverthrow,
    passVelocity,
    passTouchdown,
    passerColor,
    targetColor,
    id,
    playOutcome,
  },
  coloringOption,
  visPalette,
  selectedEventId
) => {
  if (selectedEventId && id === selectedEventId) {
    return visPalette.selectedObject;
  }

  if (coloringOption === COLORING_OPTION_UNDERTHOW_OVERTHROW.value) {
    switch (true) {
      case passOverthrow:
        return visPalette.successFail.fail.main;
      case passUnderthrow:
        return visPalette.successFail.fail2.main;
      default:
        return visPalette.successFail.neutral.main;
    }
  } else if (coloringOption === COLORING_OPTION_PASS_VELOCITY.value) {
    const minPassVelocity = 10; // yds per second ~ 20 miles per hour
    const maxPassVelocity = 30; // yds per second ~ 60 miles per hour
    const clampedVelocity = clamp(
      passVelocity,
      minPassVelocity,
      maxPassVelocity
    );
    const relativeVelocity =
      (clampedVelocity - minPassVelocity) / (maxPassVelocity - minPassVelocity);
    return csIntensity(relativeVelocity);
  } else if (coloringOption === COLORING_OPTION_NONE.value) {
    return visPalette.objects.n1.main;
  } else if (coloringOption === COLORING_OPTION_MONOCHROME) {
    return visPalette.objects.neutral.main;
  } else if (coloringOption === COLORING_OPTION_BY_PLAYER.value) {
    return passerColor;
  } else if (coloringOption === COLORING_OPTION_BY_TARGET.value) {
    return targetColor;
  } else if (coloringOption === COLORING_OPTION_PLAY_SUCCESS.value) {
    return playOutcomeColor(playOutcome, visPalette);
  } else {
    // event success (pass complete/incomplete)
    switch (true) {
      case passTouchdown:
        return visPalette.successFail.superSuccess.main;
      case success:
        return visPalette.successFail.success.main;
      case catchInterception:
        return visPalette.successFail.superFail.main;
      default:
        return visPalette.successFail.fail.main;
    }
  }
};

/** Puts the basic groups in place once only so children can modfiy without being wiped/rebuilt */
export const addSVGStructure = (
  svg,
  visPalette,
  marginTransform,
  axesPaddingTransform,
  rotationTransform,
  cropName
) => {
  // reset svg
  svg.selectAll('*').remove();

  // BACKING RECT FOR THE SVG
  svg
    .append('rect')
    .attr('class', SVG_CLASS_NAMES.BACKGROUND_RECT)
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('fill', visPalette?.background.main);

  const inMarginsG = svg
    .append('g')
    .attr('class', SVG_CLASS_NAMES.IN_MARGINS)
    .attr('transform', marginTransform);
  const inPaddingG = inMarginsG
    .append('g')
    .attr('class', SVG_CLASS_NAMES.IN_AXES_PADDING)
    .attr('transform', axesPaddingTransform);

  inPaddingG
    .append('g')
    .attr('class', SVG_CLASS_NAMES.UNDERFIELD)
    .attr('clip-path', `url(#${cropName})`)
    .attr('transform', rotationTransform);
  inPaddingG
    .append('g')
    .attr('class', SVG_CLASS_NAMES.FIELD)
    .attr('transform', rotationTransform);
  const onFieldG = inPaddingG
    .append('g')
    .attr('class', SVG_CLASS_NAMES.ONFIELD)
    .attr('transform', rotationTransform);

  const dataLayer = onFieldG
    .append('g')
    .attr('class', SVG_CLASS_NAMES.DATA_LAYER)
    .attr('clip-path', `url(#${cropName})`);
  dataLayer.append('g').attr('class', SVG_CLASS_NAMES.CLICKABLE_FIELD);
  dataLayer.append('g').attr('class', SVG_CLASS_NAMES.PLAYER_DOTS);
};

/** Updates structure for rotation or aspect change */
export const updateSVGStructure = (
  svg,
  axesPaddingTransform,
  rotationTransform,
  losTransform
) => {
  // top level areas ~ find and amend transforms
  svg
    .select(`.${SVG_CLASS_NAMES.IN_AXES_PADDING}`)
    .attr('transform', axesPaddingTransform);
  svg
    .select(`.${SVG_CLASS_NAMES.UNDERFIELD}`)
    .attr('transform', rotationTransform);
  svg
    .select(`.${SVG_CLASS_NAMES.ONFIELD}`)
    .attr('transform', rotationTransform);

  // ADD THE FIELD
  svg.select(`.${SVG_CLASS_NAMES.FIELD}`).selectAll('g').remove();
  svg.select(`.${SVG_CLASS_NAMES.FIELD}`).attr('transform', rotationTransform);

  svg
    .select(`.${SVG_CLASS_NAMES.CLICKABLE_FIELD}`)
    .attr('transform', losTransform);
};

/** Draw the heatmap layer */
export const renderHeatmap = ({
  svg,
  heatmapArea,
  losAdjust,
  displayYPassRelative,
  data,
  selectedPlays,
  heatmapCropName,
  isDark,
}) => {
  const { width, height, borderShift } = heatmapArea;

  // compute the density data
  const densityData = contourDensity()
    .x(({ lineOfScrimmageEndX }) => lineOfScrimmageEndX + losAdjust)
    .y(({ endY, passDeltaY }) =>
      displayYPassRelative ? passDeltaY + height / 2 : endY
    )
    .size([width, height])
    .bandwidth(20)(data);
  const maxDensity = (maxBy(densityData, 'value') || {}).value;

  const heatmapZone = svg.select(`.${SVG_CLASS_NAMES.UNDERFIELD}`).append('g');

  const topHeatmap = heatmapZone
    .append('g')
    .attr(
      'clip-path',
      selectedPlays.current.length > 0 ? `url(#${heatmapCropName})` : null
    );

  topHeatmap.lower().attr('transform', `translate(0,${borderShift})`);

  topHeatmap
    .selectAll('path')
    .data(densityData)
    .enter()
    .append('path')
    .attr('d', geoPath())
    .attr('opacity', 1)
    .attr('fill', ({ value }) => csHeatmap(value / maxDensity, isDark)); // magma colors

  if (selectedPlays.current.length > 0) {
    const bottomHeatmap = heatmapZone.append('g').attr('opacity', 0.4);

    bottomHeatmap
      .append('g')
      .append('rect')
      .attr('fill', csHeatmap(0, isDark)) // passing chart bg color
      .attr('x', 0)
      .attr('y', -borderShift)
      .attr('width', width)
      .attr('height', height);

    bottomHeatmap.lower().attr('transform', `translate(0,${borderShift})`);

    bottomHeatmap
      .selectAll('path')
      .data(densityData)
      .enter()
      .append('path')
      .attr('d', geoPath())
      .attr('fill', ({ value }) => csHeatmap(value / maxDensity, isDark)); // magma colors
  }
};

export const drawPassPaths = ({
  eventsG,
  displayYPassRelative,
  data,
  overrides,
  coloringOption,
  visPalette,
}) => {
  eventsG
    .selectAll('line')
    .data(data, ({ id }) => id)
    .enter()
    .append('line')
    .attr('x1', ({ lineOfScrimmageX }) => lineOfScrimmageX)
    .attr('x2', ({ lineOfScrimmageEndX }) => lineOfScrimmageEndX)
    .attr('y1', ({ y }) =>
      displayYPassRelative
        ? (FIELD_Y_YARDS_RELATIVEY / 2) * overrides.pxPerYard
        : y
    )
    .attr('y2', ({ endY, passDeltaY }) =>
      displayYPassRelative
        ? passDeltaY + (FIELD_Y_YARDS_RELATIVEY / 2) * overrides.pxPerYard
        : endY
    )
    .attr('opacity', 0.6)
    .attr('stroke-width', 1)
    .attr('stroke', (r) => applyColour(r, coloringOption, visPalette));

  if (!displayYPassRelative) {
    eventsG
      .selectAll('g')
      .data(data, ({ id }) => id)
      .enter()
      .append('g')
      .attr(
        'transform',
        (a) =>
          `translate(${a.lineOfScrimmageX},${a.y}) rotate(${a.theta},0,0) scale(0.25,0.25)`
      )
      .append('svg:path')
      .attr('fill', (r) => applyColour(r, coloringOption, visPalette))
      .attr('d', 'M-9 -10 l18 9 l-18 9 l4 -9 z')
      .attr('stroke', 'none');
  }
};

export const drawPassDots = ({
  eventsG,
  displayYPassRelative,
  data,
  overrides,
  dotColorOption,
  visPalette,
  radius,
}) =>
  eventsG
    .selectAll('circle')
    .data(data, ({ id }) => id)
    .enter()
    .append('circle')
    .attr('cx', ({ lineOfScrimmageEndX }) => lineOfScrimmageEndX)
    .attr('cy', ({ passDeltaY, endY }) =>
      displayYPassRelative
        ? passDeltaY + (FIELD_Y_YARDS_RELATIVEY / 2) * overrides.pxPerYard
        : endY
    )
    .attr('r', radius)
    .attr('fill', (r) =>
      r.isOut ? 'transparent' : applyColour(r, dotColorOption, visPalette)
    )
    .attr('stroke', (r) => applyColour(r, dotColorOption, visPalette))
    .attr('stroke-width', 1);
