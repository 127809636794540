const translatePoint = function (node) {
  const nodeLen = node?.getTotalLength();

  return () => (t) => {
    const point = node?.getPointAtLength(t * nodeLen);
    // eslint-disable-next-line consistent-return
    return `translate(${point?.x},${point?.y})`;
  };
};

// to do: refactor translatePoint to accept rotate as an override
const translatePointRotate = function (node, textRotate) {
  const nodeLen = node?.getTotalLength();

  return () => (t) => {
    const point = node?.getPointAtLength(t * nodeLen);
    // eslint-disable-next-line consistent-return
    return `translate(${point?.x},${point?.y}) rotate(${textRotate},0,0)`;
  };
};

export { translatePoint, translatePointRotate };
