export const PRODUCTION = 'production';

export const PAGE = {
  DEFAULT: {
    top: '3.25rem',
  },
  WITH_CONTEXT_FILTERS: {
    top: '6.125rem',
  },
};
