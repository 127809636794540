import PropTypes from 'prop-types';

// GET_TEAMS
export const teamDetailsPropTypes = PropTypes.shape({
  // colour hex codes
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  colorTertiary: PropTypes.string,
  gameCount: PropTypes.number,
  id: PropTypes.number,
  // team name: Arizona Cardinals
  name: PropTypes.string,
  // team short name: ARI
  shortName: PropTypes.string,
});

export const playerDetailsPropTypes = PropTypes.shape({
  gamesRostered: PropTypes.number,
  id: PropTypes.number,
  mostCommonNumber: PropTypes.number,
  mostCommonPosition: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    generalPosition: PropTypes.string,
  }),
  name: PropTypes.string,
});
