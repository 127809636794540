import { isEmpty, reverse, uniqBy, sortBy, meanBy } from 'lodash';
import { HAVOC_AGGREGATION_DATA_TERMS } from './HavocTable.jsx/HavocTable.constants';
import { API_EVENT_TYPE_KEYS } from '../../../utils/constants/api';
import { safeDivide } from '../../../utils/helpers/maths';

/* 
For some event data (e.g. row), get the count of plays containing at least one relevant havoc event 
  exported for tests
*/
export const havocPlayTotals = (havocData) => {
  if (isEmpty(havocData)) {
    return {};
  }
  const sackEvents = havocData?.filter(
    (f) => f.havocType === API_EVENT_TYPE_KEYS.SACK
  );
  const sackPlays = uniqBy(sackEvents, 'playUUID').length;

  const pressureEvents = havocData?.filter(
    (f) => f.havocType === API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE
  );
  const pressurePlays = uniqBy(pressureEvents, 'playUUID').length;
  const passPlays = uniqBy(
    pressureEvents.concat(sackEvents),
    'playUUID'
  ).length;

  const tackleEvents = havocData?.filter(
    (f) => f.havocType === API_EVENT_TYPE_KEYS.TACKLE
  );
  const tacklePlays = uniqBy(tackleEvents, 'playUUID').length;

  const runDisruptionEvents = havocData?.filter(
    (f) => f.havocType === API_EVENT_TYPE_KEYS.RUN_DISRUPTION
  );
  const runDisruptionPlays = uniqBy(runDisruptionEvents, 'playUUID').length;
  const runPlays = uniqBy(
    runDisruptionEvents.concat(tackleEvents),
    'playUUID'
  ).length;

  const havocPlays = uniqBy(havocData, 'playUUID').length;

  return {
    havocPlays,
    passPlays,
    runPlays,
    sackPlays,
    pressurePlays,
    tacklePlays,
    runDisruptionPlays,
    /** events (old) */
    havocEvents: havocData.length,
    sackEvents: sackEvents.length,
    pressureEvents: pressureEvents.length,
    tackleEvents: tackleEvents.length,
    runDisruptionEvents: runDisruptionEvents.length,
  };
};

/* For some havoc data (grouped ~ e.g. by Gap)
  Convert raw counts (events & plays) into percentages (of totals, and of havoc totals)
 */
export const havocGroupDetail = (
  groupHavocData,
  havocPlaySummary, // totals values of havoc plays
  playSummary, // totals for team of all plays (not just havoc)
  isTotals = false
) => {
  if (isEmpty(groupHavocData)) {
    return {};
  }

  const {
    havocPlays: totalHavocPlays,
    passPlays: totalPassPlays,
    runPlays: totalRunPlays,
    sackPlays: totalSackPlays,
    pressurePlays: totalPressurePlays,
    tacklePlays: totalTacklePlays,
    runDisruptionPlays: totalRunDisruptionPlays,
    /** events  */
    havocEvents: totalHavocEvents,
    sackEvents: totalSackEvents,
    pressureEvents: totalPressureEvents,
    tackleEvents: totalTackleEvents,
    runDisruptionEvents: totalRunDisruptionEvents,
  } = havocPlaySummary;

  const {
    havocPlays,
    passPlays,
    runPlays,
    sackPlays,
    pressurePlays,
    tacklePlays,
    runDisruptionPlays,
    havocEvents,
    sackEvents,
    pressureEvents,
    tackleEvents,
    runDisruptionEvents,
  } = havocPlayTotals(groupHavocData);

  /* Plays: relative percentages (i.e. just of rows) */
  const havocPercentage = safeDivide(havocPlays, totalHavocPlays);
  const passPercentage = safeDivide(passPlays, totalPassPlays);
  const runPercentage = safeDivide(runPlays, totalRunPlays);
  const sackPercentage = safeDivide(sackPlays, totalSackPlays);
  const pressurePercentage = safeDivide(pressurePlays, totalPressurePlays);
  const tacklePercentage = safeDivide(tacklePlays, totalTacklePlays);
  const runDisruptionPercentage = safeDivide(
    runDisruptionPlays,
    totalRunDisruptionPlays
  );

  /* Plays: absolute percentages  */
  const playsPercentage = safeDivide(havocPlays, playSummary.playCount);
  const playsPassPercentage = safeDivide(passPlays, playSummary.passPlays);
  const playsRunPercentage = safeDivide(runPlays, playSummary.runPlays);

  /* Common Positions */
  const rowPositions = uniqBy(groupHavocData, 'positionCode');
  const positionFrequencies = rowPositions.map((row) => ({
    positionCode: row.positionCode,
    freq: groupHavocData.filter((f) => f.positionCode === row.positionCode)
      .length,
  }));
  const mostCommonAlignmentPosition = positionFrequencies?.length
    ? reverse(sortBy(positionFrequencies, 'freq'))[0].positionCode
    : '-';

  /* Averages */
  const xLoS = meanBy(groupHavocData, 'xLoS');
  const postSnapSeconds = meanBy(groupHavocData, 'postSnapSeconds');

  /* Events: relative percentages (i.e. just of rows) */
  const havocEventPercentage = safeDivide(havocEvents, totalHavocEvents);
  const sackEventPercentage = safeDivide(sackEvents, totalSackEvents);
  const pressureEventPercentage = safeDivide(
    pressureEvents,
    totalPressureEvents
  );
  const tackleEventPercentage = safeDivide(tackleEvents, totalTackleEvents);
  const runDisruptionEventPercentage = safeDivide(
    runDisruptionEvents,
    totalRunDisruptionEvents
  );

  return {
    havocEvents,
    /* plays counts */
    havocPlays,
    passPlays,
    runPlays,
    sackPlays,
    pressurePlays,
    tacklePlays,
    runDisruptionPlays,
    /* percentages of all plays */
    playsPercentage,
    playsPassPercentage,
    playsRunPercentage,
    /* plays per group as percentage of total (makes no sense for totals) */
    havocPercentage: isTotals ? null : havocPercentage,
    passPercentage: isTotals ? null : passPercentage,
    runPercentage: isTotals ? null : runPercentage,
    sackPercentage: isTotals ? null : sackPercentage,
    pressurePercentage: isTotals ? null : pressurePercentage,
    tacklePercentage: isTotals ? null : tacklePercentage,
    runDisruptionPercentage: isTotals ? null : runDisruptionPercentage,
    /* Other summary values */
    xLoS,
    postSnapSeconds,
    mostCommonAlignmentPosition,
    /* Event stuff */
    sackEvents,
    pressureEvents,
    tackleEvents,
    runDisruptionEvents,
    havocEventPercentage,
    sackEventPercentage,
    pressureEventPercentage,
    tackleEventPercentage,
    runDisruptionEventPercentage,
  };
};

/* Take a set of havoc events and group them (gap/zone/player etc.)
  Returns a row of date per group (for tables or summary chart)
  Format: HavocGroupDatumProp
*/
export const havocEventsToGroupedHavocPlays = ({
  havocData, // events ~ should only have those with relevant grouping attributes
  aggregationMode, // grouping mode to use
  playSummary, // team's total pass/run plays (inc. non-havoc plays)
}) => {
  /* Create rows based on the splits by aggregation mode */
  const aggregationSetup = HAVOC_AGGREGATION_DATA_TERMS[aggregationMode];
  const columnName = aggregationSetup.keyField;
  const uniqByGroupData = uniqBy(havocData, columnName);
  const havocPlaySummary = havocPlayTotals(havocData);

  const groupData = uniqByGroupData.map((havocGroup) => {
    const groupHavocData = havocData.filter(
      (f) => f[columnName] === havocGroup[columnName]
    );
    const rowValues = havocGroupDetail(
      groupHavocData,
      havocPlaySummary,
      playSummary
    );
    return {
      ...rowValues,
      groupBy: columnName,
      name: havocGroup[aggregationSetup.nameField],
      key: havocGroup[columnName],
    };
  });

  const totalsData = havocGroupDetail(
    havocData,
    havocPlaySummary,
    playSummary,
    true
  );
  const totalsDatum = {
    ...totalsData,
    groupBy: columnName,
    name: 'Team Overall',
  };
  return { groupData, totalsDatum };
};
