import { styled } from 'styled-components';
import ReactSlider from 'react-slider';
import { Modal } from 'semantic-ui-react';

export const StyledContextFilters = styled.div`
  position: fixed;
  top: 3.25rem;
  left: 3.875rem;
  width: calc(100% - 3.875rem);
  z-index: 99;
  display: flex;
  align-items: center;
  height: 2.875rem;
  /* 58 + 20 */
  box-shadow: ${({ theme }) => theme.effects.dropShadow.uber};
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
`;

export const FiltersLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0 1rem;
  flex-direction: row;
  display: flex;
  line-height: 2;
  color: ${({ theme }) => theme.colours.ink.primary.main};

  span {
    margin-left: 0.625rem;
  }

  svg {
    margin-top: 0.12rem;
  }
`;

export const FiltersContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 0.8125rem;

  .ui {
    &.button {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colours.brand.weak};
      line-height: 1;
      padding: 0 10px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colours.brand.main};
      position: relative;

      &.clear {
        border: 1px ${({ theme }) => theme.colours.brand.weak} solid;
        background-color: transparent;
        color: ${({ theme }) => theme.colours.brand.weak};
        font-weight: 600;

        &.right {
          margin-left: auto;
          margin-right: 1.25rem;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colours.layering[0].main};
        }
      }
    }

    &.label {
      font-size: 11px;
      border-radius: 50%;
      padding: 3px 6px 4px;
      background-color: ${({ theme }) => theme.colours.messaging.info.weak};
      color: white;
      line-height: 1;
      margin: 6px 0 0 -7px !important;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .content {
    padding: 1.25rem;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: ${({ theme }) =>
      theme.colours.canvas.tertiary.main} !important;

    .segment {
      background-color: ${({ theme }) =>
        theme.colours.canvas.tertiary.main} !important;
    }
  }

  .ui.attached.tabular.menu {
    .item {
      color: ${({ theme }) =>
        theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
    }

    .active.item {
      color: ${({ theme }) => theme.colours.ink.primary.main};
      background: transparent !important;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: 1px solid
          ${({ theme }) => theme.colours.canvas.tertiary.main};
        left: 0;
        bottom: 0;
      }
    }
  }
  .actions {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: ${({ theme }) =>
      theme.colours.canvas.tertiary.main} !important;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem !important;
    border-top: 0;
  }

  b {
    padding-left: 5px;
    color: ${({ theme }) => theme.colours.ink.primary.main} !important;

    &.grey {
      color: ${({ theme }) =>
        theme.applyOpacity(
          theme.colours.ink.primary.main,
          'disabled'
        )} !important;
    }
  }

  .toggle.checkbox {
    min-width: 80px;
    min-height: 1.5625rem;

    input:checked ~ label:after {
      top: 0;
      left: 1.25rem;
      border: 0;
      border-radius: 50%;
    }

    label {
      line-height: 1.2;
      font-size: 0.875rem;
      padding-left: 0.3125rem;
      height: 0.9375rem;

      &:before {
        height: 1.25rem;
        width: 2.5rem;
      }

      &:after {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  .shortcuts {
    display: flex;
    gap: 0.5rem;
  }

  .ui.toggle.checkbox input:checked ~ label:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: ${({ theme }) => theme.colours.interface.main} !important;
  }

  .ui.toggle.checkbox.grey label {
    color: ${({ theme }) => theme.colours.graphics.weak};
  }

  .ui.checkbox input.hidden + label {
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }

  .ui.button {
    font-size: 12px;
    color: white;
    line-height: 1;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colours.brand.weak};

    &.brand {
      background-color: ${({ theme }) => theme.colours.brand.weak};
      color: white;
      width: fit-content;

      &:hover {
        background-color: ${({ theme }) => theme.colours.brand.main};
      }
    }

    &.clear {
      background: none;
      border: 1px solid ${({ theme }) => theme.colours.brand.weak};
      color: ${({ theme }) => theme.colours.brand.weak};
      vertical-align: top;

      &:hover {
        background-color: ${({ theme }) => theme.colours.ink.inverse.strong};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colours.brand.main};
      color: white;
    }
  }

  .ui.form {
    padding: 16px;

    .field {
      position: relative;
    }

    input[type='range'] {
      width: 100%;
    }

    hr {
      margin: 25px 20px 25px;
      border-color: ${({ theme }) => theme.colours.layering[0].main};
    }
  }

  .track {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.5625rem;
    color: ${({ theme }) => theme.colours.ink.primary.main};

    &.labels {
      margin-top: -10px;
      padding-bottom: 10px;
    }

    &.extreme {
      position: absolute;
      top: 19px;
      width: 99%;
      font-size: 1.375rem !important;
      line-height: 1.4;
    }

    &.field,
    &.clock,
    &.olLine {
      div {
        width: 15px;
      }
    }

    &.yards {
      width: calc(100% - 25px);
      margin-left: 10px;

      // only has a right sided extreme
      &.right {
        margin-left: 0;

        div {
          text-align: center;
        }
      }

      div {
        width: 20px;
        text-align: right;
      }

      div.minus {
        text-align: center;
      }
    }

    &.timeToThrow {
      width: calc(100% - 10px);
    }

    &.clock {
      div:last-child {
        margin-right: 15px;
      }
    }

    &.clock + div {
      margin-top: 0.5rem;
    }

    &.distance {
      text-align: center;
      width: calc(100% - 20px);
      margin-bottom: 0.625rem;

      div {
        width: 20px;
      }
    }
  }

  .tab .ui.toggle.checkbox label {
    font-size: 0.875rem;
  }

  .ui {
    &.form {
      [class*='equal width'].fields > .field {
        width: auto;
        flex: 1 auto;
      }

      &.types {
        hr {
          margin: 15px 20px 25px;
          border-color: ${({ theme }) => theme.colours.layering[0].main};
        }

        .hidden {
          display: none;
        }

        .fields {
          &.boxed {
            padding: 12px 8px;
            margin-bottom: 0;

            &.feature {
              margin-bottom: 0.4375rem;
            }
          }
        }
      }
    }
  }

  .fade {
    .info {
      .position,
      h3 {
        opacity: 0.3;
      }
    }
  }

  .info {
    h3:first-child {
      text-align: center;
      margin: 0 0 0.4375rem;
    }

    h3 {
      text-align: center;
      margin: 0.4375rem 0 0.4375rem;
    }

    .tiles {
      display: flex;
      grid-gap: 10px;
      margin-bottom: 10px;
      align-items: center;
      justify-content: center;
    }

    .position {
      position: relative;
      background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
      border-top: 2px solid;
      padding: 5px 10px 7px;
      text-align: center;
      border-radius: 5px;
      width: 85px;
      height: 55px;
      box-shadow: 1px 1px 1px rgb(0 0 0 / 25%);

      .drop {
        box-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
      }

      svg {
        float: right;
        top: 7px;
        position: relative;
        left: -8px;
      }

      &.ol {
        border-color: var(--light-positionOL);
        width: 180px;

        svg {
          left: -37%;
        }
      }

      &.te {
        border-color: var(--light-positionTE);
        width: 85px;

        svg {
          left: -12px;
        }
      }

      &.wr {
        border-color: var(--light-positionWR);
      }

      &.rb {
        border-color: var(--light-positionRB);
      }

      &.qb {
        border-color: var(--light-positionQB);
      }

      &.dl {
        border-color: var(--light-positionDL);
      }

      &.lb {
        border-color: var(--light-positionLB);
      }

      &.db {
        border-color: var(--light-positionDB);
      }

      p {
        font-size: 1rem;
        text-indent: -3px;
        line-height: 1.6;
      }

      label {
        letter-spacing: 1px;
        font-size: 0.8125rem;
        color: ${({ theme }) => theme.colours.ink.primary.main};
      }

      &.ol {
        .drop {
          left: 0;
          border-radius: 0 0 5px 5px;

          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionOL-RGBA);
              border-color: var(--light-positionOL);
            }

            .thumb {
              background: var(--light-positionOL);
              border-color: var(--light-positionOL);
            }
          }
        }
      }

      &.te {
        .drop {
          left: -57%;
          border-radius: 5px;

          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionTE-RGBA);
              border-color: var(--light-positionTE);
            }

            .thumb {
              background: var(--light-positionTE);
              border-color: var(--light-positionTE);
            }
          }
        }
      }

      &.wr {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionWR-RGBA);
              border-color: var(--light-positionWR);
            }

            .thumb {
              background: var(--light-positionWR);
              border-color: var(--light-positionWR);
            }
          }
        }
      }

      &.rb {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionRB-RGBA);
              border-color: var(--light-positionRB);
            }

            .thumb {
              background: var(--light-positionRB);
              border-color: var(--light-positionRB);
            }
          }
        }
      }

      &.qb {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionQB-RGBA);
              border-color: var(--light-positionQB);
            }

            .thumb {
              background: var(--light-positionQB);
              border-color: var(--light-positionQB);
            }
          }
        }
      }

      &.dl {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionDL-RGBA);
              border-color: var(--light-positionDL);
            }

            .thumb {
              background: var(--light-positionDL);
              border-color: var(--light-positionDL);
            }
          }
        }
      }

      &.db {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionDB-RGBA);
              border-color: var(--light-positionDB);
            }

            .thumb {
              background: var(--light-positionDB);
              border-color: var(--light-positionDB);
            }
          }
        }
      }

      &.lb {
        .drop {
          .two-handle-slider {
            .track.track-1 {
              background: var(--light-positionLB-RGBA);
              border-color: var(--light-positionLB);
            }

            .thumb {
              background: var(--light-positionLB);
              border-color: var(--light-positionLB);
            }
          }
        }
      }

      .drop {
        position: absolute;
        top: 48px;
        left: -57%;
        z-index: 4;
        height: 50px;
        width: 180px;
        border-radius: 5px;
        padding: 10px 7px 0 7px;
        visibility: hidden;
        background-color: ${({ theme }) => theme.colours.canvas.secondary.main};

        .track {
          height: 6px;
          top: 5px;

          &.olLine {
            top: -7px;
            position: relative;
            font-size: 0.8125rem;
            width: 165px;
          }
        }

        .two-handle-slider {
          width: 165px;

          .thumb {
            width: 15px;
            height: 15px;
            border-width: 1px;
          }
        }
      }

      &:hover {
        border-radius: 5px 5px 0 0;
        opacity: 1;

        .drop {
          visibility: visible;
        }
      }
    }
  }

  &.personnel,
  &.formations {
    .ui {
      &.form {
        .field,
        .fields {
          display: flex;
          width: max-content;

          &.no-margin {
            margin: 0;
          }
        }

        hr {
          margin: 10px 20px 20px;
          border-color: ${({ theme }) => theme.colours.layering[0].main};
        }

        .defence {
          .field {
            padding-right: 20px;
          }
        }
      }
    }

    .half {
      width: 100%;
      display: inline-block;
    }

    @media (min-width: 1024px) {
      .half {
        width: 35%;
        display: inline-block;
      }

      .half + .half {
        width: 65%;
      }
    }

    .packages {
      display: flex;
      flex-direction: column;
      flex: auto;
      grid-gap: 12px;
      margin: 5px;
      padding-right: 16px;

      &.defence {
        flex-direction: row;
        grid-gap: 8px;
        margin: 5px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
      }
    }

    h3 {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }

    .packages + .packages {
      margin-left: auto;
    }
  }
`;

export const StyledSlider = styled(ReactSlider)`
  padding: 7px 0;
  width: 100%;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
  min-height: 27px;

  &.grey {
    .thumb {
      background: ${({ theme }) => theme.colours.graphics.weak};
    }

    .track.track-1 {
      background: ${({ theme }) => theme.colours.graphics.weak};
      border: 1px solid ${({ theme }) => theme.colours.graphics.weak};
    }
  }

  &.inverted {
    .track.track-0 {
      z-index: 2;
    }

    .track.track-1 {
      z-index: 1;
    }

    .track.track-2 {
      z-index: 0;
    }

    .thumb {
      z-index: 3 !important;
      box-shadow: -3px 4px 4px rgb(0 0 0 / 20%), 0 0 4px rgb(13 13 13 / 20%);
    }

    .thumb + .thumb {
      box-shadow: 4px 4px 4px rgb(0 0 0 / 20%), 0 0 4px rgb(13 13 13 / 20%);
    }
  }

  .thumb {
    box-shadow: 4px 4px 4px rgb(0 0 0 / 20%), 0 0 4px rgb(13 13 13 / 20%);
    background-color: ${({ theme }) => theme.colours.interface.main};
    border: ${({ theme }) => `2px solid ${theme.colours.canvas.tertiary.main}`};
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  .thumb + .thumb {
    box-shadow: -3px 4px 4px rgb(0 0 0 / 20%), 0 0 4px rgb(13 13 13 / 20%);
  }

  .track {
    position: relative;
    cursor: default;
    height: 8px;
    width: 100%;

    &.track-0 {
      background-color: ${({ theme }) => theme.colours.canvas.primary.main};
    }

    &.track-1 {
      border: 1px solid ${({ theme }) => theme.colours.interface.main};
      background-color: ${({ theme }) => theme.colours.interface.main};
    }

    &.track-2 {
      background-color: ${({ theme }) => theme.colours.canvas.primary.main};
    }
  }
`;

export const TriggerButtonContainer = styled.div`
  position: relative;
`;

export const ClearFilters = styled.div`
  margin-left: auto;
  margin-right: 0.625rem;
`;

export const SelectAllButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  width: 18.75rem;
`;

StyledContextFilters.displayName = 'StyledContextFilters';
FiltersLabel.displayName = 'FiltersLabel';
FiltersContainer.displayName = 'FiltersContainer';
StyledModal.displayName = 'StyledModal';
StyledSlider.displayName = 'StyledSlider';
ClearFilters.displayName = 'ClearFilters';
TriggerButtonContainer.displayName = 'TriggerButtonContainer';
SelectAllButtonContainer.displayName = 'SelectAllButtonContainer';
