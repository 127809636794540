import { gql } from '@apollo/client';

export const GET_DRIVE_SNAPS = gql`
  query GetDriveSnaps(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $driveIds: [ID!]!
  ) {
    selectedTeamId @client @export(as: "teamId")
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    pageFilterDrive @client @export(as: "driveIds")

    plays(
      first: 500
      filters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: [$teamId]
        driveIds: $driveIds
      }
    ) {
      totalCount
      edges {
        node {
          id
          name
          yardLine
          down
          quarter
          quarterClockRemaining
          success
          shotgun
          driveIndex
          yardsToGo
          yardsNet
          type
          touchdownWon
          drive {
            gameIndex
            startingYards
            yardsNet
          }
          offenseTeam {
            id
            name
          }
          defenseTeam {
            id
            name
          }
        }
      }
    }
  }
`;
