import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { ButtonIcon, Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { mf_Leagues, mf_TeamDetails } from '../../../apollo';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import Tile from '../../../components/Tile/Tile';
import TackleLocation from '../../../visualisations/TackleLocation/TackleLocation';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  TACKLE_POSITION_ANY,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_PLAY_TYPE_ANY,
} from '../../../visualisations/TackleLocation/TackleLocation.constants';
import {
  getTacklingLeagueAverages,
  useGetPlayerStats,
  useGetTacklingPlays,
} from '../TeamTackling/TeamTackleAttempts.hooks';
import {
  addTackleDerivedValues,
  getPlayerTacklePlays,
} from '../../../visualisations/TackleLocation/TackleLocation.dataManipulation';
import TackleLocationDistribution from '../../../visualisations/TackleLocation/TackleLocation.Distribution';
import {
  TacklingDistributionTeamTile,
  TacklingVizTeamTile,
} from './TeamTackling.Tile.styles';
import { TileInlineLoader } from './TeamOverview.styles';
import { ROTATIONS } from '../../../utils/constants/charting';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { TACKLING_TILE_OPTIONS } from './TeamTackling.Tile.constants';

const TeamTacklingTile = function ({ offense }) {
  const team = useReactiveVar(mf_TeamDetails);
  const teamId = team?.id;
  const competitionId = useReactiveVar(mf_Leagues);
  const [showDistribution, setShowDistribution] = useQueryString(
    'distribution',
    false
  );
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'tacklingKey',
    true
  );
  // data
  const {
    loading: tacklingLoading,
    error: tacklingError,
    tacklingData,
  } = useGetTacklingPlays(!offense, TACKLING_PLAY_TYPE_ANY.value);
  const tacklesAndAttempts = tacklingData?.length
    ? addTackleDerivedValues(tacklingData)
    : [];
  const {
    loading: playerStatsLoading,
    error: playerStatsError,
    playerStatsData,
  } = useGetPlayerStats(teamId, TACKLING_PLAY_TYPE_ANY, !offense);

  const hasError = !!tacklingError || !!playerStatsError;
  const isLoading = tacklingLoading || playerStatsLoading;
  const tackleAttemptLeagueAverage = getTacklingLeagueAverages(
    competitionId,
    TACKLING_PLAY_TYPE_ANY.value
  );
  const filteredData = getPlayerTacklePlays(
    tacklesAndAttempts,
    !offense,
    null /* playerid */,
    TACKLING_PLAY_TYPE_ANY,
    TACKLE_POSITION_ANY.value
  );
  const loadingMsg = () => {
    if (tacklingLoading) {
      return 'Loading Team Tackling Data';
    }

    if (playerStatsLoading) {
      return 'Loading Team Player Stats Data';
    }

    return '';
  };
  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header style={{ maxHeight: '5.64rem' }} $border="2px">
        <h3>Tackle Map</h3>
        <div className="buttons">
          <DropdownWrapper>
            <Dropdown
              id="team-tackling-tile-dropdown"
              components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon,
              }}
              isSearchable={false}
              menuAlignment="right"
              menuPlacement="bottom"
              onChange={(selectedOption) =>
                setShowDistribution(selectedOption.value)
              }
              options={TACKLING_TILE_OPTIONS}
              value={TACKLING_TILE_OPTIONS.find(
                (item) => item.value === showDistribution
              )}
              labelPosition="none"
              label="Passing chart display options"
              variant="tertiary"
              size="small"
            />
          </DropdownWrapper>
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            // eslint-disable-next-line max-len
            href={`/team/tackles/:leagues/:seasons/:teams/?fieldFocusOrigin=false&playType=ANY&showFirstTackleTails=true&defensive=${!offense}&rotation=${
              offense ? 'vertical-up' : 'vertical-down'
            }`}
            linkName="Open Team Tackling Map"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="0.25rem" $minHeight="478px">
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        {isLoading && <TileDimmer />}
        <div>
          <div
            style={{
              display: showDistribution ? 'none' : 'block',
            }}
          >
            <TacklingVizTeamTile>
              <TackleLocation
                data={filteredData}
                dataLA={null}
                scaleR={false}
                orientation={
                  offense ? ROTATIONS.VERTICAL_UP : ROTATIONS.VERTICAL_DOWN
                }
                displayKey={displayChartKey}
                ballCarriers={playerStatsData}
                tacklers={playerStatsData}
                fieldMode={TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value}
                fieldFocusOrigin={false}
                showFirstTackleTails
                showDistribution={false}
                isInteractive={false}
              />
            </TacklingVizTeamTile>
          </div>
          <div style={{ display: showDistribution ? 'block' : 'none' }}>
            <TacklingDistributionTeamTile>
              <TackleLocationDistribution
                data={filteredData}
                dataLA={tackleAttemptLeagueAverage}
                scaleR={false}
                displayKey={displayChartKey}
                orientation={
                  offense ? ROTATIONS.VERTICAL_UP : ROTATIONS.VERTICAL_DOWN
                }
                ballCarriers={playerStatsData}
                tacklers={playerStatsData}
                fieldMode={TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value}
                fieldFocusOrigin={false}
                showFirstTackleTails
              />
            </TacklingDistributionTeamTile>
          </div>
        </div>
        {isLoading && (
          <TileInlineLoader>
            <Loader active inline size="small" content={loadingMsg()} />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

TeamTacklingTile.propTypes = {
  offense: PropTypes.bool,
};

TeamTacklingTile.defaultProps = {
  offense: true,
};

export default TeamTacklingTile;
