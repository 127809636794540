import { API_ALIGNMENT_POSITION_KEYS } from '../../utils/constants/api';
import { ALIGNMENT_POSITIONS } from '../../utils/constants/positions';
import { LOGO_DEFAULT_HEIGHT } from '../../utils/helpers/export';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';

const OLPRESSURE_CLASS_NAMES = {
  WITHIN_MARGINS: 'olp-within-margins',
  HEADERS: 'olp-headers',
  HEADER_SHAPES: 'olp-hexes',
  MAIN_VIS: 'olp-main',
  GAPS_AXES: 'olp-gaps-axes',
  GAPS_BASE: 'olp-gaps-base',
  SPARK_CHART: 'olp-spark-chart',
  BACKGROUND_RECT: 'background',
  EXPORT_HEADER: 'olp-export-header',
  EXPORT_FOOTER: 'olp-export-footer',
  CONTENT: 'olp-content',
};

const OLPRESSURE_WIDTHS = {
  GAP_BLOCK_AXIS: 20,
  GAP_AXIS_TEXT_WIDTH: 30,
  BAR_SECTION: 100,
  GAP_BLOCK: 60,
  POSITION_SEGMENT: 172,
  POSITION_SHAPE_R: 50,
  POSITION_SHAPE_R_INNER: 20 / 0.85, // because player shape reduces squares for consistency
  GAP_SHAPE_R_INNER: 20, // a bit smaller than OL shape
  GAP_SHAPE_TEXT_Y: 6, // displacement for gap letters
  GAP_BLOCK_MAIN: 1100, // 6 * Gaps + 5 * Positions
  GAP_AXIS_WIDTH: 1140, // 2 * GAP_BLOCK_AXIS + GAP_BLOCK_MAIN
};

const OLPRESSURE_HEIGHTS = {
  GAP_BLOCK_MAX: 160,
  OL_BELOW_ARROWS: 30,
  MAIN_SECTION_TITLE: VISUALISATION_FONT_SETUPS.AREA_TITLE.LINE_HEIGHT + 8, // add a small line break before the axis
  MAIN_VIS: 200,
  MAIN_VIS_MARGIN_BELOW: 50,
  SPARK_CHART: 50,
  SPARK_CHART_MARGIN_BELOW: 0,
  EXPORT_HEADER: 100,
  EXPORT_FOOTER_PADDING: 10,
  EXPORT_FOOTER: 10 + LOGO_DEFAULT_HEIGHT,
  KEY_BARS: 170,
  KEY_COLORS: 130,
  KEY_SPARKLINE: 70,
  KEY_VERTICAL_PADDING: 15,
};

/* 
Future extension will add Tight Ends, and have to handle multiple TE 
Will also add D Gaps, and need to examine open/closed C gaps separately
*/
const OLP_VIS_GAPS = [
  {
    gap: 'Left A Gap',
    label: 'A',
    index: 4,
    key: 'leftAPressPerc',
    playsKey: 'leftAPressurePlays',
  },
  {
    gap: 'Left B Gap',
    label: 'B',
    index: 2,
    key: 'leftBPressPerc',
    playsKey: 'leftBPressurePlays',
  },
  {
    gap: 'Left C Gap',
    label: 'C',
    index: 0,
    key: 'leftCPressPerc',
    playsKey: 'leftCPressurePlays',
  },
  {
    gap: 'Right A Gap',
    label: 'A',
    index: 6,
    key: 'rightAPressPerc',
    playsKey: 'rightAPressurePlays',
  },
  {
    gap: 'Right B Gap',
    label: 'B',
    index: 8,
    key: 'rightBPressPerc',
    playsKey: 'rightBPressurePlays',
  },
  {
    gap: 'Right C Gap',
    label: 'C',
    index: 10,
    key: 'rightCPressPerc',
    playsKey: 'rightCPressurePlays',
  },
];
const OLP_VIS_POS = [
  {
    position: 'Left Tackle',
    posCode: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE].code,
    label: 'LT',
    index: 1,
    key: 'lt_pressures',
    playsKey: 'leftTacklePressurePlays',
  },
  {
    position: 'Left Guard',
    posCode: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD].code,
    label: 'LG',
    index: 3,
    key: 'lg_pressures',
    playsKey: 'leftGuardPressurePlays',
  },
  {
    position: 'Center',
    posCode: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.CENTER].code,
    label: 'C',
    index: 5,
    key: 'c_pressures',
    playsKey: 'centerPressurePlays',
  },
  {
    position: 'Right Guard',
    posCode: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD].code,
    label: 'RG',
    index: 7,
    key: 'rg_pressures',
    playsKey: 'rightGuardPressurePlays',
  },
  {
    position: 'Right Tackle',
    posCode: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE].code,
    label: 'RT',
    index: 9,
    key: 'rt_pressures',
    playsKey: 'rightTacklePressurePlays',
  },
];

/* 
The League Averages are currently static as there is no API end point for them, and they should be fairly invariable
TODO: review periodically, and potentially make a query result later
*/
const OLPRESSURE_POSITION_LIMITS = [
  /* NFL */
  {
    position: 'LT',
    comp: 1409,
    mean: 0.05105296745373325,
  },
  {
    position: 'LG',
    comp: 1409,
    mean: 0.04476251253532683,
  },
  {
    position: 'C',
    comp: 1409,
    mean: 0.03737806545719756,
  },
  {
    position: 'RG',
    comp: 1409,
    mean: 0.04047770990974565,
  },
  {
    position: 'RT',
    comp: 1409,
    mean: 0.042027532136019695,
  },
  {
    position: 'TE',
    comp: 1409,
    mean: 0.020330020968183062,
  },
  /* NCAA */
  {
    position: 'LT',
    comp: 1446,
    mean: 0.03633695839806663,
  },
  {
    position: 'LG',
    comp: 1446,
    mean: 0.0280510961505265,
  },
  {
    position: 'C',
    comp: 1446,
    mean: 0.027187985499741066,
  },
  {
    position: 'RG',
    comp: 1446,
    mean: 0.030467805972725703,
  },
  {
    position: 'RT',
    comp: 1446,
    mean: 0.038408423959951664,
  },
  {
    position: 'TE',
    comp: 1446,
    mean: 0.012687726566545831,
  },
];

const OLPRESSURE_GAP_LIMITS = [
  /* NFL */
  { gap: 'Left D Gap', comp: 1409, mean: 0.014951226182879022 },
  { gap: 'Left C Gap', comp: 1409, mean: 0.049411979214148964 },
  { gap: 'Left B Gap', comp: 1409, mean: 0.02297383535417996 },
  { gap: 'Left A Gap', comp: 1409, mean: 0.019965356914942108 },
  { gap: 'Right A Gap', comp: 1409, mean: 0.01914486279514997 },
  { gap: 'Right B Gap', comp: 1409, mean: 0.021059349074664963 },
  { gap: 'Right C Gap', comp: 1409, mean: 0.039748381803263746 },
  { gap: 'Right D Gap', comp: 1409, mean: 0.018415534688668064 },
  /* NCAA */
  { gap: 'Left D Gap', comp: 1446, mean: 0.010012083549110996 },
  { gap: 'Left C Gap', comp: 1446, mean: 0.03659589159330226 },
  { gap: 'Left B Gap', comp: 1446, mean: 0.017607457276022784 },
  { gap: 'Left A Gap', comp: 1446, mean: 0.020024167098221992 },
  { gap: 'Right A Gap', comp: 1446, mean: 0.020973588814085967 },
  { gap: 'Right B Gap', comp: 1446, mean: 0.02166407733471431 },
  { gap: 'Right C Gap', comp: 1446, mean: 0.0410840669773865 },
  { gap: 'Right D Gap', comp: 1446, mean: 0.012342482306231658 },
];

/*
  SCALING for the charts is correlated strongly to the league averages
  distribution across all positions/gaps for all teams maxes at about 10 bar a few outliers (< 1% of results)
    in fact vast majority of results are under 5
  Using the personnel filters changes the values quite a bit though, and whilst %>10 is not super uncommon,
    want a scale that gives distinction in team mode as well as info once filtering
*/
const OLP_BASELINE_OFFSET = 4; // i.e. the baseline for each slot bar = league average - 4%
/* RAW PRESSURES (large) */
const OLPRESSURE_GAP_PRESSURE_SCALE = {
  MIN: 0,
  MAX: 15,
  axes: [0, 3, 6, 9, 12, 15],
};
const OLPRESSURE_GAP_SPARKLINE_SCALE = {
  MIN: 0,
  MAX: 10,
  axes: [0, 3, 6, 9],
};

const OLPRESSURE_OFFSET_PRESSURE_SCALE = {
  MIN: 0,
  MAX: 10,
  axes: [0, 2, 4, 6, 8, 10],
};
const OLPRESSURE_RELATIVE_SCALE = {
  MIN: 0,
  MAX: 2,
  axes: [0, 0.5, 1, 1.5, 2],
};

const olPressureChartElementId = (olpId) => `amf-ol-pressures-${olpId}`;

export {
  OLPRESSURE_CLASS_NAMES,
  OLPRESSURE_GAP_PRESSURE_SCALE,
  OLPRESSURE_OFFSET_PRESSURE_SCALE,
  OLPRESSURE_WIDTHS,
  OLPRESSURE_HEIGHTS,
  OLPRESSURE_POSITION_LIMITS,
  OLPRESSURE_GAP_LIMITS,
  OLP_VIS_GAPS,
  OLP_VIS_POS,
  OLPRESSURE_RELATIVE_SCALE,
  OLP_BASELINE_OFFSET,
  OLPRESSURE_GAP_SPARKLINE_SCALE,
  olPressureChartElementId,
};
