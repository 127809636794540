import React from 'react';
import PropTypes from 'prop-types';
import { TabButtonLink } from './TabButton.styles';

/*
 * a 'tab effect' button/link
 */
const TabButton = ({ href, active, handleClick, text }) => (
  <TabButtonLink
    href={href}
    onClick={handleClick}
    className={active ? 'active' : ''}
  >
    {text}
  </TabButtonLink>
);

TabButton.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  active: PropTypes.bool,
  handleClick: PropTypes.func,
};

TabButton.defaultProps = {
  text: null,
  // eslint-disable-next-line no-script-url
  href: 'javascript:void(0)',
  active: false,
  handleClick: () => {},
};

export default TabButton;
