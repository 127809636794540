import { sortBy } from 'lodash';
import {
  API_ALIGNMENT_POSITION_KEYS,
  API_STAT_UNITS,
} from '../../../utils/constants/api';
import { ROUTE_TYPE_CONFIG_CLASSIFIED } from '../../../visualisations/RouteTree/RouteTree.constants';
import {
  csDivergent,
  csIntensity,
  csValue,
} from '../../../utils/helpers/colorScales';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';

export const ROUTE_TYPE_API_NAMES = Object.keys(ROUTE_TYPE_CONFIG_CLASSIFIED);

/* 
Metrics to get name/description and units from stat definitions 
Any metric with a domain can be selected as an axis for the route tree vis
*/
const ROUTE_TREE_PLAYER_STATS = {
  routesRun: {
    key: 'routesRun',
    domain: null,
    columnOrder: 1,
  },
  targets: { key: 'targets', domain: null, columnOrder: 2 },
  targetsRate: {
    key: 'targetsRate',
    domain: [0, 0.3],
    columnOrder: 5,
    colorFunction: csIntensity,
  },
  averageSeparationAtPass: {
    key: 'averageSeparationAtPass',
    domain: [2, 5],
    colorFunction: csValue,
    columnOrder: 7,
  },
  averageSeparationAtTarget: {
    key: 'averageSeparationAtTarget',
    domain: [0, 3],
    colorFunction: csValue,
    columnOrder: 8,
  },
  averageSeparationAtCatch: {
    key: 'averageSeparationAtCatch',
    domain: [0, 3],
    colorFunction: csValue,
    columnOrder: 9,
  },
  averageDepthOfTarget: {
    key: 'averageDepthOfTarget',
    domain: [0, 10],
    colorFunction: csValue,
    columnOrder: 10,
  },
  recEPAPerTarget: {
    key: 'recEPAPerTarget',
    domain: [0, 0.1],
    colorFunction: csValue,
    columnOrder: 11,
  },
  epaPerPlay: {
    key: 'epaPerPlay',
    domain: [0, 0.2],
    colorFunction: csValue,
    columnOrder: 12,
  },
  receivingYards: {
    key: 'receivingYards',
    domain: [0, 10],
    colorFunction: csValue,
    columnOrder: 13,
  },
  receivingYardsPerCompletion: {
    key: 'receivingYardsPerCompletion',
    domain: [0, 10],
    colorFunction: csValue,
    columnOrder: 14,
  },
  receivingYardsPerTarget: {
    key: 'receivingYardsPerTarget',
    domain: [0, 10],
    colorFunction: csValue,
    columnOrder: 15,
  },
  receptionsRate: {
    key: 'receptionsRate',
    domain: [0, 0.1],
    colorFunction: csValue,
    columnOrder: 16,
  },
  croe: {
    key: 'croe',
    domain: [-0.5, 0.5],
    colorFunction: csDivergent,
    columnOrder: 17,
  },
  receivingExplosiveRate: {
    key: 'receivingExplosiveRate',
    domain: [0, 0.1],
    colorFunction: csValue,
    columnOrder: 18,
  },
  playSuccessRate: {
    key: 'playSuccessRate',
    domain: [0, 1],
    colorFunction: csValue,
    columnOrder: 19,
  },
};

/* 
Metrics custom to this page / not in stats defs
  and thus not requested in the player stats API
*/
export const ROUTE_TREE_CUSTOM_STATS = {
  routeType: {
    key: 'routeType',
    domain: null,
    columnOrder: 0,
    nonAxis: true,
    abbrev: 'Route Type',
    prettyName: 'Route Type',
    description: 'Name of the type of route run',
    units: API_STAT_UNITS.STRING,
  },
  routePercentage: {
    key: 'routePercentage',
    domain: [0, 0.2],
    colorFunction: csIntensity,
    columnOrder: 3,
    abbrev: 'RT Route %',
    prettyName: 'Route Type Route Percentage',
    description: 'Percentage of total Routes Run that were in this Route Type',
    units: API_STAT_UNITS.PERCENTAGE,
  },
  targetPercentage: {
    key: 'targetPercentage',
    domain: [0, 0.1],
    colorFunction: csIntensity,
    columnOrder: 4,
    abbrev: 'RT Target %',
    prettyName: 'Route Type Target Percentage',
    description: 'Percentage of total Targets that were in this Route Type',
    units: API_STAT_UNITS.PERCENTAGE,
  },
  targetRouteRatio: {
    key: 'targetRouteRatio',
    domain: [-1, 1],
    colorFunction: csDivergent,
    columnOrder: 6,
    abbrev: 'Target:Route',
    prettyName: 'Target to Route Ratio',
    description:
      'How much is the player getting targeted relative to how often they run this route type',
    units: API_STAT_UNITS.SHORT_DECIMAL,
  },
};
/* Used in the player stats query */
export const ROUTE_TREE_PLAYER_STAT_KEYS = Object.keys(ROUTE_TREE_PLAYER_STATS);

export const ROUTE_TREE_METRICS = sortBy(
  Object.values(ROUTE_TREE_PLAYER_STATS).concat(
    Object.values(ROUTE_TREE_CUSTOM_STATS)
  ),
  'columnOrder'
);

export const ROUTE_TREE_FILTER_DEFAULTS = {
  BRANCH: ROUTE_TREE_CUSTOM_STATS.routePercentage.key,
  FRUIT: ROUTE_TREE_CUSTOM_STATS.targetPercentage.key,
};

export const ROUTE_TREE_ANY_ALIGNMENT = 'ANY';
export const ROUTE_TREE_ANY_ALIGNMENT_CODES = [
  API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER,
  API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER,
  API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
];
export const ROUTE_TREE_ROLE_OPTIONS = [
  {
    value: ROUTE_TREE_ANY_ALIGNMENT,
    label: 'All',
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER].name,
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER].name,
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.TIGHT_END].name,
  },
];
