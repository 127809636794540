export const SVG_CLASS_NAMES = {
  IN_MARGINS: 'within-margins',
  IN_AXES_PADDING: 'within-axes-padding',
  DATA_LAYER: 'data-layer',
  FIELD: 'field',
  CLICKABLE_FIELD: 'clickable-field',
  ONFIELD: 'onfield',
  UNDERFIELD: 'underfield',
  PLAYER_DOTS: 'player-dots',
  BACKGROUND_RECT: 'backing-rect',
};
export const COLORING_OPTION_PASS_SUCCESS = {
  label: 'Complete and Incomplete Passes',
  value: 'success',
};
export const COLORING_OPTION_UNDERTHOW_OVERTHROW = {
  label: 'Underthrow and Overthrow',
  value: 'under_over',
};
export const COLORING_OPTION_PASS_VELOCITY = {
  label: 'Pass Velocity',
  value: 'velocity',
};
export const COLORING_OPTION_NONE = {
  label: 'None',
  value: 'plain',
};
export const COLORING_OPTION_PLAY_SUCCESS = {
  label: 'Play Success',
  value: 'play_success',
};
export const COLORING_OPTION_MONOCHROME = 'monochrome';

export const COLORING_OPTIONS = [
  COLORING_OPTION_PASS_SUCCESS,
  COLORING_OPTION_UNDERTHOW_OVERTHROW,
  COLORING_OPTION_PASS_VELOCITY,
  COLORING_OPTION_NONE,
  COLORING_OPTION_PLAY_SUCCESS,
];

export const COLORING_OPTION_BY_PLAYER = {
  label: 'Passer',
  value: 'passer',
};
export const COLORING_OPTION_BY_TARGET = {
  label: 'Target',
  value: 'target',
};
export const COLORING_OPTIONS_TEAM = COLORING_OPTIONS.concat([
  COLORING_OPTION_BY_PLAYER,
  COLORING_OPTION_BY_TARGET,
]);

export const INFO_DISPLAY_VIS = {
  label: 'Display with Visualisation',
  value: 'vis',
};
export const INFO_DISPLAY_TABLE = {
  label: 'Display In Tables',
  value: 'tables',
};
export const INFO_DISPLAY_NONE = {
  label: 'Hide',
  value: 'hide',
};

export const INFO_DISPLAY_OPTIONS = [
  INFO_DISPLAY_VIS,
  INFO_DISPLAY_TABLE,
  INFO_DISPLAY_NONE,
];

export const GRAPH_OPTION_HEATMAP = 'heatmap';
export const GRAPH_OPTION_PASS_PATHS = 'pass_paths';
export const GRAPH_OPTION_END_LOCATIONS = 'default';

export const GRAPH_OPTIONS = [
  {
    label: 'End Locations',
    value: GRAPH_OPTION_END_LOCATIONS,
    variant: 'Position',
  },
  {
    label: 'Heatmap',
    value: GRAPH_OPTION_HEATMAP,
    variant: 'HeatmapFlame',
  },
  {
    label: 'Pass Paths',
    value: GRAPH_OPTION_PASS_PATHS,
    variant: 'PassPath',
  },
];

export const AXES_PADDING_SIZE = 25; // px is big enough for "LoS" or any suitable number in axes font/size
