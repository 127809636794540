import { orderBy } from 'lodash';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { formatPackageLabel } from '../../../utils/helpers/strings';
import {
  ALL_PACKAGES_OPTION,
  API_DEFENSIVE_POSITION_KEYS,
  ALL_POSITIONS_MAP,
  CSV_PLAYER_HEADERS,
  CSV_TEAM_HEADERS,
} from './TeamDepthChart.constants';

// converts the packages returned by the api to dropdown options
export const getPackageOptions = (personnel) => {
  const orderedPackages = orderBy(
    personnel,
    'snapCount',
    SORT_DIRECTIONS.DESCENDING
  );

  // "POTHER" packages are just bad data which is why we filter them out
  const formattedPackageOptions =
    orderedPackages
      ?.filter((p) => p.package !== 'POTHER')
      .map((p) => ({
        value: p.package,
        label: `${formatPackageLabel(p.package)} [${p.snapCount}]`,
      })) || [];

  return [ALL_PACKAGES_OPTION, ...formattedPackageOptions];
};

// ['position', 'name', 'snapCount', 'snapRate', 'usageRate'],
// ['Wide Receiver', 'Tanjiro', '52', '22.6', '11.2'],
// ['Long Snapper', 'Nezuko', '52', null, null],
export const getPlayerCsvData = (tables) => {
  if (!tables) {
    return [];
  }

  const tableData = tables.flatMap((table) => {
    const isUsagePosition = ALL_POSITIONS_MAP[table.position].displayUsage;
    const isLateDownsPosition = API_DEFENSIVE_POSITION_KEYS.includes(
      table.position
    );

    const formattedData = table.data.map((player) => {
      const usageRate = isUsagePosition ? player.usageRate : null;
      const lateDownsRate = isLateDownsPosition ? player.lateDownsRate : null;

      return [
        ALL_POSITIONS_MAP[table.position].name,
        player.name,
        player.snapCount,
        player.snapRate,
        usageRate,
        lateDownsRate,
      ];
    });

    return formattedData;
  });

  return [CSV_PLAYER_HEADERS, ...tableData];
};

// formats and sorts the personnel distribution team data
export const formatTeamData = (data) => {
  if (!data) {
    return [];
  }

  // "POTHER" packages are just bad data which is why we filter them out
  const filteredPackages = data.filter((p) => p.package !== 'POTHER');
  const formattedTeamData = filteredPackages.map((p) => ({
    ...p,
    label: formatPackageLabel(p.package),
  }));

  // sort packages by snaps in descending order
  const sortedTeamData = formattedTeamData.sort((a, b) => {
    // The "other" package is a special case - we always want it to be at the end of the list
    if (!a.package) {
      return 1;
    }
    if (!b.package) {
      return -1;
    }
    return b.snapRate - a.snapRate;
  });

  return sortedTeamData;
};

// ['package', 'snapRate', 'leagueAverage'],
// ['11', '20.1', '22.6'],
// ['21', '18.3', 20.2],
export const getTeamCsvData = (data) => [
  CSV_TEAM_HEADERS,
  ...data.map((p) => [
    p.label,
    (p.snapRate * 100).toFixed(1),
    (p.competitionRate * 100).toFixed(1),
  ]),
];
