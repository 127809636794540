import { reverse, sortBy, sumBy, uniqBy } from 'lodash';

export const getPlayerList = function (teamData, positionKey) {
  const positionPlayers = teamData.map((playDatum) => playDatum[positionKey]);
  const playerData = uniqBy(positionPlayers, 'id');
  const playersList = playerData.map((p) => {
    const playerId = p.id;
    const ltSnaps = sumBy(
      teamData.filter((t) => t[positionKey].id === playerId),
      'plays'
    );
    const playerName = `${p.name} (${ltSnaps} plays)`;
    return {
      label: playerName,
      value: playerId,
      snapCount: ltSnaps,
      player: p.name,
    };
  });

  const sortedList = reverse(sortBy(playersList, 'snapCount', 'label'));
  const anyOptionList = [{ label: 'Any', value: 0 }];
  return anyOptionList.concat(sortedList);
};
