import PropTypes from 'prop-types';
import { CHART_SCALE_TYPE } from '../BasicChart/BasicChart.constants';

export const scatterDatumPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  yValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rValue: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  title: PropTypes.string,
});

const scatterDomainPropTypes = PropTypes.oneOf(Object.values(CHART_SCALE_TYPE));

export const scatterSetupPropType = PropTypes.shape({
  X: PropTypes.shape({
    TYPE: scatterDomainPropTypes,
    DOMAIN: PropTypes.arrayOf(PropTypes.number),
    PADDING: PropTypes.number,
    NICE: PropTypes.bool,
    TICKS: PropTypes.number,
  }),
  Y: PropTypes.shape({
    TYPE: scatterDomainPropTypes,
    DOMAIN: PropTypes.arrayOf(PropTypes.number),
    PADDING: PropTypes.number,
    NICE: PropTypes.bool,
    TICKS: PropTypes.number,
  }),
  R: PropTypes.shape({
    TYPE: scatterDomainPropTypes,
    DOMAIN: PropTypes.arrayOf(PropTypes.number),
    RANGE: PropTypes.arrayOf(PropTypes.number),
    NICE: PropTypes.bool,
    FIXED_RADIUS: PropTypes.number,
  }),
});

export const scatterAverageLine = PropTypes.shape({
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
});
