import { styled } from 'styled-components';
import { COMPARISON_TILE_WIDTH } from './PlayerComparison.constants';

// taken from RC lib
const getHighlightStyles = (theme) => `
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.applyOpacity(
    theme.colours.interface.main,
    'highlight'
  )};
`;

export const PlayerComparisonLabel = styled.h3`
  ${({ theme }) => theme.typography.headingMedium};
  padding-bottom: 0.25rem;
`;
PlayerComparisonLabel.displayName = 'PlayerComparisonLabel';

// the main container for the comparison tiles
export const PlayerComparisonColumn = styled.div`
  display: flex;
  min-width: ${`${COMPARISON_TILE_WIDTH}px`};
  flex-direction: column;
  padding: 0;
  content-visibility: auto;
  margin: ${({ $margin }) => $margin || '0'};

  h1 {
    ${({ theme }) => theme.typography.headingMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
    margin: 0 0 0.25rem 0;
  }

  h3 {
    ${({ theme }) => theme.typography.bodyMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
    opacity: 0.8;
  }
`;
PlayerComparisonColumn.displayName = 'PlayerComparisonColumn';

export const PlayerComparisonTableHeader = styled.div`
  display: flex;
  ${({ theme }) => theme.typography.headingMedium}
  margin: 1rem 0 0 0;
  height: 2rem;
  width: 180px;
`;
PlayerComparisonTableHeader.displayName = 'PlayerComparisonTableHeader';

export const PlayerComparisonTitleColumn = styled.div`
  display: flex;
  gap: 0;
  padding: 0;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  backface-visibility: hidden;
  min-width: 180px;
  width: 180px;

  td {
    ${({ theme }) => theme.typography.headingMedium};
  }
`;
PlayerComparisonTitleColumn.displayName = 'PlayerComparisonTitleColumn';

export const PlayerComparisonTopHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  height: 136px;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  text-align: right;
  width: ${`${COMPARISON_TILE_WIDTH}px`};
  opacity: ${({ $opacity }) => $opacity || 0};

  a {
    color: ${({ theme }) => theme.colours.ink.primary.main};
    text-decoration: underline;
  }

  a:hover {
    color: ${({ theme }) => theme.colours.ink.highlight.main};
  }

  h1 {
    ${({ theme }) => theme.typography.headingMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
    margin: 0 0 0.25rem 0;
    height: 1.25rem;
    display: block;
  }

  h3 {
    ${({ theme }) => theme.typography.bodyMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
    opacity: 0.8;
  }
`;
PlayerComparisonTopHeader.displayName = 'PlayerComparisonTopHeader';

export const PlayerComparisonNameBlock = styled.div`
  padding: ${({ $padding }) => $padding || '0.5rem'};
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  border-right: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  position: relative;
  height: 70px;
  z-index: 99;
  display: ${({ $display }) => $display || 'block'};

  button {
    position: absolute;
    right: 8px;
    top: 24px;
  }
`;
PlayerComparisonNameBlock.displayName = 'PlayerComparisonNameBlock';

export const PlayerComparisonSearchHolder = styled.div`
  border-top: ${({ theme, $error }) =>
    `1px solid ${
      $error
        ? theme.colours.messaging.error.main
        : theme.colours.canvas.secondary.main
    }`};
  border-right: ${({ theme, $error }) =>
    `1px solid ${
      $error
        ? theme.colours.messaging.error.main
        : theme.colours.canvas.secondary.main
    }`};
  border-bottom: ${({ theme, $error }) =>
    `1px solid ${$error ? theme.colours.messaging.error.main : 'transparent'}`};
  border-left: ${({ theme, $error }) =>
    `1px solid ${$error ? theme.colours.messaging.error.main : 'transparent'}`};
  position: relative;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  padding-right: 1.25rem;
  text-align: left;
  display: ${({ $display }) => $display || 'flex'};

  > button {
    position: absolute;
    right: 0.5rem;
  }

  .warningIcon {
    display: ${({ $error }) => ($error ? 'block' : 'none')};
    svg {
      color: ${({ theme }) => theme.colours.messaging.error.main};
    }
    margin-right: ${({ $error }) => `${$error ? '0.5rem' : '0'}`};
  }

  input {
    width: ${({ $error }) => `${$error ? '130px' : '150px'}}`};
  }
`;
PlayerComparisonSearchHolder.displayName = 'PlayerComparisonSearchHolder';

export const PlayerComparisonIconHolder = styled.div`
  position: relative;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}55`};
  border-right: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}55`};
  display: ${({ $display }) => $display || 'block'};
`;
PlayerComparisonIconHolder.displayName = 'PlayerComparisonIconHolder';

export const PlayerComparisonRadar = styled.div`
  position: relative;
  padding: 0.5rem;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  border-right: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  height: 180px;

  svg {
    max-height: 164px;
    overflow: hidden;
  }

  // optionally highlight radar tile within the comparisons tile
  &::after {
    ${({ theme, $highlight }) =>
      $highlight ? getHighlightStyles(theme) : null}
  }
`;
PlayerComparisonRadar.displayName = 'PlayerComparisonRadar';

export const PlayerComparisonPlayerHeader = styled.div`
  padding: 0.5rem;
  border-left: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  height: 70px;
  ${({ theme }) => theme.typography.headingSmall};
  text-align: left;
  display: flex;
  align-items: center;
`;
PlayerComparisonPlayerHeader.displayName = 'PlayerComparisonPlayerHeader';

export const PlayerComparisonRadarHeader = styled.div`
  padding: 0.5rem;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  border-left: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  height: 180px;
  ${({ theme }) => theme.typography.headingSmall};
  display: flex;
  align-items: center;
`;
PlayerComparisonRadarHeader.displayName = 'PlayerComparisonRadarHeader';

export const PlayerComparisonTrackWeight = styled.div`
  display: flex;

  div {
    width: 20%;
    height: 1.875rem;
    ${({ theme }) => theme.typography.headingSmall};
    justify-content: center;
    align-items: self-end;
    align-content: center;
    display: inline-flex;
    opacity: 0.5;
    transition: opacity 300ms ease-out;

    &.selected {
      color: ${({ theme }) => theme.colours.ink.primary.main};
      opacity: 1;
    }

    &:nth-child(1),
    &:nth-child(2) {
      justify-content: flex-start;
    }

    &:nth-child(3) {
      justify-content: center;
    }

    &:nth-child(4),
    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
`;
PlayerComparisonTrackWeight.displayName = 'PlayerComparisonTrackWeight';

export const PlayerComparisonTableBorder = styled.div`
  table {
    border-right: 0;
    border-left: 0;
    border-color: ${({ theme }) => theme.colours.canvas.secondary.main};
    opacity: ${({ $opacity }) => $opacity || 1};
  }
`;
PlayerComparisonTableBorder.displayName = 'PlayerComparisonTableBorder';

export const PlayerComparisonIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
`;
PlayerComparisonIndicator.displayName = 'PlayerComparisonIndicator';

export const PlayerComparisonIndicatorWrapper = styled.div`
  position: absolute;
  display: flex;
  z-index: 9;
  color: ${({ theme }) => theme.colours.ink.primary.main};
`;
PlayerComparisonIndicatorWrapper.displayName =
  'PlayerComparisonIndicatorWrapper';

export const PlayerComparisonScrollArea = styled.div`
  display: flex;

  > div {
    display: flex;
  }

  // first column (fixed on on the left)
  > div:first-child {
    // copied from RC lib
    // shadow is downward
    box-shadow: 8px 0px 9px -4px rgba(0, 0, 0, 0.35);
    z-index: 2;

    > div:nth-child(1) {
      table {
        border-right: 0;
        border-color: ${({ theme }) => theme.colours.canvas.secondary.main};
      }
    }

    // the tables
    > div {
      > div {
        div:nth-child(1) {
          > table {
            position: relative;
            border-right: ${({ theme }) =>
              `1px solid ${theme.colours.canvas.secondary.main}`};

            &::after {
              ${({ theme }) => getHighlightStyles(theme)}
            }
          }
        }
        // the radars
        div:nth-child(2),
        div:nth-child(4) {
          > div:nth-child(2) {
            position: relative;

            &::after {
              ${({ theme }) => getHighlightStyles(theme)}
            }
          }
        }
      }
    }
  }

  // second column or main content area for comparison tiles (elastic right hand side)
  > div:last-child {
    overflow-x: scroll;
    scroll-behavior: smooth;
    will-change: scroll-position;

    .comparisonTile:last-child {
      table {
        border-right: ${({ theme }) =>
          `1px solid ${theme.colours.canvas.secondary.main}`};
      }
    }
  }
`;
PlayerComparisonScrollArea.displayName = 'PlayerComparisonScrollArea';

export const PlayerComparisonScrollAreaStickyHeader = styled.div`
  display: flex;
  position: sticky;
  top: 3.25rem;
  z-index: 99; // 1 below header
  backface-visibility: hidden;
  // shadow is downward
  box-shadow: 0px 8px 9px -4px rgba(0, 0, 0, 0.35);
  margin-bottom: -1rem; // compensate for Grid gap
  padding-top: 1rem; // add border to top of sticky header
  margin-top: -1rem; // remove extra padding from top of sticky header
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};

  // first column (the stats of the origin player on on the left)
  > div:nth-child(1) {
    > div:nth-child(1) {
      table {
        border-right: 0;
        border-color: ${({ theme }) => theme.colours.canvas.secondary.main};
      }
    }

    > div:nth-child(2) {
      position: relative;
      // shadow is rightward
      box-shadow: 8px 0 9px -4px rgba(0, 0, 0, 0.35);
      border-right: ${({ theme }) =>
        `1px solid ${theme.colours.canvas.secondary.main}`};

      &::after {
        ${({ theme }) => getHighlightStyles(theme)}
      }
    }
  }

  // scrollable header area
  > div:nth-child(2) {
    position: relative;
    will-change: transform;

    > div {
      position: absolute;
      z-index: 97;
    }
  }

  // name/team
  > .row {
    display: flex;
    flex-direction: row;

    > .rowSub {
      display: flex;

      // last element to the right needs borders on the table and name block
      &:last-child {
        > div {
          > div:first-child {
            border-right: ${({ theme }) =>
              `1px solid ${theme.colours.canvas.secondary.main}`};
          }
          > div:last-child {
            table {
              border-right: ${({ theme }) =>
                `1px solid ${theme.colours.canvas.secondary.main}`};
            }
          }
        }
      }
    }
  }
`;
PlayerComparisonScrollAreaStickyHeader.displayName =
  'PlayerComparisonScrollAreaStickyHeader';

export const PlayerComparisonInformation = styled.div`
  ${({ theme }) => theme.typography.headingMedium}
  height: 1.25rem;
`;
PlayerComparisonInformation.displayName = 'PlayerComparisonInformation';

// this is a hack as a div cannot have multiple overflow conditions,
// i.e overflow-x: scroll and overflow-y: visible
// so we put an overflow on parent for one condition and add this overlay to align with page
export const PlayerComparisonBlank = styled.div`
  position: absolute;
  width: 1rem;
  height: 220px;
  right: 0;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  z-index: 9999;
`;
PlayerComparisonBlank.displayName = 'PlayerComparisonBlank';
