import { gql } from '@apollo/client';
import {
  defaultContextFilters,
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../utils/helpers/contextFilters';

// We use a different method to determine the context fields for this query because it is a special
// case. This end point only supports a limited amount of context filters, so we don't want the query
// to automatically opt-in to any new context filters that might be added in the future
const fields = [
  'fieldPositionMin',
  'fieldPositionMax',
  'quarters',
  'clockMin',
  'clockMax',
];
const overrides = Object.keys(defaultContextFilters).reduce(
  (acc, key) => ({
    ...acc,
    [key]: fields.includes(key),
  }),
  {}
);

export const GET_DRIVES = gql`
  query GetDrives(
    $competitionId: Int!
    $gameId: Int!
    ${getContextQueryVariables(overrides)}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedGameId @client @export(as: "gameId")
    ${getContextFilterClientExports(overrides)}

    drives(
      first: 50
      filters: {
        competitionId: $competitionId
        gameIds: [$gameId]
        ${getContextFiltersQueryArguments(overrides)}
      }
    ) {
      edges {
        node {
          id
          name
          team {
            id
          }
          game {
            homeTeam {
              id
              name
            }
            awayTeam {
              id
              name
            }
          }
        }
      }
    }
  }
`;
