import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  SearchResultHolder,
  SearchResults,
  SearchResultsLoading,
  SearchWithList,
} from '../BiometricFilters.styles';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import { useSearchHighSchools } from '../hooks/useSearchHighSchools';
import BiometricChips from './BiometricChips';
import BiometricListItem from './BiometricListItem';

const HighSchoolMultiselect = ({
  selectedBiometrics,
  setSelectedBiometrics,
}) => {
  const highSchoolFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.highSchool.key
  );
  const currentTokens = isEmpty(highSchoolFilter?.tokens)
    ? []
    : [...highSchoolFilter.tokens];

  const [searchValue, setSearchValue] = useState('');
  const trimmedSearchValue = (searchValue || '').trim();

  const { highSchools, isLoading } = useSearchHighSchools(trimmedSearchValue);

  const HighSchoolListItem = (highSchool) => {
    const { name, city, state, id: schoolId } = highSchool;
    const schoolName = `${name}, ${city}, ${state}`;
    const lIToken = { id: schoolId, name: schoolName };

    return (
      <BiometricListItem
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.highSchool.key}
        currentTokens={currentTokens}
        searchResultToken={lIToken}
        onSelected={() => setSearchValue('')}
      />
    );
  };

  const adornment = trimmedSearchValue.length > 0 ? 'Close' : 'Search';

  return (
    <SearchWithList>
      <h3>High schools</h3>
      <TextField
        id="high-school-search-filter"
        labelPosition="none"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        adornmentIcon={adornment}
        adornmentOnClick={() => {
          if (trimmedSearchValue.length > 0) {
            setSearchValue('');
          }
        }}
        placeholder="Search for a high school"
        size="small"
      />
      {trimmedSearchValue.length > 0 && (
        <SearchResultHolder>
          {isLoading && (
            <SearchResultsLoading>
              <Loader active inline size="small" content="Loading Data" />
            </SearchResultsLoading>
          )}
          <SearchResults>{highSchools?.map(HighSchoolListItem)}</SearchResults>
        </SearchResultHolder>
      )}
      <BiometricChips
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.highSchool.key}
        tokens={currentTokens}
      />
    </SearchWithList>
  );
};
HighSchoolMultiselect.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default HighSchoolMultiselect;
