import { useQuery } from '@apollo/client';
import { GET_TEAM_FORMATIONS } from './getTeamFormations';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import { FORMATIONS } from './TeamFormationDetail.constants';

/**
 * custom hook to get the options for the formations dropdown
 * Gets the list of formations with total usages for either team or league
 */
export const useFormationUsages = (teamId) => {
  const { data, loading, error } = useQuery(GET_TEAM_FORMATIONS, {
    variables: {
      offenseTeamIds: teamId,
    },
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_FORMATIONS, error);
    return { error, loading: false };
  }

  /* 
  Because the teamStats don't provide the API key needed to specify formation
  Have to use the lookup to find the value
   */
  const formationUsages = data.teamStats.items
    // format results to be used in the dropdown
    .map((formation) => ({
      ...formation,
      formationCode: FORMATIONS.find((f) => f.name === formation.formationName)
        ?.apiKey,
    }))
    // filter results that don't have a recognized formation (null or missing translation)
    .filter((formation) => formation.formationCode);

  return { data: formationUsages, loading };
};
