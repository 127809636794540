import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from './Bar';
import { BARS } from './FormationSummaryChart.constants';

// Draws the bars of the summary formation chart - bars, bar indicators, and delta indicators
export const Bars = ({ data, yScale, xScale }) => {
  const maxDomain = xScale.domain()[1];
  const x0 = xScale(0);

  return (
    <g>
      {data.map((f) => {
        const x = xScale(f.value);

        const barWidth = Math.abs(x - x0);
        const barX = Math.min(x, x0);
        const barY = yScale(f.label);

        return (
          <Bar
            key={f.label}
            barX={barX}
            barY={barY}
            color={f.color}
            value={f.value}
            barWidth={barWidth}
            barHeight={BARS.BASE_HEIGHT}
            maxDomain={maxDomain}
          />
        );
      })}
    </g>
  );
};

Bars.propTypes = {
  // the data that constructs the bars
  data: PropTypes.arrayOf(PropTypes.object),
  // d3 scale band constructor instance
  yScale: PropTypes.func.isRequired,
  // d3 scale linear constructor instance
  xScale: PropTypes.func.isRequired,
};

Bars.defaultProps = {
  data: [],
};

export default Bars;
