import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../errorCodes/errorCodes';
import { GET_TEAM_STAT_DISTRIBUTIONS } from './apiQueries/teamStats/getTeamStatDistributions';

/**
 * Custom hook to get the mean & standard deviation of selected team stats
 * Used in formation pages to color the data spread
 */
export const useTeamStatDistributions = (statNames) => {
  const { data, loading, error } = useQuery(GET_TEAM_STAT_DISTRIBUTIONS, {
    variables: {
      offense: true,
      statNames,
    },
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_STAT_DISTRIBUTIONS, error);
    return { error, loading: false };
  }

  return { data: data.teamStatDistributions, loading };
};
