import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { select, easeLinear } from 'd3';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { useTheme } from 'styled-components';
import {
  translatePoint,
  translatePointRotate,
} from '../../../utils/helpers/animation';
import {
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
  END_ZONE_WIDTH,
  ROTATIONS,
} from '../../../utils/constants/charting';
import { getPalette } from '../../../utils/visualisations/visPalettes';
import { useD3 } from '../../../utils/hooks/useD3';
import { addField } from '../../../utils/helpers/field';
import { pf_TeamPlayEvent, mf_LeagueLevel } from '../../../apollo';
import { getPlayerPaths, getBallPath } from './GameAnimation.dataManipulation';
import {
  drawPaths,
  drawTrackingPaths,
  drawPlayers,
  drawLineOfScrimmage,
  ROUTE_MODE_ALL,
  COLOR_MODE_VS,
  VIEWPORT_FIELD,
  VIEWPORT_OPTIONS,
  VIEWPORTS_SHORT_PLAY,
  VIEWPORTS_PLAY,
  VIEWPORTS_LINE,
} from './GameAnimationChart.drawing';
import { rotateScaleZoom } from '../../../utils/visualisations/rotateScaleZoom';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../../utils/helpers/field.constants';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { StyledGameAnimationChart } from './GameAnimation.styles';

const GameAnimationChart = ({
  id,
  isInteractive,
  selectedEventUUId,
  orientation,
  routeMode: routeModeProp,
  colorMode: colorModeProp,
  highlightPlayerId: highlightPlayerIdProp,
  data,
  viewPortMode,
  isLoading,
  routeSmoothing,
  isPlaying,
  setIsPlaying,
  setTeamPlayEvent,
  marginOverrides,
}) => {
  let playPaths;
  let ballPath;
  let ballPathData = [];
  let events;
  const resetButtonId = `${id}-zoom-button`;

  const { isDark } = useTheme();
  const ffsLoc = data?.map((d) => d.freezeFrames || []);
  const flattenedFFs = ffsLoc.flat();
  const players = uniqBy(
    flattenedFFs.filter((f) => f.player !== undefined),
    (f) => f.player?.id
  );
  const playOffenseTeamId = parseInt(data[0].play_offense_team_id, 10);
  const { lineOfScrimmage, lineTarget } = data[0];
  const snapY = data.find((d) => d.isSnap)?.event_y || FIELD_Y_YARDS / 2;
  const routeMode = routeModeProp || ROUTE_MODE_ALL;
  const colorMode = colorModeProp || COLOR_MODE_VS;
  const highlightPlayerId = highlightPlayerIdProp || 0;
  // SVG BASIC VALUES
  const margin = {
    top: 15,
    right: 15,
    bottom: 5,
    left: 15,
    ...marginOverrides,
  };
  const visPalette = getPalette(isDark);
  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    showFieldNumbers: true,
    competitionLevel: useReactiveVar(mf_LeagueLevel),
  };
  // viewPort is the "window" section of the vis we want to render within
  const viewPort = VIEWPORT_OPTIONS.find((f) => f.value === viewPortMode);
  const viewPortX =
    orientation === ROTATIONS.HORIZONTAL ? viewPort.x : viewPort.y;
  const viewPortY =
    orientation === ROTATIONS.HORIZONTAL ? viewPort.y : viewPort.x;
  const viewBox = `0 0 
    ${overrides.pxPerYard * viewPortX + margin.left + margin.right} 
    ${overrides.pxPerYard * viewPortY + margin.top + margin.bottom}`;
  // set the focus for the different viewPorts
  let focalPointX = (FIELD_X_YARDS / 2) * overrides.pxPerYard;
  let focalPointY = (FIELD_Y_YARDS / 2) * overrides.pxPerYard;
  if (viewPortMode === VIEWPORTS_PLAY.value) {
    focalPointX = (lineOfScrimmage + viewPort.losOffset) * overrides.pxPerYard;
  } else if (viewPortMode === VIEWPORTS_SHORT_PLAY.value) {
    focalPointX = (lineOfScrimmage + viewPort.losOffset) * overrides.pxPerYard;
  } else if (viewPortMode === VIEWPORTS_LINE.value) {
    focalPointX = (lineOfScrimmage + viewPort.losOffset) * overrides.pxPerYard;
    focalPointY = snapY * overrides.pxPerYard;
  }
  // millisecond timings for each frame
  const ffData = data.filter((d) => d.freezeFrames);
  const ffTiming = [];
  let eventIndex = ffData.findIndex(
    (evt) => evt.event_uuid === selectedEventUUId
  );
  for (let i = 0; i < ffData.length; i += 1) {
    ffTiming.push(
      Math.round(
        (ffData[i + 1]?.videoTimestamp - ffData[i].videoTimestamp) * 1000
      )
    );
  }
  ffTiming.pop();
  // count the number of freezeFrames per event
  const countFF = [];
  ffData.forEach((ff) => {
    countFF.push(ff.ffCount - ff.ffAnonCount);
  });
  // remove empty rows
  const finalFFCount = countFF.filter((c) => c);
  // keep a count of the current events' end() versus the final count
  const currentEventCount = new Array(finalFFCount.length).fill(0);
  // pitch rotation
  let textRotate = '0';
  if (orientation === ROTATIONS.VERTICAL_UP) {
    textRotate = '90';
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    textRotate = '-90';
  }
  const pitch_area_transform = `translate(${margin.left},${margin.top})`;

  const ref = useD3(
    (svg) => {
      // reset svg
      svg.selectAll('*').remove();

      // BACKING RECT FOR THE SVG
      svg
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', visPalette.background.main);

      const marginAdjustedG = svg
        .append('g')
        .attr('id', `${id}-margin-adjusted`)
        .attr('transform', pitch_area_transform);

      const rszG = rotateScaleZoom({
        baseG: marginAdjustedG,
        idPrefix: id,
        viewPortWidth: viewPortX * overrides.pxPerYard,
        viewPortHeight: viewPortY * overrides.pxPerYard,
        cropToViewport: true,
        orientation,
        fieldWidth: FIELD_X_YARDS * overrides.pxPerYard,
        fieldHeight: FIELD_Y_YARDS * overrides.pxPerYard,
        targetFieldX: focalPointX,
        targetFieldY: focalPointY,
        scaleFactor: 1,
        bindEdges: false,
        fieldBoundary: 0,
        addZoom: isInteractive,
        zoomableGId: `${id}-zoomable-g`,
        resetButtonId,
      });

      // ADD THE FIELD
      rszG.call(addField, overrides);

      // ADD Layer for Elements
      const skipEndZoneTransform = `translate(
        ${END_ZONE_WIDTH * overrides.pxPerYard},0)`;
      rszG
        .append('g')
        .attr('id', `${id}-los-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-ball-arrows-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-player-arrows-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-player-paths-zone`)
        .attr('data-testid', 'playerPathZoneT')
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-player-tracking-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-ball-paths-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-anon-players-zone`)
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-players-zone`)
        .attr('data-testid', 'playerDotZoneT')
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-ball-zone`)
        .attr('data-testid', 'ballZoneT')
        .attr('transform', skipEndZoneTransform);
      rszG
        .append('g')
        .attr('id', `${id}-jerseys-zone`)
        .attr('data-testid', 'playerJerseyZoneT')
        .attr('transform', skipEndZoneTransform);

      marginAdjustedG
        .append('g')
        .attr('id', `${id}-viewPortFrame`)
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', viewPortX * overrides.pxPerYard)
        .attr('height', viewPortY * overrides.pxPerYard)
        .attr('fill', 'none')
        .attr('stroke', visPalette.border)
        .attr('stroke-width', 1);
    },
    [orientation, viewPortMode, isInteractive, isDark]
  );

  const stopAnimation = () => {
    setIsPlaying(false);

    players.forEach((player) => {
      select(ref.current)
        .select(`#${id}-jerseys-zone`)
        .select(`#${id}-playerNumber${player.playerId}`)
        .transition()
        .duration(0);

      select(ref.current)
        .select(`#${id}-players-zone`)
        .select(`#${id}-player${player.playerId}`)
        .transition()
        .duration(0);
    });

    select(ref.current).select(`#${id}-theBall`).transition().duration(0);
  };

  const setupBallAnimation = () => {
    select(ref.current)
      .select(`#${id}-ball-zone`)
      .select('path')
      .transition()
      .ease(easeLinear)
      .duration(ffTiming[eventIndex])
      .attrTween(
        'transform',
        translatePoint(
          select(ref.current).select(`#${id}-ballPathPart${eventIndex}`).node()
        )
      )
      .on('end', () => {
        // ref against global var -the parent className which is tied to isPlaying in parent
        const stopPlaying =
          ref.current?.className.baseVal === 'disable-transition';

        if (stopPlaying) {
          stopAnimation();
        } else {
          // log that this player has completed
          currentEventCount[eventIndex] += 1;
          // if all players(+ball) have completed, then progress the animation
          if (currentEventCount[eventIndex] === finalFFCount[eventIndex]) {
            if (eventIndex < ffTiming.length) {
              eventIndex += 1;
              setTeamPlayEvent(ffData[eventIndex].event_uuid);
            }
          }
        }
      });
  };

  const setupPlayerAnimation = (playerId) => {
    select(ref.current)
      .select(`#${id}-jerseys-zone`)
      .select(`#${id}-playerNumber${playerId}`)
      .transition()
      .ease(easeLinear)
      .duration(ffTiming[eventIndex])
      .attrTween(
        'transform',
        translatePointRotate(
          select(ref.current)
            .select(`#${id}-playerPath${playerId}_${eventIndex}`)
            .node(),
          textRotate
        )
      );

    select(ref.current)
      .select(`#${id}-players-zone`)
      .select(`#${id}-player${playerId}`)
      .transition()
      .ease(easeLinear)
      .duration(ffTiming[eventIndex])
      .attrTween(
        'transform',
        translatePoint(
          select(ref.current)
            .select(`#${id}-playerPath${playerId}_${eventIndex}`)
            .node()
        )
      )
      .on('end', () => {
        // ref against global var -the parent className which is tied to isPlaying in parent
        const stopPlaying =
          ref.current?.className.baseVal === 'disable-transition';

        if (stopPlaying) {
          stopAnimation();
        } else {
          // log that this player has completed
          currentEventCount[eventIndex] += 1;
          // if all players(+ball) have completed, then progress the animation
          if (currentEventCount[eventIndex] === finalFFCount[eventIndex]) {
            if (eventIndex < ffTiming.length) {
              eventIndex += 1;
              setTeamPlayEvent(ffData[eventIndex].event_uuid);
            }
          }
        }
      });
  };

  // setup animations for each player and the ball
  const setupAnimation = (knownPlayerData) => {
    knownPlayerData.forEach((playerData) => {
      const { playerId } = playerData;
      setupPlayerAnimation(playerId);
    });

    setupBallAnimation();
  };

  const setupEvent = () => {
    // new play no event selected yet, select first event
    if (
      selectedEventUUId === null ||
      data.filter((d) => d.event_uuid === selectedEventUUId).length === 0
    ) {
      pf_TeamPlayEvent(data[0]?.event_uuid);
    }

    events = uniqBy(
      data.filter((evt) => evt.event_uuid === selectedEventUUId),
      'event_uuid'
    );

    const eventPlayerFFs = events[0]?.freezeFrames;
    const ballData =
      eventPlayerFFs === undefined
        ? []
        : eventPlayerFFs.filter((evt) => evt.ballLocation === true);

    const knownPlayerData =
      eventPlayerFFs === undefined
        ? []
        : eventPlayerFFs.filter((p) => p.player && p.player.id);

    const anonPlayerData =
      eventPlayerFFs === undefined
        ? []
        : eventPlayerFFs.filter((p) => p.player == null && p.playerIndex >= 0);

    drawPlayers(
      select(ref.current),
      knownPlayerData,
      anonPlayerData,
      ballData,
      playOffenseTeamId,
      overrides.pxPerYard,
      orientation,
      colorMode,
      highlightPlayerId,
      visPalette,
      id
    );

    const trimmedData = data.slice(
      data.findIndex((evt) => evt.event_uuid === selectedEventUUId)
    );
    const ffsLocForPath = trimmedData?.map((d) => d.freezeFrames || []);
    const flattenedFFsForPath = ffsLocForPath.flat();

    playPaths = getPlayerPaths(
      players,
      flattenedFFsForPath,
      playOffenseTeamId,
      overrides.pxPerYard,
      true // as paths
    );

    ballPathData = flattenedFFsForPath.filter((f) => f.ballLocation === true);
    ballPath = getBallPath(
      ballPathData,
      playOffenseTeamId,
      overrides.pxPerYard
    );
    if (isPlaying) {
      setupAnimation(knownPlayerData);
    } else {
      stopAnimation();
    }
  };

  const setupPaths = () => {
    if (players === undefined) {
      return;
    }

    playPaths = getPlayerPaths(
      players,
      flattenedFFs,
      playOffenseTeamId,
      overrides.pxPerYard,
      routeSmoothing
    );

    ballPathData = flattenedFFs.filter((f) => f.ballLocation === true);
    ballPath = getBallPath(
      ballPathData,
      playOffenseTeamId,
      overrides.pxPerYard
    );

    drawPaths(
      select(ref.current),
      playPaths,
      ballPath,
      playOffenseTeamId,
      routeMode,
      colorMode,
      highlightPlayerId,
      visPalette,
      routeSmoothing,
      id
    );

    const playTrackingPaths = getPlayerPaths(
      players,
      flattenedFFs,
      playOffenseTeamId,
      overrides.pxPerYard,
      true // as paths
    );

    drawTrackingPaths(select(ref.current), playTrackingPaths, ballPath, id);
  };

  useEffect(() => {
    events = data.find((evt) => evt.event_uuid === selectedEventUUId);
    const eventPlayerFFs = events?.freezeFrames;
    const knownPlayerData = !eventPlayerFFs
      ? []
      : eventPlayerFFs.filter((p) => p.player && p.player.id);

    if (isPlaying) {
      setupAnimation(knownPlayerData);
    } else {
      stopAnimation();
    }
  }, [isPlaying]);

  useEffect(() => {
    setupPaths();
  }, [highlightPlayerId, colorMode, routeMode, isDark]);

  useEffect(() => {
    drawLineOfScrimmage(
      select(ref.current),
      lineOfScrimmage,
      lineTarget,
      overrides.pxPerYard,
      visPalette,
      id
    );
    setupEvent();
    setupPaths();
  }, [data, orientation, viewPortMode, selectedEventUUId, isDark]);

  return (
    <>
      {isLoading && (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      )}
      {data?.length === 0 && (
        <Dimmer active>
          <p>No Data available</p>
        </Dimmer>
      )}
      <StyledGameAnimationChart
        id={`${id}-amf-dots-and-paths`}
        ref={ref}
        viewBox={viewBox}
        data-testid="game-animation-chart"
        $isPlaying={isPlaying}
        $isInteractive={isInteractive}
      />
    </>
  );
};

GameAnimationChart.propTypes = {
  id: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool,
  selectedEventUUId: PropTypes.string,
  orientation: PropTypes.string,
  routeMode: PropTypes.string,
  colorMode: PropTypes.string,
  highlightPlayerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      event_uuid: PropTypes.string,
      play_offense_team_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      lineOfScrimmage: PropTypes.number,
      lineTarget: PropTypes.number,
      videoTimestamp: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
  viewPortMode: PropTypes.string,
  routeSmoothing: PropTypes.bool,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setTeamPlayEvent: PropTypes.func,
  marginOverrides: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};

GameAnimationChart.defaultProps = {
  isInteractive: true,
  selectedEventUUId: undefined,
  orientation: ROTATIONS.HORIZONTAL,
  routeMode: undefined,
  colorMode: undefined,
  highlightPlayerId: undefined,
  data: undefined,
  isLoading: false,
  viewPortMode: VIEWPORT_FIELD.value,
  routeSmoothing: true,
  isPlaying: false,
  setIsPlaying: () => {},
  setTeamPlayEvent: () => {},
  marginOverrides: {},
};

export default GameAnimationChart;
