import { useState, useCallback } from 'react';
import { getQueryStringValue, setQueryStringValue } from '../queryString';
import { isNumericString } from '../helpers/strings';

function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  const parsedValue =
    value === 'false' || value === 'true' || isNumericString(value)
      ? JSON.parse(value)
      : value;

  return [parsedValue, onSetValue];
}

export default useQueryString;
