import { LOGO_DEFAULT_HEIGHT } from '../../utils/helpers/export';

/* Radars are broken into X rings
  There is also a central dot which does not count as a ring
*/
const RADAR_RING_COUNT = 12; // actually 10 ~ 0 is included and the center circle adds a phantom ring
const RADAR_RINGS = [...Array(RADAR_RING_COUNT).keys()].reverse();
const RADAR_RADIUS = 300;
const RADAR_RADIUS_KEY = 40;
// header and footer sort their own lower/upper padding respectively
const RADAR_HEADER_HEIGHT = 60;
const RADAR_KEY_HEIGHT = RADAR_RADIUS_KEY * 2;
const RADAR_SIMPLE_KEY_HEIGHT = 30;
const RADAR_FOOTER_LOGO_OFFSET = 20;
const RADAR_FOOTER_HEIGHT = LOGO_DEFAULT_HEIGHT + RADAR_FOOTER_LOGO_OFFSET;
const RADAR_DEFAULT_MARGINS = { top: 5, right: 5, bottom: 5, left: 5 };
const RADAR_AXIS_PADDING = 40;
/* 
  radar is broken into X rings + center dot, ringcount being thus 2 more than the total number of outer edge rings
  radius of the inner dot = width of each ring
    each axis is from this distance out to the full radius of the radar
  */
const RADAR_RING_WIDTH = (1 / (RADAR_RING_COUNT - 1)) * RADAR_RADIUS;
/* This is 10/11 the radius for a radar of 10 rings+center dot, but is specified logically below
  in case the desirec number of rings changes
*/
const RADAR_AXIS_WIDTH =
  ((RADAR_RING_COUNT - 2) / (RADAR_RING_COUNT - 1)) * RADAR_RADIUS;

const RADAR_CHART_CLASS_NAMES = {
  BACKGROUND_RECT: 'rc-back-rect',
  WITHIN_MARGINS: 'rc-within-margins',
  HEADER: 'rc-header',
  MAIN: 'rc-main',
  FOOTER: 'rc-footer',
  RINGS: 'rc-background-rings',
  AXES: 'rc-axes',
  SHAPES: 'rc-shapes',
  LABELS: 'rc-labels',
  KEY: 'rc-key',
  HEADER_TEXT_MAIN: 'rc-header-main',
  HEADER_TEXT_LA: 'rc-header-league-average',
};

const RADAR_CHART_CLIPPATHS = {
  MAIN: '-radar-shape-main',
  COMPARISON: '-radar-shape-comparison',
  COMBINED: '-radar-shape-combined',
  CENTER_CIRCLE: '-center-circle',
};

const radarChartElementId = (radarId) => `amf-radar-${radarId}`;

const RADAR_CHART_DRAW_COMPARISON = {
  MAIN: 'main',
  COMPARISON: 'comparison',
  BOTH: 'both',
};

const RADAR_PALETTE_OVERRIDES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export {
  RADAR_RING_COUNT,
  RADAR_RINGS,
  RADAR_RADIUS,
  RADAR_RADIUS_KEY,
  RADAR_CHART_CLASS_NAMES,
  RADAR_CHART_CLIPPATHS,
  RADAR_RING_WIDTH,
  RADAR_AXIS_WIDTH,
  radarChartElementId,
  RADAR_HEADER_HEIGHT,
  RADAR_FOOTER_HEIGHT,
  RADAR_KEY_HEIGHT,
  RADAR_SIMPLE_KEY_HEIGHT,
  RADAR_DEFAULT_MARGINS,
  RADAR_AXIS_PADDING,
  RADAR_CHART_DRAW_COMPARISON,
  RADAR_FOOTER_LOGO_OFFSET,
  RADAR_PALETTE_OVERRIDES,
};
