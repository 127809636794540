import React from 'react';
import PropTypes from 'prop-types';
import {
  VerticalGuide,
  AxisTopTickLabel,
} from './FormationSummaryChart.styles';
import {
  AXIS_TOP,
  FORMATION_SUMMARY_FONTS,
} from './FormationSummaryChart.constants';

// Draws the top axis of the formation summary chart - ticks and lines
export const AxisTop = ({ xScale, isTeamMode }) => {
  // how many ticks the x axis should render
  const ticksAmount =
    (xScale.domain()[1] - xScale.domain()[0]) / AXIS_TOP.TICK_STEP + 1;

  return (
    <g>
      {xScale.ticks(ticksAmount).map((tick, index) => (
        <g key={tick} transform={`translate(${xScale(tick)}, 0)`}>
          <VerticalGuide
            $isFirst={!isTeamMode ? index === (ticksAmount - 1) / 2 : !index}
            y1={AXIS_TOP.GUIDE_GAP + FORMATION_SUMMARY_FONTS.NORMAL.SIZE}
            y2="100%"
          />
          <AxisTopTickLabel y={FORMATION_SUMMARY_FONTS.NORMAL.SIZE}>
            {!isTeamMode && index === (ticksAmount - 1) / 2
              ? 'LA'
              : `${tick * 100}%`}
          </AxisTopTickLabel>
        </g>
      ))}
    </g>
  );
};

AxisTop.propTypes = {
  // d3 scale linear constructor instance
  xScale: PropTypes.func.isRequired,
  // whether the chart is on team mode
  isTeamMode: PropTypes.bool,
};

AxisTop.defaultProps = {
  isTeamMode: true,
};

export default AxisTop;
