import { scaleLinear, scalePoint, scaleSqrt, scaleBand } from 'd3';
import { maxBy, minBy, reverse } from 'lodash';
import {
  CHART_SCALE_DISPLAY_TYPE,
  CHART_SCALE_TYPE,
} from './BasicChart.constants';

/* Generate the transforms for all layout zones */
export const getLayoutTransforms = (scatterLayout) => {
  const axisTopTransform = `translate(${scatterLayout.AXES.AREA.left},0)`;
  const axisTopInnerTransform = `translate(${scatterLayout.AXES.PADDING.left},${scatterLayout.AXES.AREA.top})`;

  const axisRightTransform = `translate(${
    scatterLayout.AXES.AREA.left +
    scatterLayout.AXES.PADDING.left +
    scatterLayout.CANVAS.WIDTH +
    scatterLayout.AXES.PADDING.right
  },${scatterLayout.AXES.AREA.top})`;
  const axisRightInnerTransform = `translate(0,${scatterLayout.AXES.PADDING.top})`;

  const axisBottomTransform = `translate(${scatterLayout.AXES.AREA.left},${
    scatterLayout.AXES.AREA.top +
    scatterLayout.AXES.PADDING.top +
    scatterLayout.CANVAS.HEIGHT +
    scatterLayout.AXES.PADDING.bottom
  })`;
  const axisBottomInnerTransform = `translate(${scatterLayout.AXES.PADDING.left},0)`;

  const axisLeftTransform = `translate(0,${scatterLayout.AXES.AREA.top})`;
  const axisLeftInnerTransform = `translate(${scatterLayout.AXES.AREA.left},${scatterLayout.AXES.PADDING.top})`;

  const axesTransform = `translate(${scatterLayout.AXES.AREA.left},${scatterLayout.AXES.AREA.top})`;
  const canvasTransform = `translate(${scatterLayout.AXES.PADDING.left},${scatterLayout.AXES.PADDING.top})`;

  return {
    axisTopTransform,
    axisTopInnerTransform,
    axisRightTransform,
    axisRightInnerTransform,
    axisBottomTransform,
    axisBottomInnerTransform,
    axisLeftTransform,
    axisLeftInnerTransform,
    axesTransform,
    canvasTransform,
  };
};

/* 
Given setup properties and the scatter data, return an appropriate scale function
*/
export const getScale = (
  axisSetup,
  axisRange,
  data,
  dataKey,
  scaleDisplayType = CHART_SCALE_DISPLAY_TYPE.POINT
) => {
  if (axisSetup.TYPE === CHART_SCALE_TYPE.CATEGORICAL) {
    if (scaleDisplayType === CHART_SCALE_DISPLAY_TYPE.POINT) {
      const axisDomain = axisSetup.DOMAIN || data.map((g) => g[dataKey]);
      /* Default (because scatter) to points not using the edges */
      const axisPadding = axisSetup.PADDING !== null ? axisSetup.PADDING : 0.5;
      return scalePoint()
        .domain(axisDomain)
        .range(axisRange)
        .padding(axisPadding);
    }
    if (scaleDisplayType === CHART_SCALE_DISPLAY_TYPE.BAR) {
      const axisDomain = axisSetup.DOMAIN || data.map((g) => g[dataKey]);
      /* Default (because scatter) to points not using the edges */
      const axisPaddingOuter =
        axisSetup.PADDING !== null ? axisSetup.PADDING : 0;
      const axisPaddingInner =
        axisSetup.PADDING_INNER !== null ? axisSetup.PADDING_INNER : 0.05;
      return scaleBand()
        .domain(axisDomain)
        .range(axisRange)
        .paddingInner(axisPaddingInner)
        .paddingOuter(axisPaddingOuter);
    }
  }
  /* else is numeric */
  const axisMin = minBy(data, dataKey)?.[dataKey] || 0;
  const axisMax = maxBy(data, dataKey)?.[dataKey] || 0;
  const axisDomain = axisSetup.DOMAIN || [axisMin, axisMax];
  if (axisSetup.TYPE === CHART_SCALE_TYPE.NUMERIC_RADIUS) {
    return scaleSqrt().domain(axisDomain).range(axisRange).clamp(true);
  }
  if (axisSetup.NICE) {
    return scaleLinear().domain(axisDomain).range(axisRange).clamp(true).nice();
  }
  return scaleLinear().domain(axisDomain).range(axisRange).clamp(true);
};

const proportionalRange = (domainValueCount, rangeMin, rangeMax) => {
  const rangeLength = rangeMax - rangeMin;
  const proportions = [...Array(domainValueCount).keys()].map(
    (a) => a / (domainValueCount - 1)
  );
  const rangeValues = proportions.map((p) => rangeMin + p * rangeLength);
  return rangeValues;
};

/* 
Given setup properties and the scatter data, return an appropriate scale function
*/
export const getRange = (
  axisSetup,
  rangeMax, // generally canvas width or height
  rangeMin = 0
) => {
  /* If a bespoke range is specified, use it */
  if (Array.isArray(axisSetup?.RANGE)) {
    return axisSetup.RANGE;
  }
  const defaultRange =
    Array.isArray(axisSetup?.DOMAIN) && axisSetup.DOMAIN.length > 2
      ? proportionalRange(axisSetup.DOMAIN.length, rangeMin, rangeMax)
      : [rangeMin, rangeMax];
  const orderedRange =
    axisSetup?.INVERT === true ? reverse(defaultRange) : defaultRange;
  return orderedRange;
};
