import { styled } from 'styled-components';

export const FormationDetailFilterContainer = styled.div`
  dialog {
    > header:first-of-type {
      display: none;
    }

    > div {
      padding: 0;
    }

    > footer:first-of-type {
      display: none;
    }

    hr {
      margin: 0 1.6rem;
      border-top: 1px solid
        ${({ theme }) => theme.colours.canvas.secondary.main};
    }
  }
`;

export const FormationDetailFilterHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  ${({ theme }) => theme.typography.headingLarge}
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  h2 {
    margin: 0;
  }

  path {
    fill: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }
`;

export const FormationDetailFilterFooter = styled.header`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 1.6rem;
  gap: 0.8rem;
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1.6rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.typography.headingMedium}

    > div {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  .options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem;
    padding: 0 0.8rem;

    label {
      max-width: 10.4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

FormationDetailFilterContainer.displayName = 'FormationDetailFilterContainer';
FormationDetailFilterHeader.displayName = 'FormationDetailFilterHeader';
FormationDetailFilterFooter.displayName = 'FormationDetailFilterFooter';
FilterSection.displayName = 'FilterSection';
