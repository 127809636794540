import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@statsbomb/kitbag-components';

/*
 * a button to be used for displaying / hiding the key within a VIS
 */
const SwitchVisTableButton = ({ isShowingVis, handleClick, iconSize }) => (
  <ButtonIcon
    id="vis-or-table-button"
    onClick={handleClick}
    variant="secondary"
    size={iconSize}
    icon="Swap"
    title={isShowingVis ? 'Display Table' : 'Display Visualisation'}
  />
);

SwitchVisTableButton.propTypes = {
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  isShowingVis: PropTypes.bool,
  handleClick: PropTypes.func,
};

SwitchVisTableButton.defaultProps = {
  iconSize: 'small',
  isShowingVis: undefined,
  handleClick: () => {},
};

export default SwitchVisTableButton;
