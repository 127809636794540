import { useTheme } from 'styled-components';
import {
  euclideanDistanceRGB,
  getSafeColorFromHex,
  getTeamColorPair,
  hexToRGB,
} from '../helpers/colorDifference';

const COLOR_DIFFERENCE_THRESHOLD = 0.2;

/**
 * Usually, when we want to display 2 teams on the same page, we would want to make them easily
 * distinguishable by using their team colors. The problem is that some teams have very similar
 * colors and displaying those colors as would make it harder to distinguish between them. This hook takes
 * the color scheme of 2 teams and returns a distinguishable color for each team based on those colors
 */
export const useDistinctTeamColors = (
  selectedTeamColors,
  opponentTeamColors
) => {
  const { isDark } = useTheme();

  // get the selected team's primary "safe" color
  const selectedTeamColorPair = getTeamColorPair(selectedTeamColors, isDark);
  const selectedTeamColor = getSafeColorFromHex(
    selectedTeamColorPair.primary,
    isDark
  );

  // get the opponent team's primary AND secondary "safe" colors
  const opponentTeamColorPair = getTeamColorPair(opponentTeamColors, isDark);
  const opponentTeamPrimaryColor = getSafeColorFromHex(
    opponentTeamColorPair.primary,
    isDark
  );
  const opponentTeamSecondaryColor = getSafeColorFromHex(
    opponentTeamColorPair.secondary,
    isDark
  );

  // check that the difference between the 2 teams primary colors is distinct enough
  const colorDifference = euclideanDistanceRGB(
    hexToRGB(selectedTeamColor),
    hexToRGB(opponentTeamPrimaryColor)
  );
  const isDistinguishable = colorDifference >= COLOR_DIFFERENCE_THRESHOLD;

  if (isDistinguishable) {
    return {
      selectedTeamColor,
      opponentTeamColor: opponentTeamPrimaryColor,
    };
  }

  // if the 2 primary colors are too similar, use whichever opponent "safe" color is more different
  const colorDifference2 = euclideanDistanceRGB(
    hexToRGB(selectedTeamColor),
    hexToRGB(opponentTeamSecondaryColor)
  );
  const opponentTeamColor =
    colorDifference >= colorDifference2
      ? opponentTeamPrimaryColor
      : opponentTeamSecondaryColor;

  return {
    selectedTeamColor,
    opponentTeamColor,
  };
};
