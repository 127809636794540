import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

const overrides = { playTypes: false };

export const getRouteStatsQuery = (selectedStatNames) => {
  const joinedStatNames =
    (selectedStatNames && selectedStatNames.join('\n')) || '';
  const gqlString = `
  query getRoutePlayerStats(
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    $teamId: Int!
    $specificPositions: [SpecificPosition!]
    ${getContextQueryVariables(overrides)}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedPlayerId @client @export(as: "playerId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports(overrides)}
    playerStats(
      playFilters: {  
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments(overrides)}
        playTypes: [PASS]
      }
      aggregateMode: RATE
      playerStatFilters: {
        playerIds: [$playerId]
        teamIds: [$teamId]
        specificPositions: $specificPositions
        }
      addPlayIds: true
      groupBy: [PLAYER, ROUTE]
    ) {
      items {  
        groups {
          key
          value
        }
        playIds
        playerName
        plays
        routeName
        playPosition
        ${joinedStatNames}
        }
    }
  }
`;
  return gqlString;
};
