import PropTypes from 'prop-types';
import { RUN_TENDENCY_AXES_OPTIONS } from '../RunTendencies.constants';

export const runGapAverageTotalPropType = PropTypes.shape({
  plays: PropTypes.number,
  yardsGained: PropTypes.number,
  yardsRun: PropTypes.number,
  successRate: PropTypes.number,
});

const runTendencyAxesIds = RUN_TENDENCY_AXES_OPTIONS.map((opt) => opt.value);
export const runGapAxesPropType = PropTypes.oneOf(runTendencyAxesIds);
