import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_CATCH_EVENTS = gql`
  query GetCatchEvents(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedPlayerId @client @export(as: "playerId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    passEvents(
      first: 1000
      eventFilters: { receiverPlayerIds: [$playerId] }
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      edges {
        node {
          id
          team {
            name
          }
          player {
            id
            name
            teamPosition {
              code
            }
            jerseyNumber
          }
          receiverPlayer {
            id
            name
            teamPosition {
              generalPosition
            }
            jerseyNumber
          }
          x
          y
          endX
          endY
          duration
          success
          passPlacementX
          passPlacementY
          points
          passAirYards
          receiverAlignmentPosition
          types
          play {
            yardLine
            success
            touchdownWon
            yardsNet
            turnoverType
            downNegated
            id
            name
            drive {
              id
              name
            }
            explosive
          }
          game {
            id
            name
          }
          passOutcome {
            oppositionCatchTypes
            oppositionCatchSuccess
          }
          passUnderthrow
          passOverthrow
          passTouchdown
          passOutOfBounds
          passYardsGained
          yardsAfterCatch
          passThrowaway
        }
      }
    }
  }
`;
