import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, Table } from '@statsbomb/kitbag-components';
import { isEmpty, isFunction, isString, keys } from 'lodash';
import { StyledBasicTable } from './BasicTable.styles';
import { sortableTableStickyColumnsPropTypes } from '../Tables/Tables.propTypes';

/* Point of this component is to just take some table data (an array of rows ~ objects where the keys=column data)
Options:
    show/hide header
    rename header values
 */
const defaultColumnAlignment = 'left';

const backupHeaders = function (data) {
  if (!data || data.length === 0) {
    return [];
  }
  const hkeys = Object.keys(data[0]);
  const headers = hkeys.map((m) => ({
    label: m,
    key: m,
    dataClass: null,
  }));
  return headers;
};

const addRow = function (rowDatum, rowIndex, columns, onRowSelect) {
  const getColumnCell = function (column) {
    const isButtonCell =
      column?.buttonIcon && isFunction(column?.onButtonClick);
    const cellContent = isButtonCell ? (
      <ButtonIcon
        variant="ghost"
        onClick={() => column.onButtonClick(rowDatum)}
        size="small"
        icon={column.buttonIcon}
        title={column?.buttonTitle}
      />
    ) : (
      rowDatum[column.key]
    );

    const rowSelectFunc =
      isFunction(onRowSelect) && !isButtonCell ? onRowSelect : () => {};
    return (
      <Table.DataCell
        key={column.key}
        className={column.dataClass}
        $textAlign={column.alignment || defaultColumnAlignment}
        onClick={() => rowSelectFunc(rowDatum)}
      >
        {cellContent}
      </Table.DataCell>
    );
  };

  return (
    <Table.Row
      key={rowIndex}
      title={rowDatum?.title}
      data-testid={`basicTableBodyRowT-${rowIndex}`}
      className={rowDatum.disabled ? 'disabled' : ''}
    >
      {columns.map(getColumnCell)}
    </Table.Row>
  );
};

const getHeaderCell = function (headerName, description, alignment) {
  return (
    <Table.HeaderCell
      key={headerName}
      title={description}
      textAlign={alignment || defaultColumnAlignment}
    >
      {headerName}
    </Table.HeaderCell>
  );
};
const addHeaderRow = function (headers) {
  return (
    <Table.Row data-testid="basicTableHeadRowT">
      {headers?.map((h) => getHeaderCell(h.label, h.description, h.alignment))}
    </Table.Row>
  );
};

function BasicTable({
  data,
  headers,
  showHeader,
  footer,
  maxHeight,
  withBorder,
  stickyColumns,
  highlightedColumn,
  onRowSelect,
  selectedRows,
  bodytdHeight,
}) {
  let heads;
  const headersFromDataKeys = backupHeaders(data);
  const selectedRowArray = isEmpty(selectedRows) ? [] : selectedRows;

  if (!headers || headers.length === 0) {
    heads = headersFromDataKeys;
  } else if (isString(headers[0] || isString(headers[0]?.label))) {
    heads = headersFromDataKeys.slice(0, headers.length).map((m, i) => {
      const niceHead = { ...m };
      niceHead.label = headers[i];
      return niceHead;
    });
  } else if (
    keys(headers[0]).includes('label') &&
    (keys(headers[0]).includes('key') || keys(headers[0]).includes('id'))
  ) {
    heads = headers;
  } else {
    heads = headersFromDataKeys;
  }

  const isSticky = !!maxHeight;

  return (
    <StyledBasicTable
      $maxHeight={maxHeight}
      data-testid="basicTableWrapperT"
      $rowSelectable={isFunction(onRowSelect)}
      $bodytdHeight={bodytdHeight}
    >
      <Table
        data-testid="basicTableT"
        withBorder={withBorder}
        stickyColumns={stickyColumns}
        highlightedColumn={highlightedColumn}
        selectedRows={selectedRowArray}
        isHeadSticky={isSticky}
        isFootSticky={isSticky}
      >
        {showHeader && (
          <Table.Head data-testid="basicTableHeadT">
            {addHeaderRow(heads)}
          </Table.Head>
        )}
        <Table.Body data-testid="basicTableBodyT">
          {data?.map((d, i) => addRow(d, i, heads, onRowSelect))}
        </Table.Body>
        {footer && (
          <Table.Foot data-testid="basicTableFootT">
            {footer?.map((d, i) => addRow(d, i, heads, null))}
          </Table.Foot>
        )}
      </Table>
    </StyledBasicTable>
  );
}
BasicTable.propTypes = {
  // a disabled attribute can be added to the data object to add a disabled effect to the row
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
        alignment: PropTypes.string,
        buttonIcon: PropTypes.string,
        onButtonClick: PropTypes.func,
      }),
    ])
  ),
  showHeader: PropTypes.bool,
  // one row of data for footer
  footer: PropTypes.arrayOf(PropTypes.shape({})),
  // if you want the table body to have a max-height the footer and heard will be sticky
  maxHeight: PropTypes.number,
  // whether the table should have a border
  withBorder: PropTypes.bool,
  // Which columns to keep on screen when the user scrolls right.
  // Can be a single number (1-based index) if only one sticky column is
  // required, otherwise needs to be an array of column objects containing the
  // column index and width.
  stickyColumns: sortableTableStickyColumnsPropTypes,
  // which column the table sorting is currently based on (1 based index)
  highlightedColumn: PropTypes.number,
  // when the rows of the table can be selectable, the selection function
  // should expect to receive the rowDatum
  onRowSelect: PropTypes.func,
  // when rows are selectable, this should specify by index the selected rows
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  // temp values during table migration, adjusts row height
  bodytdHeight: PropTypes.string,
};

BasicTable.defaultProps = {
  data: undefined,
  headers: undefined,
  showHeader: true,
  footer: undefined,
  maxHeight: undefined,
  withBorder: true,
  stickyColumns: [],
  highlightedColumn: 0,
  onRowSelect: null,
  selectedRows: null,
  bodytdHeight: null,
};

export default BasicTable;
