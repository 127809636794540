import { useState, useEffect, useRef } from 'react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';

/*
account:
{
  isDark: true,
}
*/
const useUserConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [account, setAccount] = useState();
  const { getAccessTokenSilently } = useKitbagAuth();
  const isMountedRef = useRef(false);
  const apiUrl = process.env.USER_CONFIG;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      const data = await response.json().catch((err) => {
        if (isMountedRef.current) {
          // eslint-disable-next-line no-console
          console.error(err);
          setError(err);
          setIsLoading(false);
        }
      });
      if (isMountedRef.current) {
        setIsLoading(false);
        setAccount(data);
      }
    };

    if (!account) {
      fetchData();
    }
  }, [account]);

  /* 
  Gets called with partial inputs ~ needs to be applied to existing account 
    so saving from multiple pages all update their bits without accidentally
    removing other aspects
  */
  const save = async (input) => {
    const curAccount = account || {};
    const updatedAccount = { ...curAccount, ...input };
    const body = JSON.stringify(updatedAccount);
    // console.log('saving:', { account, input, updatedAccount, body });
    setIsLoading(true);
    const token = await getAccessTokenSilently();

    await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${token}`,
      },
      body,
    }).then(() => {
      setIsLoading(false);
      setAccount(updatedAccount); // update the local value
    });
  };

  /* 
  This method replaces the whole user object with whatever the input is
    Need to be able to delete things ~ down to caller of this function to make sure
    the input is comprehensive
   */
  const saveUnsafe = async (input) => {
    const updatedAccount = { ...input };
    const body = JSON.stringify(updatedAccount);
    // console.log('saving: (unsafe)', { body, updatedAccount });
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${token}`,
      },
      body,
    }).then(() => {
      setIsLoading(false);
      setAccount(updatedAccount); // update the local value
    });
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { isLoading, error, account, save, saveUnsafe };
};

export default useUserConfig;
