import React from 'react';
import { Table, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { StyledCompactTable } from '../GameSnapFormations/SnapList.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';

const addRow = function (event, selectedEventId, onRowClick) {
  let ffCount = 0;
  let ffAnonCount = 0;
  if (event.freezeFrames && event.freezeFrames.length > 0) {
    const nonBallFFs = event.freezeFrames.filter((f) => f.playerIndex >= 0);
    const anonFFs = event.freezeFrames.filter(
      (f) => f.playerIndex >= 0 && !f.player
    );
    ffCount = nonBallFFs.length;
    ffAnonCount = anonFFs.length;
  }

  return (
    <Table.Row
      key={event.id}
      active={event.id === selectedEventId}
      disabled={!(ffCount > 0)}
    >
      <Table.Cell onClick={() => onRowClick(event)}>{event.name}</Table.Cell>
      <Table.Cell>
        {`${ffCount}${ffAnonCount > 0 ? ` (${ffAnonCount})` : ''}`}
      </Table.Cell>
    </Table.Row>
  );
};

const EventList = function ({ events, loading, selectedEventId, onRowClick }) {
  return (
    <StyledCompactTable stackable striped>
      <Table.Body>
        {!loading &&
          events &&
          events.map((d) => addRow(d, selectedEventId, onRowClick))}
        {loading && (
          <Table.Row>
            <Table.Cell>
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </StyledCompactTable>
  );
};

EventList.propTypes = {
  events: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  loading: PropTypes.bool,
  selectedEventId: PropTypes.string,
  onRowClick: PropTypes.func,
};

EventList.defaultProps = {
  events: [],
  loading: false,
  selectedEventId: '',
  onRowClick: () => {},
};

export default EventList;
