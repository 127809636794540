import { clamp } from 'lodash';

const OPTIMAL_CATCH_HEIGHT = 1.5; // YDS (or 4.5 feet) i.e. approx center chest
const OPTIMAL_CATCH_DELTAX = 0; // Yds, i.e. dead center
const PASS_AIR_YARDS_SCALE_MAX = 30; // yds

const proximityValue = function (passNode) {
  const dx = passNode.passPlacementX - OPTIMAL_CATCH_DELTAX;
  const dy = passNode.passPlacementY - OPTIMAL_CATCH_HEIGHT;
  return 1 - clamp(Math.hypot(dx, dy), 0, 2) / 2;
};

const passLengthValue = function (passNode) {
  const fractionOfScale =
    clamp(passNode.passAirYards, 0, PASS_AIR_YARDS_SCALE_MAX) /
    PASS_AIR_YARDS_SCALE_MAX;
  return fractionOfScale;
};

export { proximityValue, passLengthValue };
