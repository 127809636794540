import { gql } from '@apollo/client';

export const GET_PLAYER_STAT_DISTRIBUTION = gql`
  query GetPlayerStatDistribution(
    $competitionId: Int!
    $seasonId: Int!
    $position: GeneralPosition!
    $statNames: [String!]!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    playerStatDistributions(
      competitionId: $competitionId
      seasonId: $seasonId
      position: $position
      statNames: $statNames
    ) {
      name
      mean
      stddev
      percentiles
    }
  }
`;
