import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useTheme } from 'styled-components';
import { clamp } from 'lodash';
import BarChart from '../../../../visualisations/BarChart/BarChart';
import { HavocGroupDataProp } from '../HavocTable.jsx/HavocTable.propTypes';
import Tile from '../../../../components/Tile/Tile';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import SwitchVisTableButton from '../../../../components/buttons/SwitchVisTable/SwitchVisTable';
import {
  HAVOC_GAP_BARS_SIZE_LIMITS,
  HAVOC_SCM_KEYS,
  HAVOC_SPT_KEYS,
  HAVOC_SUMMARY_COLORING_MDOES,
  HAVOC_SUMMARY_PERCENTAGE_TYPES,
} from './HavocBars.constants';
import { HAVOC_TABLE_AGGREGATION_MODE_TYPE } from '../HavocTable.jsx/HavocTable.constants';
import { getBarData } from './HavocBars.dataManipulation';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const HavocBarTile = ({
  isLoadingSummary,
  handleVisTableSwitch,
  chartId,
  havocData,
  playSummary,
  selectedDefenderZones,
  havocSummaryMode,
  colorMode,
}) => {
  const [displayKey, setDisplayKey] = useState(true);
  const [chartHolderWidth, setChartHolderWidth] = useState(500);
  const { isDark } = useTheme();
  const percentageSetup = HAVOC_SUMMARY_PERCENTAGE_TYPES[havocSummaryMode];

  /* Currently this is snap gap, but will need to change to exploited gap when possible */
  const aggregationMode = HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP;

  const windowWidth = useWindowWidth();
  useEffect(() => {
    const elem = document.querySelector(`#${chartId}`);
    const rect = elem?.getBoundingClientRect();
    if (rect?.width) {
      setChartHolderWidth(rect.width);
    }
  }, [windowWidth]);

  const { barData, unfilteredBarData } = getBarData({
    havocData,
    playSummary,
    selectedDefenderZones,
    aggregationMode,
    percentageSetup,
    colorMode,
    isDark,
  });

  /* Modifications to standard bar chart for this bar chart */
  const viewboxWidth = clamp(
    chartHolderWidth,
    HAVOC_GAP_BARS_SIZE_LIMITS.MIN_VIEWBOX_WIDTH,
    HAVOC_GAP_BARS_SIZE_LIMITS.MAX_VIEWBOX_WIDTH
  );
  const canvasWidth =
    viewboxWidth -
    HAVOC_GAP_BARS_SIZE_LIMITS.PADDING -
    HAVOC_GAP_BARS_SIZE_LIMITS.Y_AXIS_WIDTH;
  const layoutOverride = {
    CANVAS: { HEIGHT: 200, WIDTH: canvasWidth },
    AXES: {
      GUIDES: {
        VERTICAL: null,
      },
      AREA: { top: 0, right: 0, bottom: 30, left: 50 },
    },
  };
  const yOverrides = {
    DOMAIN: percentageSetup.gapDomain,
    NICE: true,
    TICKS: 9,
    INVERT: true,
  };
  const setupOverride = {
    Y: yOverrides,
    X: { PADDING_INNER: 0.2 },
  };

  return (
    <Tile border="0" margin="0 0 0.8rem 0">
      <Tile.Header>
        <h2 title="Visualisation displaying havoc play aggregates">
          Havoc Plays Summary
        </h2>
        <div className="buttons">
          <SwitchVisTableButton
            isShowingVis
            handleClick={handleVisTableSwitch}
          />
          <KeyButton
            showKey={displayKey}
            handleShowKey={() => setDisplayKey(!displayKey)}
          />
        </div>
      </Tile.Header>
      <Tile.Body>
        <div>
          <BarChart
            chartId={chartId}
            barData={barData}
            barDataGhost={unfilteredBarData}
            layoutOverride={layoutOverride}
            setupOverride={setupOverride}
          />
        </div>
        {isLoadingSummary && (
          <Dimmer
            active
            style={{
              maxWidth: '100%',
            }}
          >
            <Loader content="Loading Data" />
          </Dimmer>
        )}
      </Tile.Body>
    </Tile>
  );
};

HavocBarTile.propTypes = {
  isLoadingSummary: PropTypes.bool,
  handleVisTableSwitch: PropTypes.func,
  chartId: PropTypes.string,
  havocData: HavocGroupDataProp,
  playSummary: PropTypes.shape({
    playCount: PropTypes.number,
    runRate: PropTypes.number,
    passPlays: PropTypes.number,
    runPlays: PropTypes.number,
  }),
  selectedDefenderZones: PropTypes.arrayOf(PropTypes.string),
  // leagueAverageBarData: HavocGroupDataProp,
  havocSummaryMode: PropTypes.oneOf(HAVOC_SPT_KEYS),
  colorMode: PropTypes.oneOf(HAVOC_SCM_KEYS),
};

HavocBarTile.defaultProps = {
  isLoadingSummary: false,
  handleVisTableSwitch: null,
  chartId: 'run-point-of-attack-bars',
  havocData: undefined,
  playSummary: { playCount: 0, runRate: 0.5, passPlays: 0, runPlays: 0 },
  selectedDefenderZones: undefined,
  // leagueAverageBarData: undefined,
  havocSummaryMode: HAVOC_SUMMARY_PERCENTAGE_TYPES.OF_ALL_PLAYS.value,
  colorMode: HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value,
};

export default HavocBarTile;
