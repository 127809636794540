import { POSITION_COLOR_ATTRIBUTE_MAIN } from '../visualisations/visPalettes';
import {
  ALIGNMENT_POSITIONS,
  ROSTER_POSITIONS,
  ROSTER_POSITION_RESERVE,
} from '../constants/positions';
import { API_ROSTER_POSITION_KEYS } from '../constants/api';

/* 
This function gets the best match roster position
It uses the same fallback logic as the db for players lacking a suitable position
    or with a position inappropriate to their current role (e.g. a RB on defense)
https://www.notion.so/statsbomb/Personnel-Packages-50ea03a971164c45be71979d0155e842?pvs=4#67cae9dfc87d466d8de8dfc564684eed
*/
export const getRosterPositionInfo = function (
  apiRosterPositionKey,
  onOffense
) {
  const rosterPosition = ROSTER_POSITIONS[apiRosterPositionKey];
  if (onOffense === rosterPosition?.isOffense) {
    return rosterPosition;
  }
  if (rosterPosition) {
    /* This means a real position declared, but it's offensive on defense, or vice versa */
    if (apiRosterPositionKey === API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN) {
      return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN];
    }
    if (
      apiRosterPositionKey === API_ROSTER_POSITION_KEYS.TIGHT_END ||
      apiRosterPositionKey === API_ROSTER_POSITION_KEYS.WIDE_RECEIVER
    ) {
      return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.LINEBACKER];
    }
    if (!onOffense) {
      return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK];
    }
    if (apiRosterPositionKey === API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN) {
      return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN];
    }
    if (apiRosterPositionKey === API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK) {
      return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.RUNNING_BACK];
    }
    return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER];
  }
  /* No position given (blank or was "reserve")  */
  if (onOffense) {
    return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER];
  }
  return ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK];
};

/*
This allows selection of a roster position by supplying the 2 digit code
    rather than the API position key. 
*/
export const getRosterPositionByCode = function (rosterPositionCode) {
  const rosterPosition = Object.values(ROSTER_POSITIONS).find(
    (pos) => pos.code === rosterPositionCode
  );
  if (rosterPosition) {
    return rosterPosition;
  }
  return ROSTER_POSITION_RESERVE[API_ROSTER_POSITION_KEYS.RESERVE];
};

/*
Function with protection against missing information for extracting color from position
Defaults to main color, but can be used to select jersey/border attributes if required

Two utility functions support this for obtaining a rosterPosition if only the key/code is known
*/
export const getRosterPositionColor = function (
  rosterPosition,
  visPalette,
  colorAttribute = POSITION_COLOR_ATTRIBUTE_MAIN
) {
  if (rosterPosition) {
    const colorObj = rosterPosition.color(visPalette);
    return colorObj[colorAttribute];
  }
  /* Fallback to reserve (neutral gray) if position invalid */
  return visPalette.positions.reserve.r[colorAttribute];
};
export const getRosterPositionColorByAPIKey = function (
  apiRosterPositionKey,
  visPalette,
  colorAttribute = POSITION_COLOR_ATTRIBUTE_MAIN
) {
  const rosterPosition = ROSTER_POSITIONS[apiRosterPositionKey];
  return getRosterPositionColor(rosterPosition, visPalette, colorAttribute);
};
export const getRosterPositionColorByCode = function (
  rosterPositionCode,
  visPalette,
  colorAttribute = POSITION_COLOR_ATTRIBUTE_MAIN
) {
  const rosterPosition = getRosterPositionByCode(rosterPositionCode);
  return getRosterPositionColor(rosterPosition, visPalette, colorAttribute);
};

/*
This allows selection of a roster position by supplying the 2 digit code
    rather than the API position key. 
*/
export const getAlignmentPositionByCode = function (alignmentPositionCode) {
  const alignmentPosition = Object.values(ALIGNMENT_POSITIONS).find(
    (pos) => pos.code === alignmentPositionCode
  );
  return alignmentPosition;
};

/*
Takes any position object (ROSTER_POSITION or ALIGNMENT_POSITION) and the default radius
  returns the shape drawn and scaled such that shapes from all positions will have similar visual weight
*/
export const getPositionShape = function (radius, position) {
  return position.shape(radius * position.radiusScale);
};
