import { uniq } from 'lodash';
import {
  API_ALIGNMENT_POSITION_KEYS,
  API_ROSTER_POSITION_KEYS,
} from '../../utils/constants/api';

export const ALL_POSITION_CODES = uniq(
  Object.values(API_ROSTER_POSITION_KEYS).concat(
    Object.values(API_ALIGNMENT_POSITION_KEYS)
  )
);
