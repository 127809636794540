/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from '@statsbomb/kitbag-components';
import {
  StyledAccordionTableBody,
  AccordionTableButton,
  ExpandMoreButton,
  NumberIndicator,
} from './AccordionTableBody.styles';

const AccordionTableBody = ({
  label,
  amount,
  headers,
  rows,
  visibility,
  tableIndex,
  handleDataVisibility,
  expandButtonSuffix,
  expandButtonCondition,
}) => {
  const isOpen = visibility === 'partial' || visibility === 'open';
  const isFullyOpen = visibility === 'open';

  const handleOpen = () => {
    const display = isOpen ? 'closed' : 'partial';
    handleDataVisibility(tableIndex, display);
  };

  const handleMore = () => {
    const display = isFullyOpen ? 'partial' : 'open';
    handleDataVisibility(tableIndex, display);
  };

  const getCellAlignment = (index) => (index > 0 ? 'right' : 'left');
  const colSpan = 3;
  const fillRowColSpan = headers.length - colSpan;
  const hasExpandButton = expandButtonCondition && (isOpen || isFullyOpen);

  return (
    <StyledAccordionTableBody
      $hasExpandButton={hasExpandButton}
      $isOpen={isOpen}
    >
      <Table.Row onClick={handleOpen}>
        <Table.DataCell colSpan={colSpan}>
          <AccordionTableButton $isOpen={isOpen}>
            <div>{label}</div>
            <NumberIndicator>{amount}</NumberIndicator>
            <Icon variant="ChevronDown" size="small" />
          </AccordionTableButton>
        </Table.DataCell>
        <Table.DataCell colSpan={fillRowColSpan} />
      </Table.Row>
      <>
        {rows.map((row, rowIndex) => (
          <Table.Row key={`${label}-table-${tableIndex}-row-${rowIndex}`}>
            {Object.values(row).map((cell, cellIndex) => (
              <Table.DataCell
                key={`${label}-table-${tableIndex}-row-${rowIndex}-cell-${cellIndex}`}
                $size="regular"
                $textAlign={getCellAlignment(cellIndex)}
                style={{ backgroundColor: cell?.color, color: cell?.ink }}
                title={cell?.value}
              >
                {cell?.label}
              </Table.DataCell>
            ))}
          </Table.Row>
        ))}
        {hasExpandButton && (
          <Table.Row onClick={handleMore}>
            <Table.DataCell $size="regular" colSpan={colSpan}>
              <ExpandMoreButton>
                <Icon
                  variant={isFullyOpen ? 'Collapse' : 'Expand'}
                  size="small"
                />
                <div>{`Show ${
                  isFullyOpen ? 'top' : 'all'
                } ${expandButtonSuffix}`}</div>
              </ExpandMoreButton>
            </Table.DataCell>
            <Table.DataCell colSpan={fillRowColSpan} />
          </Table.Row>
        )}
      </>
    </StyledAccordionTableBody>
  );
};

AccordionTableBody.propTypes = {
  // The title of the table
  label: PropTypes.string.isRequired,
  // the number of data points that fall under this table (does not necessarily correspond to rows.length)
  amount: PropTypes.number.isRequired,
  // The headers of the table
  headers: PropTypes.arrayOf(PropTypes.object),
  // table rows
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  // the index of the accordion section the table is in
  tableIndex: PropTypes.number.isRequired,
  // callback function to manage what data should be visible in the chart
  handleDataVisibility: PropTypes.func.isRequired,
  // additional context about what the "expand more" button is controlling
  expandButtonSuffix: PropTypes.string,
  // custom condition to determine when to display the expand button
  expandButtonCondition: PropTypes.bool,
  // string value to determine the visibility state of the table
  visibility: PropTypes.oneOf(['closed', 'partial', 'open']),
};

AccordionTableBody.defaultProps = {
  headers: [],
  expandButtonSuffix: '',
  expandButtonCondition: false,
  visibility: 'closed',
};

export default AccordionTableBody;
