import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonIcon,
  Checkbox,
  Icon,
  Modal,
} from '@statsbomb/kitbag-components';
import {
  FormationDetailFilterContainer,
  FormationDetailFilterFooter,
  FormationDetailFilterHeader,
  ModalTitle,
  FilterSection,
} from './FormationDetailFilter.styles';
import { Controls } from '../TeamFormationSummary/TeamFormationSummary.styles';
import PositionIcon from '../../../components/PositionIcon/PositionIcon';
import {
  PLAYERS_FILTER_INFO,
  PLAYERS_FILTER_TITLE,
  TRAITS_FILTER_INFO,
  TRAITS_FILTER_TITLE,
} from './FormationDetailFilter.constants';

const FormationDetailFilter = ({
  isTraitsFilter,
  isOpen,
  onConfirm,
  onCancel,
  sections,
}) => {
  const title = isTraitsFilter ? TRAITS_FILTER_TITLE : PLAYERS_FILTER_TITLE;
  const info = isTraitsFilter ? TRAITS_FILTER_INFO : PLAYERS_FILTER_INFO;

  return (
    <FormationDetailFilterContainer>
      <Modal
        id="formation-detail-trait-filter"
        title="Trait Filter"
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelLabel="Cancel"
        confirmLabel="Apply Filters"
      >
        <FormationDetailFilterHeader>
          <ModalTitle>
            <h2>{title}</h2>
            <span title={info}>
              <Icon variant="Info" size="small" />
            </span>
          </ModalTitle>
          <Controls>
            <Button size="small" variant="ghost" onClick={() => {}}>
              Clear all
            </Button>
            <ButtonIcon
              onClick={() => {}}
              variant="ghost"
              size="small"
              icon="Close"
              title="close modal"
            >
              close modal
            </ButtonIcon>
          </Controls>
        </FormationDetailFilterHeader>
        {sections.map((section, index) => (
          <div key={section.title}>
            <FilterSection>
              <header>
                <div>
                  {section.position && (
                    <PositionIcon
                      alignmentPosition={section.position}
                      letter={section.letter}
                    />
                  )}
                  <h3>{section.title}</h3>
                </div>
                <Button size="small" variant="ghost" onClick={() => {}}>
                  Clear section
                </Button>
              </header>
              <div className="options">
                {section.options.map((option) => (
                  <Checkbox
                    key={option.label}
                    id={option.label}
                    size="small"
                    label={option.label}
                    checked={option.isSelected}
                    onChange={() => {}}
                    disabled={option.isDisabled}
                  />
                ))}
              </div>
            </FilterSection>
            {index !== sections.length - 1 && <hr />}
          </div>
        ))}
        <FormationDetailFilterFooter>
          <Button size="small" variant="ghost" onClick={() => {}}>
            Cancel
          </Button>
          <Button size="small" onClick={() => {}}>
            Apply Filters
          </Button>
        </FormationDetailFilterFooter>
      </Modal>
    </FormationDetailFilterContainer>
  );
};

FormationDetailFilter.propTypes = {
  // whether the dialog is open
  isOpen: PropTypes.bool,
  // callback function for when the Clear all button is clicked
  onCancel: PropTypes.func.isRequired,
  // callback function for when the Apply Filters button is clicked
  onConfirm: PropTypes.func.isRequired,
  // the list of sections and their state to display in the modal
  sections: PropTypes.arrayOf(PropTypes.object),
  // whether this modal is a traits filter or a player filter
  isTraitsFilter: PropTypes.bool,
};

FormationDetailFilter.defaultProps = {
  isOpen: false,
  sections: [],
  isTraitsFilter: false,
};

export default FormationDetailFilter;
