import {
  ROTATIONS,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import { csIntensity } from '../../utils/helpers/colorScales';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import {
  drawDistributionArea,
  drawDistributionLine,
  formatDataForCurve,
} from '../../utils/visualisations/distribution';
import { normalizeLAData } from '../../utils/visualisations/histogram';
import {
  drawCircle,
  drawPentagon,
  drawSquare,
} from '../../utils/visualisations/shapes';
import { appendText } from '../text';
import {
  TACKLE_LOCATION_CLASSES,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLE_LOCATION_VIOLINS,
  TACKLING_KEY_METRICS,
} from './TackleLocation.constants';

const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;

const drawTackleAttempts = (
  svgG,
  tackleData,
  setSelectedPlay,
  isOverlay,
  visPalette,
  isInteractive
) => {
  const tackleOriginG = svgG.append('g');
  tackleOriginG
    .selectAll('circle')
    .data(tackleData)
    .enter()
    .append('circle')
    .attr('cx', (d) => d.xFirstTackleAttempt)
    .attr('cy', (d) => d.yFirstTackleAttempt)
    .attr('r', (d) => d.r)
    .attr('stroke', 'none')
    .attr('fill', (d) => (isOverlay ? visPalette.selectedObject : d.color))
    .attr('opacity', isOverlay ? 0.25 : 1)
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => isInteractive && setSelectedPlay(d.play_uuid));
};

const drawBallToGrounds = (
  svgG,
  tackleData,
  setSelectedPlay,
  isOverlay,
  visPalette,
  isInteractive
) => {
  const finalBallLocationG = svgG.append('g');
  finalBallLocationG
    .selectAll('circle')
    .data(tackleData)
    .enter()
    .append('circle')
    .attr('cx', (d) => d.xBallToGround)
    .attr('cy', (d) => d.yBallToGround)
    .attr('r', (d) => d.r)
    .attr('stroke', 'none')
    .attr('fill', (d) => (isOverlay ? visPalette.selectedObject : d.color))
    .attr('opacity', isOverlay ? 0.25 : 1)
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => isInteractive && setSelectedPlay(d.play_uuid));
};

const drawTadpoles = (svgG, tackleData, setSelectedPlay, isInteractive) => {
  const finalBallLocationG = svgG.append('g');
  const pathG = svgG.append('g');
  finalBallLocationG
    .selectAll('circle')
    .data(tackleData)
    .enter()
    .append('circle')
    .attr('cx', (d) => d.xBallToGround)
    .attr('cy', (d) => d.yBallToGround)
    .attr('r', (d) => d.r)
    .attr('stroke', 'none')
    .attr('fill', (d) => d.color)
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => isInteractive && setSelectedPlay(d.play_uuid));
  pathG
    .selectAll('line')
    .data(tackleData)
    .enter()
    .append('line')
    .attr('x1', (d) => d.xFirstTackleAttempt)
    .attr('y1', (d) => d.yFirstTackleAttempt)
    .attr('x2', (d) => d.xBallToGround)
    .attr('y2', (d) => d.yBallToGround)
    .attr('stroke', (d) => d.color)
    .attr('stroke-width', 0.5)
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => isInteractive && setSelectedPlay(d.play_uuid));
};

const getYAlignmentEquivalent = function (alignment) {
  if (alignment === 'start') {
    return 7;
  }
  if (alignment === 'end') {
    return 0;
  }
  return 3;
};
const drawMeanLine = (
  midpointG,
  orientation,
  fieldArea,
  visPalette,
  mean,
  lineColor,
  textAlign,
  isMirrored,
  textColor = lineColor
) => {
  let meanFrac = (mean + fieldArea.fieldLoS) / fieldArea.fieldXYds;
  if (orientation === ROTATIONS.VERTICAL_UP) {
    /* Because still drawing in SVG orientation need the other edge of each box in inverted orientation */
    meanFrac = 1 - meanFrac;
  }
  const meanX = meanFrac * fieldArea.fieldSizeX;

  const xMin =
    orientation === ROTATIONS.HORIZONTAL ? -TACKLE_LOCATION_VIOLINS.WIDTH : 0;
  const xMax =
    isMirrored || orientation !== ROTATIONS.HORIZONTAL
      ? TACKLE_LOCATION_VIOLINS.WIDTH
      : 0;
  midpointG
    .append('line')
    .attr('x1', orientation === ROTATIONS.HORIZONTAL ? meanX : xMin)
    .attr('x2', orientation === ROTATIONS.HORIZONTAL ? meanX : xMax)
    .attr('y1', orientation !== ROTATIONS.HORIZONTAL ? meanX : xMin)
    .attr('y2', orientation !== ROTATIONS.HORIZONTAL ? meanX : xMax)
    .attr('stroke', lineColor)
    .attr('stroke-dasharray', '2 2');
  if (orientation === ROTATIONS.HORIZONTAL) {
    appendText(midpointG, visPalette, {
      message: mean.toFixed(1),
      x: meanX,
      y: isMirrored ? TACKLE_LOCATION_VIOLINS.WIDTH + 7 : 10,
      textAnchor: textAlign,
      fontSize: 8,
      fill: textColor,
    });
  } else {
    const yAdj = getYAlignmentEquivalent(textAlign);
    appendText(midpointG, visPalette, {
      message: mean.toFixed(1),
      x: TACKLE_LOCATION_VIOLINS.WIDTH + 2,
      y: meanX + yAdj,
      fontSize: 8,
      fill: textColor,
    });
  }
};

const drawMeans = (
  firstTackleMidpointG,
  finalBallMidpointG,
  deltaYardsMidpointG,
  orientation,
  fieldArea,
  visPalette,
  means,
  isMirrored
) => {
  const meanColor = visPalette.objects.n1.main;
  const laColor = visPalette.objects.n2.main;
  const ftAlign =
    means.firstTackle > means.firstTackleLeagueAverage ===
    (orientation !== ROTATIONS.VERTICAL_UP)
      ? 'start'
      : 'end';
  const ftLAAlign = ftAlign === 'start' ? 'end' : 'start';
  drawMeanLine(
    firstTackleMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.firstTackle,
    meanColor,
    ftAlign,
    isMirrored
  );
  drawMeanLine(
    firstTackleMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.firstTackleLeagueAverage,
    laColor,
    ftLAAlign,
    isMirrored
  );

  const flAlign =
    means.finalLocation > means.finalLocationLeagueAverage ===
    (orientation !== ROTATIONS.VERTICAL_UP)
      ? 'start'
      : 'end';
  const flLAAlign = flAlign === 'start' ? 'end' : 'start';
  drawMeanLine(
    finalBallMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.finalLocation,
    meanColor,
    flAlign,
    isMirrored
  );
  drawMeanLine(
    finalBallMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.finalLocationLeagueAverage,
    laColor,
    flLAAlign,
    isMirrored
  );

  const ygAlign =
    means.yardsGained > means.yardsGainedLeagueAverage ===
    (orientation !== ROTATIONS.VERTICAL_UP)
      ? 'start'
      : 'end';
  const ygLAAlign = ygAlign === 'start' ? 'end' : 'start';
  drawMeanLine(
    deltaYardsMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.yardsGained,
    meanColor,
    ygAlign,
    isMirrored
  );
  drawMeanLine(
    deltaYardsMidpointG,
    orientation,
    fieldArea,
    visPalette,
    means.yardsGainedLeagueAverage,
    laColor,
    ygLAAlign,
    isMirrored
  );
};

const drawQuartileValues = (
  firstTackleMidpointG,
  finalBallMidpointG,
  deltaYardsMidpointG,
  orientation,
  fieldArea,
  visPalette,
  firstTackleQuartiles,
  finalBallQuartiles,
  deltaYardsQuartiles
) => {
  const q25Color = csIntensity(0.375);
  const q50Color = csIntensity(0.625);
  const q75Color = csIntensity(1);
  drawMeanLine(
    firstTackleMidpointG,
    orientation,
    fieldArea,
    visPalette,
    firstTackleQuartiles.p25,
    q25Color,
    firstTackleQuartiles.p50 - firstTackleQuartiles.p25 < 1 ? 'end' : 'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    firstTackleMidpointG,
    orientation,
    fieldArea,
    visPalette,
    firstTackleQuartiles.p50,
    q50Color,
    'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    firstTackleMidpointG,
    orientation,
    fieldArea,
    visPalette,
    firstTackleQuartiles.p75,
    q75Color,
    firstTackleQuartiles.p75 - firstTackleQuartiles.p50 < 1
      ? 'start'
      : 'middle',
    false,
    visPalette.contrast
  );

  drawMeanLine(
    finalBallMidpointG,
    orientation,
    fieldArea,
    visPalette,
    finalBallQuartiles.p25,
    q25Color,
    finalBallQuartiles.p50 - finalBallQuartiles.p25 < 1 ? 'end' : 'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    finalBallMidpointG,
    orientation,
    fieldArea,
    visPalette,
    finalBallQuartiles.p50,
    q50Color,
    'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    finalBallMidpointG,
    orientation,
    fieldArea,
    visPalette,
    finalBallQuartiles.p75,
    q75Color,
    finalBallQuartiles.p75 - finalBallQuartiles.p50 < 1 ? 'start' : 'middle',
    false,
    visPalette.contrast
  );

  drawMeanLine(
    deltaYardsMidpointG,
    orientation,
    fieldArea,
    visPalette,
    deltaYardsQuartiles.p25,
    q25Color,
    deltaYardsQuartiles.p50 - deltaYardsQuartiles.p25 < 1 ? 'end' : 'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    deltaYardsMidpointG,
    orientation,
    fieldArea,
    visPalette,
    deltaYardsQuartiles.p50,
    q50Color,
    'middle',
    false,
    visPalette.contrast
  );
  drawMeanLine(
    deltaYardsMidpointG,
    orientation,
    fieldArea,
    visPalette,
    deltaYardsQuartiles.p75,
    q75Color,
    deltaYardsQuartiles.p75 - deltaYardsQuartiles.p50 < 1 ? 'start' : 'middle',
    false,
    visPalette.contrast
  );
};

const getRectDimensions = (val1, val2, fieldArea, orientation) => {
  const smallerValue =
    (val1 <= val2 && orientation !== ROTATIONS.VERTICAL_UP) ||
    (val1 > val2 && orientation === ROTATIONS.VERTICAL_UP)
      ? val1
      : val2;
  let valFrac = (smallerValue + fieldArea.fieldLoS) / fieldArea.fieldXYds;
  if (orientation === ROTATIONS.VERTICAL_UP) {
    /* Because still drawing in SVG orientation need the other edge of each box in inverted orientation */
    valFrac = 1 - valFrac;
  }
  const rectFieldX = valFrac * fieldArea.fieldSizeX;
  const rectWidth =
    (Math.abs(val1 - val2) / fieldArea.fieldXYds) * fieldArea.fieldSizeX;

  return { rectFieldX, rectWidth };
};

const drawDistroRect = (
  distroClippedG,
  orientation,
  rectX,
  rectWidth,
  rectHeight,
  fill
) => {
  distroClippedG
    .append('rect')
    .attr('x', orientation !== ROTATIONS.HORIZONTAL ? -rectHeight : rectX)
    .attr('y', orientation !== ROTATIONS.HORIZONTAL ? rectX : -rectHeight)
    .attr(
      'width',
      orientation !== ROTATIONS.HORIZONTAL ? rectHeight * 2 : rectWidth
    )
    .attr(
      'height',
      orientation !== ROTATIONS.HORIZONTAL ? rectWidth : rectHeight * 2
    )
    .attr('fill', fill)
    .attr('stroke', 'none');
};

const drawDifferenceRects = (
  midpointG,
  orientation,
  clipPathName,
  fieldArea,
  mean,
  meanLA,
  visPalette
) => {
  const distro1ClippedG = midpointG
    .append('g')
    .attr('clip-path', `url(#${clipPathName})`);

  // just more than half width for curving - it gets clipped
  const distroAreaWidth = TACKLE_LOCATION_VIOLINS.WIDTH * 1.2;

  /* Backing rect full size (clipeed appropriately) */
  drawDistroRect(
    distro1ClippedG,
    orientation,
    0,
    fieldArea.fieldSizeX,
    distroAreaWidth,
    visPalette.objects.neutral.main
  );

  /* Color in the difference between the means */
  const { rectFieldX, rectWidth } = getRectDimensions(
    mean,
    meanLA,
    fieldArea,
    orientation
  );
  const meanDifferenceColor =
    mean <= meanLA ? visPalette.objects.n2.main : visPalette.objects.n1.main;
  drawDistroRect(
    distro1ClippedG,
    orientation,
    rectFieldX,
    rectWidth,
    distroAreaWidth,
    meanDifferenceColor
  );
};

const drawQuartileRects = (
  midpointG,
  orientation,
  clipPathName,
  fieldArea,
  quartilesData
) => {
  const distro1ClippedG = midpointG
    .append('g')
    .attr('clip-path', `url(#${clipPathName})`);
  // just more than half width for curving - it gets clipped
  const distroAreaWidth = TACKLE_LOCATION_VIOLINS.WIDTH * 1.2;

  /* Backing rect full size (clipeed appropriately) */
  drawDistroRect(
    distro1ClippedG,
    orientation,
    0,
    fieldArea.fieldSizeX,
    distroAreaWidth,
    csIntensity(0)
  );

  const { rectFieldX: q2X, rectWidth: q2Width } = getRectDimensions(
    quartilesData.p25,
    quartilesData.p50,
    fieldArea,
    orientation
  );
  drawDistroRect(
    distro1ClippedG,
    orientation,
    q2X,
    q2Width,
    distroAreaWidth,
    csIntensity(0.333)
  );

  const { rectFieldX: q3X, rectWidth: q3Width } = getRectDimensions(
    quartilesData.p50,
    quartilesData.p75,
    fieldArea,
    orientation
  );
  drawDistroRect(
    distro1ClippedG,
    orientation,
    q3X,
    q3Width,
    distroAreaWidth,
    csIntensity(0.667)
  );

  const { rectFieldX: q4X, rectWidth: q4Width } = getRectDimensions(
    quartilesData.p75,
    quartilesData.p100,
    fieldArea,
    orientation
  );
  drawDistroRect(
    distro1ClippedG,
    orientation,
    q4X,
    q4Width,
    distroAreaWidth,
    csIntensity(1)
  );
};

const drawDistributionGuides = (
  guideLinesG,
  visPalette,
  fieldArea,
  distroArea,
  orientation
) => {
  const linesEveryYds = 5;
  const guideYds = [
    ...Array(fieldArea.fieldXYds / linesEveryYds - 1).keys(),
  ].map((n) => (n + 1) * linesEveryYds);
  const los =
    orientation === ROTATIONS.VERTICAL_UP
      ? fieldArea.fieldXYds - fieldArea.fieldLoS
      : fieldArea.fieldLoS;
  const lineName = (d) => {
    if (orientation === ROTATIONS.VERTICAL_UP) {
      return d === los ? 'LoS' : fieldArea.fieldXYds - fieldArea.fieldLoS - d;
    }
    return d === los ? 'LoS' : d - fieldArea.fieldLoS;
  };
  if (orientation !== ROTATIONS.HORIZONTAL) {
    guideLinesG
      .selectAll('line')
      .data(guideYds)
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', distroArea.width - TACKLE_LOCATION_VIOLINS.AXIS)
      .attr('y1', (d) => d * pxPerYard)
      .attr('y2', (d) => d * pxPerYard)
      .attr('stroke', visPalette.guides)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3');

    guideLinesG
      .selectAll('text')
      .data(guideYds)
      .enter()
      .append('text')
      .attr('x', -2)
      .attr(
        'y',
        (d) => d * pxPerYard + VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE * 0.4
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', 'end')
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
      .attr('fill', visPalette.text.label)
      .attr('opacity', (d) => (d % 10 === 0 ? 1 : 0))
      .text((d) => lineName(d));
  } else {
    guideLinesG
      .selectAll('line')
      .data(guideYds)
      .enter()
      .append('line')
      .attr('y1', -TACKLE_LOCATION_VIOLINS.WIDTH)
      .attr(
        'y2',
        distroArea.height -
          TACKLE_LOCATION_VIOLINS.AXIS -
          TACKLE_LOCATION_VIOLINS.WIDTH
      )
      .attr('x1', (d) => d * pxPerYard)
      .attr('x2', (d) => d * pxPerYard)
      .attr('stroke', (d) =>
        d === fieldArea.fieldLoS
          ? visPalette.commonComponents.lineOfScrimmage
          : visPalette.guides
      )
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3');
  }
};

const drawDistributions = (
  svg,
  chartId,
  distroData,
  dataLA,
  visPalette,
  orientation,
  fieldArea,
  fieldAxesPadding,
  distroArea,
  distroAreaColorMode,
  means
) => {
  const distrosG = svg.select(`.${TACKLE_LOCATION_CLASSES.DISTROS_AREA}`);
  const isVertical = orientation !== ROTATIONS.HORIZONTAL;

  const taDomain = [
    -fieldArea.fieldLoS,
    -fieldArea.fieldLoS + fieldArea.fieldXYds,
  ];

  const {
    firstTackleQuartiles,
    finalBallQuartiles,
    deltaYardsQuartiles,
    firstTackleData,
    finalBallData,
    deltaYardsData,
  } = distroData;

  /* League Average Prep */
  const firstTackleLeagueAverage = normalizeLAData(
    dataLA,
    taDomain,
    'first_ta_x_los_count'
  );
  const finalBallLeagueAverage = normalizeLAData(
    dataLA,
    taDomain,
    'final_x_los_count'
  );
  const deltaYardsLeagueAverage = normalizeLAData(
    dataLA,
    taDomain,
    'final_x_ta_count'
  );

  /* Distro Area */
  const midPointAdjust =
    orientation === ROTATIONS.HORIZONTAL ? TACKLE_LOCATION_VIOLINS.WIDTH : 0;
  const distroAreaG = distrosG
    .append('g')
    .attr(
      'transform',
      isVertical
        ? `translate(${TACKLE_LOCATION_VIOLINS.AXIS + midPointAdjust}, ${
            fieldAxesPadding.top
          })`
        : `translate(${fieldAxesPadding.left},${
            TACKLE_LOCATION_VIOLINS.AXIS + midPointAdjust
          })`
    );
  const guideLinesG = distroAreaG.append('g');

  drawDistributionGuides(
    guideLinesG,
    visPalette,
    fieldArea,
    distroArea,
    orientation
  );

  distroAreaG.selectAll('defs').remove();
  const distroDefs = distroAreaG.append('defs');
  const clipPathNames = {
    ta: `${chartId}-distro-ta-clip-path`,
    fl: `${chartId}-distro-fl-clip-path`,
    yg: `${chartId}-distro-yg-clip-path`,
    distroZone: `${chartId}-distro-zone-clip-path`,
  };
  const clipPathTA = distroDefs.append('clipPath').attr('id', clipPathNames.ta);
  const clipPathFL = distroDefs.append('clipPath').attr('id', clipPathNames.fl);
  const clipPathYG = distroDefs.append('clipPath').attr('id', clipPathNames.yg);
  const clipPathDistroZone = distroDefs
    .append('clipPath')
    .attr('id', clipPathNames.distroZone);
  clipPathDistroZone
    .append('rect')
    .attr('x', 0)
    .attr('y', -20)
    .attr(
      'width',
      TACKLE_LOCATION_VIOLINS.WIDTH + TACKLE_LOCATION_VIOLINS.SPACING * 2
    )
    .attr('height', fieldArea.fieldSizeX + 20);

  const distro2Adjust = distroArea.violinWidth + distroArea.violinSpacing;
  const distro3Adjust = (distroArea.violinWidth + distroArea.violinSpacing) * 2;

  const firstTackleMidpointG = distroAreaG
    .append('g')
    .attr('clip-path', `url(#${clipPathNames.distroZone})`);
  const deltaYardsMidpointG = distroAreaG
    .append('g')
    .attr(
      'transform',
      isVertical
        ? `translate(${distro2Adjust},0)`
        : `translate(0,${distro2Adjust})`
    );
  // .attr('clip-path', `url(#${clipPathNames.distroZone})`);
  const finalBallMidpointG = distroAreaG
    .append('g')
    .attr(
      'transform',
      isVertical
        ? `translate(${distro3Adjust},0)`
        : `translate(0,${distro3Adjust})`
    );
  // .attr('clip-path', `url(#${clipPathNames.distroZone})`);

  appendText(firstTackleMidpointG, visPalette, {
    message: TACKLING_KEY_METRICS.firstAttempt,
    y: isVertical ? 0 : -5 - TACKLE_LOCATION_VIOLINS.WIDTH,
  });
  appendText(deltaYardsMidpointG, visPalette, {
    message: TACKLING_KEY_METRICS.delta,
    y: isVertical ? 0 : -5 - TACKLE_LOCATION_VIOLINS.WIDTH,
  });
  appendText(finalBallMidpointG, visPalette, {
    message: TACKLING_KEY_METRICS.finalBall,
    y: isVertical ? 0 : -5 - TACKLE_LOCATION_VIOLINS.WIDTH,
  });

  const firstTackleCurveData = formatDataForCurve(
    firstTackleData,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionArea(
    clipPathTA,
    firstTackleCurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL
  );

  const deltaCurveData = formatDataForCurve(
    deltaYardsData,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionArea(
    clipPathYG,
    deltaCurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL
  );

  const finalBallCurveData = formatDataForCurve(
    finalBallData,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionArea(
    clipPathFL,
    finalBallCurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL
  );

  if (distroAreaColorMode === TACKLE_LOCATION_DISTRO_COLOR_WINNER.value) {
    drawDifferenceRects(
      firstTackleMidpointG,
      orientation,
      clipPathNames.ta,
      fieldArea,
      means.firstTackle,
      means.firstTackleLeagueAverage,
      visPalette
    );

    drawDifferenceRects(
      finalBallMidpointG,
      orientation,
      clipPathNames.fl,
      fieldArea,
      means.finalLocation,
      means.finalLocationLeagueAverage,
      visPalette
    );
    drawDifferenceRects(
      deltaYardsMidpointG,
      orientation,
      clipPathNames.yg,
      fieldArea,
      means.yardsGained,
      means.yardsGainedLeagueAverage,
      visPalette
    );

    drawMeans(
      firstTackleMidpointG,
      finalBallMidpointG,
      deltaYardsMidpointG,
      orientation,
      fieldArea,
      visPalette,
      means,
      false
    );
  } else {
    drawQuartileRects(
      firstTackleMidpointG,
      orientation,
      clipPathNames.ta,
      fieldArea,
      firstTackleQuartiles,
      visPalette
    );
    drawQuartileRects(
      finalBallMidpointG,
      orientation,
      clipPathNames.fl,
      fieldArea,
      finalBallQuartiles,
      visPalette
    );
    drawQuartileRects(
      deltaYardsMidpointG,
      orientation,
      clipPathNames.yg,
      fieldArea,
      deltaYardsQuartiles,
      visPalette
    );

    drawQuartileValues(
      firstTackleMidpointG,
      finalBallMidpointG,
      deltaYardsMidpointG,
      orientation,
      fieldArea,
      visPalette,
      firstTackleQuartiles,
      finalBallQuartiles,
      deltaYardsQuartiles
    );
  }

  const firstTackleLAG = firstTackleMidpointG.append('g');
  const deltaYardsLAG = deltaYardsMidpointG.append('g');
  const finalBallLAG = finalBallMidpointG.append('g');

  const firstTackleLACurveData = formatDataForCurve(
    firstTackleLeagueAverage,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionLine(
    firstTackleLAG,
    firstTackleLACurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL,
    false,
    { stroke: visPalette.objects.n2.main }
  );

  const deltaLACurveData = formatDataForCurve(
    deltaYardsLeagueAverage,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionLine(
    deltaYardsLAG,
    deltaLACurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL,
    false,
    { stroke: visPalette.objects.n2.main }
  );

  const finalBallLACurveData = formatDataForCurve(
    finalBallLeagueAverage,
    'iMidpointNormalized',
    'valNormalized'
  );
  drawDistributionLine(
    finalBallLAG,
    finalBallLACurveData,
    fieldArea.fieldSizeX,
    TACKLE_LOCATION_VIOLINS.WIDTH,
    orientation === ROTATIONS.VERTICAL_UP,
    orientation !== ROTATIONS.HORIZONTAL,
    false,
    { stroke: visPalette.objects.n2.main }
  );
};

export const drawTackleAttemptPaths = (
  svgG,
  tackleData,
  selectedPlayerId,
  selectedPlay,
  setSelectedPlay,
  visPalette,
  isInteractive
) => {
  const radius = 3.5;
  const tacklePathG = svgG.append('g');
  const tacklePathEndsG = svgG.append('g');
  const tackleShapesG = svgG.append('g');
  const selectedOpacity = (play_uuid) =>
    !selectedPlay || play_uuid === selectedPlay ? 1 : 0.2;
  tacklePathG
    .selectAll('path')
    .data(tackleData)
    .enter()
    .append('path')
    .attr('d', (d) => d.path)
    .attr('fill', 'none')
    .attr('stroke', visPalette.objects.neutral.main)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '1 2')
    .attr('opacity', (d) => selectedOpacity(d.play_uuid));
  tacklePathEndsG
    .selectAll('path')
    .data(tackleData)
    .enter()
    .append('path')
    .attr(
      'transform',
      (d) => `translate(${d.xBallToGround},${d.yBallToGround}) rotate(45)`
    )
    .attr('d', drawSquare(2))
    .attr('fill', visPalette.objects.neutral.main)
    .attr('stroke', 'none')
    .attr('opacity', (d) => selectedOpacity(d.play_uuid))
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => isInteractive && setSelectedPlay(d.play_uuid));

  const renderTackleAttemptShape = (shapesG) => {
    shapesG
      .selectAll('path')
      .data((d) => d.defenders)
      .enter()
      .append('path')
      .attr('transform', (d) => `translate(${d.x},${d.y}) rotate(-90)`)
      .attr('d', (d) => {
        const r =
          selectedPlayerId && selectedPlayerId !== d.playerId ? 2 : radius;
        return d.soloAttempt ? drawPentagon(r) : drawCircle(r);
      })
      .attr('fill', (d) => d.color)
      .attr('fill-opacity', (d) => d.fillOpacity)
      .attr('stroke', (d) => d.color)
      .attr('stroke-width', 1)
      .attr('opacity', (d) => selectedOpacity(d.play_uuid))
      .attr(
        'class',
        isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
      )
      .on('click', (_, d) => setSelectedPlay(d.play_uuid));
  };
  tackleShapesG
    .selectAll('g')
    .data(tackleData)
    .join(
      (enter) => {
        const newG = enter.append('g');
        renderTackleAttemptShape(newG);
        return newG;
      },
      (update) => update,
      (exit) => exit.remove()
    );
};

export {
  drawTadpoles,
  drawTackleAttempts,
  drawBallToGrounds,
  drawQuartileRects,
  drawMeans,
  drawDistributions,
};
