import { styled } from 'styled-components';

export const StyledDepthChartRadar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  color: ${({ theme }) => theme.colours.ink.primary.main};
`;

export const DepthChartRadarHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .jersey {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
    ${({ theme }) => theme.typography.bodySmall}
  }

  .name {
    ${({ theme }) => theme.typography.headingRegular}
  }

  a {
    margin-left: auto;
  }
`;

export const DepthChartRadarFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .count {
    ${({ theme }) => theme.typography.bodySmall}
  }

  .percentage {
    ${({ theme }) => theme.typography.headingSmall}
  }
`;

StyledDepthChartRadar.displayName = 'StyledDepthChartRadar';
DepthChartRadarHeader.displayName = 'DepthChartRadarHeader';
DepthChartRadarFooter.displayName = 'DepthChartRadarFooter';
