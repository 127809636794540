import { useQuery } from '@apollo/client';
import { GET_TEAM_STAT_CATEGORIES } from './getTeamStatCategories';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';

export const useTeamStatCategories = () => {
  const { data, loading, error } = useQuery(GET_TEAM_STAT_CATEGORIES, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_STAT_CATEGORIES, error);
    return { error, loading: false };
  }

  const categoryDefinitions = data.teamStatCategories;

  return { data: categoryDefinitions, loading: false };
};
