/* AMERICAN FOOTBALL FIELD CONSTANTS
Field layout is determined in places by the level of the competition
All values are specified in yards.
X is taken to be the length of the field end zone to end zone
Y is the width from (left) sideline to (right) sideline

0,0    EZ 10 20 30 40 50 40 30 20 10 EZ
   Home                                Opposition         
   _________________________________________     left field edge  (y = 0)
   ||  ||  |  |  |  |  |  |  |  |  |  ||  ||
   -----------------------------------------
   ||  ||  |  |  |  |  |  |  |  |  |  ||  ||
   -----------------------------------------
   ||  ||  |  |  |  |  |  |  |  |  |  ||  ||
   _________________________________________     right field edge (y = 53.333)
                                            120, 53.33*

However, normalised coordinates treat x=0 past the home end zone, 
    i.e. the field above would measure -10 to 110 yards (not 0 to 120)
*/

const COMPETITION_LEVEL = {
  NFL: 'NFL',
  NCAA: 'NCAA',
  HIGH_SCHOOL: 'HS',
};
/* 
Warning: High school pitch has different posts so this might eventually need tweaking 
  or if we start caring about Canada
*/
export const pitchCompetitionLevel = (competitionId) =>
  competitionId === 1409 ? COMPETITION_LEVEL.NFL : COMPETITION_LEVEL.NCAA;

/*
FIELD MEASURES
HASH_Y_ vaues are from y=0 to the furthest edge of the hash mark from the sideline
*/
const FIELD_MEASURES = {
  X_YARDS: 120,
  Y_YARDS: 53 + 1 / 3,
  HASH_MARK_WIDTH: 2 / 3,
  HASH_Y_NFL: 23 + 7 / 12,
  HASH_Y_NCAA: 20,
  HASH_Y_HS: 640 / 36,
  HASH_MARK_EDGE_SPACE: 1 / 3,
  GOAL_POST_WIDTH: 6 + 1 / 6,
  GOAL_POST_WIDTH_HS: 280 / 36,
  END_ZONE_WIDTH: 10,
  FIELD_NUMBERS_HEIGHT: 2, // Yards / (6')
  FIELD_NUMBERS_EDGE_NCAA: 7, // 7 to 9 yards in from the sideline
  FIELD_NUMBERS_EDGE_NFL: 12, // 12 to 14 yards in from the sideline
  FIELD_NUMBERS_WIDTH: 4 / 3, // yards wide (4') each
  FIELD_NUMBERS_WIDTH_SPACING: 1 / 3, // yds spacing from the center line (ten yard line)
  FIELD_NUMBERS_ARROW_SPACING_X: 1 / 6, // 6"
  FIELD_NUMBERS_ARROW_SPACING_Y: 15 / 36, // 15" further infield than numbers
  FIELD_NUMBERS_ARROW_X: 1, // approx (technically 36" is the hypotenuse not x value)
  FIELD_NUMBERS_ARROW_Y: 1 / 2, // 18", the difference
};

/*
The font for NCAA and NFL is not fixed, though NFL is often based on Century. 
Font choice notes: https://www.notion.so/statsbomb/Field-Font-Comparison-c0e66ebbdc63429e9ca8266abcc0934d

The size on field has to fill the boxes, and numbers in fonts come out smaller than the declared
  e.g. sans-serif, declared as 20px, the "0" will only be ~16px tall (because of accents and so forth)
  fieldNumberScaler is the factor that the font needs to scale up to the right size.
*/
// const FIELD_NUMBERS_FONT = {
//   fontFamily: 'sans-serif',
//   fieldNumberScaler: 1.4,
//   fontWeight: 'default',
// };
const FIELD_NUMBERS_FONT = {
  fontFamily: 'century',
  fieldNumberScaler: 1.5,
  fontWeight: '1000', // super bold
};

const getHashYFromCompLevel = function (competitionLevel) {
  let hashY;
  switch (competitionLevel) {
    case COMPETITION_LEVEL.HIGH_SCHOOL:
      hashY = FIELD_MEASURES.HASH_Y_HS;
      break;
    case COMPETITION_LEVEL.NCAA:
      hashY = FIELD_MEASURES.HASH_Y_NCAA;
      break;
    default:
      // NFL
      hashY = FIELD_MEASURES.HASH_Y_NFL;
  }
  return hashY;
};

const DEFAULT_FIELD_DRAWING_SETTINGS = {
  pxPerYard: 10,
  strokeWidthPx: 1,
  showGoalPosts: false,
  goalPostStrokeWidthPx: 2,
  competitionLevel: COMPETITION_LEVEL.NCAA,
  showFieldNumbers: true,
  fieldNumbersToFieldRight: false,
};

export {
  COMPETITION_LEVEL,
  FIELD_MEASURES,
  getHashYFromCompLevel,
  FIELD_NUMBERS_FONT,
  DEFAULT_FIELD_DRAWING_SETTINGS,
};
