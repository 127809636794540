import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { ButtonIcon, Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { mf_LeagueLevel } from '../../../apollo';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { toChartSpace } from '../../player/PlayerPassing/DataManipulation';
import {
  ROTATIONS,
  defaultFieldSettings,
} from '../../../utils/constants/charting';
import Tile from '../../../components/Tile/Tile';
import { GET_PASSES } from '../TeamPassing/getPasses';
import useQueryString from '../../../utils/hooks/useQueryString';
import { TileInlineLoader } from './TeamOverview.styles';
import { GET_PASSES_AGAINST } from '../TeamPassing/getPassesAgainst';
import PassingKey from '../../player/PlayerPassing/PassingKey/PassingKey';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { GRAPH_OPTIONS } from '../../../visualisations/PassingChart/PassingChart.constants';
import PassingChart from '../../../visualisations/PassingChart/PassingChart';

const TeamPassingTile = function ({ offense }) {
  const [graphOption, setGraphOption] = useQueryString(
    'passEndsGraph',
    'default'
  );

  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'passingKey',
    true
  );
  const showKey = displayChartKey && graphOption !== 'heatmap';
  const {
    loading: isLoadingFor,
    error: hasErrorFor,
    data: dataFor,
  } = useQuery(GET_PASSES, {
    skip: !offense,
  });
  const {
    loading: isLoadingAgainst,
    error: hasErrorAgainst,
    data: dataAgainst,
  } = useQuery(GET_PASSES_AGAINST, {
    skip: offense,
  });

  const tileHeight = 478; // the height of the grid row that this tile is in
  const apiData = offense
    ? dataFor?.passEvents?.edges?.map(({ node }) => node)
    : dataAgainst?.passEvents?.edges?.map(({ node }) => node);

  const formattedData =
    apiData &&
    apiData.map((m) => toChartSpace(m, defaultFieldSettings.pxPerYard));
  const competitionLevel = useReactiveVar(mf_LeagueLevel);

  const isLoading = offense ? isLoadingFor : isLoadingAgainst;
  const hasError = offense ? hasErrorFor : hasErrorAgainst;

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header style={{ maxHeight: '5.64rem' }} $border="2px">
        <h3>Passing Chart</h3>
        <div className="buttons">
          <DropdownWrapper>
            <Dropdown
              id="team-passing-tile-dropdown"
              components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon,
              }}
              isSearchable={false}
              menuAlignment="right"
              menuPlacement="bottom"
              onChange={(selectedOption) =>
                setGraphOption(selectedOption.value)
              }
              options={GRAPH_OPTIONS}
              value={GRAPH_OPTIONS.find((item) => item.value === graphOption)}
              labelPosition="none"
              label="Passing chart display options"
              variant="tertiary"
              size="small"
            />
          </DropdownWrapper>
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey || graphOption === 'heatmap'}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            href={`/team/passing/:leagues/:seasons/:teams/?graph=${graphOption}`}
            linkName="Open Team Passing Chart"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="1rem" $minHeight={`${tileHeight}px`}>
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        {formattedData?.length === 0 && (
          <TileDimmer>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {isLoading && <TileDimmer />}
        <div>
          <PassingChart
            data={formattedData}
            loading={isLoading}
            showInfo={false}
            displayPassPaths={graphOption === 'pass_paths'}
            displayHeatMap={graphOption === 'heatmap'}
            transparentBg
            competitionLevel={competitionLevel}
            isInteractive={false}
            orientation={ROTATIONS.HORIZONTAL}
          />
          {showKey && <PassingKey />}
        </div>
        {isLoading && (
          <TileInlineLoader>
            <Loader
              active
              inline
              size="small"
              content="Loading Team Passing Data"
            />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

TeamPassingTile.propTypes = {
  offense: PropTypes.bool,
};

TeamPassingTile.defaultProps = {
  offense: true,
};

export default TeamPassingTile;
