import { styled } from 'styled-components';

export const Aside = styled.aside`
  position: fixed;
  top: 3.25rem;
  height: calc(100vh - 3.5rem);
  z-index: 102;
  ${({ theme }) => theme.mqFrom('md')} {
    display: flex;
    height: inherit;
    box-shadow: ${({ theme }) => theme.effects.dropShadow.regular};
    > nav > ul {
      box-shadow: initial;
    }
  }

  a {
    color: inherit !important;
  }

  svg {
    max-width: 1.5rem;
  }

  .hamburger {
    border: 0;
    background: transparent;
    cursor: pointer;
    ${({ theme }) => theme.mqFrom('md')} {
      display: none;
    }

    &:hover {
      background: ${({ theme }) => theme.colours.brand.weak};
    }
  }

  nav {
    ul {
      width: 3.875rem;
    }
  }

  nav {
    ul {
      width: 3.875rem;
    }
  }
`;

export default Aside;
