import React from 'react';
import PropTypes from 'prop-types';
import { Button, Slider } from '@statsbomb/kitbag-components';
import { isFunction } from 'lodash';
import { METRIC_SLIDER_DEFAULTS } from '../ScoutResults.constants';
import { SliderDisplay, SliderWrapper } from './ReversibleSlider.styles';

/*
This component exists because the react components slider isn't good enough: 
  Can't format values
  Can't use ranges that don't start at 0
  Can't represent scales where smaller values are better

This component can be used for all the above cases.
It essentially takes the actual values for a range and value
  -> transposes these into a 0-and-up scale
  -> when the slider changes, it sends back the value returned to "actual space"
*/
const ReversibleSlider = ({
  min,
  max,
  value,
  onValueChanged,
  lowerIsBetter,
  formatter,
  steps,
  valueFormatter,
  onValueClick,
}) => {
  const range = Math.abs(max - min);
  const sliderMin = 0;
  const sliderMax = range;
  const sliderStep = range / steps;
  /* 
  Bigger is better value ignores direction ~ i.e. react slider logic 
  Slider value is "how many steps is this from range start
    same as difference of real value from max/min depending on direction"
  */
  const sliderValue = lowerIsBetter ? max - value : value - min;

  /* Displayed values should match user expectation */
  const displayMin = lowerIsBetter ? max : min;
  const displayMax = lowerIsBetter ? min : max;
  const stringFormatter = isFunction(formatter)
    ? formatter
    : (v) => v?.toString();
  const valueStringFormatter = isFunction(valueFormatter)
    ? valueFormatter
    : stringFormatter;

  /* Translate back into real space */
  const sliderValueChanged = (newValue) => {
    /* 
    newValue is a multiple of steps over 0
    for lower is better, thus want that many steps under the max, for 
      normal scale, that many steps over the min
    */
    const actualValue = lowerIsBetter ? max - newValue : newValue + min;
    onValueChanged(actualValue);
  };

  const formattedValue = valueStringFormatter(value);
  const valueDisplay = isFunction(onValueClick) ? (
    <Button size="tiny" onClick={onValueClick} variant="secondary">
      {formattedValue}
    </Button>
  ) : (
    <span>{formattedValue}</span>
  );

  return (
    <>
      <SliderWrapper>
        <Slider
          ariaLabel="layout-width-slider"
          min={sliderMin}
          max={sliderMax}
          step={sliderStep}
          title=""
          value={sliderValue}
          onChange={sliderValueChanged}
          reverse
          hideIndicators
        />
      </SliderWrapper>
      <SliderDisplay>
        <span>{stringFormatter(displayMin)}</span>
        {valueDisplay}
        <span>{stringFormatter(displayMax)}</span>
      </SliderDisplay>
    </>
  );
};

ReversibleSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  lowerIsBetter: PropTypes.bool,
  formatter: PropTypes.func,
  steps: PropTypes.number,
  valueFormatter: PropTypes.func,
  onValueClick: PropTypes.func,
};

ReversibleSlider.defaultProps = {
  lowerIsBetter: false,
  formatter: null,
  steps: METRIC_SLIDER_DEFAULTS.STEPS,
  valueFormatter: null,
  onValueClick: null,
};

export default ReversibleSlider;
