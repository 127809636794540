import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Dropdown } from '@statsbomb/kitbag-components';
import { GET_PLAYS } from './getPlays';
import {
  pf_TeamPlay,
  pf_Drive,
  mf_Games,
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Players,
} from '../../apollo';
import { replaceTokens } from '../../utils/helpers/general';
import { DROPDOWN_CONTAINER_WIDTH } from '../DropdownFilter/DropdownFilter.constants';
import { DropdownContainer } from '../DropdownFilter/DropdownFilter.styles';
import { byGameIndex, forPassRushPlays } from './Plays.constants';
import { isFalsyParam } from '../Layout/Layout.helper';

export const Plays = ({ onChange }) => {
  const navigate = useNavigate();
  const [pageInfo] = useOutletContext();
  const { plays: playsParam, drives: drivesParam } = useParams();
  const playId = useReactiveVar(pf_TeamPlay);

  const { loading, data } = useQuery(GET_PLAYS, {
    variables: {
      driveIds: [drivesParam],
    },
  });

  const playsDrop = data?.plays?.edges
    ?.filter(forPassRushPlays)
    .sort(byGameIndex)
    .map(({ node: { id, name } }) => ({
      label: name,
      value: id,
    }));

  if (playId !== playsParam) {
    pf_TeamPlay(playsParam);
  }
  const firstPlay = playsDrop?.length > 0 ? playsDrop[0]?.value : null;
  const selectedPlay =
    playsDrop?.find((option) => option.value === playsParam) || firstPlay;

  useEffect(() => {
    if (isFalsyParam(playsParam) && playsDrop?.length > 0) {
      const instantRV = {
        leagues: mf_Leagues(),
        players: mf_Players(),
        seasons: mf_Seasons(),
        teams: mf_Teams(),
        games: mf_Games(),
        drives: pf_Drive(),
        plays: selectedPlay,
      };

      navigate(
        `/${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: true }
      );
    }
  }, [data]);

  return (
    <DropdownContainer className={DROPDOWN_CONTAINER_WIDTH.EXTRA_WIDE}>
      <Dropdown
        id="plays-dsropdown"
        options={playsDrop}
        label="Plays"
        onChange={onChange}
        value={selectedPlay}
        isLoading={loading}
      />
    </DropdownContainer>
  );
};

Plays.propTypes = {
  onChange: PropTypes.func,
};

Plays.defaultProps = {
  onChange: () => {},
};
