import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { select } from 'd3';
import { useD3 } from '../../../utils/hooks/useD3';
import {
  HAVOC_KEY_CLASSES,
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_VIS_SIZES,
} from '../HavocChart.constants';
import { addPlayerColor } from '../HavocChart.dataManipulation';
import { drawHavocKey } from './HavocChartKey.drawing';
import {
  HAVOC_KEY_VERTICAL_HEIGHT,
  HAVOC_KEY_VERTICAL_WIDTH,
  HAVOC_KEY_WIDTH,
} from './HavocChartKey.constants';

const HavocChartKey = ({
  colorMode,
  players,
  positions,
  isFixedSize,
  vertical,
}) => {
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };

  const fullVisWidth = vertical ? HAVOC_KEY_VERTICAL_WIDTH : HAVOC_KEY_WIDTH;
  const fullVisHeight = vertical
    ? HAVOC_KEY_VERTICAL_HEIGHT
    : HAVOC_VIS_SIZES.KEY_HEIGHT;
  const vbWidth = margin.left + margin.right + fullVisWidth;
  const viewBox = `0 0 ${vbWidth} ${
    margin.top + margin.bottom + fullVisHeight
  }`;
  const svgWidth = isFixedSize ? vbWidth : '100%';
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;

  const ref = useD3((svg) => {
    svg.attr('viewBox', viewBox);
    svg.attr('width', svgWidth);
    svg.selectAll('*').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', visPalette.background.main);

    const marginTransform = `translate(${margin.left},${margin.top})`;
    svg
      .append('g')
      .attr('transform', marginTransform)
      .attr('class', HAVOC_KEY_CLASSES.IN_MARGINS);

    /* Primary G just sits in the margins */
    const withinMarginsG = svg.select(`.${HAVOC_KEY_CLASSES.IN_MARGINS}`);
    withinMarginsG.selectAll('g').remove();
    withinMarginsG.selectAll('rect').remove();

    withinMarginsG.append('g').attr('class', HAVOC_KEY_CLASSES.MAIN);
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    const playersWithColors = addPlayerColor(players, visPalette);
    svg.attr('viewBox', viewBox);
    svg.attr('width', svgWidth);

    const keyG = svg.select(`.${HAVOC_KEY_CLASSES.MAIN}`);
    keyG.selectAll('g').remove();
    drawHavocKey(
      keyG,
      visPalette,
      isDark,
      colorMode,
      playersWithColors,
      vertical ? HAVOC_KEY_VERTICAL_WIDTH : HAVOC_KEY_WIDTH,
      positions,
      vertical
    );
  }, [colorMode, visPalette, players, vertical]);

  return <svg ref={ref} />;
};

HavocChartKey.propTypes = {
  colorMode: PropTypes.string,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.number,
      playerName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      positionCode: PropTypes.string,
      positionName: PropTypes.string,
      color: PropTypes.func,
      freq: PropTypes.number,
    })
  ),
  // in export mode, fix the size of the key independent of the size of the vis
  isFixedSize: PropTypes.bool,
  // render the key in vertical or horizontal orientation
  vertical: PropTypes.bool,
};

HavocChartKey.defaultProps = {
  colorMode: HAVOC_COLOR_MODE_LOCATIONS.value,
  players: null,
  positions: null,
  isFixedSize: false,
  vertical: false,
};

export default HavocChartKey;
