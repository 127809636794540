import { isEmpty, isString } from 'lodash';
import { INVALID_NUMBER_VALIDATION_TYPES } from './NumericInput.constants';

export const isStringValidNumber = (typedValue, min, max) => {
  if (!isString(typedValue)) {
    return {
      valid: false,
      message: INVALID_NUMBER_VALIDATION_TYPES.BLANK,
    };
  }

  const numericRegEx = /^[+-]?\d+\.?\d*/;
  const regExMatches = typedValue.match(numericRegEx);
  if (isEmpty(regExMatches) || regExMatches[0] !== typedValue) {
    return {
      valid: false,
      message: INVALID_NUMBER_VALIDATION_TYPES.NON_NUMERIC_CHARACTERS,
    };
  }

  try {
    const numericValue = parseFloat(typedValue);

    if (numericValue < min) {
      return {
        valid: false,
        message: INVALID_NUMBER_VALIDATION_TYPES.TOO_SMALL,
      };
    }
    if (numericValue > max) {
      return {
        valid: false,
        message: INVALID_NUMBER_VALIDATION_TYPES.TOO_BIG,
      };
    }

    return {
      valid: true,
      numericValue,
    };
  } catch (error) {
    return {
      valid: false,
      message: INVALID_NUMBER_VALIDATION_TYPES.NOT_A_NUMBER,
    };
  }
};
