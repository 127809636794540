import React, { useEffect } from 'react';
import { select } from 'd3';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useD3 } from '../../../../utils/hooks/useD3';
import {
  PASSING_KEY_CLASS_NAMES,
  PASSING_KEY_SIZE,
} from './PassingKey.constants';
import {
  drawColorSection,
  drawFillSection,
  drawHeatmapSection,
} from './PassingKey.drawing';
import {
  COLORING_OPTION_NONE,
  COLORING_OPTION_PASS_SUCCESS,
} from '../../../../visualisations/PassingChart/PassingChart.constants';
import { PASSER_SHAPE } from '../DataManipulation';
import {
  getColorDotsSetup,
  getColorSectionHeight,
  getFillDotsSetup,
  getPlayerColors,
} from './PassingKey.dataManipulation';
import { PassingKeySVG } from './PassingKey.styles';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const PassingKey = ({
  chartId,
  coloringOption,
  displayHeatMap,
  passers,
  targets,
}) => {
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;
  const keyColoringOption = displayHeatMap
    ? COLORING_OPTION_NONE.value
    : coloringOption;

  const heightHeatmap = displayHeatMap
    ? PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL +
      PASSING_KEY_SIZE.HEIGHT.HEATMAP_ROW +
      PASSING_KEY_SIZE.PADDING.SECTION
    : 0;
  const heightColor = getColorSectionHeight(
    keyColoringOption,
    passers,
    targets
  );
  const heightFill = displayHeatMap
    ? 0
    : PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL +
      PASSING_KEY_SIZE.HEIGHT.DOT_ROW +
      PASSING_KEY_SIZE.PADDING.SECTION;

  const viewBox = `0 0 ${PASSING_KEY_SIZE.WIDTH} ${
    heightHeatmap + heightColor + heightFill
  }`;

  const coloredPlayers = getPlayerColors(
    keyColoringOption,
    passers,
    targets,
    visPalette
  );
  const colorDots = getColorDotsSetup(
    keyColoringOption,
    visPalette,
    coloredPlayers
  );
  const fillDots = getFillDotsSetup(keyColoringOption, visPalette);

  /* 
  Permanent elements 
    Create the structure of all parts, then useEffect() based on relevant 
    parameters to update those parts/their children as required
  */
  const ref = useD3((svg) => {
    svg.attr('id', chartId);
    svg.selectAll('*').remove();

    const mainG = svg.append('g').attr('class', PASSING_KEY_CLASS_NAMES.MAIN);
    mainG.append('g').attr('class', PASSING_KEY_CLASS_NAMES.COLOR);
    mainG.append('g').attr('class', PASSING_KEY_CLASS_NAMES.FILL);
    mainG.append('g').attr('class', PASSING_KEY_CLASS_NAMES.HEATMAP);
  }, []);

  /* Changing elements */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    /* Get the zones */
    const heatSegmentG = svg.select(`.${PASSING_KEY_CLASS_NAMES.HEATMAP}`);
    const colorSegmentG = svg.select(`.${PASSING_KEY_CLASS_NAMES.COLOR}`);
    const fillSegmentG = svg.select(`.${PASSING_KEY_CLASS_NAMES.FILL}`);

    /* No animation in key, can just wipe and rebuild */
    heatSegmentG.selectAll('*').remove();
    colorSegmentG.selectAll('*').remove();
    fillSegmentG.selectAll('*').remove();

    /* Heatmap color gradient */
    if (displayHeatMap) {
      drawHeatmapSection(heatSegmentG, isDark, visPalette);
    } else {
      /* Color Description */
      colorSegmentG.attr('transform', `translate(0,${heightHeatmap})`);
      if (heightColor) {
        drawColorSection(
          colorSegmentG,
          keyColoringOption,
          isDark,
          visPalette,
          colorDots
        );
      }

      /* Fill Description */
      fillSegmentG.attr(
        'transform',
        `translate(0,${heightColor + heightHeatmap})`
      );
      drawFillSection(fillSegmentG, visPalette, fillDots);
    }
  }, [isDark, keyColoringOption, passers, targets, displayHeatMap]);

  return <PassingKeySVG ref={ref} />;
};

PassingKey.propTypes = {
  chartId: PropTypes.string,
  coloringOption: PropTypes.string,
  displayHeatMap: PropTypes.bool,
  passers: PropTypes.arrayOf(PASSER_SHAPE),
  targets: PropTypes.arrayOf(PASSER_SHAPE),
};

PassingKey.defaultProps = {
  chartId: 'passing-chart-color-key',
  coloringOption: COLORING_OPTION_PASS_SUCCESS.value,
  displayHeatMap: false,
  passers: undefined,
  targets: undefined,
};

export default PassingKey;
