import React from 'react';
import { Icon, Button } from '@statsbomb/kitbag-components';
import { useReactiveState } from '../../utils/hooks/useReactiveState';
import {
  uiState,
  cf_DownEnabled,
  cf_DistanceEnabled,
  cf_FieldPositionEnabled,
  cf_QuarterEnabled,
  cf_ClockEnabled,
  cf_PlayTypeEnabled,
  cf_YardsGainedEnabled,
  cf_PassAirYardsEnabled,
  cf_PackagesEnabled,
  cf_PositionsEnabled,
  cf_FormationsEnabled,
  cf_GameIdsEnabled,
  cf_QBDropDepthEnabled,
  cf_QBTimeToThrowEnabled,
  cf_QBPocketLocationEnabled,
} from '../../apollo';
import DistanceFilter from './DistanceFilter';
import PositionFilter from './PositionFilter';
import TimeFilter from './TimeFilter';
import TypeFilter from './TypeFilter';
import PersonnelFilter from './PersonnelFilter';
import FormationFilter from './FormationFilter';
import GamesFilter from './GamesFilter';
import {
  StyledContextFilters,
  FiltersLabel,
  FiltersContainer,
  ClearFilters,
} from './ContextFilters.styles';

const ContextFilters = () => {
  // context filters in state
  const [isDownEnabled, setIsDownEnabled] = useReactiveState(cf_DownEnabled);
  const [isDistanceEnabled, setIsDistanceEnabled] =
    useReactiveState(cf_DistanceEnabled);
  const [isFieldPositionEnabled, setIsFieldPositionEnabled] = useReactiveState(
    cf_FieldPositionEnabled
  );
  const [isQuarterEnabled, setIsQuarterEnabled] =
    useReactiveState(cf_QuarterEnabled);
  const [isClockEnabled, setIsClockEnabled] = useReactiveState(cf_ClockEnabled);
  const [isPlayTypeEnabled, setIsPlayTypeEnabled] =
    useReactiveState(cf_PlayTypeEnabled);
  const [isQBDropDepthEnabled, setIsQBDropDepthEnabled] = useReactiveState(
    cf_QBDropDepthEnabled
  );
  const [isQBTimeToThrowEnabled, setIsQBTimeToThrowEnabled] = useReactiveState(
    cf_QBTimeToThrowEnabled
  );
  const [isQBPocketLocationEnabled, setIsQBPocketLocationEnabled] =
    useReactiveState(cf_QBPocketLocationEnabled);
  const [isYardsGainedEnabled, setIsYardsGainedEnabled] = useReactiveState(
    cf_YardsGainedEnabled
  );
  const [isPassAirYardsEnabled, setIsPassAirYardsEnabled] = useReactiveState(
    cf_PassAirYardsEnabled
  );
  const [isPackagesEnabled, setIsPackagesEnabled] =
    useReactiveState(cf_PackagesEnabled);
  const [isPositionsEnabled, setIsPositionsEnabled] =
    useReactiveState(cf_PositionsEnabled);
  const [isFormationsEnabled, setIsFormationsEnabled] =
    useReactiveState(cf_FormationsEnabled);
  const [isGameIdsEnabled, setIsGameIdsEnabled] =
    useReactiveState(cf_GameIdsEnabled);

  const handleEnabledToggle = (enabled, rv, property, update) => {
    rv(enabled);

    Object.assign(uiState().contextFilters[property], update);
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  // set the enabled value of any filters to false
  const handleClearFilters = () => {
    const { contextFilters } = uiState();

    setIsDownEnabled(false);
    setIsDistanceEnabled(false);
    setIsFieldPositionEnabled(false);
    setIsQuarterEnabled(false);
    setIsClockEnabled(false);
    setIsPlayTypeEnabled(false);
    setIsQBDropDepthEnabled(false);
    setIsQBTimeToThrowEnabled(false);
    setIsQBPocketLocationEnabled(false);
    setIsYardsGainedEnabled(false);
    setIsPassAirYardsEnabled(false);
    setIsPackagesEnabled(false);
    setIsPositionsEnabled(false);
    setIsFormationsEnabled(false);
    setIsGameIdsEnabled(false);

    Object.keys(contextFilters).forEach((key) => {
      contextFilters[key].enabled = false;
    });

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  // get filter count across all modals
  const filterCount = [
    isDownEnabled,
    isDistanceEnabled,
    isFieldPositionEnabled,
    isQuarterEnabled,
    isClockEnabled,
    isPlayTypeEnabled,
    isQBDropDepthEnabled,
    isQBTimeToThrowEnabled,
    isQBPocketLocationEnabled,
    isYardsGainedEnabled,
    isPassAirYardsEnabled,
    isPackagesEnabled,
    isPositionsEnabled,
    isFormationsEnabled,
    isGameIdsEnabled,
  ].filter(Boolean).length;

  return (
    <StyledContextFilters className="sub-header-container">
      <FiltersLabel>
        <Icon variant="Filter" />
        <span>Filters</span>
      </FiltersLabel>
      <FiltersContainer>
        <GamesFilter handleEnabledToggle={handleEnabledToggle} />
        <DistanceFilter handleEnabledToggle={handleEnabledToggle} />
        <PositionFilter handleEnabledToggle={handleEnabledToggle} />
        <TimeFilter handleEnabledToggle={handleEnabledToggle} />
        <TypeFilter handleEnabledToggle={handleEnabledToggle} />
        <PersonnelFilter handleEnabledToggle={handleEnabledToggle} />
        <FormationFilter handleEnabledToggle={handleEnabledToggle} />

        {filterCount > 0 && (
          <ClearFilters>
            <Button
              variant="tertiary"
              onClick={handleClearFilters}
              size="small"
            >
              Clear Filters
            </Button>
          </ClearFilters>
        )}
      </FiltersContainer>
    </StyledContextFilters>
  );
};

export default ContextFilters;
