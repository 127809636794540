import { ROTATIONS } from '../../utils/constants/charting';
import { FIELD_MEASURES } from '../../utils/helpers/field.constants';

export const ORIENTATION = ROTATIONS.VERTICAL_UP;
export const VISIBLE_FIELD_X = 40; // yds, 10 pre LoS and 30 post LoS
export const FIELD_LOS = 10; // yds, 10 pre LoS and 30 post LoS
export const VISIBLE_FIELD_Y = FIELD_MEASURES.Y_YARDS;
export const ICON_RADIUS = 10;
export const ICON_FONT_SIZE = 12;
export const ICON_FONT_WEIGHT = 600;

export const FORMATION_DETAIL_AXES_SIZE = {
  top: 0,
  right: 25,
  bottom: 0,
  left: 25,
};
export const FORMATION_DETAIL_CLASSES = {
  BACKGROUND: 'fd-background',
  IN_AXES: 'fd-in-axes',
  FIELD: 'fd-field',
  PLAYERS: 'fd-players',
};
