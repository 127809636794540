export const PLAY_OUTCOMES = {
  TOUCHDOWN: 'Touchdown',
  EXPLOSIVE: 'Explosive',
  SUCCESS: 'Success',
  FAIL: 'Fail',
  TURNOVER: 'Turnover',
  OTHER: 'Other',
};

/* 
Explosive and Chunk should come directly as API attributes where possible
Definitions: https://www.notion.so/statsbomb/Chunks-and-Explosive-225625da7683455db07f0df327e500b7
*/
export const isChunk = (yardsNet, yardLine) => yardsNet >= 15 && yardLine <= 85;

/* This function expects a play object as is found within the API in most endpoints */
export const getPlayOutcome = (apiPlay) => {
  if (apiPlay?.touchdownWon) {
    return PLAY_OUTCOMES.TOUCHDOWN;
  }
  if (apiPlay?.explosive) {
    return PLAY_OUTCOMES.EXPLOSIVE;
  }
  if (apiPlay?.success) {
    return PLAY_OUTCOMES.SUCCESS;
  }
  if (apiPlay?.turnoverType) {
    return PLAY_OUTCOMES.TURNOVER;
  }
  if (apiPlay?.downNegated) {
    return PLAY_OUTCOMES.OTHER;
  }
  return PLAY_OUTCOMES.FAIL;
};

const PLAY_OUTCOME_COLORS = {
  [PLAY_OUTCOMES.TOUCHDOWN]: (visPalette) =>
    visPalette.successFail.superSuccess.main,
  [PLAY_OUTCOMES.EXPLOSIVE]: (visPalette) =>
    visPalette.successFail.success.main,
  [PLAY_OUTCOMES.SUCCESS]: (visPalette) => visPalette.successFail.success2.main,
  [PLAY_OUTCOMES.FAIL]: (visPalette) => visPalette.successFail.fail.main,
  [PLAY_OUTCOMES.TURNOVER]: (visPalette) =>
    visPalette.successFail.superFail.main,
  [PLAY_OUTCOMES.OTHER]: (visPalette) => visPalette.successFail.neutral.main,
};

export const playOutcomeColor = (outcome, visPalette) => {
  const colorFunction = PLAY_OUTCOME_COLORS[outcome];
  return colorFunction(visPalette);
};

export const keyColorDots = (visPalette) =>
  Object.keys(PLAY_OUTCOMES).map((outcomeKey) => {
    const value = PLAY_OUTCOMES[outcomeKey];
    return {
      name: value,
      color: playOutcomeColor(value, visPalette),
    };
  });
