import { styled } from 'styled-components';

export const TooltipBody = styled.div`
  max-width: 200px;
  padding: 0.5rem 0.625rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  box-shadow: ${({ theme }) => theme.effects.dropShadow.regular};
  max-height: 270px;
  overflow-y: auto;

  p {
    margin-bottom: 0.5rem;
    pointer-events: none;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
TooltipBody.displayName = 'TooltipBody';

export const TooltipContent = styled.div`
  display: inline;
  svg {
    display: inline;
    margin-left: 4px;
    padding-bottom: 2px;
  }
`;
TooltipContent.displayName = 'TooltipContent';
