import { styled } from 'styled-components';

export const HavocMainPage = styled.div`
  display: block;
  margin-top: 8px;
  margin-right: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);

  .havoc-no-video,
  .havoc-non-video {
    display: block;
    width: 100%;
  }
  .havoc-non-video {
    @media (min-width: 1028px) {
      width: 65%;
      float: right;
    }

    @media (min-width: 1200px) {
      width: 55%;
      float: right;
    }

    @media (min-width: 1440px) {
      width: 50%;
      float: right;
    }
  }
`;
HavocMainPage.displayName = 'HavocMainPage';

export const HavocVideo = styled.div`
  display: block;
  width: 100%;

  @media (min-width: 1028px) {
    width: 35%;
    float: right;
  }

  @media (min-width: 1200px) {
    width: 45%;
    float: right;
  }

  @media (min-width: 1440px) {
    width: 50%;
    float: right;
  }
`;
HavocVideo.displayName = 'HavocVideo';

export const HavocChartTile = styled.div`
  display: block;

  .havoc-chart-row {
    display: block;
  }
  .havoc-chart-holder {
    width: 100%;
    @media (min-width: 1440px) {
      width: calc(100% - 216px);
      float: left;
    }
  }

  .havoc-chart-key-holder {
    width: 600px;
    @media (min-width: 1440px) {
      width: 200px;
      float: left;
      margin-left: 16px;
    }
  }
`;
HavocChartTile.displayName = 'HavocChartTile';
