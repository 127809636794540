import { API_STAT_UNITS } from '../../../../utils/constants/api';

export const BIO_CATEGORY = {
  name: 'Bio',
  subcategories: [
    {
      name: 'Bio',
      stats: [],
    },
  ],
};

export const COLLEGE_CLASS = {
  FR: { apiCode: 'FR', name: 'Freshman' },
  RS_FR: { apiCode: 'RS_FR', name: 'Red Shirt Freshman' },
  SO: { apiCode: 'SO', name: 'Sophomore' },
  RS_SO: { apiCode: 'RS_SO', name: 'Red Shirt Sophomore' },
  JR: { apiCode: 'JR', name: 'Junior' },
  RS_JR: { apiCode: 'RS_JR', name: 'Red Shirt Junior' },
  SR: { apiCode: 'SR', name: 'Senior' },
  RS_SR: { apiCode: 'RS_SR', name: 'Red Shirt Senior' },
  OTHER: { apiCode: 'OTHER', name: 'Other' },
  GRADUATE: { apiCode: 'GRADUATE', name: 'Graduate' },
};

/* 
Min Plays slider
Non positional
*/
export const PLAYS_STAT_KEY = 'plays';
export const PLAYS_RANGE = {
  key: PLAYS_STAT_KEY,
  name: 'Minimum Plays',
  description: 'Plays this player was on the field',
  abbreviation: 'Plays',
  min: 0,
  max: 1000,
  steps: 100,
  sliderStep: 10,
  lowerIsBetter: false,
  value: 100,
};
export const DEFAULT_METRICS = [{ ...PLAYS_RANGE }];

const { STRING, COUNT, BOOLEAN, RATE } = API_STAT_UNITS;
export const BASE_HEADERS = [
  {
    key: 'name',
    label: 'Name',
    units: STRING,
    description: 'Name of the player',
  },
  {
    key: PLAYS_STAT_KEY,
    label: 'Plays',
    units: COUNT,
    description: PLAYS_RANGE.description,
  },
  {
    key: 'rosterPositionCode',
    label: 'Pos',
    units: STRING,
    description: 'The primary roster position of the player',
  },
  {
    key: 'teamShortName',
    label: 'Team',
    units: STRING,
    description: 'The team they currently play for',
  },
];
export const SCOUT_PLAYERS_STICKY_COLUMNS = [
  { index: 1, width: 14 },
  { index: 2, width: 6 },
  { index: 3, width: 6 },
  { index: 4, width: 6 },
];
export const BIO_STAT_HEADERS = [
  {
    key: 'ncaaTransferPortal',
    label: 'In Portal?',
    units: BOOLEAN,
    description: 'Is the player currently in the NCAA transfer portal',
  },
  {
    key: 'height',
    label: 'Height',
    units: STRING,
    description: 'The height of the player in feet and inches',
  },
  {
    key: 'weight',
    label: 'Weight',
    units: COUNT,
    description: 'The weight of the player in lbs',
  },
  {
    key: 'collegeClass',
    label: 'Class',
    units: STRING,
    description: 'What college class level is the player',
    width: '140px',
  },
  {
    key: 'recruitingClass',
    label: 'Rec. Class',
    units: COUNT,
    description: "What was the player's year of entry to college",
  },
  {
    key: 'recruitingRanking247',
    label: 'Rec. 247',
    units: COUNT,
    description: 'Recruiting Ranking 247',
  },
  {
    key: 'recruitingRankingComposite',
    label: 'Rec. Composite',
    units: RATE,
    description: 'Recruiting Ranking Composite',
  },
  {
    key: 'recruitingReranking',
    label: 'Rec. ReRank',
    units: COUNT,
    description: 'Recruiting re-ranking',
  },
  {
    key: 'homeTownName',
    label: 'Home',
    units: STRING,
    description: 'The town/city and state the player calls home',
    width: '160px',
  },
  {
    key: 'highSchoolName',
    label: 'High School',
    units: STRING,
    description: 'The name of the high school the player comes from',
    width: '340px',
  },
  {
    key: 'collegesOfferedCount',
    label: 'Offers',
    units: COUNT,
    description: 'How many college teams have made offers',
  },
  {
    key: 'collegesOfferedNames',
    label: 'Offering Teams',
    units: STRING,
    description: 'The college teams that have made offers',
    width: '180px',
  },
  {
    key: 'collegesAttendedCount',
    label: 'Attended',
    units: COUNT,
    description: 'How many colleges has the player attended',
  },
  {
    key: 'collegesAttendedNames',
    label: 'Attended Teams',
    units: STRING,
    description: 'Which colleges has the player attended',
    width: '180px',
  },
];

export const SCOUT_PLAYERS_PER_PAGE = 20;
