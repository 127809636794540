import { useState, useEffect } from 'react';

const DEFAULT_OPTIONS = {
  headers: { 'Content-Type': 'application/json' },
};

export const useFetch = (
  url,
  options = {},
  dependencies = [],
  skip = false
) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(!skip);

  const fetchOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  useEffect(() => {
    if (!skip) {
      setLoading(true);
      setData(undefined);
      setError(undefined);

      fetch(url, fetchOptions)
        .then((response) => {
          if (!response.ok) {
            throw response.status;
          }
          return response.json();
        })
        .then((parsedResponse) => {
          setData(parsedResponse);
          setError(undefined);
          setLoading(false);
        })
        .catch((err) => {
          setData(undefined);
          setError(err);
          setLoading(false);
        });
    }
  }, [skip, ...dependencies]);

  return { loading, data, error };
};
