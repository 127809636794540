import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonCheck,
  Dropdown,
  Icon,
} from '@statsbomb/kitbag-components';
import { useNavigate } from 'react-router-dom';
import Tile from '../../../components/Tile/Tile';
import {
  WizardActions,
  WizardItemContainingDropdown,
  WizardDropdownLarge,
  WizardLayout,
  WizardItemContainingTab,
  WizardButtonSpacing,
} from './PlayFinderWizard.styles';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import WizardAccordion from './WizardAccordion/WizardAccordion';
import { orderSeasons } from '../../../components/Header/Header.helper';
import { GET_PLAY_FINDER_SEASONS } from './getPlayFinderSeasons';
import { GET_PLAY_FINDER_TEAMS } from './getPlayFinderTeams';
import { replaceHrefTokens } from '../../../utils/helpers/general';
import { GET_PLAY_FINDER_COMPETITIONS } from './getPlayFinderCompetitions';
import { STATSBOMB_PRESET_FILTERS } from '../PlayFinderFilters/PlayFinderFilters.constants';

const PlayFinderWizard = () => {
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedSide, setSelectedSide] = useState({
    offense: false,
    defense: false,
  });
  const navigate = useNavigate();
  // Preset filters
  const [presetFilter, setPresetFilter] = useState();

  // data queries
  const {
    data: leaguesData,
    loading: isLoadingLeaguesData,
    error: competitionsError,
  } = useQuery(GET_PLAY_FINDER_COMPETITIONS);
  if (competitionsError) {
    console.error(ERROR_CODES.GET_PLAY_FINDER_COMPETITIONS, competitionsError);
  }

  const {
    data: seasonsData,
    loading: isLoadingSeasonData,
    error: seasonsError,
  } = useQuery(GET_PLAY_FINDER_SEASONS, {
    variables: {
      competitionId: selectedLeague?.value,
    },
  });
  if (seasonsError) {
    console.error(ERROR_CODES.GET_PLAY_FINDER_SEASONS, seasonsError);
  }

  const {
    data: teamsData,
    loading: isLoadingTeamData,
    error: teamsError,
    refetch: refetchTeams,
  } = useQuery(GET_PLAY_FINDER_TEAMS, {
    variables: {
      competitionId: selectedLeague?.value,
      seasonId: selectedSeason?.value,
    },
  });
  if (teamsError) {
    console.error(ERROR_CODES.GET_PLAY_FINDER_TEAMS, teamsError);
  }

  // props passed to each filter
  const filters = {
    leagues: {
      value: selectedLeague,
      options: leaguesData?.competitions.map((comp) => ({
        value: comp.id,
        label: comp.name,
        level: comp.level,
      })),
    },
    seasons: {
      value: selectedSeason,
      options: orderSeasons(seasonsData?.seasons),
    },
    teams: {
      value: selectedTeam,
      options: teamsData?.teams?.edges.map((team) => ({
        value: team.node.id,
        label: team.node.name,
      })),
    },
  };

  useEffect(() => {
    // if the season is not available for this league reset
    if (seasonsData && !isLoadingSeasonData) {
      if (
        !filters?.seasons?.options?.some(
          (filter) => filter.value === selectedSeason?.value
        )
      ) {
        setSelectedSeason(null);
      }
      refetchTeams();
    }
  }, [seasonsData, isLoadingSeasonData, selectedLeague]);

  const canProgress =
    selectedLeague &&
    selectedSeason &&
    selectedTeam &&
    (selectedSide.offense || selectedSide.defense);

  return (
    <WizardLayout>
      <Tile width="500px" padding="2rem">
        <h1>Play Finder</h1>
        <p>Select your initial search parameters and filters.</p>
        <div style={{ height: '2rem' }} />
        <WizardItemContainingDropdown>
          <WizardAccordion
            title="League"
            selectedOption={selectedLeague}
            tabState
          >
            <Dropdown
              id="wizard-league-dropdown"
              options={filters.leagues.options}
              label="Select a league"
              onChange={(selectedOption) => {
                setSelectedLeague(selectedOption);
                // invalidates
                setSelectedTeam(null);
                refetchTeams();
              }}
              value={selectedLeague}
              isLoading={isLoadingLeaguesData}
              menuPosition="static"
            />
          </WizardAccordion>
        </WizardItemContainingDropdown>
        <WizardItemContainingDropdown>
          <WizardAccordion title="Season" selectedOption={selectedSeason}>
            <Dropdown
              id="wizard-season-dropdown"
              options={filters.seasons.options}
              label="Select a season"
              onChange={(selectedOption) => {
                setSelectedSeason(selectedOption);
                refetchTeams();
              }}
              value={selectedSeason}
              isLoading={isLoadingSeasonData}
              menuPosition="static"
            />
          </WizardAccordion>
        </WizardItemContainingDropdown>
        <WizardItemContainingDropdown>
          <WizardAccordion title="Team" selectedOption={selectedTeam}>
            <Dropdown
              id="wizard-team-dropdown"
              options={filters.teams.options}
              label="Select a team"
              onChange={(selectedOption) => setSelectedTeam(selectedOption)}
              value={selectedTeam}
              isLoading={isLoadingTeamData}
              menuPosition="static"
            />
          </WizardAccordion>
        </WizardItemContainingDropdown>
        <WizardItemContainingTab>
          <WizardAccordion
            title="Side of Ball"
            selectedOption={{
              label: `${selectedSide.offense ? 'Offense' : ''} ${
                selectedSide.defense ? 'Defense' : ''
              }`,
            }}
          >
            <WizardButtonSpacing>
              <ButtonCheck
                id="offense"
                label="Offense"
                onChange={(e) =>
                  setSelectedSide({
                    offense: e.target.checked,
                    defense: !e.target.checked,
                  })
                }
                checked={selectedSide.offense}
              />
              <ButtonCheck
                id="defense"
                label="Defense"
                onChange={(e) =>
                  setSelectedSide({
                    defense: e.target.checked,
                    offense: !e.target.checked,
                  })
                }
                checked={selectedSide.defense}
              />
            </WizardButtonSpacing>
          </WizardAccordion>
        </WizardItemContainingTab>
        <div style={{ height: '1.5rem' }} />
        <WizardDropdownLarge>
          <Dropdown
            id="wizard-saved-filters"
            options={STATSBOMB_PRESET_FILTERS}
            label="Apply saved filter set?"
            isLoading={false}
            isSearchable={false}
            onChange={(selectedOption) => setPresetFilter(selectedOption.value)}
            value={STATSBOMB_PRESET_FILTERS[presetFilter]}
          />
        </WizardDropdownLarge>
        <WizardActions>
          <Button
            primary
            isDisabled={!canProgress}
            onClick={() => {
              if (typeof presetFilter !== 'undefined') {
                navigate(
                  `/${replaceHrefTokens(
                    'playfinder/results/:leagues/:seasons/:teams/:offense/:preset',
                    {
                      leagues: selectedLeague?.value,
                      seasons: selectedSeason?.value,
                      teams: selectedTeam?.value,
                      offense: !!selectedSide.offense,
                      preset: presetFilter,
                    }
                  )}`
                );
              } else {
                navigate(
                  `/${replaceHrefTokens(
                    'playfinder/results/:leagues/:seasons/:teams/:offense',
                    {
                      leagues: selectedLeague?.value,
                      seasons: selectedSeason?.value,
                      teams: selectedTeam?.value,
                      offense: !!selectedSide.offense,
                    }
                  )}`
                );
              }
            }}
          >
            Find Plays <Icon variant="ChevronRight" />
          </Button>
        </WizardActions>
      </Tile>
    </WizardLayout>
  );
};

export default PlayFinderWizard;
