import { styled } from 'styled-components';

export const DepthChartDrawerHeader = styled.header`
  display: flex;
  background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  padding: 1rem;
  gap: 0.5rem;

  h3 {
    display: flex;
    gap: 0.5rem;
    ${({ theme }) => theme.typography.headingMedium}
    margin: 0;
    flex-grow: 2;
    line-height: 1.8;
  }
`;
DepthChartDrawerHeader.displayName = 'DepthChartDrawerHeader';

export const DepthChartDrawerContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 1rem;
  padding: 1rem;
  // height of the drawer minus the height of its header
  height: calc(100vh - 3.5rem);
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 815px) {
    grid-template-columns: 1fr;
  }
`;
DepthChartDrawerContent.displayName = 'DepthChartDrawerContent';

export const DepthChartDrawerContentExport = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 1rem;
  padding: 1rem;
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 815px) {
    grid-template-columns: 1fr;
  }
`;
DepthChartDrawerContentExport.displayName = 'DepthChartDrawerContentExport';
