import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  SearchResultHolder,
  SearchResults,
  SearchResultsLoading,
  SearchWithList,
} from '../BiometricFilters.styles';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import BiometricChips from './BiometricChips';
import BiometricListItem from './BiometricListItem';
import { useSearchCities } from '../hooks/useSearchCities';

const HomeTownMultiselect = ({ selectedBiometrics, setSelectedBiometrics }) => {
  const homeTownFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.homeTown.key
  );

  const currentTokens = isEmpty(homeTownFilter?.tokens)
    ? []
    : [...homeTownFilter.tokens];

  const [searchValue, setSearchValue] = useState('');
  const trimmedSearchValue = (searchValue || '').trim();

  const { cities, isLoading } = useSearchCities(trimmedSearchValue);

  const CityListItem = (city) => {
    const { name, state, id } = city;
    const cityName = `${name} (${state})`;
    const lIToken = { id, name: cityName };
    return (
      <BiometricListItem
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.homeTown.key}
        currentTokens={currentTokens}
        searchResultToken={lIToken}
        onSelected={() => setSearchValue('')}
      />
    );
  };

  const adornment = trimmedSearchValue.length > 0 ? 'Close' : 'Search';

  return (
    <SearchWithList>
      <h3>Home Town</h3>
      <TextField
        id="home-town-search-filter"
        labelPosition="none"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        adornmentIcon={adornment}
        adornmentOnClick={() => {
          if (trimmedSearchValue.length > 0) {
            setSearchValue('');
          }
        }}
        placeholder="Search for a US City"
        size="small"
      />
      {trimmedSearchValue.length > 0 && (
        <SearchResultHolder>
          {isLoading && (
            <SearchResultsLoading>
              <Loader active inline size="small" content="Loading Data" />
            </SearchResultsLoading>
          )}
          <SearchResults>{cities?.map(CityListItem)}</SearchResults>
        </SearchResultHolder>
      )}
      <BiometricChips
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.homeTown.key}
        tokens={currentTokens}
      />
    </SearchWithList>
  );
};
HomeTownMultiselect.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default HomeTownMultiselect;
