import { styled } from 'styled-components';

export const WizardResultsTableFooter = styled.div`
  display: flex;
  gap: 1rem;
  padding-top: 1rem;

  > * {
    flex: 1;
  }

  label {
    ${({ theme }) => theme.typography.bodyMedium}
  }
`;
WizardResultsTableFooter.displayName = 'WizardResultsTableFooter';

export const WizardResultsSummaryStats = styled.div`
  margin: 0 1rem 1rem;
  display: flex;
  gap: 1rem;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 50%;
    max-width: 500px;
    max-height: 175px;
    overflow: hidden;
  }

  > div:last-child {
    max-width: 590px;
  }
`;
WizardResultsSummaryStats.displayName = 'WizardResultsSummaryStats';

export const WizardResultsSummaryStatsColumn = styled.div`
  h3 {
    ${({ theme }) => theme.typography.headingMedium}
  }

  > div {
    display: flex;
    gap: 1rem;
    padding-top: 0.5rem;
    flex-direction: row;
    border: ${({ theme }) =>
      `1px solid ${theme.colours.canvas.secondary.main}`};
    padding: 1rem;
    flex-wrap: wrap;
    min-height: 163px;
  }
`;
WizardResultsSummaryStatsColumn.displayName = 'WizardResultsSummaryStatsColumn';

export const WizardResultsSummaryStatsColumnHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;
WizardResultsSummaryStatsColumnHeader.displayName =
  'WizardResultsSummaryStatsColumnHeader';
