import PropTypes from 'prop-types';

export const barDatumPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  yValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  title: PropTypes.string,
});

const lineDatumPropTypes = PropTypes.exact({
  xValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  yValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});
export const linePropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(lineDatumPropTypes).isRequired,
  stroke: PropTypes.shape({
    color: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    dashArray: PropTypes.string,
  }),
});
