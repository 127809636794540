import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '@statsbomb/kitbag-components';

/*
 * Renders a button for downloading a CSV file of a matching table.
 * uses: https://www.npmjs.com/package/react-csv
 *
 * This package has a bug with downloading async data and it hasn't been updated for over a year now.
 * To deal with this bug we render a dummy button which when clicked, triggers a data fetching process.
 * When that data is ready, the actual CSV button is rendered and clicked to trigger the download automatically.
 * This solution seems to be the most popular to bypass the async download bug
 * https://github.com/react-csv/react-csv/issues/189
 *
 */
const DownloadCsvAsync = ({
  data,
  reset,
  headers,
  onClick,
  fileName,
  loading,
  disabled,
}) => {
  const csvRef = useRef();

  useEffect(() => {
    if (data?.length && csvRef.current && csvRef.current.link) {
      csvRef.current.link.click();
      reset();
    }
  }, [data]);

  return (
    <>
      <Dimmer active={loading} page>
        <Loader indeterminate content="Downloading" size="large" />
      </Dimmer>
      <ButtonIcon
        onClick={onClick}
        shape="circle"
        variant="secondary"
        size="small"
        disabled={loading || disabled}
        icon="Download"
        title="Download CSV"
      >
        download
      </ButtonIcon>
      {data && data.length ? (
        <CSVLink
          data={data}
          headers={headers}
          filename={fileName}
          ref={csvRef}
          className="async-csv"
        />
      ) : null}
    </>
  );
};

DownloadCsvAsync.propTypes = {
  // The data/table rows
  data: PropTypes.arrayOf(PropTypes.shape({})),
  // the data headers
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  // the name that will be given to the downloaded file
  fileName: PropTypes.string,
  // callback function to trigger the download
  onClick: PropTypes.func.isRequired,
  // resets the data to prevent stale data downloads
  reset: PropTypes.func.isRequired,
  // whether the data to download is loading
  loading: PropTypes.bool,
  // whether the button is disabled
  disabled: PropTypes.bool,
};

DownloadCsvAsync.defaultProps = {
  data: [],
  headers: undefined,
  fileName: 'table.csv',
  loading: false,
  disabled: false,
};

export default DownloadCsvAsync;
