import { styled } from 'styled-components';

export const SummaryStatWrapper = styled.div`
  color: ${({ theme }) => `${theme.colours.ink.primary.main}`};
  width: ${({ $width }) => $width || '31%'};

  h4 {
    ${({ theme }) => theme.typography.bodySmall}
    color: ${({ theme }) => `${theme.colours.ink.primary.main}80`};
    margin-bottom: 0.25rem;
  }

  label {
    ${({ theme }) => theme.typography.headingRegular}
  }
`;
