import { styled } from 'styled-components';

export const VerticalKeyHolder = styled.div`
  width: 100%;
  svg {
    margin: 0px 0px 16px 0px;
  }
`;
VerticalKeyHolder.displayName = 'VerticalKeyHolder';

export const ChartButtonRibbon = styled.div`
  width: 100%;
  position: relative;
  display: table;
  height: 24px;
  button {
    float: right;
    margin-left: 8px;
  }
`;
ChartButtonRibbon.displayName = 'ChartButtonRibbon';

export const SecondChartTitle = styled.h3`
  margin-top: 16px;
`;
SecondChartTitle.displayName = 'SecondChartTitle';
