import PropTypes from 'prop-types';

const alignmentPositionShape = PropTypes.shape({
  apiCode: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  color: PropTypes.func,
  shape: PropTypes.func,
  radiusScale: PropTypes.number,
  rotation: PropTypes.number,
  isOffense: PropTypes.bool,
  isSpecialTeam: PropTypes.bool,
  rosterPositionKey: PropTypes.string,
  displayOrder: PropTypes.number,
});

// An object defining the shape of each position if selected
export const olgpPositionPropType = PropTypes.exact({
  // the id of the player selected
  playerId: PropTypes.number,
  // the name of the player selected
  playerName: PropTypes.string,
  // the Alignment position for this selection
  position: alignmentPositionShape,
});
