import { styled } from 'styled-components';
import {
  BASE_VIS_WIDTH,
  FORMATION_SUMMARY_FONTS,
  FORMATION_SUMMARY_KEY,
} from './FormationSummaryChart.constants';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';

export const StyledFormationSummaryChart = styled.svg`
  width: 100%;
`;

export const VerticalGuide = styled.line`
  stroke: ${({ theme, $isFirst }) =>
    theme.colours.visualisations[$isFirst ? 'axis' : 'guides']};
`;

const axesFontStyles = () => `
  font-size: ${FORMATION_SUMMARY_FONTS.NORMAL.SIZE}px;
  font-weight: ${FORMATION_SUMMARY_FONTS.NORMAL.WEIGHT};
`;

export const AxisTopTickLabel = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.label};
  text-anchor: middle;
  ${axesFontStyles}
`;

export const AxisLeftLabel = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.label};
  ${axesFontStyles}
`;

export const Personnel = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.label};
  ${({ $secondaryLabel }) =>
    $secondaryLabel
      ? `
      font-size: ${FORMATION_SUMMARY_FONTS.LARGE.SIZE}px;
      font-weight: ${FORMATION_SUMMARY_FONTS.LARGE.WEIGHT};
    `
      : axesFontStyles()}
`;

export const Delta = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.label};
  text-anchor: middle;
  ${axesFontStyles}
`;

export const ColorScaleHeader = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.info};
  font-size: ${VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE}px;
  font-weight: ${VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT};
`;

export const ColorScaleLabel = styled.text`
  fill: ${({ theme }) => theme.colours.visualisations.text.info};
  font-size: ${VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE}px;
  font-weight: ${VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT};
  user-select: none;
`;

export const StyledKey = styled.svg`
  width: 100%;
  max-width: ${BASE_VIS_WIDTH}px;
  min-height: ${FORMATION_SUMMARY_KEY.HEIGHT}px;
`;

StyledFormationSummaryChart.displayName = 'StyledFormationSummaryChart';
VerticalGuide.displayName = 'VerticalGuide';
AxisTopTickLabel.displayName = 'AxisTopTickLabel';
AxisLeftLabel.displayName = 'AxisLeftLabel';
Personnel.displayName = 'Personnel';
Delta.displayName = 'Delta';
ColorScaleHeader.displayName = 'ColorScaleHeader';
ColorScaleLabel.displayName = 'ColorScaleLabel';
StyledKey.displayName = 'StyledKey';
