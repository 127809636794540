import React from 'react';
import { Grid } from '@statsbomb/kitbag-components';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import Tile from '../../../components/Tile/Tile';

const ScoutGuidedSetup = () => (
  <KitbagPageGridHolder>
    <Grid container={false} page>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Tile border={false}>
          <Tile.Header>Player Scout Wizard</Tile.Header>
          <Tile.Body>
            <div>
              <span>This page is under construction.</span>
            </div>
          </Tile.Body>
        </Tile>
      </Grid>
    </Grid>
  </KitbagPageGridHolder>
);

export default ScoutGuidedSetup;
