import { convertIntToNth } from '../../../../utils/helpers/formatting';
import { TEAM_GROUP_BY_HEADERS_CONFIG } from './TeamStats.constants';

export const addTeamGroupColumns = (teamStats) =>
  teamStats?.items.map((team) => {
    const teamGroups = team.groups
      // ignore team and team groupings which are applied by default
      ?.filter(
        (group) => group.key !== 'TEAM_OFFENSE' && group.key !== 'TEAM_DEFENSE'
      )
      // take the remaining groupings and format them to "stat" columns
      .reduce(
        (acc, group) => ({
          ...acc,
          // add extra grouping by key and value if exists
          [TEAM_GROUP_BY_HEADERS_CONFIG[group?.key].id]:
            // use a formatted value if provided by the API
            team?.[TEAM_GROUP_BY_HEADERS_CONFIG[group?.key].id] ||
            // otherwise use the group object value as fallback (downs and quarters)
            convertIntToNth(group?.value),
        }),
        {}
      );

    return {
      ...team,
      ...teamGroups,
    };
  });
