import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@statsbomb/kitbag-components';
import Tile from '../../../../components/Tile/Tile';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import { HAVOC_TABLE_AGGREGATION_MODES } from './HavocTable.constants';

/* This provides the accordion section for filtering havoc things */
const HavocTableFilters = ({ aggregationMode, setAggregationMode }) => (
  <AccordionTile
    id="field-accordion"
    header={<Tile.AccordionHeader>Table Options</Tile.AccordionHeader>}
    body={
      <Tile.AccordionBody>
        <Dropdown
          id="havoc-aggregation-mode-dropdown"
          options={HAVOC_TABLE_AGGREGATION_MODES}
          label="Table Aggregation Mode"
          menuPosition="static"
          onChange={(selectedOption) => {
            setAggregationMode(selectedOption.value);
          }}
          value={HAVOC_TABLE_AGGREGATION_MODES.find(
            (f) => f.value === aggregationMode
          )}
        />
      </Tile.AccordionBody>
    }
    isMount
    saveState
    isExpandedDefault
  />
);

HavocTableFilters.propTypes = {
  /** state/querystring values/setters for the options controlled in this selection
   */
  aggregationMode: PropTypes.string.isRequired,
  setAggregationMode: PropTypes.func.isRequired,
};

export default HavocTableFilters;
