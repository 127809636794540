import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_TEAM_BOX_SCORE = gql`
  query GetTeamBoxScore(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    boxScores(
      filters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
      first: 1000
    ) {
      edges {
        node {
          offenseTeamId
          defenseTeamId
          playQuarter
          playDown
          playType
          playCount
          playSuccessCount
          yardsWon
          yardedPlays
          shotgunCount
          yardsAir
        }
      }
    }
  }
`;
