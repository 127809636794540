import { styled } from 'styled-components';

export const StyledReleaseNotes = styled.div`
  padding-left: 0.5rem;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colours.ink.primary.main};

  &::after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colours.layering[3].main};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    left: 15.1rem;
    top: 8rem;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colours.layering[3].main};
    height: calc(100% - 140px);
    width: 2px;
    left: 16rem;
    top: 10rem;
    z-index: 1;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  h2,
  h3 {
    margin-bottom: 0.8rem;
    ${({ theme }) => theme.typography.headingLarge}
  }

  > div {
    padding: 1rem 4rem;

    > section {
      display: grid;
      grid-template-columns: 100px auto;
      gap: 3.6rem;
      margin-top: 1rem;
    }

    section > h2 {
      grid-row-start: 1;
      grid-row-end: 4;
    }

    > section:first-of-type {
      margin-top: 6rem;
    }
  }

  > div:last-child {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc outside;

    li {
      margin-left: 2.5rem;
      position: relative;
      white-space: pre-wrap;
      font-size: 1.4rem;
    }
  }
`;
