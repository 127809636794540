import React from 'react';
import PropTypes from 'prop-types';
import { AxisLeftLabel } from './FormationSummaryChart.styles';
import { BARS } from './FormationSummaryChart.constants';

// draws the left axis (labels) of the formation summary bar chart
export const Labels = ({ yScale, data }) => (
  <g>
    {data.map((d) => {
      const textY = yScale(d.label) + BARS.BASE_HEIGHT / 2 + 2;

      return (
        <AxisLeftLabel key={d.label} transform={`translate(0, ${textY})`}>
          {d.label}
        </AxisLeftLabel>
      );
    })}
  </g>
);

Labels.propTypes = {
  // the data that constructs the bars
  data: PropTypes.arrayOf(PropTypes.object),
  // d3 scale band constructor instance
  yScale: PropTypes.func.isRequired,
};

Labels.defaultProps = {
  data: [],
};

export default Labels;
