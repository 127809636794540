import React from 'react';
import PropTypes from 'prop-types';
import { StyledSlider } from '../../../../../components/ContextFilters/ContextFilters.styles';
import { RangeSliderWrapper } from '../BiometricFilters.styles';
import { WEIGHT_RANGE } from '../BiometricFilters.constants';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { SliderDisplay } from '../../ReversibleSlider/ReversibleSlider.styles';
import { biometricProp } from '../../../Scout.proptypes';

const WeightSlider = ({ selectedBiometrics, setSelectedBiometrics }) => {
  const weightMinFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.weightMin.key
  );
  const weightMaxFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.weightMax.key
  );
  const weightMin = weightMinFilter?.value || WEIGHT_RANGE.MIN;
  const weightMax = weightMaxFilter?.value || WEIGHT_RANGE.MAX;
  const weightSliderValue = [weightMin, weightMax];

  const onWeightSliderChange = (sliderValues) => {
    const sliderWeightMin = sliderValues[0];
    const sliderWeightMax = sliderValues[1];
    const nonWeightBiometrics = selectedBiometrics.filter(
      (b) =>
        b.key !== SCOUT_BIOMETRIC_FILTERS.weightMin.key &&
        b.key !== SCOUT_BIOMETRIC_FILTERS.weightMax.key
    );
    const minWeightBiometric =
      sliderWeightMin === WEIGHT_RANGE.MIN
        ? null
        : {
            key: SCOUT_BIOMETRIC_FILTERS.weightMin.key,
            value: sliderWeightMin,
          };
    const maxWeightBiometric =
      sliderWeightMax === WEIGHT_RANGE.MAX
        ? null
        : {
            key: SCOUT_BIOMETRIC_FILTERS.weightMax.key,
            value: sliderWeightMax,
          };
    const newBiometrics = nonWeightBiometrics
      .concat(minWeightBiometric, maxWeightBiometric)
      .filter((a) => a !== null);
    setSelectedBiometrics(newBiometrics);
  };

  const weightRange = Math.abs(WEIGHT_RANGE.MAX - WEIGHT_RANGE.MIN);
  const paddingPercentage = 100 / weightRange;

  const selectedWeightRangeDescription = () => {
    if (weightMin === WEIGHT_RANGE.MIN && weightMax === WEIGHT_RANGE.MAX) {
      return 'Any Weight';
    }
    if (weightMin !== WEIGHT_RANGE.MIN && weightMax !== WEIGHT_RANGE.MAX) {
      return `${weightMin} to ${weightMax}`;
    }
    if (weightMin !== WEIGHT_RANGE.MIN) {
      return `At least ${weightMin}`;
    }
    return `At most ${weightMax}`;
  };

  return (
    <RangeSliderWrapper>
      <h3>Weight</h3>
      <StyledSlider
        thumbClassName="thumb"
        trackClassName="track"
        value={weightSliderValue}
        ariaLabel={['weightMin', 'weightMax']}
        onChange={onWeightSliderChange}
        pearling
        minDistance={1}
        min={WEIGHT_RANGE.MIN}
        max={WEIGHT_RANGE.MAX}
        withTracks
        step={1}
      />
      <SliderDisplay $padding={paddingPercentage}>
        <span>{WEIGHT_RANGE.MIN + 1}</span>
        <span>{selectedWeightRangeDescription()}</span>
        <span>{WEIGHT_RANGE.MAX - 1}</span>
      </SliderDisplay>
    </RangeSliderWrapper>
  );
};
WeightSlider.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default WeightSlider;
