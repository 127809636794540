import React, { useState, useEffect } from 'react';
import { Grid } from '@statsbomb/kitbag-components';
import { isEmpty, sortBy } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import Tile from '../../../components/Tile/Tile';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import { useGetPlayerStatDefinitions } from '../../../utils/hooks/useGetPlayerStatDefinitions';
import MetricModal from './MetricModal/MetricModal';
import { formatPlayerStatDefinitionsForModal } from './ScoutResults.dataManipulation';
import MetricFilters from './MetricFilters/MetricFilters';
import useQueryString from '../../../utils/hooks/useQueryString';
import { ui_scoutSetups } from '../../../apollo';
import SaveModal from './SaveModal/SaveModal';
import ScoutPlayers from './ScoutPlayers/ScoutPlayers';
import PositionFilter from '../../../components/PositionFilter/PositionFilter';
import BiometricFilters from './BiometricFilters/BiometricFilters';
import TransferPortalTriToggle from './BiometricFilters/toggles/TransferPortalTriToggle';
import { DEFAULT_SCOUT_POSITION } from './ScoutResults.constants';
import {
  DEFAULT_METRICS,
  PLAYS_STAT_KEY,
} from './ScoutPlayers/ScoutPlayers.constants';
import PlaysSlider from './PlaysSlider/PlaysSlider';

const ScoutResults = () => {
  /* Positions */
  const [selectedRosterPositions, setSelectedRosterPositions] = useState([
    DEFAULT_SCOUT_POSITION,
  ]);
  /* Exists so can change table later */
  const changePositionConfig = (newConfig) => {
    setSelectedRosterPositions(newConfig);
  };
  const primaryPosition = selectedRosterPositions.sort()[0];

  /* Player Stat Metrics */
  const { statDefinitions, loading: statDefinitionLoading } =
    useGetPlayerStatDefinitions();
  const metricModalDefs = formatPlayerStatDefinitionsForModal(statDefinitions);

  const [selectedStats, setSelectedStats] = useState(DEFAULT_METRICS);
  const selectedStatKeys = selectedStats.map((m) => m.name);

  const addPlayerMetric = (metricDefinition) => {
    if (!selectedStatKeys.includes(metricDefinition?.name)) {
      const newStatList = selectedStats.concat(metricDefinition);
      setSelectedStats(newStatList);
    }
  };

  const metricValueChanged = (metric, newValue) => {
    const otherSelectedMetrics = selectedStats.filter(
      (s) => s.name !== metric.name
    );
    const modifiedMetric = { ...metric, value: newValue };
    const newStatList = otherSelectedMetrics.concat(modifiedMetric);
    setSelectedStats(newStatList);
  };

  const removeMetric = (metric) => {
    const otherSelectedMetrics = selectedStats.filter(
      (s) => s.name !== metric.name
    );
    setSelectedStats(otherSelectedMetrics);
  };

  /* Because it's a static/permanent filter, exclude plays from metric filters */
  const nonPlaysStats = selectedStats.filter((s) => s.key !== PLAYS_STAT_KEY);
  const orderedStats = sortBy(nonPlaysStats, 'name');

  /* Player Bio Metrics */
  const [selectedBiometrics, setSelectedBiometrics] = useState([]);

  /* Templates */
  const savedScoutTemplates = useReactiveVar(ui_scoutSetups);
  const [templateId, setTemplateId] = useQueryString('id', null);
  const savedTemplate =
    !isEmpty(savedScoutTemplates) && templateId
      ? savedScoutTemplates.find((s) => s.id === templateId)
      : {};
  const title = `Template: ${
    savedTemplate?.name ? `${savedTemplate?.name}` : ' Unsaved'
  }`;
  const onSaved = (setup) => {
    setTemplateId(setup.id);
  };

  /* When loading via query string (nav in) set up filters */
  useEffect(() => {
    if (!isEmpty(savedTemplate)) {
      if (!isEmpty(savedTemplate?.selectedStats)) {
        setSelectedStats(savedTemplate.selectedStats);
      }
      if (!isEmpty(savedTemplate?.selectedBiometrics)) {
        setSelectedBiometrics(savedTemplate.selectedBiometrics);
      }
      setSelectedRosterPositions(savedTemplate.selectedPositions);
    }
  }, [savedTemplate]);

  const canScoutPlayers = !isEmpty(selectedRosterPositions);

  /* Metric Modal Logic */
  const [isMetricModalOpen, setIsMetricModalOpen] = useState(false);
  const [selectedModalMetric, setSelectedModalMetric] = useState(null);

  const tableHeaderSelected = (statKey) => {
    const statDef = metricModalDefs?.find((s) => s.key === statKey);
    if (statDef) {
      setSelectedModalMetric(statDef);
      setIsMetricModalOpen(true);
    }
  };

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          <Tile border="0" full margin="0">
            <Tile.Body>
              {canScoutPlayers && (
                <ScoutPlayers
                  selectedStats={selectedStats}
                  rosterPositions={selectedRosterPositions}
                  primaryPosition={primaryPosition}
                  selectedBiometrics={selectedBiometrics}
                  metricSelected={tableHeaderSelected}
                />
              )}
            </Tile.Body>
          </Tile>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          <Tile border="0" margin="0">
            <Tile.Header>{title}</Tile.Header>
            <Tile.Body>
              <SaveModal
                scoutTemplateId={templateId}
                selectedStats={selectedStats}
                selectedPositions={selectedRosterPositions}
                selectedBiometrics={selectedBiometrics}
                onSaved={onSaved}
              />
            </Tile.Body>
          </Tile>
          <AccordionTile
            id="scout-filter-positions"
            header={
              <Tile.AccordionHeader>Positions & Plays</Tile.AccordionHeader>
            }
            body={
              <Tile.AccordionBody>
                <PositionFilter
                  selectedPositions={selectedRosterPositions}
                  setSelectedPositions={changePositionConfig}
                  isRosterPositions
                />
                <PlaysSlider
                  selectedMetrics={selectedStats}
                  onSliderChange={metricValueChanged}
                  removeMetric={removeMetric}
                />
              </Tile.AccordionBody>
            }
            isExpandedDefault
          />
          <AccordionTile
            id="scout-filter-transfer-portal"
            header={
              <Tile.AccordionHeader>Transfer Portal</Tile.AccordionHeader>
            }
            body={
              <Tile.AccordionBody>
                <TransferPortalTriToggle
                  selectedBiometrics={selectedBiometrics}
                  setSelectedBiometrics={setSelectedBiometrics}
                />
              </Tile.AccordionBody>
            }
            isExpandedDefault
          />
          <AccordionTile
            id="scout-filter-accordion-bio"
            header={
              <Tile.AccordionHeader>Player Bio Filtering</Tile.AccordionHeader>
            }
            body={
              <Tile.AccordionBody>
                <BiometricFilters
                  selectedBiometrics={selectedBiometrics}
                  setSelectedBiometrics={setSelectedBiometrics}
                />
              </Tile.AccordionBody>
            }
            isExpandedDefault={false}
          />
          <AccordionTile
            id="scout-filter-accordion-metrics"
            header={<Tile.AccordionHeader>Player Metrics</Tile.AccordionHeader>}
            body={
              <Tile.AccordionBody>
                <MetricModal
                  statDefinitions={metricModalDefs}
                  statDefinitionsLoading={statDefinitionLoading}
                  addMetricFunction={addPlayerMetric}
                  title="Select a Metric to Add Filter"
                  selectedStatKeys={selectedStatKeys}
                  primaryPosition={primaryPosition}
                  isOpen={isMetricModalOpen}
                  setIsOpen={setIsMetricModalOpen}
                  selectedMetric={selectedModalMetric}
                  setSelectedMetric={setSelectedModalMetric}
                />
                <MetricFilters
                  selectedMetrics={orderedStats}
                  onSliderChange={metricValueChanged}
                  removeMetric={removeMetric}
                />
              </Tile.AccordionBody>
            }
            isExpandedDefault
          />
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default ScoutResults;
