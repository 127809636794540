import React from 'react';
import PropTypes from 'prop-types';
import {
  RatioIndicatorWrapper,
  RatioIndicatorBar,
  RatioIndicatorLabel,
} from './RatioIndicator.styles';

export const RatioIndicator = ({ ratio, labelSuffix }) => {
  const percentage = Math.round(ratio * 100);

  return (
    <RatioIndicatorWrapper data-testid="ratio-indicator-wrapper">
      <RatioIndicatorBar className={ratio > 0 && 'selected'} />
      <RatioIndicatorBar className={ratio > 0.25 && 'selected'} />
      <RatioIndicatorBar className={ratio > 0.5 && 'selected'} />
      <RatioIndicatorBar className={ratio > 0.75 && 'selected'} />
      <RatioIndicatorLabel>{`${percentage}% ${labelSuffix}`}</RatioIndicatorLabel>
    </RatioIndicatorWrapper>
  );
};

RatioIndicator.propTypes = {
  // measurement for this ratio bar
  ratio: PropTypes.number,
  // suffix for bar label
  labelSuffix: PropTypes.string,
};

RatioIndicator.defaultProps = {
  ratio: 0,
  labelSuffix: 'Weight',
};
