import { API_STAT_UNITS } from '../../../utils/constants/api';

export const FORMATIONS_CONFIG = {
  ACE: {
    apiKey: 'ACE',
    name: 'Ace',
  },
  ACE_PAIR: {
    apiKey: 'ACE_PAIR',
    name: 'Ace Pair',
  },
  ACE_PAIR_WING: {
    apiKey: 'ACE_PAIR_WING',
    name: 'Ace Pair Wing',
  },
  ACE_TRIPLE_PAIR: {
    apiKey: 'ACE_TRIPLE_PAIR',
    name: 'Ace Triple Pair',
  },
  ACE_TWINS: {
    apiKey: 'ACE_TWINS',
    name: 'Ace Twins',
  },
  ACE_TWINS_WING: {
    apiKey: 'ACE_TWINS_WING',
    name: 'Ace Twins Wing',
  },
  ACE_WING: {
    apiKey: 'ACE_WING',
    name: 'Ace Wing',
  },
  ACE_WINGS: {
    apiKey: 'ACE_WINGS',
    name: 'Ace Wings',
  },
  BIG: {
    apiKey: 'BIG',
    name: 'Big',
  },
  BIG_NASTY: {
    apiKey: 'BIG_NASTY',
    name: 'Big Nasty',
  },
  BIG_PAIR: {
    apiKey: 'BIG_PAIR',
    name: 'Big Pair',
  },
  BIG_WING: {
    apiKey: 'BIG_WING',
    name: 'Big Wing',
  },
  DEUCE: {
    apiKey: 'DEUCE',
    name: 'Deuce',
  },
  DOUBLES: {
    apiKey: 'DOUBLES',
    name: 'Doubles',
  },
  DOUBLES_WING: {
    apiKey: 'DOUBLES_WING',
    name: 'Doubles Wing',
  },
  EMPTY_3X2: {
    apiKey: 'EMPTY_3X2',
    name: 'Empty 3x2',
  },
  EMPTY_3X2_ACE: {
    apiKey: 'EMPTY_3X2_ACE',
    name: 'Empty 3x2 Ace',
  },
  EMPTY_3X2_ACE_PAIR: {
    apiKey: 'EMPTY_3X2_ACE_PAIR',
    name: 'Empty 3x2 Ace Pair',
  },
  EMPTY_3X2_ACE_WING: {
    apiKey: 'EMPTY_3X2_ACE_WING',
    name: 'Empty 3x2 Ace Wing',
  },
  EMPTY_3X2_ACE_WINGS: {
    apiKey: 'EMPTY_3X2_ACE_WINGS',
    name: 'Empty 3x2 Ace Wings',
  },
  EMPTY_3X2_ACE_WINK: {
    apiKey: 'EMPTY_3X2_ACE_WINK',
    name: 'Empty 3x2 Ace Wink',
  },
  EMPTY_3X2_PAIR: {
    apiKey: 'EMPTY_3X2_PAIR',
    name: 'Empty 3x2 Pair',
  },
  EMPTY_3X2_PEAK: {
    apiKey: 'EMPTY_3X2_PEAK',
    name: 'Empty 3x2 Peak',
  },
  EMPTY_3X2_TIGHT: {
    apiKey: 'EMPTY_3X2_TIGHT',
    name: 'Empty 3x2 Tight',
  },
  EMPTY_3X2_TWEAK: {
    apiKey: 'EMPTY_3X2_TWEAK',
    name: 'Empty 3x2 Tweak',
  },
  EMPTY_3X2_WING: {
    apiKey: 'EMPTY_3X2_WING',
    name: 'Empty 3x2 Wing',
  },
  EMPTY_3X2_WINK: {
    apiKey: 'EMPTY_3X2_WINK',
    name: 'Empty 3x2 Wink',
  },
  EMPTY_4X1: {
    apiKey: 'EMPTY_4X1',
    name: 'Empty 4x1',
  },
  EMPTY_4X1_ACE: {
    apiKey: 'EMPTY_4X1_ACE',
    name: 'Empty 4x1 Ace',
  },
  EMPTY_4X1_ACE_PAIR: {
    apiKey: 'EMPTY_4X1_ACE_PAIR',
    name: 'Empty 4x1 Ace Pair',
  },
  EMPTY_4X1_ACE_WING: {
    apiKey: 'EMPTY_4X1_ACE_WING',
    name: 'Empty 4x1 Ace Wing',
  },
  EMPTY_4X1_PAIR: {
    apiKey: 'EMPTY_4X1_PAIR',
    name: 'Empty 4x1 Pair',
  },
  EMPTY_4X1_TIGHT: {
    apiKey: 'EMPTY_4X1_TIGHT',
    name: 'Empty 4x1 Tight',
  },
  EMPTY_4X1_TWEAK: {
    apiKey: 'EMPTY_4X1_TWEAK',
    name: 'Empty 4x1 Tweak',
  },
  EMPTY_4X1_WING: {
    apiKey: 'EMPTY_4X1_WING',
    name: 'Empty 4x1 Wing',
  },
  FULL_HOUSE: {
    apiKey: 'FULL_HOUSE',
    name: 'Full House',
  },
  HEAVY_TRIO: {
    apiKey: 'HEAVY_TRIO',
    name: 'Heavy Trio',
  },
  JUMBO: {
    apiKey: 'JUMBO',
    name: 'Jumbo',
  },
  JUMBO_WING: {
    apiKey: 'JUMBO_WING',
    name: 'Jumbo Wing',
  },
  JUMBO_WINGS: {
    apiKey: 'JUMBO_WINGS',
    name: 'Jumbo Wings',
  },
  PRO: {
    apiKey: 'PRO',
    name: 'Pro',
  },
  PRO_WING: {
    apiKey: 'PRO_WING',
    name: 'Pro Wing',
  },
  SLOT: {
    apiKey: 'SLOT',
    name: 'Slot',
  },
  TREY: {
    apiKey: 'TREY',
    name: 'Trey',
  },
  TRIPLE: {
    apiKey: 'TRIPLE',
    name: 'Triple',
  },
  TRIPLE_PAIR: {
    apiKey: 'TRIPLE_PAIR',
    name: 'Triple Pair',
  },
  TRIPLE_WING: {
    apiKey: 'TRIPLE_WING',
    name: 'Triple Wing',
  },
  TRIPS: {
    apiKey: 'TRIPS',
    name: 'Trips',
  },
  TWINS: {
    apiKey: 'TWINS',
    name: 'Twins',
  },
  TWINS_PAIR: {
    apiKey: 'TWINS_PAIR',
    name: 'Twins Pair',
  },
  UNB_ACE_PAIR: {
    apiKey: 'UNB_ACE_PAIR',
    name: 'UNB Ace Pair',
  },
  UNB_ACE_TWINS: {
    apiKey: 'UNB_ACE_TWINS',
    name: 'UNB Ace Twins',
  },
  UNB_ACE_TWINS_WING: {
    apiKey: 'UNB_ACE_TWINS_WING',
    name: 'UNB Ace Twins Wing',
  },
  UNB_BIG_NASTY: {
    apiKey: 'UNB_BIG_NASTY',
    name: 'UNB Big Nasty',
  },
  UNB_BIG_PAIR: {
    apiKey: 'UNB_BIG_PAIR',
    name: 'UNB Big Pair',
  },
  UNB_BIG_WING: {
    apiKey: 'UNB_BIG_WING',
    name: 'UNB Big Wing',
  },
  UNB_DOUBLES: {
    apiKey: 'UNB_DOUBLES',
    name: 'UNB Doubles',
  },
  UNB_EMPTY_3X2: {
    apiKey: 'UNB_EMPTY_3X2',
    name: 'UNB Empty 3x2',
  },
  UNB_EMPTY_3X2_ACE_WING: {
    apiKey: 'UNB_EMPTY_3X2_ACE_WING',
    name: 'UNB Empty 3x2 Ace Wing',
  },
  UNB_EMPTY_3X2_ACE_WINGS: {
    apiKey: 'UNB_EMPTY_3X2_ACE_WINGS',
    name: 'UNB Empty 3x2 Ace Wings',
  },
  UNB_EMPTY_3X2_ACE_WINK: {
    apiKey: 'UNB_EMPTY_3X2_ACE_WINK',
    name: 'UNB Empty 3x2 Ace Wink',
  },
  UNB_EMPTY_3X2_PAIR: {
    apiKey: 'UNB_EMPTY_3X2_PAIR',
    name: 'UNB Empty 3x2 Pair',
  },
  UNB_EMPTY_3X2_TIGHT: {
    apiKey: 'UNB_EMPTY_3X2_TIGHT',
    name: 'UNB Empty 3x2 Tight',
  },
  UNB_EMPTY_3X2_TWEAK: {
    apiKey: 'UNB_EMPTY_3X2_TWEAK',
    name: 'UNB Empty 3x2 Tweak',
  },
  UNB_EMPTY_3X2_WING: {
    apiKey: 'UNB_EMPTY_3X2_WING',
    name: 'UNB Empty 3x2 Wing',
  },
  UNB_EMPTY_3X2_WINK: {
    apiKey: 'UNB_EMPTY_3X2_WINK',
    name: 'UNB Empty 3x2 Wink',
  },
  UNB_EMPTY_4X1: {
    apiKey: 'UNB_EMPTY_4X1',
    name: 'UNB Empty 4x1',
  },
  UNB_EMPTY_4X1_PAIR: {
    apiKey: 'UNB_EMPTY_4X1_PAIR',
    name: 'UNB Empty 4x1 Pair',
  },
  UNB_EMPTY_4X1_TIGHT: {
    apiKey: 'UNB_EMPTY_4X1_TIGHT',
    name: 'UNB Empty 4x1 Tight',
  },
  UNB_EMPTY_5X0: {
    apiKey: 'UNB_EMPTY_5X0',
    name: 'UNB Empty 5x0',
  },
  UNB_EMPTY_5X0_PAIR: {
    apiKey: 'UNB_EMPTY_5X0_PAIR',
    name: 'UNB Empty 5x0 Pair',
  },
  UNB_EMPTY_5X0_TIGHT: {
    apiKey: 'UNB_EMPTY_5X0_TIGHT',
    name: 'UNB Empty 5x0 Tight',
  },
  UNB_FULL_HOUSE: {
    apiKey: 'UNB_FULL_HOUSE',
    name: 'UNB Full House',
  },
  UNB_JUMBO: {
    apiKey: 'UNB_JUMBO',
    name: 'UNB Jumbo',
  },
  UNB_JUMBO_WING: {
    apiKey: 'UNB_JUMBO_WING',
    name: 'UNB Jumbo Wing',
  },
  UNB_JUMBO_WINK: {
    apiKey: 'UNB_JUMBO_WINK',
    name: 'UNB Jumbo Wink',
  },
  UNB_PRO: {
    apiKey: 'UNB_PRO',
    name: 'UNB Pro',
  },
  UNB_QUADS: {
    apiKey: 'UNB_QUADS',
    name: 'UNB Quads',
  },
  UNB_SLOT: {
    apiKey: 'UNB_SLOT',
    name: 'UNB Slot',
  },
  UNB_TREY: {
    apiKey: 'UNB_TREY',
    name: 'UNB Trey',
  },
  UNB_TRIPLE: {
    apiKey: 'UNB_TRIPLE',
    name: 'UNB Triple',
  },
  UNB_TRIPLE_PAIR: {
    apiKey: 'UNB_TRIPLE_PAIR',
    name: 'UNB Triple Pair',
  },
  UNB_TRIPLE_WING: {
    apiKey: 'UNB_TRIPLE_WING',
    name: 'UNB Triple Wing',
  },
  UNB_TRIPS: {
    apiKey: 'UNB_TRIPS',
    name: 'UNB Trips',
  },
  UNB_TRIPS_WING: {
    apiKey: 'UNB_TRIPS_WING',
    name: 'UNB Trips Wing',
  },
  UNB_TRIPS_WINK: {
    apiKey: 'UNB_TRIPS_WINK',
    name: 'UNB Trips Wink',
  },
  UNB_TWINS: {
    apiKey: 'UNB_TWINS',
    name: 'UNB Twins',
  },
  UNB_TWINS_PAIR: {
    apiKey: 'UNB_TWINS_PAIR',
    name: 'UNB Twins Pair',
  },
  UNB_TWINS_WING: {
    apiKey: 'UNB_TWINS_WING',
    name: 'UNB Twins Wing',
  },
  UNB_TWINS_WINK: {
    apiKey: 'UNB_TWINS_WINK',
    name: 'UNB Twins Wink',
  },
  TRIPLE_BUNCH: {
    apiKey: 'TRIPLE_BUNCH',
    name: 'Triple Bunch',
  },
  EMPTY_3X2_TRIO: {
    apiKey: 'EMPTY_3X2_TRIO',
    name: 'Empty 3x2 Trio',
  },
  UNB_DEUCE: {
    apiKey: 'UNB_DEUCE',
    name: 'UNB Deuce',
  },
};

export const FORMATIONS = Object.values(FORMATIONS_CONFIG);

export const STAT_BLOCKS = [
  'playCount',
  'totalPlayRate',
  'runRate',
  'playSuccessRate',
  'epaPerPlay',
  'netYardsPerPlay',
];

export const CHART_MAX_WIDTH = 600;

export const TRAITS_CONFIG = {
  BACKFIELD_ALIGNMENT: {
    traitKey: 'backfieldAlignment',
    label: 'Backfield Alignment',
  },
  FIB: {
    traitKey: 'fib',
    label: 'Fib',
  },
  HASH: {
    traitKey: 'hash',
    label: 'Hash',
  },
  MOTION: {
    traitKey: 'motion',
    label: 'Motion',
  },
  OFFENSE_PERSONNEL: {
    traitKey: 'offensePersonnel',
    label: 'Offense Personnel',
  },
  RUN_STRENGTH: {
    traitKey: 'runStrength',
    label: 'Run Strength',
  },
  WR_ALIGNMENT: {
    traitKey: 'wrAlignment',
    label: 'WR Alignment',
  },
};

export const FORMATION_DETAIL_STATS_CONFIG = {
  traitName: {
    order: 1,
    value: 'traitName',
    definition: {
      name: 'traitName',
      abbrev: 'Trait',
      description: 'Trait name',
      prettyName: 'Trait Name',
    },
  },
  playCount: {
    order: 2,
    value: 'playCount',
    apiStat: true,
    definition: {
      name: 'playCount',
      abbrev: 'Plays',
      description: 'Play Count\nHow many pass/run plays did the team have',
      prettyName: 'Plays',
      units: API_STAT_UNITS.COUNT,
    },
  },
  formationPlayRate: {
    order: 3,
    value: 'formationPlayRate',
    isDeltaStat: true,
    definition: {
      name: 'formationPlayRate',
      abbrev: 'Form %',
      description: '% of Formation Plays',
      prettyName: 'Formation Play Rate',
      units: API_STAT_UNITS.PERCENTAGE,
    },
  },
  totalPlayRate: {
    order: 4,
    value: 'totalPlayRate',
    isDeltaStat: true,
    definition: {
      name: 'totalPlayRate',
      abbrev: 'Play %',
      description: '% out of total plays',
      prettyName: 'Total Play Rate',
      units: API_STAT_UNITS.PERCENTAGE,
    },
  },
  runRate: {
    order: 5,
    value: 'runRate',
    isDeltaStat: true,
    statDistribution: true,
    csIntensityBlue: true,
    apiStat: true,
  },
  epaPerPlay: {
    order: 6,
    value: 'epaPerPlay',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  netYardsPerPlay: {
    order: 7,
    value: 'netYardsPerPlay',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  playSuccessRate: {
    order: 8,
    value: 'playSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  epaPerRush: {
    order: 9,
    value: 'epaPerRush',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  yardsPerRush: {
    order: 10,
    value: 'yardsPerRush',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  rushSuccessRate: {
    order: 11,
    value: 'rushSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  epaPerPass: {
    order: 12,
    value: 'epaPerPass',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  netYardsPerPass: {
    order: 13,
    value: 'netYardsPerPass',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  passSuccessRate: {
    order: 14,
    value: 'passSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
};

export const EXTRA_STAT_DEFINITIONS = Object.values(
  FORMATION_DETAIL_STATS_CONFIG
)
  .map((s) => s.definition)
  .filter(Boolean);
