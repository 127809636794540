import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

const context = { personnel: false, formation: false };

export const GET_TEAM_FORMATIONS = gql`
    query GetTeamFormations(
      $competitionId: Int!
      $seasonId: Int!
      $offenseTeamIds: [Int!]
      ${getContextQueryVariables(context)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      ${getContextFilterClientExports(context)}

      teamStats(
        groupBy: [FORMATION]
        filters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          offenseTeamIds: $offenseTeamIds
          ${getContextFiltersQueryArguments(context)}
        }
      ) {
        items {
          formationName
          playCount
        }
      }
    }
  `;
