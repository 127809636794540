import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { contourDensity, geoPath, scaleLinear, select } from 'd3';
import { useReactiveVar } from '@apollo/client';
import { maxBy } from 'lodash';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import {
  addLoSField,
  addLoSFieldYRelative,
} from '../../utils/helpers/fieldVariants';
import {
  FIELD_BORDER_PADDING,
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import { mf_LeagueLevel } from '../../apollo';
import {
  TACKLE_LOCATION_CLASSES,
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_DEFAULT_MARGIN,
  TACKLE_LOCATION_VIOLINS,
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLE_LOCATION_Y_MODE_DY,
  TACKLE_LOCATION_Y_MODE_FIELD,
  KEY_SEGMENT_HEIGHT,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLING_FIELD_DISPLAY_HEATMAP,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_FIELD_DISPLAY_PATHS,
  TACKLING_FIELD_AXES_PADDING,
} from './TackleLocation.constants';
import { rotateScaleZoom } from '../../utils/visualisations/rotateScaleZoom';
import {
  addPlayerColor,
  formatHeatmapData,
  formatTackleData,
  getFieldSize,
  getRotationSettings,
  getDistroData,
  getTackleMeans,
} from './TackleLocation.dataManipulation';
import {
  drawBallToGrounds,
  drawDistributions,
  drawTackleAttemptPaths,
  drawTackleAttempts,
  drawTadpoles,
} from './TackleLocation.drawing';
import { drawDistributionKey, drawKey } from './TackleLocation.key';
import { csHeatmap } from '../../utils/helpers/colorScales';
import { ClickableSVG } from '../visualisation.styles';

const TackleLocation = ({
  chartId,
  data,
  dataLA,
  orientation,
  displayYMode,
  displayXMode,
  displayXFocusedField,
  colorMode,
  scaleR,
  selectedPlay,
  setSelectedPlay,
  displayKey,
  distroAreaColorMode,
  ballCarriers,
  tacklers,
  fieldMode,
  fieldFocusOrigin,
  showHeatmapDots,
  showFirstTackleTails,
  selectedTacklerId,
  showDistribution,
  isInteractive,
}) => {
  /*
  svg
    |-within margins
      |-distros zone
      |-field zone (right of / under distros)
        |-field axes
        |-field area
      |-bar zone (right of / under field zone)
      |-key zone (under all the rest)
  */

  const margin = TACKLE_LOCATION_DEFAULT_MARGIN;

  const xLoSRelative = displayXMode === TACKLE_LOCATION_X_MODE_LOS.value;
  const isFieldYRelative = displayYMode !== TACKLE_LOCATION_Y_MODE_FIELD.value;
  const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;

  const fieldArea = getFieldSize(
    orientation,
    isFieldYRelative,
    displayXFocusedField
  );
  let keyHeight = 0;
  if (displayKey) {
    keyHeight =
      orientation === ROTATIONS.HORIZONTAL
        ? KEY_SEGMENT_HEIGHT * 2
        : KEY_SEGMENT_HEIGHT;
  }

  const distroWidth = TACKLE_LOCATION_VIOLINS.WIDTH;

  /* Spacing works inversely ~ with less width per violin pad them more */
  const distroSpacing = TACKLE_LOCATION_VIOLINS.SPACING * 2;
  const distroY =
    distroWidth * 3 + distroSpacing * 3 + TACKLE_LOCATION_VIOLINS.AXIS;
  const distroArea = {
    width: orientation === ROTATIONS.HORIZONTAL ? 0 : distroY,
    height: orientation === ROTATIONS.HORIZONTAL ? distroY : 0,
    violinWidth: distroWidth,
    violinSpacing: distroSpacing,
  };
  if (!showDistribution) {
    distroArea.width = 0;
    distroArea.height = 0;
  }

  const viewBox = `0 0 ${
    margin.left +
    margin.right +
    fieldArea.width +
    TACKLING_FIELD_AXES_PADDING.left +
    TACKLING_FIELD_AXES_PADDING.right +
    distroArea.width
  } ${
    margin.top +
    margin.bottom +
    fieldArea.height +
    TACKLING_FIELD_AXES_PADDING.top +
    TACKLING_FIELD_AXES_PADDING.bottom +
    keyHeight +
    distroArea.height
  }`;

  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const isDarkMode = theme.isDark;
  const competitionLevel = useReactiveVar(mf_LeagueLevel);

  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    orientation,
    competitionLevel,
    Y_BORDERS: 3 + 1 / 3,
    X_YARDS: fieldArea.fieldXYds,
    LOS_X: fieldArea.fieldLoS,
    hideYGuides: displayYMode === TACKLE_LOCATION_Y_MODE_DY.value,
  };

  const fieldDataClipPath = `${chartId}-active-field-area`;

  const ref = useD3((svg) => {
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', TACKLE_LOCATION_CLASSES.BACKGROUND);

    const marginTransform = `translate(${margin.left},${margin.top})`;
    svg
      .append('g')
      .attr('transform', marginTransform)
      .attr('class', TACKLE_LOCATION_CLASSES.IN_MARGINS);

    /* Add logic so clicking anywhere deselects */
    svg.on('click', (e) => {
      if (
        e.target.classList.value !== VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
      ) {
        setSelectedPlay(null);
      }
    });
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);
    svg
      .select(`.${TACKLE_LOCATION_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    /* Define based on size of field a clip-path for the data */
    svg.selectAll('defs').remove();
    const svgDefs = svg.append('defs');
    svgDefs
      .append('clipPath')
      .attr('id', fieldDataClipPath)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0.5)
      .attr('width', fieldArea.fieldSizeX - 1)
      .attr('height', fieldArea.fieldSizeY - 1);

    /* Primary G just sits in the margins */
    const withinMargingsG = svg.select(
      `.${TACKLE_LOCATION_CLASSES.IN_MARGINS}`
    );
    withinMargingsG.selectAll('g').remove();

    /* Space for the distributions */
    if (showDistribution) {
      withinMargingsG
        .append('g')
        .attr('class', TACKLE_LOCATION_CLASSES.DISTROS_AREA);
    }
    const fieldZoneTransform = `translate(${distroArea.width},${distroArea.height})`;
    const fieldZoneG = withinMargingsG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.FIELD_ZONE)
      .attr('transform', fieldZoneTransform);

    /* Adjust for Axes depending on orientation
      LoS field expects to put numbers (axes) outside the field proper
    */
    const axesTransform = `translate(${TACKLING_FIELD_AXES_PADDING.left},${TACKLING_FIELD_AXES_PADDING.top})`;
    const mainFieldG = fieldZoneG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.FIELD_IN_AXES)
      .attr('transform', axesTransform);
    /* Setup orientation and zoom stuff */
    const rszSettings = {
      baseG: mainFieldG,
      ...getRotationSettings(
        fieldArea.fieldSizeX,
        fieldArea.fieldSizeY,
        orientation
      ),
      cropToViewport: false,
    };
    const rszG = rotateScaleZoom(rszSettings);

    /* Add the relevant field & markings */
    const fieldMarkingsG = rszG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.FIELD_MARKINGS);
    if (isFieldYRelative) {
      fieldMarkingsG.call(addLoSFieldYRelative, overrides);
    } else {
      fieldMarkingsG.call(addLoSField, overrides);
    }
    /* Layer for the data */
    rszG.append('g').attr('class', TACKLE_LOCATION_CLASSES.FIELD_DATA);

    withinMargingsG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.KEY)
      .attr(
        'transform',
        `translate(0,${
          fieldArea.height +
          TACKLING_FIELD_AXES_PADDING.top +
          TACKLING_FIELD_AXES_PADDING.bottom +
          distroArea.height
        })`
      );
  }, [orientation, displayYMode, displayXFocusedField, keyHeight, theme]);

  useEffect(() => {
    const svg = select(ref.current);
    const distrosG = svg.select(`.${TACKLE_LOCATION_CLASSES.DISTROS_AREA}`);
    const dataG = svg.select(`.${TACKLE_LOCATION_CLASSES.FIELD_DATA}`);
    dataG.selectAll('g').remove();
    distrosG.selectAll('g').remove();

    if (data) {
      /* Distributions Section */
      const taDomain = [
        -fieldArea.fieldLoS,
        -fieldArea.fieldLoS + fieldArea.fieldXYds,
      ];
      const distroData = getDistroData(data, taDomain, fieldArea.fieldXYds);
      /* Means */
      if (dataLA) {
        const means = getTackleMeans(data, dataLA);
        if (means?.firstTackle) {
          drawDistributions(
            svg,
            chartId,
            distroData,
            dataLA,
            visPalette,
            orientation,
            fieldArea,
            TACKLING_FIELD_AXES_PADDING,
            distroArea,
            distroAreaColorMode,
            means
          );
        }
      }

      /* Field Stuff */
      const playersWithColor = addPlayerColor(ballCarriers, visPalette);
      const tackleData = formatTackleData(
        data,
        xLoSRelative,
        displayYMode,
        colorMode,
        visPalette,
        scaleR,
        selectedPlay,
        playersWithColor
      );
      const clippedDataG = dataG
        .append('g')
        .attr('clip-path', `url(#${fieldDataClipPath})`);

      /* Origin depends on coordinate system
      In Snap relative-y, it's the mid point, in real-field you just shift for the border
      */
      const heatmapG = clippedDataG.append('g');
      const tackleDataG = clippedDataG
        .append('g')
        .attr(
          'transform',
          `translate(${fieldArea.fieldLoS * pxPerYard},${
            isFieldYRelative
              ? (FIELD_Y_YARDS_RELATIVEY * pxPerYard) / 2
              : FIELD_BORDER_PADDING * pxPerYard
          })`
        );
      if (fieldMode === TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value) {
        if (fieldFocusOrigin) {
          drawTackleAttempts(
            tackleDataG,
            tackleData,
            setSelectedPlay,
            false,
            visPalette,
            isInteractive
          );
        } else if (showFirstTackleTails) {
          drawTadpoles(tackleDataG, tackleData, setSelectedPlay, isInteractive);
        } else {
          drawBallToGrounds(
            tackleDataG,
            tackleData,
            setSelectedPlay,
            false,
            visPalette,
            isInteractive
          );
        }
      }

      if (fieldMode === TACKLING_FIELD_DISPLAY_HEATMAP.value) {
        /* Heatmap has 3 area: background, the actual heatmap, then redraw guides atop that */
        const heatmapBackgroundG = heatmapG.append('g');
        const heatmapAreaG = heatmapG.append('g');
        const heatmapGuidesG = heatmapG.append('g');

        heatmapBackgroundG
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', fieldArea.fieldSizeX)
          .attr('height', fieldArea.fieldSizeY)
          .attr('fill', csHeatmap(0, isDarkMode));

        if (displayYMode === TACKLE_LOCATION_Y_MODE_FIELD.value) {
          heatmapAreaG.attr(
            'transform',
            `translate(0,${FIELD_BORDER_PADDING * pxPerYard})`
          );
        }
        const heatmapData = formatHeatmapData(
          data,
          xLoSRelative,
          displayYMode,
          fieldArea,
          fieldFocusOrigin
        );
        const xDomain = [0, fieldArea.fieldXYds];
        const xRange = [0, fieldArea.fieldSizeX];
        const yDomain =
          displayYMode === TACKLE_LOCATION_Y_MODE_FIELD.value
            ? [0, FIELD_Y_YARDS]
            : [0, FIELD_Y_YARDS_RELATIVEY];
        const yRange = [0, yDomain[1] * pxPerYard];
        /* compute the density data */
        const heatmapXScale = scaleLinear().domain(xDomain).range(xRange);
        const heatmapYScale = scaleLinear().domain(yDomain).range(yRange);
        const densityData = contourDensity()
          .x(({ heatmapX }) => heatmapXScale(heatmapX))
          .y(({ heatmapY }) => heatmapYScale(heatmapY))
          .size([xRange[1], yRange[1]])
          .bandwidth(30)(heatmapData);
        const maxDensity = (maxBy(densityData, 'value') || {}).value;
        heatmapAreaG
          .selectAll('path')
          .data(densityData)
          .enter()
          .append('path')
          .attr('d', geoPath())
          .attr('fill', ({ value }) =>
            csHeatmap(value / maxDensity, isDarkMode)
          ); // magma colors

        /* 
        Heatmap sits on top of all guides, so re-layer in the guides
          , but without the field backgrounds (add transparent override)
        */
        const heatmapVisPalette = { ...visPalette };
        heatmapVisPalette.zones.default = 'transparent';
        heatmapVisPalette.zones.alternate = 'transparent';
        const heatmapOverrides = {
          ...overrides,
          visPalette: heatmapVisPalette,
        };
        if (isFieldYRelative) {
          heatmapGuidesG.call(addLoSFieldYRelative, heatmapOverrides);
        } else {
          heatmapGuidesG.call(addLoSField, heatmapOverrides);
        }

        if (showHeatmapDots) {
          if (fieldFocusOrigin) {
            drawTackleAttempts(
              tackleDataG,
              tackleData,
              setSelectedPlay,
              true,
              visPalette,
              isInteractive
            );
          } else {
            drawBallToGrounds(
              tackleDataG,
              tackleData,
              setSelectedPlay,
              true,
              visPalette,
              isInteractive
            );
          }
        }
      }

      if (fieldMode === TACKLING_FIELD_DISPLAY_PATHS.value) {
        drawTackleAttemptPaths(
          tackleDataG,
          tackleData,
          selectedTacklerId,
          selectedPlay,
          setSelectedPlay,
          visPalette,
          isInteractive
        );
      }

      /* Rendering Keys */
      const keyG = svg.select(`.${TACKLE_LOCATION_CLASSES.KEY}`);
      keyG.selectAll('g').remove();
      keyG.selectAll('rect').remove();
      const keyWidth =
        orientation === ROTATIONS.HORIZONTAL
          ? fieldArea.fieldSizeX
          : fieldArea.fieldSizeY;
      if (displayKey) {
        const distroKeyG = keyG.append('g');
        const fieldKeyG = keyG
          .append('g')
          .attr(
            'transform',
            orientation === ROTATIONS.HORIZONTAL
              ? `translate(0,${KEY_SEGMENT_HEIGHT})`
              : `translate(${distroArea.width},0)`
          );

        if (showDistribution) {
          drawDistributionKey(
            distroKeyG,
            visPalette,
            distroAreaColorMode,
            distroArea.width
          );
        }

        drawKey(
          fieldKeyG,
          visPalette,
          keyWidth,
          colorMode,
          TACKLING_FIELD_AXES_PADDING,
          isDarkMode,
          scaleR,
          playersWithColor,
          fieldMode,
          fieldFocusOrigin,
          showHeatmapDots,
          selectedTacklerId,
          tackleData
        );
      }
    }
  }, [
    data,
    colorMode,
    displayYMode,
    displayXMode,
    visPalette,
    scaleR,
    displayXFocusedField,
    selectedPlay,
    setSelectedPlay,
    keyHeight,
    ballCarriers,
    tacklers,
    theme,
    fieldMode,
    fieldFocusOrigin,
    showHeatmapDots,
    showFirstTackleTails,
  ]);

  return <ClickableSVG ref={ref} />;
};

TackleLocation.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dataLA: PropTypes.arrayOf(PropTypes.shape({})),
  orientation: PropTypes.string,
  displayYMode: PropTypes.string,
  displayXMode: PropTypes.string,
  displayXFocusedField: PropTypes.bool,
  colorMode: PropTypes.string,
  scaleR: PropTypes.bool,
  selectedPlay: PropTypes.string,
  setSelectedPlay: PropTypes.func,
  displayKey: PropTypes.bool,
  distroAreaColorMode: PropTypes.string,
  ballCarriers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  tacklers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  fieldMode: PropTypes.string,
  fieldFocusOrigin: PropTypes.bool,
  showHeatmapDots: PropTypes.bool,
  showFirstTackleTails: PropTypes.bool,
  selectedTacklerId: PropTypes.number,
  // show distribution vilolin plot
  showDistribution: PropTypes.bool,
  // isInteractive: can you click on anything?
  isInteractive: PropTypes.bool,
};

TackleLocation.defaultProps = {
  chartId: 'tackling-chart',
  data: undefined,
  dataLA: undefined,
  orientation: ROTATIONS.VERTICAL_UP,
  displayYMode: TACKLE_LOCATION_Y_MODE_FIELD.value,
  displayXMode: TACKLE_LOCATION_X_MODE_LOS.value,
  displayXFocusedField: true,
  colorMode: TACKLE_LOCATION_COLOR_MODE_DX.value,
  scaleR: true,
  selectedPlay: null,
  setSelectedPlay: () => {},
  displayKey: true,
  distroAreaColorMode: TACKLE_LOCATION_DISTRO_COLOR_WINNER.value,
  ballCarriers: null,
  tacklers: null,
  fieldMode: TACKLING_FIELD_DISPLAY_HEATMAP.value,
  fieldFocusOrigin: true,
  showHeatmapDots: false,
  showFirstTackleTails: false,
  selectedTacklerId: 0,
  showDistribution: true,
  isInteractive: true,
};

export default TackleLocation;
