export const HAVOC_TILE_OPTIONS = [
  {
    label: 'Havoc Locations',
    value: 'havoc',
    variant: 'Position',
  },
  {
    label: 'Gap Table',
    value: 'gap',
    variant: 'ListView',
  },
  {
    label: 'Position Table',
    value: 'position',
    variant: 'ListView',
  },
];
