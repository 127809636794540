import {
  ROTATIONS,
  FIELD_Y_YARDS_RELATIVEY,
  FIELD_Y_YARDS,
} from '../constants/charting';
import { DEFAULT_FONT, VISUALISATION_FONT_SETUPS } from '../constants/visText';
import { COMPETITION_LEVEL, FIELD_MEASURES } from './field.constants';
import { RELATIVE_Y_FIELD_MEASURES } from './fieldVariants.constants';

const losDashArray = function ({ pxPerYard }) {
  return `${pxPerYard / 3} ${pxPerYard / 3}`;
};

const drawLOS = function (svg, fieldSettings, heightYds) {
  svg
    .append('g')
    .append('line')
    .attr('x1', fieldSettings.LOS_X * fieldSettings.pxPerYard)
    .attr('x2', fieldSettings.LOS_X * fieldSettings.pxPerYard)
    .attr('y1', 0)
    .attr('y2', heightYds * fieldSettings.pxPerYard)
    .attr(
      'stroke',
      fieldSettings.visPalette.commonComponents.lineOfScrimmage.stroke
    )
    .attr(
      'stroke-width',
      fieldSettings.visPalette.commonComponents.lineOfScrimmage.strokeWidth
    )
    .attr(
      'stroke-opacity',
      fieldSettings.visPalette.commonComponents.lineOfScrimmage.strokeOpacity
    )
    .attr(
      'stroke-dasharray',
      fieldSettings.visPalette.commonComponents.lineOfScrimmage.strokeDashArray
    );
};

const drawHorizontalGuide = function (
  svgG,
  fieldSettings,
  widthPx,
  yPx,
  isCenterLine = false
) {
  if (isCenterLine) {
    svgG
      .append('g')
      .append('line')
      .attr('x1', 0)
      .attr('x2', widthPx)
      .attr('y1', yPx)
      .attr('y2', yPx)
      .attr(
        'stroke',
        fieldSettings.visPalette.commonComponents.lineOfScrimmage.stroke
      )
      .attr('stroke-width', fieldSettings.strokeWidthPx)
      .attr('stroke-dasharray', losDashArray(fieldSettings));
  } else {
    /* Like the hash marks, one dash every yard, but half the height (not real hash marks) */
    const half_hash_dash_array = `${fieldSettings.strokeWidthPx} ${
      fieldSettings.pxPerYard - fieldSettings.strokeWidthPx
    }`;
    svgG
      .append('g')
      .append('line')
      .attr('x1', -fieldSettings.strokeWidthPx / 2) // offset so dashes match the field markings
      .attr('x2', widthPx)
      .attr('y1', yPx)
      .attr('y2', yPx)
      .attr('stroke', fieldSettings.visPalette.guides)
      .attr(
        'stroke-width',
        (FIELD_MEASURES.HASH_MARK_WIDTH / 2) * fieldSettings.pxPerYard
      )
      .attr('stroke-dasharray', half_hash_dash_array);
  }
};

const addHorizontalGuides = function (svgG, fieldSettings, widthPx) {
  // eslint-disable-next-line array-callback-return
  RELATIVE_Y_FIELD_MEASURES.Y_TENYARD_DISPLACEMENTS.map((n) => {
    const lineY = (FIELD_Y_YARDS_RELATIVEY / 2 + n) * fieldSettings.pxPerYard;
    svgG.call(drawHorizontalGuide, fieldSettings, widthPx, lineY, n === 0);
  });
};

const addNumberTopGuides = function (svgG, fieldSettings, widthPx) {
  const numberTopY =
    fieldSettings.competitionLevel === COMPETITION_LEVEL.NFL
      ? FIELD_MEASURES.FIELD_NUMBERS_EDGE_NFL +
        FIELD_MEASURES.FIELD_NUMBERS_HEIGHT
      : FIELD_MEASURES.FIELD_NUMBERS_EDGE_NCAA +
        FIELD_MEASURES.FIELD_NUMBERS_HEIGHT;

  svgG.call(
    drawHorizontalGuide,
    fieldSettings,
    widthPx,
    numberTopY * fieldSettings.pxPerYard
  );
  svgG.call(
    drawHorizontalGuide,
    fieldSettings,
    widthPx,
    (FIELD_Y_YARDS - numberTopY) * fieldSettings.pxPerYard
  );
};

const yardbreakToLOSNumber = function (d, losTenYardIndex) {
  if (d === losTenYardIndex) {
    return 'LoS';
  }
  return (d - losTenYardIndex) * 10;
};

const drawEdgeNumbersHorizontal = function (
  svg,
  fieldSettings,
  numbersToDraw,
  leftFieldEdge, // i.e. is it the edge of the field where y=0
  rotationOption,
  losTenYardIndex
) {
  const zeroEight = [...Array(numbersToDraw).keys()];
  const numbersYardsSeperated = 10;

  const numbersZone = svg.append('g').attr('id', 'ten-yd-numbers-edge');

  let rotateValue = 0;
  let textShiftY = leftFieldEdge
    ? -0.5 * fieldSettings.pxPerYard
    : 1.4 * fieldSettings.pxPerYard;
  let textAnchor = 'middle';
  let textShiftX = 0;
  if (rotationOption === ROTATIONS.VERTICAL_UP) {
    rotateValue = 90;
    textShiftY = leftFieldEdge
      ? -0.2 * fieldSettings.pxPerYard
      : 0.2 * fieldSettings.pxPerYard;
    textAnchor = leftFieldEdge ? 'end' : 'start';
    textShiftX = -0.3 * VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE;
  }
  if (rotationOption === ROTATIONS.VERTICAL_DOWN) {
    rotateValue = -90;
    textShiftY = leftFieldEdge
      ? -0.2 * fieldSettings.pxPerYard
      : 0.2 * fieldSettings.pxPerYard;
    textAnchor = leftFieldEdge ? 'start' : 'end';
    textShiftX = 0.3 * VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE;
  }

  numbersZone
    .selectAll('text')
    .data(zeroEight)
    .enter()
    .append('text')
    .attr('fill', fieldSettings.visPalette.text.label)
    .attr('x', 0)
    .attr('y', 0)
    .attr(
      'transform',
      (d) =>
        `translate(${
          (d + 1) * numbersYardsSeperated * fieldSettings.pxPerYard + textShiftX
        },${textShiftY}) rotate(${rotateValue})`
    )
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', textAnchor)
    .text((d) => yardbreakToLOSNumber(d, losTenYardIndex));
};

const addEdgeNumbersHorizontal = function (
  svgG,
  fieldSettings,
  heightYds,
  numbersToDraw = 9,
  rotationOption,
  losTenYardIndex = 1
) {
  svgG.append('g').attr('id', 'top-numbers-edge');
  drawEdgeNumbersHorizontal(
    svgG.select('#top-numbers-edge'),
    fieldSettings,
    numbersToDraw,
    true,
    rotationOption,
    losTenYardIndex
  );
  svgG
    .append('g')
    .attr('id', 'bottom-numbers-edge')
    .attr('transform', `translate(0,${heightYds * fieldSettings.pxPerYard})`);
  drawEdgeNumbersHorizontal(
    svgG.select('#bottom-numbers-edge'),
    fieldSettings,
    numbersToDraw,
    false,
    rotationOption,
    losTenYardIndex
  );
};

const addEdgeNumbersVertical = function (svgG, fieldSettings) {
  let rotateValue = 0;
  let textShiftX = -0.2 * fieldSettings.pxPerYard;
  let textAnchor = 'end';
  let textShiftY = VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE / 2;
  if (
    fieldSettings.orientation === ROTATIONS.VERTICAL_UP ||
    fieldSettings.orientation === ROTATIONS.VERTICAL_DOWN
  ) {
    rotateValue =
      fieldSettings.orientation === ROTATIONS.VERTICAL_UP ? 90 : -90;
    textShiftY = 0;
    textAnchor = 'middle';
    textShiftX =
      fieldSettings.orientation === ROTATIONS.VERTICAL_UP
        ? -1.2 * VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE
        : -0.5 * VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE;
  }

  // eslint-disable-next-line array-callback-return
  RELATIVE_Y_FIELD_MEASURES.Y_TENYARD_DISPLACEMENTS.map((n) => {
    const lineY = (FIELD_Y_YARDS_RELATIVEY / 2 + n) * fieldSettings.pxPerYard;
    const dyMarking = svgG
      .append('g')
      .attr('id', 'hash-marks-middle')
      .attr('transform', `translate(${0},${lineY})`);

    dyMarking
      .append('text')
      .attr('fill', fieldSettings.visPalette.text.label)
      .attr('x', 0)
      .attr('y', 0)
      .attr(
        'transform',
        `translate(${textShiftX},${textShiftY}) rotate(${rotateValue})`
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', textAnchor)
      .text(n);
  });
};

export {
  drawLOS,
  addEdgeNumbersHorizontal,
  addEdgeNumbersVertical,
  addHorizontalGuides,
  addNumberTopGuides,
};
