import React from 'react';
import PropTypes from 'prop-types';
import { BannerInfoWrapper } from './Banner.styles';

const BannerInfo = ({ title, message, margin }) => (
  <BannerInfoWrapper $margin={margin}>
    {title && <h3>{title}</h3>}
    {message && <p>{message}</p>}
  </BannerInfoWrapper>
);

BannerInfo.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  margin: PropTypes.string,
};

BannerInfo.defaultProps = {
  title: '',
  message: 'Error loading data',
  margin: '0',
};

export default BannerInfo;
