import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { BasicPageNoPadding } from '../../components/Layout/Layout.styles';
import { PlayFinderFilterTreeProvider } from './PlayFinderFilterTree/usePlayFinderFilterTreeContext';

const PlayFinder = () => {
  const [pageInfo, setPageInfo] = useOutletContext();

  return (
    <BasicPageNoPadding $top="52px">
      <PlayFinderFilterTreeProvider>
        <Outlet context={[pageInfo, setPageInfo]} />
      </PlayFinderFilterTreeProvider>
    </BasicPageNoPadding>
  );
};

export default PlayFinder;
