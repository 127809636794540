/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { StyledOption } from './FormationDropdown.styles';

export const Option = ({ data, ...rest }) => (
  <components.Option {...rest}>
    <StyledOption>
      <span className="label">{data.label}</span>
      <span className="secondary-label">{data.secondaryLabel}</span>
    </StyledOption>
  </components.Option>
);

Option.propTypes = {
  // the data of each option
  data: PropTypes.shape({
    value: PropTypes.string,
    secondaryValue: PropTypes.number,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
  }).isRequired,
};

export const SingleValue = ({ data, ...rest }) => (
  <components.SingleValue {...rest}>
    {`${data.secondaryValue} Plays`}
  </components.SingleValue>
);

SingleValue.propTypes = {
  // the data of each option
  data: PropTypes.shape({
    value: PropTypes.string,
    secondaryValue: PropTypes.number,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
  }).isRequired,
};
