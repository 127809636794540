import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@statsbomb/kitbag-components';
import ParsedLink from '../ParsedLink/ParsedLink';
import { GotoButton } from './Goto.styles';

// Button for redirecting to another location
const GoTo = ({ href, linkName, iconSize, disabled, ghost }) => (
  <GotoButton
    type="button"
    title={linkName}
    $disabled={disabled}
    $ghost={ghost}
  >
    <ParsedLink href={href}>
      <Icon size={iconSize} variant="Start" colour="primary.main" />
    </ParsedLink>
  </GotoButton>
);

GoTo.propTypes = {
  // url of the redirect destination
  href: PropTypes.string,
  // name of the destination page
  linkName: PropTypes.string,
  // icon size options: regular, small, tiny
  iconSize: PropTypes.string,
  // is the button disabled?
  disabled: PropTypes.bool,
  // emulate the ghost colour style
  ghost: PropTypes.bool,
};

GoTo.defaultProps = {
  href: '/',
  linkName: 'New Page',
  iconSize: 'small',
  disabled: false,
  ghost: false,
};

export default GoTo;
