import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { isArray } from 'lodash';
import { SliderHeader, SliderSection } from './MetricFilters.styles';
import {
  getHeaderTooltip,
  getScaleFormatter,
  orderValuesForPreciseValue,
} from '../ScoutResults.dataManipulation';
import { playerMetricProp } from '../../Scout.proptypes';
import NumericInput from '../../../../components/NumericInput/NumericInput';
import { NUMERIC_INPUT_ID } from '../../../../components/NumericInput/NumericInput.constants';
import MetricDualSlider from '../MetricDualSlider/MetricDualSlider';

const MetricFilters = ({ selectedMetrics, onSliderChange, removeMetric }) => {
  const [metricInEditMode, setMetricInEditMode] = useState(null);
  const [valueIndex, setValueIndex] = useState(null);

  return (
    <>
      {selectedMetrics?.map((metric) => {
        const headerTooltip = getHeaderTooltip(metric);
        const formatter = getScaleFormatter(
          metric.max - metric.min,
          metric?.unitType
        );
        const inputFormatter = getScaleFormatter(metric.max - metric.min, null);
        const inEditMode = metricInEditMode === metric.key;

        /* because early scout was a single value slider, have to have an upgrade path for singular values -> [] */
        const dualSliderValues = isArray(metric.value)
          ? metric.value
          : [metric.value, null];

        const numericSave = (val) => {
          const newPair =
            valueIndex === 0
              ? [val, dualSliderValues[1]]
              : [dualSliderValues[0], val];
          const orderedPair = orderValuesForPreciseValue(
            newPair,
            metric.lowerIsBetter
          );
          onSliderChange(metric, orderedPair);
          setMetricInEditMode(null);
          setValueIndex(null);
        };

        const editValue = (m, vI) => {
          setMetricInEditMode(m.key);
          setValueIndex(vI);
          setTimeout(() => {
            const textBox = document.getElementById(
              `textfield-${NUMERIC_INPUT_ID}`
            );
            if (textBox) {
              textBox.focus();
            }
          }, 50);
        };

        return (
          <SliderSection key={metric.key}>
            <SliderHeader>
              <h3 title={headerTooltip}>{metric.abbreviation}</h3>
              <ButtonIcon
                variant="secondary"
                onClick={() => removeMetric(metric)}
                size="small"
                icon="Delete"
                title={`Remove ${metric.abbreviation} from the list of filters`}
              />
            </SliderHeader>
            {!inEditMode && (
              <MetricDualSlider
                min={metric.min}
                max={metric.max}
                values={dualSliderValues}
                onValueChanged={(val) => onSliderChange(metric, val)}
                lowerIsBetter={metric.lowerIsBetter}
                formatter={formatter}
                onValueClick={(valIndex) => editValue(metric, valIndex)}
                metricName={metric?.abbreviation}
              />
            )}
            {inEditMode && (
              <NumericInput
                min={metric.min}
                max={metric.max}
                value={dualSliderValues[valueIndex]}
                onSave={(val) => numericSave(val)}
                onCancel={() => {
                  setMetricInEditMode(null);
                }}
                lowerIsBetter={metric.lowerIsBetter}
                formatter={inputFormatter}
              />
            )}
          </SliderSection>
        );
      })}
    </>
  );
};

MetricFilters.propTypes = {
  // metrics shape with added elements for filtering
  selectedMetrics: PropTypes.arrayOf(playerMetricProp).isRequired,
  // what to do on slider change
  onSliderChange: PropTypes.func.isRequired,
  // what to do when slider deleted
  removeMetric: PropTypes.func.isRequired,
};

export default MetricFilters;
