import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SideNavigation,
  SideNavigationItem,
  SideNavigationSubmenuItem,
  HamburgerMenu,
  Badge,
} from '@statsbomb/kitbag-components';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import ParsedLink from '../ParsedLink/ParsedLink';
import {
  SideNavConfig,
  isSectionSelected,
  isPageSelected,
} from './SideNav.config';
import { Aside } from './SideNav.styles';
import { useReactiveState } from '../../utils/hooks/useReactiveState';
import {
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Games,
  mf_Players,
  pf_Drive,
  pf_TeamPlay,
  ui_desktopMenuExpanded,
  ui_mobileMenuExpanded,
} from '../../apollo';
import {
  capitalizeFirstLetter,
  replaceHrefTokens,
} from '../../utils/helpers/general';
import ExternalLink from '../ExternalLink/ExternalLink';

const SideNav = () => {
  const location = useLocation();
  const { user, logout } = useKitbagAuth();

  const [leagueId] = useReactiveState(mf_Leagues);
  const [seasonId] = useReactiveState(mf_Seasons);
  const [teamId] = useReactiveState(mf_Teams);
  const [playerId] = useReactiveState(mf_Players);
  const [gameId] = useReactiveState(mf_Games);
  const [driveId] = useReactiveState(pf_Drive);
  const [playId] = useReactiveState(pf_TeamPlay);
  const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useReactiveState(
    ui_mobileMenuExpanded
  );
  const [isDesktopMenuExpanded, setIsDesktopMenuExpanded] = useReactiveState(
    ui_desktopMenuExpanded
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSectionId, setMobileSectionId] = useState(null);

  const urlParams = {
    leagues: leagueId,
    seasons: seasonId,
    teams: teamId,
    players: playerId,
    games: gameId,
    drives: driveId,
    plays: playId,
  };

  const isMobile = width <= 767;
  const currentLocation = location.pathname.slice(1);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onItemClick = ({ isSubmenuItem }, sectionId, name) => {
    if (isMobile && isSubmenuItem) {
      setMobileSectionId(null);
      ui_mobileMenuExpanded(false);
    } else if (isMobile && mobileSectionId === sectionId) {
      setMobileSectionId(null);
    } else if (name === 'Logout') {
      sessionStorage.removeItem('uiStateLocal');
      logout();
    } else {
      setMobileSectionId(sectionId);
    }
  };

  const formattedCurrentLocation = currentLocation
    .split('/')[0]
    .split('-')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');

  const authorizedNavConfig = SideNavConfig.filter(
    (section) =>
      !section.userGroup ||
      section.userGroup === user['https://statsbomb.com/accountID']
  );
  return (
    <Aside data-testid="left-nav" isMobileMenuOpen={isMobileMenuExpanded}>
      <HamburgerMenu
        width="30"
        alt="Menu Button"
        onClick={() => setIsMobileMenuExpanded(!isMobileMenuExpanded)}
        className="hamburger"
      />
      <SideNavigation
        isDesktopMenuExpanded={isDesktopMenuExpanded}
        isMobileMenuOpen={isMobileMenuExpanded}
        numberOfItems={SideNavConfig.length}
        onExpandButtonClick={() =>
          setIsDesktopMenuExpanded(!isDesktopMenuExpanded)
        }
        title={formattedCurrentLocation}
      >
        {authorizedNavConfig.map((section) => (
          <SideNavigationItem
            key={section.id}
            isItemSelected={
              isMobile
                ? mobileSectionId === section.id
                : isSectionSelected(currentLocation, section)
            }
            icon={section.icon}
            isMobile={isMobile}
            onItemClick={(params) =>
              onItemClick(params, section.id, section.links[0].name)
            }
            sectionName={section.name}
            position={section.position}
            component={ExternalLink}
            componentProps={{
              href: replaceHrefTokens(section.links[0].href, urlParams),
            }}
          >
            {/* TODO: remove the section.id condition once help has more links */}
            {(section.links?.length > 1 ||
              section.id === 'scout' ||
              section.id === 'playfinder') &&
              section.links.map(
                (subitem) =>
                  (subitem.userGroup ===
                    user['https://statsbomb.com/accountID'] ||
                    !subitem.userGroup) && (
                    <SideNavigationSubmenuItem
                      isSelected={isPageSelected(currentLocation, subitem.id)}
                      key={subitem.name}
                      onItemClick={(params) =>
                        onItemClick(params, section.id, subitem.name)
                      }
                    >
                      <ParsedLink href={subitem.href}>
                        {subitem.name}
                        {subitem.userGroup ===
                          user['https://statsbomb.com/accountID'] && (
                          <span
                            style={{
                              display: 'inline-flex',
                              verticalAlign: 'text-top',
                              paddingLeft: '.5rem',
                            }}
                          >
                            <Badge>WIP</Badge>
                          </span>
                        )}
                        {subitem?.inBeta && (
                          <span
                            style={{
                              display: 'inline-flex',
                              verticalAlign: 'text-top',
                              paddingLeft: '.5rem',
                            }}
                          >
                            <Badge>BETA</Badge>
                          </span>
                        )}
                      </ParsedLink>
                    </SideNavigationSubmenuItem>
                  )
              )}
          </SideNavigationItem>
        ))}
      </SideNavigation>
    </Aside>
  );
};

export default SideNav;
