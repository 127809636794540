import { useQuery } from '@apollo/client';
import { sortBy, sumBy } from 'lodash';
import { getRunAttemptsGQLString } from './getRunAttempts';
import {
  formatAPIRunData,
  formatAPIRunLeagueAverageData,
} from './RunTendencies.dataManipulation';
import { POINT_OF_ATTACK_DOMAIN } from './RunTendencies.constants';
import { getRunAttemptsLeagueAverageGQLString } from './getRunAttemptsLA';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';

export const useGetRunGapData = () => {
  const {
    data: gapData,
    loading: gapLoading,
    error: gapError,
  } = useQuery(getRunAttemptsGQLString(true));

  const {
    data: poaData,
    loading: poaLoading,
    error: poaError,
  } = useQuery(getRunAttemptsGQLString(false));

  const loading = gapLoading || poaLoading;
  if (loading) {
    return { loading, teamRunGapData: [], teamRunPOAData: [], totalPlays: 0 };
  }
  if (gapError) {
    console.error(ERROR_CODES.USE_GET_RUN_GAP_DATA, gapError);
    return {
      error: gapError,
      loading,
      teamRunGapData: [],
      teamRunPOAData: [],
      totalPlays: 0,
    };
  }
  if (poaError) {
    console.error(ERROR_CODES.USE_GET_RUN_POA_DATA, poaError);
    return {
      error: poaError,
      loading,
      teamRunGapData: [],
      teamRunPOAData: [],
      totalPlays: 0,
    };
  }

  const rawRunGapData = gapData?.offensiveLineRunGaps;
  const rawRunPOAData = poaData?.offensiveLineRunGaps;
  if (!rawRunGapData?.length || !rawRunPOAData?.length) {
    return {
      teamRunGapData: [],
      teamRunPOAData: [],
      loading: false,
      totalPlays: 0,
    };
  }

  const formattedGapData = formatAPIRunData(rawRunGapData, true);
  const formattedPOAData = formatAPIRunData(rawRunPOAData, false);

  /* Ignore D Gap data until TE logic gets applied */
  const relevantGapData = formattedGapData.filter((d) => d.runGap);

  const totalPlays = sumBy(formattedPOAData, 'plays');
  /* Filter POA down to data in desired range */
  const domainPOAData = formattedPOAData.filter(
    (d) =>
      d.poaY !== null &&
      d.poaY >= POINT_OF_ATTACK_DOMAIN[0] &&
      d.poaY <= POINT_OF_ATTACK_DOMAIN[1]
  );

  return {
    teamRunGapData: relevantGapData,
    teamRunPOAData: domainPOAData,
    totalPlays,
  };
};

export const useGetRunLeagueAverageData = () => {
  const {
    data: gapData,
    loading: gapLoading,
    error: gapError,
  } = useQuery(getRunAttemptsLeagueAverageGQLString(true));

  const {
    data: poaData,
    loading: poaLoading,
    error: poaError,
  } = useQuery(getRunAttemptsLeagueAverageGQLString(false));

  const loading = gapLoading || poaLoading;
  if (loading) {
    return { loading, leagueRunGapData: [], leagueRunPOAData: [] };
  }
  if (gapError) {
    console.error(ERROR_CODES.USE_GET_RUN_GAP_DATA_LA, gapError);
    return {
      error: gapError,
      loading,
      leagueRunGapData: [],
      leagueRunPOAData: [],
    };
  }
  if (poaError) {
    console.error(ERROR_CODES.USE_GET_RUN_POA_DATA_LA, poaError);
    return {
      error: poaError,
      loading,
      leagueRunGapData: [],
      leagueRunPOAData: [],
    };
  }

  const rawRunGapData = gapData?.offensiveLineRunGapsLA;
  const rawRunPOAData = poaData?.offensiveLineRunGapsLA;
  if (!rawRunGapData?.length || !rawRunPOAData?.length) {
    return { leagueRunGapData: [], leagueRunPOAData: [] };
  }

  const formattedGapData = formatAPIRunLeagueAverageData(rawRunGapData, true);
  const formattedPOAData = formatAPIRunLeagueAverageData(rawRunPOAData, false);

  /* Ignore D Gap data until TE logic gets applied */
  const relevantGapData = sortBy(
    formattedGapData.filter((d) => d.runGap),
    'runGap'
  );

  /* Filter POA down to data in desired range */
  const domainPOAData = sortBy(
    formattedPOAData.filter(
      (d) =>
        d.poaY !== null &&
        d.poaY >= POINT_OF_ATTACK_DOMAIN[0] &&
        d.poaY <= POINT_OF_ATTACK_DOMAIN[1]
    ),
    'poaY'
  );

  return {
    leagueRunGapData: relevantGapData,
    leagueRunPOAData: domainPOAData,
  };
};
