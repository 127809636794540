// icon sizes terminology and values are taken from the shared component library
export const POSITION_ICON_SIZES = {
  REGULAR: 'regular',
  SMALL: 'small',
  X_SMALL: 'tiny',
};

export const POSITION_ICON_SIZE_VALUES = {
  [POSITION_ICON_SIZES.REGULAR]: 24,
  [POSITION_ICON_SIZES.SMALL]: 16,
  [POSITION_ICON_SIZES.X_SMALL]: 12,
};

export const POSITION_ICON_FONT_SIZE_VALUES = {
  [POSITION_ICON_SIZES.REGULAR]: 14,
  [POSITION_ICON_SIZES.SMALL]: 12,
  [POSITION_ICON_SIZES.X_SMALL]: 10,
};
