import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_SNAP_DISTRIBUTIONS = gql`
  query GetSnapDistributions(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedTeamId @client @export(as: "teamId")
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedPlayerId @client @export(as: "playerId")
    ${getContextFilterClientExports()}

    snapEvents(
      first: 1000
      eventFilters: { freezeFramePlayerIds: [$playerId] }
      playFilters: {
        seasonId: $seasonId
        competitionId: $competitionId
        teamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      edges {
        node {
          id
          x
          y
          play {
            id
            type
            down
            yardsNet
            success
            explosive
            touchdownWon
            name
            drive {
              id
              name
            }
          }
          player {
            id
            name
          }
          team {
            name
          }
          freezeFrames {
            x
            y
            team {
              name
            }
            player {
              id
              name
              teamPosition {
                id
                name
                offensive
                generalPosition
              }
            }
          }
          game {
            id
            name
            shortName
          }
        }
      }
    }
  }
`;
