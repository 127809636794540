import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Toggle, Checkbox } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import uiDefault from '../../apollo/uiDefault';
import { returnReadableYardsLabel } from '../../utils/helpers/general';
import { convertIntToNth } from '../../utils/helpers/formatting';
import { enableFilter } from './Filters.helper';
import {
  uiState,
  cf_Down,
  cf_DownEnabled,
  cf_Distance,
  cf_DistanceEnabled,
} from '../../apollo';
import {
  StyledModal,
  StyledSlider,
  TriggerButtonContainer,
} from './ContextFilters.styles';
import { useIsEligiblePage } from './ContextFilters.hooks';

const DistanceFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_DistanceOpen, setDistanceOpen] = useState(false);
  // context filters in global state
  const cf_DownRV = useReactiveVar(cf_Down);
  const cf_DownEnabledRV = useReactiveVar(cf_DownEnabled);
  const cf_DistanceRV = useReactiveVar(cf_Distance);
  const cf_DistanceEnabledRV = useReactiveVar(cf_DistanceEnabled);

  // context filters in local state
  const [st_Down, setDown] = useState(cf_DownRV);
  const [st_Distance, setDistance] = useState(cf_DistanceRV);

  const handleDown = (isDownChecked, index) => {
    const downs = [...st_Down];
    downs[index] = isDownChecked;
    setDown([...downs]);
    enableFilter(cf_DownEnabledRV, cf_DownEnabled, 'down');
  };

  const handleShort = () => {
    setDistance([0, 2]);
    enableFilter(cf_DistanceEnabledRV, cf_DistanceEnabled, 'distance');
  };

  const handleMedium = () => {
    setDistance([2, 7]);
    enableFilter(cf_DistanceEnabledRV, cf_DistanceEnabled, 'distance');
  };

  const handleLong = () => {
    setDistance([7, 21]);
    enableFilter(cf_DistanceEnabledRV, cf_DistanceEnabled, 'distance');
  };

  const handleDistance = (sliderValues) => {
    setDistance([...sliderValues]);
  };

  const handleModalClose = () => {
    const { contextFilters } = uiState();
    const { down, distance } = contextFilters;

    cf_Down([...st_Down]);
    down.value = [...st_Down];
    cf_DownEnabled(cf_DownEnabledRV);

    cf_Distance([...st_Distance]);
    distance.value = [...st_Distance];
    cf_DistanceEnabled(cf_DistanceEnabledRV);

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setDistanceOpen(false);
  };

  const handleReset = () => {
    const { down, distance } = uiDefault.contextFilters;

    setDown([...down.value]);
    cf_DownEnabled(down.enabled);
    setDistance([...distance.value]);
    cf_DistanceEnabled(distance.enabled);

    uiState().contextFilters.down.value = [...down.value];
    uiState().contextFilters.down.enabled = down.enabled;
    uiState().contextFilters.distance.value = [...distance.value];
    uiState().contextFilters.distance.enabled = distance.enabled;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const filterCount = [cf_DownEnabledRV, cf_DistanceEnabledRV].filter(
    Boolean
  ).length;
  const isEligiblePage = useIsEligiblePage('DOWN_AND_DISTANCE');
  const isEnabled = filterCount > 0 && isEligiblePage;

  return (
    <StyledModal
      onClose={() => handleModalClose(setDistanceOpen)}
      onOpen={() => {
        setDistanceOpen(true);
      }}
      open={st_DistanceOpen}
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Down & Distance
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size="tiny"
    >
      <Modal.Content>
        <h2>Down and Distance</h2>
        <Form>
          <Form.Field>
            <Toggle
              id="down-cf-toggle"
              label="Down"
              onChange={() =>
                handleEnabledToggle(!cf_DownEnabledRV, cf_DownEnabled, 'down', {
                  enabled: !cf_DownEnabledRV,
                })
              }
              checked={cf_DownEnabledRV}
              data-testid={123}
            />
          </Form.Field>
          <Form.Group widths="equal">
            {st_Down &&
              st_Down.map((down, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Form.Field key={`down-field-${index}`}>
                  <Checkbox
                    id={`down-field-${index}-checkbox`}
                    label={index < 4 ? convertIntToNth(index + 1) : 'Extra'}
                    onChange={() => handleDown(!down, index)}
                    value={String(st_Down[index])}
                    checked={down}
                  />
                </Form.Field>
              ))}
          </Form.Group>
          <hr />
          <Form.Field>
            <Toggle
              id="distance-to-go-cf-toggle"
              label="Distance to go"
              onChange={() =>
                handleEnabledToggle(
                  !cf_DistanceEnabledRV,
                  cf_DistanceEnabled,
                  'distance',
                  {
                    enabled: !cf_DistanceEnabledRV,
                  }
                )
              }
              checked={cf_DistanceEnabledRV}
            />
            <b
              className={classNames({
                grey: !cf_DistanceEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
            >
              {st_Distance && returnReadableYardsLabel(st_Distance, 0, 21)}
            </b>
          </Form.Field>
          <Form.Field>
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_Distance}
              ariaLabel={['yardsToGoMin', 'yardsToGoMax']}
              onChange={handleDistance}
              onAfterChange={() =>
                enableFilter(
                  cf_DistanceEnabledRV,
                  cf_DistanceEnabled,
                  'distance'
                )
              }
              pearling
              minDistance={1}
              min={0}
              max={21}
            />
            <div className="track extreme">
              <div />
              <div>&rsaquo;</div>
            </div>
            <div className="track distance">
              {Array(21)
                .fill()
                .map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${index}-yards`}>{index}</div>
                ))}
            </div>
            <div className="shortcuts">
              <Button size="small" onClick={handleShort}>
                Short
              </Button>
              <Button size="small" onClick={handleMedium}>
                Medium
              </Button>
              <Button size="small" onClick={handleLong}>
                Long
              </Button>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleReset}>
          Reset
        </Button>
        <Button onClick={() => handleModalClose(setDistanceOpen)}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

DistanceFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default DistanceFilter;
