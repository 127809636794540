import { styled } from 'styled-components';

export const OffensiveLinePositionDropdown = styled.div`
  width: 20%;
  float: left;
  margin: 0px;
  padding: 0px 8px;
`;
OffensiveLinePositionDropdown.displayName = 'OffensiveLinePositionDropdown';

export const OffensiveLinePositionSpacer = styled.div`
  width: 40%;
  float: left;
  margin: 0px;
  padding: 0px 8px;
`;
OffensiveLinePositionSpacer.displayName = 'OffensiveLinePositionSpacer';

export const OffensivePlayerFiltersRow = styled.div`
  width: 100%;
  display: table;
  margin: 0px;
  padding: 0px;
`;
OffensivePlayerFiltersRow.displayName = 'OffensivePlayerFiltersRow';
