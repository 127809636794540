import { inRange } from 'lodash';
import { convertIntToNth } from './formatting';

// Takes an integer between 1-4 and returns a formatted down name i.e '3rd Down'
// Any other value passed will return 'Special Down'
const getDownName = (down) => {
  if (Number.isInteger(down) && inRange(down, 1, 5)) {
    return `${convertIntToNth(down)} Down`;
  }

  return 'Special Down';
};

/**
 * @param {Number} yardsToGo
 * @return {string}
 */
const getToGoName = (yardsToGo) => {
  if (yardsToGo === null || yardsToGo === undefined) {
    return ``;
  }
  return `${yardsToGo.toFixed(1)} yds to go`;
};

/**
 * @param {Number} yardline
 * @returns {String}
 */
const getYardlineName = (yardline) => {
  if (!yardline) {
    return '';
  }
  if (yardline <= 50) {
    return `Own ${yardline.toFixed(0)}`;
  }
  return `Opp ${(100 - yardline).toFixed(0)}`;
};

/**
 * @param {Number} yardsNet
 * @returns {String}
 */
const getYardsWon = (yardsNet) => {
  if (!yardsNet && yardsNet !== 0) {
    return ``;
  }
  return yardsNet > 0 ? `+${yardsNet.toFixed(1)}` : yardsNet.toFixed(1);
};

export { getDownName, getYardlineName, getToGoName, getYardsWon };
