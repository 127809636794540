import {
  addPosts,
  addOddEvenZones,
  addCompetitionHashMarks,
  addFieldEdgeMarks,
  addEndZones,
  addFieldBorder,
  addFieldNumbers,
} from './field.drawing';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from './field.constants';

const addField = function (svg, fieldSettingOverrides = {}) {
  /* Make sure we have all the field settings for drawing functions */
  const fieldSettings = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    ...fieldSettingOverrides,
  };

  /* create a G element for the field aspects to all sit in 
  id for finding in browser when needed, and class if overriding styles via css
  */
  const fieldArea = svg
    .append('g')
    .attr('id', 'field-area')
    .attr('class', 'amf-field');

  /* [Optional] Draw the Goal Posts */
  if (fieldSettings.showGoalPosts) {
    fieldArea.call(addPosts, fieldSettings);
  }
  /* Draw the oscillating 10yd zones */
  fieldArea.call(addOddEvenZones, fieldSettings);
  /* Draw the standard hash marks based on comp level */
  fieldArea.call(addCompetitionHashMarks, fieldSettings);
  fieldArea.call(addFieldEdgeMarks, fieldSettings);
  /* Draw the end zones */
  fieldArea.call(addEndZones, fieldSettings);
  /* Draw the overall border */
  fieldArea.call(addFieldBorder, fieldSettings);

  /* [Optional] Draw the Field Numbers */
  if (fieldSettings.showFieldNumbers) {
    fieldArea.call(addFieldNumbers, fieldSettings);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { addField };
