/* eslint-disable prettier/prettier */
export const defaultContextFilters = {
  downs: true,
  noDowns: true,
  yardsToGoMin: true,
  yardsToGoMax: true,
  fieldPositionMin: true,
  fieldPositionMax: true,
  quarters: true,
  clockMin: true,
  clockMax: true,
  yardsGainedMin: true,
  yardsGainedMax: true,
  playTypes: true,
  passAirYardsMin: true,
  passAirYardsMax: true,
  qbDropDepthMin: true,
  qbDropDepthMax: true,
  qbTimeToThrowMin: true,
  qbTimeToThrowMax: true,
  qbPassLocation: true,
  personnel: true,
  formation: true,
  gameIds: true,
};

export const getContextQueryVariables = (overrides) => {
  const contextFilters = {
    ...defaultContextFilters,
    ...overrides,
  };

  return `
    ${contextFilters.downs ? '$downs: [Int!]' : ''}
    ${contextFilters.noDowns ? '$noDowns: Boolean' : ''}
    ${contextFilters.yardsToGoMin ? '$yardsToGoMin: Float' : ''}
    ${contextFilters.yardsToGoMax ? '$yardsToGoMax: Float' : ''}
    ${contextFilters.fieldPositionMin ? '$fieldPositionMin: Float' : ''}
    ${contextFilters.fieldPositionMax ? '$fieldPositionMax: Float' : ''}
    ${contextFilters.quarters ? '$quarters: [Int!]' : ''}
    ${contextFilters.clockMin ? '$clockMin: Int' : ''}
    ${contextFilters.clockMax ? '$clockMax: Int' : ''}
    ${contextFilters.yardsGainedMin ? '$yardsGainedMin: Float' : ''}
    ${contextFilters.yardsGainedMax ? '$yardsGainedMax: Float' : ''}
    ${contextFilters.playTypes ? '$playTypes: [PlayType!]' : ''}
    ${contextFilters.passAirYardsMin ? '$passAirYardsMin: Float' : ''}
    ${contextFilters.passAirYardsMax ? '$passAirYardsMax: Float' : ''}
    ${contextFilters.qbDropDepthMin ? '$qbDropDepthMin: Float' : ''}
    ${contextFilters.qbDropDepthMax ? '$qbDropDepthMax: Float' : ''}
    ${contextFilters.qbTimeToThrowMin ? '$qbTimeToThrowMin: Float' : ''}
    ${contextFilters.qbTimeToThrowMax ? '$qbTimeToThrowMax: Float' : ''}
    ${contextFilters.qbPassLocation ? '$qbPassLocation: [PocketRelativeLocation!]' : ''}
    ${contextFilters.personnel ? '$personnel: PersonnelFilter' : ''}
    ${contextFilters.formation ? '$formation: FormationFilter' : ''}
    ${contextFilters.gameIds ? '$gameIds: [Int!]' : ''}
  `;
};

export const getContextFilterClientExports = (overrides) => {
  const contextFilters = {
    ...defaultContextFilters,
    ...overrides,
  };

  return `
    ${contextFilters.downs ? 'contextFilterDowns @client @export(as: "downs")' : ''}
    ${contextFilters.noDowns ? 'contextFilterNoDowns @client @export(as: "noDowns")' : ''}
    ${contextFilters.yardsToGoMin ? 'contextFilterYardsToGoMin @client @export(as: "yardsToGoMin")' : ''}
    ${contextFilters.yardsToGoMax ? 'contextFilterYardsToGoMax @client @export(as: "yardsToGoMax")' : ''}
    ${contextFilters.fieldPositionMin ? 'contextFilterFieldPositionMin @client @export(as: "fieldPositionMin")' : ''}
    ${contextFilters.fieldPositionMax ? 'contextFilterFieldPositionMax @client @export(as: "fieldPositionMax")' : ''}
    ${contextFilters.quarters ? 'contextFilterQuarter @client @export(as: "quarters")' : ''}
    ${contextFilters.clockMin ? 'contextFilterClockMin @client @export(as: "clockMin")' : ''}
    ${contextFilters.clockMax ? 'contextFilterClockMax @client @export(as: "clockMax")' : ''}
    ${contextFilters.yardsGainedMin ? 'contextFilterYardsGainedMin @client @export(as: "yardsGainedMin")' : ''}
    ${contextFilters.yardsGainedMax ? 'contextFilterYardsGainedMax @client @export(as: "yardsGainedMax")' : ''}
    ${contextFilters.playTypes ? 'contextFilterPlayType @client @export(as: "playTypes")' : ''}
    ${contextFilters.passAirYardsMin ? 'contextFilterPassAirYardsMin @client @export(as: "passAirYardsMin")' : ''}
    ${contextFilters.passAirYardsMax ? 'contextFilterPassAirYardsMax @client @export(as: "passAirYardsMax")' : ''}
    ${contextFilters.qbDropDepthMin ? 'contextFilterQBDropDepthMin @client @export(as: "qbDropDepthMin")' : ''}
    ${contextFilters.qbDropDepthMax ? 'contextFilterQBDropDepthMax @client @export(as: "qbDropDepthMax")' : ''}
    ${contextFilters.qbTimeToThrowMin ? 'contextFilterQBTimeToThrowMin @client @export(as: "qbTimeToThrowMin")' : ''}
    ${contextFilters.qbTimeToThrowMax ? 'contextFilterQBTimeToThrowMax @client @export(as: "qbTimeToThrowMax")' : ''}
    ${contextFilters.qbPassLocation ? 'contextFilterQBPocketLocation @client @export(as: "qbPassLocation")' : ''}
    ${contextFilters.personnel ? 'contextFilterPersonnel @client @export(as: "personnel")' : ''}
    ${contextFilters.formation ? 'contextFilterFormations @client @export(as: "formation")' : ''}
    ${contextFilters.gameIds ? 'contextFilterGameIds @client @export(as: "gameIds")' : '' }
  `;
};

export const getContextFiltersQueryArguments = (overrides) => {
  const contextFilters = {
    ...defaultContextFilters,
    ...overrides,
  };

  return `
    ${contextFilters.downs ? 'downs: $downs' : ''}
    ${contextFilters.noDowns ? 'noDowns: $noDowns' : ''}
    ${contextFilters.yardsToGoMin ? 'yardsToGoMin: $yardsToGoMin' : ''}
    ${contextFilters.yardsToGoMax ? 'yardsToGoMax: $yardsToGoMax' : ''}
    ${contextFilters.fieldPositionMin ? 'fieldPositionMin: $fieldPositionMin' : ''}
    ${contextFilters.fieldPositionMax ? 'fieldPositionMax: $fieldPositionMax' : ''}
    ${contextFilters.quarters ? 'quarters: $quarters' : ''}
    ${contextFilters.clockMin ? 'clockMin: $clockMin' : ''}
    ${contextFilters.clockMax ? 'clockMax: $clockMax' : ''}
    ${contextFilters.yardsGainedMin ? 'yardsGainedMin: $yardsGainedMin' : ''}
    ${contextFilters.yardsGainedMax ? 'yardsGainedMax: $yardsGainedMax' : ''}
    ${contextFilters.playTypes ? 'playTypes: $playTypes' : ''}
    ${contextFilters.passAirYardsMin ? 'passAirYardsMin: $passAirYardsMin' : ''}
    ${contextFilters.passAirYardsMax ? 'passAirYardsMax: $passAirYardsMax' : ''}
    ${contextFilters.qbDropDepthMin ? 'qbDropDepthMin: $qbDropDepthMin' : ''}
    ${contextFilters.qbDropDepthMax ? 'qbDropDepthMax: $qbDropDepthMax' : ''}
    ${contextFilters.qbTimeToThrowMin ? 'qbTimeToThrowMin: $qbTimeToThrowMin' : ''}
    ${contextFilters.qbTimeToThrowMax ? 'qbTimeToThrowMax: $qbTimeToThrowMax' : ''}
    ${contextFilters.qbPassLocation ? 'qbPassLocation: $qbPassLocation' : ''}
    ${contextFilters.personnel ? 'personnel: $personnel' : ''}
    ${contextFilters.formation ? 'formation: $formation' : ''}
    ${contextFilters.gameIds ? 'gameIds: $gameIds' : ''}
  `;
};
