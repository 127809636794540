import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@statsbomb/kitbag-components';
import PlayerPassEndsTile from './PlayerPassEnds.Tile';
import PlayerPassPlacementTile from './PlayerPassPlacement.Tile';
import PlayerSnapTile from './PlayerSnap.Tile';
import PlayerRadarTile from './PlayerRadar.Tile';
import PlayerStatsTile from './PlayerStats.Tile';
import PlayerTacklingTile from './PlayerTackling.Tile';
import PlayerHavocTile from './PlayerHavoc.Tile';
import { mf_PlayerDetails, mf_Players } from '../../../apollo';
import { useReactiveState } from '../../../utils/hooks/useReactiveState';
import { GET_PLAYERS } from '../../../components/Header/getPlayers';
import {
  PlayerHavocTileContainer,
  PlayerPassEndsTileContainer,
  PlayerSnapTileContainer,
  PlayerTacklingTileContainer,
  TileContentVerticallyCentered,
} from './PlayerOverview.styles';
import {
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_SCALES,
} from '../../../visualisations/PassPlacement/PassPlacement.constants';
import { GRAPH_OPTIONS } from '../../../visualisations/PassingChart/PassingChart.constants';
import PlayerRoutesTile from './PlayerRoutes.Tile';
import PlayerComparisonTile from './PlayerComparison.Tile';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PlayerBioTile from './PlayerBio.Tile';

const PlayerOverview = () => {
  const [playerDetails, setPlayerDetails] = useReactiveState(mf_PlayerDetails);
  const { data: playersData } = useQuery(GET_PLAYERS);
  const { mostCommonPosition: position, id: playerId } = playerDetails || {};

  useEffect(() => {
    setPlayerDetails(
      playersData?.players?.edges.find(
        (player) => player.node.id === mf_Players()
      )?.node
    );
  }, [playersData]);

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <PageHeader />
        </Grid>
        <Grid item xs={12}>
          <PlayerBioTile />
        </Grid>
        <Grid container={false}>
          <div
            style={{
              margin: '0.5rem 0 0 0',
              display: 'flex',
              width: 'calc(100% - 0.5rem)',
            }}
          >
            {playerDetails &&
              (position?.code === 'QB' ||
                position?.code === 'LB' ||
                position?.code === 'DB' ||
                position?.code === 'DL' ||
                position?.code === 'WR' ||
                position?.code === 'TE' ||
                position?.code === 'OL' ||
                position?.code === 'RB') && (
                <Grid item xs={6}>
                  <PlayerRadarTile />
                </Grid>
              )}
            <Grid item xs={6}>
              {playerDetails && <PlayerStatsTile />}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          {playerDetails && position?.code === 'QB' && (
            <>
              <Grid>
                <Grid item xs={3}>
                  {playerId && (
                    <PlayerComparisonTile
                      margin="0 0 0 0"
                      playerId={playerId}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <PlayerPassEndsTile
                    graphOptionDefault={GRAPH_OPTIONS[2].value}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TileContentVerticallyCentered>
                    <PlayerPassPlacementTile
                      selectedScale={PASS_PLACEMENT_SCALES[0]} // 2:1 aspect ratio
                    />
                  </TileContentVerticallyCentered>
                </Grid>
              </Grid>
            </>
          )}

          {playerDetails && position?.code === 'WR' && (
            <Grid>
              <Grid item xs={6}>
                <PlayerPassEndsTile />
              </Grid>

              <Grid item xs={2}>
                <PlayerSnapTile padding="0 11%" />
              </Grid>

              <Grid item xs={4}>
                <PlayerRoutesTile />
              </Grid>

              <Grid item xs={5}>
                <PlayerPassPlacementTile
                  selectedScale={PASS_PLACEMENT_SCALES[1]} // 3:2 aspect ratio
                />
              </Grid>

              <Grid item xs={3}>
                {playerId && (
                  <PlayerComparisonTile margin="0 0 0 0" playerId={playerId} />
                )}
              </Grid>
            </Grid>
          )}

          {playerDetails && position?.code === 'RB' && (
            <>
              <Grid>
                <Grid item xs={5}>
                  <PlayerTacklingTileContainer $marginBottom="-20px">
                    <PlayerTacklingTile />
                  </PlayerTacklingTileContainer>
                </Grid>

                <Grid item xs={2}>
                  <PlayerSnapTileContainer>
                    <PlayerSnapTile padding="0 17%" />
                  </PlayerSnapTileContainer>
                </Grid>

                <Grid item xs={5}>
                  <PlayerPassEndsTileContainer>
                    <PlayerPassEndsTile />
                  </PlayerPassEndsTileContainer>
                </Grid>

                <Grid item xs={3}>
                  {playerId && (
                    <PlayerComparisonTile
                      margin="0 0 0 0"
                      playerId={playerId}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {playerDetails && position?.code === 'TE' && (
            <Grid>
              <Grid item xs={4}>
                <PlayerPassEndsTile />
              </Grid>

              <Grid item xs={2}>
                <PlayerSnapTileContainer>
                  <PlayerSnapTile padding="0 11%" />
                </PlayerSnapTileContainer>
              </Grid>

              <Grid item xs={4}>
                <PlayerRoutesTile />
              </Grid>

              <Grid item xs={5}>
                <PlayerPassPlacementTile
                  selectedScale={PASS_PLACEMENT_SCALES[1]} // 3:2 aspect ratio
                  colorMode={PASS_PLACEMENT_COLOR_MODES.SUCCESS}
                />
              </Grid>

              <Grid item xs={3}>
                {playerId && (
                  <PlayerComparisonTile margin="0 0 0 0" playerId={playerId} />
                )}
              </Grid>
            </Grid>
          )}

          {playerDetails &&
            (position?.code === 'DL' ||
              position?.code === 'LB' ||
              position?.code === 'DB') && (
              <Grid>
                <Grid item xs={5}>
                  <PlayerTacklingTileContainer
                    $marginBottom="-20px"
                    $maxWidth="650px"
                  >
                    <PlayerTacklingTile />
                  </PlayerTacklingTileContainer>
                </Grid>

                <Grid item xs={7}>
                  <PlayerHavocTileContainer $marginBottom="-20px">
                    <PlayerHavocTile />
                  </PlayerHavocTileContainer>
                  <PlayerComparisonTile
                    margin="0.5rem 0 0 0"
                    width="50%"
                    playerId={playerId}
                  />
                </Grid>
              </Grid>
            )}

          {playerDetails && position?.code === 'OL' && (
            <Grid>
              <Grid item xs={4}>
                {playerId && (
                  <PlayerComparisonTile margin="0 0 0 0" playerId={playerId} />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayerOverview;
