import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@statsbomb/kitbag-components';
import { ALIGNMENT } from '../../../utils/constants/alignment';
import SortableTableHeader from './SortableTableHeader/SortableTableHeader';
import SortableTableBody from './SortableTableBody/SortableTableBody';
import {
  sortableTableHeadersPropTypes,
  tableRowsPropTypes,
  sortableTableAlignmentPropTypes,
  sortableTableStickyColumnsPropTypes,
  sortableTableCellSize,
} from '../Tables.propTypes';
import { SortableTableContainer } from './SortableTable.styles';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';

/**
 *  SortableTable component
 *  This component is responsible for rendering the header rows of the table
 */
const SortableTable = ({
  headers,
  rows,
  selectedRows,
  footerRows,
  name,
  stickyColumns,
  alignment,
  handleSort,
  sortDirection,
  sortBy,
  isHeadSticky,
  highlightedColumn,
  dataCellSize,
  nonTableHeight,
  rowHeight,
  withBorder,
  onChange,
  onChangeAll,
  allChecked,
  allIndeterminate,
}) => (
  <SortableTableContainer
    data-testid={name}
    $isHeadSticky={isHeadSticky}
    $nonTableHeight={nonTableHeight}
    $rowHeight={rowHeight}
    $withBorder={withBorder}
  >
    <Table
      stickyColumns={stickyColumns}
      isHeadSticky={isHeadSticky}
      highlightedColumn={highlightedColumn}
      selectedRows={selectedRows}
    >
      <SortableTableHeader
        headers={headers}
        handleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        alignment={alignment}
        onChangeAll={onChangeAll}
        allChecked={allChecked}
        allIndeterminate={allIndeterminate}
      />
      <SortableTableBody
        rows={rows}
        name={name}
        alignment={alignment}
        dataCellSize={dataCellSize}
        onChange={onChange}
      />
      {footerRows.length > 0 && (
        <SortableTableBody
          rows={footerRows}
          name={name}
          alignment={alignment}
          isFooter
          dataCellSize={dataCellSize}
        />
      )}
    </Table>
  </SortableTableContainer>
);

SortableTable.propTypes = {
  // An array of objects containing the data for the table header rows
  headers: sortableTableHeadersPropTypes.isRequired,
  // An array of objects containing the data for the table body rows
  rows: tableRowsPropTypes,
  // An array of indexes starting at 1
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  // An array of objects containing the data for the table footer rows
  footerRows: tableRowsPropTypes,
  // Which columns to keep on screen when the user scrolls right.
  // Can be a single number (1-based index) if only one sticky column is
  // required, otherwise needs to be an array of column objects containing the
  // column index and width.
  stickyColumns: sortableTableStickyColumnsPropTypes,
  // determines how each column should be aligned. can be a function that
  // uses the column index, or an alignment string which will apply to all columns
  alignment: sortableTableAlignmentPropTypes,
  // whether or not the headers remain in place when the user scrolls down
  isHeadSticky: PropTypes.bool,
  // The name of the table - used as a prefix for keys
  name: PropTypes.string.isRequired,
  // function that is called when a sortable header is clicked
  handleSort: PropTypes.func,
  // the state of the column sort
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  // by which column the table is sorted
  sortBy: PropTypes.string,
  // which column the table sorting is currently based on (1 based index)
  highlightedColumn: PropTypes.number,
  // controls the height of the data cells
  dataCellSize: sortableTableCellSize,
  // the height (with units) of all non-table elements of the page the table is in
  // only relevant to sticky headers ~ adjusts the spacing allotted in the container height
  nonTableHeight: PropTypes.string,
  // how tall the rows should be (unless wrapping). Defaults to 2.4rem
  rowHeight: PropTypes.string,
  // whether or not to add a border around the table
  withBorder: PropTypes.bool,
  // function that is called when a row is clicked, null will omit this feature
  onChange: PropTypes.func,
  // function that is called when the select all checkbox is clicked
  onChangeAll: PropTypes.func,
  // whether all checkboxes are checked, null will omit this feature
  allChecked: PropTypes.bool,
  // whether all checkboxes are indeterminate
  allIndeterminate: PropTypes.bool,
};

SortableTable.defaultProps = {
  rows: [],
  selectedRows: [],
  footerRows: [],
  stickyColumns: [],
  alignment: ALIGNMENT.LEFT,
  isHeadSticky: false,
  handleSort: () => {},
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  sortBy: '',
  highlightedColumn: 0,
  dataCellSize: 'small',
  nonTableHeight: null,
  rowHeight: null,
  withBorder: false,
  onChange: null,
  onChangeAll: null,
  allChecked: false,
  allIndeterminate: false,
};

export default SortableTable;
