import React from 'react';
import PropTypes from 'prop-types';
import HeightSlider from './sliders/HeightSlider';
import WeightSlider from './sliders/WeightSlider';
import { biometricProp } from '../../Scout.proptypes';
import HighSchoolMultiselect from './searchAndChips/HighSchoolMultiselect';
import CollegeClassMultiselect from './dropdowns/CollegeClassMultiselect';
import TeamOfferMultiselect from './searchAndChips/TeamOfferMultiselect';
import TeamAttendedMultiselect from './searchAndChips/TeamAttendedMultiselect';
import HomeStateMultiselect from './dropdowns/HomeStateMultiselect';
import {
  RECRUITING_247_RANGE,
  RECRUITING_COMPOSITE_RANGE,
  RECRUITING_RERANKING_RANGE,
  RECRUITING_YEAR_RANGE,
} from './BiometricFilters.constants';
import { SCOUT_BIOMETRIC_FILTERS } from '../ScoutResults.constants';
import BiometricSingleSlider from './sliders/BiometricSingleSlider';
import HomeTownMultiselect from './searchAndChips/HomeTownMultiselect';

const BiometricFilters = ({ selectedBiometrics, setSelectedBiometrics }) => (
  <>
    <HeightSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <WeightSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <CollegeClassMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <BiometricSingleSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
      sliderSetup={RECRUITING_YEAR_RANGE}
      biometricKey={SCOUT_BIOMETRIC_FILTERS.recruitingClassMin.key}
      unselectedText="Any year"
      sliderName="Recruiting class"
    />
    <BiometricSingleSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
      sliderSetup={RECRUITING_247_RANGE}
      biometricKey={SCOUT_BIOMETRIC_FILTERS.recruitingRanking247Min.key}
      unselectedText="Any ranking"
      sliderName="Recruiting 247 ranking"
    />
    <BiometricSingleSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
      sliderSetup={RECRUITING_COMPOSITE_RANGE}
      biometricKey={SCOUT_BIOMETRIC_FILTERS.recruitingRankingCompositeMin.key}
      unselectedText="Any ranking"
      sliderName="Recruiting composite ranking"
    />
    <BiometricSingleSlider
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
      sliderSetup={RECRUITING_RERANKING_RANGE}
      biometricKey={SCOUT_BIOMETRIC_FILTERS.recruitingRerankingMin.key}
      unselectedText="Any ranking"
      sliderName="Recruiting re-ranking"
    />
    <HomeStateMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <HomeTownMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <HighSchoolMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <TeamOfferMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
    <TeamAttendedMultiselect
      selectedBiometrics={selectedBiometrics}
      setSelectedBiometrics={setSelectedBiometrics}
    />
  </>
);
BiometricFilters.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default BiometricFilters;
