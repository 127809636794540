import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';

export const METRIC_SLIDER_DEFAULTS = {
  MIN: 0,
  MAX: 1,
  STEPS: 10,
  DEFAULT: 0.1,
  MEDIAN: 0.5,
};

export const SAVE_MENU_ACTIONS = {
  SAVE_AS: {
    label: 'Save as a new template',
    value: 'saveAs',
    variant: 'SaveAs',
  },
  EDIT: {
    label: 'Edit template name',
    value: 'edit',
    variant: 'Edit',
  },
};
export const SAVE_MENU_OPTIONS = Object.values(SAVE_MENU_ACTIONS);

export const VALIDATOR_MESSAGES = {
  TOO_SHORT: 'Please specify a name at least 4 characters long',
  TOO_LONG: 'Please specify a name no more than 100 characters long',
  BAD_CHARACTERS:
    'Only Letters, Numbers, Spaces and Underscores may be used in template names',
  NOT_UNIQUE:
    'There is already another template with this name, please specify a unique name',
};
export const VALID_TEMPLATE_NAME_LENGTH = { MIN: 4, MAX: 60 }; // characters maximum

export const DEFAULT_SCOUT_POSITION = API_ROSTER_POSITION_KEYS.QUARTERBACK;

export const SCOUT_BIOMETRIC_FILTERS = {
  ncaaTransferPortal: {
    key: 'ncaaTransferPortal',
    name: 'In Portal?',
    headerKey: 'ncaaTransferPortal',
  },
  heightMin: { key: 'heightMin', name: 'Min Height', headerKey: 'height' },
  heightMax: { key: 'heightMax', name: 'Max Height', headerKey: 'height' },
  weightMin: { key: 'weightMin', name: 'Min Weight', headerKey: 'weight' },
  weightMax: { key: 'weightMax', name: 'Max Weight', headerKey: 'weight' },
  collegeClass: {
    key: 'collegeClass',
    name: 'Classes',
    headerKey: 'collegeClass',
  },
  homeTown: { key: 'homeTown', name: 'Home Town', headerKey: 'homeTownName' },
  homeState: {
    key: 'homeState',
    name: 'Home State',
    headerKey: 'homeTownName',
  },
  highSchool: {
    key: 'highSchool',
    name: 'High School',
    headerKey: 'highSchoolName',
  },
  recruitingClassMin: {
    key: 'recruitingClassMin',
    name: 'Recruiting Class',
    headerKey: 'recruitingClass',
  },
  recruitingRanking247Min: {
    key: 'recruitingRanking247Min',
    name: 'Recruiting Ranking 247',
    headerKey: 'recruitingRanking247',
  },
  recruitingRankingCompositeMin: {
    key: 'recruitingRankingCompositeMin',
    name: 'Recruiting Ranking Composite',
    headerKey: 'recruitingRankingComposite',
  },
  recruitingRerankingMin: {
    key: 'recruitingRerankingMin',
    name: 'Recruiting Re-ranking',
    headerKey: 'recruitingReranking',
  },
  collegesOffered: {
    key: 'collegesOffered',
    name: 'College Offering',
    headerKey: 'collegesOfferedNames',
  },
  collegesAttended: {
    key: 'collegesAttended',
    name: 'College Attended',
    headerKey: 'collegesAttendedNames',
  },
};
