import { styled } from 'styled-components';

export const StyledRoutesChart = styled.svg`
  width: 100%;

  .route-path:hover {
    stroke: ${({ theme, $isInteractive }) =>
      $isInteractive && theme.colours.interface.main};
  }
`;
