import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';

export const deleteApiKey = async (keyId, accessToken) => {
  const url = `${process.env.ACCOUNT_API_KEYS}/${keyId}`;

  const result = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response.status;
      }
      return response.json();
    })
    .catch((error) => {
      console.error(ERROR_CODES.DELETE_API_KEY, error);
      return error;
    });

  return result;
};

export const generateApiKey = async (accessToken) => {
  const url = process.env.ACCOUNT_API_KEYS;

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response.status;
      }
      return response.json();
    })
    .catch((error) => {
      console.error(ERROR_CODES.GENERATE_API_KEY, error);
      return error;
    });

  return result;
};
