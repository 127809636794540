import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';
import { QUERY_CONTEXT } from './TeamDepthChart.constants';

export const getDepthChartTeam = (type) => gql`
  query GetDepthChart(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $offense: Boolean!
    $personnel: PersonnelFilter
    ${getContextQueryVariables(QUERY_CONTEXT)}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports(QUERY_CONTEXT)}

    depthChart(
      teamId: $teamId
      offense: $offense
      groupByAlignment: false
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        personnel: $personnel
        ${getContextFiltersQueryArguments(QUERY_CONTEXT)}
      }
    ) {
      snapCount
      personnel {
        ... on DepthChart${type}Personnel {
          package
          snapCount
          snapRate
          competitionRate
        }
      }
    }
  }
`;
