import React from 'react';
import PropTypes from 'prop-types';
import { StyledDimmer } from './Dimmer.styles';

const Dimmer = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledDimmer {...props}>{children}</StyledDimmer>
);

Dimmer.propTypes = {
  children: PropTypes.node,
};

Dimmer.defaultProps = {
  children: null,
};

export default Dimmer;
