import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../../utils/helpers/contextFilters';

export const getRunAttemptsGQLString = (gapMode) => {
  const queryName = gapMode ? 'GetRunGapAttempts' : 'GetRunPOAAttempts';
  const grouping = gapMode ? 'gap' : 'dY';
  const queryStr = `query ${queryName}(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    offensiveLineRunGaps(
      teamId: $teamId
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
      grouping: "${grouping}"
    ) {
      team {
        id
        name
      }
      leftTackle {
        id
        name
      }
      leftGuard {
        id
        name
      }
      center {
        id
        name
      }
      rightGuard {
        id
        name
      }
      rightTackle {
        id
        name
      }
      plays
      runGap
      pointOfAttackdY
      yardsGained
      yardsRun
      successPlays
      carrier {
        id
        name
      }
      carrierRosterAlignment
      carrierPlayAlignments
    }
  }
`;
  return gql(queryStr);
};
