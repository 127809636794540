import {
  COMPETITION_LEVEL as _COMPETITION_LEVEL,
  DEFAULT_FIELD_DRAWING_SETTINGS,
  FIELD_MEASURES,
} from '../helpers/field.constants';

const COMPETITION_LEVEL = _COMPETITION_LEVEL;
/* 
These constants are used throughout project already. 
Moving core definition to separate file for react components
  TODO: update all references to core
*/
const FIELD_X_YARDS = FIELD_MEASURES.X_YARDS; // 120
const FIELD_Y_YARDS = FIELD_MEASURES.Y_YARDS; // 53 + 1 / 3;
const { HASH_Y_NFL } = FIELD_MEASURES; // 23 + 7 / 12;
const { HASH_Y_NCAA } = FIELD_MEASURES; // 20;
const { HASH_Y_HS } = FIELD_MEASURES; // 640 / 36;
const { HASH_MARK_WIDTH } = FIELD_MEASURES; // 2 / 3;
const { HASH_MARK_EDGE_SPACE } = FIELD_MEASURES; // 1 / 3;
const { GOAL_POST_WIDTH_HS } = FIELD_MEASURES; // 280 / 36;
const { GOAL_POST_WIDTH } = FIELD_MEASURES; // 6 + 1 / 6;
const { END_ZONE_WIDTH } = FIELD_MEASURES; // 10;
const { FIELD_NUMBERS_HEIGHT } = FIELD_MEASURES; // 2; // Yards / 6ft
const { FIELD_NUMBERS_TOP } = FIELD_MEASURES; // 10; // yards in from the sideline
const { FIELD_NUMBERS_WIDTH } = FIELD_MEASURES; // 4 / 3; // yards wide (4 ft)
const { FIELD_NUMBERS_WIDTH_SPACING } = FIELD_MEASURES; // 1 / 3; // yds spacing from the center line

const FIELD_BORDER_PADDING = 3 + 1 / 3; // AMOUNT EACH SIDE OF THE FIELD (out of bounds) TO SHOW AS "TRAMS"
const FIELD_Y_YARDS_RELATIVEY = 70; // This is probably going to be too big when we start exploring the NFL.
const LOS_FIELD_LOS_X = 20;
const LOS_FIELD_X_YARDS = 80;

const getFieldYSplits = function (compLevel) {
  const hashY = compLevel === COMPETITION_LEVEL.NFL ? HASH_Y_NFL : HASH_Y_NCAA;
  const fieldNumbersEdge =
    compLevel === COMPETITION_LEVEL.NFL
      ? FIELD_MEASURES.FIELD_NUMBERS_EDGE_NFL + FIELD_NUMBERS_HEIGHT
      : FIELD_MEASURES.FIELD_NUMBERS_EDGE_NCAA + FIELD_NUMBERS_HEIGHT;
  const vals = [
    0,
    fieldNumbersEdge,
    hashY,
    FIELD_Y_YARDS - hashY,
    FIELD_Y_YARDS - fieldNumbersEdge,
    FIELD_Y_YARDS,
  ];
  const zones = vals
    .slice(0, -1)
    .map((m, i) => ({ zoneIndex: i, y: m, height: vals[i + 1] - m }));
  return zones;
};
const getFieldRelativeYSplits = function () {
  const tenYards = 10;
  const tenYardSplits = FIELD_Y_YARDS_RELATIVEY / tenYards;
  const indexes = [...Array(tenYardSplits).keys()];
  const zones = indexes.map((i) => ({
    zoneIndex: i,
    y: i * tenYards,
    height: tenYards,
  }));
  return zones;
};

const YDS_PER_S_IN_MPH = 0.488889; // To convert yds/s to Miles per Hour, divide by 0.49;

const LEFT_HASH_NCAA = HASH_Y_NCAA + 1;
const CENTER_FIELD_Y = FIELD_Y_YARDS / 2;
const RIGHT_HASH_NCAA = FIELD_Y_YARDS - HASH_Y_NCAA - 1;
const LEFT_HASH_NFL = HASH_Y_NFL + 1;
const RIGHT_HASH_NFL = FIELD_Y_YARDS - HASH_Y_NFL - 1;

const ROTATIONS = {
  HORIZONTAL: 'horizontal', // offense left to right
  VERTICAL_UP: 'vertical-up', // offense bottom to top
  VERTICAL_DOWN: 'vertical-down', // defense bottom to top
};
const ROTATION_OPTIONS = [
  { value: ROTATIONS.VERTICAL_UP, label: 'Attacking Up' },
  { value: ROTATIONS.VERTICAL_DOWN, label: 'Defending Up' },
  { value: ROTATIONS.HORIZONTAL, label: 'Attacking Right' },
];

const OFFENSE_FORMATIONS = [
  'OF0x2',
  'OF0x3',
  'OF0x4',
  'OF0x5',
  'OF1x1',
  'OF1x2',
  'OF1x3',
  'OF1x4',
  'OF2x0',
  'OF2x1',
  'OF2x2',
  'OF2x3',
  'OF3x0',
  'OF3x1',
  'OF3x2',
  'OF4x0',
  'OF4x1',
  'OF5x0',
];

// TO DO: phase this out
const defaultFieldSettings = {
  ...DEFAULT_FIELD_DRAWING_SETTINGS,
  showFieldNumbers: false, // used for LoS, rel-Y and other non normal field settings
};

/* matches the marginPropType structure  */
export const DEFAULT_VISUALISATION_MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};
export const VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS = 'clickable-element';

export {
  FIELD_NUMBERS_HEIGHT,
  FIELD_NUMBERS_TOP,
  FIELD_NUMBERS_WIDTH,
  FIELD_NUMBERS_WIDTH_SPACING,
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  HASH_Y_NFL,
  HASH_Y_NCAA,
  HASH_Y_HS,
  HASH_MARK_WIDTH,
  HASH_MARK_EDGE_SPACE,
  CENTER_FIELD_Y,
  LEFT_HASH_NCAA,
  RIGHT_HASH_NCAA,
  LEFT_HASH_NFL,
  RIGHT_HASH_NFL,
  GOAL_POST_WIDTH_HS,
  GOAL_POST_WIDTH,
  END_ZONE_WIDTH,
  FIELD_BORDER_PADDING,
  LOS_FIELD_LOS_X,
  LOS_FIELD_X_YARDS,
  defaultFieldSettings,
  YDS_PER_S_IN_MPH,
  COMPETITION_LEVEL,
  ROTATIONS,
  ROTATION_OPTIONS,
  getFieldYSplits,
  getFieldRelativeYSplits,
  OFFENSE_FORMATIONS,
};
