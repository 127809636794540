import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  PlayerComparisonColumn,
  PlayerComparisonTableHeader,
  PlayerComparisonRadar,
  PlayerComparisonTableBorder,
} from './PlayerComparison.styles';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import BasicTable from '../../../components/Table/BasicTable';
import Tile from '../../../components/Tile/Tile';
import { getTableData } from '../PlayerRadar/PlayerRadar.dataManipulation';
import { radarTemplatePropTypes } from '../../../visualisations/RadarChart/RadarChart.PropTypes';
import {
  COMPARE_TILE_ADD,
  COMPARE_TILE_DATA,
  COMPARE_TILE_EMPTY,
  COMPARE_TILE_ERROR,
  COMPARE_TILE_HIDDEN,
  COMPARISON_TABLE_HEADERS,
  COMPARISON_TILE_WIDTH,
} from './PlayerComparison.constants';
import { escapeDownloadString } from '../../../utils/helpers/strings';
import { getComparisonTileOpacity } from './PlayerComparison.helpers';

const PlayerComparisonTile = ({
  playerName,
  showTrait,
  radarTraitData,
  radarTraitComparisonData,
  showPerformance,
  radarPerformanceData,
  radarPerformanceComparisonData,
  margin,
  padding,
  highlight,
  tileState,
  idSuffix,
  flipData,
}) => {
  // Trait Radar
  const { radarTableData: traitTableData } = getTableData(
    radarTraitData?.templateConfigTrait,
    false,
    radarTraitData?.radarTraitStats,
    null
  );

  const emptyTraitData = radarTraitData?.templateConfigTrait?.map(
    ({ name }) => ({ name, statValue: '-' })
  );

  const traitStatDatum = flipData
    ? radarTraitData?.radarTraitStats
    : radarTraitComparisonData;

  const traitComparisonDatum = flipData
    ? radarTraitComparisonData
    : radarTraitData?.radarTraitStats;

  // Performance Radar
  const { radarTableData: perfTableData } = getTableData(
    radarPerformanceData?.templateConfigPerf,
    false,
    radarPerformanceData?.radarPerfStats,
    null
  );

  const emptyPerformanceData = radarPerformanceData?.templateConfigPerf?.map(
    ({ name }) => ({ name, statValue: '-' })
  );

  const perfStatDatum = flipData
    ? radarPerformanceData?.radarPerfStats
    : radarPerformanceComparisonData;

  const perfComparisonDatum = flipData
    ? radarPerformanceComparisonData
    : radarPerformanceData?.radarPerfStats;

  const noData =
    tileState === COMPARE_TILE_EMPTY ||
    tileState === COMPARE_TILE_ADD ||
    tileState === COMPARE_TILE_ERROR;

  return (
    <Tile
      padding="0"
      border="0"
      margin={margin}
      className="comparisonTile"
      width={`${COMPARISON_TILE_WIDTH}px`}
      opacity={getComparisonTileOpacity(tileState)}
    >
      <Tile.Body $gap={0} $padding={padding || '0'}>
        <PlayerComparisonColumn>
          <div
            style={{
              display: showPerformance ? 'block' : 'none',
            }}
          >
            <div>
              <PlayerComparisonTableHeader />
              <PlayerComparisonRadar $highlight={highlight}>
                <RadarChart
                  radarId={escapeDownloadString(
                    `radar-comparison-performance-${playerName.replace(
                      /\s/g,
                      ''
                    )}-${idSuffix}`
                  )}
                  templateConfig={
                    tileState === COMPARE_TILE_EMPTY
                      ? []
                      : radarPerformanceData?.templateConfigPerf
                  }
                  radarStatDatum={noData ? {} : perfStatDatum}
                  radarComparisonDatum={noData ? {} : perfComparisonDatum}
                  keyInfo={null}
                  drawAxes={false}
                />
              </PlayerComparisonRadar>
            </div>

            <PlayerComparisonTableBorder>
              <BasicTable
                data={
                  noData
                    ? emptyPerformanceData
                    : perfTableData?.filter((d) => d.prettyName !== 'Plays')
                }
                headers={COMPARISON_TABLE_HEADERS}
                showHeader={false}
                bodytdHeight="2rem"
              />
            </PlayerComparisonTableBorder>
          </div>

          <div
            style={{
              display: showTrait ? 'block' : 'none',
            }}
          >
            <div>
              <PlayerComparisonTableHeader />
              <PlayerComparisonRadar $highlight={highlight}>
                <RadarChart
                  radarId={escapeDownloadString(
                    `radar-comparison-trait-${playerName.replace(
                      /\s/g,
                      ''
                    )}-${idSuffix}`
                  )}
                  templateConfig={
                    tileState === COMPARE_TILE_EMPTY
                      ? []
                      : radarTraitData?.templateConfigTrait
                  }
                  radarStatDatum={noData ? {} : traitStatDatum}
                  radarComparisonDatum={noData ? {} : traitComparisonDatum}
                  keyInfo={null}
                  axisLabelFontSize={0}
                  drawAxes={false}
                />
              </PlayerComparisonRadar>
            </div>

            <PlayerComparisonTableBorder>
              <BasicTable
                data={
                  noData
                    ? emptyTraitData
                    : traitTableData?.filter((d) => d.prettyName !== 'Plays')
                }
                headers={COMPARISON_TABLE_HEADERS}
                showHeader={false}
                bodytdHeight="2rem"
              />
            </PlayerComparisonTableBorder>
          </div>
        </PlayerComparisonColumn>
      </Tile.Body>
    </Tile>
  );
};

PlayerComparisonTile.propTypes = {
  playerName: PropTypes.string,
  showTrait: PropTypes.bool,
  radarTraitData: PropTypes.shape({
    templateConfigTrait: radarTemplatePropTypes,
    radarTraitStats: PropTypes.oneOfType([
      radarTemplatePropTypes,
      PropTypes.object,
    ]),
    teamColors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
  }),
  // data from the original player radar
  radarTraitComparisonData: PropTypes.shape({
    templateConfigPerf: radarTemplatePropTypes,
    radarPerfStats: PropTypes.oneOfType([
      radarTemplatePropTypes,
      PropTypes.object,
    ]),
    teamColors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
  }),
  showPerformance: PropTypes.bool,
  radarPerformanceData: PropTypes.shape({
    templateConfigPerf: radarTemplatePropTypes,
    radarPerfStats: PropTypes.oneOfType([
      radarTemplatePropTypes,
      PropTypes.object,
    ]),
    teamColors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
  }),
  // data from the original player radar
  radarPerformanceComparisonData: PropTypes.shape({
    templateConfigPerf: radarTemplatePropTypes,
    radarPerfStats: PropTypes.oneOfType([
      radarTemplatePropTypes,
      PropTypes.object,
    ]),
    teamColors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
  }),
  margin: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // add highlight overlay to comparison tile
  highlight: PropTypes.bool,
  tileState: PropTypes.oneOf([
    COMPARE_TILE_ADD,
    COMPARE_TILE_DATA,
    COMPARE_TILE_EMPTY,
    COMPARE_TILE_HIDDEN,
    COMPARE_TILE_ERROR,
  ]),
  idSuffix: PropTypes.string,
  // swap comparison abd original radar (for colouring purposes)
  flipData: PropTypes.bool,
};

PlayerComparisonTile.defaultProps = {
  playerName: '',
  showTrait: true,
  radarTraitData: {},
  radarTraitComparisonData: null,
  showPerformance: true,
  radarPerformanceData: {},
  radarPerformanceComparisonData: null,
  margin: '0',
  padding: '0 0 0 0.8rem',
  highlight: false,
  tileState: COMPARE_TILE_EMPTY,
  // added to radars to allow for multiple radars on the same page
  idSuffix: '',
  flipData: false,
};

export default memo(PlayerComparisonTile);
