/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useTypingState from '../../utils/hooks/useTypingState';
import ParsedLink from '../ParsedLink/ParsedLink';
import { StyledGlobalSearch, StyledSearch } from './GlobalSearch.styles';
import { SEARCH_METADATA } from './searchMetadata';
import { replaceHrefTokens } from '../../utils/helpers/general';
import { keys } from '../../utils/constants/keys';
import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';

const GlobalSearch = () => {
  const [isTyping, setIsTyping, searchString, setSearchValue] =
    useTypingState();
  const navigate = useNavigate();

  const {
    data: searchData,
    loading: isLoading,
    error: searchError,
  } = useQuery(SEARCH_METADATA, {
    skip: isTyping,
    variables: {
      searchString,
    },
  });

  if (searchError) {
    console.error(ERROR_CODES.SEARCH_METADATA, searchError);
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setIsTyping(true);
  };

  // https://github.com/Semantic-Org/Semantic-UI-React/issues/3389
  // Search.close() within Search._selectItemOnEnter throws a no-op error
  const handleResultSelect = (e, data) => {
    if (e.key === keys.ENTER) {
      const linkVars = {
        leagues: data.result.competition,
        seasons: data.result.season,
        teams: data.result.team,
        players: data.result.player,
      };

      navigate(
        `/${replaceHrefTokens(
          'player/overview/:leagues/:seasons/:teams/:players',
          linkVars
        )}`
      );
    }
  };

  const ResultRenderer = ({
    player: playerId,
    playername: playerName,
    team: teamId,
    teamname: teamName,
    competition: competitionId,
    season: seasonId,
    position,
  }) => (
    <ParsedLink
      href={`/player/overview/${competitionId}/${seasonId}/${teamId}/${playerId}`}
      title={`Search Result ${playerName}`}
      key={playerId}
    >
      {`${playerName} (${position}) - ${teamName}`}
    </ParsedLink>
  );

  // https://github.com/Semantic-Org/Semantic-UI-React/issues/1141
  // requires fields for "title", "description", "price" and "image"
  // component spreads data onto the DOM element so no camelCase
  const parsedResults = searchData?.searchMetadata.map((result) => ({
    title: '',
    description: '',
    price: '',
    image: '',
    player: result.id,
    playername: result.name,
    team: result.team.id,
    teamname: result.team.shortName,
    competition: result.team.competition?.id,
    position: result.mostCommonPosition.code,
    season: result.season.id,
    key: result.id,
  }));

  return (
    <StyledGlobalSearch>
      <StyledSearch
        id="global-search-input"
        fluid
        input={{
          icon: 'search',
          iconPosition: 'left',
          type: 'search',
          'aria-label': 'globalSearchInput',
        }}
        onSearchChange={handleSearch}
        onResultSelect={handleResultSelect}
        value={searchString}
        showNoResults={false}
        loading={isLoading}
        resultRenderer={ResultRenderer}
        results={parsedResults}
        data-testid="globalSearchTest"
      />
    </StyledGlobalSearch>
  );
};

export default GlobalSearch;
