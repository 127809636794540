import React, { useEffect, useState } from 'react';
import { Breadcrumb, Grid } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import {
  Dropdown,
  Pagination,
  Slider,
  TextField,
} from '@statsbomb/kitbag-components';
import Tile from '../../../../components/Tile/Tile';
import {
  getDownloadColumns,
  formatStatValues,
  orderStats,
  getStatHeaders,
} from '../stats.dataManipulation';
import { addTeamGroupColumns } from './TeamStats.dataManipulation';
import useQueryString from '../../../../utils/hooks/useQueryString';
import { ALIGNMENT } from '../../../../utils/constants/alignment';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import TabbedTable from '../../../../components/Tables/TabbedTable/TabbedTable';
import { getTeamStats } from './getTeamStats';
import { useTeamStatDefinitions } from './useTeamStatDefinitions';
import {
  DEFAULT_HIGHLIGHTED_COLUMN,
  DEFAULT_SORT_BY,
  TEAMS_PER_PAGE,
  TEAM_AGGREGATES_CONFIG,
  TEAM_AGGREGATE_OPTIONS,
  TEAM_GROUP_BY_CONFIG,
  TEAM_GROUP_BY_HEADERS_CONFIG,
  TEAM_GROUP_BY_OPTIONS,
  TEAM_GROUP_BY_STICKY_COLUMNS_WIDTH,
  TEAM_PERMANENT_HEADERS_CONFIG,
} from './TeamStats.constants';
import { useTeamStatCategories } from './TeamStats.hooks';
import { PaginationContainer, LeagueStatsContainer } from '../../League.styles';
import { useGetFullResults } from '../../../../utils/hooks/useGetFullResults';
import DownloadCsvAsync from '../../../../components/buttons/DownloadCsvAsync/DownloadCsvAsync';
import useTypingState from '../../../../utils/hooks/useTypingState';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
const RATE = TEAM_AGGREGATES_CONFIG.RATE.apiValue;
const TEAM_OFFENSE = TEAM_GROUP_BY_CONFIG.TEAM_OFFENSE.apiValue;
const TEAM_DEFENSE = TEAM_GROUP_BY_CONFIG.TEAM_DEFENSE.apiValue;

const TeamStats = () => {
  const [selectedCategory, setSelectedCategory] = useQueryString(
    'category',
    ''
  );
  const [selectedSubcategory, setSelectedSubcategory] = useQueryString(
    'subcategory',
    ''
  );
  const [aggregateMode, setAggregateMode] = useQueryString('aggregate', RATE);
  const [groupByModes, setGroupByModes] = useState([]);
  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [sortBy, setSortBy] = useQueryString('sort', DEFAULT_SORT_BY);
  const [isTyping, setIsTyping, searchValue, setSearchValue] = useTypingState();
  const [minPlays, setMinPlays] = useQueryString('plays', 0);
  const [minimumTeamPlays, setMinimumTeamPlays] = useState(minPlays);
  const [activePage, setActivePage] = useQueryString('page', 1);
  const [highlightedColumn, setHighlightedColumn] = useQueryString(
    'col',
    DEFAULT_HIGHLIGHTED_COLUMN
  );

  const { data: categoryDefinitions, loading: categoryDefinitionsLoading } =
    useTeamStatCategories();

  const categories = categoryDefinitions?.map((c) => c.name) || [];

  // definition of the selected category
  const selectedCategoryDefinition = categoryDefinitions?.find(
    (c) => c.name === selectedCategory
  );

  // get all subcategories of the selected category
  const subcategories = selectedCategoryDefinition?.subcategories.map(
    (subcategory) => subcategory.name
  );

  // get an ordered list of stats of the selected category/subcategory combination
  const orderedSubcategoryStats =
    selectedCategoryDefinition?.subcategories.find(
      (subcategory) => subcategory.name === selectedSubcategory
    )?.stats;

  const isRateMode = aggregateMode === RATE;
  const { data: teamStatDefinitions, loading: teamStatDefinitionsLoading } =
    useTeamStatDefinitions(orderedSubcategoryStats, isRateMode);

  // set a default category if none is selected yet
  useEffect(() => {
    if (!selectedCategory && categories?.length) {
      setSelectedCategory(categories[0]);
    }
  }, [categories?.length]);

  // set default subcategory once the category has been set
  useEffect(() => {
    if (!selectedSubcategory && subcategories?.length) {
      setSelectedSubcategory(subcategories[0]);
    }
  }, [selectedCategory, subcategories?.length]);

  const additionalGroupBys = groupByModes.map(
    (groupByMode) => groupByMode.value
  );
  const baseGroupBy =
    selectedCategory === 'Offense' ? TEAM_OFFENSE : TEAM_DEFENSE;
  const groupBy = [baseGroupBy, ...additionalGroupBys];

  const sharedVariables = {
    teamName: searchValue,
    minPlays,
    groupBy,
    orderBy: [
      {
        name: sortBy,
        descending: sortDirection === DESCENDING,
      },
    ],
  };

  const sharedContext = {
    headers: {
      'Accept-Version': 'v2023q2',
    },
  };

  const GET_TEAM_STATS = getTeamStats(teamStatDefinitions);
  const { data: teamStats, loading: teamStatsLoading } = useQuery(
    GET_TEAM_STATS,
    {
      skip: !teamStatDefinitions?.length || isTyping,
      variables: {
        ...sharedVariables,
        limit: TEAMS_PER_PAGE,
        offset: (activePage - 1) * TEAMS_PER_PAGE,
      },
      context: sharedContext,
      notifyOnNetworkStatusChange: true,
    }
  );

  const isLoadingTeamStats =
    teamStatDefinitionsLoading ||
    categoryDefinitionsLoading ||
    teamStatsLoading ||
    isTyping;

  const playerStats = addTeamGroupColumns(teamStats?.teamStats);

  // insert additional group by column if selected
  const groupByColumns = groupByModes.length
    ? groupByModes.map((mode) => TEAM_GROUP_BY_HEADERS_CONFIG[mode.value])
    : [];

  const headers = [
    ...TEAM_PERMANENT_HEADERS_CONFIG,
    ...groupByColumns,
    ...getStatHeaders(teamStatDefinitions),
  ];

  const headerIds = headers.map((header) => header.id);
  const downloadColumns = getDownloadColumns(headers);

  const formattedStats = formatStatValues(
    playerStats,
    headerIds,
    teamStatDefinitions,
    aggregateMode
  );

  const orderedStats = orderStats(formattedStats, headerIds);

  const stickyColumns = [
    { index: 1, width: 8.75 },
    { index: 2, width: 4.1875 },
    { index: 3, width: 3.5625 },
    ...groupByModes.map((mode, index) => ({
      index: index + 1 + TEAM_PERMANENT_HEADERS_CONFIG.length,
      width: TEAM_GROUP_BY_STICKY_COLUMNS_WIDTH[mode.value],
    })),
  ];

  const tableTabsConfig = categories?.map((category) => ({
    id: category,
    label: category,
    tableConfig: {
      headers,
      rows: orderedStats,
      alignment: (index) =>
        stickyColumns.length > index && index !== 1 && index !== 2
          ? ALIGNMENT.LEFT
          : ALIGNMENT.RIGHT,
      stickyColumns,
    },
  }));

  const totalCount = teamStats?.teamStats.totalCount || 0;

  const { data, loading, start, reset } = useGetFullResults(
    GET_TEAM_STATS,
    sharedVariables,
    totalCount,
    'data.teamStats.items',
    sharedContext
  );

  const handleSort = (statName) => {
    if (statName === sortBy) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(DESCENDING);
      setSortBy(statName);
      setHighlightedColumn(headerIds.indexOf(statName) + 1);
    }
    setActivePage(1);
  };

  const handleTabChange = (e, isSubTab) => {
    const setTab = isSubTab ? setSelectedSubcategory : setSelectedCategory;
    setTab(e.target.innerText);
    setSortBy(DEFAULT_SORT_BY);
    setHighlightedColumn(DEFAULT_HIGHLIGHTED_COLUMN);
    setActivePage(1);

    if (!isSubTab) {
      setSelectedSubcategory('');
    }
  };

  const handleSearch = (e) => {
    if (activePage !== 1) {
      setActivePage(1);
    }
    setSearchValue(e.target.value);
    setIsTyping(true);
  };

  const handleAggregateMode = (selectedOption) => {
    setAggregateMode(selectedOption.value);
    setSortBy(DEFAULT_SORT_BY);
    setHighlightedColumn(DEFAULT_HIGHLIGHTED_COLUMN);
    setActivePage(1);
  };

  const handleGroupByModes = (selectedOption) => {
    setGroupByModes(selectedOption);
    setActivePage(1);
  };

  return (
    <LeagueStatsContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Tile margin="0">
              <Tile.Header>
                <Breadcrumb size="huge">
                  <Breadcrumb.Section>League</Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active>Team Stats</Breadcrumb.Section>
                </Breadcrumb>
                <div className="buttons">
                  <TextField
                    id="team-stats-search-filter"
                    labelPosition="none"
                    value={searchValue}
                    onChange={handleSearch}
                    adornmentIcon="Search"
                    adornmentOnClick={() => {}}
                    placeholder="Search teams"
                    size="small"
                  />
                  <DownloadCsvAsync
                    onClick={start}
                    reset={reset}
                    data={data}
                    headers={downloadColumns}
                    fileName="Team Stats.csv"
                    disabled={isLoadingTeamStats}
                    loading={loading}
                  />
                </div>
              </Tile.Header>
              <Tile.Body $padding="0">
                {selectedSubcategory && (
                  <TabbedTable
                    tableTabsConfig={tableTabsConfig}
                    isLoading={isLoadingTeamStats}
                    onTabChange={(e) => handleTabChange(e, false)}
                    subTabs={subcategories}
                    selectedSubTab={selectedSubcategory}
                    handleSort={handleSort}
                    onSubTabChange={(e) => handleTabChange(e, true)}
                    sortDirection={sortDirection}
                    sortBy={sortBy || teamStatDefinitions?.[0]?.name}
                    withBorder
                    isHeadSticky
                    highlightedColumn={highlightedColumn}
                    nonTableHeight="21rem"
                  />
                )}
                {orderedStats && (
                  <PaginationContainer>
                    <Pagination
                      activePage={activePage}
                      onChange={({ pageSelected }) =>
                        setActivePage(pageSelected)
                      }
                      itemsPerPage={TEAMS_PER_PAGE}
                      dataLength={totalCount}
                      hasEdgePageButtons
                    />
                  </PaginationContainer>
                )}
              </Tile.Body>
            </Tile>
          </Grid.Column>
          <Grid.Column width={4}>
            <Tile>
              <Tile.Body>
                <Dropdown
                  id="team-stats-aggregate-filter"
                  label="View values aggregated"
                  options={TEAM_AGGREGATE_OPTIONS}
                  onChange={handleAggregateMode}
                  value={TEAM_AGGREGATE_OPTIONS.find(
                    (f) => f.value === aggregateMode
                  )}
                />
                <Dropdown
                  id="team-stats-group-by-filter"
                  label="Break down team stats by"
                  options={TEAM_GROUP_BY_OPTIONS}
                  onChange={handleGroupByModes}
                  value={groupByModes}
                  isMulti
                />
              </Tile.Body>
            </Tile>
            <Tile>
              <Tile.Body>
                <div>
                  <Slider
                    ariaLabel="minimum-snaps-filter"
                    max={1000}
                    min={0}
                    step={10}
                    title="Minimum Total Plays"
                    value={minimumTeamPlays}
                    onAfterChange={setMinPlays}
                    onChange={setMinimumTeamPlays}
                    reverse
                  />
                </div>
              </Tile.Body>
            </Tile>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LeagueStatsContainer>
  );
};

export default TeamStats;
