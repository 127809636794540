import { gql } from '@apollo/client';

export const GET_PLAY_FINDER_COMPETITIONS = gql`
  query GetCompetitions {
    competitions {
      id
      name
      level
    }
  }
`;
