import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from '@statsbomb/kitbag-components';
import DownloadCSVButton from '../buttons/DownloadCSV';
import GoTo from '../buttons/GoTo';
import Tile from '../Tile/Tile';
import { TableTileHeader } from './TableTile.styles';

const LinkedTableTile = ({
  data,
  tileTitle,
  columnHeaders,
  footer,
  fileName,
  error,
  loading,
  linkHref,
  iconSize,
  iconStyle,
  onChange,
  onChangeAll,
  allIndeterminate,
  allChecked,
}) => (
  <Tile dataTestId="tableTileT" border="0">
    {tileTitle && (
      <Tile.Header $isSlim>
        <TableTileHeader>
          <h2 data-testid="header2T" title={tileTitle}>
            {tileTitle}
          </h2>
          <div className="buttons">
            {!error && !loading && data && fileName && (
              <div style={iconStyle}>
                <DownloadCSVButton
                  data={data}
                  headers={columnHeaders}
                  fileName={fileName}
                  data-testid="downloadCSVButtonT"
                  iconSize={iconSize}
                />
              </div>
            )}
            {linkHref && (
              <GoTo href={linkHref} linkName="Open in Play Animation" />
            )}
          </div>
        </TableTileHeader>
      </Tile.Header>
    )}
    <Tile.Body $padding="0" data-testid="tableHolderT">
      {!error && !loading && data && (
        <Table>
          <Table.Body>
            {columnHeaders && (
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={allChecked}
                    id="select-all-rows"
                    indeterminate={allIndeterminate}
                    label="select-all-rows"
                    onChange={onChangeAll}
                    withLabel={false}
                    size="small"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>{columnHeaders[0].label}</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: 'right' }}>
                  {columnHeaders[1].label}
                </Table.HeaderCell>
              </Table.Row>
            )}
            {data?.map((d, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Table.Row key={i}>
                <Table.DataCell style={{ width: '32px' }}>
                  <Checkbox
                    checked={d?.checked}
                    id={`select-row-${i}`}
                    label={`select-row-${i}`}
                    onChange={() => onChange(i)}
                    withLabel={false}
                    size="small"
                  />
                </Table.DataCell>
                <Table.DataCell $textAlign="left">{d?.label}</Table.DataCell>
                <Table.DataCell $textAlign="right">{d?.value}</Table.DataCell>
              </Table.Row>
            ))}
          </Table.Body>
          {footer && (
            <Table.Foot>
              <Table.Row>
                <Table.DataCell />
                <Table.DataCell
                  $textAlign="left"
                  style={{ padding: '0.4rem 0.8rem 0.3rem 0.8rem' }}
                >
                  {footer?.label}
                </Table.DataCell>
                <Table.DataCell $textAlign="right">
                  {footer?.value}
                </Table.DataCell>
              </Table.Row>
            </Table.Foot>
          )}
        </Table>
      )}
    </Tile.Body>
  </Tile>
);

LinkedTableTile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  tileTitle: PropTypes.string.isRequired,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  footer: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  fileName: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  linkHref: PropTypes.string,
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  iconStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onChange: PropTypes.func,
  onChangeAll: PropTypes.func,
  allIndeterminate: PropTypes.bool,
  allChecked: PropTypes.bool,
};

LinkedTableTile.defaultProps = {
  data: undefined,
  columnHeaders: undefined,
  footer: undefined,
  fileName: 'table.csv',
  error: false,
  loading: false,
  linkHref: null,
  iconSize: 'small',
  iconStyle: {},
  onChange: () => {},
  onChangeAll: () => {},
  allIndeterminate: false,
  allChecked: false,
};

export default LinkedTableTile;
