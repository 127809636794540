const addBackgroundRect = function (svg, background) {
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('fill', background);
};

export { addBackgroundRect };
