import PropTypes from 'prop-types';
import { API_STAT_UNITS } from '../../utils/constants/api';

const routeSummaryDatumPropType = PropTypes.exact({
  routeType: PropTypes.string,
  routes: PropTypes.number,
  targets: PropTypes.number,
  branch: PropTypes.shape({
    value: PropTypes.number,
    valueFormatted: PropTypes.string,
    strokeWidth: PropTypes.number,
    strokeDash: PropTypes.bool,
  }),
  fruit: PropTypes.shape({
    value: PropTypes.number,
    valueFormatted: PropTypes.string,
    fillValue: PropTypes.number,
    fillFunction: PropTypes.func,
    opacity: PropTypes.number,
  }),
  tooltip: PropTypes.string,
});

/* Route class config defines these names */
export const RouteSummaryPropType = PropTypes.exact({
  totalPlays: PropTypes.number,
  totalRoutes: PropTypes.number,
  totalTargets: PropTypes.number,
  go: routeSummaryDatumPropType,
  comeback: routeSummaryDatumPropType,
  corner: routeSummaryDatumPropType,
  curl: routeSummaryDatumPropType,
  deep_out: routeSummaryDatumPropType,
  dig: routeSummaryDatumPropType,
  drag: routeSummaryDatumPropType,
  flat: routeSummaryDatumPropType,
  hitch: routeSummaryDatumPropType,
  in: routeSummaryDatumPropType,
  out: routeSummaryDatumPropType,
  over: routeSummaryDatumPropType,
  post: routeSummaryDatumPropType,
  screen: routeSummaryDatumPropType,
  slant: routeSummaryDatumPropType,
  unclassified: routeSummaryDatumPropType,
});

/* Used for the key */
export const RouteTreeStatDefinitionPropType = PropTypes.shape({
  key: PropTypes.string,
  domain: PropTypes.arrayOf(PropTypes.number),
  colorFunction: PropTypes.func,
  columnOrder: PropTypes.number,
  abbrev: PropTypes.string,
  prettyName: PropTypes.string,
  description: PropTypes.string,
  units: PropTypes.oneOf(Object.values(API_STAT_UNITS)),
});
