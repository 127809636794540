import { gql } from '@apollo/client';

export const GET_PLAYER_SCOUT = gql`
  query GetPlayerScout(
    $competitionId: Int!
    $seasonId: Int!
    $positions: [GeneralPosition!]!
    $statFilters: [StatConstraint!]
    $stats: [String!]!
    $bioFilter: PlayerBioFilter
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    playerScout(
      competitionId: $competitionId
      seasonIds: [$seasonId]
      positions: $positions
      stats: $stats
      filters: { statFilters: $statFilters, bioFilter: $bioFilter }
    ) {
      season {
        id
        name
      }
      player {
        id
        name
        knownName
        height
        weight
        collegeClass
        ncaaTransferPortal
        homeTown {
          id
          name
          state
        }
        highSchool {
          id
          name
          city
          state
        }
        recruitingClass
        recruitingRanking247
        recruitingRankingComposite
        recruitingReranking
        collegesOffered {
          id
          name
          shortName
        }
        collegesAttended {
          id
          name
          shortName
        }
        rosteredPosition
        team {
          id
          name
          shortName
        }
      }
      stats {
        key
        value
      }
    }
  }
`;
