import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  SearchResultHolder,
  SearchResults,
  SearchResultsLoading,
  SearchWithList,
} from '../BiometricFilters.styles';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import { useSearchColleges } from '../hooks/useSearchColleges';
import BiometricChips from './BiometricChips';
import BiometricListItem from './BiometricListItem';

const TeamOfferMultiselect = ({
  selectedBiometrics,
  setSelectedBiometrics,
}) => {
  const collegesOfferedFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.collegesOffered.key
  );

  const currentTokens = isEmpty(collegesOfferedFilter?.tokens)
    ? []
    : [...collegesOfferedFilter.tokens];

  const [searchValue, setSearchValue] = useState('');
  const trimmedSearchValue = (searchValue || '').trim();

  const { collegeTeams, isLoading } = useSearchColleges(trimmedSearchValue);

  const CollegeListItem = (collegeTeam) => {
    const { name, shortName, id: teamId } = collegeTeam;
    const teamName = `${shortName}, ${name}`;
    const lIToken = { id: teamId, name: teamName };
    return (
      <BiometricListItem
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.collegesOffered.key}
        currentTokens={currentTokens}
        searchResultToken={lIToken}
        onSelected={() => setSearchValue('')}
      />
    );
  };

  const adornment = trimmedSearchValue.length > 0 ? 'Close' : 'Search';

  return (
    <SearchWithList>
      <h3>College teams offering </h3>
      <TextField
        id="colleges-offered-search-filter"
        labelPosition="none"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        adornmentIcon={adornment}
        adornmentOnClick={() => {
          if (trimmedSearchValue.length > 0) {
            setSearchValue('');
          }
        }}
        placeholder="Search for a college team"
        size="small"
      />
      {trimmedSearchValue.length > 0 && (
        <SearchResultHolder>
          {isLoading && (
            <SearchResultsLoading>
              <Loader active inline size="small" content="Loading Data" />
            </SearchResultsLoading>
          )}
          <SearchResults>{collegeTeams?.map(CollegeListItem)}</SearchResults>
        </SearchResultHolder>
      )}
      <BiometricChips
        selectedBiometrics={selectedBiometrics}
        setSelectedBiometrics={setSelectedBiometrics}
        biometricKey={SCOUT_BIOMETRIC_FILTERS.collegesOffered.key}
        tokens={currentTokens}
      />
    </SearchWithList>
  );
};
TeamOfferMultiselect.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default TeamOfferMultiselect;
