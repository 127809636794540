import { styled } from 'styled-components';

export const AccordionTileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5rem 0 1.5rem;
  height: 100%;
  color: ${({ theme }) => theme.colours.ink.primary.main};

  .drive-index {
    width: 7.5%;
    color: ${({ theme }) => theme.colours.graphics.primary.main};
  }

  .drive-clock {
    width: 10%;
    color: ${({ theme }) => theme.colours.graphics.primary.main};

    span:first-of-type {
      margin-right: 0.5rem;
    }
  }

  .drive-team-on-offense {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 600;

    .team-indicator {
      display: flex;

      span {
        margin-left: 0.5rem;
      }
    }
  }

  .drive-result {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 600;
  }

  .drive-scores {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 2rem;
    color: ${({ theme }) => theme.colours.graphics.primary.main};
    font-weight: 600;
  }

  svg {
    min-height: 1.4rem;
    min-width: 1.4rem;

    path {
      fill: ${({ $teamColor }) => $teamColor};
    }
  }
`;
