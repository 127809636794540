import { styled } from 'styled-components';

export const RatioIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  top: 0.25rem;
  height: 0.75rem;
  margin-left: 0.625rem;
`;

export const RatioIndicatorBar = styled.div`
  width: 0.25rem;
  background-color: ${({ theme }) => theme.colours.canvas.primary.main};
  margin-left: 0.1875rem;
  transition: background-color 600ms ease-in-out;

  &.selected {
    background-color: ${({ theme }) => theme.colours.interface.main};
  }
`;

export const RatioIndicatorLabel = styled.span`
  position: relative;
  top: -0.0625rem;
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.colours.ink.primary.main};
  opacity: 0.8;
  margin-left: 0.5rem;
`;
