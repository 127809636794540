import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Pagination,
} from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { drop, take } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { getPlayFinderResults } from './getPlayFinderResults';
import {
  PLAY_FINDER_COLUMNS,
  PLAY_FINDER_PAGESIZE_INITIAL,
  PLAY_FINDER_PAGESIZE_OPTIONS,
} from './PlayFinderResults.constants';
import {
  SummaryError,
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';
import {
  formatPlayFinderResultsTable,
  formatPlayFinderVideoData,
  formatSelectedFilters,
  getPlayFinderHeader,
  sortPlays,
  formatPlaysCSV,
  formatSelectedHeaders,
  mergeArraysExcludeDuplicates,
} from './PlayFinderResults.dataManipulation';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import DownloadCSVButton from '../../../components/buttons/DownloadCSV';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { WizardResultsTableFooter } from './PlayFinderResults.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { IconButtonWrapper } from '../../../components/buttons/Playlist/Playlist.styles';
import PlayFinderFilters from '../PlayFinderFilters/PlayFinderFilters';
import { MemoizedVideoTile } from '../../../components/VideoTile/VideoTile';
import PlaylistButton from '../../../components/buttons/Playlist/Playlist';
import { USER_ROLES } from '../../../utils/constants/auth';
import SelectableSortableTable from '../../../components/Tables/SortableTable/SelectableSortableTable';
import { usePlayFinderFilterTreeContext } from '../PlayFinderFilterTree/usePlayFinderFilterTreeContext';
import PlayFinderColumns from '../PlayFinderColumns/PlayFinderColumns';
import PlayFinderSummary from './PlayFinderSummary/PlayFinderSummary';
import { restoreFilterTree } from '../PlayFinderFilters/PlayFinderFilters.dataManipulation';
import { STATSBOMB_PRESET_FILTERS_DATA } from '../PlayFinderFilters/PlayFinderFilters.constants';

// PlayFinderResults displays the results of the GET_PLAY_FINDER_RESULTS query in a table
// It also displays a summary of the results and allows the user to download the results as a CSV
const PlayFinderResults = () => {
  const windowWidth = useWindowWidth();
  // table
  const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
  const [activePage, setActivePage] = useState(1);
  const [sortDirection, setSortDirection] = useState(ASCENDING);
  const [sortKey, setSortKey] = useState('down');
  const [playsPerPage, setPlaysPerPage] = useState(
    PLAY_FINDER_PAGESIZE_INITIAL
  );
  const [tableData, setTableData] = useState({ data: [], columns: [] });
  // summary open/close state
  const [displaySummaryOpen, setDisplaySummaryOpen] = useState(true);
  // video
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const [videoOpen, setVideoOpen] = useState(false);
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const videoActive = useRef(false);
  const isVideoVisible = (() => videoOpen)();
  const csvData = formatPlaysCSV(tableData.data, tableData.columns);
  // multiple plays/events
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [currentlyPlayingPlay, setCurrentlyPlayingPlay] = useState();
  const areEventsSelected = selectedEvents.length > 0;

  // video Auth
  const { user } = useKitbagAuth();
  const hasVideoAuth = user[
    'https://video.statsbomb.com/jwt/claims'
  ]?.roles.includes(USER_ROLES.VIDEO_CUSTOMER);
  const expansionPercentage = hasVideoAuth ? 0.5 : 0.7;

  const {
    leagues: leagueParam,
    seasons: seasonParam,
    teams: teamParam,
    offense: offenseParam,
    preset: presetParam,
  } = useParams();

  // filters
  const filtersRef = useRef();
  const [selectedFilters, setSelectedFilters] = useState(
    presetParam
      ? restoreFilterTree(
          JSON.parse(STATSBOMB_PRESET_FILTERS_DATA[presetParam].json)
        )
      : []
  );
  const selectedFiltersCount = formatSelectedFilters(selectedFilters).length;

  const excludeColumns = PLAY_FINDER_COLUMNS.map((col) => col.key);
  const playFinderResultsQuery = getPlayFinderResults(
    tableData.columns
      .filter((col) => !excludeColumns.includes(col.key))
      ?.map((col) => col.key)
  );
  const {
    data: playFinderResultData,
    loading: isLoadingPlayFinderResults,
    error: hasPlayFinderResultsError,
  } = useQuery(gql(playFinderResultsQuery), {
    fetchPolicy: 'no-cache',
    variables: {
      competitionId: parseInt(leagueParam, 10),
      seasonId: parseInt(seasonParam, 10),
      teamId: parseInt(teamParam, 10),
      offense: JSON.parse(offenseParam),
      filters: formatSelectedFilters(selectedFilters),
      columns: tableData.columns
        .filter((col) => !excludeColumns.includes(col.key))
        .map((col) => col.filterId),
    },
  });
  if (hasPlayFinderResultsError) {
    console.error(
      ERROR_CODES.GET_PLAY_FINDER_RESULTS,
      hasPlayFinderResultsError
    );
  }

  const { data: playFinderFilters, loading: isLoadingPlayFinderFilters } =
    usePlayFinderFilterTreeContext();

  const isLoading = isLoadingPlayFinderResults || isLoadingPlayFinderFilters;
  const hasError = hasPlayFinderResultsError;
  const playsCount = playFinderResultData?.playFinder?.plays?.length || 0;

  // sort table columns
  const handleSort = (statName) => {
    if (statName === sortKey) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      const sortColumn = tableData.columns.find((h) => h.key === statName);
      const defaultSortDirection =
        sortColumn?.units === API_STAT_UNITS.STRING ? ASCENDING : DESCENDING;
      setSortDirection(defaultSortDirection);
      setSortKey(statName);
    }
    setActivePage(1);
  };

  // callback: handleFilterUpdate
  // after filters are added or removed
  const onFiltersUpdate = (activeFilters) => {
    setActivePage(1);
    setSelectedFilters(activeFilters);
    const filtersAsHeaders = formatSelectedHeaders(activeFilters);
    // cols in the current table that aren't mandatory
    const extraColumns = tableData.columns.filter(
      (col) => !excludeColumns.includes(col.key)
    );
    // new cols that aren't mandatory
    const newFilterColumns = filtersAsHeaders.filter(
      (col) => !excludeColumns.includes(col.key)
    );
    const newCols = mergeArraysExcludeDuplicates(
      extraColumns.length > newFilterColumns.length
        ? tableData.columns.filter(
            (item) =>
              !extraColumns.some((filterItem) => filterItem.key === item.key)
          )
        : tableData.columns,
      filtersAsHeaders,
      'key'
    );
    setTableData({
      data: [...tableData.data],
      columns: newCols,
    });
  };

  // callback from video player playlist hook
  const onPlaylistChange = (currentlyPlayingPl) => {
    // if multiple select state
    if (hasVideoAuth && selectedEvents.length > 0) {
      setCurrentlyPlayingPlay(currentlyPlayingPl);
    }
  };

  // clear single and multiple selections, close the video panel
  const closeVideo = () => {
    videoActive.current = false;
    setVideoOpen(false);
  };

  // select table row(s)
  const onToggle = (rows) => {
    setSelectedEvents(rows.filter((r) => r.checked));
    setTableData({ data: rows, columns: tableData.columns });
  };

  useEffect(() => {
    if (playFinderResultData) {
      // first start with default columns
      const cols =
        tableData.columns.length < PLAY_FINDER_COLUMNS.length
          ? PLAY_FINDER_COLUMNS
          : tableData.columns;
      const playsSkipped = (activePage - 1) * playsPerPage;
      const sortedPlays = sortPlays(
        playFinderResultData?.playFinder?.plays,
        sortKey,
        sortDirection
      );
      const visiblePlays = take(drop(sortedPlays, playsSkipped), playsPerPage);
      const tableDataIn = formatPlayFinderResultsTable(visiblePlays, cols);
      setTableData({ data: tableDataIn, columns: cols });
    }
  }, [playFinderResultData, tableData.columns]);

  useEffect(() => {
    const playsSkipped = (activePage - 1) * playsPerPage;
    const sortedPlays = sortPlays(
      playFinderResultData?.playFinder?.plays,
      sortKey,
      sortDirection
    );
    const visiblePlays = take(drop(sortedPlays, playsSkipped), playsPerPage);
    const tableDataIn = formatPlayFinderResultsTable(
      visiblePlays,
      tableData.columns
    );
    setTableData({ data: tableDataIn, columns: tableData.columns });
  }, [activePage, playsPerPage, sortDirection, selectedFilters]);

  useEffect(() => {
    // set sidebar
    setSidebarWidthCalc(
      windowWidth - (isVideoVisible ? expansionPercentage : 1) * windowWidth
    );
  }, [windowWidth, isVideoVisible]);

  return (
    <PlayFinderFilters
      ref={filtersRef}
      handleFilterUpdate={onFiltersUpdate}
      selectedFilters={selectedFilters}
    >
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <SidebarRightLayout
            $sidebarWidth={`${sidebarWidthCalc}px`}
            $inlineSize={`${isVideoVisible ? 40 : 0}%`}
            $gap="0"
            $padding="0"
            $closed={isVideoVisible ? '1' : '0'}
          >
            <div>
              <Grid item xs={12}>
                <PageHeader
                  href="/playfinder/wizard/"
                  rootPage="Play Finder"
                  activePage="Results"
                  showPlayerName={false}
                  showTeamName={false}
                  customTitle={getPlayFinderHeader(
                    playFinderResultData?.playFinder?.team?.name,
                    playFinderResultData?.playFinder?.season?.name,
                    JSON.parse(offenseParam)
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <PlayFinderSummary
                  playFinderResultData={playFinderResultData}
                  playsCount={playsCount}
                  isLoading={isLoading}
                  handleDisplaySummaryOpen={setDisplaySummaryOpen}
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryTile>
                  <SummaryTileHeader>
                    <div>
                      <h3>Plays</h3>
                      <IconButtonWrapper>
                        <Button
                          onClick={() => {
                            filtersRef.current.toggle();
                            if (videoOpen) {
                              setVideoOpen(false);
                            }
                          }}
                          size="small"
                          title="Add plays to playlist"
                          variant={
                            selectedFiltersCount > 0 ? 'primary' : 'secondary'
                          }
                          isDisabled={isLoading || hasError}
                        >
                          <span>
                            <Icon variant="Filter" size="small" />
                          </span>
                          Filters ({selectedFiltersCount})
                        </Button>
                      </IconButtonWrapper>
                    </div>
                    <div>
                      <PlaylistButton
                        handleClick={() => {
                          if (selectedEvents.length === 0) {
                            videoActive.current = true;
                          }
                          setVideoOpen(true);
                          if (filtersRef.current.filterOpen) {
                            filtersRef.current.toggle();
                          }
                        }}
                        numberOfPlays={selectedEvents.length}
                        isDisabled={
                          selectedEvents.length === 0 ||
                          isLoading ||
                          isVideoVisible
                        }
                        variant={areEventsSelected ? 'primary' : 'secondary'}
                      />
                      <PlayFinderColumns
                        isDisabled={isLoading || hasError}
                        playFinderFilterTree={
                          playFinderFilters?.playFinderFilterTree
                        }
                        selectedColumns={tableData.columns}
                        handleSortedColumns={(columns) =>
                          setTableData({ data: tableData.data, columns })
                        }
                      />
                      <DownloadCSVButton
                        data={csvData}
                        headers={tableData.columns}
                        fileName="test"
                        data-testid="downloadCSVButtonT"
                        isDisabled={isLoading || hasError}
                      />
                    </div>
                  </SummaryTileHeader>
                  <SummaryTileBody
                    style={{
                      minHeight: displaySummaryOpen
                        ? 'calc(100vh - 448px)'
                        : 'calc(100vh - 268px)',
                    }}
                  >
                    {isLoading && !hasError && (
                      <Dimmer active>
                        <Loader content="Loading" />
                      </Dimmer>
                    )}
                    {!isLoading && hasError && (
                      <Dimmer active>
                        <SummaryError>
                          <Icon
                            size="small"
                            variant="Warning"
                            colour="primary.main"
                          />
                          There has been an error.
                        </SummaryError>
                      </Dimmer>
                    )}
                    <SelectableSortableTable
                      name="play-finder-results-table"
                      headers={tableData.columns}
                      data={tableData.data}
                      handleSort={handleSort}
                      sortDirection={sortDirection}
                      sortBy={sortKey}
                      handleToggle={onToggle}
                    />

                    <WizardResultsTableFooter>
                      <Dropdown
                        id="playFinderResultsPaginationDropdown"
                        isSearchable={false}
                        menuAlignment="left"
                        menuPlacement="top"
                        onChange={(item) => setPlaysPerPage(item.value)}
                        options={PLAY_FINDER_PAGESIZE_OPTIONS}
                        value={PLAY_FINDER_PAGESIZE_OPTIONS.find(
                          (op) => op.value === playsPerPage
                        )}
                        labelPosition="left"
                        label="Per page"
                        variant="tertiary"
                        size="small"
                      />
                      <Pagination
                        activePage={activePage}
                        onChange={({ pageSelected }) =>
                          setActivePage(pageSelected)
                        }
                        itemsPerPage={playsPerPage}
                        dataLength={playsCount}
                        hasEdgePageButtons
                      />
                    </WizardResultsTableFooter>
                  </SummaryTileBody>
                </SummaryTile>
              </Grid>
            </div>

            <div>
              {isVideoVisible && playFinderResultData && (
                <Grid item xs={12}>
                  <MemoizedVideoTile
                    title={currentlyPlayingPlay?.description}
                    subTitle={currentlyPlayingPlay?.descriptionLineTwo}
                    data={formatPlayFinderVideoData(selectedEvents)}
                    handlePlaylistChange={onPlaylistChange}
                    handleClose={closeVideo}
                  />
                </Grid>
              )}
            </div>
          </SidebarRightLayout>
        </Grid>
      </KitbagPageGridHolder>
    </PlayFinderFilters>
  );
};

export default PlayFinderResults;
