import React, { useState } from 'react';
import { Message, Loader } from 'semantic-ui-react';
import { Dropdown } from '@statsbomb/kitbag-components';
import useQueryString from '../../../../utils/hooks/useQueryString';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../../components/Layout/Layout.styles';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import { HavocMainPage } from '../TeamHavoc.styles';
import Tile from '../../../../components/Tile/Tile';
import {
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_TYPE_ALL,
  HAVOC_PLAYER_OPTION_ANY,
  HAVOC_PLAY_TYPES,
  HAVOC_BLITZ_OPTION_ANY,
  HAVOC_DEFENSIVE_FRONT_OPTION_ANY,
} from '../../../../visualisations/HavocChart/HavocChart.constants';
import { useGetHavocEvents, useGetHavocTeamStats } from '../TeamHavoc.hooks';
import {
  getPlayers,
  getPositions,
  getBlitzTypes,
  getDefensiveFrontTypes,
  getHavocTotals,
  filterHavocData,
  getZonedHavocData,
} from '../TeamHavoc.DataManipulation';
import HavocSelection from '../HavocSelection';
import HavocSummaryBar from '../HavocSummaryBar/HavocSummaryBar';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import HavocTableTile from '../HavocTable.jsx/HavocTableTile';
import { HAVOC_TABLE_AGGREGATION_MODE_TYPE } from '../HavocTable.jsx/HavocTable.constants';
import HavocTableFilters from '../HavocTable.jsx/HavocTableFilters';
import SwitchVisTableButton from '../../../../components/buttons/SwitchVisTable/SwitchVisTable';
import HavocBarTile from '../HavocBars/HavocBarTile';
import {
  HAVOC_SUMMARY_COLORING_MDOES,
  HAVOC_SUMMARY_PERCENTAGE_TYPES,
} from '../HavocBars/HavocBars.constants';
import HavocBarFilters from '../HavocBars/HavocBarFilters';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import { HAVOC_DEFENDER_ZONES } from '../../../../visualisations/HavocSummary/HavocSummary.constants';

const HavocSummary = () => {
  /** selected event will open video / change layout
   * but for now in summary can just be always off
   * but included to maintain logic parity in layout elements
   */
  const selectedEvent = false;

  const [showDefensive, setShowDefensive] = useQueryString('defensive', true);
  const [havocPlayMode, setHavocPlayMode] = useQueryString(
    'havocPlayMode',
    HAVOC_PLAY_TYPES.ANY.value
  );
  const [havocMode, setHavocMode] = useQueryString(
    'havocmode',
    HAVOC_TYPE_ALL.value
  );
  const [selectedPlayerId, setSelectedPlayerId] = useQueryString(
    'player',
    HAVOC_PLAYER_OPTION_ANY.value
  );
  const [selectedPositionCode, setSelectedPositionCode] = useQueryString(
    'position',
    HAVOC_POSITION_OPTION_ANY.value
  );
  const [selectedBlitzType, setSelectedBlitzType] = useQueryString(
    'blitz',
    HAVOC_BLITZ_OPTION_ANY.value
  );
  const [selectedDefensiveFront, setSelectedDefensiveFront] = useQueryString(
    'front',
    HAVOC_DEFENSIVE_FRONT_OPTION_ANY.value
  );
  /* For tables */
  const [aggregationMode, setAggregationMode] = useQueryString(
    'aggregationmode',
    HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP
  );
  /* For Summary Chart */
  const [percentageMode, setPercentageMode] = useQueryString(
    'percentageMode',
    HAVOC_SUMMARY_PERCENTAGE_TYPES.OF_ALL_PLAYS.value
  );
  const [summaryColorMode, setSummaryColorMode] = useQueryString(
    'summaryColorMode',
    HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value
  );

  /* 
  temporary ~ selecting zones will come from sankey vis, but for now tmp ddl added to show functionality of bar chart
  */
  const noZone = { value: 'none', label: 'No selection' };
  const defenderZones = Object.values(HAVOC_DEFENDER_ZONES)
    .map((m) => ({
      value: m.key,
      label: m.name,
    }))
    .concat(noZone);
  const [selectedDefenderZone, setSelectedDefenderZone] = useState(
    noZone.value
  );
  const selectedDefenderZones =
    selectedDefenderZone === noZone.value ? [] : [selectedDefenderZone];

  /* Get the data */
  const {
    loading: isLoadingHavoc,
    error: errorHavoc,
    teamHavocData,
  } = useGetHavocEvents(showDefensive, havocMode);

  const {
    loading: isLoadingPlays,
    error: errorSummary,
    playSummary,
  } = useGetHavocTeamStats(showDefensive);
  const isError = !!errorHavoc || !!errorSummary;
  const isLoadingSummary = isLoadingHavoc || isLoadingPlays;

  /* Define the list of players and positions to filter on */
  const relevantPlayers = getPlayers(teamHavocData);
  const positionFrequencies = getPositions(teamHavocData);
  const blitzTypes = getBlitzTypes(teamHavocData);
  const defensiveFronts = getDefensiveFrontTypes(teamHavocData);

  /* Get the havoc events that correspond to the filtering */
  const filteredHavocData = filterHavocData({
    teamHavocData,
    selectedPlayerId,
    selectedBlitzType,
    selectedDefensiveFront,
    selectedPositionCode,
  });

  /* Summary bar stuff ~ based on filtering */
  const havocCounts = getHavocTotals(filteredHavocData);
  const havocSummary = {
    havocPlays: havocCounts.zonedHavocPlays,
    havocEvents: havocCounts.zonedHavocEvents,
  };

  /* Summary chart stuff */
  const zonedHavocEvents = getZonedHavocData(filteredHavocData);

  /** Page level display settings */
  const [displayTable, setDisplayTable] = useState(false);
  const [displayTableEvents, setDisplayTableEvents] = useState(false);

  return (
    <KitbagPageGridHolder>
      <HavocMainPage>
        <div className={selectedEvent ? 'havoc-non-video' : 'havoc-no-video'}>
          <SidebarRightLayout
            $sidebarWidth={selectedEvent ? '0px' : '320px'}
            $gap="0.8rem"
            $padding="0.8rem 0 0 0"
          >
            <div>
              <PageHeader
                href="/team/havoc/:leagues/:seasons/:teams"
                rootPage="Team"
                activePage="Havoc Summary"
                showPlayerName={false}
                showTeamName
              />
              <HavocSummaryBar
                playSummary={playSummary}
                havocSummary={havocSummary}
                isError={isError} // if a problem, just list once up top
              />

              {!displayTable && (
                <HavocBarTile
                  havocData={zonedHavocEvents}
                  playSummary={playSummary}
                  isLoadingSummary={isLoadingSummary}
                  handleVisTableSwitch={() => setDisplayTable(!displayTable)}
                  colorMode={summaryColorMode}
                  havocSummaryMode={percentageMode}
                  selectedDefenderZones={selectedDefenderZones}
                />
              )}
              {displayTable && (
                <HavocTableTile
                  havocData={zonedHavocEvents}
                  aggregationMode={aggregationMode}
                  playSummary={playSummary}
                  tileTitle="Havoc Plays Summary"
                  fileName="Havoc Plays counts.csv"
                  handleVisTableSwitch={() => setDisplayTable(!displayTable)}
                  isPlaysMode
                />
              )}

              <Tile border="0" margin="0 0 0.8rem 0">
                <Tile.Header>
                  <h2 title="Visualisation displaying havoc event locations, or corresponding table">
                    Havoc Events
                  </h2>
                  <div className="buttons">
                    <SwitchVisTableButton
                      isShowingVis={!displayTableEvents}
                      handleClick={() =>
                        setDisplayTableEvents(!displayTableEvents)
                      }
                    />
                  </div>
                </Tile.Header>
                <Tile.Body>
                  {isError && !isLoadingHavoc && (
                    <Message negative>
                      There has been an error. Please contact support.
                    </Message>
                  )}
                  <span>Events vis/table will go here</span>
                  <span>
                    {displayTableEvents ? 'Event table mode' : 'Event vis mode'}
                  </span>
                  {isLoadingHavoc && (
                    <Dimmer
                      active
                      style={{
                        maxWidth: '100%',
                      }}
                    >
                      <Loader content="Loading Data" />
                    </Dimmer>
                  )}
                </Tile.Body>
              </Tile>
            </div>
            <div>
              {!selectedEvent && (
                <>
                  <HavocSelection
                    showDefensive={showDefensive}
                    setShowDefensive={setShowDefensive}
                    havocPlayMode={havocPlayMode}
                    setHavocPlayMode={setHavocPlayMode}
                    havocMode={havocMode}
                    setHavocMode={setHavocMode}
                    selectedBlitzType={selectedBlitzType}
                    setSelectedBlitzType={setSelectedBlitzType}
                    selectedDefensiveFront={selectedDefensiveFront}
                    setSelectedDefensiveFront={setSelectedDefensiveFront}
                    selectedPlayerId={selectedPlayerId}
                    setSelectedPlayerId={setSelectedPlayerId}
                    selectedPositionCode={selectedPositionCode}
                    setSelectedPositionCode={setSelectedPositionCode}
                    relevantPlayers={relevantPlayers}
                    positionFrequencies={positionFrequencies}
                    blitzTypes={blitzTypes}
                    defensiveFronts={defensiveFronts}
                  />
                </>
              )}
              <HavocTableFilters
                aggregationMode={aggregationMode}
                setAggregationMode={setAggregationMode}
              />
              <HavocBarFilters
                percentageMode={percentageMode}
                setPercentageMode={setPercentageMode}
                summaryColorMode={summaryColorMode}
                setSummaryColorMode={setSummaryColorMode}
              />
              <AccordionTile
                id="field-accordion"
                header={
                  <Tile.AccordionHeader>
                    Tmp Vis 2 Simulation
                  </Tile.AccordionHeader>
                }
                body={
                  <Tile.AccordionBody>
                    <Dropdown
                      id="tmp-selected-defender-zones"
                      options={defenderZones}
                      label="Selected Defender Zone Proxy"
                      menuPosition="static"
                      onChange={(selectedOption) => {
                        setSelectedDefenderZone(selectedOption.value);
                      }}
                      value={defenderZones.find(
                        (f) => f.value === selectedDefenderZone
                      )}
                    />
                  </Tile.AccordionBody>
                }
                isMount
                saveState
                isExpandedDefault
              />
            </div>
          </SidebarRightLayout>
        </div>
      </HavocMainPage>
    </KitbagPageGridHolder>
  );
};

export default HavocSummary;
