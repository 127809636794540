import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Icon } from '@statsbomb/kitbag-components';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import SnapDistributionChart from '../PlayerSnap/SnapDistributionChart';
import { GET_SNAP_DISTRIBUTIONS } from '../PlayerSnap/getSnapDistributions';
import { formatSnapData } from '../PlayerSnap/PlayerSnap.dataManipulation';
import { DOT_COLOR_MODE } from '../PlayerSnap/SnapDistributionChart.constants';
import { ROTATIONS } from '../../../utils/constants/charting';

const PlayerSnapTile = ({ padding }) => {
  // chart data
  const {
    loading: isLoading,
    error: isError,
    data,
  } = useQuery(GET_SNAP_DISTRIBUTIONS);
  // clean up the data
  const snapData = data?.snapEvents?.edges.map(formatSnapData).map((d) => ({
    ...d,
    inSelectedZone: true,
  }));

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Snap Distribution</h3>
        </div>
        <div>
          <GoTo
            href="/player/snap/:leagues/:seasons/:teams/:players"
            linkName="Open snap distribution"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {isLoading && <TileDimmer />}
        {snapData?.length === 0 && (
          <TileDimmer active>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {isError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <SnapDistributionChart
          id="player-overview-snap-distribution"
          data={snapData}
          showDx
          showTotalOnly
          margin={{ top: 30, right: 80, bottom: 0, left: 0 }}
          isInteractive={false}
          withinTile
          colorMode={DOT_COLOR_MODE.PLAY_TYPE}
          orientation={ROTATIONS.HORIZONTAL}
          padding={padding}
        />
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol style={{ textAlign: 'left' }}>
          {isLoading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

PlayerSnapTile.propTypes = {
  padding: PropTypes.string,
};

PlayerSnapTile.defaultProps = {
  padding: '0',
};

export default PlayerSnapTile;
