import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@statsbomb/kitbag-components';
import Tile from '../../../../components/Tile/Tile';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import {
  HAVOC_SUMMARY_COLORING_MDOES,
  HAVOC_SUMMARY_PERCENTAGE_TYPES,
} from './HavocBars.constants';

/* This provides the accordion section for filtering havoc things */
const HavocBarFilters = ({
  percentageMode,
  setPercentageMode,
  summaryColorMode,
  setSummaryColorMode,
}) => {
  const percentageTypes = Object.values(HAVOC_SUMMARY_PERCENTAGE_TYPES);
  const colourModes = Object.values(HAVOC_SUMMARY_COLORING_MDOES);
  return (
    <AccordionTile
      id="field-accordion"
      header={
        <Tile.AccordionHeader>Summary Chart Options</Tile.AccordionHeader>
      }
      body={
        <Tile.AccordionBody>
          <Dropdown
            id="havoc-summary-percentage-mode-dropdown"
            options={percentageTypes}
            label="Percentage Mode"
            menuPosition="static"
            onChange={(selectedOption) => {
              setPercentageMode(selectedOption.value);
            }}
            value={percentageTypes.find((f) => f.value === percentageMode)}
          />
          <Dropdown
            id="havoc-summary-color-mode-dropdown"
            options={colourModes}
            label="Color Mode"
            menuPosition="static"
            onChange={(selectedOption) => {
              setSummaryColorMode(selectedOption.value);
            }}
            value={colourModes.find((f) => f.value === summaryColorMode)}
          />
        </Tile.AccordionBody>
      }
      isMount
      saveState
      isExpandedDefault
    />
  );
};

HavocBarFilters.propTypes = {
  /** state/querystring values/setters for the options controlled in this selection
   */
  percentageMode: PropTypes.string.isRequired,
  setPercentageMode: PropTypes.func.isRequired,
  summaryColorMode: PropTypes.string.isRequired,
  setSummaryColorMode: PropTypes.func.isRequired,
};

export default HavocBarFilters;
