import { styled } from 'styled-components';

export const StyledPersonnelDistributionBar = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .label {
    width: 4.1rem;
    text-align: right;
    ${({ theme }) => theme.typography.headingMedium};
  }

  .bar {
    flex: 1;

    ${({ theme, $indicator }) =>
      $indicator &&
      `
      > div {
        > div:first-of-type {
          div {
            &::after {
              content: '';
              position: absolute;
              top: 4px;
              right: -10px;
              border-top: 12px solid transparent;
              border-bottom: 12px solid transparent;
              border-left: 8px solid ${theme.colours.visualisations.objects.n1.main};
            }
          }
        }
      }
    `}
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 8rem;

    .plays {
      ${({ theme }) => theme.typography.headingSmall};
    }

    .league-average {
      color: ${({ theme }) =>
        theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
      ${({ theme }) => theme.typography.bodySmall};
    }
  }
`;
