import {
  FIELD_X_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
} from '../../../utils/constants/charting';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../../utils/helpers/field.constants';
import {
  HAVOC_AXES_PADDING,
  HAVOC_FIELD_SIZES_X,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_FOCUS_MODE_LOS,
} from '../../../visualisations/HavocChart/HavocChart.constants';

/* Aim of this function is to set a small max width for horizontal-orientation small field displays
    Because the havoc field is (most views) much wider (sideline-sideline) than deep, if it expands
    to fill horizontal space it becomes unusably big vertically.
    Solution here is that horizontal mode defaults to full field, and shrinking maintains aspect ratio (i.e. height)

    TODO: General Vis Issue of needing to know how much height is available, and sizing to maximise 
        from this in some orientations. 
        Needs thought/coordination about layout breakpoints.

   Current assumption here that axes padding is independent of orientation. If that changes, this code
        will require updating
*/
export const getHavocWidth = (selectedRotation, fieldFocus, isExport) => {
  const havocPxPerYd = DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard;
  const havocAxes = HAVOC_AXES_PADDING.left + HAVOC_AXES_PADDING.right;
  if (selectedRotation !== ROTATIONS.HORIZONTAL) {
    const rawWidth = FIELD_Y_YARDS_RELATIVEY * havocPxPerYd + havocAxes;
    /* In vertical orientation, allow larger size */
    const enlargementFactor = isExport ? 1.5 : 2;
    return rawWidth * enlargementFactor;
  }
  /* If horizontal amount of field shown determines width to cap to */
  if (fieldFocus === HAVOC_FOCUS_MODE_BOX.value) {
    return (
      (HAVOC_FIELD_SIZES_X[fieldFocus].LOS +
        HAVOC_FIELD_SIZES_X[fieldFocus].END) *
        havocPxPerYd +
      havocAxes
    );
  }
  if (fieldFocus === HAVOC_FOCUS_MODE_LOS.value) {
    return (
      (HAVOC_FIELD_SIZES_X[fieldFocus].LOS +
        HAVOC_FIELD_SIZES_X[fieldFocus].END) *
        havocPxPerYd +
      havocAxes
    );
  }
  return FIELD_X_YARDS * havocPxPerYd + havocAxes;
};
