import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import uiDefault from '../../apollo/uiDefault';
import {
  uiState,
  cf_FormationsEnabled,
  cf_FormationsOffense,
} from '../../apollo';

import { OFFENSE_FORMATIONS } from '../../utils/constants/charting';
import {
  StyledModal,
  TriggerButtonContainer,
  SelectAllButtonContainer,
} from './ContextFilters.styles';
import { enableFilter } from './Filters.helper';
import { useIsEligiblePage } from './ContextFilters.hooks';

const FormationFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_ModalOpen, setModalOpen] = useState(false);
  // context filters in global state
  const cf_FormationsOffenseRV = useReactiveVar(cf_FormationsOffense);
  const cf_FormationsEnabledRV = useReactiveVar(cf_FormationsEnabled);

  // context filters in local state
  const [st_FormationsOffense, setFormationsOffense] = useState(
    cf_FormationsOffenseRV
  );

  const handleFormationsOffense = (isChecked, index) => {
    const formationsOffense = [...st_FormationsOffense];
    formationsOffense[index] = isChecked;
    setFormationsOffense([...formationsOffense]);
    enableFilter(cf_FormationsEnabledRV, cf_FormationsEnabled, 'formations');
  };

  const handleAll = (condition) => {
    setFormationsOffense(new Array(OFFENSE_FORMATIONS.length).fill(condition));
    enableFilter(cf_FormationsEnabledRV, cf_FormationsEnabled, 'formations');
  };

  const handleReset = () => {
    const { formations } = uiDefault.contextFilters;

    setFormationsOffense(new Array(OFFENSE_FORMATIONS.length).fill(false));
    cf_FormationsEnabled(formations.enabled);

    uiState().contextFilters.formations.offenseFormations = new Array(
      OFFENSE_FORMATIONS.length
    ).fill(false);
    uiState().contextFilters.formations.enabled = formations.enabled;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleModalClose = () => {
    const { contextFilters } = uiState();
    const { formations } = contextFilters;

    cf_FormationsOffense([...st_FormationsOffense]);
    formations.offenseFormations = [...st_FormationsOffense];
    cf_FormationsEnabled(cf_FormationsEnabledRV);

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setModalOpen(false);
  };

  const filterCount = [cf_FormationsEnabledRV].filter(Boolean).length;
  const isEligiblePage = useIsEligiblePage('FORMATIONS');
  const isEnabled = filterCount > 0 && isEligiblePage;

  // slicing on the first value of each formation to display in columns
  const offenseCol1 = [...OFFENSE_FORMATIONS.slice(0, 4)];
  const offenseCol2 = [...OFFENSE_FORMATIONS.slice(4, 8)];
  const offenseCol3 = [...OFFENSE_FORMATIONS.slice(8, 12)];
  const offenseCol4 = [...OFFENSE_FORMATIONS.slice(12, 15)];
  const offenseCol5 = [...OFFENSE_FORMATIONS.slice(15, 17)];
  const offenseCol6 = [...OFFENSE_FORMATIONS.slice(17, 18)];

  return (
    <StyledModal
      onClose={handleModalClose}
      onOpen={() => {
        setModalOpen(true);
      }}
      open={st_ModalOpen}
      className="formations"
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Formations
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size="tiny"
    >
      <Modal.Content>
        <h2>Formations</h2>
        <Form className="types">
          <Form.Field>
            <Toggle
              id="formations-cf-toggle"
              label="Formations"
              onChange={() =>
                handleEnabledToggle(
                  !cf_FormationsEnabledRV,
                  cf_FormationsEnabled,
                  'formations',
                  {
                    enabled: !cf_FormationsEnabledRV,
                  }
                )
              }
              checked={cf_FormationsEnabledRV}
              data-testid="offense-formations"
            />
          </Form.Field>
          <div className="half">
            <h3>Offense</h3>
            <Form.Group>
              <div className="packages">
                {offenseCol1.map((item, index) => (
                  <Form.Field key={`offense-formations-${item}`}>
                    <Checkbox
                      id={`offense-formations-${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index],
                          index
                        )
                      }
                      checked={st_FormationsOffense[index]}
                      data-testid={`offense-formations-${index}`}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="packages">
                {offenseCol2.map((item, index) => (
                  <Form.Field key={`offense-formations${item}`}>
                    <Checkbox
                      id={`offense-formations${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index + 4],
                          index + 4
                        )
                      }
                      checked={st_FormationsOffense[index + 4]}
                      data-testid={`offense-formations-${index + 4}`}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="packages">
                {offenseCol3.map((item, index) => (
                  <Form.Field key={`offense-formations-${item}`}>
                    <Checkbox
                      id={`offense-formations-${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index + 8],
                          index + 8
                        )
                      }
                      checked={st_FormationsOffense[index + 8]}
                      data-testid={`offense-formations-${index + 8}`}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="packages">
                {offenseCol4.map((item, index) => (
                  <Form.Field key={`offense-formations-${item}`}>
                    <Checkbox
                      id={`offense-formations-${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index + 12],
                          index + 12
                        )
                      }
                      checked={st_FormationsOffense[index + 12]}
                      data-testid={`offense-formations-${index + 12}`}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="packages">
                {offenseCol5.map((item, index) => (
                  <Form.Field key={`offense-formations-${item}`}>
                    <Checkbox
                      id={`offense-formations-${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index + 15],
                          index + 15
                        )
                      }
                      checked={st_FormationsOffense[index + 15]}
                      data-testid={`offense-formations-${index + 15}`}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="packages">
                {offenseCol6.map((item, index) => (
                  <Form.Field key={`offense-formations-${item}`}>
                    <Checkbox
                      id={`offense-formations-${item}-checkbox`}
                      label={item.substring(2)}
                      onChange={() =>
                        handleFormationsOffense(
                          !st_FormationsOffense[index + 17],
                          index + 17
                        )
                      }
                      checked={st_FormationsOffense[index + 17]}
                      data-testid={`offense-formations-${index + 17}`}
                    />
                  </Form.Field>
                ))}
              </div>
            </Form.Group>
            <SelectAllButtonContainer>
              <Button size="small" onClick={() => handleAll(true)}>
                Select All
              </Button>
              <Button size="small" onClick={() => handleAll(false)}>
                Deselect All
              </Button>
            </SelectAllButtonContainer>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleReset}>
          Reset
        </Button>
        <Button onClick={handleModalClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

FormationFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default FormationFilter;
