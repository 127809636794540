/*
 * This is to get the line endings that a file / string is using.
 * Since our PR build uses linux, the RELEASE_NOTES.md file uses LF endings
 * whereas windows local build uses CRLF. To be able get the release notes helper code
 * working both locally for a windows user and working on the PR build we need to do
 * two separate ways of handling the markdown, one for handling using CRLF and one using LF.
 */
export const getLineEndings = (string) => {
  if (!string) return 'NONE';

  const cr = string.split('\r').length;
  const lf = string.split('\n').length;
  const crlf = string.split('\r\n').length;

  if (cr + lf === 0) {
    return 'NONE';
  }

  if (crlf === cr && crlf === lf) {
    return 'CRLF';
  }

  if (cr > lf) {
    return 'CR';
  }

  return 'LF';
};

// Uses a different way of filtering based on the LineEnding
// in the file as windows and unix need to be handled differently
export const getMarkdownLines = ({ lineEnding, markdown }) => {
  const filterByLineLength = (singleLineOfMarkdownText) =>
    singleLineOfMarkdownText.length > 0;

  const markdownFilterMap = {
    LF: markdown.split('\n').filter(filterByLineLength),
    CRLF: markdown
      .split('\r')
      .map((line) => line.replace('\n', ''))
      .filter(filterByLineLength),
  };

  // Default to unix rendering
  return markdownFilterMap[lineEnding]
    ? markdownFilterMap[lineEnding]
    : markdownFilterMap.LF;
};

export const getFormattedMarkdown = (markdown) => {
  const lineEnding = getLineEndings(markdown);
  const markdownLines = getMarkdownLines({ lineEnding, markdown });

  let sectionId = 0;
  let listId = 0;

  return markdownLines.map((textString) => {
    const textSubstring = textString.substring(0, 3);
    // handle headings
    if (textSubstring === '## ') {
      // get the date and format it to the user's locale
      const date = textString.substring(3);

      // month -1 because months are 0 indexed
      const localDate = new Date(date).toLocaleDateString('en-US');

      sectionId += 1;
      return {
        component: 'h2',
        text: localDate,
        id: `h2-${sectionId}`,
      };
    }

    // Handle sub headings (added, changed etc.)
    if (textSubstring === '###') {
      listId += 1;
      return {
        component: 'h3',
        text: textString.substring(4),
        parent: `h2-${sectionId}`,
        id: `h3-${listId}`,
      };
    }

    // Assume everything else is a list item
    return {
      component: 'li',
      text: textString.trim().replace('- ', ''),
      parent: `h3-${listId}`,
    };
  });
};
