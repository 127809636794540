import { convertIntToNth } from '../../../../utils/helpers/formatting';
import { API_PLAYER_GROUP_BY } from '../../../../utils/constants/api';
import { PLAYER_GROUP_BY_HEADERS_CONFIG } from './PlayerStats.constants';

const { TEAM, PLAYER } = API_PLAYER_GROUP_BY;
/**
 * checks for and formats groupBy columns information in the player stats data
 */
export const addPlayerGroupColumns = (playerStats) =>
  playerStats?.items.map((player) => {
    const playerGroups = player.groups
      // ignore player and team groupings which are applied by default
      ?.filter((group) => group.key !== PLAYER && group.key !== TEAM)
      // take the remaining groupings and format them to "stat" columns
      .reduce(
        (acc, group) => ({
          ...acc,
          // add extra grouping by key and value if exists
          [PLAYER_GROUP_BY_HEADERS_CONFIG[group?.key].id]:
            // use a formatted value if provided by the API
            player?.[PLAYER_GROUP_BY_HEADERS_CONFIG[group?.key].id] ||
            // otherwise use the group object value as fallback (downs and quarters)
            convertIntToNth(group?.value),
        }),
        {}
      );

    return {
      ...player,
      ...playerGroups,
    };
  });
