import { styled } from 'styled-components';

export const StyledAccordionTable = styled.div`
  overflow: auto;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.layering[0].main};
  }

  table {
    thead {
      th:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        button {
          min-width: 5.3125rem;
        }
      }
    }

    tbody tr:nth-of-type(odd) td {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    }

    tbody tr:first-of-type td:nth-of-type(2) {
      box-shadow: none;
    }

    tbody tr:nth-of-type(2) td:first-of-type {
      ${({ theme, $withAllRows }) =>
        $withAllRows && theme.typography.headingSmall}
    }

    tbody tr:not(:nth-of-type(2)) {
      position: relative;
    }

    tbody tr:first-of-type td {
      padding: 0;
    }
  }
`;

StyledAccordionTable.displayName = 'StyledAccordionTable';
