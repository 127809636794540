import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { ButtonIcon, Dropdown, Icon } from '@statsbomb/kitbag-components';
import { mf_Leagues } from '../../../apollo';
import GoTo from '../../../components/buttons/GoTo';
import Tile from '../../../components/Tile/Tile';
import { PassPressuresVisHolder } from '../lineBattles/PassPressures/PassPressures.styles';
import { OLGapPosition } from '../../../visualisations/OLPressure/OLGapPosition/OLGapPosition';
import { OLPressure } from '../../../visualisations/OLPressure/OLPressure';
import { GET_TEAM_PASS_PRESSURES_ALLOWED } from '../lineBattles/PassPressures/getPassPressuresAllowed';
import { API_ALIGNMENT_POSITION_KEYS } from '../../../utils/constants/api';
import {
  getGapLeagueAverages,
  getGapsTableData,
  getPosLeagueAverages,
  getPositionsTableData,
  olGapPositionPlayer,
  summarizeGapTotals,
  summarizeOLTotals,
} from '../lineBattles/PassPressures/PassPressures.dataManipulation';
import { getPlayerList } from '../lineBattles/lineBattles.dataManipulation';
import {
  BAR_MODE_LA_OFFSET,
  COLOR_DIVERGENT_SCALING,
  PG_BOTH,
  PG_GAP,
  PG_POS,
} from '../lineBattles/PassPressures/PassPressures.constants';
import {
  gapsDatumToGaps,
  positionDatumToPositions,
} from '../../../visualisations/OLPressure/OLPressure.dataManipulation';
import { SummaryError } from '../../../components/Tile/TileSummary.styles';
import useQueryString from '../../../utils/hooks/useQueryString';
import { OLPressureKey } from '../../../visualisations/OLPressure/OLPressureKey/OLPressureKey';
import TableTile from '../../../components/TableTile/TableTile';
import { TileInlineLoader } from './TeamOverview.styles';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { LINE_PRESSURE_TILE_OPTIONS } from './TeamLinePressure.Tile.constants';

const TeamLinePressure = function () {
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'pressuresKey',
    true
  );
  const [showTables, setShowTables] = useQueryString('pressuresTables', false);
  // specify NFL as default to make sure League Average query can't fall over
  const selectedCompetitionId = useReactiveVar(mf_Leagues) || 1409;
  const showGapPressures = PG_BOTH.value;
  const [compareTeamValues] = useState(true);
  const colorMode = COLOR_DIVERGENT_SCALING.value;
  const barMode = BAR_MODE_LA_OFFSET.value;

  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_TEAM_PASS_PRESSURES_ALLOWED, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const teamData = data && data?.offensiveLinePressure;
  const leftTacklesList = teamData && getPlayerList(teamData, 'leftTackle');
  const leftGuardsList = teamData && getPlayerList(teamData, 'leftGuard');
  const centersList = teamData && getPlayerList(teamData, 'center');
  const rightGuardsList = teamData && getPlayerList(teamData, 'rightGuard');
  const rightTacklesList = teamData && getPlayerList(teamData, 'rightTackle');

  const selectedPlayers = [
    olGapPositionPlayer(
      0,
      leftTacklesList,
      API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE
    ),
    olGapPositionPlayer(
      0,
      leftGuardsList,
      API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD
    ),
    olGapPositionPlayer(0, centersList, API_ALIGNMENT_POSITION_KEYS.CENTER),
    olGapPositionPlayer(
      0,
      rightGuardsList,
      API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD
    ),
    olGapPositionPlayer(
      0,
      rightTacklesList,
      API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE
    ),
  ].filter(Boolean);

  const totalsLA = getPosLeagueAverages(selectedCompetitionId);
  const totalsTeam = summarizeOLTotals(teamData, selectedCompetitionId);
  const totalsOLFiltered = summarizeOLTotals(teamData, selectedCompetitionId);

  const niceTableData = getPositionsTableData(
    totalsOLFiltered,
    totalsTeam,
    totalsLA
  );

  const PRESSURES_DATA_HEADERS = [
    { label: 'Metric', key: 'name' },
    { label: 'Left Tackle', key: 'lt' },
    { label: 'Left Guard', key: 'lg' },
    { label: 'Center', key: 'c' },
    { label: 'Right Guard', key: 'rg' },
    { label: 'Right Tackle', key: 'rt' },
  ];

  /* gaps data */
  const gapTotalsLA = getGapLeagueAverages(selectedCompetitionId);
  const gapTotalsTeam = summarizeGapTotals(teamData, selectedCompetitionId);
  const gapTotalsOLFiltered = summarizeGapTotals(
    teamData,
    selectedCompetitionId
  );
  const niceGapTableData = getGapsTableData(
    gapTotalsOLFiltered,
    gapTotalsTeam,
    gapTotalsLA
  );
  const LINE_GAP_DATA_HEADERS = [
    { label: 'Metric', key: 'name' },
    { label: 'C Left', key: 'lC' },
    { label: 'B Left', key: 'lB' },
    { label: 'A Left', key: 'lA' },
    { label: 'A Right', key: 'rA' },
    { label: 'B Right', key: 'rB' },
    { label: 'C Right', key: 'rC' },
  ];

  /* Prepare Gaps and Positions data for the vis */
  const visPositionsData = positionDatumToPositions(
    totalsOLFiltered,
    totalsTeam
  );
  const visGapsData = gapsDatumToGaps(
    gapTotalsOLFiltered,
    gapTotalsTeam,
    selectedCompetitionId
  );
  /* Can choose to view either gaps or positions or both in the vis 
    Pick relevant or combine based onn selection mode */
  const baseData = showGapPressures === PG_GAP.value ? [] : visPositionsData;
  const visData =
    showGapPressures === PG_POS.value ? baseData : baseData.concat(visGapsData);

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header style={{ maxHeight: '5.64rem' }} $border="2px">
        <h3>Line Pressure</h3>
        <div className="buttons">
          <DropdownWrapper>
            <Dropdown
              id="team-tackling-tile-dropdown"
              components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon,
              }}
              isSearchable={false}
              menuAlignment="right"
              menuPlacement="bottom"
              onChange={(selectedOption) => setShowTables(selectedOption.value)}
              options={LINE_PRESSURE_TILE_OPTIONS}
              value={LINE_PRESSURE_TILE_OPTIONS.find(
                (item) => item.value === showTables
              )}
              labelPosition="none"
              label="Passing chart display options"
              variant="tertiary"
              size="small"
            />
          </DropdownWrapper>
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            href="/team/linePressure/:leagues/:seasons/:teams/"
            linkName="Open Team Line Pressures"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="1.25rem">
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div>
          <div style={{ display: showTables ? 'none' : 'block' }}>
            <PassPressuresVisHolder>
              <OLGapPosition selectedPlayers={selectedPlayers} />
            </PassPressuresVisHolder>
            <PassPressuresVisHolder>
              <OLPressure
                pressureData={visData}
                barMode={barMode}
                compareTeamValues={compareTeamValues}
                colorMode={colorMode}
                showRawDots
              />
            </PassPressuresVisHolder>
          </div>
          <div style={{ display: showTables ? 'block' : 'none' }}>
            <PassPressuresVisHolder>
              <TableTile
                data={niceTableData}
                tileTitle="Offensive Lineman Position Pressures"
                columnHeaders={PRESSURES_DATA_HEADERS}
                showColumnHeader
                error={hasError}
                loading={isLoading}
                fileName="OL Position Pressures.csv"
              />
            </PassPressuresVisHolder>
            <PassPressuresVisHolder>
              <TableTile
                data={niceGapTableData}
                tileTitle="Offensive Line Gap Pressures"
                columnHeaders={LINE_GAP_DATA_HEADERS}
                showColumnHeader
                error={hasError}
                loading={isLoading}
                fileName="OL Gap Pressures.csv"
              />
            </PassPressuresVisHolder>
          </div>
          {displayChartKey && !showTables && (
            <OLPressureKey
              showRawDots
              compareTeamValues={compareTeamValues}
              colorMode={colorMode}
              horizontalKey
            />
          )}
        </div>
        {isLoading && (
          <TileInlineLoader>
            <Loader
              active
              inline
              size="small"
              content="Loading Line Pressure Data"
            />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

export default TeamLinePressure;
