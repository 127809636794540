import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

const context = { personnel: false, formation: false };

export const GET_TEAM_FORMATION_STATS = gql`
    query GetTeamFormationStats(
      $competitionId: Int!
      $seasonId: Int!
      $teamId: Int
      $formation: FormationGroup!
      $backfieldAlignment: [BackfieldAlignment!]
      $wrAlignment: WrAlignment
      $motion: [Motion!]
      $hash: [PlayStartPosition!]
      $runStrength: [RunStrength!]
      $fib: Boolean
      ${getContextQueryVariables(context)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      ${getContextFilterClientExports(context)}

      formationDetail(
        teamId: $teamId
        formation: $formation
        filters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          backfieldAlignment: $backfieldAlignment
          wrAlignment: $wrAlignment
          motion: $motion
          hash: $hash
          runStrength: $runStrength
          fib: $fib
          ${getContextFiltersQueryArguments(context)}
        }
      ) {
        groupingSet
        offensePersonnel
        runStrength
        backfieldAlignment
        wrAlignment
        motion
        hash
        fib
        teamStats {
          playCount
          runRate
          netYardsPerPlay
          playSuccessRate
          epaPerPlay
          yardsPerRush
          rushSuccessRate
          epaPerRush
          netYardsPerPass
          passSuccessRate
          epaPerPass
        }
      }
    }
  `;
