import {
  DEFAULT_FONT,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_TEXT_PROPORTION,
} from '../utils/constants/visText';

export const appendText = function (svgElement, visPalette, overrides) {
  const x = overrides.x || 0;
  const y = overrides.y || 0;
  const message = overrides.message || 'null text';
  const textAnchor = overrides.textAnchor || 'start';
  const transform = overrides.transform || null;
  const fontSize = overrides.fontSize || DEFAULT_FONT_SIZE;
  const fill = overrides.fill || visPalette.text.info;
  const fontWeight = overrides.fontWeight || 'normal';
  svgElement
    .append('text')
    .attr('x', x)
    .attr('y', y)
    .attr('font-size', `${fontSize}px`)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-weight', fontWeight)
    .attr('text-anchor', textAnchor)
    .attr('fill', fill)
    .attr('transform', transform)
    .text(message);
};

/* 
  For text in the default font (inter), for a given font size, where should the svg baseline (y)
  be in order for the text to be vertically centered
*/
export const fontVerticalCenterY = (fontSize) =>
  (fontSize * DEFAULT_FONT_TEXT_PROPORTION) / 2;

/* 
  For text in the default font (inter), for a given font size and line spacing
    , where should the svg baseline (y) be (to match html line spacing)
*/
export const fontBaselineY = (fontSize, lineSpacing) => {
  const linePadding = (lineSpacing - fontSize) / 2;
  const fontBase = fontSize * DEFAULT_FONT_TEXT_PROPORTION;
  return linePadding + fontBase;
};
