import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../../../utils/errorCodes/errorCodes';
import useDebounce from '../../../../../utils/hooks/useDebounce';
import { SEARCH_CITIES } from './searchCities';
import { toTitleCase } from '../../../../../utils/helpers/general';

export const useSearchCities = (searchString) => {
  const debouncedSearchString = useDebounce(searchString, 400);

  const { data, loading, error } = useQuery(SEARCH_CITIES, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    variables: {
      searchString: debouncedSearchString,
    },
  });

  if (loading) {
    return { isLoading: true };
  }
  if (error) {
    console.error(ERROR_CODES.USE_SEARCH_CITIES, error);
    return {
      isLoading: false,
      error,
    };
  }

  const citiesRaw = data.searchCities;
  const cities = citiesRaw?.map((c) => ({ ...c, name: toTitleCase(c.name) }));
  return {
    isLoading: false,
    cities,
  };
};
