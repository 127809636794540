import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AuthProvider } from '@statsbomb/kitbag-auth';

// https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
const Auth0ProviderWithHistory = ({ domain, clientId, audience, children }) => {
  if (!domain || !clientId || !audience)
    throw new Error('Please check domain, clientId, and audience vars');

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <AuthProvider
      providerOptions={{
        domain,
        clientId,

        authorizationParams: {
          redirect_uri: window.location.origin,
          audience,
        },
        onRedirectCallback,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
      }}
    >
      {children}
    </AuthProvider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  domain: PropTypes.string,
  clientId: PropTypes.string,
  audience: PropTypes.string,
  children: PropTypes.element,
};

Auth0ProviderWithHistory.defaultProps = {
  domain: '',
  clientId: '',
  audience: '',
  children: null,
};

export default Auth0ProviderWithHistory;
