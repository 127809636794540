import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { RangeSliderWrapper } from '../BiometricFilters.styles';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import ReversibleSlider from '../../ReversibleSlider/ReversibleSlider';
import { formatValue } from '../../../../League/stats/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../../../utils/constants/api';
import { biometricProp } from '../../../Scout.proptypes';

/* 
These are all value sliders where the smallest value is taken to mean "do not filter"
  Values above this are actually used
*/
const BiometricSingleSlider = ({
  selectedBiometrics,
  setSelectedBiometrics,
  sliderSetup,
  biometricKey,
  unselectedText,
  sliderName,
}) => {
  const bioFilter = selectedBiometrics.find((b) => b.key === biometricKey);
  const bioValue = bioFilter?.value || sliderSetup.MIN;

  const onSliderChange = (sliderValue) => {
    const otherBiometrics = selectedBiometrics.filter(
      (b) => b.key !== biometricKey
    );
    if (sliderValue > sliderSetup.MIN) {
      const newRecruitingFilter = {
        key: biometricKey,
        value: sliderValue,
      };
      const newBiometrics = otherBiometrics.concat(newRecruitingFilter);
      setSelectedBiometrics(newBiometrics);
    } else {
      setSelectedBiometrics(otherBiometrics);
    }
  };

  const recruitingFormatter = (value) => {
    if (value === sliderSetup.MIN) {
      return unselectedText;
    }

    return `At least ${formatValue(value, sliderSetup.UNITS)}`;
  };

  return (
    <RangeSliderWrapper>
      <h3>{sliderName}</h3>
      <ReversibleSlider
        min={sliderSetup.MIN}
        max={sliderSetup.MAX}
        value={bioValue}
        onValueChanged={onSliderChange}
        steps={sliderSetup.STEPS}
        valueFormatter={recruitingFormatter}
      />
    </RangeSliderWrapper>
  );
};

BiometricSingleSlider.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
  // definition of the slider values
  sliderSetup: PropTypes.shape({
    MIN: PropTypes.number,
    MAX: PropTypes.number,
    STEPS: PropTypes.number,
    UNITS: PropTypes.oneOf(Object.values(API_STAT_UNITS)),
  }).isRequired,
  // the name of the stat. In practise only certain filter keys would be valid as have to be numeric
  biometricKey: oneOf(Object.keys(SCOUT_BIOMETRIC_FILTERS)).isRequired,
  // nice name for the metric
  unselectedText: PropTypes.string.isRequired,
  // what to call the slider
  sliderName: PropTypes.string.isRequired,
};

export default BiometricSingleSlider;
