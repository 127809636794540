import { gql } from '@apollo/client';

export const GET_PLAYER_RADAR_TEMPLATES = gql`
  query GetPlayerRadarTemplates($competitionId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")

    playerRadarTemplates(competitionId: $competitionId) {
      name
      defaultFor
      specificPositions
      stats {
        name
        axisMin
        axisMax
      }
      secondaryStats {
        name
        axisMin
        axisMax
      }
    }
  }
`;
