import { API_STAT_UNITS } from '../../../utils/constants/api';

const {
  STRING,
  COUNT,
  BOOLEAN,
  YARDS,
  EPA,
  DATE,
  PERCENTAGE,
  MPHS,
  SHORT_DECIMAL,
} = API_STAT_UNITS;

export const PLAY_FINDER_PAGESIZE_INITIAL = 25;

export const PLAY_FINDER_PAGESIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const PLAY_FINDER_STAT_BLOCKS = [
  {
    key: 'Snapspergame',
    units: SHORT_DECIMAL,
    hasColour: false,
  },
  {
    key: 'Chunk%',
    units: MPHS,
  },
  {
    key: 'EPAperplay',
    units: EPA,
    hasColour: false,
  },
  {
    key: 'Successrate',
    units: PERCENTAGE,
  },
  {
    key: 'Yardsperplay',
    units: YARDS,
  },
  {
    key: 'RunRate',
    units: EPA,
  },
];

export const PLAY_FINDER_COLUMNS = [
  {
    key: 'date',
    label: 'Date',
    units: DATE,
    description: 'Date the game was played',
    width: '70px',
    isSortable: false,
  },
  {
    key: 'offensiveTeam',
    label: 'Offensive Team',
    units: STRING,
    description: 'The offensive team',
    isSortable: false,
  },
  {
    key: 'defensiveTeam',
    label: 'Defensive Team',
    units: STRING,
    description: 'The defensive team',
    isSortable: false,
  },
  {
    key: 'down',
    label: 'Down',
    units: COUNT,
    description: 'Down of the game',
    width: '60px',
  },
  {
    key: 'yardsToGo',
    label: 'Distance',
    units: YARDS,
    description: 'Distance of the play',
    width: '50px',
  },
  {
    key: 'yardLine',
    label: 'Field Position',
    units: YARDS,
    description: 'Field position',
    width: '70px',
    isSortable: false,
  },
  {
    key: 'time',
    label: 'Time',
    units: STRING,
    description: 'Time (Quarter and time)',
    width: '80px',
    isSortable: false,
  },
  {
    key: 'type',
    label: 'Play type',
    units: STRING,
    description: 'Play type',
  },
  {
    key: 'yardsNet',
    label: 'Yards Gained',
    units: STRING,
    description: 'Yards gained on the play',
    width: '70px',
  },
  {
    key: 'scrimmageEpa',
    label: 'EPA',
    units: EPA,
    description: 'EPA',
    width: '50px',
  },
  {
    key: 'success',
    label: 'Success',
    units: BOOLEAN,
    description: 'Play success',
    width: '50px',
  },
  {
    key: 'penalty',
    label: 'Penalty',
    units: STRING,
    description: 'Penalty on this play',
  },
  {
    key: 'formationGroup',
    label: 'Formation Group',
    units: STRING,
    description: 'Formation group',
  },
];
