import { gql } from '@apollo/client';

export const GET_PLAYERS = gql`
  query GetPlayers($teamId: Int!, $seasonId: Int!) {
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    players(first: 1000, filters: { teamIds: [$teamId], seasonId: $seasonId }) {
      edges {
        node {
          id
          name
          gamesRostered
          mostCommonNumber
          mostCommonPosition {
            id
            code
            name
            generalPosition
          }
        }
      }
    }
  }
`;
