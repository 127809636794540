import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import {
  olPressureChartElementId,
  OLPRESSURE_CLASS_NAMES,
  OLPRESSURE_HEIGHTS,
  OLPRESSURE_WIDTHS,
} from './OLPressure.constants';
import { drawOL, drawSectionTitle } from './OLPressure.drawing';
import {
  BAR_MODE_LA_OFFSET,
  COLOR_DIVERGENT_SCALING,
} from '../../pages/team/lineBattles/PassPressures/PassPressures.constants';
import { addExportFontReference } from '../../utils/helpers/export';
import { marginPropType } from '../../utils/constants/propTypes';
import { DEFAULT_VISUALISATION_MARGIN } from '../../utils/constants/charting';
import { ClickableSVG } from '../visualisation.styles';

const OLPressure = function ({
  pressureData,
  barMode,
  compareTeamValues,
  colorMode,
  showRawDots,
  olpId,
  margin,
  handleBarClick,
  selectedBar,
}) {
  // values that default or get fixed in export mode
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;

  // SVG BASIC VALUES
  const visualisationWidth =
    OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_AXIS_WIDTH;
  const sparklineHeight = showRawDots
    ? OLPRESSURE_HEIGHTS.SPARK_CHART +
      OLPRESSURE_HEIGHTS.SPARK_CHART_MARGIN_BELOW
    : 0;
  const visualisationHeight =
    OLPRESSURE_HEIGHTS.MAIN_SECTION_TITLE +
    OLPRESSURE_HEIGHTS.MAIN_VIS +
    OLPRESSURE_HEIGHTS.MAIN_VIS_MARGIN_BELOW +
    sparklineHeight;
  const viewBox = `0 0 ${(
    visualisationWidth +
    margin.left +
    margin.right
  ).toString()} ${(
    visualisationHeight +
    margin.top +
    margin.bottom
  ).toString()}`;

  // // this level declares anything static
  const ref = useD3((svg) => {
    svg.attr('id', olPressureChartElementId(olpId)).attr('width', '100%');

    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();
    svg.selectAll('defs').remove();
    const svgDefs = svg.append('defs');
    addExportFontReference(svgDefs);

    // backing rect
    svg
      .append('rect')
      .attr('class', OLPRESSURE_CLASS_NAMES.BACKGROUND_RECT)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', visPalette.background.main);

    const marginTransform = `translate(${margin.left},${margin.top})`;

    // permanent base
    const withinMarginsG = svg
      .append('g')
      .attr('class', OLPRESSURE_CLASS_NAMES.WITHIN_MARGINS)
      .attr('transform', marginTransform);

    // area for the title of the first section
    withinMarginsG.append('g').attr('class', OLPRESSURE_CLASS_NAMES.HEADERS);
    // main chart area
    withinMarginsG
      .append('g')
      .attr('class', OLPRESSURE_CLASS_NAMES.CONTENT)
      .attr(
        'transform',
        `translate(0,${OLPRESSURE_HEIGHTS.MAIN_SECTION_TITLE})`
      );
  }, []);

  // draw the chart
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    /* Update backing rect */
    const backgroundRect = svg.select(
      `.${OLPRESSURE_CLASS_NAMES.BACKGROUND_RECT}`
    );
    backgroundRect.attr('fill', visPalette.background.main).on('click', () => {
      handleBarClick(null);
    });

    /* Write section titles */
    const mainTitleArea = svg.select(`.${OLPRESSURE_CLASS_NAMES.HEADERS}`);
    drawSectionTitle(mainTitleArea, visPalette);

    const displayG = svg.select(`.${OLPRESSURE_CLASS_NAMES.CONTENT}`);
    drawOL(
      displayG,
      visPalette,
      pressureData,
      barMode,
      compareTeamValues,
      colorMode,
      showRawDots,
      isDark,
      handleBarClick,
      selectedBar
    );
  }, [
    pressureData,
    barMode,
    compareTeamValues,
    colorMode,
    showRawDots,
    visPalette,
    selectedBar,
  ]);

  return <ClickableSVG ref={ref} data-testid={olpId} />;
};

OLPressure.propTypes = {
  /* data with values for each position/gap */
  pressureData: PropTypes.arrayOf(PropTypes.shape({})),
  /* drawing mode for the bars */
  barMode: PropTypes.string,
  /* whether or not to show team values when filtering applied */
  compareTeamValues: PropTypes.bool,
  /* color mode of the bars */
  colorMode: PropTypes.string,
  /* Show the sparkline (true value) dots or not */
  showRawDots: PropTypes.bool,
  /* Object declaring margins if they need adding */
  olpId: PropTypes.string,
  /* Object declaring margins if they need adding */
  margin: marginPropType,
  /* Function to handle clicks on the bars */
  handleBarClick: PropTypes.func,
  /* Selected bar id key */
  selectedBar: PropTypes.string,
};

OLPressure.defaultProps = {
  pressureData: null,
  barMode: BAR_MODE_LA_OFFSET.value,
  compareTeamValues: true,
  colorMode: COLOR_DIVERGENT_SCALING.value,
  showRawDots: PropTypes.bool,
  olpId: 'olp-123',
  margin: DEFAULT_VISUALISATION_MARGIN,
  handleBarClick: () => {},
  selectedBar: null,
};

export { OLPressure };
