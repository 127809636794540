import { useQuery } from '@apollo/client';
import { GET_TEAM_RADAR_TEMPLATES } from '../TeamOverview/getTeamRadarTemplates';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import { GET_TEAM_STAT_DEFINITIONS } from '../../../utils/hooks/apiQueries/teamStats/getTeamStatDefinitions';

const useGetTemplateDefinitionsTeam = () => {
  const {
    data: templates,
    loading: tLoading,
    error: tError,
  } = useQuery(GET_TEAM_RADAR_TEMPLATES, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  const {
    data: statDefinitions,
    loading: sdLoading,
    error: sdError,
  } = useQuery(GET_TEAM_STAT_DEFINITIONS, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const loading = tLoading || sdLoading;
  const error = tError || sdError;
  if (loading) {
    return { loading };
  }
  if (error) {
    console.error(ERROR_CODES.USE_GET_TEMPLATE_DEFINITIONS_TEAM, error);
    return { error, loading: false };
  }

  const getStatDef = (statName, statData) => {
    const statDef = statData?.find((d) => statName === d.name) || {};
    return statDef;
  };

  const niceTemplates = templates?.teamRadarTemplates?.map((t) => {
    const niceTemplateStats = t?.stats?.map((s) => {
      const statDef = getStatDef(s.name, statDefinitions?.teamStatDefinitions);
      return { ...s, ...statDef };
    });

    return {
      ...t,
      stats: niceTemplateStats,
    };
  });

  return { loading, error, templates: niceTemplates };
};

export { useGetTemplateDefinitionsTeam };
