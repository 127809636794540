import { styled } from 'styled-components';

export const FormationTable = styled.div`
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  > div {
    overflow: auto;
    max-height: calc(
      100vh - 26.3rem - ${({ $displayKey }) => ($displayKey ? '72px' : '0px')}
    );
  }

  table {
    background-color: ${({ theme }) => theme.colours.canvas.secondary.main};

    thead {
      th:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        button {
          min-width: 8.5rem;
        }
      }
    }

    tbody tr:nth-of-type(odd) td {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    }

    tbody tr {
      position: relative;

      &:hover {
        td {
          background-color: ${({ theme }) =>
            theme.colours.canvas.tertiary.main};
        }
      }
    }
  }
`;

FormationTable.displayName = 'FormationTable';
