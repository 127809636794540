/* eslint-disable max-len */
export const DATA_DICTIONARY_PLAYS = [
  {
    field: 'play_uuid',
    fieldType: 'uuid',
    fieldValues: 'a2b88e4b-22ae-4d56-b895-ff23ab774afd',
    fieldDescription: 'Unique identifier for the play',
  },
  {
    field: 'game_id',
    fieldType: 'integer',
    fieldValues: '1069669',
    fieldDescription: 'Unique identifier for the game',
  },
  {
    field: 'drive_uuid',
    fieldType: 'uuid',
    fieldValues: 'ec53f166-ee81-4d57-8c79-5f4652dacb92',
    fieldDescription:
      'Unique identifier for the drive in which the play occurs',
  },
  {
    field: 'play_game_index',
    fieldType: 'integer',
    fieldValues: '7, 141',
    fieldDescription:
      'Ranked order of plays over the series of a game (a value of 7 means that a event was the 7th play in that game)',
  },
  {
    field: 'play_drive_index',
    fieldType: 'integer',
    fieldValues: '1, 8',
    fieldDescription: 'Ranked order of plays over the series of a drive',
  },
  {
    field: 'play_quarter',
    fieldType: 'integer',
    fieldValues: '1, 2',
    fieldDescription: 'Quarter the event takes place (OT is 5)',
  },
  {
    field: 'play_quarter_clock_remaining',
    fieldType: 'integer',
    fieldValues: '900000 (start of quarter), 752965',
    fieldDescription:
      'Remaining clock time left in the quarter in milliseconds (a value of 752965 equates to 12:33 left in quarter)',
  },
  {
    field: 'play_clock_paused',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the game clock was paused prior to the play starting',
  },
  {
    field: 'play_start_event_index',
    fieldType: 'integer',
    fieldValues: '18',
    fieldDescription:
      'The event_game_index value of the first event in this play',
  },
  {
    field: 'play_end_event_index',
    fieldType: 'integer',
    fieldValues: '25',
    fieldDescription:
      'The event_game_index value of the last event in this play Note: values of 18 for play_start_event_index and 35 for play_end_event_index would mean that the 18th through 35th events of this game occurred on this play',
  },
  {
    field: 'play_offense_team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription:
      'Unique team identifier for the team on offense at the start of the play',
  },
  {
    field: 'play_defense_team_id',
    fieldType: 'integer',
    fieldValues: '1001399',
    fieldDescription:
      'Unique team identifier for the team on defense at the start of the play',
  },
  {
    field: 'play_offense_is_home_team',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the home team is on offense at the start of the play',
  },
  {
    field: 'play_down',
    fieldType: 'integer',
    fieldValues: '1, 2, 3, 4',
    fieldDescription:
      'Down of the play. Blank for kickoff and conversion attempts',
  },
  {
    field: 'play_yards_to_go',
    fieldType: 'decimal',
    fieldValues: '10, 4.98',
    fieldDescription:
      'Yards to go at the beginning of the play. Blank for kickoff and conversion attempts',
  },
  {
    field: 'play_yardline',
    fieldType: 'decimal',
    fieldValues: '42.11, 83.21',
    fieldDescription:
      "Line of scrimmage (measured by how many yards the offensive team is from their own end zone). A value of 42 means a team is at their own 42 yard line, a value of 83 means a team is at the opponent's 17 yard line",
  },
  {
    field: 'play_start_position',
    fieldType: 'text',
    fieldValues: 'left, center, right',
    fieldDescription:
      'Location of ball with respect to the hashes. Possible values are left, center, right',
  },
  {
    field: 'play_home_score',
    fieldType: 'integer',
    fieldValues: '17',
    fieldDescription: 'Score of the home team at the start of the play',
  },
  {
    field: 'play_away_score',
    fieldType: 'integer',
    fieldValues: '14',
    fieldDescription: 'Score of the away team at the start of the play',
  },
  {
    field: 'play_home_timeouts_remaining',
    fieldType: 'integer',
    fieldValues: '2',
    fieldDescription:
      'Timeouts remaining for the home team at the start of the play',
  },
  {
    field: 'play_away_timeouts_remaining',
    fieldType: 'integer',
    fieldValues: '3',
    fieldDescription:
      'Timeouts remaining for the away team at the start of the play',
  },
  {
    field: 'play_box_players',
    fieldType: 'integer',
    fieldValues: '4, 7',
    fieldDescription:
      'Number of defensive players with X yards of the LOS between the tackles at the start of the play',
  },
  {
    field: 'play_defensive_back_depths',
    fieldType: 'array',
    fieldValues: '',
    fieldDescription:
      'Array specifying how many yds behind the line of scrimmage (in ascending distance) the players classed as cornerbacks and safeties are',
  },
  {
    field: 'play_linebacker_depths',
    fieldType: 'array',
    fieldValues: '',
    fieldDescription:
      'Array specifying how many yds behind the line of scrimmage (in ascending distance) the players classed as linebackers are.',
  },
  {
    field: 'play_shotgun',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the snap was a shotgun. Null on plays without a snap',
  },
  {
    field: 'play_pre_snap_motion',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not a player was in motion (as defined by having moved >=3 yards laterally) prior to the snap',
  },
  {
    field: 'play_snap_hurried',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not there was a huddle prior to the snap',
  },
  {
    field: 'play_success',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'A play is deemed successful if 40% of the yards to go is gained on 1st down, 50% gained on 2nd down, and 100% gained on 3rd or 4th down',
  },
  {
    field: 'play_first_down_won',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not a first down was made on the play',
  },
  {
    field: 'play_touchdown_won',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not a touchdown was made on the play',
  },
  {
    field: 'play_yards_net',
    fieldType: 'decimal',
    fieldValues: '41.7, -5.68',
    fieldDescription:
      'Total yards gained or lost on the play. For punts, this is the distance from the LOS to the end of the return',
  },
  {
    field: 'play_points_won',
    fieldType: 'integer',
    fieldValues: '0, 1, 2, 3, 6',
    fieldDescription: 'Total points generated by the offense on the play',
  },
  {
    field: 'play_type',
    fieldType: 'text',
    fieldValues: 'Pass, Punt, Rush',
    fieldDescription:
      'Description of type of play Possible values: Extra Point, Field Goal, Kickoff, No Play: Penalty, No Play: Stoppage (Injury), No Play: Stoppage (Other) No Play: Timeout, Off Camera, Pass, Punt, Quarterback Kneel, Quarterback Spike, Rush, Safety Kick, Two Point Conversion',
  },
  {
    field: 'play_included_fake',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the play contained at least one attemped fake',
  },
  {
    field: 'play_qb_hurried',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the QB was hurried on the play (as defined by a pressure occurring before the QB takes an action)',
  },
  {
    field: 'play_qb_hit',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the QB was hit on the play',
  },
  {
    field: 'play_qb_sacked',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the QB was sacked on the play',
  },
  {
    field: 'play_pass_yards_air',
    fieldType: 'decimal',
    fieldValues: '37.02, -2.95',
    fieldDescription:
      'How far down the field the pass traveled (difference in the x axis between the LOS and the catch location)',
  },
  {
    field: 'play_pass_yards_after_catch',
    fieldType: 'decimal',
    fieldValues: '5.39, -4.35',
    fieldDescription:
      'How far the ball was moved down the field after the catch on a passing play. Value can be negative if the player loses yards after having made the catch',
  },
  {
    field: 'play_pass_outcome',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass was caught',
  },
  {
    field: 'play_pass_dropped',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass was dropped',
  },
  {
    field: 'play_pass_intercepted',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass was intercepted',
  },
  {
    field: 'play_pass_interception_dropped',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the interception opportunity was dropped',
  },
  {
    field: 'play_time_to_pass',
    fieldType: 'decimal',
    fieldValues: '2.668, 3.23',
    fieldDescription: 'Time in seconds from the snap to the throw',
  },
  {
    field: 'play_passer_moved_off_spot',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the QB was forced to leave the pocket',
  },
  {
    field: 'play_pass_batted',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the pass was tipped',
  },
  {
    field: 'play_yards_run',
    fieldType: 'decimal',
    fieldValues: '-3.99, 13.1',
    fieldDescription:
      'How many north/south yards were run by the ball carrier on the play (difference in the x axis between the handoff location and the end location)',
  },
  {
    field: 'play_penalty_yards',
    fieldType: 'decimal',
    fieldValues: '-10, 15, 52.4',
    fieldDescription:
      'How many yards the offense gained or lost as a result of the penalty. Positive value = penalty on defense, negative value = penalty on offense',
  },
  {
    field: 'play_penalty_first_down',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the penalty led to the offense gaining a first down',
  },
  {
    field: 'play_penalty_down_loss',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the penalty is a loss-of-down penalty',
  },
  {
    field: 'play_penalty_offset',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not there were offsetting penalties on the play',
  },
  {
    field: 'play_return_yards',
    fieldType: 'decimal',
    fieldValues: '2.83, 45.63',
    fieldDescription:
      'How many yards the returner gained after receiving the kick/punt',
  },
  {
    field: 'play_field_goal_result',
    fieldType: 'string',
    fieldValues: 'Blocked, Success',
    fieldDescription:
      'Description of outcome of field goal Possible values: Blocked, Goalpost, Short, Success, Wide Left, Wide Right',
  },
  {
    field: 'play_extra_point_result',
    fieldType: 'string',
    fieldValues: 'Blocked, Success',
    fieldDescription:
      'Description of outcome of extra point Possible values: Blocked, Goalpost, Short, Success, Wide Left, Wide Right',
  },
  {
    field: 'play_kick_yards',
    fieldType: 'decimal',
    fieldValues: '23.45, 67.87',
    fieldDescription:
      'Yards traveled by the kick For FG: distance along x-axis from the point of the kick to the goalposts For kickoff: distance along x-axis from the point of the kick to the catch/where it lands  For punt: distance along x-axis from the LOS to the catch/where it lands ',
  },
  {
    field: 'play_kick_hangtime',
    fieldType: 'decimal',
    fieldValues: '1.683',
    fieldDescription: 'Time in seconds from the kick to where it lands',
  },
  {
    field: 'play_kick_blocked',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the kick was blocked',
  },
  {
    field: 'play_kick_fair_catch',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the kick returner caught the kick via fair catch',
  },
  {
    field: 'play_kick_downed',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the kick was ended with the kicking team downing the ball',
  },
  {
    field: 'play_fumbled',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not there was a fumble on the play',
  },
  {
    field: 'play_fumble_forced',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not a fumble was forced on the play',
  },
  {
    field: 'play_fumble_lost',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not there was a fumble lost on the play',
  },
  {
    field: 'play_fumble_out_of_bounds',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not there was a fumble that went out of bounds on the play',
  },
  {
    field: 'play_challenged',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the play was challenged',
  },
  {
    field: 'play_ball_path',
    fieldType: 'array',
    fieldValues: '',
    fieldDescription: 'Intended to track the path of the ball on the play',
  },
  {
    field: 'play_action_pass',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the play included a fake-handoff event and has a Pass play type',
  },
  {
    field: 'play_points_lost',
    fieldType: 'integer',
    fieldValues: '0, 2, 6',
    fieldDescription:
      'Total points generated by the defense on the play. Values are always positive',
  },
  {
    field: 'play_start_position_yards',
    fieldType: 'decimal',
    fieldValues: '-0.27, 0.44',
    fieldDescription:
      'Distance from the center of the field (left is negative, right is positive). Intended to be a fuller measure of play_start_position',
  },
  {
    field: 'play_penalty_types',
    fieldType: 'array',
    fieldValues:
      '{illegal_forward_pass}, {illegal_formation,illegal_block_in_the_back}',
    fieldDescription:
      'Description of the penalty(ies) on the play. Multiple penalties separated by a comma',
  },
  {
    field: 'play_explosive',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the play gained 20+ yards on a pass or 15+ yards on a run. If on a 3rd/4th down, it additionally has to generate a first down or touchdown to be defined as explosive',
  },
  {
    field: 'play_offensive_personnel',
    fieldType: 'array',
    fieldValues: '{"OL": 5, "QB": 1, "RB": 1, "TE": 1, "WR": 3}',
    fieldDescription:
      'Number of players by position group for the offense on the play Note: In limited circumstances, when 11 players are not identified prior to the snap, we automatically pad WRs to get to 11 so you may see some occurrences of something like {"OL": 2, "QB": 1, "RB": 0, "TE": 1, "WR": 7}',
  },
  {
    field: 'play_defensive_personnel',
    fieldType: 'array',
    fieldValues: '{"DB": 5, "DL": 3, "LB": 3}',
    fieldDescription:
      'Number of players by position group for the defense on the play Note: In limited circumstances, when 11 players are not identified prior to the snap, we automatically pad DBs to get to 11 so you may see some occurrences of something like {DL: 2, LB: 1, DB: 8} ',
  },
  {
    field: 'play_turnover_type',
    fieldType: 'text',
    fieldValues: 'Turnover from Punt, Turnover on Downs',
    fieldDescription:
      'Description of how the play led to a change in possession Possible Values: Turnover from Fumble, Turnover from Interception, Turnover from Punt, Turnover on Downs',
  },
  {
    field: 'play_offense_penalty_accepted',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the play had an offensive penalty accepted',
  },
  {
    field: 'play_defense_penalty_accepted',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the play had an defensive penalty accepted',
  },
  {
    field: 'play_offense_flagged',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the offense was flagged on the play',
  },
  {
    field: 'play_defense_flagged',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not the defense was flagged on the play',
  },
  {
    field: 'play_tackle_success',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      "Whether or not a tackle attempt was successful.  TRUE means a tackle attempt was successful FALSE means tackle attempts were made but they were not successful (eg, the offensive player went out of bounds or broke the tackle) NULL values mean there wasn't a tackle attempt on the play",
  },
  {
    field: 'play_lateral_success',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription: 'Whether or not there was a lateral on the play',
  },
  {
    field: 'play_yards_gained',
    fieldType: 'decimal',
    fieldValues: '-7.78, 56.72',
    fieldDescription: 'How many net yards were gained on the play',
  },
  {
    field: 'play_epoch_uuid',
    fieldType: 'uuid',
    fieldValues: 'd9e3f4ad-4f77-47cc-8882-807ef221da30',
    fieldDescription:
      'The event id of the epoch event on the play.  Epoch events are defined as (in order): - Catch - When a runner passes the LOS - When the ball changes possession - When the ball is dead',
  },
  {
    field: 'play_offense_players_left',
    fieldType: 'integer',
    fieldValues: '0, 1, 2, 3, 4, 5, 6',
    fieldDescription:
      'Number of WRs and TEs lined up to the left of the Center',
  },
  {
    field: 'play_offense_players_right',
    fieldType: 'integer',
    fieldValues: '0, 1, 2, 3, 4, 5, 6',
    fieldDescription:
      'Number of WRs and TEs lined up to the right of the Center',
  },
  {
    field: 'play_snap_y',
    fieldType: 'decimal',
    fieldValues: '18.61',
    fieldDescription: 'y coordinate of the location of the snap of the ball',
  },
];
