// do not display these widgets ...yet
export const PLAY_FINDER_WIDGET_BLACKLIST = [
  'play_player_role_f',
  'play_player_role_h',
  'play_player_role_x',
  'play_player_role_y',
  'play_player_role_z',
  'game_ids',
  'opponent_ids',
];
