import { scaleLinear } from 'd3';
import { clamp } from 'lodash';
import {
  COLOR_DIVERGENT_SCALING,
  BAR_MODE_LA_OFFSET,
  BAR_MODE_SIGMA,
  COLOR_GAP_VS_POSITION,
  COLOR_GOOD_BAD,
} from '../../pages/team/lineBattles/PassPressures/PassPressures.constants';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import { csDivergent, csValue } from '../../utils/helpers/colorScales';
import {
  OLPRESSURE_CLASS_NAMES,
  OLPRESSURE_GAP_PRESSURE_SCALE,
  OLPRESSURE_GAP_SPARKLINE_SCALE,
  OLPRESSURE_HEIGHTS,
  OLPRESSURE_OFFSET_PRESSURE_SCALE,
  OLPRESSURE_RELATIVE_SCALE,
  OLPRESSURE_WIDTHS,
} from './OLPressure.constants';
import { fontBaselineY } from '../text';
import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../../utils/constants/charting';

const yScaleRaw = scaleLinear()
  .domain([
    OLPRESSURE_GAP_PRESSURE_SCALE.MIN,
    OLPRESSURE_GAP_PRESSURE_SCALE.MAX,
  ])
  .range([2, OLPRESSURE_HEIGHTS.MAIN_VIS])
  .clamp(true);

const yScaleOffset = scaleLinear()
  .domain([
    OLPRESSURE_OFFSET_PRESSURE_SCALE.MIN,
    OLPRESSURE_OFFSET_PRESSURE_SCALE.MAX,
  ])
  .range([2, OLPRESSURE_HEIGHTS.MAIN_VIS])
  .clamp(true);

const yScaleRelative = scaleLinear()
  .domain([OLPRESSURE_RELATIVE_SCALE.MIN, OLPRESSURE_RELATIVE_SCALE.MAX])
  .range([2, OLPRESSURE_HEIGHTS.MAIN_VIS])
  .clamp(true);

const axisArrow = (svgG, visPalette, width, height) => {
  const aw = width / 2; // working from center, width center to edge
  const r = aw / 2; // how fat to make the arrow before the head
  const r2 = aw - r; // arrow goes to desired width
  const tailLength = 0.9 * height; // most of arrow is tail
  const headLength = height - tailLength;
  svgG
    .append('path')
    .attr(
      'd',
      `M0 0 l${r} ${tailLength} l${r2} 0 l-${aw} ${headLength} l-${aw} -${headLength} l${r2} 0 z`
    )
    .attr('stroke', visPalette.axis)
    .attr('fill', 'none')
    .attr('stroke-width', 1);
};
const drawMainAxes = (svgG, visPalette, barMode) => {
  let axisHeights = OLPRESSURE_GAP_PRESSURE_SCALE.axes;
  let scalingFunction = yScaleRaw;
  if (barMode === BAR_MODE_LA_OFFSET.value) {
    axisHeights = OLPRESSURE_OFFSET_PRESSURE_SCALE.axes;
    scalingFunction = yScaleOffset;
  }
  if (barMode === BAR_MODE_SIGMA.value) {
    axisHeights = OLPRESSURE_RELATIVE_SCALE.axes;
    scalingFunction = yScaleRelative;
  }

  const axesG = svgG
    .append('g')
    .attr('class', OLPRESSURE_CLASS_NAMES.GAPS_AXES)
    .attr('transform', 'translate(0,-2)'); // stroke of bars

  axesG
    .selectAll('line')
    .data(axisHeights)
    .enter()
    .append('line')
    .attr('x1', OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH)
    .attr(
      'x2',
      OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_AXIS_WIDTH
    )
    .attr('y1', (d) => scalingFunction(d))
    .attr('y2', (d) => scalingFunction(d))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', (d) => (d === 0 ? '' : '2 2'))
    .attr('opacity', 0.8);

  /* Because of relative scaling, hide actual numbers in favour of indicator arrow */
  // axesG
  //   .selectAll('text')
  //   .data(axisHeights)
  //   .enter()
  //   .append('text')
  //   .attr('x', OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH - 4)
  //   .attr('y', (d) => scalingFunction(d) + DEFAULT_FONT_SIZE / 2)
  //   .attr('font-size', `${DEFAULT_FONT_SIZE}px`)
  //   .attr('font-family', DEFAULT_FONT)
  //   .attr('text-anchor', 'end')
  //   .attr('fill', visPalette.text.label)
  //   .text((d) => `${d}`);
  const axesGArrow = axesG
    .append('g')
    .attr(
      'transform',
      `translate(${OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH / 2},2)`
    );
  axisArrow(
    axesGArrow,
    visPalette,
    OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH - 4, // keep it off the axis itself
    OLPRESSURE_HEIGHTS.MAIN_VIS
  );

  const axesYG = axesG.append('g');
  axesYG
    .append('line')
    .attr('x1', OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH)
    .attr('x2', OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH)
    .attr('y1', 0)
    .attr('y2', OLPRESSURE_HEIGHTS.MAIN_VIS + 10) // eats into margin slightly
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 1);

  /* Dev Helper: adds vertical strokes to the vis showing bar segments */
  // const axesYG2 = axesG.append('g');
  // axesYG2
  //   .selectAll('line')
  //   .data([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
  //   .enter()
  //   .append('line')
  //   .attr('x1', (d) => (d + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION)
  //   .attr('x2', (d) => (d + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION)
  //   .attr('y1', 0)
  //   .attr('y2', OLPRESSURE_HEIGHTS.MAIN_VIS)
  //   .attr('stroke', visPalette.axis)
  //   .attr('stroke-width', 1)
  //   .attr('stroke-dasharray', '2 2')
  //   .attr('opacity', 0.8);
};

export const drawSectionTitle = (svgG, visPalette) => {
  svgG.selectAll('text').remove();
  svgG
    .append('text')
    .attr('x', 0)
    .attr(
      'y',
      fontBaselineY(
        VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE,
        VISUALISATION_FONT_SETUPS.AREA_TITLE.LINE_HEIGHT
      )
    )
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AREA_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'start')
    .attr('fill', visPalette.text.info)
    .text('Pressure relative to average for line position/gap');
};

const drawBars = (
  svgG,
  visPalette,
  pressureData,
  barMode,
  compareTeamValues,
  colorMode,
  isDarkMode,
  handleBarClick,
  selectedBar
) => {
  const blocksG = svgG
    .append('g')
    .attr(
      'transform',
      `translate(${
        OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_BLOCK_AXIS
      },0)`
    );

  const blocksFilteredG = blocksG.append('g');
  const blocksTeamG = blocksG.append('g');

  const scaleY = (pressureDatum) => {
    if (barMode === BAR_MODE_LA_OFFSET.value) {
      return yScaleOffset(pressureDatum.pressOverBaseline);
    }
    if (barMode === BAR_MODE_SIGMA.value) {
      return yScaleRelative(pressureDatum.pressMu);
    }
    return yScaleRaw(pressureDatum.pressurePerc);
  };
  const scaleYTeam = (pressureDatum) => {
    if (barMode === BAR_MODE_LA_OFFSET.value) {
      return yScaleOffset(pressureDatum.teamOverBaseline);
    }
    if (barMode === BAR_MODE_SIGMA.value) {
      return yScaleRelative(pressureDatum.teamMu);
    }
    return yScaleRaw(pressureDatum.teamPressurePerc);
  };

  const colorBar = (pressureDatum) => {
    if (colorMode === COLOR_GAP_VS_POSITION.value) {
      return pressureDatum.gap
        ? visPalette.successFail.fail.main
        : visPalette.successFail.fail2.main;
    }
    if (colorMode === COLOR_GOOD_BAD.value) {
      if (
        pressureDatum.pressurePerc < pressureDatum.teamPressurePerc &&
        pressureDatum.pressurePerc < pressureDatum.avgPressurePerc
      ) {
        return visPalette.successFail.superSuccess.main;
      }
      if (pressureDatum.pressurePerc < pressureDatum.teamPressurePerc) {
        return visPalette.successFail.success.main;
      }
      if (pressureDatum.pressurePerc === pressureDatum.teamPressurePerc) {
        return visPalette.successFail.neutral.main;
      }
      if (
        pressureDatum.pressurePerc > pressureDatum.teamPressurePerc &&
        pressureDatum.pressurePerc > pressureDatum.avgPressurePerc
      ) {
        return visPalette.successFail.superFail.main;
      }
      return visPalette.successFail.fail.main;
    }
    if (colorMode === COLOR_DIVERGENT_SCALING.value) {
      const clampedRelativeValue = clamp(pressureDatum.pressMu, 0, 2);
      const flippedValue = clampedRelativeValue / 2;
      return csDivergent(flippedValue, isDarkMode);
    }
    // else relative coloring
    const clampedRelativeValue = clamp(pressureDatum.pressMu, 0, 2);
    const flippedValue = 1 - clampedRelativeValue / 2;
    return csValue(flippedValue, isDarkMode);
  };

  blocksFilteredG
    .selectAll('rect')
    .data(pressureData)
    .enter()
    .append('rect')
    .attr(
      'x',
      (d) =>
        (d.index + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION -
        OLPRESSURE_WIDTHS.GAP_BLOCK / 2
    )
    .attr('y', 0)
    .attr('width', OLPRESSURE_WIDTHS.GAP_BLOCK)
    .attr('height', (d) => scaleY(d))
    .attr('fill', (d) => colorBar(d))
    .attr('opacity', (d) =>
      selectedBar !== null && d.key !== selectedBar ? 0.4 : 1
    )
    .attr('class', (d) =>
      d.hasOwnProperty('position')
        ? VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
        : ''
    );
  if (compareTeamValues) {
    blocksTeamG
      .selectAll('rect')
      .data(pressureData)
      .enter()
      .append('rect')
      .attr(
        'x',
        (d) =>
          (d.index + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION -
          OLPRESSURE_WIDTHS.GAP_BLOCK / 2 +
          1
      )
      .attr('y', 1)
      .attr('width', OLPRESSURE_WIDTHS.GAP_BLOCK - 2)
      .attr('height', (d) => scaleYTeam(d) - 2)
      .attr('stroke', visPalette.contrast)
      .attr('stroke-width', 2)
      .attr('stroke-opacity', 0.8)
      .attr('fill', 'transparent')
      .attr('opacity', (d) =>
        selectedBar !== null && d.key !== selectedBar ? 0.4 : 1
      )
      .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
      .on('click', (_, datum) => handleBarClick(datum));
  }
};

const drawSparkChart = (sparkG, visPalette, pressureData) => {
  const yScaleSpark = scaleLinear()
    .domain([
      OLPRESSURE_GAP_SPARKLINE_SCALE.MIN,
      OLPRESSURE_GAP_SPARKLINE_SCALE.MAX,
    ])
    .range([0, OLPRESSURE_HEIGHTS.SPARK_CHART])
    .clamp(true);

  const mainG = sparkG
    .append('g')
    .attr('transform', `translate(${OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH},0)`);
  const axisG = mainG.append('g').attr('transform', 'translate(0,-2)'); // stroke of bars

  const slotsG2 = mainG
    .append('g')
    .attr('transform', `translate(${OLPRESSURE_WIDTHS.GAP_BLOCK_AXIS},0)`);
  const slotsG = mainG
    .append('g')
    .attr('transform', `translate(${OLPRESSURE_WIDTHS.GAP_BLOCK_AXIS},0)`);

  const titleG = sparkG.append('g');
  titleG
    .append('text')
    .attr('x', 0)
    .attr('y', -VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE)
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AREA_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'start')
    .attr('fill', visPalette.text.info)
    .text('Pressures per 100 plays');

  const axisHeights = OLPRESSURE_GAP_SPARKLINE_SCALE.axes;
  axisG
    .selectAll('line')
    .data(axisHeights)
    .enter()
    .append('line')
    .attr('x1', 0)
    .attr('x2', OLPRESSURE_WIDTHS.GAP_AXIS_WIDTH)
    .attr('y1', (d) => yScaleSpark(d))
    .attr('y2', (d) => yScaleSpark(d))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', (d) => (d === 0 ? '' : '2 4'));
  const axisYG = axisG.append('g');
  axisYG
    .append('line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', yScaleSpark(0))
    .attr('y2', yScaleSpark(25))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 1);
  axisYG
    .selectAll('text')
    .data(axisHeights)
    .enter()
    .append('text')
    .attr('x', -2)
    .attr(
      'y',
      (d) => yScaleSpark(d) + VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE / 2
    )
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .attr('fill', visPalette.text.label)
    .text((d) => `${d}`);

  slotsG
    .selectAll('circle')
    .data(pressureData)
    .enter()
    .append('circle')
    .attr('cx', (d) => (d.index + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION)
    .attr('cy', (d) => yScaleSpark(d.avgPressurePerc))
    .attr('r', 4)
    .attr('stroke', 'none')
    .attr('fill', (d) =>
      d.gap
        ? visPalette.positions.defense.dl.main
        : visPalette.positions.offense.ol.main
    );

  slotsG2
    .selectAll('circle')
    .data(pressureData)
    .enter()
    .append('circle')
    .attr('cx', (d) => (d.index + 0.5) * OLPRESSURE_WIDTHS.BAR_SECTION)
    .attr('cy', (d) => yScaleSpark(d.pressurePerc))
    .attr('r', 6)
    .attr('stroke', visPalette.contrast)
    .attr('stroke-width', 2)
    .attr('fill', 'transparent');
};

const drawOL = function (
  svgG,
  visPalette,
  pressureData,
  barMode,
  compareTeamValues,
  colorMode,
  showRawDots,
  isDarkMode,
  handleBarClick,
  selectedBar
) {
  svgG.selectAll('g').remove();
  svgG.selectAll('rect').remove();
  svgG.selectAll('line').remove();

  const mainVisG = svgG
    .append('g')
    .attr('class', OLPRESSURE_CLASS_NAMES.MAIN_VIS);

  const sparkG = svgG
    .append('g')
    .attr('class', OLPRESSURE_CLASS_NAMES.SPARK_CHART)
    .attr(
      'transform',
      `translate(0,${
        OLPRESSURE_HEIGHTS.MAIN_VIS + OLPRESSURE_HEIGHTS.MAIN_VIS_MARGIN_BELOW
      })`
    );

  drawMainAxes(mainVisG, visPalette, barMode);

  drawBars(
    mainVisG,
    visPalette,
    pressureData,
    barMode,
    compareTeamValues,
    colorMode,
    isDarkMode,
    handleBarClick,
    selectedBar
  );

  if (showRawDots) {
    drawSparkChart(sparkG, visPalette, pressureData);
  }
};

export { drawOL };
