import React from 'react';
import { ButtonIcon, Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { ROTATIONS } from '../../../utils/constants/charting';
import Tile from '../../../components/Tile/Tile';
import useQueryString from '../../../utils/hooks/useQueryString';
import { TileInlineLoader } from './TeamOverview.styles';
import HavocChart from '../../../visualisations/HavocChart/HavocChart';
import HavocChartKey from '../../../visualisations/HavocChart/HavocChartKey/HavocChartKey';
import {
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_TYPE_ALL,
  HAVOC_Y_MODE_SNAP,
} from '../../../visualisations/HavocChart/HavocChart.constants';
import { useGetHavocEvents } from '../TeamHavoc/TeamHavoc.hooks';
import {
  HAVOC_TABLE_AGGREGATION_MODE_TYPE,
  getHavocHeaders,
} from '../TeamHavoc/HavocTable.jsx/HavocTable.constants';
import { HAVOC_TILE_OPTIONS } from './TeamHavoc.Tile.constants';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { havocEventsToGroupedHavocPlays } from '../TeamHavoc/HavocPlays.dataManipulation';
import HavocTable from '../TeamHavoc/HavocTable.jsx/HavocTable';

const TeamHavocTile = function () {
  const [tileMode, setTileMode] = useQueryString('havocMode', 'havoc');
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'havocKey',
    true
  );

  const {
    loading: isLoading,
    error: hasError,
    teamHavocData,
  } = useGetHavocEvents(true, HAVOC_TYPE_ALL.value);

  // Havoc Table ~ format rows
  const aggregationMode =
    tileMode === 'gap'
      ? HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP
      : HAVOC_TABLE_AGGREGATION_MODE_TYPE.POSITION;
  const { columnHeaders, defaultColumnKey } = getHavocHeaders(aggregationMode);
  const { groupData, totalsDatum } = havocEventsToGroupedHavocPlays({
    havocData: teamHavocData,
    aggregationMode,
    playSummary: {},
  });

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header style={{ maxHeight: '5.64rem' }} $border="2px">
        <h3>Havoc Chart</h3>
        <div className="buttons">
          <DropdownWrapper>
            <Dropdown
              id="team-havoc-tile-dropdown"
              components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon,
              }}
              isSearchable={false}
              menuAlignment="right"
              menuPlacement="bottom"
              onChange={(selectedOption) => setTileMode(selectedOption.value)}
              options={HAVOC_TILE_OPTIONS}
              value={HAVOC_TILE_OPTIONS.find((item) => item.value === tileMode)}
              labelPosition="none"
              label="Passing chart display options"
              variant="tertiary"
              size="small"
            />
          </DropdownWrapper>
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            href="/team/havoc/:leagues/:seasons/:teams"
            linkName="Open Team Havoc Chart"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="1rem" $minHeight="364px">
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        {isLoading && <TileDimmer />}
        <div>
          <div
            style={{
              maxWidth: '800px',
              margin: 'auto',
              display: tileMode === 'havoc' ? 'block' : 'none',
            }}
          >
            <HavocChart
              data={teamHavocData}
              orientation={ROTATIONS.VERTICAL_DOWN}
              displayYMode={HAVOC_Y_MODE_SNAP.value}
              colorMode={HAVOC_COLOR_MODE_LOCATIONS_TIME.value}
              fieldFocusMode={HAVOC_FOCUS_MODE_BOX.value}
              showPaths={false}
              selectedEvent={null}
              setSelectedEvent={null}
            />
          </div>
          <div
            style={{
              display:
                tileMode === 'gap' || tileMode === 'position'
                  ? 'block'
                  : 'none',
            }}
          >
            {teamHavocData && (
              <Tile>
                <Tile.Header $isSlim>
                  <h2>{`Havoc ${
                    tileMode === 'gap' ? 'Gap' : 'Position'
                  } Events`}</h2>
                </Tile.Header>
                <Tile.Body $padding="0">
                  <HavocTable
                    bodyRows={groupData}
                    footerRows={[totalsDatum]}
                    columnHeaders={columnHeaders}
                    defaultColumnKey={defaultColumnKey}
                  />
                </Tile.Body>
              </Tile>
            )}
          </div>
          {!hasError && displayChartKey && tileMode === 'havoc' && (
            <div style={{ maxWidth: '550px' }}>
              <HavocChartKey
                colorMode={HAVOC_COLOR_MODE_LOCATIONS_TIME.value}
              />
            </div>
          )}
        </div>
        {isLoading && (
          <TileInlineLoader>
            <Loader
              active
              inline
              size="small"
              content="Loading Team Passing Data"
            />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

export default TeamHavocTile;
