import { useReactiveVar } from '@apollo/client';
import { mf_LeagueLevel } from '../../apollo';
import { COMPETITION_LEVEL } from '../constants/charting';

/**
 * hook that determines whether the currently selected league is NCAA
 */
export const useIsNcaa = () => {
  const leagueLevel = useReactiveVar(mf_LeagueLevel);
  const isNcaa = leagueLevel === COMPETITION_LEVEL.NCAA;

  return isNcaa;
};
