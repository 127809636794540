/*
  The values for pass placement vary from 
    x = -16 to +16 (feet left/right of catcher) 
    y = 0 to 16 (feet, ground upwards)
    This is larger than the original collection spec (the collectable area was enlarged)

  In terms of covering %s of passes, 
    99.4% of passes occur y <= 12'
    99.2% of passes occur x = +- 6'
      99.94% of passes occur x = +- 12' (i.e. a 2:1 ratio ~ v1 design ratio)
      99.7% of passes occur x = +- 8' (3:2 ratio)
*/
const PASS_PLACEMENT_DOMAIN = {
  PLUS_MINUS_X: 16, // FEET (not yards!)
  MAX_Y: 16, // FEET (not yards!)
};
const PASS_PLACEMENT_SCALES = [
  {
    ASPECT_RATIO: '2:1',
    RATIO_Y_OVER_X: 1 / 2,
    PLUS_MINUS_X_OPTIONS: [8 / 3, 10 / 3, 12 / 3, 14 / 3, 16 / 3],
    DEFAULT_PLUS_MINUS_X_INDEX: 1, // 24': 12'
  },
  {
    ASPECT_RATIO: '3:2',
    RATIO_Y_OVER_X: 2 / 3,
    PLUS_MINUS_X_OPTIONS: [6 / 3, 7.5 / 3, 9 / 3, 12 / 3],
    DEFAULT_PLUS_MINUS_X_INDEX: 1, // 15': 10'
  },
  {
    ASPECT_RATIO: '4:3',
    RATIO_Y_OVER_X: 3 / 4,
    PLUS_MINUS_X_OPTIONS: [6 / 3, 8 / 3, 10 / 3], // 24': 12'
    DEFAULT_PLUS_MINUS_X_INDEX: 1, // 16': 12'
  },
];
const PASS_PLACEMENT_SCALE_OPTIONS = PASS_PLACEMENT_SCALES.map(
  (aspectRatio) => ({
    value: aspectRatio.ASPECT_RATIO,
    label: `${aspectRatio.ASPECT_RATIO} aspect ratio`,
  })
);
const PASS_PLACEMENT_SCALE_DEFAULTS = PASS_PLACEMENT_SCALES[0]; // 2:1 ratio by default

/* Where the ball would ideally hit */
const PASS_PLACEMENT_TARGET = {
  X: 0, // middle of player
  Y: 4.5 / 3, // 4.5' off the ground in yds
};
const CHART_AREA_SIZES = {
  MAIN_WIDTH: 1000, // note height is determined by selected ratio
  AXIS_SPACE: 20,
};
const PASS_PLACEMENT_COLOR_MODES = {
  LENGTH: 'LENGTH',
  SUCCESS: 'SUCCESS',
  PROXIMITY: 'PROXIMITY',
  LOCATION: 'LOCATION',
};

const PASS_PLACEMENT_COLOR_OPTIONS = [
  { value: PASS_PLACEMENT_COLOR_MODES.LOCATION, label: 'Location' },
  { value: PASS_PLACEMENT_COLOR_MODES.PROXIMITY, label: 'Proximity Grade' },
  { value: PASS_PLACEMENT_COLOR_MODES.SUCCESS, label: 'Catch Success' },
  { value: PASS_PLACEMENT_COLOR_MODES.LENGTH, label: 'Pass Length' },
];

/* A ball nose towards passer/catcher */
const FOOTBALL_RADIUS_YDS = 0.093;

const PASS_PLACEMENT_CLASS_NAMES = {
  MARGIN: 'margin-adjusted',
  UNCLIPPED: 'unclipped',
  CLIPPED: 'clipped',
  AXES: 'within-axes',
  FIELD: 'field-adjusted',
  Y_NUMBERS: 'y-numbers',
  X_NUMBERS: 'x-numbers',
  VERTICAL_GRID: 'vertical-grid',
  HORIZONTAL_GRID: 'horizontal-grid',
  GRID_FOCUS: 'focus-grid',
  IMAGE: 'footballer-image-holder',
  DOTS_FIELD: 'field-dot-zone',
  CATCHER: 'catcher-zone',
  GRID: 'grid-zone',
  DOTS_PLAYER: 'dots-zone',
  KEY: 'key',
};

const FEET_PER_YARD = 3;

export {
  PASS_PLACEMENT_DOMAIN,
  PASS_PLACEMENT_SCALES,
  PASS_PLACEMENT_SCALE_DEFAULTS,
  PASS_PLACEMENT_SCALE_OPTIONS,
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_CLASS_NAMES,
  PASS_PLACEMENT_COLOR_OPTIONS,
  CHART_AREA_SIZES,
  PASS_PLACEMENT_TARGET,
  FOOTBALL_RADIUS_YDS,
  FEET_PER_YARD,
};
