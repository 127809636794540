import { styled } from 'styled-components';

export const RunTableContainer = styled.div`
  width: 100%;
`;
RunTableContainer.displayName = 'RunTableContainer';

export const RunTableTitleHolder = styled.div`
  width: 100%;
  position: relative;
  h2 {
    display: inline-block;
  }
  button {
    float: right;
  }
`;
RunTableTitleHolder.displayName = 'RunTableTitleHolder';
