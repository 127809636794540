import {
  join,
  max,
  meanBy,
  omit,
  reverse,
  sortBy,
  sumBy,
  uniq,
  uniqBy,
} from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { scaleLinear, scaleSqrt } from 'd3-scale';
import {
  FIELD_BORDER_PADDING,
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
} from '../../utils/constants/charting';
import { csValue } from '../../utils/helpers/colorScales';
import { ROTATE_SCALE_ZOOM_DEFAULTS } from '../../utils/visualisations/rotateScaleZoom';
import {
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE,
  TACKLE_LOCATION_Y_MODE_DY,
  TACKLE_LOCATION_Y_MODE_FIELD,
  TACKLE_LOCATION_Y_MODE_SNAP,
  TACKLE_LOCATION_Y_MODE_TA,
  TACKLE_LOCATION_FIELD_SIZES_X,
  TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER,
  TACKLING_KEY_METRICS,
  TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME,
  TACKLING_TACKLER_FIRST,
  TACKLING_TACKLER_SUCCESS,
  TACKLE_POSITION_ANY,
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLING_COORDINATE_SNAP,
  TACKLING_COORDINATE_FIRST_TACKLE,
  TACKLE_LOCATION_X_MODE_TAORIGIN,
  TACKLING_TACKLER_SOLO_ATTEMPT,
  TACKLING_OUTCOMES,
  TACKLING_OUTCOMES_API_KEYS,
  TACKLING_PLAY_TYPE_RUN,
  TACKLING_PLAY_TYPE_SACK,
  TACKLE_RESULT_TACKLE,
  TACKLE_RESULT_MISSED,
  TACKLING_TACKLER_MISSED_ATTEMPT,
} from './TackleLocation.constants';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import { getYardlineName } from '../../utils/helpers/play';
import {
  addBucketDots,
  getBinnedData,
  getQuartiles,
} from '../../utils/visualisations/histogram';
import {
  getObjectColor,
  paletteIsDark,
} from '../../utils/visualisations/visPalettes';
import {
  getPlayOutcome,
  isChunk,
  playOutcomeColor,
} from '../../utils/visualisations/playSuccess';
import { ALIGNMENT_POSITIONS } from '../../utils/constants/positions';
import {
  getBallCarrierLeagueAverages,
  getTacklerLeagueAverages,
} from '../../pages/team/TeamTackling/TeamTackleAttempts.hooks';
// eslint-disable-next-line max-len
import { getTacklingPlayerTableHeaders } from '../../pages/team/TeamTackling/TacklingPlayerTable/TacklingPlayerTable.constants';

const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;

/* 
API Tackling type determines whether attempt was solo/assisted,
  whether the actual tackle was solo/assisted, 
  and whether the actual tackle was successful or not
This can be null in some circumstances (even with a player/location)
  fallback is slightly naive: 
    solo assist missed could happen mid stream in theory
    being first could still be a solo assisted missed
*/
const getTacklingOutcome = (apiTackleType, firstAttempt, oneAttempt) => {
  if (apiTackleType) {
    return TACKLING_OUTCOMES[apiTackleType];
  }
  if (oneAttempt) {
    return TACKLING_OUTCOMES[TACKLING_OUTCOMES_API_KEYS.SOLO_MISSED];
  }
  if (firstAttempt) {
    return TACKLING_OUTCOMES[TACKLING_OUTCOMES_API_KEYS.SOLO_ASSISTED_MISSED];
  }
  return TACKLING_OUTCOMES[TACKLING_OUTCOMES_API_KEYS.ASSISTED_MISSED];
};

const addTackleDerivedValues = (tackleData) => {
  const enrichedTackleData = tackleData.map((tackleDatum) => {
    const lineOfScrimmage = tackleDatum.play.yardLine;
    /* Ditch any attempts with no coordinates, but allow missing player/type */
    const tackleAttempts = sortBy(
      tackleDatum.attempts.filter((a) => a.attemptX && a.attemptY),
      'videoTimestamp'
    );
    const firstAttemptX = tackleAttempts[0].attemptX;
    const firstAttemptY = tackleAttempts[0].attemptY;
    const firstAttemptXLOS = firstAttemptX - lineOfScrimmage;
    const firstAttemptYSnap = firstAttemptY - tackleDatum.play.snapY;
    const finalBallXLOS = tackleDatum.finalBallX - lineOfScrimmage;
    const finalBallXTA = tackleDatum.finalBallX - firstAttemptX;
    const finalBallYSnap = tackleDatum.finalBallY - tackleDatum.play.snapY;
    const finalBallYTA = tackleDatum.finalBallY - firstAttemptY;
    const playOutcome = getPlayOutcome(tackleDatum.play);
    const defenders = tackleAttempts.map((m, i) => {
      const tacklingOutcome = getTacklingOutcome(
        m.tackleType,
        i === 0,
        tackleDatum.attempts.length === 1
      );
      return {
        id: m.tackler?.id,
        videoTimestamp: m.videoTimestamp,
        name: m.tackler?.name,
        position: m.tacklerPosition,
        x: m.attemptX,
        y: m.attemptY,
        xLOS: m.attemptX - lineOfScrimmage,
        xTA: m.attemptX - firstAttemptX,
        ySnap: m.attemptY - tackleDatum.play.snapY,
        yTA: m.attemptY - firstAttemptY,
        ...tacklingOutcome,
      };
    });
    const tackleForLoss = finalBallXLOS < 0;
    const missedTackles = defenders.filter((m) => !m.success).length;

    /* The following two definitions are temporary */
    const { tackleOutcomeColor, tackleResult } = sortBy(
      defenders,
      'priority'
    )[0];
    const chunk = isChunk(tackleDatum.play.yardsNet, tackleDatum.play.yardLine);
    return {
      ...tackleDatum,
      firstAttemptX,
      firstAttemptY,
      firstAttemptXLOS,
      firstAttemptYSnap,
      finalBallXLOS,
      finalBallYSnap,
      finalBallXTA,
      finalBallYTA,
      defenders,
      tackleForLoss,
      tackleOutcomeColor,
      playOutcome,
      missedTackles,
      chunk,
      explosive: tackleDatum.play.explosive,
      bestTackleResult: tackleResult,
    };
  });
  return enrichedTackleData;
};

const getPlayerTacklePlays = (
  tackleData,
  showDefensive,
  selectedPlayerId,
  tacklerMode,
  selectedTacklerPosition,
  tacklingType
) => {
  if (
    selectedPlayerId ||
    selectedTacklerPosition !== TACKLE_POSITION_ANY.value
  ) {
    if (showDefensive) {
      if (tacklerMode === TACKLING_TACKLER_FIRST.value) {
        return tackleData.filter(
          (play) =>
            (selectedPlayerId === 0 ||
              play.defenders[0].id === selectedPlayerId) &&
            (selectedTacklerPosition === TACKLE_POSITION_ANY.value ||
              play.defenders[0].position === selectedTacklerPosition)
        );
      }
      if (tacklerMode === TACKLING_TACKLER_SOLO_ATTEMPT.value) {
        return tackleData.filter(
          (play) =>
            play.defenders.length === 1 && // is solo for now: TODO better def
            (selectedPlayerId === 0 ||
              play.defenders[0].id === selectedPlayerId) &&
            (selectedTacklerPosition === TACKLE_POSITION_ANY.value ||
              play.defenders[0].position === selectedTacklerPosition)
        );
      }
      if (tacklerMode === TACKLING_TACKLER_SUCCESS.value) {
        return tackleData.filter((play) => {
          const matchingDefenders = play?.defenders?.filter(
            (def) =>
              def.success &&
              (selectedPlayerId === 0 || def.id === selectedPlayerId) &&
              (selectedTacklerPosition === TACKLE_POSITION_ANY.value ||
                def.position === selectedTacklerPosition)
          );
          return matchingDefenders.length > 0;
        });
      }
      if (tacklerMode === TACKLING_TACKLER_MISSED_ATTEMPT.value) {
        return tackleData?.filter(
          (play) =>
            play.defenders[0].id === selectedPlayerId &&
            play.attempts.some((attempt) => !attempt.success)
        );
      }
      return tackleData?.filter((play) => {
        const matchingDefenders = play.defenders.filter(
          (def) =>
            (selectedPlayerId === 0 || def.id === selectedPlayerId) &&
            (selectedTacklerPosition === TACKLE_POSITION_ANY.value ||
              def.position === selectedTacklerPosition)
        );
        return matchingDefenders.length > 0;
      });
    }
    /* Else in offensive mode ~ tackler position irrelevant */
    return tackleData.filter(
      (run) => run?.ballCarrier?.id === selectedPlayerId
    );
  }

  if (tacklingType === TACKLE_RESULT_TACKLE.value) {
    return tackleData?.filter((play) =>
      play.attempts.some((attempt) => attempt.success)
    );
  }
  if (tacklingType === TACKLE_RESULT_MISSED.value) {
    return tackleData?.filter((play) =>
      play.attempts.some((attempt) => !attempt.success)
    );
  }

  return tackleData;
};

/* Get settings for Rotate / Scale / Zoom function
 */
const getRotationSettings = (width, height, orientation) => {
  const vbw = orientation === ROTATIONS.HORIZONTAL ? width : height;
  const vbh = orientation === ROTATIONS.HORIZONTAL ? height : width;
  return {
    ...ROTATE_SCALE_ZOOM_DEFAULTS,
    viewPortWidth: vbw,
    viewPortHeight: vbh,
    fieldWidth: width,
    fieldHeight: height,
    targetFieldX: width / 2,
    targetFieldY: height / 2,
    orientation,
  };
};

const getFieldSize = (orientation, isFieldYRelative, displayXFocusedField) => {
  const horizontal = orientation === ROTATIONS.HORIZONTAL;
  const fieldYYds = isFieldYRelative
    ? FIELD_Y_YARDS_RELATIVEY
    : FIELD_Y_YARDS + FIELD_BORDER_PADDING * 2;
  const fieldSizeY = fieldYYds * pxPerYard;

  const fieldLoS = displayXFocusedField
    ? TACKLE_LOCATION_FIELD_SIZES_X.FOCUSED.LOS
    : TACKLE_LOCATION_FIELD_SIZES_X.FULL.LOS;
  const fieldPostLoS = displayXFocusedField
    ? TACKLE_LOCATION_FIELD_SIZES_X.FOCUSED.END
    : TACKLE_LOCATION_FIELD_SIZES_X.FULL.END;
  const fieldXYds = fieldLoS + fieldPostLoS;

  const fieldSizeX = fieldXYds * pxPerYard;
  const fieldArea = {
    width: horizontal ? fieldSizeX : fieldSizeY,
    height: horizontal ? fieldSizeY : fieldSizeX,
    fieldXYds,
    fieldLoS,
    fieldYYds,
    fieldSizeX,
    fieldSizeY,
  };

  return fieldArea;
};

const getLAMean = (leagueAverageData, datumKey) => {
  const weightedValues = leagueAverageData.map((m) => {
    const bucketVal = (m.bucketmin + m.bucketmax) / 2;
    const frequency = m[datumKey];
    return { ...m, weightedTotal: bucketVal * frequency };
  });
  const totalWeight = sumBy(weightedValues, 'weightedTotal');
  const totalFrequency = sumBy(weightedValues, datumKey);
  const weightedMean = totalFrequency === 0 ? 0 : totalWeight / totalFrequency;
  return weightedMean;
};

const tackleToFinalScaler = scaleLinear()
  .domain([0, 1, 2, 5, 20])
  .range([0, 0.25, 0.5, 0.75, 1])
  .clamp(true);

const radiusScaler = scaleSqrt().domain([0, 1]).range([1, 4]).clamp(true);

const tackleColor = (
  tackleDatum,
  colorMode,
  visPalette,
  selectedPlay,
  playersWithColor
) => {
  if (selectedPlay === tackleDatum.play.id) {
    return visPalette.selectedObject;
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_DX.value) {
    if (tackleDatum.finalBallXTA < 0) {
      return visPalette.objects.n2.main;
    }
    const dXNormalized = tackleToFinalScaler(tackleDatum.finalBallXTA);
    const isDarkMode = paletteIsDark(visPalette);
    return csValue(dXNormalized, isDarkMode);
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE.value) {
    if (tackleDatum.tacklingPlayType === TACKLING_PLAY_TYPE_RUN.value) {
      return visPalette.objects.n1.main;
    }
    if (tackleDatum.tacklingPlayType === TACKLING_PLAY_TYPE_SACK.value) {
      return visPalette.objects.n3.main;
    }
    return visPalette.objects.n2.main;
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME.value) {
    return playOutcomeColor(tackleDatum.playOutcome, visPalette);
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME.value) {
    return tackleDatum.tackleOutcomeColor(visPalette);
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER.value) {
    /* Check ball carriers first */
    const matchingCarrier = playersWithColor?.find(
      (f) => f.playerId === tackleDatum?.ballCarrier?.id
    );
    if (matchingCarrier?.color) {
      return matchingCarrier.color;
    }
    return visPalette.objects.neutral.main;
  }
  return visPalette.contrast;
};

const addBallCarrierDerviedValues = (ballCarrierDatum) => {
  const {
    touches,
    soloAttempts,
    soloTackles,
    shortStops,
    brokenTackles,
    yardsGained,
    yardsBeforeAttempt,
    yardsAfterAttempt,
    tackleAttempts,
    assistTacklesSoloAttempt,
  } = ballCarrierDatum;
  const soloTackleSuccessRate = soloAttempts
    ? (soloTackles + 0.5 * assistTacklesSoloAttempt) / soloAttempts
    : 0;
  const stopRate = touches !== 0 ? shortStops / touches : 0;
  const brokenTackleRate = tackleAttempts ? brokenTackles / tackleAttempts : 0;
  const yardsPerAttempt = touches ? yardsGained / touches : 0;
  const yardsBeforeTackleAttempt = touches ? yardsBeforeAttempt / touches : 0;
  const yardsAfterTackleAttempt = touches ? yardsAfterAttempt / touches : 0;
  return {
    ...ballCarrierDatum,
    soloTackleSuccessRate,
    stopRate,
    brokenTackleRate,
    yardsPerAttempt,
    yardsBeforeTackleAttempt,
    yardsAfterTackleAttempt,
  };
};
export const getBallCarriers = (tackleData, playerStatsData) => {
  const ballCarriers = uniqBy(
    tackleData.filter((b) => b?.ballCarrier?.id),
    (d) => d.ballCarrier.id
  );
  const ballCarrierCounts = ballCarriers.map((b) => {
    const carrierPlayerStats = playerStatsData.find(
      (p) => p.playerId === b.ballCarrier.id
    );
    const carrierPlays = tackleData.filter(
      (t) => t?.ballCarrier?.id === b.ballCarrier.id
    );
    /* For each play find assignment position */
    const carrierAlignmentPositions = uniq(
      carrierPlays.map((l) => l.ballCarrierPosition)
    ).filter(Boolean); // ditch plays where no position assigned for this
    const carrierPositionUsages = carrierAlignmentPositions.map((position) => {
      const plays = carrierPlays.filter(
        (t) => t.ballCarrierPosition === position
      ).length;
      const playPercentage = plays / carrierPlays.length;
      return {
        position,
        plays,
        playPercentage,
        positionCode: ALIGNMENT_POSITIONS[position].code,
        positionName: ALIGNMENT_POSITIONS[position].name,
      };
    });
    const sortedPositionUsageCodes = reverse(
      sortBy(
        carrierPositionUsages.filter((g) => g.position),
        'playPercentage'
      )
    );
    const primaryPositionCode =
      sortedPositionUsageCodes[0]?.positionCode || '-';
    const positionUsageCodes = sortedPositionUsageCodes.map(
      (usage) =>
        `${usage.positionName}: ${usage.plays} (${
          usage.playPercentage ? (100 * usage.playPercentage).toFixed(1) : 0
        }%)`
    );
    const positionUsageDescription = positionUsageCodes?.length
      ? join(positionUsageCodes, '\n')
      : '-';

    const plays = carrierPlayerStats?.plays;
    const touches = carrierPlays.length;
    const explosives = carrierPlays.filter((f) => f.explosive).length;
    const touchdowns = carrierPlays.filter((f) => f.play.touchdownWon).length;
    const chunks = carrierPlays.filter((f) => f.chunk).length;

    const flattenedAttempts = carrierPlays.map((c) => c.defenders || []).flat();
    const soloAttempts =
      flattenedAttempts.filter((f) => f.soloAttempt)?.length || 0;
    const assistAttempts =
      flattenedAttempts.filter((f) => !f.soloAttempt)?.length || 0;
    const tackleAttempts = soloAttempts + assistAttempts;
    const soloTackles =
      flattenedAttempts.filter((f) => f.success && f.soloTackle)?.length || 0;
    const assistTackles =
      flattenedAttempts.filter((f) => f.success && !f.soloTackle)?.length || 0;
    const assistTacklesSoloAttempt =
      flattenedAttempts.filter(
        (f) => f.success && f.soloAttempt && !f.soloTackle
      )?.length || 0;
    const brokenTackles =
      flattenedAttempts.filter((f) => !f.success)?.length || 0;
    const shortStops =
      carrierPlays.filter((f) => f.finalBallXTA <= 1)?.length || 0;
    const yardsGained = sumBy(carrierPlays, 'finalBallXLOS');
    const yardsBeforeAttempt = sumBy(carrierPlays, 'firstAttemptXLOS');
    const yardsAfterAttempt = sumBy(carrierPlays, 'finalBallXTA');

    const ballCarrierTotals = {
      playerId: b.ballCarrier.id,
      playerName: b.ballCarrier.name,
      teamId: carrierPlays[0]?.play?.offenseTeam?.id,
      teamName: carrierPlays[0]?.play?.offenseTeam?.name,
      /* position bits */
      carrierPositionUsages,
      primaryPositionCode,
      positionUsageDescription,
      /* totals needed in table/for derived metrics */
      plays,
      touches,
      touchdowns,
      chunks,
      explosives,
      soloAttempts,
      assistAttempts,
      tackleAttempts,
      soloTackles,
      assistTackles,
      assistTacklesSoloAttempt,
      brokenTackles,
      shortStops,
      yardsGained,
      yardsBeforeAttempt,
      yardsAfterAttempt,
    };

    return addBallCarrierDerviedValues(ballCarrierTotals);
  });
  const { defaultColumnKey } = getTacklingPlayerTableHeaders(false);
  return reverse(sortBy(ballCarrierCounts, defaultColumnKey));
};
export const getBallCarriersFooter = (
  ballCarriers,
  teamName,
  competitionId,
  playType
) => {
  const laSum = getBallCarrierLeagueAverages(competitionId, playType);
  const laFooterAllValues = addBallCarrierDerviedValues(laSum);
  /* 
  At League Average level the raw counts for touchers / attempts etc. make little sense: 
    drop them having used them to work out the rates & percentage values
  */
  const laFooter = omit(
    laFooterAllValues,
    'touches',
    'yardsGained',
    'soloAttempts',
    'soloTackles'
  );

  const footerBase = {
    playerId: 0,
    playerName: teamName,
  };
  if (!ballCarriers?.length) {
    return [footerBase, laFooter];
  }
  const footerWithSum = {
    ...footerBase,
    touches: sumBy(ballCarriers, 'touches'),
    touchdowns: sumBy(ballCarriers, 'touchdowns'),
    chunks: sumBy(ballCarriers, 'chunks'),
    explosives: sumBy(ballCarriers, 'explosives'),
    soloAttempts: sumBy(ballCarriers, 'soloAttempts'),
    assistAttempts: sumBy(ballCarriers, 'assistAttempts'),
    tackleAttempts: sumBy(ballCarriers, 'tackleAttempts'),
    soloTackles: sumBy(ballCarriers, 'soloTackles'),
    assistTackles: sumBy(ballCarriers, 'assistTackles'),
    assistTacklesSoloAttempt: sumBy(ballCarriers, 'assistTacklesSoloAttempt'),
    shortStops: sumBy(ballCarriers, 'shortStops'),
    brokenTackles: sumBy(ballCarriers, 'brokenTackles'),
    yardsGained: sumBy(ballCarriers, 'yardsGained'),
    yardsBeforeAttempt: sumBy(ballCarriers, 'yardsBeforeAttempt'),
    yardsAfterAttempt: sumBy(ballCarriers, 'yardsAfterAttempt'),
  };
  const teamFooter = addBallCarrierDerviedValues(footerWithSum);
  return [teamFooter, laFooter];
};

/* 
This can be applied to any tackler row (normal or footer)
The totals are pre-calculated
*/
const addDerivedTacklerValues = (tacklerDatum) => {
  const {
    plays,
    allAttempts,
    soloAttempts,
    allTackles,
    trueTackles,
    soloTackles,
    assistTacklesSoloAttempt,
    shortStops,
    soloDepthOfTackleAttempt,
    soloDepthOfTackle,
    soloYardsAfterTackleAttempt,
  } = tacklerDatum;
  const tacklePerc = plays ? allTackles / plays : 1;
  const trueTacklePerc = plays ? trueTackles / plays : 1;
  const soloTackleSuccessRate = soloAttempts
    ? (soloTackles + 0.5 * assistTacklesSoloAttempt) / soloAttempts
    : 0;
  const stopRate = allAttempts !== 0 ? shortStops / allAttempts : 0;
  /* 
  Note: because DOTA/DOTK/YATA only care about solo attempts, the already calculated 
    relative to LoS distances must be correct to the attempt/tackle 
  */
  const depthOfTackleAttempt = soloAttempts
    ? soloDepthOfTackleAttempt / soloAttempts
    : 0;
  const depthOfTackle = soloAttempts ? soloDepthOfTackle / soloAttempts : 0;
  const yardsAfterTackleAttempt = soloAttempts
    ? soloYardsAfterTackleAttempt / soloAttempts
    : 0;
  return {
    ...tacklerDatum,
    tacklePerc,
    trueTacklePerc,
    soloTackleSuccessRate,
    stopRate,
    depthOfTackleAttempt,
    depthOfTackle,
    yardsAfterTackleAttempt,
  };
};
export const getTacklers = (tackleData, playerStatsData) => {
  const flatTacklerList = tackleData?.map((t) => t.defenders || []).flat();
  const tacklers = uniqBy(flatTacklerList, 'id');
  const tacklerCounts = tacklers.map((tackler) => {
    /* Total count of plays and play usage by position come from player stats */
    const tacklerPlayerStats = playerStatsData.find(
      (p) => p.playerId === tackler.id
    );
    const plays = tacklerPlayerStats?.plays || 0;
    const primaryPositionCode = tacklerPlayerStats?.primaryPositionCode;
    const positionUsageDescription =
      tacklerPlayerStats?.positionUsageDescription;
    const positions = tacklerPlayerStats?.positions;

    const tacklerPlays = tackleData
      .filter((t) => t?.defenders?.map((d) => d.id).includes(tackler.id))
      .map((play) => {
        const tacklerDefenderInfo = play.defenders.find(
          (d) => d.id === tackler.id
        );
        return {
          ...play,
          tackler: tacklerDefenderInfo,
        };
      });
    const soloAttemptPlays = tacklerPlays.filter((f) => f.tackler.soloAttempt);
    const soloAttempts = soloAttemptPlays?.length || 0;
    const assistAttempts =
      tacklerPlays.filter((f) => !f.tackler.soloAttempt)?.length || 0;
    const allAttempts = soloAttempts + assistAttempts; // =tacklerPlays.length

    const soloTackles =
      tacklerPlays.filter((f) => f.tackler.success && f.tackler.soloTackle)
        ?.length || 0;
    const assistTackles =
      tacklerPlays.filter((f) => f.tackler.success && !f.tackler.soloTackle)
        ?.length || 0;
    const assistTacklesSoloAttempt =
      tacklerPlays.filter(
        (f) =>
          f.tackler.success && f.tackler.soloAttempt && !f.tackler.soloTackle
      )?.length || 0;
    const allTackles = soloTackles + assistTackles;
    const trueTackles = soloTackles + 0.5 * assistTackles;
    const shortStops =
      tacklerPlays.filter((f) => f.finalBallXTA <= 1)?.length || 0;

    const soloDepthOfTackleAttempt = sumBy(
      soloAttemptPlays,
      'firstAttemptXLOS'
    );
    const soloDepthOfTackle = sumBy(soloAttemptPlays, 'finalBallXLOS');
    const soloYardsAfterTackleAttempt = sumBy(soloAttemptPlays, 'finalBallXTA');

    const tacklerCountsDatum = {
      playerId: tackler.id,
      playerName: tackler.name,
      teamId: tacklerPlays[0]?.play?.defenseTeam?.id,
      teamName: tacklerPlays[0]?.play?.defenseTeam?.name,
      /* position bits */
      positions,
      positionUsageDescription,
      primaryPositionCode,
      /* totals needed in table/for derived metrics */
      plays,
      allAttempts,
      soloAttempts,
      assistAttempts,
      allTackles,
      trueTackles,
      soloTackles,
      assistTackles,
      assistTacklesSoloAttempt,
      shortStops,
      soloDepthOfTackleAttempt,
      soloDepthOfTackle,
      soloYardsAfterTackleAttempt,
    };
    return addDerivedTacklerValues(tacklerCountsDatum);
  });
  const { defaultColumnKey } = getTacklingPlayerTableHeaders(true);
  const sortedTacklers = reverse(sortBy(tacklerCounts, defaultColumnKey));
  return sortedTacklers;
};
export const getTacklersFooter = (
  tacklers,
  teamName,
  competitionId,
  playType
) => {
  const laSum = getTacklerLeagueAverages(competitionId, playType);
  const laFooterAllValues = addDerivedTacklerValues(laSum);
  /* 
  At League Average level the raw counts for touchers / attempts etc. make little sense: 
    drop them having used them to work out the rates & percentage values
  */
  const laFooter = omit(
    laFooterAllValues,
    'plays',
    'allAttempts',
    'soloAttempts',
    'assistAttempts',
    'allTackles',
    'trueTackles',
    'soloTackles',
    'assistTackles'
  );

  const footerBase = {
    playerId: 0,
    playerName: teamName,
  };
  if (!tacklers?.length) {
    return [footerBase, laFooter];
  }
  const footerWithSum = {
    ...footerBase,
    plays: sumBy(tacklers, 'plays'),
    allAttempts: sumBy(tacklers, 'allAttempts'),
    soloAttempts: sumBy(tacklers, 'soloAttempts'),
    assistAttempts: sumBy(tacklers, 'assistAttempts'),
    allTackles: sumBy(tacklers, 'allTackles'),
    trueTackles: sumBy(tacklers, 'trueTackles'),
    soloTackles: sumBy(tacklers, 'soloTackles'),
    assistTackles: sumBy(tacklers, 'assistTackles'),
    assistTacklesSoloAttempt: sumBy(tacklers, 'assistTacklesSoloAttempt'),
    shortStops: sumBy(tacklers, 'shortStops'),
    soloDepthOfTackleAttempt: sumBy(tacklers, 'soloDepthOfTackleAttempt'),
    soloDepthOfTackle: sumBy(tacklers, 'soloDepthOfTackle'),
    soloYardsAfterTackleAttempt: sumBy(tacklers, 'soloYardsAfterTackleAttempt'),
  };
  const footerAllValues = addDerivedTacklerValues(footerWithSum);
  /* Because it doesn't make */
  const footer = omit(footerAllValues, 'plays');
  return [footer, laFooter];
};

export const getTacklerPositions = (tacklers) => {
  const noneList = [TACKLE_POSITION_ANY];
  if (!tacklers?.length) {
    return noneList;
  }
  const flatPositionsList = tacklers?.map((t) => t?.positions || []).flat();
  const realPositions = sortBy(
    uniqBy(flatPositionsList.filter(Boolean), 'name'),
    'name'
  );
  const listPositions = realPositions.map((position) => ({
    value: position.apiCode,
    label: position.name,
  }));
  const fullList = noneList.concat(sortBy(listPositions, 'label'));
  return fullList;
};

const addPlayerColor = (players, visPalette) => {
  const coloredPlayers = players?.map((c, i) => ({
    ...c,
    color: getObjectColor(visPalette, i),
  }));
  return coloredPlayers;
};

const getSelectablePlayerList = (players) => {
  const playerOptions = players?.map((p) => ({
    value: p.playerId,
    label: `${p.playerName} (${p.plays})`,
  }));
  const noneList = [{ value: 0, label: 'Any Player' }];
  const fullList = noneList.concat(playerOptions);
  return fullList;
};

const formatTackleData = (
  tackleData,
  xLoSRelative,
  displayYMode,
  colorMode,
  visPalette,
  scaleR,
  selectedPlay,
  playersWithColor
) => {
  const sortedTackles = sortBy(tackleData, 'finalBallYTA');

  const formattedTackles = sortedTackles.map((tackleDatum, i) => {
    const xFirstTackleAttempt = xLoSRelative
      ? tackleDatum.firstAttemptXLOS * pxPerYard
      : 0;
    const xBallToGround = xLoSRelative
      ? tackleDatum.finalBallXLOS * pxPerYard
      : tackleDatum.finalBallXTA * pxPerYard;

    /* Default is y relative to snap mode */
    let yFirstTackleAttempt = tackleDatum.firstAttemptYSnap * pxPerYard;
    let yBallToGround = tackleDatum.finalBallYSnap * pxPerYard;
    if (displayYMode === TACKLE_LOCATION_Y_MODE_FIELD.value) {
      yFirstTackleAttempt = tackleDatum.firstAttemptY * pxPerYard;
      yBallToGround = tackleDatum.finalBallY * pxPerYard;
    }
    if (displayYMode === TACKLE_LOCATION_Y_MODE_TA.value) {
      yFirstTackleAttempt = 0;
      yBallToGround = tackleDatum.finalBallYTA * pxPerYard;
    }
    if (displayYMode === TACKLE_LOCATION_Y_MODE_DY.value) {
      const indexFraction = i / sortedTackles?.length - 0.5;
      yFirstTackleAttempt = indexFraction * FIELD_Y_YARDS_RELATIVEY * pxPerYard;
      yBallToGround = indexFraction * FIELD_Y_YARDS_RELATIVEY * pxPerYard;
    }

    const dXNormalized = tackleToFinalScaler(tackleDatum.finalBallXTA);
    const color = tackleColor(
      tackleDatum,
      colorMode,
      visPalette,
      selectedPlay,
      playersWithColor
    );
    const r = scaleR ? radiusScaler(dXNormalized) : 2;

    const defenders = tackleDatum.defenders.map((def) => {
      const x = xLoSRelative ? def.xLOS * pxPerYard : def.xTA * pxPerYard;
      const y = def.ySnap * pxPerYard;
      const defenderInfo = {
        x,
        y,
        soloAttempt: def.soloAttempt,
        fillOpacity: def.soloTackle ? 1 : 0,
        color: def.success
          ? visPalette.successFail.success.main
          : visPalette.successFail.fail.main,
        playerId: def.id,
        play_uuid: tackleDatum.play.id,
      };
      if (displayYMode === TACKLE_LOCATION_Y_MODE_FIELD.value) {
        defenderInfo.y = def.y * pxPerYard;
      }
      if (displayYMode === TACKLE_LOCATION_Y_MODE_TA.value) {
        defenderInfo.y = def.yTA * pxPerYard;
      }
      return defenderInfo;
    });
    let path = `M${defenders[0]?.x} ${defenders[0]?.y}`;
    if (defenders.length > 1) {
      path += join(
        defenders.slice(1).map((m) => `L${m.x} ${m.y}`),
        ' '
      );
    }
    if (
      xBallToGround !== defenders[defenders.length - 1]?.x ||
      yBallToGround !== defenders[defenders.length - 1]?.y
    ) {
      path += `L${xBallToGround} ${yBallToGround}`;
    }

    return {
      play_uuid: tackleDatum.play.id,
      xFirstTackleAttempt,
      yFirstTackleAttempt,
      xBallToGround,
      yBallToGround,
      dXNormalized,
      color,
      r,
      failedTackles: tackleDatum.tackleAttempts - 1,
      xYardsGained: tackleDatum.finalBallXTA,
      defenders,
      path,
      tacklingPlayType: tackleDatum.tacklingPlayType,
      bestTackleResult: tackleDatum.bestTackleResult,
    };
  });
  return formattedTackles;
};

const formatHeatmapData = (
  tackleData,
  xLoSRelative,
  displayYMode,
  fieldArea,
  taOrigin
) => {
  const sortedTackles = sortBy(tackleData, 'finalBallYTA');
  const formattedTackles = sortedTackles.map((tackleDatum, i) => {
    let fieldX = taOrigin
      ? tackleDatum.firstAttemptXLOS
      : tackleDatum.finalBallXLOS;
    if (!xLoSRelative) {
      fieldX -= tackleDatum.firstAttemptXLOS;
    }
    const heatmapX = fieldX + fieldArea.fieldLoS;

    let heatmapY = 0;
    if (displayYMode === TACKLE_LOCATION_Y_MODE_FIELD.value) {
      heatmapY = taOrigin ? tackleDatum.firstAttemptY : tackleDatum.finalBallY;
    }
    if (displayYMode === TACKLE_LOCATION_Y_MODE_TA.value) {
      const relativeY = taOrigin ? 0 : tackleDatum.finalBallYTA;
      heatmapY = relativeY + FIELD_Y_YARDS_RELATIVEY / 2;
    }
    if (displayYMode === TACKLE_LOCATION_Y_MODE_SNAP.value) {
      const relativeY = taOrigin
        ? tackleDatum.firstAttemptYSnap
        : tackleDatum.finalBallYSnap;
      heatmapY = relativeY + FIELD_Y_YARDS_RELATIVEY / 2;
    }
    if (displayYMode === TACKLE_LOCATION_Y_MODE_DY.value) {
      const indexFraction = i / sortedTackles?.length;
      heatmapY = indexFraction * FIELD_Y_YARDS_RELATIVEY;
    }

    return {
      heatmapX,
      heatmapY,
    };
  });
  return formattedTackles;
};

/* Hence the headers to convert label value in table tiles are: */
const TACKLE_TABLE_HEADERS = [
  { label: 'Metric', key: 'label' },
  { label: 'Team Value', key: 'value' },
];

// temporarily disabled for lint until API solved
// const countSacks = (total, tackleDatum) =>
//   tackleDatum.play.qbSacked ? total + 1 : total;

const TACKLE_DISTRO_HEADERS = [
  { label: 'Metric', key: 'label' },
  { label: TACKLING_KEY_METRICS.firstAttempt, key: 'tackleAttempt' },
  { label: TACKLING_KEY_METRICS.delta, key: 'deltaYards' },
  { label: TACKLING_KEY_METRICS.finalBall, key: 'finalBall' },
];

/* Create table data */
const getTackleMeans = (tackleData, tackleDataLA) => {
  /* Averages */
  const firstTackle = meanBy(tackleData, 'firstAttemptXLOS');
  const firstTackleLeagueAverage = getLAMean(
    tackleDataLA,
    'first_ta_x_los_count'
  );
  const finalLocation = meanBy(tackleData, 'finalBallXLOS');
  const finalLocationLeagueAverage = getLAMean(
    tackleDataLA,
    'final_x_los_count'
  );
  const yardsGained = meanBy(tackleData, 'finalBallXTA');
  const yardsGainedLeagueAverage = getLAMean(tackleDataLA, 'final_x_ta_count');

  return {
    firstTackle,
    firstTackleLeagueAverage,
    finalLocation,
    finalLocationLeagueAverage,
    yardsGained,
    yardsGainedLeagueAverage,
  };
};

const niceRunOutcomeName = function (tacklePlay) {
  if (tacklePlay.play.touchdownWon) {
    return 'Touchdown';
  }
  if (tacklePlay.play.fumbled) {
    return 'Fumble';
  }
  return 'Tackled';
};

/* Showing multiple info inside a single table cell */
const formatDefenders = function (defenders) {
  const defenderNames = defenders.map((d) => {
    let info = d.name;
    info += d.soloAttempt ? ', Solo Attempt ->' : ', Assist Attempt ->';
    info += d.soloTackle ? 'Solo ' : 'Assist ';
    info += d.success ? 'Success' : 'Missed';
    return info;
  });
  return join(defenderNames, '\n');
};

/* Create table data */
const getPlayTableData = (tackleData, selectedPlay) => {
  if (!tackleData || !selectedPlay || tackleData?.length === 0) {
    return { selectedPlayData: null, href: null };
  }

  const tacklePlay = tackleData.find((t) => t.play.id === selectedPlay);
  const href =
    `/game/animation/:leagues/:seasons/:teams/${tacklePlay?.play?.game?.id}` +
    `/${tacklePlay?.play?.drive?.id}/${tacklePlay?.play?.id}`;
  const tackleRows = [];
  const trf = (label, value) => ({ label, value });
  if (tacklePlay) {
    tackleRows.push(trf('Game', tacklePlay?.play?.game?.name));
    tackleRows.push(trf('Drive', tacklePlay?.play?.drive?.name));
    tackleRows.push(trf('Play', tacklePlay?.play?.name));

    tackleRows.push(trf('Snap', getYardlineName(tacklePlay?.play?.yardLine)));
    tackleRows.push(trf('Ball Carrier', tacklePlay?.ballCarrier?.name));
    tackleRows.push(
      trf(
        `${TACKLING_KEY_METRICS.firstAttempt}:`,
        `${tacklePlay?.firstAttemptX?.toFixed(
          1
        )}, ${tacklePlay?.firstAttemptY?.toFixed(1)}`
      )
    );
    tackleRows.push(
      trf(
        `${TACKLING_KEY_METRICS.delta}:`,
        tacklePlay?.finalBallXTA?.toFixed(1)
      )
    );
    tackleRows.push(
      trf(
        `${TACKLING_KEY_METRICS.finalBall}:`,
        `${tacklePlay?.finalBallX?.toFixed(
          0
        )}, ${tacklePlay?.finalBallY?.toFixed(0)}`
      )
    );
    tackleRows.push(trf('Run Outcome:', niceRunOutcomeName(tacklePlay)));
    tackleRows.push(trf('Tacklers:', formatDefenders(tacklePlay?.defenders)));
  }
  return { selectedPlayData: tackleRows, href };
};

const getDistroData = (data, taDomain, fieldXYds) => {
  const getTackleAttemptValue = (d) => d?.firstAttemptXLOS;
  const getFinalLocationValue = (d) => d?.finalBallXLOS;
  const getDeltaYardsValue = (d) => d?.finalBallXTA;
  /* Get quartile ranges for each metric */
  const firstTackleQuartiles = getQuartiles(data, getTackleAttemptValue);
  const finalBallQuartiles = getQuartiles(data, getFinalLocationValue);
  const deltaYardsQuartiles = getQuartiles(data, getDeltaYardsValue);
  /* Generate Histogram data for each metric */

  const firstTackleHistoData = getBinnedData(
    data,
    getTackleAttemptValue,
    taDomain,
    fieldXYds
  );
  const finalBallHistoData = getBinnedData(
    data,
    getFinalLocationValue,
    taDomain,
    fieldXYds
  );
  const deltaYardsHistoData = getBinnedData(
    data,
    getDeltaYardsValue,
    taDomain,
    fieldXYds
  );

  /* Work out the largest frequency of any bin across all metrics
  Use this to get a differently normalized set of data (for dots) */
  const maxOfMaxes = max([
    firstTackleHistoData[0].maxCount,
    finalBallHistoData[0].maxCount,
    deltaYardsHistoData[0].maxCount,
  ]);

  const firstTackleData = addBucketDots(firstTackleHistoData, maxOfMaxes);
  const finalBallData = addBucketDots(finalBallHistoData, maxOfMaxes);
  const deltaYardsData = addBucketDots(deltaYardsHistoData, maxOfMaxes);

  return {
    firstTackleQuartiles,
    finalBallQuartiles,
    deltaYardsQuartiles,
    maxOfMaxes,
    firstTackleData,
    finalBallData,
    deltaYardsData,
    getDistroData,
  };
};

export const splitCoordinateMode = (coordinateMode) => {
  const displayModes = {
    displayXMode: TACKLE_LOCATION_X_MODE_LOS.value,
    displayYMode: TACKLE_LOCATION_Y_MODE_FIELD.value,
  };
  if (coordinateMode === TACKLING_COORDINATE_SNAP.value) {
    displayModes.displayYMode = TACKLE_LOCATION_Y_MODE_SNAP.value;
  }
  if (coordinateMode === TACKLING_COORDINATE_FIRST_TACKLE.value) {
    displayModes.displayXMode = TACKLE_LOCATION_X_MODE_TAORIGIN.value;
    displayModes.displayYMode = TACKLE_LOCATION_Y_MODE_TA.value;
  }
  return displayModes;
};

export {
  getFieldSize,
  getRotationSettings,
  formatTackleData,
  formatHeatmapData,
  TACKLE_TABLE_HEADERS,
  getPlayTableData,
  getLAMean,
  getTackleMeans,
  addTackleDerivedValues,
  TACKLE_DISTRO_HEADERS,
  addPlayerColor,
  getDistroData,
  getSelectablePlayerList,
  getPlayerTacklePlays,
};
