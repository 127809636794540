import { styled } from 'styled-components';

export const StyledPageFilters = styled.div`
  position: fixed;
  top: 98px;
  left: 0;
  width: 100vw;
  z-index: 99;
  display: flex;
  align-items: center;
  height: 52px;
  padding-left: 74px; /* 58 + 20 */
  box-shadow: ${({ theme }) => theme.effects.dropShadow.uber};
  padding-bottom: 6px;
  grid-gap: 20px;

  ${({ $inline }) =>
    $inline &&
    `
    position: relative;
    width: auto;
    top: 0;
    box-shadow: none;
    height: fit-content;
    padding: 0;
    z-index: auto;
  `}

  .sub-header-label {
    font-size: 12px;
    font-weight: 600;
    padding-right: 14px;
    flex-direction: row;
    display: flex;
    line-height: 1.6;
  }

  .filter-icon {
    padding-right: 6px;
    width: 20px;
    top: -1px;
    position: relative;
    line-height: 1.4;
  }

  .filter {
    min-width: ${({ $wider }) => ($wider ? '550px' : '450px')};
  }
`;

StyledPageFilters.name = 'StyledPageFilters';
