import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { get } from 'lodash';
import { ERROR_CODES } from '../errorCodes/errorCodes';

const maxResultsPerQuery = 1000;

/**
 * Custom hook for querying large amount of results.
 *
 * The amount of items returned by the API are maxed out at 1000 per query and there are some scenarios
 * where we would like to get a higher amount of results. This hook creates and executes multiple queries
 * depending on the amount of results we are expecting, and batches those paginated results together
 */
export const useGetFullResults = (
  query,
  variables,
  totalCount,
  resultPath,
  context
) => {
  const client = useApolloClient();

  const [shouldFetch, setShouldFetch] = useState(false);
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const start = () => setShouldFetch(true);

  const reset = () => {
    setData(undefined);
    setLoading(false);
    setError(undefined);
  };

  const fetchFullResults = async () => {
    setLoading(true);

    // create an array of queries for each expected batch
    const items = [...new Array(Math.ceil(totalCount / maxResultsPerQuery))];
    const queries = items.map((_, index) =>
      client.query({
        query,
        variables: {
          ...variables,
          offset: index * maxResultsPerQuery,
        },
        context,
      })
    );

    // execute all the queries
    const fullResults = await Promise.all(queries)
      .then((results) => {
        setData(results.map((result) => get(result, resultPath)).flat());
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });

    return fullResults;
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchFullResults();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  if (loading) {
    return { loading, start, reset };
  }

  if (error) {
    console.error(error, ERROR_CODES.GET_FULL_RESULTS);
    return { loading, error, start, reset };
  }

  return { data, loading, error, start, reset };
};
