// each context filter and its blacklist of pages where it should be disabled
export const CONTEXT_FILTERS_CONFIG = {
  GAMES: ['/league/', '/game/'],
  DOWN_AND_DISTANCE: ['/impact/'],
  FIELD_POSITION: ['/impact/'],
  TIME: ['/impact/'],
  PLAY: ['/impact/'],
  PERSONNEL: ['/depth/', '/impact/', '/summary', '/detail'],
  FORMATIONS: ['/impact/', '/summary', '/detail'],
};

export const OFFENSE_PACKAGES_CONFIG = [
  { value: ['P10'], label: '10', column: 1 },
  { value: ['P11'], label: '11', column: 1 },
  { value: ['P12'], label: '12', column: 1 },
  { value: ['P20'], label: '20', column: 2 },
  { value: ['P21'], label: '21', column: 2 },
  {
    value: [
      'P00',
      'P01',
      'P02',
      'P03',
      'P04',
      'P05',
      'P13',
      'P14',
      'P22',
      'P23',
      'P30',
      'P31',
      'P32',
      'P40',
      'P41',
      'P50',
      'POTHER',
    ],
    label: 'Other',
    column: 3,
  },
];

export const DEFENSE_PACKAGES_CONFIG = [
  { value: ['P2_3_6'], label: '2-3-6', column: 1 },
  { value: ['P2_4_5'], label: '2-4-5', column: 1 },
  { value: ['P3_2_6'], label: '3-2-6', column: 2 },
  { value: ['P3_3_5'], label: '3-3-5', column: 2 },
  { value: ['P3_4'], label: '3-4', column: 2 },
  { value: ['P4_1_6'], label: '4-1-6', column: 3 },
  { value: ['P4_2_5'], label: '4-2-5', column: 3 },
  { value: ['P4_3'], label: '4-3', column: 3 },
  { value: ['P5_2'], label: '5-2', column: 4 },
  {
    value: [
      'P0_0_11',
      'P0_1_10',
      'P0_2_9',
      'P0_3_8',
      'P0_4_7',
      'P0_5_6',
      'P0_6_5',
      'P0_7_4',
      'P0_8_3',
      'P0_9_2',
      'P0_10_1',
      'P0_11_0',
      'P1_0_10',
      'P1_1_9',
      'P1_2_8',
      'P1_3_7',
      'P1_4_6',
      'P1_5_5',
      'P1_6_4',
      'P1_7_3',
      'P1_8_2',
      'P1_9_1',
      'P1_10_0',
      'P2_0_9',
      'P2_1_8',
      'P2_2_7',
      'P2_5_4',
      'P2_6_3',
      'P2_7_2',
      'P2_8_1',
      'P2_9_0',
      'P3_0_8',
      'P3_1_7',
      'P3_5_3',
      'P3_6_2',
      'P3_7_1',
      'P3_8_0',
      'P4_0_7',
      'P4_4_3',
      'P4_5_2',
      'P4_6_1',
      'P4_7_0',
      'P5_0_6',
      'P5_1_5',
      'P5_3_3',
      'P5_4_2',
      'P5_5_1',
      'P5_6_0',
      'POTHER',
    ],
    label: 'Other',
    column: 5,
  },
];
