import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import { COLLEGE_CLASS } from '../../ScoutPlayers/ScoutPlayers.constants';
import { BiometricDropdownWrapper } from '../BiometricFilters.styles';

const CollegeClassMultiselect = ({
  selectedBiometrics,
  setSelectedBiometrics,
}) => {
  const collegeClassFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.collegeClass.key
  );
  const currentCollegeClasses = isEmpty(collegeClassFilter?.value)
    ? []
    : [...collegeClassFilter.value];

  const collegeClassOptions = Object.values(COLLEGE_CLASS).map((cc) => ({
    value: cc.apiCode,
    label: cc.name,
  }));

  const handleCollegeClassSelection = (selectedCollegeClasses) => {
    const selectedClassCodes = selectedCollegeClasses?.map((m) => m.value);
    const otherBiometrics = selectedBiometrics.filter(
      (b) => b.key !== SCOUT_BIOMETRIC_FILTERS.collegeClass.key
    );
    if (isEmpty(selectedClassCodes)) {
      setSelectedBiometrics(otherBiometrics);
    } else {
      const newFilter = {
        key: SCOUT_BIOMETRIC_FILTERS.collegeClass.key,
        value: selectedClassCodes,
      };
      setSelectedBiometrics(otherBiometrics.concat(newFilter));
    }
  };

  const dropDownValue = currentCollegeClasses.map((c) => ({
    value: c,
    label: COLLEGE_CLASS[c].name,
  }));

  return (
    <BiometricDropdownWrapper>
      <Dropdown
        label="College class"
        id="scout-biometrics-class-select"
        options={collegeClassOptions}
        onChange={handleCollegeClassSelection}
        value={dropDownValue}
        isMulti
        size="small"
      />
    </BiometricDropdownWrapper>
  );
};
CollegeClassMultiselect.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default CollegeClassMultiselect;
