import { gql } from '@apollo/client';

export const GET_TEAM_SCHEDULE = gql`
  query GetTeamSchedule(
    $teamId: [Int!]
    $competitionId: Int!
    $seasonId: Int!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    teamSchedule(
      filters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: $teamId
      }
    ) {
      game {
        id
        name
        homeTeam {
          id
          name
          shortName
          conferenceShortName
        }
        awayTeam {
          id
          name
          shortName
          conferenceShortName
        }
        homeScore
        awayScore
        roundShortName
        roundName
        date
        competitionSeasonName
        phaseName
        kickoff
        localTimezone
      }
      gameAvailable
      homeEpaPerDrive
      awayEpaPerDrive
    }
  }
`;
