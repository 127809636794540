import {
  DEFAULT_FIELD_DRAWING_SETTINGS,
  FIELD_MEASURES,
} from './field.constants';
import {
  addOddEvenZones,
  addFieldBorder,
  addCompetitionHashMarks,
  addFieldEdgeMarks,
} from './field.drawing';
import {
  LOS_FIELD_DRAWING_DEFAULTS,
  RELATIVE_Y_FIELD_MEASURES,
} from './fieldVariants.constants';
import {
  drawLOS,
  addEdgeNumbersHorizontal,
  addEdgeNumbersVertical,
  addHorizontalGuides,
} from './fieldVariants.drawing';

const addLoSField = function (svg, fieldSettingOverrides = {}) {
  /* Make sure we have all the field settings for drawing functions */
  const fieldSettings = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    ...LOS_FIELD_DRAWING_DEFAULTS,
    ...fieldSettingOverrides,
  };

  /* create a G element for the field aspects to all sit in 
  id for finding in browser when needed, and class if overriding styles via css
  */
  const fieldArea = svg
    .append('g')
    .attr('id', 'field-area')
    .attr('class', 'amf-field');

  const borderedFieldHeightYds =
    fieldSettings.Y_BORDERS * 2 + FIELD_MEASURES.Y_YARDS;

  /* Draw the oscillating 10yd zones */
  const tenYards = 10;
  const tenYdZones = fieldSettings.X_YARDS / tenYards;
  fieldArea.call(
    addOddEvenZones,
    fieldSettings,
    borderedFieldHeightYds,
    tenYdZones
  );

  /* Add a layer adjusted by border padding so can show Out of Bounds */
  const borderAdjusted = fieldArea
    .append('g')
    .attr('id', 'border-shift')
    .attr(
      'transform',
      `translate(0,${fieldSettings.Y_BORDERS * fieldSettings.pxPerYard})`
    );

  /* Add regular field hashes */
  borderAdjusted.call(
    addCompetitionHashMarks,
    fieldSettings,
    fieldSettings.X_YARDS
  );
  borderAdjusted.call(addFieldEdgeMarks, fieldSettings, fieldSettings.X_YARDS);

  /* Draw the border for the field if there are borders
    Full outline (including y-borders occurs below)
  */
  if (fieldSettings.Y_BORDERS > 0) {
    borderAdjusted.call(
      addFieldBorder,
      fieldSettings,
      FIELD_MEASURES.Y_YARDS,
      fieldSettings.X_YARDS
    );
  }
  /* Draw the Line of Scrimmage (extends into border) */
  fieldArea.call(drawLOS, fieldSettings, borderedFieldHeightYds);
  /* Draw the overall border (including padding zones) */
  fieldArea.call(
    addFieldBorder,
    fieldSettings,
    borderedFieldHeightYds,
    fieldSettings.X_YARDS
  );

  /* Add edge numbers for x-yardlines relative to LoS */
  const losTenYardIndex = fieldSettings.LOS_X / tenYards - 1;
  fieldArea.call(
    addEdgeNumbersHorizontal,
    fieldSettings,
    borderedFieldHeightYds,
    tenYdZones - 1, // don't label the edge of the vis
    fieldSettings.orientation,
    losTenYardIndex
  );
};

const addLoSFieldYRelative = function (svg, fieldSettingOverrides = {}) {
  /* Make sure we have all the field settings for drawing functions */
  const fieldSettings = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    ...LOS_FIELD_DRAWING_DEFAULTS,
    ...fieldSettingOverrides,
  };

  /* create a G element for the field aspects to all sit in 
  id for finding in browser when needed, and class if overriding styles via css
  */
  const fieldArea = svg
    .append('g')
    .attr('id', 'field-area')
    .attr('class', 'amf-field');

  const fieldWidth = fieldSettings.X_YARDS * fieldSettings.pxPerYard;
  const tenYards = 10;
  const tenYdZones = fieldSettings.X_YARDS / tenYards;
  fieldArea.call(
    addOddEvenZones,
    fieldSettings,
    RELATIVE_Y_FIELD_MEASURES.Y_YARDS,
    tenYdZones
  );

  /* Add only the edge hashes (comp level ones make no sense relative to Y) */
  fieldArea.call(
    addFieldEdgeMarks,
    fieldSettings,
    fieldSettings.X_YARDS,
    RELATIVE_Y_FIELD_MEASURES.Y_YARDS
  );

  /* Add markings for LoS */
  fieldArea.call(drawLOS, fieldSettings, RELATIVE_Y_FIELD_MEASURES.Y_YARDS);

  /* Add Los relevant x-yd numbers
  Always orientate for the viewer
  */
  const losTenYardIndex = fieldSettings.LOS_X / tenYards - 1; // 0 based indexes
  fieldArea.call(
    addEdgeNumbersHorizontal,
    fieldSettings,
    RELATIVE_Y_FIELD_MEASURES.Y_YARDS,
    tenYdZones - 1, // don't label the edge of the vis
    fieldSettings.orientation,
    losTenYardIndex
  );

  if (!fieldSettingOverrides?.hideYGuides) {
    /* Add relevant to center y markings
      Always orientate for the viewer
    */
    fieldArea.call(addEdgeNumbersVertical, fieldSettings);
    /* Add markings for dY levels (ten yards) matching axis numbers */
    fieldArea.call(addHorizontalGuides, fieldSettings, fieldWidth);
  }

  /* Lastly (on top) Draw the overall border */
  fieldArea.call(
    addFieldBorder,
    fieldSettings,
    RELATIVE_Y_FIELD_MEASURES.Y_YARDS,
    fieldSettings.X_YARDS
  );
};

export { addLoSField, addLoSFieldYRelative };
