import { useParams } from 'react-router-dom';
import { groupBy as _groupBy, orderBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { GET_DEPTH_CHART_PLAYERS } from './getDepthChartPlayers';
import {
  ALL_PACKAGES_OPTION,
  ALL_POSITIONS_MAP,
  OFFENSIVE_LINEMAN_POSITIONS,
} from './TeamDepthChart.constants';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';
import { getDepthChartTeam } from './getDepthChartTeam';
import { getRadarPlayersStats } from './getRadarPlayersStats';
import { useGetTemplateDefinitions } from '../../player/PlayerRadar/PlayerRadar.hooks';
import { formatTemplateStatsAsRadarAxes } from '../../player/PlayerRadar/PlayerRadar.dataManipulation';

// A hook to get all the depth chart team related data.
// We don't want the team data to change when the user selects a package which
// is why we need a separate query for the packages data and team snaps
export const useDepthChartTeam = (offense, teamType) => {
  // the team data query is slightly different between offense and defense personnel
  const GET_DEPTH_CHART_TEAM = getDepthChartTeam(teamType);

  const { data, loading, error } = useQuery(GET_DEPTH_CHART_TEAM, {
    variables: {
      offense,
    },
  });

  if (loading) {
    return { depthChartTeamLoading: true };
  }

  if (error) {
    return { depthChartTeamLoading: false, depthChartTeamError: error };
  }

  return { depthChartTeamLoading: false, depthChartTeamData: data.depthChart };
};

const getPersonnel = (selectedPackage, offense) => {
  if (selectedPackage === ALL_PACKAGES_OPTION.value) {
    return null;
  }
  return {
    offensePackage: offense ? [selectedPackage] : null,
    defensePackage: offense ? null : [selectedPackage],
  };
};

// hook to get all the depth chart players related data and format it.
// We need 2 queries because SMEs want some alignment positions to appear in roster mode
export const useDepthChartPlayers = (
  offense,
  groupByAlignment,
  selectedPackage
) => {
  const { leagues, seasons, teams } = useParams();
  // get the tables priority order based on the combination of selected team and position types
  const personnel = getPersonnel(selectedPackage, offense);

  const {
    data: alignmentPositionsData,
    error: alignmentPositionsError,
    loading: alignmentPositionsLoading,
  } = useQuery(GET_DEPTH_CHART_PLAYERS, {
    variables: {
      offense,
      groupByAlignment: true,
      personnel,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: rosterPositionsData,
    error: rosterPositionsError,
    loading: rosterPositionsLoading,
  } = useQuery(GET_DEPTH_CHART_PLAYERS, {
    // we don't need the roster query if we group by alignment
    skip: groupByAlignment,
    variables: {
      offense,
      groupByAlignment: false,
      personnel,
    },
    notifyOnNetworkStatusChange: true,
  });

  const alignmentData = alignmentPositionsData?.depthChart.players || [];
  const rosterData = rosterPositionsData?.depthChart.players || [];
  // when in roster mode, we only want to add the offensive lineman alignment positions
  const filteredAlignmentData = groupByAlignment
    ? alignmentData
    : alignmentData.filter((player) =>
        OFFENSIVE_LINEMAN_POSITIONS.includes(player.alignmentPosition)
      );
  // filter out offensive lineman because we specifically display them broken down by alignment
  const filteredRosterData = rosterData.filter(
    (player) =>
      player.rosterPosition !== API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN
  );
  const combinedData = [...filteredAlignmentData, ...filteredRosterData];
  // when in roster mode, we need to make sure all of our players have a rosterPosition value
  const formattedData = groupByAlignment
    ? combinedData
    : combinedData.map((player) => ({
        ...player,
        rosterPosition: player.rosterPosition || player.alignmentPosition,
      }));

  // filter out incomplete player results and group the rest by the appropriate position type
  const groupBy = groupByAlignment ? 'alignmentPosition' : 'rosterPosition';
  const sanitizedData = formattedData.filter((player) => player[groupBy]);
  const groupedData = _groupBy(sanitizedData, groupBy);

  const tablesData = Object.entries(groupedData).map(([group, players]) => {
    // order the results in each table
    const sortedData = orderBy(
      players,
      ['snapCount'],
      [SORT_DIRECTIONS.DESCENDING]
    );

    return {
      position: group,
      order: ALL_POSITIONS_MAP[group].displayOrder,
      data: sortedData.map((player) => ({
        id: player.player.id,
        name: `${player.jerseyNumber} - ${player.player.name}`,
        snapCount: player.snapCount,
        snapRate: (player?.snapRate * 100).toFixed(1),
        usageRate: (player?.usageRate * 100)?.toFixed(1),
        lateDownsRate: (player?.lateDownsRate * 100)?.toFixed(1),
        opacity: player.snapRate,
        link: `/player/overview/${leagues}/${seasons}/${teams}/${player.player.id}`,
      })),
    };
  });

  const error = alignmentPositionsError || rosterPositionsError;

  if (alignmentPositionsLoading || rosterPositionsLoading) {
    return { loading: true };
  }

  if (error) {
    return { loading: false, error };
  }

  return { loading: false, data: tablesData };
};

export const useDepthChartRadars = (templatePosition, playerIds) => {
  const {
    templates,
    loading: templatesLoading,
    error: templatesError,
  } = useGetTemplateDefinitions();

  const selectedTemplate = templates?.find(
    (t) =>
      t.defaultFor.includes(templatePosition) ||
      t.specificPositions.includes(templatePosition)
  );
  const templateStatsNames = selectedTemplate?.stats.map((m) => m.name) || [];

  const templateConfig = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.stats
  );

  const GET_RADAR_PLAYER_STATS = getRadarPlayersStats(templateStatsNames);
  const {
    data: playerStats,
    loading: playerStatsLoading,
    error: playerStatsError,
  } = useQuery(GET_RADAR_PLAYER_STATS, {
    skip: !templatePosition || !playerIds.length || !templateStatsNames.length,
    variables: {
      playerIds,
    },
  });

  const radarsLoading = templatesLoading || playerStatsLoading;
  const radarsError = templatesError || playerStatsError;
  const radarsData = playerStats?.playerStats.items || [];

  if (radarsLoading) {
    return { radarsLoading: true };
  }

  if (radarsError) {
    return { radarsLoading: false, radarsError };
  }

  return { radarsLoading: false, radarsData, templateConfig };
};
