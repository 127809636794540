import React from 'react';
import { Dropdown } from '@statsbomb/kitbag-components';
import PropTypes from 'prop-types';
import {
  RUN_POSITION_NAMES,
  RUN_TENDENCY_POSITION_KEYS,
} from '../RunTendencies.constants';
import { OffensiveLinePositionDropdown } from './offensePlayerFilters.styles';
import {
  selectedPlayersShape,
  playersListsShape,
} from '../RunTendencies.propTypes';

const PlayerDropdown = function ({
  playersLists,
  selectedPlayers,
  setSelectedPlayers,
  positionKey,
}) {
  const positionPlayerList = playersLists[positionKey];
  return (
    <OffensiveLinePositionDropdown>
      <Dropdown
        id={`ol-filter-${positionKey}`}
        options={positionPlayerList}
        label={RUN_POSITION_NAMES[positionKey]}
        onChange={(selectedOption) => {
          const newSelectedPlayers = {
            ...selectedPlayers,
            [positionKey]: selectedOption.value,
          };
          setSelectedPlayers(newSelectedPlayers);
        }}
        value={
          positionPlayerList.filter(
            (f) => f.value === selectedPlayers[positionKey]
          )[0]
        }
      />
    </OffensiveLinePositionDropdown>
  );
};

PlayerDropdown.propTypes = {
  playersLists: playersListsShape.isRequired,
  selectedPlayers: selectedPlayersShape.isRequired,
  setSelectedPlayers: PropTypes.func.isRequired,
  positionKey: PropTypes.oneOf(Object.values(RUN_TENDENCY_POSITION_KEYS))
    .isRequired,
};

export { PlayerDropdown };
