import { useQuery } from '@apollo/client';
import { GET_PLAYER_STATS_CATEGORIES } from './apiQueries/playerStats/getPlayerStatsCategories';
import { ERROR_CODES } from '../errorCodes/errorCodes';

// gets the player stats category definitions
export const useGetPlayerStatsCategoryDefinitions = () => {
  const { data, loading, error } = useQuery(GET_PLAYER_STATS_CATEGORIES);

  if (loading) {
    return { isLoading: loading };
  }

  if (error) {
    console.error(
      ERROR_CODES.USE_GET_PLAYER_STATS_CATEGORIES_DEFINITIONS,
      error
    );
    return {
      isLoading: false,
      categoryDefinitionsError: error,
    };
  }

  return {
    isLoading: false,
    categoryDefinitions: data.playerStatCategories,
  };
};
