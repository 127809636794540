import { styled } from 'styled-components';
import { Dimmer } from 'semantic-ui-react';

export const StyledDimmer = styled(Dimmer)`
  &.ui.dimmer {
    display: flex;
    opacity: 1;
    z-index: 98;
    background-color: ${({ theme }) =>
      theme.applyOpacity(
        theme.colours.canvas.secondary.weak,
        'overlay'
      )} !important;

    .text,
    .content {
      color: ${({ theme }) => theme.colours.ink.primary.main} !important;
    }

    &.inverted {
      background-color: ${({ theme }) =>
        theme.applyOpacity(
          theme.colours.canvas.secondary.strong,
          'overlay'
        )} !important;
    }

    .ui.loader:before {
      border-color: ${({ theme }) => theme.colours.canvas.primary.strong};
    }
  }
`;
