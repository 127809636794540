import React from 'react';
import { Grid, Breadcrumb, Message, Loader } from 'semantic-ui-react';
import { Toggle } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { sortBy } from 'lodash';
import Tile from '../../../components/Tile/Tile';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import {
  FixedAside,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import BasicTable from '../../../components/Table/BasicTable';
import {
  mf_Leagues,
  mf_Seasons,
  mf_TeamDetails,
  mf_Teams,
} from '../../../apollo';
import useQueryString from '../../../utils/hooks/useQueryString';
import { scheduleHeaders } from './TeamSchedule.constants';
import { getResult, parseScheduleScore } from './TeamSchedule.helpers';
import Dimmer from '../../../components/Dimmer/Dimmer';
import GoTo from '../../../components/buttons/GoTo';
import { GET_TEAM_SCHEDULE } from './getTeamSchedule';
import { formatValue } from '../../League/stats/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { ScheduleTable } from './TeamSchedule.styles';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';

const TeamSchedule = () => {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const mf_TeamsRV = useReactiveVar(mf_Teams);
  const mf_SeasonsRV = useReactiveVar(mf_Seasons);
  const mf_LeaguesRV = useReactiveVar(mf_Leagues);
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const teamName = mf_TeamDetailsRV?.name;
  const [hideFutureGames, setHideFutureGames] = useQueryString('future', false);
  const [hideCollected, setHideCollected] = useQueryString('collected', false);

  const {
    data: teamScheduleData,
    loading: isLoading,
    error: hasError,
  } = useQuery(GET_TEAM_SCHEDULE);
  if (hasError) {
    console.error(ERROR_CODES.GET_TEAM_SCHEDULE, hasError);
  }

  const parsedSchedule = teamScheduleData?.teamSchedule?.map((week) => {
    const isHomeTeam = week.game.homeTeam?.id === mf_TeamsRV;

    return {
      date: week.game.date,
      week: week.game.roundShortName,
      kickoff: week.game.kickoff?.slice(0, -3), // trim seconds
      conference:
        week.game.homeTeam.conferenceShortName ===
        week.game.awayTeam.conferenceShortName
          ? 'conf'
          : 'non-conf',
      result: getResult(
        week.game.homeTeam?.id,
        mf_TeamsRV,
        week.game.homeScore,
        week.game.awayScore,
        week.gameAvailable,
        visPalette
      ),
      isAway: isHomeTeam ? 'vs' : '@',
      opponent: isHomeTeam
        ? week.game.awayTeam?.name
        : week.game.homeTeam?.name,
      score: isHomeTeam
        ? parseScheduleScore(week.game.homeScore, week.game.awayScore)
        : parseScheduleScore(week.game.awayScore, week.game.homeScore),
      offensiveEpaPerDrive: formatValue(
        isHomeTeam ? week.homeEpaPerDrive : week.awayEpaPerDrive,
        API_STAT_UNITS.EPA
      ),
      defensiveEpaPerDrive: formatValue(
        isHomeTeam ? week.awayEpaPerDrive : week.homeEpaPerDrive,
        API_STAT_UNITS.EPA
      ),
      disabled: !week.gameAvailable,
      link: week.gameAvailable && (
        <div style={{ textAlign: 'right' }}>
          <GoTo
            href={`/game/byplay/${mf_LeaguesRV}/${mf_SeasonsRV}/${mf_TeamsRV}/${week.game.id}`}
            linkName="Open play by play for this game"
            iconSize="small"
            ghost
          />
        </div>
      ),
    };
  });

  let sortedSchedule = sortBy(parsedSchedule, 'date');
  if (hideFutureGames) {
    const today = new Date().toISOString().split('T')[0];
    sortedSchedule = sortedSchedule.filter((week) => week.date <= today);
  }
  if (hideCollected) {
    sortedSchedule = sortedSchedule.filter((week) => week.disabled === false);
  }

  return (
    <Grid>
      <Grid.Row>
        <SidebarRightLayout $sidebarWidth="300px">
          <Tile>
            <Tile.Header>
              <Breadcrumb size="huge">
                <Breadcrumb.Section>Team</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Schedule</Breadcrumb.Section>
              </Breadcrumb>
              <h1>{teamName && `${teamName} Schedule`}</h1>
            </Tile.Header>
            <Tile.Body $padding="0">
              {hasError && !isLoading && (
                <Message negative>
                  There has been an error. Please contact support.
                </Message>
              )}
              {isLoading && (
                <Dimmer active style={{ minHeight: '30vh' }}>
                  <Loader content="Loading Data" />
                </Dimmer>
              )}
              {sortedSchedule?.length === 0 && !isLoading && !hasError && (
                <Dimmer active style={{ minHeight: '30vh' }}>
                  <p>No Data available</p>
                </Dimmer>
              )}
              {!hasError && !isLoading && parsedSchedule && (
                <ScheduleTable>
                  <BasicTable
                    data={sortedSchedule}
                    headers={scheduleHeaders}
                    withBorder={false}
                  />
                </ScheduleTable>
              )}
            </Tile.Body>
          </Tile>

          <div>
            <FixedAside $width="325px">
              <AccordionTile
                header={<Tile.AccordionHeader>Settings</Tile.AccordionHeader>}
                body={
                  <Tile.AccordionBody>
                    <Toggle
                      id="hide-future-games-toggle"
                      label="Hide future games"
                      checked={hideFutureGames}
                      onChange={() => setHideFutureGames(!hideFutureGames)}
                    />
                    <Toggle
                      id="hide-non-collected-games-toggle"
                      label="Hide non-collected games"
                      checked={hideCollected}
                      onChange={() => setHideCollected(!hideCollected)}
                    />
                  </Tile.AccordionBody>
                }
                isExpandedDefault
                isMount
              />
            </FixedAside>
          </div>
        </SidebarRightLayout>
      </Grid.Row>
    </Grid>
  );
};

export default TeamSchedule;
