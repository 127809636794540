import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const getHavocLineEvents = (isOffense) => {
  const gqlString = gql`
  query GetHavocLineEvents(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    havocEvents(
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        offenseTeamIds: [${isOffense ? '$teamId' : ''}]
        defenseTeamIds: [${!isOffense ? '$teamId' : ''}] 
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      items {
        play {
          id
          name
          drive {
            id
            name
          }
          game {
            id
            name
          }
          offenseTeam {
            id
            name
          }
          defenseTeam {
            id
            name
          }
          yardLine
          snapY
          type
          defensiveFront
          defensiveFrontNumPlayers
          blitzType
        }
        eventUUID
        eventLocationX
        eventLocationY
        eventTypes
        defender {
          id 
          name
          alignmentPosition
          teamPosition {
            code
            generalPosition
          }
          jerseyNumber
        }
        targetPlayer {
          id 
          name
          alignmentPosition
          teamPosition {
            code
            generalPosition
          }
          jerseyNumber
        }
        targetLocationX
        targetLocationY
        defenderMovementPath {
          x 
          y
        }
        defenderOrigin
        linePosition
        exploitedLineGap
        timePostSnap
        pocketRelativeLocation
      }
    }
  }
`;
  return gqlString;
};
