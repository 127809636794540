import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { getHavocLineEvents } from './getHavocLineEvents';
import { getHavocTeamStats } from './getHavocTeamStats';
import { HAVOC_TYPES } from '../../../visualisations/HavocChart/HavocChart.constants';
import { formatHavocData } from './TeamHavoc.DataManipulation';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';

export const useGetHavocEvents = (showDefensive, havocMode) => {
  const query = getHavocLineEvents(!showDefensive);
  const { data, loading, error } = useQuery(query);

  if (loading) {
    return { loading };
  }
  if (error) {
    console.error(ERROR_CODES.GET_HAVOC_EVENTS, error);
    return { error, loading };
  }

  const rawHavocData = data?.havocEvents?.items;

  if (!rawHavocData?.length) {
    return { teamHavocData: null };
  }

  const allHavocData = formatHavocData(rawHavocData, showDefensive);
  /* Get relevant types as array (can be all, pass pair, run pair, or individual) */
  const havocModeType = HAVOC_TYPES.find((h) => h.value === havocMode);
  const filterHavocTypes = isEmpty(havocModeType?.filterValues)
    ? [havocModeType.value]
    : havocModeType.filterValues;
  const modedHavocData = allHavocData.filter((h) =>
    filterHavocTypes.includes(h.havocType)
  );

  /* 
 Filter out events with offensive players ~ Turnover bug 
 When https://statsbomb.atlassian.net/browse/AM-2122 is resolved, this cleanup step can be removed
 Note: this explicitly leaves in undefined position data on the assumption it is correct
   but havoc summary information based on position explicitly removes it
 */
  const cleanHavocData = modedHavocData.filter(
    (d) =>
      d.defenderPosition === undefined ||
      d.defenderPosition?.isOffense === false
  );
  return { teamHavocData: cleanHavocData };
};

export const useGetHavocTeamStats = (showDefensive) => {
  const query = getHavocTeamStats(!showDefensive);
  const { data, loading, error } = useQuery(query, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const noPlayCount = { playCount: 0, runRate: 0.5, passPlays: 0, runPlays: 0 };
  if (loading) {
    return { loading, playSummary: noPlayCount };
  }
  if (error) {
    console.error(ERROR_CODES.GET_HAVOC_TEAM_STATS, error);
    return { error, loading, playSummary: noPlayCount };
  }

  const rawTeamStats = data?.teamStats?.items[0];
  if (!rawTeamStats) {
    return { playSummary: noPlayCount };
  }
  const { playCount, runRate } = rawTeamStats;
  const runPlays = Math.round(playCount * runRate);
  const passPlays = playCount - runPlays;
  return { playSummary: { playCount, runRate, runPlays, passPlays } };
};
