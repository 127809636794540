import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

const overrides = { playTypes: false };

export const getHavocTeamStats = (isOffense) => {
  const gqlString = gql`
    query GetTeamStats(
      $competitionId: Int!
      $seasonId: Int!
      $teamId: Int!
      ${getContextQueryVariables(overrides)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      selectedTeamId @client @export(as: "teamId")
      ${getContextFilterClientExports(overrides)}
      teamStats(
        filters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          offenseTeamIds: [${isOffense ? '$teamId' : ''}]
          defenseTeamIds: [${!isOffense ? '$teamId' : ''}]
          ${getContextFiltersQueryArguments(overrides)}
          playTypes: [PASS, RUSH]
        }
      ) {
        items {
          playCount
          runRate
        }
      }
    }
  `;

  return gqlString;
};
