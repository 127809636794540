import React from 'react';
import PropTypes from 'prop-types';
import { Button, Toggle } from '@statsbomb/kitbag-components';
import { FORMATION_MEASURES, FORMATION_MEASURES_OFF } from './DataManipulation';

function FFMeasureOptions({
  selectedMeasures,
  handleSetMeasure,
  handleSetAllMeasures,
}) {
  return (
    <>
      <div style={{ display: 'flex', gap: '0.8rem' }}>
        <Button
          size="small"
          onClick={() => handleSetAllMeasures(FORMATION_MEASURES)}
        >
          All Measures
        </Button>
        <Button
          size="small"
          onClick={() => handleSetAllMeasures(FORMATION_MEASURES_OFF)}
        >
          No Measures
        </Button>
      </div>
      <Toggle
        id="offensive-width-toggle"
        label="Offensive Width"
        onChange={() => handleSetMeasure('offensiveFormationWidth')}
        checked={selectedMeasures.offensiveFormationWidth}
      />
      <Toggle
        id="offense-line-toggle"
        label="Offensive Line"
        onChange={() => handleSetMeasure('offensiveLineWidth')}
        checked={selectedMeasures.offensiveLineWidth}
      />
      <Toggle
        id="defensive-line-toggle"
        label="Defensive Line"
        onChange={() => handleSetMeasure('defensiveFormationWidth')}
        checked={selectedMeasures.defensiveFormationWidth}
      />
      <Toggle
        id="box-players-toggle"
        label="Box Players"
        onChange={() => handleSetMeasure('boxPlayersCount')}
        checked={selectedMeasures.boxPlayersCount}
      />
      <Toggle
        id="te-splits-toggle"
        label="TE Splits"
        onChange={() => handleSetMeasure('tightEndSplits')}
        checked={selectedMeasures.tightEndSplits}
      />
      <Toggle
        id="te-depths-toggle"
        label="TE Depths"
        onChange={() => handleSetMeasure('tightEndDepths')}
        checked={selectedMeasures.tightEndDepths}
      />
      <Toggle
        id="wr-splits-toggle"
        label="WR Splits"
        onChange={() => handleSetMeasure('wideReceiverSplits')}
        checked={selectedMeasures.wideReceiverSplits}
      />
      <Toggle
        id="wr-depths-toggle"
        label="WR Depths"
        onChange={() => handleSetMeasure('wideReceiverDepths')}
        checked={selectedMeasures.wideReceiverDepths}
      />
      <Toggle
        id="db-width-toggle"
        label="DB Widths"
        onChange={() => handleSetMeasure('defensiveBackWidths')}
        checked={selectedMeasures.defensiveBackWidths}
      />
      <Toggle
        id="db-depths-toggle"
        label="DB Depths"
        onChange={() => handleSetMeasure('defensiveBackDepths')}
        checked={selectedMeasures.defensiveBackDepths}
      />
      <Toggle
        id="lb-widths-toggle"
        label="LB Widths"
        onChange={() => handleSetMeasure('linebackerWidths')}
        checked={selectedMeasures.linebackerWidths}
      />
      <Toggle
        id="lb-depths-toggle"
        label="LB Depths"
        onChange={() => handleSetMeasure('linebackerDepths')}
        checked={selectedMeasures.linebackerDepths}
      />
    </>
  );
}

FFMeasureOptions.propTypes = {
  // object listing selectedMeasures (each a boolean)
  selectedMeasures: PropTypes.shape({
    offensiveLineWidth: PropTypes.bool,
    offensiveFormationWidth: PropTypes.bool,
    tightEndSplits: PropTypes.bool,
    tightEndDepths: PropTypes.bool,
    defensiveBackDepths: PropTypes.bool,
    linebackerDepths: PropTypes.bool,
    defensiveFormationWidth: PropTypes.bool,
    boxPlayersCount: PropTypes.bool,
    wideReceiverSplits: PropTypes.bool,
    wideReceiverDepths: PropTypes.bool,
    defensiveBackWidths: PropTypes.bool,
    linebackerWidths: PropTypes.bool,
  }),
  // takes a selectedMeasure name and toggles the boolean value
  handleSetMeasure: PropTypes.func,
  // takes a whole selectedMeasures obj and sets it
  handleSetAllMeasures: PropTypes.func,
};

FFMeasureOptions.defaultProps = {
  selectedMeasures: FORMATION_MEASURES,
  handleSetMeasure: null,
  handleSetAllMeasures: null,
};

export default FFMeasureOptions;
