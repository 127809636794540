/*
Makes sure that id, radius and color are given values if left blank
    scatterDatumPropTypes definition makes xValue and yValue required
*/
export const makeScatterDataSafe = (scatterData, visPalette) => {
  if (!scatterData?.length > 0) {
    return [];
  }
  const safeData = scatterData.map((datum, i) => {
    const id = datum?.id || i;
    const rValue =
      datum?.rValue === null || datum?.rValue === undefined ? 0 : datum?.rValue;
    const fill = datum?.fill || visPalette.objects.n1.main;
    const stroke = datum?.stroke || 'transparent';
    const safeDatum = {
      id,
      xValue: datum.xValue,
      yValue: datum.yValue,
      rValue,
      fill,
      stroke,
    };
    if (datum?.title?.length > 0) {
      safeDatum.title = datum.title;
    }
    return safeDatum;
  });
  return safeData;
};
