import { gql } from '@apollo/client';

export const GET_PLAY_FINDER_TEAMS = gql`
  query GetTeams($competitionId: Int!, $seasonId: Int!) {
    teams(
      first: 400
      filters: { competitionId: $competitionId, seasonId: $seasonId }
    ) {
      edges {
        node {
          id
          name
          shortName
          colorPrimary
          colorSecondary
          colorTertiary
          gameCount
        }
      }
    }
  }
`;
