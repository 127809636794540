import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import {
  StyledDepthChartRadar,
  DepthChartRadarHeader,
  DepthChartRadarFooter,
} from './DepthChartRadar.styles';
import {
  radarTemplatePropTypes,
  teamColorPropTypes,
} from '../../../visualisations/RadarChart/RadarChart.PropTypes';

const DepthChartRadar = ({
  id,
  name,
  link,
  snapRate,
  snapCount,
  teamColors,
  playerStats,
  jerseyNumber,
  templateConfig,
}) => (
  <StyledDepthChartRadar>
    <DepthChartRadarHeader>
      <div className="jersey">{jerseyNumber}</div>
      <div className="name">{name}</div>
      {link && (
        <Link to={link}>
          <ButtonIcon
            variant="ghost"
            size="small"
            icon="Start"
            title="open radars comparison drawer"
          >
            open radars comparison drawer
          </ButtonIcon>
        </Link>
      )}
    </DepthChartRadarHeader>
    <RadarChart
      radarId={id}
      templateConfig={templateConfig}
      radarStatDatum={playerStats}
      marginOverride={{ top: 0, right: 0, bottom: 0, left: 0 }}
      teamColors={teamColors}
      fontScaler={1.8}
    />
    <DepthChartRadarFooter>
      <div className="count">{snapCount} plays</div>
      <div className="percentage">{(snapRate * 100).toFixed()}%</div>
    </DepthChartRadarFooter>
  </StyledDepthChartRadar>
);

DepthChartRadar.propTypes = {
  /** The name of the player the radar belongs to. */
  name: PropTypes.string.isRequired,
  /** The name of the player the radar belongs to. */
  jerseyNumber: PropTypes.number.isRequired,
  /** The id of the player the radar belongs to */
  id: PropTypes.string.isRequired,
  /** url to the radar page of that player */
  link: PropTypes.string,
  /** the amount of times the player played in that formation */
  snapCount: PropTypes.number.isRequired,
  /** percentage of the amount of times a player played in that formation */
  snapRate: PropTypes.number.isRequired,
  /** an object containing color information for a team */
  teamColors: teamColorPropTypes.isRequired,
  /** the object changes with the template. The properties are the selected metrics */
  // eslint-disable-next-line react/forbid-prop-types
  playerStats: PropTypes.object,
  /** an array of objects containing the data for the each radar axis */
  templateConfig: radarTemplatePropTypes.isRequired,
};

DepthChartRadar.defaultProps = {
  playerStats: {},
  link: '',
};

export default DepthChartRadar;
