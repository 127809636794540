import PropTypes from 'prop-types';

/* 
General margin object used in visualisations
Current standard is for all margins to be 0
Note: No visualisation should ever rely on the basic margins to provide space for axis/shapes etc.
Specific guttering/space needed within each vis should be accounted for internally
*/
export const marginPropType = PropTypes.exact({
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  bottom: PropTypes.number,
});
