import { join } from 'lodash';
import { colorDot } from './SnapDistribution.drawing';

const getSVGPath = function (pathArray, fieldSettings) {
  const pathOrigin = pathArray[0];
  const pathAfter = pathArray.slice(1);
  const pathString = `M${pathOrigin.x * fieldSettings.pxPerYard} ${
    pathOrigin.y * fieldSettings.pxPerYard
  } `;
  const pathElements = pathAfter.map(
    (m) => `L${m.x * fieldSettings.pxPerYard} ${m.y * fieldSettings.pxPerYard}`
  );
  const fullPath = pathString + join(pathElements, ' ');

  return fullPath;
};

const addPaths = function (
  svgG,
  pathsData,
  overrides,
  colorMode,
  visPalette,
  selectedPlay,
  setSelectedPlay
) {
  const safePaths =
    pathsData && pathsData.filter((p) => p.path && p.path.length > 0);
  svgG
    .selectAll('path')
    .data(safePaths)
    .enter()
    .append('svg:path')
    .attr('d', (d) => getSVGPath(d.path, overrides))
    .attr('id', (d) => d.play.id)
    .attr('fill', 'transparent')
    .attr('stroke', (d) =>
      colorDot(d, colorMode, visPalette, d.play.id === selectedPlay)
    )
    .attr('class', 'route-path')
    .attr('stroke-width', '1px')
    .attr('opacity', 0.6)
    .on('click', (_, d) => setSelectedPlay(d.play.id));
};

const getSVGDot = function (pathArray, fieldSettings, isX) {
  const pathOrigin = pathArray[0];
  return isX
    ? pathOrigin.x * fieldSettings.pxPerYard
    : pathOrigin.y * fieldSettings.pxPerYard;
};

const addOriginDots = function (
  svgG,
  pathsData,
  overrides,
  colorMode,
  visPalette
) {
  svgG
    .selectAll('circle')
    .data(pathsData)
    .enter()
    .append('circle')
    .attr('cx', (d) => getSVGDot(d.path, overrides, true))
    .attr('cy', (d) => getSVGDot(d.path, overrides, false))
    .attr('r', 3)
    .attr('fill', 'transparent')
    .attr('fill', (d) => colorDot(d, colorMode, visPalette))
    .attr('stroke', 'none');
};

export { addPaths, addOriginDots };
