import React from 'react';
import PropTypes from 'prop-types';
import {
  StarRatingEmptyStar,
  StarRatingFullStar,
  StarRatingRow,
} from './StarRating.styles';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating / 20);
  const emptyStars = 5 - fullStars;

  const starsArray = [
    ...Array(fullStars).fill(1),
    ...Array(emptyStars).fill(0),
  ];

  return (
    <StarRatingRow>
      {starsArray.map((filled, index) =>
        filled === 1 ? (
          // eslint-disable-next-line react/no-array-index-key
          <StarRatingFullStar key={`star-${index}`} data-testid="full-star" />
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <StarRatingEmptyStar key={`star-${index}`} data-testid="empty-star" />
        )
      )}
    </StarRatingRow>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
