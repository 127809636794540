import {
  API_AGGREGATE_MODES,
  API_STAT_UNITS,
} from '../../../utils/constants/api';

const {
  COUNT,
  YARDS,
  POINTS,
  RATE,
  RATIO,
  PERCENTAGE,
  SECONDS,
  EPA,
  SHORT_DECIMAL,
  BOOLEAN,
  MPH,
  MPHS,
} = API_STAT_UNITS;
const { TOTAL } = API_AGGREGATE_MODES;

// takes a number and formats it to be displayed in stats tables
export const formatValue = (value, units, aggregateMode = TOTAL) => {
  const percent = (value * 100).toFixed(1);
  const ratio = parseInt((value * 100).toFixed(0), 10);

  switch (true) {
    case units === BOOLEAN && value === true:
      return `Yes`;
    case units === BOOLEAN && value === false:
      return `No`;
    case units === BOOLEAN:
      return `-`;
    case value && typeof value !== 'number':
      return value;
    case !value && value !== 0:
      return '-';
    // true 0 in percentage based stats is a special case as we don't want to display the trailing `.0`
    case units === PERCENTAGE && value === 0:
      return `${value}%`;
    case units === PERCENTAGE:
      return `${percent}%`;
    case units === RATIO:
      return `${ratio}% : ${100 - ratio}%`;
    case value === 0:
      return value.toString();
    case units === RATE:
      return value.toFixed(3);
    case units === EPA:
      return value.toFixed(2);
    case units === MPH:
      return value.toFixed(2);
    case units === MPHS:
      return value.toFixed(2);
    case units === SHORT_DECIMAL:
      return value.toFixed(1);
    case units === YARDS || units === SECONDS:
      return value.toFixed(1);
    case (units === COUNT || units === POINTS) && aggregateMode !== TOTAL:
      return value.toFixed(1);
    case (units === COUNT || units === POINTS) && aggregateMode === TOTAL:
      return value.toString();
    default:
      return value;
  }
};

// transforms stat definitions we want to display in the stat table,
// to the headers list rendered in the table
export const getStatHeaders = (statDefinitions) =>
  statDefinitions?.map((stat) => ({
    id: stat.name,
    label: stat.abbrev,
    isSortable: true,
    description: `${stat.prettyName}\n${stat.description}`,
  })) || [];

// format and return only the stat values that will display in the table.
export const formatStatValues = (stats, headerIds, statsToQuery, aggMode) =>
  stats?.map((stat) =>
    Object.entries(stat).reduce((acc, [key, value]) => {
      if (headerIds.includes(key)) {
        return {
          ...acc,
          [key]: formatValue(
            value,
            statsToQuery?.find((def) => def.name === key)?.units,
            aggMode
          ),
        };
      }
      return acc;
    }, {})
  );

// this ensures the order of the stats properties corresponds to the order of the headers,
// which prevents stats from being rendered under the wrong column
export const orderStats = (stats, headerIds) =>
  stats?.map((stat) =>
    headerIds?.reduce(
      (acc, headerId) => ({
        ...acc,
        [headerId]: stat[headerId],
      }),
      {}
    )
  );

// formats the headers list so the data can be downloaded as CSV file
export const getDownloadColumns = (headers) =>
  headers?.map((header) => ({
    key: header.id,
    label: header.label,
  }));
