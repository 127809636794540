import { join, sortBy } from 'lodash';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';
import { toLocalDateTime } from '../../../utils/helpers/formatting';

/* Selects the data from the setup used to display the list */
const promtAnswerRow = (prompt, answer) => ({ prompt, answer });
export const templateDetailRows = (template) => {
  const templateRows = [];

  /* Identifier */
  templateRows.push(promtAnswerRow('ID', template?.id));
  templateRows.push(promtAnswerRow('Name', template?.name));

  /* Properties */
  const rosterPositions = template.selectedPositions?.map(
    (p) => ROSTER_POSITIONS[p]
  );
  const orderedCodes = sortBy(rosterPositions, 'name').map((p) => p.code);
  const codeList = join(orderedCodes, ', ');
  templateRows.push(promtAnswerRow('Positions', codeList));
  templateRows.push(
    promtAnswerRow('Metrics', template?.selectedStats?.length || 0)
  );

  /* Edited by */
  templateRows.push(promtAnswerRow('Last Editor', template?.lastEditor));
  const editedDateTime = template?.lastModified
    ? toLocalDateTime(template?.lastModified)
    : '-';
  templateRows.push(promtAnswerRow('Edited', editedDateTime));

  /* Origin */
  templateRows.push(promtAnswerRow('Made By', template?.creator));
  const createdDateTime = template?.created
    ? toLocalDateTime(template?.created)
    : '-';
  templateRows.push(promtAnswerRow('Made', createdDateTime));

  return templateRows;
};
