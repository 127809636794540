import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { ButtonIcon, Icon } from '@statsbomb/kitbag-components';
import {
  PlayerComparisonTableBorder,
  PlayerComparisonIndicator,
  PlayerComparisonTitleColumn,
  PlayerComparisonTopHeader,
  PlayerComparisonScrollAreaStickyHeader,
  PlayerComparisonPlayerHeader,
  PlayerComparisonNameBlock,
  PlayerComparisonIconHolder,
  PlayerComparisonSearchHolder,
} from './PlayerComparison.styles';
import BasicTable from '../../../components/Table/BasicTable';
import {
  COMPARE_SELECTED,
  COMPARE_SIMILAR,
  COMPARE_TILE_ADD,
  COMPARE_TILE_DATA,
  COMPARE_TILE_EMPTY,
  COMPARE_TILE_ERROR,
  COMPARE_TILE_HIDDEN,
  COMPARISON_DEFAULT_MIN_PLAYS,
  COMPARISON_DEFAULT_WEIGHT,
  COMPARISON_TABLE_COLS,
  COMPARISON_TABLE_HEADERS,
  COMPARISON_WEIGHT_OPTIONS,
  RADAR_DISPLAY_MODE,
} from './PlayerComparison.constants';
import { OutcomeIndicator } from '../../Game/GamePlayByPlay/PlayByPlayAccordion/PlayByPlayAccordionTileBody.styles';
import { findClosestBucket } from './PlayerComparison.dataManipulation';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import { teamDetailsPropTypes } from '../../../apollo/index.data.propTypes';
import { playerComparisonPropTypes } from './getPlayerComparison.PropTypes';
import { formatValue } from '../../League/stats/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { csValue } from '../../../utils/helpers/colorScales';
import {
  getComparativeTileState,
  getComparisonTileOpacity,
} from './PlayerComparison.helpers';
import LocalSearch from '../../../components/LocalSearch/LocalSearch';
import Tooltip from '../../../components/Tooltip/Tooltip';

const PlayerComparisonTileHeader = ({
  generalData,
  playerComparisonData,
  playerDetails,
  teamDetails,
  scrollingHeaderRef,
  comparisonMode,
  handleAdd,
  handleClose,
  handleResult,
  isInteractive,
  comparativeTileState,
  playerLinkComparison,
  displayMode,
  minPlays,
  radarWeight,
  renderData,
  comparisonPlayerIds,
}) => {
  const { isDark } = useTheme();
  const { TRAIT, PERFORMANCE } = RADAR_DISPLAY_MODE;

  const onResult = (result, index) => {
    handleResult(result, index);
  };

  const getQueryString = () => {
    let qs = '?';
    if (displayMode === PERFORMANCE) {
      qs += 'display=performance';
    }
    if (displayMode === TRAIT) {
      qs += 'display=trait';
    }
    if (minPlays !== COMPARISON_DEFAULT_MIN_PLAYS) {
      qs += `${qs.length > 1 ? '&' : ''}min=${minPlays}`;
    }
    if (radarWeight !== COMPARISON_DEFAULT_WEIGHT) {
      qs += `${qs.length > 1 ? '&' : ''}weight=${radarWeight}`;
    }
    if (playerLinkComparison) {
      qs += `${qs.length > 1 ? '&' : ''}link=true`;
    }
    return qs;
  };

  return (
    <PlayerComparisonScrollAreaStickyHeader
      style={{ position: isInteractive ? 'sticky' : 'static' }}
    >
      <div className="row">
        <PlayerComparisonTitleColumn>
          <PlayerComparisonTopHeader $opacity={1}>
            <PlayerComparisonPlayerHeader>Player</PlayerComparisonPlayerHeader>
            <BasicTable
              data={[{ statValue: 'Plays' }, { statValue: 'Similarity %' }]}
              headers={COMPARISON_TABLE_COLS}
              showHeader={false}
              bodytdHeight="2rem"
            />
          </PlayerComparisonTopHeader>
        </PlayerComparisonTitleColumn>
        <PlayerComparisonTopHeader $opacity={1}>
          <PlayerComparisonNameBlock>
            <h1>
              {isInteractive ? (
                <ParsedLink
                  href={`/player/${
                    playerLinkComparison ? 'comparison' : 'overview'
                  }/:leagues/:seasons/${teamDetails?.id}/${playerDetails?.id}`}
                >
                  {playerDetails?.name}
                </ParsedLink>
              ) : (
                playerDetails?.name
              )}
            </h1>
            <h3>
              {isInteractive ? (
                <ParsedLink
                  href={`/team/overview/:leagues/:seasons/${teamDetails?.id}`}
                >
                  {teamDetails?.name}
                </ParsedLink>
              ) : (
                teamDetails?.name
              )}
            </h3>
          </PlayerComparisonNameBlock>
          <PlayerComparisonTableBorder>
            <BasicTable
              data={[
                { statValue: generalData?.plays },
                {
                  statValue: '-',
                },
              ]}
              headers={COMPARISON_TABLE_HEADERS}
              showHeader={false}
              bodytdHeight="2rem"
            />
          </PlayerComparisonTableBorder>
        </PlayerComparisonTopHeader>
      </div>
      <div ref={scrollingHeaderRef} className="row">
        <div className="rowSub">
          {renderData &&
            playerComparisonData?.map((player, index) => {
              const tileState = getComparativeTileState(
                comparisonMode,
                playerComparisonData,
                comparativeTileState,
                index
              );
              const noData =
                tileState === COMPARE_TILE_ADD ||
                tileState === COMPARE_TILE_EMPTY;
              const showData = !noData;
              return (
                <PlayerComparisonTopHeader
                  key={`comparison-tile-header-${player?.playerId || index}`}
                  $opacity={
                    tileState !== COMPARE_TILE_ADD
                      ? getComparisonTileOpacity(tileState)
                      : 1
                  }
                  className="comparisonTile"
                >
                  <PlayerComparisonNameBlock
                    $padding={
                      comparisonMode === COMPARE_SELECTED && isInteractive
                        ? '0.5rem 2.125rem 0.5rem 0.5rem'
                        : '0.5rem'
                    }
                    $display={
                      tileState === COMPARE_TILE_DATA ? 'block' : 'none'
                    }
                  >
                    <h1>
                      {isInteractive ? (
                        <ParsedLink
                          href={`/player/${
                            playerLinkComparison ? 'comparison' : 'overview'
                          }/:leagues/:seasons/${player?.teamId}/${
                            player?.playerId
                          }${playerLinkComparison ? getQueryString() : ''}`}
                        >
                          {player?.playerName}
                        </ParsedLink>
                      ) : (
                        player?.playerName
                      )}
                    </h1>
                    <h3>
                      {isInteractive ? (
                        <ParsedLink
                          href={`/team/overview/:leagues/:seasons/${player?.teamId}`}
                        >
                          {player?.teamName}
                        </ParsedLink>
                      ) : (
                        player?.teamName
                      )}
                    </h3>
                    {comparisonMode === COMPARE_SELECTED && isInteractive && (
                      <ButtonIcon
                        onClick={() => handleClose(index, tileState)}
                        size="tiny"
                        variant="secondary"
                        icon="Close"
                        title="Remove player from comparison"
                      />
                    )}
                  </PlayerComparisonNameBlock>

                  <PlayerComparisonNameBlock
                    $padding={
                      comparisonMode === COMPARE_SELECTED
                        ? '0.5rem 2.125rem 0.5rem 0.5rem'
                        : '0.5rem'
                    }
                    $display={
                      tileState === COMPARE_TILE_HIDDEN ? 'block' : 'none'
                    }
                  />

                  <PlayerComparisonIconHolder
                    $display={tileState === COMPARE_TILE_ADD ? 'flex' : 'none'}
                  >
                    <ButtonIcon
                      onClick={() => handleAdd(index)}
                      size="small"
                      icon="PlusAdd"
                      title="Add player to comparison"
                    />
                  </PlayerComparisonIconHolder>

                  <PlayerComparisonSearchHolder
                    $display={
                      (tileState === COMPARE_TILE_EMPTY ||
                        tileState === COMPARE_TILE_ERROR) &&
                      comparisonMode === COMPARE_SELECTED
                        ? 'flex'
                        : 'none'
                    }
                    $error={tileState === COMPARE_TILE_ERROR}
                  >
                    <div className="warningIcon">
                      <Tooltip
                        content={<p>Player unavailable for this comparison.</p>}
                        target={<Icon variant="Warning" />}
                      />
                    </div>
                    <LocalSearch
                      handleResult={(result) => onResult(result, index)}
                      filterResultIds={[
                        playerDetails?.id,
                        ...comparisonPlayerIds,
                      ]}
                    />
                    <ButtonIcon
                      onClick={() => handleClose(index, tileState)}
                      size="tiny"
                      variant="secondary"
                      icon="Close"
                      title="Remove player comparison tile"
                    />
                  </PlayerComparisonSearchHolder>

                  <PlayerComparisonTableBorder
                    $opacity={tileState === COMPARE_TILE_ADD ? 0.28 : 1}
                  >
                    <BasicTable
                      data={[
                        {
                          statValue: showData ? player?.plays : '-',
                        },
                        {
                          statValue:
                            showData && player?.similarity ? (
                              <PlayerComparisonIndicator>
                                <OutcomeIndicator
                                  $size="0.625rem"
                                  $colour={csValue(
                                    findClosestBucket(player?.similarity),
                                    isDark
                                  )}
                                />
                                {formatValue(
                                  player?.similarity,
                                  API_STAT_UNITS.PERCENTAGE
                                )}
                              </PlayerComparisonIndicator>
                            ) : (
                              '-'
                            ),
                        },
                      ]}
                      headers={COMPARISON_TABLE_HEADERS}
                      showHeader={false}
                      bodytdHeight="2rem"
                    />
                  </PlayerComparisonTableBorder>
                </PlayerComparisonTopHeader>
              );
            })}
        </div>
      </div>
    </PlayerComparisonScrollAreaStickyHeader>
  );
};

PlayerComparisonTileHeader.propTypes = {
  generalData: PropTypes.shape({
    plays: PropTypes.number,
    similarity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  playerComparisonData: PropTypes.oneOfType([
    playerComparisonPropTypes,
    PropTypes.array,
  ]),
  playerDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  teamDetails: teamDetailsPropTypes,
  scrollingHeaderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  // overall comparison mode
  comparisonMode: PropTypes.oneOf([COMPARE_SELECTED, COMPARE_SIMILAR]),
  // event handlers for comparison tiles
  handleAdd: PropTypes.func,
  handleClose: PropTypes.func,
  handleResult: PropTypes.func,
  // optional scrolling and links (useful to turn off for export)
  isInteractive: PropTypes.bool,
  // the tile states, apart from data
  comparativeTileState: PropTypes.arrayOf(
    PropTypes.oneOf([
      COMPARE_TILE_ADD,
      COMPARE_TILE_EMPTY,
      COMPARE_TILE_HIDDEN,
      COMPARE_TILE_DATA,
      COMPARE_TILE_ERROR,
    ])
  ),
  // link players to comparison page
  playerLinkComparison: PropTypes.bool,
  displayMode: PropTypes.oneOf([
    RADAR_DISPLAY_MODE.TRAIT,
    RADAR_DISPLAY_MODE.PERFORMANCE,
    RADAR_DISPLAY_MODE.BOTH,
  ]),
  // minimum number of plays to compare
  minPlays: PropTypes.number,
  // radar weight
  radarWeight: PropTypes.oneOf(
    COMPARISON_WEIGHT_OPTIONS.map((val) => val.value)
  ).isRequired,
  // ensure data is ready before rendering
  renderData: PropTypes.bool,
  // parent data for current player IDs
  comparisonPlayerIds: PropTypes.arrayOf(PropTypes.number),
};

PlayerComparisonTileHeader.defaultProps = {
  generalData: {},
  playerComparisonData: {},
  playerDetails: {},
  teamDetails: {},
  scrollingHeaderRef: null,
  comparisonMode: COMPARE_SIMILAR,
  handleAdd: () => {},
  handleClose: () => {},
  handleResult: () => {},
  isInteractive: true,
  comparativeTileState: [],
  playerLinkComparison: true,
  displayMode: RADAR_DISPLAY_MODE.BOTH,
  minPlays: COMPARISON_DEFAULT_MIN_PLAYS,
  renderData: false,
  comparisonPlayerIds: [],
};

export default PlayerComparisonTileHeader;
