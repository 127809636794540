import { useQuery, useReactiveVar } from '@apollo/client';
import { groupBy, sumBy } from 'lodash';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';
import { GET_TEAM_FORMATION_STATS } from '../getTeamFormationStats';
import { mf_Teams } from '../../../../apollo';
import { RUN_RATE } from '../../TeamFormationSummary/TeamFormationSummary.constants';
import {
  useRankColor,
  useRunRateColor,
} from '../../TeamFormationSummary/TeamFormationSummary.hooks';
import {
  TRAITS_CONFIG,
  FORMATION_DETAIL_STATS_CONFIG,
} from '../TeamFormationDetail.constants';
import { formatValue } from '../../../League/stats/stats.dataManipulation';

/**
 * custom hook to get a break down of a team's performance broken down by formation traits
 */
export const useTeamFormationStats = (
  formation,
  isTeamMode,
  statDefinitions,
  statDistributions,
  totalTeamFormationPlayCount,
  totalLeagueFormationPlayCount
) => {
  const teamId = useReactiveVar(mf_Teams);

  const {
    data: teamStatsData,
    loading: isLoadingTeamStats,
    error: isErrorTeamStats,
  } = useQuery(GET_TEAM_FORMATION_STATS, {
    variables: {
      teamId,
      formation,
    },
    skip: !formation,
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const {
    data: leagueStatsData,
    loading: isLoadingLeagueStats,
    error: isErrorLeagueStats,
  } = useQuery(GET_TEAM_FORMATION_STATS, {
    variables: {
      formation,
    },
    skip: !formation,
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const loading = isLoadingTeamStats || isLoadingLeagueStats;
  const error = isErrorTeamStats || isErrorLeagueStats;

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_FORMATION_STATS, error);
    return { error, loading: false };
  }

  const runRateMean = statDistributions?.find((s) => s.name === RUN_RATE)?.mean;
  const { getRankColor } = useRankColor();
  const { getRunRateColor } = useRunRateColor(true, runRateMean);

  const teamStats = teamStatsData?.formationDetail;
  const leagueStats = leagueStatsData?.formationDetail;

  const totalTeamPlayCount = sumBy(teamStats, (d) => d.teamStats.playCount);
  const totalLeaguePlayCount = sumBy(leagueStats, (d) => d.teamStats.playCount);

  const enrichedLeagueStats = leagueStats?.map((d) => ({
    ...d,
    teamStats: {
      ...d.teamStats,
      totalPlayRate: d.teamStats.playCount / totalLeaguePlayCount,
      formationPlayRate: d.teamStats.playCount / totalLeagueFormationPlayCount,
    },
  }));
  const groupedTeamStats = groupBy(teamStats, (d) => d.groupingSet[0]);

  const formattedStats = Object.entries(groupedTeamStats).map(([key, rows]) => {
    const trait = TRAITS_CONFIG[key];

    return {
      label: trait.label,
      amount: rows.length,
      rows: rows
        // remove null entries
        .filter((d) => d[trait.traitKey])
        // enrich and format to expected tables/stats structure
        .map((d) => ({
          traitName: d[trait.traitKey],
          totalPlayRate: d.teamStats.playCount / totalTeamPlayCount,
          formationPlayRate:
            d.teamStats.playCount / totalTeamFormationPlayCount,
          ...d.teamStats,
        }))
        .map((d) =>
          Object.entries(d).reduce((acc, [stat, value]) => {
            // find corresponding league stat
            const leagueValue = enrichedLeagueStats?.find(
              (leagueStat) => leagueStat[trait.traitKey] === d.traitName
            )?.teamStats[stat];

            const isDeltaStat =
              FORMATION_DETAIL_STATS_CONFIG[stat]?.isDeltaStat;
            const val =
              isTeamMode || !isDeltaStat ? value : value - leagueValue;
            const statDistribution = statDistributions?.find(
              (s) => s.name === stat
            );
            const formattedValue = formatValue(
              val,
              statDefinitions?.find((def) => def.name === stat)?.units
            );
            const deltaValue = `${
              val >= 0 && val !== null && isDeltaStat ? '+' : ''
            }${formattedValue}`;
            const label = isTeamMode ? formattedValue : deltaValue;
            const mean = statDistribution?.mean;
            const standardDeviation = statDistribution?.stddev;
            const colors = FORMATION_DETAIL_STATS_CONFIG[stat]?.csIntensityBlue
              ? getRunRateColor(value)
              : getRankColor(value, mean, standardDeviation);

            return {
              ...acc,
              [stat]: {
                value,
                label,
                color: colors.color,
                ink: colors.ink,
              },
            };
          }, {})
        ),
    };
  });

  return { data: formattedStats, loading };
};
