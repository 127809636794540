import { styled } from 'styled-components';

export const HavocSummaryStats = styled.div`
  display: flex;
  gap: 1rem;
`;
HavocSummaryStats.displayName = 'HavocSummaryStats';

export const HavocSummaryStatsColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: ${({ $width }) => $width || '50%'};
  overflow: hidden;
  min-width: 200px;

  h3 {
    ${({ theme }) => theme.typography.headingMedium}
  }

  > div {
    display: flex;
    gap: 1rem;
    padding-top: 0.5rem;
    flex-direction: row;
    border: ${({ theme }) =>
      `1px solid ${theme.colours.canvas.secondary.main}`};
    padding: 1rem;
    flex-wrap: wrap;
    min-height: 20px;
  }
`;
HavocSummaryStatsColumn.displayName = 'HavocSummaryStatsColumn';

export const HavocSummaryStatsColumnHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;
HavocSummaryStatsColumnHeader.displayName = 'HavocSummaryStatsColumnHeader';
