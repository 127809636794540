import { gql } from '@apollo/client';

export const GET_PLAY_FINDER_FILTER_TREE = gql`
  query GetPlayFinderFilterTree(
    $competitionId: Int!
    $seasonId: Int!
    $teamId: Int!
    $offense: Boolean!
  ) {
    playFinderFilterTree(
      competitionId: $competitionId
      seasonId: $seasonId
      teamId: $teamId
      offense: $offense
    ) {
      label
      children {
        label
        children {
          label
          filterId
          columnId
          widgetType
          widgetArgs {
            default
            min
            max
            step
            options
            optionsWithIds {
              id
              label
            }
          }
          units
          abbrev
        }
      }
    }
  }
`;
