import { styled } from 'styled-components';
import { slideAnimationStyle } from '../../styles/global.styles';

export const StyledLayout = styled.div`
  height: 100vh;
  padding-top: ${({ $withFilters }) => ($withFilters ? '100px' : '52px')};
  background-color: ${({ theme }) => theme.colours.canvas.primary.main};

  .core {
    z-index: 90;
    display: flex;
    height: ${({ $withFilters }) =>
      `calc(100vh - ${$withFilters ? '100px' : '52px'})`};
  }

  .cover {
    z-index: 102;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: ${({ theme }) => theme.colours.canvas.primary.main};
    left: 56px;
    top: 54px;
    padding: 30px;
  }
`;
StyledLayout.displayName = 'StyledLayout';

export const BasicPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
BasicPage.displayName = 'BasicPage';

export const FixedAside = styled.aside`
  position: relative;
  height: 100vh;
  padding-top: 0;
  margin-top: -1.25rem;
  padding-bottom: 5.875rem;
  overflow-y: auto;
  box-shadow: none;
  width: ${({ $width }) => $width || 'inherit'};
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  scrollbar-gutter: stable;

  ${({ theme }) => theme.mqFrom('md')} {
    position: fixed;
    padding-top: ${({ $paddingTopMD }) => $paddingTopMD || '1.25rem'};
  }
`;
FixedAside.displayName = 'FixedAside';

export const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 75px;
  padding-right: 15px;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.canvas.primary.main};
`;
Main.displayName = 'Main';

// (RH) sidebar layout
// based on immediate two child <div>
// sidebar width defaults to 200px
export const SidebarRightLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $gap }) => $gap || '1rem'};
  width: 100%;
  padding: ${({ $padding }) => $padding || '0 0.5rem'};

  > :last-child {
    flex-basis: ${({ $sidebarWidth }) => $sidebarWidth || '200px'};
    transition: ${`flex-basis 500ms ${slideAnimationStyle}`};
    flex-grow: ${({ $closed }) => $closed || 1};
  }

  > :first-child {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: ${({ $inlineSize }) => $inlineSize || '60%'};
  }
`;
SidebarRightLayout.displayName = 'SidebarRightLayout';

// wrapper for semantic-ui grid
export const GridWrapper = styled.div`
  .ui.grid > .row > .column {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:first-child {
      padding-bottom: 1rem;
    }
  }
`;
GridWrapper.displayName = 'GridWrapper';

/*
This is removing the standard padding from Main in the layout so the grid component page can be used instead
Once the grid is implemented universally it can be dropped
 */
export const BasicPageNoPadding = styled.div`
  position: absolute;
  top: ${({ $top }) => $top || '98px'}; /* visible height of the topbar */
  left: ${({ $left }) => $left || '62px'}; /* visible width of the sidebar */
  margin: 0;
  padding: 0;
  width: calc(100% - 64px);
`;
BasicPageNoPadding.displayName = 'BasicPageNoPadding';

/*
Div holding the Grid used to override default margin-top so layout is correct
 */
export const KitbagPageGridHolder = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;

  /*the <Grid> component */
  & > div:first-child {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;
KitbagPageGridHolder.displayName = 'KitbagPageGridHolder';
