import { gql } from '@apollo/client';

export const getRadarPlayersStats = (selectedStatNames) => {
  const joinedStatNames = selectedStatNames.join('\n');
  const gqlString = gql`
    query getRadarPlayerStats(
      $competitionId: Int!
      $seasonId: Int!
      $teamId: Int!
      $playerIds: [Int!]
      $downs: [Int!]
      $noDowns: Boolean
      $yardsToGoMin: Float
      $yardsToGoMax: Float
      $fieldPositionMin: Float
      $fieldPositionMax: Float
      $quarters: [Int!]
      $clockMin: Int
      $clockMax: Int
      $yardsGainedMin: Float
      $yardsGainedMax: Float
      $passAirYardsMin: Float
      $passAirYardsMax: Float
      $playTypes: [PlayType!]
      $qbDropDepthMin: Float
      $qbDropDepthMax: Float
      $qbTimeToThrowMin: Float
      $qbTimeToThrowMax: Float
      $qbPassLocation: [PocketRelativeLocation!]
      $formation: FormationFilter
      $gameIds: [Int!]
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      selectedTeamId @client @export(as: "teamId")
      contentFilterDowns @client @export(as: "downs")
      contentFilterNoDowns @client @export(as: "noDowns")
      contentFilterYardsToGoMin @client @export(as: "yardsToGoMin")
      contentFilterYardsToGoMax @client @export(as: "yardsToGoMax")
      contentFilterFieldPositionMin @client @export(as: "fieldPositionMin")
      contentFilterFieldPositionMax @client @export(as: "fieldPositionMax")
      contentFilterQuarter @client @export(as: "quarters")
      contentFilterClockMin @client @export(as: "clockMin")
      contentFilterClockMax @client @export(as: "clockMax")
      contentFilterPlayType @client @export(as: "playTypes")
      contentFilterQBDropDepthMin @client @export(as: "qbDropDepthMin")
      contentFilterQBDropDepthMax @client @export(as: "qbDropDepthMax")
      contentFilterQBTimeToThrowMin @client @export(as: "qbTimeToThrowMin")
      contentFilterQBTimeToThrowMax @client @export(as: "qbTimeToThrowMax")
      contentFilterQBPocketLocation @client @export(as: "qbPassLocation")
      contentFilterYardsGainedMin @client @export(as: "yardsGainedMin")
      contentFilterYardsGainedMax @client @export(as: "yardsGainedMax")
      contentFilterPassAirYardsMin @client @export(as: "passAirYardsMin")
      contentFilterPassAirYardsMax @client @export(as: "passAirYardsMax")
      contentFilterFormations @client @export(as: "formation")
      contentFilterGameIds @client @export(as: "gameIds")
      playerStats(
        playFilters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          downs: $downs
          noDowns: $noDowns
          yardsToGoMin: $yardsToGoMin
          yardsToGoMax: $yardsToGoMax
          fieldPositionMin: $fieldPositionMin
          fieldPositionMax: $fieldPositionMax
          quarters: $quarters
          clockMin: $clockMin
          clockMax: $clockMax
          playTypes: $playTypes
          yardsGainedMin: $yardsGainedMin
          yardsGainedMax: $yardsGainedMax
          passAirYardsMin: $passAirYardsMin
          passAirYardsMax: $passAirYardsMax
          qbDropDepthMin: $qbDropDepthMin
          qbDropDepthMax: $qbDropDepthMax
          qbTimeToThrowMin: $qbTimeToThrowMin
          qbTimeToThrowMax: $qbTimeToThrowMax
          qbPassLocation: $qbPassLocation
          formation: $formation
          gameIds: $gameIds
        }
        aggregateMode: RATE
        groupBy: [PLAYER]
        orderBy: [{ name: "plays", descending: true }]
        playerStatFilters: {
          teamIds: [$teamId]
          playerIds: $playerIds
        }
      ) {
        items {
          groups {
            key
            value
          }
          playerName
          plays
          jerseyNumber
          ${joinedStatNames}
        }
      }
    }
  `;

  return gqlString;
};
