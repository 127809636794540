import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../../../utils/errorCodes/errorCodes';
import useDebounce from '../../../../../utils/hooks/useDebounce';
import { SEARCH_COLLEGES } from './searchColleges';

export const useSearchColleges = (searchString) => {
  const debouncedSearchString = useDebounce(searchString, 400);

  const { data, loading, error } = useQuery(SEARCH_COLLEGES, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    variables: {
      searchString: debouncedSearchString,
    },
  });

  if (loading) {
    return { isLoading: true };
  }
  if (error) {
    console.error(ERROR_CODES.USE_SEARCH_COLLEGES, error);
    return {
      isLoading: false,
      error,
    };
  }

  const collegeTeams = data.searchColleges;
  return {
    isLoading: false,
    collegeTeams,
  };
};
