import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isUrl from '../../utils/helpers/isUrl';

// react-router Link doesn't support external links
const ExternalLink = function ({ href, children }) {
  const isScript = href.substring(0, 10) === 'javascript';
  return isUrl(href) || isScript ? (
    <a href={href} target={isScript ? '' : '_blank'} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={href}>{children}</Link>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ExternalLink.defaultProps = {
  children: null,
};

export default ExternalLink;
