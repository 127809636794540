import { formatValue } from '../../../../League/stats/stats.dataManipulation';
import { RUN_TENDENCY_TABLE_ROWS } from './RunTable.constants';

const getRows = (teamName, leagueName) => {
  const filteredRows = RUN_TENDENCY_TABLE_ROWS.map((rowDefinition) => ({
    ...rowDefinition,
    metric: `${rowDefinition.label}`,
  }));
  const teamRows = RUN_TENDENCY_TABLE_ROWS.map((rowDefinition) => ({
    ...rowDefinition,
    metric: `${teamName} ${rowDefinition.label}`,
  }));
  const leagueRows = RUN_TENDENCY_TABLE_ROWS.filter((r) => r.leagueValue).map(
    (rowDefinition) => ({
      ...rowDefinition,
      metric: `${leagueName} ${rowDefinition.label}`,
    })
  );
  return { filteredRows, teamRows, leagueRows };
};

const addColumnData = (rowDefinitions, runData, columnHeaders) => {
  if (!runData?.length) {
    return [];
  }
  const rows = rowDefinitions.map((rowDefinition) => {
    const columnValues = columnHeaders
      .filter((h) => h.isValue)
      .map((columnHeader) => {
        // find the relevant gap or poa in the data
        const relevantDatum = runData.find((r) => r.id === columnHeader.key);
        const value = formatValue(
          relevantDatum[rowDefinition.key],
          rowDefinition.units
        );
        return { [columnHeader.key]: value };
      });
    const columnsObject = Object.assign(...columnValues);
    return { metric: rowDefinition.metric, ...columnsObject };
  });
  return rows;
};
export const formatRunTableData = (
  runData,
  teamRunData,
  leagueRunData,
  columnHeaders,
  teamName,
  leagueName
) => {
  const { filteredRows, teamRows, leagueRows } = getRows(teamName, leagueName);
  const formattedRowsFiltered = addColumnData(
    filteredRows,
    runData,
    columnHeaders
  );
  const formattedRowsTeam = addColumnData(teamRows, teamRunData, columnHeaders);
  const formattedRowsLeague = addColumnData(
    leagueRows,
    leagueRunData,
    columnHeaders
  );

  return formattedRowsFiltered.concat(formattedRowsTeam, formattedRowsLeague);
};
