import { isArray, isEmpty, mergeWith, unionBy } from 'lodash';

// remove filter from filter tree, by filterId
export const removeFilterById = (filterTree, filterIdToRemove) =>
  filterTree
    .map((item) => {
      const newItem = {
        ...item,
        children: item.children.map((child) => ({
          ...child,
          children: child.children.filter(
            (filter) => !(filter.filterId === filterIdToRemove)
          ),
        })),
      };

      // Recursively remove parent elements with empty children arrays
      if (newItem.children.every((child) => child.children.length === 0)) {
        return null;
      }
      return newItem;
    })
    .filter(Boolean);

// return filter from filterTree, by filterId
export const findFilterById = (filterTree, filterId) => {
  if (!filterTree) return null;
  const flattenTree = (tree) =>
    tree.flatMap((item) => {
      if (item.children) {
        return [item, ...flattenTree(item.children)];
      }
      return [item];
    });

  const flattenedData = flattenTree(filterTree);

  return flattenedData.find((item) => item.filterId === filterId) || null;
};

// update filter selected attribute from filterTree, by filterId
export const updateFilterById = (filterTree, filterId, newValue) =>
  filterTree.map((item) => {
    if (item.children && item.children.length > 0) {
      const updatedChildren = updateFilterById(
        item.children,
        filterId,
        newValue
      );

      return { ...item, children: updatedChildren };
    }

    if (item.filterId === filterId) {
      let replacementValue;
      // a multiselect widget is marked by passing value as a len 1 array, i.e. [1]
      if (Array.isArray(newValue) && newValue.length === 1) {
        const value = newValue[0];

        if (typeof item.widgetArgs.selected === 'undefined') {
          replacementValue = [value];
        } else if (
          typeof item.widgetArgs.selected !== 'undefined' &&
          !item.widgetArgs.selected.includes(value)
        ) {
          replacementValue = [...item.widgetArgs.selected, value]
            .flat()
            .sort((a, b) => a - b);
        } else {
          replacementValue = item.widgetArgs.selected.filter(
            (val) => val !== value
          );
        }
      } else {
        replacementValue = newValue;
      }

      return {
        ...item,
        widgetArgs: {
          ...item.widgetArgs,
          selected: replacementValue,
        },
      };
    }

    return item;
  });

// return all filters with additional "selected" attribute
export const findSelectedFilters = (filterTree) => {
  const result = filterTree.map((item) => {
    const itemChildren = item.children.map((child) => {
      const grandChildren = child.children.filter(
        (grandchild) =>
          grandchild.widgetArgs &&
          typeof grandchild.widgetArgs.selected !== 'undefined'
      );
      return isEmpty(grandChildren)
        ? null
        : { ...child, children: grandChildren };
    });
    const goodChildren = itemChildren.filter((c) => c !== null);
    return { ...item, children: goodChildren };
  });
  return result.filter((i) => !isEmpty(i.children));
};

// merge filter tree fragments
export const mergeFilterTrees = (tree1, tree2) => {
  const mergeNodes = (objValue, srcValue) => {
    if (isArray(objValue)) {
      return unionBy(objValue, srcValue, 'label');
    }
    return undefined;
  };

  const mergeChildren = (children1, children2) => {
    const allChildren = unionBy(children1, children2, 'label');

    return allChildren.map((child) => {
      const matchingChild1 = children1.find((c) => c.label === child.label);
      const matchingChild2 = children2.find((c) => c.label === child.label);

      if (matchingChild1 && matchingChild2) {
        return mergeWith({}, matchingChild1, matchingChild2, mergeNodes);
      }
      return matchingChild1 || matchingChild2;
    });
  };

  const mergeTree = (treeA, treeB) => {
    const allNodes = unionBy(treeA, treeB, 'label');

    return allNodes.map((node) => {
      const matchingNode1 = treeA.find((n) => n.label === node.label);
      const matchingNode2 = treeB.find((n) => n.label === node.label);

      if (matchingNode1 && matchingNode2) {
        const mergedNode = mergeWith(
          {},
          matchingNode1,
          matchingNode2,
          mergeNodes
        );
        if (matchingNode1.children || matchingNode2.children) {
          mergedNode.children = mergeChildren(
            matchingNode1.children || [],
            matchingNode2.children || []
          );
        }
        return mergedNode;
      }
      return matchingNode1 || matchingNode2;
    });
  };

  return mergeTree(tree1, tree2);
};

// flatten filter tree structure
export const flattenFilterTree = (
  tree,
  parentLabel = '',
  grandparentLabel = ''
) => {
  let result = [];
  if (!tree) return result;
  tree.forEach((node) => {
    // eslint-disable-next-line no-underscore-dangle
    if (node.__typename === 'FilterNode') {
      result.push({
        ...node,
        parentLabel,
        grandparentLabel,
      });
    }

    if (node.children) {
      result = result.concat(
        flattenFilterTree(node.children, node.label, parentLabel)
      );
    }
  });

  return result;
};

// restore flattened filter tree structure
export const restoreFilterTree = (flatList) => {
  const root = [];
  if (!flatList) return root;

  const nodeMap = new Map();
  flatList.forEach((node) => {
    nodeMap.set(node.label, { ...node, children: [] });
  });

  // Iterate through the flat list to build the tree
  flatList.forEach((node) => {
    if (node.parentLabel) {
      const parentNode = nodeMap.get(node.parentLabel);
      if (parentNode) {
        parentNode.children.push(nodeMap.get(node.label));
      } else {
        // Handle case where parentNode is not found
        const newParentNode = {
          label: node.parentLabel,
          children: [nodeMap.get(node.label)],
          __typename: 'SubCategoryNode',
        };
        nodeMap.set(node.parentLabel, newParentNode);
        if (node.grandparentLabel) {
          const grandparentNode = nodeMap.get(node.grandparentLabel);
          if (grandparentNode) {
            grandparentNode.children.push(newParentNode);
          } else {
            // Handle case where grandparentNode is not found
            const newGrandparentNode = {
              label: node.grandparentLabel,
              children: [newParentNode],
              __typename: 'CategoryNode',
            };
            nodeMap.set(node.grandparentLabel, newGrandparentNode);
            root.push(newGrandparentNode);
          }
        } else {
          root.push(newParentNode);
        }
      }
    } else {
      root.push(nodeMap.get(node.label));
    }
  });

  return root;
};
