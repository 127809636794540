import { styled } from 'styled-components';

export const StyledPlayerPassPlacement = styled.div`
  .button.clear {
    border: 0;
    width: fit-content;
    position: absolute;
    top: 16px;
    right: 0;

    .icon {
      font-size: 2rem;
    }
  }

  .ui {
    &.loader {
      top: calc(25% + 35px);
    }
  }

  .dot {
    &:hover {
      cursor: pointer;
    }
  }

  .legend {
    fill: ${({ theme }) => theme.colours.ink.primary.main};
  }
`;
StyledPlayerPassPlacement.displayName = 'StyledPlayerPassPlacement';

export const PassPlacementChartContainer = styled.div`
  max-width: ${({ $maxWidth }) => $maxWidth};
  align-self: center;
  width: 100%;
`;
PassPlacementChartContainer.displayName = 'PassPlacementChartContainer';
