import { gql } from '@apollo/client';

export const SEARCH_METADATA = gql`
  query SearchMetadata($searchString: String!) {
    searchMetadata(searchString: $searchString, filters: { maxResults: 100 }) {
      ... on PlayerListPlayer {
        id
        name
        mostCommonPosition {
          id
          code
        }
        team {
          id
          shortName
          competition {
            id
          }
        }
        season {
          id
        }
      }
    }
  }
`;
