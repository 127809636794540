import { API_PLAYER_AGGREGATE_MODES } from '../../../utils/constants/api';

export const STATS_AGGREGATION_MODE_OPTIONS = [
  {
    secondaryLabel: 'Aggregated by Rate',
    value: API_PLAYER_AGGREGATE_MODES.RATE,
    label: '%',
  },
  {
    secondaryLabel: 'Aggregated by Total',
    value: API_PLAYER_AGGREGATE_MODES.TOTAL,
    label: '.0',
  },
];

export const PERMANENT_HEADERS = [
  {
    id: 'gameDate',
    label: 'Date',
    description: 'Date the game was played',
    isSortable: true,
  },
  {
    id: 'gameName',
    label: 'Game',
    description: 'Teams that played in this game',
  },
  {
    id: 'plays',
    label: 'Plays',
    isSortable: true,
    description: '# of plays a player played',
  },
];

export const DEFAULT_HIGHLIGHTED_COLUMN = 1;
export const DEFAULT_SORT_BY = 'gameDate';
