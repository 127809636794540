import { API_EVENT_TYPE_KEYS } from '../../utils/constants/api';
import {
  drawCircle,
  drawHexagon,
  drawPentagonArrow,
  drawTriangularArrow,
} from '../../utils/visualisations/shapes';

export const HAVOC_AXES_PADDING = { top: 16, right: 16, bottom: 16, left: 16 };

export const HAVOC_CLASSES = {
  IN_MARGINS: 'hvk-in-margin',
  FIELD_ZONE: 'hvk-field-zone',
  FIELD_IN_AXES: 'hvk-field-area',
  FIELD_MARKINGS: 'hvk-field-markings',
  FIELD_DATA: 'hvk-field-data',
  BACKGROUND: 'hvk-background',
};

export const HAVOC_KEY_CLASSES = {
  IN_MARGINS: 'hvk-key-in-margin',
  MAIN: 'hvk-key-main',
};

/* Values come from API Event Types */
export const HAVOC_TYPE_SACK = {
  value: API_EVENT_TYPE_KEYS.SACK,
  label: 'Sack',
};
export const HAVOC_TYPE_PRESSURE = {
  value: API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE,
  label: 'Pressure',
};
export const HAVOC_TYPE_RUN_DISRUPTION = {
  value: API_EVENT_TYPE_KEYS.RUN_DISRUPTION,
  label: 'Run Disruption',
};
export const HAVOC_TYPE_TACKLE_FOR_LOSS = {
  value: API_EVENT_TYPE_KEYS.TACKLE,
  label: 'Tackle For Loss',
};
export const HAVOC_TYPE_ALL = {
  value: 'All',
  label: 'All',
  filterValues: [
    API_EVENT_TYPE_KEYS.SACK,
    API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE,
    API_EVENT_TYPE_KEYS.RUN_DISRUPTION,
    API_EVENT_TYPE_KEYS.TACKLE,
  ],
};
export const HAVOC_TYPE_PASS = {
  value: 'Pass',
  label: 'Pass Havoc Types',
  filterValues: [
    API_EVENT_TYPE_KEYS.SACK,
    API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE,
  ],
};
export const HAVOC_TYPE_RUN = {
  value: 'Run',
  label: 'Run Havoc Types',
  filterValues: [
    API_EVENT_TYPE_KEYS.RUN_DISRUPTION,
    API_EVENT_TYPE_KEYS.TACKLE,
  ],
};
export const HAVOC_TYPES = [
  HAVOC_TYPE_ALL,
  HAVOC_TYPE_SACK,
  HAVOC_TYPE_PRESSURE,
  HAVOC_TYPE_TACKLE_FOR_LOSS,
  HAVOC_TYPE_RUN_DISRUPTION,
  HAVOC_TYPE_PASS,
  HAVOC_TYPE_RUN,
];
export const HAVOC_PLAY_TYPES = {
  PASS: {
    value: 'PASS',
    label: 'Pass Plays',
    havocTypeOptions: [HAVOC_TYPE_PASS, HAVOC_TYPE_PRESSURE, HAVOC_TYPE_SACK],
  },
  RUN: {
    value: 'RUN',
    label: 'Run Plays',
    havocTypeOptions: [
      HAVOC_TYPE_RUN,
      HAVOC_TYPE_RUN_DISRUPTION,
      HAVOC_TYPE_TACKLE_FOR_LOSS,
    ],
  },
  ANY: {
    value: 'ANY',
    label: 'All Plays',
    havocTypeOptions: [
      HAVOC_TYPE_ALL,
      HAVOC_TYPE_PRESSURE,
      HAVOC_TYPE_SACK,
      HAVOC_TYPE_RUN_DISRUPTION,
      HAVOC_TYPE_TACKLE_FOR_LOSS,
    ],
  },
};
export const HAVOC_PLAY_TRIBUTTON = {
  labels: {
    null: HAVOC_PLAY_TYPES.ANY.label,
    true: HAVOC_PLAY_TYPES.PASS.label,
    false: HAVOC_PLAY_TYPES.RUN.label,
  },
  values: {
    null: HAVOC_PLAY_TYPES.ANY.value,
    true: HAVOC_PLAY_TYPES.PASS.value,
    false: HAVOC_PLAY_TYPES.RUN.value,
  },
};

export const HAVOC_EVENT_TYPES = {
  [HAVOC_TYPE_PRESSURE.value]: {
    name: 'Pressure',
    shape: (r) => drawPentagonArrow(r * 1.2),
    color: (palette) => palette.objects.n1.main,
  },
  [HAVOC_TYPE_RUN_DISRUPTION.value]: {
    name: 'Run Disruption',
    shape: (r) => drawTriangularArrow(r),
    color: (palette) => palette.objects.n2.main,
  },
  [HAVOC_TYPE_SACK.value]: {
    name: 'Sack',
    shape: (r) => drawHexagon(r),
    color: (palette) => palette.objects.n3.main,
  },
  [HAVOC_TYPE_TACKLE_FOR_LOSS.value]: {
    name: 'Tackle for Loss',
    shape: (r) => drawCircle(r),
    color: (palette) => palette.objects.n4.main,
  },
};

export const HAVOC_Y_MODE_FIELD = {
  value: 'FIELD',
  label: 'Field Y Locations',
};
export const HAVOC_Y_MODE_SNAP = {
  value: 'SNAP',
  label: 'Relative to Snap',
};
export const HAVOC_Y_MODES = [HAVOC_Y_MODE_FIELD, HAVOC_Y_MODE_SNAP];

export const HAVOC_COLOR_MODE_LOCATIONS = {
  value: 'LOC',
  label: 'Havoc Locations',
  variant: 'Position',
};
export const HAVOC_COLOR_MODE_LOCATIONS_TIME = {
  value: 'LOC_TIME',
  label: 'Havoc Locations, Time after snap',
  variant: 'PassPath',
};
export const HAVOC_COLOR_MODE_LOCATIONS_PLAYERS = {
  value: 'LOC_PLAYERS',
  label: 'Havoc Locations, Player Identification',
};
export const HAVOC_COLOR_MODE_LOCATIONS_POSITIONS = {
  value: 'LOC_POSITIONS',
  label: 'Havoc Locations, Position',
};
export const HAVOC_COLOR_MODE_LOCATIONS_GAPS = {
  value: 'LOC_GAPS',
  label: 'Havoc Locations, Gap Origin',
  variant: 'PlayerHighlight',
};
export const HAVOC_COLOR_MODE_HEATMAP = {
  value: 'HEATMAP',
  label: 'Heatmap',
  variant: 'HeatmapFlame',
};
export const HAVOC_COLOR_MODES = [
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_COLOR_MODE_LOCATIONS_PLAYERS,
  HAVOC_COLOR_MODE_LOCATIONS_POSITIONS,
  HAVOC_COLOR_MODE_LOCATIONS_GAPS,
  HAVOC_COLOR_MODE_HEATMAP,
];
export const HAVOC_TILE_COLOR_MODES = [
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_COLOR_MODE_LOCATIONS_GAPS,
  HAVOC_COLOR_MODE_HEATMAP,
];

export const HAVOC_FOCUS_MODE_BOX = {
  value: 'BOX',
  label: 'Box+',
};
export const HAVOC_FOCUS_MODE_LOS = {
  value: 'LOS',
  label: 'Line of Scrimmage Zone',
};
export const HAVOC_FOCUS_MODE_FIELD = {
  value: 'FIELD',
  label: 'Full Field',
};
export const HAVOC_FOCUS_MODES = [
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_FOCUS_MODE_LOS,
  HAVOC_FOCUS_MODE_FIELD,
];

export const HAVOC_FIELD_SIZES_X = {
  [HAVOC_FOCUS_MODE_BOX.value]: { LOS: 15, END: 5 },
  [HAVOC_FOCUS_MODE_LOS.value]: { LOS: 20, END: 30 },
  [HAVOC_FOCUS_MODE_FIELD.value]: { LOS: 30, END: 90 },
};

export const HAVOC_VIS_SIZES = {
  KEY_HEIGHT: 120,
};

export const HAVOC_PLAYER_OPTION_ANY = { value: 0, label: 'All Players' };
export const HAVOC_POSITION_OPTION_ANY = {
  value: 'ANY',
  label: 'All Positions',
};

export const HAVOC_BLITZ_OPTION_ANY = {
  value: 'ANY',
  label: 'All Blitz Types',
};

export const HAVOC_DEFENSIVE_FRONT_OPTION_ANY = {
  value: 'ANY',
  label: 'All Defensive Fronts',
};
