import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Toggle } from '@statsbomb/kitbag-components';
import { mf_TeamDetails } from '../../../apollo';
import { GET_PASSES } from './getPasses';
import { GET_PASSES_AGAINST } from './getPassesAgainst';
import PassEndsPage from '../../player/PlayerPassing/PassEndsPage';
import {
  toChartSpace,
  DEFAULT_PLAYER_SELECTION,
} from '../../player/PlayerPassing/DataManipulation';
import { defaultFieldSettings } from '../../../utils/constants/charting';
import useQueryString from '../../../utils/hooks/useQueryString';
import PageHeader from '../../../components/PageHeader/PageHeader';

const TeamPassing = () => {
  const [displayPassesFor, setDisplayPassesFor] = useQueryString('show', true);
  const [selectedPasser, setSelectedPasser] = useState(
    DEFAULT_PLAYER_SELECTION.value
  );
  const [selectedTarget, setSelectedTarget] = useState(
    DEFAULT_PLAYER_SELECTION.value
  );
  // exporting
  const rv_TeamDetails = useReactiveVar(mf_TeamDetails);

  const handleDisplayPassesForPress = () => {
    setDisplayPassesFor(!displayPassesFor);
    setSelectedPasser(DEFAULT_PLAYER_SELECTION.value);
    setSelectedTarget(DEFAULT_PLAYER_SELECTION.value);
  };

  const {
    loading: loadingFor,
    error: errorFor,
    data: dataFor,
  } = useQuery(GET_PASSES);
  const {
    loading: loadingAgainst,
    error: errorAgainst,
    data: dataAgainst,
  } = useQuery(GET_PASSES_AGAINST);

  const loading = displayPassesFor ? loadingFor : loadingAgainst;
  const error = displayPassesFor ? errorFor : errorAgainst;

  const apiData = displayPassesFor
    ? dataFor?.passEvents?.edges?.map(({ node }) => node)
    : dataAgainst?.passEvents?.edges?.map(({ node }) => node);

  const formattedData =
    apiData &&
    apiData.map((m) => toChartSpace(m, defaultFieldSettings.pxPerYard));

  const teamName = displayPassesFor
    ? formattedData?.[0]?.play?.offenseTeam?.name
    : formattedData?.[0]?.play?.defenseTeam?.name;

  const teamNameTitle = displayPassesFor
    ? `${teamName}, Passes`
    : `${teamName}, Passes Against`;

  const breadcrumbAndTitle = (
    <PageHeader
      href="/team/overview/:leagues/:seasons/:teams/"
      rootPage="Team"
      activePage="Passing Chart"
      showPlayerName={false}
      showTeamName
    />
  );

  const passesForAgainstFilter = (
    <Toggle
      id="passes-for-against-toggle"
      label={
        displayPassesFor
          ? `Passes made by ${teamName}`
          : `Passes made against ${teamName}`
      }
      onChange={handleDisplayPassesForPress}
      checked={displayPassesFor}
    />
  );

  return (
    <PassEndsPage
      formattedData={formattedData}
      isError={!!error}
      loading={loading}
      selectedPasser={selectedPasser}
      setSelectedPasser={(p) => setSelectedPasser(p)}
      selectedTarget={selectedTarget}
      setSelectedTarget={(t) => setSelectedTarget(t)}
      visTitle={teamNameTitle}
      downloadNameRoot={rv_TeamDetails?.name}
      breadcrumb={breadcrumbAndTitle}
      dataFilters={passesForAgainstFilter}
      showPasses={displayPassesFor}
    />
  );
};

export default TeamPassing;
