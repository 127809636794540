import {
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_PLAYER_OPTION_ANY,
  HAVOC_BLITZ_OPTION_ANY,
  HAVOC_DEFENSIVE_FRONT_OPTION_ANY,
} from '../../../visualisations/HavocChart/HavocChart.constants';

export const formatPlayersSelect = (players) => {
  if (!players?.length) {
    return [HAVOC_PLAYER_OPTION_ANY];
  }
  const playerOptions = players.map((player) => ({
    value: player.playerId,
    label: `${player.playerName} (${player.freq})`,
  }));
  return [HAVOC_PLAYER_OPTION_ANY].concat(playerOptions);
};

export const formatPositionsSelect = (positions) => {
  if (!positions?.length) {
    return [HAVOC_POSITION_OPTION_ANY];
  }
  const positionOptions = positions.map((pos) => ({
    value: pos.positionCode,
    label: `${pos.positionName} (${pos.freq})`,
  }));
  return [HAVOC_POSITION_OPTION_ANY].concat(positionOptions);
};

export const formatBlitzSelect = (blitzTypes) => {
  if (!blitzTypes?.length) {
    return [HAVOC_BLITZ_OPTION_ANY];
  }
  const positionOptions = blitzTypes.map((b) => ({
    value: b.blitzType,
    label: `${b.blitzTypeName} (${b.freq})`,
  }));
  return [HAVOC_BLITZ_OPTION_ANY].concat(positionOptions);
};
export const formatDefensiveFrontSelect = (defensiveFronts) => {
  if (!defensiveFronts?.length) {
    return [HAVOC_DEFENSIVE_FRONT_OPTION_ANY];
  }
  const dfOptions = defensiveFronts.map((b) => ({
    value: b.defensiveFront,
    label: `${b.defensiveFrontName} (${b.freq})`,
  }));
  return [HAVOC_DEFENSIVE_FRONT_OPTION_ANY].concat(dfOptions);
};
