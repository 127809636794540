import { API_STAT_UNITS } from '../../../../utils/constants/api';

const { COUNT, SHORT_DECIMAL, STRING, PERCENTAGE } = API_STAT_UNITS;

export const HAVOC_TABLE_AGGREGATION_MODE_TYPE = {
  PLAYER: 'Player',
  GAP: 'Snap Gap',
  GAP_EXPLOITED: 'Exploited Gap',
  POSITION: 'Position',
  FRONT: 'Defensive Front',
  BLITZ: 'Blitz Type',
  ZONE: 'Defender Zone',
};
export const HAVOC_TABLE_AGGREGATION_MODES = Object.values(
  HAVOC_TABLE_AGGREGATION_MODE_TYPE
).map((k) => ({ value: k, label: k }));

/*
  the key field is what to group by 
  the name field is what to display in a table column
*/
export const HAVOC_AGGREGATION_DATA_TERMS = {
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.PLAYER]: {
    keyField: 'playerName',
    nameField: 'playerName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP]: {
    keyField: 'snapGapAPICode',
    nameField: 'snapGapName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP_EXPLOITED]: {
    keyField: 'exploitedGapAPICode',
    nameField: 'eventGapName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.POSITION]: {
    keyField: 'positionCode',
    nameField: 'positionName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.FRONT]: {
    keyField: 'defensiveFront',
    nameField: 'defensiveFrontName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.BLITZ]: {
    keyField: 'blitzType',
    nameField: 'blitzTypeName',
  },
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.ZONE]: {
    keyField: 'defenderZoneAPICode',
    nameField: 'defenderZoneName',
  },
};

/** If mode requires a second column ~ i.e. player adds most played position */
const HAVOC_TABLE_MODE_ADDITIONAL_HEADERS = {
  [HAVOC_TABLE_AGGREGATION_MODE_TYPE.PLAYER]: [
    {
      label: 'Position',
      key: 'mostCommonAlignmentPosition',
      units: STRING,
      isSortable: true,
    },
  ],
};

const HAVOC_PLAYS_HEADERS = [
  {
    label: 'Havoc Plays',
    description: 'Count of plays containing Havoc Events',
    key: 'havocPlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Havoc %',
    description: 'Havoc Plays / All Plays',
    key: 'playsPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Pass Plays Havoc %',
    description: 'Havoc Pass Plays / All Pass Plays',
    key: 'playsPassPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Run Plays Havoc %',
    description: 'Havoc Run Plays / All Run Plays',
    key: 'playsRunPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of havoc',
    description: 'Havoc Plays for row / Overall Havoc Plays',
    key: 'havocPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Havoc Pass Plays',
    description: 'Pass Plays / Overall Pass Plays',
    key: 'passPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Havoc Run Plays',
    description: 'Run Plays / Overall Sack Plays',
    key: 'runPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Sacks',
    description: 'Sack Plays / Overall Sack Plays',
    key: 'sackPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Pressures',
    description: 'Pressure Plays / Overall Pressure Plays',
    key: 'pressurePercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Run Disruptions',
    description: 'Run Disruptions / Overall Run Disruptions Plays',
    key: 'runDisruptionPercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: '% of Tackles for loss',
    description: 'Tackles for Loss / Overall Tackles for Loss Plays',
    key: 'tacklePercentage',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Havoc Pass Plays',
    description:
      'Pass Plays containing at least one havoc event (sack or pressure)',
    key: 'passPlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Havoc Run Plays',
    description:
      'Run Plays containing at least one havoc event (run disruption or tackle for loss)',
    key: 'runPlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Sack Plays',
    description: 'Plays containing at least one sack event',
    key: 'sackPlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Pressure Plays',
    description: 'Plays containing at least one pressure',
    key: 'pressurePlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Run Disruption Plays',
    description: 'Plays containing at least one run disruption',
    key: 'runDisruptionPlays',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Tackle for Loss Plays',
    description: 'Plays containing at least one tackle for loss event',
    key: 'tacklePlays',
    units: COUNT,
    isSortable: true,
  },
];
const HAVOC_EVENT_HEADERS = [
  {
    label: 'Total',
    description: 'Count of all line havoc events',
    key: 'havocEvents',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Pressures',
    description: 'Count of Pressures',
    key: 'pressureEvents',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Run Disruptions',
    description: 'Count of Run Disruptions',
    key: 'runDisruptionEvents',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Tackles for Loss',
    description: 'Count of Tackles (that resulted in a loss of yards)',
    key: 'tackleEvents',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Sacks',
    description: 'Count of Sacks',
    key: 'sackEvents',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Yards to LoS',
    description: 'Distance to the LoS at which the havoc event occurred',
    key: 'xLoS',
    units: SHORT_DECIMAL,
    isSortable: true,
  },
  {
    label: 'Time from Snap',
    description: 'Average time from snap at which havoc event occurs',
    key: 'postSnapSeconds',
    units: SHORT_DECIMAL,
    isSortable: true,
  },
];

export const getHavocHeaders = (aggregationMode, isPlaysMode = false) => {
  const groupColumn = {
    label: aggregationMode,
    key: 'name',
    units: STRING,
    isSortable: true,
  };
  const modeHeaders =
    HAVOC_TABLE_MODE_ADDITIONAL_HEADERS[aggregationMode] || [];
  const statHeaders = isPlaysMode ? HAVOC_PLAYS_HEADERS : HAVOC_EVENT_HEADERS;
  const columnHeaders = [groupColumn].concat(modeHeaders, statHeaders);
  const defaultColumnKey = 'tackleEvents';
  return { columnHeaders, defaultColumnKey };
};
