import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../../utils/helpers/contextFilters';

const overrides = { gameIds: false };

export const getTeamStats = (statsToQuery) => {
  const cat1StatNames = statsToQuery?.map((stat) => stat.name) || [];
  const joinedStatNames = cat1StatNames.join('\n');

  const gqlString = gql`
    query GetTeamStats(
      $competitionId: Int!
      $seasonId: Int!
      $groupBy: [TeamStatGroupBy!]
      $orderBy: [StatOrderBy!]
      $limit: Int
      $offset: Int
      $teamName: String
      $minPlays: Int
      ${getContextQueryVariables(overrides)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      ${getContextFilterClientExports(overrides)}

      teamStats(
        teamStatFilters: {
          teamName: $teamName
          minPlays: $minPlays
        }
        filters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          ${getContextFiltersQueryArguments(overrides)}
        }
        groupBy: $groupBy
        orderBy: $orderBy
        limit: $limit
        offset: $offset
      ) {
        items {
          groups {
            key
            value
          }
          teamName
          games
          playCount
          ${joinedStatNames}
        }
        totalCount
      }
    }
  `;

  return gqlString;
};
