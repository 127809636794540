/* eslint-disable max-len */
export const DATA_DICTIONARY_EVENTS = [
  {
    field: 'event_uuid',
    fieldType: 'uuid',
    fieldValues: 'c8643c36-b00a-4e54-a156-842a1b79cda9',
    fieldDescription: 'Unique identifier for the event',
  },
  {
    field: 'game_id',
    fieldType: 'integer',
    fieldValues: '1069669',
    fieldDescription: 'Unique identifier for the game',
  },
  {
    field: 'game_quarter',
    fieldType: 'integer',
    fieldValues: '1, 2',
    fieldDescription: 'Quarter the event takes place (OT is 5)',
  },
  {
    field: 'event_game_index',
    fieldType: 'integer',
    fieldValues: '7, 141',
    fieldDescription:
      'Ranked order of events over the series of a game (a value of 7 means that a event was the 8th collected event in that game).',
  },
  {
    field: 'play_uuid',
    fieldType: 'uuid',
    fieldValues: 'a2b88e4b-22ae-4d56-b895-ff23ab774afd',
    fieldDescription:
      'Unique identifier for the play (will be null for certain non-play event types like Quarter Start or Camera Paused)',
  },
  {
    field: 'event_video_timestamp',
    fieldType: 'decimal',
    fieldValues: '68.055408',
    fieldDescription:
      'Internal measure of when the event occurred relative to the video used to collect the game',
  },
  {
    field: 'game_clock',
    fieldType: 'integer',
    fieldValues: '900000 (start of quarter), 752965',
    fieldDescription:
      'Remaining clock time left in the quarter in milliseconds (a value of 752965 equates to 12:33 left in quarter)',
  },
  {
    field: 'team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription:
      'Unique team identifier for the player/team whose event is being recorded',
  },
  {
    field: 'team_attacking_left_to_right',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'True if offensive team is moving the ball from left to right from the vantage point of the camera',
  },
  {
    field: 'event_types',
    fieldType: 'array',
    fieldValues:
      '{Catch,"From Kick",Return,"Kickoff Return"}, {"Tackle Attempt"}',
    fieldDescription:
      'An event can have 1 or more event_types associated with it (see "definition: event_types" tab for full list)',
  },
  {
    field: 'player_id',
    fieldType: 'integer',
    fieldValues: '1024073',
    fieldDescription:
      'For events that describe an action [Catch, Pass, Pressure, Tackle], this is the person performing that action',
  },
  {
    field: 'receiver_player_id',
    fieldType: 'integer',
    fieldValues: '1024021',
    fieldDescription:
      'The teammate of the player taking the primary action. For a pass, this is the player being targeted. For a handoff, this is the player receiving the ball.',
  },
  {
    field: 'opponent_player_id',
    fieldType: 'integer',
    fieldValues: '1023386',
    fieldDescription:
      'The opposing player being targeted by the primary action. For a pressure, this is the QB being pressured. For a tackle attempt, this is the offensive player trying to be tackled',
  },
  {
    field: 'event_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the event started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the event started (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_end_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the event\'s (ie pass, kick, batted ball) action ended.  (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_end_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the event\'s (ie pass, kick, batted ball) action ended (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_duration',
    fieldType: 'decimal',
    fieldValues: '0.430874, 3.88031',
    fieldDescription: 'Number of seconds from the start to the end of an event',
  },
  {
    field: 'event_success',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the event was successful for the player ID (eg on a Tackle Avoided event, this field would be TRUE for the ball carrier and this field would be FALSE for the corresponding Tackle event for the defensive player). Null if irrelevant for event.',
  },
  {
    field: 'event_points',
    fieldType: 'integer',
    fieldValues: '0, 1, 2, 3, 6',
    fieldDescription: 'Points scored from this event',
  },
  {
    field: 'event_catch_fail_type',
    fieldType: 'string',
    fieldValues: "Dropped', 'Missed', 'Broken Up', 'Out'",
    fieldDescription:
      "Values are only populated when an Event Type of unsuccessful  Catch is recorded (is null otherwise). Field can have only 1 of the following values: 'Dropped', 'Missed', 'Broken Up', 'Out'",
  },
  {
    field: 'event_yards_after_catch',
    fieldType: 'decimal',
    fieldValues: '3.72, 6.53',
    fieldDescription: 'Yards made by receiver after the catch',
  },
  {
    field: 'event_kick_fail_type',
    fieldType: 'string',
    fieldValues: 'Blocked, Goalpost, Short, Wide Left, Wide Right',
    fieldDescription:
      'Values are only populated when an Event Type of unsuccessful Kick is recorded (is null otherwise). Field can have only 1 of the following values: Blocked, Goalpost, Short, Wide Left, Wide Right',
  },
  {
    field: 'event_kick_distance',
    fieldType: 'decimal',
    fieldValues: '22.96, 52.09',
    fieldDescription:
      'For all Kick Event Types, we collect the distance the kick traveled down the field (difference between event_x and event_end_x)',
  },
  {
    field: 'event_kick_lateral',
    fieldType: 'decimal',
    fieldValues: '-26.92, 17.36',
    fieldDescription:
      'For all Kick Event Types, we collect the distance the kick traveled across the field (difference between event_y and event_end_y)',
  },
  {
    field: 'event_forced_fumble',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription: 'Whether or not the event generated a forced fumble',
  },
  {
    field: 'event_pass_air_yards',
    fieldType: 'decimal',
    fieldValues: '-2.95, 6.77',
    fieldDescription:
      'How far down the field the pass traveled (difference in the x axis between the LOS and the catch location)',
  },
  {
    field: 'event_pass_catchable',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the pass was calculated to be close enough to the receiver to be catchable',
  },
  {
    field: 'event_pass_time_to_throw',
    fieldType: 'decimal',
    fieldValues: '0.83207, 2.755212',
    fieldDescription:
      'Attached to pass event -- calculation of time from snap to pass',
  },
  {
    field: 'event_penalty_offset',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Only populated on Penalty Event Types -- set to true if there were penalties on both teams that (at least partially) offset one another',
  },
  {
    field: 'event_penalty_yards',
    fieldType: 'decimal',
    fieldValues: '-8.2, -5, 15',
    fieldDescription: 'Yards gained or lost on the penalty',
  },
  {
    field: 'event_penalty_type',
    fieldType: 'text',
    fieldValues: 'holding, illegal-substitution',
    fieldDescription:
      'Description of the penalty (multiple words separated with a hyphen)',
  },
  {
    field: 'event_penalty_down_lost',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription: 'Whether or not the penalty was a loss of down',
  },
  {
    field: 'event_penalty_replay_down',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the penalty leads to the down being replayed',
  },
  {
    field: 'event_snap_accuracy',
    fieldType: 'text',
    fieldValues: '',
    fieldDescription: '',
  },
  {
    field: 'event_snap_under_center',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the QB took the snap directly under center',
  },
  {
    field: 'event_tackle_contact',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the tackle attempt made contact. Will be FALSE in the case where a ball carrier jukes to avoid contact on a tackle attempt',
  },
  {
    field: 'preceding_tackle_attempt_uuid',
    fieldType: 'uuid',
    fieldValues: '86a5e577-8887-40c4-a27f-714ad9a4d43c',
    fieldDescription:
      'Event id of the tackle attempt that will be paired to a tackle made event',
  },
  {
    field: 'opposing_tackle_uuid',
    fieldType: 'uuid',
    fieldValues: '87d74838-01ad-4238-ae74-8e591912d31a',
    fieldDescription:
      'On the Tackled, Tackle Avoided, or Tackle Broken event for the ball carrier, this is the event id of the tackle attempt of the defender',
  },
  {
    field: 'preceding_ball_origin_uuid',
    fieldType: 'uuid',
    fieldValues: '0959c2f0-b289-41bb-87a2-4cded8494217',
    fieldDescription:
      "For events of type ['Catch','Out','Batted-Ball','Block','Passer-Hit','Ball To Ground’] this is the event id of the originating event (eg for a catch from snap event, this is the event id of the snap. for a break-up pass event, this is the event id of the pass)",
  },
  {
    field: 'opposing_pressured_event_uuid',
    fieldType: 'uuid',
    fieldValues: '8532bd7c-cbc2-41bf-8d0e-eca378d618ae',
    fieldDescription:
      'For a pressure event, if there is a pass/handoff/kick/lateral following the pressure, this is the event id.',
  },
  {
    field: 'opposing_penalty_event_uuid',
    fieldType: 'uuid',
    fieldValues: '546065f4-771a-4eeb-a490-f706013a67ff',
    fieldDescription:
      'For offsetting penalties, this is event id for the event that generated the other penalty',
  },
  {
    field: 'event_pass_underthrow',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the pass was determined by the collector to be underthrown',
  },
  {
    field: 'event_pass_overthrow',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not the pass was determined by the collector to be overthrown',
  },
  {
    field: 'event_lateral_technique',
    fieldType: 'string',
    fieldValues: 'Throw, Toss',
    fieldDescription:
      'Description of lateral technique. Only two possible values when present: Throw, Toss',
  },
  {
    field: 'event_pass_placement_x',
    fieldType: 'decimal',
    fieldValues: '0.2167',
    fieldDescription:
      "When the pass reaches the target (or the point where the pass is closest to the targeted receiver), how far away (in yards) the ball is from the center of the receiver's body",
  },
  {
    field: 'event_pass_placement_y',
    fieldType: 'decimal',
    fieldValues: '1.2708',
    fieldDescription:
      'How high (in yards) the ball was from the ground at the point when the pass reaches the target (or the point where the pass is closest to the targeted receiver)',
  },
  {
    field: 'event_score_fail_type',
    fieldType: 'string',
    fieldValues: 'Reversed by Review, Discounted by Penalty',
    fieldDescription:
      'For scoring events, this describes why the scoring event was negated. Only two possible values when present: Reversed by Review, Discounted by Penalty',
  },
  {
    field: 'event_stoppage_review_outcome',
    fieldType: 'string',
    fieldValues: 'Ruling Reversed, Ruling Upheld',
    fieldDescription:
      'Outcome of a challenge. Only two possible values when present: Ruling Reversed, Ruling Upheld',
  },
  {
    field: 'event_pass_throwaway',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription: 'Whether or not the pass was deliberately thrown away',
  },
  {
    field: 'event_pass_out_of_bounds',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription: 'Whether or not the pass landed out of bounds',
  },
  {
    field: 'event_pass_touchdown',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription: 'Whether or not the pass resulted in a touchdown',
  },
  {
    field: 'event_pass_target_x',
    fieldType: 'decimal',
    fieldValues: '27.47510177',
    fieldDescription:
      'x coordinate of the location of the targeted receiver at the time of pass (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'event_pass_target_y',
    fieldType: 'decimal',
    fieldValues: '19.49409071',
    fieldDescription:
      'y coordinate of the location of the targeted receiver at the time of pass (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'start_engagement_uuid',
    fieldType: 'uuid',
    fieldValues: '76a1277a-dd18-4c26-90b0-b5dd4f715c0c',
    fieldDescription:
      'For an Engagement End event, this is the event id of the corresponding Engagement Start event. It is otherwise null',
  },
  {
    field: 'end_engagement_uuid',
    fieldType: 'uuid',
    fieldValues: '3170c71b-0156-43cb-8c03-51ac770a57c3',
    fieldDescription:
      'For an Engagement Start event, this is the event id of the corresponding Engagement End event. It is otherwise null',
  },
  {
    field: 'event_pass_yards_gained',
    fieldType: 'decimal',
    fieldValues: '-5.68, 42.69',
    fieldDescription: 'Pass yards gained on the play',
  },
  {
    field: 'base_event_uuid',
    fieldType: 'uuid',
    fieldValues: '',
    fieldDescription:
      'For events generated based on other events, such as Tackle Attempt/Tackled or Engagement Start/End, this field is populated for the second event in the pair with a reference to the first event. For example, for a Tackled event, this would show the event ID of the Tackle Attempt.',
  },
  {
    field: 'event_tackle_first_contact',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE, NULL',
    fieldDescription:
      'Whether or not tackle is the first contact made by defense during this play with the ball carrier',
  },
];
