import React from 'react';
import PropTypes from 'prop-types';
import DownloadCSVButton from '../buttons/DownloadCSV';
import GoTo from '../buttons/GoTo';
import BasicTable from '../Table/BasicTable';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../Tile/TileSummary.styles';

// TableTile is a component that displays a table of data with a title and optional download button
// This is the proposed new version and will replace the existing TableTile
const TableSummaryTile = ({
  data,
  tileTitle,
  columnHeaders,
  showColumnHeader,
  fileName,
  error,
  loading,
  linkHref,
  iconSize,
  iconStyle,
  withBorder,
  highlightedColumn,
  footer,
}) => (
  <SummaryTile dataTestId="tableTileT">
    <SummaryTileHeader>
      {tileTitle && (
        <>
          <div>
            <h3 data-testid="header2T" title={tileTitle}>
              {tileTitle}
            </h3>
          </div>
          <div>
            {!error && !loading && data && (
              <div style={iconStyle}>
                <DownloadCSVButton
                  data={data}
                  headers={columnHeaders}
                  fileName={fileName}
                  data-testid="downloadCSVButtonT"
                  iconSize={iconSize}
                />
              </div>
            )}
            {linkHref && (
              <GoTo href={linkHref} linkName="Open in Play Animation" />
            )}
          </div>
        </>
      )}
    </SummaryTileHeader>
    <SummaryTileBody
      $padding="0"
      $margin="0.5rem"
      $minHeight="auto"
      data-testid="tableHolderT"
    >
      {!error && !loading && data && (
        <BasicTable
          data={data}
          headers={columnHeaders}
          showHeader={showColumnHeader}
          withBorder={withBorder}
          highlightedColumn={highlightedColumn}
          footer={footer}
        />
      )}
    </SummaryTileBody>
  </SummaryTile>
);

TableSummaryTile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  tileTitle: PropTypes.string.isRequired,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  showColumnHeader: PropTypes.bool,
  fileName: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  linkHref: PropTypes.string,
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  iconStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  withBorder: PropTypes.bool,
  // which column the table sorting is currently based on (1 based index)
  highlightedColumn: PropTypes.number,
  // Basic Table footer
  footer: PropTypes.arrayOf(PropTypes.shape({})),
};

TableSummaryTile.defaultProps = {
  data: undefined,
  columnHeaders: undefined,
  showColumnHeader: true,
  fileName: 'table.csv',
  error: false,
  loading: false,
  linkHref: null,
  iconSize: 'small',
  iconStyle: {},
  withBorder: false,
  highlightedColumn: 0,
  footer: undefined,
};

export default TableSummaryTile;
