import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@statsbomb/kitbag-components';

/*
 * a button to be used for displaying / hiding the key within a VIS
 */
const KeyButton = ({ showKey, handleShowKey, iconSize }) => (
  <ButtonIcon
    onClick={handleShowKey}
    variant="secondary"
    size={iconSize}
    off={!showKey}
    icon="Key"
    title={
      showKey
        ? 'Visualisation Key is being displayed, click to hide'
        : 'Visualisation Key is hidden, click to display'
    }
  />
);

KeyButton.propTypes = {
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  showKey: PropTypes.bool,
  handleShowKey: PropTypes.func,
};

KeyButton.defaultProps = {
  iconSize: 'small',
  showKey: undefined,
  handleShowKey: () => {},
};

export default KeyButton;
