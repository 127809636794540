import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { select } from 'd3';
import { useD3 } from '../../utils/hooks/useD3';
import {
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_CLASS_NAMES,
  CHART_AREA_SIZES,
  PASS_PLACEMENT_SCALES,
  PASS_PLACEMENT_SCALE_DEFAULTS,
} from './PassPlacement.constants';
import { getPalette } from '../../utils/visualisations/visPalettes';
import {
  drawPasses,
  drawGrid,
  drawCatcher,
  drawAxes,
} from './PassPlacement.drawing';
import { linearScaleBuilder } from '../../utils/visualisations/axes';
import { addBackgroundRect } from '../../utils/visualisations/basic';
import { drawPassPlacementKey } from './PassPlacementKey/PassPlacementKey';

const PassPlacement = function ({
  data,
  showCatcher,
  showFailsRing,
  showBallSize,
  colorMode,
  showKey,
  margin,
  selectedPlay,
  setSelectedPlay,
  aspectRatioName,
  plusMinusX,
}) {
  const { isDark } = useTheme();
  const visPalette = getPalette(isDark);
  const selectionEnabled = !!setSelectedPlay; // if no selection function, selection is disabled

  const passPlacementChartWidth = CHART_AREA_SIZES.MAIN_WIDTH; // basic size choice
  const aspectRatio =
    PASS_PLACEMENT_SCALES.find((a) => a.ASPECT_RATIO === aspectRatioName) ||
    PASS_PLACEMENT_SCALES[0];
  const maxX =
    plusMinusX ||
    aspectRatio.PLUS_MINUS_X_OPTIONS[aspectRatio.DEFAULT_PLUS_MINUS_X_INDEX];
  const passPlacementChartHeight =
    passPlacementChartWidth * aspectRatio.RATIO_Y_OVER_X; // defaults to 3:2 ratio
  const maxY = maxX * 2 * aspectRatio.RATIO_Y_OVER_X;

  /* Define the scaling functions according to chart size */
  const xScale = linearScaleBuilder(-maxX, maxX, 0, passPlacementChartWidth);
  /* 
  Default scale for sizing things (player / football)
  Inverted scale for ground (y=0) at bottom 
  */
  const yScale = linearScaleBuilder(0, maxY, 0, passPlacementChartHeight);
  const yScaleInverted = linearScaleBuilder(
    maxY,
    0,
    0,
    passPlacementChartHeight
  );

  const chart_w = passPlacementChartWidth + CHART_AREA_SIZES.AXIS_SPACE;
  const chart_h = passPlacementChartHeight + CHART_AREA_SIZES.AXIS_SPACE;
  const key_margin_top = 10;
  const key_h = showKey ? key_margin_top + 30 : 0; // 30 for actual key stuff
  const vbw = chart_w + margin.left + margin.right;
  const vbh = chart_h + key_h + margin.top + margin.bottom;
  const viewBox = `0 0 ${vbw} ${vbh}`;

  const chartAreaClipPath = 'pass-placement-chart-area-clip-path';

  /* Static Bit - set up classes that get modded */
  const ref = useD3(
    (svg) => {
      // reset svg
      svg.selectAll('*').remove();

      svg
        .attr('id', 'pass-placement-vis')
        .attr('width', '100%')
        .attr('viewBox', viewBox);

      addBackgroundRect(svg, visPalette.background.main);

      /* Add logic so clicking anywhere deselects */
      svg.on('click', (e) => {
        if (e.target.classList.value !== 'dot') {
          setSelectedPlay(null);
        }
      });

      const within_margin_transform = `translate(${margin.left},${margin.top})`;
      const marginAdjustedArea = svg
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.MARGIN)
        .attr('data-testid', 'marginAdjusted')
        .attr('transform', within_margin_transform);

      marginAdjustedArea
        .append('defs')
        .append('clipPath')
        .attr('id', chartAreaClipPath)
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', passPlacementChartWidth)
        .attr('height', passPlacementChartHeight);

      /* Define area within the axes, and use clip-path to prevent overflow */
      const within_axis_transform = `translate(${CHART_AREA_SIZES.AXIS_SPACE},0)`;
      const chartAreaUnclipped = marginAdjustedArea
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.UNCLIPPED)
        .attr('transform', within_axis_transform);

      const chartAreaClipped = marginAdjustedArea
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.CLIPPED)
        .attr('transform', within_axis_transform)
        .attr('clip-path', `url(#${chartAreaClipPath})`);

      const clippedChild = chartAreaClipped.append('g');
      const axesZone = marginAdjustedArea
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.AXES)
        .attr('transform', within_axis_transform);

      // catcher is unclipped, bottomm layer
      const catcherZone = chartAreaUnclipped
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.CATCHER);
      catcherZone.call(
        drawCatcher,
        xScale,
        yScale,
        yScaleInverted,
        showCatcher,
        isDark
      );

      // clipped zone for grid/passes
      const gridZone = clippedChild
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.GRID);
      gridZone.call(drawGrid, visPalette, xScale, yScaleInverted);

      clippedChild
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.DOTS_PLAYER)
        .attr('data-testid', 'dotZone');

      /* axes layer unclipped above grid */
      axesZone.call(drawAxes, visPalette, xScale, yScaleInverted, maxX, maxY);

      /* Add a <g> for the key */
      marginAdjustedArea
        .append('g')
        .attr('class', PASS_PLACEMENT_CLASS_NAMES.KEY)
        .attr(
          'transform',
          `translate(${CHART_AREA_SIZES.AXIS_SPACE},${
            chart_h + key_margin_top
          })`
        );
    },
    [isDark]
  );

  /* When things change: redraw */
  useEffect(() => {
    const svg = select(ref.current);

    svg.attr('viewBox', viewBox);

    const clipPath = svg.select(`#${chartAreaClipPath}`);
    clipPath.selectAll('rect').remove();
    clipPath
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', passPlacementChartWidth)
      .attr('height', passPlacementChartHeight);

    svg
      .select(`.${PASS_PLACEMENT_CLASS_NAMES.CATCHER}`)
      .call(drawCatcher, xScale, yScale, yScaleInverted, showCatcher, isDark);
    svg
      .select(`.${PASS_PLACEMENT_CLASS_NAMES.AXES}`)
      .call(drawAxes, visPalette, xScale, yScaleInverted, maxX, maxY);
    svg
      .select(`.${PASS_PLACEMENT_CLASS_NAMES.GRID}`)
      .call(drawGrid, visPalette, xScale, yScaleInverted);

    if (data) {
      const circlesG = svg.select(`.${PASS_PLACEMENT_CLASS_NAMES.DOTS_PLAYER}`);
      drawPasses(
        circlesG,
        visPalette,
        data,
        selectedPlay,
        colorMode,
        showFailsRing,
        showBallSize,
        xScale,
        yScale,
        yScaleInverted,
        selectionEnabled,
        setSelectedPlay
      );
    }
  }, [
    data,
    showCatcher,
    showFailsRing,
    showBallSize,
    colorMode,
    selectedPlay,
    aspectRatioName,
    plusMinusX,
    showKey,
    isDark,
  ]);

  /* Redraw key has fewer dependencies */
  useEffect(() => {
    const svg = select(ref.current);
    const keyG = svg.select(`.${PASS_PLACEMENT_CLASS_NAMES.KEY}`);
    keyG.attr(
      'transform',
      `translate(${CHART_AREA_SIZES.AXIS_SPACE},${chart_h + key_margin_top})`
    );
    keyG.selectAll('g').remove();
    if (showKey) {
      drawPassPlacementKey(
        keyG,
        colorMode,
        visPalette,
        isDark,
        passPlacementChartWidth,
        showFailsRing,
        showBallSize
      );
    }
  }, [
    showKey,
    colorMode,
    aspectRatioName,
    plusMinusX,
    showFailsRing,
    showBallSize,
    isDark,
  ]);

  return (
    <svg ref={ref} data-testid="passPlacementChart" id="passPlacementChart" />
  );
};

PassPlacement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCatcher: PropTypes.bool,
  showFailsRing: PropTypes.bool,
  showBallSize: PropTypes.bool,
  colorMode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  aspectRatioName: PropTypes.string,
  plusMinusX: PropTypes.number,
  showKey: PropTypes.bool,
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  selectedPlay: PropTypes.object,
  setSelectedPlay: PropTypes.func,
};

PassPlacement.defaultProps = {
  data: null,
  showCatcher: false,
  showFailsRing: true,
  showBallSize: true,
  colorMode: PASS_PLACEMENT_COLOR_MODES.LOCATION,
  aspectRatioName: PASS_PLACEMENT_SCALE_DEFAULTS.ASPECT_RATIO,
  plusMinusX: null,
  showKey: false,
  margin: { top: 20, right: 20, bottom: 10, left: 10 }, // minimal margins as axis "feel" like space
  selectedPlay: null,
  setSelectedPlay: () => {},
};

export default PassPlacement;
