import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { replaceHrefTokens } from '../../utils/helpers/general';
import {
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Games,
  mf_Players,
  pf_Drive,
  pf_TeamPlay,
} from '../../apollo';
import ExternalLink from '../ExternalLink/ExternalLink';

const ParsedLink = function ({ href, children }) {
  const rv = {
    leagues: parseInt(useReactiveVar(mf_Leagues), 10),
    players: parseInt(useReactiveVar(mf_Players), 10),
    seasons: parseInt(useReactiveVar(mf_Seasons), 10),
    teams: parseInt(useReactiveVar(mf_Teams), 10),
    games: parseInt(useReactiveVar(mf_Games), 10),
    drives: useReactiveVar(pf_Drive),
    plays: useReactiveVar(pf_TeamPlay),
  };
  const parsedHref = replaceHrefTokens(href, rv);
  return <ExternalLink href={parsedHref}>{children}</ExternalLink>;
};

ParsedLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ParsedLink.defaultProps = {
  children: null,
};

export default ParsedLink;
