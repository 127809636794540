import { isEmpty } from 'lodash';
import { AXES_PADDING_SIZE } from './PassingChart.constants';
import { ROTATIONS } from '../../utils/constants/charting';

export const paddingAndRotation = (
  orientation,
  displayYPassRelative,
  fieldSizeX,
  fieldSizeY
) => {
  if (orientation === ROTATIONS.VERTICAL_UP) {
    const axesPadding = {
      bottom: displayYPassRelative ? AXES_PADDING_SIZE : 0,
      left: AXES_PADDING_SIZE,
      right: AXES_PADDING_SIZE,
      top: 0,
    };
    const fieldRotation = {
      angle: -90,
      rotationPoint: fieldSizeX / 2,
    };
    return { axesPadding, fieldRotation };
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    const axesPadding = {
      bottom: 0,
      left: AXES_PADDING_SIZE,
      right: AXES_PADDING_SIZE,
      top: displayYPassRelative ? AXES_PADDING_SIZE : 0,
    };
    const fieldRotation = {
      angle: 90,
      rotationPoint: fieldSizeY / 2,
    };
    return { axesPadding, fieldRotation };
  }
  /* Default axes padding and rotation for horizontal mode */
  const axesPadding = {
    bottom: AXES_PADDING_SIZE,
    left: displayYPassRelative ? AXES_PADDING_SIZE : 0,
    right: 0,
    top: AXES_PADDING_SIZE,
  };
  const fieldRotation = {
    angle: 0,
    rotationPoint: 0,
  };
  return { axesPadding, fieldRotation };
};

export const partitionData = (
  data,
  selectedPlayIds,
  selectedEventId,
  displayHeatMap
) => {
  if (isEmpty(data)) {
    return { focusData: [], backgroundData: [] };
  }
  if (isEmpty(selectedPlayIds) && !selectedEventId) {
    /** heatmap mode hide everything unless focused */
    if (displayHeatMap) {
      return { focusData: [], backgroundData: data };
    }
    /** regular mode show everything unless unfocused */
    return { focusData: data, backgroundData: [] };
  }
  const focusData = data.filter(
    (d) => selectedPlayIds.includes(d.play.id) || selectedEventId === d.id
  );
  const backgroundData = data.filter(
    (d) => !selectedPlayIds.includes(d.play.id) && selectedEventId !== d.id
  );
  return { focusData, backgroundData };
};
