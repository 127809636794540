import React from 'react';
import PropTypes from 'prop-types';
import WizardAccordion from '../../PlayFinderWizard/WizardAccordion/WizardAccordion';
import { getPlayFiltersWidget } from './PlayFinderWidgets';
import { PLAY_FINDER_WIDGET_TYPES } from '../../../../utils/constants/api';

const WizardAccordionWrapper = ({ widgetProps, title }) => (
  <WizardAccordion title={title}>
    {getPlayFiltersWidget(widgetProps)}
  </WizardAccordion>
);

export default WizardAccordionWrapper;

WizardAccordionWrapper.propTypes = {
  widgetProps: PropTypes.shape({
    rangeValues: PropTypes.array.isRequired,
    setRangeValues: PropTypes.func.isRequired,
    filterId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    widgetType: PropTypes.oneOf(Object.values(PLAY_FINDER_WIDGET_TYPES)).isRequired,
    widgetArgs: PropTypes.object.isRequired,
    handleCallback: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
