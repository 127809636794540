import { gql } from '@apollo/client';

export const SEARCH_CITIES = gql`
  query SearchCities($searchString: String!) {
    searchCities(searchString: $searchString, maxResults: 10) {
      id
      name
      state
    }
  }
`;
