import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { Grid } from '@statsbomb/kitbag-components';
import { useReactiveState } from '../../../utils/hooks/useReactiveState';
import Tile from '../../../components/Tile/Tile';
import BoxScoreTable from './TeamBoxScoreTable';
import { GET_TEAM_BOX_SCORE } from './getTeamBoxScore';
import { mf_Teams } from '../../../apollo';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Dimmer from '../../../components/Dimmer/Dimmer';

const BoxScore = () => {
  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_TEAM_BOX_SCORE);
  const [teamId] = useReactiveState(mf_Teams);

  const chartData = data?.boxScores?.edges?.map(({ node }) => node);

  const hasData = !!chartData?.length;

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <PageHeader
            href="/team/box/:leagues/:seasons/:teams/"
            rootPage="Team"
            activePage="Box Score"
            showTeamName
            showPlayerName={false}
          />
        </Grid>

        <Grid item xs={12}>
          <Tile border="0" margin="0 0 0 0">
            <Tile.Body $minHeight="790px">
              {hasError && (
                <Message negative>
                  There has been an error. Please contact support.
                </Message>
              )}
              {isLoading && (
                <Dimmer active>
                  <Loader content="Loading" />
                </Dimmer>
              )}
              {hasData && <BoxScoreTable data={chartData} teamId={teamId} />}
              {!isLoading && !hasData && (
                <p>No data available for selected team</p>
              )}
            </Tile.Body>
          </Tile>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default BoxScore;
