export const BAND_ZONE_WIDTH = 24; // yds
export const BAND_ZONE_LOS_X = 15; // yds
export const HASH_MARK_ZONE_PADDING = 1; // yds ~ making the zones on the hash marks 1.667 yds wide total
export const KEY_HEIGHT = 40;

export const SNAP_DISTRIBUTION_LAYER_CLASSES = {
  MARGIN_ADJUSTED: 'sd-in-margin',
  DATA: 'sd-data',
  DATA_TEXT: 'sd-background-text',
  DATA_DOTS: 'sd-data-dots',
  DATA_DOTS_TOTAL: 'sd-data-dots-total',
  DATA_DOTS_LEFT_HASH: 'sd-data-dots-ls',
  DATA_DOTS_LEFT_CENTER: 'sd-data-dots-lc',
  DATA_DOTS_RIGHT_CENTER: 'sd-data-dots-rc',
  DATA_DOTS_RIGHT_HASH: 'sd-data-dots-rs',
  KEY: 'sd-key',
  DOTS_HEAT_SUFFIX: '-dots-and-heatmaps',
  DATA_SELECTION_SUFFIX: '-data-selection',
};

export const HEATMAP_COLOR_MODE_TYPES = {
  INDEPENDENT: 'INDEPENDENT',
  VS_TOTAL_MAX: 'TOTAL_MAX',
  VS_PARTITION_MAX: 'PARTITION_MAX',
};

export const HEATMAP_COLOR_MODES = [
  {
    value: HEATMAP_COLOR_MODE_TYPES.INDEPENDENT,
    label: 'Separate Scaling per Band',
  },
  { value: HEATMAP_COLOR_MODE_TYPES.VS_TOTAL_MAX, label: 'Scale by Total' },
  {
    value: HEATMAP_COLOR_MODE_TYPES.VS_PARTITION_MAX,
    label: 'Scale to Band Maximum',
  },
];

export const DOT_COLOR_MODE = {
  DEFAULT: 'DEFAULT',
  PLAY_TYPE: 'PLAY_TYPE',
  DOWN: 'DOWN',
  PLAY_SUCCESS: 'PLAY_SUCCESS',
  ROUTE: 'ROUTE',
  YARDS_GAINED: 'YARDS_GAINED',
  GHOST: 'GHOST',
};

export const DOT_COLOR_MODES = [
  { value: DOT_COLOR_MODE.DEFAULT, label: 'Default' },
  { value: DOT_COLOR_MODE.PLAY_TYPE, label: 'Play Type' },
  { value: DOT_COLOR_MODE.ROUTE, label: 'Routes & Targets' },
  { value: DOT_COLOR_MODE.DOWN, label: 'Down' },
  { value: DOT_COLOR_MODE.PLAY_SUCCESS, label: 'Play Outcome' },
  { value: DOT_COLOR_MODE.YARDS_GAINED, label: 'Yards Gained' },
];

export const ROUTE_COLOR_MODES = [
  ...DOT_COLOR_MODES,
  { value: DOT_COLOR_MODE.GHOST, label: 'Monochrome' },
];

export const SNAP_DISTRIBUTION_CONFIG = {
  TOTAL: {
    CLASS: SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_TOTAL,
    BAND_DATA: 'all',
    LABEL: 'All Snaps',
  },
  LEFT_HASH: {
    CLASS: SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_LEFT_HASH,
    BAND_DATA: 'leftHash',
    LABEL: 'Left Hash Snaps',
  },
  LEFT_CENTER: {
    CLASS: SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_LEFT_CENTER,
    BAND_DATA: 'leftCenter',
    LABEL: 'Left Center Snaps',
  },
  RIGHT_CENTER: {
    CLASS: SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_RIGHT_CENTER,
    BAND_DATA: 'rightCenter',
    LABEL: 'Right Center Snaps',
  },
  RIGHT_HASH: {
    CLASS: SNAP_DISTRIBUTION_LAYER_CLASSES.DATA_DOTS_RIGHT_HASH,
    BAND_DATA: 'rightHash',
    LABEL: 'Right Hash Snaps',
  },
};

export const SNAP_BANDS = Object.values(SNAP_DISTRIBUTION_CONFIG);
