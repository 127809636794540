import { gql } from '@apollo/client';

export const GET_GAMES = gql`
  query GetGames($teamId: [Int!], $competitionId: Int!, $seasonId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    games(
      first: 100
      filters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: $teamId
      }
    ) {
      edges {
        node {
          id
          name
          date
          phaseName
          roundShortName
          homeScore
          awayScore
          dataComplete
          shortName
          homeTeam {
            id
            name
            shortName
          }
          awayTeam {
            id
            name
            shortName
          }
        }
      }
    }
  }
`;
