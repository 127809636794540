import React, { useState } from 'react';
import { sumBy } from 'lodash';
import { ButtonIcon, Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import GoTo from '../../../components/buttons/GoTo';
import { SummaryError } from '../../../components/Tile/TileSummary.styles';
import Tile from '../../../components/Tile/Tile';
import RunGapScatter from '../lineBattles/RunTendencies/RunGapScatter/RunGapScatter';
import {
  getAverageTotals,
  getGapData,
  getPOAData,
  getSelectedPlayersData,
} from '../lineBattles/RunTendencies/RunTendencies.dataManipulation';
import {
  useGetRunGapData,
  useGetRunLeagueAverageData,
} from '../lineBattles/RunTendencies/RunTendencies.hooks';
import {
  RUN_TENDENCY_AXES,
  SELECTED_PLAYERS,
} from '../lineBattles/RunTendencies/RunTendencies.constants';
import useQueryString from '../../../utils/hooks/useQueryString';
import RunKey from '../lineBattles/RunTendencies/RunKey/RunKey';
import RunPOAHistogram from '../lineBattles/RunTendencies/RunPOAHistogram/RunPOAHistogram';
import { TileInlineLoader } from './TeamOverview.styles';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import { RUN_TENDENCIES_TILE_OPTIONS } from './TeamRunTendencies.Tile.constants';

const TeamRunTendencies = function () {
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'tendenciesKey',
    true
  );
  const [showRunGaps, setShowRunGaps] = useQueryString('showRunGaps', true);

  const [selectedPlayers] = useState(SELECTED_PLAYERS);
  const bubbleYAxis = RUN_TENDENCY_AXES.yardsGained.value;
  const bubbleRAxis = RUN_TENDENCY_AXES.playPercentage.value;
  const bubbleColoring = RUN_TENDENCY_AXES.successRate.value;

  /* Team data from API */
  const { teamRunGapData, teamRunPOAData, totalPlays, isLoadingRun, error } =
    useGetRunGapData();

  /* League average from API */
  const {
    leagueRunGapData,
    leagueRunPOAData,
    loading: loadingLA,
    error: errorLA,
  } = useGetRunLeagueAverageData();
  const hasError = !!error || !!errorLA;
  const isLoading = isLoadingRun || loadingLA;

  /* Average Totals (bubble chart values), League and Team */
  const leagueAverageTotals = getAverageTotals(leagueRunGapData);
  const teamAverageTotals = getAverageTotals(
    teamRunGapData,
    leagueAverageTotals
  );

  /* Get Gap and POA data matching player selections */
  const selectedPlayersRunData = getSelectedPlayersData(
    teamRunGapData,
    selectedPlayers
  );

  const selectedPlayersPOAData = getSelectedPlayersData(
    teamRunPOAData,
    selectedPlayers
  );
  const playTotals = {
    all: totalPlays,
    gapsFiltered: sumBy(selectedPlayersRunData, 'plays'),
    gapsTeam: sumBy(teamRunGapData, 'plays'),
    poaFiltered: sumBy(selectedPlayersPOAData, 'plays'),
    poaTeam: sumBy(teamRunPOAData, 'plays'),
  };
  /* Bubbles */
  const gapDataLeague = getGapData(
    leagueRunGapData,
    sumBy(leagueRunGapData, 'plays')
  );
  const gapDataTeam = getGapData(
    teamRunGapData,
    playTotals.gapsTeam,
    gapDataLeague
  );
  const gapData = getGapData(
    selectedPlayersRunData,
    playTotals.gapsFiltered,
    gapDataLeague
  );
  /* Distro */
  const poaDataLeague = getPOAData(
    leagueRunPOAData,
    sumBy(leagueRunPOAData, 'plays')
  );
  const poaDataTeam = getPOAData(
    teamRunPOAData,
    playTotals.poaTeam,
    poaDataLeague
  );
  const poaData = getPOAData(
    selectedPlayersPOAData,
    playTotals.poaFiltered,
    poaDataLeague
  );

  const hideScatterAverageLines = [
    RUN_TENDENCY_AXES.plays.value,
    RUN_TENDENCY_AXES.playPercentage.value,
  ].includes(bubbleYAxis);
  const gapLinesMetricName = hideScatterAverageLines
    ? null
    : RUN_TENDENCY_AXES[bubbleYAxis].label;

  const loadingMsg = () => {
    if (loadingLA) {
      return 'Loading League Average Data';
    }

    if (isLoadingRun) {
      return 'Loading Run Gap Data';
    }

    return '';
  };

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header style={{ maxHeight: '5.64rem' }} $border="2px">
        <h3>Run Tendencies</h3>

        <div className="buttons">
          <DropdownWrapper>
            <Dropdown
              id="team-run-tendencies-dropdown"
              components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon,
              }}
              isSearchable={false}
              menuAlignment="right"
              menuPlacement="bottom"
              onChange={(selectedOption) =>
                setShowRunGaps(selectedOption.value)
              }
              options={RUN_TENDENCIES_TILE_OPTIONS}
              value={RUN_TENDENCIES_TILE_OPTIONS.find(
                (item) => item.value === showRunGaps
              )}
              labelPosition="none"
              label="Passing chart display options"
              variant="tertiary"
              size="small"
            />
          </DropdownWrapper>
          <ButtonIcon
            size="small"
            variant="secondary"
            icon="Key"
            onClick={() => setDisplayChartKey(!displayChartKey)}
            off={!displayChartKey}
            title={`${displayChartKey ? 'hide' : 'display'} chart key`}
          >
            {`${displayChartKey ? 'hide' : 'display'} chart key`}
          </ButtonIcon>
          <GoTo
            href="/team/runTendencies/:leagues/:seasons/:teams/?distroYAxis=playPercentage"
            linkName="Open Team Run Tendencies"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body $padding="0.25rem" $margin="0 0.5rem 0.0625rem 0">
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div>
          <div style={{ display: showRunGaps ? 'block' : 'none' }}>
            <RunGapScatter
              runGapData={gapData}
              runGapDataTeam={gapDataTeam}
              teamAverageTotals={teamAverageTotals}
              leagueAverageTotals={leagueAverageTotals}
              yAxisKey={bubbleYAxis}
              rAxisKey={bubbleRAxis}
              colorAxisKey={bubbleColoring}
            />
          </div>
          <div style={{ display: showRunGaps ? 'none' : 'block' }}>
            <RunPOAHistogram
              poaData={poaData}
              poaDataTeam={poaDataTeam}
              poaDataLeague={poaDataLeague}
              yAxisKey={RUN_TENDENCY_AXES.playPercentage.value}
              colorAxisKey={RUN_TENDENCY_AXES.successRate.value}
            />
          </div>
          {displayChartKey && (
            <div style={{ padding: '0.625rem 0 0 1.25rem' }}>
              <div style={{ display: showRunGaps ? 'none' : 'block' }}>
                <RunKey
                  title="Run Point of Attack Bars"
                  isVertical={false}
                  colorAxisSetup={RUN_TENDENCY_AXES[bubbleColoring]}
                  linesMetricName={RUN_TENDENCY_AXES[bubbleYAxis].label}
                />
              </div>
              <div style={{ display: showRunGaps ? 'block' : 'none' }}>
                <RunKey
                  title="Run Gap Bubbles"
                  isVertical={false}
                  scalingAxisSetup={RUN_TENDENCY_AXES[bubbleRAxis]}
                  colorAxisSetup={RUN_TENDENCY_AXES[bubbleColoring]}
                  linesMetricName={gapLinesMetricName}
                />
              </div>
            </div>
          )}
        </div>
        {isLoading && (
          <TileInlineLoader>
            <Loader active inline size="small" content={loadingMsg()} />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

export default TeamRunTendencies;
