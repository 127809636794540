/* AMERICAN FOOTBALL VARIANT FIELD CONSTANTS
Unlike the normal field, the variants are designed to show only a part of the field, 
  or to distort it (primarily in terms of the Y axis) to visualise events in a relative manner,
  rather than absolutely on the field. 

Values in this file denote how these variant fields are sized
*/

const LOS_FIELD_DRAWING_DEFAULTS = {
  X_YARDS: 80,
  LOS_X: 20,
  Y_BORDERS: 0,
  AXIS_SPACE: 2, // Yards
};

const RELATIVE_Y_FIELD_MEASURES = {
  Y_YARDS: 70,
  Y_TENYARD_DISPLACEMENTS: [-30, -20, -10, 0, 10, 20, 30],
};

export { LOS_FIELD_DRAWING_DEFAULTS, RELATIVE_Y_FIELD_MEASURES };
