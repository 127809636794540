import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Icon } from '@statsbomb/kitbag-components';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { mf_TeamDetails, mf_Teams } from '../../../apollo';
import Tile from '../../../components/Tile/Tile';
import StatBlock from '../../../components/StatBlock/StatBlock';
import FormationDetailFilter from './FormationDetailFilter';
import FormationsDropdown from './FormationDropdown';
import {
  ChartFooter,
  FormationDetailHeader,
  FormationDetailMainContent,
  FormationDetailPage,
  Prompt,
  StatBlockContainer,
} from './TeamFormationDetail.styles';
import { useFormationUsages } from './TeamFormationDetail.hooks';
import {
  Controls,
  FormationSummarySectionHeader,
} from '../TeamFormationSummary/TeamFormationSummary.styles';
import { players, traits, mockTable } from './mockData';
import DepthChartTable from '../TeamDepthChart/DepthChartTable';
import { useTeamFormationLocations } from './useTeamFormationLocations';
import FormationDetailChart from '../../../visualisations/FormationDetailChart/FormationDetailChart';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { useTeamStatDistributions } from '../../../utils/hooks/useTeamStatDistributions';
import { useFormationSummaryStats } from '../TeamFormationSummary/useFormationSummaryStats';
import { useTableDataByFormation } from '../TeamFormationSummary/TeamFormationSummary.hooks';
import {
  FORMATIONS_CONFIG,
  STAT_BLOCKS,
} from './TeamFormationDetail.constants';
import { useFormationDetailStatDefinitions } from './useFormationDetailStatDefinitions';
import { FORMATION_SUMMARY_STAT_DISTRIBUTION } from '../TeamFormationSummary/TeamFormationSummary.constants';
import { formatFormationsForDropDown } from './TeamFormationDetail.dataManipulation';
import TraitAccordion from './TraitAccordion/TraitAccordion';

const TeamFormationDetail = () => {
  // global state
  const teamId = useReactiveVar(mf_Teams);
  const teamDetails = useReactiveVar(mf_TeamDetails);

  // local state
  const [isTraitsFilterOpen, setIsTraitsFilterOpen] = useState(false);
  const [isPlayersFilterOpen, setIsPlayersFilterOpen] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [isTeamMode, setIsTeamMode] = useState(true);

  // queries
  const {
    data: formationUsagesTeam,
    loading: formationUsagesLoading,
    error: formationUsagesError,
  } = useFormationUsages(teamId);
  const {
    data: formationLocations,
    loading: formationLocationsLoading,
    error: formationLocationsError,
  } = useTeamFormationLocations(selectedFormation);
  const {
    data: statDistributions,
    loading: statDistributionsLoading,
    error: statDistributionsError,
  } = useTeamStatDistributions(FORMATION_SUMMARY_STAT_DISTRIBUTION);
  const {
    data: statDefinitions,
    loading: statDefinitionsLoading,
    error: statDefinitionsError,
  } = useFormationDetailStatDefinitions();

  /*
  TODO: this is the same query (team stats grouped on formation as formationUsages)
    The formatting in this hook can be extracted and used by the blocks here and formation summary
    with this page then making two fewer queries against teamStats 
   */
  const {
    data: teamStatsByFormation,
    loading: teamStatsByFormationLoading,
    error: teamStatsByFormationError,
  } = useFormationSummaryStats(
    teamId,
    ['FORMATION'],
    statDistributions,
    isTeamMode,
    false
  );

  const info1 = useExportSeasonInfo();
  const selectedFormationUsage = formationUsagesTeam?.find(
    (formation) => formation.formationCode === selectedFormation
  );
  const totalTeamFormationPlayCount = selectedFormationUsage?.playCount;

  // calculate and format stat blocks
  const [statBlockData] = useTableDataByFormation(
    teamStatsByFormation,
    statDistributions,
    statDefinitions,
    isTeamMode,
    STAT_BLOCKS,
    FORMATIONS_CONFIG[selectedFormation]?.name
  );

  const statBlocks = STAT_BLOCKS.map((stat) => {
    const currentStat = statBlockData?.[stat];
    const definition = statDefinitions?.find((def) => def.name === stat);

    return {
      statName: definition?.abbrev,
      value: currentStat?.label,
      backgroundColor: currentStat?.color,
      color: currentStat?.ink,
    };
  });

  const playerLocations = formationLocations?.find(
    (d) => d.backfield === 'ALL'
  ).playerLocs;

  useEffect(() => {
    if (!selectedFormation && formationUsagesTeam?.length) {
      setSelectedFormation(formationUsagesTeam[0].formationCode);
    }
  }, [formationUsagesLoading]);

  const handleChangeFormation = (option) => setSelectedFormation(option.value);

  const handleClickPosition = (position) => {
    setSelectedPosition(position === selectedPosition ? '' : position);
  };

  const isLoading =
    formationUsagesLoading ||
    formationLocationsLoading ||
    statDistributionsLoading ||
    statDefinitionsLoading ||
    teamStatsByFormationLoading;

  const hasError =
    !!formationUsagesError ||
    !!formationLocationsError ||
    !!statDistributionsError ||
    !!statDefinitionsError ||
    !!teamStatsByFormationError;

  const getFeedback = () => {
    if (isLoading) {
      return <Loader inverted content="Loading" />;
    }
    if (hasError) {
      return 'Data error';
    }
    if (!formationUsagesTeam?.length) {
      return `No data available`;
    }
    return null;
  };
  const feedback = getFeedback();

  // Dropdown Formatting
  const dropdownOptions = formatFormationsForDropDown(formationUsagesTeam);

  return (
    <FormationDetailPage>
      <FormationDetailHeader>
        <div className="title">
          <h1>{selectedFormationUsage?.label || ''}</h1>
          <FormationsDropdown
            options={dropdownOptions}
            value={selectedFormationUsage}
            isLoading={formationUsagesLoading}
            onChange={handleChangeFormation}
          />
        </div>
        <div className="controls">
          <div>Stats</div>
          <ButtonGroup>
            <Button
              variant={isTeamMode ? 'primary' : 'secondary'}
              onClick={() => setIsTeamMode(true)}
              size="small"
            >
              Team
            </Button>
            <Button
              variant={isTeamMode ? 'secondary' : 'primary'}
              onClick={() => setIsTeamMode(false)}
              size="small"
            >
              vs. League Avg
            </Button>
          </ButtonGroup>
          <div className="divider" />
          <div>Filters</div>
          <Button
            variant="secondary"
            onClick={() => setIsTraitsFilterOpen(true)}
            size="small"
          >
            Traits
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsPlayersFilterOpen(true)}
            size="small"
          >
            Players
          </Button>
        </div>
      </FormationDetailHeader>
      <FormationDetailMainContent>
        <Tile>
          <Tile.Header>
            <FormationSummarySectionHeader>
              <div>Formation Evaluation Tool</div>
              <Controls>
                <ExportModal
                  title={teamDetails?.name}
                  secondaryTitle="Formation Detail"
                  info1={info1}
                  fileName={`Formation Detail - ${teamDetails?.name}`}
                >
                  <FormationDetailChart
                    id="formation-detail-chart-export"
                    data={playerLocations}
                    selectedPosition={selectedPosition}
                    isInteractive={false}
                  />
                </ExportModal>
              </Controls>
            </FormationSummarySectionHeader>
          </Tile.Header>
          <Tile.Body className="section-body">
            {feedback && (
              <Dimmer active inverted>
                {feedback}
              </Dimmer>
            )}
            <FormationDetailChart
              id="formation-detail-chart"
              data={playerLocations}
              selectedPosition={selectedPosition}
              handleClickPosition={handleClickPosition}
            />
            <ChartFooter>
              <StatBlockContainer>
                {!feedback &&
                  statBlocks.map((stat) => (
                    <StatBlock
                      key={stat.statName}
                      statName={stat.statName}
                      value={stat.value}
                      backgroundColor={stat.backgroundColor}
                      color={stat.color}
                    />
                  ))}
              </StatBlockContainer>
              {selectedPosition ? (
                <DepthChartTable
                  data={mockTable}
                  positionApiKey="WIDE_RECEIVER"
                  formationRole="X"
                  handleClick={() => setSelectedPosition('')}
                  selectableRows
                  onChangeRow={() => {}}
                  onChangeHeader={() => {}}
                  headerIcon="Close"
                />
              ) : (
                <Prompt>
                  <Icon variant="Info" />
                  <span className="prompt-message">
                    Select a position to view the depth chart and routes/targets
                  </span>
                </Prompt>
              )}
            </ChartFooter>
          </Tile.Body>
        </Tile>
        {!isLoading && selectedFormation && (
          <TraitAccordion
            formationCode={selectedFormation}
            isTeamStats={isTeamMode}
            statDefinitions={statDefinitions}
            statDistributions={statDistributions}
            formationPlayCountTeam={totalTeamFormationPlayCount}
          />
        )}
      </FormationDetailMainContent>
      <FormationDetailFilter
        isOpen={isTraitsFilterOpen}
        onConfirm={() => {}}
        onCancel={() => setIsTraitsFilterOpen(false)}
        sections={traits}
        isTraitsFilter
      />
      <FormationDetailFilter
        isOpen={isPlayersFilterOpen}
        onConfirm={() => {}}
        onCancel={() => setIsPlayersFilterOpen(false)}
        sections={players}
      />
    </FormationDetailPage>
  );
};

export default TeamFormationDetail;
