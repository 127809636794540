import { maxBy, minBy } from 'lodash';
import { FIELD_Y_YARDS } from '../../../utils/constants/charting';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';
import { getRosterPositionInfo } from '../../../utils/helpers/positions';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';

// these values are minimal values required to cover 99% of snap formations
// https://www.notion.so/statsbomb/Snap-Formation-View-Issue-Case-Issue-e0bc92367b3a479abc14dd4fde0a39ae
// they are also a 4:3 ratio
const SNAP_FORMATION_DEFAULT_Y_YDS = 44;
const SNAP_FORMATION_DEFAULT_X_YDS = 33;
const SNAP_FORMATION_ZOOM_MODES = {
  DEFAULT: 'Default Zoom',
  BOX: 'Zoom to Box',
  FIELD: 'Field Width',
};

const ZOOM_OPTIONS = [
  { value: SNAP_FORMATION_ZOOM_MODES.DEFAULT, label: 'Default Zoom' },
  { value: SNAP_FORMATION_ZOOM_MODES.BOX, label: 'Zoom to Offensive Line' },
  { value: SNAP_FORMATION_ZOOM_MODES.FIELD, label: 'Display Field Width' },
];

const SNAP_FORMATION_FOCUS_MODES = {
  PLAYERS: 'players',
  BALL: 'Ball',
  LOS: 'Line of Scrimmage',
};

const SNAP_FORMATION_FOCUS_OPTIONS = [
  { value: SNAP_FORMATION_FOCUS_MODES.PLAYERS, label: 'Players Midpoint' },
  { value: SNAP_FORMATION_FOCUS_MODES.BALL, label: 'Ball Location' },
  { value: SNAP_FORMATION_FOCUS_MODES.LOS, label: 'Line of Scrimmage' },
];

const SNAP_FORMATION_FOCUS_MODES_Y = {
  PLAYERS: 'players',
  BALL: 'ball',
  FIELD: 'field',
  OFFENSIVE_LINE: 'ol',
};

const SNAP_FORMATION_FOCUS_OPTIONS_Y = [
  { value: SNAP_FORMATION_FOCUS_MODES_Y.PLAYERS, label: 'Players Midpoint' },
  { value: SNAP_FORMATION_FOCUS_MODES_Y.BALL, label: 'Ball Location' },
  { value: SNAP_FORMATION_FOCUS_MODES_Y.FIELD, label: 'Field Midpoint' },
  { value: SNAP_FORMATION_FOCUS_MODES_Y.OFFENSIVE_LINE, label: 'OL Midpoint' },
];

const BOX_OL_ADDITION = 6; // yds
const FORMATION_MEASURES = {
  offensiveLineWidth: true,
  offensiveFormationWidth: true,
  tightEndSplits: true,
  tightEndDepths: true,
  defensiveBackDepths: true,
  linebackerDepths: true,
  defensiveFormationWidth: true,
  boxPlayersCount: true,
  wideReceiverSplits: true,
  wideReceiverDepths: true,
  defensiveBackWidths: true,
  linebackerWidths: true,
};
const FORMATION_MEASURES_OFF = {
  offensiveLineWidth: false,
  offensiveFormationWidth: false,
  tightEndSplits: false,
  tightEndDepths: false,
  defensiveBackDepths: false,
  linebackerDepths: false,
  defensiveFormationWidth: false,
  boxPlayersCount: false,
  wideReceiverSplits: false,
  wideReceiverDepths: false,
  defensiveBackWidths: false,
  linebackerWidths: false,
};

const getZoomFactor = function (zoomOption, ffEvent) {
  let zoomFactor = 1;
  // if not using default mode, zoom factor is relative size of the target width
  if (zoomOption === SNAP_FORMATION_ZOOM_MODES.BOX) {
    const boxViewport = ffEvent.olWidth + BOX_OL_ADDITION * 2;
    zoomFactor = SNAP_FORMATION_DEFAULT_Y_YDS / boxViewport;
  } else if (zoomOption === SNAP_FORMATION_ZOOM_MODES.FIELD) {
    zoomFactor = SNAP_FORMATION_DEFAULT_Y_YDS / FIELD_Y_YARDS;
  }
  return zoomFactor;
};

const getMidFrames = function (focusOptionX, focusOptionY, ffEvent) {
  if (!ffEvent) {
    return {
      midViewX: 0,
      midViewY: 0,
    };
  }
  // default to middle of player positions
  let midViewX = ffEvent.midFrameX;
  let midViewY = ffEvent.midFrameY;

  // overrides
  if (focusOptionX === SNAP_FORMATION_FOCUS_MODES.LOS) {
    midViewX = ffEvent.play.yardLine;
  }
  if (focusOptionX === SNAP_FORMATION_FOCUS_MODES.BALL) {
    const ballFFs = ffEvent?.freezeFrames.filter((f) => f.ballLocation);
    midViewX =
      ballFFs && ballFFs.length > 0 ? ballFFs[0].x : ffEvent.play.yardLine;
  }

  if (focusOptionY === SNAP_FORMATION_FOCUS_MODES_Y.BALL) {
    const ballFFs = ffEvent?.freezeFrames.filter((f) => f.ballLocation);
    midViewY = ballFFs && ballFFs.length > 0 ? ballFFs[0].y : FIELD_Y_YARDS / 2;
  }
  if (focusOptionY === SNAP_FORMATION_FOCUS_MODES_Y.FIELD) {
    midViewY = FIELD_Y_YARDS / 2;
  }
  if (focusOptionY === SNAP_FORMATION_FOCUS_MODES_Y.OFFENSIVE_LINE) {
    midViewY = ffEvent.olMidFrameY;
  }

  return { midViewX, midViewY };
};

const formatSnapFF = (ff, offenseTeamId) => {
  const onOffense = parseInt(ff.team?.id, 10) === parseInt(offenseTeamId, 10);
  const rosterPositionInfo = getRosterPositionInfo(
    ff.player?.teamPosition?.generalPosition,
    onOffense
  );
  return {
    ...ff,
    player: {
      ...ff.player,
      position: rosterPositionInfo,
    },
  };
};

/**
 * @param {*} snapFormationsnapDatum //form from GQL query
 * @returns PropTypes.
 */
const formatSnapData = function (snapFormationEdge) {
  const snapDatum = snapFormationEdge;

  if (!snapFormationEdge) {
    return;
  }
  // add positional info into the FFs
  const formattedFFs = snapDatum.freezeFrames?.map((ff) =>
    formatSnapFF(ff, +snapDatum.play.offenseTeam.id)
  );

  const offensiveLineFFs = formattedFFs.filter(
    (f) => f.player.position.code === 'OL'
  );

  const minY =
    snapDatum.freezeFrames?.length > 0
      ? minBy(snapDatum.freezeFrames, 'y').y
      : 0;
  const maxY =
    snapDatum.freezeFrames?.length > 0
      ? maxBy(snapDatum.freezeFrames, 'y').y
      : 0;
  const midFrameY = (minY + maxY) / 2;
  const snapFFWidth = Math.abs(maxY - minY); // this variable is for auto-zooming
  let olMidFrameY = midFrameY;
  let olWidth = snapFFWidth;
  if (offensiveLineFFs.length > 0) {
    const olMinY = minBy(offensiveLineFFs, 'y').y;
    const olMaxY = maxBy(offensiveLineFFs, 'y').y;
    olMidFrameY = (olMinY + olMaxY) / 2;
    olWidth = Math.abs(olMaxY - olMinY);
  }

  // similarly work out how much to translate by X in the visible frame
  const minX =
    snapDatum.freezeFrames?.length > 0
      ? minBy(snapDatum.freezeFrames, 'x').x
      : 0;
  const maxX =
    snapDatum.freezeFrames?.length > 0
      ? maxBy(snapDatum.freezeFrames, 'x').x
      : 0;
  const midFrameX = (minX + maxX) / 2;

  const modifiedDatum = {
    ...snapDatum,
    freezeFrames: formattedFFs,
    midFrameY,
    midFrameX,
    olMidFrameY,
    olWidth,
  };
  // eslint-disable-next-line consistent-return
  return modifiedDatum;
};

// note index is 0 based to index 5 = the sixth item
// row and col are also 0 based ~ so second row = row: 1
const rowAndColumn = function (index, cols) {
  const row = Math.floor(index / cols);
  const col = index - cols * row;
  return { row, col };
};
const addRowCol = (snapDatum, snaps, cols) => {
  const modifiedSnap = { ...snapDatum };
  const n = snaps.filter(
    (event) => event.eventGameIndex < modifiedSnap.eventGameIndex
  ).length;
  modifiedSnap.rowCol = rowAndColumn(n, cols);
  return modifiedSnap;
};

const getBoxLimits = function (ffEvent) {
  if (!ffEvent) {
    return [];
  }
  const offensiveLineFFs = ffEvent.freezeFrames.filter(
    (f) =>
      f.player?.position?.code ===
      ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN].code
  );
  const basicBox = {
    los: ffEvent.play.yardLine,
    minY: FIELD_Y_YARDS / 2,
    maxY: FIELD_Y_YARDS / 2,
    minX: ffEvent.play.yardLine - BOX_OL_ADDITION,
    maxX: ffEvent.play.yardLine + BOX_OL_ADDITION,
  };
  if (offensiveLineFFs.length > 0) {
    const minY_offense = minBy(offensiveLineFFs, 'y');
    const maxY_offense = maxBy(offensiveLineFFs, 'y');
    return {
      ...basicBox,
      minY: minY_offense.y,
      maxY: maxY_offense.y,
    };
  }
  // If no offensive line (??) just assume the box to be midpoint of the field
  return basicBox;
};

const pointInBox = function (ffDatum, boxLimits) {
  const inSquareBit =
    ffDatum.y >= boxLimits.minY &&
    ffDatum.y <= boxLimits.maxY &&
    ffDatum.x >= boxLimits.minX &&
    ffDatum.x <= boxLimits.maxX;
  const inLeftRadius =
    Math.hypot(ffDatum.x - boxLimits.los, ffDatum.y - boxLimits.minY) <=
    BOX_OL_ADDITION;
  const inRightRadius =
    Math.hypot(ffDatum.x - boxLimits.los, ffDatum.y - boxLimits.maxY) <=
    BOX_OL_ADDITION;
  return inSquareBit || inLeftRadius || inRightRadius;
};

const getTaggedFFs = function (ffEvent, boxLimits) {
  if (!ffEvent) {
    return [];
  }
  const ffs = ffEvent.freezeFrames.map((f) => {
    const modifiedFF = { ...f };
    modifiedFF.isEventer = f.player && ffEvent.player?.id === f.player.id;
    modifiedFF.isOffense = f.team && f.team.id === ffEvent.play.offenseTeam.id;
    modifiedFF.isInBox = pointInBox(f, boxLimits);
    modifiedFF.ballOnly = f.playerIndex < 0;
    return modifiedFF;
  });

  return ffs;
};

const getClampedFreezeFrames = function (
  taggedFFs,
  minY,
  maxY,
  minX,
  maxX,
  outOfFrameShift
) {
  const ffs = taggedFFs.map((f) => {
    const modifiedFF = { ...f };
    modifiedFF.isClamped = f.x < minX || f.x > maxX || f.y < minY || f.y > maxY;
    modifiedFF.x = f.x;
    if (f.x < minX) {
      modifiedFF.x = minX - outOfFrameShift;
    }
    if (f.x > maxX) {
      modifiedFF.x = maxX + outOfFrameShift;
    }
    modifiedFF.y = f.y;
    if (f.y < minY) {
      modifiedFF.y = minY - outOfFrameShift;
    }
    if (f.y > maxY) {
      modifiedFF.y = maxY + outOfFrameShift;
    }

    return modifiedFF;
  });

  return ffs;
};

export {
  formatSnapData,
  rowAndColumn,
  addRowCol,
  SNAP_FORMATION_DEFAULT_Y_YDS,
  SNAP_FORMATION_DEFAULT_X_YDS,
  SNAP_FORMATION_ZOOM_MODES,
  ZOOM_OPTIONS,
  getClampedFreezeFrames,
  getTaggedFFs,
  getZoomFactor,
  getMidFrames,
  BOX_OL_ADDITION,
  getBoxLimits,
  FORMATION_MEASURES,
  FORMATION_MEASURES_OFF,
  SNAP_FORMATION_FOCUS_MODES,
  SNAP_FORMATION_FOCUS_OPTIONS,
  SNAP_FORMATION_FOCUS_MODES_Y,
  SNAP_FORMATION_FOCUS_OPTIONS_Y,
};
