import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { Option } from './DropdownFilter.components';
import {
  DropdownContainer,
  DropdownLabel,
  customDropdownStyles,
} from './DropdownFilter.styles';
import { dropdownOptionPropType } from './DropdownFilter.propTypes';
import { DROPDOWN_CONTAINER_WIDTH } from './DropdownFilter.constants';

const DropdownFilter = ({
  data,
  label,
  value,
  isLoading,
  isDisabled,
  handleChange,
  defaultValue,
  containerWidth,
  isStaticMenu,
  isMulti,
  containedMenu,
}) => {
  const theme = useTheme();

  return (
    <DropdownContainer className={containerWidth}>
      <DropdownLabel htmlFor={label}>{label}:</DropdownLabel>
      <Select
        defaultValue={defaultValue}
        styles={customDropdownStyles(
          theme,
          isStaticMenu,
          isMulti,
          containedMenu
        )}
        options={data}
        onChange={handleChange}
        name={label}
        value={value}
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading}
        components={{
          Option,
        }}
        isMulti={isMulti}
      />
    </DropdownContainer>
  );
};

DropdownFilter.propTypes = {
  data: PropTypes.arrayOf(dropdownOptionPropType),
  // default selected value if no specific value is selected yet
  defaultValue: dropdownOptionPropType,
  // function that triggers when a value is selected
  handleChange: PropTypes.func,
  // label of the dropdown
  label: PropTypes.string,
  // the selected value in the dropdown
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // the width of the dropdown
  containerWidth: PropTypes.oneOf(Object.values(DROPDOWN_CONTAINER_WIDTH)),
  // whether the dropdown is in loading state
  isLoading: PropTypes.bool,
  // whether the dropdown is disabled
  isDisabled: PropTypes.bool,
  // if within an overflow div will get cut off without this
  isStaticMenu: PropTypes.bool,
  // whether the dropdown supports selecting multiple options
  isMulti: PropTypes.bool,
  // whether the menu can get wider than the dropdown
  containedMenu: PropTypes.bool,
};

DropdownFilter.defaultProps = {
  data: [
    {
      label: '',
      value: '',
    },
  ],
  defaultValue: null,
  handleChange: null,
  label: '',
  value: undefined,
  containerWidth: DROPDOWN_CONTAINER_WIDTH.DEFAULT,
  isLoading: false,
  isDisabled: false,
  isStaticMenu: false,
  isMulti: false,
  containedMenu: true,
};

export default DropdownFilter;
