import React from 'react';
import PropTypes from 'prop-types';
import DownloadCSVButton from '../buttons/DownloadCSV';
import GoTo from '../buttons/GoTo';
import BasicTable from '../Table/BasicTable';
import Tile from '../Tile/Tile';
import { TableTileHeader } from './TableTile.styles';

const TableTile = ({
  data,
  tileTitle,
  columnHeaders,
  showColumnHeader,
  fileName,
  error,
  loading,
  linkHref,
  iconSize,
  iconStyle,
  withBorder,
  padding,
  margin,
  highlightedColumn,
  footer,
}) => (
  <Tile dataTestId="tableTileT" border="0" padding={padding} margin={margin}>
    <Tile.Header $isSlim>
      <TableTileHeader>
        {tileTitle && (
          <>
            <h2 data-testid="header2T" title={tileTitle}>
              {tileTitle}
            </h2>
            <div className="buttons">
              {!error && !loading && data && (
                <div style={iconStyle}>
                  <DownloadCSVButton
                    data={data}
                    headers={columnHeaders}
                    fileName={fileName}
                    data-testid="downloadCSVButtonT"
                    iconSize={iconSize}
                  />
                </div>
              )}
              {linkHref && (
                <GoTo href={linkHref} linkName="Open in Play Animation" />
              )}
            </div>
          </>
        )}
      </TableTileHeader>
    </Tile.Header>
    <Tile.Body $padding="0" data-testid="tableHolderT">
      {!error && !loading && data && (
        <BasicTable
          data={data}
          headers={columnHeaders}
          showHeader={showColumnHeader}
          withBorder={withBorder}
          highlightedColumn={highlightedColumn}
          footer={footer}
        />
      )}
    </Tile.Body>
  </Tile>
);

TableTile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  tileTitle: PropTypes.string.isRequired,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  showColumnHeader: PropTypes.bool,
  fileName: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  linkHref: PropTypes.string,
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  iconStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  withBorder: PropTypes.bool,
  // padding string for the tile
  padding: PropTypes.string,
  // margin string for the tile
  margin: PropTypes.string,
  // which column the table sorting is currently based on (1 based index)
  highlightedColumn: PropTypes.number,
  // Basic Table footer
  footer: PropTypes.arrayOf(PropTypes.shape({})),
};

TableTile.defaultProps = {
  data: undefined,
  columnHeaders: undefined,
  showColumnHeader: true,
  fileName: 'table.csv',
  error: false,
  loading: false,
  linkHref: null,
  iconSize: 'small',
  iconStyle: {},
  withBorder: false,
  padding: '0',
  margin: '0 0 1.25rem 0',
  highlightedColumn: 0,
  footer: undefined,
};

export default TableTile;
