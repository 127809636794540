import { getYardlineName } from '../../../../utils/helpers/play';
import { getRosterPositionColorByCode } from '../../../../utils/helpers/positions';
import { HAVOC_EVENT_TYPES } from '../../../../visualisations/HavocChart/HavocChart.constants';

export const noEvent = { tableData: null, href: null, name: '-' };

export const formatSelectedHavocDataForTable = (havocData, selectedEvent) => {
  if (!havocData || !selectedEvent || havocData?.length === 0) {
    return noEvent;
  }
  const havocEvent = havocData.find((t) => t.eventUUID === selectedEvent);
  if (!havocEvent) {
    return noEvent;
  }

  const href =
    `/game/animation/:leagues/:seasons/:teams/${havocEvent.game_id}` +
    `/${havocEvent.driveUUID}/${havocEvent.playUUID}`;
  const tackleRows = [];
  const havocType = HAVOC_EVENT_TYPES[havocEvent.havocType];

  /* table row formatter */
  const trf = (label, value) => ({ label, value });

  tackleRows.push(trf('Game', havocEvent?.gameName));
  tackleRows.push(trf('Drive', havocEvent?.driveName));
  tackleRows.push(trf('Play', havocEvent?.playName));
  tackleRows.push(trf('Snap', getYardlineName(havocEvent?.snap?.x)));
  const defenderCode = havocEvent?.defenderPosition
    ? `[${havocEvent?.defenderPosition.code}]`
    : '';
  tackleRows.push(
    trf('Defender', `${havocEvent.defenderName} ${defenderCode}`)
  );
  const targetCode = havocEvent?.targetPosition
    ? `[${havocEvent?.targetPosition.code}]`
    : '';
  const targetName = havocEvent?.targetName
    ? `${havocEvent.targetName} ${targetCode}`
    : '-';
  tackleRows.push(trf('Target', targetName));
  tackleRows.push(
    trf('Post Snap', `${havocEvent?.postSnapSeconds?.toFixed(2)}s`)
  );
  tackleRows.push(trf('Snap Gap', havocEvent?.snapGapName || '-'));
  //   tackleRows.push(trf('Line Position', havocEvent?.snapLinePosition || '-'));
  tackleRows.push(trf('Exploited Gap', havocEvent?.eventGapName || '-'));
  tackleRows.push(trf('Yards to LoS', havocEvent?.xLoS?.toFixed(1)));

  const name = `Havoc Event ${havocType.name} ${havocEvent.defenderName}`;
  return { tableData: tackleRows, href, name };
};

// format data for video tile to allow player tracking and colouring
export const formatHavocVideoData = (events, visPalette) =>
  events.map((evt) => ({
    playId: evt?.play?.id,
    highlightPlayers: [
      {
        active: true,
        id: evt?.targetId,
        colour: getRosterPositionColorByCode(
          evt?.targetRosterPositionCode,
          visPalette
        ),
        name: evt?.targetName,
        number: evt?.targetJerseyNumber,
      },
      {
        active: true,
        id: evt?.playerId,
        colour: getRosterPositionColorByCode(
          evt?.defenderRosterPositionCode,
          visPalette
        ),
        name: evt?.playerName,
        number: evt?.defenderJerseyNumber,
      },
    ],
  }));
