// basic component
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { mf_PlayerDetails, mf_TeamDetails } from '../../apollo';
import { PageHeaderTile } from './PageHeader.styles';
import ParsedLink from '../ParsedLink/ParsedLink';
import { InfoBar } from '../../pages/player/PlayerOverview/PlayerOverview.styles';

const PageHeader = ({
  href,
  rootPage,
  activePage,
  showPlayerName,
  showTeamName,
  customTitle,
}) => {
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const { mostCommonPosition: position } = mf_PlayerDetailsRV || {};
  const playerNameAndPosition =
    mf_PlayerDetailsRV && `${mf_PlayerDetailsRV?.name} - ${position?.name}`;
  const teamName = mf_TeamDetailsRV?.name;

  return (
    <PageHeaderTile>
      <InfoBar>
        <div>
          {rootPage && (
            <Breadcrumb size="huge">
              <Breadcrumb.Section>
                <ParsedLink href={href}>{rootPage}</ParsedLink>
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>{activePage}</Breadcrumb.Section>
            </Breadcrumb>
          )}
        </div>
        <div>
          <h3>
            {showPlayerName && playerNameAndPosition}
            {showTeamName && teamName}
            {customTitle && customTitle}
          </h3>
        </div>
        <div />
      </InfoBar>
    </PageHeaderTile>
  );
};

PageHeader.propTypes = {
  href: PropTypes.string,
  rootPage: PropTypes.string,
  activePage: PropTypes.string,
  showPlayerName: PropTypes.bool,
  showTeamName: PropTypes.bool,
  customTitle: PropTypes.string,
};

PageHeader.defaultProps = {
  href: '',
  rootPage: '',
  activePage: '',
  showPlayerName: true,
  showTeamName: false,
  customTitle: '',
};

export default PageHeader;
