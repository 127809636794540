import { pf_AllDrives, pf_Drive } from '../../apollo';
import {
  DROPDOWN_SPECIAL_OPTIONS,
  SPECIAL_OPTIONS,
} from '../DropdownFilter/DropdownFilter.constants';

export const getDriveIdsByParam = (drivesData, drivesParam, gameDetails) => {
  const allDrives = drivesData?.drives?.edges || [];

  switch (drivesParam) {
    case SPECIAL_OPTIONS.ALL:
      return allDrives.map((d) => d.node.id);

    case SPECIAL_OPTIONS.HOME:
      return allDrives
        .filter((d) => d.node.team.id === gameDetails.homeTeam.id)
        .map((d) => d.node.id);

    case SPECIAL_OPTIONS.AWAY:
      return allDrives
        .filter((d) => d.node.team.id === gameDetails.awayTeam.id)
        .map((d) => d.node.id);

    default:
      return [];
  }
};

export const getDriveIds = () => {
  if (DROPDOWN_SPECIAL_OPTIONS.includes(pf_Drive())) {
    return pf_AllDrives() || [];
  }
  return pf_Drive() ? [pf_Drive()] : [];
};
