import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import { TooltipBody, TooltipContent } from './Tooltip.styles';

const Tooltip = ({ content, target, withScroll }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Popover
      isOpen={tooltipOpen}
      positions={['bottom', 'top']}
      padding={0}
      reposition={false}
      onClickOutside={() => setTooltipOpen(false)}
      content={() => (
        <TooltipBody
          onMouseOver={() => setTooltipOpen(true)}
          onMouseOut={() => setTooltipOpen(false)}
        >
          {content}
        </TooltipBody>
      )}
    >
      <TooltipContent
        onMouseOver={() => setTooltipOpen(true)}
        onFocus={() => setTooltipOpen(true)}
        onMouseOut={() => (withScroll ? null : setTooltipOpen(false))}
        onBlur={() => setTooltipOpen(false)}
        data-testid="tooltip-trigger"
      >
        {target}
      </TooltipContent>
    </Popover>
  );
};

Tooltip.propTypes = {
  content: PropTypes.element.isRequired,
  target: PropTypes.element.isRequired,
  // withScroll will keep the tooltip open when the user scrolls the popover
  withScroll: PropTypes.bool,
};

Tooltip.defaultProps = {
  withScroll: false,
};

export default Tooltip;
