import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@statsbomb/kitbag-components';
import SortableTableHeader from '../SortableTable/SortableTableHeader/SortableTableHeader';
import { StyledAccordionTable } from './AccordionTable.styles';
import AccordionTableBody from './AccordionTableBody';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { sortableTableStickyColumnsPropTypes } from '../Tables.propTypes';

const AccordionTable = ({
  tables,
  headers,
  handleSort,
  sortBy,
  sortDirection,
  handleDataVisibility,
  stickyColumns,
  expandButtonSuffix,
  visibleStats,
  withAllRows,
}) => {
  const getCellAlignment = (index) => (index > 0 ? 'right' : 'left');

  return (
    <StyledAccordionTable $withAllRows={withAllRows}>
      <Table withBorder={false} stickyColumns={stickyColumns} isHeadSticky>
        <SortableTableHeader
          headers={headers}
          handleSort={handleSort}
          alignment={getCellAlignment}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
        {tables.map((table, index) => (
          <AccordionTableBody
            key={table.label}
            headers={headers}
            isOpenInitialState={index === 0}
            label={table.label}
            amount={table.amount}
            rows={table.rows}
            tableIndex={index}
            handleDataVisibility={handleDataVisibility}
            expandButtonSuffix={expandButtonSuffix}
            visibility={visibleStats[index]}
            expandButtonCondition={table.expandButtonCondition}
          />
        ))}
      </Table>
    </StyledAccordionTable>
  );
};

AccordionTable.propTypes = {
  // The personnel group
  tables: PropTypes.arrayOf(PropTypes.object),
  // The headers of the table
  headers: PropTypes.arrayOf(PropTypes.object),
  // function that is called when a sortable header is clicked
  handleSort: PropTypes.func.isRequired,
  // the state of the column sort
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  // by which column the table is sorted
  sortBy: PropTypes.string,
  // callback function to manage what data should be visible in the chart
  handleDataVisibility: PropTypes.func.isRequired,
  // Which columns to keep on screen when the user scrolls right.
  stickyColumns: sortableTableStickyColumnsPropTypes,
  // additional context about what the "expand more" button is controlling
  expandButtonSuffix: PropTypes.string,
  // an enum of table indexes and their visibility status - ie. { 0: 'partial', 1: 'closed' }
  visibleStats: PropTypes.shape({}),
  // whether the table sections display an All row
  withAllRows: PropTypes.bool,
};

AccordionTable.defaultProps = {
  tables: [],
  headers: [],
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  sortBy: '',
  stickyColumns: [],
  expandButtonSuffix: '',
  visibleStats: {},
  withAllRows: false,
};

export default AccordionTable;
