import { styled } from 'styled-components';

export const WizardLayout = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  width: 100%;

  h1 {
    ${({ theme }) => theme.typography.headingLarge}
  }

  p {
    ${({ theme }) => theme.typography.bodyRegular}
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }
`;
WizardLayout.displayName = 'WizardLayout';

export const WizardItemContainingDropdown = styled.div`
  label {
    ${({ theme }) => theme.typography.bodySmall}
    padding-bottom: 0.5rem;
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }
`;
WizardItemContainingDropdown.displayName = 'WizardItemContainingDropdown';

export const WizardDropdownLarge = styled.div`
  label {
    ${({ theme }) => theme.typography.bodyRegular}
    padding-bottom: 0.5rem;
  }
`;
WizardDropdownLarge.displayName = 'WizardDropdownLarge';

export const WizardItemContainingTab = styled.div`
  label {
    width: 50%;
  }
`;
WizardItemContainingTab.displayName = 'WizardItemContainingTab';

export const WizardButtonSpacing = styled.div`
  display: flex;
  gap: 1rem;
`;
WizardButtonSpacing.displayName = 'WizardButtonSpacing';

export const WizardActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding-top: 2rem;

  button {
    padding-right: 0.3rem;
  }
`;
WizardActions.displayName = 'WizardActions';
