import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Toggle } from '@statsbomb/kitbag-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import { mf_PlayerDetails, mf_TeamDetails } from '../../../apollo';
import { GET_PASS_EVENTS } from './getPassEvents';
import { GET_CATCH_EVENTS } from './getCatchEvents';
import PassEndsPage from './PassEndsPage';
import { toChartSpace } from './DataManipulation';
import { defaultFieldSettings } from '../../../utils/constants/charting';
import { escapeDownloadString } from '../../../utils/helpers/strings';
import PageHeader from '../../../components/PageHeader/PageHeader';

const PassEnds = () => {
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const [showPasses, setShowPasses] = useQueryString(
    'show',
    mf_PlayerDetailsRV?.mostCommonPosition?.code === undefined ||
      mf_PlayerDetailsRV?.mostCommonPosition?.code === 'QB'
  );
  const handleShowPasses = () => {
    setShowPasses(!showPasses);
  };

  const {
    loading: loadingPasses,
    error: errorPasses,
    data: dataPasses,
  } = useQuery(GET_PASS_EVENTS);
  const {
    loading: loadingCatches,
    error: errorCatches,
    data: dataCatches,
  } = useQuery(GET_CATCH_EVENTS);

  const loading = showPasses ? loadingPasses : loadingCatches;
  const error = showPasses ? errorPasses : errorCatches;

  const chartData = showPasses
    ? dataPasses?.passEvents?.edges?.map(({ node }) => node)
    : dataCatches?.passEvents?.edges?.map(({ node }) => node);

  const formattedData =
    chartData &&
    chartData.map((m) => toChartSpace(m, defaultFieldSettings.pxPerYard));

  const playerName = mf_PlayerDetailsRV?.name;
  const teamName = mf_TeamDetailsRV?.name;
  const nameAndTeam = playerName && teamName && `${playerName} (${teamName})`;

  const passesOrTargetsFilter = (
    <Toggle
      id="passes-or-targets-toggle"
      label={
        showPasses ? `Passes from ${playerName}` : `Passes to ${playerName}`
      }
      onChange={handleShowPasses}
      checked={showPasses}
    />
  );

  const breadcrumbAndTitle = (
    <PageHeader
      href="/player/overview/:leagues/:seasons/:teams/:players"
      rootPage="Player"
      activePage="Passing Chart"
    />
  );

  return (
    <PassEndsPage
      formattedData={formattedData}
      isError={!!error}
      loading={loading}
      visTitle={nameAndTeam}
      downloadNameRoot={escapeDownloadString(mf_PlayerDetailsRV?.name)}
      dataFilters={passesOrTargetsFilter}
      showPasses={showPasses}
      breadcrumb={breadcrumbAndTitle}
    />
  );
};

export default PassEnds;
