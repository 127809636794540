import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import {
  renderSnapDistribution,
  renderSnapDistributionHeatmaps,
  setupViewBoxAndLayers,
  setupSelectionZones,
  drawSnapDistributionKey,
} from './SnapDistribution.drawing';
import {
  defaultFieldSettings,
  ROTATIONS,
} from '../../../utils/constants/charting';
import {
  getBandedData,
  getBandedHeatmapData,
} from './PlayerSnap.dataManipulation';
import { useD3 } from '../../../utils/hooks/useD3';
import { SnapDistributionChartSVG } from './SnapDistributionChart.styles';
import { mf_LeagueLevel, mf_PlayerDetails } from '../../../apollo';
import {
  DOT_COLOR_MODE,
  HEATMAP_COLOR_MODE_TYPES,
} from './SnapDistributionChart.constants';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';

const SnapDistributionChart = ({
  id,
  data,
  showDy,
  showDx,
  orientation,
  colorMode,
  colorModeHeatmap,
  selectedZones,
  setSelectedZones,
  showKey,
  showTotalOnly,
  margin,
  isInteractive,
  withinTile,
  padding,
}) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const playerDetails = useReactiveVar(mf_PlayerDetails);
  const isDefensivePlayer =
    !ROSTER_POSITIONS[playerDetails?.mostCommonPosition?.generalPosition]
      ?.isOffense;

  // SVG BASIC VALUES
  const overrides = {
    ...defaultFieldSettings,
    visPalette,
    competitionLevel,
    orientation,
  };

  // this level declares anything static
  const ref = useD3((svg) => svg, []);

  // setup the base layer, only changes with horizontal filter
  useEffect(() => {
    const svg = select(ref.current);

    setupViewBoxAndLayers(
      id,
      svg,
      overrides,
      margin,
      showDy,
      showKey,
      showTotalOnly,
      isDefensivePlayer
    );
  }, [
    orientation,
    competitionLevel,
    showDy,
    showKey,
    theme.isDark,
    isDefensivePlayer,
  ]);

  // render the data information (dots), changes with data
  useEffect(() => {
    const svg = select(ref.current);

    if (data) {
      const bandedData = getBandedData(data);
      renderSnapDistribution(
        svg,
        bandedData,
        overrides,
        showDy,
        showDx,
        colorMode,
        showTotalOnly,
        isDefensivePlayer
      );

      const bandedHeatmapData = getBandedHeatmapData(bandedData, showDy);
      renderSnapDistributionHeatmaps(
        svg,
        bandedHeatmapData,
        overrides,
        showDy,
        colorModeHeatmap,
        id
      );
    }
  }, [
    data,
    orientation,
    showDy,
    showDx,
    colorMode,
    colorModeHeatmap,
    theme.isDark,
  ]);

  // render selectable zones, only changes with horizontal filter
  useEffect(() => {
    const svg = select(ref.current);

    if (isInteractive) {
      setupSelectionZones(
        svg,
        overrides,
        showDy,
        selectedZones,
        setSelectedZones
      );
    }
  }, [showDy, selectedZones, overrides, theme.isDark]);

  // render the key
  useEffect(() => {
    const svg = select(ref.current);

    drawSnapDistributionKey(
      svg,
      showKey,
      overrides,
      colorMode,
      showDy,
      theme.isDark,
      showTotalOnly
    );
  }, [overrides, showDy, showKey, colorMode, theme.isDark]);

  return (
    <SnapDistributionChartSVG
      $withinTile={withinTile}
      $padding={padding}
      id={id}
      ref={ref}
    />
  );
};

SnapDistributionChart.propTypes = {
  // unique id for the vis
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  showDy: PropTypes.bool,
  showDx: PropTypes.bool,
  orientation: PropTypes.string,
  colorMode: PropTypes.string,
  colorModeHeatmap: PropTypes.string,
  selectedZones: PropTypes.arrayOf(
    PropTypes.shape({
      snapZone: PropTypes.string,
      zoneIndex: PropTypes.number,
    })
  ),
  setSelectedZones: PropTypes.func,
  // show key
  showKey: PropTypes.bool,
  // show main distribution only, rather than: [main, left, left-c, right-c, right]
  showTotalOnly: PropTypes.bool,
  // margin values for chart within the svg
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  // does the chart support any interactions?
  isInteractive: PropTypes.bool,
  // is the chart hosted within a summary tile?
  withinTile: PropTypes.bool,
  // transient styling prop, string to allow '55px', '5rem 10%' etc.
  padding: PropTypes.string,
};

SnapDistributionChart.defaultProps = {
  data: undefined,
  showDy: false,
  showDx: false,
  orientation: ROTATIONS.VERTICAL_UP,
  colorMode: DOT_COLOR_MODE.DEFAULT,
  colorModeHeatmap: HEATMAP_COLOR_MODE_TYPES.INDEPENDENT,
  selectedZones: null,
  setSelectedZones: () => {},
  showKey: true,
  showTotalOnly: false,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  isInteractive: true,
  withinTile: false,
  padding: '0',
};

export default SnapDistributionChart;
