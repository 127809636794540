import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Button, Checkbox, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import {
  uiState,
  cf_PlayType,
  cf_PlayTypeEnabled,
  cf_YardsGained,
  cf_YardsGainedEnabled,
  cf_PassAirYardsEnabled,
  cf_PassAirYards,
  cf_QBDropDepth,
  cf_QBDropDepthEnabled,
  cf_QBTimeToThrow,
  cf_QBPocketLocation,
  cf_QBTimeToThrowEnabled,
  cf_QBPocketLocationEnabled,
} from '../../apollo';
import { returnReadableYardsLabel } from '../../utils/helpers/general';
import { normalPlayTypes, qbPocketLocation } from '../../utils/constants/api';
import {
  StyledModal,
  StyledSlider,
  TriggerButtonContainer,
} from './ContextFilters.styles';
import { enableFilter, getTimeToLabel } from './Filters.helper';
import { useIsEligiblePage } from './ContextFilters.hooks';

const TypeFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_ModalOpen, setModalOpen] = useState(false);
  // context filters in global state
  const cf_PlayTypeRV = useReactiveVar(cf_PlayType);
  const cf_PlayTypeEnabledRV = useReactiveVar(cf_PlayTypeEnabled);
  const cf_YardsGainedRV = useReactiveVar(cf_YardsGained);
  const cf_YardsGainedEnabledRV = useReactiveVar(cf_YardsGainedEnabled);
  const cf_QBDropDepthRV = useReactiveVar(cf_QBDropDepth);
  const cf_QBDropDepthEnabledRV = useReactiveVar(cf_QBDropDepthEnabled);
  const cf_QBTimeToThrowRV = useReactiveVar(cf_QBTimeToThrow);
  const cf_QBTimeToThrowEnabledRV = useReactiveVar(cf_QBTimeToThrowEnabled);
  const cf_QBPocketLocationRV = useReactiveVar(cf_QBPocketLocation);
  const cf_QBPocketLocationEnabledRV = useReactiveVar(
    cf_QBPocketLocationEnabled
  );
  const cf_PassAirYardsRV = useReactiveVar(cf_PassAirYards);
  const cf_PassAirYardsEnabledRV = useReactiveVar(cf_PassAirYardsEnabled);

  // context filters in local state
  const [st_PlayType, setPlayType] = useState(cf_PlayTypeRV);
  const [st_YardsGained, setYardsGained] = useState(cf_YardsGainedRV);
  const [st_QBDropDepth, setQBDropDepth] = useState(cf_QBDropDepthRV);
  const [st_QBTimeToThrow, setQBTimeToThrow] = useState(cf_QBTimeToThrowRV);
  const [st_QBPocketLocation, setQBPocketLocation] = useState(
    cf_QBPocketLocationRV
  );
  const [st_PassAirYards, setPassAirYards] = useState(cf_PassAirYardsRV);

  const handlePlayType = (isTypeChecked, index) => {
    const playType = [...st_PlayType];
    playType[index] = isTypeChecked;

    // if pass play is disabled, disable drop depth and time to throw
    if (index === 0 && !isTypeChecked) {
      if (cf_QBDropDepthEnabledRV) {
        cf_QBDropDepthEnabled(false);
      }
      if (cf_QBTimeToThrowEnabledRV) {
        cf_QBTimeToThrowEnabled(false);
      }
    }
    enableFilter(cf_PlayTypeEnabledRV, cf_PlayTypeEnabled, 'playType');
    setPlayType([...playType]);
  };

  const handlePlayEnabledToggle = (enabled, rv, property, update) => {
    // if pass play is disabled and the user enables playTypes THEN
    // disable enabled toggles for QB drop and QBTimeToThrow
    if (st_PlayType[0] === false && enabled) {
      handleEnabledToggle(false, cf_QBDropDepthEnabled, 'qbDropDepth', {
        enabled: false,
      });
      handleEnabledToggle(false, cf_QBTimeToThrowEnabled, 'qbTimeToThrow', {
        enabled: false,
      });
    }

    handleEnabledToggle(enabled, rv, property, update);
  };

  const handleQBPocketLocation = (isLocationChecked, index) => {
    const pocketLocation = [...st_QBPocketLocation];
    pocketLocation[index] = isLocationChecked;
    setQBPocketLocation([...pocketLocation]);
    enableFilter(
      cf_QBPocketLocationEnabledRV,
      cf_QBPocketLocationEnabled,
      'qbPocketLocation'
    );
  };

  const handleReset = () => {
    setPlayType([true, true, false, false]);
    cf_PlayTypeEnabled(false);
    setQBDropDepth([0, 16]);
    cf_QBDropDepthEnabled(false);
    setQBTimeToThrow([0, 11]);
    cf_QBTimeToThrowEnabled(false);
    setQBPocketLocation([
      ...new Array(Object.keys(qbPocketLocation).length).fill(true),
    ]);
    cf_QBPocketLocation(false);
    setYardsGained([-10, 25]);
    cf_YardsGainedEnabled(false);
    setPassAirYards([-5, 40]);
    cf_PassAirYardsEnabled(false);

    uiState().contextFilters.playType.enabled = true;
    uiState().contextFilters.playType.value = [true, true, false, false];
    uiState().contextFilters.qbDropDepth.enabled = false;
    uiState().contextFilters.qbDropDepth.value = [0, 16];
    uiState().contextFilters.qbTimeToThrow.enabled = false;
    uiState().contextFilters.qbTimeToThrow.value = [0, 11];
    uiState().contextFilters.qbPocketLocation.enabled = false;
    uiState().contextFilters.qbPocketLocation.value = [
      ...new Array(Object.keys(qbPocketLocation).length).fill(true),
    ];
    uiState().contextFilters.yardsGained.value = [-10, 25];
    uiState().contextFilters.yardsGained.enabled = false;
    uiState().contextFilters.passAirYards.value = [-5, 40];
    uiState().contextFilters.passAirYards.enabled = false;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleModalClose = () => {
    const { contextFilters } = uiState();
    const {
      playType,
      yardsGained,
      passAirYards,
      qbDropDepth,
      qbTimeToThrow,
      qbPocketLocation: qbPocketLocationContext,
    } = contextFilters;

    cf_PlayType([...st_PlayType]);
    playType.value = [...st_PlayType];
    cf_PlayTypeEnabled(cf_PlayTypeEnabledRV);

    cf_QBDropDepth([...st_QBDropDepth]);
    qbDropDepth.value = [...st_QBDropDepth];
    cf_QBDropDepthEnabled(cf_QBDropDepthEnabledRV);

    cf_QBTimeToThrow([...st_QBTimeToThrow]);
    qbTimeToThrow.value = [...st_QBTimeToThrow];
    cf_QBTimeToThrowEnabled(cf_QBTimeToThrowEnabledRV);

    cf_QBPocketLocation([...st_QBPocketLocation]);
    qbPocketLocationContext.value = [...st_QBPocketLocation];
    cf_QBPocketLocationEnabled(cf_QBPocketLocationEnabledRV);

    cf_YardsGained([...st_YardsGained]);
    yardsGained.value = [...st_YardsGained];
    cf_YardsGainedEnabled(cf_YardsGainedEnabledRV);

    cf_PassAirYards([...st_PassAirYards]);
    passAirYards.value = [...st_PassAirYards];
    cf_PassAirYardsEnabled(cf_PassAirYardsEnabledRV);

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setModalOpen(false);
  };

  const filterCount = [
    cf_PlayTypeEnabledRV,
    cf_YardsGainedEnabledRV,
    cf_PassAirYardsEnabledRV,
    cf_QBDropDepthEnabledRV,
    cf_QBTimeToThrowEnabledRV,
    cf_QBPocketLocationEnabledRV,
  ].filter(Boolean).length;
  const isEligiblePage = useIsEligiblePage('PLAY');
  const isEnabled = filterCount > 0 && isEligiblePage;

  return (
    <StyledModal
      onClose={handleModalClose}
      onOpen={() => {
        setModalOpen(true);
      }}
      open={st_ModalOpen}
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Play
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size="tiny"
    >
      <Modal.Content>
        <h2>Play</h2>
        <Form className="types">
          <Form.Field>
            <Toggle
              id="play-type-cf-toggle"
              label="Play Type"
              onChange={() =>
                handlePlayEnabledToggle(
                  !cf_PlayTypeEnabledRV,
                  cf_PlayTypeEnabled,
                  'playType',
                  {
                    enabled: !cf_PlayTypeEnabledRV,
                  }
                )
              }
              checked={cf_PlayTypeEnabledRV}
              data-testid="play-type-toggle"
            />
          </Form.Field>
          <Form.Group className="boxed feature">
            {st_PlayType &&
              Object.values(normalPlayTypes).map((item, index) => (
                <Form.Field key={`normal-play-types-${item}`}>
                  <Checkbox
                    id={`normal-play-types-${item}`}
                    label={item}
                    onChange={() => handlePlayType(!st_PlayType[index], index)}
                    value={String(st_PlayType[index])}
                    checked={st_PlayType[index]}
                    data-testid={`normal-play-types-${item}`}
                  />
                </Form.Field>
              ))}
          </Form.Group>
          <hr />
          <Form.Field>
            <Toggle
              id="yards-gained-cf-toggle"
              label="Yards Gained"
              onChange={() =>
                handleEnabledToggle(
                  !cf_YardsGainedEnabledRV,
                  cf_YardsGainedEnabled,
                  'yardsGained',
                  {
                    enabled: !cf_YardsGainedEnabledRV,
                  }
                )
              }
              checked={cf_YardsGainedEnabledRV}
              data-testid="yards-gained-toggle"
            />
            <b
              className={classNames({
                grey: !cf_YardsGainedEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
              data-testid="yards-gained-label"
            >
              {st_YardsGained &&
                returnReadableYardsLabel(st_YardsGained, -11, 26)}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_YardsGained}
              ariaLabel={['yardsGainedMin', 'yardsGainedMax']}
              onChange={setYardsGained}
              onAfterChange={() =>
                enableFilter(
                  cf_YardsGainedEnabledRV,
                  cf_YardsGainedEnabled,
                  'yardsGained'
                )
              }
              pearling
              minDistance={1}
              min={-11}
              max={26}
              withTracks
              step={1}
            />
            <div className="track extreme">
              <div>&lsaquo;</div>
              <div>&rsaquo;</div>
            </div>
            <div className="track yards">
              <div className="minus">-10</div>
              <div className="minus">-5</div>
              <div className="minus">&nbsp;0</div>
              <div>&nbsp;5</div>
              <div>10</div>
              <div>15</div>
              <div>20</div>
              <div>25</div>
            </div>
          </Form.Field>

          <hr />
          <Form.Field>
            <Toggle
              id="qb-drop-depth-cf-toggle"
              label="QB Drop Depth"
              onChange={() =>
                handleEnabledToggle(
                  !cf_QBDropDepthEnabledRV,
                  cf_QBDropDepthEnabled,
                  'qbDropDepth',
                  {
                    enabled: !cf_QBDropDepthEnabledRV,
                  }
                )
              }
              checked={cf_QBDropDepthEnabledRV}
              data-testid="qb-drop-depth-toggle"
            />
            <b
              className={classNames({
                grey: !cf_QBDropDepthEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
            >
              {st_QBDropDepth &&
                returnReadableYardsLabel(st_QBDropDepth, 0, 16)}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_QBDropDepth}
              ariaLabel={['qbDropDepthMin', 'qbDropDepthMax']}
              onChange={setQBDropDepth}
              onAfterChange={() =>
                enableFilter(
                  cf_QBDropDepthEnabledRV,
                  cf_QBDropDepthEnabled,
                  'qbDropDepth'
                )
              }
              pearling
              minDistance={1}
              min={0}
              max={16}
              withTracks
              step={1}
            />
            <div className="track extreme">
              <div />
              <div>&rsaquo;</div>
            </div>
            <div className="track yards right">
              <div>&nbsp;0</div>
              <div>5</div>
              <div>10</div>
              <div>15</div>
            </div>
          </Form.Field>

          <hr />
          <Form.Field>
            <Toggle
              id="qb-ttt-cf-toggle"
              label="QB Time to Throw"
              onChange={() =>
                handleEnabledToggle(
                  !cf_QBTimeToThrowEnabledRV,
                  cf_QBTimeToThrowEnabled,
                  'qbTimeToThrow',
                  {
                    enabled: !cf_QBTimeToThrowEnabledRV,
                  }
                )
              }
              checked={cf_QBTimeToThrowEnabledRV}
              data-testid="qb-time-to-throw-toggle"
            />
            <b
              className={classNames({
                grey: !cf_QBTimeToThrowEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
            >
              {st_QBTimeToThrow && getTimeToLabel(st_QBTimeToThrow, [0, 5.1])}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_QBTimeToThrow}
              ariaLabel={['qbTimeToThrowMin', 'qbTimeToThrowMax']}
              onChange={setQBTimeToThrow}
              onAfterChange={() =>
                enableFilter(
                  cf_QBTimeToThrowEnabledRV,
                  cf_QBTimeToThrowEnabled,
                  'qbTimeToThrow'
                )
              }
              pearling
              minDistance={0.1}
              min={0}
              max={5.1}
              withTracks
              step={0.1}
            />
            <div className="track extreme">
              <div />
              <div>&rsaquo;</div>
            </div>
            <div className="track yards right timeToThrow">
              <div>&nbsp;0</div>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
          </Form.Field>

          <hr />
          <Form.Field>
            <Toggle
              id="qb-pocket-location-cf-toggle"
              label="QB Pocket Location"
              onChange={() =>
                handleEnabledToggle(
                  !cf_QBPocketLocationEnabledRV,
                  cf_QBPocketLocationEnabled,
                  'qbPocketLocation',
                  {
                    enabled: !cf_QBPocketLocationEnabledRV,
                  }
                )
              }
              checked={cf_QBPocketLocationEnabledRV}
              data-testid="qb-pocket-location-toggle"
            />
          </Form.Field>
          <Form.Group className="boxed feature">
            {st_QBPocketLocation &&
              Object.values(qbPocketLocation).map((item, index) => (
                <Form.Field key={`qb-pocket-location-${item}`}>
                  <Checkbox
                    id={`qb-pocket-location-${item}-checkbox`}
                    label={item}
                    onChange={() =>
                      handleQBPocketLocation(!st_QBPocketLocation[index], index)
                    }
                    checked={st_QBPocketLocation[index]}
                    value={String(st_QBPocketLocation[index])}
                  />
                </Form.Field>
              ))}
          </Form.Group>

          <hr />
          <Form.Field>
            <Toggle
              id="depth-of-target-cf-toggle"
              label="Depth of Target"
              onChange={() =>
                handleEnabledToggle(
                  !cf_PassAirYardsEnabledRV,
                  cf_PassAirYardsEnabled,
                  'passAirYards',
                  {
                    enabled: !cf_PassAirYardsEnabledRV,
                  }
                )
              }
              checked={cf_PassAirYardsEnabledRV}
              data-testid="depth-of-target-toggle"
            />
            <b
              className={classNames({
                grey: !cf_PassAirYardsEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
              data-testid="depth-of-target-label"
            >
              {st_PassAirYards &&
                returnReadableYardsLabel(st_PassAirYards, -6, 41)}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_PassAirYards}
              ariaLabel={['airYardsGainedMin', 'airYardsGainedMax']}
              onChange={setPassAirYards}
              onAfterChange={() =>
                enableFilter(
                  cf_PassAirYardsEnabledRV,
                  cf_PassAirYardsEnabled,
                  'passAirYards'
                )
              }
              pearling
              minDistance={1}
              min={-6}
              max={41}
              withTracks
              step={1}
            />
            <div className="track extreme">
              <div>&lsaquo;</div>
              <div>&rsaquo;</div>
            </div>
            <div className="track yards">
              <div className="minus">-5</div>
              <div className="minus">&nbsp;0</div>
              <div>&nbsp;5</div>
              <div>10</div>
              <div>15</div>
              <div>20</div>
              <div>25</div>
              <div>30</div>
              <div>35</div>
              <div>40</div>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleReset}>
          Reset
        </Button>
        <Button onClick={handleModalClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

TypeFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default TypeFilter;
