import { styled } from 'styled-components';

export const StyledHeader = styled.header`
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 3.25rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  box-shadow: ${({ theme }) => theme.effects.dropShadow.uber};

  a {
    background-color: ${({ theme }) => theme.colours.brand.main};
    flex: 0 0 187px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // changes box shadow when sub filters are present
  &:has(+ .core > .sub-header-container) {
    box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 5%);
  }
`;
