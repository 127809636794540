import { orderBy } from 'lodash';
import {
  FORMATION_DETAIL_STATS_CONFIG,
  FORMATIONS,
} from './TeamFormationDetail.constants';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';

export const getFormationDetailCsvData = (data) => {
  if (!data) {
    return [];
  }
  const headers = ['trait', ...Object.keys(FORMATION_DETAIL_STATS_CONFIG)];
  const csvData = data
    .map((d) =>
      d.rows.map((row) => [
        d.label,
        ...Object.values(row).map((stat) => stat.label),
      ])
    )
    .flat();

  return [headers, ...csvData];
};

// format results to be used in the dropdown
export const formatFormationsForDropDown = (formationUsages) => {
  const options = formationUsages?.map((formation) => ({
    value: FORMATIONS.find((f) => f.name === formation.formationName).apiKey,
    secondaryValue: formation.playCount,
    label: formation.formationName,
    secondaryLabel: `(${formation.playCount}) plays`,
  }));

  const orderedOptions = orderBy(
    options,
    ['secondaryValue'],
    [SORT_DIRECTIONS.DESCENDING]
  );

  return orderedOptions;
};
