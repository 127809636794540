import { styled } from 'styled-components';
import { Table } from 'semantic-ui-react';

export const StyledCompactTable = styled(Table)`
  border: 0 !important;
  margin: 0 !important;

  &.table tbody {
    font-size: 1.2rem;

    tr {
      color: ${({ theme }) => theme.colours.ink.primary.main};

      &:nth-child(odd) {
        background-color: ${({ theme }) =>
          theme.colours.canvas.secondary.main} !important;
      }

      &:nth-child(even) {
        background-color: ${({ theme }) =>
          theme.colours.canvas.tertiary.main} !important;
      }

      &:hover {
        background: ${({ theme }) =>
          theme.colours.canvas.secondary.strong} !important;
      }

      &.active {
        background-color: ${({ theme }) =>
          theme.colours.interface.weak} !important;
        color: ${({ theme }) => theme.colours.interface.ink} !important;
      }

      &.disabled {
        td {
          color: ${({ theme }) =>
            theme.applyOpacity(
              theme.colours.ink.primary.main,
              'disabled'
            )} !important;
        }
      }
    }

    tr {
      td {
        height: 3rem;
        line-height: 1.3;
        padding: 5px 8px;
        cursor: pointer;
        transition: background 0.25s ease;
      }

      td + td {
        width: 50px;
        text-align: right;
      }
    }
  }
`;
