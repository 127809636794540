import {
  CHART_SCALE_TYPE,
  DEFAULT_AXIS_SETUP,
} from '../BasicChart/BasicChart.constants';

export const SCATTER_AXES_SETUP = {
  X: {
    ...DEFAULT_AXIS_SETUP,
    TYPE: CHART_SCALE_TYPE.CATEGORICAL,
  },
  Y: {
    ...DEFAULT_AXIS_SETUP,
  },
  R: {
    ...DEFAULT_AXIS_SETUP,
    TYPE: CHART_SCALE_TYPE.NUMERIC_RADIAL,
    RANGE: [1, 10], // note: range max > axes padding means cropping may occur
    NICE: false,
  },
};

export const SCATTER_CLASSES = {
  AVERAGE_LINES: 'scatter-average-lines',
  SCATTER_DATA: 'scatter-canvas-data',
  SCATTER_DATA_SECOND_SERIES: 'scatter-canvas-data-2',
};
