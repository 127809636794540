import PropTypes from 'prop-types';

const radarAxisPropTypes = PropTypes.exact({
  // the name of the stat (player stat or team stat key)
  key: PropTypes.string,
  // The human readable name of the metric (should be a short name)
  name: PropTypes.string,
  // The long form stat name
  prettyName: PropTypes.string,
  // String describing the meaning of the metric
  description: PropTypes.string,
  // int that determines the display order of axis in a radar
  index: PropTypes.number,
  // Number that is the lower limit for this metric, or the upper limit for a reverse metric
  // i.e. the value to be displayed at the inner edge of the axis
  min: PropTypes.number,
  // Number that is the upper limit for this metric (or vice versa as above)
  max: PropTypes.number,
  // enum denoting how the stat should get formatted
  units: PropTypes.string,
});
// An array of objects containing the data for the table header rows
export const radarTemplatePropTypes = PropTypes.arrayOf(
  // An object containing properties to render the table header
  radarAxisPropTypes
);

// An object containing color information for a team
export const teamColorPropTypes = PropTypes.shape({
  primary: PropTypes.string, // should be a hex code, i.e. string like "#00ff00"
  secondary: PropTypes.string, // should be a hex code, i.e. string like "#00ff00"
  tertiary: PropTypes.string, // should be a hex code, i.e. string like "#00ff00"
});

// An object containing properties to render in the radar header
export const headerInfoPropTypes = PropTypes.shape({
  playerName: PropTypes.string,
  teamName: PropTypes.string,
  seasonName: PropTypes.string,
  templateName: PropTypes.string,
  showLeagueAverage: PropTypes.bool,
});

// An object detailing a radar shape
export const keyRadarShapePropTypes = PropTypes.shape({
  title: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
});

// An object containing the info to make a Radar Vis Key
export const radarKeyPropTypes = PropTypes.shape({
  main: keyRadarShapePropTypes,
  comparison: keyRadarShapePropTypes,
});
