import { styled } from 'styled-components';

export const SaveModalContent = styled.div`
  width: 25vw;

  div {
    margin-bottom: 8px;
  }
`;
SaveModalContent.displayName = 'SaveModalContent';

export const SaveStatusMessage = styled.span`
  display: block;
  margin-bottom: 4px;
`;
SaveStatusMessage.displayName = 'SaveStatusMessage';
