export const METRIC_MODAL_HEADERS = [
  { key: 'abbreviation', label: 'Short Name' },
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' },
];

export const METRIC_MODAL_HEIGHTS = {
  BODY: 400,
  TABLE: 376, // body -24 = no scrolling on right hand panel
};
