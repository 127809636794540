import { sortBy } from 'lodash';
import {
  END_ZONE_WIDTH,
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
} from '../../../utils/constants/charting';
import { getJerseyRotation } from '../../../utils/helpers/player';
import { addField } from '../../../utils/helpers/field';
import {
  getZoomFactor,
  getClampedFreezeFrames,
  getMidFrames,
  getBoxLimits,
  getTaggedFFs,
  SNAP_FORMATION_ZOOM_MODES,
} from './DataManipulation';
import {
  addOffensiveLineMeasure,
  addBoxMeasure,
  addDefensiveFormationMeasure,
  addOffensiveFormationMeasure,
  addOffensiveSplitMeasure,
  addDepths,
  addDefensiveSplitMeasure,
} from './FFChartMeasures.drawing';
import {
  POSITION_COLOR_ATTRIBUTE_BORDER,
  POSITION_COLOR_ATTRIBUTE_JERSEY,
  POSITION_COLOR_ATTRIBUTE_MAIN,
} from '../../../utils/visualisations/visPalettes';
import {
  rotateScaleZoom,
  rotateScaleZoomViewPortLimits,
} from '../../../utils/visualisations/rotateScaleZoom';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { drawBall } from '../../../utils/visualisations/shapes';
import {
  ALIGNMENT_POSITIONS,
  ROSTER_POSITIONS,
} from '../../../utils/constants/positions';
import { getRosterPositionColor } from '../../../utils/helpers/positions';
import {
  API_ALIGNMENT_POSITION_KEYS,
  API_ROSTER_POSITION_KEYS,
} from '../../../utils/constants/api';

const DEFAULT_PLAYER_SHAPE_RADIUS = 0.8; // yds ~ minimal overlapping
const DEFAULT_PLAYER_JERSEY_RADIUS = 1; // yds ~ slight overlapping

const ffPlayerColor = (ffDatum, visPalette, positionColorAttribute) =>
  getRosterPositionColor(
    ffDatum?.player?.position,
    visPalette,
    positionColorAttribute
  );

/* TODO: 
The player match logic only works in snap formations because that event is 
  where the center has the ball (is the eventer)
For event tiles we either need alignment codes from the API or to otherwise
    identify the center
*/
const playerShapeTransform = (freezeFrame, snapPlayerId, pxPerYard) => {
  const translate = `translate(${freezeFrame.x * pxPerYard},${
    freezeFrame.y * pxPerYard
  })`;
  const position =
    snapPlayerId === freezeFrame?.player?.id
      ? ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.CENTER]
      : freezeFrame?.player?.position;
  const rotation = position?.rotation
    ? `rotate(${position.rotation})`
    : 'rotate(90)';

  return `${translate} ${rotation}`;
};

const addLOS = function (
  fieldG,
  ffEvent,
  fieldOverrides,
  zoomFactor,
  visPalette
) {
  if (!ffEvent) {
    return;
  }
  const relativeStroke =
    visPalette.commonComponents.lineOfScrimmage.strokeWidth / zoomFactor;
  const relativeDash =
    visPalette.commonComponents.lineOfScrimmage.strokeDashArray
      .split(' ')
      .map((m) => m / zoomFactor)
      .join(' ');
  fieldG
    .append('g')
    .append('line')
    .attr(
      'x1',
      (ffEvent.play.yardLine + END_ZONE_WIDTH) * fieldOverrides.pxPerYard
    )
    .attr(
      'x2',
      (ffEvent.play.yardLine + END_ZONE_WIDTH) * fieldOverrides.pxPerYard
    )
    .attr('y1', 0)
    .attr('y2', FIELD_Y_YARDS * fieldOverrides.pxPerYard)
    .attr('stroke', visPalette.commonComponents.lineOfScrimmage.stroke)
    .attr(
      'stroke-opacity',
      visPalette.commonComponents.lineOfScrimmage.strokeOpacity
    )
    .attr('stroke-width', relativeStroke)
    .attr('stroke-dasharray', relativeDash);
};

const addJersey = (
  gZone,
  freezeFrames,
  pxPerYard,
  rotationOption,
  playerShapeRadius,
  visPalette,
  selectedOffensePlayerId,
  selectedDefensePlayerId
) => {
  gZone
    .append('g')
    .attr('id', 'player-jerseys')
    .selectAll('g')
    .data(freezeFrames)
    .enter()
    .append('g')
    .attr(
      'transform',
      (k) => `translate(${k.x * pxPerYard},${k.y * pxPerYard})`
    )
    .append('text')
    .attr('x', 0)
    .attr('y', playerShapeRadius * 0.45)
    .attr('transform', getJerseyRotation(rotationOption))
    .attr('fill', (d) =>
      d?.player?.id &&
      (parseInt(d.player.id, 10) === selectedOffensePlayerId ||
        parseInt(d.player.id, 10) === selectedDefensePlayerId)
        ? ffPlayerColor(d, visPalette, POSITION_COLOR_ATTRIBUTE_MAIN)
        : ffPlayerColor(d, visPalette, POSITION_COLOR_ATTRIBUTE_JERSEY)
    )
    .attr('font-family', DEFAULT_FONT)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.ICON_LABEL.WEIGHT)
    .attr('font-size', playerShapeRadius * 1.2)
    .attr('text-anchor', 'middle')
    .text((k) => k.player.jerseyNumber);
};

const addMeasures = function (
  measuresG,
  freezeFrames,
  viewFrame,
  rYds,
  outOfFrameShift,
  zoomFactor,
  fieldOverrides,
  measureOptions,
  lineOfScrimmage,
  rotationOption,
  visPalette
) {
  const outOfFrameBump = outOfFrameShift * 2;
  const inFrameBump = outOfFrameShift * 2;

  if (measureOptions.boxPlayersCount) {
    addBoxMeasure(
      measuresG,
      freezeFrames,
      zoomFactor,
      fieldOverrides,
      lineOfScrimmage
    );
  }

  if (measureOptions.offensiveFormationWidth) {
    addOffensiveFormationMeasure(
      measuresG,
      freezeFrames,
      viewFrame,
      rYds,
      outOfFrameShift,
      zoomFactor,
      fieldOverrides,
      visPalette,
      measureOptions,
      outOfFrameBump,
      rotationOption
    );
  }
  if (measureOptions.defensiveFormationWidth) {
    addDefensiveFormationMeasure(
      measuresG,
      freezeFrames,
      viewFrame,
      rYds,
      outOfFrameShift,
      zoomFactor,
      fieldOverrides,
      visPalette,
      outOfFrameBump,
      rotationOption
    );
  }

  if (measureOptions.offensiveLineWidth) {
    addOffensiveLineMeasure(
      measuresG,
      freezeFrames,
      viewFrame,
      rYds,
      outOfFrameShift,
      zoomFactor,
      fieldOverrides,
      visPalette,
      outOfFrameBump,
      rotationOption
    );
  }

  if (measureOptions.tightEndSplits || measureOptions.wideReceiverSplits) {
    const positionCodes = [];
    if (measureOptions.tightEndSplits) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.TIGHT_END].code
      );
    }
    if (measureOptions.wideReceiverSplits) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER].code
      );
    }
    addOffensiveSplitMeasure(
      measuresG,
      freezeFrames,
      rYds,
      zoomFactor,
      fieldOverrides,
      inFrameBump,
      positionCodes,
      lineOfScrimmage,
      rotationOption,
      visPalette
    );
  }
  if (measureOptions.defensiveBackWidths || measureOptions.linebackerWidths) {
    const positionCodes = [];
    if (measureOptions.defensiveBackWidths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK].code
      );
    }
    if (measureOptions.linebackerWidths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.LINEBACKER].code
      );
    }
    addDefensiveSplitMeasure(
      measuresG,
      freezeFrames,
      rYds,
      zoomFactor,
      fieldOverrides,
      inFrameBump,
      positionCodes,
      lineOfScrimmage,
      rotationOption,
      visPalette
    );
  }

  if (
    measureOptions.defensiveBackDepths ||
    measureOptions.linebackerDepths ||
    measureOptions.wideReceiverDepths ||
    measureOptions.tightEndDepths
  ) {
    const positionCodes = [];
    if (measureOptions.defensiveBackDepths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK].code
      );
    }
    if (measureOptions.linebackerDepths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.LINEBACKER].code
      );
    }
    if (measureOptions.wideReceiverDepths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER].code
      );
    }
    if (measureOptions.tightEndDepths) {
      positionCodes.push(
        ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.TIGHT_END].code
      );
    }
    addDepths(
      measuresG,
      freezeFrames,
      viewFrame,
      rYds,
      zoomFactor,
      fieldOverrides,
      outOfFrameBump,
      positionCodes,
      lineOfScrimmage,
      rotationOption,
      outOfFrameShift,
      visPalette
    );
  }
};

const selectPlayerColor = function (
  ffDatum,
  visPalette,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  isFill = true,
  isBorder = false,
  showJersey = false
) {
  const isSelectedPlayer =
    ffDatum?.player?.id &&
    (parseInt(ffDatum.player.id, 10) === selectedOffensePlayerId ||
      parseInt(ffDatum.player.id, 10) === selectedDefensePlayerId);
  if (isSelectedPlayer) {
    // when selected invert main and jersey colours
    return isBorder
      ? ffPlayerColor(ffDatum, visPalette, POSITION_COLOR_ATTRIBUTE_MAIN)
      : ffPlayerColor(ffDatum, visPalette, POSITION_COLOR_ATTRIBUTE_JERSEY);
  }
  if (isBorder) {
    return showJersey
      ? ffPlayerColor(ffDatum, visPalette, POSITION_COLOR_ATTRIBUTE_BORDER)
      : ffPlayerColor(ffDatum, visPalette, POSITION_COLOR_ATTRIBUTE_MAIN);
  }
  return isFill && ffDatum.isClamped // shapes outside the main viewframe
    ? 'transparent'
    : ffPlayerColor(ffDatum, visPalette, POSITION_COLOR_ATTRIBUTE_MAIN);
};

export const addEventFF = function (
  parentG,
  fieldOverrides,
  visPalette,
  ffEvent,
  ffZoneSizes,
  rotationOption,
  zoomOption,
  focusOptionX,
  focusOptionY,
  showJerseys,
  measureOptions,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  showBall,
  id
) {
  parentG.selectAll('g').remove();

  const marginTranslate = `translate(${ffZoneSizes.margin.left},${ffZoneSizes.margin.top})`;

  const { midViewX, midViewY } = getMidFrames(
    focusOptionX,
    focusOptionY,
    ffEvent
  );
  const zoomFactor = getZoomFactor(zoomOption, ffEvent);
  // when not showing full field width, allow 0.5yds allowance at field edges
  const fieldBoundary = zoomOption === SNAP_FORMATION_ZOOM_MODES.FIELD ? 0 : 5;

  /* The viewable limits works in the standard coordinate zone (no x shift) */
  const viewPortEdges = rotateScaleZoomViewPortLimits({
    viewPortWidth: ffZoneSizes.width,
    viewPortHeight: ffZoneSizes.height,
    orientation: rotationOption,
    fieldMinX: -1 * END_ZONE_WIDTH * fieldOverrides.pxPerYard,
    fieldMaxX: (FIELD_X_YARDS - END_ZONE_WIDTH) * fieldOverrides.pxPerYard,
    fieldMinY: 0,
    fieldMaxY: FIELD_Y_YARDS * fieldOverrides.pxPerYard,
    targetFieldX: midViewX * fieldOverrides.pxPerYard,
    targetFieldY: midViewY * fieldOverrides.pxPerYard,
    scaleFactor: zoomFactor,
    bindEdges: true,
    fieldBoundary,
  });

  /** THe coordinates of dots etc. are based on x=0 not including the end zone
   * Rotations layer is full field, so the target point has to be shifted by the end zone
   */
  const fieldFocalPointShiftX = END_ZONE_WIDTH;

  const rotateScaleZoomOptions = {
    idPrefix: `dnl-${id}-${ffEvent.id}`,
    viewPortWidth: ffZoneSizes.width,
    viewPortHeight: ffZoneSizes.height,
    cropToViewport: true,
    orientation: rotationOption,
    fieldWidth: FIELD_X_YARDS * fieldOverrides.pxPerYard,
    fieldHeight: FIELD_Y_YARDS * fieldOverrides.pxPerYard,
    targetFieldX: (midViewX + fieldFocalPointShiftX) * fieldOverrides.pxPerYard,
    targetFieldY: midViewY * fieldOverrides.pxPerYard,
    scaleFactor: zoomFactor,
    bindEdges: true,
    fieldBoundary,
    addZoom: false,
    zoomableGId: `${id}-zoomable-g`,
    resetButtonId: null,
  };

  /* Need 4 layers: 
    layer (clipped) for the field 
    layer (no clip) for measures
    layer (clipped) for players/balls
    layer (no clip) for out of bounds players
  */
  const fieldG = rotateScaleZoom({
    ...rotateScaleZoomOptions,
    baseG: parentG.append('g').attr('transform', marginTranslate),
  });
  const measuresBaseG = rotateScaleZoom({
    ...rotateScaleZoomOptions,
    cropToViewport: false,
    baseG: parentG.append('g').attr('transform', marginTranslate),
  });
  const playersG = rotateScaleZoom({
    ...rotateScaleZoomOptions,
    baseG: parentG.append('g').attr('transform', marginTranslate),
  });
  const outOfBoundsG = rotateScaleZoom({
    ...rotateScaleZoomOptions,
    cropToViewport: false,
    baseG: parentG.append('g').attr('transform', marginTranslate),
  });

  /* Add the field */
  fieldG.call(addField, fieldOverrides);
  /* Line of Scrimmage */
  fieldG.call(addLOS, ffEvent, fieldOverrides, zoomFactor, visPalette);

  /* Players */
  const outOfFrameShift = 1 / zoomFactor;
  /* Using the viewFrame, limit the displacement of out of frame players */
  const boxLimits = getBoxLimits(ffEvent);
  const allFFs = getTaggedFFs(ffEvent, boxLimits);
  const clampedFreezeFrames = getClampedFreezeFrames(
    allFFs,
    viewPortEdges.minY / fieldOverrides.pxPerYard,
    viewPortEdges.maxY / fieldOverrides.pxPerYard,
    viewPortEdges.minX / fieldOverrides.pxPerYard,
    viewPortEdges.maxX / fieldOverrides.pxPerYard,
    outOfFrameShift
  );

  /* filter to visible players (not ball) & 
    make sure the eventer is drawn last (snaper on snap, passer on pass etc.) */
  const freezeFrames = sortBy(
    clampedFreezeFrames
      .filter((f) => f.isClamped === false)
      .filter((f) => f.ballOnly === false),
    'isEventer'
  );
  const rYds = showJerseys
    ? DEFAULT_PLAYER_JERSEY_RADIUS / zoomFactor
    : DEFAULT_PLAYER_SHAPE_RADIUS / zoomFactor;
  const r = rYds * fieldOverrides.pxPerYard;

  /* Measures sit under player shapes */
  if (
    measureOptions.offensiveLineWidth ||
    measureOptions.offensiveFormationWidth ||
    measureOptions.defensiveFormationWidth ||
    measureOptions.boxPlayersCount ||
    measureOptions.tightEndSplits ||
    measureOptions.tightEndDepths ||
    measureOptions.wideReceiverSplits ||
    measureOptions.wideReceiverDepths ||
    measureOptions.linebackerWidths ||
    measureOptions.linebackerDepths ||
    measureOptions.defensiveBackWidths ||
    measureOptions.defensiveBackDepths
  ) {
    const measuresG = measuresBaseG
      .append('g')
      .attr(
        'transform',
        `translate(${END_ZONE_WIDTH * fieldOverrides.pxPerYard},0) `
      );

    /** viewFrame is what measures used before refactor to use RSZ
     * TODO (non urgent): change all the measures file logic to use viewPortEdges
     */
    const viewFrame = {
      xShift: {
        minX: viewPortEdges.minX / fieldOverrides.pxPerYard,
        maxX: viewPortEdges.maxX / fieldOverrides.pxPerYard,
      },
      yShift: {
        minY: viewPortEdges.minY / fieldOverrides.pxPerYard,
        maxY: viewPortEdges.maxY / fieldOverrides.pxPerYard,
      },
    };

    addMeasures(
      measuresG,
      freezeFrames,
      viewFrame,
      rYds,
      outOfFrameShift,
      zoomFactor,
      fieldOverrides,
      measureOptions,
      ffEvent.play.yardLine,
      rotationOption,
      visPalette
    );
  }

  /* add the visible players */
  playersG
    .append('g')
    .attr(
      'transform',
      `translate(${END_ZONE_WIDTH * fieldOverrides.pxPerYard},0) `
    )
    .selectAll('path')
    .data(freezeFrames)
    .enter()
    .append('svg:path')
    .attr('d', (k) => k.player?.position?.shape(r))
    .attr('transform', (d) =>
      playerShapeTransform(d, ffEvent?.player?.id, fieldOverrides.pxPerYard)
    )
    .attr('fill', (d) =>
      selectPlayerColor(
        d,
        visPalette,
        selectedOffensePlayerId,
        selectedDefensePlayerId,
        true,
        false,
        showJerseys
      )
    )
    .attr('stroke', (d) =>
      selectPlayerColor(
        d,
        visPalette,
        selectedOffensePlayerId,
        selectedDefensePlayerId,
        false,
        true,
        showJerseys
      )
    )
    .attr('stroke-width', 1 / zoomFactor);

  if (showBall) {
    const ballG = playersG
      .append('g')
      .attr(
        'transform',
        `translate(${END_ZONE_WIDTH * fieldOverrides.pxPerYard},0) `
      );
    const ballFFs = allFFs.filter((f) => f.ballLocation);

    if (ballFFs.length > 0) {
      const ballR = 0.8 * r;
      ballG
        .append('svg:path')
        .attr(
          'transform',
          `translate(${ballFFs[0].x * fieldOverrides.pxPerYard}, ${
            ballFFs[0].y * fieldOverrides.pxPerYard
          })`
        )
        .attr('d', drawBall(ballR))
        .attr('fill', visPalette.commonComponents.ball.fill)
        .attr('fill-opacity', visPalette.commonComponents.ball.fillOpacity)
        .attr('stroke', visPalette.commonComponents.ball.stroke)
        .attr('stroke-opacity', visPalette.commonComponents.ball.strokeOpacity)
        .attr('stroke-width', visPalette.commonComponents.ball.strokeWidth);
    }
  }

  if (showJerseys) {
    const playerFFs = freezeFrames.filter((f) => f.player);
    const jerseyG = playersG
      .append('g')
      .attr(
        'transform',
        `translate(${END_ZONE_WIDTH * fieldOverrides.pxPerYard},0) `
      );
    addJersey(
      jerseyG,
      playerFFs,
      fieldOverrides.pxPerYard,
      rotationOption,
      r,
      visPalette,
      selectedOffensePlayerId,
      selectedDefensePlayerId
    );
  }

  /* Add indicators for the players OUB */
  const freezeFramesOUB = clampedFreezeFrames.filter(
    (f) => f.isClamped === true
  );
  const rOutOfBounds = r / 2;

  outOfBoundsG
    .append('g')
    .attr(
      'transform',
      `translate(${END_ZONE_WIDTH * fieldOverrides.pxPerYard},0) `
    )
    .selectAll('path')
    .data(freezeFramesOUB)
    .enter()
    .append('svg:path')
    .attr('d', (k) => k.player?.position?.shape(rOutOfBounds))
    .attr('transform', (d) =>
      playerShapeTransform(d, ffEvent?.player?.id, fieldOverrides.pxPerYard)
    )
    .attr('fill', (d) =>
      selectPlayerColor(
        d,
        visPalette,
        selectedOffensePlayerId,
        selectedDefensePlayerId,
        true,
        false,
        showJerseys
      )
    )
    .attr('stroke', (d) =>
      selectPlayerColor(
        d,
        visPalette,
        selectedOffensePlayerId,
        selectedDefensePlayerId,
        false,
        true,
        showJerseys
      )
    )
    .attr('stroke-width', 1 / zoomFactor);
};
