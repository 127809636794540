import { useQuery, useReactiveVar } from '@apollo/client';
import {
  mf_AllSeasons,
  mf_LeagueLevel,
  mf_Seasons,
  mf_Teams,
  cf_GameIds,
  cf_GameIdsEnabled,
} from '../../apollo';
import { GET_GAMES } from '../../components/Header/getGames';
import { ERROR_CODES } from '../errorCodes/errorCodes';

/**
 * Custom hook for figuring out what text to display in the
 * export template season info
 */
export const useExportSeasonInfo = () => {
  const { data, loading, error } = useQuery(GET_GAMES);
  const gamesData = data?.games.edges.map(({ node }) => node) || [];

  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const season = useReactiveVar(mf_Seasons);
  const teamId = useReactiveVar(mf_Teams);
  const allSeasons = useReactiveVar(mf_AllSeasons);
  const seasonName = allSeasons?.find((s) => s.id === season)?.name;

  const games = useReactiveVar(cf_GameIds);
  const cf_gamesEnabled = useReactiveVar(cf_GameIdsEnabled);
  const isFiltered = gamesData.length !== games.length && cf_gamesEnabled;

  if (loading) {
    return 'Loading';
  }

  if (error) {
    console.error(ERROR_CODES.USE_EXPORT_SEASON_INFO, error);
    return '';
  }

  // context filter is on and has 1 game selected
  if (games.length === 1 && isFiltered) {
    const game = gamesData.find((g) => g.id === games[0]);
    const { homeTeam, awayTeam } = game;
    const formattedDate = new Date(game.date).toLocaleString('en-us', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
    const isHomeTeam = homeTeam.id === teamId;
    const opponentTeam = isHomeTeam ? awayTeam : homeTeam;

    return `${competitionLevel}, ${formattedDate} ${isHomeTeam ? 'vs' : '@'} ${
      opponentTeam.shortName
    }`;
  }

  // // context filter is on and multiple games are selected
  if (games.length > 1 && isFiltered) {
    return `${competitionLevel}, ${games.length} games from ${seasonName}`;
  }

  // context game filter is off or all games are currently selected
  return `${competitionLevel}, ${seasonName}`;
};
