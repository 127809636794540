import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextField,
  Icon,
  Dropdown,
} from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { AccordionSaveRow } from '../../Scout.styles';
import { SaveModalContent, SaveStatusMessage } from './SaveModal.styles';
import {
  hasUnsavedChanges,
  saveStatus,
} from '../ScoutResults.dataManipulation';
import { uiState, ui_scoutSetups } from '../../../../apollo';
import { isTemplateNameGood } from './SaveModal.validator';
import BannerError from '../../../../components/Banners/Banner.Error';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../../components/DropdownFilter/DropdownFilter.components';
import {
  SAVE_MENU_ACTIONS,
  SAVE_MENU_OPTIONS,
} from '../ScoutResults.constants';
import { SCOUT_TEMPLATE_SAVE_MDOES } from '../../Scout.constants';
import { biometricProp, playerMetricProp } from '../../Scout.proptypes';
import useUserConfig from '../../../../utils/hooks/useUserConfig';

const SaveModal = ({
  modalId,
  scoutTemplateId,
  selectedStats,
  selectedPositions,
  selectedBiometrics,
  onSaved,
}) => {
  /* Button & Open state */
  const [isOpen, setIsOpen] = useState(false);
  const savedSetups = useReactiveVar(ui_scoutSetups);
  const savedSetup = savedSetups?.find((s) => s.id === scoutTemplateId) || {};
  const [saveMode, setSaveMode] = useState(SCOUT_TEMPLATE_SAVE_MDOES.QUICK);

  /* 
  Quick-save wants to fire save function and setSaveMode simultaneously, which 
    results in the save function seeing the prior saveMode value (async functions)
    Thus: whenever there is no modal open, saveMode is quick. 
  When a save modal (edit/new/save-as) is opened the state gets appropriately set:
    the save buttons in the modal then work as expected
  */
  useEffect(() => {
    if (!isOpen) {
      setSaveMode(SCOUT_TEMPLATE_SAVE_MDOES.QUICK);
    }
  }, [isOpen]);

  /* Template Name */
  const [templateName, setTemplateName] = useState('');
  const makingNewTemplate = [
    SCOUT_TEMPLATE_SAVE_MDOES.NEW,
    SCOUT_TEMPLATE_SAVE_MDOES.SAVE_AS,
  ].includes(saveMode);

  /* validator checks for unique names against the other templates (and other safety valves) ~
      when doing "save as" the currently selected template is a potential name conflict
  */
  const validatorTemplateId = makingNewTemplate ? '' : savedSetup.id;
  const { valid: validTemplateName, message: validatorMessage } =
    isTemplateNameGood(templateName, savedSetups, validatorTemplateId);

  const { save } = useUserConfig();
  const { user } = useKitbagAuth();

  /* 
  Every save creates a new object, removes the old from the array (if exists) and adds the new
    Properties get cloned to new object when updating/editing
  */
  const saveCurrentSetup = () => {
    const setup = { lastEditor: user.name, lastModified: Date.now() };

    /* If making a new template, generate id and creation refs */
    if (makingNewTemplate) {
      setup.id = crypto.randomUUID();
      setup.creator = user.name;
      setup.created = Date.now();
    } else {
      /* 
      Updating an existing template (quick-save or edit) 
      Properties just maintained (should match below ~ those set on creation)
      */
      setup.id = savedSetup.id;
      setup.creator = savedSetup.creator;
      setup.created = savedSetup.created;
    }

    /* Name always changeable in modal, but not quicksave */
    if (saveMode === SCOUT_TEMPLATE_SAVE_MDOES.QUICK) {
      setup.name = savedSetup.name;
    } else {
      setup.name = templateName;
    }

    /* always update: 
      stats defs 
      positions
      biometrics
    */
    setup.selectedStats = selectedStats;
    setup.selectedPositions = selectedPositions;
    setup.selectedBiometrics = selectedBiometrics;

    /* add to array of saves and update page */
    const otherSetups = isEmpty(savedSetups)
      ? []
      : savedSetups.filter((s) => s.id !== setup.id);
    const updatedSetups = otherSetups.concat(setup);
    ui_scoutSetups(updatedSetups);
    uiState().ui.scoutSetups = updatedSetups;
    save(uiState().ui);
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setIsOpen(false);
    onSaved(setup);
  };

  const modalTitle = makingNewTemplate
    ? 'Save New Template'
    : `Save Template "${savedSetup.name}"`;

  /* Options to disable */
  const saveNewDisabled = isEmpty(selectedStats) && isEmpty(selectedBiometrics);
  const quickSaveDisabled =
    !hasUnsavedChanges(
      savedSetup,
      scoutTemplateId,
      selectedStats,
      selectedPositions,
      selectedBiometrics
    ) || saveNewDisabled;
  const modalSaveDisabled = !validTemplateName;

  const newSaveButton = (
    <Button
      variant="primary"
      size="small"
      isDisabled={saveNewDisabled}
      onClick={() => {
        setSaveMode(SCOUT_TEMPLATE_SAVE_MDOES.NEW);
        setIsOpen(true);
        setTemplateName('');
      }}
    >
      <span>Save new template</span>
      <Icon variant="Save" size="small" />
    </Button>
  );
  const quickSaveButton = (
    <Button
      variant="primary"
      size="small"
      isDisabled={quickSaveDisabled}
      onClick={() => {
        setSaveMode(SCOUT_TEMPLATE_SAVE_MDOES.QUICK);
        saveCurrentSetup();
      }}
    >
      <Icon variant="Save" size="small" />
      <span>Save changes</span>
    </Button>
  );

  const saveMenuSelected = (selectedOption) => {
    if (selectedOption.value === SAVE_MENU_ACTIONS.SAVE_AS.value) {
      setIsOpen(true);
      setSaveMode(SCOUT_TEMPLATE_SAVE_MDOES.SAVE_AS);
      setTemplateName('');
    }
    if (selectedOption.value === SAVE_MENU_ACTIONS.EDIT.value) {
      setIsOpen(true);
      setSaveMode(SCOUT_TEMPLATE_SAVE_MDOES.EDIT);
      setTemplateName(savedSetup?.name);
    }
  };

  const cancelSave = () => {
    setIsOpen(false);
  };

  return (
    <>
      <AccordionSaveRow>
        <SaveStatusMessage>
          {saveStatus(
            savedSetup,
            scoutTemplateId,
            selectedStats,
            selectedPositions,
            selectedBiometrics
          )}
        </SaveStatusMessage>
        <ButtonGroup>
          {scoutTemplateId ? quickSaveButton : newSaveButton}
          <Dropdown
            id="saveMenuDropdown"
            components={{
              Option: OptionWithIcon,
              SingleValue: SingleValueWithIcon,
            }}
            isSearchable={false}
            menuAlignment="right"
            onChange={saveMenuSelected}
            options={SAVE_MENU_OPTIONS}
            placeholder=""
            value={null} // forced null because don't want to lose placeholder
            labelPosition="none"
            label="Passing chart display options"
            variant="tertiary"
            size="small"
            isDisabled={!scoutTemplateId}
          />
        </ButtonGroup>
      </AccordionSaveRow>
      <Modal id={modalId} isOpen={isOpen}>
        <ModalHeader id={`${modalId}-header`}>{modalTitle}</ModalHeader>
        <ModalBody>
          <SaveModalContent>
            <div>
              <TextField
                id="scout-template-name"
                labelPosition="none"
                value={templateName}
                onChange={(t) => setTemplateName(t.target.value)}
                placeholder="scout template name"
                size="small"
              />
            </div>
            {!validTemplateName && <BannerError message={validatorMessage} />}
          </SaveModalContent>
        </ModalBody>
        <ModalFooter
          id={`${modalId}-footer`}
          cancelLabel="Cancel without saving"
          confirmLabel="Save template"
          onCancel={cancelSave}
          onConfirm={saveCurrentSetup}
          onEscape={cancelSave}
          isDisabledConfirm={modalSaveDisabled}
          isDisabledEscape={false}
        />
      </Modal>
    </>
  );
};

SaveModal.propTypes = {
  // id for the modal
  modalId: PropTypes.string,
  // uuid for the setup
  scoutTemplateId: PropTypes.string,
  // the metrics & values to store as part of the setup
  selectedStats: PropTypes.arrayOf(playerMetricProp),
  // the metrics & values to store as part of the setup
  selectedBiometrics: PropTypes.arrayOf(biometricProp),
  // the roster position config to store as part of the setup
  selectedPositions: PropTypes.arrayOf(PropTypes.string),
  // function to perform on close (saving) ~ passes in the new setup
  onSaved: PropTypes.func.isRequired,
};

SaveModal.defaultProps = {
  modalId: 'save-modal',
  scoutTemplateId: null,
  selectedStats: null,
  selectedPositions: null,
  selectedBiometrics: null,
};

export default SaveModal;
