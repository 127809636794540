import PropTypes from 'prop-types';

const playerPropTypes = PropTypes.shape({
  playerId: PropTypes.number,
  playerName: PropTypes.string,
  plays: PropTypes.number,
  similarity: PropTypes.number,
  stats: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  teamName: PropTypes.string,
  teamId: PropTypes.number,
});

export const playerComparisonPropTypes = PropTypes.shape({
  selectedCompetitionId: PropTypes.number,
  selectedPlayerId: PropTypes.number,
  selectedSeasonId: PropTypes.number,
  selectedTeamId: PropTypes.number,
  basePlayer: playerPropTypes,
  comparativePlayers: PropTypes.arrayOf(PropTypes.shape(playerPropTypes)),
});
