import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextField,
} from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { AccordionSaveRow } from '../../Scout.styles';
import {
  MetricModalContent,
  ModalGridHolder,
  ModalMetricHeader,
  ModalSearchHolder,
} from './MetricModal.styles';
import BasicTable from '../../../../components/Table/BasicTable';
import {
  getSliderMetric,
  sortStatsBySearch,
} from './MetricModal.dataManipulation';
import {
  METRIC_MODAL_HEADERS,
  METRIC_MODAL_HEIGHTS,
} from './MetricModal.constants';
import BannerError from '../../../../components/Banners/Banner.Error';
import { useGetPlayerStatDistribution } from './useGetPlayerStatDistribution';
import { API_ROSTER_POSITION_KEYS } from '../../../../utils/constants/api';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import {
  getHeaderTooltip,
  getScaleFormatter,
} from '../ScoutResults.dataManipulation';
import MetricDualSlider from '../MetricDualSlider/MetricDualSlider';
import { playerMetricProp, statDefinitionProp } from '../../Scout.proptypes';

const MetricModal = ({
  filterId,
  title,
  selectedStatKeys,
  statDefinitions,
  statDefinitionsLoading,
  addMetricFunction,
  primaryPosition,
  isOpen,
  setIsOpen,
  selectedMetric,
  setSelectedMetric,
}) => {
  /* Button & Open state */
  const noStats = isEmpty(statDefinitions);
  const noneSaved = isEmpty(selectedStatKeys);

  const [metricValue, setMetricValue] = useState([null, null]);
  const selectMetric = (metric) => {
    setMetricValue([null, null]);
    setSelectedMetric(metric);
  };

  const metricKey = selectedMetric?.key || 'passes';
  const { statDistribution, isLoading, error } = useGetPlayerStatDistribution(
    metricKey,
    primaryPosition
  );
  const isStatDistroError = !!error;

  /* disable the confirm button if nothing is selected or
    the selected metric is already a filter
   */
  const isSaveable =
    selectedMetric &&
    !selectedStatKeys.includes(selectedMetric?.key) &&
    selectedMetric?.key === statDistribution?.name;

  const [searchValue, setSearchValue] = useState('');
  const searchedMetrics = sortStatsBySearch(statDefinitions, searchValue);

  /* 
  Table component expects array of 1-based index values for rows because it is stupid 
    so find index and +1
  */
  const selectedRow = [
    searchedMetrics.findIndex((i) => i.key === selectedMetric?.key) + 1,
  ];

  const sliderMetric = getSliderMetric(selectedMetric, statDistribution);
  const sliderMin = sliderMetric.min;
  const sliderMax = sliderMetric.max;
  const { defaultMode, lowerIsBetter } = sliderMetric;
  const formatter = getScaleFormatter(
    sliderMax - sliderMin,
    selectedMetric?.unitType
  );
  /* 
  When metric changes a default slider setup is temporarily generated
  Assuming a distro returns a sensible set of min/max/scale etc. gets set
    Value then needs to be made something in range
  */
  useEffect(() => {
    const sensibleDefault = lowerIsBetter
      ? [sliderMax, null]
      : [sliderMin, null];
    setMetricValue(sensibleDefault);
  }, [defaultMode]);

  const showSlider =
    sliderMetric.key && sliderMetric.key === selectedMetric?.key;

  const metricTooltip = getHeaderTooltip(sliderMetric);

  return (
    <>
      <AccordionSaveRow>
        {statDefinitionsLoading && (
          <Loader inverted content="Available Metrics Loading" />
        )}
        {!statDefinitionsLoading && noStats && (
          <span>Error Loading Metric List</span>
        )}
        {!statDefinitionsLoading && !noStats && (
          <Button
            variant={noneSaved ? 'primary' : 'secondary'}
            size="small"
            isDisabled={noStats}
            onClick={() => {
              selectMetric(null);
              setIsOpen(true);
            }}
          >
            Add a Metric Filter
          </Button>
        )}
      </AccordionSaveRow>
      <Modal id={filterId} title={title} isOpen={isOpen}>
        <ModalHeader id={`${filterId}-header`}>{title}</ModalHeader>
        <ModalBody>
          <MetricModalContent>
            <ModalGridHolder>
              <Grid container page={false}>
                <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
                  <ModalSearchHolder>
                    <TextField
                      id="metric-search-filter"
                      labelPosition="none"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      adornmentIcon="Search"
                      adornmentOnClick={() => {}}
                      placeholder="Search Metrics"
                      size="small"
                    />
                  </ModalSearchHolder>
                  <BasicTable
                    data={searchedMetrics}
                    headers={METRIC_MODAL_HEADERS}
                    selectedRows={selectedRow}
                    onRowSelect={selectMetric}
                    maxHeight={METRIC_MODAL_HEIGHTS.TABLE}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                  <ModalMetricHeader title={metricTooltip}>
                    {selectedMetric
                      ? `Add: ${selectedMetric?.name}`
                      : 'Select a Metric from the table'}
                  </ModalMetricHeader>
                  {selectedMetric &&
                    selectedStatKeys.includes(selectedMetric.name) && (
                      <BannerError message="This metric is already being applied as a filter" />
                    )}
                  {isStatDistroError && (
                    <BannerError message="Metric Range could not be found." />
                  )}
                  {!isStatDistroError && isSaveable && showSlider && (
                    <>
                      <MetricDualSlider
                        min={sliderMin}
                        max={sliderMax}
                        values={metricValue}
                        onValueChanged={setMetricValue}
                        lowerIsBetter={lowerIsBetter}
                        formatter={formatter}
                        metricName={selectedMetric?.abbreviation}
                      />
                      {isLoading && (
                        <Dimmer active>
                          <Loader content="Distribution Loading" />
                        </Dimmer>
                      )}
                    </>
                  )}
                  <div />
                </Grid>
              </Grid>
            </ModalGridHolder>
          </MetricModalContent>
        </ModalBody>
        <ModalFooter
          id={`${filterId}-footer`}
          cancelLabel="Cancel"
          confirmLabel="Add Filter"
          onCancel={() => {
            setIsOpen(false);
            setSearchValue('');
          }}
          onConfirm={() => {
            const metricWithValue = {
              ...sliderMetric,
              value: metricValue,
            };
            /* Use a tiny timeout just to stop the warning flashing up before modal close */
            setTimeout(() => {
              addMetricFunction(metricWithValue);
              setSearchValue('');
            }, 50);
            setIsOpen(false);
          }}
          onEscape={() => {
            setIsOpen(false);
            setSearchValue('');
          }}
          isDisabledConfirm={!isSaveable}
          isDisabledEscape={false}
        />
      </Modal>
    </>
  );
};

MetricModal.propTypes = {
  // id for the modal
  filterId: PropTypes.string,
  // function to take the selected definition and do whatever with it
  addMetricFunction: PropTypes.func.isRequired,
  // the list of possible metrics
  // note this is not a playerMetricProp -> the combination of this with values selected on save will be
  statDefinitions: PropTypes.arrayOf(statDefinitionProp),
  // title of the modal
  title: PropTypes.string,
  // are the stat defs still loading
  statDefinitionsLoading: PropTypes.bool,
  // which metrics of this type are already chosen
  selectedStatKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  // the roster position to use for getting metric ranges
  primaryPosition: PropTypes.oneOf(Object.values(API_ROSTER_POSITION_KEYS))
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  selectedMetric: playerMetricProp,
  setSelectedMetric: PropTypes.func.isRequired,
};

MetricModal.defaultProps = {
  filterId: 'metric-modal',
  title: 'Add Metric Filter',
  statDefinitions: null,
  statDefinitionsLoading: false,
  selectedMetric: null,
};

export default MetricModal;
