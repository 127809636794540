import PropTypes from 'prop-types';

const HavocGroupDatumProp = PropTypes.shape({
  /* identification */
  groupBy: PropTypes.string, // the event-data key being grouped by
  name: PropTypes.string, // the name for the grouping (row)
  key: PropTypes.string, // the key/id for the grouping (row)
  havocEvents: PropTypes.number, // count of events
  /* plays counts */
  havocPlays: PropTypes.number,
  passPlays: PropTypes.number,
  runPlays: PropTypes.number,
  sackPlays: PropTypes.number,
  pressurePlays: PropTypes.number,
  tacklePlays: PropTypes.number,
  runDisruptionPlays: PropTypes.number,
  /* percentages of all plays */
  playsPercentage: PropTypes.number,
  playsPassPercentage: PropTypes.number,
  playsRunPercentage: PropTypes.number,
  /* plays per group as percentage of total (makes no sense for totals) */
  havocPercentage: PropTypes.number,
  passPercentage: PropTypes.number,
  runPercentage: PropTypes.number,
  sackPercentage: PropTypes.number,
  pressurePercentage: PropTypes.number,
  tacklePercentage: PropTypes.number,
  runDisruptionPercentage: PropTypes.number,
  /* Event stuff */
  sackEvents: PropTypes.number,
  pressureEvents: PropTypes.number,
  tackleEvents: PropTypes.number,
  runDisruptionEvents: PropTypes.number,
  havocEventPercentage: PropTypes.number,
  sackEventPercentage: PropTypes.number,
  pressureEventPercentage: PropTypes.number,
  tackleEventPercentage: PropTypes.number,
  runDisruptionEventPercentage: PropTypes.number,
  /* Other summary values */
  xLoS: PropTypes.number,
  postSnapSeconds: PropTypes.number,
  mostCommonAlignmentPosition: PropTypes.string,
});
export const HavocGroupDataProp = PropTypes.arrayOf(HavocGroupDatumProp);
