import { uniq } from 'lodash';
import { csIntensity, csValue } from '../../utils/helpers/colorScales';
import {
  proximityValue,
  passLengthValue,
} from './PassPlacement.dataManipulation';
import Footballer from '../../assets/images/IQ_Football_QB.svg';
import {
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_CLASS_NAMES,
  PASS_PLACEMENT_DOMAIN,
  PASS_PLACEMENT_TARGET,
  FOOTBALL_RADIUS_YDS,
  FEET_PER_YARD,
} from './PassPlacement.constants';
import { isMultipleOfThree } from '../../utils/helpers/general';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import { paletteIsDark } from '../../utils/visualisations/visPalettes';

/* Horizontal Lines for y=0 to y=16 inclusive */
const HORIZONTAL_GUIDES_FEET = [
  ...Array(PASS_PLACEMENT_DOMAIN.MAX_Y + 1).keys(),
];
const HORIZONTAL_GUIDES_YDS = HORIZONTAL_GUIDES_FEET.map((f) => f / 3);

/* Vertical Lines for x=-16 to x=16 inclusive
 */
const VERTICAL_GUIDES_FEET = [
  ...Array(PASS_PLACEMENT_DOMAIN.PLUS_MINUS_X * 2 + 1).keys(),
];
const VERTICAL_GUIDES_YDS = VERTICAL_GUIDES_FEET.map(
  (f) => (f - PASS_PLACEMENT_DOMAIN.PLUS_MINUS_X) / FEET_PER_YARD
);

const colorDot = function (d, visPalette, selectedPlay, colorMode) {
  if (d?.node.id === selectedPlay?.id) {
    return visPalette.selectedObject;
  }
  if (colorMode === PASS_PLACEMENT_COLOR_MODES.LOCATION) {
    return visPalette.objects.n1.main;
  }
  if (colorMode === PASS_PLACEMENT_COLOR_MODES.PROXIMITY) {
    const fractionalValue = proximityValue(d.node);
    const isDarkMode = paletteIsDark(visPalette);
    return csValue(fractionalValue, isDarkMode);
  }
  if (colorMode === PASS_PLACEMENT_COLOR_MODES.LENGTH) {
    const fractionOfScale = passLengthValue(d.node);
    return csIntensity(fractionOfScale);
  }
  return d.node.success
    ? visPalette.successFail.success.main
    : visPalette.successFail.fail.main;
};

const drawPasses = (
  svgG,
  visPalette,
  data,
  selectedPlay,
  colorMode,
  showFailsRing,
  showBallSize,
  xScale,
  yScale,
  yScaleInverted,
  selectionEnabled,
  setSelectedPlay
) => {
  const opacityDot = function (d) {
    if (showFailsRing && !d.node.success) {
      return 0;
    }
    if (showBallSize && d?.node.id === selectedPlay?.id) {
      return 0.75;
    }
    if (showBallSize) {
      return 0.4;
    }
    return 1;
  };

  svgG
    .selectAll('circle')
    .data(
      data.passEvents.edges.filter((edge) => edge.node.passPlacementX !== null)
    )
    .join(
      (enter) => enter.append('circle'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr('cx', (d) => xScale(d.node.passPlacementX))
    .attr('cy', (d) => yScaleInverted(d.node.passPlacementY))
    .attr('r', showBallSize ? yScale(FOOTBALL_RADIUS_YDS) : 3)
    .attr('fill', (d) => colorDot(d, visPalette, selectedPlay, colorMode))
    .attr('stroke', (d) => colorDot(d, visPalette, selectedPlay, colorMode))
    .attr('stroke-width', showBallSize ? 2 : 1)
    .attr('fill-opacity', (d) => opacityDot(d))
    .attr('class', 'dot')
    .on('click', (e, d) => {
      if (selectionEnabled) {
        setSelectedPlay(d.node);
      }
    });
};

/* Draw the backing grid */
const drawGrid = function (svgZone, visPalette, xScale, yScale) {
  svgZone.selectAll('g').remove();
  const horG = svgZone
    .append('g')
    .attr('class', PASS_PLACEMENT_CLASS_NAMES.HORIZONTAL_GRID);
  const verG = svgZone
    .append('g')
    .attr('class', PASS_PLACEMENT_CLASS_NAMES.VERTICAL_GRID);
  const focusG = svgZone
    .append('g')
    .attr('class', PASS_PLACEMENT_CLASS_NAMES.GRID_FOCUS);
  /* 
  Convert the collection domain into yards
  Draw grid lines for entire domain (most will be out of render scope)
  */
  const minX = PASS_PLACEMENT_DOMAIN.PLUS_MINUS_X / -FEET_PER_YARD;
  const maxX = PASS_PLACEMENT_DOMAIN.PLUS_MINUS_X / FEET_PER_YARD;
  const minY = 0;
  const maxY = PASS_PLACEMENT_DOMAIN.MAX_Y / FEET_PER_YARD;

  horG
    .selectAll('line')
    .data(HORIZONTAL_GUIDES_YDS)
    .enter()
    .append('line')
    .attr('id', (d) => d)
    .attr('x1', xScale(minX))
    .attr('x2', xScale(maxX))
    .attr('y1', (d) => yScale(d))
    .attr('y2', (d) => yScale(d))
    .attr('stroke', visPalette.guides)
    .attr('stroke-width', (d, i) =>
      isMultipleOfThree(HORIZONTAL_GUIDES_FEET[i]) ? 2 : 1
    )
    .attr('stroke-dasharray', '5 5');

  verG
    .selectAll('line')
    .data(VERTICAL_GUIDES_YDS)
    .enter()
    .append('line')
    .attr('x1', (d) => xScale(d))
    .attr('x2', (d) => xScale(d))
    .attr('y1', yScale(minY))
    .attr('y2', yScale(maxY))
    .attr('stroke', visPalette.guides)
    .attr('stroke-width', (d, i) =>
      isMultipleOfThree(VERTICAL_GUIDES_FEET[i]) ? 2 : 1
    )
    .attr('stroke-dasharray', '5 5');

  /* Add Focal Point Lines */
  focusG
    .append('line')
    .attr('x1', xScale(PASS_PLACEMENT_TARGET.X))
    .attr('x2', xScale(PASS_PLACEMENT_TARGET.X))
    .attr('y1', yScale(minY))
    .attr('y2', yScale(maxY))
    .attr('stroke', visPalette.focus)
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', '5 5');
  focusG
    .append('line')
    .attr('x1', xScale(minX))
    .attr('x2', xScale(maxX))
    .attr('y1', yScale(PASS_PLACEMENT_TARGET.Y))
    .attr('y2', yScale(PASS_PLACEMENT_TARGET.Y))
    .attr('stroke', visPalette.focus)
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', '5 5');
};

const drawAxes = function (
  svgZone,
  visPalette,
  xScale,
  yScale,
  plusMinusX,
  maxY
) {
  svgZone.selectAll('g').remove();
  const xLabelZone = svgZone
    .append('g')
    .attr('class', PASS_PLACEMENT_CLASS_NAMES.X_NUMBERS);
  const yLabelZone = svgZone
    .append('g')
    .attr('class', PASS_PLACEMENT_CLASS_NAMES.Y_NUMBERS);

  /* Y Axis */
  const maxYFeet = Math.ceil(maxY * FEET_PER_YARD); // converts to feet
  const yLabelsFeet = [...Array(maxYFeet).keys()]; // no +1 because don't label top of chart

  yLabelZone
    .selectAll('text')
    .data(yLabelsFeet)
    .enter()
    .append('text')
    .attr('fill', visPalette.text.label)
    .attr('x', xScale(-plusMinusX) - 5)
    .attr('y', (d) => yScale(d / FEET_PER_YARD) + 4)
    .attr('font-size', VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text((d) => `${d}'`);
  yLabelZone
    .append('line')
    .attr('x1', xScale(-plusMinusX))
    .attr('x2', xScale(-plusMinusX))
    .attr('y1', yScale(0))
    .attr('y2', yScale(maxY))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 2);

  /* X Axis */
  const maxXFeet = Math.ceil(plusMinusX * FEET_PER_YARD); // converts to feet
  const xLabelsPosFeet = [...Array(maxXFeet).keys()]; // no +1 because don't label edges of chart
  const xLabelsFeet = uniq(
    xLabelsPosFeet.concat(xLabelsPosFeet.map((f) => -f))
  ); // remove second 0
  xLabelZone
    .selectAll('text')
    .data(xLabelsFeet)
    .enter()
    .append('text')
    .attr('fill', visPalette.text.label)
    .attr('x', (d) => xScale(d / FEET_PER_YARD))
    .attr('y', yScale(0) + 14)
    .attr('font-size', VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .text((d) => `${d}'`);
  xLabelZone
    .append('line')
    .attr('x1', xScale(-plusMinusX))
    .attr('x2', xScale(plusMinusX))
    .attr('y1', yScale(0))
    .attr('y2', yScale(0))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 2);
};

const drawCatcher = function (
  svgZone,
  xScale,
  yScale,
  yScaleInverted,
  showCatcher,
  isDark
) {
  svgZone.selectAll('g').remove();

  const avgFootballerHeight = 2; // yds (based on WR not all positions)
  const imgFootOverhang = 0.03; // % of the footballer's height that his feet project below the horizon
  const imgHelmetAddition = 0.02; // % of the footballer's height that the helmet adds above his head
  // includes tip of helmet and feet overhang
  const imgHeightYds =
    avgFootballerHeight * (1 + imgFootOverhang + imgHelmetAddition);
  const imgTop = imgHeightYds - imgFootOverhang * avgFootballerHeight;
  const imgWidthHeightRatio = 202 / 81; // the relative dimensions of the image
  const imgWidthYds = imgHeightYds / imgWidthHeightRatio;

  if (showCatcher) {
    svgZone
      .append('g')
      .attr('class', PASS_PLACEMENT_CLASS_NAMES.IMAGE)
      .append('svg:image')
      .attr('x', xScale(imgWidthYds / -2)) // -half width is origin of image
      .attr('y', yScaleInverted(imgTop))
      .attr('height', yScale(imgHeightYds))
      .attr('xlink:href', Footballer)
      .attr('opacity', isDark ? 1 : 0.25);
  }
};

export { drawGrid, drawAxes, drawCatcher, drawPasses };
