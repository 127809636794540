import { styled } from 'styled-components';

export const SliderWrapper = styled.div`
  padding: 0 8px 16px;

  h3 {
    padding-left: 4px;
  }
`;
SliderWrapper.displayName = 'SliderWrapper';

// if you want an RC slider to be full width, use this wrapper
export const RCSliderWrapper = styled.div`
  transition: opacity 0.5s ease;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .rc-slider {
    width: 100%;
    margin-left: 0;
  }
`;
RCSliderWrapper.displayName = 'RCSliderWrapper';

export const slideAnimationStyle = 'cubic-bezier(0.465, 0.183, 0.153, 0.946)';
