import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonIcon,
  Dropdown,
  Icon,
} from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FilterFooter,
  FilterLayout,
  FilterSelectedContent,
} from './PlayFinderFilters.style';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';

import { IconButtonWrapper } from '../../../components/buttons/Playlist/Playlist.styles';
import PlayFinderFiltersConfig from '../PlayFinderFiltersConfig/PlayFinderFiltersConfig';
import { getWidgetValue } from '../PlayFinderFiltersConfig/PlayFinderFiltersConfig.dataManipulation';
import Dimmer from '../../../components/Dimmer/Dimmer';
import PlayFinderPopup from './PlayFinderPopup/PlayFinderPopup';
import {
  findFilterById,
  findSelectedFilters,
  flattenFilterTree,
  mergeFilterTrees,
  removeFilterById,
  updateFilterById,
} from './PlayFinderFilters.dataManipulation';
import { FilterTileFooter } from './PlayFinderPopup/PlayFinderPopup.styles';
import { usePlayFinderFilterTreeContext } from '../PlayFinderFilterTree/usePlayFinderFilterTreeContext';
import { STATSBOMB_PRESET_FILTERS } from './PlayFinderFilters.constants';
import { replaceHrefTokens } from '../../../utils/helpers/general';
import PlayFinderFiltersWrapper from '../PlayFinderFiltersConfig/PlayFinderWidgets/PlayFinderFiltersWrapper';

// PlayFinder Filters:
// The root page component that holds the selected filter tree
// Allows adjustment of selected filters via Popup
const PlayFinderFilters = forwardRef(
  ({ handleFilterUpdate, selectedFilters, children }, ref) => {
    const [filterOpen, setFilterOpen] = useState(
      sessionStorage.getItem('playFinderFilterOpen') || false
    );
    const filtersConfigRef = useRef();
    // Filter Popup
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupFilter, setPopupFilter] = useState({});
    const popupRef = useRef([]);
    const [popupPositions, setPopupPositions] = useState([]);
    const [popupYPosition, setYPosition] = useState(0);
    const numberOfFilters = flattenFilterTree(selectedFilters).length;

    // Preset filters
    const {
      leagues: leagueParam,
      seasons: seasonParam,
      teams: teamParam,
      offense: offenseParam,
      preset: presetParam,
    } = useParams();
    const navigate = useNavigate();
    const [presetFilter] = useState(presetParam || null);

    const { data: playFinderFilters, loading: isLoading } =
      usePlayFinderFilterTreeContext();

    // handle panel open/close
    const toggleFilterOpen = () => {
      setFilterOpen(!filterOpen);
      sessionStorage.setItem('playFinderFilterOpen', !filterOpen);
    };
    useImperativeHandle(ref, () => ({
      filterOpen,
      toggle() {
        toggleFilterOpen();
      },
    }));

    useEffect(() => {
      // once rendered, get the y position of each filter in the popup
      const yPositions = popupRef.current
        .map((div) => div?.offsetTop)
        .slice(-numberOfFilters);
      setPopupPositions(yPositions);
    }, [selectedFilters]);

    const getPopupPosition = (label) =>
      // get the y position of the filter in the popup by array index
      popupPositions[
        popupRef.current
          .filter((item) => item !== null)
          .slice(-numberOfFilters)
          .findIndex((el) => el.id === label)
      ];

    // remove filter from the within the popup
    const handleRemoveFilter = (filter) => {
      setPopupOpen(false);
      // update the side panel and result query
      handleFilterUpdate(removeFilterById(selectedFilters, filter.filterId));
    };

    // handle filter update from popup
    const handleUpdateFilter = (filterId, option) => {
      const updatedFilter = updateFilterById(selectedFilters, filterId, option);
      // update the popup
      setPopupFilter(findFilterById(updatedFilter, filterId));
      // update the side panel and result query
      handleFilterUpdate(updatedFilter);
    };

    // add filters from FilterConfig
    const handleAddFilter = (filterTree) => {
      // close the filter config
      filtersConfigRef.current.toggle();

      // separate selected filters
      const filterTreeSelected = findSelectedFilters(filterTree);

      // merge selected filters with existing filters
      const updatedFilterTree =
        selectedFilters.length === 0
          ? filterTreeSelected
          : mergeFilterTrees(selectedFilters, filterTreeSelected);

      // update the side panel and result query
      handleFilterUpdate(updatedFilterTree);
    };

    // preset filter dropdown
    const handlePresetFilter = (selectedOption) => {
      // update the URL
      navigate(
        `/${replaceHrefTokens(
          'playfinder/results/:leagues/:seasons/:teams/:offense/:preset',
          {
            leagues: leagueParam,
            seasons: seasonParam,
            teams: teamParam,
            offense: offenseParam,
            preset: selectedOption.value,
          }
        )}`,
        { replace: false }
      );
    };

    // Tree of filters with selected attribute
    // eslint-disable-next-line react/prop-types
    const FormattedFilters = ({ data }) => {
      const renderFilter = (items, level) =>
        items?.map((item, index) => (
          <div
            className={classNames('headers', { section: level === 1 })}
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.name}-${index}`}
          >
            {level === 1 && <h3>{item.label}</h3>}
            {level === 2 && <h4>{item.label}</h4>}
            {level === 3 && (
              <div>
                <PlayFinderPopup
                  isOpen={popupOpen}
                  top={popupYPosition}
                  trigger={
                    <Button
                      id={`${item.label}`}
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        setYPosition(getPopupPosition(item.label));
                        setPopupOpen(!popupOpen);
                        setPopupFilter(item);
                      }}
                      ref={(el) => {
                        popupRef.current.push(el);
                      }}
                    >
                      <span>{item.abbrev}</span>
                      <span>
                        {typeof item.widgetArgs.selected !== 'undefined' &&
                          getWidgetValue(
                            item.units,
                            item.widgetType,
                            item.widgetArgs,
                            ''
                          )}
                        <Icon variant="ChevronRight" size="small" />
                      </span>
                    </Button>
                  }
                >
                  <SummaryTile style={{ minWidth: '500px' }}>
                    <PlayFinderFiltersWrapper
                      filter={popupFilter}
                      onWidgetCallback={handleUpdateFilter}
                      handleRemoveFilter={handleRemoveFilter}
                      type="POPUP_FILTER"
                    />
                    <FilterTileFooter>
                      <Button
                        variant="primary"
                        size="small"
                        onClick={() => setPopupOpen(false)}
                      >
                        Done
                      </Button>
                    </FilterTileFooter>
                  </SummaryTile>
                </PlayFinderPopup>
              </div>
            )}
            {item.children && renderFilter(item.children, level + 1)}
          </div>
        ));

      return <>{renderFilter(data, 1)}</>;
    };

    return (
      <>
        <FilterLayout>
          <div style={{ left: filterOpen ? `63px` : `-260px` }}>
            <SummaryTile>
              <SummaryTileHeader>
                <div>
                  <h3>Filter</h3>
                </div>
                <div>
                  <ButtonIcon
                    onClick={toggleFilterOpen}
                    variant="ghost"
                    shape="pill"
                    size="small"
                    icon="Close"
                    title="Cancel"
                  />
                </div>
              </SummaryTileHeader>
              <SummaryTileHeader>
                <Dropdown
                  id="preset-filters"
                  options={STATSBOMB_PRESET_FILTERS}
                  label="Preset Filters"
                  labelPosition="none"
                  variant="tertiary"
                  isSearchable={false}
                  onChange={handlePresetFilter}
                  value={STATSBOMB_PRESET_FILTERS[presetFilter]}
                />
                <span>
                  <Button
                    id="clear-filters"
                    variant="secondary"
                    size="small"
                    isDisabled={selectedFilters.length === 0}
                    onClick={() => {
                      navigate(
                        `/${replaceHrefTokens(
                          'playfinder/results/:leagues/:seasons/:teams/:offense',
                          {
                            leagues: leagueParam,
                            seasons: seasonParam,
                            teams: teamParam,
                            offense: offenseParam,
                          }
                        )}`,
                        { replace: false }
                      );
                    }}
                  >
                    Clear
                  </Button>
                </span>
              </SummaryTileHeader>
              <SummaryTileBody>
                <FilterSelectedContent $margin="0 0 1rem 0">
                  <FormattedFilters data={selectedFilters} />
                </FilterSelectedContent>
              </SummaryTileBody>
              <FilterFooter>
                <IconButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => filtersConfigRef.current.toggle()}
                  >
                    <span>
                      <Icon variant="PlusAdd" />
                    </span>
                    New Filter
                  </Button>
                </IconButtonWrapper>
              </FilterFooter>
            </SummaryTile>
          </div>
          <div style={{ padding: filterOpen ? `0 0 0 320px` : `0` }}>
            {popupOpen && (
              <Dimmer inverted onClick={() => setPopupOpen(false)} />
            )}
            {children}
          </div>
        </FilterLayout>
        {!isLoading && (
          <PlayFinderFiltersConfig
            ref={filtersConfigRef}
            playFinderFilterTree={playFinderFilters?.playFinderFilterTree}
            onAddFilter={handleAddFilter}
          />
        )}
      </>
    );
  }
);

PlayFinderFilters.propTypes = {
  children: PropTypes.node,
  handleFilterUpdate: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.object),
};

PlayFinderFilters.defaultProps = {
  children: null,
  handleFilterUpdate: () => {},
  selectedFilters: [],
};

export default PlayFinderFilters;
