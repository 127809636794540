import { useIsNcaa } from '../../utils/hooks/useIsNcaa';

// returns the correct x scale domain for a given combination of settings
export const useXScaleDomain = (teamMode, isPersonnelMode) => {
  const isNcaa = useIsNcaa();

  if (teamMode && !isNcaa) {
    return [0, 0.2];
  }

  if (teamMode && isNcaa) {
    return isPersonnelMode ? [0, 0.25] : [0, 0.3];
  }

  // in league mode
  return [-0.1, 0.1];
};
