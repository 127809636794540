import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Icon } from '@statsbomb/kitbag-components';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import RouteTree from '../../../visualisations/RouteTree/RouteTree';
import {
  ROUTE_TREE_ANY_ALIGNMENT_CODES,
  ROUTE_TREE_CUSTOM_STATS,
  ROUTE_TREE_FILTER_DEFAULTS,
} from '../PlayerRoute/PlayerRoute.constants';
import { useGetRoutePlayerStats } from '../PlayerRoute/PlayerRoute.hooks';
import { ROTATIONS } from '../../../utils/constants/charting';
import {
  addRouteProportionalities,
  addVisulisationInfo,
  getRouteTotals,
  objectifyRoutes,
} from '../PlayerRoute/PlayerRoute.dataManipulation';
import RouteTreeKey from '../../../visualisations/RouteTree/RouteTreeKey/RouteTreeKey';
import { RouteTreeTileWrapper } from './PlayerOverview.styles';

const PlayerRoutesTile = () => {
  // chart data
  const apiPositionCodes = ROUTE_TREE_ANY_ALIGNMENT_CODES;
  const {
    loading: isLoading,
    hasError,
    routesData,
  } = useGetRoutePlayerStats(apiPositionCodes);

  const routeTotals = getRouteTotals(routesData);
  const formattedRoutes = addRouteProportionalities(routesData, routeTotals);

  const branchConfig =
    ROUTE_TREE_CUSTOM_STATS[ROUTE_TREE_FILTER_DEFAULTS.BRANCH];
  const fruitConfig = ROUTE_TREE_CUSTOM_STATS[ROUTE_TREE_FILTER_DEFAULTS.FRUIT];

  const showRoutes = !isLoading && !hasError && formattedRoutes?.length > 0;

  const scaledRoutes = addVisulisationInfo(
    formattedRoutes,
    branchConfig,
    fruitConfig
  );
  const routesSummary = showRoutes ? objectifyRoutes(scaledRoutes) : {};

  const loadingMsg = () => {
    if (isLoading) {
      return 'Loading Route Player Data';
    }

    return '';
  };

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Routes Run</h3>
        </div>
        <div>
          <GoTo
            href="/player/route/:leagues/:seasons/:teams/:players"
            linkName="Open routes run"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody $margin="0.8rem">
        {isLoading && <TileDimmer />}
        {!isLoading && !hasError && Object.keys(routesSummary).length === 0 && (
          <TileDimmer active>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <RouteTreeTileWrapper>
          {showRoutes && (
            <div>
              <RouteTree
                routesSummary={routesSummary}
                orientation={ROTATIONS.VERTICAL_UP}
                inRight
              />
            </div>
          )}
          {showRoutes && branchConfig && fruitConfig && (
            <div>
              <RouteTreeKey
                branchConfig={branchConfig}
                fruitConfig={fruitConfig}
              />
            </div>
          )}
        </RouteTreeTileWrapper>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol style={{ textAlign: 'left' }}>
          {isLoading && (
            <Loader active inline size="small" content={loadingMsg()} />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerRoutesTile;
