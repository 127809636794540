import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Grid } from '@statsbomb/kitbag-components';
import { mf_TeamDetails } from '../../../apollo';
import { useReactiveState } from '../../../utils/hooks/useReactiveState';
import {
  SummaryTile,
  SummaryTileBody,
} from '../../../components/Tile/TileSummary.styles';
import { InfoBar } from '../../player/PlayerOverview/PlayerOverview.styles';
import TeamRadarTile from './TeamRadar.Tile';
import TeamStatsTile from './TeamSchedule.Tile';
import { StyledTab } from './TeamOverview.styles';
import TeamFormationTile from './TeamFormation.Tile';
import TeamDepthTile from './TeamDepth.Tile';
import TeamPassingTile from './TeamPassing.Tile';
import TeamRunTendencies from './TeamRunTendencies.Tile';
import TeamLinePressure from './TeamLinePressure.Tile';
import TeamTacklingTile from './TeamTackling.Tile';
import useQueryString from '../../../utils/hooks/useQueryString';
import TeamHavocTile from './TeamHavoc.Tile';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';

const TeamOverview = () => {
  const [teamDetails] = useReactiveState(mf_TeamDetails);
  const [activeTabIndex, setActiveTabIndex] = useQueryString('overview', 0);
  const panes = [
    {
      menuItem: 'Offense',
      render: () => (
        <Tab.Pane>
          <Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamFormationTile />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamDepthTile />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamPassingTile />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamTacklingTile />
            </Grid>
            <Grid item sm={12} lg={12} xl={6}>
              <TeamRunTendencies />
            </Grid>
            <Grid item sm={12} lg={12} xl={6}>
              <TeamLinePressure />
            </Grid>
          </Grid>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Defense',
      render: () => (
        <Tab.Pane>
          <Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamDepthTile offense={false} />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamPassingTile offense={false} />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamTacklingTile offense={false} />
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <TeamHavocTile />
            </Grid>
          </Grid>
        </Tab.Pane>
      ),
    },
  ];
  const handleTabChange = (e) => {
    const tab = e.currentTarget;
    const tabIndex = [...tab.parentElement.children].indexOf(tab);

    setActiveTabIndex(tabIndex);
  };

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SummaryTile style={{ height: '3rem' }}>
            <InfoBar>
              <div />
              <div>
                <h3>{teamDetails && `${teamDetails?.name}`}</h3>
              </div>
              <div />
            </InfoBar>
          </SummaryTile>
        </Grid>

        <Grid item xs={6}>
          <TeamRadarTile />
        </Grid>
        <Grid item xs={6}>
          <TeamStatsTile />
        </Grid>

        <Grid item xs={12}>
          <SummaryTile>
            <SummaryTileBody $margin="0">
              <StyledTab>
                <Tab
                  panes={panes}
                  activeIndex={activeTabIndex}
                  onTabChange={handleTabChange}
                />
              </StyledTab>
            </SummaryTileBody>
          </SummaryTile>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default TeamOverview;
