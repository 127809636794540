import React from 'react';
import PropTypes from 'prop-types';
import { Table, ButtonIcon, Checkbox } from '@statsbomb/kitbag-components';
import { isArray } from 'lodash';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { ALIGNMENT } from '../../../../utils/constants/alignment';
import {
  sortableTableHeaderPropTypes,
  sortableTableHeadersPropTypes,
  sortableTableAlignmentPropTypes,
} from '../../Tables.propTypes';
import { HeaderWithButtons } from '../SortableTable.styles';

const ButtonsHeaderCell = ({ header }) => (
  <HeaderWithButtons>
    {header.label}
    <div className="dropdown-content">
      {header.buttons.map((buttonDefinition) => (
        <ButtonIcon
          id="table-header-button"
          size="small"
          title={buttonDefinition.title}
          icon={buttonDefinition.icon}
          variant="secondary"
          onClick={() => buttonDefinition.clickHandler(header.id)}
        />
      ))}
    </div>
  </HeaderWithButtons>
);
ButtonsHeaderCell.propTypes = {
  // An array of objects containing the data for the table header rows
  header: sortableTableHeaderPropTypes.isRequired,
};

/**
 *  SortableTableHeader component
 *  This component is responsible for rendering the header rows of the table
 */
const SortableTableHeader = ({
  headers,
  sortDirection,
  handleSort,
  sortBy,
  alignment,
  onChangeAll,
  allChecked,
  allIndeterminate,
}) => (
  <Table.Head>
    <Table.Row>
      {onChangeAll && (
        <Table.HeaderCell>
          <Checkbox
            checked={allChecked}
            id="select-all-rows"
            indeterminate={allIndeterminate}
            label="select-all-rows"
            onChange={onChangeAll}
            withLabel={false}
            size="small"
          />
        </Table.HeaderCell>
      )}
      {headers.map((header, headerIndex) => {
        const styleOverride = header.width
          ? { minWidth: header.width, maxWidth: header.width }
          : {};
        const sortState =
          sortBy === header.id ? sortDirection : SORT_DIRECTIONS.DEFAULT;
        const headerContent = isArray(header.buttons) ? (
          <ButtonsHeaderCell header={header} />
        ) : (
          header.label
        );

        return (
          <Table.HeaderCell
            style={styleOverride}
            key={header.id}
            textAlign={
              typeof alignment === 'function'
                ? alignment(headerIndex)
                : alignment
            }
            sortCb={
              header.isSortable && !isArray(header.buttons)
                ? () => handleSort(header.id)
                : null
            }
            sortState={sortState}
            title={header.description}
          >
            {headerContent}
          </Table.HeaderCell>
        );
      })}
    </Table.Row>
  </Table.Head>
);

SortableTableHeader.propTypes = {
  // An array of objects containing the data for the table header rows
  headers: sortableTableHeadersPropTypes.isRequired,
  // whether the sorting is ascending or descending
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  // function that handles the sort direction change
  handleSort: PropTypes.func.isRequired,
  // which column to sort in the table
  sortBy: PropTypes.string,
  // which column to sort in the table
  alignment: sortableTableAlignmentPropTypes,
  // function that handles the select all checkbox
  onChangeAll: PropTypes.func,
  // whether all checkboxes are checked
  allChecked: PropTypes.bool,
  // whether all checkboxes are indeterminate
  allIndeterminate: PropTypes.bool,
};

SortableTableHeader.defaultProps = {
  alignment: ALIGNMENT.LEFT,
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  sortBy: '',
  onChangeAll: null,
  allChecked: false,
  allIndeterminate: false,
};

export default SortableTableHeader;
