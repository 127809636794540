import { toPng, toSvg, toJpeg } from 'html-to-image';

export const EXPORT_TYPES = {
  PNG: 'PNG',
  SVG: 'SVG',
  JPG: 'JPG',
};

export const EXPORT_TYPE_FUNCS = {
  [EXPORT_TYPES.PNG]: toPng,
  [EXPORT_TYPES.SVG]: toSvg,
  [EXPORT_TYPES.JPG]: toJpeg,
};

// download choices
export const DOWNLOAD_FORMAT_OPTIONS = [
  { value: EXPORT_TYPES.PNG, label: 'png' },
  { value: EXPORT_TYPES.JPG, label: 'jpg' },
  { value: EXPORT_TYPES.SVG, label: 'svg' },
];

export const PALETTE_OPTIONS = [
  { value: 'true', label: 'Dark Palette' },
  { value: 'false', label: 'Light Palette' },
];

export const LOGO_DEFAULT_HEIGHT = 20;
