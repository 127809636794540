import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Button, Icon } from '@statsbomb/kitbag-components';

const backupHeaders = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  const hkeys = Object.keys(data[0]);
  const headers = hkeys.map((m) => ({ label: m, key: m }));
  return headers;
};

/*
 * Creates a button to be used for downloading a CSV of a matching table
 * uses: https://www.npmjs.com/package/react-csv
 */
const DownloadCSVButton = ({
  data,
  headers,
  fileName,
  iconSize,
  title,
  isDisabled,
}) => {
  const heads = headers || backupHeaders(data);
  return (
    <Button
      shape="circle"
      variant="secondary"
      size="small"
      title={title}
      isDisabled={isDisabled}
    >
      <CSVLink data={data} headers={heads} filename={fileName}>
        <Icon variant="Download" size={iconSize} colour="primary.main" />
      </CSVLink>
    </Button>
  );
};

DownloadCSVButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  fileName: PropTypes.string,
  iconSize: PropTypes.oneOf(['regular', 'small', 'tiny']),
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
};

DownloadCSVButton.defaultProps = {
  data: undefined,
  headers: undefined,
  fileName: 'table.csv',
  iconSize: 'small',
  title: 'Download CSV',
  isDisabled: false,
};

export default DownloadCSVButton;
