import { API_STAT_UNITS } from '../../../../../utils/constants/api';
import {
  POINT_OF_ATTACK_DOMAIN,
  RUN_TENDENCY_GAPS,
} from '../RunTendencies.constants';

const TITLE_HEADERS = [{ key: 'metric', label: 'Metric', isValue: false }];
const GAP_HEADERS = RUN_TENDENCY_GAPS.filter((g) => g.visible).map((gap) => ({
  key: gap.name,
  label: gap.name,
  isValue: true,
}));
const poaBucketCount =
  POINT_OF_ATTACK_DOMAIN[1] - POINT_OF_ATTACK_DOMAIN[0] + 1;
const POA_HEADERS = [...Array(poaBucketCount).keys()].map((j) => ({
  key: j + POINT_OF_ATTACK_DOMAIN[0],
  label: j + POINT_OF_ATTACK_DOMAIN[0],
  isValue: true,
}));

export const RUN_TENDENCY_TABLE_MODES = {
  GAP: {
    value: 'GAP',
    label: 'Gap',
    tableHeaders: TITLE_HEADERS.concat(GAP_HEADERS),
  },
  POA: {
    value: 'POA',
    label: 'Point of Attack',
    tableHeaders: TITLE_HEADERS.concat(POA_HEADERS),
  },
};

export const RUN_TENDENCY_TABLE_ROWS = [
  {
    key: 'plays',
    label: 'Plays',
    leagueValue: false,
    units: API_STAT_UNITS.COUNT,
  },
  {
    key: 'playPercentage',
    label: 'Play%',
    leagueValue: true,
    units: API_STAT_UNITS.PERCENTAGE,
  },
  {
    key: 'yardsGained',
    label: 'Yds Gained',
    leagueValue: true,
    units: API_STAT_UNITS.YARDS,
  },
  {
    key: 'successRate',
    label: 'Success%',
    leagueValue: true,
    units: API_STAT_UNITS.PERCENTAGE,
  },
];
