export const getPlayerComparisonQuery = (weightedStats) => {
  const weightStatsString = weightedStats?.map(
    (stat) => `{name: "${stat.name}", weight: ${stat.weight}}`
  );
  const gqlString = `
  query getPlayerComparison(
    $competitionId: Int!
    $seasonId: Int!
    $teamId: Int!
    $comparisonPlayerIds: [Int!]
    $minPlays: Int!
    $rosterPositions: [GeneralPosition!]
    $specificPositions: [SpecificPosition!]
    $ncaaTransferPortal: Boolean!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    playerComparison(
      playerStatFilters: {
        teamIds: [$teamId]
        playerIds: $comparisonPlayerIds
        minPlays: $minPlays
        rosterPositions: $rosterPositions
        specificPositions: $specificPositions
        ncaaTransferPortal: $ncaaTransferPortal
      }
      playerComparisonFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
      }
      stats: [${weightStatsString}]
      ) {
        basePlayer {
          playerId
          playerName
          teamName
          plays
          similarity
          stats {
            name
            value
          }
        }
        comparativePlayers {
          playerId
          playerName
          teamName
          teamId
          similarity
          plays
          stats {
            name
            value
          }
        }
        totalComparisons
      }
    }  
  `;
  return gqlString;
};
