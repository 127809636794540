import { VISUALISATION_FONT_SETUPS } from '../../../../utils/constants/visText';
import { csHeatmap, csIntensity } from '../../../../utils/helpers/colorScales';
import {
  addGradientDots,
  addMeaningDots,
} from '../../../../utils/visualisations/keys';
import { appendText, fontBaselineY } from '../../../../visualisations/text';
import { COLORING_OPTION_PASS_VELOCITY } from '../../../../visualisations/PassingChart/PassingChart.constants';
import { PASSING_KEY_SIZE } from './PassingKey.constants';
import { colorOptionName } from './PassingKey.dataManipulation';

const sectionLabelY = fontBaselineY(
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
);

export const drawColorSection = (
  colorSegmentG,
  coloringOption,
  isDark,
  visPalette,
  colorDots
) => {
  appendText(colorSegmentG, visPalette, {
    message: colorOptionName(coloringOption),
    y: sectionLabelY,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  });

  const colorDotsG = colorSegmentG
    .append('g')
    .attr('transform', `translate(0,${PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL})`);

  if (coloringOption === COLORING_OPTION_PASS_VELOCITY.value) {
    addGradientDots(
      colorDotsG,
      csIntensity,
      visPalette,
      isDark,
      PASSING_KEY_SIZE.WIDTH / 2,
      '20 MPH',
      '60 MPH'
    );
  } else {
    addMeaningDots(colorDotsG, colorDots, visPalette, PASSING_KEY_SIZE.WIDTH, {
      radius: PASSING_KEY_SIZE.DOTS.RADIUS,
    });
  }
};

export const drawFillSection = (fillSegmentG, visPalette, fillDots) => {
  appendText(fillSegmentG, visPalette, {
    message: 'Fill by in/out of bounds',
    y: sectionLabelY,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  });
  const fillDotsG = fillSegmentG
    .append('g')
    .attr('transform', `translate(0,${PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL})`);
  addMeaningDots(fillDotsG, fillDots, visPalette, PASSING_KEY_SIZE.WIDTH, {
    radius: PASSING_KEY_SIZE.DOTS.RADIUS,
  });
};

export const drawHeatmapSection = (colorSegmentG, isDark, visPalette) => {
  appendText(colorSegmentG, visPalette, {
    message: 'Heatmap shows pass end density',
    y: sectionLabelY,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  });

  const colorDotsG = colorSegmentG
    .append('g')
    .attr('transform', `translate(0,${PASSING_KEY_SIZE.HEIGHT.SECTION_LABEL})`);

  addGradientDots(
    colorDotsG,
    csHeatmap,
    visPalette,
    isDark,
    PASSING_KEY_SIZE.WIDTH / 2,
    'No events',
    'High event density'
  );
};
