import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import { fontVerticalCenterY } from '../../text';

export const OL_GAP_POSITION_CLASS_NAMES = {
  BACKGROUND_RECT: 'olph-background',
  WITHIN_MARGINS: 'olph-within-margins',
  SYMBOLS: 'olph-symbols',
  NAMES: 'olph-names',
};

export const OL_GAP_POSITION_SIZING = {
  ICON_FONT_SIZE: VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE, // 14px
  ICON_FONT_BASELINE: fontVerticalCenterY(
    VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE
  ), // baseline for vertical text
  SHAPE_RADIUS: VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE + 2, // 16px
  NAME_HEIGHT: VISUALISATION_FONT_SETUPS.HEADER_LABEL.LINE_HEIGHT, // 20px
  /* 
  Symbol heights: diamond = square. Shape radius = 16 means the distance to the top point is sqrt(2)*16 ~ 23
  Double (radius -> diameter), add space for stroke and then round up from ~ 56px
  */
  ICONS_HEIGHT: 50,
};
