import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import {
  scaleLinear,
  axisBottom,
  select,
  line,
  easeLinear,
  interpolateRound,
} from 'd3';
import classnames from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { getPalette } from '../../../utils/visualisations/visPalettes';
import { useD3 } from '../../../utils/hooks/useD3';
import { StyledTimeline } from './Timeline.styles';
import { ui_isDark } from '../../../apollo';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { addExportFontReference } from '../../../utils/helpers/export';

const Timeline = ({
  id,
  isInteractive,
  selectedEventUUId,
  data,
  setIsPlaying,
  isPlaying,
  onEventSelect,
}) => {
  const theme = useTheme();
  const ui_isDarkRV = useReactiveVar(ui_isDark);
  const visPalette = getPalette(ui_isDarkRV);
  const [eventIndex, setEventIndex] = useState(0);
  // svg content axis pos
  const xPos = 8;
  const yPos = 20;
  // does the event have ff?
  const ffActive = [];
  data.forEach((elem) => {
    if (elem.freezeFrames && elem.freezeFrames.length > 0) {
      ffActive.push(
        elem.freezeFrames.filter((f) => f.playerIndex >= 0).length > 0
      );
    } else {
      ffActive.push(false);
    }
  });
  const ffData = data.filter((d) => d.freezeFrames);
  // millisecond timings for each frame
  const ffTiming = [];
  ffData.forEach((elem, index) => {
    ffTiming.push(
      Math.round(
        (ffData[index + 1]?.videoTimestamp - elem.videoTimestamp) * 1000
      )
    );
  });
  // remove the last NaN
  ffTiming.pop();

  // cumulative timing
  const ffTimingCumil = [0];
  ffTiming.forEach((elem, index) => {
    ffTimingCumil.push(
      elem + ffTiming.slice(0, index).reduce((a, b) => a + b, 0)
    );
  });
  // data for lines
  const dataPoint = [];
  dataPoint.push([0, ffTimingCumil[0]]);
  ffTimingCumil.forEach((elem, index) => {
    dataPoint.push([
      elem,
      ffTimingCumil[index + 1] ? ffTimingCumil[index + 1] : elem,
    ]);
  });
  // calculate total time
  const totalSeconds = ffTimingCumil?.slice(-1) / 1000;
  const totalSecondsFixed = totalSeconds.toFixed(2);

  const returnTimeLabel = (milliseconds) => {
    if (milliseconds === 0) {
      return `0.00`;
    }

    if (!milliseconds) {
      return '';
    }

    let returnMS = '';
    const msAsArray = Array.from(milliseconds.toString());
    returnMS = `${msAsArray[0]}${milliseconds > 10000 ? msAsArray[1] : ''}.
      ${milliseconds > 1000 ? msAsArray[1] : ''}${msAsArray?.slice(-1)}`;

    return returnMS;
  };

  function tweenText(newValue) {
    return function () {
      // get current value (from data-current) as starting point for tween animation
      // eslint-disable-next-line react/no-this-in-sfc
      const currentValue = +this.dataset.current;
      // create interpolator and do not show nasty floating numbers
      const i = interpolateRound(currentValue, newValue);

      return function (t) {
        // eslint-disable-next-line react/no-this-in-sfc
        this.textContent = `${returnTimeLabel(
          i(t)
        )} s / ${totalSecondsFixed} s`;
      };
    };
  }

  const ref = useD3(
    (svg) => {
      svg.selectAll('rect').remove();
      svg.selectAll('g').remove();
      svg.selectAll('text').remove();
      svg.selectAll('path').remove();
      svg.selectAll('defs').remove();

      const svgDefs = svg.append('defs');
      addExportFontReference(svgDefs);

      svg
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('fill', visPalette.background.main);

      const svgWidth = parseInt(svg.style('width'), 10);
      const axisScale = scaleLinear()
        .domain([0, totalSeconds])
        .range([0, svgWidth - 150]); // -150 right margin (for label)
      const axis = axisBottom(axisScale).tickSize(10).tickPadding(10);
      const axis2 = axisBottom(axisScale)
        .tickSize(7)
        .tickPadding(10)
        .ticks(totalSeconds * 20, '')
        .tickFormat(() => null);

      svg
        .append('g')
        .attr('id', `${id}-timeline-scale-2`)
        .attr('transform', `translate(${xPos},${yPos})`)
        .attr('color', visPalette.text.guides)
        .call(axis2);

      svg
        .append('g')
        .attr('id', `${id}-timeline-scale`)
        .attr('transform', `translate(${xPos},${yPos})`)
        .attr('color', visPalette.text.label)
        .style('font-family', DEFAULT_FONT)
        .style('font-size', VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE)
        .style('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
        .call(axis);

      svg
        .append('g')
        .attr('id', `${id}-event-section-container`)
        .attr('transform', `translate(${xPos},${yPos})`);

      // event lines
      const lines = line()
        .x((d) => axisScale(d / 1000))
        .y(0);
      dataPoint.forEach((evt, index) => {
        svg
          .select(`#${id}-event-section-container`)
          .append('svg:path')
          .attr('d', lines(evt))
          .attr('stroke', theme.colours.interface.main)
          .attr('stroke-width', '2')
          .attr('opacity', 1)
          .attr('data-testid', `timelineSectionContainer${index}Test`)
          .attr('id', `${id}-event-section-${index}`);
      });

      // event points
      svg
        .select(`#${id}-event-section-container`)
        .selectAll('circle')
        .data(ffTimingCumil)
        .enter()
        .append('circle')
        .attr('cx', (d) => axisScale(d / 1000))
        .attr('cy', 0)
        .attr('r', (d, i) => (ffActive[i] ? 3 : 1))
        .attr('id', (d, i) => `${id}-event-start${i}`)
        .attr('class', (d, i) => (ffActive[i] ? 'clickable' : ''))
        .attr('stroke', visPalette.text.label)
        .attr('stroke-width', (d, i) => (ffActive[i] ? 1.5 : 3))
        .attr('data-testid', (d, i) => `eventStartTest${ffData[i]?.id}`)
        .attr('data-eventid', (d, i) => data[i]?.id)
        .attr('data-active', (d, i) => ffActive[i])
        .on('click', (e) => {
          if (e.target.dataset.active === 'true') {
            onEventSelect({
              event_uuid: e.target.dataset.eventid,
            });
          }
        })
        .attr('fill', visPalette.background.main);

      svg
        .append('text')
        .text(`0.00 s / ${totalSecondsFixed} s`)
        .attr('id', `${id}-timeline-display`)
        .attr('x', svgWidth - 120)
        .attr('y', yPos + 5)
        .attr('font-size', VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE)
        .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('data-current', 0)
        .attr('data-testid', `timelineDisplayTest`)
        .attr('fill', visPalette.text.label);
    },
    [data]
  );

  function setupAnimation() {
    const path = select(ref.current)
      .select(`#${id}-event-section-${eventIndex + 1}`)
      .node();
    const duration = ffTiming[eventIndex];

    select(ref.current)
      .select(`#${id}-event-section-${eventIndex + 1}`)
      .transition()
      .ease(easeLinear)
      .duration(duration)
      .attr('stroke-dashoffset', path?.getTotalLength())
      .attr('stroke-dasharray', path?.getTotalLength())
      .attr('stroke-dashoffset', 0);

    if (ffTimingCumil[eventIndex + 1]) {
      select(ref.current)
        .select(`#${id}-timeline-display`)
        .transition()
        .ease(easeLinear)
        .duration(duration)
        .tween('text', tweenText(ffTimingCumil[eventIndex + 1]));
    } else {
      select(ref.current)
        .select(`#${id}-timeline-display`)
        .transition()
        .ease(easeLinear)
        .duration(0);
    }
  }

  function setupEvent() {
    // set state of each item
    dataPoint.forEach((evt, index) => {
      const path = select(ref.current)
        .select(`#${id}-event-section-${index}`)
        .node();
      select(ref.current)
        .select(`#${id}-event-section-${index}`)
        .attr(
          'stroke-dashoffset',
          index <= eventIndex ? 0 : path?.getTotalLength()
        )
        .attr(
          'stroke-dasharray',
          index <= eventIndex ? 0 : path?.getTotalLength()
        );

      select(ref.current)
        .select(`#${id}-event-start${index}`)
        .attr(
          'stroke',
          index <= eventIndex
            ? theme.colours.interface.main
            : visPalette.text.label
        )
        .attr(
          'fill',
          index <= eventIndex
            ? theme.colours.interface.main
            : visPalette.text.label
        );
    });

    // set the start time for the clock
    const start = eventIndex === 0 ? 0 : ffTimingCumil[eventIndex];
    select(ref.current)
      .select(`#${id}-timeline-display`)
      .attr('data-current', start)
      .text(
        `${returnTimeLabel(
          start >= 0 ? start : totalSeconds
        )} s / ${totalSecondsFixed} s`
      );

    if (isPlaying) {
      setupAnimation();
    }
  }

  useEffect(() => {
    if (isPlaying) {
      setupAnimation();
    } else {
      ffTiming.forEach((elem, index) => {
        select(ref.current)
          .select(`#${id}-event-section-${index}`)
          .transition()
          .ease(easeLinear)
          .duration(0);
      });

      select(ref.current)
        .select(`#${id}-timeline-display`)
        .transition()
        .ease(easeLinear)
        .duration(0);
    }
  }, [isPlaying]);

  useEffect(() => {
    setEventIndex(
      data.findIndex((evt) => evt.event_uuid === selectedEventUUId)
    );
  }, [selectedEventUUId]);

  useEffect(() => {
    setupEvent();
  }, [eventIndex, data]);

  return (
    <StyledTimeline
      data-testid="timelineWrapperTest"
      $isInteractive={isInteractive}
    >
      {isInteractive && (
        <div className="controls">
          <Button size="small" onClick={() => setIsPlaying(!isPlaying)}>
            <Icon size="small" variant={isPlaying ? 'Pause' : 'Play'} />
            {isPlaying ? 'Pause' : 'Play'}
          </Button>
        </div>
      )}

      <svg
        ref={ref}
        data-testid="timelineSvgTest"
        className={classnames({ 'disable-transition': isPlaying })}
      />
    </StyledTimeline>
  );
};

Timeline.propTypes = {
  id: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool,
  selectedEventUUId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      event_uuid: PropTypes.string,
      videoTimestamp: PropTypes.number,
    })
  ),
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  // click on an event and return event_uuid to parent
  onEventSelect: PropTypes.func,
};

Timeline.defaultProps = {
  selectedEventUUId: '',
  isInteractive: true,
  data: [],
  isPlaying: false,
  setIsPlaying: () => {},
  onEventSelect: () => {},
};

export default Timeline;
