import React from 'react';
import { Toggle, Badge, Grid } from '@statsbomb/kitbag-components';
import { useReactiveVar } from '@apollo/client';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import ParsedLink from '../../components/ParsedLink/ParsedLink';
import {
  AccountDetails,
  StyledAdmin,
  TableOfContents,
  TableOfContentsList,
} from './Admin.styles';
import Tile from '../../components/Tile/Tile';
import { ui_isDark, uiState } from '../../apollo';
import { USER_GROUP } from '../../utils/constants/auth';
import { TABLE_OF_CONTENTS_CONFIG } from './Admin.constants';
import { KitbagPageGridHolder } from '../../components/Layout/Layout.styles';
import useUserConfig from '../../utils/hooks/useUserConfig';

const Admin = () => {
  const ui_isDarkRV = useReactiveVar(ui_isDark);
  const { user, isAuthenticated } = useKitbagAuth();
  const { save } = useUserConfig();

  const themeToggleHandler = async () => {
    const isDark = !ui_isDarkRV;
    ui_isDark(isDark);
    uiState().ui.theme.isDark = isDark;
    save(uiState().ui.theme);
    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  return (
    <StyledAdmin>
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <Grid item xs={12}>
            <AccountDetails>
              {!isAuthenticated && <p>No user logged in.</p>}
              {user && (
                <>
                  <p>
                    <img src={user.picture} alt={`${user.name} profile`} />
                  </p>
                  <p>
                    <span>Name:</span> {user.name}
                  </p>
                  <p>
                    <span>Email:</span> {user.email}
                  </p>
                </>
              )}
            </AccountDetails>
          </Grid>
          <Grid item xs={3} sm={3} lg={2} xl={1}>
            <Tile margin="0" padding="0 0 0.25rem 0" border="0">
              <Tile.Body>
                <h3>UI Theme</h3>
                <div style={{ padding: '0.25rem 0 0 0' }}>
                  <Toggle
                    id="app-theme-toggle"
                    label="Dark"
                    checked={ui_isDarkRV}
                    onChange={themeToggleHandler}
                  />
                </div>
              </Tile.Body>
            </Tile>
          </Grid>
          <Grid item xs={12}>
            <TableOfContents>
              {TABLE_OF_CONTENTS_CONFIG.map((section) => (
                <TableOfContentsList key={section.section}>
                  <h3>{section.section}</h3>
                  <ul>
                    {section.pages.map(
                      (page) =>
                        (!page.wip ||
                          user['https://statsbomb.com/accountID'] ===
                            USER_GROUP.STATSBOMB) && (
                          <li key={page.label}>
                            <ParsedLink href={page.link}>
                              {page.label}
                              {page.wip && <Badge>WIP</Badge>}
                              {page.beta && <Badge>BETA</Badge>}
                            </ParsedLink>
                          </li>
                        )
                    )}
                  </ul>
                </TableOfContentsList>
              ))}
            </TableOfContents>
          </Grid>
        </Grid>
      </KitbagPageGridHolder>
    </StyledAdmin>
  );
};

export default Admin;
