import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Breadcrumb } from 'semantic-ui-react';
import PageFilters from '../../../components/PageFilters/PageFilters';
import Tile from '../../../components/Tile/Tile';
import { GET_PLAY_EVENTS } from './getPlayEvents';
import EventList from '../GameAnimation/EventList';
import EventTiles from '../GameSnapFormations/EventTiles';

const GamePlayEvents = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { error, data, loading } = useQuery(GET_PLAY_EVENTS);

  const formattedData = data?.playEvents?.filter(
    (p) => p.freezeFrames && p.freezeFrames.length > 0
  );

  const handleEventRowClick = (event) => {
    setSelectedEvent(event.id);
  };

  const pageFilters = <PageFilters displayPassFilter inline wider />;
  const breadcrumb = (
    <Breadcrumb size="huge">
      <Breadcrumb.Section>Game</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Play Event Tiles</Breadcrumb.Section>
    </Breadcrumb>
  );

  const tileList = data && data?.playEvents?.length && (
    <Tile>
      <Tile.Body>
        <EventList
          events={data?.playEvents}
          loading={!!loading}
          selectedEventId={selectedEvent}
          onRowClick={handleEventRowClick}
        />
      </Tile.Body>
    </Tile>
  );

  const selectedPlayName = function (d) {
    const eventObj = d?.playEvents?.length > 0 && d?.playEvents[0];
    return eventObj?.play?.name || '';
  };
  const visTitle = `Events in ${selectedPlayName(data)}`;

  return (
    <EventTiles
      formattedData={formattedData}
      isError={!!error}
      isLoading={!!loading}
      selectedEvent={selectedEvent}
      setSelectedEvent={setSelectedEvent}
      visTitle={visTitle}
      breadcrumb={breadcrumb}
      pageFilters={pageFilters}
      tileList={tileList}
      headersArePlays={false}
    />
  );
};

export default GamePlayEvents;
