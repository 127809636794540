import { styled } from 'styled-components';

export const StyledAdmin = styled.div`
  color: ${({ theme }) => theme.colours.ink.primary.main};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  h3 {
    ${({ theme }) => theme.typography.headingMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }
`;

export const AccountDetails = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  padding: 1rem;
  width: fit-content;

  p {
    ${({ theme }) => theme.typography.bodyMedium}

    span {
      ${({ theme }) => theme.typography.headingSmall}
    }
  }
`;

export const TableOfContents = styled.section`
  display: flex;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  padding: 1rem;
  width: fit-content;
`;

export const TableOfContentsList = styled.div`
  h3 {
    padding-bottom: 0.5rem;
  }

  ul {
    width: 130px;

    li {
      padding-bottom: 0.25rem;
      ${({ theme }) => theme.typography.bodyMedium}

      a {
        color: ${({ theme }) => theme.colours.ink.primary.main};

        &:hover {
          color: ${({ theme }) => theme.colours.ink.highlight.main};
          text-decoration: underline;
        }

        span {
          margin-left: 0.5rem;
        }
      }
    }
  }
`;

StyledAdmin.displayName = 'StyledAdmin';
AccountDetails.displayName = 'AccountDetails';
TableOfContents.displayName = 'TableOfContents';
TableOfContentsList.displayName = 'TableOfContentsList';
