export const DROPDOWN_CONTAINER_WIDTH = {
  NARROW: 'narrow',
  DEFAULT: 'default',
  WIDE: 'wide',
  EXTRA_WIDE: 'extra-wide',
};

export const SPECIAL_OPTIONS = {
  ALL: 'all',
  HOME: 'home',
  AWAY: 'away',
};

export const DROPDOWN_SPECIAL_OPTIONS = Object.values(SPECIAL_OPTIONS);
