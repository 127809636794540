import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, ButtonIcon, Checkbox } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { mf_Players } from '../../../apollo';
import { getPalette } from '../../../utils/visualisations/visPalettes';
import PositionIcon from '../../../components/PositionIcon/PositionIcon';
import {
  ALL_POSITIONS_MAP,
  API_DEFENSIVE_POSITION_KEYS,
  ROSTER_POSITION_KEYS,
  ALIGNMENT_POSITION_KEYS,
} from './TeamDepthChart.constants';
import {
  DepthChartTableContainer,
  DepthChartTableHeader,
  StyledTableDataCell,
} from './DepthChartTable.styles';

const DepthChartTable = ({
  data,
  positionApiKey,
  handleClick,
  formationRole,
  headerIcon,
  selectableRows,
  onChangeRow,
  onChangeHeader,
  numberOfTables,
  isHeadSticky,
}) => {
  const positionName = ALL_POSITIONS_MAP[positionApiKey].name;
  const role = formationRole ? ` (${formationRole})` : '';

  const { isDark } = useTheme();
  const visPalette = getPalette(isDark);
  const color = ALL_POSITIONS_MAP[positionApiKey].color(visPalette).main;
  const isUsagePosition = ALL_POSITIONS_MAP[positionApiKey].displayUsage;
  const isLateDownsPosition =
    API_DEFENSIVE_POSITION_KEYS.includes(positionApiKey);

  const rosterPosition = ROSTER_POSITION_KEYS.includes(positionApiKey)
    ? positionApiKey
    : null;
  const alignmentPosition = ALIGNMENT_POSITION_KEYS.includes(positionApiKey)
    ? positionApiKey
    : null;

  const checked = data.every((player) => player.checked);
  const indeterminate = data.some((player) => player.checked);

  // determine what the 4th header cell should be depending on the data passed
  const get4thHeaderCell = () => {
    let label = '';
    let title = '';

    if (isUsagePosition) {
      label = 'Usage %';
      title = 'Usage rate';
    }

    if (isLateDownsPosition) {
      label = '3/4 Down %';
      title =
        "% of a team's total 3rd and 4th down plays that this player was on the field";
    }

    if (label) {
      return (
        <Table.HeaderCell textAlign="right" title={title}>
          {label}
        </Table.HeaderCell>
      );
    }

    return null;
  };

  // determine what the 4th data cell should be based on the position
  const get4thDataCell = (player) => {
    let label = '-';

    if (isUsagePosition) {
      label = player.usageRate;
    }

    if (isLateDownsPosition) {
      label = player.lateDownsRate;
    }

    if (label !== '-') {
      return <Table.DataCell $textAlign="right">{label}</Table.DataCell>;
    }

    return null;
  };

  return (
    <DepthChartTableContainer
      $selectableRows={selectableRows}
      $numberOfTables={numberOfTables}
    >
      <DepthChartTableHeader>
        <h3>
          <PositionIcon
            rosterPosition={rosterPosition}
            alignmentPosition={alignmentPosition}
            letter={formationRole}
          />
          <span>{`${positionName}${role}`}</span>
        </h3>
        {handleClick && (
          <ButtonIcon
            variant="ghost"
            onClick={handleClick}
            size="small"
            icon={headerIcon}
            title="open radars comparison drawer"
          />
        )}
      </DepthChartTableHeader>
      <div className={isHeadSticky ? 'scrollable' : ''}>
        <Table withBorder={false} isHeadSticky={isHeadSticky}>
          <Table.Head>
            <Table.Row>
              {selectableRows && (
                <Table.HeaderCell>
                  <Checkbox
                    checked={checked}
                    id="select-all-rows"
                    indeterminate={indeterminate}
                    label="select-all-rows"
                    onChange={onChangeHeader}
                    withLabel={false}
                    size="small"
                  />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell textAlign="left" title="Player name">
                Player
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right" title="Play count total">
                Plays
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right" title="Play count %">
                Play %
              </Table.HeaderCell>
              {get4thHeaderCell()}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((player) => (
              <Table.Row key={player.id}>
                {selectableRows && (
                  <Table.DataCell>
                    <Checkbox
                      checked={player.checked}
                      id={`select-row-${player.id}`}
                      label={`select-row-${player.id}`}
                      onChange={() => onChangeRow(player.id)}
                      withLabel={false}
                      size="small"
                    />
                  </Table.DataCell>
                )}
                <Table.DataCell $textAlign="left">
                  <Link onClick={() => mf_Players(player.id)} to={player.link}>
                    {player.name}
                  </Link>
                </Table.DataCell>
                <Table.DataCell $textAlign="right">
                  <StyledTableDataCell $color={color} $opacity={player.opacity}>
                    {player.snapCount}
                  </StyledTableDataCell>
                </Table.DataCell>
                <Table.DataCell $textAlign="right">
                  <StyledTableDataCell $color={color} $opacity={player.opacity}>
                    {player.snapRate}
                  </StyledTableDataCell>
                </Table.DataCell>
                {get4thDataCell(player)}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </DepthChartTableContainer>
  );
};

DepthChartTable.propTypes = {
  // the data rows for the table
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      checked: PropTypes.bool,
      snapCount: PropTypes.number,
      snapRate: PropTypes.string,
      usageRate: PropTypes.string,
      link: PropTypes.string,
      opacity: PropTypes.number,
    })
  ),
  // function that is called when the redirect icon is clicked
  handleClick: PropTypes.func,
  // API value for the position the table is displaying (i.e. 'QUARTERBACK')
  positionApiKey: PropTypes.string.isRequired,
  // which icon to render in the header of the table
  headerIcon: PropTypes.string,
  // the formation role of position displayed in the table
  formationRole: PropTypes.string,
  // how many tables are rendered in the depth chart - used for styling purposes
  numberOfTables: PropTypes.number,
  // whether the table's rows are selectable
  selectableRows: PropTypes.bool,
  // callback function for selectable rows
  onChangeRow: PropTypes.func,
  // callback function for selectable header row
  onChangeHeader: PropTypes.func,
  // do we want a sticky header? if sticky, the table will scroll
  isHeadSticky: PropTypes.bool,
};

DepthChartTable.defaultProps = {
  data: [],
  handleClick: null,
  headerIcon: 'Radar',
  formationRole: '',
  numberOfTables: null,
  selectableRows: false,
  onChangeRow: undefined,
  onChangeHeader: undefined,
  isHeadSticky: true,
};

export default DepthChartTable;
