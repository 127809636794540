import {
  appendText,
  fontVerticalCenterY,
} from '../../../../../visualisations/text';
import { VISUALISATION_FONT_SETUPS } from '../../../../../utils/constants/visText';
import { RUN_TENDENCY_AVERAGE_LINE_SETUP } from '../RunTendencies.constants';

export const drawLeagueLines = (
  linesG,
  visPalette,
  sectionWidth,
  linesMetricName
) => {
  const teamLineG = linesG.append('g');
  const leagueLineG = linesG
    .append('g')
    .attr(
      'transform',
      `translate(0,${VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT})`
    );
  const lineY = VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT / 2;
  const fontY = fontVerticalCenterY(VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE);
  const textSetup = {
    y: lineY + fontY,
    x: sectionWidth / 4 + 4,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  };
  teamLineG
    .append('line')
    .attr('x1', 0)
    .attr('x2', sectionWidth / 4)
    .attr('y1', lineY)
    .attr('y2', lineY)
    .attr('stroke', RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.COLOR(visPalette))
    .attr('stroke-width', RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.STROKE_WIDTH)
    .attr('stroke-dasharray', RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.STROKE_DASH);
  appendText(teamLineG, visPalette, {
    ...textSetup,
    message: `Team Average ${linesMetricName}`,
  });
  leagueLineG
    .append('line')
    .attr('x1', 0)
    .attr('x2', sectionWidth / 4)
    .attr('y1', lineY)
    .attr('y2', lineY)
    .attr('stroke', RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.COLOR(visPalette))
    .attr('stroke-width', RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.STROKE_WIDTH)
    .attr(
      'stroke-dasharray',
      RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.STROKE_DASH
    );
  appendText(leagueLineG, visPalette, {
    ...textSetup,
    message: `League Average ${linesMetricName}`,
  });
};

export const drawSolidFrameDots = (solidFrameG, visPalette) => {
  const filteredDotG = solidFrameG.append('g');
  const teamDotG = solidFrameG
    .append('g')
    .attr(
      'transform',
      `translate(0,${VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT})`
    );

  const midpointY = VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT / 2;
  const fontY = fontVerticalCenterY(VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE);
  const circleRadius = 5;
  const textSetup = {
    y: midpointY + fontY,
    x: circleRadius * 2 + 4,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
  };
  filteredDotG
    .append('circle')
    .attr('cx', circleRadius)
    .attr('cy', midpointY)
    .attr('r', circleRadius)
    .attr('fill', visPalette.objects.neutral.main)
    .attr('stroke', visPalette.objects.neutral.main)
    .attr('stroke-width', 1);
  appendText(filteredDotG, visPalette, {
    ...textSetup,
    message: `Player filtered value`,
  });
  teamDotG
    .append('circle')
    .attr('cx', circleRadius)
    .attr('cy', midpointY)
    .attr('r', circleRadius)
    .attr('fill', 'transparent')
    .attr('stroke', visPalette.objects.neutral.main)
    .attr('stroke-width', 1);
  appendText(teamDotG, visPalette, {
    ...textSetup,
    message: `Team (unfiltered) value`,
  });
};
