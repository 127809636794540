import React from 'react';
import PropTypes from 'prop-types';
import DownloadCSVButton from '../../../components/buttons/DownloadCSV';
import GoTo from '../../../components/buttons/GoTo';
import Tile from '../../../components/Tile/Tile';
import BasicTable from '../../../components/Table/BasicTable';

const PlayerRoutesSummary = ({ fileName, snapDatum, error, loading }) => {
  if (!snapDatum) {
    return <></>;
  }

  const data = [
    { label: 'Game', value: snapDatum?.game?.name },
    { label: 'Drive', value: snapDatum?.play?.drive?.name },
    { label: 'Play', value: snapDatum?.play?.name },
    { label: 'Route', value: snapDatum?.routeRun ? 'True' : 'False' },
    { label: 'Target', value: snapDatum?.target ? 'True' : 'False' },
  ];

  // eslint-disable-next-line max-len
  const href = `/game/animation/:leagues/:seasons/:teams/${snapDatum?.game?.id}/${snapDatum?.play?.drive?.id}/${snapDatum?.play?.id}`;
  const headers = [
    { label: 'Info', key: 'label', dataClass: 'text-right' },
    { label: 'Value', key: 'value', dataClass: 'wrap-text text-left' },
  ];

  return (
    <Tile dataTestid="PassInfoTileT" border="0" margin="0 0 0.8rem 0">
      <Tile.Header $isSlim>
        <h2>Selected Path</h2>
        <div className="buttons">
          <GoTo href={href} linkName="Open in Play Animation" />
          {!error && !loading && data && (
            <DownloadCSVButton data={data} fileName={fileName} />
          )}
        </div>
      </Tile.Header>
      <Tile.Body $padding="0">
        {!error && !loading && data && (
          <BasicTable data={data} headers={headers} showHeader={false} />
        )}
      </Tile.Body>
    </Tile>
  );
};

PlayerRoutesSummary.propTypes = {
  fileName: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  snapDatum: PropTypes.shape({
    id: PropTypes.string,
    routeRun: PropTypes.bool,
    target: PropTypes.bool,
    play: { id: PropTypes.string, drive: { id: PropTypes.string } },
    game: { id: PropTypes.number },
  }),
};

PlayerRoutesSummary.defaultProps = {
  fileName: 'table.csv',
  error: false,
  loading: false,
  snapDatum: null,
};

export default PlayerRoutesSummary;
