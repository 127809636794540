import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Icon } from '@statsbomb/kitbag-components';
import { uniqBy } from 'lodash';
import { mf_Teams } from '../../../../apollo';
import { formatDriveInfo } from '../PlayByPlay.DataManipulation';
import {
  AccordionWrapper,
  DriveHeader,
  TeamHeader,
} from './PlayByPlayAccordion.styles';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import PlayByPlayAccordionTileHeader from './PlayByPlayAccordionTileHeader';
import PlayByPlayAccordionTileBody from './PlayByPlayAccordionTileBody';
import Dimmer from '../../../../components/Dimmer/Dimmer';

const PlayByPlayAccordion = ({
  playsData,
  isLoading,
  isError,
  selectedTeamColor,
  opponentTeamColor,
  visPalette,
}) => {
  const selectedTeam = useReactiveVar(mf_Teams);

  const feedbackContent = () => {
    if (isLoading) {
      return <Loader content="Loading" />;
    }
    if (isError) {
      return <p>There was an error loading the data</p>;
    }
    if (!playsData || playsData?.length === 0) {
      return <p>No Data available</p>;
    }
    return null;
  };

  const feedback = feedbackContent() && (
    <div>
      <Dimmer active>{feedbackContent()}</Dimmer>
    </div>
  );

  const drives = uniqBy(playsData, (p) => p.drive.id);
  const formattedDrives = drives.map((p) =>
    formatDriveInfo(p.drive, playsData)
  );

  const accordianTile = function (driveDatum) {
    if (!driveDatum) {
      return <></>;
    }
    const header = (
      <PlayByPlayAccordionTileHeader
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...driveDatum}
        selectedTeamColor={selectedTeamColor}
        opponentTeamColor={opponentTeamColor}
      />
    );
    const body = (
      <PlayByPlayAccordionTileBody
        playsData={driveDatum.playsInDrive}
        visPalette={visPalette}
      />
    );
    return <AccordionTile key={driveDatum.id} header={header} body={body} />;
  };

  const gameDetails = playsData?.[0]?.game;
  const isHomeTeam = gameDetails?.homeTeam.id === selectedTeam;
  const selectedTeamName = isHomeTeam
    ? gameDetails?.homeTeam.name
    : gameDetails?.awayTeam.name;
  const opponentTeamName = isHomeTeam
    ? gameDetails?.awayTeam.name
    : gameDetails?.homeTeam.name;

  return (
    <>
      <DriveHeader>
        <span className="drive-index">Drive</span>
        <span className="drive-clock">Clock</span>
        <span className="drive-team-on-offense">Team on offense</span>
        <span className="drive-result">Drive Result</span>
        <span className="team-scores">
          <TeamHeader $isSelectedTeam $color={selectedTeamColor}>
            <span className="team-container">
              <span className="team-name">
                {selectedTeamName || 'Selected Team'}
              </span>
              <span className="underline" />
            </span>
            <Icon variant="HalfMoonLeft" size="tiny" />
          </TeamHeader>
          <span className="team-separator" />
          <TeamHeader $color={opponentTeamColor}>
            <Icon variant="HalfMoonTop" size="tiny" />
            <span className="team-container">
              <span className="team-name">
                {opponentTeamName || 'Opponent Team'}
              </span>
              <span className="underline" />
            </span>
          </TeamHeader>
        </span>
      </DriveHeader>
      <AccordionWrapper>
        {feedback || formattedDrives.map(accordianTile)}
      </AccordionWrapper>
    </>
  );
};

PlayByPlayAccordion.propTypes = {
  playsData: PropTypes.arrayOf(
    PropTypes.shape({
      game: PropTypes.shape({
        awayTeam: PropTypes.shape({ name: PropTypes.string }),
        homeTeam: PropTypes.shape({ name: PropTypes.string }),
      }),
    })
  ),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  selectedTeamColor: PropTypes.string, // hex code
  opponentTeamColor: PropTypes.string, // hex code
  visPalette: PropTypes.shape({}),
};

PlayByPlayAccordion.defaultProps = {
  playsData: undefined,
  isLoading: false,
  isError: false,
  selectedTeamColor: 'Red',
  opponentTeamColor: 'Blue',
  visPalette: {},
};

export default PlayByPlayAccordion;
