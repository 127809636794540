import { styled } from 'styled-components';

export const StarRatingRow = styled.span`
  font-size: 0.75rem;
`;
StarRatingRow.displayName = 'StarRatingRow';

export const StarRatingFullStar = styled.span`
  &::before {
    content: '★';
  }
  color: ${({ theme }) => theme.colours.messaging.warning.main};
`;
StarRatingFullStar.displayName = 'StarRatingFullStar';

export const StarRatingEmptyStar = styled.span`
  &::before {
    content: '★';
  }
  color: ${({ theme }) => theme.colours.canvas.secondary.main};
`;
StarRatingEmptyStar.displayName = 'StarRatingEmptyStar';
