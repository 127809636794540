import { useQuery } from '@apollo/client';
import { isArray, sortBy } from 'lodash';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';
import { GET_PLAYER_SCOUT } from './getPlayerScout';
import useDebounce from '../../../../utils/hooks/useDebounce';
import { formatAPIScoutPlayers } from './ScoutPlayers.dataManipulation';

export const useGetPlayerScout = (
  metricFilters,
  selectedPositions,
  stats,
  selectedBiometrics
) => {
  const statFilters = sortBy(metricFilters, 'key').map((m) => {
    if (isArray(m.value)) {
      const minValue = m.lowerIsBetter ? m.value[1] : m.value[0];
      const maxValue = m.lowerIsBetter ? m.value[0] : m.value[1];
      return {
        name: m.key,
        minValue,
        maxValue,
      };
    }
    /* Fallback logic for templates saved before metric values were dual */
    return {
      name: m.key,
      minValue: m.lowerIsBetter ? null : m.value,
      maxValue: m.lowerIsBetter ? m.value : null,
    };
  });
  const stringifiedStatFilters = JSON.stringify(statFilters);
  const debouncedStringFilters = useDebounce(stringifiedStatFilters, 1000);
  const debouncedStatFilters = JSON.parse(debouncedStringFilters);

  const bioFilters = sortBy(selectedBiometrics, 'key').map((m) => ({
    [m.key]: m.value,
  }));
  const bioFilter = bioFilters?.length > 0 ? Object.assign(...bioFilters) : {};
  const stringifiedBioFilter = JSON.stringify(bioFilter);
  const debouncedBioStringFilter = useDebounce(stringifiedBioFilter, 1000);
  const debouncedBioFilter = JSON.parse(debouncedBioStringFilter);

  const { data, loading, error } = useQuery(GET_PLAYER_SCOUT, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    variables: {
      positions: selectedPositions,
      statFilters: debouncedStatFilters,
      stats,
      bioFilter: debouncedBioFilter,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return { isLoading: true, players: [] };
  }
  if (error) {
    console.error(ERROR_CODES.USE_PLAYER_SCOUT, error);
    return {
      isLoading: false,
      error,
      players: [],
    };
  }

  const rawPlayers = data?.playerScout;
  const players = formatAPIScoutPlayers(rawPlayers);
  return {
    isLoading: false,
    players,
  };
};
