import React from 'react';
import { useTheme } from 'styled-components';
import { addGradientDots } from '../../../utils/visualisations/keys';
import { useD3 } from '../../../utils/hooks/useD3';
import { appendText, fontBaselineY } from '../../../visualisations/text';
import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import {
  COLOR_BUCKETS_Y_OFFSET,
  DOT_GAP,
  HEADER_Y_OFFSET,
  KEY_HEIGHT,
  LABEL_Y_OFFSET,
  MARGIN_LEFT,
  MARGIN_TOP,
  RADIUS,
  RUN_RATE_WIDTH,
  CIRCLE_AMOUNT,
} from './FormationSummaryTable.constants';
import { reversedCsIntensityBlue } from './TeamFormationSummary.dataManipulation';

const FormationSummaryTableKey = () => {
  const { colours, isDark } = useTheme();
  const visPalette = colours.visualisations;

  const colorBuckets = [
    visPalette.rank.antiGold.main,
    visPalette.rank.antiSilver.main,
    visPalette.rank.antiBronze.main,
    colours.canvas.tertiary.main,
    visPalette.rank.bronze.main,
    visPalette.rank.silver.main,
    visPalette.rank.gold.main,
  ];

  const ref = useD3(
    (svg) => {
      // reset svg
      svg.selectAll('*').remove();

      // run rate gradient section
      const runRateG = svg
        .append('g')
        .attr('transform', `translate(-${RADIUS}, ${MARGIN_TOP})`);
      const runRateGradient = runRateG
        .append('g')
        .attr('transform', `translate(${MARGIN_LEFT}, ${MARGIN_LEFT})`);

      appendText(runRateG, visPalette, {
        message: 'Run Rate',
        fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
        fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
        y: fontBaselineY(
          VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
          VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
        ),
        x: MARGIN_LEFT,
      });

      const gradientValuesOverride = new Array(CIRCLE_AMOUNT)
        .fill()
        .map((_, i) => i * (1 / (CIRCLE_AMOUNT - 1)));

      addGradientDots(
        runRateGradient,
        reversedCsIntensityBlue,
        visPalette,
        isDark,
        RUN_RATE_WIDTH,
        'All Pass',
        'All Run',
        '',
        {
          gradientValues: gradientValuesOverride,
          reverse: true,
        }
      );

      // color buckets section
      const colorBucketsG = svg
        .append('g')
        .attr(
          'transform',
          `translate(${COLOR_BUCKETS_Y_OFFSET}, ${MARGIN_TOP})`
        );

      colorBucketsG
        .selectAll('circle')
        .data(colorBuckets)
        .enter()
        .append('circle')
        .attr('cx', (_, i) => i * DOT_GAP)
        .attr('cy', RADIUS + MARGIN_LEFT)
        .attr('r', RADIUS)
        .attr('fill', (d) => d);

      appendText(colorBucketsG, visPalette, {
        message: 'Ranks',
        fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
        fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
        y: HEADER_Y_OFFSET,
        x: -RADIUS,
      });
      appendText(colorBucketsG, visPalette, {
        message: 'Worst',
        fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
        fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
        y: LABEL_Y_OFFSET,
        x: -RADIUS,
      });
      appendText(colorBucketsG, visPalette, {
        message: 'Best',
        fontSize: VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
        fontWeight: VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT,
        y: LABEL_Y_OFFSET,
        x: DOT_GAP * colorBuckets.length - RADIUS * 2,
        textAnchor: 'end',
      });
    },
    [isDark]
  );

  return (
    <div>
      <svg
        ref={ref}
        style={{
          width: '100%',
          height: `${KEY_HEIGHT}px`,
        }}
      />
    </div>
  );
};

export default FormationSummaryTableKey;
