export const HAVOC_SUMMARY_PERCENTAGE_TYPES = {
  OF_ALL_PLAYS: {
    value: 'OF_ALL_PLAYS',
    label: 'Havoc %',
    dataKey: 'playsPercentage',
    gapDomain: [0, 0.1],
    dzDomain: [0, 0.1],
  },
  OF_HAVOC_PLAYS: {
    value: 'OF_HAVOC_PLAYS',
    label: '% of Havoc',
    dataKey: 'havocPercentage',
    gapDomain: [0, 0.3],
    dzDomain: [0, 0.4],
  },
};
export const HAVOC_SPT_KEYS = Object.keys(HAVOC_SUMMARY_PERCENTAGE_TYPES);

export const HAVOC_SUMMARY_COLORING_MDOES = {
  TEAM_INTENSITY: {
    value: 'TEAM_INTENSITY',
    label: 'Team intensity',
  },
  VS_LA: {
    value: 'VS_LA',
    label: 'vs League Average',
  },
};
export const HAVOC_SCM_KEYS = Object.keys(HAVOC_SUMMARY_COLORING_MDOES);

export const HAVOC_GAP_BARS_SIZE_LIMITS = {
  MIN_VIEWBOX_WIDTH: 780,
  MAX_VIEWBOX_WIDTH: 1000,
  PADDING: 20, // the default 10 either side of the canvas area
  Y_AXIS_WIDTH: 50, // left
};
