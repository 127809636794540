import { styled } from 'styled-components';

// flyout panel for "New Filter"
export const PlayFinderFilterConfigWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${({ $left }) => $left || '-768px'};
  width: 768px;
  height: 100vh;
  z-index: 9999;
  transition: left 0.5s ease-in-out;
  scrollbar-gutter: stable;
`;
PlayFinderFilterConfigWrapper.displayName = 'PlayFinderFilterConfigWrapper';

// flyout panel footer, a modification of SummaryTileFooter
export const PlayFinderTileFooter = styled.div`
  position: absolute;
  display: flex;
  padding: 0 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  border-top: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  gap: 0.5rem;
  z-index: 2;

  button {
    height: 1.5rem;
  }
`;
PlayFinderTileFooter.displayName = 'PlayFinderTileFooter';

// background for the flyout panel
export const PlayFinderFilterConfigBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ $display }) => $display || 'none'};
  z-index: 101;
`;
PlayFinderFilterConfigBg.displayName = 'PlayFinderFilterConfigBg';

// These are the styles for the "New Filter" flyout panel content
export const PlayFinderFilterConfigLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  // adjustment for search input
  .ui.input {
    width: 100%;
    height: 36px;

    > i.icon {
      top: 4px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    height: 100%;

    // left column: parent categories
    > div:first-child {
      width: 220px;
      border-right: ${({ theme }) =>
        `1px solid ${theme.colours.canvas.secondary.main}`};
      padding-right: 1rem;

      button {
        width: 100%;
        border-radius: 6px;
        justify-content: left;
        ${({ theme }) => theme.typography.bodyRegular};
        padding: 0.5rem;
        max-height: 36px;
        margin-bottom: 0.5rem;
      }
    }

    // right column: child categories
    > div:last-child {
      flex: 1;
      padding: 0 1rem 3rem 1rem;
      height: 84vh;
      overflow-y: auto;
      scrollbar-gutter: stable;

      h3 {
        ${({ theme }) => theme.typography.headingRegular};
        margin-bottom: 1rem;
      }

      h4 {
        ${({ theme }) => theme.typography.headingSmall};
        color: ${({ theme }) => `${theme.colours.ink.primary.main}60`};
        margin-bottom: 0.5rem;
      }
    }
  }
`;
PlayFinderFilterConfigLayout.displayName = 'PlayFinderFilterConfigLayout';
