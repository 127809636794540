const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = SECOND * 60;

/**
 * Prefixes a number with 0 if required. Else stringifies the number.
 * @param {Number} time - either seconds or minutes
 * @return {String}
 */
const timeToString = (time) => (time > 9 ? time.toString() : `0${time}`);

/**
 * Returns a human readable format for a game duration
 * @param {Number} duration
 * @return {String} `10:02` for example.
 */
export function millisecondsToTime(duration) {
  const seconds = Math.floor(duration / SECOND) % 60;
  const minute = Math.floor(duration / MINUTE) % 60;
  return `${timeToString(minute)}:${timeToString(seconds)}`;
}

export function secondsToTime(duration) {
  return millisecondsToTime(duration * 1000);
}

export function formatTimestamp(videoTimestamp) {
  const niceTimestampSeperator = videoTimestamp % 60 < 10 ? '0' : '';
  const niceTimestamp =
    niceTimestampSeperator + (videoTimestamp % 60).toFixed(2);
  return niceTimestamp;
}

export default { millisecondsToTime };
