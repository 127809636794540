import { gql } from '@apollo/client';

export const GET_TEAM_STAT_DEFINITIONS = gql`
  query GetStatDefinitions {
    teamStatDefinitions {
      name
      prettyName
      rate
      units
      abbrev
      description
      lowerIsBetter
    }
  }
`;
