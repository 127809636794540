import { styled } from 'styled-components';

export const StyledMultiFFChart = styled.svg`
  .clickable {
    opacity: 0;
    fill: ${({ theme }) =>
      theme.applyOpacity(theme.colours.interface.main, 'highlight')};

    ${({ $isInteractive }) =>
      $isInteractive &&
      `
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    `}
  }
`;
