/* eslint-disable max-len */
/* 
- Title: “3rd Down and long”
    - Filter criteria:
        - Down = 3rd
        - Distance to go ≥ 7
- Title: “3rd Down and medium”
    - Filter criteria:
        - Down = 3rd
        - Distance to go > 2 & < 7
- Title: “3rd and short”
    - Filter criteria:
        - Down = 3rd
        - Distance to go ≤ 2
- Title: “Open Downs”
    - Filter criteria:
        - Down = 1st and 2nd
        - Field position = Between your own 10 and redzone (opp 20)
- Title: “Redzone”
    - Filter criteria:
        - Field Position = ≤ Opponent 20 yardline
- Title: “Goalline”
    - Filter criteria:
        - Field Position = ≤ Opponent 5 yardline
 */

export const STATSBOMB_PRESET_FILTERS = [
  {
    label: '3rd Down and long',
    value: 0,
  },
  {
    label: '3rd Down and medium',
    value: 1,
  },
  {
    label: '3rd and short',
    value: 2,
  },
  {
    label: 'Open Downs',
    value: 3,
  },
  {
    label: 'Redzone',
    value: 4,
  },
  {
    label: 'Goalline',
    value: 5,
  },
];

export const STATSBOMB_PRESET_FILTERS_DATA = [
  {
    json: '[{"__typename":"FilterNode","label":"Down","filterId":"play_down","columnId":"down","widgetType":"SELECT_MULTIPLE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":null,"max":null,"step":null,"options":["1","2","3","4"],"optionsWithIds":null,"selected":["3"]},"units":"SHORT_DECIMAL","abbrev":"Down","parentLabel":"Down & Distance","grandparentLabel":"Situation"},{"__typename":"FilterNode","label":"Yards to go","filterId":"play_yards_to_go","columnId":"yardsToGo","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":30,"step":1,"options":[],"optionsWithIds":null,"selected":[7,30]},"units":"YARDS","abbrev":"Distance","parentLabel":"Down & Distance","grandparentLabel":"Situation"}]',
  },
  {
    json: '[{"__typename":"FilterNode","label":"Down","filterId":"play_down","columnId":"down","widgetType":"SELECT_MULTIPLE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":null,"max":null,"step":null,"options":["1","2","3","4"],"optionsWithIds":null,"selected":["3"]},"units":"SHORT_DECIMAL","abbrev":"Down","parentLabel":"Down & Distance","grandparentLabel":"Situation"},{"__typename":"FilterNode","label":"Yards to go","filterId":"play_yards_to_go","columnId":"yardsToGo","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":30,"step":1,"options":[],"optionsWithIds":null,"selected":[3,7]},"units":"YARDS","abbrev":"Distance","parentLabel":"Down & Distance","grandparentLabel":"Situation"}]',
  },
  {
    json: '[{"__typename":"FilterNode","label":"Down","filterId":"play_down","columnId":"down","widgetType":"SELECT_MULTIPLE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":null,"max":null,"step":null,"options":["1","2","3","4"],"optionsWithIds":null,"selected":["3"]},"units":"SHORT_DECIMAL","abbrev":"Down","parentLabel":"Down & Distance","grandparentLabel":"Situation"},{"__typename":"FilterNode","label":"Yards to go","filterId":"play_yards_to_go","columnId":"yardsToGo","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":30,"step":1,"options":[],"optionsWithIds":null,"selected":[0,2]},"units":"YARDS","abbrev":"Distance","parentLabel":"Down & Distance","grandparentLabel":"Situation"}]',
  },
  {
    json: '[{"__typename":"FilterNode","label":"Down","filterId":"play_down","columnId":"down","widgetType":"SELECT_MULTIPLE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":null,"max":null,"step":null,"options":["1","2","3","4"],"optionsWithIds":null,"selected":["1","2"]},"units":"SHORT_DECIMAL","abbrev":"Down","parentLabel":"Down & Distance","grandparentLabel":"Situation"},{"__typename":"FilterNode","label":"Yardline","filterId":"play_yardline","columnId":"yardLine","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":100,"step":1,"options":[],"optionsWithIds":null,"selected":[10,80]},"units":"YARDS","abbrev":"Yardline","parentLabel":"Field Position","grandparentLabel":"Situation"}]',
  },
  {
    json: '[{"__typename":"FilterNode","label":"Yardline","filterId":"play_yardline","columnId":"yardLine","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":100,"step":1,"options":[],"optionsWithIds":null,"selected":[80,100]},"units":"YARDS","abbrev":"Yardline","parentLabel":"Field Position","grandparentLabel":"Situation"}]',
  },
  {
    json: '[{"__typename":"FilterNode","label":"Yardline","filterId":"play_yardline","columnId":"yardLine","widgetType":"RANGE","widgetArgs":{"__typename":"WidgetArgs","default":null,"min":0,"max":100,"step":1,"options":[],"optionsWithIds":null,"selected":[95,100]},"units":"YARDS","abbrev":"Yardline","parentLabel":"Field Position","grandparentLabel":"Situation"}]',
  },
];
