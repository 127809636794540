import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useD3 } from '../../../utils/hooks/useD3';
import {
  formatBoxScoreData,
  addTableBodyRows,
} from './TeamBoxScoreTable.dataManipulation';
import { addTableHeaderRowCells } from './TeamBoxScoreDrawing';
import { StyledBoxScoreTable } from './TeamBoxScoreTable.styles';

const TABLE_CLASS_NAMES = {
  BODY: 'tbody-main',
  HEAD: 'thead-main',
};

const BoxScoreTable = ({ data, teamId }) => {
  const ref = useD3((table) => {
    table.append('thead').attr('class', TABLE_CLASS_NAMES.HEAD);
    table.append('tbody').attr('class', TABLE_CLASS_NAMES.BODY);
  }, []);

  useEffect(() => {
    if (data) {
      const table = select(ref.current);

      const tableHead = table.select(`.${TABLE_CLASS_NAMES.HEAD}`);
      tableHead.selectAll('tr').remove();
      const tableHeadTR = tableHead.append('tr');
      addTableHeaderRowCells(tableHeadTR);

      const formattedData = data.map(formatBoxScoreData);
      const forData = formattedData.filter((r) => r.offenseTeamId === teamId);
      const againstData = formattedData.filter(
        (r) => r.defenseTeamId === teamId
      );

      const tableBody = table.select(`.${TABLE_CLASS_NAMES.BODY}`);
      tableBody.selectAll('tr').remove();
      addTableBodyRows(tableBody, forData, againstData);
    }
  }, [data]);

  return <StyledBoxScoreTable ref={ref} />;
};

BoxScoreTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  teamId: PropTypes.number,
};

BoxScoreTable.defaultProps = {
  data: undefined,
  teamId: 0,
};

export default BoxScoreTable;
