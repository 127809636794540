import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'styled-components';
import { Loader } from 'semantic-ui-react';
import {
  defaultFieldSettings,
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
} from '../../../utils/constants/charting';
import { useD3 } from '../../../utils/hooks/useD3';
import { ROUTES_Y_MODES } from './PlayerRoutesSummary.dataManipulation';
import { addOriginDots, addPaths } from './PlayerRoutesChart.drawing';
import { mf_LeagueLevel } from '../../../apollo';
import { rotateScaleZoom } from '../../../utils/visualisations/rotateScaleZoom';
import {
  addLoSField,
  addLoSFieldYRelative,
} from '../../../utils/helpers/fieldVariants';
import { drawPlayerHeatmap } from './SnapDistribution.drawing';
import { getHeatmapData } from './PlayerSnap.dataManipulation';
import { StyledRoutesChart } from './PlayerRoutesChart.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { DOT_COLOR_MODE } from './SnapDistributionChart.constants';
import BannerError from '../../../components/Banners/Banner.Error';

const PlayerRoutesChart = ({
  id,
  data,
  isError,
  isLoading,
  orientation,
  yMode,
  showPaths,
  showDots,
  showHeatmap,
  dotColorMode,
  pathColorMode,
  selectedPlay,
  setSelectedPlay,
  isInteractive,
  marginOverride,
}) => {
  let svg;
  const theme = useTheme();
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const visPalette = theme.colours.visualisations;

  const visibleFieldX = 70; // yds, 20 pre LoS and 50 post LoS
  const heatmapX = 1; // heatmap bar should display 1 yd wide
  const heatmapXOffset = 0.5; // yds from visible field boundary
  const visibleHeatmapX = showHeatmap ? heatmapX + heatmapXOffset : 0;
  const visibleFieldY =
    yMode === ROUTES_Y_MODES.FIELD ? FIELD_Y_YARDS : FIELD_Y_YARDS_RELATIVEY;
  const margin = {
    top: 25,
    right: 25,
    bottom: 25,
    left: 25,
    ...marginOverride,
  };
  const overrides = {
    ...defaultFieldSettings,
    visPalette,
    numbersOrientateWithField: false,
    showFieldNumbers: false,
    showEdgeNumbers: true,
    colorEndZoneHome: 'transparent',
    colorEndZoneAway: 'transparent',
    showGoalPosts: false,
    competitionLevel,
    horizontal: true,
    orientation,
    X_YARDS: visibleFieldX,
    LOS_X: 20,
  };
  const viewPortX =
    orientation === ROTATIONS.HORIZONTAL
      ? visibleFieldX + visibleHeatmapX
      : visibleFieldY;
  const viewPortY =
    orientation === ROTATIONS.HORIZONTAL
      ? visibleFieldY
      : visibleFieldX + visibleHeatmapX;
  const viewboxWidth =
    viewPortX * overrides.pxPerYard + margin.left + margin.right;

  const viewboxHeight =
    viewPortY * overrides.pxPerYard + margin.top + margin.bottom;
  const viewbox = `0 0 ${viewboxWidth} ${viewboxHeight}`;

  // this level declares anything static
  const ref = useD3((s) => {
    svg = s;
  });

  useEffect(() => {
    svg.attr('viewBox', viewbox);
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();

    const gBase = svg
      .append('g')
      .attr('id', `${id}-within-the-margins`)
      .attr('transform', `translate(${margin.left} ${margin.top})`);

    const rszG = rotateScaleZoom({
      baseG: gBase,
      idPrefix: id,
      viewPortWidth: viewPortX * overrides.pxPerYard,
      viewPortHeight: viewPortY * overrides.pxPerYard,
      cropToViewport: false,
      orientation,
      fieldWidth: visibleFieldX * overrides.pxPerYard,
      fieldHeight: visibleFieldY * overrides.pxPerYard,
      targetFieldX: (visibleFieldX * overrides.pxPerYard) / 2,
      targetFieldY: (visibleFieldY * overrides.pxPerYard) / 2,
      scaleFactor: 1,
      bindEdges: false,
      fieldBoundary: 0,
      addZoom: false,
      zoomableGId: `${id}-zoomable-g`,
      resetButtonId: null,
    });

    // ADD BASE LAYER FOR HEATMAP
    const heatmapG = rszG.append('g').attr('id', `${id}-heatmap-layer`);

    // ADD THE FIELD
    const fieldG = rszG
      .append('g')
      .attr('id', `${id}-field-layer`)
      .attr(
        'transform',
        `translate(${visibleHeatmapX * overrides.pxPerYard} 0)`
      );
    if (yMode === ROUTES_Y_MODES.FIELD) {
      fieldG.call(addLoSField, overrides);
    } else {
      fieldG.call(addLoSFieldYRelative, overrides);
    }

    // ROUTES LAYER
    const yShift =
      yMode === ROUTES_Y_MODES.ORIGIN || yMode === ROUTES_Y_MODES.SNAP
        ? (visibleFieldY / 2) * overrides.pxPerYard
        : 0;
    const xShift = overrides.LOS_X * overrides.pxPerYard;
    const routesG = fieldG
      .append('g')
      .attr('id', `${id}-routes-G`)
      .attr('transform', `translate(${xShift},${yShift})`);
    if (data) {
      if (showDots) {
        routesG.call(addOriginDots, data, overrides, dotColorMode, visPalette);
      }
      if (showPaths) {
        routesG.call(
          addPaths,
          data,
          overrides,
          pathColorMode,
          visPalette,
          selectedPlay,
          setSelectedPlay
        );
      }

      if (showHeatmap) {
        const showDy =
          yMode === ROUTES_Y_MODES.ORIGIN || yMode === ROUTES_Y_MODES.SNAP;
        const heatmapData = getHeatmapData(data, showDy);
        drawPlayerHeatmap(
          heatmapG,
          heatmapData,
          showDy,
          'routes-heatmap-zone',
          overrides,
          '', // TODO
          0,
          visPalette,
          id,
          theme.isDark
        );
      }
    }
  }, [data, orientation, yMode, visPalette, showHeatmap, theme.isDark]);

  return (
    <>
      {!isLoading && isError && (
        <Dimmer active>
          <BannerError message="Error loading route data" />
        </Dimmer>
      )}
      {isLoading && (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      )}
      {data?.length === 0 && (
        <Dimmer active>
          <p>No Data available</p>
        </Dimmer>
      )}
      <StyledRoutesChart ref={ref} $isInteractive={isInteractive} />
    </>
  );
};

PlayerRoutesChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  orientation: PropTypes.string,
  yMode: PropTypes.string,
  showPaths: PropTypes.bool,
  showDots: PropTypes.bool,
  showHeatmap: PropTypes.bool,
  dotColorMode: PropTypes.string,
  pathColorMode: PropTypes.string,
  selectedPlay: PropTypes.string,
  setSelectedPlay: PropTypes.func,
  isInteractive: PropTypes.bool,
  marginOverride: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
};

PlayerRoutesChart.defaultProps = {
  data: undefined,
  isError: false,
  isLoading: false,
  orientation: ROTATIONS.HORIZONTAL,
  yMode: ROUTES_Y_MODES.FIELD,
  showPaths: true,
  showDots: false,
  showHeatmap: false,
  dotColorMode: DOT_COLOR_MODE.PLAY_TYPE,
  pathColorMode: DOT_COLOR_MODE.GHOST,
  selectedPlay: null,
  setSelectedPlay: () => {},
  isInteractive: true,
  marginOverride: {},
};

export default PlayerRoutesChart;
