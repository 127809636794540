import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import { indexOf } from 'lodash';
import classnames from 'classnames';
import { SubTabWrapper, TabWrapper } from '../ScoutPlayers.styles';

const StatCategoryTabs = ({
  statCategories,
  selectedCategoryName,
  setSelectedCategoryName,
  selectedSubCategoryName,
  setSelectedSubCategoryName,
}) => {
  const panes = statCategories.map((tab) => ({
    menuItem: tab.name,
    render: () => (
      <Tab.Pane key={tab.name}>
        <SubTabWrapper>
          {tab.subcategories.map((subTab) => (
            <button
              key={`${subTab.name}-sub-tab`}
              type="button"
              onClick={() => setSelectedSubCategoryName(subTab.name)}
              className={classnames({
                selected: subTab.name === selectedSubCategoryName,
              })}
            >
              {subTab.name}
            </button>
          ))}
        </SubTabWrapper>
      </Tab.Pane>
    ),
  }));
  const statCategoryNames = statCategories.map((c) => c.name);
  const activeTableIndex = indexOf(statCategoryNames, selectedCategoryName);
  const handleTabChange = (_, { activeIndex }) => {
    const tabIndex = parseInt(activeIndex, 10);
    const newCatName = statCategoryNames[tabIndex];
    const newStatCategory = statCategories.find((s) => s.name === newCatName);
    setSelectedCategoryName(newStatCategory.name);
    setSelectedSubCategoryName(newStatCategory.subcategories[0].name);
  };
  /* Prevent tabs expanding outside the tile by giving them a max width
  Imagine one extra tab to not collide with the download button (and avoid divide by 0)
  */
  const maxTabWidth = (statCategories?.length || 0) + 1;

  return (
    <TabWrapper $tabWidth={maxTabWidth}>
      <Tab
        panes={panes}
        activeIndex={activeTableIndex}
        onTabChange={handleTabChange}
      />
    </TabWrapper>
  );
};

StatCategoryTabs.propTypes = {
  // the stat categories (will always have at least bio)
  statCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      subcategories: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  setSelectedCategoryName: PropTypes.func.isRequired,
  selectedSubCategoryName: PropTypes.string.isRequired,
  setSelectedSubCategoryName: PropTypes.func.isRequired,
};

export default StatCategoryTabs;
