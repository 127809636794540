import { toPng, toJpeg } from 'html-to-image';
import { saveAs } from 'file-saver';
import logo from '../../assets/images/sb_logo_with_icon.svg';
import { DOWNLOAD_FORMATS } from '../../components/DownloadTool/DownloadTool.constants';
import { LOGO_DEFAULT_HEIGHT } from '../constants/exporting';
/*
Function that takes a reference to an SVG element and exports it
Adds xml/xmlns attributes not needed in site
Warning: does not add any additional (external) CSS information into the SVG
Code original source: https://stackoverflow.com/a/46403589
*/
const saveSvg = (svgEl, name) => {
  svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  const svgData = svgEl.outerHTML;
  const preface = '<?xml version="1.0" standalone="no"?>\r\n';
  const svgBlob = new Blob([preface, svgData], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const svgUrl = URL.createObjectURL(svgBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = svgUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

/* Note: Logo in exports must be at least 33mm wide
   Exporter defaults to 96dpi ~= 125px (20px tall)
    (digital min is 95px, take the larger)
*/
const LOGO_WIDTH_HEIGHT_RATIO = 588.46 / 94.22; // viewbox size of sb_logo_with_icon.svg
const logoFooter = (svgG, width) => {
  svgG
    .append('image')
    .attr('id', 'sb-logo')
    .attr('href', logo)
    .attr('width', width);
};

/* For an SVG to export with references to the fonts used, 
    this should be called and added to the top level defs item
    Note: Inter font and boldness (400/600) match those in Kitbag, 
    and utils/constants/visText
*/
const addExportFontReference = (svgDefs) => {
  svgDefs
    .append('style')
    .attr('type', 'text/css')
    .text(
      "@import url('https://fonts.googleapis.com/css?family=Inter%7CInter:semi-bold');"
    );
};

// perform the download
const onDownloadPicture = (
  svgId,
  filename,
  exportFormat,
  setIsExporting,
  setShowPrintDialog
) => {
  const radarChartSVG = document.getElementById(svgId);

  const processBlob = (blob, outputFile) => {
    setIsExporting(false);
    if (window.saveAs) {
      window.saveAs(blob, outputFile);
    } else {
      saveAs(blob, outputFile);
    }
  };

  if (exportFormat === DOWNLOAD_FORMATS.PNG) {
    setIsExporting(true);
    toPng(radarChartSVG, {})
      .then((blob) => {
        processBlob(blob, filename);
      })
      .then(() => setShowPrintDialog(false));
  } else if (exportFormat === DOWNLOAD_FORMATS.JPG) {
    setIsExporting(true);
    toJpeg(radarChartSVG, {
      quality: 0.8,
    })
      .then((blob) => {
        processBlob(blob, `${filename}.jpg`);
      })
      .then(() => setShowPrintDialog(false));
  } else if (exportFormat === DOWNLOAD_FORMATS.SVG) {
    setIsExporting(true);
    /* Export Directly when SVG as opposed to conversion
    Avoids creating a nested SVG with foreign object
    */
    saveSvg(radarChartSVG, filename);
    // TODO: make saveSVG thenable so can close automatically when done
    // meanwhile, so hourglass at least shows temporarily, use fake timeout
    setTimeout(() => setIsExporting(false), 1000);
  }
};

export {
  saveSvg,
  logoFooter,
  LOGO_WIDTH_HEIGHT_RATIO,
  onDownloadPicture,
  LOGO_DEFAULT_HEIGHT,
  addExportFontReference,
};
