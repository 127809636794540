import React from 'react';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader, Message } from 'semantic-ui-react';
import { useQuery, useReactiveVar, gql, useLazyQuery } from '@apollo/client';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import { mf_TeamDetails } from '../../../apollo';
import { formatTemplateStatsAsRadarAxes } from '../../player/PlayerRadar/PlayerRadar.dataManipulation';
import useQueryString from '../../../utils/hooks/useQueryString';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { RadarTileContainer } from '../../player/PlayerOverview/PlayerRadar.Tile.styles';
import {
  getTeamRadarStatsQuery,
  getTeamRadarStatsLeagueAverageQuery,
} from './getTeamRadarStats';
import { useGetTemplateDefinitionsTeam } from '../TeamRadar/TeamRadar.hooks';
import { TEAM_RADAR_CHART_OPTIONS } from './TeamRadar.Tile.constants';
import GoTo from '../../../components/buttons/GoTo';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const TeamRadarTile = function () {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const [showLeagueAverage, setShowLeagueAverage] = useQueryString(
    'avg',
    false
  );

  const teamColors = {
    primary: mf_TeamDetailsRV?.colorPrimary,
    secondary: mf_TeamDetailsRV?.colorSecondary,
    tertiary: mf_TeamDetailsRV?.colorTertiary,
  };

  const {
    templates,
    loading: isConfigLoading,
    error: configError,
  } = useGetTemplateDefinitionsTeam();

  const selectedTemplate = templates?.find((t) => t.name === 'Offense');
  const selectedTemplateDefense = templates?.find((t) => t.name === 'Defense');
  const templateNames = selectedTemplate?.stats?.map((m) => m.name);
  const templateNamesDefense = selectedTemplateDefense?.stats?.map(
    (m) => m.name
  );
  const templateNamesLA = selectedTemplate?.stats
    ?.concat(selectedTemplateDefense?.stats)
    ?.map((m) => m.name);

  const teamOffenseStatsGQLString = getTeamRadarStatsQuery(templateNames, true);
  const teamDefenseStatsGQLString = getTeamRadarStatsQuery(
    templateNamesDefense,
    false
  );
  const teamStatsLAGQLString =
    getTeamRadarStatsLeagueAverageQuery(templateNamesLA);

  const [
    getDefense,
    {
      data: dataDefenseStats,
      loading: isDefeneStatsLoading,
      error: defenseStatsError,
    },
  ] = useLazyQuery(gql(teamDefenseStatsGQLString), {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  if (defenseStatsError) {
    console.error(ERROR_CODES.GET_TEAM_RADAR_DEFENSE_STATS, defenseStatsError);
  }

  const {
    data: dataOffenseStats,
    loading: isOffenseStatsLoading,
    error: offenseStatsError,
  } = useQuery(gql(teamOffenseStatsGQLString), {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    onCompleted: (drivesData) => {
      if (drivesData?.teamStats?.items.length > 0) {
        getDefense();
      }
    },
  });
  if (offenseStatsError) {
    console.error(ERROR_CODES.GET_TEAM_RADAR_OFFENSE_STATS, offenseStatsError);
  }

  const isError = !!offenseStatsError || !!configError || !!defenseStatsError;
  const isLoading = isConfigLoading || isOffenseStatsLoading;

  const {
    data: dataStatsLA,
    loading: statsLoadingLA,
    error: statsLAError,
  } = useQuery(gql(teamStatsLAGQLString), {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  if (statsLAError) {
    console.error(ERROR_CODES.GET_RADAR_STATS_LA, statsLAError);
  }

  const loadingMsg = () => {
    if (isConfigLoading) {
      return 'Loading Templates';
    }

    if (isOffenseStatsLoading) {
      return 'Loading Team Offense Stats ';
    }

    if (isDefeneStatsLoading) {
      return 'Loading Team Defense Stats';
    }

    if (statsLoadingLA) {
      return 'Loading League Average Data';
    }

    return '';
  };

  const radarLAComparison =
    showLeagueAverage && dataStatsLA?.teamStats?.items[0];
  const templateConfigOffense = formatTemplateStatsAsRadarAxes(
    templates && templates[0]?.stats
  );
  const templateConfigDefense = formatTemplateStatsAsRadarAxes(
    templates && templates[1]?.stats
  );

  return (
    <SummaryTile>
      {isConfigLoading || (isLoading && <TileDimmer />)}
      <SummaryTileHeader>
        <div>
          <h3>Radars</h3>
        </div>
        <div>
          {!isLoading && !isError && (
            <DropdownWrapper>
              <Dropdown
                id="team-radar-tile-dropdown"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(item) => setShowLeagueAverage(item.value === 1)}
                options={TEAM_RADAR_CHART_OPTIONS}
                value={TEAM_RADAR_CHART_OPTIONS[showLeagueAverage ? 1 : 0]}
                labelPosition="none"
                label="Team radar display options"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            href={`/team/radar/:leagues/:seasons/:teams${
              showLeagueAverage ? '?avg=true' : ''
            }`}
            linkName="Open Team Radar"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {isError && !isLoading && (
          <Message negative>
            There has been an error. Please contact support.
          </Message>
        )}
        {isError && !isLoading && !statsLoadingLA && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div>
          <RadarTileContainer>
            <div>
              <p>Offense</p>
              <RadarChart
                radarId="radar-offense"
                templateConfig={templateConfigOffense}
                radarStatDatum={
                  dataOffenseStats && dataOffenseStats?.teamStats?.items[0]
                }
                radarComparisonDatum={radarLAComparison}
                teamColors={teamColors}
                marginOverride={{ top: 0, right: 0, bottom: 0, left: 0 }}
                fontScaler={1.2} // default content width/constrained width = 680/400
                simpleKey={showLeagueAverage === true}
                subjectLabel="Team"
              />
            </div>
            <div>
              <p>Defense</p>
              <RadarChart
                radarId="radar-defense"
                templateConfig={templateConfigDefense}
                radarStatDatum={
                  dataDefenseStats && dataDefenseStats?.teamStats?.items[0]
                }
                radarComparisonDatum={radarLAComparison}
                teamColors={teamColors}
                marginOverride={{ top: 0, right: 0, bottom: 0, left: 0 }}
                fontScaler={1.2} // default content width/constrained width = 680/400
                simpleKey={showLeagueAverage === true}
                subjectLabel="Team"
              />
            </div>
          </RadarTileContainer>
        </div>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {(isLoading || statsLoadingLA) && (
            <Loader active inline size="small" content={loadingMsg()} />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default TeamRadarTile;
