import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import {
  getPalette,
  POSITION_COLOR_ATTRIBUTE_BORDER,
  POSITION_COLOR_ATTRIBUTE_JERSEY,
  POSITION_COLOR_ATTRIBUTE_MAIN,
} from '../../utils/visualisations/visPalettes';
import {
  POSITION_ICON_FONT_SIZE_VALUES,
  POSITION_ICON_SIZES,
  POSITION_ICON_SIZE_VALUES,
} from './PositionIcon.constants';
import {
  ALIGNMENT_POSITIONS,
  ROSTER_POSITIONS,
} from '../../utils/constants/positions';
import {
  getRosterPositionByCode,
  getAlignmentPositionByCode,
  getPositionShape,
} from '../../utils/helpers/positions';

const PositionIcon = ({ rosterPosition, alignmentPosition, size, letter }) => {
  const { isDark } = useTheme();
  const visPalette = getPalette(isDark);

  const positionInfo = alignmentPosition
    ? ALIGNMENT_POSITIONS[alignmentPosition] ||
      getAlignmentPositionByCode(alignmentPosition)
    : ROSTER_POSITIONS[rosterPosition] ||
      getRosterPositionByCode(rosterPosition);
  const colorObj = positionInfo.color(visPalette);
  const fill = colorObj[POSITION_COLOR_ATTRIBUTE_MAIN];
  const stroke = colorObj[POSITION_COLOR_ATTRIBUTE_BORDER];
  const textFill = colorObj[POSITION_COLOR_ATTRIBUTE_JERSEY];

  const rotate = positionInfo.rotation
    ? `rotate(${positionInfo.rotation})`
    : '';

  const iconSize = POSITION_ICON_SIZE_VALUES[size];
  const fontSize = POSITION_ICON_FONT_SIZE_VALUES[size];

  const viewBoxWidth = 24;
  const offset = viewBoxWidth / 2;
  // minus 2.5px to account for 2px stroke and 0.5px extra space
  const radius = offset - 2.5;

  return (
    <svg
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxWidth}`}
      width={iconSize}
      data-testid="PositionIconT"
    >
      <path
        transform={`translate(${offset},${offset}) ${rotate}`}
        d={getPositionShape(radius, positionInfo)}
        fill={fill}
        stroke={stroke}
        strokeWidth={2}
      />
      {letter && (
        <text
          fill={textFill}
          fontSize={fontSize}
          textAnchor="middle"
          dominantBaseline="central"
          x="50%"
          y="50%"
        >
          {letter}
        </text>
      )}
    </svg>
  );
};

PositionIcon.propTypes = {
  /* 
  Can specify either the API_ROSTER_POSITION_KEYS value (e.g. 'TIGHT_END') 
    or a corresponding ROSTER_POSITIONS code (e.g. 'TE')
  */
  rosterPosition: PropTypes.string,
  /* 
  Can specify either the API_ALIGNMENT_POSITION_KEYS value (e.g. 'TIGHT_END' or 'SLOT_RECEIVER') 
    or a corresponding ALIGNMENT_POSITIONS code (e.g. 'TE' or 'SR')
  */
  alignmentPosition: PropTypes.string,
  size: PropTypes.oneOf(Object.values(POSITION_ICON_SIZES)),
  // a single letter to display in the middle of the position icon (i.e H, W)
  letter: PropTypes.string,
};

PositionIcon.defaultProps = {
  rosterPosition: null,
  alignmentPosition: null,
  size: POSITION_ICON_SIZES.REGULAR,
  letter: '',
};

export default PositionIcon;
