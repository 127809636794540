import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const overrides = { gameIds: false };

export const GET_SNAP_EVENTS = gql`
  query GetSnapEvents(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $driveIds: [ID!]!
    ${getContextQueryVariables(overrides)}
  ) {
    selectedTeamId @client @export(as: "teamId")
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    pageFilterDrive @client @export(as: "driveIds")
    ${getContextFilterClientExports(overrides)}

    snapEvents(
      first: 500
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: [$teamId]
        driveIds: $driveIds
        ${getContextFiltersQueryArguments(overrides)}
      }
    ) {
      totalCount
      edges {
        node {
          id
          x
          y
          eventGameIndex
          freezeFrames {
            playerIndex
            ballLocation
            x
            y
            player {
              id
              name
              jerseyNumber

              teamPosition {
                id
                name
                code
                generalPosition
              }
            }
            team {
              id
            }
          }
          game {
            id
            name
            date
            homeTeam {
              name
            }
            awayTeam {
              name
            }
          }
          play {
            id
            name
            down
            quarter
            quarterClockRemaining
            success
            shotgun
            driveIndex
            yardsToGo
            yardsNet
            type
            touchdownWon
            yardLine
            offensePenaltyAccepted
            defensePenaltyAccepted
            drive {
              gameIndex
              startingYards
              yardsNet
              name
            }
            offenseTeam {
              id
              name
              shortName
            }
            defenseTeam {
              id
              name
              shortName
            }
          }
          player {
            id
          }
          team {
            id
          }
        }
      }
    }
  }
`;
