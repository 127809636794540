import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@statsbomb/kitbag-components';
import { filterTree } from './TreeSearch.dataManipulation';
import { StyledTreeSearch } from './TreeSearch.styles';

const TreeSearch = forwardRef(({ initialTree, onFilter }, ref) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    const filteredTree = filterTree(initialTree, query);
    onFilter(filteredTree);
  }, [query, onFilter]);

  useImperativeHandle(ref, () => ({
    clearInput() {
      setQuery('');
    },
  }));

  return (
    <StyledTreeSearch>
      <TextField
        adornmentIcon="Search"
        adornmentOnClick={() => {}}
        id="filter-search"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search..."
        value={query}
      />
    </StyledTreeSearch>
  );
});

TreeSearch.propTypes = {
  initialTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default TreeSearch;
