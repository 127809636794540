import { FORMATION_DETAIL_STATS_CONFIG } from '../TeamFormationDetail.constants';

export const rowsToDisplay = (rows, visibility) => {
  if (!visibility || visibility === 'closed') {
    return [];
  }

  if (visibility === 'partial') {
    return rows.filter((row) => row.formationPlayRate.value >= 0.1);
  }

  return rows;
};

export const getFormationDetailCsvData = (data) => {
  if (!data) {
    return [];
  }
  const headers = ['trait', ...Object.keys(FORMATION_DETAIL_STATS_CONFIG)];
  const csvData = data
    .map((d) =>
      d.rows.map((row) => [
        d.label,
        ...Object.values(row).map((stat) => stat.label),
      ])
    )
    .flat();

  return [headers, ...csvData];
};
