export const TEAM_PERMANENT_HEADERS_CONFIG = [
  {
    id: 'teamName',
    label: 'Team',
    isSortable: true,
    description: 'Team Name',
  },
  {
    id: 'games',
    label: 'Games',
    isSortable: true,
    description: 'Games\nThe number of games a team played',
  },
  {
    id: 'playCount',
    label: 'Plays',
    isSortable: true,
    description: 'Play Count\nHow many pass/run plays did the team have',
  },
];

export const TEAM_AGGREGATES_CONFIG = {
  TOTAL: {
    apiValue: 'TOTAL',
    label: 'Total',
  },
  RATE: {
    apiValue: 'RATE',
    label: 'Rate',
  },
};

export const TEAM_AGGREGATE_OPTIONS = Object.values(TEAM_AGGREGATES_CONFIG).map(
  (aggregate) => ({
    value: aggregate.apiValue,
    label: aggregate.label,
  })
);

export const TEAM_GROUP_BY_CONFIG = {
  TEAM_OFFENSE: {
    apiValue: 'TEAM_OFFENSE',
    label: 'Team Offense',
    id: 'teamOffense',
  },
  TEAM_DEFENSE: {
    apiValue: 'TEAM_DEFENSE',
    label: 'Team Defense',
    id: 'teamDefense',
  },
  DOWN: {
    apiValue: 'DOWN',
    label: 'Down',
    id: 'down',
    teamStatsOption: true,
    description: 'Down\nThe down metrics are aggregated for',
    columnWidth: 3.75,
  },
  QUARTER: {
    apiValue: 'QUARTER',
    label: 'Quarter',
    id: 'quarter',
    teamStatsOption: true,
    description: 'Quarter\nThe quarter metrics are aggregated for',
    columnWidth: 4.6875,
  },
  FORMATION: {
    apiValue: 'FORMATION',
    label: 'Formation',
    id: 'formation',
  },
  OFFENSE_PERSONNEL: {
    apiValue: 'OFFENSE_PERSONNEL',
    label: 'Offense Personnel',
    id: 'offensePersonnel',
  },
};

export const TEAM_STATS_GROUP_BYS = Object.values(TEAM_GROUP_BY_CONFIG).filter(
  (groupBy) => groupBy.teamStatsOption
);

export const TEAM_GROUP_BY_OPTIONS = TEAM_STATS_GROUP_BYS.map((groupBy) => ({
  value: groupBy.apiValue,
  label: groupBy.label,
}));

export const TEAM_GROUP_BY_HEADERS_CONFIG = TEAM_STATS_GROUP_BYS.reduce(
  (acc, groupBy) => ({
    ...acc,
    [groupBy.apiValue]: {
      id: groupBy.id,
      label: groupBy.label,
      description: groupBy.description,
      isSortable: false,
    },
  }),
  {}
);

export const TEAM_GROUP_BY_STICKY_COLUMNS_WIDTH = TEAM_STATS_GROUP_BYS.reduce(
  (acc, groupBy) => ({
    ...acc,
    [groupBy.apiValue]: groupBy.columnWidth,
  }),
  {}
);

export const TEAMS_PER_PAGE = 100;
export const DEFAULT_SORT_BY = 'playCount';
export const DEFAULT_HIGHLIGHTED_COLUMN = 3;
