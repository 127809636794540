import PropTypes from 'prop-types';
import {
  CHART_AXES_LABEL_TYPE,
  CHART_GUIDE_FREQUENCY,
} from './BasicChart.constants';

const cardinalNumbersPropType = PropTypes.shape({
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
});
const scatterLabelPropTypes = PropTypes.oneOf(
  Object.values(CHART_AXES_LABEL_TYPE)
);
const guideFrequencyPropTypes = PropTypes.oneOf(
  Object.values(CHART_GUIDE_FREQUENCY)
);

export const basicChartLayoutPropType = PropTypes.shape({
  AXES: PropTypes.shape({
    AREA: cardinalNumbersPropType,
    LABELS: PropTypes.shape({
      top: scatterLabelPropTypes,
      right: scatterLabelPropTypes,
      bottom: scatterLabelPropTypes,
      left: scatterLabelPropTypes,
    }),
    PADDING: cardinalNumbersPropType,
    /* Draw axis lines into the corners or not */
    CONSTRAIN_LINES_TO_PADDING: PropTypes.bool,
    TICKS: cardinalNumbersPropType,
    TICKS_OUTER: cardinalNumbersPropType,
    GUIDES: PropTypes.shape({
      VERTICAL: guideFrequencyPropTypes,
      HORIZONTAL: guideFrequencyPropTypes,
    }),
  }),
  CANVAS: PropTypes.shape({
    WIDTH: PropTypes.number,
    HEIGHT: PropTypes.number,
  }),
});
