import { min } from 'lodash';

// get rect dimensions depending on the direction the user is dragging the mouse
export const getRectDimensions = (startX, startY, endX, endY) => {
  const rectX = min([startX, endX]);
  const rectWidth = Math.abs(startX - endX);
  const rectY = min([startY, endY]);
  const rectHeight = Math.abs(startY - endY);
  // return dimensions [x, y, width, height]
  return { rectX, rectY, rectWidth, rectHeight };
};
