export const PLUS_MINUS_CATEGORIES = {
  TOTAL: 'TOTAL',
  RUN_PASS: 'RUN_PASS',
  DOWN_AND_DISTANCE: 'DOWN_AND_DISTANCE',
  PERSONNEL: 'PERSONNEL',
};

export const PLUS_MINUS_CATEGORY_NAMES = {
  TOTAL: 'Total',
  RUN_PASS: 'Run/Pass',
  DOWN_AND_DISTANCE: 'Down & Distance',
  PERSONNEL: 'Personnel',
};
