import React from 'react';
import PropTypes from 'prop-types';
import {
  StatsBlock,
  StatsBlockItemHeader,
  StatsBlockLeftColumn,
  StatsBlockRightColumn,
} from '../../../../components/VideoTile/VideoTile.styles';
import ParsedLink from '../../../../components/ParsedLink/ParsedLink';

const HavocEventTile = ({ data }) => (
  <StatsBlock>
    <StatsBlockLeftColumn>
      <div>
        <div
          style={{
            padding: '0 0 1.6rem 0',
            height: '5.05rem',
            display: 'flex',
            gap: '1.6rem',
          }}
        >
          <div>
            <StatsBlockItemHeader>Post Snap</StatsBlockItemHeader>
            {`${data?.postSnapSeconds?.toFixed(2) || ''}s`}
          </div>
          {data?.snapGapName && (
            <div>
              <StatsBlockItemHeader>Snap Gap</StatsBlockItemHeader>
              {`${data?.snapGapName || ''}`}
            </div>
          )}
        </div>
        <div style={{ width: 'max-content' }}>
          <StatsBlockItemHeader>Snap Defender</StatsBlockItemHeader>
          <ParsedLink
            href={`/player/overview/:leagues/:seasons/:teams/${data?.playerId}`}
          >
            {data?.defenderName}
          </ParsedLink>
        </div>
      </div>
      <div>
        <div
          style={{
            padding: '0 0 1.6rem 0',
            height: '5.05rem',
            display: 'flex',
            gap: '1.6rem',
          }}
        >
          <div style={{ width: 'max-content' }}>
            <StatsBlockItemHeader>Exploited Gap</StatsBlockItemHeader>
            {data?.eventGapName ? `${data?.eventGapName}` : '-'}
          </div>

          <div style={{ width: 'max-content' }}>
            <StatsBlockItemHeader>Yards to LoS</StatsBlockItemHeader>
            {`${data?.xLoS?.toFixed(1) || '-'}`}
          </div>
        </div>
        <div style={{ width: 'max-content' }}>
          <StatsBlockItemHeader>Target</StatsBlockItemHeader>
          {data?.targetName}
        </div>
      </div>
    </StatsBlockLeftColumn>
    <StatsBlockRightColumn>
      <div style={{ margin: '0 -0.8rem 0 0' }}>
        <div
          style={{
            padding: '0 0 0.8rem 0',
            height: '4.25rem',
          }}
        >
          <StatsBlockItemHeader>Play</StatsBlockItemHeader>
          {data?.playName}
        </div>
        <StatsBlockItemHeader>Drive</StatsBlockItemHeader>
        {data?.driveName}
      </div>
    </StatsBlockRightColumn>
  </StatsBlock>
);

HavocEventTile.propTypes = {
  data: PropTypes.shape({
    postSnapSeconds: PropTypes.number,
    defenderName: PropTypes.string,
    playerId: PropTypes.number,
    snapGapName: PropTypes.string,
    targetName: PropTypes.string,
    playName: PropTypes.string,
    driveName: PropTypes.string,
    xLoS: PropTypes.number,
    eventGapName: PropTypes.string,
  }),
};

HavocEventTile.defaultProps = {
  data: null,
};

export default HavocEventTile;
