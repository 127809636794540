import {
  API_ALIGNMENT_POSITION_KEYS,
  API_OL_GAPS,
  API_DEFENDER_ZONES,
} from '../../utils/constants/api';

/* 
Defender zones are a havoc concept combining the position of the defender at snap
    with their alignment position for the play
The names are inverted left/right because the gap nomenclature comes from the OL, 
    whereas the defender zones are Defense orientated
*/
export const HAVOC_DEFENDER_ZONES = {
  [API_DEFENDER_ZONES.BACK_CENTRAL]: {
    key: API_DEFENDER_ZONES.BACK_CENTRAL,
    name: 'DB Central',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_B,
      API_OL_GAPS.RIGHT_A,
      API_OL_GAPS.LEFT_B,
      API_OL_GAPS.LEFT_A,
    ],
  },
  [API_DEFENDER_ZONES.BACK_LEFT]: {
    key: API_DEFENDER_ZONES.BACK_LEFT,
    name: 'DB Wide Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
  },
  [API_DEFENDER_ZONES.BACK_RIGHT]: {
    key: API_DEFENDER_ZONES.BACK_RIGHT,
    name: 'DB Wide Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
  },
  [API_DEFENDER_ZONES.FRONT_LEFT_CENTRAL]: {
    key: API_DEFENDER_ZONES.FRONT_LEFT_CENTRAL,
    name: 'DL Central Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [API_OL_GAPS.LEFT_B, API_OL_GAPS.LEFT_A],
  },
  [API_DEFENDER_ZONES.FRONT_LEFT_WIDE]: {
    key: API_DEFENDER_ZONES.FRONT_LEFT_WIDE,
    name: 'DL Wide Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
  },
  [API_DEFENDER_ZONES.FRONT_RIGHT_CENTRAL]: {
    key: API_DEFENDER_ZONES.FRONT_RIGHT_CENTRAL,
    name: 'DL Central Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [API_OL_GAPS.RIGHT_B, API_OL_GAPS.RIGHT_A],
  },
  [API_DEFENDER_ZONES.FRONT_RIGHT_WIDE]: {
    key: API_DEFENDER_ZONES.FRONT_RIGHT_WIDE,
    name: 'DL Wide Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
  },
  [API_DEFENDER_ZONES.MID_CENTRAL]: {
    key: API_DEFENDER_ZONES.MID_CENTRAL,
    name: 'LB Central',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.RIGHT_B,
      API_OL_GAPS.RIGHT_A,
      API_OL_GAPS.LEFT_B,
      API_OL_GAPS.LEFT_A,
    ],
  },
  [API_DEFENDER_ZONES.MID_LEFT]: {
    key: API_DEFENDER_ZONES.MID_LEFT,
    name: 'LB Wide Right',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
  },
  [API_DEFENDER_ZONES.MID_RIGHT]: {
    key: API_DEFENDER_ZONES.MID_RIGHT,
    name: 'LB Wide Left',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
  },
};
