import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'styled-components';
import { select } from 'd3';
import { useD3 } from '../../utils/hooks/useD3';
import { mf_LeagueLevel } from '../../apollo';
import { rotateScaleZoom } from '../../utils/visualisations/rotateScaleZoom';
import { addLoSField } from '../../utils/helpers/fieldVariants';
import { getPositionShape } from '../../utils/helpers/positions';
import {
  POSITION_COLOR_ATTRIBUTE_BORDER,
  POSITION_COLOR_ATTRIBUTE_JERSEY,
  POSITION_COLOR_ATTRIBUTE_MAIN,
} from '../../utils/visualisations/visPalettes';
import {
  DEFAULT_FIELD_DRAWING_SETTINGS,
  FIELD_MEASURES,
  getHashYFromCompLevel,
} from '../../utils/helpers/field.constants';
import {
  ORIENTATION,
  VISIBLE_FIELD_X,
  VISIBLE_FIELD_Y,
  ICON_RADIUS,
  ICON_FONT_SIZE,
  FORMATION_DETAIL_CLASSES,
  ICON_FONT_WEIGHT,
  FIELD_LOS,
  FORMATION_DETAIL_AXES_SIZE,
} from './FormationDetailChart.constants';
import { marginPropType } from '../../utils/constants/propTypes';
import { fontVerticalCenterY } from '../text';
import {
  DEFAULT_VISUALISATION_MARGIN,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';
import { ClickableSVG } from '../visualisation.styles';
import { ALIGNMENT_POSITIONS } from '../../utils/constants/positions';

const FormationDetailChart = ({
  id,
  data,
  selectedPosition,
  isInteractive,
  handleClickPosition,
  margin,
}) => {
  const theme = useTheme();
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const visPalette = theme.colours.visualisations;

  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    orientation: ORIENTATION,
    competitionLevel,
    Y_BORDERS: 0,
    X_YARDS: VISIBLE_FIELD_X,
    LOS_X: FIELD_LOS,
    hideYGuides: false,
  };

  const viewBoxWidth =
    (VISIBLE_FIELD_Y + overrides.Y_BORDERS * 2) * overrides.pxPerYard +
    margin.left +
    margin.right +
    FORMATION_DETAIL_AXES_SIZE.left +
    FORMATION_DETAIL_AXES_SIZE.right;

  const viewBoxHeight =
    VISIBLE_FIELD_X * overrides.pxPerYard +
    margin.top +
    margin.bottom +
    FORMATION_DETAIL_AXES_SIZE.top +
    FORMATION_DETAIL_AXES_SIZE.bottom;

  const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
  const hashY =
    getHashYFromCompLevel(competitionLevel) -
    FIELD_MEASURES.HASH_MARK_WIDTH / 2;

  /*
   * Field markings are in horizontal orientation, even though this vis is fixed vertical up
   * standard for working with the field in AMF
   * rotate for orientation would be variable if ORIENTATION wasn't fixed up
   */
  const snapY = hashY * overrides.pxPerYard;
  const snapX = FIELD_LOS * overrides.pxPerYard;
  const rotateVsOrientation = 90;

  const enrichedData = data?.map((d) => ({
    ...d,
    config: ALIGNMENT_POSITIONS[d.position], // should be alignment once API fixed
  }));

  /* Static declarations ~ things that don't change for a single rendering of the vis */
  const ref = useD3((svg) => {
    svg.selectAll('*').remove();

    svg
      .append('rect')
      .attr('class', FORMATION_DETAIL_CLASSES.BACKGROUND)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('stroke', 'none')
      .attr('fill', visPalette.background.main);

    const inMarginsG = svg
      .append('g')
      .attr('transform', `translate(${margin.left} ${margin.top})`);

    inMarginsG
      .append('g')
      .attr('class', FORMATION_DETAIL_CLASSES.IN_AXES)
      .attr(
        'transform',
        `translate(${FORMATION_DETAIL_AXES_SIZE.left} ${FORMATION_DETAIL_AXES_SIZE.top})`
      );
  }, []);

  /* Backing changes ~ affected by viewbox/orientation/theme, but not the data */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    /* Update background from theme */
    svg
      .select(`.${FORMATION_DETAIL_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    const inAxesG = svg.select(`.${FORMATION_DETAIL_CLASSES.IN_AXES}`);
    inAxesG.selectAll('*').remove();
    const rszG = rotateScaleZoom({
      baseG: inAxesG,
      idPrefix: id,
      viewPortWidth: VISIBLE_FIELD_Y * overrides.pxPerYard,
      viewPortHeight: VISIBLE_FIELD_X * overrides.pxPerYard,
      cropToViewport: false,
      orientation: ORIENTATION,
      fieldWidth: VISIBLE_FIELD_X * overrides.pxPerYard,
      fieldHeight: VISIBLE_FIELD_Y * overrides.pxPerYard,
      targetFieldX: (VISIBLE_FIELD_X * overrides.pxPerYard) / 2,
      targetFieldY: (VISIBLE_FIELD_Y * overrides.pxPerYard) / 2,
      addZoom: false,
    }); // don't have to re-specify defaults

    const fieldG = rszG
      .append('g')
      .attr('class', FORMATION_DETAIL_CLASSES.FIELD);
    fieldG.call(addLoSField, overrides);

    if (isInteractive) {
      fieldG.on('click', () => handleClickPosition(''));
    }

    rszG
      .append('g')
      .attr('class', FORMATION_DETAIL_CLASSES.PLAYERS)
      .attr('transform', `translate(${snapX}, ${snapY})`);
  }, [theme.isDark, competitionLevel, viewBox]); // technically viewbox is fixed because no orientation changes yet

  /* Data changes */
  useEffect(() => {
    const svg = select(ref.current);
    const centerG = svg.select(`.${FORMATION_DETAIL_CLASSES.PLAYERS}`);

    if (enrichedData?.length) {
      centerG
        .selectAll('g')
        .data(enrichedData, (d) => d.playerTag) // this is the identifier for this data
        .join(
          (enter) => {
            const playerG = enter
              .append('g')
              .attr(
                'class',
                isInteractive ? VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS : ''
              )
              .attr('transform', `rotate(${rotateVsOrientation})`); // negates rotation animation

            if (isInteractive) {
              playerG.on('click', (_, d) => handleClickPosition(d.playerTag));
            }

            /* Add the shape */
            playerG
              .append('path')
              .attr('d', (d) => getPositionShape(ICON_RADIUS, d.config))
              .attr('transform', (d) => `rotate(${d.config.rotation})`)
              .attr(
                'fill',
                (d) => d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_MAIN]
              )
              .attr(
                'stroke',
                (d) =>
                  d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_BORDER]
              )
              .attr('stroke-width', 1);

            /* Add the label */
            playerG
              .append('text')
              .style(
                'fill',
                (d) =>
                  d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_JERSEY]
              )
              .style('font-size', (d) => d.config.radiusScale * ICON_FONT_SIZE)
              .style('font-weight', ICON_FONT_WEIGHT)
              .style('text-anchor', `middle`)
              .attr('y', (d) =>
                fontVerticalCenterY(d.config.radiusScale * ICON_FONT_SIZE)
              )
              .text((d) => d.playerTag);

            return playerG;
          },
          (update) => {
            // update shape
            update
              .select('path')
              .attr('d', (d) => getPositionShape(ICON_RADIUS, d.config))
              .attr('transform', (d) => `rotate(${d.config.rotation})`)
              .attr(
                'fill',
                (d) => d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_MAIN]
              )
              .attr(
                'stroke',
                (d) =>
                  d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_BORDER]
              );

            // update text
            update
              .select('text')
              .style(
                'fill',
                (d) =>
                  d.config.color(visPalette)[POSITION_COLOR_ATTRIBUTE_JERSEY]
              )
              .attr('y', (d) =>
                fontVerticalCenterY(d.config.radiusScale * ICON_FONT_SIZE)
              );

            // refresh icons click function on update
            if (isInteractive) {
              update.on('click', (_, d) => handleClickPosition(d.playerTag));
            }

            return update;
          },
          (exit) => exit.remove()
        )
        .transition()
        .duration(600)
        .attr(
          'transform',
          (d) =>
            `translate(${d.locations[0].x * overrides.pxPerYard}, ${
              d.locations[0].y * overrides.pxPerYard
            }) rotate(${rotateVsOrientation})`
        )
        .style('opacity', (d) =>
          selectedPosition && d.playerTag !== selectedPosition
            ? theme.opacity.highlight
            : 1
        );
    }
  }, [enrichedData, selectedPosition, theme.isDark]);

  return <ClickableSVG ref={ref} />;
};

FormationDetailChart.propTypes = {
  id: PropTypes.string.isRequired,
  // the positions locations to display in the chart
  data: PropTypes.arrayOf(
    PropTypes.shape({
      // The role of the player in the formation
      playerTag: PropTypes.string,
      // The position type of the player in the formation
      position: PropTypes.string,
      // the locations the player was positioned
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        })
      ),
    })
  ),
  // custom chart margins
  margin: marginPropType,
  // currently selected position
  selectedPosition: PropTypes.string,
  // callback function for clicking on position icons
  handleClickPosition: PropTypes.func,
  // whether the chart can be interacted with
  isInteractive: PropTypes.bool,
};

FormationDetailChart.defaultProps = {
  data: [],
  margin: DEFAULT_VISUALISATION_MARGIN,
  selectedPosition: '',
  isInteractive: true,
  handleClickPosition: undefined,
};

export default FormationDetailChart;
