export const SCOUT_SETUP_COLUMNS = {
  NAME: { key: 'name', label: 'Name' },
  POSITIONS: { key: 'positions', label: 'Positions' },
  METRICS: { key: 'metricCount', label: 'Metrics' },
  EDITOR: { key: 'lastEditor', label: 'Editor' },
  MODIFIED: { key: 'lastModified', label: 'Saved' },
};

export const SCOUT_SETUP_HEADERS = Object.values(SCOUT_SETUP_COLUMNS);

export const SCOUT_LANDING_TEXT = {
  QUICK_START_HELP:
    'Start Scouting for players with no pre-sets.' +
    '\nScout settings can be saved as a template once added.',
  GUIDE_HELP:
    "It looks like you're trying to find a player, would you like me to help you?",
  SAVED_TEMPLATE_HELP:
    'Select a previously saved setup from the table below and resume scouting',
  DELETE_WARNING:
    'You are about to permanently delete this template.' +
    '\nThis action can not be undone.' +
    '\nAre you sure you wish to proceed?',
};

export const SCOUT_TEMPLATE_SAVE_MDOES = {
  NEW: 'new',
  QUICK: 'quick-save',
  EDIT: 'edit',
  SAVE_AS: 'save as',
};
