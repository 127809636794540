import { styled } from 'styled-components';

export const PlayFinderPopupContent = styled.div`
  position: fixed;
  z-index: 99999;
  top: ${({ top }) => top || 0}px;
  left: 391px;
`;
PlayFinderPopupContent.displayName = 'PlayFinderPopupContent';

// mod of SUmmaryTileFooter
export const FilterTileFooter = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: flex-end;
  height: 48px;
  bottom: 0;
  width: 100%;
  align-items: center;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
`;
FilterTileFooter.displayName = 'FilterTileFooter';
