import { round } from 'lodash';
import { API_EVENT_TYPE_KEYS } from '../../../utils/constants/api';
import { getYardsWon } from '../../../utils/helpers/play';

const getQBPressuredNote = function (playDatum) {
  if (playDatum?.qbSacked) {
    return 'QB Sacked';
  }
  if (playDatum?.qbHit) {
    return 'QB Hit';
  }
  return null;
};

const getPassNote = function (eventsData) {
  const pass = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.PASS)
  );
  if (pass) {
    const passerName = pass?.player?.name || 'unknown';
    const receiverName = pass?.receiverPlayer?.name || 'unknown';
    if (pass.types.includes(API_EVENT_TYPE_KEYS.INTERCEPTED)) {
      return `Pass from ${passerName} to ${receiverName} intercepted`;
    }
    if (pass.success) {
      const airYards = pass.passAirYards ? pass.passAirYards.toFixed(1) : 0;
      const yardsAfterCatch = pass.yardsAfterCatch
        ? round(pass.yardsAfterCatch, 1)
        : 0;

      const passNote =
        `Pass from ${passerName} to ${receiverName}` +
        ` for ${airYards} air yards, and ${yardsAfterCatch} yards after catch`;
      return passNote;
    }
    const failType = pass.catchFailType ? `, ${pass.catchFailType}` : '';
    const passNote = `Pass from ${passerName} to ${receiverName}${failType}`;
    return passNote;
  }
  return null;
};

const getTackledNote = function (eventsData) {
  const tackled = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.TACKLED)
  );
  if (tackled) {
    const runnerName = tackled?.player?.name || '-';
    let runNote = `${runnerName} tackled`;

    const tacklerName = tackled?.opponentPlayer?.name || '-';
    if (tacklerName) {
      runNote += ` by ${tacklerName}`;
    }
    return runNote;
  }
  return null;
};

const getHandoffNote = function (playDatum, eventsData) {
  const handOff = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.HANDOFF)
  );
  if (handOff) {
    const qbName = handOff?.player?.name || 'unknown';
    const runnerName = handOff?.receiverPlayer?.name || 'unknown';
    const yardsNet = getYardsWon(playDatum?.yardsNet);

    return `Handoff from ${qbName} to ${runnerName} for ${yardsNet} yards`;
  }
  return null;
};

const getQBRunNote = function (playDatum, eventsData) {
  const handOff = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.HANDOFF)
  );
  const pass = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.PASS)
  );
  const snapCatch = eventsData.find(
    (e) =>
      e.types.includes(API_EVENT_TYPE_KEYS.CATCH) &&
      e.types.includes(API_EVENT_TYPE_KEYS.FROM_SNAP)
  );
  const snap = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.SNAP)
  );

  if (snap && !handOff && !pass && playDatum.type === 'RUSH') {
    const qbName =
      snap?.receiverPlayer?.name || snapCatch?.player?.name || 'unknown';
    const yardsRun = getYardsWon(playDatum?.yardsNet);
    return `${qbName} QB run for ${yardsRun}`;
  }
  return null;
};

const getInterceptionNote = function (eventsData) {
  const interception = eventsData.find((e) =>
    e.types.includes(API_EVENT_TYPE_KEYS.INTERCEPTION)
  );
  if (interception) {
    const interceptorName = interception?.player?.name || 'unknown';
    return `Interception by ${interceptorName}`;
  }
  return null;
};

const niceScoringTypeName = function (eventTypes) {
  if (eventTypes.includes(API_EVENT_TYPE_KEYS.TOUCHDOWN)) {
    return 'Touchdown';
  }
  if (eventTypes.includes(API_EVENT_TYPE_KEYS.SAFETY)) {
    return 'Safety';
  }
  if (eventTypes.includes(API_EVENT_TYPE_KEYS.FIELD_GOAL)) {
    return 'Field Goal';
  }
  if (eventTypes.includes(API_EVENT_TYPE_KEYS.EXTRA_POINT)) {
    return 'Extra Point';
  }
  if (eventTypes.includes(API_EVENT_TYPE_KEYS.TWO_POINT_CONVERSION)) {
    return '2 Point Conversion';
  }
  return 'Score Event';
};

const getScoreChangeNote = function (eventsData) {
  const scoringEvent = eventsData.find((e) => e.points && e.points !== 0);
  if (scoringEvent) {
    const type = niceScoringTypeName(scoringEvent.types);
    const scorerName = scoringEvent?.player?.name || 'unknown';
    const points =
      scoringEvent.points > 0 ? `+${scoringEvent.points}` : scoringEvent.points;
    return `${type} by ${scorerName} for ${points} points`;
  }
  return null;
};

const getKeyEventDescriptions = function (playDatum, eventsData) {
  if (!eventsData || eventsData.length === 0) {
    return '';
  }

  const qbPressuredNote = getQBPressuredNote(playDatum);
  const passNote = getPassNote(eventsData);
  const interceptionNote = getInterceptionNote(eventsData);
  const handoffNote = getHandoffNote(playDatum, eventsData);
  const qbRunNote = getQBRunNote(playDatum, eventsData);
  const tackledNote = getTackledNote(eventsData);
  const scoreChangeNote = getScoreChangeNote(eventsData);

  // make an array of notes, in order, removing all nulls
  const eventNotes = [
    qbPressuredNote,
    passNote,
    interceptionNote,
    handoffNote,
    qbRunNote,
    tackledNote,
    scoreChangeNote,
  ].filter(Boolean);

  // return the valid notes, separated with a semi-colon
  return eventNotes.join('; ');
};

export {
  getKeyEventDescriptions,
  getQBPressuredNote,
  getPassNote,
  getTackledNote,
  getHandoffNote,
  getQBRunNote,
  getInterceptionNote,
  getScoreChangeNote,
};
