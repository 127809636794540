import React from 'react';
import PropTypes from 'prop-types';
import BasicTable from '../../../components/Table/BasicTable';
import { templateDetailRows } from './TemplateDetails.dataManipulation';

const TemplateDetails = ({ template }) => {
  /* Button & Open state */
  const templateRows = templateDetailRows(template);

  return (
    <>
      <BasicTable data={templateRows} showHeader={false} />
    </>
  );
};

TemplateDetails.propTypes = {
  // the template whose details to share
  template: PropTypes.shape({}).isRequired,
};

export default TemplateDetails;
