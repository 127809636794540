import {
  drawCircle,
  drawHexagon,
  drawPentagon,
  drawSquare,
} from '../visualisations/shapes';
import { API_ALIGNMENT_POSITION_KEYS, API_ROSTER_POSITION_KEYS } from './api';

export const POSITION_CATEGORIES = {
  OFFENSE: {
    key: 'OFFENSE',
    name: 'Offense',
    order: 0,
  },
  DEFENSE: {
    key: 'DEFENSE',
    name: 'Defense',
    order: 1,
  },
  SPECIAL_TEAMS: {
    key: 'SPECIAL_TEAMS',
    name: 'Special Teams',
    order: 2,
  },
};
/* 
For each roster position (as is defined in the API_ROSTER_POSITION_KEYS)
    define nice terms and display properties.
Properties
name: Display name for the position
code: Short code for the position
color: function(visPalette) returns the palette object (NOT A COLOR),
shape: function(radius) returns the "d" for an svg path element of supplied radius
radiusScale: a factor by which the default radius should be altered for all shapes
    to have a similar weight
rotation: rotation of shape where needed (i.e. square vs diamond for C and rest of OL)
isOffense: boolean for whether this is an offensive position
isSpecialTeam: boolean for whether this is a special team position
*/
export const ROSTER_POSITIONS = {
  [API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN]: {
    apiCode: API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN,
    name: 'Offensive Lineman',
    code: 'OL',
    color: (palette) => palette.positions.offense.ol,
    shape: drawSquare,
    radiusScale: 0.8,
    rotation: 45,
    isOffense: true,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.OFFENSE.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN,
  },
  [API_ROSTER_POSITION_KEYS.QUARTERBACK]: {
    apiCode: API_ROSTER_POSITION_KEYS.QUARTERBACK,
    name: 'Quarterback',
    code: 'QB',
    color: (palette) => palette.positions.offense.qb,
    shape: drawHexagon,
    radiusScale: 1,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.OFFENSE.key,
    isEligibleReceiver: true,
    displayOrder: 1,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.QUARTERBACK,
    displayUsage: true,
  },
  [API_ROSTER_POSITION_KEYS.RUNNING_BACK]: {
    apiCode: API_ROSTER_POSITION_KEYS.RUNNING_BACK,
    name: 'Running Back',
    code: 'RB',
    color: (palette) => palette.positions.offense.rb,
    shape: drawHexagon,
    radiusScale: 1,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.OFFENSE.key,
    isEligibleReceiver: true,
    displayOrder: 2,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.RUNNING_BACK,
    displayUsage: true,
  },
  [API_ROSTER_POSITION_KEYS.WIDE_RECEIVER]: {
    apiCode: API_ROSTER_POSITION_KEYS.WIDE_RECEIVER,
    name: 'Wide Receiver',
    code: 'WR',
    color: (palette) => palette.positions.offense.wr,
    shape: drawHexagon,
    radiusScale: 1,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.OFFENSE.key,
    isEligibleReceiver: true,
    displayOrder: 4,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.WIDE_RECEIVER,
    displayUsage: true,
  },
  [API_ROSTER_POSITION_KEYS.TIGHT_END]: {
    apiCode: API_ROSTER_POSITION_KEYS.TIGHT_END,
    name: 'Tight End',
    code: 'TE',
    color: (palette) => palette.positions.offense.te,
    shape: drawHexagon,
    radiusScale: 1,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.OFFENSE.key,
    isEligibleReceiver: true,
    displayOrder: 6,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.TIGHT_END,
    displayUsage: true,
  },
  [API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN]: {
    apiCode: API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN,
    name: 'Defensive Lineman',
    code: 'DL',
    color: (palette) => palette.positions.defense.dl,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.DEFENSE.key,
    displayOrder: 12,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN,
  },
  [API_ROSTER_POSITION_KEYS.LINEBACKER]: {
    apiCode: API_ROSTER_POSITION_KEYS.LINEBACKER,
    name: 'Linebacker',
    code: 'LB',
    color: (palette) => palette.positions.defense.lb,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.DEFENSE.key,
    displayOrder: 15,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.LINEBACKER,
  },
  [API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK]: {
    apiCode: API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK,
    name: 'Defensive Back',
    code: 'DB',
    color: (palette) => palette.positions.defense.db,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: false,
    positionCategory: POSITION_CATEGORIES.DEFENSE.key,
    displayOrder: 16,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK,
  },
  [API_ROSTER_POSITION_KEYS.PUNTER]: {
    apiCode: API_ROSTER_POSITION_KEYS.PUNTER,
    name: 'Punter',
    code: 'P',
    color: (palette) => palette.positions.offense.kp,
    shape: drawPentagon,
    radiusScale: 1.15,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.PUNTER,
  },
  [API_ROSTER_POSITION_KEYS.HOLDER]: {
    apiCode: API_ROSTER_POSITION_KEYS.HOLDER,
    name: 'Holder',
    code: 'H',
    color: (palette) => palette.positions.offense.kp,
    shape: drawPentagon,
    radiusScale: 1.15,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.HOLDER,
  },
  [API_ROSTER_POSITION_KEYS.KICKER]: {
    apiCode: API_ROSTER_POSITION_KEYS.KICKER,
    name: 'Kicker',
    code: 'K',
    color: (palette) => palette.positions.offense.kp,
    shape: drawPentagon,
    radiusScale: 1.15,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.KICKER,
  },
  [API_ROSTER_POSITION_KEYS.LONG_SNAPPER]: {
    apiCode: API_ROSTER_POSITION_KEYS.LONG_SNAPPER,
    name: 'Long Snapper',
    code: 'LS',
    color: (palette) => palette.positions.offense.ol,
    shape: drawSquare,
    radiusScale: 0.8,
    rotation: 0,
    isOffense: true,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.LONG_SNAPPER,
  },
  [API_ROSTER_POSITION_KEYS.KICK_RETURNER]: {
    apiCode: API_ROSTER_POSITION_KEYS.KICK_RETURNER,
    name: 'Kick Returner',
    code: 'KR',
    color: (palette) => palette.positions.defense.kpr,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.KICK_RETURNER,
  },
  [API_ROSTER_POSITION_KEYS.PUNT_RETURNER]: {
    apiCode: API_ROSTER_POSITION_KEYS.PUNT_RETURNER,
    name: 'Punt Returner',
    code: 'PR',
    color: (palette) => palette.positions.defense.kpr,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: true,
    positionCategory: POSITION_CATEGORIES.SPECIAL_TEAMS.key,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.PUNT_RETURNER,
  },
};

/*
In most situations, reserve is not a useful position.
If it is wanted, it should be merged to the general ROSTER_POSITIONS object
 */
export const ROSTER_POSITION_RESERVE = {
  [API_ROSTER_POSITION_KEYS.RESERVE]: {
    apiCode: API_ROSTER_POSITION_KEYS.RESERVE,
    name: 'Reserve',
    code: 'R',
    color: (palette) => palette.positions.reserve.r,
    shape: drawCircle,
    radiusScale: 1,
    rotation: 0,
    isOffense: false,
    isSpecialTeam: false,
    rosterPositionKey: API_ROSTER_POSITION_KEYS.RESERVE,
  },
};

/*
Each alignment position has a parent roster position
Alignment positions inherit their parent styling information, with some minor overrides
The nomenclature is amended as appropriate
*/
export const ALIGNMENT_POSITIONS = {
  /* offense */
  [API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE,
    name: 'Left Tackle',
    code: 'LT',
    displayOrder: 7,
  },
  [API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD,
    name: 'Left Guard',
    code: 'LG',
    displayOrder: 8,
  },
  [API_ALIGNMENT_POSITION_KEYS.CENTER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.CENTER,
    name: 'Center',
    code: 'C',
    rotation: 0,
    displayOrder: 9,
  },
  [API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD,
    name: 'Right Guard',
    code: 'RG',
    displayOrder: 10,
  },
  [API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE,
    name: 'Right Tackle',
    code: 'RT',
    displayOrder: 11,
  },
  [API_ALIGNMENT_POSITION_KEYS.QUARTERBACK]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.QUARTERBACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.QUARTERBACK,
  },
  [API_ALIGNMENT_POSITION_KEYS.RUNNING_BACK]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.RUNNING_BACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.RUNNING_BACK,
  },
  [API_ALIGNMENT_POSITION_KEYS.FULLBACK]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.RUNNING_BACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.FULLBACK,
    name: 'Full Back',
    code: 'FB',
    displayOrder: 3,
    displayUsage: true,
  },
  [API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER,
  },
  [API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.WIDE_RECEIVER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER,
    name: 'Slot Receiver',
    code: 'SR',
    displayOrder: 5,
    displayUsage: true,
  },
  [API_ALIGNMENT_POSITION_KEYS.TIGHT_END]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.TIGHT_END],
    apiCode: API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
  },
  /* defense */
  [API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    name: 'Defensive End',
    code: 'EDGE',
    displayOrder: 14,
  },
  [API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN],
    apiCode: API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
    name: 'Defensive Tackle',
    code: 'IDL',
    displayOrder: 13,
  },
  [API_ALIGNMENT_POSITION_KEYS.LINEBACKER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.LINEBACKER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.LINEBACKER,
  },
  [API_ALIGNMENT_POSITION_KEYS.CORNERBACK]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    name: 'Cornerback',
    code: 'CB',
    displayOrder: 18,
  },
  [API_ALIGNMENT_POSITION_KEYS.NICKELBACK]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
    name: 'Nickelback',
    code: 'NIC',
    displayOrder: 17,
  },
  [API_ALIGNMENT_POSITION_KEYS.SAFETY]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK],
    apiCode: API_ALIGNMENT_POSITION_KEYS.SAFETY,
    name: 'Safety',
    code: 'S',
    displayOrder: 19,
  },
  /* special team */
  [API_ALIGNMENT_POSITION_KEYS.PUNTER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.PUNTER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.PUNTER,
  },
  [API_ALIGNMENT_POSITION_KEYS.HOLDER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.HOLDER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.HOLDER,
  },
  [API_ALIGNMENT_POSITION_KEYS.KICKER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.KICKER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.KICKER,
  },
  [API_ALIGNMENT_POSITION_KEYS.LONG_SNAPPER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.LONG_SNAPPER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.LONG_SNAPPER,
  },
  [API_ALIGNMENT_POSITION_KEYS.PUNT_RETURNER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.PUNT_RETURNER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.PUNT_RETURNER,
  },
  [API_ALIGNMENT_POSITION_KEYS.KICK_RETURNER]: {
    ...ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.KICK_RETURNER],
    apiCode: API_ALIGNMENT_POSITION_KEYS.KICK_RETURNER,
  },
};
