import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { minBy } from 'lodash';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import { ButtonIcon, Dropdown, Toggle } from '@statsbomb/kitbag-components';
import GameAnimationChart from './GameAnimationChart';
import Tile from '../../../components/Tile/Tile';
import { FixedAside } from '../../../components/Layout/Layout.styles';
import { mf_GameDetails, pf_TeamPlay, mf_TeamDetails } from '../../../apollo';
import {
  ROUTE_MODE_ALL,
  ROUTE_MODE_ER,
  ROUTE_MODE_BALL,
  ROUTE_MODE_NONE,
  COLOR_MODE_VS,
  COLOR_MODE_POS,
  VIEWPORT_FIELD,
  VIEWPORT_OPTIONS,
  VIEWPORTS_SHORT_PLAY,
} from './GameAnimationChart.drawing';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import {
  getFFPlayers,
  dotsLinesRowConverter,
  removeLineBattles,
} from './GameAnimation.dataManipulation';
import { GET_PLAY_EVENTS } from '../GamePlayEvents/getPlayEvents';
import PageFilers from '../../../components/PageFilters/PageFilters';
import EventList from './EventList';
import Timeline from './Timeline';
import {
  GameAnimationChartWrapper,
  StyledGameAnimation,
} from './GameAnimation.styles';
import useQueryString from '../../../utils/hooks/useQueryString';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import Dimmer from '../../../components/Dimmer/Dimmer';
import ExportModal from '../../../components/ExportModal/ExportModal';

const routeDisplays = [
  { value: ROUTE_MODE_ALL, label: ROUTE_MODE_ALL },
  { value: ROUTE_MODE_ER, label: ROUTE_MODE_ER },
  { value: ROUTE_MODE_BALL, label: ROUTE_MODE_BALL },
  { value: ROUTE_MODE_NONE, label: ROUTE_MODE_NONE },
];

const colorModes = [
  { value: COLOR_MODE_VS, label: COLOR_MODE_VS },
  { value: COLOR_MODE_POS, label: COLOR_MODE_POS },
];

const GameAnimation = () => {
  const {
    data: playEventsData,
    loading: playEventsLoading,
    error: playEventsError,
  } = useQuery(GET_PLAY_EVENTS);
  const teamDetails = useReactiveVar(mf_TeamDetails);
  // page filters (uuid)
  const pf_TeamPlayRV = useReactiveVar(pf_TeamPlay);
  // vis options
  const [teamPlayEvent, setTeamPlayEvent] = useQueryString('teamPlayEvent', '');
  const [routeMode, setRouteMode] = useQueryString(
    'routeMode',
    routeDisplays[0].value
  );
  const [colorMode, setColorMode] = useQueryString(
    'colorMode',
    colorModes[0].value
  );
  const [highlightPlayerId, setHighlightedPlayerId] = useQueryString(
    'highlightedPlayerId',
    0
  );
  const [orientation, setOrientation] = useQueryString(
    'orientation',
    ROTATIONS.VERTICAL_UP
  );
  const [routeSmoothing, setRouteSmoothing] = useQueryString('smoothing', true);
  const [lineBattles, setLineBattles] = useQueryString('line', false);
  // play functionality
  const [isPlaying, setIsPlaying] = useState(false);
  const [playHeadIndex, setPlayHeadIndex] = useQueryString('playHeadIndex', 0);
  const [viewPortSelection, setViewPortSelection] = useQueryString(
    'viewport',
    VIEWPORTS_SHORT_PLAY.value
  );

  const playStartVideoTimestamp =
    playEventsData?.playEvents?.length > 0
      ? parseFloat(
          minBy(playEventsData?.playEvents, 'videoTimestamp').videoTimestamp
        )
      : 0;

  const formattedEvents =
    !playEventsError &&
    playEventsData?.playEvents.map((m, index) =>
      dotsLinesRowConverter(m, playStartVideoTimestamp, index)
    );
  /* The events we pass into the vis/timeline */
  const animationEvents =
    !lineBattles && formattedEvents
      ? removeLineBattles(formattedEvents)
      : formattedEvents;
  /* The full list for the table, but anything we've removed from animation
      make look like it has no FFs (i.e. disabled in table) */
  const eventListAnimationEvents =
    formattedEvents &&
    formattedEvents?.map((e) => {
      const ae = animationEvents.find((a) => a.eventIndex === e.eventIndex);
      return ae ? e : { ...e, freezeFrames: null };
    });
  /* Now filter out no-ff pathing as they become just clutter, 
  but leave any other no-ff events as before 
  Does mean we end up with #gaps in the list, but no missing events
  */
  const eventListEvents =
    eventListAnimationEvents &&
    eventListAnimationEvents?.filter(
      (f) =>
        !f?.name.includes('Pathing') ||
        (f?.freezeFrames && f?.freezeFrames.length > 0)
    );

  const defaultTeamPlayEvent = formattedEvents?.[0]?.event_uuid;
  const playerList = formattedEvents && getFFPlayers(formattedEvents);

  const ffEvents =
    !playEventsLoading &&
    !playEventsError &&
    playEventsData?.playEvents.filter((f) => f.freezeFrames?.length > 0);

  // this is quite brute force ~ TODO: make the div holding the vis draggable width
  let wrapperMaxWidth = '40%';
  if (
    orientation === ROTATIONS.HORIZONTAL &&
    viewPortSelection === VIEWPORT_FIELD.value
  ) {
    wrapperMaxWidth = '100%';
  }
  if (
    orientation !== ROTATIONS.HORIZONTAL &&
    viewPortSelection === VIEWPORTS_SHORT_PLAY.value
  ) {
    wrapperMaxWidth = '100%';
  }

  const selectedEventUUId = teamPlayEvent || defaultTeamPlayEvent;

  // exporting
  const gameDetails = useReactiveVar(mf_GameDetails);
  const driveName = playEventsData?.playEvents[0]?.play?.drive?.name;
  const playName = playEventsData?.playEvents[0]?.play?.name;
  const eventName = playEventsData?.playEvents.find(
    (event) => event.id === selectedEventUUId
  )?.name;

  const handleEventSelect = (playEvent) => {
    setIsPlaying(false);
    setPlayHeadIndex(
      ffEvents?.findIndex((playEvt) => playEvt.id === playEvent.event_uuid)
    );
    setTeamPlayEvent(playEvent.event_uuid);
  };

  return (
    <StyledGameAnimation>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Tile>
              <Tile.Body>
                <PageFilers
                  onPlayChange={() => {
                    setPlayHeadIndex(0);
                    setTeamPlayEvent(null);
                  }}
                  onDriveChange={() => {
                    setPlayHeadIndex(0);
                    setTeamPlayEvent(null);
                  }}
                  inline
                  wider={false}
                />
              </Tile.Body>
            </Tile>
            <Tile>
              <Tile.Header $noBottomPadding>
                <Breadcrumb size="huge">
                  <Breadcrumb.Section>Game</Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active>Play Animation</Breadcrumb.Section>
                </Breadcrumb>
                <h1>Play Animation</h1>
                <div className="buttons">
                  <ButtonIcon
                    id="game-animation-chart-zoom-button"
                    size="small"
                    title="Reset Zoom"
                    icon="ResetZoom"
                    variant="secondary"
                  />
                  <ExportModal
                    title={gameDetails?.name}
                    secondaryTitle="Play Diagram"
                    info1={driveName}
                    info2={playName}
                    info3={eventName}
                    fileName={`${teamDetails?.name} - Play Diagram`}
                    customWidth={1067}
                    isDisabled={isPlaying}
                  >
                    {!playEventsError && animationEvents?.length > 0 && (
                      <GameAnimationChartWrapper>
                        <GameAnimationChart
                          id="game-animation-chart-export"
                          isInteractive={false}
                          selectedEventUUId={selectedEventUUId}
                          orientation={orientation}
                          routeMode={routeMode}
                          colorMode={colorMode}
                          highlightPlayerId={highlightPlayerId}
                          viewPortMode={viewPortSelection}
                          data={animationEvents}
                          isLoading={playEventsLoading}
                          routeSmoothing={routeSmoothing}
                          isPlaying={isPlaying}
                          setIsPlaying={setIsPlaying}
                          playHeadIndex={playHeadIndex}
                          setPlayHeadIndex={setPlayHeadIndex}
                          setTeamPlayEvent={setTeamPlayEvent}
                          marginOverrides={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                        />
                        <Timeline
                          id="game-animation-timeline-export"
                          isInteractive={false}
                          selectedEventUUId={selectedEventUUId}
                          data={animationEvents}
                          setIsPlaying={setIsPlaying}
                          isPlaying={isPlaying}
                        />
                      </GameAnimationChartWrapper>
                    )}
                  </ExportModal>
                </div>
              </Tile.Header>
              <Tile.Body $padding="0" $minHeight="20rem">
                <GameAnimationChartWrapper $maxWidth={wrapperMaxWidth}>
                  {!playEventsError && animationEvents?.length > 0 && (
                    <>
                      <GameAnimationChart
                        id="game-animation-chart"
                        selectedEventUUId={selectedEventUUId}
                        orientation={orientation}
                        routeMode={routeMode}
                        colorMode={colorMode}
                        highlightPlayerId={highlightPlayerId}
                        viewPortMode={viewPortSelection}
                        data={animationEvents}
                        isLoading={playEventsLoading}
                        routeSmoothing={routeSmoothing}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        playHeadIndex={playHeadIndex}
                        setPlayHeadIndex={setPlayHeadIndex}
                        setTeamPlayEvent={setTeamPlayEvent}
                      />
                      <Timeline
                        id="game-animation-timeline"
                        selectedEventUUId={selectedEventUUId}
                        data={animationEvents}
                        setIsPlaying={setIsPlaying}
                        isPlaying={isPlaying}
                        onEventSelect={handleEventSelect}
                      />
                    </>
                  )}
                  {animationEvents?.length === 0 && (
                    <Dimmer>
                      <p>No Data available</p>
                    </Dimmer>
                  )}
                </GameAnimationChartWrapper>
              </Tile.Body>
            </Tile>
          </Grid.Column>
          <Grid.Column width={4}>
            {!playEventsError && pf_TeamPlayRV && (
              <FixedAside>
                <AccordionTile
                  header={<Tile.AccordionHeader>Settings</Tile.AccordionHeader>}
                  body={
                    <Tile.AccordionBody>
                      <Dropdown
                        id="rotation-dropdown"
                        options={ROTATION_OPTIONS}
                        label="Rotation Choice"
                        onChange={(selectedOption) =>
                          setOrientation(selectedOption.value)
                        }
                        value={ROTATION_OPTIONS.find(
                          (f) => f.value === orientation
                        )}
                        menuPosition="static"
                      />
                      <Dropdown
                        id="focus-dropdown"
                        options={VIEWPORT_OPTIONS}
                        label="Focus Level"
                        onChange={(selectedOption) =>
                          setViewPortSelection(selectedOption.value)
                        }
                        value={VIEWPORT_OPTIONS.find(
                          (f) => f.value === viewPortSelection
                        )}
                        menuPosition="static"
                      />
                      <Dropdown
                        id="route-display-dropdown"
                        options={routeDisplays}
                        label="Route Display Mode"
                        onChange={(selectedOption) =>
                          setRouteMode(selectedOption.value)
                        }
                        value={routeDisplays.find((f) => f.value === routeMode)}
                        menuPosition="static"
                      />
                      <Dropdown
                        id="colour-mode-dropdown"
                        options={colorModes}
                        label="Color Display Mode"
                        onChange={(selectedOption) =>
                          setColorMode(selectedOption.value)
                        }
                        value={colorModes.find((f) => f.value === colorMode)}
                        menuPosition="static"
                      />
                      {!playEventsLoading && !playEventsError && (
                        <Dropdown
                          id="highlight-dropdown"
                          options={playerList}
                          label="Highlight Player"
                          onChange={(selectedOption) =>
                            setHighlightedPlayerId(selectedOption.value)
                          }
                          value={playerList.find(
                            (f) => f.value === highlightPlayerId
                          )}
                          menuPosition="static"
                        />
                      )}
                      <Toggle
                        id="route-smoothing-toggle"
                        label="Route Smoothing"
                        onChange={() => setRouteSmoothing(!routeSmoothing)}
                        checked={routeSmoothing}
                      />
                      <Toggle
                        id="line-battles-toggle"
                        label="Line Battles"
                        onChange={() => setLineBattles(!lineBattles)}
                        checked={lineBattles}
                      />
                    </Tile.AccordionBody>
                  }
                  isExpandedDefault
                  isMount
                />

                <AccordionTile
                  header={<Tile.AccordionHeader>Events</Tile.AccordionHeader>}
                  body={
                    <Tile.AccordionBody>
                      <EventList
                        events={eventListEvents}
                        loading={playEventsLoading}
                        selectedEventId={teamPlayEvent || defaultTeamPlayEvent}
                        onRowClick={handleEventSelect}
                      />
                    </Tile.AccordionBody>
                  }
                  isExpandedDefault
                  isMount
                />
              </FixedAside>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledGameAnimation>
  );
};

export default GameAnimation;
