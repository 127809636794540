import {
  API_STAT_UNITS,
  PLAY_FINDER_WIDGET_TYPES,
} from '../../../utils/constants/api';
import { returnReadableYardsLabel } from '../../../utils/helpers/general';

export const getWidgetValue = (
  units,
  widgetType,
  widgetArgs,
  prefix = ': '
) => {
  if (
    widgetType === PLAY_FINDER_WIDGET_TYPES.BOOL &&
    typeof widgetArgs.selected !== 'undefined'
  ) {
    return widgetArgs.selected ? `${prefix}True` : `${prefix}False`;
  }
  if (
    widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE &&
    units === API_STAT_UNITS.SHORT_DECIMAL &&
    typeof widgetArgs.selected !== 'undefined'
  ) {
    return `${prefix}${widgetArgs.selected[0]} - ${widgetArgs.selected[1]}`;
  }
  if (
    widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE &&
    units === API_STAT_UNITS.YARDS &&
    typeof widgetArgs.selected !== 'undefined'
  ) {
    return `${prefix}${returnReadableYardsLabel(
      widgetArgs.selected,
      widgetArgs.min,
      widgetArgs.max
    )}`;
  }

  return widgetArgs.selected ? `${prefix}${widgetArgs.selected}` : '';
};
