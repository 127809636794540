import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { getPalette } from '../../utils/visualisations/visPalettes';
import { useD3 } from '../../utils/hooks/useD3';
import {
  headerInfoPropTypes,
  radarTemplatePropTypes,
  teamColorPropTypes,
  radarKeyPropTypes,
} from './RadarChart.PropTypes';
import {
  RADAR_RADIUS,
  RADAR_CHART_CLASS_NAMES,
  radarChartElementId,
  RADAR_HEADER_HEIGHT,
  RADAR_FOOTER_HEIGHT,
  RADAR_KEY_HEIGHT,
  RADAR_SIMPLE_KEY_HEIGHT,
  RADAR_AXIS_PADDING,
  RADAR_DEFAULT_MARGINS,
  RADAR_CHART_DRAW_COMPARISON,
  RADAR_FOOTER_LOGO_OFFSET,
  RADAR_PALETTE_OVERRIDES,
} from './RadarChart.constants';
import {
  setupRadar,
  addAxisInfo,
  createRadarPath,
  addClipPaths,
  drawRings,
  drawKey,
  drawSimpleKey,
} from './RadarChart.drawing';
import {
  logoFooter,
  LOGO_DEFAULT_HEIGHT,
  LOGO_WIDTH_HEIGHT_RATIO,
} from '../../utils/helpers/export';
import { addRadarHeader } from './RadarChart.header';
import {
  getSafeColorFromHex,
  getTeamColorPair,
} from '../../utils/helpers/colorDifference';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';

const RadarChart = function ({
  templateConfig,
  radarStatDatum,
  radarComparisonDatum,
  radarId,
  fixedWidth,
  paletteOverride,
  headerInfo,
  marginOverride,
  teamColors,
  keyInfo,
  fontScaler,
  simpleKey,
  subjectLabel,
  drawAxes,
}) {
  // SVG BASIC VALUES
  const { isDark } = useTheme();
  const isDarkMode = paletteOverride
    ? paletteOverride === RADAR_PALETTE_OVERRIDES.DARK
    : isDark;
  const visPalette = getPalette(isDarkMode);

  const svgWidth = fixedWidth || '100%';
  const showHeaderAndFooter = !!headerInfo;
  const hfHeight = showHeaderAndFooter
    ? RADAR_HEADER_HEIGHT + RADAR_FOOTER_HEIGHT
    : 0;
  let keyHeight = keyInfo ? RADAR_KEY_HEIGHT : 0;
  if (simpleKey) {
    keyHeight = RADAR_SIMPLE_KEY_HEIGHT;
  }
  /* The radar rings extend to the radius
    Some space that is part of the main vis is then allowed for axis names 
    There is then optional additional margin space surrounding this (+head/foot)
  */
  const margin = marginOverride || RADAR_DEFAULT_MARGINS;
  const axisLabelFontSize =
    fontScaler > 1
      ? Math.round(fontScaler * VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE)
      : null;
  const radarAxisPadding =
    fontScaler > 1
      ? RADAR_AXIS_PADDING +
        /* Because there is some fixed padding from radar edge to base of label, need some additional
        safety spacing in the additional padding added for changed font size (hence the factor 2 below) */
        (axisLabelFontSize - VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE) * 2
      : RADAR_AXIS_PADDING;

  const mainWidth = RADAR_RADIUS * 2 + radarAxisPadding * 2;
  const mainHeight = RADAR_RADIUS * 2 + radarAxisPadding * 2;

  const viewBox = `0 0 ${mainWidth + margin.left + margin.right} ${
    mainHeight + hfHeight + keyHeight + margin.top + margin.bottom
  }`;

  /* Colors */
  let primaryColor = visPalette.forAgainst.for.main;
  let secondaryColor = radarComparisonDatum
    ? visPalette.forAgainst.against.main
    : visPalette.forAgainst.for.alternate;
  if (teamColors) {
    const teamColorPair = getTeamColorPair(teamColors, isDarkMode);
    primaryColor = getSafeColorFromHex(teamColorPair.primary, isDarkMode);
    secondaryColor = getSafeColorFromHex(teamColorPair.secondary, isDarkMode);
  }

  // this level declares anything static
  const ref = useD3((svg) => {
    svg
      .attr('id', radarChartElementId(radarId))
      .attr('width', svgWidth)
      .attr('viewBox', viewBox);

    svg.selectAll('rect').remove();
    svg.selectAll('g').remove();
    svg
      .append('rect')
      .attr('class', RADAR_CHART_CLASS_NAMES.BACKGROUND_RECT)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', visPalette.background.main);

    const marginTransform = `translate(${margin.left},${margin.top})`;
    const withinMarginsG = svg
      .append('g')
      .attr('class', RADAR_CHART_CLASS_NAMES.WITHIN_MARGINS)
      .attr('transform', marginTransform);

    const headerAdjust = showHeaderAndFooter ? RADAR_HEADER_HEIGHT : 0;
    const mainTransform = `translate(${radarAxisPadding},${
      headerAdjust + radarAxisPadding
    })`;

    /* Add rings layer under main in same location, and a place for the   /legend */
    withinMarginsG
      .append('g')
      .attr('class', RADAR_CHART_CLASS_NAMES.RINGS)
      .attr('data-testid', `${RADAR_CHART_CLASS_NAMES.RINGS}-${radarId}-test`)
      .attr('transform', mainTransform);
    withinMarginsG
      .append('g')
      .attr('class', RADAR_CHART_CLASS_NAMES.MAIN)
      .attr('transform', mainTransform);
    withinMarginsG
      .append('g')
      .attr('class', RADAR_CHART_CLASS_NAMES.KEY)
      .attr('transform', `translate(0,${headerAdjust + mainHeight})`);
    /* If relevant, add places for header/footer */
    if (showHeaderAndFooter) {
      withinMarginsG
        .append('g')
        .attr('class', RADAR_CHART_CLASS_NAMES.HEADER)
        .attr(
          'data-testid',
          `${RADAR_CHART_CLASS_NAMES.HEADER}-${radarId}-test`
        );

      withinMarginsG
        .append('g')
        .attr('class', RADAR_CHART_CLASS_NAMES.FOOTER)
        .attr(
          'data-testid',
          `${RADAR_CHART_CLASS_NAMES.FOOTER}-${radarId}-test`
        )
        .attr(
          'transform',
          `translate(0,${RADAR_HEADER_HEIGHT + mainHeight + keyHeight})`
        );
    }
  }, []);

  // setup the base layer and background, only changes when palette altered
  useEffect(() => {
    const svg = select(ref.current);

    /* Update backing rect */
    const backgroundRect = svg.select(
      `.${RADAR_CHART_CLASS_NAMES.BACKGROUND_RECT}`
    );
    backgroundRect.attr('fill', visPalette.background.main);

    /* Update rings 
    Note: these are in a separate layer to main, as the setupRadar() function
      will kill off any <g> in the <g class='MAIN'> element
    */
    const ringsG = svg.select(`.${RADAR_CHART_CLASS_NAMES.RINGS}`);
    ringsG.selectAll('circle').remove();
    // DRAW THE BACKGROUND RINGS
    drawRings(ringsG, visPalette);
  }, [paletteOverride, isDark]);

  // setup the viewbox and margins; change based on key display
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    if (showHeaderAndFooter) {
      const footerG = svg.select(`.${RADAR_CHART_CLASS_NAMES.FOOTER}`);
      footerG.attr(
        'transform',
        `translate(0,${RADAR_HEADER_HEIGHT + mainHeight + keyHeight})`
      );
    }
  }, [keyInfo, simpleKey]);

  // draw the radar shapes
  useEffect(() => {
    const svg = select(ref.current);

    if (templateConfig?.length > 0) {
      const axesCount = templateConfig.length;
      const axesConfig = templateConfig.map((t) => addAxisInfo(t, axesCount));

      // Get the shapes based on metrics
      const path = createRadarPath(
        axesConfig,
        radarStatDatum?.plays === 0 ? null : radarStatDatum
      );
      const pathAlt =
        radarComparisonDatum &&
        createRadarPath(axesConfig, radarComparisonDatum);

      // Then turn the shapes into clip-paths
      addClipPaths(svg, radarId, path, pathAlt);

      const displayG = svg.select(`.${RADAR_CHART_CLASS_NAMES.MAIN}`);
      const drawComparisonMode = radarComparisonDatum
        ? RADAR_CHART_DRAW_COMPARISON.BOTH
        : RADAR_CHART_DRAW_COMPARISON.MAIN;
      setupRadar(
        displayG,
        visPalette,
        axesConfig,
        radarId,
        drawComparisonMode,
        primaryColor,
        secondaryColor,
        drawAxes,
        axisLabelFontSize
      );

      const keyG = svg.select(`.${RADAR_CHART_CLASS_NAMES.KEY}`);
      keyG.selectAll('g').remove();
      if (keyInfo) {
        drawKey(
          keyG,
          mainWidth,
          visPalette,
          axesConfig,
          radarId,
          drawComparisonMode,
          primaryColor,
          secondaryColor,
          keyInfo
        );
      }
      if (simpleKey) {
        drawSimpleKey(
          keyG,
          visPalette,
          primaryColor,
          secondaryColor,
          fontScaler,
          subjectLabel
        );
      }
    }

    if (showHeaderAndFooter) {
      /* Header */
      const headerG = svg.select(`.${RADAR_CHART_CLASS_NAMES.HEADER}`);
      addRadarHeader(headerG, visPalette, headerInfo, radarId, mainWidth);
      /* Footer */
      const footerG = svg.select(`.${RADAR_CHART_CLASS_NAMES.FOOTER}`);
      footerG.selectAll('g').remove();
      const logoHeight = LOGO_DEFAULT_HEIGHT;
      const logoWidth = logoHeight * LOGO_WIDTH_HEIGHT_RATIO;
      const logoG = footerG
        .append('g')
        .attr(
          'transform',
          `translate(${mainWidth - logoWidth},${RADAR_FOOTER_LOGO_OFFSET})`
        );
      logoFooter(logoG, logoWidth);
    }
  }, [
    templateConfig,
    radarStatDatum,
    radarComparisonDatum,
    paletteOverride,
    keyInfo,
    simpleKey,
    isDark,
  ]);

  return <svg ref={ref} data-testid={radarId} />;
};

RadarChart.propTypes = {
  /* The template config is an array of objects akin to this example ~
  Each represents one "spoke" or axis of the radar */
  templateConfig: radarTemplatePropTypes.isRequired,
  // the object changes with the template. The properties are the selected metrics
  // eslint-disable-next-line react/forbid-prop-types
  radarStatDatum: PropTypes.object,
  // the comparison if not shown will be FALSE else it'll be the same shape as the primary stat datum
  // eslint-disable-next-line react/forbid-prop-types
  radarComparisonDatum: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  radarId: PropTypes.string,
  // For exporting -> add header/footer, overwrite normal width and palette
  fixedWidth: PropTypes.string,
  // Force dark/light mode rather than pulling from reactive var
  paletteOverride: PropTypes.string,
  // When provided, details the player/team info for an export header
  headerInfo: headerInfoPropTypes,
  // TODO: PropTYpes up the marginOverride Object
  // eslint-disable-next-line react/forbid-prop-types
  marginOverride: PropTypes.object,
  // team colors (optional), used when present
  teamColors: teamColorPropTypes,
  // showKey: whether or not to display a legend explaining the colors
  keyInfo: radarKeyPropTypes,
  // fontScaler:
  fontScaler: PropTypes.number,
  // simpleKey: whether or not to display a small legend explaining the colors
  simpleKey: PropTypes.bool,
  // simpleKey: subject label prop drilling
  subjectLabel: PropTypes.string,
  // drawAxes: whether or not to draw the axes drills to setupRadar in RadarChart.drawing
  drawAxes: PropTypes.bool,
};

RadarChart.defaultProps = {
  /* The stat datum should be an object with a set of keys that match the keys from each object in templateConfig
  The keys depend on the API call to template config so cannot be prop spec'd
  */
  radarStatDatum: undefined,
  radarComparisonDatum: false,
  /* When multiple radars are rendered on one page, the ids must differ else the clip-paths of the last rendered
  will be applied to all of them */
  radarId: 'radar-123',
  fixedWidth: null,
  paletteOverride: null,
  headerInfo: null,
  marginOverride: null,
  teamColors: null,
  keyInfo: null,
  fontScaler: null,
  simpleKey: false,
  subjectLabel: 'Player',
  drawAxes: true,
};

export default RadarChart;
