import { gql } from '@apollo/client';

export const GET_PLAY_EVENTS = gql`
  query GetPlayEvents($playId: ID!) {
    pageFilterPlay @client @export(as: "playId")

    playEvents(playId: $playId) {
      id
      x
      y
      eventGameIndex
      name
      types
      quarter
      videoTimestamp
      freezeFrames {
        playerIndex
        ballLocation
        x
        y
        player {
          id
          name
          jerseyNumber

          teamPosition {
            id
            name
            code
            generalPosition
          }
        }
        team {
          id
          name
        }
      }
      game {
        id
        name
        date
        homeTeam {
          name
        }
        awayTeam {
          name
        }
      }
      play {
        id
        name
        down
        quarter
        quarterClockRemaining
        success
        shotgun
        driveIndex
        yardsToGo
        yardsNet
        type
        touchdownWon
        yardLine
        offensePenaltyAccepted
        defensePenaltyAccepted
        drive {
          gameIndex
          startingYards
          yardsNet
          name
        }
        offenseTeam {
          id
          name
        }
        defenseTeam {
          id
          name
        }
      }
      player {
        id
      }
      team {
        id
      }
    }
  }
`;
