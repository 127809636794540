import { isEmpty } from 'lodash';
import {
  VALIDATOR_MESSAGES,
  VALID_TEMPLATE_NAME_LENGTH,
} from '../ScoutResults.constants';

export const isTemplateNameGood = (
  templateName,
  savedTemplates,
  templateId
) => {
  /* has a name */
  if (!templateName || templateName?.length < VALID_TEMPLATE_NAME_LENGTH.MIN) {
    return {
      valid: false,
      message: VALIDATOR_MESSAGES.TOO_SHORT,
    };
  }

  /* No stupid names */
  if (templateName.length > VALID_TEMPLATE_NAME_LENGTH.MAX) {
    return {
      valid: false,
      message: VALIDATOR_MESSAGES.TOO_LONG,
    };
  }

  /* 
  Only valid characters 
  Currently not very accent friendly but clamping hard for safety
  */
  const alphaNumSpaceUnderscoreRegEx = /^[A-Za-z0-9 _]*$/;
  if (!templateName.match(alphaNumSpaceUnderscoreRegEx)) {
    return {
      valid: false,
      message: VALIDATOR_MESSAGES.BAD_CHARACTERS,
    };
  }

  /* Name is unique */
  const otherTemplates = isEmpty(savedTemplates)
    ? []
    : savedTemplates.filter((t) => t.id !== templateId);
  const otherNames = otherTemplates.map((t) => t.name);
  if (otherNames?.includes(templateName)) {
    return {
      valid: false,
      message: VALIDATOR_MESSAGES.NOT_UNIQUE,
    };
  }

  return {
    valid: true,
    message: null,
  };
};
