import { styled } from 'styled-components';

export const TeamDepthChartPage = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const PersonnelDistributionHeader = styled.div`
  display: flex;
  flex-direction: column;

  .details {
    display: flex;
    justify-content: end;
    align-items: center;

    .total-plays {
      color: ${({ theme }) => theme.colours.ink.primary.main};
      ${({ theme }) => theme.typography.bodyMedium}
    }

    .team {
      color: ${({ theme }) => theme.colours.ink.primary.main};
      text-align: end;
      ${({ theme }) => theme.typography.headingSmall}
    }

    .indicator {
      width: 2px;
      margin-right: 0.5rem;
      height: 100%;
      background-color: ${({ theme }) =>
        theme.colours.visualisations.objects.n2.main};
    }

    .league {
      height: 1rem;
      display: flex;
      justify-content: end;
      color: ${({ theme }) =>
        theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
      ${({ theme }) => theme.typography.bodySmall}
    }
  }
`;

export const DepthChartHeader = styled.div`
  width: 100%;

  .controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media screen and (max-width: 815px) {
      flex-direction: column;
      align-items: start;
    }

    .dropdown {
      width: 16rem;
    }

    .divider {
      margin: 0 0.5rem;
      width: 0.0625rem;
      height: 1.5rem;
      background-color: ${({ theme }) => theme.colours.canvas.secondary.main};

      @media screen and (max-width: 815px) {
        display: none;
      }
    }

    // make the dropdown menu appear above the table headers
    [class*='-menu'] {
      z-index: 11;
    }
  }
`;

export const DepthChartTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;

  h2 {
    font-family: Inter;
    font-size: 14.3px;
    font-weight: 700;
    margin: 0;
  }
`;

const getRowsHeight = ({ $numberOfTables }) => {
  if ($numberOfTables <= 3) {
    return `
      grid-auto-rows: auto;

      @media screen and (max-width: 1450px) {
        grid-template-rows: 1fr 1fr;
      }

      @media screen and (max-width: 1150px) {
        grid-template-rows: 1fr 1fr 1fr;
      }
    `;
  }
  return `grid-auto-rows: 19.4rem;`;
};

export const DepthChartTablesContainer = styled.div`
  position: relative;
  height: calc(100vh - 22rem);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${getRowsHeight}
  gap: 0.5rem;
  padding: 0 1rem 1rem 1rem;

  @media screen and (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

export const PersonnelDistribution = styled.div`
  position: relative;
  height: calc(100% - 6.25rem);
  border-top: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  padding: 0.625rem;
`;

export const PersonnelDistributionBars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ScaleIndicator = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colours.canvas.primary.main};
  border-top: none;
  height: 0.25rem;
  width: calc(100% - 8.5rem);
  margin: 1rem 0 0.5rem 3rem;
  color: ${({ theme }) =>
    theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  ${({ theme }) => theme.typography.bodySmall};

  ::before {
    position: absolute;
    top: -1rem;
    left: -0.5rem;
    content: '0%';
  }

  ::after {
    position: absolute;
    top: -1rem;
    right: -0.625rem;
    content: attr(data-max);
  }
`;

TeamDepthChartPage.displayName = 'TeamDepthChartPage';
DepthChartHeader.displayName = 'DepthChartHeader';
DepthChartTitle.displayName = 'DepthChartTitle';
DepthChartTablesContainer.displayName = 'DepthChartTablesContainer';
PersonnelDistributionHeader.displayName = 'PersonnelDistributionHeader';
PersonnelDistribution.displayName = 'PersonnelDistribution';
ScaleIndicator.displayName = 'ScaleIndicator';
PersonnelDistributionBars.displayName = 'PersonnelDistributionBars';
