import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const getPlayerStats = (category) => {
  const cat1StatNames = category?.map((m) => m.name) || [];
  const joinedStatNames = cat1StatNames.join('\n');
  return `
    query GetPlayersStats(
        $competitionId: Int!
        $seasonId: Int!
        $aggMode: PlayerStatAggregate!
        $groupByMode: [PlayerStatGroupBy!]
        $orderBy: [StatOrderBy!]
        $playerId: Int!
        ${getContextQueryVariables()}
      ) {
        selectedCompetitionId @client @export(as: "competitionId")
        selectedSeasonId @client @export(as: "seasonId")
        selectedPlayerId @client @export(as: "playerId")
        ${getContextFilterClientExports()}
        playerStats(
          playFilters: {
            competitionId: $competitionId
            seasonId: $seasonId
            ${getContextFiltersQueryArguments()}
          }
          playerStatFilters: {
            playerIds: [$playerId]
          }
          groupBy: $groupByMode
          aggregateMode: $aggMode
          orderBy: $orderBy
        ) {
          items {
            groups {
              key
              value
            }
            plays
            gameName
            gameDate
            ${joinedStatNames}
          }
        }
      }
  `;
};
