export const RUN_TENDENCIES_TILE_OPTIONS = [
  {
    label: 'Run Gaps',
    value: true,
    variant: 'Analysis',
  },
  {
    label: 'Run Point of Attack',
    value: false,
    variant: 'ViolinPlot',
  },
];
