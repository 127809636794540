import {
  API_ALIGNMENT_POSITION_KEYS,
  API_OL_GAPS,
  API_STAT_UNITS,
} from '../../../../utils/constants/api';
import { ALIGNMENT_POSITIONS } from '../../../../utils/constants/positions';
import {
  csDivergent,
  csIntensity,
  csValue,
} from '../../../../utils/helpers/colorScales';
import { CHART_LAYOUT } from '../../../../visualisations/BasicChart/BasicChart.constants';

export const RUN_TENDENCY_POSITION_KEYS = {
  leftTackle: 'leftTackle',
  leftGuard: 'leftGuard',
  center: 'center',
  rightGuard: 'rightGuard',
  rightTackle: 'rightTackle',
  ballCarrier: 'ballCarrier',
};

export const SELECTED_PLAYERS = {
  [RUN_TENDENCY_POSITION_KEYS.leftTackle]: 0,
  [RUN_TENDENCY_POSITION_KEYS.leftGuard]: 0,
  [RUN_TENDENCY_POSITION_KEYS.center]: 0,
  [RUN_TENDENCY_POSITION_KEYS.rightGuard]: 0,
  [RUN_TENDENCY_POSITION_KEYS.rightTackle]: 0,
  [RUN_TENDENCY_POSITION_KEYS.ballCarrier]: 0,
};

export const RUN_POSITION_NAMES = {
  [RUN_TENDENCY_POSITION_KEYS.leftTackle]:
    ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE].name,
  [RUN_TENDENCY_POSITION_KEYS.leftGuard]:
    ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD].name,
  [RUN_TENDENCY_POSITION_KEYS.center]:
    ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.CENTER].name,
  [RUN_TENDENCY_POSITION_KEYS.rightGuard]:
    ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD].name,
  [RUN_TENDENCY_POSITION_KEYS.rightTackle]:
    ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE].name,
  [RUN_TENDENCY_POSITION_KEYS.ballCarrier]: 'Ball Carrier',
};

/* 
Has nice names suitable for use in charts etc. 
D values set to invisible until TE logic added to chart
*/
export const RUN_TENDENCY_GAPS = [
  { name: 'Left D', visible: false, apiGaps: [API_OL_GAPS.LEFT_D] },
  {
    name: 'Left C',
    visible: true,
    apiGaps: [API_OL_GAPS.LEFT_C_CLOSED, API_OL_GAPS.LEFT_C_OPEN],
  },
  { name: 'Left B', visible: true, apiGaps: [API_OL_GAPS.LEFT_B] },
  { name: 'Left A', visible: true, apiGaps: [API_OL_GAPS.LEFT_A] },
  { name: 'Right A', visible: true, apiGaps: [API_OL_GAPS.RIGHT_A] },
  { name: 'Right B', visible: true, apiGaps: [API_OL_GAPS.RIGHT_B] },
  {
    name: 'Right C',
    visible: true,
    apiGaps: [API_OL_GAPS.RIGHT_C_OPEN, API_OL_GAPS.RIGHT_C_CLOSED],
  },
  { name: 'Right D', visible: false, apiGaps: [API_OL_GAPS.RIGHT_D] },
];

/* 
In theory the point of Attack can be anything up to ~34yds (NCAA hash snap -> opposing boundary) 
For consistency, ditch the data out of range
*/
export const POINT_OF_ATTACK_DOMAIN = [-10, 10];

/* 
Possible axes choices, for both Gap and POA 
id is the attribute names produced in RunTendencies.dataManipulation/getRunAggregates
objects named after Id so can index in easily
*/
export const RUN_TENDENCY_AXES = {
  plays: {
    value: 'plays',
    label: 'Play Count',
    gap: { DOMAIN: [0, 40], NICE: true, TICKS: 9, INVERT: true },
    poa: { DOMAIN: [0, 20], NICE: true, TICKS: 5, INVERT: true },
    colorFunction: csIntensity,
    unitType: API_STAT_UNITS.COUNT,
  },
  playPercentage: {
    value: 'playPercentage',
    label: 'Play Percentage',
    gap: { DOMAIN: [0, 0.2], NICE: false, TICKS: 5, INVERT: true },
    poa: { DOMAIN: [0, 0.1], NICE: true, TICKS: 5, INVERT: true },
    colorFunction: csIntensity,
    unitType: API_STAT_UNITS.PERCENTAGE,
  },
  yardsGained: {
    value: 'yardsGained',
    label: 'Yards Gained',
    gap: { DOMAIN: [0, 12], NICE: true, TICKS: 7, INVERT: true },
    poa: { DOMAIN: [0, 20], NICE: true, TICKS: 5, INVERT: true },
    colorFunction: csValue,
    unitType: API_STAT_UNITS.YARDS,
  },
  successRate: {
    value: 'successRate',
    label: 'Success Rate',
    gap: { DOMAIN: [0.3, 0.8], NICE: true, TICKS: 6, INVERT: true },
    poa: { DOMAIN: [0, 1], NICE: true, TICKS: 5, INVERT: true },
    colorFunction: csValue,
    unitType: API_STAT_UNITS.PERCENTAGE,
  },
  yardsGainedOverAverage: {
    value: 'yardsGainedOverAverage',
    label: 'Yards Gained over Average',
    gap: { DOMAIN: [-4, 4], NICE: true, TICKS: 9, INVERT: true },
    poa: { DOMAIN: [-10, 10], NICE: true, TICKS: 6, INVERT: true, Y_ORIGIN: 0 },
    colorFunction: csDivergent,
    unitType: API_STAT_UNITS.YARDS,
  },
  successVsAverage: {
    value: 'successVsAverage',
    label: 'Success Vs League Average',
    gap: { DOMAIN: [-0.5, 0.5], NICE: false, TICKS: null, INVERT: true },
    poa: { DOMAIN: [-1, 1], NICE: true, TICKS: 9, INVERT: true, Y_ORIGIN: 0 },
    colorFunction: csDivergent,
    unitType: API_STAT_UNITS.PERCENTAGE,
  },
};
export const RUN_TENDENCY_AXES_OPTIONS = Object.values(RUN_TENDENCY_AXES).map(
  (opt) => ({ value: opt.value, label: opt.label })
);

export const RUN_TENDENCY_SIDEBAR_WIDTH = {
  MAIN: 300,
  PADDING: 32,
};

export const RUN_TENDENCY_AVERAGE_LINE_SETUP = {
  TEAM: {
    COLOR: (visPalette) => visPalette.objects.n1.main,
    STROKE_DASH: '5 2',
    STROKE_WIDTH: 2,
  },
  LEAGUE: {
    COLOR: (visPalette) => visPalette.objects.n2.main,
    STROKE_DASH: '3 4',
    STROKE_WIDTH: 2,
  },
};

export const RUN_CHARTS_WIDTH =
  CHART_LAYOUT.CANVAS.WIDTH +
  CHART_LAYOUT.AXES.AREA.left +
  CHART_LAYOUT.AXES.AREA.right +
  CHART_LAYOUT.AXES.PADDING.left +
  CHART_LAYOUT.AXES.PADDING.right;

export const RUN_TENDENCY_BUBBLE_SIZES = {
  minRadius: 4,
  maxRadius: 20,
};
