import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@statsbomb/kitbag-components';
import {
  WizardTab,
  WizardTabBody,
  WizardTabHeader,
  WizardSelected,
} from './WizardAccordion.styles';

const WizardAccordion = ({ title, children, selectedOption, tabState }) => {
  const [isTabOpen, setIsTabOpen] = useState(tabState);
  return (
    <WizardTab>
      <WizardTabHeader
        onClick={() => setIsTabOpen(!isTabOpen)}
        style={{
          borderRadius: isTabOpen ? '0.4rem 0.4rem 0 0' : '0.4rem',
          borderWidth: isTabOpen ? '0 0 1px 0' : 0,
        }}
        selected={selectedOption?.label.length > 1}
      >
        <span>{title}</span>
        <span className={isTabOpen ? 'open' : 'close'}>
          <Icon variant="ChevronDown" size="small" />
        </span>
        <WizardSelected>{selectedOption?.label}</WizardSelected>
      </WizardTabHeader>
      <WizardTabBody $isExpanded={isTabOpen}>
        <div>{children}</div>
      </WizardTabBody>
    </WizardTab>
  );
};

WizardAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  tabState: PropTypes.bool,
};

WizardAccordion.defaultProps = {
  selectedOption: null,
  tabState: false,
};

export default WizardAccordion;
