import React from 'react';
import PropTypes from 'prop-types';
import {
  OffensiveLinePositionSpacer,
  OffensivePlayerFiltersRow,
} from './offensePlayerFilters.styles';
import { PlayerDropdown } from './PlayerDropdown';
import { RUN_TENDENCY_POSITION_KEYS } from '../RunTendencies.constants';
import {
  playersListsShape,
  selectedPlayersShape,
} from '../RunTendencies.propTypes';

const FilterRow = function ({
  playersLists,
  selectedPlayers,
  setSelectedPlayers,
  isOffensiveLine,
}) {
  const olFilters = (
    <>
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.leftTackle}
      />
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.leftGuard}
      />
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.center}
      />
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.rightGuard}
      />
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.rightTackle}
      />
    </>
  );
  const bcFilters = (
    <>
      <OffensiveLinePositionSpacer>&nbsp;</OffensiveLinePositionSpacer>
      <PlayerDropdown
        playersLists={playersLists}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        positionKey={RUN_TENDENCY_POSITION_KEYS.ballCarrier}
      />
    </>
  );
  const zIndex = isOffensiveLine ? 4 : 3;
  return (
    <OffensivePlayerFiltersRow style={{ zIndex }}>
      {isOffensiveLine ? olFilters : bcFilters}
    </OffensivePlayerFiltersRow>
  );
};

FilterRow.propTypes = {
  playersLists: playersListsShape.isRequired,
  selectedPlayers: selectedPlayersShape.isRequired,
  setSelectedPlayers: PropTypes.func.isRequired,
  isOffensiveLine: PropTypes.bool,
};

FilterRow.defaultProps = {
  isOffensiveLine: true,
};

export { FilterRow };
