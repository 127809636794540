import { interpolatePlasma, interpolateMagma, scaleLinear } from 'd3';
import { clamp, sortBy } from 'lodash';
import { getPalette } from '../visualisations/visPalettes';

/** ************************
 * COLOR SCALES USAGE
 * Color scales are used when a continual value needs a corresponding color from a scale
 *
 * There are 3 Color Scales currently for usage in AMF IQ:
 *
 * value scale - for areas/objects/text colored on a scale valued bad->good
 * heatmap scale - for tiles/areas colored in a gradiated way
 * intensity scale - for objects/text colored on a scale, but not valued bad->good
 *        - i.e. pass length isn't good/bad, but variable
 *
 * read more on scale definitions:
 * https://www.notion.so/statsbomb/Visualisation-Colour-Palettes-bd089e6840324e3290b3fa7a5765f334
 * ************************
 * */

/**
 * Converts a fractional value to a colour
 * Used for heatmaps / solid gradient objects displaying the colouring
 * Crops the full range to the lighter 90% to work on dark backgrounds
 * @param {number} value
 */
const csHeatmap = function (value, darkMode = true) {
  const v = clamp(value, 0, 1);
  const visPalette = getPalette(darkMode);
  /* Want the array [0, 0.05, 0.1, 0.15 ... 0.95, 1], 21 values total */
  const domainSteps = [...Array(21).keys()].map((m) => m / 20);

  /* 
  The heatmap scales use a portion of the magma scale, then extended into the palette background
  
  Light mode uses the lighter 80% of the magma scale, dark mode the lighter 90%
  
  Light mode: The [top of magma scale]->[white] should correspond to the bottom 15% of the heatmap scale
  Dark mode: The [bottom of magma scale]->[off-black] should correspond to the bottom 5% of the heatmap scale
  
  This makes the gradient of the combined bar approximately linear in speed of color change

  /*
  Thus we need to: 
    transpose Magma values used (0.1->1 or 0.2->1) onto the relevant range (0.05 -> 1 or 0.15->1)
    add a linear gradient from background for values 0->domainMin
  */
  const domainMin = darkMode ? 0.05 : 0.15; // how much of the resultant domain should be gradient from white
  const magmaIgnoring = darkMode ? 0.1 : 0.2; // ignoring the darkest 20%
  const magmaUsing = 1 - magmaIgnoring;
  const magmaDomain = domainSteps.filter((f) => f >= domainMin); // of the final scale, the parts coming from magma
  const l = magmaDomain.length - 1;

  /* Final output domain is the palette background at 0 + filtered domain */
  const heatmapDomain = [0].concat(magmaDomain);
  let rangeColors;
  if (darkMode) {
    /* Get the colors from sample section, re-spread to target range */
    const magmaRangeIndexes = [...Array(magmaDomain.length).keys()].map(
      (m) => (m / l) * magmaUsing + magmaIgnoring
    );
    const magmaRange = magmaRangeIndexes.map(interpolateMagma);
    rangeColors = [visPalette.background.main].concat(magmaRange);
  } else {
    /* For light mode need to flip direction of magma
    then re-scale (i.e. spread what was 80% of the scale to fit the 85% it's now representing) */
    const magmaRangeIndexes = [...Array(magmaDomain.length).keys()].map(
      (m) => ((l - m) / l) * magmaUsing + magmaIgnoring
    );
    const magmaRange = magmaRangeIndexes.map(interpolateMagma);
    rangeColors = [visPalette.background.main].concat(magmaRange);
  }
  const adjustedHeatmapScale = scaleLinear()
    .domain(heatmapDomain)
    .range(rangeColors);

  return adjustedHeatmapScale(v);
};

/**
 * Converts a fractional value to a colour
 * Used for discreet objects (circles/hexes/lines/text etc.)
 * The variable should not be valued ~ (i.e. one colour is not inherently "good" or "bad")
 * Crops the top 10% as the palest yellows don't work on pale backgrounds
 * @param {number} value
 */
const csIntensity = function (value) {
  const v = clamp(value, 0, 1);
  const FRACTION_TO_CROP = 0.1;
  const FRACTION_REMAINING = 1 - FRACTION_TO_CROP;
  return interpolatePlasma(v * FRACTION_REMAINING);
};

const INTENSITY_SCALE_BLUE_ENDS = [
  { percentile: 0, color: '#002d82' }, // deep blue
  { percentile: 1, color: '#8cd2e5' }, // pale blue
];
/**
 * Converts a fractional value to a colour
 * Used for cells in tables (assumes table cell background to match theme background)
 * The variable should not be valued ~ (i.e. one colour is not inherently "good" or "bad")
 * Designed to be sat in a table along with csValue (poseidon) scale
 * @param {number} value
 */
export const csIntensityBlue = function (value, darkMode = true) {
  const v = clamp(value, 0, 1);
  const midpoint = {
    percentile: 0.5,
    color: getPalette(darkMode).background.main,
  };
  const mergedScaleColors = sortBy(
    INTENSITY_SCALE_BLUE_ENDS.concat(midpoint),
    'percentile'
  );
  const domain = mergedScaleColors.map((colorStop) => colorStop.percentile);
  const range = mergedScaleColors.map((colorStop) => colorStop.color);
  const getIntensityColour = scaleLinear().domain(domain).range(range);
  return getIntensityColour(v);
};

// Valued Continuous Scale for AMF:
// https://www.notion.so/statsbomb/Poseidon-Scale-ab01c26cfac84ca3b6237cdf12211cd3
// values are named p0 -> p100 (i.e. percentile)
const VALUED_CONTINUOUS_SCALE_COLORS_DARK = [
  { percentile: 0, color: '#94009f' },
  { percentile: 0.5, color: '#6385b3' },
  { percentile: 1, color: '#82ffc3' },
];
const VALUED_CONTINUOUS_SCALE_COLORS_LIGHT = [
  { percentile: 0, color: '#5f0065' },
  { percentile: 0.5, color: '#6383b2' },
  { percentile: 1, color: '#75f5b7' },
];
/**
 * Converts a fractional value to a colour as part of the value Statsbomb Spectrum
 * Used for discreet objects (circles/hexes/lines etc.) being coloured to denote a continual variable
 * The variable should be valued: the nearer the fraction is to 1 the better
 * @param {number} value
 */
const csValue = function (value, darkMode = true) {
  const v = clamp(value, 0, 1);
  const scaleSource = darkMode
    ? VALUED_CONTINUOUS_SCALE_COLORS_DARK
    : VALUED_CONTINUOUS_SCALE_COLORS_LIGHT;
  const domain = scaleSource.map((colorStop) => colorStop.percentile);
  const range = scaleSource.map((colorStop) => colorStop.color);
  const getVColour = scaleLinear().domain(domain).range(range);
  return getVColour(v);
};

/**
 * Converts a fractional value to a colour
 * Used for blocks of color (bars, or heatmap sections)
 * The values should be divergent from a central value, i.e.
 *  0 is far from the average (negatively)
 *  0.5 the average value
 *  1 is far from the average (positively)
 * https://www.notion.so/statsbomb/The-Vs-Grey-Scale-6112e773c6824f379add5e3797eba9be
 * @param {number} value
 */
const csDivergent = function (value, darkMode = true) {
  const v = clamp(value, 0, 1);
  const domainVCC = [
    0, 0.1, 0.2, 0.3, 0.4, 0.45, 0.5, 0.55, 0.6, 0.7, 0.8, 0.9, 1,
  ];
  /* Note the blues change but the reds don't */
  const rangeVCC = darkMode
    ? [
        '#1E298B',
        '#444d9c',
        '#636baa',
        '#8288b7',
        '#a1a5c5',
        '#bfc2d2',
        '#dedfe0',
        '#f2baad',
        '#f7a18e',
        '#f17b67',
        '#e84335',
        '#e11f19',
        '#b40b0e',
      ]
    : [
        '#171832',
        '#323a60',
        '#45537f',
        '#7183a3',
        '#b5c6d5',
        '#d3dee4',
        '#dedfe0',
        '#f2baad',
        '#f7a18e',
        '#f17b67',
        '#e84335',
        '#e11f19',
        '#b40b0e',
      ];
  const getVColour = scaleLinear().domain(domainVCC).range(rangeVCC);
  return getVColour(v);
};

export { csHeatmap, csIntensity, csValue, csDivergent };
