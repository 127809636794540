import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { drop, isEmpty, take, uniq } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { Pagination, TextField } from '@statsbomb/kitbag-components';
import { useReactiveVar } from '@apollo/client';
import { useGetPlayerScout } from './useGetPlayerScout';
import {
  API_ROSTER_POSITION_KEYS,
  API_STAT_UNITS,
} from '../../../../utils/constants/api';
import { useGetPlayerStatsCategoryDefinitions } from '../../../../utils/hooks/useGetPlayerStatCategories';
import { useGetPlayerStatDefinitions } from '../../../../utils/hooks/useGetPlayerStatDefinitions';
import {
  getCategories,
  getStatHeaders,
  formatScoutSortableTableRows,
  formatPlayersCSV,
  sortPlayers,
  getBiometricHeaders,
} from './ScoutPlayers.dataManipulation';
import {
  BASE_HEADERS,
  BIO_CATEGORY,
  PLAYS_STAT_KEY,
  SCOUT_PLAYERS_PER_PAGE,
  SCOUT_PLAYERS_STICKY_COLUMNS,
} from './ScoutPlayers.constants';
import {
  DownloadButtonWrapper,
  ScoutSearch,
  TableWrapper,
} from './ScoutPlayers.styles';
import StatCategoryTabs from './StatCategoryTabs/StatCategoryTabs';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import {
  SORT_DIRECTIONS,
  SORT_ICONS,
} from '../../../../utils/constants/sortDirections';
import SortableTable from '../../../../components/Tables/SortableTable/SortableTable';
import DownloadCSVButton from '../../../../components/buttons/DownloadCSV';
import { ROSTER_POSITIONS } from '../../../../utils/constants/positions';
import { mf_Leagues, mf_Seasons } from '../../../../apollo';
import { biometricProp, playerMetricProp } from '../../Scout.proptypes';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;

const ScoutPlayers = ({
  selectedStats,
  rosterPositions,
  primaryPosition,
  selectedBiometrics,
  metricSelected,
}) => {
  const [searchValue, setSearchValue] = useState('');
  /* Tab State */
  const [selectedCategoryName, setSelectedCategoryName] = useState(
    BIO_CATEGORY.name
  );
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState(
    BIO_CATEGORY.subcategories[0].name
  );

  const { categoryDefinitions: apiStatCategories } =
    useGetPlayerStatsCategoryDefinitions();
  const { statDefinitions } = useGetPlayerStatDefinitions();

  const statCategories = getCategories(apiStatCategories);
  const selectedStatCategory = statCategories.find(
    (s) => s.name === selectedCategoryName
  );
  const selectedStatSubCategory = selectedStatCategory.subcategories.find(
    (s) => s.name === selectedSubCategoryName
  );
  const filterStatKeys = isEmpty(selectedStats)
    ? []
    : selectedStats.map((s) => s.key);
  const columnStatKeys = selectedStatSubCategory.stats;
  const queryStatKeys = uniq(
    columnStatKeys.concat(PLAYS_STAT_KEY).concat(filterStatKeys)
  );
  const bioStatKeys = isEmpty(selectedBiometrics)
    ? []
    : selectedBiometrics.map((s) => s.key);

  const selectedBiometricsIncludingName = [
    ...selectedBiometrics,
    { key: 'playerName', value: searchValue },
  ];

  /* Core Query: get players */
  const { players, isLoading, error } = useGetPlayerScout(
    selectedStats,
    rosterPositions,
    queryStatKeys,
    selectedBiometricsIncludingName
  );
  const isScoutError = !!error;
  const playersCount = players?.length || 0;

  /** *
  Table State 
  ** */
  const [activePage, setActivePage] = useState(1);
  const [sortDirection, setSortDirection] = useState(ASCENDING);
  const [sortKey, setSortKey] = useState('name');
  /* If filters change, then results list changes ~ revert to page 1 */
  useEffect(() => {
    setActivePage(1);
  }, [selectedStats, rosterPositions, selectedBiometrics]);

  /* Headers */
  const filterStatHeaders = getStatHeaders(filterStatKeys, statDefinitions);
  const nonFilterColumnStatKeys = columnStatKeys.filter(
    (k) => !filterStatKeys.includes(k)
  );
  const columnStatHeaders = getStatHeaders(
    nonFilterColumnStatKeys,
    statDefinitions
  );
  const { stickyBiometricHeaders, nonStickyBiometricHeaders } =
    getBiometricHeaders(bioStatKeys);

  const stickyHeaders = BASE_HEADERS.concat(
    filterStatHeaders,
    stickyBiometricHeaders
  );
  const headers =
    selectedCategoryName === BIO_CATEGORY.name
      ? stickyHeaders.concat(nonStickyBiometricHeaders)
      : stickyHeaders.concat(columnStatHeaders);

  /* Sticky Columns (Make all filtered stats & basic info sticky) */
  const baseStickyColumnCount = SCOUT_PLAYERS_STICKY_COLUMNS.length;
  const stickyStatColumnDefs = filterStatHeaders
    .concat(stickyBiometricHeaders)
    .map((_, i) => ({
      index: baseStickyColumnCount + i + 1, // index is not 0 based because react component reasons
      width: 6, // all numerics this should be big enough
    }));
  const stickyColumns =
    SCOUT_PLAYERS_STICKY_COLUMNS.concat(stickyStatColumnDefs);

  const handleSearch = (e) => {
    if (activePage !== 1) {
      setActivePage(1);
    }
    setSearchValue(e.target.value);
  };

  /* Sorting and Paging */
  const handleSort = (statName) => {
    if (statName === sortKey) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      const sortColumn = headers.find((h) => h.key === statName);
      const defaultSortDirection =
        sortColumn?.units === API_STAT_UNITS.STRING ? ASCENDING : DESCENDING;
      setSortDirection(defaultSortDirection);
      setSortKey(statName);
    }
    setActivePage(1);
  };
  const stringHeaderIndexes = headers
    .map((h, i) => (h.units === API_STAT_UNITS.STRING ? i : null))
    .filter((m) => m !== null);
  const alignmentFunction = (columnIndex) =>
    stringHeaderIndexes.includes(columnIndex) ? 'left' : 'right';
  /* Sortable headers have an exacting prop type */
  const sortableTableHeaders = headers.map((header) => {
    const formattedHeader = {
      id: header.key,
      key: header.key,
      label: header.label,
      isSortable: true,
      description: header.description,
      width: header.width,
    };
    // for metric columns not already filters
    if (nonFilterColumnStatKeys.includes(header.key)) {
      const curSort =
        sortKey === header.key ? sortDirection : SORT_DIRECTIONS.DEFAULT;
      const buttons = [
        {
          icon: SORT_ICONS[curSort],
          title: `Sort table by ${header.label}`,
          clickHandler: handleSort,
        },
        {
          icon: 'Filter',
          title: `Add ${header.label} as a metric filter`,
          clickHandler: metricSelected,
        },
      ];
      formattedHeader.buttons = buttons;
    }

    return formattedHeader;
  });

  const playersSkipped = (activePage - 1) * SCOUT_PLAYERS_PER_PAGE;
  const sortedPlayers = sortPlayers(players, sortKey, sortDirection);
  const visiblePlayers = take(
    drop(sortedPlayers, playersSkipped),
    SCOUT_PLAYERS_PER_PAGE
  );

  const leagueId = useReactiveVar(mf_Leagues) || 1409;
  const seasonId = useReactiveVar(mf_Seasons) || 1000;
  const tableData = formatScoutSortableTableRows(
    visiblePlayers,
    headers,
    leagueId,
    seasonId
  );

  /* CSV Export */
  const csvData = formatPlayersCSV(sortedPlayers, headers);
  const seasonName = players?.length > 0 ? players[0]?.seasonName : '-';
  const csvName = `Scout for ${ROSTER_POSITIONS[primaryPosition].name} ${seasonName}`;

  return (
    <>
      <ScoutSearch>
        <TextField
          id="player-scout-search-filter"
          labelPosition="none"
          value={searchValue}
          onChange={handleSearch}
          adornmentIcon="Search"
          adornmentOnClick={handleSearch}
          placeholder="Search players"
          size="small"
        />
      </ScoutSearch>
      <DownloadButtonWrapper>
        {!isEmpty(csvData) && (
          <DownloadCSVButton
            data={csvData}
            headers={headers}
            fileName={csvName}
            iconSize="small"
          />
        )}
      </DownloadButtonWrapper>
      <StatCategoryTabs
        statCategories={statCategories}
        selectedCategoryName={selectedCategoryName}
        setSelectedCategoryName={setSelectedCategoryName}
        selectedSubCategoryName={selectedSubCategoryName}
        setSelectedSubCategoryName={setSelectedSubCategoryName}
      />
      <TableWrapper>
        {isLoading && (
          <Dimmer active style={{ minHeight: '30vh', maxWidth: '100%' }}>
            <Loader content="Loading Data" />
          </Dimmer>
        )}
        {!isScoutError && (
          <SortableTable
            name="scout-table"
            headers={sortableTableHeaders}
            rows={tableData}
            stickyColumns={stickyColumns}
            alignment={alignmentFunction}
            handleSort={handleSort}
            sortDirection={sortDirection}
            sortBy={sortKey}
            isHeadSticky
            nonTableHeight="238px"
            border="0"
            rowHeight="2rem"
          />
        )}
      </TableWrapper>
      <Pagination
        activePage={activePage}
        onChange={({ pageSelected }) => setActivePage(pageSelected)}
        itemsPerPage={SCOUT_PLAYERS_PER_PAGE}
        dataLength={playersCount}
        hasEdgePageButtons
      />
    </>
  );
};

ScoutPlayers.propTypes = {
  // the metrics & values to store as part of the setup
  selectedStats: PropTypes.arrayOf(playerMetricProp),
  // the roster position config to store as part of the setup
  rosterPositions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(API_ROSTER_POSITION_KEYS))
  ).isRequired,
  // the roster position config to store as part of the setup
  primaryPosition: PropTypes.oneOf(Object.values(API_ROSTER_POSITION_KEYS))
    .isRequired,
  // any biometric filter settings
  selectedBiometrics: PropTypes.arrayOf(biometricProp),
  // function that takes a stat key and uses it in add metric logic
  metricSelected: PropTypes.func.isRequired,
};

ScoutPlayers.defaultProps = {
  selectedStats: null,
  selectedBiometrics: null,
};

export default ScoutPlayers;
