import { styled } from 'styled-components';

export const PlayerBioStatsRow = styled.div`
  display: flex;
  gap: 1rem 5rem;
  flex-direction: row;
  padding: 1rem;
  flex-wrap: wrap;
  border-top: ${({ theme, $border }) =>
    `${$border || 0}px solid ${theme.colours.canvas.secondary.main}`};
`;
PlayerBioStatsRow.displayName = 'PlayerBioStatsRow';

export const PlayerBioStatWrapper = styled.div`
  color: ${({ theme }) => `${theme.colours.ink.primary.main}`};
  width: auto;

  h4 {
    ${({ theme }) => theme.typography.bodySmall}
    color: ${({ theme }) => `${theme.colours.ink.primary.main}80`};
  }
`;
PlayerBioStatWrapper.displayName = 'PlayerBioStatWrapper';

export const PlayerBioStat = styled.div`
  ${({ theme }) => theme.typography.headingRegular}

  ul {
    li {
      display: inline;
    }

    li + li {
      margin-left: 0.75rem;
    }
  }

  span.stars {
    float: left;
    margin-right: 0.5rem;
  }
`;
PlayerBioStat.displayName = 'PlayerBioStat';
