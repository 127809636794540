import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmationDialog,
  Toggle,
  ButtonIcon,
  Dropdown,
} from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import Tile from '../Tile/Tile';
import {
  ExportContainer,
  ExportSettings,
  ExportTemplateContainer,
} from './ExportModal.styles';
import {
  DOWNLOAD_FORMAT_OPTIONS,
  PALETTE_OPTIONS,
} from '../../utils/constants/exporting';
import { getExportWidth } from './ExportModal.dataManipulation';
import ExportTemplate from './ExportTemplate';
import { exportVisual } from './export';
import { X_PADDING } from './ExportModal.constants';
import { escapeDownloadString } from '../../utils/helpers/strings';

/**
 * An export modal with customization options
 * Takes an SVG and displays it within an export template
 */
const ExportModal = ({
  children,
  title,
  secondaryTitle,
  info1,
  info2,
  info3,
  fileName,
  enlarged,
  columns,
  customWidth,
  isDisabled,
}) => {
  const { isDark } = useTheme();
  const currentPalette = PALETTE_OPTIONS.find(
    (palette) => palette.value === isDark.toString()
  );

  const exportRef = useRef();
  const content = exportRef?.current?.children[1];
  const contentWidth = getExportWidth(content, columns);
  const exportWidth = enlarged ? contentWidth * 2 : contentWidth;

  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // export settings
  const [exportType, setExportType] = useState(DOWNLOAD_FORMAT_OPTIONS[0]);
  const [palette, setPalette] = useState(currentPalette);
  const [withLogo, setWithLogo] = useState(true);

  // exporting blocks the UI thread so it's important to insure that isExporting
  // is set synchronously before running the export function
  useEffect(() => {
    if (isExporting) {
      exportVisual(
        exportRef,
        exportType.value,
        escapeDownloadString(fileName)
      ).then(() => {
        setIsExporting(false);
        setIsOpen(false);
      });
    }
  }, [isExporting]);

  const isDarkExport = JSON.parse(palette.value);

  const width = (customWidth || exportWidth) + X_PADDING;
  const widthInPx = `${width}px`;

  return (
    <>
      <ButtonIcon
        onClick={() => setIsOpen(true)}
        shape="circle"
        variant="secondary"
        size="small"
        icon="Download"
        disabled={isDisabled}
        title="Export visualisation dialogue"
      >
        open export modal
      </ButtonIcon>
      <ConfirmationDialog
        id="export-visualisation-modal"
        title="Export Preview"
        isOpen={isOpen}
        cancelLabel="Close"
        confirmLabel="Export"
        onCancel={() => setIsOpen(false)}
        onConfirm={() => setIsExporting(true)}
        onEscape={() => setIsOpen(false)}
        isDisabledConfirm={isExporting}
      >
        <ExportContainer>
          <ExportTemplateContainer>
            <ExportTemplate
              ref={exportRef}
              width={widthInPx}
              title={title}
              secondaryTitle={secondaryTitle}
              info1={info1}
              info2={info2}
              info3={info3}
              withLogo={withLogo}
              isDarkExport={isDarkExport}
            >
              {children}
            </ExportTemplate>
          </ExportTemplateContainer>
          <ExportSettings>
            <Tile>
              <Tile.Header>Export Settings</Tile.Header>
              <Tile.Body>
                <div>{`Image width: ${width.toFixed()}`}</div>
                <Dropdown
                  id="image-type-dropdown"
                  options={DOWNLOAD_FORMAT_OPTIONS}
                  label="Image Type"
                  onChange={setExportType}
                  value={exportType}
                />
                <Dropdown
                  id="color-mode-dropdown"
                  options={PALETTE_OPTIONS}
                  label="Color Mode"
                  onChange={setPalette}
                  value={palette}
                />
                <Toggle
                  id="export-logo-toggle"
                  label="Logo"
                  checked={withLogo}
                  onChange={() => setWithLogo(!withLogo)}
                />
              </Tile.Body>
            </Tile>
          </ExportSettings>
        </ExportContainer>
      </ConfirmationDialog>
    </>
  );
};

ExportModal.propTypes = {
  // the visualisation to export
  children: PropTypes.node,
  // the main title of the export
  title: PropTypes.string,
  /** The secondary title of the exported image */
  secondaryTitle: PropTypes.string,
  /** extra context about the exported image */
  info1: PropTypes.string,
  /** extra context about the exported image */
  info2: PropTypes.string,
  /** extra context about the exported image */
  info3: PropTypes.string,
  /** extra context about the exported image */
  fileName: PropTypes.string,
  /** whether the viz should be double in size */
  enlarged: PropTypes.bool,
  /** number of columns the exported content contains - only relevant in multi element exports */
  columns: PropTypes.number,
  /** custom export width to override default width calculations */
  customWidth: PropTypes.number,
  /** whether the button to open the modal is disabled */
  isDisabled: PropTypes.bool,
};

ExportModal.defaultProps = {
  title: '',
  secondaryTitle: '',
  info1: '',
  info2: '',
  info3: '',
  fileName: 'Statsbomb-Export',
  children: null,
  enlarged: false,
  columns: 1,
  customWidth: 0,
  isDisabled: false,
};

export default ExportModal;
