import { flatten, sumBy } from 'lodash';
import {
  OLPRESSURE_GAP_LIMITS,
  OLPRESSURE_POSITION_LIMITS,
} from '../../../../visualisations/OLPressure/OLPressure.constants';
import { API_OL_GAPS } from './PassPressures.constants';
import { ALIGNMENT_POSITIONS } from '../../../../utils/constants/positions';
import { getRosterPositionColorByCode } from '../../../../utils/helpers/positions';

const summarizeOLTotals = function (filteredData, selectedComp) {
  if (!filteredData || filteredData.length === 0) {
    return {
      comp: selectedComp,
      plays: 0,
      lt_pressures: 0,
      lg_pressures: 0,
      c_pressures: 0,
      rg_pressures: 0,
      rt_pressures: 0,
    };
  }
  const totalPlays = sumBy(filteredData, 'plays');
  const lt_pressures = sumBy(filteredData, 'leftTacklePressures');
  const lg_pressures = sumBy(filteredData, 'leftGuardPressures');
  const c_pressures = sumBy(filteredData, 'centerPressures');
  const rg_pressures = sumBy(filteredData, 'rightGuardPressures');
  const rt_pressures = sumBy(filteredData, 'rightTacklePressures');
  return {
    comp: selectedComp,
    plays: totalPlays,
    lt_pressures,
    lg_pressures,
    c_pressures,
    rg_pressures,
    rt_pressures,
  };
};

const getPosLeagueAverages = (comp) => {
  const lt_pressures =
    OLPRESSURE_POSITION_LIMITS.find(
      (l) => l.position === 'LT' && l.comp === comp
    ).mean * 100;

  const lg_pressures =
    OLPRESSURE_POSITION_LIMITS.find(
      (l) => l.position === 'LG' && l.comp === comp
    ).mean * 100;

  const c_pressures =
    OLPRESSURE_POSITION_LIMITS.find(
      (l) => l.position === 'C' && l.comp === comp
    ).mean * 100;

  const rg_pressures =
    OLPRESSURE_POSITION_LIMITS.find(
      (l) => l.position === 'RG' && l.comp === comp
    ).mean * 100;

  const rt_pressures =
    OLPRESSURE_POSITION_LIMITS.find(
      (l) => l.position === 'RT' && l.comp === comp
    ).mean * 100;

  return {
    lt_pressures,
    lg_pressures,
    c_pressures,
    rg_pressures,
    rt_pressures,
  };
};

const gapInfo = function (allGapsData, gapName, gapNameAlt) {
  const gapData = allGapsData.filter(
    (f) =>
      f?.pressureGaps?.includes(gapName) ||
      (gapNameAlt && f?.pressureGaps?.includes(gapNameAlt))
  );
  const gapPlays = sumBy(gapData, 'plays');
  const totalPlays = sumBy(allGapsData, 'plays');
  const gapPressureRate = totalPlays !== 0 ? (gapPlays / totalPlays) * 100 : 0;
  return [gapPlays, gapPressureRate];
};

const getGapPlays = (filteredData, gapName, gapNameAlt) => {
  let uniquePlays = [];

  filteredData.forEach((item) => {
    if (
      item?.pressureGaps?.includes(gapName) ||
      (gapNameAlt && item?.pressureGaps?.includes(gapNameAlt))
    ) {
      uniquePlays = uniquePlays.concat(
        item.allPressurePlays.map((playId) => ({ playId }))
      );
    }
  });
  return uniquePlays;
};

/*
  Each row of data at this point is 
  LT/LG/C/RG/RT/[GAPS], [LT-pressure-playIds], [LG-pressure-play-ids] ...
  i.e. grouped on the combination of OL players and gaps, 
    have any number of plays, and of that list of plays, a subset of those are relevant to each position
  
  So need to go from array(rows of data), each of which can have an array of position relevant play-ids
    -> flat list of those play-ids, each linked to the relevant player
 */
const getOLPlays = (lineBattleData, positionColumn, pressureListColumn) => {
  /* Get the rows of data that contain 1+ plays relevant tot this OL Position */
  const relevantOLData = lineBattleData.filter((d) => d[pressureListColumn]);
  /* For each row ~ get back the array of plays transformed into video relevant object */
  const listOfPlayArrays = relevantOLData.map((d) =>
    d[pressureListColumn]?.map((pressurePlayId) => ({
      player: d[positionColumn],
      playId: pressurePlayId,
    }))
  );
  /* merge the list of arrays */
  const mergedList = flatten(listOfPlayArrays);
  return mergedList;
};

const getPositionPlays = function (filteredPositionData) {
  if (!filteredPositionData || filteredPositionData.length === 0) {
    return {
      rightCPressurePlays: [],
      rightTacklePressurePlays: [],
      rightBPressurePlays: [],
      rightGuardPressurePlays: [],
      rightAPressurePlays: [],
      centerPressurePlays: [],
      leftAPressurePlays: [],
      leftGuardPressurePlays: [],
      leftBPressurePlays: [],
      leftTacklePressurePlays: [],
      leftCPressurePlays: [],
    };
  }

  const rightCPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.RIGHT_C_CLOSED,
    API_OL_GAPS.RIGHT_C_OPEN
  );
  const rightTacklePressurePlays = getOLPlays(
    filteredPositionData,
    'rightTackle',
    'rightTacklePressurePlays'
  );
  const rightBPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.RIGHT_B
  );
  const rightGuardPressurePlays = getOLPlays(
    filteredPositionData,
    'rightGuard',
    'rightGuardPressurePlays'
  );
  const rightAPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.RIGHT_A
  );
  const centerPressurePlays = getOLPlays(
    filteredPositionData,
    'center',
    'centerPressurePlays'
  );
  const leftAPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.LEFT_A
  );
  const leftGuardPressurePlays = getOLPlays(
    filteredPositionData,
    'leftGuard',
    'leftGuardPressurePlays'
  );
  const leftBPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.LEFT_B
  );
  const leftTacklePressurePlays = getOLPlays(
    filteredPositionData,
    'leftTackle',
    'leftTacklePressurePlays'
  );
  const leftCPressurePlays = getGapPlays(
    filteredPositionData,
    API_OL_GAPS.LEFT_C_CLOSED,
    API_OL_GAPS.LEFT_C_OPEN
  );

  return {
    rightCPressurePlays,
    rightTacklePressurePlays,
    rightBPressurePlays,
    rightGuardPressurePlays,
    rightAPressurePlays,
    centerPressurePlays,
    leftAPressurePlays,
    leftGuardPressurePlays,
    leftBPressurePlays,
    leftTacklePressurePlays,
    leftCPressurePlays,
  };
};

const summarizeGapTotals = function (filteredGapData, selectedCompetitionId) {
  if (!filteredGapData || filteredGapData.length === 0) {
    return {
      comp: selectedCompetitionId,
      plays: 0,
      noPressurePlays: 0,
      leftAPlays: 0,
      leftAPressPerc: 0,
      leftAConversionPerc: 0,
      leftBPlays: 0,
      leftBPressPerc: 0,
      leftBConversionPerc: 0,
      leftCPlays: 0,
      leftCPressPerc: 0,
      leftCConversionPerc: 0,
      rightAPlays: 0,
      rightAPressPerc: 0,
      rightAConversionPerc: 0,
      rightBPlays: 0,
      rightBPressPerc: 0,
      rightBConversionPerc: 0,
      rightCPlays: 0,
      rightCPressPerc: 0,
      rightCConversionPerc: 0,
      overlaps: 0,
    };
  }

  const totalPlays = sumBy(filteredGapData, 'plays');

  const noPressureData = filteredGapData.filter(
    (f) => f?.pressureGaps?.length === 0
  );
  const noPressurePlays = sumBy(noPressureData, 'plays');

  const [leftAPlays, leftAPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.LEFT_A
  );
  const [leftBPlays, leftBPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.LEFT_B
  );
  const [leftCPlays, leftCPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.LEFT_C_CLOSED,
    API_OL_GAPS.LEFT_C_OPEN
  );
  const [rightAPlays, rightAPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.RIGHT_A
  );
  const [rightBPlays, rightBPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.RIGHT_B
  );
  const [rightCPlays, rightCPressPerc] = gapInfo(
    filteredGapData,
    API_OL_GAPS.RIGHT_C_CLOSED,
    API_OL_GAPS.RIGHT_C_OPEN
  );

  return {
    comp: selectedCompetitionId,
    plays: totalPlays,
    noPressurePlays,
    leftAPlays,
    leftAPressPerc,
    leftBPlays,
    leftBPressPerc,
    leftCPlays,
    leftCPressPerc,
    rightAPlays,
    rightAPressPerc,
    rightBPlays,
    rightBPressPerc,
    rightCPlays,
    rightCPressPerc,
    overlaps:
      noPressurePlays +
      leftAPlays +
      leftBPlays +
      leftCPlays +
      rightAPlays +
      rightBPlays +
      rightCPlays -
      totalPlays,
  };
};

const getGapLeagueAverages = (comp) => {
  const leftAPressPerc =
    OLPRESSURE_GAP_LIMITS.find((l) => l.gap === 'Left A Gap' && l.comp === comp)
      .mean * 100;
  const leftBPressPerc =
    OLPRESSURE_GAP_LIMITS.find((l) => l.gap === 'Left B Gap' && l.comp === comp)
      .mean * 100;
  const leftCPressPerc =
    OLPRESSURE_GAP_LIMITS.find((l) => l.gap === 'Left C Gap' && l.comp === comp)
      .mean * 100;
  const rightAPressPerc =
    OLPRESSURE_GAP_LIMITS.find(
      (l) => l.gap === 'Right A Gap' && l.comp === comp
    ).mean * 100;
  const rightBPressPerc =
    OLPRESSURE_GAP_LIMITS.find(
      (l) => l.gap === 'Right B Gap' && l.comp === comp
    ).mean * 100;
  const rightCPressPerc =
    OLPRESSURE_GAP_LIMITS.find(
      (l) => l.gap === 'Right C Gap' && l.comp === comp
    ).mean * 100;
  return {
    leftAPressPerc,
    leftBPressPerc,
    leftCPressPerc,
    rightAPressPerc,
    rightBPressPerc,
    rightCPressPerc,
  };
};

const concededName = (plays) => `Pressures conceded (${plays} plays)`;
const teamName = (plays) => `Pressure % (Whole Team, ${plays} plays)`;
const FIXED_NAMES = {
  PRESSURE: 'Pressure %',
  LEAGUE_AVERAGE: 'Pressure % (League Average)',
};
const getPositionsTableData = function (totals, teamTotals, laTotals) {
  const pressuresCountRow = {
    name: concededName(totals.plays),
    lt: totals.lt_pressures,
    lg: totals.lg_pressures,
    c: totals.c_pressures,
    rg: totals.rg_pressures,
    rt: totals.rt_pressures,
  };
  const nicePerc = function (pressures, plays) {
    if (plays === 0) {
      return '0%';
    }
    return `${((pressures / plays) * 100).toFixed(1)}%`;
  };
  const pressuresPercentageRow = {
    name: FIXED_NAMES.PRESSURE,
    lt: nicePerc(totals.lt_pressures, totals.plays),
    lg: nicePerc(totals.lg_pressures, totals.plays),
    c: nicePerc(totals.c_pressures, totals.plays),
    rg: nicePerc(totals.rg_pressures, totals.plays),
    rt: nicePerc(totals.rt_pressures, totals.plays),
  };

  const pressuresPercentageRowTeam = {
    name: teamName(teamTotals.plays),
    lt: nicePerc(teamTotals.lt_pressures, teamTotals.plays),
    lg: nicePerc(teamTotals.lg_pressures, teamTotals.plays),
    c: nicePerc(teamTotals.c_pressures, teamTotals.plays),
    rg: nicePerc(teamTotals.rg_pressures, teamTotals.plays),
    rt: nicePerc(teamTotals.rt_pressures, teamTotals.plays),
  };

  const pressuresPercentageRowLA = {
    name: FIXED_NAMES.LEAGUE_AVERAGE,
    lt: `${laTotals.lt_pressures.toFixed(1)}%`,
    lg: `${laTotals.lg_pressures.toFixed(1)}%`,
    c: `${laTotals.c_pressures.toFixed(1)}%`,
    rg: `${laTotals.rg_pressures.toFixed(1)}%`,
    rt: `${laTotals.rt_pressures.toFixed(1)}%`,
  };

  return [
    pressuresCountRow,
    pressuresPercentageRow,
    pressuresPercentageRowTeam,
    pressuresPercentageRowLA,
  ];
};

const getGapsTableData = function (totals, teamTotals, laTotals) {
  const pressuresCountRow = {
    name: concededName(totals.plays),
    lC: totals.leftCPlays,
    lB: totals.leftBPlays,
    lA: totals.leftAPlays,
    rA: totals.rightAPlays,
    rB: totals.rightBPlays,
    rC: totals.rightCPlays,
  };

  const addPercentageSymbol = (pressurePercentage) => {
    if (pressurePercentage === 0) {
      return '0%';
    }
    return `${pressurePercentage.toFixed(1)}%`;
  };

  const pressuresPercRow = {
    name: FIXED_NAMES.PRESSURE,
    lC: addPercentageSymbol(totals.leftCPressPerc),
    lB: addPercentageSymbol(totals.leftBPressPerc),
    lA: addPercentageSymbol(totals.leftAPressPerc),
    rA: addPercentageSymbol(totals.rightAPressPerc),
    rB: addPercentageSymbol(totals.rightBPressPerc),
    rC: addPercentageSymbol(totals.rightCPressPerc),
  };

  const pressuresPercRowTeam = {
    name: teamName(teamTotals.plays),
    lC: addPercentageSymbol(teamTotals.leftCPressPerc),
    lB: addPercentageSymbol(teamTotals.leftBPressPerc),
    lA: addPercentageSymbol(teamTotals.leftAPressPerc),
    rA: addPercentageSymbol(teamTotals.rightAPressPerc),
    rB: addPercentageSymbol(teamTotals.rightBPressPerc),
    rC: addPercentageSymbol(teamTotals.rightCPressPerc),
  };

  const pressuresPercRowLA = {
    name: FIXED_NAMES.LEAGUE_AVERAGE,
    lC: addPercentageSymbol(laTotals.leftCPressPerc),
    lB: addPercentageSymbol(laTotals.leftBPressPerc),
    lA: addPercentageSymbol(laTotals.leftAPressPerc),
    rA: addPercentageSymbol(laTotals.rightAPressPerc),
    rB: addPercentageSymbol(laTotals.rightBPressPerc),
    rC: addPercentageSymbol(laTotals.rightCPressPerc),
  };

  return [
    pressuresCountRow,
    pressuresPercRow,
    pressuresPercRowTeam,
    pressuresPercRowLA,
  ];
};

/*
  generates objects of olgpPositionPropType for the symbols header
*/
export const olGapPositionPlayer = (
  selectedPlayerId,
  playerList,
  positionAPICode
) => {
  if (!selectedPlayerId) {
    return null;
  }
  const selectedPlayer = playerList.find((p) => p.value === selectedPlayerId);
  return {
    playerId: selectedPlayer.value,
    playerName: selectedPlayer.player,
    position: ALIGNMENT_POSITIONS[positionAPICode],
  };
};

// format data for video tile to allow player tracking and colouring
const formatPressuresVideoData = (plays, visPalette) => {
  if (!plays) {
    return null;
  }
  return plays?.map((play) => ({
    playId: play.playId,
    highlightPlayers: [
      {
        active: true,
        id: play.player?.id,
        colour:
          play.player?.mostCommonPosition &&
          getRosterPositionColorByCode(
            play.player?.mostCommonPosition?.code,
            visPalette
          ),
        name: play.player?.name,
        number: play.player?.mostCommonNumber,
      },
    ],
  }));
};

export {
  summarizeOLTotals,
  summarizeGapTotals,
  getPosLeagueAverages,
  getGapLeagueAverages,
  getPositionsTableData,
  getGapsTableData,
  getPositionPlays,
  formatPressuresVideoData,
};
