import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import { BiometricDropdownWrapper } from '../BiometricFilters.styles';
import { USA_STATES } from '../../../../../utils/constants/geography';

const HomeStateMultiselect = ({
  selectedBiometrics,
  setSelectedBiometrics,
}) => {
  const stateFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.homeState.key
  );
  const currentStates = isEmpty(stateFilter?.value)
    ? []
    : [...stateFilter.value];

  const stateOptions = Object.values(USA_STATES).map((cc) => ({
    value: cc.apiCode,
    label: cc.name,
  }));

  const handleStateSelection = (selectedStates) => {
    const selectedStateCodes = selectedStates?.map((m) => m.value);
    const otherBiometrics = selectedBiometrics.filter(
      (b) => b.key !== SCOUT_BIOMETRIC_FILTERS.homeState.key
    );
    if (isEmpty(selectedStateCodes)) {
      setSelectedBiometrics(otherBiometrics);
    } else {
      const newFilter = {
        key: SCOUT_BIOMETRIC_FILTERS.homeState.key,
        value: selectedStateCodes,
      };
      setSelectedBiometrics(otherBiometrics.concat(newFilter));
    }
  };

  const dropDownValue = currentStates.map((c) => ({
    value: c,
    label: USA_STATES[c].name,
  }));

  return (
    <BiometricDropdownWrapper>
      <Dropdown
        label="Home state"
        id="scout-biometrics-state-select"
        options={stateOptions}
        onChange={handleStateSelection}
        value={dropDownValue}
        isMulti
        size="small"
      />
    </BiometricDropdownWrapper>
  );
};
HomeStateMultiselect.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default HomeStateMultiselect;
