import { useState, useEffect } from 'react';

const useTypingState = () => {
  const [inputString, setInputString] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setIsTyping(false);
    }, 400);

    return () => clearTimeout(delaySearch);
  }, [inputString]);

  return [isTyping, setIsTyping, inputString, setInputString];
};

export default useTypingState;
