export const USA_STATES = {
  AK: { apiCode: 'AK', name: 'Alaska' },
  AL: { apiCode: 'AL', name: 'Alabama' },
  AR: { apiCode: 'AR', name: 'Arkansas' },
  AZ: { apiCode: 'AZ', name: 'Arizona' },
  CA: { apiCode: 'CA', name: 'California' },
  CO: { apiCode: 'CO', name: 'Colorado' },
  CT: { apiCode: 'CT', name: 'Connecticut' },
  DC: { apiCode: 'DC', name: 'District of Columbia' },
  DE: { apiCode: 'DE', name: 'Delaware' },
  FL: { apiCode: 'FL', name: 'Florida' },
  GA: { apiCode: 'GA', name: 'Georgia' },
  HI: { apiCode: 'HI', name: 'Hawaii' },
  IA: { apiCode: 'IA', name: 'Iowa' },
  ID: { apiCode: 'ID', name: 'Idaho' },
  IL: { apiCode: 'IL', name: 'Illinois' },
  IN: { apiCode: 'IN', name: 'Indiana' },
  KS: { apiCode: 'KS', name: 'Kansas' },
  KY: { apiCode: 'KY', name: 'Kentucky' },
  LA: { apiCode: 'LA', name: 'Louisiana' },
  MA: { apiCode: 'MA', name: 'Massachusetts' },
  MD: { apiCode: 'MD', name: 'Maryland' },
  ME: { apiCode: 'ME', name: 'Maine' },
  MI: { apiCode: 'MI', name: 'Michigan' },
  MN: { apiCode: 'MN', name: 'Minnesota' },
  MO: { apiCode: 'MO', name: 'Missouri' },
  MS: { apiCode: 'MS', name: 'Mississippi' },
  MT: { apiCode: 'MT', name: 'Montana' },
  NC: { apiCode: 'NC', name: 'North Carolina' },
  ND: { apiCode: 'ND', name: 'North Dakota' },
  NE: { apiCode: 'NE', name: 'Nebraska' },
  NH: { apiCode: 'NH', name: 'New Hampshire' },
  NJ: { apiCode: 'NJ', name: 'New Jersey' },
  NM: { apiCode: 'NM', name: 'New Mexico' },
  NV: { apiCode: 'NV', name: 'Nevada' },
  NY: { apiCode: 'NY', name: 'New York' },
  OH: { apiCode: 'OH', name: 'Ohio' },
  OK: { apiCode: 'OK', name: 'Oklahoma' },
  OR: { apiCode: 'OR', name: 'Oregon' },
  PA: { apiCode: 'PA', name: 'Pennsylvania' },
  PR: { apiCode: 'PR', name: 'Puerto Rico' },
  RI: { apiCode: 'RI', name: 'Rhode Island' },
  SC: { apiCode: 'SC', name: 'South Carolina' },
  SD: { apiCode: 'SD', name: 'South Dakota' },
  TN: { apiCode: 'TN', name: 'Tennessee' },
  TX: { apiCode: 'TX', name: 'Texas' },
  UT: { apiCode: 'UT', name: 'Utah' },
  VA: { apiCode: 'VA', name: 'Virginia' },
  VT: { apiCode: 'VT', name: 'Vermont' },
  WA: { apiCode: 'WA', name: 'Washington' },
  WI: { apiCode: 'WI', name: 'Wisconsin' },
  WV: { apiCode: 'WV', name: 'West Virginia' },
  WY: { apiCode: 'WY', name: 'Wyoming' },
};
