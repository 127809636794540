import { styled } from 'styled-components';

export const StyledEventTiles = styled.div`
  .ui {
    &.table {
      tr {
        cursor: pointer;

        &.active,
        &.active:hover {
          td {
            background-color: ${({ theme }) =>
              theme.colours.interface.main} !important;
            color: ${({ theme }) => theme.colours.ink.primary.main} !important;
          }
        }
      }
    }

    &.dimmer {
      min-height: 200px;
    }
  }
`;
StyledEventTiles.displayName = 'StyledEventTiles';

export const StickyEventTile = styled.div`
  position: sticky;
  top: 115px;
`;
StickyEventTile.displayName = 'StickyEventTile';
