export const SORT_DIRECTIONS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  DEFAULT: 'default',
};

export const SORT_ICONS = {
  [SORT_DIRECTIONS.ASCENDING]: 'SortAscending',
  [SORT_DIRECTIONS.DESCENDING]: 'SortDescending',
  [SORT_DIRECTIONS.DEFAULT]: 'SortNone',
};
