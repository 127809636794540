import { styled } from 'styled-components';

export const StyledTabbedTable = styled.div`
  padding: ${({ $withPadding }) => ($withPadding ? '0.9375rem' : '0')};

  table {
    min-height: ${({ $isLoading }) => $isLoading && '50rem'};
  }

  .ui.attached.tabular.menu {
    font-size: 0.875rem;
    border-color: ${({ theme, $withBorder }) =>
      $withBorder && `${theme.colours.layering[2].main}`};
    ${({ $withBorder }) => !$withBorder && 'border: 0;'};

    .item {
      color: ${({ theme }) =>
        theme.applyOpacity(
          theme.colours.ink.primary.main,
          'secondary'
        )} !important;
      ${({ $withBorder }) => !$withBorder && 'border: 0;'};
    }

    .active.item {
      background-color: ${({ theme }) =>
        theme.colours.canvas.tertiary.main} !important;
      color: ${({ theme }) => theme.colours.ink.primary.main} !important;
      border-color: ${({ theme, $withBorder }) =>
        $withBorder && `${theme.colours.layering[2].main}`};
    }
  }

  .ui.attached.segment {
    ${({ $withBorder }) => ($withBorder ? 'border-top: none' : 'border: 0')};
    padding: 0;
  }

  .ui.bottom.attached.segment.tab {
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    border-color: ${({ theme, $withBorder }) =>
      $withBorder ? `${theme.colours.layering[2].main}` : ''};
  }

  .ui.bottom.attached.segment.active.tab {
    min-height: 8rem;
  }

  h3 {
    height: 3.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
StyledTabbedTable.displayName = 'StyledTabbedTable';

export const StyledPaneContainer = styled.div`
  border-right: ${({ theme, $withBorder }) =>
    $withBorder && `1px solid ${theme.colours.layering[2].main}`};
  border-left: ${({ theme, $withBorder }) =>
    $withBorder && `1px solid ${theme.colours.layering[2].main}`};
  border-bottom: ${({ theme, $withBorder }) =>
    $withBorder && `1px solid ${theme.colours.layering[2].main}`};
  // hide corner-square when overflow-x and overflow-y being used simultaneously
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
StyledPaneContainer.displayName = 'StyledPaneContainer';

export const StyledSubTabs = styled.div`
  display: flex;
  font-size: 0.875rem;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colours.layering[0].main}`};

  button {
    padding: ${({ $withPadding }) =>
      $withPadding ? '0.9375rem 1.25rem' : '0.4375rem 1.25rem 0.625rem'};
    outline: none;
    color: ${({ theme }) =>
      theme.applyOpacity(
        theme.colours.ink.primary.main,
        'secondary'
      )} !important;

    &.selected {
      position: relative;
      border: ${({ $withBorder }) => $withBorder && '0'};
      border-top: ${({ theme, $withBorder }) =>
        !$withBorder && `1px solid ${theme.colours.layering[0].main}`};
      border-left: ${({ theme, $withBorder }) =>
        !$withBorder && `1px solid ${theme.colours.layering[0].main}`};
      border-right: ${({ theme, $withBorder }) =>
        !$withBorder && `1px solid ${theme.colours.layering[0].main}`};
      font-weight: 600;
      color: ${({ theme }) => theme.colours.ink.primary.main} !important;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: ${({ theme, $withBorder }) =>
          !$withBorder && `1px solid ${theme.colours.canvas.tertiary.main}`};
        left: 0;
        bottom: -1px;
      }
    }
  }
`;
StyledSubTabs.displayName = 'StyledSubTabs';
