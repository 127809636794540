import { gql, useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import { getPlayerComparisonQuery } from './getPlayerComparison';
import useDebounce from '../../../utils/hooks/useDebounce';
import {
  COMPARE_SIMILAR,
  COMPARISON_DEBOUNCE_DELAY,
  COMPARISON_WEIGHT_OPTIONS,
} from './PlayerComparison.constants';

export const usePlayerComparison = (
  comparisonPlayerIds,
  minPlays,
  transferPortal,
  allPlays,
  radarWeight,
  alignmentPositionsApiCodes,
  selectedTemplate,
  defaultRosterPositions,
  comparisonMode
) => {
  const debouncedMinPlays = useDebounce(
    minPlays,
    comparisonMode === COMPARE_SIMILAR ? COMPARISON_DEBOUNCE_DELAY : 0
  );
  // skip query
  const skip = (allPlays && !defaultRosterPositions) || !selectedTemplate;
  // Extract trait and performance data
  const traitTemplate = selectedTemplate?.secondaryStats?.map((m) => m.name);
  const perfTemplate = selectedTemplate?.stats?.map((m) => m.name);
  const combinedTemplate = traitTemplate?.concat(perfTemplate);
  // weighted stats for comparison query
  const traitWeight = COMPARISON_WEIGHT_OPTIONS[radarWeight]?.trait;
  const perfWeight = COMPARISON_WEIGHT_OPTIONS[radarWeight]?.performance;
  const weightedStats = combinedTemplate?.map((name) => {
    const weight = traitTemplate.includes(name) ? traitWeight : perfWeight;
    return { name, weight };
  });
  const playerComparisonGQLString = getPlayerComparisonQuery(weightedStats);
  const {
    loading: isComparisonLoading,
    error: hasComparisonError,
    data: playerComparisonData,
  } = useQuery(gql(playerComparisonGQLString), {
    skip,
    variables: {
      comparisonPlayerIds,
      minPlays: debouncedMinPlays,
      ncaaTransferPortal: transferPortal,
      rosterPositions: allPlays ? defaultRosterPositions : [],
      specificPositions: allPlays ? [] : alignmentPositionsApiCodes,
    },
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (isComparisonLoading) {
    return {
      loading: true,
    };
  }

  if (hasComparisonError) {
    console.error(ERROR_CODES.GET_PLAYER_COMPARISON, hasComparisonError);
    return {
      loading: false,
      error: hasComparisonError,
    };
  }

  // check for errors
  // have we asked for a player comparison and not received a result? if so, this is an error
  const comparisonIds = comparisonPlayerIds.slice(1); // remove base player id
  if (
    comparisonIds.length >
      playerComparisonData?.playerComparison?.comparativePlayers?.length &&
    comparisonIds.length > 0
  ) {
    const modifiedPlayerComparisonData = {
      ...playerComparisonData,
      playerComparison: {
        ...playerComparisonData?.playerComparison,
        comparativePlayers: [
          ...playerComparisonData?.playerComparison?.comparativePlayers,
          {
            playerId: comparisonPlayerIds[comparisonPlayerIds.length - 1],
            hasError: true,
          },
        ],
      },
    };

    return {
      loading: false,
      data: modifiedPlayerComparisonData,
    };
  }

  return {
    loading: false,
    data: playerComparisonData,
  };
};
