import React, { useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'styled-components';
import { useD3 } from '../../../utils/hooks/useD3';
import {
  COMPETITION_LEVEL,
  ROTATIONS,
} from '../../../utils/constants/charting';
import {
  SNAP_FORMATION_DEFAULT_Y_YDS,
  SNAP_FORMATION_DEFAULT_X_YDS,
  formatSnapData,
  SNAP_FORMATION_ZOOM_MODES,
  FORMATION_MEASURES,
  SNAP_FORMATION_FOCUS_MODES,
  SNAP_FORMATION_FOCUS_MODES_Y,
} from './DataManipulation';
import { addBackgroundRect } from '../../../utils/visualisations/basic';
import { addEventFF } from './FFChart.drawing';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../../utils/helpers/field.constants';
import { addExportFontReference } from '../../../utils/helpers/export';

function FFChart({
  id,
  data,
  cols,
  competitionLevel,
  rotationOption,
  margin,
  fieldSettings,
  selectedEvent,
  zoomOption,
  focusOptionX,
  focusOptionY,
  showJerseys,
  measureOptions,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  showBall,
}) {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    ...fieldSettings,
    visPalette,
    horizontal: rotationOption === ROTATIONS.HORIZONTAL,
    strokeWidthPx: 2,
    competitionLevel,
  };
  const snapViewSize = overrides.horizontal
    ? SNAP_FORMATION_DEFAULT_X_YDS * overrides.pxPerYard
    : SNAP_FORMATION_DEFAULT_Y_YDS * overrides.pxPerYard;
  const snapViewDepth = overrides.horizontal
    ? SNAP_FORMATION_DEFAULT_Y_YDS * overrides.pxPerYard
    : SNAP_FORMATION_DEFAULT_X_YDS * overrides.pxPerYard;

  const tb = overrides.horizontal ? 60 : 80;
  const lr = overrides.horizontal ? 80 : 60;
  const ffPlayZoneSizes = {
    margin: { top: tb, bottom: tb, left: lr, right: lr },
    width: snapViewSize,
    height: snapViewDepth,
  };

  // tidy up the data from the edges/nodes of the API response & add formatting
  const selectedData = data?.filter((s) => s.id === selectedEvent);
  // could set it to first int he list, will wait for deep linking merge
  /* if (!selectedData || selectedData.length === 0) {
    selectedData.push(data[0]);
  } */
  const formattedEvent = formatSnapData(selectedData[0]) || null;

  let svg = null;
  let ffsArea = null;

  const ref = useD3((s) => {
    svg = s;
    const svgDefs = svg.append('defs');
    addExportFontReference(svgDefs);
  });

  const VIEWER_CLIP_PATH_NAME = `${id}-ff-viewer-clip-path`;

  useEffect(() => {
    const ffViewX =
      snapViewSize + ffPlayZoneSizes.margin.left + ffPlayZoneSizes.margin.right;
    const x = ffViewX + margin.left + margin.right;
    const ffViewY =
      snapViewDepth +
      ffPlayZoneSizes.margin.top +
      ffPlayZoneSizes.margin.bottom;
    const y = ffViewY + margin.top + margin.bottom;

    const viewBox = `0 0 ${x} ${y}`;
    svg.attr('viewBox', viewBox);
    svg.selectAll('rect').remove();
    svg.selectAll('g').remove();
    svg.call(addBackgroundRect, visPalette.background.main);
    ffsArea = svg
      .append('g')
      .attr('id', `${id}-plays-area`)
      .attr('transform', `translate(${margin.left} ${margin.top})`);
    // add the clip path that all children will use to limit the field view
    ffsArea
      .append('defs')
      .append('clipPath')
      .attr('id', VIEWER_CLIP_PATH_NAME)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', snapViewSize)
      .attr('height', snapViewDepth);

    ffsArea
      .append('rect')
      .attr('x', ffPlayZoneSizes.margin.left - 1)
      .attr('y', ffPlayZoneSizes.margin.top - 1)
      .attr('width', snapViewSize + 2)
      .attr('height', snapViewDepth + 2)
      .attr('fill', 'transparent')
      .attr('stroke', visPalette.border)
      .attr('stroke-width', 1);

    addEventFF(
      ffsArea,
      overrides,
      visPalette,
      formattedEvent,
      ffPlayZoneSizes,
      rotationOption,
      zoomOption,
      focusOptionX,
      focusOptionY,
      showJerseys,
      measureOptions,
      selectedOffensePlayerId,
      selectedDefensePlayerId,
      showBall,
      id
    );
  }, [
    cols,
    data,
    margin,
    selectedEvent,
    rotationOption,
    zoomOption,
    focusOptionX,
    focusOptionY,
    competitionLevel,
    showJerseys,
    measureOptions,
    selectedOffensePlayerId,
    selectedDefensePlayerId,
    showBall,
    theme.isDark,
  ]);

  return !!data?.length && <svg id={id} ref={ref} />;
}

FFChart.propTypes = {
  competitionLevel: PropTypes.string,
  cols: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array, // TODO
  rotationOption: string,
  id: PropTypes.string.isRequired,
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  fieldSettings: PropTypes.object,
  selectedEvent: PropTypes.string,
  zoomOption: PropTypes.string,
  focusOptionX: PropTypes.string,
  focusOptionY: PropTypes.string,
  showJerseys: PropTypes.bool,
  measureOptions: PropTypes.shape({
    offensiveLineWidth: PropTypes.bool,
    offensiveFormationWidth: PropTypes.bool,
    tightEndSplits: PropTypes.bool,
    tightEndDepths: PropTypes.bool,
    defensiveBackDepths: PropTypes.bool,
    linebackerDepths: PropTypes.bool,
    defensiveFormationWidth: PropTypes.bool,
    boxPlayersCount: PropTypes.bool,
    wideReceiverSplits: PropTypes.bool,
    wideReceiverDepths: PropTypes.bool,
    defensiveBackWidths: PropTypes.bool,
    linebackerWidths: PropTypes.bool,
  }),
  selectedOffensePlayerId: PropTypes.number,
  selectedDefensePlayerId: PropTypes.number,
  showBall: PropTypes.bool,
};

FFChart.defaultProps = {
  competitionLevel: COMPETITION_LEVEL.NCAA,
  fieldSettings: DEFAULT_FIELD_DRAWING_SETTINGS,
  cols: 4,
  data: [],
  rotationOption: ROTATIONS.VERTICAL_UP,
  margin: {
    bottom: 5,
    left: 5,
    right: 5,
    top: 5,
  },
  selectedEvent: null,
  zoomOption: SNAP_FORMATION_ZOOM_MODES.DEFAULT,
  focusOptionX: SNAP_FORMATION_FOCUS_MODES.PLAYERS,
  focusOptionY: SNAP_FORMATION_FOCUS_MODES_Y.PLAYERS,
  showJerseys: false,
  measureOptions: FORMATION_MEASURES,
  selectedOffensePlayerId: 0,
  selectedDefensePlayerId: 0,
  showBall: false,
};

export default FFChart;
