import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { StyledCompactTable } from './SnapList.styles';

function SnapList({
  driveInfo,
  selectedEvent,
  setSelectedEvent,
  displayedEvents,
}) {
  const drivePlays = driveInfo?.plays?.edges?.map((m) => m.node);
  const displayedPlayIds = displayedEvents?.map((m) => m.node?.play?.id);
  const selectedEvents =
    selectedEvent && displayedEvents.filter((f) => f.node.id === selectedEvent);
  const selectedObj =
    (selectedEvents && selectedEvents.length >= 1 && selectedEvents[0].node) ||
    null;

  const selectSnapFromPlay = function (play_uuid) {
    const matchingEvent = displayedEvents.filter(
      (f) => f.node.play.id === play_uuid
    )[0];
    if (matchingEvent) {
      setSelectedEvent(matchingEvent.node.id);
    }
  };
  return (
    <StyledCompactTable stackable striped>
      <Table.Body>
        {drivePlays.map((play) => (
          <Table.Row
            key={play.id}
            active={selectedObj && selectedObj.play.id === play.id}
            disabled={!(displayedPlayIds && displayedPlayIds.includes(play.id))}
            onClick={() => selectSnapFromPlay(play.id)}
          >
            <Table.Cell>{play.name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </StyledCompactTable>
  );
}

SnapList.propTypes = {
  driveInfo: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    plays: PropTypes.shape({ edges: PropTypes.array }),
  }),
  selectedEvent: PropTypes.string,
  setSelectedEvent: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  displayedEvents: PropTypes.array,
};

SnapList.defaultProps = {
  driveInfo: null,
  selectedEvent: null,
  setSelectedEvent: null,
  displayedEvents: [],
};

export default SnapList;
