import { VISUALISATION_FONT_SETUPS } from '../../../../../utils/constants/visText';
import {
  RUN_CHARTS_WIDTH,
  RUN_TENDENCY_BUBBLE_SIZES,
  RUN_TENDENCY_SIDEBAR_WIDTH,
} from '../RunTendencies.constants';

export const RUN_KEY_CLASSES = {
  BACKGROUND: 'rk-background-rect',
  TITLE: 'rk-title',
  COLORING: 'rk-coloring',
  SCALING: 'rk-scaling',
  LINES: 'rk-lines',
  SOLID_FRAME: 'rk-solid-frame',
};

export const RUN_KEY_OBJECT_SIZES = {
  COLOR_DOT_RADIUS: 5,
  SCALE_BUBBLE_MAX_RADIUS: RUN_TENDENCY_BUBBLE_SIZES.maxRadius,
};
export const RUN_KEY_SECTION_MARGIN = { VERTICAL: 8, HORIZONTAL: 32 };
export const RUN_KEY_TITLE_FONT = VISUALISATION_FONT_SETUPS.KEY_TITLE;
export const RUN_KEY_SIZE_Y = {
  TITLE: RUN_KEY_TITLE_FONT.LINE_HEIGHT,
  COLORING:
    VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT +
    RUN_KEY_OBJECT_SIZES.COLOR_DOT_RADIUS * 2,
  SCALING:
    VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT +
    RUN_KEY_OBJECT_SIZES.SCALE_BUBBLE_MAX_RADIUS * 2,
  LINES: VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT * 2,
  SOLID_FRAME: VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT * 2,
};

export const RUN_KEY_WIDTH = {
  HORIZONTAL: RUN_CHARTS_WIDTH,
  VERTICAL: RUN_TENDENCY_SIDEBAR_WIDTH.MAIN,
};
export const RUN_KEY_SECTION_WIDTH =
  (RUN_KEY_WIDTH.HORIZONTAL - RUN_KEY_SECTION_MARGIN.HORIZONTAL * 2) / 4;
