import React from 'react';
import PropTypes from 'prop-types';
import {
  StatBlockContainer,
  StatBlockName,
  StatBlockValue,
} from './StatBlock.styles';

const StatBlock = ({ statName, value, backgroundColor, color }) => (
  <StatBlockContainer style={{ backgroundColor, color }}>
    <StatBlockName>{statName}</StatBlockName>
    <StatBlockValue>{value}</StatBlockValue>
  </StatBlockContainer>
);

StatBlock.propTypes = {
  // The stat displayed
  statName: PropTypes.string.isRequired,
  // The formatted value of the stat displayed
  value: PropTypes.string.isRequired,
  // The background color of the stat block
  backgroundColor: PropTypes.string,
  // The text color of the stat block
  color: PropTypes.string,
};

StatBlock.defaultProps = {
  backgroundColor: undefined,
  color: undefined,
};

export default StatBlock;
