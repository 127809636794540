import {
  ROSTER_POSITIONS,
  ALIGNMENT_POSITIONS,
} from '../../../utils/constants/positions';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';

export const ALL_POSITIONS_MAP = {
  ...ROSTER_POSITIONS,
  ...ALIGNMENT_POSITIONS,
};

export const API_DEFENSIVE_POSITION_KEYS = Object.entries(ALL_POSITIONS_MAP)
  .filter(([, value]) => !value.isOffense)
  .map(([key]) => key);

export const ALL_PACKAGES_OPTION = { value: 'ALL', label: 'All packages' };

export const CSV_PLAYER_HEADERS = [
  'position',
  'player',
  'playCount',
  'playRate',
  'usageRate',
  'lateDownsRate',
];

export const CSV_TEAM_HEADERS = ['package', 'playRate', 'leagueAverage'];

export const OFFENSIVE_LINEMAN_POSITIONS = Object.keys(
  ALIGNMENT_POSITIONS
).filter(
  (key) =>
    ALIGNMENT_POSITIONS[key].rosterPositionKey ===
    API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN
);

export const ROSTER_POSITION_KEYS = Object.keys(ROSTER_POSITIONS);
export const ALIGNMENT_POSITION_KEYS = Object.keys(ALIGNMENT_POSITIONS);

export const QUERY_CONTEXT = { personnel: false };
