import { styled } from 'styled-components';

export const GotoButton = styled.button`
  border-radius: 100%;
  padding: 0.1875rem;
  margin: 0;
  transition: background 0.2s ease-in-out;
  background-color: ${({ theme, $ghost }) =>
    $ghost ? '' : theme.colours.canvas.secondary.main};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ theme, $disabled }) =>
    $disabled ? theme.opacity.disabled : 1};
  width: 1.5rem;

  svg {
    margin: auto;
  }

  a:focus-visible,
  &:focus-visible {
    outline: none;
    background-color: ${({ theme }) => theme.colours.canvas.secondary.strong};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.canvas.secondary.strong};
  }
`;
