import React, { useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'styled-components';
import { useD3 } from '../../../utils/hooks/useD3';
import {
  COMPETITION_LEVEL,
  ROTATIONS,
} from '../../../utils/constants/charting';
import {
  SNAP_FORMATION_DEFAULT_Y_YDS,
  SNAP_FORMATION_DEFAULT_X_YDS,
  formatSnapData,
  addRowCol,
  SNAP_FORMATION_ZOOM_MODES,
  SNAP_FORMATION_FOCUS_MODES,
  SNAP_FORMATION_FOCUS_MODES_Y,
} from './DataManipulation';
import { addBackgroundRect } from '../../../utils/visualisations/basic';
import {
  addFFZone,
  getZoneWidth,
  getZoneHeight,
  getZoneSpacers,
} from './MultiFFChart.drawing';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../../utils/helpers/field.constants';
import { StyledMultiFFChart } from './MultiFFChart.styles';

const MultiFFChart = ({
  id,
  data,
  cols,
  competitionLevel,
  rotationOption,
  margin,
  showJerseys,
  selectedEvent,
  setSelectedEvent,
  zoomOption,
  focusOptionX,
  focusOptionY,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  showBall,
  playInfo,
  isInteractive,
}) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    horizontal: rotationOption === ROTATIONS.HORIZONTAL,
    strokeWidthPx: 2,
    competitionLevel,
  };
  const snapViewSize = overrides.horizontal
    ? SNAP_FORMATION_DEFAULT_X_YDS * overrides.pxPerYard
    : SNAP_FORMATION_DEFAULT_Y_YDS * overrides.pxPerYard;
  const snapViewDepth = overrides.horizontal
    ? SNAP_FORMATION_DEFAULT_Y_YDS * overrides.pxPerYard
    : SNAP_FORMATION_DEFAULT_X_YDS * overrides.pxPerYard;

  const playZoneSizes = {
    info: {
      margin: { top: 0, bottom: 0, left: 30, right: 30 },
      width: snapViewSize,
      height: 50,
    },
    ff: {
      margin: { top: 30, bottom: 30, left: 30, right: 30 },
      width: snapViewSize,
      height: snapViewDepth,
    },
    spacer: 50,
  };
  const zoneWidth = getZoneWidth(playZoneSizes);
  const zoneHeight = getZoneHeight(playZoneSizes);

  // tidy up the data from the edges/nodes of the API response & add formatting
  const formattedData = data?.map(formatSnapData);
  const chartData = formattedData.map((snap) =>
    addRowCol(snap, formattedData, cols)
  );
  const rows = Math.ceil(chartData.length / cols);

  let svg = null;
  let ffsArea = null;

  const ref = useD3((s) => {
    svg = s;
  });

  useEffect(() => {
    const x = zoneWidth * cols + margin.left + margin.right;
    const y =
      zoneHeight * rows +
      margin.top +
      margin.bottom +
      getZoneSpacers(playZoneSizes, rows - 1);
    const viewBox = `0 0 ${x} ${y}`;
    svg.attr('viewBox', viewBox);
    svg.selectAll('rect').remove();
    svg.selectAll('g').remove();
    svg.call(addBackgroundRect, visPalette.background.main);
    ffsArea = svg
      .append('g')
      .attr('id', `${id}-plays-area`)
      .attr('transform', `translate(${margin.left} ${margin.top})`);

    addFFZone(
      ffsArea,
      chartData,
      playZoneSizes,
      selectedEvent,
      setSelectedEvent,
      rotationOption,
      zoomOption,
      focusOptionX,
      focusOptionY,
      showJerseys,
      overrides,
      selectedOffensePlayerId,
      selectedDefensePlayerId,
      showBall,
      playInfo,
      visPalette,
      id,
      isInteractive
    );
  }, [
    cols,
    data,
    margin,
    selectedEvent,
    rotationOption,
    zoomOption,
    competitionLevel,
    showJerseys,
    selectedOffensePlayerId,
    selectedDefensePlayerId,
    focusOptionX,
    focusOptionY,
    showBall,
    theme.isDark,
  ]);

  return (
    !!chartData?.length && (
      <StyledMultiFFChart id={id} ref={ref} $isInteractive={isInteractive} />
    )
  );
};

MultiFFChart.propTypes = {
  competitionLevel: PropTypes.string,
  cols: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  rotationOption: string,
  id: PropTypes.string.isRequired,
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  showJerseys: PropTypes.bool,
  selectedEvent: PropTypes.string,
  setSelectedEvent: PropTypes.func,
  zoomOption: PropTypes.string,
  focusOptionX: PropTypes.string,
  focusOptionY: PropTypes.string,
  selectedOffensePlayerId: PropTypes.number,
  selectedDefensePlayerId: PropTypes.number,
  showBall: PropTypes.bool,
  playInfo: PropTypes.bool, // should the info be about the play or about the event
  isInteractive: PropTypes.bool,
};

MultiFFChart.defaultProps = {
  competitionLevel: COMPETITION_LEVEL.NCAA,
  cols: 4,
  data: [],
  rotationOption: ROTATIONS.VERTICAL_UP,
  margin: {
    bottom: 10,
    left: 10,
    right: 10,
    top: 10,
  },
  showJerseys: true,
  selectedEvent: null,
  setSelectedEvent: null,
  zoomOption: SNAP_FORMATION_ZOOM_MODES.DEFAULT,
  focusOptionX: SNAP_FORMATION_FOCUS_MODES.PLAYERS,
  focusOptionY: SNAP_FORMATION_FOCUS_MODES_Y.PLAYERS,
  selectedOffensePlayerId: 0,
  selectedDefensePlayerId: 0,
  showBall: false,
  playInfo: true,
  isInteractive: true,
};

export default MultiFFChart;
