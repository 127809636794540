import { API_PLAYER_AGGREGATE_MODES } from '../../../utils/constants/api';
import { formatValue } from '../../League/stats/stats.dataManipulation';

export const getTeamRadarTableData = (
  templateConfig,
  showLeagueAverage,
  radarStats,
  radarStatsComparison
) => {
  const laHeader = showLeagueAverage && { label: 'League', key: 'la' };
  const radarTableHeaders = [
    { label: 'Axis', key: 'name' },
    { label: 'Metric Name', key: 'prettyName' },
    { label: 'Team', key: 'statValue' },
    laHeader,
  ].filter(Boolean);
  const playsMetric = [
    {
      index: -1,
      key: 'playCount',
      min: 0,
      max: 99999,
      name: '',
      prettyName: 'Plays',
      units: 'PLAYS',
    },
  ];
  const tableMetrics = templateConfig && playsMetric.concat(templateConfig);
  const radarTableData = tableMetrics?.map((m) => ({
    prettyName: m.prettyName,
    name: m.name,
    statValue:
      m.units === 'PLAYS'
        ? radarStats?.[m.key]
        : formatValue(
            radarStats?.[m.key],
            m.units,
            API_PLAYER_AGGREGATE_MODES.RATE
          ) || '-',
    la:
      m.units === 'PLAYS'
        ? '-'
        : formatValue(
            radarStatsComparison?.[m.key],
            m.units,
            API_PLAYER_AGGREGATE_MODES.RATE
          ) || '-',
    title: m?.description,
  }));
  return { radarTableData, radarTableHeaders };
};

export const getTeamRadarKeyInfo = (
  teamName,
  groupName, // offensive or defensive
  radarStats,
  radarStatsComparison
) => {
  const keyBaseInfo = {
    main: {
      title: `${teamName} (${groupName})`,
      info1: `Data from ${radarStats?.playCount} plays`,
      info2: ' ',
    },
  };

  if (radarStatsComparison) {
    const laInfo = {
      title: 'League Average',
      info1: `Average across ${radarStatsComparison?.playCount} plays`,
      info2: ' ',
    };
    return { ...keyBaseInfo, comparison: laInfo };
  }
  return keyBaseInfo;
};
