import React, { useEffect, useState, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  Dropdown,
  Grid,
  Slider,
  Toggle,
  Icon,
} from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import Tile from '../../../components/Tile/Tile';
import { mf_PlayerDetails } from '../../../apollo';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import { useGetTemplateDefinitions } from '../PlayerRadar/PlayerRadar.hooks';
import { formatTemplateStatsAsRadarAxes } from '../PlayerRadar/PlayerRadar.dataManipulation';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  PlayerComparisonLabel,
  PlayerComparisonInformation,
  PlayerComparisonBlank,
} from './PlayerComparison.styles';
import {
  COMPARISON_WEIGHT_OPTIONS,
  COMPARISON_DEFAULT_MIN_PLAYS,
  COMPARISON_DEFAULT_WEIGHT,
  RADAR_DISPLAY_MODE,
  COMPARISON_TEMPLATE_MAPPING,
  COMPARE_SIMILAR,
  COMPARE_SELECTED,
  COMPARISON_TILE_WIDTH,
} from './PlayerComparison.constants';
import { RCSliderWrapper } from '../../../styles/global.styles';
import { useIsNcaa } from '../../../utils/hooks/useIsNcaa';
import { numberOfMatchesInfo } from './PlayerComparison.helpers';
import { usePlayerComparison } from './usePlayerComparison.hook';
import PositionFilter from '../../../components/PositionFilter/PositionFilter';
import { useGetPlayerStatPositionUsage } from '../../../utils/hooks/useGetPlayerStatPositionUsage';
import Tooltip from '../../../components/Tooltip/Tooltip';
import TabButton from '../../../components/buttons/TabButton/TabButton';
import { TabButtonGroup } from '../../../components/buttons/TabButton/TabButton.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PlayerComparisonArea from './PlayerComparisonArea';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';
import { escapeDownloadString } from '../../../utils/helpers/strings';

const PlayerComparison = () => {
  // selected player
  const playerDetails = useReactiveVar(mf_PlayerDetails);
  const playerRole = playerDetails?.mostCommonPosition?.generalPosition;
  const playerPosition = playerDetails?.mostCommonPosition;
  const playerName = playerDetails?.name;
  const seasonInfo = useExportSeasonInfo();
  // playerId for basePlayer
  const playerId = playerDetails?.id || 0;
  // playerIds for comparison
  const [comparisonPlayerIds, setComparisonPlayerIds] = useState([]);
  // player link mode (to comparison) true/false
  const [playerLinkComparison, setPlayerLinkComparison] = useQueryString(
    'link',
    false
  );
  // tab & comparison mode
  const [comparisonMode, setComparisonMode] = useQueryString(
    'mode',
    COMPARE_SIMILAR
  );
  // display mode
  const { TRAIT, PERFORMANCE, BOTH } = RADAR_DISPLAY_MODE;
  const [displayMode, setDisplayMode] = useQueryString('display', BOTH);
  // ncaa transfer portal
  const isNcaa = useIsNcaa();
  const [onlyTransferPortal, setOnlyTransferPortal] = useQueryString(
    'ncaa',
    false
  );
  // check in case the user toggles leagues
  if (!isNcaa && onlyTransferPortal) {
    setOnlyTransferPortal(false);
  }
  // play types
  const [allPlays, setAllPlays] = useQueryString('all', true);
  const posTypeName = allPlays ? 'Roster Positions' : 'Alignment Positions';
  // minimum plays
  const [minPlays, setMinPlays] = useQueryString(
    'min',
    COMPARISON_DEFAULT_MIN_PLAYS
  );
  // trait/performance weight
  const [radarWeight, setRadarWeight] = useQueryString(
    'weight',
    COMPARISON_DEFAULT_WEIGHT
  );
  const [renderData, setRenderData] = useState(true);
  // radar templates
  const {
    templates,
    loading: isConfigLoading,
    error: hasConfigError,
  } = useGetTemplateDefinitions();
  const templateDefinitions = templates?.map((t) => {
    const opt = { value: t.defaultFor[0], label: t.name };
    return opt;
  });
  const [templateOptions, setTemplateOptions] = useState(templateDefinitions);
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(null);
  // player doesn't have a supported template
  const playerPositionOverride = useRef();
  // alignment position for queries etc.
  const [alignmentPositionsApiCodes, setAlignmentPositionsApiCodes] = useState(
    []
  );

  /* 
  Position Usages ~ how many plays per position a player was in ~ for alignment filter 
  It's just nice additional data so don't really care if it errors or is still loading
  */
  const { playerStatsData } = useGetPlayerStatPositionUsage({
    selectedPlayerId: playerId,
    useContextFilters: false,
  });
  const positionUsages =
    playerStatsData?.length > 0 ? playerStatsData[0].positions : [];

  useEffect(() => {
    const defaultTemplate =
      playerRole && templates?.find((t) => t.defaultFor.includes(playerRole));
    if (defaultTemplate?.name) {
      // populate templates dropdown based on COMPARISON_TEMPLATE_MAPPING mapping
      setTemplateOptions(
        templateDefinitions.filter((item) =>
          COMPARISON_TEMPLATE_MAPPING[playerRole].includes(item.value)
        )
      );
      // set selected based on players' mostCommonPosition
      setSelectedTemplateOption(
        templateDefinitions.find((item) => playerRole === item.value)?.value
      );
      // clear template override for players without templates
      playerPositionOverride.current = null;
    } else {
      // no template, populate dropdown with all
      setTemplateOptions(templateDefinitions);
      // select first
      setSelectedTemplateOption(
        templateDefinitions ? templateDefinitions[0]?.value : null
      );
      // add template override
      playerPositionOverride.current = playerRole;
    }
  }, [playerDetails, isConfigLoading]);

  const selectedTemplate = templates?.find((t) =>
    t.defaultFor.includes(selectedTemplateOption)
  );

  const templateConfigTrait = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.secondaryStats
  );
  const templateConfigPerf = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.stats
  );

  const queryComparisonPlayerIds =
    comparisonMode === COMPARE_SIMILAR
      ? [playerId]
      : [playerId, ...comparisonPlayerIds];
  const {
    loading: isLoadingComparison,
    error: hasComparisonError,
    data: comparisonData,
  } = usePlayerComparison(
    queryComparisonPlayerIds,
    comparisonMode === COMPARE_SIMILAR ? minPlays : 1,
    onlyTransferPortal,
    allPlays,
    radarWeight,
    alignmentPositionsApiCodes,
    selectedTemplate,
    playerPositionOverride.current || playerPosition?.generalPosition,
    comparisonMode
  );

  const hasError = hasComparisonError || hasConfigError;
  const isLoading = isConfigLoading || isLoadingComparison;

  useEffect(() => {
    if (comparisonData?.playerComparison?.comparativePlayers?.length > 0) {
      setRenderData(true);
    }
  }, [comparisonData]);

  // positions for alignment filter
  const specificPositions = selectedTemplate?.specificPositions;
  useEffect(() => {
    if (!isEmpty(specificPositions)) {
      setAlignmentPositionsApiCodes(specificPositions);
    }
  }, [specificPositions]);

  // are any players being selected for comparison?
  // if true show 'clear all' button
  const activeSelection =
    comparisonMode === COMPARE_SELECTED &&
    comparisonPlayerIds.length > 0 &&
    comparisonData?.playerComparison?.comparativePlayers.length > 0 &&
    comparisonData?.playerComparison?.comparativePlayers?.some(
      (player) => player.hasError !== true
    );

  const templateTooltipContent = (
    <>
      <p>
        The template controls which metrics are displayed on the radar spokes.
      </p>
      <p>
        To only show data when a player lined up at a certain position, use the
        &lsquo;By Alignment&rsquo; filter and select a position.
      </p>
    </>
  );
  const templateLabel = (
    <>
      <span>Template</span>
      <Tooltip
        content={templateTooltipContent}
        target={<Icon variant="Info" size="small" colour="primary.main" />}
      />
    </>
  );

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <PageHeader
            href="/player/overview/:leagues/:seasons/:teams/:players"
            rootPage="Player"
            activePage="Comparison"
          />
        </Grid>
        <Grid item xs={12}>
          <SidebarRightLayout $sidebarWidth="350px" $gap="0.5rem" $padding="0">
            <div style={{ overflowX: 'hidden' }}>
              <Tile border="0" margin="0">
                <Tile.Body $gap={0} $padding="1rem 1rem 0.5rem 1rem">
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <TabButtonGroup style={{ flex: 1 }}>
                      <TabButton
                        text="Similar Players"
                        handleClick={() => {
                          setComparisonMode(COMPARE_SIMILAR);
                          setRenderData(false);
                        }}
                        active={comparisonMode === COMPARE_SIMILAR}
                      />
                      <TabButton
                        text="Choose Players"
                        handleClick={() => {
                          setComparisonMode(COMPARE_SELECTED);
                          setRenderData(false);
                        }}
                        active={comparisonMode === COMPARE_SELECTED}
                      />
                    </TabButtonGroup>
                    <PlayerComparisonInformation
                      style={{ margin: '1.25rem 0 0 0' }}
                    >
                      {comparisonData &&
                        comparisonMode === COMPARE_SIMILAR &&
                        numberOfMatchesInfo(
                          comparisonData?.playerComparison?.totalComparisons
                        )}
                    </PlayerComparisonInformation>
                    {activeSelection && (
                      <div style={{ height: '1.5rem' }}>
                        <Button
                          onClick={() => setComparisonPlayerIds([0])}
                          variant="secondary"
                          size="small"
                          title="Clear Selection"
                        >
                          Clear All
                        </Button>
                      </div>
                    )}
                    <ExportModal
                      title={`${playerName} - ${
                        ROSTER_POSITIONS[
                          playerDetails?.mostCommonPosition?.generalPosition
                        ]?.name || 'Player'
                      }`}
                      secondaryTitle="Player Comparison"
                      info1={posTypeName}
                      info2={seasonInfo}
                      fileName={escapeDownloadString(
                        `${playerName}-Comparison`
                      )}
                      isDisabled={
                        isLoading ||
                        hasError ||
                        (comparisonMode === COMPARE_SELECTED &&
                          !activeSelection)
                      }
                      customWidth={
                        (comparisonData?.playerComparison?.comparativePlayers
                          .length +
                          1.9) *
                        COMPARISON_TILE_WIDTH
                      }
                    >
                      <PlayerComparisonArea
                        playerDetails={playerDetails}
                        comparisonData={comparisonData}
                        templateConfigPerf={templateConfigPerf}
                        templateConfigTrait={templateConfigTrait}
                        radarWeight={radarWeight}
                        comparisonMode={comparisonMode}
                        displayMode={displayMode}
                        comparisonPlayerIds={comparisonPlayerIds}
                        idSuffix="export"
                        isInteractive={false}
                        ratioLabelSuffix=""
                        renderData={renderData}
                      />
                    </ExportModal>
                    <PlayerComparisonBlank />
                  </div>

                  <PlayerComparisonArea
                    playerDetails={playerDetails}
                    comparisonData={comparisonData}
                    templateConfigPerf={templateConfigPerf}
                    templateConfigTrait={templateConfigTrait}
                    radarWeight={radarWeight}
                    comparisonMode={comparisonMode}
                    displayMode={displayMode}
                    comparisonPlayerIds={comparisonPlayerIds}
                    setComparisonPlayerIds={setComparisonPlayerIds}
                    playerLinkComparison={playerLinkComparison}
                    minPlays={minPlays}
                    renderData={renderData}
                  />
                </Tile.Body>
              </Tile>
            </div>
            <div>
              <AccordionTile
                body={
                  <Tile.AccordionBody
                    $padding={isNcaa ? '1rem' : '1rem 1rem 1.25rem 1rem'}
                  >
                    <Dropdown
                      id="template-choice-dropdown"
                      options={templateOptions}
                      label={templateLabel}
                      onChange={(selectedOption) => {
                        setSelectedTemplateOption(selectedOption?.value);
                      }}
                      value={templateOptions?.find(
                        (opt) => opt.value === selectedTemplateOption
                      )}
                      isSearchable={false}
                      isLoading={isLoading}
                      isDisabled={!templateOptions}
                      menuPosition="static"
                    />
                    <div>
                      <PlayerComparisonLabel>
                        Display mode
                      </PlayerComparisonLabel>
                      <ButtonGroup>
                        <Button
                          id="display-mode-trait"
                          size="small"
                          onClick={() => setDisplayMode(TRAIT)}
                          shape="pill"
                          variant={displayMode === TRAIT ? 'primary' : 'ghost'}
                          disabled={isLoading}
                        >
                          Trait
                        </Button>
                        <Button
                          id="display-mode-performance"
                          size="small"
                          onClick={() => setDisplayMode(PERFORMANCE)}
                          shape="pill"
                          variant={
                            displayMode === PERFORMANCE ? 'primary' : 'ghost'
                          }
                          disabled={isLoading}
                        >
                          Performance
                        </Button>
                        <Button
                          id="display-mode-both"
                          size="small"
                          onClick={() => setDisplayMode(BOTH)}
                          shape="pill"
                          variant={displayMode === BOTH ? 'primary' : 'ghost'}
                          disabled={isLoading}
                        >
                          Both
                        </Button>
                      </ButtonGroup>
                    </div>
                    <Toggle
                      id="player-links"
                      onChange={() =>
                        setPlayerLinkComparison(!playerLinkComparison)
                      }
                      checked={playerLinkComparison}
                      label={`Link players to ${
                        playerLinkComparison ? 'Comparison' : 'Overview'
                      }`}
                    />
                    {isNcaa && (
                      <Toggle
                        id="player-comparison-ncaa"
                        onChange={() =>
                          setOnlyTransferPortal(!onlyTransferPortal)
                        }
                        checked={onlyTransferPortal}
                        label="NCAA Transfer Portal"
                      />
                    )}
                  </Tile.AccordionBody>
                }
                isExpandedDefault
                isMount
              />

              <AccordionTile
                header={<Tile.AccordionHeader>Filters</Tile.AccordionHeader>}
                body={
                  <Tile.AccordionBody>
                    <>
                      <div>
                        <ButtonGroup>
                          <Button
                            id="filter-all-plays"
                            size="small"
                            onClick={() => setAllPlays(true)}
                            shape="pill"
                            variant={allPlays ? 'primary' : 'ghost'}
                            disabled={isLoading}
                          >
                            All Plays
                          </Button>
                          <Button
                            id="filter-by-alignment"
                            size="small"
                            onClick={() => setAllPlays(false)}
                            shape="pill"
                            variant={!allPlays ? 'primary' : 'ghost'}
                            disabled={isLoading}
                          >
                            By Alignment
                          </Button>
                        </ButtonGroup>
                      </div>

                      {!allPlays && (
                        <PositionFilter
                          selectedPositions={alignmentPositionsApiCodes}
                          setSelectedPositions={setAlignmentPositionsApiCodes}
                          isRosterPositions={false}
                          positionUsages={positionUsages}
                        />
                      )}

                      <RCSliderWrapper
                        className={
                          comparisonMode === COMPARE_SELECTED ? 'disabled' : ''
                        }
                      >
                        <Slider
                          id="min-plays-slider"
                          ariaLabel="min-plays-slider"
                          max={2000}
                          min={0}
                          step={25}
                          title="Minimum Plays"
                          value={
                            comparisonMode === COMPARE_SIMILAR ? minPlays : 0
                          }
                          onChange={setMinPlays}
                          reverse
                        />
                      </RCSliderWrapper>

                      <div>
                        <PlayerComparisonLabel>
                          Radar Weight (Trait/Performance)
                        </PlayerComparisonLabel>
                        <ButtonGroup>
                          {COMPARISON_WEIGHT_OPTIONS.map((weight, i) => (
                            <Button
                              id={`radar-weight-${weight.label}`}
                              size="small"
                              onClick={() => {
                                setRadarWeight(i);
                                setRenderData(false);
                              }}
                              shape="pill"
                              variant={i === radarWeight ? 'primary' : 'ghost'}
                              disabled={isLoading}
                            >
                              {weight.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </>
                  </Tile.AccordionBody>
                }
                isExpandedDefault
                isMount
              />
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayerComparison;
