import { CHART_SCALE_TYPE } from '../BasicChart/BasicChart.constants';

export const BAR_CLASSES = {
  AVERAGE_LINES: 'bar-chart-average-lines',
  GHOST_BARS: 'bar-chart-ghost-bars',
  BARS: 'bar-chart-bars',
  INTERACTION_BARS: 'bar-chart-tooltips',
};

export const BAR_AXES_SETUP = {
  X: {
    TYPE: CHART_SCALE_TYPE.CATEGORICAL,
    DOMAIN: null,
    PADDING: 0,
    PADDING_INNER: null,
    NICE: true,
    TICKS: null,
  },
  Y: {
    TYPE: CHART_SCALE_TYPE.NUMERIC,
    DOMAIN: null,
    PADDING: null,
    NICE: true,
    TICKS: null,
  },
};
