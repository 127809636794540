import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import { Dropdown } from '@statsbomb/kitbag-components';
import { GET_DRIVES } from './getDrives';
import {
  pf_Drive,
  pf_AllDrives,
  mf_Games,
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Players,
  pf_TeamPlay,
  mf_GameDetails,
} from '../../apollo';
import { replaceTokens } from '../../utils/helpers/general';
import {
  DROPDOWN_CONTAINER_WIDTH,
  DROPDOWN_SPECIAL_OPTIONS,
  SPECIAL_OPTIONS,
} from '../DropdownFilter/DropdownFilter.constants';
import { DropdownContainer } from '../DropdownFilter/DropdownFilter.styles';
import { getDriveIdsByParam } from './Drives.helpers';

const Drives = ({ onChange, optionAll }) => {
  const navigate = useNavigate();
  const [pageInfo] = useOutletContext();
  const { drives: drivesParam } = useParams();
  const gameDetails = useReactiveVar(mf_GameDetails);
  
  const { loading, data } = useQuery(GET_DRIVES, {
    skip: !Object.keys(mf_GameDetails).length,
    onCompleted: (drivesData) => {
      const driveIds = getDriveIdsByParam(drivesData, drivesParam, gameDetails);
      pf_AllDrives(driveIds);
    },
  });

  const drivesDrop = data?.drives?.edges?.map(({ node: { id, name } }) => ({
    label: name,
    value: id,
  }));

  const extraOptions = [
    { value: SPECIAL_OPTIONS.ALL, label: 'All Drives' },
    {
      value: SPECIAL_OPTIONS.HOME,
      label: `All ${gameDetails?.homeTeam?.name}`,
    },
    {
      value: SPECIAL_OPTIONS.AWAY,
      label: `All ${gameDetails?.awayTeam?.name}`,
    },
  ];

  if (optionAll) {
    drivesDrop?.unshift(...extraOptions);
  }

  const selectedDrive = drivesDrop?.find(
    (option) => option.value === drivesParam
  );
  const minOptions = optionAll ? extraOptions.length : 0;

  useEffect(() => {
    if (!selectedDrive && drivesDrop?.length > minOptions) {
      pf_Drive(drivesDrop[minOptions]?.value);

      const instantRV = {
        leagues: mf_Leagues(),
        players: mf_Players(),
        seasons: mf_Seasons(),
        teams: mf_Teams(),
        games: mf_Games(),
        drives: pf_Drive(),
        plays: pf_TeamPlay(),
      };

      navigate(
        `/${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: true }
      );
    } else if (DROPDOWN_SPECIAL_OPTIONS.includes(pf_Drive())) {
      const driveIds = getDriveIdsByParam(data, drivesParam, gameDetails);
      driveIds && driveIds.length && pf_AllDrives(driveIds);
    } else {
      pf_Drive(drivesParam); // Keep pf_Drive as is
    }
  }, [data, selectedDrive]);
  return (
    <DropdownContainer className={DROPDOWN_CONTAINER_WIDTH.EXTRA_WIDE}>
      <Dropdown
        id="drives-dropdown"
        options={drivesDrop}
        label="Drives"
        onChange={onChange}
        value={selectedDrive || null}
        isLoading={loading}
      />
    </DropdownContainer>
  );
};

Drives.propTypes = {
  onChange: PropTypes.func,
  optionAll: PropTypes.bool,
};

Drives.defaultProps = {
  onChange: () => {},
  optionAll: false,
};

export default Drives;
