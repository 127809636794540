import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Icon } from '@statsbomb/kitbag-components';
import { AccordionTileHeader } from './PlayByPlayAccordionTileHeader.styles';
import { millisecondsToTime } from '../../../../utils/helpers/time';
import { getYardlineName, getYardsWon } from '../../../../utils/helpers/play';
import { mf_Teams } from '../../../../apollo';

const PlayByPlayAccordionTileHeader = ({
  id,
  name,
  team,
  gameIndex,
  playCount,
  startQuarter,
  quarterClockRemaining,
  homeScore,
  awayScore,
  homeTeamId,
  startingYards,
  yardsNet,
  selectedTeamColor,
  opponentTeamColor,
}) => {
  const selectedTeam = useReactiveVar(mf_Teams);
  const driveResult = name?.split('→ ')[1] || '';

  const driveYardage = `${getYardlineName(startingYards)}, ${getYardsWon(
    yardsNet
  )}`;

  // whether the attacking team is the selected team
  const isSelectedTeam = selectedTeam === team.id;
  const teamColor = isSelectedTeam ? selectedTeamColor : opponentTeamColor;
  const isHomeTeam = selectedTeam === homeTeamId;
  const selectedTeamScore = isHomeTeam ? homeScore : awayScore;
  const opponentTeamScore = isHomeTeam ? awayScore : homeScore;

  return (
    <AccordionTileHeader id={`drive-${id}`} $teamColor={teamColor}>
      <span
        className="drive-index"
        title="Drive Index, sequential through the game"
      >
        {gameIndex}
      </span>
      <span
        className="drive-clock"
        title="Clock Time for the quarter at the beginning of the drive"
      >
        <span>{`Q${startQuarter}`}</span>
        <span>{millisecondsToTime(quarterClockRemaining)}</span>
      </span>
      <span
        className="drive-team-on-offense"
        title="Team on Offense for this drive"
      >
        <span className="team-indicator">
          <Icon
            variant={isSelectedTeam ? 'HalfMoonLeft' : 'HalfMoonTop'}
            size="tiny"
          />
          <span>{team?.name}</span>
        </span>
        <span title="Count of plays in the drive">{`${playCount} plays in drive`}</span>
      </span>
      <span className="drive-result" title="End action of the drive">
        <span>{driveResult}</span>
        <span title="Starting Yardline of the drive, and total yards gained">
          {driveYardage}
        </span>
      </span>
      <span className="drive-scores">
        <span
          className="drive-away-score"
          title="Away Team Score at the beginning of the drive"
        >
          {selectedTeamScore}
        </span>
        <span
          className="drive-home-score"
          title="Home Team Score at the beginning of the drive"
        >
          {opponentTeamScore}
        </span>
      </span>
    </AccordionTileHeader>
  );
};

PlayByPlayAccordionTileHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  team: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  gameIndex: PropTypes.number,
  playCount: PropTypes.number,
  startQuarter: PropTypes.number,
  quarterClockRemaining: PropTypes.number,
  homeScore: PropTypes.number,
  awayScore: PropTypes.number,
  homeTeamId: PropTypes.number,
  startingYards: PropTypes.number,
  yardsNet: PropTypes.number,
  selectedTeamColor: PropTypes.string,
  opponentTeamColor: PropTypes.string,
};

PlayByPlayAccordionTileHeader.defaultProps = {
  id: 'some-uuid',
  name: 'dX Q0 -> Victory',
  team: { id: '0', name: 'Fighting Demons' },
  gameIndex: 0,
  playCount: 0,
  startQuarter: 0,
  quarterClockRemaining: -15000,
  homeScore: 99,
  awayScore: 99,
  homeTeamId: '-1',
  startingYards: 35,
  yardsNet: 20,
  selectedTeamColor: 'Red',
  opponentTeamColor: 'Blue',
};

export default PlayByPlayAccordionTileHeader;
