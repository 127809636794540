import { useQuery } from '@apollo/client';
import { SEARCH_HIGH_SCHOOLS } from './searchHighSchools';
import { ERROR_CODES } from '../../../../../utils/errorCodes/errorCodes';
import useDebounce from '../../../../../utils/hooks/useDebounce';
import { toTitleCase } from '../../../../../utils/helpers/general';

export const useSearchHighSchools = (searchString) => {
  const debouncedSearchString = useDebounce(searchString, 400);

  const { data, loading, error } = useQuery(SEARCH_HIGH_SCHOOLS, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    variables: {
      searchString: debouncedSearchString,
    },
  });

  if (loading) {
    return { isLoading: true };
  }
  if (error) {
    console.error(ERROR_CODES.USE_SEARCH_HIGH_SCHOOLS, error);
    return {
      isLoading: false,
      error,
    };
  }

  const highSchoolsRaw = data.searchHighSchools;
  const highSchools = highSchoolsRaw?.map((hs) => ({
    ...hs,
    name: toTitleCase(hs.name),
    city: toTitleCase(hs.city),
  }));
  return {
    isLoading: false,
    highSchools,
  };
};
