export const COMPARISON_SUMMARY_TABLE_HEADERS = [
  {
    label: 'Player',
    key: 'player',
  },
  {
    label: 'Team',
    key: 'team',
  },
  {
    label: 'Similarity',
    key: 'similarity',
  },
];
