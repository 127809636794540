import { clamp, maxBy, sortBy } from 'lodash';

const HISTOGRAM_DOMAIN_EDGE_MODES = {
  EXCLUDE: "Items outside of the domain aren't counted",
  EXTRA_BUCKETS:
    'Items outside the domain are added as additional over/under buckets as required',
  EDGE_BUCKETS: 'Items outside the domain are counted in the min/max buckets',
};

const getBinnedData = (
  tackleData,
  datumValueFunction,
  domain = [0, 1],
  bucketCount = 10,
  domainEdgeMode = HISTOGRAM_DOMAIN_EDGE_MODES.EXCLUDE
) => {
  const bucketInts = [...Array(bucketCount).keys()];
  const bucketSize = (domain[1] - domain[0]) / bucketCount;
  const buckets = bucketInts.map((m, i) => {
    const bucketMin = domain[0] + m * bucketSize;
    const bucketMax = domain[0] + (m + 1) * bucketSize;
    const valCount = tackleData.filter(
      (f) =>
        datumValueFunction(f) >= bucketMin && datumValueFunction(f) < bucketMax
    ).length;

    return { i, bucketMin, bucketMax, valCount };
  });
  const outOfDomainUnder = tackleData.filter(
    (f) => datumValueFunction(f) < domain[0]
  ).length;
  const outOfDomainOver = tackleData.filter(
    (f) => datumValueFunction(f) >= domain[1]
  ).length;
  if (domainEdgeMode === HISTOGRAM_DOMAIN_EDGE_MODES.EDGE_BUCKETS) {
    buckets[0].valCount += outOfDomainUnder;
    buckets[bucketCount - 1].valCount += outOfDomainOver;
  }
  if (domainEdgeMode === HISTOGRAM_DOMAIN_EDGE_MODES.EXTRA_BUCKETS) {
    const underBucket = {
      i: -1,
      bucketMin: null,
      bucketMax: domain[0],
      valCount: outOfDomainUnder,
    };
    const overBucket = {
      i: bucketCount,
      bucketMin: domain[1],
      bucketMax: null,
      valCount: outOfDomainOver,
    };
    buckets.push(underBucket, overBucket);
  }

  /* Normalize the values by the max to get sizing. */
  const maxCount = maxBy(buckets, 'valCount').valCount;
  const normalizedCounts = buckets.map((m) => ({
    ...m,
    maxCount,
    valNormalized: m.valCount / maxCount,
    iNormalized: m.i / (bucketCount - 1),
    iMidpointNormalized: (m.i + 0.5) / bucketCount,
  }));

  return normalizedCounts;
};

const normalizeLAData = (dataLA, domain, datumKey) => {
  const domainData = sortBy(
    dataLA.filter((d) => d.bucketmin >= domain[0] && d.bucketmin < domain[1]),
    'bucketmin'
  );
  const maxCountLA = maxBy(domainData, datumKey)[datumKey];
  const bucketCount = domainData.length;
  const normalizedDataLA = domainData.map((bucket, i) => ({
    i,
    bucketMin: bucket.bucketmin,
    bucketMax: bucket.bucketmax,
    valCount: bucket[datumKey],
    maxCount: maxCountLA,
    valNormalized: bucket[datumKey] / maxCountLA,
    iNormalized: i / (bucketCount - 1),
    iMidpointNormalized: (i + 0.5) / bucketCount,
  }));
  return normalizedDataLA;
};

const addBucketDots = (normalizedCounts, rowMax, maxDots = 10) => {
  const dotWorth = rowMax / maxDots; // could be fractional here
  const dotVals = normalizedCounts.map((d) => {
    const dotCount = Math.ceil(d.valCount / dotWorth);
    const emptyLastDot = dotCount > Math.round(d.valCount / dotWorth);
    const dots = dotCount
      ? [...Array(dotCount).keys()].map((index) => ({
          index,
          isEmpty: index === dotCount - 1 && emptyLastDot,
        }))
      : []; // splits go from field min to field max
    const moddedVal = {
      ...d,
      dots,
    };
    return moddedVal;
  });
  return dotVals;
};

const getPercentileValue = (sortedNumbers, percentile) => {
  const fraction = clamp(0, percentile, 1);
  const index = Math.floor(sortedNumbers.length * fraction) - 1; // account for 0-based array indexing
  const i = clamp(0, index, sortedNumbers.length - 1);
  const value = sortedNumbers[i];
  return value;
};

const getQuartiles = (objectData, datumFn) => {
  const numericData = objectData.map(datumFn).sort((a, b) => a - b);
  const p0 = getPercentileValue(numericData, 0);
  const p25 = getPercentileValue(numericData, 0.25);
  const p50 = getPercentileValue(numericData, 0.5);
  const p75 = getPercentileValue(numericData, 0.75);
  const p100 = getPercentileValue(numericData, 1);
  return { p0, p25, p50, p75, p100, numericData };
};

export {
  HISTOGRAM_DOMAIN_EDGE_MODES,
  getBinnedData,
  addBucketDots,
  getQuartiles,
  normalizeLAData,
};
