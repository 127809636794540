import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import { GET_TEAM_FORMATION_LOCATIONS } from './getTeamFormationLocations';

/**
 * custom hook to get the positions locations of a given team's formation
 */
export const useTeamFormationLocations = (formation) => {
  const { data, loading, error } = useQuery(GET_TEAM_FORMATION_LOCATIONS, {
    variables: {
      formation,
    },
    skip: !formation,
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_FORMATION_LOCATIONS, error);
    return { error, loading: false };
  }

  const locations = data?.formationLocations.backfieldLocs;

  return { data: locations, loading };
};
