export const DEFAULT_FONT = 'Inter, sans-serif';

/* Font setups for different parts of visualisations */
const KITBAG_TYPOGRAPHY = {
  HEADING_LARGE: { SIZE: 18, WEIGHT: 700, LINE_HEIGHT: 26 },
  HEADING_REGULAR: { SIZE: 16, WEIGHT: 600, LINE_HEIGHT: 24 },
  HEADING_MEDIUM: { SIZE: 14, WEIGHT: 600, LINE_HEIGHT: 20 },
  HEADING_SMALL: { SIZE: 12, WEIGHT: 600, LINE_HEIGHT: 18 },
  BODY_REGULAR: { SIZE: 14, WEIGHT: 400, LINE_HEIGHT: 20 },
  BODY_MEDIUM: { SIZE: 12, WEIGHT: 400, LINE_HEIGHT: 18 },
  BODY_SMALL: { SIZE: 10, WEIGHT: 400, LINE_HEIGHT: 16 },
};
export const DEFAULT_FONT_SIZE = KITBAG_TYPOGRAPHY.BODY_MEDIUM.SIZE; // 12px
/* 
Documentation about usage can be found here:
https://www.notion.so/statsbomb/Visualisation-Text-Font-900e907001a949468a59b1e85aea0b1e 
*/
export const VISUALISATION_FONT_SETUPS = {
  /* Generic Vis Parts */
  AXES_VALUES: KITBAG_TYPOGRAPHY.BODY_MEDIUM,
  AXES_VALUES_SMALL: KITBAG_TYPOGRAPHY.BODY_SMALL,
  AXES_LABELS: KITBAG_TYPOGRAPHY.BODY_REGULAR,
  DATUM_LABEL: KITBAG_TYPOGRAPHY.BODY_SMALL,
  ICON_LABEL: { SIZE: 8, WEIGHT: 600, LINE_HEIGHT: 12 },
  AREA_TITLE: KITBAG_TYPOGRAPHY.HEADING_SMALL,
  INFO_LABEL: KITBAG_TYPOGRAPHY.BODY_MEDIUM,
  INFO_LABEL_SMALL: KITBAG_TYPOGRAPHY.BODY_SMALL,
  /* snap formations ~ field width segments get shrunk to tiles, so have to proportionally scale up */
  TILE_HEADER: { SIZE: 18, WEIGHT: 400, LINE_HEIGHT: 26 },
  TILE_SUBHEADER: { SIZE: 17, WEIGHT: 400, LINE_HEIGHT: 26 },
  TILE_INFO: { SIZE: 16, WEIGHT: 400, LINE_HEIGHT: 24 },
  /* Header Section (Export) */
  HEADER_TITLE: KITBAG_TYPOGRAPHY.HEADING_LARGE,
  HEADER_SUB_TITLE: KITBAG_TYPOGRAPHY.HEADING_REGULAR,
  HEADER_SUB_SUB_TITLE: KITBAG_TYPOGRAPHY.HEADING_MEDIUM,
  /* Header Section (Export) ~ secondary header */
  HEADER_INFO: KITBAG_TYPOGRAPHY.HEADING_SMALL,
  HEADER_LABEL: KITBAG_TYPOGRAPHY.BODY_MEDIUM,
  /* Key / Legend */
  KEY_TITLE: KITBAG_TYPOGRAPHY.HEADING_MEDIUM, // rarely needed
  KEY_SECTION_HEADER: KITBAG_TYPOGRAPHY.HEADING_SMALL, // parts of keys/legends
  KEY_LABEL: KITBAG_TYPOGRAPHY.BODY_MEDIUM, // normal key labels
  KEY_LABEL_SMALL: KITBAG_TYPOGRAPHY.BODY_SMALL, // additional key information
};

/*
The declared font size (e.g. 10px) generates text where any given character takes up only a proportion of that value
  i.e. because if "o" sits on the line "p" drops a tail under and "b" extends one above. 
  The amount of a declared font size that Inter takes up for an uppercase letter/number is 0.73
https://www.notion.so/statsbomb/Line-Height-an-SVG-problem-9b93b97afc1747df84f7730218df9669
*/
export const DEFAULT_FONT_TEXT_PROPORTION = 0.73;
