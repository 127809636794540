import { sortBy } from 'lodash';
import { API_ALIGNMENT_POSITION_KEYS } from '../../../utils/constants/api';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';

export const FIELD_X_YARDS_PASSING_PRE_LOS = 20;
const FIELD_X_YARDS_PASSING_POST_LOS = 60;
export const FIELD_X_YARDS_PASSING_FRAME =
  FIELD_X_YARDS_PASSING_PRE_LOS + FIELD_X_YARDS_PASSING_POST_LOS;

/**
 * @param {Number} total
 * @param {Object} row
 * @param {Boolean} row.success //or equivalent property check
 * @return {Number}
 */
export const countSuccessful = (total, { success }) =>
  success ? total + 1 : total;

const countTouchdowns = (total, { passTouchdown }) =>
  passTouchdown ? total + 1 : total;
const countIncomplete = (total, { success }) => (success ? total : total + 1);
const countInterceptions = (total, { catchInterception }) =>
  catchInterception ? total + 1 : total;
const countOuts = (total, { isOut }) => (isOut ? total + 1 : total);
const countUnderthrows = (total, { passUnderthrow }) =>
  passUnderthrow ? total + 1 : total;
const countDefenderTouchedFails = (total, { defenderTouched, success }) =>
  defenderTouched && !success ? total + 1 : total;
const countOverthrows = (total, { passOverthrow }) =>
  passOverthrow ? total + 1 : total;
const countThrowaways = (total, { passThrowaway }) =>
  passThrowaway ? total + 1 : total;

export const PASS_OUTCOME_UNSELECTED = {
  value: '',
  label: 'All Passes',
  order: 0,
  isChild: false,
};
export const PASS_OUTCOME_TYPES = {
  COMPLETE: {
    value: 'COMPLETE',
    label: 'Complete Passes',
    order: 1,
    isChild: false,
    filter: (event) => event.success,
    counter: countSuccessful,
  },
  TOUCHDOWN: {
    value: 'TOUCHDOWN',
    label: 'Touchdowns',
    order: 2,
    isChild: true,
    filter: (event) => event.passTouchdown,
    counter: countTouchdowns,
  },
  INCOMPLETE: {
    value: 'INCOMPLETE',
    label: 'Incomplete Passes',
    order: 10,
    isChild: false,
    filter: (event) => !event.success,
    counter: countIncomplete,
  },
  INTERCEPTION: {
    value: 'INTERCEPTION',
    label: 'Interceptions',
    order: 11,
    isChild: true,
    filter: (event) => event.catchInterception,
    counter: countInterceptions,
  },
  OUT_OF_BOUNDS: {
    value: 'OUT_OF_BOUNDS',
    label: 'Out of bounds',
    order: 12,
    isChild: true,
    filter: (event) => event.isOut,
    counter: countOuts,
  },
  THROWAWAY: {
    value: 'THROWAWAY',
    label: 'Throwaways',
    order: 13,
    isChild: true,
    filter: (event) => event.passThrowaway,
    counter: countThrowaways,
  },
  DEFENDER_TOUCHED: {
    value: 'DEFENDER_TOUCHED',
    label: 'Defender Touched',
    order: 14,
    isChild: true,
    filter: (event) => event.defenderTouched,
    counter: countDefenderTouchedFails,
  },
  UNDERTHROW: {
    value: 'UNDERTHROW',
    label: 'Underthrows',
    order: 15,
    isChild: true,
    filter: (event) => event.passUnderthrow,
    counter: countUnderthrows,
  },
  OVERTHROW: {
    value: 'OVERTHROW',
    label: 'Overthrows',
    order: 16,
    isChild: true,
    filter: (event) => event.passOverthrow,
    counter: countOverthrows,
  },
};
export const PASS_OUTCOMES = Object.values(PASS_OUTCOME_TYPES);
export const PASS_OUTCOME_OPTIONS = sortBy(
  PASS_OUTCOMES.concat(PASS_OUTCOME_UNSELECTED),
  'order'
);

export const TARGET_ANY_ALIGNMENT = 'ANY';
export const TARGET_ALIGNMENT_OPTIONS = [
  {
    value: TARGET_ANY_ALIGNMENT,
    label: 'Any',
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.WIDE_RECEIVER].name,
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.SLOT_RECEIVER].name,
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.TIGHT_END].name,
  },
  {
    value: API_ALIGNMENT_POSITION_KEYS.RUNNING_BACK,
    label: ALIGNMENT_POSITIONS[API_ALIGNMENT_POSITION_KEYS.RUNNING_BACK].name,
  },
];
