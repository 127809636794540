import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_DEFENSE_TACKLING_PLAYS = gql`
  query GetDefenseTacklingPlays(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    tackleFirstContact(
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        defenseTeamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      items {
        play {
          id
          name
          drive {
            id
            name
          }
          game {
            id
            name
          }
          driveIndex
          offenseTeam {
            id
            name
          }
          defenseTeam {
            id
            name
          }
          yardLine
          snapY
          type
          success
          touchdownWon
          fumbled
          tackleSuccess
          yardsNet
          turnoverType
          downNegated
          qbSacked
          explosive
        }
        # types
        ballCarrier {
          id
          name
          teamPosition {
            id
            name
            code
            generalPosition
          }
        }
        ballCarrierPosition
        finalBallX
        finalBallY
        attempts {
          videoTimestamp
          attemptX
          attemptY
          contact
          success
          tacklerPosition
          tackler {
            id
            name
            teamPosition {
              id
              name
              code
              generalPosition
            }
          }
          tackleType
        }
      }
    }
  }
`;
