/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@statsbomb/kitbag-components';
import useTypingState from '../../utils/hooks/useTypingState';
import { keys } from '../../utils/constants/keys';
import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';
import {
  StyledLocalSearch,
  StyledLocalSearchElement,
} from './LocalSearch.styles';
import { SEARCH_METADATA_PARAM } from './searchMetadataWithParam';

const LocalSearch = ({ handleResult, filterResultIds }) => {
  const [isTyping, setIsTyping, searchString, setSearchValue] =
    useTypingState();

  const {
    data: searchData,
    loading: isLoading,
    error: searchError,
  } = useQuery(SEARCH_METADATA_PARAM, {
    skip: isTyping,
    variables: {
      searchString,
    },
    headers: {
      'Accept-Version': 'v2023q2',
    },
  });

  if (searchError) {
    console.error(ERROR_CODES.SEARCH_METADATA, searchError);
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setIsTyping(true);
  };

  // https://github.com/Semantic-Org/Semantic-UI-React/issues/3389
  // Search.close() within Search._selectItemOnEnter throws a no-op error
  const handleResultSelect = (e, data) => {
    if (e.key === keys.ENTER) {
      handleResult(data.result.player);
    }
  };

  const ResultRenderer = ({
    player: playerId,
    playername: playerName,
    teamname: teamName,
    position,
  }) => (
    <Button variant="ghost" size="small" onClick={() => handleResult(playerId)}>
      {`${playerName} (${position}) - ${teamName}`}
    </Button>
  );

  // filter IDs from the search results
  const parsedSearch = searchData?.searchMetadata.filter(
    (obj) => !filterResultIds.includes(obj.id)
  );

  // https://github.com/Semantic-Org/Semantic-UI-React/issues/1141
  // requires fields for "title", "description", "price" and "image"
  // component spreads data onto the DOM element so no camelCase
  const parsedResults = parsedSearch?.map((result) => ({
    title: '',
    description: '',
    price: '',
    image: '',
    player: result.id,
    playername: result.name,
    team: result.team.id,
    teamname: result.team.shortName,
    competition: result.team.competition?.id,
    position: result.mostCommonPosition.code,
    season: result.season.id,
    key: result.id,
  }));

  return (
    <StyledLocalSearch>
      <StyledLocalSearchElement
        id="local-search-input"
        fluid
        input={{
          icon: 'search',
          iconPosition: 'left',
          type: 'search',
          'aria-label': 'localSearchInput',
        }}
        onSearchChange={handleSearch}
        onResultSelect={handleResultSelect}
        value={searchString}
        showNoResults={false}
        loading={isLoading}
        resultRenderer={ResultRenderer}
        results={parsedResults}
        data-testid="SearchTest"
      />
    </StyledLocalSearch>
  );
};

LocalSearch.propTypes = {
  handleResult: PropTypes.func,
  // fitler these IDs out of the search results
  filterResultIds: PropTypes.arrayOf(PropTypes.number),
};

LocalSearch.defaultProps = {
  handleResult: () => {},
  filterResultIds: [],
};

export default LocalSearch;
