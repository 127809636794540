// sourced from: https://www.pluralsight.com/guides/using-d3.js-inside-a-react-app

import React, { useEffect } from 'react';
import { select } from 'd3';

export const useD3 = (renderChartFn, dependencies) => {
  const ref = React.useRef();

  useEffect(() => {
    renderChartFn(select(ref.current));
    return () => {};
  }, dependencies);
  return ref;
};
