import {
  COMPETITION_LEVEL,
  FIELD_MEASURES,
  getHashYFromCompLevel,
  FIELD_NUMBERS_FONT,
} from './field.constants';
import { isEven } from './general';

const drawPost = function (
  svgG,
  { pxPerYard, visPalette, goalPostStrokeWidthPx, competitionLevel },
  drawHomePosts
) {
  const goalPostWidth =
    competitionLevel === COMPETITION_LEVEL.HIGH_SCHOOL
      ? FIELD_MEASURES.GOAL_POST_WIDTH_HS
      : FIELD_MEASURES.GOAL_POST_WIDTH;

  const goalPostY = (FIELD_MEASURES.Y_YARDS - goalPostWidth) / 2; // 60ft = 20yds in NFL

  const goalPostDepth = drawHomePosts ? -0.5 * pxPerYard : 0.5 * pxPerYard;
  const goalPostM = drawHomePosts
    ? 2 * goalPostDepth
    : FIELD_MEASURES.X_YARDS * pxPerYard + 2 * goalPostDepth;

  svgG
    .append('svg:path')
    .attr(
      'd',
      `M${goalPostM},${goalPostY * pxPerYard}` +
        ` l${-1 * goalPostDepth},0` +
        ` l0,${goalPostWidth * pxPerYard}` +
        ` l${goalPostDepth},0` +
        ` M${goalPostM - goalPostDepth},${
          (goalPostY + goalPostWidth / 2) * pxPerYard
        }` +
        ` l${-1 * goalPostDepth},0`
    )
    .attr('stroke', visPalette.border)
    .attr('fill', 'none')
    .attr('stroke-width', goalPostStrokeWidthPx);
};

/* Draw Goal Post Symbols just outside the field boundaries
note: High School Goal Posts are a different size
*/
const addPosts = function (svgG, fieldSettings) {
  svgG.call(drawPost, fieldSettings, true).call(drawPost, fieldSettings, false);
};

/* Draw Oscillating 10yds zones
Zones have a hard border every 10yds and soft one every 5 yards
Default height is field, but can be overridden for relative-y fields
Default zones is 12 (including end zones), but can be overriden
*/
const addOddEvenZones = function (
  svgG,
  { pxPerYard, visPalette },
  fieldHeightYds = FIELD_MEASURES.Y_YARDS,
  tenZonesToDraw = 12,
  isFlipped = false
) {
  const tenZoneIndexes = [...Array(tenZonesToDraw).keys()];
  const fiveZoneIndexes = [...Array(tenZonesToDraw * 2).keys()];
  const tenYardsPx = 10 * pxPerYard;
  const defaultZoneColor = isFlipped
    ? visPalette.zones.alternate
    : visPalette.zones.default;
  const alternateZoneColor = isFlipped
    ? visPalette.zones.default
    : visPalette.zones.alternate;

  const tensArea = svgG.append('g').attr('id', 'ten-yd-cells');
  tensArea
    .selectAll('rect')
    .data(tenZoneIndexes)
    .enter()
    .append('rect')
    .attr('fill', (d) => (isEven(d) ? alternateZoneColor : defaultZoneColor))
    .attr('x', (d) => d * tenYardsPx)
    .attr('y', 0)
    .attr('height', fieldHeightYds * pxPerYard)
    .attr('width', tenYardsPx);
  tensArea
    .append('g')
    .attr('id', 'ten-yd-cell-markings')
    .selectAll('line')
    .data(fiveZoneIndexes)
    .enter()
    .append('line')
    .attr('stroke', visPalette.guides)
    .attr('stroke-width', '1px')
    .attr('stroke-dasharray', `${pxPerYard / 3} ${pxPerYard / 3}`)
    .attr('x1', (d) => d * 5 * pxPerYard)
    .attr('x2', (d) => d * 5 * pxPerYard)
    .attr('y1', 0)
    .attr('y2', fieldHeightYds * pxPerYard);
};

/* Draw a row of vertical dashes on the field
These are the hash marks, and edge markings
Function expects to be called from a G at the level of the bottom of the hashes
    (the official field sizes are for the distance field edge to inner point
        , and the dashes are drawn out from this point)
*/
const drawHashMarkRow = function (
  svgG,
  { visPalette, strokeWidthPx, pxPerYard },
  hashesToDraw
) {
  const hashesIndexes = [...Array(hashesToDraw).keys()];
  svgG
    .selectAll('line')
    .data(hashesIndexes)
    .enter()
    .append('line')
    .attr('stroke', visPalette.guides)
    .attr('stroke-width', strokeWidthPx)
    .attr('x1', (d) => d * pxPerYard)
    .attr('x2', (d) => d * pxPerYard)
    .attr('y1', 0)
    .attr('y2', -1 * FIELD_MEASURES.HASH_MARK_WIDTH * pxPerYard);
};

/* Draw a row of horizontal dashes on the field
These are used in combination with the hash marks (not edges)
Function expects to be called from the same G as the hashes it is topping
*/
const drawHashMarkToppersRow = function (
  svgG,
  { visPalette, strokeWidthPx, pxPerYard },
  drawAboveHashes, // i.e. at a lower y value / nearer left field edge
  hashTopsToDraw
) {
  const oneNineteen = [...Array(hashTopsToDraw).keys()];
  oneNineteen.shift();
  const topperSpacingYards = 5;
  const topperWidthYds = 1;
  const topperY = drawAboveHashes
    ? -1 * FIELD_MEASURES.HASH_MARK_WIDTH * pxPerYard
    : 0;

  svgG
    .selectAll('path')
    .data(oneNineteen)
    .enter()
    .append('svg:path')
    .attr('stroke', visPalette.guides)
    .attr('stroke-width', strokeWidthPx)
    .attr('fill', 'none')
    .attr(
      'd',
      (d) =>
        `M${d * topperSpacingYards * pxPerYard},${topperY} l-${
          (topperWidthYds / 2) * pxPerYard
        },0 l${topperWidthYds * pxPerYard},0`
    );
};

/* Draw the hash marks on the field
These repeat every 1yd down the x-axis of the field
They are the middle markings that change with competition level
horizontal dashes occur every 5 yards at the point innermost to the field center
*/
const addCompetitionHashMarks = function (
  svgG,
  fieldSettings,
  hashesToDraw = 120
) {
  const hashY = getHashYFromCompLevel(fieldSettings.competitionLevel);
  const hashY2 =
    FIELD_MEASURES.Y_YARDS - hashY + FIELD_MEASURES.HASH_MARK_WIDTH;
  const hashTopsToDraw = hashesToDraw / 5; // drawn every 5 yards

  svgG
    .append('g')
    .attr('id', 'hash-marks-upper')
    .attr('transform', `translate(${0},${hashY * fieldSettings.pxPerYard})`)
    .call(drawHashMarkRow, fieldSettings, hashesToDraw)
    .call(drawHashMarkToppersRow, fieldSettings, false, hashTopsToDraw);

  svgG
    .append('g')
    .attr('id', 'hash-marks-lower')
    .attr('transform', `translate(${0},${hashY2 * fieldSettings.pxPerYard})`)
    .call(drawHashMarkRow, fieldSettings, hashesToDraw)
    .call(drawHashMarkToppersRow, fieldSettings, true, hashTopsToDraw);
};

/* Draw the dashes (look like hash marks) on the field at the field edges */
const addFieldEdgeMarks = function (
  svgG,
  fieldSettings,
  hashesToDraw = 120,
  fieldHeightYds = FIELD_MEASURES.Y_YARDS
) {
  svgG
    .append('g')
    .attr('id', 'hash-marks-top')
    .attr(
      'transform',
      `translate(${0},${
        (FIELD_MEASURES.HASH_MARK_EDGE_SPACE + FIELD_MEASURES.HASH_MARK_WIDTH) *
        fieldSettings.pxPerYard
      })`
    )
    .call(drawHashMarkRow, fieldSettings, hashesToDraw);

  svgG
    .append('g')
    .attr('id', 'hash-marks-bottom')
    .attr(
      'transform',
      `translate(${0},${
        (fieldHeightYds - FIELD_MEASURES.HASH_MARK_EDGE_SPACE) *
        fieldSettings.pxPerYard
      })`
    )
    .call(drawHashMarkRow, fieldSettings, hashesToDraw);
};

/* Adds End Zones
This should be called after odd/even zones (it sits on top of them) 
Uses line not stroke to separate from main pitch without adding a full border
*/
const addEndZones = function (
  svgG,
  { pxPerYard, strokeWidthPx, colorEndZoneHome, colorEndZoneAway, visPalette }
) {
  svgG
    .append('rect')
    .attr('id', 'end-zone-left')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', FIELD_MEASURES.END_ZONE_WIDTH * pxPerYard)
    .attr('height', FIELD_MEASURES.Y_YARDS * pxPerYard)
    .attr('fill', colorEndZoneHome || visPalette.zones.important)
    .attr('stroke', 'none');
  svgG
    .append('line')
    .attr('id', 'goal-line-left')
    .attr('x1', FIELD_MEASURES.END_ZONE_WIDTH * pxPerYard)
    .attr('x2', FIELD_MEASURES.END_ZONE_WIDTH * pxPerYard)
    .attr('y1', 0)
    .attr('y2', FIELD_MEASURES.Y_YARDS * pxPerYard)
    .attr('stroke', visPalette.border)
    .attr('stroke-width', strokeWidthPx);

  svgG
    .append('rect')
    .attr('id', 'end-zone-right')
    .attr(
      'x',
      (FIELD_MEASURES.X_YARDS - FIELD_MEASURES.END_ZONE_WIDTH) * pxPerYard
    )
    .attr('y', 0)
    .attr('width', FIELD_MEASURES.END_ZONE_WIDTH * pxPerYard)
    .attr('height', FIELD_MEASURES.Y_YARDS * pxPerYard)
    .attr('fill', colorEndZoneAway || visPalette.zones.important)
    .attr('stroke', 'none');
  svgG
    .append('line')
    .attr('id', 'goal-line-right')
    .attr(
      'x1',
      (FIELD_MEASURES.X_YARDS - FIELD_MEASURES.END_ZONE_WIDTH) * pxPerYard
    )
    .attr(
      'x2',
      (FIELD_MEASURES.X_YARDS - FIELD_MEASURES.END_ZONE_WIDTH) * pxPerYard
    )
    .attr('y1', 0)
    .attr('y2', FIELD_MEASURES.Y_YARDS * pxPerYard)
    .attr('stroke', visPalette.border)
    .attr('stroke-width', strokeWidthPx);
};

/* Add the final border around the whole field */
const addFieldBorder = function (
  svgG,
  { pxPerYard, visPalette, strokeWidthPx },
  heightYds = FIELD_MEASURES.Y_YARDS,
  widthYds = FIELD_MEASURES.X_YARDS
) {
  svgG
    .append('rect')
    .attr('id', 'field-border')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', widthYds * pxPerYard)
    .attr('height', heightYds * pxPerYard)
    .attr('fill', 'transparent')
    .attr('stroke', visPalette.border)
    .attr('stroke-width', strokeWidthPx);
};

/* 
Field Numbers are of fixed size:
    6' tall, 2' wide, 1' either side from the yardline they're denoting)
              |
    ________  |  ________
    |      |  |  |      |
    |      |  |  |      |   6'
    |      |  |  |      |
    ________  |  ________
              |
       4'      1'

Their location is also fixed, but like hashes, varyies with competition level
    The field is often divided based on the "top of the numbers" for analysis
    (the edge of the numbers nearest the center of the field)
Orientation of the numbers is thus fixed as "horizontal"
    Real fields always rotate so the text baseline is the field edge
    a legibility option is available here, but only makes sesne for a horizontal field
*/

const drawFieldNumbers = function (
  svg,
  { pxPerYard, visPalette },
  flipNumber = false,
  drawBoundingBox = false
) {
  const zeroEight = [...Array(9).keys()];
  const tenYards = 10;
  const firstNumbersMidpoint =
    (FIELD_MEASURES.END_ZONE_WIDTH + tenYards) * pxPerYard;

  const numbersZone = svg
    .append('g')
    .attr('id', 'ten-yd-numbers')
    .attr('transform', `translate(${firstNumbersMidpoint},0)`);

  /* Can be turned on to draw a bounding box around each number (more clearly define heights)
    dev note: Use to check sizing if changing font
  */
  if (drawBoundingBox) {
    const addFieldNumberBoundingBox = function (fieldBoxesG) {
      const tenZoneIndexes = [...Array(9).keys()];
      const tenYardsPx = 10 * pxPerYard;
      fieldBoxesG
        .selectAll('rect')
        .data(tenZoneIndexes)
        .enter()
        .append('rect')
        .attr('fill', 'transparent')
        .attr('stroke', visPalette.guides)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '1 1')
        .attr('x', (d) => d * tenYardsPx)
        .attr('y', 0)
        .attr('height', FIELD_MEASURES.FIELD_NUMBERS_HEIGHT * pxPerYard)
        .attr('width', FIELD_MEASURES.FIELD_NUMBERS_WIDTH * pxPerYard);
    };

    const fieldNumbersLeftBoxes = numbersZone
      .append('g')
      .attr('id', 'field-numbers-left-boxes')
      .attr(
        'transform',
        `translate(${
          -1 *
          (FIELD_MEASURES.FIELD_NUMBERS_WIDTH +
            FIELD_MEASURES.FIELD_NUMBERS_WIDTH_SPACING) *
          pxPerYard
        },0)`
      );

    fieldNumbersLeftBoxes.call(addFieldNumberBoundingBox);

    const fieldNumbersRightBoxes = numbersZone
      .append('g')
      .attr('id', 'field-numbers-right-boxes')
      .attr(
        'transform',
        `translate(${FIELD_MEASURES.FIELD_NUMBERS_WIDTH_SPACING * pxPerYard},0)`
      );
    fieldNumbersRightBoxes.call(addFieldNumberBoundingBox);
  }

  const numberHeight = FIELD_MEASURES.FIELD_NUMBERS_HEIGHT * pxPerYard;
  const numbersFontHeight = numberHeight * FIELD_NUMBERS_FONT.fieldNumberScaler;
  const yShift = flipNumber
    ? 0
    : FIELD_MEASURES.FIELD_NUMBERS_HEIGHT * pxPerYard;
  const numberRotation = flipNumber ? 180 : 0;
  /* For accurate placement, and control of rotation, easiest to split the numbers 
  i.e. instead of adding "20" add "2" and "0" independently */
  const fieldNumbersNonZero = numbersZone
    .append('g')
    .attr('id', 'field-numbers-non-zero')
    .attr(
      'transform',
      `translate(${
        -1 *
        (FIELD_MEASURES.FIELD_NUMBERS_WIDTH / 2 +
          FIELD_MEASURES.FIELD_NUMBERS_WIDTH_SPACING) *
        pxPerYard
      },${yShift})`
    );
  const fieldNumbersZeroes = numbersZone
    .append('g')
    .attr('id', 'field-numbers-zeroes')
    .attr(
      'transform',
      `translate(${
        (FIELD_MEASURES.FIELD_NUMBERS_WIDTH / 2 +
          FIELD_MEASURES.FIELD_NUMBERS_WIDTH_SPACING) *
        pxPerYard
      },${yShift})`
    );

  const drawNumbers = function (fieldNumbersG, isZeroes) {
    fieldNumbersG
      .selectAll('text')
      .data(zeroEight)
      .enter()
      .append('text')
      .attr('fill', visPalette.guides)
      .attr('x', 0)
      .attr('y', 0)
      .attr(
        'transform',
        (d) =>
          `translate(${
            d * tenYards * pxPerYard
          },0) rotate(${numberRotation},0,0)`
      )
      .attr('font-size', `${numbersFontHeight}px`)
      .attr('font-family', FIELD_NUMBERS_FONT.fontFamily)
      .attr('font-weight', FIELD_NUMBERS_FONT.fontWeight)
      .attr('text-anchor', 'middle')
      .text((d) => {
        if (isZeroes !== flipNumber) {
          return 0;
        }
        if (d <= 4) {
          return d + 1;
        }
        return 9 - d;
      });
  };

  fieldNumbersNonZero.call(drawNumbers, false);
  fieldNumbersZeroes.call(drawNumbers, true);

  /* Add directional arrows when not the 50 line (i.e. 10 yards to what)
   */
  const arrowTransform = function (n) {
    const arrowLeftRight = n <= 4 ? -1 : 1;
    const arrowShiftX =
      (FIELD_MEASURES.FIELD_NUMBERS_WIDTH +
        FIELD_MEASURES.FIELD_NUMBERS_WIDTH_SPACING +
        FIELD_MEASURES.FIELD_NUMBERS_ARROW_SPACING_X) *
      arrowLeftRight;
    const arrowShiftY = flipNumber
      ? FIELD_MEASURES.FIELD_NUMBERS_HEIGHT -
        FIELD_MEASURES.FIELD_NUMBERS_ARROW_SPACING_Y -
        FIELD_MEASURES.FIELD_NUMBERS_ARROW_Y
      : FIELD_MEASURES.FIELD_NUMBERS_ARROW_SPACING_Y;
    return `translate(${(n * tenYards + arrowShiftX) * pxPerYard},${
      arrowShiftY * pxPerYard
    })`;
  };
  const arrowShape = function (n) {
    let arrowD = '';
    if (n < 4) {
      arrowD = `M0 0 l-${FIELD_MEASURES.FIELD_NUMBERS_ARROW_X * pxPerYard} ${
        (FIELD_MEASURES.FIELD_NUMBERS_ARROW_Y / 2) * pxPerYard
      } l${FIELD_MEASURES.FIELD_NUMBERS_ARROW_X * pxPerYard} ${
        (FIELD_MEASURES.FIELD_NUMBERS_ARROW_Y / 2) * pxPerYard
      } z`;
    } else if (n > 4) {
      arrowD = `M0 0 l${FIELD_MEASURES.FIELD_NUMBERS_ARROW_X * pxPerYard} ${
        (FIELD_MEASURES.FIELD_NUMBERS_ARROW_Y / 2) * pxPerYard
      } l-${FIELD_MEASURES.FIELD_NUMBERS_ARROW_X * pxPerYard} ${
        (FIELD_MEASURES.FIELD_NUMBERS_ARROW_Y / 2) * pxPerYard
      } z`;
    }
    return arrowD;
  };

  numbersZone
    .selectAll('path')
    .data(zeroEight)
    .enter()
    .append('svg:path')
    .attr('d', (d) => arrowShape(d))
    .attr('fill', visPalette.guides)
    .attr('transform', (d) => arrowTransform(d));
};

const addFieldNumbers = function (svgG, fieldSettings) {
  const numberEdgeDistance =
    fieldSettings.competitionLevel === COMPETITION_LEVEL.NFL
      ? FIELD_MEASURES.FIELD_NUMBERS_EDGE_NFL * fieldSettings.pxPerYard
      : FIELD_MEASURES.FIELD_NUMBERS_EDGE_NCAA * fieldSettings.pxPerYard;

  const topNumbersG = svgG
    .append('g')
    .attr('id', 'top-numbers')
    .attr('transform', `translate(0,${numberEdgeDistance})`);
  drawFieldNumbers(
    topNumbersG,
    fieldSettings,
    !fieldSettings.fieldNumbersToFieldRight
  );

  const bottomNumbersG = svgG
    .append('g')
    .attr('id', 'bottom-numbers')
    .attr(
      'transform',
      `translate(0,${
        (FIELD_MEASURES.Y_YARDS - FIELD_MEASURES.FIELD_NUMBERS_HEIGHT) *
          fieldSettings.pxPerYard -
        numberEdgeDistance
      })`
    );
  drawFieldNumbers(bottomNumbersG, fieldSettings);
};

export {
  addPosts,
  addOddEvenZones,
  addCompetitionHashMarks,
  addFieldEdgeMarks,
  addEndZones,
  addFieldBorder,
  addFieldNumbers,
};
