import { gql } from '@apollo/client';

export const SEARCH_COLLEGES = gql`
  query SearchColleges($searchString: String!) {
    searchColleges(searchString: $searchString, maxResults: 10) {
      id
      name
      shortName
    }
  }
`;
