import { ButtonIcon, Dropdown } from '@statsbomb/kitbag-components';
import React, { useState } from 'react';
import { Grid, Message, Breadcrumb, Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { sumBy } from 'lodash';
import { SidebarRightLayout } from '../../../../components/Layout/Layout.styles';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import Tile from '../../../../components/Tile/Tile';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import { mf_TeamDetails } from '../../../../apollo';
import {
  getGapData,
  getAverageTotals,
  getPlayersLists,
  getPOAData,
  getSelectedPlayersData,
  getFilterSelectedPlayers,
} from './RunTendencies.dataManipulation';
import {
  RUN_CHARTS_WIDTH,
  RUN_TENDENCY_AXES,
  RUN_TENDENCY_AXES_OPTIONS,
  RUN_TENDENCY_POSITION_KEYS,
  RUN_TENDENCY_SIDEBAR_WIDTH,
  SELECTED_PLAYERS,
} from './RunTendencies.constants';
import { FilterRow } from './offensePlayerFilters/FilterRow';
import RunGapScatter from './RunGapScatter/RunGapScatter';
import RunPOAHistogram from './RunPOAHistogram/RunPOAHistogram';
import useQueryString from '../../../../utils/hooks/useQueryString';
import {
  useGetRunGapData,
  useGetRunLeagueAverageData,
} from './RunTendencies.hooks';
import RunTable from './RunTable/RunTable';
import { RUN_TENDENCY_TABLE_MODES } from './RunTable/RunTable.constants';
import RunKey from './RunKey/RunKey';
import {
  ChartButtonRibbon,
  SecondChartTitle,
  VerticalKeyHolder,
} from './RunTendencies.styles';
import ExportModal from '../../../../components/ExportModal/ExportModal';
import { OLGapPosition } from '../../../../visualisations/OLPressure/OLGapPosition/OLGapPosition';
import { CHART_LAYOUT } from '../../../../visualisations/BasicChart/BasicChart.constants';
import { useExportSeasonInfo } from '../../../../utils/hooks/useExportSeasonInfo';

const RunTendencies = function () {
  // selected team and comp
  const selectedTeamDetails = useReactiveVar(mf_TeamDetails);

  const [displayTable, setDisplayTable] = useState(false);
  const [displayKey, setDisplayKey] = useQueryString('showkey', true);
  const [selectedPlayers, setSelectedPlayers] = useState(SELECTED_PLAYERS);
  const [bubbleYAxis, setBubbleYAxis] = useQueryString(
    'bubbleYAxis',
    RUN_TENDENCY_AXES.yardsGained.value
  );
  const [bubbleRAxis, setBubbleRAxis] = useQueryString(
    'bubbleRAxis',
    RUN_TENDENCY_AXES.playPercentage.value
  );
  const [bubbleColoring, setBubbleColoring] = useQueryString(
    'bubbleColoring',
    RUN_TENDENCY_AXES.successRate.value
  );
  const [distroYAxis, setDistroYAxis] = useQueryString(
    'distroYAxis',
    RUN_TENDENCY_AXES.playPercentage.value
  );
  const [distroColoring, setDistroColoring] = useQueryString(
    'distroColoring',
    RUN_TENDENCY_AXES.successRate.value
  );

  /* Team data from API */
  const { teamRunGapData, teamRunPOAData, totalPlays, loading, error } =
    useGetRunGapData();

  /* League average from API */
  const {
    leagueRunGapData,
    leagueRunPOAData,
    loading: loadingLA,
    error: errorLA,
  } = useGetRunLeagueAverageData();
  const isError = !!error || !!errorLA;

  /* Average Totals (bubble chart values), League and Team */
  const leagueAverageTotals = getAverageTotals(leagueRunGapData);
  const teamAverageTotals = getAverageTotals(
    teamRunGapData,
    leagueAverageTotals
  );

  /* Set up the player selections */
  const playersLists = getPlayersLists(teamRunPOAData);
  /* Get Gap and POA data matching player selections */
  const selectedPlayersRunData = getSelectedPlayersData(
    teamRunGapData,
    selectedPlayers
  );
  const selectedPlayersPOAData = getSelectedPlayersData(
    teamRunPOAData,
    selectedPlayers
  );

  const selectedPlayerDetails = getFilterSelectedPlayers(
    playersLists,
    selectedPlayers
  );

  /* Final formatting for charts */
  const playTotals = {
    all: totalPlays,
    gapsFiltered: sumBy(selectedPlayersRunData, 'plays'),
    gapsTeam: sumBy(teamRunGapData, 'plays'),
    poaFiltered: sumBy(selectedPlayersPOAData, 'plays'),
    poaTeam: sumBy(teamRunPOAData, 'plays'),
  };
  const playsTitleAll = `${selectedTeamDetails?.name} ${playTotals.all}`;
  const playsTitleGaps =
    playTotals.gapsFiltered !== playTotals.gapsTeam
      ? `${playTotals.gapsFiltered}/${playTotals.gapsTeam}`
      : `${playTotals.gapsTeam}`;
  const playsTitlePOA =
    playTotals.poaFiltered !== playTotals.poaTeam
      ? `${playTotals.poaFiltered}/${playTotals.poaTeam}`
      : `${playTotals.poaFiltered}`;
  const playsTitle = `${playsTitleAll} run plays, ${playsTitleGaps} gap plays, ${playsTitlePOA} POA plays`;
  /* Bubbles */
  const gapDataLeague = getGapData(
    leagueRunGapData,
    sumBy(leagueRunGapData, 'plays')
  );
  const gapDataTeam = getGapData(
    teamRunGapData,
    playTotals.gapsTeam,
    gapDataLeague
  );
  const gapData = getGapData(
    selectedPlayersRunData,
    playTotals.gapsFiltered,
    gapDataLeague
  );
  /* Distro */
  const poaDataLeague = getPOAData(
    leagueRunPOAData,
    sumBy(leagueRunPOAData, 'plays')
  );
  const poaDataTeam = getPOAData(
    teamRunPOAData,
    playTotals.poaTeam,
    poaDataLeague
  );
  const poaData = getPOAData(
    selectedPlayersPOAData,
    playTotals.poaFiltered,
    poaDataLeague
  );

  /* Titles for the two charts */
  const gapChartTitle = `Run Gaps: ${RUN_TENDENCY_AXES[bubbleYAxis].label}`;
  const poaChartTitle = `Run Point of Attack: ${RUN_TENDENCY_AXES[distroYAxis].label}`;
  /* Y-axis name used in keys for average lines */
  const hideScatterAverageLines = [
    RUN_TENDENCY_AXES.plays.value,
    RUN_TENDENCY_AXES.playPercentage.value,
  ].includes(bubbleYAxis);
  const gapLinesMetricName = hideScatterAverageLines
    ? null
    : RUN_TENDENCY_AXES[bubbleYAxis].label;
  const poaLinesMetricName = RUN_TENDENCY_AXES[distroYAxis].label;

  /* For export mode, when positionally filtering, render the gaps/positions diagram */
  const canvasStart =
    CHART_LAYOUT.AXES.AREA.left + CHART_LAYOUT.AXES.PADDING.left;
  const canvasIgnore = CHART_LAYOUT.CANVAS.WIDTH / 6 / 4; // 41.667 ~ accounts for auto axis internal padding
  const olGapOverrides = {
    visualisationWidth: RUN_CHARTS_WIDTH,
    leftSpacing: canvasStart + canvasIgnore,
    symbolAreaWidth: (CHART_LAYOUT.CANVAS.WIDTH - canvasIgnore * 2) / 11, // 6 gaps + 5 positions
  };
  /* Export mode title * details */
  const ballCarrierDetails =
    selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier] > 0 &&
    playersLists[RUN_TENDENCY_POSITION_KEYS.ballCarrier]?.find(
      (p) => p.value === selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier]
    );
  const ballCarrierName =
    selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier] > 0
      ? `Ball Carrier: ${ballCarrierDetails?.player}`
      : '';
  const exportDetails = {
    title: playsTitle,
    secondaryTitle: 'Run Tendencies',
    info1: useExportSeasonInfo(),
    info2: ballCarrierName,
    info3: null,
    fileName: `${selectedTeamDetails?.name} run tendencies`,
  };

  return (
    <div className="team-run-tendencies-page">
      <Grid>
        <Grid.Row>
          <SidebarRightLayout
            $sidebarWidth={`${
              RUN_TENDENCY_SIDEBAR_WIDTH.MAIN +
              RUN_TENDENCY_SIDEBAR_WIDTH.PADDING
            }px`}
          >
            <div>
              <Tile>
                <Tile.Header>
                  <Breadcrumb size="huge">
                    <Breadcrumb.Section>Team</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>
                      Run Tendencies
                    </Breadcrumb.Section>
                  </Breadcrumb>
                  <h1>{playsTitle}</h1>
                  <div className="buttons">
                    <ButtonIcon
                      id="vis-or-table-button"
                      size="small"
                      title={
                        displayTable ? 'Display Visualisation' : 'Display Table'
                      }
                      icon="Swap"
                      variant="secondary"
                      onClick={() => setDisplayTable(!displayTable)}
                    />
                  </div>
                </Tile.Header>
                <Tile.Body>
                  {isError && (
                    <Message negative>
                      There has been an error. Please contact support.
                    </Message>
                  )}

                  <FilterRow
                    playersLists={playersLists}
                    selectedPlayers={selectedPlayers}
                    setSelectedPlayers={setSelectedPlayers}
                  />
                  <FilterRow
                    playersLists={playersLists}
                    selectedPlayers={selectedPlayers}
                    setSelectedPlayers={setSelectedPlayers}
                    isOffensiveLine={false}
                  />
                  {!displayTable && (
                    <div>
                      {loading && (
                        <Dimmer active style={{ opacity: 0.4 }}>
                          <Loader content="Loading Data" />
                        </Dimmer>
                      )}
                      <ChartButtonRibbon>
                        <KeyButton
                          showKey={displayKey}
                          handleShowKey={() => setDisplayKey(!displayKey)}
                        />
                        <ExportModal
                          title={exportDetails.title}
                          secondaryTitle={exportDetails.secondaryTitle}
                          info1={exportDetails.info1}
                          info2={exportDetails.info2}
                          info3={exportDetails.info3}
                          fileName={exportDetails.fileName}
                          isDisabled={
                            loading || !gapData?.length || !poaData?.length
                          }
                        >
                          {selectedPlayerDetails?.length > 0 && (
                            <OLGapPosition
                              selectedPlayers={selectedPlayerDetails}
                              widthOverrides={olGapOverrides}
                              hidePlayerInfo={false}
                            />
                          )}
                          <RunGapScatter
                            runGapData={gapData}
                            runGapDataTeam={gapDataTeam}
                            teamAverageTotals={teamAverageTotals}
                            leagueAverageTotals={leagueAverageTotals}
                            yAxisKey={bubbleYAxis}
                            rAxisKey={bubbleRAxis}
                            colorAxisKey={bubbleColoring}
                          />
                          {displayKey && (
                            <RunKey
                              isVertical={false}
                              scalingAxisSetup={RUN_TENDENCY_AXES[bubbleRAxis]}
                              colorAxisSetup={RUN_TENDENCY_AXES[bubbleColoring]}
                              linesMetricName={gapLinesMetricName}
                            />
                          )}
                          <SecondChartTitle>{poaChartTitle}</SecondChartTitle>
                          <RunPOAHistogram
                            poaData={poaData}
                            poaDataTeam={poaDataTeam}
                            poaDataLeague={poaDataLeague}
                            yAxisKey={distroYAxis}
                            colorAxisKey={distroColoring}
                          />
                          {displayKey && (
                            <RunKey
                              isVertical={false}
                              colorAxisSetup={RUN_TENDENCY_AXES[distroColoring]}
                              linesMetricName={poaLinesMetricName}
                            />
                          )}
                        </ExportModal>
                      </ChartButtonRibbon>
                      <h3>{gapChartTitle}</h3>
                      <RunGapScatter
                        runGapData={gapData}
                        runGapDataTeam={gapDataTeam}
                        teamAverageTotals={teamAverageTotals}
                        leagueAverageTotals={leagueAverageTotals}
                        yAxisKey={bubbleYAxis}
                        rAxisKey={bubbleRAxis}
                        colorAxisKey={bubbleColoring}
                      />
                      <SecondChartTitle>{poaChartTitle}</SecondChartTitle>
                      <RunPOAHistogram
                        poaData={poaData}
                        poaDataTeam={poaDataTeam}
                        poaDataLeague={poaDataLeague}
                        yAxisKey={distroYAxis}
                        colorAxisKey={distroColoring}
                      />
                    </div>
                  )}
                  {displayTable && (
                    <>
                      <RunTable
                        runData={gapData}
                        teamRunData={gapDataTeam}
                        leagueRunData={gapDataLeague}
                        tableMode={RUN_TENDENCY_TABLE_MODES.GAP.value}
                        tableName="Gap Information"
                        csvName="Run Gaps"
                      />
                      <RunTable
                        runData={poaData}
                        teamRunData={poaDataTeam}
                        leagueRunData={poaDataLeague}
                        tableMode={RUN_TENDENCY_TABLE_MODES.POA.value}
                        tableName="Point of Attack Information"
                        csvName="Run POA"
                      />
                    </>
                  )}
                  {loadingLA && (
                    <div>
                      <span>Loading League Average Data</span>
                    </div>
                  )}
                </Tile.Body>
              </Tile>
            </div>
            <div>
              <AccordionTile
                id="axes-settings-accordion"
                isExpandedDefault
                isMount
                header={
                  <Tile.AccordionHeader>Run Gap Settings</Tile.AccordionHeader>
                }
                body={
                  <Tile.AccordionBody>
                    <Dropdown
                      id="bubble-y-axis-dropdown"
                      options={RUN_TENDENCY_AXES_OPTIONS}
                      label="Gap Bubble Y Axis"
                      menuPosition="static"
                      onChange={(selectedOption) =>
                        setBubbleYAxis(selectedOption.value)
                      }
                      value={RUN_TENDENCY_AXES_OPTIONS.find(
                        (f) => f.value === bubbleYAxis
                      )}
                    />
                    <Dropdown
                      id="bubble-r-axis-dropdown"
                      options={RUN_TENDENCY_AXES_OPTIONS}
                      label="Gap Bubble Sizing"
                      menuPosition="static"
                      onChange={(selectedOption) =>
                        setBubbleRAxis(selectedOption.value)
                      }
                      value={RUN_TENDENCY_AXES_OPTIONS.find(
                        (f) => f.value === bubbleRAxis
                      )}
                    />
                    <Dropdown
                      id="bubble-coloring-axis-dropdown"
                      options={RUN_TENDENCY_AXES_OPTIONS}
                      label="Gap Bubble Coloring"
                      menuPosition="static"
                      onChange={(selectedOption) =>
                        setBubbleColoring(selectedOption.value)
                      }
                      value={RUN_TENDENCY_AXES_OPTIONS.find(
                        (f) => f.value === bubbleColoring
                      )}
                    />
                  </Tile.AccordionBody>
                }
              />

              <AccordionTile
                id="chart-keys-accordion"
                header={
                  <Tile.AccordionHeader>
                    Point of Attack Settings
                  </Tile.AccordionHeader>
                }
                isExpandedDefault
                isMount
                body={
                  <Tile.AccordionBody>
                    <Dropdown
                      id="distro-y-axis-dropdown"
                      options={RUN_TENDENCY_AXES_OPTIONS}
                      label="Point of Attack Y Axis"
                      menuPosition="static"
                      onChange={(selectedOption) =>
                        setDistroYAxis(selectedOption.value)
                      }
                      value={RUN_TENDENCY_AXES_OPTIONS.find(
                        (f) => f.value === distroYAxis
                      )}
                    />
                    <Dropdown
                      id="distro-coloring-axis-dropdown"
                      options={RUN_TENDENCY_AXES_OPTIONS}
                      label="Point of Attack Coloring"
                      menuPosition="static"
                      onChange={(selectedOption) =>
                        setDistroColoring(selectedOption.value)
                      }
                      value={RUN_TENDENCY_AXES_OPTIONS.find(
                        (f) => f.value === distroColoring
                      )}
                    />
                  </Tile.AccordionBody>
                }
              />

              {displayKey && (
                <AccordionTile
                  id="chart-keys-accordion"
                  header={
                    <Tile.AccordionHeader>Chart Keys</Tile.AccordionHeader>
                  }
                  isExpandedDefault
                  isMount
                  body={
                    <Tile.AccordionBody>
                      <VerticalKeyHolder>
                        <RunKey
                          title="Run Gap Bubbles"
                          isVertical
                          scalingAxisSetup={RUN_TENDENCY_AXES[bubbleRAxis]}
                          colorAxisSetup={RUN_TENDENCY_AXES[bubbleColoring]}
                          linesMetricName={gapLinesMetricName}
                        />
                      </VerticalKeyHolder>
                      <VerticalKeyHolder>
                        <RunKey
                          title="Run Point of Attack Bars"
                          isVertical
                          colorAxisSetup={RUN_TENDENCY_AXES[distroColoring]}
                          linesMetricName={poaLinesMetricName}
                        />
                      </VerticalKeyHolder>
                    </Tile.AccordionBody>
                  }
                />
              )}
            </div>
          </SidebarRightLayout>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default RunTendencies;
