import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3';
import { useTheme } from 'styled-components';
import { sumBy } from 'lodash';
import BarChart from '../../../../../visualisations/BarChart/BarChart';
import { runGapTooltip } from '../RunTendencies.dataManipulation';
import { runGapAxesPropType } from '../RunGapScatter/RunGapScatter.propTypes';
import {
  RUN_TENDENCY_AVERAGE_LINE_SETUP,
  RUN_TENDENCY_AXES,
} from '../RunTendencies.constants';
import { runDatumPropTypes } from '../RunTendencies.propTypes';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const RunPOAHistogram = ({
  chartId,
  poaData,
  poaDataTeam,
  poaDataLeague,
  yAxisKey,
  colorAxisKey,
}) => {
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;
  const layoutOverride = {
    CANVAS: { HEIGHT: 100 },
    AXES: {
      GUIDES: {
        VERTICAL: null,
      },
      AREA: { top: 0, right: 50, bottom: 30, left: 50 },
    },
  };

  const yOverrides = RUN_TENDENCY_AXES[yAxisKey].poa;
  const setupOverride = {
    Y: yOverrides,
  };

  /* 
  Color scales all work on a 0-1 domain
  This function takes the metric-specific domain from the axis definition
    and mutates it to the equivalent 0-1 domain so the standard color functions can be called
  */
  const coloringFunction = RUN_TENDENCY_AXES[colorAxisKey].colorFunction;
  const colorDomain = RUN_TENDENCY_AXES[colorAxisKey].poa.DOMAIN;
  const colorRange = [0, 1];
  const transformer = scaleLinear()
    .domain(colorDomain)
    .range(colorRange)
    .clamp(true);
  const barColorScale = (rValue) => {
    const transformedValue = transformer(rValue);
    return coloringFunction(transformedValue, isDark);
  };

  const barData = poaData.map((poa) => ({
    id: poa.id,
    xValue: poa.id,
    yValue: poa[yAxisKey],
    fill: barColorScale(poa[colorAxisKey]),
    stroke: 'transparent',
    title: runGapTooltip(
      poa,
      `${poa.id} yards ${poa.id < 0 ? 'left' : 'right'} of snap`
    ),
  }));

  const linesData = [];
  if (!Number.isNaN(poaDataTeam[0]?.plays) && sumBy(poaDataTeam, 'plays') > 0) {
    const teamLineData = poaDataTeam.map((poa) => ({
      xValue: poa.id,
      yValue: poa[yAxisKey],
    }));
    const teamLine = {
      id: 'teamData',
      data: teamLineData,
      stroke: {
        color: RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.COLOR(visPalette),
        width: RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.STROKE_WIDTH,
        dashArray: RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.STROKE_DASH,
      },
    };
    linesData.push(teamLine);
  }

  if (
    !Number.isNaN(poaDataLeague[0]?.plays) &&
    sumBy(poaDataLeague, 'plays') > 0
  ) {
    const leagueLineData = poaDataLeague.map((poa) => ({
      xValue: poa.id,
      yValue: poa[yAxisKey],
    }));
    const leagueLine = {
      id: 'leagueData',
      data: leagueLineData,
      stroke: {
        color: RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.COLOR(visPalette),
        width: RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.STROKE_WIDTH,
        dashArray: RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.STROKE_DASH,
      },
    };
    linesData.push(leagueLine);
  }

  return (
    <BarChart
      chartId={chartId}
      barData={barData}
      linesData={linesData}
      layoutOverride={layoutOverride}
      setupOverride={setupOverride}
    />
  );
};

RunPOAHistogram.propTypes = {
  chartId: PropTypes.string,
  poaData: PropTypes.arrayOf(runDatumPropTypes),
  poaDataTeam: PropTypes.arrayOf(runDatumPropTypes),
  poaDataLeague: PropTypes.arrayOf(runDatumPropTypes),
  yAxisKey: runGapAxesPropType,
  colorAxisKey: runGapAxesPropType,
};

RunPOAHistogram.defaultProps = {
  chartId: 'run-point-of-attack-bars',
  poaData: undefined,
  poaDataTeam: undefined,
  poaDataLeague: undefined,
  yAxisKey: RUN_TENDENCY_AXES.playPercentage.id,
  colorAxisKey: RUN_TENDENCY_AXES.successRate.id,
};

export default RunPOAHistogram;
