import { clamp } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { zoom } from 'd3-zoom';
import { FIELD_X_YARDS, FIELD_Y_YARDS, ROTATIONS } from '../constants/charting';

/*
This page exposes a function that adds and returns a <g> element in an SVG
The returned <g> will be scaled and orientated as desired so that child elements can
then just be added using standard horizontal field coordinates
Details on how this works can be found in Notion 
https://www.notion.so/statsbomb/Zoom-and-Rotation-03bd9edd807f41209c539b570da74e3d
*/
const VIEWPORT_CLIP_NAME = 'viewport-clip-name';
const addViewPortClipPath = function (
  baseG,
  clipName,
  viewPortWidth,
  viewPortHeight
) {
  const viewPortDefs = baseG.append('defs');
  const clippy = viewPortDefs.append('clipPath').attr('id', clipName);
  clippy
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', viewPortWidth)
    .attr('height', viewPortHeight);
};

const addD3Zoom = function (svgG, fieldWidth, fieldHeight) {
  const zoomSettings = {
    scaleMin: 0.5,
    scaleMax: 5,
    width: fieldWidth,
    height: fieldHeight,
  };
  svgG.call(
    zoom()
      .scaleExtent([zoomSettings.scaleMin, zoomSettings.scaleMax])
      .extent([
        [0, 0],
        [zoomSettings.width, zoomSettings.height],
      ])
      .on('zoom', (a) => {
        svgG.attr('transform', a.transform);
      })
  );
  // .on('mousedown.zoom', null); //can disable panning with this
  // .on('wheel.zoom', null); //can disable scrolling (zooming) with this
};

const setupResetButton = function (svgG, buttonName) {
  const resetButton = document.getElementById(buttonName);
  if (resetButton) {
    resetButton.onclick = () => {
      svgG.attr('transform', 'translate(0,0) scale(1)');
    };
  }
};

const ROTATE_SCALE_ZOOM_DEFAULTS = {
  idPrefix: 'ipx', // prefix used to create a unique clip path name,
  viewPortWidth: 10, // the width of visible area
  viewPortHeight: 10, // the height of visible area
  cropToViewport: true, // should the contents get cropped to viewPort
  orientation: ROTATIONS.HORIZONTAL, // which way up is the field
  fieldWidth: FIELD_X_YARDS, // the width of the field (or other coordinate system), in horizontal orientation
  fieldHeight: FIELD_Y_YARDS, // the height of the field (or other coordinate system), in horizontal orientation
  targetFieldX: FIELD_X_YARDS / 2, // the x coordinate, on the field (horizontal), which is the target focal point
  targetFieldY: FIELD_Y_YARDS / 2, // the y coordinate, on the field (horizontal), which is the target focal point
  scaleFactor: 1, // value for scaling the content. < 1: Zoom Out, > 1: Zoom In
  bindEdges: false, // Should the translation be limited to prevent blank space in viewPort
  fieldBoundary: 0, // If binding edges, optional padding parameter
  addZoom: false, // option specifying if d3 zoom should get added
  zoomableGId: 'zoomable-g', // unique name for the zoomable G (returned layer)
  resetButtonId: null,
};

const rotateScaleZoom = function ({
  baseG, // should be a <g> moved within the margins
  idPrefix = 'ipx', // prefix used to create a unique clip path name,
  viewPortWidth = 10, // the width of visible area
  viewPortHeight = 10, // the height of visible area
  cropToViewport = true, // should the contents get cropped to viewPort
  orientation = ROTATIONS.HORIZONTAL, // which way up is the field
  fieldWidth = FIELD_X_YARDS, // the width of the field (or other coordinate system), in horizontal orientation
  fieldHeight = FIELD_Y_YARDS, // the height of the field (or other coordinate system), in horizontal orientation
  targetFieldX = FIELD_X_YARDS / 2, // the x coordinate, on the field (horizontal), which is the target focal point
  targetFieldY = FIELD_Y_YARDS / 2, // the y coordinate, on the field (horizontal), which is the target focal point
  scaleFactor = 1, // value for scaling the content. < 1 = Zoom Out, > 1 = Zoom In
  bindEdges = false, // Should the translation be limited to prevent blank space in viewPort
  fieldBoundary = 0, // If binding edges, optional padding parameter
  addZoom = true, // option specifying if d3 zoom should get added
  zoomableGId = 'zoomable-g', // unique name for the zoomable G (returned layer)
  resetButtonId = null,
}) {
  // clear any previous children, go again
  baseG.selectAll('g').remove();
  baseG.selectAll('defs').remove();

  // make a clip-path for the viewPort if wanted
  if (cropToViewport) {
    const clipName = `${idPrefix}-${VIEWPORT_CLIP_NAME}`;
    addViewPortClipPath(baseG, clipName, viewPortWidth, viewPortHeight);
    baseG.attr('clip-path', `url(#${clipName})`);
  }

  // default values are for horizontal mode
  let rotationTransform = '';
  let dx = viewPortWidth / 2 - targetFieldX; // adjust so the LoS is midpoint of the viewport
  let dy = viewPortHeight / 2 - targetFieldY; // adjust so the LoS is midpoint of the viewport

  // if scaling (zooming) then an additional shift by the change in size required
  const shiftX = scaleFactor * targetFieldX - targetFieldX;
  const shiftY = scaleFactor * targetFieldY - targetFieldY;

  // These values are for limiting the shifts due to boundary points
  let maxDX = 0;
  let minDX = viewPortWidth - fieldWidth * scaleFactor;
  let maxDY = 0;
  let minDY = viewPortHeight - fieldHeight * scaleFactor;
  // check each axis can be bound (not possible if scaled field is smaller than viewport)
  let xBindable = viewPortWidth <= fieldWidth * scaleFactor;
  let yBindable = viewPortHeight <= fieldHeight * scaleFactor;

  // if orientation is not horizontal, then need to add a rotation, and change the translations
  if (orientation === ROTATIONS.VERTICAL_UP) {
    rotationTransform = `rotate(-90,${fieldWidth / 2},${fieldWidth / 2})`;

    // viewport is outside of the orientation, so height becomes x relevant, width y relevant
    dx = viewPortHeight / -2 + (fieldWidth - targetFieldX);
    dy = viewPortWidth / 2 - targetFieldY;
    // boundary limitations check change
    xBindable = viewPortHeight <= fieldWidth * scaleFactor;
    yBindable = viewPortWidth <= fieldHeight * scaleFactor;

    /* relative0 is the effective scale origin ~ 
        this is x = 0, as it's the shift from rotation point to viewport
    */
    const relative0 = fieldWidth - viewPortHeight;
    maxDX = relative0;
    minDX = relative0 + viewPortHeight - fieldWidth * scaleFactor;
    maxDY = 0;
    minDY = viewPortWidth - fieldHeight * scaleFactor;
  }
  if (orientation === ROTATIONS.VERTICAL_DOWN) {
    rotationTransform = `rotate(90,${fieldHeight / 2},${fieldHeight / 2})`;

    // viewport is outside of the orientation, so height becomes x relevant, width y relevant
    dx = viewPortHeight / 2 - targetFieldX;
    dy = fieldHeight - targetFieldY - viewPortWidth / 2;
    // boundary limitations
    xBindable = viewPortHeight <= fieldWidth * scaleFactor;
    yBindable = viewPortWidth <= fieldHeight * scaleFactor;

    maxDX = 0;
    minDX = viewPortHeight - fieldWidth * scaleFactor;
    /* relative0 is the effective scale origin ~ 
        this is y = 0, as it's the shift from rotation point to viewport
    */
    const relative0 = fieldHeight - viewPortWidth;
    maxDY = relative0;
    minDY = relative0 + viewPortWidth - fieldHeight * scaleFactor;
  }

  // add a new G, and orientate it (scale comes later)
  const rotatedG = baseG
    .append('g')
    .attr('id', `${idPrefix}rotated`)
    .attr('data-testid', 'rotationGT')
    .attr('transform', rotationTransform);

  // next adjust the field to move the targets to center of the viewPort
  // if not scaling, then the addional scale shift will be 0
  dx -= shiftX;
  dy -= shiftY;
  // if binding the edges of the field to prevent blank viewPort space, check dx and dy
  if (bindEdges) {
    minDX -= fieldBoundary;
    maxDX += fieldBoundary;
    minDY -= fieldBoundary;
    maxDY += fieldBoundary;
    if (xBindable) {
      dx = clamp(dx, minDX, maxDX);
    }
    if (yBindable) {
      dy = clamp(dy, minDY, maxDY);
    }
  }
  const translationTransform = `translate(${dx},${dy}) scale(${scaleFactor},${scaleFactor})`;
  const movedG = rotatedG
    .append('g')
    .attr('id', `${idPrefix}moved-scaled`)
    .attr('transform', translationTransform);

  const zoomableG = movedG.append('g').attr('id', zoomableGId);

  // ADD ZOOM ABILITY
  if (addZoom) {
    addD3Zoom(zoomableG, fieldWidth, fieldHeight);
    setupResetButton(zoomableG, resetButtonId);
  }

  // All Field and Object drawing can now be done on this layer
  return zoomableG;
};

/* 
This function works out the x/y values of the viewport edges
It ignores rotation in the calculation
  (whatever field-orientated limits are true in one orientation will be true in all orientations)
  It uses it to work out the actual visible width/height though
*/
const rotateScaleZoomViewPortLimits = function ({
  viewPortWidth = 10, // the width of visible area
  viewPortHeight = 10, // the height of visible area
  orientation = ROTATIONS.HORIZONTAL, // which way up is the field
  fieldMinX = 0, // the min X value (coord system) that could be shown
  fieldMaxX = FIELD_X_YARDS,
  fieldMinY = 0,
  fieldMaxY = FIELD_Y_YARDS, // the height of the field (or other coordinate system), in horizontal orientation
  targetFieldX = FIELD_X_YARDS / 2, // the x coordinate, on the field (horizontal), which is the target focal point
  targetFieldY = FIELD_Y_YARDS / 2, // the y coordinate, on the field (horizontal), which is the target focal point
  scaleFactor = 1, // value for scaling the content. < 1 = Zoom Out, > 1 = Zoom In
  bindEdges = false, // Should the translation be limited to prevent blank space in viewPort
  fieldBoundary = 0, // If binding edges, optional padding parameter
}) {
  const visibleWidth =
    orientation === ROTATIONS.HORIZONTAL
      ? viewPortWidth / scaleFactor
      : viewPortHeight / scaleFactor;
  const visibleHeight =
    orientation !== ROTATIONS.HORIZONTAL
      ? viewPortWidth / scaleFactor
      : viewPortHeight / scaleFactor;

  let minX = targetFieldX - visibleWidth / 2;
  let maxX = targetFieldX + visibleWidth / 2;
  let minY = targetFieldY - visibleHeight / 2;
  let maxY = targetFieldY + visibleHeight / 2;
  if (bindEdges) {
    if (minX < fieldMinX - fieldBoundary) {
      minX = fieldMinX - fieldBoundary;
      maxX = minX + visibleWidth;
    }
    if (maxX > fieldMaxX + fieldBoundary) {
      maxX = fieldMaxX + fieldBoundary;
      minX = maxX - visibleWidth;
    }
    if (minY < fieldMinY - fieldBoundary) {
      minY = fieldMinY - fieldBoundary;
      maxY = minY + visibleHeight;
    }
    if (maxY > fieldMaxY + fieldBoundary) {
      maxY = fieldMaxY + fieldBoundary;
      minY = maxY - visibleHeight;
    }
  }

  return { minX, maxX, minY, maxY };
};

export {
  rotateScaleZoom,
  rotateScaleZoomViewPortLimits,
  ROTATE_SCALE_ZOOM_DEFAULTS,
};
