import { OLP_VIS_GAPS, OLP_VIS_POS } from '../OLPressure.constants';
import { getAlignmentPositionByCode } from '../../../utils/helpers/positions';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { drawGapBarriers } from '../../../utils/visualisations/shapes';
import { OL_GAP_POSITION_SIZING } from './OLGapPosition.constants';
import { fontBaselineY } from '../../text';

export const gapPositionSymbols = (
  svgG,
  visPalette,
  selectedPlayers,
  symbolAreaWidth
) => {
  const midHeight = OL_GAP_POSITION_SIZING.ICONS_HEIGHT / 2;
  svgG.selectAll('g').remove();
  const symbolsG = svgG
    .append('g')
    .attr('transform', `translate(0,${midHeight})`);
  // layer Gs, shapes below text
  const olShapesG = symbolsG.append('g');
  const gapShapesG = symbolsG.append('g');
  const olLbl = symbolsG.append('g');
  const gapLbl = symbolsG.append('g');

  const positions = OLP_VIS_POS.map((m) => {
    const isSelected = !!selectedPlayers?.find(
      (p) => p.position.code === m.posCode
    );
    return { ...m, isSelected };
  });
  // draw OL Shapes
  olShapesG
    .selectAll('path')
    .data(positions)
    .enter()
    .append('path')
    .attr('d', (d) => {
      const position = getAlignmentPositionByCode(d.posCode);
      return position.shape(OL_GAP_POSITION_SIZING.SHAPE_RADIUS);
    })
    .attr(
      'transform',
      (d) =>
        `translate(${(d.index + 0.5) * symbolAreaWidth} 0) rotate(${
          getAlignmentPositionByCode(d.posCode)?.rotation
        })`
    )
    .attr('fill', (d) => (d.isSelected ? visPalette.contrast : 'none'))
    .attr('stroke-width', 2)
    .attr('stroke', visPalette.axis);

  olLbl
    .selectAll('text')
    .data(positions)
    .enter()
    .append('text')
    .attr('x', (d) => (d.index + 0.5) * symbolAreaWidth)
    .attr('y', OL_GAP_POSITION_SIZING.ICON_FONT_BASELINE)
    .attr(
      'font-size',
      `${VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE}px`
    )
    .attr('font-family', DEFAULT_FONT)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT)
    .attr('text-anchor', 'middle')
    .attr('fill', (d) =>
      d.isSelected ? visPalette.background.main : visPalette.text.info
    )
    .text((d) => `${d.label}`);

  // Draw Gap Shapes
  gapShapesG
    .selectAll('path')
    .data(OLP_VIS_GAPS)
    .enter()
    .append('path')
    .attr(
      'transform',
      (d) => `translate(${(d.index + 0.5) * symbolAreaWidth},0)`
    )
    // TODO: With TE implementation, denote if gap open/closed
    .attr('d', drawGapBarriers(OL_GAP_POSITION_SIZING.SHAPE_RADIUS))
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 2)
    .attr('fill', 'none');
  // Add Gap Text
  gapLbl
    .selectAll('text')
    .data(OLP_VIS_GAPS)
    .enter()
    .append('text')
    .attr('x', (d) => (d.index + 0.5) * symbolAreaWidth)
    .attr('y', OL_GAP_POSITION_SIZING.ICON_FONT_BASELINE)
    .attr(
      'font-size',
      `${VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE}px`
    )
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT)
    .attr('fill', visPalette.text.info)
    .text((d) => `${d.label}`);
};

export const drawSelectedPlayerNames = (
  svgG,
  visPalette,
  selectedPlayers,
  symbolAreaWidth
) => {
  const namesG = svgG.append('g');

  const players = selectedPlayers.map((player) => {
    const visPosition = OLP_VIS_POS.find(
      (p) => player.position.code === p.posCode
    );
    return { ...player, index: visPosition.index };
  });
  const nameLabelFont = VISUALISATION_FONT_SETUPS.AXES_LABELS;
  namesG
    .selectAll('text')
    .data(players)
    .enter()
    .append('text')
    .attr('x', (d) => (d.index + 0.5) * symbolAreaWidth)
    .attr('y', fontBaselineY(nameLabelFont.SIZE, nameLabelFont.LINE_HEIGHT))
    .attr('font-size', `${nameLabelFont.SIZE}px`)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .attr('font-weight', nameLabelFont.WEIGHT)
    .attr('fill', visPalette.text.info)
    .text((d) => `${d.playerName}`);
};
