import { isArray, isEmpty, isEqual } from 'lodash';
import { METRIC_SLIDER_DEFAULTS } from './ScoutResults.constants';
import { PLAYS_STAT_KEY } from './ScoutPlayers/ScoutPlayers.constants';
import { API_STAT_UNITS } from '../../../utils/constants/api';

/*
 Modal expects an exact shape for metric selection
 */
export const formatPlayerStatDefinitionsForModal = (statDefinitions) => {
  if (isEmpty(statDefinitions)) {
    return [];
  }
  const metricModalDefs = statDefinitions.map((def, i) => ({
    key: def?.name,
    name: def?.prettyName,
    abbreviation: def?.abbrev,
    description:
      def?.description?.length > 100
        ? `${def.description.substring(0, 100)}...`
        : def?.description,
    lowerIsBetter: def?.lowerIsBetter,
    unitType: def?.units,
    index: i,
  }));

  /* 
  Have duplicates because of categorizations we want to clear out 
  If name & description differ at all then treat as distinct
  */
  const markedForDeath = metricModalDefs.map((def) => {
    const isDuplicate =
      metricModalDefs.filter(
        (d) =>
          d.index < def.index &&
          d.name === def.name &&
          d.abbreviation === def.abbreviation &&
          d.description === def.description
      ).length > 0;
    return { ...def, isDuplicate };
  });

  const noDupes = markedForDeath.filter((d) => !d.isDuplicate);

  /* Remove plays because it's a static filter */
  const filteredDefinitions = noDupes.filter((d) => d.key !== PLAYS_STAT_KEY);

  return filteredDefinitions;
};

export const hasUnsavedChanges = (
  setup,
  id,
  selectedStats,
  selectedPositions,
  selectedBiometrics
) => {
  if (isEmpty(setup)) {
    return true;
  }

  if (setup?.id !== id) {
    return true;
  }

  if (!isEqual(setup?.selectedStats, selectedStats)) {
    return true;
  }

  if (!isEqual(setup?.selectedPositions, selectedPositions)) {
    return true;
  }

  if (!isEqual(setup?.selectedBiometrics, selectedBiometrics)) {
    return true;
  }

  return false;
};

export const saveStatus = (
  savedSetup,
  setupId,
  selectedStats,
  selectedPositions,
  selectedBiometrics
) => {
  if (
    !hasUnsavedChanges(
      savedSetup,
      setupId,
      selectedStats,
      selectedPositions,
      selectedBiometrics
    )
  ) {
    return 'Up to date';
  }
  if (!setupId && isEmpty(selectedStats) && isEmpty(selectedBiometrics)) {
    return 'Add a metric or player filter to be able to save';
  }
  return 'You have unsaved changes';
};

const getScaleMultiplier = (range) => {
  if (range >= 10) {
    return 1;
  }
  if (range >= 1) {
    return 10;
  }
  if (range >= 0.1) {
    return 100;
  }
  if (range >= 0.01) {
    return 1000;
  }
  if (range >= 0.001) {
    return 10000;
  }
  return 100000;
};

export const getScaleFormatter = (range, unitType) => {
  const rangeAbsolute = Math.abs(range);
  if (unitType === API_STAT_UNITS.PERCENTAGE) {
    return (value) => `${((value || 0) * 100)?.toFixed(1)}%`;
  }
  if (rangeAbsolute >= 10) {
    return (value) => value?.toFixed(1);
  }
  if (rangeAbsolute >= 1) {
    return (value) => value?.toFixed(2);
  }
  if (rangeAbsolute >= 0.1) {
    return (value) => value?.toFixed(3);
  }
  if (rangeAbsolute >= 0.01) {
    return (value) => value?.toFixed(4);
  }
  if (rangeAbsolute >= 0.001) {
    return (value) => value?.toFixed(5);
  }
  return (value) => value?.toFixed(6);
};

/*
For a value within a certain range, round to an appropriate level but stay a number
*/
const roundValueForScale = (value, range, isMax = false) => {
  const scaleMultiplier = getScaleMultiplier(range);
  const bigVal = isMax
    ? Math.ceil(value * scaleMultiplier)
    : Math.floor(value * scaleMultiplier);
  const roundedVal = bigVal / scaleMultiplier;
  return roundedVal;
};

export const getScale = (percentiles) => {
  /* Real distro data */
  const percentileCount = percentiles.length;
  /* If have quintile data, use P5 and P95 as the limits */
  const p0 = percentiles[0];
  const p5 = percentileCount === 21 ? percentiles[1] : p0;
  const p100 = percentiles[percentileCount - 1];
  const p95 = percentileCount === 21 ? percentiles[19] : p100;
  const range5_95 = Math.abs(p95 - p5);
  const range0_100 = Math.abs(p100 - p0);
  const isFullRange = range5_95 === 0;
  /* some metrics have p5 and p95 of 0 (super narrow distros) ~ in which case revert to full range */
  const range = isFullRange ? range0_100 : range5_95;
  const min = isFullRange ? p0 : p5;
  const max = isFullRange ? p100 : p95;
  const roundedMin = roundValueForScale(min, range);
  const roundedMax = roundValueForScale(max, range, true);
  const preciseStep = (roundedMax - roundedMin) / METRIC_SLIDER_DEFAULTS.STEPS;

  return {
    min: roundedMin,
    max: roundedMax,
    sliderStep: preciseStep,
  };
};

export const getHeaderTooltip = (metric) => {
  const headerTooltip =
    `${metric?.name}` +
    `\n${metric?.description}` +
    `\nMean: ${metric?.mean}` +
    `\nStandard Deviation: ${metric?.stdDev}`;
  return headerTooltip;
};

/* 
When precise editing one of the slider pair of values, the resultant array needs to be in the right order
  i.e. if the precise value chosen would flip the handle the pair should flip so as to preserve value ordering
*/
export const orderValuesForPreciseValue = (valuePair, lowerIsBetter) => {
  if (!isArray(valuePair) || valuePair[0] === null || valuePair[1] === null) {
    return valuePair;
  }
  if (
    (lowerIsBetter && valuePair[1] > valuePair[0]) ||
    (!lowerIsBetter && valuePair[0] > valuePair[1])
  ) {
    return [valuePair[1], valuePair[0]];
  }
  return valuePair;
};
