export const scheduleHeaders = [
  {
    key: 'week',
    label: 'Week',
    description: 'Week the game was played',
  },
  {
    key: 'date',
    label: 'Date',
    description: 'Date the game was played',
  },
  {
    key: 'kickoff',
    label: 'Time',
    description: 'Time the game was played',
  },
  {
    key: 'conference',
    label: 'Conf',
    description: 'Conference the team is in',
  },
  {
    key: 'result',
    label: 'Result',
    description: 'Result of the game',
  },
  {
    key: 'isAway',
    label: '',
    description: '',
  },
  {
    key: 'opponent',
    label: 'Opponent',
    description: 'Game opponent',
  },
  {
    key: 'score',
    label: 'Score',
    description: 'Score',
  },
  {
    key: 'offensiveEpaPerDrive',
    label: 'Off EPA/Drive',
    description: 'Offensive (Expected Points Added) per drive',
  },
  {
    key: 'defensiveEpaPerDrive',
    label: 'Def EPA/Drive',
    description: 'Defensive (Expected Points Added) per drive',
  },
  {
    key: 'link',
    label: ' ',
    description: 'Link to Play-by-Play',
  },
];
