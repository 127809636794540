import {
  COMPARE_SELECTED,
  COMPARE_SIMILAR,
  COMPARE_TILE_ADD,
  COMPARE_TILE_DATA,
  COMPARE_TILE_EMPTY,
  COMPARE_TILE_ERROR,
  COMPARE_TILE_HIDDEN,
} from './PlayerComparison.constants';

// comparison information
export const numberOfMatchesInfo = (totalComparisons) => {
  if (totalComparisons > 0 && totalComparisons <= 5) {
    return `Top ${totalComparisons} of ${totalComparisons} available players`;
  }
  if (totalComparisons > 5) {
    return `Top 5 of ${totalComparisons} available player${
      totalComparisons > 1 ? 's' : ''
    }`;
  }
  if (totalComparisons === 0) {
    return 'No matches found';
  }
  return '';
};

export const fixArrayLength = (arr, fixedLength) => {
  if (!arr) {
    return [];
  }
  const newArr = arr.slice(0, fixedLength); // Take only the first fixedLength elements
  const zerosToAdd = Math.max(0, fixedLength - newArr.length); // Calculate the number of zeros to add
  const zerosArray = new Array(zerosToAdd).fill(COMPARE_TILE_HIDDEN);

  return newArr.concat(zerosArray); // Concatenate the original array with the array of COMPARE_TILE_HIDDEN
};

export const getComparisonTileOpacity = (compareTileState) => {
  switch (compareTileState) {
    case COMPARE_TILE_ADD:
      return 0.28;
    case COMPARE_TILE_EMPTY:
    case COMPARE_TILE_DATA:
    case COMPARE_TILE_ERROR:
      return 1;
    case COMPARE_TILE_HIDDEN:
      return 0;
    default:
      return 1;
  }
};

export const getComparativeTileState = (
  comparisonMode,
  playerComparisonData,
  comparativeTileState,
  tileIndex
) => {
  if (comparisonMode === COMPARE_SIMILAR) {
    const state =
      typeof playerComparisonData[tileIndex] === 'object' &&
      playerComparisonData[tileIndex] !== null
        ? COMPARE_TILE_DATA
        : COMPARE_TILE_HIDDEN;
    return state;
  }
  if (comparisonMode === COMPARE_SELECTED) {
    return comparativeTileState[tileIndex];
  }
  return COMPARE_TILE_EMPTY;
};
