/* eslint-disable max-len */
export const DATA_DICTIONARY_LINE_BATTLE_TRACKING_FRAMES = [
  {
    field: 'game_id',
    fieldType: 'integer',
    fieldValues: '1069669',
    fieldDescription: 'Unique identifier for the game',
  },
  {
    field: 'game_date',
    fieldType: 'string',
    fieldValues: '2021-09-12, 2022-10-11',
    fieldDescription: 'Date that the game took place',
  },
  {
    field: 'game_away_team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription: 'Unique team identifier for the away team',
  },
  {
    field: 'away_team_name',
    fieldType: 'string',
    fieldValues: 'Jacksonville Jaguars',
    fieldDescription: 'Away team name',
  },
  {
    field: 'game_home_team_id',
    fieldType: 'integer',
    fieldValues: '1001399',
    fieldDescription: 'Unique team identifier for the home team',
  },
  {
    field: 'home_team_name',
    fieldType: 'string',
    fieldValues: 'Jacksonville Jaguars',
    fieldDescription: 'Home team name',
  },
  {
    field: 'event_uuid',
    fieldType: 'uuid',
    fieldValues: 'e1ec0da9-ac62-4f93-a8e2-be28f93d69a5',
    fieldDescription:
      'Unique identifier for the event whose line battle is recorded\n\n\n\nNote: for a unique value on this table, you need to combine event_uuid with freeze_frame_player_index',
  },
  {
    field: 'event_game_index',
    fieldType: 'integer',
    fieldValues: '7, 141',
    fieldDescription:
      'Ranked order of events over the series of a game (a value of 7 means that a event was the 7th collected event in that game)',
  },
  {
    field: 'event_video_timestamp',
    fieldType: 'decimal',
    fieldValues: '68.055408',
    fieldDescription:
      'Internal measure of when the event occurred relative to the video used to collect the game',
  },
  {
    field: 'event_types',
    fieldType: 'array',
    fieldValues:
      '{Catch,"From Kick",Return,"Kickoff Return"},\n\n{"Tackle Attempt"}',
    fieldDescription:
      'An event can have 1 or more event_types associated with it (see "definition: event_types" tab for full list)',
  },
  {
    field: 'team_id',
    fieldType: 'integer',
    fieldValues: '1001398',
    fieldDescription:
      'Unique team identifier for the player whose line battle is being recorded',
  },
  {
    field: 'team_name',
    fieldType: 'string',
    fieldValues: 'New York Giants, Tampa Bay Buccaneers',
    fieldDescription: 'Team Name of the player whose line battle is recorded',
  },
  {
    field: 'player_id',
    fieldType: 'integer',
    fieldValues: '1024073',
    fieldDescription:
      'Unique player identifier for the player whose line battle is being recorded',
  },
  {
    field: 'player_name',
    fieldType: 'string',
    fieldValues: 'Shaquill Griffin',
    fieldDescription:
      'Player name for the player whose line battle is being recorded',
  },
  {
    field: 'jersey_number',
    fieldType: 'integer',
    fieldValues: '26',
    fieldDescription:
      'Jersey number for the player whose line battle is being recorded',
  },
  {
    field: 'team_position_code',
    fieldType: 'string',
    fieldValues: 'CB',
    fieldDescription:
      'Position for the player whose line battle is being recorded',
  },
  {
    field: 'freeze_frame_player_index',
    fieldType: 'integer',
    fieldValues: '0 - 21',
    fieldDescription:
      'Value for each player on the field at the time of the line battle.\n\n\n\nNote: there will be 22 of these values for each line battle with possible values from 0-21',
  },
  {
    field: 'freeze_frame_ball_location',
    fieldType: 'boolean',
    fieldValues: 'TRUE, FALSE',
    fieldDescription:
      'Whether or not the player in the line battle has the ball\n\n\n\nNote: there should only be 1 TRUE value for a particular event',
  },
  {
    field: 'freeze_frame_x',
    fieldType: 'decimal',
    fieldValues: '-4.56, 22.56',
    fieldDescription:
      'x coordinate of where the player location at the time of the line battle (see "definition: coordinates" tab for how to interpret coordinates)',
  },
  {
    field: 'freeze_frame_y',
    fieldType: 'decimal',
    fieldValues: '0, 23.6633333333333',
    fieldDescription:
      'y coordinate of where the player location at the time of the line battle (see "definition: coordinates" tab for how to interpret coordinates)',
  },
];
