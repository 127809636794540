import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@statsbomb/kitbag-components';
import { isEmpty } from 'lodash';
import { ChipHolder } from '../BiometricFilters.styles';
import { biometricProp, biometricToken } from '../../../Scout.proptypes';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';

const BiometricChips = ({
  selectedBiometrics,
  setSelectedBiometrics,
  biometricKey,
  tokens,
}) => {
  if (isEmpty(tokens)) {
    return <></>;
  }

  const tokenChip = (token) => (
    <Chip
      key={`${token.id}-chip`}
      label={token.name}
      onClick={() => {
        const otherBiometrics = selectedBiometrics.filter(
          (b) => b.key !== biometricKey
        );
        const otherTokens = tokens.filter((tok) => tok.id !== token.id);
        const otherIds = otherTokens.map((t) => t.id);
        if (otherIds.length > 0) {
          const updatedFilter = {
            key: biometricKey,
            value: otherIds,
            tokens: otherTokens,
          };
          setSelectedBiometrics(otherBiometrics.concat(updatedFilter));
        } else {
          setSelectedBiometrics(otherBiometrics);
        }
      }}
    />
  );

  return <ChipHolder>{tokens?.map((t) => tokenChip(t))}</ChipHolder>;
};

BiometricChips.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
  // the bio metric stat key that is used in scout filtering
  biometricKey: PropTypes.oneOf(Object.keys(SCOUT_BIOMETRIC_FILTERS))
    .isRequired,
  // the tokens are the id / name pairs representing each chip, held in the filter
  tokens: PropTypes.arrayOf(biometricToken),
};

BiometricChips.defaultProps = {
  tokens: null,
};

export default BiometricChips;
