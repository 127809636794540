import PropTypes from 'prop-types';

export const PlayFilterAggregateDistributionPropTypes = PropTypes.shape({
  __typename: PropTypes.string,
  name: PropTypes.string,
  percentage: PropTypes.number,
  count: PropTypes.number,
  rank: PropTypes.number,
});

export const PlayFilterAggregatePropTypes = PropTypes.shape({
  __typename: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  distribution: PropTypes.oneOfType([
    PropTypes.arrayOf(PlayFilterAggregateDistributionPropTypes),
    PropTypes.oneOf([null]),
  ]),
});

export const PlayPropTypes = PropTypes.shape({
  __typename: PropTypes.string,
  offenseTeam: PropTypes.shape({
    __typename: PropTypes.string,
    name: PropTypes.string,
  }),
  defenseTeam: PropTypes.shape({
    __typename: PropTypes.string,
    name: PropTypes.string,
  }),
  game: PropTypes.shape({
    __typename: PropTypes.string,
    id: PropTypes.number,
    shortName: PropTypes.string,
    date: PropTypes.string,
  }),
  id: PropTypes.string,
  quarter: PropTypes.number,
  quarterClockRemaining: PropTypes.number,
  type: PropTypes.string,
  down: PropTypes.number,
  yardsNet: PropTypes.number,
  yardsToGo: PropTypes.number,
  yardLine: PropTypes.number,
  success: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  penalties: PropTypes.arrayOf(PropTypes.any),
  offensePenaltyAccepted: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  defensePenaltyAccepted: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  offensivePersonnel: PropTypes.shape({
    OL: PropTypes.number,
    QB: PropTypes.number,
    RB: PropTypes.number,
    TE: PropTypes.number,
    WR: PropTypes.number,
  }),
  defensivePersonnel: PropTypes.shape({
    DB: PropTypes.number,
    DL: PropTypes.number,
    LB: PropTypes.number,
  }),
  scrimmageEpa: PropTypes.number,
  formationGroup: PropTypes.string,
  defensiveCoverage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  backfieldNumRbs: PropTypes.number,
});

export const PlayFilterResultPropTypes = PropTypes.shape({
  __typename: PropTypes.string,
  team: PropTypes.shape({
    __typename: PropTypes.string,
    name: PropTypes.string,
  }),
  season: PropTypes.shape({
    __typename: PropTypes.string,
    name: PropTypes.string,
  }),
  aggregates: PropTypes.arrayOf(PlayFilterAggregatePropTypes),
  plays: PropTypes.arrayOf(PlayPropTypes),
});
