import { styled } from 'styled-components';

export const SnapDistributionChartSVG = styled.svg`
  width: 100%;
  padding: ${({ $padding }) => $padding || '0'};
  overflow: ${({ $withinTile }) => $withinTile && 'inherit !important'};

  .selectable-zone {
    &.selected {
      stroke: ${({ theme }) => theme.colours.interface.main};
    }

    &:hover {
      cursor: pointer;
      fill: ${({ theme }) => theme.colours.interface.main};
      fill-opacity: 0.5;
    }
  }
`;
