import { styled } from 'styled-components';

export const StyledOption = styled.div`
  .label {
    ${({ theme }) => theme.typography.headingSmall}
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }

  .secondary-label {
    padding-left: 0.4rem;
    ${({ theme }) => theme.typography.bodySmall}
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }
`;
