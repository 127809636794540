// eventually these will probably be imported through react components...

/* POSITION PALETTE */
const POSITION_COLOR_ATTRIBUTE_JERSEY = 'jersey';
const POSITION_COLOR_ATTRIBUTE_MAIN = 'main';
// const POSITION_COLOR_ATTRIBUTE_HOVER = 'hover';
const POSITION_COLOR_ATTRIBUTE_BORDER = 'border';

const posPaletteDark = {
  defense: {
    default: {
      main: '#e84200',
      hover: '#ff8757',
      jersey: '#fff',
      border: '#fff',
    }, // default to db (scale)
    dl: {
      main: '#fff31d',
      hover: '#fffbab',
      jersey: '#65600a',
      border: '#fff',
    }, // yellow
    lb: {
      main: '#ff9700',
      hover: '#ffbf62',
      jersey: '#fff',
      border: '#fff',
    }, // orange
    db: {
      main: '#e84200',
      hover: '#ff8757',
      jersey: '#fff',
      border: '#fff',
    }, // dark orange
    kpr: {
      main: '#e84200',
      hover: '#ff8757',
      jersey: '#fff',
      border: '#fff',
    }, // dark orange
  },
  offense: {
    default: {
      main: '#258eff',
      hover: '#87c1ff',
      jersey: '#fff',
      border: '#fff',
    }, // default to WR (mid scale)
    ol: {
      main: '#64fff2',
      hover: '#b2ffeb',
      jersey: '#0e6d54',
      border: '#fff',
    }, // cyan
    te: {
      main: '#14d3f8',
      hover: '#93eeff',
      jersey: '#fff',
      border: '#fff',
    }, // pale blue
    wr: {
      main: '#258eff',
      hover: '#87c1ff',
      jersey: '#fff',
      border: '#fff',
    }, // blue
    rb: {
      main: '#d8a5ff',
      hover: '#e9cdff',
      jersey: '#fff',
      border: '#fff',
    }, // lilac
    qb: {
      main: '#db00f0',
      hover: '#f590ff',
      jersey: '#fff',
      border: '#fff',
    }, // purple
    kp: {
      main: '#f700b6',
      hover: '#ff90e2',
      jersey: '#fff',
      border: '#fff',
    }, // cherry
  },
  reserve: {
    r: {
      main: '#aaa',
      hover: '#ddd',
      jersey: '#fff',
      border: '#fff',
    },
  },
};

const posPaletteLight = {
  defense: {
    default: {
      main: '#d15100',
      hover: '#d15100',
      jersey: '#fff',
      border: '#121212',
    }, // default to db (scale)
    dl: {
      main: '#ebde0c',
      hover: '#ebde0c',
      jersey: '#454009',
      border: '#121212',
    }, // yellow
    lb: {
      main: '#ec8102',
      hover: '#ec8102',
      jersey: '#fff',
      border: '#121212',
    }, // orange
    db: {
      main: '#d15100',
      hover: '#d15100',
      jersey: '#fff',
      border: '#121212',
    }, // dark orange
    kpr: {
      main: '#d15100',
      hover: '#d15100',
      jersey: '#fff',
      border: '#121212',
    }, // dark orange
  },
  offense: {
    default: {
      main: '#0665ca',
      hover: '#0665ca',
      jersey: '#fff',
      border: '#121212',
    }, // default to WR (mid scale)
    ol: {
      main: '#5ae3d7',
      hover: '#5ae3d7',
      jersey: '#0e5945',
      border: '#121212',
    }, // cyan
    te: {
      main: '#04bade',
      hover: '#04bade',
      jersey: '#fff',
      border: '#121212',
    }, // pale blue
    wr: {
      main: '#0665ca',
      hover: '#0665ca',
      jersey: '#fff',
      border: '#121212',
    }, // blue
    rb: {
      main: '#b362fb',
      hover: '#b362fb',
      jersey: '#fff',
      border: '#121212',
    }, // lilac
    qb: {
      main: '#a200b2',
      hover: '#a200b2',
      jersey: '#fff',
      border: '#121212',
    }, // purple
    kp: {
      main: '#b90068',
      hover: '#b90068',
      jersey: '#fff',
      border: '#121212',
    }, // cherry
  },
  reserve: {
    r: {
      main: '#aaa',
      hover: '#ddd',
      jersey: '#fff',
      border: '#fff',
    },
  },
};

/* COLOURS TO USE FOR US/THEM or FOR/AGAINST rendering (categorical / distinct) 
mod versions are when you need a second colour within the same vis
*/
const FOR_AGAINST_COLORS_DARK = {
  for: {
    main: '#E21017',
    alternate: '#b2080e',
    hover: '#ff8286',
    jersey: '#fff',
    border: '#fff',
  }, // sb red
  against: {
    main: '#0044CC',
    alternate: '#00297c',
    hover: '#4381ff',
    jersey: '#fff',
    border: '#fff',
  }, // sb blue
  forMod: {
    main: '#F0878B',
    alternate: '#b74b4f',
    hover: '#F2999C',
    jersey: '#fff',
    border: '#fff',
  }, // lightened red
  againstMod: {
    main: '#80A1E5',
    alternate: '#4e6596',
    hover: '#93AFE9',
    jersey: '#fff',
    border: '#fff',
  }, // lightened blue
};
// for light mode keep the colours (including jersey) but invert the border
const FOR_AGAINST_COLORS_LIGHT = {
  for: {
    main: '#E21017',
    alternate: '#b2080e',
    hover: '#ff8286',
    jersey: '#fff',
    border: '#121212',
  }, // sb red
  against: {
    main: '#0044CC',
    alternate: '#00297c',
    hover: '#4381ff',
    jersey: '#fff',
    border: '#121212',
  }, // sb blue
  forMod: {
    main: '#F0878B',
    alternate: '#b74b4f',
    hover: '#F2999C',
    jersey: '#fff',
    border: '#121212',
  }, // lightened red
  againstMod: {
    main: '#80A1E5',
    alternate: '#4e6596',
    hover: '#93AFE9',
    jersey: '#fff',
    border: '#121212',
  }, // lightened blue
};

/* COLOURS TO USE FOR GOOD/BAD or SUCCESS/FAIL states (categorical / distinct) 
e.g. pass outcomes including success / touchdown (super success) / fail / interception (super fail)
*/
const GOOD_BAD_COLORS = {
  superSuccess: { main: '#00FF00', hover: '#f8ff00' }, // bright green
  success: { main: '#94ffe5', hover: '#e8ce12' }, // cyan
  success2: { main: '#0ed0ff', hover: '#e18933' }, // light blue
  neutral: { main: '#666666', hover: '#daece4' }, // grey
  fail: { main: '#c247ff', hover: '#767dff' }, // purple
  fail2: { main: '#ff87de', hover: '#b56ff3' }, // pink
  superFail: { main: '#ff0020', hover: '#bc123f' }, // red
};

const GOOD_BAD_COLORS_LIGHT = {
  superSuccess: { main: '#00A122', hover: '#f8ff00' }, // forest green
  success: { main: '#4cdab5', hover: '#e8ce12' }, // cyan
  success2: { main: '#00bcfa', hover: '#e18933' }, // light blue
  neutral: { main: '#cccccc', hover: '#daece4' }, // grey
  fail: { main: '#8700ce', hover: '#767dff' }, // purple
  fail2: { main: '#ea7ccd', hover: '#b56ff3' }, // pink
  superFail: { main: '#ff0020', hover: '#bc123f' }, // red
};

/* COLOURS TO USE FOR RENDERING CATEGORIES OF OBJECTS
For example you are rendering dots and need to distinguish between play types
Values should be selected in numerical order (i.e. n1 is theme default, n2 the next choice, n3...)
*/
const CATEGORY_COLORS = {
  n1: { main: '#6EF5DF' }, // cyan
  n2: { main: '#FF7A30' }, // orange
  n3: { main: '#D3A7FF' }, // pale purple
  n4: { main: '#D70F9A' }, // magenta
  n5: { main: '#1DB3F3' }, // sky blue
  n6: { main: '#FFDA7A' }, // gold
  n7: { main: '#C138DC' }, // purple
  n8: { main: '#FFB4C1' }, // peach
  neutral: { main: '#787878' }, // mid grey
};
const CATEGORY_COLORS_LIGHT = {
  n1: { main: '#02C3C8' }, // cyan
  n2: { main: '#F14E00' }, // orange
  n3: { main: '#6F04DA' }, // purple
  n4: { main: '#F980DB' }, // pink
  n5: { main: '#1682BE' }, // teal
  n6: { main: '#FFB84D' }, // sand
  n7: { main: '#740C5A' }, // purple plum
  n8: { main: '#BC1090' }, // cherry
  neutral: { main: '#C7C7C7' }, // mid grey
};

/* COLOURS TO USE FOR RANKING / OUTLIER CLASSES
For example in tables, where values more than a SD from a mean should be highlighted
  or the top 3 and bottom 3 values should be highlighted
*/
const RANK_BLACK_INK = '#111'; // so generally used in light mode
const RANK_WHITE_INK = '#fff'; // so generally used in dark mode
const RANK_COLORS_DARK = {
  antiGold: { main: '#d173d8', ink: RANK_WHITE_INK }, // worst values or most extreme negatively
  antiSilver: { main: '#822c9f', ink: RANK_WHITE_INK }, // second worst
  antiBronze: { main: '#4a1f83', ink: RANK_WHITE_INK }, // third worst
  bronze: { main: '#427a7c', ink: RANK_WHITE_INK }, // third best
  silver: { main: '#6cc5ab', ink: RANK_WHITE_INK }, // second best
  gold: { main: '#82ffc3', ink: RANK_BLACK_INK }, // best value or most extreme positively
};
const RANK_COLORS_LIGHT = {
  antiGold: { main: '#700078', ink: RANK_WHITE_INK }, // worst values or most extreme negatively
  antiSilver: { main: '#9144ab', ink: RANK_WHITE_INK }, // second worst
  antiBronze: { main: '#baa6d5', ink: RANK_BLACK_INK }, // third worst
  bronze: { main: '#afebe1', ink: RANK_BLACK_INK }, // third best
  silver: { main: '#73c9ba', ink: RANK_BLACK_INK }, // second best
  gold: { main: '#71edb1', ink: RANK_BLACK_INK }, // best value or most extreme positively
};

export const DARK_BACKGROUND = '#121212';
const DARK_GUIDE = '#333333';
const DARK_FOCUS = '#666666';
const DARK_AXIS = '#999999';
const DARK_CONTRAST = '#cccccc';

/* AMF OBJECTS COMMONLY USED */
const AMF_COMPONENTS = {
  ball: {
    fill: CATEGORY_COLORS.n1.main,
    fillOpacity: 1,
    stroke: '#fff',
    strokeOpacity: 1,
    strokeWidth: 1,
  },
  ballPath: {
    stroke: CATEGORY_COLORS.n1.main,
    strokeWidth: 1,
    strokeOpacity: 1,
    strokeDashArray: '3 3',
  },
  lineOfScrimmage: {
    stroke: DARK_CONTRAST,
    strokeWidth: 2,
    strokeOpacity: 1,
    strokeDashArray: '8 2',
  },
  targetLine: {
    stroke: DARK_CONTRAST,
    strokeWidth: 2,
    strokeOpacity: 1,
    strokeDashArray: '2 3',
  },
};

const darkModePalette = {
  background: {
    main: DARK_BACKGROUND, // will be dark mode tile/canvas color
    selected: '#222222',
    selectedBorder: '#1370C2', // brand colour
  },
  contrast: DARK_CONTRAST,
  border: DARK_AXIS,
  axis: DARK_AXIS,
  focus: DARK_FOCUS,
  guides: DARK_GUIDE,
  text: {
    header: '#f9f9f9', // top of vis
    subHeader: '#f4f4f4', // secondary information
    info: '#f0f0f0', // general info block text (keys + legends)
    label: DARK_AXIS, // e.g. names and numbers of axis
    focus: DARK_FOCUS, // e.g. denoting areas that have been selected
    guides: DARK_GUIDE,
  },
  zones: {
    default: DARK_BACKGROUND,
    alternate: '#1a1a1a', // minor differential
    alternate2: '#1f1f1f',
    important: '#222222', // e.g. endzone, still darker than all guides
  },
  positions: posPaletteDark,
  forAgainst: FOR_AGAINST_COLORS_DARK,
  successFail: GOOD_BAD_COLORS,
  objects: CATEGORY_COLORS,
  commonComponents: AMF_COMPONENTS,
  selectedObject: '#fff',
  selectionFaded: '#6c6c6c',
  rank: RANK_COLORS_DARK,
};

const LIGHT_BACKGROUND = '#ffffff';
const LIGHT_GUIDE = '#eeeeee';
const LIGHT_FOCUS = '#cccccc';
const LIGHT_AXIS = '#999999';
const LIGHT_CONTRAST = '#666666';

const AMF_COMPONENTS_LIGHT = {
  ball: {
    fill: CATEGORY_COLORS_LIGHT.n1.main,
    fillOpacity: 1,
    stroke: '#121212',
    strokeOpacity: 1,
    strokeWidth: 1,
  },
  ballPath: {
    stroke: CATEGORY_COLORS_LIGHT.n1.main,
    strokeWidth: 1,
    strokeOpacity: 1,
    strokeDashArray: '3 3',
  },
  lineOfScrimmage: {
    stroke: '#777777',
    strokeWidth: 2,
    strokeOpacity: 1,
    strokeDashArray: '8 2',
  },
  targetLine: {
    stroke: '#777777',
    strokeWidth: 2,
    strokeOpacity: 1,
    strokeDashArray: '2 3',
  },
};

const lightModePalette = {
  background: {
    main: LIGHT_BACKGROUND, // will be dark mode tile/canvas color
    selected: '#f0f0f0',
    selectedBorder: '#2B6CB0', // brand colour
  },
  contrast: LIGHT_CONTRAST,
  border: LIGHT_AXIS,
  axis: LIGHT_AXIS,
  focus: LIGHT_FOCUS,
  guides: LIGHT_GUIDE,
  text: {
    header: '#333333', // top of vis
    subHeader: '#393939', // secondary information
    info: '#404040',
    label: LIGHT_AXIS, // e.g. names and numbers of axis
    focus: LIGHT_FOCUS, // e.g. denoting areas that have been selected
    guides: LIGHT_GUIDE,
  },
  zones: {
    default: LIGHT_BACKGROUND,
    alternate: '#fcfcfc', // minor differential
    alternate2: '#f0f0f0',
    important: '#f4f4f4', // e.g. endzone, still darker than all guides
  },
  positions: posPaletteLight,
  forAgainst: FOR_AGAINST_COLORS_LIGHT,
  successFail: GOOD_BAD_COLORS_LIGHT,
  objects: CATEGORY_COLORS_LIGHT,
  commonComponents: AMF_COMPONENTS_LIGHT,
  selectedObject: '#000',
  selectionFaded: '#ccc',
  rank: RANK_COLORS_LIGHT,
};

const getPalette = (isDarkMode = true) =>
  isDarkMode ? darkModePalette : lightModePalette;

/* 
Slightly backwards logic, and shouldn't get used in new visualisations, 
  but many older functions pass around the palette independent of mode, but then call 
  color schemes
*/
const paletteIsDark = (visPalette) =>
  visPalette.background.main === darkModePalette.background.main;

/* Go from a numeric int to the relevant index within the object palette */
const getObjectColor = (visPalette, n) => {
  const mainColors = [
    visPalette.objects.n1.main,
    visPalette.objects.n2.main,
    visPalette.objects.n3.main,
    visPalette.objects.n4.main,
    visPalette.objects.n5.main,
    visPalette.objects.n6.main,
    visPalette.objects.n7.main,
    visPalette.objects.n8.main,
  ];
  if (n <= mainColors.length) {
    return mainColors[n];
  }
  return visPalette.objects.neutral.main;
};
export const getEnrichedTheme = (theme) => ({
  ...theme,
  colours: {
    ...theme.colours,
    visualisations: getPalette(theme.isDark),
  },
});

export {
  getPalette,
  POSITION_COLOR_ATTRIBUTE_JERSEY,
  POSITION_COLOR_ATTRIBUTE_MAIN,
  POSITION_COLOR_ATTRIBUTE_BORDER,
  getObjectColor,
  paletteIsDark,
};
