import PropTypes from 'prop-types';
import { ALIGNMENT } from '../../utils/constants/alignment';

// An array of objects containing the data for the table header rows
export const sortableTableHeaderPropTypes = PropTypes.shape({
  // the header id
  id: PropTypes.string,
  // same as id but we need it as an option because that's what basic table expects
  key: PropTypes.string,
  // The header label
  label: PropTypes.string,
  // whether the column the header belongs to, is sortable
  isSortable: PropTypes.bool,
  // description tooltip which displays when the header is hovered
  description: PropTypes.string,
  // override for setting string columns to a fixed width
  width: PropTypes.string,
  // button definitions
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      clickHandler: PropTypes.func,
    })
  ),
});

// An array of objects containing the data for the table header rows
export const sortableTableHeadersPropTypes = PropTypes.arrayOf(
  sortableTableHeaderPropTypes
);

// the data driving the rows of the table
export const tableRowsPropTypes = PropTypes.oneOfType([
  // a list of objects with values that can be strings or numbers
  PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
]);

// determines how each column should be aligned. can be either an alignment
// string or a function using the index of the columns/headers
export const sortableTableAlignmentPropTypes = PropTypes.oneOfType([
  PropTypes.oneOf(Object.values(ALIGNMENT)),
  PropTypes.func,
]);

// Which columns to keep on screen when the user scrolls right.
// Can be a single number (1-based index) if only one sticky column is
// required, otherwise needs to be an array of column objects containing the
// column index and width.
export const sortableTableStickyColumnsPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.exact({
      // Column index (1-based as-per CSS)
      index: PropTypes.number,
      // Width of the column in REMs
      width: PropTypes.number,
    })
  ),
  PropTypes.number,
]);

export const sortableTableConfigPropTypes = PropTypes.exact({
  // the id of the tab
  id: PropTypes.string.isRequired,
  // the displayed label for the tab
  label: PropTypes.string.isRequired,
  // the config driving the table a tab is rendering
  tableConfig: PropTypes.exact({
    headers: sortableTableHeadersPropTypes.isRequired,
    rows: tableRowsPropTypes,
    alignment: sortableTableAlignmentPropTypes,
    stickyColumns: sortableTableStickyColumnsPropTypes,
  }),
});

export const sortableTableCellSize = PropTypes.oneOf([
  'small',
  'regular',
  'large',
]);
