import { ROTATIONS } from '../constants/charting';

export const getJerseyRotation = function (rotationOption) {
  if (rotationOption === ROTATIONS.VERTICAL_UP) {
    return 'rotate(90,0,0)';
  }
  if (rotationOption === ROTATIONS.VERTICAL_DOWN) {
    return 'rotate(-90,0,0)';
  }
  return '';
};
