import React, { useEffect } from 'react';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import PropTypes from 'prop-types';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import {
  Playlist,
  VideoKeyBoardShortcutProvider,
  VideoPlay,
  usePlaylistCurrentlySelected,
} from '@statsbomb/video-client-js';
import Tile from '../Tile/Tile';
import {
  USER_GROUP,
  USER_ROLES,
  VIDEO_JWT_CLAIMS,
} from '../../utils/constants/auth';
import useQueryString from '../../utils/hooks/useQueryString';
import DownloadCSVButton from '../buttons/DownloadCSV';
import GoTo from '../buttons/GoTo';
import {
  StatsBlockItem,
  PlaylistContainer,
  VideoStyleWrapper,
} from './VideoTile.styles';

const VideoTile = ({
  children,
  data,
  title,
  subTitle,
  gotoLink,
  isGotoDisabled,
  handlePlaylistChange,
  handleClose,
  downloadData,
  downloadHeaders,
  dataTransformFunction,
}) => {
  const { user } = useKitbagAuth();
  const [showClipInfo, setShowClipInfo] = useQueryString('clipInfo', true);
  const [showVideo, setShowVideo] = useQueryString('video', true);
  const [currentlyPlayingPlay] = usePlaylistCurrentlySelected();

  const isAuthorised =
    user['https://statsbomb.com/accountID'] === USER_GROUP.STATSBOMB ||
    user[VIDEO_JWT_CLAIMS]?.roles.includes(USER_ROLES.VIDEO_CUSTOMER);

  const currentEventData = data?.find(
    (i) => i?.play?.id === currentlyPlayingPlay?.id
  );

  if (currentEventData === null) {
    handleClose();
  }

  useEffect(() => {
    handlePlaylistChange(currentlyPlayingPlay);
  }, [currentlyPlayingPlay]);

  // focus on video for keyboard
  useEffect(() => {
    const observerCallback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          Array.from(mutation.addedNodes).some((node) => {
            if (node.tagName && node.tagName.toLowerCase() === 'video') {
              node.focus(); // Add focus to the video element
              return true; // Exit loop early if a video element is found
            }
            return false;
          });
        }
      });
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const currentEventFromPlaylist = dataTransformFunction(currentEventData);
  const videoTitle = currentEventFromPlaylist?.title;
  const videoSubTitle = currentEventFromPlaylist?.game;

  return (
    <Tile dataTestId="video-tile" border="0">
      <Tile.Header>
        <div style={{ minHeight: '2.625rem' }}>
          <h3>{title || videoTitle}</h3>
          <div style={{ padding: '0.25rem 0 0 0' }}>
            <StatsBlockItem>{subTitle || videoSubTitle}</StatsBlockItem>
          </div>
        </div>
        <div className="buttons">
          {data && children && (
            <ButtonIcon
              id="show-clip-info-button"
              onClick={() => setShowClipInfo(!showClipInfo)}
              icon="Faq"
              size="small"
              variant="secondary"
              off={!showClipInfo}
              title={showClipInfo ? 'Hide Event Info' : 'Show Event Info'}
            />
          )}
          {isAuthorised && data && (
            <ButtonIcon
              id="show-video-panel-button"
              onClick={() => setShowVideo(!showVideo)}
              icon="Video"
              size="small"
              variant="secondary"
              off={!showVideo}
              title={showVideo ? 'Hide Video Panel' : 'Show Video Panel'}
            />
          )}
          {downloadData && downloadHeaders && (
            <DownloadCSVButton
              data={downloadData}
              fileName={`${title}.csv`}
              title={`Download ${title}.csv`}
              headers={downloadHeaders}
            />
          )}
          {gotoLink && (
            <GoTo
              href={gotoLink}
              linkName="Open in Play Animation"
              disabled={isGotoDisabled}
            />
          )}
          <ButtonIcon
            id="close-video-panel-button"
            onClick={handleClose}
            icon="Close"
            size="small"
            variant="secondary"
            title="Close Video Panel"
          />
        </div>
      </Tile.Header>
      <Tile.Body
        $padding={showClipInfo ? '0 0 0.2rem 0' : '0'}
        $gap="0"
        className="this"
      >
        {isAuthorised && showVideo && data && (
          <VideoKeyBoardShortcutProvider>
            <VideoStyleWrapper>
              <VideoPlay
                controls
                autoPlay
                muted
                plays={data}
                testId="video-play"
              />
              {showClipInfo && children}
              {data.length > 1 && (
                <PlaylistContainer>
                  <Playlist />
                </PlaylistContainer>
              )}
            </VideoStyleWrapper>
          </VideoKeyBoardShortcutProvider>
        )}
        {!isAuthorised && showVideo && data && showClipInfo && children}
      </Tile.Body>
    </Tile>
  );
};

VideoTile.propTypes = {
  // info panel to display event info
  children: PropTypes.node,
  // play event data
  data: PropTypes.arrayOf(PropTypes.object),
  // title for the video tile
  title: PropTypes.string,
  // subtitle for the video tile
  subTitle: PropTypes.string,
  // link (from play) to /game/animation
  gotoLink: PropTypes.string,
  // disabled state for play animation button
  isGotoDisabled: PropTypes.bool,
  // function to set selected play uuid
  handlePlaylistChange: PropTypes.func,
  // function to close the video panel
  handleClose: PropTypes.func,
  // data for download function
  downloadData: PropTypes.arrayOf(PropTypes.object),
  // headers for download data
  downloadHeaders: PropTypes.arrayOf(PropTypes.object),
  // how to extract the title and subtitle from the data being passed
  dataTransformFunction: PropTypes.func,
};

VideoTile.defaultProps = {
  children: null,
  data: [],
  title: '',
  subTitle: '',
  gotoLink: '',
  isGotoDisabled: false,
  handlePlaylistChange: () => {},
  handleClose: () => {},
  downloadData: null,
  downloadHeaders: null,
  dataTransformFunction: () => {},
};

export default VideoTile;
export const MemoizedVideoTile = React.memo(VideoTile);
