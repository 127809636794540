import { gql } from '@apollo/client';

export const GET_TEAM_RADAR_TEMPLATES = gql`
  query GetTeamRadarTemplates($competitionId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")

    teamRadarTemplates(competitionId: $competitionId) {
      name
      defaultFor
      stats {
        name
        axisMin
        axisMax
      }
      secondaryStats {
        name
        axisMin
        axisMax
      }
    }
  }
`;
