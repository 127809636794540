import React, { useState, useEffect } from 'react';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { useQuery, useReactiveVar, gql } from '@apollo/client';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import { mf_PlayerDetails, mf_TeamDetails } from '../../../apollo';
import GoTo from '../../../components/buttons/GoTo';
import {
  getRadarStatsLeagueAverageQuery,
  getRadarStatsQuery,
} from '../PlayerRadar/getPlayerRadarStats';
import { useGetTemplateDefinitions } from '../PlayerRadar/PlayerRadar.hooks';
import { formatTemplateStatsAsRadarAxes } from '../PlayerRadar/PlayerRadar.dataManipulation';
import useQueryString from '../../../utils/hooks/useQueryString';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import { PLAYER_RADAR_CHART_OPTIONS } from './PlayerRadar.Tile.constants';
import { RadarTileContainer } from './PlayerRadar.Tile.styles';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const PlayerRadarTile = function () {
  // selected player
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const [showLeagueAverage, setShowLeagueAverage] = useQueryString(
    'avg',
    false
  );

  const teamColors = {
    primary: mf_TeamDetailsRV?.colorPrimary,
    secondary: mf_TeamDetailsRV?.colorSecondary,
    tertiary: mf_TeamDetailsRV?.colorTertiary,
  };

  const {
    templates,
    loading: isConfigLoading,
    error: configError,
  } = useGetTemplateDefinitions();

  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  /* Can't set the default selectedTemplate until you have the player's details */
  useEffect(() => {
    const playerRole = mf_PlayerDetailsRV?.mostCommonPosition?.generalPosition;

    if (playerRole && templates) {
      const defaultTemplate =
        (playerRole &&
          templates?.find((t) => t.defaultFor.includes(playerRole))) ||
        templates?.[0];

      setSelectedTemplateName(defaultTemplate?.name);
    }
  }, [mf_PlayerDetailsRV, isConfigLoading]);

  /* Selected template and stats */
  const selectedTemplate = templates?.find(
    (t) => t.name === selectedTemplateName
  );
  const templateNames = selectedTemplate?.stats
    ?.concat(selectedTemplate?.secondaryStats)
    ?.map((m) => m.name);

  /* From the stats, get the player and League Average values */
  const playerStatsGQLString = getRadarStatsQuery(templateNames);
  const playerStatsLAGQLString = getRadarStatsLeagueAverageQuery(templateNames);
  const defaultRosterPositions = selectedTemplate?.defaultFor;

  const {
    data: dataStats,
    loading: isStatsLoading,
    error: statsError,
  } = useQuery(gql(playerStatsGQLString), {
    variables: {
      specificPositions: [],
    },
    headers: {
      'Accept-Version': 'v2023q2',
    },
  });
  const isError = !!statsError || !!configError;
  const isLoading = isConfigLoading || isStatsLoading;
  if (statsError) {
    console.error(ERROR_CODES.GET_RADAR_STATS, statsError);
  }
  const {
    data: dataStatsLA,
    loading: statsLoadingLA,
    error: statsLAError,
  } = useQuery(gql(playerStatsLAGQLString), {
    variables: {
      specificPositions: [],
      rosterPositions: defaultRosterPositions,
    },
    headers: {
      'Accept-Version': 'v2023q2',
    },
  });
  if (statsLAError) {
    console.error(ERROR_CODES.GET_RADAR_STATS_LA, statsLAError);
  }

  const loadingMsg = () => {
    if (isConfigLoading) {
      return 'Loading Templates';
    }

    if (isStatsLoading) {
      return 'Loading Player Stats ';
    }

    if (statsLoadingLA) {
      return 'Loading League Average Data';
    }

    return '';
  };

  const radarStats = dataStats?.playerStats?.items?.[0];
  const radarStatsLA = dataStatsLA?.playerStats?.items?.[0];

  // Extract trait and performance data
  const traitTemplate = selectedTemplate?.secondaryStats?.map((m) => m.name);
  const perfTemplate = selectedTemplate?.stats?.map((m) => m.name);
  const radarTraitStats = {};
  const radarPerfStats = {};
  const radarTraitStatsLA = {};
  const radarPerfStatsLA = {};

  // prepare player data separate from the long league average query
  if (dataStats && selectedTemplate) {
    traitTemplate.forEach((attr) => {
      radarTraitStats[attr] = radarStats[attr];
    });
    radarTraitStats.plays = radarStats?.plays;

    perfTemplate.forEach((attr) => {
      radarPerfStats[attr] = radarStats[attr];
    });
    radarPerfStats.plays = radarStats?.plays;
  }

  if (dataStatsLA && selectedTemplate) {
    traitTemplate.forEach((attr) => {
      radarTraitStatsLA[attr] = radarStatsLA[attr];
    });
    radarTraitStatsLA.plays = radarStatsLA?.plays;

    perfTemplate.forEach((attr) => {
      radarPerfStatsLA[attr] = radarStatsLA[attr];
    });
    radarPerfStatsLA.plays = radarStatsLA?.plays;
  }

  const radarTraitComparison = showLeagueAverage && radarTraitStatsLA;
  const radarPerfComparison = showLeagueAverage && radarPerfStatsLA;

  const templateConfigTrait = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.secondaryStats
  );
  const templateConfigPerf = formatTemplateStatsAsRadarAxes(
    selectedTemplate?.stats
  );

  return (
    <SummaryTile>
      {isConfigLoading || (isStatsLoading && <TileDimmer />)}
      <SummaryTileHeader>
        <div>
          <h3>Radars</h3>
        </div>
        <div>
          {!isLoading && !isError && (
            <DropdownWrapper>
              <Dropdown
                id="playerRadarDrop"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(item) => setShowLeagueAverage(item.value === 1)}
                options={PLAYER_RADAR_CHART_OPTIONS}
                value={PLAYER_RADAR_CHART_OPTIONS[showLeagueAverage ? 1 : 0]}
                labelPosition="none"
                label="Player radar display options"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            href={`/player/radar/:leagues/:seasons/:teams/:players${
              showLeagueAverage ? '?average=true' : ''
            }`}
            linkName="Open Player Radar"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        <div>
          {isError && !isLoading && !statsLoadingLA && (
            <SummaryError>
              <Icon size="small" variant="Warning" colour="primary.main" />
              There has been an error.
            </SummaryError>
          )}
          <RadarTileContainer>
            <div>
              <p>Trait</p>
              <RadarChart
                radarId="radar-trait"
                templateConfig={templateConfigTrait}
                radarStatDatum={radarTraitStats}
                radarComparisonDatum={radarTraitComparison}
                teamColors={teamColors}
                marginOverride={{ top: 0, right: 0, bottom: 0, left: 0 }}
                fontScaler={1.2} // default content width/constrained width = 680/400
                simpleKey={showLeagueAverage === true}
              />
            </div>
            <div>
              <p>Performance</p>
              <RadarChart
                radarId="radar-performance"
                templateConfig={templateConfigPerf}
                radarStatDatum={radarPerfStats}
                radarComparisonDatum={radarPerfComparison}
                teamColors={teamColors}
                marginOverride={{ top: 0, right: 0, bottom: 0, left: 0 }}
                fontScaler={1.2} // default content width/constrained width = 680/400
                simpleKey={showLeagueAverage === true}
              />
            </div>
          </RadarTileContainer>
        </div>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {(isLoading || statsLoadingLA) && (
            <Loader active inline size="small" content={loadingMsg()} />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerRadarTile;
