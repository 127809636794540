import React from 'react';
import PropTypes from 'prop-types';
import {
  PlayerBioStat,
  PlayerBioStatsRow,
  PlayerBioStatWrapper,
} from '../PlayerBio.Tile.styles';
import {
  inchesToFeetAndInches,
  TABLE_NULL,
} from '../../../../utils/helpers/formatting';

const PlayerBioNFL = ({ playerBioData }) => {
  if (!playerBioData) return null;
  const {
    alignmentSnaps,
    height,
    weight,
    homeTown,
    homeState,
    attended,
    highSchool,
  } = playerBioData;

  return (
    <>
      <PlayerBioStatsRow>
        <PlayerBioStatWrapper>
          <h4>{`Alignment Position${
            alignmentSnaps?.length > 1 ? 's' : ''
          }`}</h4>
          <PlayerBioStat>
            <ul>
              {alignmentSnaps &&
                alignmentSnaps?.map((snap) => (
                  <li
                    key={snap.position}
                  >{`${snap.position} (${snap.total})`}</li>
                ))}
              {alignmentSnaps?.length === 0 && TABLE_NULL}
            </ul>
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Height</h4>
          <PlayerBioStat>
            {height ? inchesToFeetAndInches(height) : TABLE_NULL}
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Weight</h4>
          <PlayerBioStat>{weight ? `${weight} lbs` : TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Home town</h4>
          <PlayerBioStat>{homeTown || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Home state</h4>
          <PlayerBioStat>{homeState || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>High School</h4>
          <PlayerBioStat>{highSchool || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4> {`College${attended?.length > 1 ? 's' : ''}`}</h4>
          <PlayerBioStat>
            <ul>
              {attended &&
                attended.map((atten) => <li key={atten}>{atten}</li>)}
              {attended.length === 0 && TABLE_NULL}
            </ul>
          </PlayerBioStat>
        </PlayerBioStatWrapper>
      </PlayerBioStatsRow>
    </>
  );
};

PlayerBioNFL.propTypes = {
  playerBioData: PropTypes.shape({
    alignmentSnaps: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.string,
        total: PropTypes.number,
      })
    ),
    height: PropTypes.number,
    weight: PropTypes.number,
    collegeClass: PropTypes.string,
    homeTown: PropTypes.string,
    homeState: PropTypes.string,
    attended: PropTypes.arrayOf(PropTypes.string),
    highSchool: PropTypes.string,
  }),
};

PlayerBioNFL.defaultProps = {
  playerBioData: {
    alignmentSnaps: [],
    height: 0,
    weight: 0,
    collegeClass: '',
    homeTown: '',
    homeState: '',
    attended: [],
    highSchool: '',
  },
};

export default PlayerBioNFL;
