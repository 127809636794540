import { ALIGNMENT } from '../../../utils/constants/alignment';

export const PASS_COUNT_HEADERS_SELECTED = [
  { label: 'Metric', key: 'label', alignment: ALIGNMENT.LEFT },
  { label: 'Selection', key: 'selection', alignment: ALIGNMENT.RIGHT },
  { label: 'Total', key: 'value', alignment: ALIGNMENT.RIGHT },
];

export const PASS_COUNT_HEADERS_UNSELECTED = [
  { label: 'Metric', key: 'label', alignment: ALIGNMENT.LEFT },
  { label: 'Value', key: 'value', alignment: ALIGNMENT.RIGHT },
];

export const PLAYER_SELECTION_HEADERS = [
  { label: 'Player', key: 'label' },
  { label: 'Total', key: 'value' },
];
