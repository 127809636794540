import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../../utils/helpers/contextFilters';

export const getRunAttemptsLeagueAverageGQLString = (gapMode) => {
  const queryName = gapMode ? 'GetRunGapAttemptsLA' : 'GetRunPOAAttemptsLA';
  const grouping = gapMode ? 'gap' : 'dY';
  const queryStr = `query ${queryName}(
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    ${getContextFilterClientExports()}

    offensiveLineRunGapsLA(
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
      grouping: "${grouping}"
    ) {
      plays
      runGap
      pointOfAttackdY
      yardsGained
      successPlays
    }
  }
`;
  return gql(queryStr);
};
