import { join, meanBy, startsWith, sumBy, round, forEach } from 'lodash';
import { YDS_PER_S_IN_MPH } from '../../../utils/constants/charting';
import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import {
  COLORING_OPTION_PASS_SUCCESS,
  COLORING_OPTION_PLAY_SUCCESS,
} from '../../../visualisations/PassingChart/PassingChart.constants';
import { PASS_OUTCOMES, countSuccessful } from './PlayerPassing.constants';

/**
 * @param {Number} total
 * @param {Object} datum
 * @return {Number}
 */
const countPlaySuccess = (total, datum) =>
  datum.play.success ? total + 1 : total;
/**
 * @param {Number} total
 * @param {Object} datum
 * @return {Number}
 */
const countPlayFail = (total, datum) =>
  datum.play.success ? total : total + 1;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.playExplosive
 * * @return {Number}
 */
const countPlayExplosive = (total, { playExplosive }) =>
  playExplosive ? total + 1 : total;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.playTurnover
 * * @return {Number}
 */
const countPlayTurnover = (total, { playTurnover }) =>
  playTurnover ? total + 1 : total;

const getAveragePassVelocity = (datum) => {
  const totalPassLength = sumBy(datum, 'distance');
  const totalPassDuration = sumBy(datum, 'duration');
  const averagePassVelocity =
    totalPassDuration && totalPassLength / totalPassDuration;
  const averagePassVelocityMPH = (
    averagePassVelocity / YDS_PER_S_IN_MPH
  ).toFixed(1);
  return averagePassVelocityMPH;
};

const SECTION_PADDING = 30; // px
const LINE_PADDING = 18; // px

const noNaNValues = function (infoItem) {
  const modifiedDatum = { ...infoItem };
  if (startsWith(infoItem.value, 'NaN')) {
    modifiedDatum.value = '';
  }
  return modifiedDatum;
};

const passInfoItems = function (
  data,
  selectedEventsData,
  coloringOption,
  visPalette,
  passers = [],
  showPassCounts = true,
  showPassAverages = true,
  showPlayCounts = true,
  showPlayers = true
) {
  const infoItems = [];

  const isPassSuccess = coloringOption === COLORING_OPTION_PASS_SUCCESS.value;
  const isPlaySuccess = coloringOption === COLORING_OPTION_PLAY_SUCCESS.value;

  const textInfoColor = visPalette?.text.info;
  const playOutcomeColors = visPalette?.successFail;

  /*
  all data returned will be in this format
  this constructor gets used by tables + vis to render same information (mode selectable)
    x, dy, fontSize and fill are only relevant when displaying in the vis
    there are no "table specific" attributes 
  */
  const infoItemShell = {
    fill: textInfoColor,
    x: 0,
    dy: LINE_PADDING,
    fontSize: VISUALISATION_FONT_SETUPS.INFO_LABEL.SIZE,
    label: '',
    value: null,
    checked: true,
    selection: null,
  };

  // utility for adding items so that you only HAVE to specify label/value
  const addInfoItem = (label, value, selection, options = {}) => {
    const modified = {
      ...infoItemShell,
      ...options,
      label,
      value,
      selection,
    };
    infoItems.push(modified);
  };

  if (showPassCounts) {
    forEach(PASS_OUTCOMES, (passOutcome) => {
      const passTypeCount = data.reduce(passOutcome.counter, 0);
      const passTypeCountSelected = selectedEventsData?.reduce(
        passOutcome.counter,
        0
      );
      const name = passOutcome.isChild
        ? `  |  ${passOutcome.label}`
        : passOutcome.label;
      addInfoItem(name, passTypeCount, passTypeCountSelected, {
        fill: isPassSuccess
          ? playOutcomeColors?.superSuccess.main
          : textInfoColor,
      });
    });

    addInfoItem('Total Passes', data.length, selectedEventsData?.length, {});
  }

  if (showPassAverages) {
    const averagePassLength = meanBy(data, 'distance').toFixed(1);
    const averagePassLengthSelected = meanBy(
      selectedEventsData,
      'distance'
    ).toFixed(1);
    addInfoItem(
      'Average Distance',
      `${averagePassLength} yds`,
      startsWith(averagePassLengthSelected, 'NaN')
        ? null
        : `${averagePassLengthSelected} yds`,
      {
        dy: SECTION_PADDING,
      }
    );

    const averagePassSuccessLength = meanBy(
      data.filter(({ success }) => success),
      'distance'
    ).toFixed(1);
    const averagePassSuccessLengthSelected = meanBy(
      selectedEventsData?.filter(({ success }) => success),
      'distance'
    ).toFixed(1);
    addInfoItem(
      'Average Distance Completed',
      `${averagePassSuccessLength} yds`,
      startsWith(averagePassSuccessLengthSelected, 'NaN')
        ? null
        : `${averagePassSuccessLengthSelected} yds`,
      {}
    );

    const averagePassDuration = meanBy(data, 'duration').toFixed(1);
    const averagePassDurationSelected = meanBy(
      selectedEventsData,
      'duration'
    ).toFixed(1);
    addInfoItem(
      'Average Duration',
      `${averagePassDuration} s`,
      startsWith(averagePassDurationSelected, 'NaN')
        ? null
        : `${averagePassDurationSelected} s`,
      {}
    );

    addInfoItem(
      'Average Velocity',
      `${getAveragePassVelocity(data)} mph`,
      getAveragePassVelocity(selectedEventsData) > 0
        ? `${getAveragePassVelocity(selectedEventsData)} mph`
        : null,
      {}
    );

    const nonThrowaways = data.filter((f) => f.passThrowaway === false);
    const averageAirYards = meanBy(nonThrowaways, 'passAirYards').toFixed(1);
    const nonThrowawaysSelected = selectedEventsData?.filter(
      (f) => f.passThrowaway === false
    );
    const averageAirYardsSelected = meanBy(
      nonThrowawaysSelected,
      'passAirYards'
    ).toFixed(1);
    addInfoItem(
      'Average Air Yards Attempted',
      `${averageAirYards} yds`,
      startsWith(averageAirYardsSelected, 'NaN')
        ? null
        : `${averageAirYardsSelected} yds`,
      {}
    );

    const averagePassSuccessAirYards = meanBy(
      nonThrowaways.filter(({ success }) => success),
      'passAirYards'
    ).toFixed(1);
    const averagePassSuccessAirYardsSelected = meanBy(
      nonThrowawaysSelected?.filter(({ success }) => success),
      'passAirYards'
    ).toFixed(1);

    addInfoItem(
      'Average Air Yards Completed',
      `${averagePassSuccessAirYards} yds`,
      startsWith(averagePassSuccessAirYardsSelected, 'NaN')
        ? null
        : `${averagePassSuccessAirYardsSelected} yds`,
      {}
    );
  }

  if (showPlayCounts) {
    const passSuccess = data.reduce(countSuccessful, 0);
    const successPercentage = data.length
      ? ((passSuccess / data.length) * 100).toFixed(1)
      : 0;
    const passSuccessSelected = selectedEventsData?.reduce(countSuccessful, 0);
    const successPercentageSelected = selectedEventsData?.length
      ? ((passSuccessSelected / selectedEventsData?.length) * 100).toFixed(1)
      : 0;

    // info about the play
    const playSuccess = data.reduce(countPlaySuccess, 0);
    const formattedPlaySuccessRate = `${round(
      (playSuccess / data.length) * 100,
      1
    )}%`;
    const playSuccessSelected = selectedEventsData?.reduce(countPlaySuccess, 0);
    const formattedPlaySuccessRateSelected = `${round(
      (playSuccessSelected / selectedEventsData?.length) * 100,
      1
    )}%`;

    // different check here for successPercentageSelected as 0% success rate is valid
    addInfoItem(
      'Completion Rate',
      `${successPercentage}%`,
      startsWith(formattedPlaySuccessRateSelected, 'NaN')
        ? null
        : `${successPercentageSelected}%`,
      {}
    );

    addInfoItem(
      'Play Success %',
      formattedPlaySuccessRate,
      startsWith(formattedPlaySuccessRateSelected, 'NaN')
        ? null
        : formattedPlaySuccessRateSelected,
      {
        dy: SECTION_PADDING,
        fill: isPlaySuccess ? playOutcomeColors?.success.main : textInfoColor,
      }
    );

    addInfoItem(
      'Play Success',
      `${playSuccess}`,
      playSuccessSelected && `${playSuccessSelected}`,
      {
        dy: SECTION_PADDING,
        fill: isPlaySuccess ? playOutcomeColors?.success.main : textInfoColor,
      }
    );

    const playExplosive = data.reduce(countPlayExplosive, 0);
    const playExplosiveSelected = selectedEventsData?.reduce(
      countPlayExplosive,
      0
    );
    addInfoItem(
      'Explosive',
      `${playExplosive}`,
      playExplosiveSelected && `${playExplosiveSelected}`,
      {
        fill: isPlaySuccess
          ? playOutcomeColors?.superSuccess.main
          : textInfoColor,
      }
    );

    const playFail = data.reduce(countPlayFail, 0);
    const playFailSelected = selectedEventsData?.reduce(countPlayFail, 0);
    addInfoItem(
      'Play Fail',
      `${playFail}`,
      playFailSelected && `${playFailSelected}`,
      {
        fill: isPlaySuccess ? playOutcomeColors?.fail.main : textInfoColor,
      }
    );

    const playTurnover = data.reduce(countPlayTurnover, 0);
    const playTurnoverSelected = selectedEventsData?.reduce(
      countPlayTurnover,
      0
    );
    addInfoItem(
      'Turnover',
      `${playTurnover}`,
      playTurnoverSelected && `${playTurnoverSelected}`,
      {
        fill: isPlaySuccess ? playOutcomeColors?.superFail.main : textInfoColor,
      }
    );

    const averageYardsAfterCatch = meanBy(data, 'yardsAfterCatch').toFixed(1);
    const averageYardsAfterCatchSelected = meanBy(
      selectedEventsData,
      'yardsAfterCatch'
    ).toFixed(1);
    addInfoItem(
      'Average Yards After Catch',
      `${averageYardsAfterCatch} yds`,
      startsWith(averageYardsAfterCatchSelected, 'NaN')
        ? null
        : `${averageYardsAfterCatchSelected} yds`,
      {}
    );

    const averageYardsGained = meanBy(data, 'yardsGained').toFixed(1);
    const averageYardsGainedSelected = meanBy(
      selectedEventsData,
      'yardsGained'
    ).toFixed(1);
    addInfoItem(
      'Average Yards Gained',
      `${averageYardsGained} yds`,
      startsWith(averageYardsGainedSelected, 'NaN')
        ? null
        : `${averageYardsGainedSelected} yds`,
      {}
    );
  }

  if (showPlayers && passers?.length > 0) {
    passers.map((m, i) => {
      addInfoItem(m.playerName, m.passes, null, {
        dy: i === 0 ? SECTION_PADDING : LINE_PADDING,
        fontSize: VISUALISATION_FONT_SETUPS.INFO_LABEL_SMALL.SIZE,
        fill: m.color,
        id: m.playerId,
      });

      return null;
    });
  }

  return infoItems.map(noNaNValues);
};

const getSelectedEventDetails = function (selectedEventObj) {
  if (!selectedEventObj) {
    return null;
  }
  let outcome = 'Complete';
  const notes = [];
  if (selectedEventObj.passTouchdown) {
    outcome = 'Touchdown';
  } else if (selectedEventObj.catchInterception) {
    outcome = 'Interception';
  } else if (!selectedEventObj.success) {
    outcome = 'Incomplete';
  }
  if (selectedEventObj.isOut) {
    notes.push('Out of Bounds');
  }
  if (selectedEventObj.passUnderthrow) {
    notes.push('Underthrow');
  }
  if (selectedEventObj.passOverthrow) {
    notes.push('Overthrow');
  }
  if (selectedEventObj.passThrowaway) {
    notes.push('Throwaway');
  }
  const joinedNotes = join(notes, ', ');
  const selectedEventInfo = [
    {
      label: 'Passer',
      value: selectedEventObj?.player?.name,
      id: selectedEventObj?.player?.id,
    },
    {
      label: 'Target',
      value: selectedEventObj?.receiverPlayer?.name,
      id: selectedEventObj?.receiverPlayer?.id,
    },
    { label: 'Outcome', value: outcome },
    { label: 'Extras', value: joinedNotes },
    { label: 'Game', value: selectedEventObj?.game?.name },
    { label: 'Drive', value: selectedEventObj?.play?.drive?.name },
    { label: 'Play', value: selectedEventObj?.play?.name },
  ];
  return selectedEventInfo;
};

export { passInfoItems, getSelectedEventDetails, noNaNValues };
