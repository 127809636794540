import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sortBy, reverse, omit } from 'lodash';
import SortableTable from '../../../../components/Tables/SortableTable/SortableTable';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { API_STAT_UNITS } from '../../../../utils/constants/api';
import { formatHavocRows } from './HavocTable.dataManipulation';
import { HavocGroupDataProp } from './HavocTable.propTypes';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
const stickyColumns = [{ index: 1, width: 15 }];

const HavocTable = ({
  bodyRows,
  footerRows,
  columnHeaders,
  defaultColumnKey,
}) => {
  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [sortKey, setSortKey] = useState(defaultColumnKey);
  useEffect(() => {
    if (!columnHeaders.map((h) => h.isSortable.key).includes(sortKey)) {
      setSortKey(defaultColumnKey);
    }
  }, [columnHeaders, defaultColumnKey]);

  const handleSort = (statName) => {
    if (statName === sortKey) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      const sortColumn = columnHeaders.find((h) => h.key === statName);
      const defaultSortDirection =
        sortColumn?.units === API_STAT_UNITS.STRING ? ASCENDING : DESCENDING;
      setSortDirection(defaultSortDirection);
      setSortKey(statName);
    }
  };

  const sortedData = sortBy(bodyRows, sortKey);
  const orientatedData =
    sortDirection === DESCENDING ? reverse(sortedData) : sortedData;

  /* Lastly use the units in the headers to format the data */
  const formattedRows = formatHavocRows(orientatedData, columnHeaders);
  const formattedFooters = formatHavocRows(footerRows, columnHeaders);

  /* 
  Format the headers in sortable table structure 
  NOTE: Uses the term "id" not "key" as the parameter passed into the handleSort function
  */
  const stringHeaderIndexes = columnHeaders
    .map((h, i) => (h.units === API_STAT_UNITS.STRING ? i : null))
    .filter((m) => m !== null);
  const alignmentFunction = (columnIndex) =>
    stringHeaderIndexes.includes(columnIndex) ? 'left' : 'right';
  const sortableTableHeaders = columnHeaders.map((header) => {
    const unitlessHeader = omit(header, 'units');
    return { ...unitlessHeader, id: header.key };
  });

  return (
    <SortableTable
      headers={sortableTableHeaders}
      rows={formattedRows}
      footerRows={formattedFooters}
      name="havoc-player-table"
      stickyColumns={stickyColumns}
      alignment={alignmentFunction}
      handleSort={handleSort}
      sortDirection={sortDirection}
      sortBy={sortKey}
      isHeadSticky
      withBorder
    />
  );
};

HavocTable.propTypes = {
  bodyRows: HavocGroupDataProp.isRequired,
  footerRows: HavocGroupDataProp,
  columnHeaders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultColumnKey: PropTypes.string.isRequired,
};

HavocTable.defaultProps = {
  footerRows: null,
};

export default HavocTable;
