import { API_STAT_UNITS } from '../../../utils/constants/api';

export const FORMATION_SUMMARY_STICKY_COLUMNS = [
  { index: 1, width: 12 },
  { index: 2, width: 6 },
];

export const FORMATION_SUMMARY_STATS_CONFIG = {
  formationName: {
    order: 1,
    value: 'formationName',
    apiStat: true,
    definition: {
      name: 'formationName',
      abbrev: 'Formation',
      description: 'Formation name',
      prettyName: 'Formation Name',
    },
  },
  playCount: {
    order: 2,
    value: 'playCount',
    apiStat: true,
  },
  personnelPlayRate: {
    order: 3,
    value: 'personnelPlayRate',
    isDeltaStat: true,
    definition: {
      name: 'personnelPlayRate',
      abbrev: 'Pers %',
      description: '% of Personnel Plays',
      prettyName: 'Personnel Play Rate',
      units: API_STAT_UNITS.PERCENTAGE,
    },
  },
  totalPlayRate: {
    order: 4,
    value: 'totalPlayRate',
    isDeltaStat: true,
    definition: {
      name: 'totalPlayRate',
      abbrev: 'Play %',
      description: '% out of total plays',
      prettyName: 'Total Play Rate',
      units: API_STAT_UNITS.PERCENTAGE,
    },
  },
  runRate: {
    order: 5,
    value: 'runRate',
    isDeltaStat: true,
    statDistribution: true,
    csIntensityBlue: true,
    apiStat: true,
  },
  netYardsPerPlay: {
    order: 7,
    value: 'netYardsPerPlay',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  playSuccessRate: {
    order: 8,
    value: 'playSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  epaPerPlay: {
    order: 6,
    value: 'epaPerPlay',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  yardsPerRush: {
    order: 10,
    value: 'yardsPerRush',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  rushSuccessRate: {
    order: 11,
    value: 'rushSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  epaPerRush: {
    order: 9,
    value: 'epaPerRush',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  netYardsPerPass: {
    order: 13,
    value: 'netYardsPerPass',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  passSuccessRate: {
    order: 14,
    value: 'passSuccessRate',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
  epaPerPass: {
    order: 12,
    value: 'epaPerPass',
    isDeltaStat: true,
    statDistribution: true,
    apiStat: true,
  },
};

const FORMATION_SUMMARY_STATS = Object.values(FORMATION_SUMMARY_STATS_CONFIG);

export const FORMATION_SUMMARY_STAT_NAMES = FORMATION_SUMMARY_STATS.map(
  (s) => s.value
);

export const EXTRA_STAT_DEFINITIONS = FORMATION_SUMMARY_STATS.map(
  (s) => s.definition
).filter(Boolean);

export const FORMATION_SUMMARY_STAT_DISTRIBUTION =
  FORMATION_SUMMARY_STATS.filter((s) => s.statDistribution).map((s) => s.value);

export const FORMATION_SUMMARY_API_STATS = FORMATION_SUMMARY_STATS.filter(
  (s) => s.apiStat
).map((s) => s.value);

export const PLAY_COUNT = FORMATION_SUMMARY_STATS_CONFIG.playCount.value;
export const RUN_RATE = FORMATION_SUMMARY_STATS_CONFIG.runRate.value;
