import { join } from 'lodash';
import {
  API_STAT_UNITS,
  API_PLAYER_AGGREGATE_MODES,
} from '../../../utils/constants/api';
import { formatValue } from '../../League/stats/stats.dataManipulation';
import {
  ALIGNMENT_POSITIONS,
  ROSTER_POSITIONS,
} from '../../../utils/constants/positions';

const NONE_DROPDOWN_OPTION = { value: 'None', label: 'None' };

// OFFENSE: 0, DEFENCE: 1, SPECIAL_TEAMS: 2
const getCategoryIndex = (isOffense, isSpecialTeam) => {
  let index = 1;
  if (isOffense) {
    index = 0;
  }
  if (isSpecialTeam) {
    index = 2;
  }
  return index;
};

const getTemplateDropdownOptions = (templates) => {
  const templateOptions = templates?.map((t) => {
    const opt = { value: t.name, label: t.name };
    return opt;
  });

  const allOptions = [NONE_DROPDOWN_OPTION]
    .concat(templateOptions)
    .filter(Boolean);
  return allOptions;
};

const formatTemplateStatsAsRadarAxes = (templateStats) => {
  const templateConfig = templateStats?.map((t, i) => {
    /* Because requesting in rate mode, force counts into percentages when a rate mode stat of type count */
    const isPercentage =
      t.units === API_STAT_UNITS.PERCENTAGE ||
      (t.rate && t.units === API_STAT_UNITS.COUNT);
    const percentageAdjuster = isPercentage ? 100 : 1;
    const axisInnerLimit = t.lowerIsBetter ? t.axisMax : t.axisMin;
    const axisOuterLimit = t.lowerIsBetter ? t.axisMin : t.axisMax;
    const metric = {
      index: i,
      key: t.name,
      min: axisInnerLimit * percentageAdjuster,
      max: axisOuterLimit * percentageAdjuster,
      name: t?.abbrev || t.prettyName,
      prettyName: t.prettyName,
      description: t?.description || '',
      units: isPercentage ? API_STAT_UNITS.PERCENTAGE : t.units,
    };
    return metric;
  });
  return templateConfig;
};

const getTableData = (
  templateConfig,
  showLeagueAverage,
  radarStats,
  radarStatsComparison
) => {
  const laHeader = showLeagueAverage && { label: 'League', key: 'la' };
  const radarTableHeaders = [
    { label: 'Axis', key: 'name' },
    { label: 'Metric Name', key: 'prettyName' },
    { label: 'Player', key: 'statValue' }, // should be the actual player
    laHeader,
  ].filter(Boolean);
  const playsMetric = [
    {
      index: -1,
      key: 'plays',
      min: 0,
      max: 99999,
      name: '',
      prettyName: 'Plays',
      units: 'PLAYS',
    },
  ];
  const tableMetrics = templateConfig && playsMetric.concat(templateConfig);
  const radarTableData = tableMetrics?.map((m) => ({
    prettyName: m.prettyName,
    name: m.name,
    statValue:
      m.units === 'PLAYS'
        ? radarStats?.[m.key]
        : formatValue(
            radarStats?.[m.key],
            m.units,
            API_PLAYER_AGGREGATE_MODES.RATE
          ),
    la:
      m.units === 'PLAYS'
        ? '-'
        : formatValue(
            radarStatsComparison?.[m.key],
            m.units,
            API_PLAYER_AGGREGATE_MODES.RATE
          ),
    title: m?.description,
  }));
  return { radarTableData, radarTableHeaders };
};

const getRosterPositionBlurb = (rosterPositions) => {
  if (rosterPositions?.length > 0) {
    if (rosterPositions.length > 5) {
      return `Positions: ${rosterPositions?.length} selected`;
    }
    const rosterPositionCodes = join(
      rosterPositions?.map((m) => ROSTER_POSITIONS[m].code),
      ', '
    );
    return `Positions: ${rosterPositionCodes}`;
  }
  return null;
};
const getKeyInfo = (
  specificPositions, // for both queries (alignment mode)
  rosterPositions, // for league average query (all mode)
  playerName,
  teamName,
  radarStats,
  radarStatsComparison
) => {
  let positionBlurb = 'Any Roster/Alignment Position';
  if (specificPositions?.length > 5) {
    positionBlurb = `Positions: ${specificPositions?.length} selected`;
  } else if (specificPositions?.length > 0) {
    const specificPositionCodes =
      specificPositions?.length > 0 &&
      join(
        specificPositions?.map((m) => ALIGNMENT_POSITIONS[m].code),
        ', '
      );
    positionBlurb = `Positions: ${specificPositionCodes}`;
  }
  const keyBaseInfo = {
    main: {
      title: `${playerName} (${teamName})`,
      info1: `Data from ${radarStats?.plays} plays`,
      info2: positionBlurb,
    },
  };

  if (radarStatsComparison) {
    /* In "all" mode roster positions are passed in and should be used for LA
      else default to same as player 
    */
    const laPositionBlurb =
      getRosterPositionBlurb(rosterPositions) || positionBlurb;
    const laInfo = {
      title: 'League Average',
      info1: `Average across ${radarStatsComparison?.plays} plays`,
      info2: laPositionBlurb,
    };
    return { ...keyBaseInfo, comparison: laInfo };
  }
  return keyBaseInfo;
};

export {
  NONE_DROPDOWN_OPTION,
  getTemplateDropdownOptions,
  formatTemplateStatsAsRadarAxes,
  getTableData,
  getKeyInfo,
  getRosterPositionBlurb,
  getCategoryIndex,
};
