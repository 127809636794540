import { styled } from 'styled-components';

export const InputRow = styled.div`
  position: relative;
  width: 100%;
  height: 2.4rem;

  span {
    display: block;
    position: absolute;
    height: 1.6rem;
    font-size: 10px;
    padding-top: 6px;
  }
  span:first-child {
    text-align: left;
    left: 0px;
  }
  span:last-child {
    right: 0px;
    text-align: right;
  }

  & > div {
    display: block;
    position: absolute;
    left: 20%;
    width: 60%;

    > div {
      width: 100%;
    }
  }
`;
InputRow.displayName = 'InputRow';

export const ValidatorRow = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 16px 8px 16px;
  height: 3.2rem;
  margin: 8px 0px;

  div {
    width: 100%;
    padding: 4px;
    font-size: 12px;
  }
`;
ValidatorRow.displayName = 'ValidatorRow';
