import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { METRIC_SLIDER_DEFAULTS } from '../ScoutResults.constants';
import { SliderDisplay } from '../ReversibleSlider/ReversibleSlider.styles';
import {
  displayValuesSemantically,
  realValueToSliderValue,
  sliderValueToRealValue,
} from './MetricDualSlider.dataManipulation';
import { StyledSlider } from '../../../../components/ContextFilters/ContextFilters.styles';
import { RangeSliderWrapper } from '../BiometricFilters/BiometricFilters.styles';
import { sortNumerically } from '../../../../utils/helpers/sort';

/*
This component exists because the react components slider isn't good enough: 
  Can't format values
  Can't use ranges that don't start at 0
  Can't represent scales where smaller values are better
  -> pre-kitbag multi-value doesn't work too

This component can be used for all the above cases.
It essentially takes the actual values for a range and value-pair
  -> transposes these into a 0+ integer scale
  -> when the slider changes, it sends back the values returned to "actual space"

Null is a valid number for either end of the slider
  Null value means don't filter at this end of the scale
  As this is designed for metrics where the filter can be deleted, null for both ends is prohibited
*/
const MetricDualSlider = ({
  min,
  max,
  values,
  onValueChanged,
  lowerIsBetter,
  formatter,
  steps,
  valueFormatter,
  onValueClick,
  metricName,
}) => {
  /* Slider itself always works in integers */
  const sliderMin = -1;
  const sliderMax = steps + 1;
  const sliderStep = 1;

  /* 
  Bigger is better value ignores direction ~ i.e. react slider logic 
  Slider value is "how many steps is this from range start
    same as difference of real value from max/min depending on direction"
  */
  const sliderValueSetup = {
    realMin: min,
    realMax: max,
    lowerIsBetter,
    steps,
  };
  const stepValues = values.map((v, i) =>
    realValueToSliderValue({
      ...sliderValueSetup,
      realValue: v,
      isLeftSlider: i === 0,
    })
  );
  /* Slider values should be provided smallest first */
  const sliderValues = sortNumerically(stepValues);

  /* Translate back into real space */
  const sliderValuesChanged = (newValues) => {
    /* 
    newValues are each a multiple of steps over 0
    for lower is better, thus want that many steps under the max, for 
      normal scale, that many steps over the min
    */
    const actualValues = newValues.map((v) =>
      sliderValueToRealValue({ ...sliderValueSetup, sliderValue: v })
    );
    onValueChanged(actualValues);
  };

  /* Displayed values should match user expectation */
  const displayMin = lowerIsBetter ? max : min;
  const displayMax = lowerIsBetter ? min : max;
  const stringFormatter = isFunction(formatter)
    ? formatter
    : (v) => v?.toString();
  const valueStringFormatter = isFunction(valueFormatter)
    ? valueFormatter
    : stringFormatter;

  return (
    <RangeSliderWrapper>
      <StyledSlider
        ariaLabel="layout-width-slider"
        min={sliderMin}
        max={sliderMax}
        step={sliderStep}
        title=""
        value={sliderValues}
        onChange={sliderValuesChanged}
        reverse
        hideIndicators
      />

      <SliderDisplay>
        <span>{stringFormatter(displayMin)}</span>
        {displayValuesSemantically({
          values,
          valueStringFormatter,
          onValueClick,
          lowerIsBetter,
          metricName,
        })}
        <span>{stringFormatter(displayMax)}</span>
      </SliderDisplay>
    </RangeSliderWrapper>
  );
};

MetricDualSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onValueChanged: PropTypes.func.isRequired,
  lowerIsBetter: PropTypes.bool,
  formatter: PropTypes.func,
  steps: PropTypes.number,
  valueFormatter: PropTypes.func,
  onValueClick: PropTypes.func,
  metricName: PropTypes.string,
};

MetricDualSlider.defaultProps = {
  lowerIsBetter: false,
  formatter: null,
  steps: METRIC_SLIDER_DEFAULTS.STEPS,
  valueFormatter: null,
  onValueClick: null,
  metricName: '',
};

export default MetricDualSlider;
