/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Icon } from '@statsbomb/kitbag-components';
import { dropdownOptionPropType } from './DropdownFilter.propTypes';
import { StyledOption } from './DropdownFilter.styles';
import { HalfSizeIcon } from '../../pages/player/PlayerOverview/PlayerOverview.styles';

export const Option = ({ children, data, ...rest }) => (
  <components.Option {...rest}>
    <StyledOption title={data.label}>
      {children}
      {data.secondaryLabel && (
        <span style={{ margin: '0 1rem 0' }}>{data.secondaryLabel}</span>
      )}
    </StyledOption>
  </components.Option>
);

Option.propTypes = {
  // the label and value of the option
  data: dropdownOptionPropType.isRequired,
  // the contents of the option
  children: PropTypes.node.isRequired,
};

export const OptionWithIcon = ({ data, ...rest }) => (
  <components.Option {...rest}>
    <div
      style={{ alignItems: 'center', display: 'flex', gap: '0.8rem' }}
      title={data.label}
    >
      {data.iconHalfSize ? (
        <HalfSizeIcon>
          <Icon variant={data.variant} size="small" />
        </HalfSizeIcon>
      ) : (
        <Icon variant={data.variant} size="small" />
      )}
      {data.label}
    </div>
  </components.Option>
);

OptionWithIcon.propTypes = {
  // the label and value of the option
  data: dropdownOptionPropType.isRequired,
  // the contents of the option
  children: PropTypes.node.isRequired,
};

export const SingleValueWithIcon = ({ data, ...rest }) => (
  <components.SingleValue {...rest}>
    {data.iconHalfSize ? (
      <HalfSizeIcon>
        <Icon variant={data.variant} size="small" />
      </HalfSizeIcon>
    ) : (
      <Icon variant={data.variant} size="small" />
    )}
  </components.SingleValue>
);

SingleValueWithIcon.propTypes = {
  // the label and value of the option
  data: dropdownOptionPropType.isRequired,
};

export const SingleValue = ({ data, ...rest }) => (
  <components.SingleValue {...rest}>{data.label}</components.SingleValue>
);

SingleValue.propTypes = {
  // the label and value of the option
  data: dropdownOptionPropType.isRequired,
};
