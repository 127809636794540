const COLOR_GOOD_BAD = { label: 'Compare to Team', value: 'GOOD_BAD' };
const COLOR_RELATIVE_SCALING = { label: 'Relative', value: 'SCALING' };
const COLOR_DIVERGENT_SCALING = {
  label: 'Compare to League Average',
  value: 'DIVERGENT',
};
const COLOR_GAP_VS_POSITION = {
  label: 'Distinguish Gap and Position',
  value: 'GAP_POS',
};
/* Some Options disabled for v1 release */
const COLOR_MODES = [
  COLOR_GOOD_BAD,
  // COLOR_RELATIVE_SCALING,
  COLOR_DIVERGENT_SCALING,
  // COLOR_GAP_VS_POSITION,
];

const BAR_MODE_RAW = { label: 'Raw Pressures Allowed', value: 'RAW' };
const BAR_MODE_VS_LA = { label: 'Compared to LA +/-', value: 'LA' };
const BAR_MODE_LA_OFFSET = {
  label: 'Pressures allowed, relative to league average',
  value: 'LA_OFFSET',
};
const BAR_MODE_SIGMA = {
  label: 'Scale in terms of the league average',
  value: 'SIGMA',
};
const BAR_MODES = [
  // BAR_MODE_VS_LA,
  BAR_MODE_LA_OFFSET,
  BAR_MODE_RAW,
  BAR_MODE_SIGMA,
];

const PG_BOTH = { label: 'Positions and Gaps', value: 'BOTH' };
const PG_POS = { label: 'Positions only', value: 'POS' };
const PG_GAP = { label: 'Gaps only', value: 'GAP' };
const PG_MODES = [PG_BOTH, PG_POS, PG_GAP];

const API_OL_GAPS = {
  LEFT_D: 'D_LEFT',
  LEFT_C_OPEN: 'C_OPEN_LEFT',
  LEFT_C_CLOSED: 'C_CLOSED_LEFT',
  LEFT_B: 'B_LEFT',
  LEFT_A: 'A_LEFT',
  RIGHT_A: 'A_RIGHT',
  RIGHT_B: 'B_RIGHT',
  RIGHT_C_CLOSED: 'C_CLOSED_RIGHT',
  RIGHT_C_OPEN: 'C_OPEN_RIGHT',
  RIGHT_D: 'D_RIGHT',
};

export {
  COLOR_GOOD_BAD,
  COLOR_RELATIVE_SCALING,
  COLOR_DIVERGENT_SCALING,
  COLOR_GAP_VS_POSITION,
  COLOR_MODES,
  BAR_MODES,
  BAR_MODE_RAW,
  BAR_MODE_SIGMA,
  BAR_MODE_VS_LA,
  BAR_MODE_LA_OFFSET,
  PG_BOTH,
  PG_POS,
  PG_GAP,
  PG_MODES,
  API_OL_GAPS,
};
