import { API_PLAY_TYPES } from '../../../utils/constants/api';
import { millisecondsToTime } from '../../../utils/helpers/time';
import { getDownName, getYardlineName } from '../../../utils/helpers/play';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';

/**
 * @param {Object} event
 * @param {Object} event.play
 * @returns {String}
 */
const buildPlayTitle = function ({ play }) {
  const offenseTeam = play.offenseTeam.shortName;
  const toGo =
    typeof play?.yardsToGo === 'number'
      ? `, ${play.yardsToGo.toFixed(1)} to go`
      : '';

  return `${offenseTeam}, d${play.drive.gameIndex}, p${
    play.driveIndex
  } ${getDownName(play.down)}${toGo}, ${getYardlineName(play.yardLine)}`;
};

/**
 * @param {Object} event
 * @param {Object} event.play
 * @returns {String}
 */
const buildSubTitle = function ({ play }) {
  let title = API_PLAY_TYPES[play.type];
  if (play.touchdownWon) {
    title += ', Touchdown';
  }
  if (play.defensePenaltyAccepted) {
    title += ', Penalty on Offense';
  }
  if (play.offensePenaltyAccepted) {
    title += ', Penalty on Defense';
  }
  return title;
};

/**
 * @param {Number} yardsNet
 * @returns {String}
 */
const buildYdsWon = (yardsNet = 0) => {
  let title = '';
  if (yardsNet > 0) {
    title += '+';
  }
  title += yardsNet.toFixed(1);

  return title;
};

/**
 * @param {Object} event
 * @param {Object} event.play
 * @returns {String}
 */
const buildPlayClock = function ({ play }) {
  return `Q${play.quarter}, ${millisecondsToTime(play.quarterClockRemaining)}`;
};

/**
 * @param {Object} event
 * @param {Object} event.play
 * @returns {String}
 */
const buildEventTitle = function (event) {
  const name = event.name.replace('(FF)', '');
  return name.split('[')[0];
};

/**
 * @param {Object} event
 * @param {Object} event.play
 * @returns {String}
 */
const buildEventSubtitle = function (event) {
  const nameSplits = event.name.replace('(FF)', '').split('[');
  const subTitle = nameSplits.length > 0 ? nameSplits[1] : '';
  const tidiedSubTitle = (subTitle || '').replace(']', '');
  return tidiedSubTitle;
};

const addInfoZone = function (
  infoG,
  ffEvent,
  infoWidth,
  playInfo = true,
  visPalette
) {
  const title = playInfo ? buildPlayTitle(ffEvent) : buildEventTitle(ffEvent);
  const subTitle = playInfo
    ? buildSubTitle(ffEvent)
    : buildEventSubtitle(ffEvent);
  const mainInfo = playInfo ? buildYdsWon(ffEvent.play.yardsNet) : '';
  const subInfo = playInfo ? buildPlayClock(ffEvent) : '';

  infoG
    .append('text')
    .attr('x', 0)
    .attr('y', 25)
    .attr('fill', visPalette.text.header)
    .attr('font-size', VISUALISATION_FONT_SETUPS.TILE_HEADER.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.TILE_HEADER.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .text(title);

  infoG
    .append('text')
    .attr('x', 0)
    .attr('y', 48)
    .attr('fill', visPalette.text.subHeader)
    .attr('font-size', VISUALISATION_FONT_SETUPS.TILE_SUBHEADER.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.TILE_SUBHEADER.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .text(subTitle);

  infoG
    .append('text')
    .attr('x', infoWidth)
    .attr('y', 25)
    .attr('fill', visPalette.text.header)
    .attr('font-size', VISUALISATION_FONT_SETUPS.TILE_HEADER.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.TILE_HEADER.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text(mainInfo);

  infoG
    .append('text')
    .attr('x', infoWidth)
    .attr('y', 48)
    .attr('fill', visPalette.text.info)
    .attr('font-size', VISUALISATION_FONT_SETUPS.TILE_SUBHEADER.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.TILE_SUBHEADER.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text(subInfo);
};

export { addInfoZone };
