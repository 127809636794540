import { styled } from 'styled-components';

export const WizardTab = styled.div`
  border: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const WizardTabHeader = styled.div`
  ${({ theme }) => theme.typography.bodyRegular}
  line-height: 1;
  padding: 1rem;
  background: ${({ theme, selected }) =>
    selected
      ? theme.colours.canvas.secondary.main
      : theme.colours.canvas.tertiary.main};
  border-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  color: ${({ theme }) => theme.colours.ink.primary.main};

  &:hover {
    cursor: pointer;
    background: ${({ theme, selected }) =>
      selected
        ? theme.colours.canvas.secondary.strong
        : theme.colours.canvas.tertiary.strong};
  }

  span + span {
    ${({ theme }) => theme.typography.headingMedium}
    color: ${({ theme }) => theme.colours.ink.primary.main};
    display: inline-flex;
    gap: 1rem;
    float: right;
    line-height: 1;
    transition: transform 0.3s ease-in-out;

    &.open {
      transform: rotate(180deg);
    }

    svg {
      position: relative;
      top: -1px;
    }
  }
`;

export const WizardSelected = styled.span`
  padding: 0 1rem 0 0;
`;

export const WizardTabBody = styled.div`
  overflow: hidden;
  border-left: 4px solid ${({ theme }) => theme.colours.interface.main};
  border-radius: 0 0 0 0.4rem;
  display: flex;
  gap: 1rem;
  transition: min-height 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946),
    max-height 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  border-width: 0 0 0 4px;

  ${({ $isExpanded }) => `
    min-height: ${$isExpanded ? '40px' : '0'};
    max-height: ${$isExpanded ? '550px' : '0'};
  `}

  > div {
    width: 100%;
    padding: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
