import React from 'react';
import PropTypes from 'prop-types';
import { StyledSlider } from '../../../../../components/ContextFilters/ContextFilters.styles';
import { RangeSliderWrapper } from '../BiometricFilters.styles';
import { HEIGHT_RANGE } from '../BiometricFilters.constants';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { SliderDisplay } from '../../ReversibleSlider/ReversibleSlider.styles';
import { biometricProp } from '../../../Scout.proptypes';
import { inchesToFeetAndInches } from '../../../../../utils/helpers/formatting';

const HeightSlider = ({ selectedBiometrics, setSelectedBiometrics }) => {
  const heightMinFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.heightMin.key
  );
  const heightMaxFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.heightMax.key
  );
  const heightMin = heightMinFilter?.value || HEIGHT_RANGE.MIN;
  const heightMax = heightMaxFilter?.value || HEIGHT_RANGE.MAX;
  const heightSliderValue = [heightMin, heightMax];

  const onHeightSliderChange = (sliderValues) => {
    const sliderHeightMin = sliderValues[0];
    const sliderHeightMax = sliderValues[1];
    const nonHeightBiometrics = selectedBiometrics.filter(
      (b) =>
        b.key !== SCOUT_BIOMETRIC_FILTERS.heightMin.key &&
        b.key !== SCOUT_BIOMETRIC_FILTERS.heightMax.key
    );
    const minHeightBiometric =
      sliderHeightMin === HEIGHT_RANGE.MIN
        ? null
        : {
            key: SCOUT_BIOMETRIC_FILTERS.heightMin.key,
            value: sliderHeightMin,
          };
    const maxHeightBiometric =
      sliderHeightMax === HEIGHT_RANGE.MAX
        ? null
        : {
            key: SCOUT_BIOMETRIC_FILTERS.heightMax.key,
            value: sliderHeightMax,
          };
    const newBiometrics = nonHeightBiometrics
      .concat(minHeightBiometric, maxHeightBiometric)
      .filter((a) => a !== null);
    setSelectedBiometrics(newBiometrics);
  };

  const heightRange = Math.abs(HEIGHT_RANGE.MAX - HEIGHT_RANGE.MIN);
  const paddingPercentage = 100 / heightRange;

  const selectedHeightRangeDescription = () => {
    if (heightMin === HEIGHT_RANGE.MIN && heightMax === HEIGHT_RANGE.MAX) {
      return 'Any Height';
    }
    if (heightMin !== HEIGHT_RANGE.MIN && heightMax !== HEIGHT_RANGE.MAX) {
      return `${inchesToFeetAndInches(heightMin)} to ${inchesToFeetAndInches(
        heightMax
      )}`;
    }
    if (heightMin !== HEIGHT_RANGE.MIN) {
      return `At least ${inchesToFeetAndInches(heightMin)}`;
    }
    return `At most ${inchesToFeetAndInches(heightMax)}`;
  };

  return (
    <RangeSliderWrapper>
      <h3>Height</h3>
      <StyledSlider
        thumbClassName="thumb"
        trackClassName="track"
        value={heightSliderValue}
        ariaLabel={['heightMin', 'heightMax']}
        onChange={onHeightSliderChange}
        pearling
        minDistance={1}
        min={HEIGHT_RANGE.MIN}
        max={HEIGHT_RANGE.MAX}
        withTracks
        step={1}
      />
      <SliderDisplay $padding={paddingPercentage}>
        <span>{inchesToFeetAndInches(HEIGHT_RANGE.MIN + 1)}</span>
        <span>{selectedHeightRangeDescription()}</span>
        <span>{inchesToFeetAndInches(HEIGHT_RANGE.MAX - 1)}</span>
      </SliderDisplay>
    </RangeSliderWrapper>
  );
};
HeightSlider.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default HeightSlider;
