import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import { ROTATIONS } from '../../utils/constants/charting';
import {
  TACKLE_LOCATION_CLASSES,
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_DEFAULT_MARGIN,
  TACKLE_LOCATION_VIOLINS,
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLE_LOCATION_Y_MODE_FIELD,
  KEY_SEGMENT_HEIGHT,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLING_FIELD_DISPLAY_HEATMAP,
  TACKLING_FIELD_AXES_PADDING,
} from './TackleLocation.constants';
import {
  getFieldSize,
  getDistroData,
  getTackleMeans,
} from './TackleLocation.dataManipulation';
import { drawDistributions } from './TackleLocation.drawing';
import { drawDistributionKey } from './TackleLocation.key';
import { ClickableSVG } from '../visualisation.styles';

const TackleLocationDistribution = ({
  chartId,
  data,
  dataLA,
  orientation,
  displayYMode,
  displayXMode,
  displayXFocusedField,
  colorMode,
  scaleR,
  selectedPlay,
  setSelectedPlay,
  displayKey,
  distroAreaColorMode,
  ballCarriers,
  tacklers,
  fieldMode,
  fieldFocusOrigin,
  showHeatmapDots,
  showFirstTackleTails,
}) => {
  const margin = TACKLE_LOCATION_DEFAULT_MARGIN;
  const isFieldYRelative = displayYMode !== TACKLE_LOCATION_Y_MODE_FIELD.value;

  const fieldArea = getFieldSize(
    orientation,
    isFieldYRelative,
    displayXFocusedField
  );
  let keyHeight = 0;
  if (displayKey) {
    keyHeight =
      orientation === ROTATIONS.HORIZONTAL
        ? KEY_SEGMENT_HEIGHT * 2
        : KEY_SEGMENT_HEIGHT;
  }

  const distroWidth = TACKLE_LOCATION_VIOLINS.WIDTH;

  /* Spacing works inversely ~ with less width per violin pad them more */
  const distroSpacing = TACKLE_LOCATION_VIOLINS.SPACING * 2;
  const distroY =
    distroWidth * 3 + distroSpacing * 3 + TACKLE_LOCATION_VIOLINS.AXIS;
  const distroArea = {
    width: orientation === ROTATIONS.HORIZONTAL ? 0 : distroY,
    height: orientation === ROTATIONS.HORIZONTAL ? distroY : 0,
    violinWidth: distroWidth,
    violinSpacing: distroSpacing,
  };

  const viewBox = `0 0 ${margin.left + margin.right + distroArea.width} ${
    margin.top +
    margin.bottom +
    fieldArea.height +
    keyHeight +
    distroArea.height
  }`;

  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const ref = useD3((svg) => {
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', TACKLE_LOCATION_CLASSES.BACKGROUND);

    const marginTransform = `translate(${margin.left},${margin.top})`;
    svg
      .append('g')
      .attr('transform', marginTransform)
      .attr('class', TACKLE_LOCATION_CLASSES.IN_MARGINS);
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);
    svg
      .select(`.${TACKLE_LOCATION_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    /* Primary G just sits in the margins */
    const withinMargingsG = svg.select(
      `.${TACKLE_LOCATION_CLASSES.IN_MARGINS}`
    );
    withinMargingsG.selectAll('g').remove();

    /* Space for the distributions */
    withinMargingsG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.DISTROS_AREA);

    withinMargingsG
      .append('g')
      .attr('class', TACKLE_LOCATION_CLASSES.KEY)
      .attr(
        'transform',
        `translate(0,${
          fieldArea.height +
          TACKLING_FIELD_AXES_PADDING.top +
          TACKLING_FIELD_AXES_PADDING.bottom +
          distroArea.height
        })`
      );
  }, [orientation, displayYMode, displayXFocusedField, keyHeight, theme]);

  useEffect(() => {
    const svg = select(ref.current);
    const distrosG = svg.select(`.${TACKLE_LOCATION_CLASSES.DISTROS_AREA}`);
    const dataG = svg.select(`.${TACKLE_LOCATION_CLASSES.FIELD_DATA}`);
    dataG.selectAll('g').remove();
    distrosG.selectAll('g').remove();

    if (data) {
      /* Distributions Section */
      const taDomain = [
        -fieldArea.fieldLoS,
        -fieldArea.fieldLoS + fieldArea.fieldXYds,
      ];
      const distroData = getDistroData(data, taDomain, fieldArea.fieldXYds);
      /* Means */
      if (dataLA) {
        const means = getTackleMeans(data, dataLA);
        if (means?.firstTackle) {
          drawDistributions(
            svg,
            chartId,
            distroData,
            dataLA,
            visPalette,
            orientation,
            fieldArea,
            TACKLING_FIELD_AXES_PADDING,
            distroArea,
            distroAreaColorMode,
            means
          );
        }
      }

      /* Rendering Keys */
      const keyG = svg.select(`.${TACKLE_LOCATION_CLASSES.KEY}`);
      keyG.selectAll('g').remove();
      keyG.selectAll('rect').remove();
      if (displayKey) {
        const distroKeyG = keyG.append('g');

        drawDistributionKey(
          distroKeyG,
          visPalette,
          distroAreaColorMode,
          distroArea.width
        );
      }
    }
  }, [
    data,
    colorMode,
    displayYMode,
    displayXMode,
    visPalette,
    scaleR,
    displayXFocusedField,
    selectedPlay,
    setSelectedPlay,
    keyHeight,
    ballCarriers,
    tacklers,
    theme,
    fieldMode,
    fieldFocusOrigin,
    showHeatmapDots,
    showFirstTackleTails,
  ]);

  return <ClickableSVG ref={ref} />;
};

TackleLocationDistribution.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dataLA: PropTypes.arrayOf(PropTypes.shape({})),
  orientation: PropTypes.string,
  displayYMode: PropTypes.string,
  displayXMode: PropTypes.string,
  displayXFocusedField: PropTypes.bool,
  colorMode: PropTypes.string,
  scaleR: PropTypes.bool,
  selectedPlay: PropTypes.string,
  setSelectedPlay: PropTypes.func,
  displayKey: PropTypes.bool,
  distroAreaColorMode: PropTypes.string,
  ballCarriers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  tacklers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  fieldMode: PropTypes.string,
  fieldFocusOrigin: PropTypes.bool,
  showHeatmapDots: PropTypes.bool,
  showFirstTackleTails: PropTypes.bool,
};

TackleLocationDistribution.defaultProps = {
  chartId: 'tackling-chart-distribution',
  data: undefined,
  dataLA: undefined,
  orientation: ROTATIONS.VERTICAL_UP,
  displayYMode: TACKLE_LOCATION_Y_MODE_FIELD.value,
  displayXMode: TACKLE_LOCATION_X_MODE_LOS.value,
  displayXFocusedField: true,
  colorMode: TACKLE_LOCATION_COLOR_MODE_DX.value,
  scaleR: true,
  selectedPlay: null,
  setSelectedPlay: () => {},
  displayKey: true,
  distroAreaColorMode: TACKLE_LOCATION_DISTRO_COLOR_WINNER.value,
  ballCarriers: null,
  tacklers: null,
  fieldMode: TACKLING_FIELD_DISPLAY_HEATMAP.value,
  fieldFocusOrigin: true,
  showHeatmapDots: false,
  showFirstTackleTails: false,
};

export default TackleLocationDistribution;
