import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_PASSES = gql`
  query GetPasses(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    passEvents(
      first: 1000
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        offenseTeamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      edges {
        node {
          id
          team {
            name
          }
          player {
            id
            name
            teamPosition {
              code
            }
            jerseyNumber
          }
          receiverPlayer {
            id
            name
            teamPosition {
              code
              generalPosition
            }
            jerseyNumber
          }
          x
          y
          endX
          endY
          duration
          success
          passPlacementX
          passPlacementY
          points
          passAirYards
          receiverAlignmentPosition
          types
          play {
            yardLine
            success
            touchdownWon
            yardsNet
            turnoverType
            downNegated
            offensePenaltyAccepted
            defensePenaltyAccepted
            explosive
            offenseTeam {
              id
              name
            }
            id
            name
            drive {
              id
              name
            }
          }
          game {
            id
            name
          }
          passOutcome {
            oppositionCatchTypes
            oppositionCatchSuccess
          }
          passUnderthrow
          passOverthrow
          passTouchdown
          passOutOfBounds
          passYardsGained
          yardsAfterCatch
          passThrowaway
        }
      }
    }
  }
`;
