import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { GET_PLAYER_STAT_DEFINITIONS } from '../../../../utils/hooks/apiQueries/playerStats/getPlayerStatDefinitions';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';
import { GET_TEAMS } from '../../../../components/Header/getTeams';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { NO_TEAMS_OPTION } from './PlayerStats.constants';

// returns an enriched and ordered player stat definitions for a given category/subcategory/aggMode combination
export const useGetPlayerStatDefinitions = (
  orderedSubcategoryStats,
  isRateMode
) => {
  const { data, loading, error } = useQuery(GET_PLAYER_STAT_DEFINITIONS);

  if (loading) {
    return { isLoadingStatDefinitions: loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_GET_PLAYER_STATS_DEFINITIONS, error);
    return {
      isLoadingStatDefinitions: false,
      statDefinitionsError: error,
    };
  }

  const statDefinitions = orderedSubcategoryStats
    // enrich the list of ordered stats with their corresponding stat definition
    ?.map((stat) =>
      data?.playerStatDefinitions.find(
        (statDefinition) => statDefinition.name === stat
      )
    )
    // we only want the stats for the selected aggregate mode
    .filter((stat) => stat.rate === isRateMode);

  return {
    isLoadingStatDefinitions: false,
    statDefinitions,
  };
};

export const useGetPlayerStatsTeams = () => {
  const { data, loading, error } = useQuery(GET_TEAMS);

  if (loading) {
    return { teamsLoading: loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_GET_PLAYER_STATS_TEAMS, error);
    return { teamsLoading: true, teamsError: error };
  }

  const teams = data?.teams.edges.map((team) => ({
    label: team.node.name,
    value: team.node.id,
  }));
  const orderedTeams = orderBy(teams, ['name'], [SORT_DIRECTIONS.ASCENDING]);
  const options = [NO_TEAMS_OPTION, ...orderedTeams];

  return { teamsLoading: false, teamsData: options };
};
