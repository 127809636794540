import { sortBy } from 'lodash';
import { API_OL_GAPS } from '../../../utils/constants/api';
import { LINE_GAPS, gapColor } from '../../../utils/constants/gaps';

export const HAVOC_KEY_WIDTH = 560;
export const HAVOC_KEY_VERTICAL_WIDTH = 200;
export const HAVOC_KEY_VERTICAL_HEIGHT = 320;

export const getGapDots = (visPalette) => {
  const gapDots = Object.values(API_OL_GAPS).map((apiKey) => {
    const gap = LINE_GAPS[apiKey];
    const fillColor = gapColor(apiKey, visPalette, false);
    const strokeColor = gapColor(apiKey, visPalette, true);
    return { ...gap, color: fillColor, stroke: strokeColor };
  });
  return sortBy(gapDots, 'index');
};
