import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';
import { FORMATION_SUMMARY_API_STATS } from './TeamFormationSummary.constants';

const context = { personnel: false, formation: false };

export const GET_FORMATION_SUMMARY_TEAM_STATS = gql`
    query GetFormationSummaryTeamStats(
      $competitionId: Int!
      $seasonId: Int!
      $offenseTeamIds: [Int!]
      $groupBy: [TeamStatGroupBy!]
      ${getContextQueryVariables(context)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      ${getContextFilterClientExports(context)}

      teamStats(
        groupBy: $groupBy
        filters: {  
          competitionId: $competitionId
          seasonId: $seasonId
          offenseTeamIds: $offenseTeamIds
          ${getContextFiltersQueryArguments(context)}
        }
      ) {
        items {
          groups {
            key
            value
          }
          # offensePersonnel is not included in the config because it is not part of the table columns
          offensePersonnel
          ${FORMATION_SUMMARY_API_STATS.join('\n')}
        }
      }
    }
  `;
