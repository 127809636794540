import React from 'react';
import { Button } from '@statsbomb/kitbag-components';
import PropTypes from 'prop-types';
import {
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../../components/Tile/TileSummary.styles';
import { getPlayFiltersWidget } from './PlayFinderWidgets';
import { PLAY_FINDER_WIDGET_TYPES } from '../../../../utils/constants/api';

const PopupFilterWrapper = ({ widgetProps, title, handleRemoveFilter }) => (
  <>
    <SummaryTileHeader>
      <div>
        <h3>{title}</h3>
      </div>
      <div style={{ flex: 0 }}>
        <Button variant="secondary" size="small" onClick={handleRemoveFilter}>
          Remove
        </Button>
      </div>
    </SummaryTileHeader>
    <SummaryTileBody $minHeight="auto" $maxHeight="20rem">
      {getPlayFiltersWidget(widgetProps)}
    </SummaryTileBody>
  </>
);

export default PopupFilterWrapper;

PopupFilterWrapper.propTypes = {
  widgetProps: PropTypes.shape({
    rangeValues: PropTypes.array.isRequired,
    setRangeValues: PropTypes.func.isRequired,
    filterId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    widgetType: PropTypes.oneOf(Object.values(PLAY_FINDER_WIDGET_TYPES))
      .isRequired,
    widgetArgs: PropTypes.object.isRequired,
    handleCallback: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
};
