/* eslint-disable max-len */
export const releaseNotesText = `
## 2024-01-05

### Changed
- Season dropdown defaults to 2023/2024 season

## 2024-01-04

### Added
- Team overview is now set as the homepage

### Fixed
- Dual slider on Player Scout fixed
- First time log in bug fixed
- Team pass chart toggle to passes against fixed

## 2023-12-22

### Added
- New coverage stats added to the Team Stats page on the Defense > Passing tabs:
- Man Coverage % (Team Stats > Defense > Passing)
- New coverage stats added to the Team Stats page on the Defense tab:
- Team Blitz %
- Pass Play Blitz %
- Our defensive front identification added to Team Stats on the Defense > Play tabs:
- Over %
- Under %
- Heads %
- Split %
- Pinch %
- Bear %
- Tite %
- Okie %
- Stack %
- Other %
- Team and LB/DB Radars have been updated to include the new Blitz % stat

## 2023-12-14

### Added
- Player comparison widget to OL player overview
- Player scout metrics added the ability to edit values directly

### Fixed
- Player scout play filter shifting columns
- Player scout tabs overflowing container
- Player scout all caps removed, and long town cropping adjusted
- Player scout metrics slider for lower numbers being better fixed
- Player scout table dimensions adjusted to better fit screen
- Player comparison alignment filter

## 2023-12-07

### Changed
- Select all added to position filtering on Player Scout and Stats pages

### Fixed
- Cowboys team colors on Team Radar

## 2023-12-06

### Added
- The play comparison tool is now available as its own page as well as on each player overview page. The tool helps you find the five players whose outputs most closely match your target. Filters within the tool can be adjusted to alter results based on your preferred weighting of a player's trait or performance metrics.

## 2023-11-30

### Added
- Player Scout is now available on the left side navigation, and allows you to select the metrics you care about to filter for players

## 2023-11-15

### Added
- On the Pass Chart you can now draw a box to select multiple plays and see a column on the stats table for the statistics for those selected plays vs all plays.

## 2023-11-04

### Fixed
- Bug causing the pass chart filters to work incorrectly has been fixed. The pass outcome filter (Complete, Touchdown etc.) has been moved into the display settings panel

## 2023-10-19

### Fixed
- Fixed bug causing zero to display a blank on the team schedule

## 2023-10-18

### Fixed
- Fixed bug causing dropdowns to get cutoff by the container they were in

## 2023-10-10

### Added
- Routes Run visualization added to WR and TE overview pages

## 2023-09-28

### Added
- Route tree visualization is now available for each player
- Team Overview page available for every team
- Team schedule now available for every team
- Additions to the player metadata database including fields for: height, weight, class, home town, high school, schools offered

### Changed
- Usage % added to Fullbacks and Slot Receivers on the Depth Chart alignment mode
- Updated home and away team display on the Play by Play page and added team colors
- Plays added as a fixed column to the team stats table on the Player Overview page
- Snap Distribution supports correct vertical orientation for defensive players

### Fixed
- Fixed bug causing 0 scores to not display on the team schedule
- Fixed bug causing video to disappear when chart set to 52% width of screen
- Fixed Search bar bug causing incorrect link to player overview page

## 2023-08-31

### Added
- Team radars are now available covering Offense and Defensive units

### Changed
- Team stats have been updated to include all the metrics created for Player Stats plus additional EPA stats
- 2 decimal place formatting for EPA

### Fixed
- Season dropdown for NFL ordering bug fixed

## 2023-08-24

### Added
- Our new Run Tendencies page allows users to analyze point of attack and strengths and weaknesses of a teams running game
- Our newly created EPA metric has been added across the site including on Player Stats and Player Radars 
- Our new Formation Summary page allows users to analyze the formations used by a specific team and how successful they were
- Season added to radar export

## 2023-08-03

### Added
- Player overview pages have been added for the following positions: RB, TE, OL, DL, LB, DB, K, P, LS
- The percentage of each type of route run by a receiver is now available on Player Stats under the Receiving > Routes tabs
- Jersey Number and Play Count have been added as permanent columns to player stats 

### Changed
- Pass 1-on-1% and Run 1-on-1% are now the main metrics for OL and DL trait radars for blocking type
- QB and WR Player overview's now include dual radars

## 2023-07-19

### Changed
- The Tackle Map visualization now defaults to Play Locations instead of Heatmap

### Fixed
- The bug causing users to lose connection to the database and needing to log out, refresh, and log back in has been resolved
- The bug causing the page to crash when selecting Red Zone from the Field Position context filter has been resolved

## 2023-07-06

### Added
- The HAVOC chart is now available for users to view a teams ability to generate: pressures, run disruptions, sacks, and tackles for loss

### Changed
- Updated personnel packages available for offense and defense in the personnel context filter

## 2023-06-21

### Added
- The Depth Chart feature gives insight to who played for a team, and what personnel groups the team utilized
- The trait radar highlights how a player was used, and when combined with the performance radar gives a more holistic view of a player

### Changed
- The API access page is now driven by entitlements
- Users with accounts that have API access can generate a new key
- Users with accounts that do not have API access will have the generate key section disabled with a message to contact their StatsBomb representative to learn more

### Fixed
- The bug from periods and apostrophes in player names preventing export has been resolved

## 2023-06-07

### Changed
- The following improvements were made to the Tackle Map 
- Heatmap view
- Area distribution chart of first contact, yards after contact, and total yards distribution chart
- League average line added to distribution chart
- Keys were added
- Advanced export options were added
- Filtering is now available by position and specific player
- Filtering available for play type including: Run, Pass, Sack, All Run & Pass
- Filtering available for tackle type including: tackle attempt, first tackle attempt, successful tackle, solo tackle
- Clicking a tackle dot brings up information on the play and links to Play Animation
- Play success coloring
- New tackle metrics added to the table 

## 2023-05-24

### Changed
- Context filters now auto enable when a user makes selections
- Updated ordering of player stats
- Updated ordering of tabs on player stats
- Context filters now reset on user logout or browser close
- Player stats can now be filtered by team using the dropdown in the display options
- Context filter queries have been refactored and updated to use newest filters

### Fixed
- The issue of the page scrollbar being visible even if there was no scroll option has been resolved
- Maryland team color on radars has been updated
- Texas A&M team color on radars has been updated

## 2023-05-10

### Changed
- Advanced export options now available on the Play Animation page
- The line of scrimmage and first down markers color design has been altered to be less intrusive on the visualization

### Fixed
- The scroll bug on the Player Overview page stats table has been resolved

## 2023-05-03

### Changed
- Advanced export options now available on the Snap Formation and Pass Placement pages 

### Fixed
- Bug causing site crash on the tackle map for San Francisco 49ers resolved

## 2023-04-26

### Added
- The first version of Statsbomb special teams data covering field goals, extra points, punts, and kickoffs  is now available on the Player Stats page under the Special Teams tab
- Completion Percentage Over Expected (CPOE) and Catch Rate Over Expected (CROE) metrics are now available for QB and WR on the Player Radars and Player Stats pages

### Changed
- Advanced export options now available for the Snap Distribution chart

## 2023-04-12

### Added
- Radars are now available for Linebackers and Defensive Backs
- Tackling map visualization beta has been released and can be found under the Team navigation group

### Changed
- Player stats has a new table design with updated column sorting

## 2023-03-29

### Changed
- QB Time To Throw filter now uses 0.1 second increments and the higher bound has been changed to 5
- Advanced download now available for Pass Chart with options for logo, dark or light palette, and file format
- The toggle to change the visualizations on player overview is now a dropdown which can have multiple options

## 2023-03-20

### Added
- The number of games available to view for a team is displayed in the team dropdown
- Snap formation allows a user to select all drives for each team

### Changed
- Play by play feed now uses end of drive score
- Player stats table defaults to 100 rows
- Player stats table column headers are fixed during scroll
- Player stats export downloads all rows

### Fixed
- Play animation changing drive site crash bug
- League/season dropdown selection not cascading down to other dropdown

## 2023-03-06

### Added
- QB Drop depth filter under Play > QB Drop Depth
- QB Time to throw filter under Play > QB Time to Throw
- QB Pocket Location filter under Play > QB Pocket Location
- Position icons for the roster position filter on the Player Stats page
- Individual game filter
- Team names displayed in the title above tiles on Snap Formation page

### Changed
- Fixed column headers on the Player Stats page
- 100 rows by default on the Player Stats page
- Default Jersey numbers toggle to on for Snap Formations and Play Event Tiles pages
- Game short name implemented for the game dropdown

### Fixed
- Games not chronological on player overview bug fixed
- Philadelphia Eagles team color bug on radar

## 2023-02-17

### Added
- Player overview stats table shows stats by game and season
- Player overview stats table toggle between rate and total stats modes

### Fixed
- Play animation smoothing
- Depth of target filter bug

## 2023-02-02

### Added
- New blue color theme implemented
- Search available on all pages for Players
- Pressures allowed chart is now available for end users
- Team colors available on radar page
- Legends available for radar page

### Fixed
- Player Stats navigation bug
- Download on Pressures chart title bug

## 2023-01-30

### Changed
- Player stats default to rate mode

### Fixed
- Sorting on a column on the Player Stats page no longer resets the horizontal scroll

## 2023-01-23

### Added

- Link from a players name on the Player Stats page to their Overview page
- Team name and group by team options on Player Stats page

### Changed
- Default to vertical orientation on Snap Distribution page

### Fixed

- Rename incompletions toggle label on Pass Placement page
- Pass placement chart layout bug fix
- Remove redundant links on left hand navigation
- Scrolling for left and right columns on Snap Formation page

## 2023-01-06

### Added

- Player Stats page with breakdowns for rate/total modes, breakdowns for down/quarter/team/alignment, filters for the Transfer Portal, minimum snaps, and roster position
- New Player overview page for QB and WR positions with additional charts

### Fixed

- Some scaling and axis issues with the radars have been addressed

## 2022-12-19

### Added
- Player Radar radars available for OL and DL

## 2022-12-16

### Added

- Season dropdown defaulted to current season

## 2022-12-08

### Added

- Player Radar page with radars available for QB, RB, and WR

## 2022-11-21

### Added

- Save query string to the URL for the pass chart
- The event list now has an accordion and the ability to see the list of events on screen next to the play animation

### Fixed

- Bug on the link between a route path on snap distribution and the individual play animation fixed

## 2022-10-28

### Added

- Show all on Snap Formation
- Play animation event list re-design

### Fixed

- Play animation bugs
- Updated Play Success definition
- Dropdown styling on dark theme

## 2022-10-12

### Added

- QB charts updated on the Player Overview page
- The game dropdown will now be sorted by date with most recent game at the top and oldest game at the bottom
- Success Rate (%) was added to the Pass Chart table
- Pass placement toggle added to view from Passer or Target perspective

## 2022-10-04

### Added

- Play animation updates including: real time animation, smoother player routes, timeline below the animation
- Snap distribution now allows a region of the field to be selected to show the routes a player ran from that location
- Display settings are now saved to the URL so a shared link will include them
- New Data Access page gives API keys and a data dictionary to allow direct API access
- Download now available on the Player Overview page

### Fixed

- A bug causing a crash on play animation was fixed

## 2022-09-14

### Added

- The Play by Play feature lists each drive of the game which can be expanded to show each play that makes up the drive
- The data points available in the feature are: Drive/Play Number, Clock, Team on Offense, Drive/Play Result, Notes, and Score
- Each play links to the play animation for the corresponding play using the >> button
- Release notes are now available and accessed via the main navigation

## 2022-09-06

### Added

- Depth of target slider added to Play filter
- Team stats added under League > Team Stats
- Player number added to player dropdown

### Fixed

- URL parsing
- Optional defense/offense team IDs

## 2022-08-30

### Added

- Formation filter added for basic offensive formations
- Single number selection available on the Positions tab of the Personnel filter

### Fixed

- Selecting Play Animation from the menu when already there causes a crash fix
- Deep linking to some formations returns 404 not found
- Clear button CSS
- Dark theme new color
- Show pass when receiver missing

## 2022-08-09

### Added

- Key added to Pass Chart with option to toggle on/off
- Metric tables added alongside Pass Chart
- The ability to click on an individual dot on the pass chart and see information on a specific pass, as well as a link to the Play Animation fo rthat pass
- Toggle for displaying the ball on Event Tiles and Snap Formation
- New zoom options for on Event Tiles and Snap Formation: Offensive Line, Field Width, Players Midpoint, Ball Location, Line of Scrimmage
- Position width and depth measurements added when selecting a specific frame from Event Tile

### Fixed

- Bug that caused event tiles to crash site fixed

## 2022-08-08

### Added

- Web app connected to production API
- Component Library integration

### Fixed

- Minor bug when downloading snap distribution when using focus level filter fixed

## 2022-08-05

### Added

- Personnel packages for offense and defense added as a tab to Personnel filter
- Specific position ranges selection available as a tab under Personnel filter

## 2022-07-21

### Added

- Play animation oriented vertically by default
- New Play Animation zoom options: Full field, Play long, Play short, Line battle

## 2022-07-12

### Added

- Pass chart available at a team level under Team > Pass Chart
- Filter pass chart by QB and intended receiver at team level
- Toggle added for viewing pass chart from receiver perspective
- New color options for pass chart: Under/Overthrows, Pass Velocity, Play Success, Color by passer, Color by targeted receiver
- Dark/Light theme available for all charts

### Fixed

- The team name listed for the player selected is now correctly displaying

## 2022-06-17

### Added

- Deep linking saves page information in the URL allowing sharing of URLs and linking between visualizations
- Snap distribution available for defensive players

### Fixed

- The page was failing if there was a missing ID, this issue is now resolved with a message saying no data available
- Selecting a play event while one is selected no longer exits the page

## 2022-06-06

### Added

- Download available for all visualizations in JPG and PNG file formats

## 2022-05-23

### Added

- Play type filter added with selections: Pass, Run, QB Kneel, QB Spike
- Yards Gained slider added to Play filter

## 2022-05-04

### Added

- Pass placement chart available under Player > Pass Placement
- Pass chart available under Player > Pass Chart
- Snap Distribution chart available under Player > Snap Distribution
- Boxscore available under Team > Boxscore
- Play Animation available under Game > Play Animation
- Snap Formation available under Game > Snap Formation
- Play Event Tiles available under Game > Event Tiles
- Filters for League, Season, Team, Player added
- Filters for Down and Distance, Field Position, and Time
`;
