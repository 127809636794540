import React from 'react';
import PropTypes from 'prop-types';
import { PercentageBar } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { StyledPersonnelDistributionBar } from './PersonnelDistributionBar.styles';

const PersonnelDistributionBar = ({
  snapCount,
  snapRate,
  leagueAverage,
  label,
  visibleMax,
}) => {
  const theme = useTheme();

  // the values that drive the width of the bar and the position of the notch
  // Math.min to ensure we don't pass values over 100.
  const teamValue = (snapRate / visibleMax) * 100;
  const adjustedTeamValue = Math.min(teamValue, 100);
  const adjustedLeagueValue = Math.min((leagueAverage / visibleMax) * 100, 100);

  // the values to display
  const formattedTeamValue = (snapRate * 100).toFixed(1);
  const formattedLeagueValue = (leagueAverage * 100).toFixed(1);

  return (
    <StyledPersonnelDistributionBar $indicator={teamValue > 100}>
      <div className="label">{label}</div>
      <div className="bar">
        <PercentageBar
          barValue={adjustedTeamValue}
          barColor={theme.colours.visualisations.objects.n1.main}
          markerValue={adjustedLeagueValue}
          markerColor={theme.colours.visualisations.objects.n2.main}
        />
      </div>
      <div className="stats">
        <div className="plays">{`${formattedTeamValue}% [${snapCount}]`}</div>
        <div className="league-average">{formattedLeagueValue}%</div>
      </div>
    </StyledPersonnelDistributionBar>
  );
};

PersonnelDistributionBar.propTypes = {
  /** The number of times this package was used */
  snapCount: PropTypes.number.isRequired,
  /** The percentage for how much this package was used */
  snapRate: PropTypes.number.isRequired,
  /** The league average for how much this package was used */
  leagueAverage: PropTypes.number.isRequired,
  /** the label of the package the component represents */
  label: PropTypes.string.isRequired,
  /** cropped portion of the bar to display */
  visibleMax: PropTypes.number,
};

PersonnelDistributionBar.defaultProps = {
  visibleMax: 0.7,
};

export default PersonnelDistributionBar;
