import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { BasicPageNoPadding } from '../../components/Layout/Layout.styles';

const Player = ({ top }) => (
  <BasicPageNoPadding $top={top}>
    <Outlet />
  </BasicPageNoPadding>
);

Player.propTypes = {
  top: PropTypes.string,
};

Player.defaultProps = {
  top: '9.8rem',
};
export default Player;
