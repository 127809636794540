import { styled } from 'styled-components';

export const HalfSpaceTile = styled.div`
  width: calc(50% - 16px);
  float: left;
  margin-right: 16px;
  min-width: 450px;
`;
HalfSpaceTile.displayName = 'HalfSpaceTile';

export const RouteTreeHolder = styled.div`
  padding-bottom: 1rem;
  width: 100%;
  svg {
    max-width: 700px;
    margin: 0px auto;
  }
`;
RouteTreeHolder.displayName = 'RouteTreeHolder';
