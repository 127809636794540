export const getPlayFinderResults = (columns) => {
  const joinedColumns = columns.join('\n');
  return `
    query GetPlayFinderResults(
      $competitionId: Int!
      $seasonId: Int!
      $teamId: Int!
      $offense: Boolean!
      $filters: [PlayFinderFilter!]
      $columns: [String!]
    ) {
      playFinder(
        competitionId: $competitionId
        seasonId: $seasonId
        teamId: $teamId
        offense: $offense
        filters: $filters
        columns: $columns
      ) {
        team {
          name
        }
        season {
          name
        }
        aggregates {
          label
          value
          distribution {
            name
            percentage
            count
            rank
          }
        }
        plays {
          offenseTeam {
            name
          }
          defenseTeam {
            name
          }
          game {
            id
            shortName
            date
          }
          id
          quarter
          quarterClockRemaining
          type
          down
          yardsNet
          yardsToGo
          yardLine
          success
          penalties {
            name
          }
          offensePenaltyAccepted
          defensePenaltyAccepted
          offensivePersonnel
          defensivePersonnel
          scrimmageEpa
          formationGroup
          defensiveCoverage
          ${joinedColumns}
        }
      }
    }
  `;
};
