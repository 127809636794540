import { styled } from 'styled-components';

export const SliderSection = styled.div`
  margin-bottom: 32px;
`;
SliderSection.displayName = 'SliderSection';

export const SliderHeader = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  height: 1rem;

  h3 {
    position: absolute;
    top: 0;
    left: 0;
  }

  button {
    float: right;
    margin-left: 8px;
  }
`;
SliderHeader.displayName = 'SliderHeader';
