import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_CATCH_PLACEMENT_EVENTS = gql`
  query GetPassPlacementEvents(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    $playerId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedPlayerId @client @export(as: "playerId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    passEvents(
      first: 500
      eventFilters: { receiverPlayerIds: [$playerId] }
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        teamIds: [$teamId]
        ${getContextFiltersQueryArguments()}
      }
    ) {
      totalCount
      edges {
        node {
          id
          team {
            name
          }
          player {
            id
            name
          }
          receiverPlayer {
            id
            name
          }
          x
          y
          endX
          endY
          duration
          passPlacementX
          passPlacementY
          success
          points
          passAirYards
          freezeFrames {
            player {
              id
            }
            x
            y
          }
        }
      }
    }
  }
`;
