import React, { useState, useEffect } from 'react';
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Dropdown } from '@statsbomb/kitbag-components';
import {
  API_PLAYER_AGGREGATE_MODES,
  API_PLAYER_GROUP_BY,
} from '../../../utils/constants/api';
import {
  GENERAL_POSITION_TO_TILE_SUB_CATEGORY,
  GENERAL_POSITION_TO_CATEGORY,
} from '../../League/stats/PlayerStats/PlayerStats.constants';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import TabbedTable from '../../../components/Tables/TabbedTable/TabbedTable';
import GoTo from '../../../components/buttons/GoTo';
import { getPlayerStats } from './getPlayerStats';
import {
  getStatHeaders,
  formatStatValues,
  orderStats,
} from '../../League/stats/stats.dataManipulation';
import { mf_AllSeasons, mf_PlayerDetails, mf_Seasons } from '../../../apollo';
import { PlayerStatsTableWrapper } from '../../League/stats/PlayerStats/PlayerStats.styles';
import { OverviewTileMessage } from './PlayerOverview.styles';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  SingleValue,
  Option,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import {
  DEFAULT_HIGHLIGHTED_COLUMN,
  DEFAULT_SORT_BY,
  PERMANENT_HEADERS,
  STATS_AGGREGATION_MODE_OPTIONS,
} from './PlayerStats.Tile.constants';
import { useGetPlayerStatDefinitions } from '../../League/stats/PlayerStats/PlayerStats.hooks';
import { ALIGNMENT } from '../../../utils/constants/alignment';
import { useIsNcaa } from '../../../utils/hooks/useIsNcaa';
import { toUsDate } from '../../../utils/helpers/formatting';
import { useGetPlayerStatsCategoryDefinitions } from '../../../utils/hooks/useGetPlayerStatCategories';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const { DESCENDING, ASCENDING } = SORT_DIRECTIONS;

const PlayerStatsTile = () => {
  // selected player
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const isNcaa = useIsNcaa();
  const playerName = mf_PlayerDetailsRV?.name;
  const [aggMode, setAggMode] = useQueryString(
    'agg',
    API_PLAYER_AGGREGATE_MODES.TOTAL
  );
  const generalPosition =
    mf_PlayerDetailsRV?.mostCommonPosition?.generalPosition;
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    GENERAL_POSITION_TO_TILE_SUB_CATEGORY[generalPosition]
  );
  const [sortDirection, setSortDirection] = useState(ASCENDING);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);
  const [highlightedColumn, setHighlightedColumn] = useState(
    DEFAULT_HIGHLIGHTED_COLUMN
  );

  const { categoryDefinitions, isLoading: isLoadingCategoryDefinitions } =
    useGetPlayerStatsCategoryDefinitions();

  // get all unique categories
  const categories =
    categoryDefinitions?.map((category) => category.name) || [];

  const selectedCategoryDefinition = categoryDefinitions?.find(
    (category) =>
      category.name === GENERAL_POSITION_TO_CATEGORY[generalPosition]
  );

  const orderedSubcategoryStats =
    selectedCategoryDefinition?.subcategories.find(
      (subcategory) => subcategory.name === selectedSubcategory
    )?.stats;

  const isRateMode = aggMode === API_PLAYER_AGGREGATE_MODES.RATE;
  const { statDefinitions, isLoadingStatDefinitions } =
    useGetPlayerStatDefinitions(orderedSubcategoryStats, isRateMode);

  // get all subcategories of the selected category
  const subcategories = selectedCategoryDefinition?.subcategories.map(
    (subcategory) => subcategory.name
  );

  const selectedCategory = categories[0];
  // set default subcategory once the category has been set
  useEffect(() => {
    if (subcategories?.length) {
      setSelectedSubcategory(
        GENERAL_POSITION_TO_TILE_SUB_CATEGORY[generalPosition]
      );
    }
  }, [selectedCategory]);

  const playerStatsQuery = getPlayerStats(statDefinitions);
  const { data: playerStatsData, loading: isLoadingPlayerStats } = useQuery(
    gql(playerStatsQuery),
    {
      skip: !statDefinitions?.length,
      variables: {
        aggMode,
        groupByMode: API_PLAYER_GROUP_BY.GAME,
        orderBy: [
          {
            name: sortBy,
            descending: sortDirection === DESCENDING,
          },
        ],
      },
    }
  );

  const { data: playerStatsTotalData, loading: isLoadingPlayerStatsTotal } =
    useQuery(gql(playerStatsQuery), {
      skip: !statDefinitions?.length,
      variables: {
        aggMode,
        groupByMode: API_PLAYER_GROUP_BY.PLAYER,
      },
    });

  const playerStats = playerStatsData?.playerStats.items.map((player) => ({
    ...player,
    gameDate: toUsDate(player.gameDate),
    season: mf_AllSeasons()?.find((season) => season.id === mf_Seasons())?.name,
  }));

  const playerStatsTotal = playerStatsTotalData?.playerStats.items;

  const headers = [...PERMANENT_HEADERS, ...getStatHeaders(statDefinitions)];
  const headerIds = headers.map((header) => header.id);

  const formattedStats = formatStatValues(
    playerStats,
    headerIds,
    statDefinitions,
    aggMode
  );

  const orderedStats = orderStats(formattedStats, headerIds);

  const formattedStatsTotal = formatStatValues(
    playerStatsTotal,
    headerIds,
    statDefinitions,
    aggMode
  );

  if (formattedStatsTotal && formattedStatsTotal.length > 0) {
    formattedStatsTotal[0].gameDate = mf_AllSeasons()?.find(
      (season) => season.id === mf_Seasons()
    )?.name;
    formattedStatsTotal[0].gameName = '-';
  }
  const orderedStatsTotal = orderStats(formattedStatsTotal, headerIds);

  const stickyColumns = [
    { index: 1, width: 5.625 },
    { index: 2, width: isNcaa ? 6.25 : 5.625 },
    { index: 3, width: 3.75 },
  ];

  const playerStatsConfig = categories?.map((category) => ({
    id: category,
    label: category,
    tableConfig: {
      headers,
      footer: orderedStatsTotal,
      rows: orderedStats,
      alignment: (index) =>
        stickyColumns.length > index && index !== 2
          ? ALIGNMENT.LEFT
          : ALIGNMENT.RIGHT,
      stickyColumns,
      maxHeight: 353,
    },
  }));

  const loadingMsg = () => {
    if (isLoadingStatDefinitions || isLoadingCategoryDefinitions) {
      return 'Loading Stat Definitions';
    }

    if (isLoadingPlayerStats) {
      return 'Loading Game Data';
    }

    if (isLoadingPlayerStatsTotal) {
      return 'Loading Season Total Data';
    }

    return '';
  };

  const handleTabChange = (e) => {
    setSelectedSubcategory(e.target.innerText);
    setSortBy(DEFAULT_SORT_BY);
    setHighlightedColumn(DEFAULT_HIGHLIGHTED_COLUMN);
  };

  const handleSort = (statName) => {
    if (statName === sortBy) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(DESCENDING);
      setSortBy(statName);
      setHighlightedColumn(headerIds.indexOf(statName) + 1);
    }
  };

  const isLoading =
    isLoadingCategoryDefinitions ||
    isLoadingPlayerStats ||
    isLoadingStatDefinitions ||
    isLoadingPlayerStatsTotal;

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Stats</h3>
        </div>
        <div>
          {playerStatsData && !isLoading && playerName && (
            <DropdownWrapper>
              <Dropdown
                id="statsAggregationDrop"
                components={{
                  Option,
                  SingleValue,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(item) => setAggMode(item.value)}
                options={STATS_AGGREGATION_MODE_OPTIONS}
                value={
                  STATS_AGGREGATION_MODE_OPTIONS[
                    aggMode === API_PLAYER_AGGREGATE_MODES.TOTAL ? 1 : 0
                  ]
                }
                label="Stats aggregation mode"
                labelPosition="none"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            href="/league/stats/players/:leagues/:seasons"
            linkName="Open league stats"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {playerStatsData && playerStatsTotalData && playerName && (
          <PlayerStatsTableWrapper>
            <TabbedTable
              tableTabsConfig={playerStatsConfig}
              isLoading={isLoading}
              subTabs={subcategories}
              selectedSubTab={selectedSubcategory}
              onSubTabChange={handleTabChange}
              sortDirection={sortDirection}
              sortBy={sortBy}
              highlightedColumn={highlightedColumn}
              handleSort={handleSort}
              withBorder={false}
              withPadding={false}
              hideParentTabs
            />
          </PlayerStatsTableWrapper>
        )}
        {!playerStatsData && !isLoading && playerName && (
          <OverviewTileMessage>
            <p>No Data Available.</p>
          </OverviewTileMessage>
        )}
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content={loadingMsg()} />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerStatsTile;
