import { gql } from '@apollo/client';

export const GET_PLAY_BY_PLAY_EVENTS = gql`
  query GetPlayByPlayEvents($playId: ID!) {
    playEvents(playId: $playId) {
      id
      x
      y
      eventGameIndex
      name
      types
      quarter
      videoTimestamp
      player {
        id
        name
      }
      receiverPlayer {
        id
        name
      }
      opponentPlayer {
        id
        name
      }
      passAirYards
      yardsAfterCatch
      success
      catchFailType
      points
      scoreFailType
    }
  }
`;
