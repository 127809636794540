import PropTypes from 'prop-types';

/* An custom object describing the aspect ratio of pass placement
    , and possible zoom configurations for that zoom
*/
export const passPlacementScalePropTypes = PropTypes.shape({
  // the description of the aspect ratio in standard x:y notation
  ASPECT_RATIO: PropTypes.string,
  // THe aspect ratio inverted (used to multiply x to get y)
  RATIO_Y_OVER_X: PropTypes.number,
  // An array of possible values for +-x (so half the x-axis) which work for this aspect ratio
  // essentially: how zoomed in to be
  // sequence ordered most zoomed in (smallest +-x) to zoomed out (largest axis range)
  PLUS_MINUS_X_OPTIONS: PropTypes.arrayOf(PropTypes.number),
  // which of the x-axis options to default to (should display 98%+ of passes)
  DEFAULT_PLUS_MINUS_X_INDEX: PropTypes.number,
});
