import { minBy, maxBy, join, sortBy } from 'lodash';
import { ROTATIONS } from '../../../utils/constants/charting';
import { BOX_OL_ADDITION } from './DataManipulation';
import { getJerseyRotation } from '../../../utils/helpers/player';
import { DEFAULT_FONT } from '../../../utils/constants/visText';
import { POSITION_COLOR_ATTRIBUTE_MAIN } from '../../../utils/visualisations/visPalettes';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';

const MEASURES_FONT_SIZE = 1; // yds
const getFontSize = function (zoomFactor, pxPerYard) {
  return Math.ceil((MEASURES_FONT_SIZE / zoomFactor) * pxPerYard);
};

const drawMeasureU = function (
  measuresG,
  pathPairs,
  pxPerYard,
  zoomFactor,
  color
) {
  const pathParts = pathPairs.map((pair, i) => {
    const opt = i === 0 ? 'M' : 'L';
    return `${opt}${pair.x * pxPerYard} ${pair.y * pxPerYard}`;
  });
  const path = join(pathParts, ' ');

  measuresG
    .append('svg:path')
    .attr('d', path)
    .attr('fill', 'transparent')
    .attr('stroke', color)
    .attr('stroke-width', 2 / zoomFactor)
    .attr('stroke-dasharray', '4 3');
};

const drawMeasureText = function (
  measuresG,
  textX,
  textY,
  fontColor,
  fontSize,
  msg,
  rotationOption
) {
  let dX = 0;
  let dY = 0;
  let textAnchor = 'middle';
  if (rotationOption === ROTATIONS.VERTICAL_UP) {
    dX = -fontSize;
  } else if (rotationOption === ROTATIONS.VERTICAL_DOWN) {
    dX = -3;
  } else {
    dX = -3; // just off the line
    textAnchor = 'end';
    dY = 2;
  }
  measuresG
    .append('g')
    .attr('transform', `translate(${textX + dX},${textY + dY})`)
    .append('text')
    .attr('x', 0)
    .attr('y', 0)
    .attr('transform', getJerseyRotation(rotationOption))
    .attr('fill', fontColor)
    .attr('text-anchor', textAnchor)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-size', `${fontSize}px`)
    .text(msg);
};

const addOffensiveLineMeasure = function (
  measuresG,
  freezeFrames,
  viewFrame,
  rYds,
  outOfFrameShift,
  zoomFactor,
  fieldOverrides,
  visPalette,
  outOfFrameBump,
  rotationOption
) {
  const outOfFrame = outOfFrameShift + outOfFrameBump;
  const offenseFFs = freezeFrames.filter(
    (f) =>
      f.player?.position?.code ===
      ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN].code
  );
  const minY_offense = minBy(offenseFFs, 'y');
  const maxY_offense = maxBy(offenseFFs, 'y');
  const pathPairs = [
    { x: minY_offense?.x - rYds, y: minY_offense?.y },
    { x: viewFrame.xShift.minX - outOfFrame, y: minY_offense?.y },
    { x: viewFrame.xShift.minX - outOfFrame, y: maxY_offense?.y },
    { x: maxY_offense?.x - rYds, y: maxY_offense?.y },
  ];
  drawMeasureU(
    measuresG,
    pathPairs,
    fieldOverrides.pxPerYard,
    zoomFactor,
    visPalette.positions.offense.ol.main
  );

  const FormationWidth = (maxY_offense?.y - minY_offense?.y).toFixed(1);
  const fontColor = visPalette.positions.offense.ol.main;

  const fontSize = getFontSize(zoomFactor, fieldOverrides.pxPerYard);
  const textX = (viewFrame.xShift.minX - outOfFrame) * fieldOverrides.pxPerYard;
  const textY =
    ((minY_offense?.y + maxY_offense?.y) / 2) * fieldOverrides.pxPerYard;
  drawMeasureText(
    measuresG,
    textX,
    textY,
    fontColor,
    fontSize,
    FormationWidth,
    rotationOption
  );
};

const addOffensiveFormationMeasure = function (
  measuresG,
  freezeFrames,
  viewFrame,
  rYds,
  outOfFrameShift,
  zoomFactor,
  fieldOverrides,
  visPalette,
  measureOptions,
  outOfFrameBump,
  rotationOption
) {
  let outOfFrame = outOfFrameShift + outOfFrameBump;
  if (measureOptions.offensiveLineWidth) {
    outOfFrame += outOfFrameBump;
  }
  const offenseFFs = freezeFrames.filter((f) => f.isOffense === true);
  const minY_offense = minBy(offenseFFs, 'y');
  const maxY_offense = maxBy(offenseFFs, 'y');
  const pathPairs = [
    { x: minY_offense?.x - rYds, y: minY_offense?.y },
    { x: viewFrame.xShift.minX - outOfFrame, y: minY_offense?.y },
    { x: viewFrame.xShift.minX - outOfFrame, y: maxY_offense?.y },
    { x: maxY_offense?.x - rYds, y: maxY_offense?.y },
  ];
  drawMeasureU(
    measuresG,
    pathPairs,
    fieldOverrides.pxPerYard,
    zoomFactor,
    visPalette.positions.offense.default.main
  );

  const FormationWidth = (maxY_offense?.y - minY_offense?.y).toFixed(1);
  const fontColor = visPalette.positions.offense.default.main;

  const fontSize = getFontSize(zoomFactor, fieldOverrides.pxPerYard);
  const textX = (viewFrame.xShift.minX - outOfFrame) * fieldOverrides.pxPerYard;
  const textY =
    ((minY_offense?.y + maxY_offense?.y) / 2) * fieldOverrides.pxPerYard;
  drawMeasureText(
    measuresG,
    textX,
    textY,
    fontColor,
    fontSize,
    FormationWidth,
    rotationOption
  );
};

const addDefensiveFormationMeasure = function (
  measuresG,
  freezeFrames,
  viewFrame,
  rYds,
  outOfFrameShift,
  zoomFactor,
  fieldOverrides,
  visPalette,
  outOfFrameBump,
  rotationOption
) {
  const outOfFrame = outOfFrameShift + outOfFrameBump;
  const defenseFFs = freezeFrames.filter((f) => f.isOffense === false);
  const minY_defense = minBy(defenseFFs, 'y');
  const maxY_defense = maxBy(defenseFFs, 'y');
  const pathPairsD = [
    { x: minY_defense?.x + rYds, y: minY_defense?.y },
    { x: viewFrame.xShift.maxX + outOfFrame, y: minY_defense?.y },
    { x: viewFrame.xShift.maxX + outOfFrame, y: maxY_defense?.y },
    { x: maxY_defense?.x + rYds, y: maxY_defense?.y },
  ];
  drawMeasureU(
    measuresG,
    pathPairsD,
    fieldOverrides.pxPerYard,
    zoomFactor,
    visPalette.positions.defense.default.main
  );

  const FormationWidth = (maxY_defense?.y - minY_defense?.y).toFixed(1);
  const fontColor = visPalette.positions.defense.default.main;

  const fontSize = getFontSize(zoomFactor, fieldOverrides.pxPerYard);
  const textX = (viewFrame.xShift.maxX + outOfFrame) * fieldOverrides.pxPerYard;
  const textY =
    ((minY_defense?.y + maxY_defense?.y) / 2) * fieldOverrides.pxPerYard;
  drawMeasureText(
    measuresG,
    textX,
    textY,
    fontColor,
    fontSize,
    FormationWidth,
    rotationOption
  );
};

const addBoxMeasure = function (
  measuresG,
  freezeFrames,
  zoomFactor,
  fieldOverrides,
  lineOfScrimmage
) {
  const olFFs = freezeFrames.filter(
    (f) =>
      f.player?.position?.code ===
      ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN].code
  );
  const minY_offense = minBy(olFFs, 'y');
  const maxY_offense = maxBy(olFFs, 'y');
  const boxTop = lineOfScrimmage + BOX_OL_ADDITION;
  const boxBottom = lineOfScrimmage - BOX_OL_ADDITION;
  let path = `M${boxTop * fieldOverrides.pxPerYard} ${
    minY_offense?.y * fieldOverrides.pxPerYard
  }`;
  path += ` L${boxTop * fieldOverrides.pxPerYard} ${
    maxY_offense?.y * fieldOverrides.pxPerYard
  }`;
  path += ` a${BOX_OL_ADDITION * fieldOverrides.pxPerYard} ${
    BOX_OL_ADDITION * fieldOverrides.pxPerYard
  } 0 0 1 -${BOX_OL_ADDITION * 2 * fieldOverrides.pxPerYard} 0`;
  path += ` L${boxBottom * fieldOverrides.pxPerYard} ${
    minY_offense?.y * fieldOverrides.pxPerYard
  }`;
  path += ` a${BOX_OL_ADDITION * fieldOverrides.pxPerYard} ${
    BOX_OL_ADDITION * fieldOverrides.pxPerYard
  } 0 0 1 ${BOX_OL_ADDITION * 2 * fieldOverrides.pxPerYard} 0`;
  path += ' z';
  measuresG
    .append('svg:path')
    .attr('d', path)
    .attr('fill', 'transparent')
    .attr('stroke', '#666')
    .attr('stroke-width', 1 / zoomFactor)
    .attr('stroke-dasharray', '2 2')
    .attr('fill', '#666')
    .attr('fill-opacity', 0.15);
};

const drawSplits = function (
  measuresG,
  freezeFrames,
  rYds,
  zoomFactor,
  fieldOverrides,
  visPalette,
  inFrameBump,
  positionCodes,
  lineOfScrimmage,
  rotationOption,
  offensiveLineman,
  isLeft,
  isDefense = false
) {
  const filteredFFs = isLeft
    ? freezeFrames.filter(
        (f) =>
          positionCodes.includes(f.player?.position?.code) &&
          f?.y < offensiveLineman?.y
      )
    : freezeFrames.filter(
        (f) =>
          positionCodes.includes(f.player?.position?.code) &&
          f?.y > offensiveLineman?.y
      );
  const sortedFFs = sortBy(filteredFFs, 'x');
  const splitFFs = isDefense ? sortedFFs : sortedFFs.reverse();
  let prevX = lineOfScrimmage;
  for (let index = 0; index < splitFFs.length; index += 1) {
    const t = splitFFs[index];
    const thisX =
      (isDefense && t.x > prevX) || (!isDefense && t.x < prevX) ? t.x : prevX;
    const bumpedX = isDefense ? thisX + inFrameBump : thisX - inFrameBump;
    const pathPairs = [
      { x: isDefense ? t.x + rYds : t.x - rYds, y: t.y },
      { x: bumpedX, y: t.y },
      { x: bumpedX, y: offensiveLineman.y },
      {
        x: isDefense ? offensiveLineman.x + rYds : offensiveLineman.x - rYds,
        y: offensiveLineman.y,
      },
    ];

    const color =
      t.player.position.color(visPalette)[POSITION_COLOR_ATTRIBUTE_MAIN];
    drawMeasureU(
      measuresG,
      pathPairs,
      fieldOverrides.pxPerYard,
      zoomFactor,
      color
    );

    const splitWidth = Math.abs(t.y - offensiveLineman.y).toFixed(1);

    const fontSize = getFontSize(zoomFactor, fieldOverrides.pxPerYard);
    const textX = bumpedX * fieldOverrides.pxPerYard;
    const textY = isLeft
      ? t.y * fieldOverrides.pxPerYard + 10
      : t.y * fieldOverrides.pxPerYard - 10;
    drawMeasureText(
      measuresG,
      textX,
      textY,
      color,
      fontSize,
      splitWidth,
      rotationOption
    );

    prevX = bumpedX;
  }
};

const addOffensiveSplitMeasure = function (
  measuresG,
  freezeFrames,
  rYds,
  zoomFactor,
  fieldOverrides,
  inFrameBump,
  positionCodes,
  lineOfScrimmage,
  rotationOption,
  visPalette
) {
  // const outOfFrame = outOfFrameShift + outOfFrameBump;
  const offenseFFs = freezeFrames.filter(
    (f) =>
      f.player?.position?.code ===
      ROSTER_POSITIONS[API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN].code
  );
  const minY_offense = minBy(offenseFFs, 'y');
  const maxY_offense = maxBy(offenseFFs, 'y');
  drawSplits(
    measuresG,
    freezeFrames,
    rYds,
    zoomFactor,
    fieldOverrides,
    visPalette,
    inFrameBump,
    positionCodes,
    lineOfScrimmage,
    rotationOption,
    minY_offense,
    true
  );

  drawSplits(
    measuresG,
    freezeFrames,
    rYds,
    zoomFactor,
    fieldOverrides,
    visPalette,
    inFrameBump,
    positionCodes,
    lineOfScrimmage,
    rotationOption,
    maxY_offense,
    false
  );
};

const addDefensiveSplitMeasure = function (
  measuresG,
  freezeFrames,
  rYds,
  zoomFactor,
  fieldOverrides,
  inFrameBump,
  positionCodes,
  lineOfScrimmage,
  rotationOption,
  visPalette
) {
  // const outOfFrame = outOfFrameShift + outOfFrameBump;
  const snapFFs = freezeFrames.filter((f) => f.isEventer);
  drawSplits(
    measuresG,
    freezeFrames,
    rYds,
    zoomFactor,
    fieldOverrides,
    visPalette,
    inFrameBump,
    positionCodes,
    lineOfScrimmage,
    rotationOption,
    snapFFs[0],
    true,
    true
  );

  drawSplits(
    measuresG,
    freezeFrames,
    rYds,
    zoomFactor,
    fieldOverrides,
    visPalette,
    inFrameBump,
    positionCodes,
    lineOfScrimmage,
    rotationOption,
    snapFFs[0],
    false,
    true
  );
};

const addDepths = function (
  measuresG,
  freezeFrames,
  viewFrame,
  rYds,
  zoomFactor,
  fieldOverrides,
  outOfFrameBump,
  positionCodes,
  lineOfScrimmage,
  rotationOption,
  outOfFrameShift,
  visPalette
) {
  const filteredFFs = sortBy(
    freezeFrames.filter((f) =>
      positionCodes.includes(f.player?.position?.code)
    ),
    'x'
  );
  const centerFrameY = (viewFrame.yShift.minY + viewFrame.yShift.maxY) / 2;
  let prevLeftX = -9999;
  let prevRightX = -9999;
  const fontSize = getFontSize(zoomFactor, fieldOverrides.pxPerYard);

  for (let index = 0; index < filteredFFs.length; index += 1) {
    const ff = filteredFFs[index];
    let showNumbers = false;
    // in horizontal mode need more space per number
    const textSpacer =
      rotationOption === ROTATIONS.HORIZONTAL
        ? (fontSize / fieldOverrides.pxPerYard) * 2
        : fontSize / fieldOverrides.pxPerYard;

    if (ff.y < centerFrameY) {
      showNumbers = Math.abs(ff.x - prevLeftX) > textSpacer;
      prevLeftX = showNumbers ? ff.x : prevLeftX;
    } else {
      showNumbers = Math.abs(ff.x - prevRightX) > textSpacer;
      prevRightX = showNumbers ? ff.x : prevRightX;
    }

    const color =
      ff.player.position.color(visPalette)[POSITION_COLOR_ATTRIBUTE_MAIN];
    const y1 = ff.y < centerFrameY ? ff.y - rYds : ff.y + rYds;
    const y2 =
      ff.y < centerFrameY
        ? viewFrame.yShift.minY - outOfFrameShift - outOfFrameBump
        : viewFrame.yShift.maxY + outOfFrameShift + outOfFrameBump;

    measuresG
      .append('line')
      .attr('x1', ff.x * fieldOverrides.pxPerYard)
      .attr('x2', ff.x * fieldOverrides.pxPerYard)
      .attr('y1', y1 * fieldOverrides.pxPerYard)
      .attr('y2', y2 * fieldOverrides.pxPerYard)
      .attr('stroke', color)
      .attr('stroke-width', 1 / zoomFactor)
      .attr('stroke-dasharray', '1 1');

    if (showNumbers) {
      const ffDepth = Math.abs(ff.x - lineOfScrimmage).toFixed(1);
      const textX = ff.x * fieldOverrides.pxPerYard;
      const textY = y2 * fieldOverrides.pxPerYard;
      drawMeasureText(
        measuresG,
        textX,
        textY,
        color,
        fontSize,
        ffDepth,
        rotationOption
      );
    }
  }
};

export {
  addOffensiveLineMeasure,
  addBoxMeasure,
  addDefensiveFormationMeasure,
  addOffensiveFormationMeasure,
  addOffensiveSplitMeasure,
  addDefensiveSplitMeasure,
  addDepths,
};
