import { styled } from 'styled-components';

export const PlayerStatsTableWrapper = styled.div`
  > div > div {
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  }

  .ui.attached.tabular.menu {
    display: none;
  }
`;

PlayerStatsTableWrapper.displayName = 'PlayerStatsTableWrapper';
