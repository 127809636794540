import { easeCubic } from 'd3';
import {
  ROTATIONS,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import {
  ROTATE_SCALE_ZOOM_DEFAULTS,
  rotateScaleZoom,
} from '../../utils/visualisations/rotateScaleZoom';
import {
  HIGH_NODE_ROUTES,
  LOW_NODE_ROUTES,
  ROUTE_TREE_BRANCH_DASH,
  ROUTE_TREE_BRANCH_NODE,
  ROUTE_TREE_CLASSES,
  ROUTE_TREE_RADII,
} from './RouteTree.constants';

export const addRouteTreeLayers = (mainG, { orientation, fieldX, fieldY }) => {
  const ROUTE_TREE_RSZ = {
    ...ROTATE_SCALE_ZOOM_DEFAULTS,
    viewPortWidth: orientation === ROTATIONS.HORIZONTAL ? fieldX : fieldY,
    viewPortHeight: orientation === ROTATIONS.HORIZONTAL ? fieldY : fieldX,
    fieldWidth: fieldX,
    fieldHeight: fieldY,
    targetFieldX: fieldX / 2,
    targetFieldY: fieldY / 2,
  };

  mainG.selectAll('*').remove();
  const rszG = rotateScaleZoom({
    ...ROUTE_TREE_RSZ,
    baseG: mainG,
    orientation,
  });
  rszG.attr('class', ROUTE_TREE_CLASSES.FIELD_HOLDER);
  rszG.append('g').attr('class', ROUTE_TREE_CLASSES.FIELD_GUIDES);
  const treeG = rszG.append('g').attr('class', ROUTE_TREE_CLASSES.TREE);
  treeG.append('g').attr('class', ROUTE_TREE_CLASSES.TREE_TRUNK);
  treeG.append('g').attr('class', ROUTE_TREE_CLASSES.TREE_BRANCHES);
  treeG.append('g').attr('class', ROUTE_TREE_CLASSES.TREE_FRUIT);
  treeG.append('g').attr('class', ROUTE_TREE_CLASSES.TREE_INFO);
};

export const addRouteTreeNodes = (
  treeTrunkG,
  visPalette,
  selectedRouteType
) => {
  treeTrunkG.selectAll('*').remove();
  treeTrunkG
    .append('circle')
    .attr('cy', 0)
    .attr(
      'cx',
      ROUTE_TREE_BRANCH_NODE.ORIGIN * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr('r', ROUTE_TREE_RADII.NODE)
    .attr('stroke', 'none')
    .attr('fill', visPalette.selectedObject);

  treeTrunkG
    .append('circle')
    .attr('cy', 0)
    .attr(
      'cx',
      ROUTE_TREE_BRANCH_NODE.LOW * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr('r', ROUTE_TREE_RADII.NODE)
    .attr('stroke', 'none')
    .attr('opacity', 1)
    .attr('fill', () =>
      LOW_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      HIGH_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      selectedRouteType === null
        ? visPalette.selectedObject
        : visPalette.selectionFaded
    ); // change colour due to overlap with lines when using opacity

  treeTrunkG
    .append('circle')
    .attr('cy', 0)
    .attr(
      'cx',
      ROUTE_TREE_BRANCH_NODE.HIGH * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr('r', ROUTE_TREE_RADII.NODE)
    .attr('opacity', 1)
    .attr('stroke', 'none')
    .attr('fill', () =>
      HIGH_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      selectedRouteType === null
        ? visPalette.selectedObject
        : visPalette.selectionFaded
    ); // change colour due to overlap with lines when using opacity

  // low section of trunk
  treeTrunkG
    .append('line')
    .attr(
      'x1',
      ROUTE_TREE_BRANCH_NODE.ORIGIN * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr(
      'x2',
      ROUTE_TREE_BRANCH_NODE.LOW * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr('y1', 0)
    .attr('y2', 0)
    .attr('stroke', visPalette.selectedObject)
    .attr('opacity', () =>
      LOW_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      HIGH_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      selectedRouteType === null
        ? 1
        : 0.4
    )
    .attr('stroke-width', ROUTE_TREE_RADII.NODE);

  // high section of trunk
  treeTrunkG
    .append('line')
    .attr(
      'x1',
      ROUTE_TREE_BRANCH_NODE.LOW * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr(
      'x2',
      ROUTE_TREE_BRANCH_NODE.HIGH * DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard
    )
    .attr('y1', 0)
    .attr('y2', 0)
    .attr('stroke', visPalette.selectedObject)
    .attr('opacity', () =>
      HIGH_NODE_ROUTES.some((route) => route.apiKey === selectedRouteType) ||
      selectedRouteType === null
        ? 1
        : 0.4
    )
    .attr('stroke-width', ROUTE_TREE_RADII.NODE);
};

const branchPath = (branchDatum) => {
  if (branchDatum.routeType === 'over') {
    /* Over path has a weird super bespoke 2-step curve */
    const dX = branchDatum.x2Path - branchDatum.x1;
    const dY = branchDatum.y2Path - branchDatum.y1;
    /* If have flipped then y values need mirroring */
    const mirrored = branchDatum.y2Path < 0 ? -1 : 1;
    /* Step curve for 1/3 the height (circular curve) */
    const dXStep = dX / 3;
    const dYStep = (dX / 3) * mirrored;
    /* Invert curved (sweep) to change direction */
    const dXSweep = dX / 6;
    const dYSweep = (dX / 6) * mirrored;
    /* Back to initial angle to finish the path */
    const dYLast = dY - (dYStep + dYSweep);
    return (
      `M${branchDatum.x1} ${branchDatum.y1}` +
      ` c${dXStep} 0 ${dXStep} ${dYStep} ${dXStep} ${dYStep}` +
      ` s0 ${dYSweep / 2} ${dXSweep} ${dYSweep}` +
      ` s${dX / 2} ${dYLast / 2} ${
        dX / 2 + ROUTE_TREE_RADII.FRUIT / 2
      } ${dYLast}`
    );
  }
  if (branchDatum.isCurved) {
    return (
      `M${branchDatum.x1} ${branchDatum.y1}` +
      ` S${branchDatum.x2PathC} ${branchDatum.y2PathC} ${branchDatum.x2Path} ${branchDatum.y2Path}`
    );
  }
  return `M${branchDatum.x1} ${branchDatum.y1} L${branchDatum.x2Path} ${branchDatum.y2Path}`;
};
export const addRouteTreeBranches = (
  treeBranchG,
  routeBranchData,
  isAnimated,
  selectedRouteType
) => {
  const branches = treeBranchG
    .selectAll('path')
    .data(routeBranchData, (d) => d.routeType)
    .join(
      (enter) => {
        const r = enter.append('path');
        return r;
      },
      (update) => update,
      (exit) => exit.remove()
    );
  if (isAnimated) {
    branches
      .transition()
      .ease(easeCubic)
      .duration(1000)
      .attr('d', (d) => branchPath(d))
      .attr('fill', 'none')
      .attr('stroke', (d) => (d.strokeDash ? '#aaa' : d.stroke))
      .attr('stroke-dasharray', (d) =>
        d.strokeDash ? ROUTE_TREE_BRANCH_DASH : ''
      )
      .attr('opacity', (d) =>
        selectedRouteType === null || d.routeType === selectedRouteType
          ? 1
          : 0.4
      )
      .attr('stroke-width', (d) => d.strokeWidth);
  } else {
    branches
      .attr('d', (d) => branchPath(d))
      .attr('fill', 'none')
      .attr('stroke', (d) => (d.strokeDash ? '#aaa' : d.stroke))
      .attr('stroke-dasharray', (d) =>
        d.strokeDash ? ROUTE_TREE_BRANCH_DASH : ''
      )
      .attr('opacity', (d) =>
        selectedRouteType === null || d.routeType === selectedRouteType
          ? 1
          : 0.4
      )
      .attr('stroke-width', (d) => d.strokeWidth);
  }
};

export const addRouteTreeFruit = (
  treeFruitG,
  routeFruitData,
  isAnimated,
  handleRouteTreeFruitClick,
  selectedRouteType
) => {
  const fruit = treeFruitG
    .selectAll('circle')
    .data(routeFruitData, (d) => d.routeType)
    .join(
      (enter) => {
        const r = enter.append('circle');
        r.attr('r', ROUTE_TREE_RADII.FRUIT);
        return r;
      },
      (update) => update,
      (exit) => exit.remove()
    );
  if (isAnimated) {
    fruit
      .transition()
      .ease(easeCubic)
      .duration(600)
      .attr('fill', (d) => d.fill)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)
      .attr('stroke', (d) => d.stroke)
      .attr('stroke-width', (d) => d.strokeWidth)
      .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
      .attr('opacity', (d) =>
        selectedRouteType === null || d.routeType === selectedRouteType
          ? 1
          : 0.4
      )
      .end() // Ensure the transition completes before adding the click event
      .then(() => {
        fruit.on('click', (_, datum) => {
          try {
            handleRouteTreeFruitClick(datum);
          } catch (clickError) {
            console.error('Error handling click event:', clickError);
          }
        });
      })
      .catch((transitionError) => {
        console.error('Transition error:', transitionError);
      });
  } else {
    fruit
      .attr('fill', (d) => d.fill)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)
      .attr('stroke', (d) => d.stroke)
      .attr('stroke-width', (d) => d.strokeWidth)
      .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
      .attr('opacity', (d) =>
        selectedRouteType === null || d.routeType === selectedRouteType
          ? 1
          : 0.4
      )
      .on('click', (_, datum) => handleRouteTreeFruitClick(datum));
  }
};

export const addRouteTreeLabels = (
  treeLeafLabelG,
  routeLabelData,
  visPalette,
  selectedRouteType
) => {
  treeLeafLabelG
    .selectAll('g')
    .data(routeLabelData, (d) => d.routeType)
    .join(
      (enter) => {
        const leafG = enter.append('g');
        const leafText = leafG
          .append('text')
          .attr('x', 0)
          .attr('y', (d) => d.y)
          .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
          .attr('font-family', DEFAULT_FONT)
          .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
          .attr('text-anchor', (d) => d.anchor)
          .attr('fill', visPalette.text.subHeader)
          .attr('transform', (d) => d.rotation)
          .attr('opacity', (d) =>
            selectedRouteType === null || d.routeType === selectedRouteType
              ? 1
              : 0.4
          )
          .text((d) => d.name);
        leafText.append('title').text((d) => d.tooltip);
        return leafG;
      },
      (update) => {
        update.selectAll('text').remove();
        const leafText = update
          .append('text')
          .attr('x', 0)
          .attr('y', (d) => d.y)
          .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
          .attr('font-family', DEFAULT_FONT)
          .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
          .attr('text-anchor', (d) => d.anchor)
          .attr('fill', visPalette.text.subHeader)
          .attr('transform', (d) => d.rotation)
          .attr('opacity', (d) =>
            selectedRouteType === null || d.routeType === selectedRouteType
              ? 1
              : 0.4
          )
          .text((d) => d.name);
        leafText.append('title').text((d) => d.tooltip);
        return update;
      },
      (exit) => exit.remove()
    )
    .attr('transform', (d) => `translate(${d.xLabel},${d.yLabel})`);
};
