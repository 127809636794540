import { styled } from 'styled-components';

export const RangeSliderWrapper = styled.div`
  .track {
    height: 4px;
    width: calc(100% - 8px);
  }
  .thumb {
    border: 1px solid ${({ theme }) => theme.colours.canvas.tertiary.main};
    border-radius: 12px;
    height: 16px;
    width: 16px;
    margin-top: -6px;
  }
`;
RangeSliderWrapper.displayName = 'RangeSliderWrapper';

export const SearchWithList = styled.div`
  width: 100%;
`;
SearchWithList.displayName = 'SearchWithList';

export const SearchResultHolder = styled.div`
  width: 100%;
  padding: 4px 16px;
`;
SearchResultHolder.displayName = 'SearchResultHolder';

export const SearchResultsLoading = styled.div`
  width: 100%;
  height: 2px;
  text-align: center;
`;
SearchResultsLoading.displayName = 'SearchResultsLoading';

export const SearchResults = styled.ul`
  width: 100%;
  max-height: 200px;
  overflow: auto;
  list-style-type: none;
  padding: 4;
  border-radius: 4px;
  margin: 0;
  border-width: 1px;
`;
SearchResults.displayName = 'SearchResults';

export const ChipHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  padding: 4px 0px;
`;
ChipHolder.displayName = 'ChipHolder';

export const BiometricDropdownWrapper = styled.div`
  label {
    font-size: 14.5px;
    font-weight: 400;
  }
`;
BiometricDropdownWrapper.displayName = 'BiometricDropdownWrapper';

export const SearchResultLi = styled.li`
  height: 24px;
  padding: 4px;
  width: 100%;
  margin-bottom: 2px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: ${({ theme }) => theme.colours.canvas.secondary.main};
  }
`;
SearchResultLi.displayName = 'SearchResultLi';
