import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { BasicPage } from '../../components/Layout/Layout.styles';

const Game = () => {
  const [pageInfo, setPageInfo] = useOutletContext();

  return (
    <BasicPage>
      <Outlet context={[pageInfo, setPageInfo]} />
    </BasicPage>
  );
};

export default Game;
