import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import { RADAR_CHART_CLASS_NAMES } from './RadarChart.constants';

const addRadarHeader = (svgG, visPalette, headerInfo, radarId, headerWidth) => {
  svgG.selectAll('text').remove();
  const titleBaselineY = VISUALISATION_FONT_SETUPS.HEADER_TITLE.SIZE;
  const newLineDY = 24;
  /* Left Hand Side* */
  svgG
    .append('text')
    .attr(
      'data-testid',
      `${RADAR_CHART_CLASS_NAMES.HEADER_TEXT_MAIN}-${radarId}-test`
    )
    .attr('x', 0)
    .attr('y', titleBaselineY)
    .attr('fill', visPalette.text.header)
    .attr('font-size', VISUALISATION_FONT_SETUPS.HEADER_TITLE.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'start')
    .text(headerInfo?.playerName);

  svgG
    .append('text')
    .attr('x', 0)
    .attr('y', titleBaselineY + newLineDY)
    .attr('fill', visPalette.text.info)
    .attr('font-size', VISUALISATION_FONT_SETUPS.HEADER_SUB_TITLE.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_SUB_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'start')
    .text(headerInfo?.templateName);

  /* Right Hand Side */
  svgG
    .append('text')
    .attr(
      'data-testid',
      `${RADAR_CHART_CLASS_NAMES.HEADER_TEXT_LA}-${radarId}-test`
    )
    .attr('x', headerWidth)
    .attr('y', titleBaselineY) // match baseline of main title
    .attr('fill', visPalette.text.subHeader)
    .attr('font-size', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text(headerInfo?.teamName);

  svgG
    .append('text')
    .attr('x', headerWidth)
    .attr('y', titleBaselineY + newLineDY)
    .attr('fill', visPalette.text.info)
    .attr('font-size', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text(headerInfo?.seasonName);
};
export { addRadarHeader };
