// format title as:
// if pass and target 'passer pass to target'

import { getRosterPositionColorByCode } from '../../../utils/helpers/positions';
import { getSelectedEventDetails } from './PassInfo';

// if no target then: 'passer outcome'
export const getSelectedEventTitle = (passer, target, outcome) => {
  if (passer && target) {
    return `${passer} pass to ${target}`;
  }
  if (passer && outcome) {
    return `${passer} ${outcome}`;
  }
  return '';
};

export const getCurrentPlayData = (data) => {
  const currentPlayData = {};
  if (data === null) return currentPlayData;
  // expect object formatted by: getSelectedEventDetails() pages/player/PlayerPassing/PassInfo
  currentPlayData.outcome = data?.find((i) => i.label === 'Outcome');
  currentPlayData.passer = data?.find((i) => i.label === 'Passer');
  currentPlayData.target = data?.find((i) => i.label === 'Target');
  currentPlayData.play = data?.find((i) => i.label === 'Play');
  currentPlayData.drive = data?.find((i) => i.label === 'Drive');
  currentPlayData.title = getSelectedEventTitle(
    currentPlayData.passer?.value,
    currentPlayData.target?.value,
    currentPlayData.outcome?.value
  );
  currentPlayData.game = data?.find((i) => i.label === 'Game')?.value;

  return currentPlayData;
};

// function passed to video tile to extract data
export const dataTransformFunction = (input) =>
  getCurrentPlayData(getSelectedEventDetails(input));

// format data for video tile to allow player tracking and colouring
export const formatPassingVideoData = (events, visPalette) =>
  events.map((evt) => ({
    playId: evt?.play?.id,
    highlightPlayers: [
      {
        active: true,
        id: evt?.player?.id,
        colour: getRosterPositionColorByCode(
          evt?.player?.teamPosition?.code,
          visPalette
        ),
        name: evt?.player?.name,
        number: evt?.player?.jerseyNumber,
      },
      {
        active: true,
        id: evt?.receiverPlayer?.id,
        colour: getRosterPositionColorByCode(
          evt?.receiverPlayer?.teamPosition?.code,
          visPalette
        ),
        name: evt?.receiverPlayer?.name,
        number: evt?.receiverPlayer?.jerseyNumber,
      },
    ],
  }));
