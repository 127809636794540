import {
  concat,
  drop,
  find,
  reverse,
  sortBy,
  sumBy,
  take,
  uniqBy,
} from 'lodash';
import PropTypes from 'prop-types';
import {
  API_EVENT_TYPE_DEFENDER_TOUCHED,
  API_PASS_PLAY_TURNOVER_TYPES,
} from '../../../utils/constants/api';
import { getPlayOutcome } from '../../../utils/visualisations/playSuccess';
import {
  COLORING_OPTION_BY_PLAYER,
  COLORING_OPTION_BY_TARGET,
} from '../../../visualisations/PassingChart/PassingChart.constants';
import {
  PASS_OUTCOME_TYPES,
  TARGET_ANY_ALIGNMENT,
} from './PlayerPassing.constants';

const INTERCEPTION_TYPE = 'INTERCEPTION';
const PASSERS_TO_COLOR = 4;
const TARGETS_TO_COLOR = 7; // This + 1 for "Other" (8) cannot be more than the colours in the object palette

const toChartSpace = (row, pxPerYard) => ({
  ...row,
  // TODO: probably want to get this from the DB eventually.
  distance: Math.hypot(row.endX - row.x, row.endY - row.y),
  yardsGained: row.passYardsGained || 0,
  isOut: row.passOutOfBounds,
  passVelocity:
    row.duration && row.duration > 0
      ? Math.hypot(row.endX - row.x, row.endY - row.y) / row.duration
      : 0,
  endX: row.endX * pxPerYard,
  endY: row.endY * pxPerYard,
  lineOfScrimmage: row.play.yardLine * pxPerYard,
  lineOfScrimmageEndX: (row.endX - row.play.yardLine) * pxPerYard,
  lineOfScrimmageEndY: (row.endY - row.play.yardLine) * pxPerYard,
  lineOfScrimmageX: (row.x - row.play.yardLine) * pxPerYard,
  lineOfScrimmageY: (row.y - row.play.yardLine) * pxPerYard,
  passDeltaX: (row.endX - row.x) * pxPerYard,
  passDeltaY: (row.endY - row.y) * pxPerYard,
  x: row.x * pxPerYard,
  y: row.y * pxPerYard,
  theta: (Math.atan2(row.endY - row.y, row.endX - row.x) * 180) / Math.PI,
  catchInterception:
    row.passOutcome?.oppositionCatchTypes?.includes(INTERCEPTION_TYPE) &&
    row.passOutcome?.oppositionCatchSuccess === true,
  playExplosive: row?.play?.explosive,
  playTurnover:
    row.play.turnoverType &&
    API_PASS_PLAY_TURNOVER_TYPES.includes(row.play.turnoverType),
  defenderTouched: row.types.includes(API_EVENT_TYPE_DEFENDER_TOUCHED),
  playOutcome: getPlayOutcome(row.play),
});

const sumPasses = (total, { passes }) => total + passes;

const OTHER_PASSER = {
  playerId: -1,
  playerName: 'Not Identified',
  passes: 0,
};

const PASSER_SHAPE = PropTypes.shape({
  playerId: PropTypes.number,
  playerName: PropTypes.string,
  passes: PropTypes.number,
});

const getPassers = function (formattedData) {
  const passesWithPassers = formattedData.filter(
    (f) => f.player && f.player.id
  );
  const unidentifiedPassCount = formattedData.length - passesWithPassers.length;
  const passers = uniqBy(passesWithPassers, (m) => m.player.id);
  const passerCounts = passers.map((m) => {
    const tot = passesWithPassers.filter(
      (p) => p.player.id === m.player.id
    ).length;
    const passerObj = {
      playerId: parseInt(m.player.id, 10),
      playerName: m.player.name,
      passes: tot,
    };
    return passerObj;
  });
  let rankedPassers = reverse(sortBy(passerCounts, 'passes'));
  if (unidentifiedPassCount > 0) {
    const otherPasserObj = { ...OTHER_PASSER, passes: unidentifiedPassCount };
    rankedPassers = concat(rankedPassers, [otherPasserObj]); // add other last to put at bottom of list
  }
  return rankedPassers;
};

const colorPassers = function (rankedPassers, visPalette) {
  const realPasses = rankedPassers.filter(
    (f) => f.playerId !== OTHER_PASSER.playerId
  );
  const topX = take(realPasses, PASSERS_TO_COLOR);
  const rankedOtherPasser = find(
    rankedPassers,
    (m) => m.playerId === OTHER_PASSER.playerId
  );
  if (realPasses.length > PASSERS_TO_COLOR || rankedOtherPasser) {
    // add an "other" object for the rest
    const others = drop(realPasses, PASSERS_TO_COLOR);
    const otherPasserObj = rankedOtherPasser || OTHER_PASSER;
    const coloredOther = {
      ...otherPasserObj,
      playerName: 'Other',
      passes: otherPasserObj.passes + others.reduce(sumPasses, 0),
    };
    topX.push(coloredOther);
  }
  const rankedTopPassers = topX.map((m, i) => {
    const val = { ...m, rank: i, color: visPalette.objects[`n${i + 1}`].main };
    return val;
  });
  return rankedTopPassers;
};

const addPasserColour = function (chartData, passers) {
  const coloredData = chartData.map((m) => {
    const matchingPassers = passers.filter(
      (p) => p.playerId === parseInt(m?.player?.id, 10)
    );
    const passerColor =
      matchingPassers?.length > 0
        ? matchingPassers[0].color
        : passers[passers.length - 1].color;
    const coloredDatum = { ...m, passerColor };
    return coloredDatum;
  });
  return coloredData;
};

const getTargets = function (formattedData) {
  const passesWithTargets = formattedData.filter(
    (f) => f.receiverPlayer && f.receiverPlayer.id
  );
  const unidentifiedPassCount = formattedData.length - passesWithTargets.length;
  const targets = uniqBy(passesWithTargets, (m) => m.receiverPlayer.id);
  const targetCounts = targets.map((m) => {
    const tot = passesWithTargets.filter(
      (p) => p.receiverPlayer.id === m.receiverPlayer.id
    ).length;
    const passerObj = {
      playerId: parseInt(m.receiverPlayer.id, 10),
      playerName: m.receiverPlayer.name,
      passes: tot,
    };
    return passerObj;
  });
  let rankedTargets = reverse(sortBy(targetCounts, 'passes'));
  if (unidentifiedPassCount > 0) {
    const otherPasserObj = { ...OTHER_PASSER, passes: unidentifiedPassCount };
    rankedTargets = concat(rankedTargets, [otherPasserObj]); // add other last to put at bottom of list
  }
  return rankedTargets;
};

const colorTargets = function (rankedTargets, visPalette) {
  const realPasses = rankedTargets.filter(
    (f) => f.playerId !== OTHER_PASSER.playerId
  );
  const topX = take(rankedTargets, TARGETS_TO_COLOR);
  const rankedOtherTarget = find(
    rankedTargets,
    (m) => m.playerId === OTHER_PASSER.playerId
  );
  if (rankedTargets.length > TARGETS_TO_COLOR || rankedOtherTarget > 0) {
    // add an "other" object for the rest
    const others = drop(realPasses, TARGETS_TO_COLOR);
    const otherPasserObj = rankedOtherTarget || OTHER_PASSER;
    const coloredOther = {
      ...otherPasserObj,
      playerName: 'Other',
      passes: otherPasserObj.passes + others.reduce(sumPasses, 0),
    };
    topX.push(coloredOther);
  }
  const rankedTopTargets = topX.map((m, i) => {
    const val = { ...m, rank: i, color: visPalette.objects[`n${i + 1}`].main };
    return val;
  });
  return rankedTopTargets;
};

const addTargetColour = function (chartData, passers) {
  const coloredData = chartData.map((m) => {
    const matchingPassers = passers.filter(
      (p) => p.playerId === parseInt(m?.receiverPlayer?.id, 10)
    );
    const targetColor =
      matchingPassers?.length > 0
        ? matchingPassers[0].color
        : passers[passers.length - 1].color;
    const coloredDatum = { ...m, targetColor };
    return coloredDatum;
  });
  return coloredData;
};

// value is int because react complains about nulls in dropdowns
const DEFAULT_PLAYER_SELECTION = { label: 'Any', value: 0 };

// filter targets/receivers based on player ids
const getTargetsFilter = (evt, playerIds) => {
  if (!playerIds || playerIds.length === 0) {
    return false;
  }
  return (
    playerIds.includes(evt?.receiverPlayer?.id) ||
    (playerIds.includes(OTHER_PASSER.playerId) && !evt?.receiverPlayer)
  );
};

// filter passers based on player ids
const getPassersFilter = (evt, playerIds) => {
  if (!playerIds || playerIds.length === 0) {
    return false;
  }
  return (
    playerIds.includes(evt?.player?.id) ||
    (playerIds.includes(OTHER_PASSER.playerId) && !evt?.player)
  );
};

// filter games based on pass outcomes
const getPassOutcomeFilter = (evt, passOutcomeKey) => {
  const passOutcome = PASS_OUTCOME_TYPES[passOutcomeKey];
  if (!passOutcome) {
    return true;
  }
  return passOutcome.filter(evt);
};

// filter based on target alignment position
const getTargetAlignmentFilter = (evt, targetAlignment) => {
  if (
    targetAlignment === TARGET_ANY_ALIGNMENT ||
    targetAlignment === evt?.receiverAlignmentPosition
  ) {
    return true;
  }
  return false;
};

// get total count of checked items
const getCountTotal = (data) =>
  sumBy(
    data.filter((e) => e.checked),
    'value'
  );

/* When coloring based on a player, add color value to data */
export const addColorToPlayer = (
  passData,
  coloringOption,
  passers,
  targets,
  visPalette
) => {
  if (coloringOption === COLORING_OPTION_BY_PLAYER.value) {
    const coloredPlayers = colorPassers(passers, visPalette);
    const chartData = addPasserColour(passData, coloredPlayers);
    return chartData;
  }
  if (coloringOption === COLORING_OPTION_BY_TARGET.value) {
    const coloredPlayers = colorTargets(targets, visPalette);
    const chartData = addTargetColour(passData, coloredPlayers);
    return chartData;
  }
  return passData;
};

export {
  toChartSpace,
  getPassers,
  colorPassers,
  addPasserColour,
  getTargets,
  getPassOutcomeFilter,
  getTargetAlignmentFilter,
  getTargetsFilter,
  getPassersFilter,
  getCountTotal,
  colorTargets,
  addTargetColour,
  PASSER_SHAPE,
  DEFAULT_PLAYER_SELECTION,
};
