import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames/bind';
import {
  uiState,
  cf_FieldPosition,
  cf_FieldPositionEnabled,
} from '../../apollo';
import {
  StyledModal,
  StyledSlider,
  TriggerButtonContainer,
} from './ContextFilters.styles';
import { enableFilter } from './Filters.helper';
import { useIsEligiblePage } from './ContextFilters.hooks';

const PositionFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_FieldOpen, setFieldOpen] = useState(false);
  // global state
  const cf_FieldPositionRV = useReactiveVar(cf_FieldPosition);
  const cf_FieldPositionEnabledRV = useReactiveVar(cf_FieldPositionEnabled);
  // local state
  const [st_FieldPosition, setFieldPosition] = useState(cf_FieldPositionRV);

  const handleFieldPosition = (sliderValues) => {
    setFieldPosition([...sliderValues]);
  };

  const handleRedZone = () => {
    setFieldPosition([80, 100]);
    enableFilter(
      cf_FieldPositionEnabledRV,
      cf_FieldPositionEnabled,
      'fieldPosition'
    );
  };

  const handleFieldReset = () => {
    setFieldPosition([0, 100]);
    cf_FieldPositionEnabled(true);

    uiState().contextFilters.fieldPosition.value = [0, 100];
    uiState().contextFilters.fieldPosition.enabled = true;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleModalClose = () => {
    cf_FieldPosition([...st_FieldPosition]);
    uiState().contextFilters.fieldPosition.value = [...st_FieldPosition];
    cf_FieldPositionEnabled(cf_FieldPositionEnabledRV);

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setFieldOpen(false);
  };

  const filterCount = [cf_FieldPositionEnabledRV].filter(Boolean).length;
  const isEligiblePage = useIsEligiblePage('FIELD_POSITION');
  const isEnabled = filterCount > 0 && isEligiblePage;

  const positionYards = (pos, flip) => {
    let yds = parseInt(pos, 10);

    let prefix = '';
    if (yds < 50) {
      prefix = 'own ';
    } else if (yds > 50) {
      prefix = 'opp ';
      yds -= 100;
    }

    if (flip) {
      return `${prefix}${Math.abs(yds)}`;
    }
    return `${Math.abs(yds)} ${prefix}`;
  };

  return (
    <StyledModal
      onClose={handleModalClose}
      onOpen={() => {
        setFieldOpen(true);
      }}
      open={st_FieldOpen}
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Field Position
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size="tiny"
    >
      <Modal.Content>
        <h2>Field Position</h2>
        <Form>
          <Form.Field>
            <Toggle
              id="field-position-cf-toggle"
              label="Field Position"
              onChange={() =>
                handleEnabledToggle(
                  !cf_FieldPositionEnabledRV,
                  cf_FieldPositionEnabled,
                  'fieldPosition',
                  {
                    enabled: !cf_FieldPositionEnabledRV,
                  }
                )
              }
              checked={cf_FieldPositionEnabledRV}
            />
            <b
              className={classNames({
                grey: !cf_FieldPositionEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
            >
              {st_FieldPosition &&
                `${positionYards(st_FieldPosition[0], true)} → ${positionYards(
                  st_FieldPosition[1],
                  false
                )}`}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              thumbClassName="thumb"
              trackClassName="track"
              value={st_FieldPosition}
              ariaLabel={['fieldPositionMin', 'fieldPositionMax']}
              onChange={handleFieldPosition}
              onAfterChange={() =>
                enableFilter(
                  cf_FieldPositionEnabledRV,
                  cf_FieldPositionEnabled,
                  'fieldPosition'
                )
              }
              pearling
              minDistance={1}
              min={0}
              max={100}
              withTracks
            />
            <div className="track field">
              <div>0</div>
              <div>10</div>
              <div>20</div>
              <div>30</div>
              <div>40</div>
              <div>50</div>
              <div>40</div>
              <div>30</div>
              <div>20</div>
              <div>10</div>
              <div>0</div>
            </div>
            <div className="track labels">
              <div>Own</div>
              <div>Opp</div>
            </div>
            <div>
              <Button size="small" onClick={handleRedZone}>
                Redzone
              </Button>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleFieldReset}>
          Reset
        </Button>
        <Button onClick={handleModalClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

PositionFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default PositionFilter;
