import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../../utils/helpers/contextFilters';

const overrides = { playTypes: false };

export const GET_TEAM_PASS_PRESSURES_ALLOWED = gql`
  query getOffensiveLinePressure(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables(overrides)}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports(overrides)}

    offensiveLinePressure(
      teamId: $teamId
      playFilters: {
        playTypes: [PASS]
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments(overrides)}
      }
    ) {
      leftTackle {
        id
        name
        knownName 
        mostCommonPosition {
          code
        }
        mostCommonNumber
      }
      leftGuard {
        id
        name
        knownName
        mostCommonPosition {
          code
        }
        mostCommonNumber
      }
      center {
        id
        name
        knownName
        mostCommonPosition {
          code
        }
        mostCommonNumber
      }
      rightGuard {
        id
        name
        knownName
        mostCommonPosition {
          code
        }
        mostCommonNumber
      }
      rightTackle {
        id
        name
        knownName
        mostCommonPosition {
          code
        }
        mostCommonNumber
      }
      plays
      pressureGaps
      leftTacklePressures
      leftTacklePressurePlays
      leftGuardPressures
      leftGuardPressurePlays
      centerPressures
      centerPressurePlays
      rightGuardPressures
      rightGuardPressurePlays
      rightTacklePressures
      rightTacklePressurePlays
      allPressurePlays
    }
  }
`;
