import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { select } from 'd3';
import { getPalette } from '../../utils/visualisations/visPalettes';
import { useD3 } from '../../utils/hooks/useD3';
import { appendText } from '../text';
import { addZones, addColumn } from './SafePalette.drawing';
import { GET_TEAMS } from '../../components/Header/getTeams';
import { getSafeColorFromHex } from '../../utils/helpers/colorDifference';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';

const TeamPalette = () => {
  // SVG BASIC VALUES
  const visPaletteDark = getPalette(true);
  const visPaletteLight = getPalette(false);

  const { data: teamsData } = useQuery(GET_TEAMS);
  const teams = teamsData?.teams?.edges?.map((n) => n.node);

  const svgWidth = '100%';
  const canvasWidth = 900;
  const colorLineHeight = 70;
  const teamBatchHeight = teams
    ? teams?.length * colorLineHeight
    : colorLineHeight;
  const canvasHeight = teamBatchHeight + 100;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };

  const viewBox = `0 0 ${(
    canvasWidth +
    margin.left +
    margin.right
  ).toString()} ${(canvasHeight + margin.top + margin.bottom).toString()}`;

  // this level declares anything static
  const ref = useD3((svg) => {
    svg
      .attr('id', 'palette-display-vis')
      .attr('width', svgWidth)
      .attr('viewBox', viewBox);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', '#aaa');

    const marginTransform = `translate(${margin.left},${margin.top})`;
    const canvasG = svg
      .append('g')
      .attr('transform', marginTransform)
      .attr('class', 'canvas-group');
    appendText(canvasG, visPaletteLight, {
      x: 0,
      y: 15,
      message: 'Team',
    });

    /* Add dark and Light Canvases */
    addColumn(canvasG, {
      x: 200,
      w: 300,
      h: '100%',
      fill: visPaletteDark.background.main,
    });
    addColumn(canvasG, {
      x: 550,
      w: 300,
      h: '100%',
      fill: visPaletteLight.background.main,
    });
    const darkG = canvasG.append('g').attr('transform', 'translate(200,0)');
    const lightG = canvasG.append('g').attr('transform', 'translate(550,0)');

    addZones(darkG, visPaletteDark, 'Dark Palette');
    addZones(lightG, visPaletteLight, 'Light Palette');
    canvasG
      .append('g')
      .attr('transform', 'translate(0,40)')
      .attr('class', 'colors-unsafe');
    canvasG
      .append('g')
      .attr('transform', 'translate(0,40)')
      .attr('class', 'colors-safe');
    canvasG
      .append('g')
      .attr('transform', 'translate(0,40)')
      .attr('class', 'color-texts');
  }, []);

  useEffect(() => {
    const svg = select(ref.current);

    svg.attr('viewBox', viewBox);

    const unsafeG = svg.select('.colors-unsafe');
    const safeG = svg.select('.colors-safe');
    const textG = svg.select('.color-texts');
    unsafeG.selectAll('g').remove();
    safeG.selectAll('g').remove();
    textG.selectAll('g').remove();

    const primaryUnsafeG = unsafeG.append('g');
    const primarySafeGD = safeG.append('g');
    const primarySafeGL = safeG.append('g');
    const secondaryUnsafeG = unsafeG.append('g');
    const secondarySafeGD = safeG.append('g');
    const secondarySafeGL = safeG.append('g');
    const tertiaryUnsafeG = unsafeG.append('g');
    const tertiarySafeGD = safeG.append('g');
    const tertiarySafeGL = safeG.append('g');

    const teamNamesG = textG.append('g');
    const rawPCHexG = textG.append('g');
    const safePCHexG = textG.append('g');
    const safePCHexGL = textG.append('g');
    const rawSCHexG = textG.append('g');
    const safeSCHexG = textG.append('g');
    const safeSCHexGL = textG.append('g');
    const rawTCHexG = textG.append('g');
    const safeTCHexG = textG.append('g');
    const safeTCHexGL = textG.append('g');
    /*  
        Render each color category
        For each color category, render each color
    */

    const customTeamPaletteHexFontSetup = { SIZE: 7, WEIGHT: 600 };
    if (teams) {
      teamNamesG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 0)
        .attr('y', (d, i) => i * colorLineHeight)
        .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
        .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'start')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => d?.name);

      rawPCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 3)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => d?.colorPrimary);

      safePCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 14)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorPrimary, true));

      safePCHexGL
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 870)
        .attr('y', (d, i) => i * colorLineHeight + 14)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'start')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorPrimary, false));

      rawSCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 25)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => d?.colorSecondary);

      safeSCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 36)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorSecondary, true));

      safeSCHexGL
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 870)
        .attr('y', (d, i) => i * colorLineHeight + 36)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'start')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorSecondary, false));

      rawTCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 47)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => d?.colorTertiary);

      safeTCHexG
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 178)
        .attr('y', (d, i) => i * colorLineHeight + 58)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'end')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorTertiary, true));

      safeTCHexGL
        .selectAll('text')
        .data(teams)
        .enter()
        .append('text')
        .attr('x', 870)
        .attr('y', (d, i) => i * colorLineHeight + 58)
        .attr('font-size', `${customTeamPaletteHexFontSetup.SIZE}px`)
        .attr('font-weight', customTeamPaletteHexFontSetup.WEIGHT)
        .attr('font-family', DEFAULT_FONT)
        .attr('text-anchor', 'start')
        .attr('fill', visPaletteLight.text.info)
        .text((d) => getSafeColorFromHex(d?.colorTertiary, false));

      primaryUnsafeG
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight)
        .attr('width', 720)
        .attr('height', 5)
        .attr('fill', (d) => d.colorPrimary)
        .attr('stroke', 'none');
      primarySafeGD
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight + 10)
        .attr('width', 340)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorPrimary, true))
        .attr('stroke', 'none');
      primarySafeGL
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 540)
        .attr('y', (d, i) => i * colorLineHeight + 10)
        .attr('width', 320)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorPrimary, false))
        .attr('stroke', 'none');

      secondaryUnsafeG
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight + 20)
        .attr('width', 720)
        .attr('height', 5)
        .attr('fill', (d) => d.colorSecondary)
        .attr('stroke', 'none');
      secondarySafeGD
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight + 30)
        .attr('width', 340)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorSecondary, true))
        .attr('stroke', 'none');
      secondarySafeGL
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 540)
        .attr('y', (d, i) => i * colorLineHeight + 30)
        .attr('width', 320)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorSecondary, false))
        .attr('stroke', 'none');

      tertiaryUnsafeG
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight + 40)
        .attr('width', 720)
        .attr('height', 5)
        .attr('fill', (d) => d.colorTertiary)
        .attr('stroke', 'none');
      tertiarySafeGD
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 180)
        .attr('y', (d, i) => i * colorLineHeight + 50)
        .attr('width', 340)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorTertiary, true))
        .attr('stroke', 'none');
      tertiarySafeGL
        .selectAll('rect')
        .data(teams)
        .enter()
        .append('rect')
        .attr('x', 540)
        .attr('y', (d, i) => i * colorLineHeight + 50)
        .attr('width', 320)
        .attr('height', 5)
        .attr('fill', (d) => getSafeColorFromHex(d.colorTertiary, false))
        .attr('stroke', 'none');
    }
  }, [teams]);

  return <svg ref={ref} data-testid="palette-display-vis" />;
};

export default TeamPalette;
