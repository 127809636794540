import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, Dropdown } from '@statsbomb/kitbag-components';
import {
  SummaryTile,
  SummaryTileHeader,
  SummaryTileBody,
} from '../../../../components/Tile/TileSummary.styles';
import {
  WizardResultsSummaryStats,
  WizardResultsSummaryStatsColumn,
  WizardResultsSummaryStatsColumnHeader,
} from '../PlayFinderResults.styles';
import { PlayFinderSummaryBars } from '../PlayFinderSummaryBars/PlayFinderSummaryBars';
import { formatPlayFinderSummaryData } from '../PlayFinderResults.dataManipulation';
import { PLAY_FINDER_STAT_BLOCKS } from '../PlayFinderResults.constants';
import { PlayFilterResultPropTypes } from '../../PlayFinder.propTypes';
import { SummaryStat } from '../../../../components/SummaryStat/SummaryStat';

const PlayFinderSummary = ({
  playFinderResultData,
  playsCount,
  isLoading,
  handleDisplaySummaryOpen,
}) => {
  const [displaySummaryOpen, setDisplaySummaryOpen] = useState(true);
  const [selectedDistribution, setSelectedDistribution] = useState(0);

  // summary data
  const aggregateData = formatPlayFinderSummaryData(
    playFinderResultData?.playFinder?.aggregates,
    PLAY_FINDER_STAT_BLOCKS
  );
  const distributionAggregateDataTitles = aggregateData
    .filter((datum) => Array.isArray(datum.distribution))
    .map((datum, index) => ({ label: datum.label, value: index }));

  const formattedAggregateData = aggregateData
    .filter((datum) => Array.isArray(datum.distribution))
    [selectedDistribution]?.distribution.map((dist) => ({
      name: dist.name,
      percentage: dist.percentage,
    }));

  const onDisplaySummaryOpen = (isOpen) => {
    setDisplaySummaryOpen(isOpen);
    handleDisplaySummaryOpen(isOpen);
  };

  return (
    <SummaryTile>
      <SummaryTileHeader $borderWidth="0">
        <div>
          <h3>{`Summary of ${playsCount} snap${
            playsCount !== 1 ? 's' : ''
          }`}</h3>
        </div>
        <div>
          <ButtonIcon
            onClick={() => onDisplaySummaryOpen(!displaySummaryOpen)}
            variant="secondary"
            shape="pill"
            size="small"
            icon={displaySummaryOpen ? 'ChevronUp' : 'ChevronDown'}
            isDisabled={isLoading}
            title="ChevronUp"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody
        style={{
          minHeight: displaySummaryOpen ? '125px' : '0',
          maxHeight: displaySummaryOpen ? '220px' : '0',
        }}
        $margin="0"
        $overflowY="hidden"
      >
        <WizardResultsSummaryStats>
          <WizardResultsSummaryStatsColumn>
            <div>
              <WizardResultsSummaryStatsColumnHeader>
                <h3>Performance</h3>
              </WizardResultsSummaryStatsColumnHeader>
              {aggregateData
                .filter((data) => !Array.isArray(data.distribution))
                .map((stat) => (
                  <SummaryStat
                    label={stat.label}
                    value={stat.value}
                    key={stat.label}
                  />
                ))}
            </div>
          </WizardResultsSummaryStatsColumn>
          <WizardResultsSummaryStatsColumn>
            <div>
              <WizardResultsSummaryStatsColumnHeader>
                <h3>Distribution</h3>
                <Dropdown
                  id="distribution-aggregate-dropdown"
                  options={distributionAggregateDataTitles}
                  label="Select distribution category"
                  labelPosition="none"
                  onChange={(selectedOption) => {
                    setSelectedDistribution(selectedOption.value);
                  }}
                  value={distributionAggregateDataTitles.find(
                    (item) => item.value === selectedDistribution
                  )}
                  isSearchable={false}
                  size="small"
                />
              </WizardResultsSummaryStatsColumnHeader>
              <PlayFinderSummaryBars data={formattedAggregateData} />
            </div>
          </WizardResultsSummaryStatsColumn>
        </WizardResultsSummaryStats>
      </SummaryTileBody>
    </SummaryTile>
  );
};

PlayFinderSummary.propTypes = {
  playFinderResultData: PlayFilterResultPropTypes,
  playsCount: PropTypes.number,
  isLoading: PropTypes.bool,
  handleDisplaySummaryOpen: PropTypes.func,
};

PlayFinderSummary.defaultProps = {
  playFinderResultData: {},
  playsCount: 0,
  isLoading: false,
  handleDisplaySummaryOpen: () => {},
};

export default PlayFinderSummary;
