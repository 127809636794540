import {
  OLPRESSURE_GAP_LIMITS,
  OLPRESSURE_POSITION_LIMITS,
  OLP_BASELINE_OFFSET,
  OLP_VIS_GAPS,
  OLP_VIS_POS,
} from './OLPressure.constants';

/* Creates the various values used in the visualisation by scaling relative to League Average ("Mu") */
const addPressureModeValues = (pressureDatum) => {
  const baseline = pressureDatum.avgPressurePerc - OLP_BASELINE_OFFSET;
  const modifiedDatum = {
    ...pressureDatum,
    pressMu: pressureDatum.pressurePerc / pressureDatum.avgPressurePerc,
    teamMu: pressureDatum.teamPressurePerc / pressureDatum.avgPressurePerc,
    pressOverBaseline:
      pressureDatum.pressurePerc === 0
        ? 0
        : pressureDatum.pressurePerc - baseline,
    teamOverBaseline:
      pressureDatum.teamPressurePerc === 0
        ? 0
        : pressureDatum.teamPressurePerc - baseline,
    pressRelative: pressureDatum.pressurePerc - pressureDatum.avgPressurePerc,
    teamRelative:
      pressureDatum.teamPressurePerc - pressureDatum.avgPressurePerc,
  };
  return modifiedDatum;
};

/* 
This takes the object with all of the gaps info 
splits it into an array that d3 can iterate over to get info
*/
const gapsDatumToGaps = function (gapsDatum, teamDatum, selectedCompetitionId) {
  const averagedGaps = OLP_VIS_GAPS.map((m) => {
    const pressurePerc = gapsDatum?.[m.key];
    const teamPressurePerc = teamDatum?.[m.key];
    const { mean } = OLPRESSURE_GAP_LIMITS.find(
      (f) => f.gap === m.gap && f.comp === selectedCompetitionId
    );
    return {
      ...m,
      pressurePerc,
      teamPressurePerc,
      avgPressurePerc: mean * 100,
    };
  });

  const modedGaps = averagedGaps.map(addPressureModeValues);
  return modedGaps;
};

const positionDatumToPositions = function (positionDatum, teamDatum) {
  const percentilefier =
    positionDatum.plays !== 0 ? 100 / positionDatum.plays : 1;
  const teamPercentilefier = teamDatum.plays !== 0 ? 100 / teamDatum.plays : 1;
  const comp = positionDatum?.comp || 1409;

  const positionData = OLP_VIS_POS.map((m) => {
    const pressurePerc = positionDatum[m.key] * percentilefier;
    const teamPressurePerc = teamDatum[m.key] * teamPercentilefier;
    const { mean } = OLPRESSURE_POSITION_LIMITS.find(
      (f) => f.position === m.label && f.comp === comp
    );
    return {
      ...m,
      pressurePerc,
      teamPressurePerc,
      avgPressurePerc: mean * 100,
    };
  });

  const modedData = positionData.map(addPressureModeValues);
  return modedData;
};

export { gapsDatumToGaps, positionDatumToPositions };
