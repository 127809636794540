import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Toggle } from '@statsbomb/kitbag-components';
import { Modal, Form, Label } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import uiDefault from '../../apollo/uiDefault';
import { convertIntToNth } from '../../utils/helpers/formatting';
import { secondsToTime } from '../../utils/helpers/time';
import {
  uiState,
  cf_Quarter,
  cf_QuarterEnabled,
  cf_Clock,
  cf_ClockEnabled,
} from '../../apollo';
import {
  StyledModal,
  StyledSlider,
  TriggerButtonContainer,
} from './ContextFilters.styles';
import { enableFilter } from './Filters.helper';
import { useIsEligiblePage } from './ContextFilters.hooks';

const TimeFilter = ({ handleEnabledToggle }) => {
  // modal open/close
  const [st_ModalOpen, setModalOpen] = useState(false);
  // context filters in global state
  const cf_QuarterRV = useReactiveVar(cf_Quarter);
  const cf_QuarterEnabledRV = useReactiveVar(cf_QuarterEnabled);
  const cf_ClockRV = useReactiveVar(cf_Clock);
  const cf_ClockEnabledRV = useReactiveVar(cf_ClockEnabled);
  // context filters in local state
  const [st_Quarter, setQuarter] = useState(cf_QuarterRV);
  const [st_Clock, setClock] = useState(cf_ClockRV);

  const handleQuarter = (isQuarterChecked, index) => {
    const quarters = [...st_Quarter];
    quarters[index] = isQuarterChecked;
    setQuarter([...quarters]);
    enableFilter(cf_QuarterEnabledRV, cf_QuarterEnabled, 'quarter');
  };

  const handleClockPosition = (sliderValues) => {
    setClock([...sliderValues]);
  };

  const handleInside = () => {
    setClock([0, 120]);
    enableFilter(cf_ClockEnabledRV, cf_ClockEnabled, 'clock');
  };

  const handleReset = () => {
    const { quarter, clock } = uiDefault.contextFilters;

    setQuarter([...quarter.value]);
    cf_QuarterEnabled(quarter.enabled);
    setClock([...clock.value]);
    cf_ClockEnabled(clock.enabled);

    uiState().contextFilters.fieldPosition.value = [...quarter.value];
    uiState().contextFilters.fieldPosition.enabled = quarter.enabled;
    uiState().contextFilters.clock.value = [...clock.value];
    uiState().contextFilters.clock.enabled = clock.enabled;

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
  };

  const handleModalClose = () => {
    const { contextFilters } = uiState();
    const { quarter, clock } = contextFilters;

    cf_Quarter([...st_Quarter]);
    quarter.value = [...st_Quarter];
    cf_QuarterEnabled(cf_QuarterEnabledRV);

    // convert from seconds to milliseconds
    cf_Clock([st_Clock[0] * 1000, st_Clock[1] * 1000]);
    clock.value = [...st_Clock];
    cf_ClockEnabled(cf_ClockEnabledRV);

    sessionStorage.setItem('uiStateLocal', JSON.stringify(uiState()));
    setModalOpen(false);
  };

  const filterCount = [cf_QuarterEnabledRV, cf_ClockEnabledRV].filter(
    Boolean
  ).length;
  const isEligiblePage = useIsEligiblePage('TIME');
  const isEnabled = filterCount > 0 && isEligiblePage;

  return (
    <StyledModal
      onClose={handleModalClose}
      onOpen={() => {
        setModalOpen(true);
      }}
      open={st_ModalOpen}
      trigger={
        <TriggerButtonContainer>
          <Button
            variant={isEnabled ? 'primary' : 'tertiary'}
            size="small"
            isDisabled={!isEligiblePage}
          >
            Time
          </Button>
          {isEnabled && <Label floating>{filterCount}</Label>}
        </TriggerButtonContainer>
      }
      size="tiny"
    >
      <Modal.Content>
        <h2>Time</h2>
        <Form>
          <Form.Field>
            <Toggle
              id="quarter-cf-toggle"
              label="Quarter"
              onChange={() =>
                handleEnabledToggle(
                  !cf_QuarterEnabledRV,
                  cf_QuarterEnabled,
                  'quarter',
                  {
                    enabled: !cf_QuarterEnabledRV,
                  }
                )
              }
              checked={cf_QuarterEnabledRV}
            />
          </Form.Field>
          <Form.Group widths="equal">
            {st_Quarter &&
              st_Quarter.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Form.Field key={`quarters-field-${index}`}>
                  <Checkbox
                    id={`quarters-field-${index}-checkbox`}
                    label={index < 4 ? convertIntToNth(index + 1) : 'Overtime'}
                    onChange={() => handleQuarter(!item, index)}
                    value={String(st_Quarter[index])}
                    checked={item}
                  />
                </Form.Field>
              ))}
          </Form.Group>
          <hr />
          <Form.Field>
            <Toggle
              id="clock-cf-toggle"
              label="Clock"
              onChange={() =>
                handleEnabledToggle(
                  !cf_ClockEnabledRV,
                  cf_ClockEnabled,
                  'clock',
                  {
                    enabled: !cf_ClockEnabledRV,
                  }
                )
              }
              checked={cf_ClockEnabledRV}
            />
            <b
              className={classNames({
                grey: !cf_ClockEnabledRV,
              })}
              style={{ position: 'absolute', top: '5px' }}
            >
              {st_Clock &&
                `${secondsToTime(st_Clock[1])} — ${secondsToTime(st_Clock[0])}`}
            </b>
          </Form.Field>
          <Form.Field className="flex">
            <StyledSlider
              className="inverted"
              thumbClassName="thumb"
              trackClassName="track"
              value={st_Clock}
              ariaLabel={['clockMin', 'clockMax']}
              onChange={handleClockPosition}
              onAfterChange={() =>
                enableFilter(cf_ClockEnabledRV, cf_ClockEnabled, 'clock')
              }
              pearling
              minDistance={1}
              min={0}
              max={900}
              withTracks
              step={10}
              invert
            />
            <div className="track clock">
              <div>15:00</div>
              <div>10:00</div>
              <div>05:00</div>
              <div>00:00</div>
            </div>
            <div>
              <Button size="small" onClick={handleInside}>
                Inside 2:00
              </Button>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleReset}>
          Reset
        </Button>
        <Button onClick={handleModalClose}>Save</Button>
      </Modal.Actions>
    </StyledModal>
  );
};

TimeFilter.propTypes = {
  handleEnabledToggle: PropTypes.func.isRequired,
};

export default TimeFilter;
