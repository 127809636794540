import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';

import {
  addTackleDerivedValues,
  getBallCarriers,
  getPlayerTacklePlays,
  getTacklers,
} from '../../../visualisations/TackleLocation/TackleLocation.dataManipulation';
import {
  getTacklingLeagueAverages,
  useGetPlayerStats,
  useGetTacklingPlays,
} from '../../team/TeamTackling/TeamTackleAttempts.hooks';
import {
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLE_LOCATION_Y_MODE_FIELD,
  TACKLE_POSITION_ANY,
  TACKLING_FIELD_DISPLAY_MODES,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_PLAY_TYPE_ANY,
} from '../../../visualisations/TackleLocation/TackleLocation.constants';
import {
  mf_Players,
  mf_PlayerDetails,
  mf_Leagues,
  mf_TeamDetails,
} from '../../../apollo';
import { ROTATIONS } from '../../../utils/constants/charting';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import useQueryString from '../../../utils/hooks/useQueryString';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';
import TackleLocationDistribution from '../../../visualisations/TackleLocation/TackleLocation.Distribution';
import TackleLocation from '../../../visualisations/TackleLocation/TackleLocation';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const PlayerTacklingTile = () => {
  const team = useReactiveVar(mf_TeamDetails);
  const teamId = team?.id;
  const playerId = useReactiveVar(mf_Players);
  const competitionId = useReactiveVar(mf_Leagues);
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const [fieldMode, setFieldMode] = useQueryString(
    'fieldModeTackling',
    TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value
  );

  const generalPosition =
    mf_PlayerDetailsRV?.mostCommonPosition?.generalPosition;
  const onDefense =
    generalPosition === API_ROSTER_POSITION_KEYS.LINEBACKER ||
    generalPosition === API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN ||
    generalPosition === API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK;
  const fieldOrientation = onDefense
    ? ROTATIONS.VERTICAL_DOWN
    : ROTATIONS.VERTICAL_UP;

  // data
  const {
    loading: tacklingLoading,
    error: tacklingError,
    tacklingData,
  } = useGetTacklingPlays(onDefense, TACKLING_PLAY_TYPE_ANY.value);

  const tacklesAndAttempts = tacklingData?.length
    ? addTackleDerivedValues(tacklingData)
    : [];
  const {
    loading: playerStatsLoading,
    error: playerStatsError,
    playerStatsData,
  } = useGetPlayerStats(teamId, TACKLING_PLAY_TYPE_ANY, onDefense);

  const hasError = !!tacklingError || !!playerStatsError;
  const isLoading = tacklingLoading || playerStatsLoading;

  const tackleAttemptLeagueAverage = getTacklingLeagueAverages(
    competitionId,
    TACKLING_PLAY_TYPE_ANY.value
  );

  const ballCarriers =
    tacklesAndAttempts && playerStatsData
      ? getBallCarriers(tacklesAndAttempts, playerStatsData)
      : null;
  const tacklers =
    tacklesAndAttempts && playerStatsData
      ? getTacklers(tacklesAndAttempts, playerStatsData)
      : null;

  const filteredData = getPlayerTacklePlays(
    tacklesAndAttempts,
    onDefense,
    playerId,
    TACKLING_PLAY_TYPE_ANY,
    TACKLE_POSITION_ANY.value
  );

  const TACKLING_TILE_DISPLAY_MODES = [
    {
      value: 'DIST',
      label: 'Distribution',
      variant: 'Analysis',
    },
    ...TACKLING_FIELD_DISPLAY_MODES,
  ];

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Tackling</h3>
        </div>
        <div>
          {!hasError && !isLoading && filteredData?.length !== 0 && (
            <DropdownWrapper>
              <Dropdown
                id="tacklingChartDisplayDrop"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(selectedOption) =>
                  setFieldMode(selectedOption.value)
                }
                options={TACKLING_TILE_DISPLAY_MODES}
                value={TACKLING_TILE_DISPLAY_MODES.find(
                  (f) => f.value === fieldMode
                )}
                labelPosition="none"
                label="Tackling chart display options"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            // eslint-disable-next-line max-len
            href={`/team/tackles/:leagues/:seasons/:teams/?coordinateMode=FIELD&&playType=ANY&fieldFocusOrigin=false&defensive=${onDefense}&fieldMode=${fieldMode}&player=${playerId}&rotation=${ROTATIONS.VERTICAL_UP}&scaleR=false&showFirstTackleTails=true`}
            linkName="Open tackling"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {isLoading && <TileDimmer />}
        {filteredData?.length === 0 && !isLoading && (
          <TileDimmer active>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div className="chartWrapper">
          <div className={fieldMode !== 'DIST' ? 'show' : 'hide'}>
            <TackleLocation
              data={filteredData}
              dataLA={null}
              orientation={fieldOrientation}
              displayXFocusedField
              displayXMode={TACKLE_LOCATION_X_MODE_LOS.value}
              displayYMode={TACKLE_LOCATION_Y_MODE_FIELD.value}
              colorMode={TACKLE_LOCATION_COLOR_MODE_DX.value}
              scaleR={false}
              selectedPlay={null}
              setSelectedPlay={() => {}}
              displayKey
              distroAreaColorMode={TACKLE_LOCATION_DISTRO_COLOR_WINNER.value}
              ballCarriers={ballCarriers}
              tacklers={tacklers}
              fieldMode={fieldMode}
              fieldFocusOrigin={false}
              showHeatmapDots={false}
              showFirstTackleTails
              selectedTacklerId={playerId}
              showDistribution={false}
              isInteractive={false}
            />
          </div>
          <div className={fieldMode === 'DIST' ? 'dist show' : 'dist'}>
            <TackleLocationDistribution
              data={filteredData}
              dataLA={tackleAttemptLeagueAverage}
              orientation={fieldOrientation}
              displayXFocusedField
              displayXMode={TACKLE_LOCATION_X_MODE_LOS.value}
              displayYMode={TACKLE_LOCATION_Y_MODE_FIELD.value}
              colorMode={TACKLE_LOCATION_COLOR_MODE_DX.value}
              scaleR={false}
              selectedPlay={null}
              setSelectedPlay={() => {}}
              displayKey
              distroAreaColorMode={TACKLE_LOCATION_DISTRO_COLOR_WINNER.value}
              ballCarriers={ballCarriers}
              tacklers={tacklers}
              fieldMode={fieldMode}
              fieldFocusOrigin={false}
              showHeatmapDots={false}
              showFirstTackleTails
              selectedTacklerId={playerId}
              showDistribution
              isInteractive={false}
            />
          </div>
        </div>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerTacklingTile;
