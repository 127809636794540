import { minBy } from 'lodash';

const formatPlay = function (playNode) {
  const playDatum = playNode.node;

  return playDatum;
};

const formatDriveInfo = (driveDatum, playsData) => {
  const playsInDrive = playsData.filter((d) => d.drive.id === driveDatum.id);
  // scores can only increase so don't care about ordering chronologically
  const { homeScore } = minBy(playsInDrive, 'homeScore');
  const { awayScore } = minBy(playsInDrive, 'awayScore');

  const homeTeamId = playsData?.[0].game.homeTeam.id || 0;
  const isAwayOffense = driveDatum.team.id !== homeTeamId;

  // add points that were scored in the drive to the appropriate team current score
  const endOfDriveAwayScore =
    awayScore +
    (isAwayOffense ? driveDatum.offensePoints : driveDatum.defensePoints);
  const endOfDriveHomeScore =
    homeScore +
    (!isAwayOffense ? driveDatum.offensePoints : driveDatum.defensePoints);

  const modifiedDatum = {
    ...driveDatum,
    homeScore: endOfDriveHomeScore,
    awayScore: endOfDriveAwayScore,
    homeTeamId,
    playsInDrive,
  };
  return modifiedDatum;
};

export { formatPlay, formatDriveInfo };
