import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import {
  OutcomeRoundel,
  StatsBlock,
  StatsBlockItemHeader,
  StatsBlockRightColumn,
  StatsPlayersWrapper,
} from '../../../components/VideoTile/VideoTile.styles';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import { applyOutcomeColor } from '../../../components/VideoTile/VideoTile.drawing';

const PassSelectedEvent = ({ data }) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const { outcome, passer, target, play, drive } = data && data;

  return passer ? (
    <StatsBlock>
      <div>
        <div>
          <div
            style={{
              padding: '0 0 1rem 0',
              height: '3.15625rem',
            }}
          >
            {outcome?.value && (
              <>
                <StatsBlockItemHeader>Outcome</StatsBlockItemHeader>
                <OutcomeRoundel
                  $color={applyOutcomeColor(outcome?.value, visPalette)}
                >
                  {outcome?.value}
                </OutcomeRoundel>
              </>
            )}
          </div>
        </div>
        <StatsPlayersWrapper>
          {passer?.value && (
            <div>
              <StatsBlockItemHeader>Passer</StatsBlockItemHeader>
              <ParsedLink
                href={`/player/overview/:leagues/:seasons/:teams/${passer?.id}`}
              >
                {passer?.value}
              </ParsedLink>
            </div>
          )}

          {target?.value && (
            <div>
              <StatsBlockItemHeader>Target</StatsBlockItemHeader>
              <ParsedLink
                href={`/player/overview/:leagues/:seasons/:teams/${target?.id}`}
              >
                {target?.value}
              </ParsedLink>
            </div>
          )}
        </StatsPlayersWrapper>
      </div>
      <StatsBlockRightColumn>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                padding: '0 0 0.5rem 0',
                height: '2.65625rem',
              }}
            >
              <StatsBlockItemHeader>Play</StatsBlockItemHeader>
              {play?.value}
            </div>
          </Grid>
          <Grid item xs={12}>
            <StatsBlockItemHeader>Drive</StatsBlockItemHeader>
            {drive?.value}
          </Grid>
        </Grid>
      </StatsBlockRightColumn>
    </StatsBlock>
  ) : null;
};

PassSelectedEvent.propTypes = {
  data: PropTypes.shape({
    outcome: PropTypes.shape({
      value: PropTypes.string,
    }),
    passer: PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
    target: PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
    play: PropTypes.shape({
      value: PropTypes.string,
    }),
    drive: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

PassSelectedEvent.defaultProps = {
  data: null,
};

export default PassSelectedEvent;
