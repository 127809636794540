// pull keys from the (trait or performance) template
// use those keys and find the matching data (based on matching to name)
// [key]: value
export const getRadarComparisonData = (data, template) => {
  const mergedData = {};

  template?.forEach((templateItem) => {
    const matchingValueItem = data?.find(
      (valueItem) => valueItem.name === templateItem.key
    );
    if (matchingValueItem) {
      mergedData[templateItem.key] = matchingValueItem.value;
    }
  });

  return mergedData;
};

// the buckets are slightly skewed to the top end
// ...we are generally showing the Top 5 matches (and they're normally good)
// in this instance passed to csValue which on a null will default to 0, see colorScales.js
export const findClosestBucket = (value) => {
  switch (true) {
    case value >= 0 && value <= 0.25:
      return 0;
    case value > 0.25 && value <= 0.5:
      return 0.25;
    case value > 0.5 && value <= 0.7:
      return 0.5;
    case value > 0.7 && value <= 0.8:
      return 0.75;
    case value > 0.8 && value <= 0.9:
      return 0.85;
    case value > 0.9 && value <= 1:
      return 0.95;
    default:
      return null;
  }
};
