import { meanBy } from '../../../utils/helpers/aggregation';
import { HASH_BANDS } from './PlayerSnap.dataManipulation';

/**
 * @param {Number} total
 * * @return {Number}
 */
const countAny = (total) => total + 1;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.hashBand
 * * @return {Number}
 */
const countLeftHash = (total, { hashBand }) =>
  hashBand === HASH_BANDS.leftHash ? total + 1 : total;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.hashBand
 * * @return {Number}
 */
const countLefCenter = (total, { hashBand }) =>
  hashBand === HASH_BANDS.leftCenter ? total + 1 : total;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.hashBand
 * * @return {Number}
 */
const countRightHash = (total, { hashBand }) =>
  hashBand === HASH_BANDS.rightHash ? total + 1 : total;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.hashBand
 * * @return {Number}
 */
const countRightCenter = (total, { hashBand }) =>
  hashBand === HASH_BANDS.rightCenter ? total + 1 : total;

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.routeRun
 * * @return {Number}
 */
const countRoute = (total, { routeRun }) => (routeRun ? total + 1 : total);

/**
 * @param {Number} total
 * @param {Object} datum
 * @param {Boolean} datum.target
 * * @return {Number}
 */
const countTarget = (total, { target }) => (target ? total + 1 : total);

const INFO_ITEM_HEADERS = [
  { label: 'Metric', key: 'label' },
  { label: 'Pass', key: 'passValue' },
  { label: 'Run', key: 'runValue' },
  { label: 'Total', key: 'totalValue' },
];

const snapDistroInfo = function (snapData) {
  const infoItems = [];
  if (!snapData || snapData.length === 0) {
    return infoItems;
  }

  const passSnapData = snapData.filter((p) => p.play.type === 'PASS');
  const runSnapData = snapData.filter((p) => p.play.type === 'RUSH');

  const addInfoItem_Reduce = function (label, reducer) {
    const totalValue = snapData.reduce(reducer, 0);
    const passValue = passSnapData.reduce(reducer, 0);
    const runValue = runSnapData.reduce(reducer, 0);
    return { label, totalValue, passValue, runValue };
  };

  const addInfoItem_Average = function (label, valueName) {
    const totalValue = meanBy(snapData, valueName);
    const passValue = meanBy(passSnapData, valueName);
    const runValue = meanBy(runSnapData, valueName);
    return { label, totalValue, passValue, runValue };
  };

  infoItems.push(addInfoItem_Reduce('Total Plays', countAny));
  infoItems.push(addInfoItem_Reduce('Left Hash Snaps', countLeftHash));
  infoItems.push(addInfoItem_Reduce('Left Center Snaps', countLefCenter));
  infoItems.push(addInfoItem_Reduce('Right Center Snaps', countRightCenter));
  infoItems.push(addInfoItem_Reduce('Right Hash Snaps', countRightHash));
  infoItems.push(addInfoItem_Reduce('Routes', countRoute));
  infoItems.push(addInfoItem_Reduce('Targeted', countTarget));
  infoItems.push(addInfoItem_Average('Change in Depth', 'dxRoute'));
  infoItems.push(addInfoItem_Average('Change in Width', 'dyRouteAbs'));
  return infoItems;
};

export { snapDistroInfo, INFO_ITEM_HEADERS };
