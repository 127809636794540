import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import VideoTile from '../../../../components/VideoTile/VideoTile';
import {
  formatHavocVideoData,
  formatSelectedHavocDataForTable,
} from './HavocEvent.dataManipulation';

const HavocEvent = ({ children, havocData, selectedEvent, handleClose }) => {
  // player colours for highlighting
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const { href } = formatSelectedHavocDataForTable(havocData, selectedEvent);

  const selectedEventObj = havocData?.find(
    (evt) => evt.eventUUID === selectedEvent
  );

  // havoc event data reformat for VideoTile
  const selectedAsEvent = {
    ...selectedEventObj,
    ...(selectedEventObj && { play: { id: selectedEventObj?.playUUID } }),
  };

  return (
    <VideoTile
      title={`${selectedEventObj?.playerName || ''} ${
        selectedEventObj?.havocType?.toLowerCase() || ''
      } ${selectedEventObj?.targetName || ''}`}
      subTitle={selectedEventObj?.gameName}
      data={formatHavocVideoData([selectedAsEvent], visPalette)}
      gotoLink={href}
      selectedPlayId={selectedEventObj?.playUUID}
      handleClose={handleClose}
    >
      {children}
    </VideoTile>
  );
};

HavocEvent.propTypes = {
  // info passed to VideoTile
  children: PropTypes.node,
  havocData: PropTypes.arrayOf(
    PropTypes.shape({
      eventUUID: PropTypes.string,
      game_id: PropTypes.number,
      drive_id: PropTypes.string,
      play_id: PropTypes.string,
      havocType: PropTypes.string,
      playName: PropTypes.string,
      driveName: PropTypes.string,
      gameName: PropTypes.string,
      targetPosition: PropTypes.shape({}),
      defenderPosition: PropTypes.shape({}),
      defenderName: PropTypes.string,
      targetName: PropTypes.string,
      snapGapName: PropTypes.string,
      eventGapName: PropTypes.string,
      postSnapSeconds: PropTypes.number,
      xLoS: PropTypes.number,
      snap: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    })
  ),
  selectedEvent: PropTypes.string,
  handleClose: PropTypes.func,
};

HavocEvent.defaultProps = {
  children: null,
  havocData: undefined,
  selectedEvent: null,
  handleClose: () => {},
};

export default HavocEvent;
