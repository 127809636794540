import { gql } from '@apollo/client';

export const GET_NAVIGATION = gql`
  query GetNavigationData($teamId: Int, $competitionId: Int!, $seasonId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")

    navigationData(
      competitionId: $competitionId
      seasonId: $seasonId
      teamId: $teamId
    ) {
      players {
        id
        name
        gamesRostered
        mostCommonNumber
        mostCommonPosition {
          id
          code
          name
          generalPosition
        }
      }
      competitions {
        id
        name
        level
      }
      seasons {
        id
        name
      }
      teams {
        id
        name
        shortName
        colorPrimary
        colorSecondary
        colorTertiary
        gameCount
      }
    }
  }
`;
