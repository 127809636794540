import React from 'react';
import { GameResult } from './TeamSchedule.styles';
import { OutcomeIndicator } from '../../Game/GamePlayByPlay/PlayByPlayAccordion/PlayByPlayAccordionTileBody.styles';

export const getResult = (
  homeTeamId,
  teamId,
  homeScore,
  awayScore,
  gameAvailable,
  visPalette
) => {
  if (!gameAvailable) {
    return <GameResult>-</GameResult>;
  }
  if (homeTeamId === teamId) {
    if (homeScore > awayScore) {
      return (
        <GameResult>
          <OutcomeIndicator
            $size="0.5rem"
            $colour={visPalette.successFail.success.main}
          />
          W
        </GameResult>
      );
    }
    if (homeScore < awayScore) {
      return (
        <GameResult>
          <OutcomeIndicator
            $size="0.5rem"
            $colour={visPalette.successFail.fail.main}
          />
          L
        </GameResult>
      );
    }
    return (
      <GameResult>
        <OutcomeIndicator
          $size="0.5rem"
          $colour={visPalette.successFail.neutral.main}
        />
        D
      </GameResult>
    );
  }
  if (homeScore > awayScore) {
    return (
      <GameResult>
        <OutcomeIndicator
          $size="0.5rem"
          $colour={visPalette.successFail.fail.main}
        />
        L
      </GameResult>
    );
  }
  if (homeScore < awayScore) {
    return (
      <GameResult>
        <OutcomeIndicator
          $size="0.5rem"
          $colour={visPalette.successFail.success.main}
        />
        W
      </GameResult>
    );
  }
  return (
    <GameResult>
      <OutcomeIndicator
        $size="0.5rem"
        $colour={visPalette.successFail.neutral.main}
      />
      D
    </GameResult>
  );
};

const returnScore = (score) => {
  if (typeof score === 'number') {
    return score;
  }
  return '';
};

export const parseScheduleScore = (homeScore, awayScore) =>
  `${returnScore(homeScore)} - ${returnScore(awayScore)}`;
