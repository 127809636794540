/* 
Biometric ranges: https://www.notion.so/statsbomb/Bespoke-Filter-Options-for-Bio-Data-5a2deeb4dec54fae9469e6d1a382d9e0?pvs=4 
*/

import { API_STAT_UNITS } from '../../../../utils/constants/api';

/* Height values are in Inches (ints) 
5th percentile is 69
95th percentile is 78
Range is extended by 1 either side so you can select from 5/95th or "unlimited"
*/
export const HEIGHT_RANGE = {
  MIN: 68,
  MAX: 79,
  MEDIAN: 74,
};

/* Weight values are in pounds
5th percentile is 175
95th percentile is 317
Range is extended by 1 either side so you can select from 5/95th or "unlimited"
*/
export const WEIGHT_RANGE = {
  MIN: 174,
  MAX: 318,
  MEDIAN: 220,
};

/* 
Recruiting 247 is a ranking from 0-100
It's an x+ slider but only sets if the value is greater than 0
*/
export const RECRUITING_247_RANGE = {
  MIN: 75,
  MAX: 100,
  P5: 78,
  MEDIAN: 86,
  P95: 97,
  STEPS: 25,
  UNITS: API_STAT_UNITS.COUNT,
};

/* 
Recruiting Composite is a ranking from 0-1
It's an x+ slider but only sets if the value is greater than the min
*/
export const RECRUITING_COMPOSITE_RANGE = {
  MIN: 0.8,
  MAX: 1,
  P5: 0.8,
  MEDIAN: 0.86,
  P95: 0.96,
  STEPS: 20,
  UNITS: API_STAT_UNITS.RATE,
};

/* 
Recruiting Reranking is a ranking from 0-100
It's an x+ slider but only sets if the value is greater than the min
*/
export const RECRUITING_RERANKING_RANGE = {
  MIN: 80,
  MAX: 100,
  P5: 80,
  MEDIAN: 87,
  P95: 92,
  STEPS: 20,
  UNITS: API_STAT_UNITS.COUNT,
};

/* 
Year slider
It's an x+ slider but only sets if the value is greater than the min
*/
const currentYear = new Date().getFullYear();

export const RECRUITING_YEAR_RANGE = {
  MIN: 2017,
  MAX: currentYear + 1,
  STEPS: currentYear - 2016,
  UNITS: API_STAT_UNITS.COUNT,
};
