import React from 'react';
import { Outlet } from 'react-router-dom';
import { BasicPageNoPadding } from '../../components/Layout/Layout.styles';

const Scout = () => (
  <BasicPageNoPadding $top="52px">
    <Outlet />
  </BasicPageNoPadding>
);

export default Scout;
