import { gql } from '@apollo/client';

export const SEARCH_METADATA_PARAM = gql`
  query SearchMetadata(
    $searchString: String!
    $competitionId: Int!
    $seasonId: Int!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")

    searchMetadata(
      searchString: $searchString
      filters: {
        competitionIds: [$competitionId]
        seasonIds: [$seasonId]
        maxResults: 100
      }
    ) {
      ... on PlayerListPlayer {
        id
        name
        mostCommonPosition {
          id
          code
        }
        team {
          id
          shortName
          competition {
            id
          }
        }
        season {
          id
        }
      }
    }
  }
`;
