import React from 'react';
import { clamp, isFunction } from 'lodash';
import { Button } from '@statsbomb/kitbag-components';

export const realValueToSliderValue = ({
  realValue,
  realMin,
  realMax,
  lowerIsBetter,
  steps,
  isLeftSlider,
}) => {
  /* 
  real value of null means don't filter this end of the slider 
  returning slider value, so lowerIsBetter irrelevant (slider values are always bigger is better)
  */
  if (realValue === null) {
    /* Left slider */
    if (isLeftSlider) {
      return -1;
    }
    /* Right slider */
    return steps + 1;
  }
  const realRange = Math.abs(realMax - realMin);
  const realStep = realRange / steps;

  const valueOffset = lowerIsBetter ? realMax - realValue : realValue - realMin;
  const stepsOffset = realStep !== 0 ? Math.round(valueOffset / realStep) : 0;

  const limitedValue = clamp(stepsOffset, 0, steps);

  return limitedValue;
};

/* 
Takes a slider value (should be an integer) and works out the proportion of the scale this represents
  -> uses the real range to translate into a "real" value
*/
export const sliderValueToRealValue = ({
  sliderValue,
  realMin,
  realMax,
  lowerIsBetter,
  steps,
}) => {
  /* slider value less than 0 or greater than count of steps means ignore (convert to null) */
  if (sliderValue < 0 || sliderValue > steps) {
    return null;
  }

  const realRange = Math.abs(realMax - realMin);
  const realStep = realRange / steps;
  const realOffset = sliderValue * realStep;
  const realValue = lowerIsBetter ? realMax - realOffset : realOffset + realMin;

  return realValue;
};

export const displayValuesSemantically = ({
  values,
  valueStringFormatter,
  onValueClick,
  lowerIsBetter,
  metricName,
}) => {
  if (values[0] === null && values[1] === null) {
    return <span>{`Any ${metricName}`}</span>;
  }
  if (values[0] === null) {
    const formattedValue = valueStringFormatter(values[1]);
    const comparator = lowerIsBetter ? 'At least' : 'At most';
    const valueText = `${comparator} ${formattedValue}`;

    if (isFunction(onValueClick)) {
      return (
        <Button size="tiny" onClick={() => onValueClick(1)} variant="secondary">
          {valueText}
        </Button>
      );
    }
    return <span>{valueText}</span>;
  }
  if (values[1] === null) {
    const formattedValue = valueStringFormatter(values[0]);
    const comparator = lowerIsBetter ? 'At most' : 'At least';
    const valueText = `${comparator} ${formattedValue}`;

    if (isFunction(onValueClick)) {
      return (
        <Button size="tiny" onClick={() => onValueClick(0)} variant="secondary">
          {valueText}
        </Button>
      );
    }
    return <span>{valueText}</span>;
  }
  /* Else it's two value time */
  const formattedValues = values.map(valueStringFormatter);
  if (isFunction(onValueClick)) {
    return (
      <span>
        <Button size="tiny" onClick={() => onValueClick(0)} variant="secondary">
          {formattedValues[0]}
        </Button>
        {' to '}
        <Button size="tiny" onClick={() => onValueClick(1)} variant="secondary">
          {formattedValues[1]}
        </Button>
      </span>
    );
  }
  const valueText = `${formattedValues[0]} to ${formattedValues[1]}`;
  return <span>{valueText}</span>;
};
