import { styled } from 'styled-components';

export const TabButtonLink = styled.a`
  position: relative;
  ${({ theme }) => theme.typography.headingRegular}
  padding: 0.5rem 1rem;
  transition: opacity 300ms ease-out;
  opacity: 0.8;

  &.active {
    border-bottom: ${({ theme }) =>
      `2px solid ${theme.colours.interface.main}`};
    opacity: 1;
  }

  &:hover {
    color: ${({ theme }) => theme.colours.ink.primary.main};
    opacity: 1;
  }
`;

export const TabButtonGroup = styled.div`
  display: flex;
`;
