import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
  query GetTeams($competitionId: Int!, $seasonId: Int!) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")

    teams(
      first: 400
      filters: { competitionId: $competitionId, seasonId: $seasonId }
    ) {
      edges {
        node {
          id
          name
          shortName
          colorPrimary
          colorSecondary
          colorTertiary
          gameCount
        }
      }
    }
  }
`;
