import { API_STAT_UNITS } from '../../../../utils/constants/api';

const { COUNT, YARDS, PERCENTAGE, SHORT_DECIMAL, STRING } = API_STAT_UNITS;

const TACKLER_TABLE_HEADERS = [
  { label: 'Player', key: 'playerName', units: STRING, isSortable: true },
  {
    label: 'Position',
    description:
      'The position the player was most often in for tackling plays.' +
      '\nHover to see tackle attempt plays per position.',
    key: 'primaryPositionCode',
    units: STRING,
    isSortable: true,
  },
  { label: 'Plays', key: 'plays', units: COUNT, isSortable: true },
  {
    label: 'Tackle Att',
    description: 'Tackle Attempts',
    key: 'allAttempts',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Solo Att',
    description: 'Solo Attempts',
    key: 'soloAttempts',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Assist Att',
    description: 'Assist Attempts',
    key: 'assistAttempts',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Tot Tack',
    description: 'Total Tackles',
    key: 'allTackles',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'True Tack',
    description: 'True Tackles',
    key: 'trueTackles',
    units: SHORT_DECIMAL,
    isSortable: true,
  },
  {
    label: 'Solo Tack',
    description: 'Solo Tackles',
    key: 'soloTackles',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Assist Tack',
    description: 'Assist Tackles',
    key: 'assistTackles',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Tack%',
    description: 'Tackle%',
    key: 'tacklePerc',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'True Tack%',
    description: 'True Tackle%',
    key: 'trueTacklePerc',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'STS%',
    description: 'Solo Tackle Success%',
    key: 'soloTackleSuccessRate',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Stop%',
    description: 'Stop%',
    key: 'stopRate',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'DoTA',
    description: 'Depth of Tackle Attempt',
    key: 'depthOfTackleAttempt',
    units: YARDS,
    isSortable: true,
  },
  {
    label: 'DoTk',
    description: 'Depth of Tackle',
    key: 'depthOfTackle',
    units: YARDS,
    isSortable: true,
  },
  {
    label: 'YaTA',
    description: 'Yards after Tackle Attempt',
    key: 'yardsAfterTackleAttempt',
    units: YARDS,
    isSortable: true,
  },
];

const BALL_CARRIER_TABLE_HEADERS = [
  { label: 'Player', key: 'playerName', units: STRING, isSortable: true },
  {
    label: 'Position',
    description:
      'The position the player was most often in for tackling plays.' +
      '\nHover to see tackle attempt plays per position.',
    key: 'primaryPositionCode',
    units: STRING,
    isSortable: true,
  },
  { label: 'Touches', key: 'touches', units: COUNT, isSortable: true },
  { label: 'Yards', key: 'yardsGained', units: YARDS, isSortable: true },
  {
    label: 'TD',
    description: 'Touchdowns',
    key: 'touchdowns',
    units: COUNT,
    isSortable: true,
  },
  { label: 'Chunks', key: 'chunks', units: COUNT, isSortable: true },
  {
    label: 'Explosives',
    key: 'explosives',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Solo Att',
    description: 'Solo Attempts',
    key: 'soloAttempts',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Assist Att',
    description: 'Assist Attempts',
    key: 'assistAttempts',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Solo Tack',
    description: 'Solo Tackles',
    key: 'soloTackles',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Assist Tack',
    description: 'Assist Tackles',
    key: 'assistTackles',
    units: COUNT,
    isSortable: true,
  },
  {
    label: 'Yards/Att',
    description: 'Yards per Attempt',
    key: 'yardsPerAttempt',
    units: YARDS,
    isSortable: true,
  },
  {
    label: 'YBCt/Att',
    description: 'Yards Before Contact per Attempt',
    key: 'yardsBeforeTackleAttempt',
    units: YARDS,
    isSortable: true,
  },
  {
    label: 'YACt/Att',
    description: 'Yards After Contact per Attempt',
    key: 'yardsAfterTackleAttempt',
    units: YARDS,
    isSortable: true,
  },
  {
    label: 'STS%',
    description: 'Solo Tackle Success%',
    key: 'soloTackleSuccessRate',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Stop%',
    description: 'Stop%',
    key: 'stopRate',
    units: PERCENTAGE,
    isSortable: true,
  },
  {
    label: 'Broken Tk%',
    description: 'Broken Tackle%',
    key: 'brokenTackleRate',
    units: PERCENTAGE,
    isSortable: true,
  },
];

export const getTacklingPlayerTableHeaders = (showDefensive) => {
  const columnHeaders = showDefensive
    ? TACKLER_TABLE_HEADERS
    : BALL_CARRIER_TABLE_HEADERS;
  const defaultColumnKey = columnHeaders[2].key; // player name and position show in both modes
  return { columnHeaders, defaultColumnKey };
};
