import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { mf_LeagueLevel, ui_isDark } from '../../apollo';
import {
  FORMATION_SUMMARY_KEY,
  BASE_VIS_WIDTH,
  SCALE_BOUNDS,
} from './FormationSummaryChart.constants';
import {
  ColorScaleHeader,
  ColorScaleLabel,
  StyledKey,
} from './FormationSummaryChart.styles';
import { csValue } from '../../utils/helpers/colorScales';
import { useIsNcaa } from '../../utils/hooks/useIsNcaa';

const { HEIGHT, HEADER, CIRCLES, LABELS } = FORMATION_SUMMARY_KEY;

const FormationSummaryChartKey = () => {
  const isNcaa = useIsNcaa();
  const leagueLevel = useReactiveVar(mf_LeagueLevel);

  const worst = isNcaa ? SCALE_BOUNDS.NCAA.WORST : SCALE_BOUNDS.NFL.WORST;
  const best = isNcaa ? SCALE_BOUNDS.NCAA.BEST : SCALE_BOUNDS.NFL.BEST;

  const margin = { top: 0, right: 0, bottom: 0, left: 0 };

  const baseWidth = BASE_VIS_WIDTH;
  const baseHeight = HEIGHT;
  const width = margin.left + margin.right + baseWidth;
  const height = margin.top + margin.bottom + baseHeight;
  const viewBox = `0 0 ${width} ${height}`;

  const isDark = useReactiveVar(ui_isDark);

  const circles = new Array(CIRCLES.AMOUNT)
    .fill()
    .map((_, i) => csValue(i * CIRCLES.STEP, isDark));

  return (
    <StyledKey viewBox={viewBox}>
      <ColorScaleHeader y={HEADER.Y_OFFSET}>Play Success Rate</ColorScaleHeader>
      <g
        transform={`
          translate(${CIRCLES.GROUP_X_OFFSET}, ${CIRCLES.GROUP_Y_OFFSET})
        `}
      >
        {circles.map((c, i) => (
          <circle
            key={c}
            cx={i * CIRCLES.X}
            cy={CIRCLES.Y}
            r={CIRCLES.RADIUS}
            fill={c}
            strokeWidth={0}
          />
        ))}
      </g>
      <g transform={`translate(0, ${LABELS.GROUP_Y_OFFSET})`}>
        <ColorScaleLabel x={0} y={LABELS.Y_OFFSET}>
          <title>{`${leagueLevel} lower bound: ${Math.round(
            worst * 100
          )}%`}</title>
          Worst
        </ColorScaleLabel>
        <ColorScaleLabel
          x={LABELS.MAX_LABEL_X_OFFSET}
          y={LABELS.Y_OFFSET}
          textAnchor="end"
        >
          <title>{`${leagueLevel} higher bound: ${Math.round(
            best * 100
          )}%`}</title>
          Best
        </ColorScaleLabel>
      </g>
    </StyledKey>
  );
};

export default FormationSummaryChartKey;
