import { styled } from 'styled-components';

export const StyledTimeline = styled.div`
  display: flex;
  align-items: center;

  .controls {
    padding: 0 10px 5px 25px;

    button {
      width: 7rem;
    }
  }

  .clickable {
    cursor: ${({ $isInteractive }) => $isInteractive && 'pointer'};
  }

  > svg {
    width: ${({ $isInteractive }) => ($isInteractive ? '100%' : '1067px')};
    height: 6rem;

    &.disable-transition {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
  }
`;
