import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonIcon,
  Checkbox,
  ConfirmationDialog,
  Icon,
} from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconButtonWrapper } from '../../../components/buttons/Playlist/Playlist.styles';
import TreeSearch from '../../../components/TreeSearch/TreeSearch';
import { FilterSelectedContent } from '../PlayFinderFilters/PlayFinderFilters.style';
import {
  PlayFinderColumnStyle,
  PlayFinderColumnsLayout,
  PlayFinderColumnsDragHeader,
} from './PlayFinderColumns.styles';
import { updateFilterById } from '../PlayFinderFilters/PlayFinderFilters.dataManipulation';
import { PLAY_FINDER_COLUMNS } from '../PlayFinderResults/PlayFinderResults.constants';
import { PLAY_FINDER_COLUMNS_BLACKLIST } from './PlayFinderColumns.constants';

// PlaysFinderColumns modal to edit the columns of the results table
const PlayFinderColumns = ({
  isDisabled,
  playFinderFilterTree,
  selectedColumns,
  handleSortedColumns,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterTree, setFilterTree] = useState([]);
  const [sortedColumns, setSortedColumns] = useState([]);
  const excludeColumns = [
    ...PLAY_FINDER_COLUMNS.map((col) => col.key),
    ...PLAY_FINDER_COLUMNS_BLACKLIST,
  ];

  useEffect(() => {
    setSortedColumns(selectedColumns);
  }, [selectedColumns]);

  useEffect(() => {
    setFilterTree(playFinderFilterTree);
  }, [playFinderFilterTree]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedColumns = Array.from(sortedColumns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    setSortedColumns(reorderedColumns);
  };

  const handleColumnCheck = (item) => {
    const value =
      typeof item.widgetArgs.selected === 'undefined'
        ? true
        : !item.widgetArgs.selected;
    setFilterTree(updateFilterById(filterTree, item.filterId, value));
    if (value) {
      const tempColumn = {
        id: item.columnId,
        key: item.columnId,
        label: item.abbrev,
        isSortable: false,
        description: item.label,
        units: item.units,
        filterId: item.filterId,
      };
      setSortedColumns([...sortedColumns, tempColumn]);
    } else {
      setSortedColumns(
        sortedColumns.filter((column) => column.key !== item.columnId)
      );
    }
  };

  // Tree of filters with selected attribute
  // eslint-disable-next-line react/prop-types
  const FormattedFilters = ({ data }) => {
    const renderFilter = (items, level) =>
      items?.map((item, index) => (
        <div
          className={classNames('headers', { section: level === 1 })}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.name}-${index}`}
        >
          {level === 1 && <h3>{item.label}</h3>}
          {level === 2 && <h4>{item.label}</h4>}
          {level === 3 && !excludeColumns.includes(item.columnId) && (
            <div className="inputCheck">
              <Checkbox
                id={item.label}
                label={item.label}
                checked={item.widgetArgs.selected}
                size="small"
                onChange={() => handleColumnCheck(item)}
              />
            </div>
          )}
          {item.children && renderFilter(item.children, level + 1)}
        </div>
      ));

    return <>{renderFilter(data, 1)}</>;
  };

  return (
    <>
      <IconButtonWrapper>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          size="small"
          title="Edit results table columns"
          variant="secondary"
          isDisabled={isDisabled}
        >
          <span>
            <Icon variant="Filter" size="small" />
          </span>
          Edit Columns
        </Button>
      </IconButtonWrapper>

      <ConfirmationDialog
        id="playFinderColumnsDialog"
        title="Edit Columns"
        isOpen={isOpen}
        confirmLabel="Apply"
        cancelLabel="Cancel"
        onConfirm={() => {
          handleSortedColumns(sortedColumns);
          setIsOpen(false);
        }}
        onCancel={() => setIsOpen(false)}
      >
        <PlayFinderColumnsLayout>
          <div className="row">
            <div>
              {playFinderFilterTree && (
                <TreeSearch
                  initialTree={playFinderFilterTree}
                  onFilter={setFilterTree}
                  initialQuery=" "
                />
              )}
              <br />
              <FilterSelectedContent $margin="0 0 1rem 0">
                <FormattedFilters data={filterTree} />
              </FilterSelectedContent>
            </div>
            <div>
              <PlayFinderColumnsDragHeader>
                <h3>Selected Columns</h3>
              </PlayFinderColumnsDragHeader>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(dropProvided) => (
                      <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                      >
                        {sortedColumns.map((column, index) => (
                          <Draggable
                            key={column.key}
                            draggableId={column.key}
                            index={index}
                          >
                            {(dragProvided) => (
                              <PlayFinderColumnStyle
                                ref={dragProvided.innerRef}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...dragProvided.draggableProps}
                              >
                                <span
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...dragProvided.dragHandleProps}
                                >
                                  <Icon variant="DragHandle" size="small" />
                                </span>
                                <span>{column.label}</span>
                                {!PLAY_FINDER_COLUMNS.some(
                                  (item) => item.key === column.key
                                ) && (
                                  <span>
                                    <ButtonIcon
                                      icon="Close"
                                      variant="ghost"
                                      size="small"
                                      onClick={() => {
                                        setSortedColumns(
                                          sortedColumns.filter(
                                            (item) => item.key !== column.key
                                          )
                                        );

                                        setFilterTree(
                                          updateFilterById(
                                            filterTree,
                                            column.filterId,
                                            false
                                          )
                                        );
                                      }}
                                    />
                                  </span>
                                )}
                              </PlayFinderColumnStyle>
                            )}
                          </Draggable>
                        ))}
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </PlayFinderColumnsLayout>
      </ConfirmationDialog>
    </>
  );
};

PlayFinderColumns.propTypes = {
  isDisabled: PropTypes.bool,
  playFinderFilterTree: PropTypes.arrayOf(PropTypes.object),
  selectedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSortedColumns: PropTypes.func,
};

PlayFinderColumns.defaultProps = {
  isDisabled: false,
  playFinderFilterTree: [],
  handleSortedColumns: () => {},
};

export default PlayFinderColumns;
