import { csIntensityBlue } from '../../../utils/helpers/colorScales';
import { FORMATION_SUMMARY_STAT_NAMES } from './TeamFormationSummary.constants';

const getCsvDataByFormation = (data) => {
  const formattedData = data.map((formation) => {
    const stats = Object.values(formation).map((stat) => stat.title);

    return stats;
  });

  return formattedData;
};

const getCsvDataByPersonnel = (data) => {
  const formattedData = data.flatMap((table) => {
    const offensePersonnel = table.personnel;

    const formattedRows = table.rows.map((formation) => {
      const stats = FORMATION_SUMMARY_STAT_NAMES.map(
        (s) => formation[s]?.value
      );

      return [offensePersonnel, ...stats];
    });

    return formattedRows;
  });

  return formattedData;
};

export const getFormationSummaryCsvData = (data) => {
  if (!data) {
    return [];
  }

  const isPersonnelMode = data.some((d) => d.rows);
  const personnelHeader = isPersonnelMode ? ['offensePersonnel'] : [];
  const headers = [...personnelHeader, ...FORMATION_SUMMARY_STAT_NAMES];

  const tableData = isPersonnelMode
    ? getCsvDataByPersonnel(data)
    : getCsvDataByFormation(data);

  return [headers, ...tableData];
};

export const reversedCsIntensityBlue = (value, isDarkTheme) =>
  csIntensityBlue(1 - value, isDarkTheme);

export const getRowsToRender = (rows, visibility) => {
  if (!visibility || visibility === 'closed') {
    return rows.slice(0, 1);
  }

  if (visibility === 'partial') {
    return rows.slice(0, 7);
  }

  return rows;
};
