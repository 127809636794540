import { styled } from 'styled-components';

export const AccordionHeader = styled.button`
  width: 100%;
  position: relative;
  text-align: left;
  padding: 0px;
  height: 3rem;
  font-size: 0.75rem;

  &:focus {
    outline: none;
  }

  .ui.grid {
    margin-top: 0px;

    .row {
      padding: 0px;
    }
  }

  & > svg {
    position: absolute;
    top: 0.78125rem;
    right: 0.78125rem;

    transition: transform 0.3s ease-in-out;
    ${({ $isExpanded }) => $isExpanded && `transform: rotate(180deg)`};
  }
`;

export const AccordionBody = styled.div`
  width: 100%;
  transition: all 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  overflow: hidden;

  ${({ $isExpanded }) => `
    min-height: ${$isExpanded ? '40px' : '0'};
    max-height: ${$isExpanded ? '2500px' : '0'};
  `}

  > div > div {
    box-shadow: none;
    margin: 0;
  }
`;

AccordionHeader.displayName = 'AccordionHeader';
AccordionBody.displayName = 'AccordionBody';
