import { scaleLinear, area, line, curveCatmullRom } from 'd3';
import { clamp, maxBy, minBy, sortBy } from 'lodash';

/*
    This function draws the path for an area representing a set of points as a smoothed curve
        e.g. a histogram/distribution 
    It expects the data to be supplied as an array of objects with the keys bucket and frequency
    Both values should be normalized on a 0-1 scale. 
    
    The shape can be used as part of a clip-path, or as a rendered area (depending on the parent svgElement)

    By nomenclature it expects the x axis to be buckets and y frequency, though the function can also draw vertically
        Thus "width" refers to the displayed length in px of the bucket axis
            , and "height" to the size of the frequency axis
        Default draws frequency upwards (-y) in horizontal mode, or left to right (+x) in vertical mode. 
        Both can be mirrored
*/
export const drawDistributionArea = (
  svgElement,
  curveData,
  width,
  height,
  reverseX = false,
  isVertical = true,
  drawMirrored = false,
  coloring = null
) => {
  const bucketRange = reverseX ? [width, 0] : [0, width];
  const bucketScale = scaleLinear()
    .domain([0, 1])
    .range(bucketRange)
    .clamp(true);
  const freqMax = drawMirrored === isVertical ? -height : height;
  const frequencyScale = scaleLinear()
    .domain([0, 1])
    .range([0, freqMax])
    .clamp(true);

  /* Coloring irrelevant to clip-paths but for solid areas */
  const fill = coloring?.fill || '#fff';
  const stroke = coloring?.stroke || 'none';
  const strokeWidth = coloring?.strokeWidth || 0;

  if (isVertical) {
    svgElement
      .append('path')
      .datum(curveData)
      .style('stroke', stroke)
      .style('stroke-width', strokeWidth)
      .style('fill', fill)
      .attr(
        'd',
        area()
          .x0(0)
          .x1((d) => frequencyScale(d.frequency))
          .y((d) => bucketScale(d.bucket))
          .curve(curveCatmullRom)
      );
  } else {
    svgElement
      .append('path')
      .datum(curveData)
      .style('stroke', stroke)
      .style('stroke-width', strokeWidth)
      .style('fill', fill)
      .attr(
        'd',
        area()
          .y0(0)
          .y1((d) => frequencyScale(d.frequency))
          .x((d) => bucketScale(d.bucket))
          .curve(curveCatmullRom)
      );
  }
};

/*
    This function draws the path for a line representing a set of points as a smoothed curve
        e.g. a histogram/distribution 
    It expects the data to be supplied as an array of objects with the keys bucket and frequency
    Both values should be normalized on a 0-1 scale. 
    
    By nomenclature it expects the x axis to be buckets and y frequency, though the function can also draw vertically
        Thus "width" refers to the displayed length in px of the bucket axis
            , and "height" to the size of the frequency axis
        Default draws frequency upwards (-y) in horizontal mode, or left to right (+x) in vertical mode. 
        Both can be mirrored
*/
export const drawDistributionLine = (
  svgElement,
  curveData,
  width,
  height,
  reverseX = false,
  isVertical = true,
  drawMirrored = false,
  lineStyle = null
) => {
  const stroke = lineStyle?.stroke || '#fff';
  const strokeWidth = lineStyle?.strokeWidth || 1;
  const strokeDashArray = lineStyle?.strokeDashArray || 'none';

  const bucketRange = reverseX ? [width, 0] : [0, width];
  const bucketScale = scaleLinear()
    .domain([0, 1])
    .range(bucketRange)
    .clamp(true);
  const freqMax = drawMirrored === isVertical ? -height : height;
  const frequencyScale = scaleLinear()
    .domain([0, 1])
    .range([0, freqMax])
    .clamp(true);

  const pathLocations = curveData.map((d) => {
    const pair = isVertical
      ? [frequencyScale(d.frequency), bucketScale(d.bucket)]
      : [bucketScale(d.bucket), frequencyScale(d.frequency)];
    return pair;
  });

  svgElement
    .append('path')
    .style('stroke', stroke)
    .style('stroke-width', strokeWidth)
    .style('stroke-dasharray', strokeDashArray)
    .style('fill', 'transparent')
    .attr('d', line().curve(curveCatmullRom)(pathLocations));
};

/* 
Take a data set and normalizes the values for the drawDistribution functions
If you want to ignore certain outliers, you can manually override the min/max values
    By default they will be the limits of the data
*/
export const getCurveData = (
  data,
  bucketMetricName,
  frequencyMetricName,
  limitOverrides
) => {
  const minFreq =
    limitOverrides?.minFreq ||
    minBy(data, frequencyMetricName)[frequencyMetricName];
  const maxFreq =
    limitOverrides?.maxFreq ||
    maxBy(data, frequencyMetricName)[frequencyMetricName];
  const minBucket =
    limitOverrides?.minBucket ||
    minBy(data, bucketMetricName)[bucketMetricName];
  const maxBucket =
    limitOverrides?.maxBucket ||
    maxBy(data, bucketMetricName)[bucketMetricName];

  /* Safety valve denominators */
  const freqDenominator = maxFreq !== minFreq ? maxFreq - minFreq : 1;
  const bucketDenominator = maxBucket !== minBucket ? maxBucket - minBucket : 1;
  const normalizeFrequency = (value) =>
    (clamp(value, minFreq, maxFreq) - minFreq) / freqDenominator;
  const normalizeBucket = (value) =>
    (clamp(value, minBucket, maxBucket) - minBucket) / bucketDenominator;

  const curveData = data.map((datum) => {
    const frequency = normalizeFrequency(datum[frequencyMetricName]);
    const bucket = normalizeBucket(datum[bucketMetricName]);
    return { frequency, bucket };
  });

  return curveData;
};

/* Converts any other data labels into "bucket" and "frequency" */
export const formatDataForCurve = (
  data,
  bucketMetricName,
  frequencyMetricName
) => {
  const curveData = data.map((datum) => {
    const frequency = datum[frequencyMetricName];
    const bucket = datum[bucketMetricName];
    return { frequency, bucket };
  });

  return sortBy(curveData, 'bucket');
};
