import React from 'react';
import PropTypes from 'prop-types';
import { BarIndicator } from './BarIndicator';

// Draws a bar in an svg/chart
export const Bar = ({
  barX,
  barY,
  color,
  value,
  barWidth,
  barHeight,
  maxDomain,
}) => {
  const isNegative = value < 0;
  const absoluteValue = Math.abs(value);

  return (
    <g>
      <rect x={barX} y={barY} width={barWidth} height={barHeight} fill={color}>
        <title>{value}</title>
      </rect>
      {absoluteValue > maxDomain && (
        <BarIndicator
          barY={barY}
          barWidth={barX + barWidth}
          barHeight={barHeight}
          isNegative={isNegative}
          fill={color}
        />
      )}
    </g>
  );
};

Bar.propTypes = {
  // x position of the bar
  barX: PropTypes.number.isRequired,
  // y position of bar
  barY: PropTypes.number.isRequired,
  // color of the bar
  color: PropTypes.string.isRequired,
  // the value the bar represents
  value: PropTypes.number.isRequired,
  // the width of the bar
  barWidth: PropTypes.number.isRequired,
  // the height of the bar
  barHeight: PropTypes.number.isRequired,
  // prevents the bar from exceeding the scale it is displayed in
  maxDomain: PropTypes.number.isRequired,
};

export default Bar;
