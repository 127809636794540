/* eslint-disable react/no-array-index-key */
import React from 'react';
import { getFormattedMarkdown } from './ReleaseNotes.helper';
import { releaseNotesText } from './ReleaseNotes.text';
import { StyledReleaseNotes } from './ReleaseNotes.styles';
import Tile from '../../components/Tile/Tile';

/**
 * ReleaseNotes
 * Release notes page - takes a stringified markdown file and styles it.
 */
const ReleaseNotes = () => {
  const formattedMarkdownArray = getFormattedMarkdown(releaseNotesText);

  const h2Elements = formattedMarkdownArray.filter(
    ({ component }) => component === 'h2'
  );

  return (
    <StyledReleaseNotes>
      <h1>Release Notes</h1>
      <Tile.Body>
        {h2Elements.map((h2Element, h2Index) => {
          const h3Elements = formattedMarkdownArray.filter(
            ({ component, parent }) =>
              component === 'h3' && parent === h2Element.id
          );

          return (
            <section key={`filtered-h2-element-${h2Index}`}>
              <h2>{h2Element.text}</h2>
              {h3Elements.map((h3Element, h3Index) => {
                const filteredListElements = formattedMarkdownArray.filter(
                  ({ component, parent }) =>
                    component === 'li' && parent === h3Element.id
                );
                return (
                  <section key={`filtered-h3-element-${h3Index}`}>
                    <h3>{h3Element.text}</h3>
                    <ul>
                      {filteredListElements.map(
                        (filteredListElement, liIndex) => (
                          <li key={`filtered-list-element-${liIndex}`}>
                            {filteredListElement.text}
                          </li>
                        )
                      )}
                    </ul>
                  </section>
                );
              })}
            </section>
          );
        })}
      </Tile.Body>
    </StyledReleaseNotes>
  );
};

export default ReleaseNotes;
