import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, ButtonIcon } from '@statsbomb/kitbag-components';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
  DepthChartDrawerHeader,
  DepthChartDrawerContent,
  DepthChartDrawerContentExport,
} from './DepthChartDrawer.styles';
import PositionIcon from '../../../components/PositionIcon/PositionIcon';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';
import {
  ROSTER_POSITION_KEYS,
  ALIGNMENT_POSITION_KEYS,
} from './TeamDepthChart.constants';
import ExportModal from '../../../components/ExportModal/ExportModal';
import DepthChartRadar from './DepthChartRadar';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { mf_TeamDetails } from '../../../apollo';
import { radarTemplatePropTypes } from '../../../visualisations/RadarChart/RadarChart.PropTypes';

const DepthChartDrawer = ({
  isOpen,
  positionApiKey,
  onBackdropClick,
  radarsData,
  drawerFeedback,
  teamTotalSnaps,
  templateConfig,
  exportTitle,
  exportSubHeading,
  exportInfo1,
  exportInfo2,
  exportFileName,
}) => {
  // global state
  const { leagues, seasons, teams } = useParams();
  const selectedTeam = useReactiveVar(mf_TeamDetails);
  const teamColors = {
    primary: selectedTeam?.colorPrimary,
    secondary: selectedTeam?.colorSecondary,
    tertiary: selectedTeam?.colorTertiary,
  };

  const rosterPosition = ROSTER_POSITION_KEYS.includes(positionApiKey)
    ? positionApiKey
    : null;
  const alignmentPosition = ALIGNMENT_POSITION_KEYS.includes(positionApiKey)
    ? positionApiKey
    : null;

  return (
    <Drawer isOpen={isOpen} onBackdropClick={onBackdropClick}>
      <DepthChartDrawerHeader>
        <h3>
          <PositionIcon
            rosterPosition={rosterPosition}
            alignmentPosition={alignmentPosition}
          />
          <span>{ALIGNMENT_POSITIONS[positionApiKey]?.name || ''}</span>
        </h3>
        <ExportModal
          title={exportTitle}
          secondaryTitle={exportSubHeading}
          info1={exportInfo1}
          info2={exportInfo2}
          customWidth={1125}
          fileName={exportFileName}
          isDisabled={false}
        >
          <DepthChartDrawerContentExport>
            {radarsData &&
              radarsData?.map((player) => {
                const playerId = player.groups[0].value.toString();
                const snapRate = player.plays / teamTotalSnaps;

                return (
                  <DepthChartRadar
                    key={playerId}
                    id={`export-${playerId}`}
                    name={player.playerName}
                    jerseyNumber={player.jerseyNumber}
                    snapCount={player.plays}
                    snapRate={snapRate}
                    teamColors={teamColors}
                    templateConfig={templateConfig}
                    playerStats={player}
                  />
                );
              })}
          </DepthChartDrawerContentExport>
        </ExportModal>
        <ButtonIcon
          variant="ghost"
          onClick={onBackdropClick}
          size="small"
          icon="Close"
          id="close-drawer-button"
          title="close depth chart drawer"
        >
          close depth chart drawer
        </ButtonIcon>
      </DepthChartDrawerHeader>
      <DepthChartDrawerContent>
        {drawerFeedback && (
          <Dimmer active inverted>
            {drawerFeedback}
          </Dimmer>
        )}
        {radarsData &&
          radarsData?.map((player) => {
            const playerId = player.groups[0].value.toString();
            const link = `/player/radar/${leagues}/${seasons}/${teams}/${playerId}`;
            const snapRate = player.plays / teamTotalSnaps;

            return (
              <DepthChartRadar
                key={playerId}
                id={playerId}
                name={player.playerName}
                jerseyNumber={player.jerseyNumber}
                snapCount={player.plays}
                snapRate={snapRate}
                link={link}
                teamColors={teamColors}
                templateConfig={templateConfig}
                playerStats={player}
              />
            );
          })}
      </DepthChartDrawerContent>
    </Drawer>
  );
};

DepthChartDrawer.propTypes = {
  /** determines whether to display the panel or not */
  isOpen: PropTypes.bool,
  /** function that is called when the backdrop is clicked */
  onBackdropClick: PropTypes.func,
  /** which player position is being compared within the drawer */
  positionApiKey: PropTypes.string.isRequired,
  // radar data
  radarsData: PropTypes.arrayOf(PropTypes.object),
  // feedback string for non-qualifying data sets
  drawerFeedback: PropTypes.string,
  // team total snaps
  teamTotalSnaps: PropTypes.number,
  // radar template config
  templateConfig: radarTemplatePropTypes,
  // export heading for position radars
  exportTitle: PropTypes.string,
  // export sub-heading for position radars
  exportSubHeading: PropTypes.string,
  // export info1: the season
  exportInfo1: PropTypes.string,
  // export info2: are the radars by alignment or roster?
  exportInfo2: PropTypes.string,
  // export filename for position radars
  exportFileName: PropTypes.string,
};

DepthChartDrawer.defaultProps = {
  isOpen: false,
  onBackdropClick: null,
  radarsData: [],
  drawerFeedback: '',
  teamTotalSnaps: 0,
  templateConfig: {},
  exportTitle: '',
  exportSubHeading: '',
  exportInfo1: '',
  exportInfo2: '',
  exportFileName: '',
};

export default DepthChartDrawer;
