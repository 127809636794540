import React from 'react';
import PropTypes from 'prop-types';
import {
  SliderHeader,
  SliderSection,
} from '../MetricFilters/MetricFilters.styles';
import ReversibleSlider from '../ReversibleSlider/ReversibleSlider';
import { playerMetricProp } from '../../Scout.proptypes';
import {
  PLAYS_RANGE,
  PLAYS_STAT_KEY,
} from '../ScoutPlayers/ScoutPlayers.constants';

const PlaysSlider = ({ selectedMetrics, onSliderChange, removeMetric }) => {
  const backupPlaysMetric = { ...PLAYS_RANGE, value: PLAYS_RANGE.min };
  const existingPlaysMetric = selectedMetrics?.find(
    (m) => m.key === PLAYS_STAT_KEY
  );
  const playsMetric = existingPlaysMetric || backupPlaysMetric;

  const headerTooltip = `${playsMetric?.name}\n${playsMetric?.description}`;
  const formatter = (value) => value?.toFixed(0);

  const onPlaysSliderChage = (val) => {
    if (existingPlaysMetric && val === PLAYS_RANGE.min) {
      removeMetric(playsMetric);
    }
    onSliderChange(playsMetric, val);
  };
  return (
    <SliderSection>
      <SliderHeader>
        <h3 title={headerTooltip}>{playsMetric.abbreviation}</h3>
      </SliderHeader>
      <ReversibleSlider
        min={playsMetric.min}
        max={playsMetric.max}
        value={playsMetric.value}
        onValueChanged={onPlaysSliderChage}
        lowerIsBetter={playsMetric.lowerIsBetter}
        formatter={formatter}
        steps={PLAYS_RANGE.steps}
      />
    </SliderSection>
  );
};

PlaysSlider.propTypes = {
  // metrics shape with added elements for filtering
  selectedMetrics: PropTypes.arrayOf(playerMetricProp).isRequired,
  // what to do on slider change
  onSliderChange: PropTypes.func.isRequired,
  // what to do when slider deleted
  removeMetric: PropTypes.func.isRequired,
};

export default PlaysSlider;
