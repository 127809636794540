import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';

export const ROUTE_TREE_CLASSES = {
  BACKGROUND: 'rt-background',
  MAIN: 'rt-main',
  FIELD_HOLDER: 'rt-field-holder',
  FIELD_GUIDES: 'rt-field-guides',
  TREE: 'rt-tree',
  TREE_TRUNK: 'rt-tree-trunk',
  TREE_BRANCHES: 'rt-tree-branch',
  TREE_FRUIT: 'rt-tree-fruit',
  TREE_INFO: 'rt-tree-info',
};

export const ROUTE_TREE_FIELD_OVERRIDES = {
  ...DEFAULT_FIELD_DRAWING_SETTINGS,
  Y_BORDERS: 0,
  X_YARDS: 70,
  Y_YARDS: 70,
  LOS_X: 20,
  hideYGuides: true,
};

/* Route Origin relative to LoS */
export const ROUTE_TREE_BRANCH_NODE = {
  ORIGIN: -7,
  LOW: 10,
  HIGH: 30,
};
export const ROUTE_TREE_BRANCH_DIRECTION = {
  IN: 'turns in towards passer',
  OUT: 'turns out from passer',
  UP: 'goes straight',
};
/* 
Angle from line origin to end point 
    Described in degrees but held in radians
0 is +X, and 90degrees is +y (with in = right, in routes have a positive y)
*/
const radians = (degrees) => (degrees * Math.PI) / 180;
const ROUTE_TREE_BRANCH_ANGLE = {
  STRAIGHT_UP: 0,
  UP_DIAGONAL: radians(45),
  UP_SHALLOW: radians(65),
  UP_NUDGE: radians(72),
  FLAT: radians(90),
  DOWN_NUDGE: radians(90),
  DOWN_DIAGONAL: radians(135),
};
/* Radius size in yds */
const ROUTE_TREE_BRANCH_LENGTH = {
  SHORT: 4,
  REGULAR: 9,
  LONG: 20,
  SUPER_LONG: 28,
};
const ROUTE_TREE_BRANCH_CURVE = {
  STRAIGHT: 'straight',
  CURVED: 'curved',
};

export const ROUTE_TYPE_CONFIG_CLASSIFIED = {
  go: {
    apiKey: 'go',
    name: 'Go',
    description:
      'Vertical route in which the receiver approximately maintains the same horizontal location throughout the play',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.UP,
    angle: ROUTE_TREE_BRANCH_ANGLE.STRAIGHT_UP,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  comeback: {
    apiKey: 'comeback',
    name: 'Comeback',
    description:
      'Stop route in which the receiver stops and turns towards the sideline > 8 yards from the LOS',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
    angle: ROUTE_TREE_BRANCH_ANGLE.DOWN_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.SHORT,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  corner: {
    apiKey: 'corner',
    name: 'Corner',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks outward towards the sideline at an approximately 45 degree angle',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
    angle: ROUTE_TREE_BRANCH_ANGLE.UP_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  curl: {
    apiKey: 'curl',
    name: 'Curl',
    description:
      'Stop route in which the receiver stops and turns towards the field > 8 yards from the LOS',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.DOWN_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.SHORT,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  deep_out: {
    apiKey: 'deep_out',
    name: 'Deep Out',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks outward > 10 yards from the LOS at an approximately flat angle',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
    angle: ROUTE_TREE_BRANCH_ANGLE.FLAT,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  dig: {
    apiKey: 'dig',
    name: 'Dig',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks inward > 10 yards from the LOS at an approximately flat angle',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.FLAT,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  drag: {
    apiKey: 'drag',
    name: 'Drag',
    description:
      'Curved crossing route in which the receiver crosses the horizontal snap location < 6 yards from the LOS',
    node: ROUTE_TREE_BRANCH_NODE.ORIGIN,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: radians(64),
    length: 24,
    curve: ROUTE_TREE_BRANCH_CURVE.CURVED,
  },
  flat: {
    apiKey: 'flat',
    name: 'Flat',
    description:
      'Curved out-breaking route in which the receiver immediately begins arching towards the sideline',
    node: ROUTE_TREE_BRANCH_NODE.ORIGIN,
    direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
    angle: radians(65),
    length: 20,
    curve: ROUTE_TREE_BRANCH_CURVE.CURVED,
  },
  hitch: {
    apiKey: 'hitch',
    name: 'Hitch',
    description:
      'Stop route in which the receiver stops and turns towards the field < 8 yards from the LOS',
    node: ROUTE_TREE_BRANCH_NODE.LOW,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.DOWN_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.SHORT,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  in: {
    apiKey: 'in',
    name: 'In',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks inward < 10 yards from the LOS at an approximately flat angle',
    node: ROUTE_TREE_BRANCH_NODE.LOW,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.FLAT,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  out: {
    apiKey: 'out',
    name: 'Out',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks outward < 10 yards from the LOS at an approximately flat angle',
    node: ROUTE_TREE_BRANCH_NODE.LOW,
    direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
    angle: ROUTE_TREE_BRANCH_ANGLE.FLAT,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  over: {
    apiKey: 'over',
    name: 'Over',
    description:
      'Curved crossing route in which the receiver crosses the horizontal snap location > 6 yards from the LOS',
    node: ROUTE_TREE_BRANCH_NODE.ORIGIN,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: radians(50),
    length: 30,
    curve: ROUTE_TREE_BRANCH_CURVE.CURVED,
  },
  post: {
    apiKey: 'post',
    name: 'Post',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks inward > 10 yards from the LOS at an approximately 45 degree angle',
    node: ROUTE_TREE_BRANCH_NODE.HIGH,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.UP_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
  screen: {
    apiKey: 'screen',
    name: 'Screen',
    description: 'Receiver receives immediate target behind the LOS',
    node: ROUTE_TREE_BRANCH_NODE.ORIGIN,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.FLAT,
    length: ROUTE_TREE_BRANCH_LENGTH.LONG,
    curve: ROUTE_TREE_BRANCH_CURVE.CURVED,
  },
  slant: {
    apiKey: 'slant',
    name: 'Slant',
    description:
      // eslint-disable-next-line max-len
      '2-way straight line route in which the receiver breaks inward < 10 yards from the LOS at an approximately 45 degree angle',
    node: ROUTE_TREE_BRANCH_NODE.LOW,
    direction: ROUTE_TREE_BRANCH_DIRECTION.IN,
    angle: ROUTE_TREE_BRANCH_ANGLE.UP_DIAGONAL,
    length: ROUTE_TREE_BRANCH_LENGTH.REGULAR,
    curve: ROUTE_TREE_BRANCH_CURVE.STRAIGHT,
  },
};
export const ROUTE_TYPE_UNCLASSIFIED = {
  apiKey: 'unclassified',
  name: 'Unclassified',
  description: 'A route run but unable to be classified',
  node: ROUTE_TREE_BRANCH_NODE.ORIGIN,
  direction: ROUTE_TREE_BRANCH_DIRECTION.OUT,
  angle: ROUTE_TREE_BRANCH_ANGLE.DOWN_NUDGE,
  length: 19,
  curve: ROUTE_TREE_BRANCH_CURVE.CURVED,
};
export const ROUTE_TYPE_CONFIG = {
  ...ROUTE_TYPE_CONFIG_CLASSIFIED,
  [ROUTE_TYPE_UNCLASSIFIED.apiKey]: ROUTE_TYPE_UNCLASSIFIED,
};
export const LOW_NODE_ROUTES = Object.values(
  ROUTE_TYPE_CONFIG_CLASSIFIED
).filter((route) => route.node === ROUTE_TREE_BRANCH_NODE.LOW);
export const HIGH_NODE_ROUTES = Object.values(
  ROUTE_TYPE_CONFIG_CLASSIFIED
).filter((route) => route.node === ROUTE_TREE_BRANCH_NODE.HIGH);

const PX_PER_YD = DEFAULT_FIELD_DRAWING_SETTINGS.pxPerYard;
export const ROUTE_TREE_RADII = { FRUIT: 11, TEXT: 8, NODE: 7 };

export const ROUTE_TREE_COORDINATES = Object.values(ROUTE_TYPE_CONFIG).map(
  (routeType) => {
    const x1 = routeType.node * PX_PER_YD;
    const y1 = 0;
    const invertY =
      routeType.direction === ROUTE_TREE_BRANCH_DIRECTION.OUT ? -1 : 1;
    const endPoint = (radius) => {
      const x2 = x1 + radius * Math.cos(routeType.angle);
      const y2 = radius * Math.sin(routeType.angle) * invertY;
      return { x2, y2 };
    };
    const { x2: x2Path, y2: y2Path } = endPoint(routeType.length * PX_PER_YD);
    const { x2: cx, y2: cy } = endPoint(
      routeType.length * PX_PER_YD + ROUTE_TREE_RADII.FRUIT
    );
    const levelLabel = {
      x2: x2Path,
      y2:
        y2Path + invertY * (ROUTE_TREE_RADII.FRUIT * 2 + ROUTE_TREE_RADII.TEXT),
    };
    const { x2: xLabel, y2: yLabel } =
      routeType.curve === ROUTE_TREE_BRANCH_CURVE.STRAIGHT
        ? endPoint(
            routeType.length * PX_PER_YD +
              ROUTE_TREE_RADII.FRUIT * 2 +
              ROUTE_TREE_RADII.TEXT
          )
        : levelLabel;

    const x2PathC =
      routeType.angle >= Math.PI / 2 ? x2Path - 45 : x1 + (x2Path - x1) * 0.85;
    const y2PathC = y1 + (y2Path - y1) * 0.3; // towards the middle

    const branchInfo = {
      routeType: routeType.apiKey,
      x1,
      y1,
      x2Path,
      y2Path,
      cx,
      cy,
      xLabel,
      yLabel,
      x2PathC,
      y2PathC,
      isCurved: routeType.curve === ROUTE_TREE_BRANCH_CURVE.CURVED,
    };
    return branchInfo;
  }
);

export const ROUTE_TREE_COORDINATES_MIRRORED = ROUTE_TREE_COORDINATES.map(
  (branch) => {
    const mirrorClass = {
      ...branch,
      y1: branch.y1 * -1,
      y2Path: branch.y2Path * -1,
      cy: branch.cy * -1,
      yLabel: branch.yLabel * -1,
      y2PathC: branch.y2PathC * -1,
    };
    return mirrorClass;
  }
);

export const ROUTE_TREE_BRANCH_RANGE = [2, 10];

export const ROUTE_TREE_BRANCH_DASH = '2 8';
