import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../utils/helpers/contextFilters';

const overrides = { gameIds: false };

export const GET_PLAYS = gql`
  query GetPlays(
    $competitionId: Int!
    $gameId: [Int!]
    $driveIds: [ID!]
    ${getContextQueryVariables(overrides)}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedGameId @client @export(as: "gameId")
    ${getContextFilterClientExports(overrides)}

    plays(
      first: 100
      filters: {
        competitionId: $competitionId
        gameIds: $gameId
        driveIds: $driveIds
        ${getContextFiltersQueryArguments(overrides)}
      }
    ) {
      edges {
        node {
          id
          name
          down
          quarter
          driveIndex
          gameIndex
          yardsToGo
          yardLine
          type
          drive {
            gameIndex
          }
          offenseTeam {
            name
          }
          defenseTeam {
            name
          }
          game {
            id
            date
            homeTeam {
              name
            }
            awayTeam {
              name
            }
          }
        }
      }
    }
  }
`;
