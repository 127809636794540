export const traits = [
  {
    title: 'Backfield Alignment',
    options: [
      { label: 'Gun Strong (35)', isSelected: true, isDisabled: false },
      { label: 'Gun Weak (20)', isSelected: false, isDisabled: false },
      { label: 'Gun (15)', isSelected: false, isDisabled: false },
      { label: 'Pistol (8)', isSelected: false, isDisabled: false },
      { label: 'Pistol Solo', isSelected: false, isDisabled: true },
      { label: 'Pistol Strong', isSelected: false, isDisabled: true },
      { label: 'Pistol Weak', isSelected: false, isDisabled: true },
      { label: 'Solo Weak', isSelected: false, isDisabled: true },
      { label: 'Solo Strong', isSelected: false, isDisabled: true },
    ],
  },
  {
    title: 'WR Alignment',
    options: [
      { label: 'Z on (90)', isSelected: false, isDisabled: false },
      { label: 'Normal (68)', isSelected: false, isDisabled: false },
    ],
  },
];

export const players = [
  {
    title: 'Wide Receiver (X)',
    position: 'WIDE_RECEIVER',
    letter: 'X',
    options: [
      { label: 'Gustavo Botosh', isSelected: false },
      { label: 'Davis Vetrovs', isSelected: false },
    ],
  },
  {
    title: 'Wide Receiver (Y)',
    position: 'WIDE_RECEIVER',
    letter: 'Y',
    options: [
      { label: 'Cristofer Botosh', isSelected: false },
      { label: 'Jaydon Botosh', isSelected: false },
      { label: 'Leo Aminoff', isSelected: false },
      { label: 'Alfredo Dokidis', isSelected: false },
      { label: 'Jason Cink', isSelected: false },
      { label: 'Marcus Lubin', isSelected: false },
    ],
  },
  {
    title: 'Wide Receiver (Z)',
    position: 'WIDE_RECEIVER',
    letter: 'Z',
    options: [
      { label: 'Gustavo Philips', isSelected: false },
      { label: 'Roger Herwitz', isSelected: false },
      { label: 'Jakob Lubin', isSelected: false },
      { label: 'Abram Herwitz', isSelected: false },
      { label: 'Martin Kenter', isSelected: false },
    ],
  },
  {
    title: 'Running Back (H)',
    position: 'RUNNING_BACK',
    letter: 'H',
    options: [
      { label: 'Jakob Lipshutz', isSelected: false },
      { label: 'Kadin Donin', isSelected: false },
      { label: 'Leo Passaquindici Arcand', isSelected: false },
    ],
  },
  {
    title: 'Quarterback',
    position: 'QUARTERBACK',
    options: [
      { label: 'Talan Levin', isSelected: false },
      { label: 'Abram Dokidis', isSelected: false },
      { label: 'Zain Dorwart', isSelected: false },
    ],
  },
];

export const mockTable = [
  {
    id: 1,
    name: 'Player 1',
    snapCount: 70,
    snapRate: '80.0',
    usageRate: '12',
    link: '#',
    opacity: 0.8,
  },
  {
    id: 2,
    name: 'Player 2',
    snapCount: 50,
    snapRate: '60.0',
    usageRate: '12',
    link: '#',
    opacity: 0.6,
    checked: true,
  },
  {
    id: 3,
    name: 'Player 3',
    snapCount: 30,
    snapRate: '15.0',
    usageRate: '12',
    link: '#',
    opacity: 0.15,
  },
  {
    id: 4,
    name: 'Player 4',
    snapCount: 3,
    snapRate: '0.8',
    usageRate: '0.42',
    link: '#',
    opacity: 0.08,
  },
  {
    id: 5,
    name: 'Player 5',
    snapCount: 3,
    snapRate: '0.8',
    usageRate: '0.42',
    link: '#',
    opacity: 0.08,
  },
  {
    id: 6,
    name: 'Player 6',
    snapCount: 3,
    snapRate: '0.8',
    usageRate: '0.42',
    link: '#',
    opacity: 0.08,
  },
  {
    id: 7,
    name: 'Player 7',
    snapCount: 3,
    snapRate: '0.8',
    usageRate: '0.42',
    link: '#',
    opacity: 0.08,
  },
];
