import { styled } from 'styled-components';

export const StyledDataAccess = styled.div`
  width: 100%;
  padding: 0 0.625rem 1.875rem;
  font-weight: 600;

  h1 {
    font-size: 1.5625rem;
    margin-bottom: 0.625rem;
  }

  > div:last-of-type {
    h2 {
      padding: 0.9375rem 0.9375rem 0.625rem 0.9375rem;
    }

    > div {
      padding-top: 0;
    }
  }
`;

export const ApiKey = styled.section`
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  padding: 1rem;
  margin-bottom: 1rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ $withFeedback }) => $withFeedback && 'margin-bottom: 0.8rem;'}

    h2 {
      margin: 0;
    }
  }

  p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    svg {
      min-width: 1rem;

      path {
        fill: ${({ theme }) => theme.colours.messaging.warning.main};
      }
    }
  }

  .api-key-container {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colours.ink.primary.main};

    button {
      position: relative;
      background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
      padding: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background-color: ${({ theme }) => theme.colours.layering[0].main};
      }
    }

    .clipboard-alert {
      position: absolute;
      font-size: 0%.75rem;
      font-weight: 600;
      white-space: nowrap;
      top: 2.0625rem;
      left: 0;
    }
  }
`;

StyledDataAccess.displayName = 'StyledDataAccess';
ApiKey.displayName = 'ApiKey';
