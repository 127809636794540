/* 
Expects an object representing two point locations
Then describes the angle of the resultant line from the first point to second point
Defines a 0degree change as being positive x (no change in y)
Defines angles in terms of +-π (as opposed to 0 -> 2π)
Note the orientation of the Y axis is that of SVGs (not default axis)
o → +X
↓
+Y
                -π/2
                -90
                 |
                 |
    180(π)  -----o-----    0
                 |
                 |
                 90
                 π/2

*/
export const arrowRotation = ({ x1, y1, x2, y2 }, degrees = true) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const thetaRad = Math.atan2(dy, dx);
  const thetaDegrees = (thetaRad * 180) / Math.PI;
  return degrees ? thetaDegrees : thetaRad;
};

export const hypotenuseLength = ({ x1, y1, x2, y2 }) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.hypot(dx, dy);
};

/* 
Drawing an "ice cream cone" shape, i.e. a rounded end with a tail
The cone is "lying flat", with the base of the cone (the point) at origin x=0 y=0
The ball (ice cream) is then centered at point dx with a radius r
*/
export const drawCone = (dx, r) => {
  const path = `M0 0 l${dx} -${r} a${r} ${r} 0 1 1 0 ${2 * r} z`;
  return path;
};

/* 
Default orientation is points at top/bottom
Width of hexagon = full radius
Hexagon is rotationally symmetrical
 */
export const drawHexagon = (radius) => {
  const v30 = radius * Math.tan((30 / 360) * 2 * Math.PI);
  return `M0,-${2 * v30} l${radius},${v30} v${
    2 * v30
  } l-${radius},${v30} l-${radius},-${v30} v-${2 * v30} z`;
};

/* 
Draws the circle as a function of two arcs */
export const drawCircle = (radius) =>
  `M0,0 ` +
  `m-${radius},0 ` +
  `a ${radius},${radius} 0 1,0 ${radius * 2},0 ` +
  `a ${radius},${radius} 0 1,0 -${radius * 2},0 z`;

/* 
Squares are notably larger in area than other shapes visibly:
  recommend supplied radius be ~0.85 * circular radius */
export const drawSquare = (radius) =>
  `M${0 - radius},${0 - radius} ` +
  `l${radius * 2},0 l0,${radius * 2} l-${radius * 2},0 z`;

/* 
Default orientation is point at top flat bottom
All 5 pentagon points fall on circle radius (so area slightly less than circle)
*/
export const drawPentagon = (radius) => {
  const vUp = radius * Math.cos((72 / 180) * Math.PI);
  const hUp = radius * Math.sin((72 / 180) * Math.PI);
  const vDown = radius * Math.cos((36 / 180) * Math.PI);
  const hDown = radius * Math.sin((36 / 180) * Math.PI);
  return `M0,-${radius} L${hUp},-${vUp} L${hDown},${vDown} L-${hDown},${vDown} L-${hUp},-${vUp} z`;
};

/* 
Like the pentagon above, but the flat base is indented
*/
export const drawPentagonArrow = (radius) => {
  const vUp = radius * Math.cos((72 / 180) * Math.PI);
  const hUp = radius * Math.sin((72 / 180) * Math.PI);
  const vDown = radius * Math.cos((36 / 180) * Math.PI);
  const hDown = radius * Math.sin((36 / 180) * Math.PI);
  return `M0,-${radius} L${hUp},-${vUp} L${hDown},${vDown} L${0},${
    vDown * 0.15
  } L-${hDown},${vDown} L-${hUp},-${vUp} z`;
};

/* 
Like the pentagon above, but the flat base is indented
*/
export const drawTriangularArrow = (radius) =>
  `M${-radius},${radius} L${0},-${radius} L${radius},${radius} L${0},${
    radius * 0.7
  } z`;

/* 
Draws an AMF ball shape (pointed oval), angled diagonally top left to bottom right
Fits snugly into a circle of matching radius 
*/
export const drawBall = (radius) => {
  const diameter = radius * 2;
  return `M${radius}-${radius} q0 ${diameter} -${diameter} ${diameter} q0 -${diameter} ${diameter} -${diameter} z`;
};

/* 
Draws a pair of barriers to indicate a gap (in the offensive line)
Additional overrides can be added to turn either side either "open"
  for C-Open and D gaps
*/
export const drawGapBarriers = (
  radius,
  leftOpen = false,
  rightOpen = false
) => {
  /* Angled Bracket Blocks */
  const centerGapR = 0.5 * radius;
  const blockWidth = 0.2 * radius;
  const cornerHeight = 0.1 * radius;
  const nonGapR = radius - centerGapR - blockWidth;
  const vert = 0.25 * radius;

  const leftBarrier = `M${-radius} ${-radius} l${blockWidth} 0 l${nonGapR} ${vert} l0 ${
    2 * (radius - vert)
  } l${-nonGapR} ${vert} l${-blockWidth} 0 l0 ${-cornerHeight}  l${nonGapR} ${-vert} l0 ${
    -2 * (radius - vert - cornerHeight)
  } l${-nonGapR} ${-vert} z`;
  const rightBarrier = `M${radius} ${-radius} l${-blockWidth} 0 l${-nonGapR} ${vert} l0 ${
    2 * (radius - vert)
  } l${nonGapR} ${vert} l${blockWidth} 0 l0 ${-cornerHeight} l${-nonGapR} ${-vert} l0 ${
    -2 * (radius - vert - cornerHeight)
  } l${nonGapR} ${-vert} z`;

  /* open style is non closed loop made of "dashed" lines in equivalent shape (will stroke thinner) */
  const rDash = (radius - vert) * 0.4;
  const leftBarrierOpen =
    `M${-radius} ${-radius} l${centerGapR} ${vert}` +
    ` m0 ${rDash} l0 ${rDash} m0 ${rDash} l0 ${rDash} m0 ${rDash}` +
    ` l${-centerGapR} ${vert}`;
  const rightBarrierOpen =
    `M${radius} ${-radius} l${-centerGapR} ${vert}` +
    ` m0 ${rDash} l0 ${rDash} m0 ${rDash} l0 ${rDash} m0 ${rDash}` +
    ` l${centerGapR} ${vert}`;

  const path = `${leftOpen ? leftBarrierOpen : leftBarrier} ${
    rightOpen ? rightBarrierOpen : rightBarrier
  }`;
  return path;
};
