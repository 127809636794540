import { gql } from '@apollo/client';

export const GET_TEAM_STAT_DISTRIBUTIONS = gql`
  query GetTeamStatDistributions(
    $competitionId: Int!
    $seasonId: Int!
    $statNames: [String!]!
    $offense: Boolean!
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")

    teamStatDistributions(
      competitionId: $competitionId
      seasonId: $seasonId
      statNames: $statNames
      offense: $offense
    ) {
      name
      mean
      stddev
    }
  }
`;
