import { styled } from 'styled-components';

// These are the styles for the "Edit Columns" popup
export const PlayFinderColumnsLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  // adjustment for search input
  .ui.input {
    width: 100%;
    height: 36px;

    > i.icon {
      top: 4px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    margin: -1rem -1rem -1rem 0;
    height: 40vh;

    // left column: parent categories
    > div:first-child {
      width: 360px;
      padding: 1rem 0 0 0;
      overflow-y: auto;

      .inputCheck {
        padding: 0.25rem 0.5rem 0.5rem 0.5rem;
      }

      h4 {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
      }
    }

    // right column: child categories
    > div:last-child {
      flex: 1;
      padding: 0 1rem;
      width: 290px;
      height: 100%;
      background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
      overflow-y: auto;

      h3 {
        ${({ theme }) => theme.typography.headingRegular};
        margin-bottom: 1rem;
      }

      h4 {
        ${({ theme }) => theme.typography.headingSmall};
        color: ${({ theme }) => `${theme.colours.ink.primary.main}60`};
        margin-bottom: 0.5rem;
      }
    }
  }
`;
PlayFinderColumnsLayout.displayName = 'PlayFinderColumnsLayout';

export const PlayFinderColumnStyle = styled.div`
  ${({ theme }) => theme.typography.bodyMedium};
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  height: 32px;
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  border-radius: 4px;
  padding: 0 0.5rem;
  gap: 0.5rem;

  span:first-child {
    cursor: grab;
  }

  span:nth-child(2) {
    flex-grow: 2;
  }
`;
PlayFinderColumnStyle.displayName = 'PlayFinderColumnStyle';

export const PlayFinderColumnsDragHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0 0;
  align-items: flex-start;
`;
PlayFinderColumnsDragHeader.displayName = 'PlayFinderColumnsDragHeader';
