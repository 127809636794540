import React, { useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Message, Loader } from 'semantic-ui-react';
import { Dropdown, Grid, Toggle } from '@statsbomb/kitbag-components';
import Dimmer from '../../../components/Dimmer/Dimmer';
import SnapDistributionChart from './SnapDistributionChart';
import { GET_SNAP_DISTRIBUTIONS } from './getSnapDistributions';
import { GET_PLAYER_PATHS } from './getPlayerPaths';
import { formatSnapData, snapsFromZone } from './PlayerSnap.dataManipulation';
import Tile from '../../../components/Tile/Tile';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  mf_PlayerDetails,
  mf_TeamDetails,
  mf_LeagueLevel,
  mf_Players,
} from '../../../apollo';
import PlayerRoutesChart from './PlayerRoutesChart';
import {
  addRouteInfo,
  ROUTES_Y_MODES,
  ROUTES_Y_OPTIONS,
} from './PlayerRoutesSummary.dataManipulation';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import TableTile from '../../../components/TableTile/TableTile';
import { INFO_ITEM_HEADERS, snapDistroInfo } from './SnapInfo';
import PlayerRoutesSummary from './PlayerRoutesSummary';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import { escapeDownloadString } from '../../../utils/helpers/strings';
import {
  DOT_COLOR_MODE,
  DOT_COLOR_MODES,
  ROUTE_COLOR_MODES,
  HEATMAP_COLOR_MODES,
  HEATMAP_COLOR_MODE_TYPES,
} from './SnapDistributionChart.constants';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import PageHeader from '../../../components/PageHeader/PageHeader';

const PlayerSnap = () => {
  // global state
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_PlayerRV = useReactiveVar(mf_Players);

  // page filters
  const [displayKey, setDisplayKey] = useState(true);
  // routes options
  const [routesDirection, setRoutesDirection] = useQueryString(
    'routes-direction',
    ROTATIONS.VERTICAL_UP
  );
  const [selectedYMode, setSelectedYMode] = useState(ROUTES_Y_MODES.FIELD);
  const [selectedZones, setSelectedZones] = useState(null);
  const [routeShowPaths, setRouteShowPaths] = useState(true);
  const [routeShowDots, setRouteShowDots] = useState(false);
  const [routeShowHeat, setRouteShowHeat] = useState(false);
  const [routeColorMode, setRouteColorMode] = useState(DOT_COLOR_MODE.GHOST);
  const [selectedPlay, setSelectedPlay] = useState(null);

  const [snapDirection, setSnapDirection] = useQueryString(
    'snap-direction',
    ROTATIONS.VERTICAL_UP
  );
  const [st_SnapY, setSnapY] = useQueryString('snap', false);
  const [st_PositionX, setPositionX] = useQueryString('pos', true);
  const [st_ColorMode, setColorMode] = useQueryString(
    'color',
    DOT_COLOR_MODES[0].value
  );
  const [st_HeatmapColorMode, setHeatmapColorMode] = useQueryString(
    'heatmap',
    HEATMAP_COLOR_MODE_TYPES.INDEPENDENT
  );

  // chart data
  const { loading, error, data } = useQuery(GET_SNAP_DISTRIBUTIONS);
  // paths data
  const {
    loading: loadingPaths,
    error: errorPaths,
    data: dataPaths,
  } = useQuery(GET_PLAYER_PATHS);

  // exporting
  const info1 = mf_TeamDetailsRV?.name;
  const info2 = useExportSeasonInfo();
  const infoDownloadName = `${escapeDownloadString(
    mf_PlayerDetailsRV?.name
  )} Snap Distribution Info.csv`;

  const handleSnapY = () => {
    setSelectedZones(null);
    setSnapY(!st_SnapY);
  };

  const playerPosition =
    mf_PlayerDetailsRV?.mostCommonPosition?.generalPosition;

  // auto set initial orientation depending on whether a player is attack or defense
  useEffect(() => {
    const isDefensivePlayer = !ROSTER_POSITIONS[playerPosition]?.isOffense;
    const orientation = isDefensivePlayer
      ? ROTATIONS.VERTICAL_DOWN
      : ROTATIONS.VERTICAL_UP;

    setRoutesDirection(orientation);
    setSnapDirection(orientation);
  }, [mf_PlayerRV, playerPosition]);

  // clean up the data
  const snapData = data?.snapEvents?.edges.map((m) =>
    formatSnapData(m, competitionLevel)
  );
  const playerName =
    snapData?.length > 0 ? snapData[0].freezeFrames[0].player?.name : '';
  const teamName = snapData?.length > 0 ? `(${mf_TeamDetailsRV?.name})` : '';
  const positionAndName = snapData && `${playerName} ${teamName}`;
  const pathsData = dataPaths?.playerPlays?.edges.map((d) => d.node);
  const routedSnapData =
    snapData &&
    pathsData &&
    snapData?.map((p) => addRouteInfo(p, pathsData, selectedYMode));

  const zonedData =
    selectedZones === null
      ? routedSnapData
      : snapsFromZone(
          routedSnapData,
          st_SnapY,
          selectedZones,
          competitionLevel
        );
  const zonedDataPlayIds = zonedData && zonedData.map((m) => m.play.id);

  const mergedData = routedSnapData?.map((m) => ({
    ...m,
    inSelectedZone: zonedDataPlayIds
      ? zonedDataPlayIds.includes(m.play.id)
      : true,
  }));

  const infoItems = snapDistroInfo(mergedData);
  const zonedInfoItems = selectedZones && snapDistroInfo(zonedData);

  const selectedEventObj =
    mergedData &&
    selectedPlay &&
    mergedData?.find((f) => f.play.id === selectedPlay);

  const marginOverride =
    routesDirection === 'routes-direction'
      ? { top: 0, bottom: 0 }
      : { right: 0, left: 0 };

  const isHorizontal = snapDirection === ROTATIONS.HORIZONTAL;

  const isChartReady =
    positionAndName && !loading && !error && !!data.snapEvents.totalCount;

  const routeChartColumn = (
    <Tile border="0" margin="0.5rem 0 0 0">
      <Tile.Body $padding="1rem 0.5rem 0.5rem 0.5rem">
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'flex-end',
            padding: '0 1rem',
          }}
        >
          <ExportModal
            title={playerName}
            secondaryTitle="Player Routes"
            info1={info1}
            info2={info2}
            fileName={`${mf_PlayerDetailsRV?.name} Player Routes Chart`}
            isDisabled={!isChartReady}
          >
            <PlayerRoutesChart
              id="amf-player-routes-chart-export"
              data={zonedData}
              isError={!!errorPaths}
              isLoading={loadingPaths}
              orientation={routesDirection}
              yMode={selectedYMode}
              showPaths={routeShowPaths}
              showDots={routeShowDots}
              showHeatmap={routeShowHeat}
              dotColorMode={st_ColorMode}
              pathColorMode={routeColorMode}
              selectedPlay={selectedPlay}
              isInteractive={false}
              marginOverride={marginOverride}
            />
          </ExportModal>
        </div>
        <PlayerRoutesChart
          id="amf-player-routes-chart"
          data={zonedData}
          isError={!!errorPaths}
          isLoading={loadingPaths}
          orientation={routesDirection}
          yMode={selectedYMode}
          showPaths={routeShowPaths}
          showDots={routeShowDots}
          showHeatmap={routeShowHeat}
          dotColorMode={st_ColorMode}
          pathColorMode={routeColorMode}
          selectedPlay={selectedPlay}
          setSelectedPlay={setSelectedPlay}
        />
      </Tile.Body>
    </Tile>
  );
  const routeControls = (
    <AccordionTile
      id="route-settings-accordion"
      header={<Tile.AccordionHeader>Routes Settings</Tile.AccordionHeader>}
      body={
        <Tile.AccordionBody style={{ margin: '0 0 0.5rem 0' }}>
          <Dropdown
            id="routes-field-rotation-dropdown"
            options={ROTATION_OPTIONS}
            label="Routes Field Direction"
            onChange={(selectedOption) =>
              setRoutesDirection(selectedOption.value)
            }
            value={ROTATION_OPTIONS.find((f) => f.value === routesDirection)}
            isSearchable={false}
          />
          <Dropdown
            id="routes-y-mode-dropdown"
            options={ROUTES_Y_OPTIONS}
            label="Draw Routes"
            onChange={(selectedOption) =>
              setSelectedYMode(selectedOption.value)
            }
            value={ROUTES_Y_OPTIONS.find((f) => f.value === selectedYMode)}
            isSearchable={false}
          />
          <Dropdown
            id="routes-color-mode-dropdown"
            options={ROUTE_COLOR_MODES}
            label="Path Coloring"
            onChange={(selectedOption) =>
              setRouteColorMode(selectedOption.value)
            }
            value={ROUTE_COLOR_MODES.find((f) => f.value === routeColorMode)}
            isSearchable={false}
          />
          <Toggle
            id="routes-show-paths-toggle"
            label="Show Paths"
            onChange={() => setRouteShowPaths(!routeShowPaths)}
            checked={routeShowPaths}
          />
          <Toggle
            id="routes-show-dots-toggle"
            label="Show Origin Dots in Route Chart"
            onChange={() => setRouteShowDots(!routeShowDots)}
            checked={routeShowDots}
          />
          <Toggle
            id="routes-show-heat-toggle"
            label="Show Snap Heat Map in Route Chart"
            onChange={() => setRouteShowHeat(!routeShowHeat)}
            checked={routeShowHeat}
          />
        </Tile.AccordionBody>
      }
      isExpandedDefault
      saveState
    />
  );

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth="398px"
            $inlineSize="16%"
            $gap="0.5rem"
            $padding="0"
          >
            <div>
              <PageHeader
                href="/player/overview/:leagues/:seasons/:teams/:players"
                rootPage="Player"
                activePage="Snap Distribution"
              />
              <Grid>
                <Grid item xs={isHorizontal ? 8 : 6}>
                  <Tile border="0" margin="0.5rem 0 0 0">
                    <Tile.Body
                      $padding="1rem 0.5rem 0.5rem 0.5rem"
                      style={{ gap: '0' }}
                    >
                      {error && !loading && (
                        <Message negative>
                          There has been an error. Please contact support.
                        </Message>
                      )}
                      {loading && (
                        <Dimmer active style={{ minHeight: '30vh' }}>
                          <Loader content="Loading" />
                        </Dimmer>
                      )}
                      {mergedData?.length === 0 && (
                        <Dimmer active onClick={() => setSelectedZones(null)}>
                          <p>No Data available</p>
                        </Dimmer>
                      )}

                      <div
                        style={{
                          display: 'flex',
                          gap: '0.5rem',
                          justifyContent: 'flex-end',
                          padding: '0 1rem',
                        }}
                      >
                        <KeyButton
                          showKey={displayKey}
                          handleShowKey={() => setDisplayKey(!displayKey)}
                        />
                        <ExportModal
                          title={playerName}
                          secondaryTitle="Snap Distribution"
                          info1={info1}
                          info2={info2}
                          fileName={`${mf_PlayerDetailsRV?.name} Snap Distribution Chart`}
                          isDisabled={!isChartReady}
                        >
                          <SnapDistributionChart
                            id="amf-snap-distribution-export"
                            data={mergedData}
                            summaryMode={false}
                            loading={loading}
                            showDy={st_SnapY}
                            showDx={st_PositionX}
                            orientation={snapDirection}
                            colorMode={st_ColorMode}
                            colorModeHeatmap={st_HeatmapColorMode}
                            selectedZones={selectedZones}
                            setSelectedZones={setSelectedZones}
                            showKey={displayKey}
                            margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                            isInteractive={false}
                          />
                        </ExportModal>
                      </div>
                      <SnapDistributionChart
                        id="amf-snap-distribution"
                        data={mergedData}
                        summaryMode={false}
                        loading={loading}
                        showDy={st_SnapY}
                        showDx={st_PositionX}
                        orientation={snapDirection}
                        colorMode={st_ColorMode}
                        colorModeHeatmap={st_HeatmapColorMode}
                        selectedZones={selectedZones}
                        setSelectedZones={setSelectedZones}
                        showKey={displayKey}
                      />
                    </Tile.Body>
                  </Tile>
                </Grid>
                <Grid item xs={isHorizontal ? 4 : 6}>
                  {routeChartColumn}
                </Grid>
              </Grid>
            </div>
            <div>
              <FixedAside
                $width="410px"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                <AccordionTile
                  id="snap-distribution-settings-accordion"
                  header={
                    <Tile.AccordionHeader>
                      Snap Distribution Settings
                    </Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody style={{ margin: '0 0 0.5625rem 0' }}>
                      <Toggle
                        id="show-lateral-position-toggle"
                        label="Show lateral position relative to Snap"
                        onChange={handleSnapY}
                        checked={st_SnapY}
                      />
                      <Toggle
                        id="show-depth-position-toggle"
                        label="Show Depth from Line of Scrimmage"
                        onChange={() => setPositionX(!st_PositionX)}
                        checked={st_PositionX}
                      />
                      <Dropdown
                        id="snap-direction-dropdown"
                        options={ROTATION_OPTIONS}
                        label="Snap Distribution Direction"
                        onChange={(selectedOption) =>
                          setSnapDirection(selectedOption.value)
                        }
                        value={ROTATION_OPTIONS.find(
                          (f) => f.value === snapDirection
                        )}
                        isSearchable={false}
                      />
                      <Dropdown
                        id="dot-colouring-dropdown"
                        options={DOT_COLOR_MODES}
                        label="Dot Coloring"
                        onChange={(selectedOption) =>
                          setColorMode(selectedOption.value)
                        }
                        value={DOT_COLOR_MODES.find(
                          (f) => f.value === st_ColorMode
                        )}
                        isSearchable={false}
                      />
                      <Dropdown
                        id="heatmap-colouring-dropdown"
                        options={HEATMAP_COLOR_MODES}
                        label="Heatmap Intensity Scaling"
                        onChange={(selectedOption) =>
                          setHeatmapColorMode(selectedOption.value)
                        }
                        value={HEATMAP_COLOR_MODES.find(
                          (f) => f.value === st_HeatmapColorMode
                        )}
                        isSearchable={false}
                      />
                    </Tile.AccordionBody>
                  }
                  isExpandedDefault
                  saveState
                />

                {routeControls}

                {selectedEventObj && (
                  <PlayerRoutesSummary
                    fileName="Route Summary.csv"
                    snapDatum={selectedEventObj}
                    error={!!error || !!errorPaths}
                    loading={loading}
                  />
                )}

                {infoItems && infoItems?.length > 0 && (
                  <TableTile
                    data={infoItems}
                    tileTitle="Snap Information"
                    showColumnHeader
                    columnHeaders={INFO_ITEM_HEADERS}
                    error={!!errorPaths || !!error}
                    loading={loading}
                    fileName={infoDownloadName}
                    padding="0"
                    margin="0 0 0.5rem 0"
                  />
                )}

                {zonedInfoItems && zonedInfoItems?.length > 0 && (
                  <TableTile
                    data={zonedInfoItems}
                    tileTitle="Snap Information - Selected Zones"
                    showColumnHeader
                    columnHeaders={INFO_ITEM_HEADERS}
                    error={!!errorPaths || !!error}
                    loading={loading}
                    fileName={infoDownloadName}
                    padding="0"
                    margin="0"
                  />
                )}
              </FixedAside>
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayerSnap;
