/* TODO: Be clever over blank space below in some orientation */
export const TACKLING_FIELD_AXES_PADDING = {
  top: 25,
  right: 25,
  bottom: 25,
  left: 25,
};

const TACKLING_KEY_METRICS = {
  firstAttempt: 'First tackle attempt',
  delta: 'Yards after contact',
  finalBall: 'Total yards gained',
};
const TACKLE_LOCATION_DEFAULT_MARGIN = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

const TACKLE_LOCATION_VIOLINS = {
  WIDTH: 80,
  SPACING: 20,
  AXIS: 30,
};
const TACKLE_LOCATION_CLASSES = {
  BACKGROUND: 'tl-background',
  IN_MARGINS: 'tl-in-margin',
  DISTROS_AREA: 'tl-distros',
  FIELD_ZONE: 'tl-field-zone',
  FIELD_IN_AXES: 'tl-field-area',
  FIELD_MARKINGS: 'tl-field-markings',
  FIELD_DATA: 'tl-field-data',
  KEY: 'tl-key',
};

const KEY_SEGMENT_HEIGHT = 110; // so the distro or field key

const TACKLE_LOCATION_FIELD_SIZES_X = {
  FOCUSED: { LOS: 10, END: 40 },
  FULL: { LOS: 20, END: 100 },
};

export const TACKLING_FIELD_DISPLAY_HEATMAP = {
  value: 'HEATMAP',
  label: 'Heatmap',
  variant: 'HeatmapFlame',
};
export const TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS = {
  value: 'PLAY_LOCATIONS',
  label: 'Play Locations',
  variant: 'Position',
};
export const TACKLING_FIELD_DISPLAY_PATHS = {
  value: 'PATHS',
  label: 'Paths',
  variant: 'PassPath',
};
export const TACKLING_FIELD_DISPLAY_MODES = [
  TACKLING_FIELD_DISPLAY_HEATMAP,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_FIELD_DISPLAY_PATHS,
];

export const TACKLING_COORDINATE_FIELD = {
  value: 'FIELD',
  label: 'Field Locations, relative to Line of Scrimmage',
};
export const TACKLING_COORDINATE_SNAP = {
  value: 'SNAP',
  label: 'Relative to Snap',
};
export const TACKLING_COORDINATE_FIRST_TACKLE = {
  value: 'FIRST_TACKLE',
  label: 'Relative to First Tackle Attempt',
};
export const TACKLING_COORDINATE_MODES = [
  TACKLING_COORDINATE_FIELD,
  TACKLING_COORDINATE_SNAP,
  TACKLING_COORDINATE_FIRST_TACKLE,
];

const TACKLE_LOCATION_SHAPE_MODE_CONE = {
  value: 'CONE',
  label: 'Tackle Cones',
};
const TACKLE_LOCATION_SHAPE_MODE_TADPOLE = {
  value: 'TADPOLE',
  label: 'Tackle Tadpoles',
};
const TACKLE_LOCATION_SHAPE_MODE_TA_DOTS = {
  value: 'TACKLE_ATTEMPT_DOTS',
  label: 'Tackle Attempt Locations',
};
const TACKLE_LOCATION_SHAPE_MODE_BTG_DOTS = {
  value: 'BALL_TO_GROUND_DOTS',
  label: 'Final Ball Locations',
};
const TACKLE_LOCATION_SHAPE_MODE_TA_HEAT = {
  value: 'TACKLE_ATTEMPT_HEAT',
  label: 'Tackle Attempt Heatmap',
};
const TACKLE_LOCATION_SHAPE_MODE_TA_HEAT_FINAL_DOT = {
  value: 'TACKLE_ATTEMPT_HEAT_FINAL_DOT',
  label: 'Tackle Attempt Heatmap with Final Balls',
};
const TACKLE_LOCATION_SHAPE_MODE_BTG_HEAT = {
  value: 'BALL_TO_GROUND_HEAT',
  label: 'Ball to ground heatmap',
};
const TACKLE_LOCATION_SHAPE_MODES = [
  TACKLE_LOCATION_SHAPE_MODE_TA_HEAT,
  TACKLE_LOCATION_SHAPE_MODE_TA_HEAT_FINAL_DOT,
  TACKLE_LOCATION_SHAPE_MODE_BTG_HEAT,
  // TACKLE_LOCATION_SHAPE_MODE_CONE,
  TACKLE_LOCATION_SHAPE_MODE_TADPOLE,
  TACKLE_LOCATION_SHAPE_MODE_TA_DOTS,
  TACKLE_LOCATION_SHAPE_MODE_BTG_DOTS,
];

const TACKLE_LOCATION_COLOR_MODE_PLAY_NEUTRAL = {
  value: 'NEUTRAL',
  label: 'None',
};
const TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME = {
  value: 'OUTCOME',
  label: 'Tackle Outcome',
};
const TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME = {
  value: 'PLAY_OUTCOME',
  label: 'Play Outcome',
};
const TACKLE_LOCATION_COLOR_MODE_DX = {
  value: 'DX',
  label: TACKLING_KEY_METRICS.delta,
};
const TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE = {
  value: 'PLAY_TYPE',
  label: 'Play Type',
};
const TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER = {
  value: 'BALL_CARRIER',
  label: 'Ball Carrier',
};
const TACKLE_LOCATION_COLOR_MODES = [
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE,
  TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_PLAY_NEUTRAL,
];

const TACKLE_LOCATION_Y_MODE_FIELD = {
  value: 'FIELD',
  label: 'Field Y Locations',
};
const TACKLE_LOCATION_Y_MODE_SNAP = {
  value: 'SNAP',
  label: 'Relative to Snap',
};
const TACKLE_LOCATION_Y_MODE_TA = {
  value: 'TA',
  label: 'Relative to first tackle attempt',
};
const TACKLE_LOCATION_Y_MODE_DY = {
  value: 'DY_ORDERED',
  label: 'Order by dY, but ignore it',
};
const TACKLE_LOCATION_Y_MODES = [
  TACKLE_LOCATION_Y_MODE_FIELD,
  TACKLE_LOCATION_Y_MODE_SNAP,
  TACKLE_LOCATION_Y_MODE_TA,
  TACKLE_LOCATION_Y_MODE_DY,
];

const TACKLE_LOCATION_X_MODE_LOS = {
  value: 'LOS',
  label: 'Relative to the Line of Scrimmage',
};
const TACKLE_LOCATION_X_MODE_TAORIGIN = {
  value: 'TA',
  label: 'Relative to the first tackle attempt',
};
const TACKLE_LOCATION_X_MODES = [
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLE_LOCATION_X_MODE_TAORIGIN,
];

const TACKLE_LOCATION_DISTRO_COLOR_WINNER = {
  value: 'DIFFERENCE',
  label: 'Difference to League Average',
};
const TACKLE_LOCATION_DISTRO_COLOR_QUARTILES = {
  value: 'QUARTILES',
  label: 'Quartiles',
};
const TACKLE_LOCATION_DISTRO_COLOR_MODES = [
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLE_LOCATION_DISTRO_COLOR_QUARTILES,
];

const TACKLING_TACKLER_ANY = {
  value: 'ANY',
  label: 'Tackler made an attempt (no conditions)',
};
const TACKLING_TACKLER_FIRST = {
  value: 'FIRST',
  label: 'Tackler made the first tackle attempt',
};
const TACKLING_TACKLER_SUCCESS = {
  value: 'SUCCESS',
  label: 'Tackler made a successful tackle',
};
const TACKLING_TACKLER_SOLO_ATTEMPT = {
  value: 'SOLO_ATTEMPT',
  label: 'Tackler made a solo attempt',
};
const TACKLING_TACKLER_MISSED_ATTEMPT = {
  value: 'SOLO_MISSED',
  label: 'Tackler missed tackle',
};
const TACKLING_TACKLER_MODES = [
  TACKLING_TACKLER_ANY,
  TACKLING_TACKLER_FIRST,
  TACKLING_TACKLER_SUCCESS,
  TACKLING_TACKLER_SOLO_ATTEMPT,
  TACKLING_TACKLER_MISSED_ATTEMPT,
];

export const TACKLE_POSITION_ANY = { value: 'ANY', label: 'Any Position' };

export const TACKLE_RESULT_ALL = { value: 'ALL', label: 'All' };

export const TACKLE_RESULT_TACKLE = { value: 'TACKLE', label: 'Made Tackle' };

export const TACKLE_RESULT_MISSED = { value: 'MISSED', label: 'Missed Tackle' };

export const TACKLE_RESULT_OPTIONS = [
  TACKLE_RESULT_ALL,
  TACKLE_RESULT_TACKLE,
  TACKLE_RESULT_MISSED,
];

export const TACKLING_PLAY_TYPE_RUN = {
  value: 'RUN',
  label: 'Run',
};
export const TACKLING_PLAY_TYPE_PASS = {
  value: 'PASS',
  label: 'Pass (no sack)',
};
export const TACKLING_PLAY_TYPE_SACK = {
  value: 'SACK',
  label: 'Sacks',
};
export const TACKLING_PLAY_TYPE_ANY = {
  value: 'ANY',
  label: 'All Runs & Passes',
};
export const TACKLING_PLAY_TYPES = [
  TACKLING_PLAY_TYPE_RUN,
  TACKLING_PLAY_TYPE_PASS,
  TACKLING_PLAY_TYPE_SACK,
  TACKLING_PLAY_TYPE_ANY,
];

export const TACKLING_OUTCOMES_API_KEYS = {
  SOLO_SUCCESS: 'SOLO_SUCCESS',
  SOLO_ASSISTED_SUCCESS: 'SOLO_ASSISTED_SUCCESS',
  ASSISTED_SUCCESS: 'ASSISTED_SUCCESS',
  SOLO_MISSED: 'SOLO_MISSED',
  SOLO_ASSISTED_MISSED: 'SOLO_ASSISTED_MISSED',
  ASSISTED_MISSED: 'ASSISTED_MISSED',
};
export const TACKLING_OUTCOMES = {
  [TACKLING_OUTCOMES_API_KEYS.SOLO_SUCCESS]: {
    soloAttempt: true,
    soloTackle: true,
    success: true,
    tackleResult: 'Solo Attempt -> Solo Tackle',
    priority: 0,
    tackleOutcomeColor: (palette) => palette.successFail.superSuccess.main,
  },
  [TACKLING_OUTCOMES_API_KEYS.SOLO_ASSISTED_SUCCESS]: {
    soloAttempt: true,
    soloTackle: false,
    success: true,
    tackleResult: 'Solo Attempt -> Assist Tackle',
    priority: 1,
    tackleOutcomeColor: (palette) => palette.successFail.success.main,
  },
  [TACKLING_OUTCOMES_API_KEYS.ASSISTED_SUCCESS]: {
    soloAttempt: false,
    soloTackle: false,
    success: true,
    tackleResult: 'Assist Attempt -> Assist Tackle',
    priority: 2,
    tackleOutcomeColor: (palette) => palette.successFail.success2.main,
  },
  [TACKLING_OUTCOMES_API_KEYS.SOLO_MISSED]: {
    soloAttempt: true,
    soloTackle: true,
    success: false,
    tackleResult: 'Solo Attempt -> Solo Missed Tackle',
    priority: 3,
    tackleOutcomeColor: (palette) => palette.successFail.fail.main,
  },
  [TACKLING_OUTCOMES_API_KEYS.SOLO_ASSISTED_MISSED]: {
    soloAttempt: true,
    soloTackle: false,
    success: false,
    tackleResult: 'Solo Attempt -> Assist Missed Tackle',
    priority: 4,
    tackleOutcomeColor: (palette) => palette.successFail.fail2.main,
  },
  [TACKLING_OUTCOMES_API_KEYS.ASSISTED_MISSED]: {
    soloAttempt: false,
    soloTackle: false,
    success: false,
    tackleResult: 'Assist Attempt -> Assist Missed Tackle',
    priority: 5,
    tackleOutcomeColor: (palette) => palette.successFail.superFail.main,
  },
};

export {
  TACKLE_LOCATION_CLASSES,
  TACKLE_LOCATION_FIELD_SIZES_X,
  TACKLE_LOCATION_SHAPE_MODES,
  TACKLE_LOCATION_SHAPE_MODE_CONE,
  TACKLE_LOCATION_SHAPE_MODE_TADPOLE,
  TACKLE_LOCATION_SHAPE_MODE_TA_DOTS,
  TACKLE_LOCATION_SHAPE_MODE_BTG_DOTS,
  TACKLE_LOCATION_SHAPE_MODE_TA_HEAT,
  TACKLE_LOCATION_COLOR_MODES,
  TACKLE_LOCATION_COLOR_MODE_PLAY_NEUTRAL,
  TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE,
  TACKLE_LOCATION_Y_MODES,
  TACKLE_LOCATION_Y_MODE_FIELD,
  TACKLE_LOCATION_Y_MODE_SNAP,
  TACKLE_LOCATION_Y_MODE_TA,
  TACKLE_LOCATION_Y_MODE_DY,
  TACKLE_LOCATION_X_MODES,
  TACKLE_LOCATION_X_MODE_LOS,
  TACKLE_LOCATION_X_MODE_TAORIGIN,
  TACKLE_LOCATION_DEFAULT_MARGIN,
  TACKLE_LOCATION_VIOLINS,
  TACKLE_LOCATION_SHAPE_MODE_TA_HEAT_FINAL_DOT,
  TACKLE_LOCATION_SHAPE_MODE_BTG_HEAT,
  KEY_SEGMENT_HEIGHT,
  TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER,
  TACKLE_LOCATION_DISTRO_COLOR_MODES,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLE_LOCATION_DISTRO_COLOR_QUARTILES,
  TACKLING_KEY_METRICS,
  TACKLING_TACKLER_MODES,
  TACKLING_TACKLER_ANY,
  TACKLING_TACKLER_FIRST,
  TACKLING_TACKLER_SUCCESS,
  TACKLING_TACKLER_SOLO_ATTEMPT,
  TACKLING_TACKLER_MISSED_ATTEMPT,
};
