import React from 'react';
import PropTypes from 'prop-types';
import { Personnel, AxisLeftLabel } from './FormationSummaryChart.styles';
import { AXIS_LEFT } from './FormationSummaryChart.constants';

// draws the left axis (labels) of the formation summary bar chart
export const HeaderBarLabel = ({ section, labelY }) => (
  <g transform={`translate(0, ${labelY})`}>
    <Personnel $secondaryLabel={section.secondaryLabel}>
      {section.label}
    </Personnel>
    {section.secondaryLabel && (
      <AxisLeftLabel x={AXIS_LEFT.TEXT_GAP}>
        {`${section.secondaryLabel}`}
      </AxisLeftLabel>
    )}
  </g>
);

HeaderBarLabel.propTypes = {
  // y position of the label
  labelY: PropTypes.number.isRequired,
  // the data that constructs the label
  section: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
};

export default HeaderBarLabel;
