import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';
import { GET_TEAM_STAT_DEFINITIONS } from '../../../../utils/hooks/apiQueries/teamStats/getTeamStatDefinitions';

// returns an enriched and ordered player stat definitions for a given category/subcategory/aggMode combination
export const useTeamStatDefinitions = (orderedSubcategoryStats, isRateMode) => {
  const { data, loading, error } = useQuery(GET_TEAM_STAT_DEFINITIONS, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  if (loading || !orderedSubcategoryStats) {
    return { loading };
  }

  if (error) {
    console.error(ERROR_CODES.USE_TEAM_STAT_DEFINITIONS, error);
    return {
      loading: false,
      error,
    };
  }

  const statDefinitions = orderedSubcategoryStats
    // enrich the list of ordered stats with their corresponding stat definition
    ?.map((stat) =>
      data?.teamStatDefinitions.find(
        (statDefinition) => statDefinition.name === stat
      )
    )
    // we only want the stats for the selected aggregate mode
    .filter((stat) => stat.rate === isRateMode);

  return {
    loading: false,
    data: statDefinitions,
  };
};
