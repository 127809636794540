import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import { GET_PLAY_FINDER_FILTER_TREE } from './getPlayFinderFilterTree';

const PlayFinderFilterTreeContext = createContext();

export const usePlayFinderFilterTreeContext = () =>
  useContext(PlayFinderFilterTreeContext);

export const PlayFinderFilterTreeProvider = ({ children }) => {
  const {
    leagues: leagueParam,
    seasons: seasonParam,
    teams: teamParam,
    offense: offenseParam,
  } = useParams();

  if (!leagueParam || !seasonParam || !teamParam || !offenseParam) {
    return (
      <PlayFinderFilterTreeContext.Provider
        value={{ data: null, loading: false }}
      >
        {children}
      </PlayFinderFilterTreeContext.Provider>
    );
  }

  const { data, loading, error } = useQuery(GET_PLAY_FINDER_FILTER_TREE, {
    skip: !leagueParam || !seasonParam || !teamParam || !offenseParam,
    variables: {
      competitionId: parseInt(leagueParam, 10),
      seasonId: parseInt(seasonParam, 10),
      teamId: parseInt(teamParam, 10),
      offense: JSON.parse(offenseParam),
    },
  });
  if (error) {
    console.error(ERROR_CODES.GET_PLAY_FINDER_FILTER_TREE, error);
  }

  return (
    <PlayFinderFilterTreeContext.Provider value={{ data, loading }}>
      {children}
    </PlayFinderFilterTreeContext.Provider>
  );
};

PlayFinderFilterTreeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
