import { isEmpty, isEqual, orderBy, sum } from 'lodash';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { METRIC_SLIDER_DEFAULTS } from '../ScoutResults.constants';
import { getScale, getScaleFormatter } from '../ScoutResults.dataManipulation';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
const removeAccents = (str) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
/* 
Gives a value for how similar a search term is to another string
TODO: implement some form of actual fuzzy compare rather than just includes
*/
const nearnessValue = (baseText, searchValue) => {
  if (!baseText?.length || !searchValue?.length) {
    return -1;
  }
  const base = removeAccents(baseText);
  const searchTerm = removeAccents(searchValue);
  /* If the seach term is the same */
  if (isEqual(base, searchTerm)) {
    return 1;
  }
  /* If the search term is in the base term */
  if (base.includes(searchTerm)) {
    return 0.99;
  }
  const splitSearchTerms = searchTerm.split(' ');
  if (splitSearchTerms.length > 1) {
    const termValues = splitSearchTerms.map((t) => (base.includes(t) ? 1 : 0));
    return sum(termValues) / termValues.length;
  }
  return 0;
};
/* Grades each metric for similarity to search term, then returns full list */
export const sortStatsBySearch = (statDefinitions, searchValue) => {
  if (isEmpty(statDefinitions)) {
    return [];
  }
  const rankedDefinitions = statDefinitions.map((def) => {
    const nameNearness = nearnessValue(def?.name, searchValue);
    const abrevNearness = nearnessValue(def?.abbrev, searchValue);
    const descriptionNearness = nearnessValue(def?.description, searchValue);
    const nearness = nameNearness * 10 + abrevNearness + descriptionNearness;
    return { ...def, nearness };
  });

  const sortedDefinitions = orderBy(
    rankedDefinitions,
    ['nearness', 'name'],
    [DESCENDING, ASCENDING]
  );
  return sortedDefinitions;
};

export const getSliderMetric = (selectedMetric, metricDistribution) => {
  /* If there is no selected metric or distro not yet back */
  const sliderMetric = { ...selectedMetric };
  if (!selectedMetric?.key || metricDistribution?.name !== selectedMetric.key) {
    sliderMetric.min = METRIC_SLIDER_DEFAULTS.MIN;
    sliderMetric.max = METRIC_SLIDER_DEFAULTS.MAX;
    sliderMetric.sliderStep =
      (METRIC_SLIDER_DEFAULTS.MAX - METRIC_SLIDER_DEFAULTS.MIN) /
      METRIC_SLIDER_DEFAULTS.STEPS;
    sliderMetric.mean =
      (METRIC_SLIDER_DEFAULTS.MAX + METRIC_SLIDER_DEFAULTS.MIN) / 2;
    sliderMetric.stdDev = sliderMetric.sliderStep;
    sliderMetric.defaultMode = true;
    sliderMetric.lowerIsBetter = false;
  } else {
    /* Real distro data */
    const {
      min: sliderMin,
      max: sliderMax,
      sliderStep,
    } = getScale(metricDistribution.percentiles);
    const formatter = getScaleFormatter(
      sliderMax - sliderMin,
      selectedMetric?.unitType
    );

    sliderMetric.min = sliderMin;
    sliderMetric.max = sliderMax;
    sliderMetric.sliderStep = sliderStep;
    sliderMetric.mean = formatter(metricDistribution.mean);
    sliderMetric.stdDev = formatter(metricDistribution.stddev);
    sliderMetric.defaultMode = false;
  }
  return sliderMetric;
};
