import { gql, useQuery } from '@apollo/client';
import { omit, reverse, uniqBy, sortBy, sumBy, join } from 'lodash';
import { API_PLAYER_GROUP_BY } from '../constants/api';
import { getPlayersStatPositionUsage } from './apiQueries/playerStats/getPlayerStatPositionUsage';
import { getAlignmentPositionByCode } from '../helpers/positions';
import { ERROR_CODES } from '../errorCodes/errorCodes';

export const useGetPlayerStatPositionUsage = ({
  selectedTeamId,
  selectedPlayerId,
  apiPlayTypes,
  alignmentPositions,
  rosterPositions,
  additionalPlayFilters,
  useContextFilters,
}) => {
  const playerIds = selectedPlayerId ? [selectedPlayerId] : [];
  const playerStatsQuery = getPlayersStatPositionUsage(
    additionalPlayFilters,
    useContextFilters,
    apiPlayTypes
  );
  const { data, loading, error } = useQuery(gql(playerStatsQuery), {
    variables: {
      alignmentPositions: alignmentPositions || [],
      rosterPositions: rosterPositions || [],
      playerIds,
    },
  });

  if (loading) {
    return { loading };
  }
  if (error) {
    console.error(ERROR_CODES.GET_PLAYER_POSITION_STATS, error);
    return { error, loading };
  }

  if (!data?.playerStats?.items?.length) {
    return { playerStatsData: [] };
  }

  /* 
  This query uses fixed group by of player/team/alignment position:
    simplify the output by merging in the groups
    Ignore alignment position ~ the group gives the database Id, the alignment code is in playPosition
  */
  const niceData = data?.playerStats?.items?.map((player) => {
    const playerId = player.groups.find(
      (g) => g.key === API_PLAYER_GROUP_BY.PLAYER
    )?.value;
    const teamId = player.groups.find(
      (g) => g.key === API_PLAYER_GROUP_BY.TEAM
    )?.value;
    const nicePlayer = {
      ...player,
      playerId,
      teamId,
    };
    return omit(nicePlayer, 'groups');
  });

  /* 
  Player Stats team filter works at game level
    If have a specific team: throw away all other team's players
  */
  const teamData = selectedTeamId
    ? niceData.filter((d) => d.teamId === selectedTeamId)
    : niceData;

  /*
  Finally want to merge down to a single row per player, with details of their usages by alignment position
  */
  const players = uniqBy(teamData, 'playerId');
  const playerPositionUsages = players.map((player) => {
    const playsData = reverse(
      sortBy(
        teamData.filter((t) => t.playerId === player.playerId),
        'plays'
      )
    );
    const totalPlays = sumBy(playsData, 'plays');
    // Future note: if wanting to display only top X positions, filtering should happen here
    const positions = playsData.map((p) => {
      const alignmentPosition = getAlignmentPositionByCode(p.playPosition);
      const positionUsage = {
        ...alignmentPosition,
        plays: p.plays,
        percentage: totalPlays ? p.plays / totalPlays : 0,
      };
      return positionUsage;
    });
    /* 
    Finally turn each position usage into a string then merge them
    */
    const positionUsageStrings = positions.map(
      (pos) =>
        `${pos.name}: ${pos.plays} (${
          pos.percentage ? (100 * pos.percentage).toFixed(1) : 0
        }%)`
    );
    const positionUsageDescription = positionUsageStrings?.length
      ? join(positionUsageStrings, '\n')
      : '-';

    const primaryPositionCode = positions[0]?.code || '-';
    return {
      ...player,
      plays: totalPlays,
      positions,
      positionUsageDescription,
      primaryPositionCode,
    };
  });

  return { playerStatsData: playerPositionUsages, loading: false };
};
