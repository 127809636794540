import React, { useEffect } from 'react';
import { max, scaleBand, scaleLinear, select } from 'd3';
import { useTheme } from 'styled-components';
import { isEmpty } from 'lodash';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../../utils/constants/visText';
import { distributionData } from './PlayFinderSummary.constants';
import { getPalette } from '../../../../utils/visualisations/visPalettes';
import { useD3 } from '../../../../utils/hooks/useD3';
import { PlayFinderSummaryBarsWrapper } from './PlayFinderSummaryBars.styles';

export const PlayFinderSummaryBars = ({ data: summaryData }) => {
  if (isEmpty(summaryData)) return null;

  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;
  const visPaletteLight = getPalette(false);
  const GUTTER_FOR_LABELS = 110;

  // Set the dimensions and margins of the chart
  const margin = { top: 0, right: 48, bottom: 0, left: GUTTER_FOR_LABELS };
  const width = 550 - margin.left - margin.right;
  const height = 89 - margin.top - margin.bottom;

  const ref = useD3((svg) => {
    // Reset svg
    svg.selectAll('*').remove();

    svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('id', `base-chart-group`)
      .attr('transform', `translate(${margin.left},${margin.top})`);
  });

  useEffect(() => {
    const svg = select(ref.current);
    const chartGroup = svg.select('#base-chart-group');

    // Set the ranges
    const y = scaleBand().range([height, 0]).padding(0.4);
    const x = scaleLinear().range([0, width]);

    // Scale the range of the data in the domains
    x.domain([0, max(summaryData, (d) => d.percentage)]);
    y.domain(summaryData.map((d) => d.name));
    const barHeight = Math.max(18, y.bandwidth());

    // Append the bars for the bar chart
    chartGroup
      .selectAll('.bar')
      .data(summaryData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('fill', visPalette.objects.n1.main)
      .attr('width', 0)
      .attr('y', (d) => y(d.name))
      .attr('height', barHeight)
      .attr('width', (d) => x(d.percentage));

    // Append labels inside the bars, anchored to the left-hand side of the bar
    chartGroup
      .selectAll('.label')
      .data(summaryData)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', 5)
      .attr('y', (d) => y(d.name) + barHeight / 2) // Adjusting y for vertical centering
      .attr('dy', '0.35em') // Align text vertically in the middle
      .attr('font-family', DEFAULT_FONT)
      .attr('font-size', VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
      .attr('text-anchor', 'start')
      .text((d) => `${d.percentage.toFixed(2)}%`)
      .attr('fill', visPaletteLight.text.info);

    // Append labels to the left of each bar, right-aligned to the bar
    chartGroup
      .selectAll('.name-label')
      .data(summaryData)
      .enter()
      .append('text')
      .attr('class', 'name-label')
      .attr('font-family', DEFAULT_FONT)
      .attr('font-size', VISUALISATION_FONT_SETUPS.AREA_TITLE.SIZE)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AREA_TITLE.WEIGHT)
      .attr('x', -10)
      .attr('y', (d) => y(d.name) + barHeight / 2) // Adjusting y for vertical centering
      .attr('dy', '0.35em') // Align text vertically in the middle
      .attr('text-anchor', 'end')
      .text((d) => d.name)
      .attr('fill', visPalette.text.header);
  }, [isDark, visPalette, summaryData]);

  return (
    <PlayFinderSummaryBarsWrapper id="play-finder-summary-bars" ref={ref} />
  );
};

PlayFinderSummaryBars.propTypes = {
  data: distributionData,
};

PlayFinderSummaryBars.defaultProps = {
  data: [],
};
