import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { sortBy } from 'lodash';
import { useTheme } from 'styled-components';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';
import GoTo from '../../../components/buttons/GoTo';
import { mf_Leagues, mf_Seasons, mf_Teams } from '../../../apollo';
import BasicTable from '../../../components/Table/BasicTable';
import { scheduleTileHeaders } from './TeamSchedule.Tile.constants';
import {
  getResult,
  parseScheduleScore,
} from '../TeamSchedule/TeamSchedule.helpers';
import { GET_TEAM_SCHEDULE } from '../TeamSchedule/getTeamSchedule';
import { formatValue } from '../../League/stats/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { OverviewTileMessage } from '../../player/PlayerOverview/PlayerOverview.styles';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';

const TeamScheduleTile = () => {
  const mf_TeamsRV = useReactiveVar(mf_Teams);
  const mf_SeasonsRV = useReactiveVar(mf_Seasons);
  const mf_LeaguesRV = useReactiveVar(mf_Leagues);
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const {
    data: teamScheduleData,
    loading: isLoading,
    error: hasError,
  } = useQuery(GET_TEAM_SCHEDULE);
  if (hasError) {
    console.error(ERROR_CODES.GET_TEAM_SCHEDULE, hasError);
  }

  const parsedSchedule = teamScheduleData?.teamSchedule?.map((week) => {
    const isHomeTeam = week.game.homeTeam?.id === mf_TeamsRV;

    return {
      date: week.game.date,
      week: week.game.roundShortName,
      kickoff: week.game.kickoff?.slice(0, -3), // trim seconds
      conference:
        week.game.homeTeam.conferenceShortName ===
        week.game.awayTeam.conferenceShortName
          ? 'conf'
          : 'non-conf',
      result: getResult(
        week.game.homeTeam?.id,
        mf_TeamsRV,
        week.game.homeScore,
        week.game.awayScore,
        week.gameAvailable,
        visPalette
      ),
      isAway: isHomeTeam ? 'vs' : '@',
      opponent: isHomeTeam
        ? week.game.awayTeam.shortName
        : week.game.homeTeam.shortName,
      offensiveEpaPerDrive: formatValue(
        isHomeTeam ? week.homeEpaPerDrive : week.awayEpaPerDrive,
        API_STAT_UNITS.EPA
      ),
      defensiveEpaPerDrive: formatValue(
        isHomeTeam ? week.awayEpaPerDrive : week.homeEpaPerDrive,
        API_STAT_UNITS.EPA
      ),
      pbp: week.gameAvailable ? (
        <span style={{ display: 'flex', gap: '0.6rem' }}>
          <GoTo
            href={`/game/byplay/${mf_LeaguesRV}/${mf_SeasonsRV}/${mf_TeamsRV}/${week.game.id}`}
            linkName="Open play by play for this game"
            iconSize="tiny"
            ghost
          />
        </span>
      ) : (
        ''
      ),
      disabled: !week.gameAvailable,
      score: isHomeTeam
        ? parseScheduleScore(week.game.homeScore, week.game.awayScore)
        : parseScheduleScore(week.game.awayScore, week.game.homeScore),
    };
  });

  const loadingMsg = () => {
    if (isLoading) {
      return 'Loading Team Schedule ';
    }
    return '';
  };

  const sortedSchedule = sortBy(parsedSchedule, 'date');

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Schedule</h3>
        </div>
        <div>
          <GoTo
            href="/team/schedule/:leagues/:seasons/:teams"
            linkName="Open team schedule"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {!hasError && !isLoading && parsedSchedule && (
          <BasicTable
            data={sortedSchedule}
            headers={scheduleTileHeaders}
            showHeader
            maxHeight={480}
            withBorder={false}
          />
        )}
        {!parsedSchedule && !isLoading && (
          <OverviewTileMessage>
            <p>No Data Available.</p>
          </OverviewTileMessage>
        )}
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content={loadingMsg()} />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
        <SummaryTileCol $marginRight="-1rem" />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default TeamScheduleTile;
