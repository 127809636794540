export const PASS_PLACEMENT_BALL_DISPLAY_OPTIONS = [
  {
    label: 'Approximate Ball Size',
    value: 0,
    variant: 'WomensNoLetter',
    iconHalfSize: false,
  },
  {
    label: 'Ball Location',
    value: 1,
    variant: 'MensNoLetter',
    iconHalfSize: true,
  },
];
