import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sortBy, reverse, omit } from 'lodash';
import DownloadCSVButton from '../../../../components/buttons/DownloadCSV';
import Tile from '../../../../components/Tile/Tile';
import { TableTileHeader } from '../../../../components/TableTile/TableTile.styles';
import SortableTable from '../../../../components/Tables/SortableTable/SortableTable';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { formatPlayers } from './TacklingPlayerTable.dataManipulation';
import { getTacklingPlayerTableHeaders } from './TacklingPlayerTable.constants';
import { API_STAT_UNITS } from '../../../../utils/constants/api';
import { TacklingTableContainer } from './TacklingPlayerTable.styles';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
const stickyColumns = [{ index: 1, width: 15 }];

const TacklingPlayerTable = ({
  bodyData,
  footerData,
  tileTitle,
  showDefensive,
  fileName,
  error,
  loading,
}) => {
  const { columnHeaders, defaultColumnKey } =
    getTacklingPlayerTableHeaders(showDefensive);

  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [sortKey, setSortKey] = useState(defaultColumnKey);
  useEffect(() => {
    if (!columnHeaders.map((h) => h.isSortable.key).includes(sortKey)) {
      setSortKey(defaultColumnKey);
    }
  }, [showDefensive]);

  const handleSort = (statName) => {
    if (statName === sortKey) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      const sortColumn = columnHeaders.find((h) => h.key === statName);
      const defaultSortDirection =
        sortColumn?.units === API_STAT_UNITS.STRING ? ASCENDING : DESCENDING;
      setSortDirection(defaultSortDirection);
      setSortKey(statName);
    }
  };
  const sortedData = sortBy(bodyData, sortKey);
  const orientatedData =
    sortDirection === DESCENDING ? reverse(sortedData) : sortedData;
  /* Lastly use the units in the headers to format the data */
  const formattedPlayers = formatPlayers(orientatedData, columnHeaders);
  const formattedFooters = formatPlayers(footerData, columnHeaders);
  const csvData = formatPlayers(orientatedData, columnHeaders, true);

  /* 
  Format the headers in sortable table structure 
  NOTE: Uses the term "id" not "key" as the parameter passed into the handleSort function
  */
  const stringHeaderIndexes = columnHeaders
    .map((h, i) => (h.units === API_STAT_UNITS.STRING ? i : null))
    .filter((m) => m !== null);
  const alignmentFunction = (columnIndex) =>
    stringHeaderIndexes.includes(columnIndex) ? 'left' : 'right';
  const sortableTableHeaders = columnHeaders.map((header) => {
    const unitlessHeader = omit(header, 'units');
    return { ...unitlessHeader, id: header.key };
  });

  return (
    <Tile>
      <Tile.Header $isSlim>
        <TableTileHeader data-testid="tackling-table-header">
          {tileTitle && (
            <>
              <h2 title={tileTitle}>{tileTitle}</h2>
            </>
          )}
          {!error && !loading && bodyData && (
            <div>
              <DownloadCSVButton
                data={csvData}
                headers={columnHeaders}
                fileName={fileName}
                data-testid="tackling-table-download-button"
                iconSize="small"
              />
            </div>
          )}
        </TableTileHeader>
      </Tile.Header>
      <Tile.Body $padding="0" data-testid="tackling-table-tile">
        {!error && !loading && bodyData && (
          <TacklingTableContainer>
            <SortableTable
              headers={sortableTableHeaders}
              rows={formattedPlayers}
              footerRows={formattedFooters}
              name="tackling-player-table"
              stickyColumns={stickyColumns}
              alignment={alignmentFunction}
              handleSort={handleSort}
              sortDirection={sortDirection}
              sortBy={sortKey}
              isHeadSticky
            />
          </TacklingTableContainer>
        )}
      </Tile.Body>
    </Tile>
  );
};

TacklingPlayerTable.propTypes = {
  bodyData: PropTypes.arrayOf(PropTypes.shape({})),
  footerData: PropTypes.arrayOf(PropTypes.shape({})),
  tileTitle: PropTypes.string.isRequired,
  showDefensive: PropTypes.bool,
  fileName: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};

TacklingPlayerTable.defaultProps = {
  bodyData: undefined,
  footerData: undefined,
  showDefensive: true,
  fileName: 'table.csv',
  error: false,
  loading: false,
};

export default TacklingPlayerTable;
