import React from 'react';
import PropTypes from 'prop-types';
import { SCOUT_BIOMETRIC_FILTERS } from '../../ScoutResults.constants';
import { biometricProp } from '../../../Scout.proptypes';
import { TriButtonToggle } from '../../../../../components/buttons/TriButtonToggle/TriButtonToggle';

const TransferPortalTriToggle = ({
  selectedBiometrics,
  setSelectedBiometrics,
}) => {
  const portalFilter = selectedBiometrics.find(
    (b) => b.key === SCOUT_BIOMETRIC_FILTERS.ncaaTransferPortal.key
  );
  const inPortal = portalFilter?.key ? portalFilter.value : null;

  const setPortal = (portalState) => {
    const otherBiometrics = selectedBiometrics.filter(
      (b) => b.key !== SCOUT_BIOMETRIC_FILTERS.ncaaTransferPortal.key
    );
    if (portalState === null) {
      setSelectedBiometrics(otherBiometrics);
    } else {
      const newPortalFilter = {
        key: SCOUT_BIOMETRIC_FILTERS.ncaaTransferPortal.key,
        value: portalState,
      };
      setSelectedBiometrics(otherBiometrics.concat(newPortalFilter));
    }
  };

  return (
    <TriButtonToggle
      selectionState={inPortal}
      setSelectionState={setPortal}
      labels={{
        true: 'In Portal',
        false: 'Not in portal',
        null: 'All players',
      }}
    />
  );
};
TransferPortalTriToggle.propTypes = {
  // list of currently selected biometric filters
  selectedBiometrics: PropTypes.arrayOf(biometricProp).isRequired,
  // function to update the selected biometrics
  setSelectedBiometrics: PropTypes.func.isRequired,
};

export default TransferPortalTriToggle;
