import { styled } from 'styled-components';

// Styles and override=s for the flyout panel widgets returned form the API
export const ConfigWidgetSlider = styled.div`
  .track {
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${({ theme }) => theme.typography.bodyMedium};
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }
`;

export const ConfigWidgetSelectSingle = styled.div`
  // override for the RadioGroup
  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    width: 435px;

    h4 {
      display: none;
    }
  }
`;

export const ConfigWidgetSelectMultiple = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  width: 435px;
`;

export const ConfigWidgetBool = styled.div`
  display: flex;
  gap: 2rem;

  label {
    width: 130px;
  }
`;
