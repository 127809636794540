import { API_STAT_UNITS } from '../../../../utils/constants/api';

export const HAVOC_SUMMARY_TOTALS = {
  PLAYS: {
    playCount: {
      key: 'playCount',
      label: 'Plays',
      units: API_STAT_UNITS.COUNT,
    },
    runRate: {
      key: 'runRate',
      label: 'Run%',
      units: API_STAT_UNITS.PERCENTAGE,
    },
    runPlays: {
      key: 'runPlays',
      label: 'Run Plays',
      units: API_STAT_UNITS.COUNT,
    },
    passPlays: {
      key: 'passPlays',
      label: 'Pass Plays',
      units: API_STAT_UNITS.COUNT,
    },
  },
  HAVOC: {
    havocPlays: {
      key: 'havocPlays',
      label: 'Havoc Plays',
      units: API_STAT_UNITS.COUNT,
    },
    havocEvents: {
      key: 'havocEvents',
      label: 'Havoc Events',
      units: API_STAT_UNITS.COUNT,
    },
  },
};
