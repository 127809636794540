import { styled } from 'styled-components';
import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../utils/constants/charting';

export const ClickableSVG = styled.svg`
  .${VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS} {
    cursor: pointer;
  }
`;

ClickableSVG.displayName = 'ClickableSVG';
