import { isEmpty, orderBy, sortBy } from 'lodash';
import { SCOUT_SETUP_COLUMNS } from '../Scout.constants';
import { toLocalDateTime } from '../../../utils/helpers/formatting';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { ROSTER_POSITIONS } from '../../../utils/constants/positions';

export const positionDisplay = (selectedPositions) => {
  if (isEmpty(selectedPositions)) {
    return '-';
  }
  if (selectedPositions.length > 1) {
    const rosterPositions = selectedPositions.map((p) => ROSTER_POSITIONS[p]);
    const firstPosition = sortBy(rosterPositions, 'name')[0];
    return `${firstPosition.code} +${rosterPositions.length - 1}`;
  }
  return ROSTER_POSITIONS[selectedPositions[0]].code;
};
/* Selects the data from the setup used to display the list */
export const formatTemplate = (template) => {
  const formattedDate = template?.lastModified
    ? toLocalDateTime(template?.lastModified)
    : '-';
  const formattedSetup = {
    id: template?.id, // will not show as a column
    [SCOUT_SETUP_COLUMNS.NAME.key]: template?.name || template?.id,
    [SCOUT_SETUP_COLUMNS.POSITIONS.key]: positionDisplay(
      template?.selectedPositions
    ),
    [SCOUT_SETUP_COLUMNS.METRICS.key]: template?.selectedStats?.length || 0,
    [SCOUT_SETUP_COLUMNS.EDITOR.key]: template?.lastEditor,
    [SCOUT_SETUP_COLUMNS.MODIFIED.key]: formattedDate,
  };
  return formattedSetup;
};

/* Selects the data from the setup used to display the list */
export const formatScoutSetupsSelectionTable = (scoutSetups) => {
  if (isEmpty(scoutSetups)) {
    return [];
  }
  const tableSetups = scoutSetups.map(formatTemplate);

  const recentFirst = orderBy(
    tableSetups,
    ['lastModified'],
    [SORT_DIRECTIONS.DESCENDING]
  );

  return recentFirst;
};
