import { styled } from 'styled-components';

export const EmbeddedVideo = styled.div`
  height: 449px;

  iframe {
    height: 100%;
    width: 100%;
  }
`;
EmbeddedVideo.displayName = 'EmbeddedVideo';

export const BasicTableWrapper = styled.div`
  table {
    padding: 0.0625rem 0 0.125rem 0;
  }
`;
BasicTableWrapper.displayName = 'BasicTableWrapper';

export const PlaylistContainer = styled.div`
  width: 100%;
  border-top: ${({ theme }) =>
    `0.5rem solid ${theme.colours.canvas.primary.main}`};
`;

export const StatsBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8125rem;
  padding: 1rem;
  min-height: 7.3125rem;
  flex-direction: column;

  @media (min-width: 1440px) {
    flex-direction: row;
  }
  @media (max-width: 1028px) {
    flex-direction: row;
  }

  a {
    color: ${({ theme }) => theme.colours.ink.primary.main};
    text-decoration: underline;
  }

  a:hover {
    color: ${({ theme }) => theme.colours.ink.highlight.main};
  }
`;

export const StatsBlockLeftColumn = styled.div`
  display: flex;
  gap: 3.2rem;
  flex-direction: row;
`;

export const StatsBlockRightColumn = styled.div`
  @media (min-width: 1440px) {
    border-left: ${({ theme }) =>
      `1px solid ${theme.colours.canvas.primary.main}`};
    padding-left: 1rem;
    margin-left: 1rem;
  }
  @media (max-width: 1028px) {
    border-left: ${({ theme }) =>
      `1px solid ${theme.colours.canvas.primary.main}`};
    padding-left: 1rem;
    margin-left: 1rem;
  }
  border-left: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.primary.main}`};
  padding-left: 1rem;
  margin-left: 1rem;
`;

export const StatsBlockItemHeader = styled.div`
  color: ${({ theme }) =>
    theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  font-size: 0.625rem;
  padding-bottom: 0.5rem;
`;

export const StatsBlockItem = styled.div`
  color: ${({ theme }) =>
    theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  font-size: 0.75rem;
`;

export const OutcomeRoundel = styled.div`
  position: relative;
  padding-left: 1rem;

  &::before {
    position: absolute;
    left: 0;
    top: 0.125rem;
    content: '';
    border: ${({ theme, $color }) =>
      `${$color ? '5px' : '0px'} solid ${
        $color || theme.colours.ink.primary.main
      }`};
    border-radius: 50%;
  }
`;

export const StatsPlayersWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: max-content;
`;

export const VideoStyleWrapper = styled.div`
  aside {
    // sticky header for playlist
    ol {
      overflow: auto;
      max-height: 17.5rem;
    }
  }
`;
