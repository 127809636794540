import { styled } from 'styled-components';

export const AccordionSaveRow = styled.div`
  width: 100%;

  & > button {
    float: right;
    margin-left: 8px;

    *:not(:first-child) {
      margin-left: 8px;
    }
  }
  /*the Button Group component */
  & > div {
    float: right;

    button {
      *:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
`;
AccordionSaveRow.displayName = 'AccordionSaveRow';

export const DeleteModalContent = styled.div`
  width: 50vw;

  div {
    margin-bottom: 8px;
  }
`;
DeleteModalContent.displayName = 'DeleteModalContent';

export const ExplanationBlurb = styled.div`
  span {
    white-space: pre-wrap;
  }
`;
ExplanationBlurb.displayName = 'ExplanationBlurb';
