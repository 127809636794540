import React from 'react';
import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3';
import { StyledFormationSummaryChart } from './FormationSummaryChart.styles';
import { AxisTop } from './AxisTop';
import { Labels } from './Labels';
import { Bars } from './Bars';
import {
  FORMATION_SUMMARY_FONTS,
  BASE_VIS_WIDTH,
  BARS,
  AXIS_TOP,
  VIS_GAPS,
} from './FormationSummaryChart.constants';
import { marginPropType } from '../../utils/constants/propTypes';
import { getSectionHeight } from './FormationSummaryChart.dataManipulation';
import { useXScaleDomain } from './FormationSummaryChart.hooks';
import { DEFAULT_VISUALISATION_MARGIN } from '../../utils/constants/charting';
import { HeaderBar } from './HeaderBar';
import { HeaderBarLabel } from './HeaderBarLabel';

const FormationSummaryChart = ({
  id,
  data,
  isTeamMode,
  isPersonnelMode,
  marginOverride,
}) => {
  const margin = {
    ...DEFAULT_VISUALISATION_MARGIN,
    ...marginOverride,
  };
  const verticalVisMargin = margin.top + margin.bottom;
  const horizontalMargin = margin.left + margin.right;

  const visWidth = BASE_VIS_WIDTH + horizontalMargin;

  const largeBarHeight = BARS.LARGE_HEIGHT + BARS.BAR_GAP;
  const headerBars = data.length * largeBarHeight;
  const sections = data.reduce(
    (acc, section) => acc + getSectionHeight(section.data),
    0
  );
  const sectionGap = isPersonnelMode ? BARS.SECTION_GAP : BARS.SECTION_GAP / 2;
  const totalSectionGap = sectionGap * (data.length - 1);
  const dataHeight = headerBars + sections + totalSectionGap;

  const visHeight =
    verticalVisMargin +
    AXIS_TOP.GUIDE_GAP +
    FORMATION_SUMMARY_FONTS.NORMAL.SIZE / 2 +
    BARS.OUTER_VERTICAL_PADDING * 2 +
    dataHeight;

  const viewBox = `0 0 ${visWidth} ${visHeight}`;

  const fromX = VIS_GAPS.LEFT;
  const toX = visWidth - margin.right - VIS_GAPS.RIGHT;
  const xScaleDomain = useXScaleDomain(isTeamMode, isPersonnelMode);
  const xScale = scaleLinear()
    .domain(xScaleDomain)
    .range([fromX, toX])
    .clamp(true);

  const barsYOffset =
    AXIS_TOP.GUIDE_GAP +
    FORMATION_SUMMARY_FONTS.NORMAL.SIZE +
    BARS.OUTER_VERTICAL_PADDING;

  return (
    <StyledFormationSummaryChart id={id} viewBox={viewBox}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <AxisTop xScale={xScale} isTeamMode={isTeamMode} />
        {data.map((section, index) => {
          const previousData = data.slice(0, index);
          const currentSectionGap = previousData.length * sectionGap;

          const fromHeight =
            previousData.reduce((acc, s) => acc + getSectionHeight(s.data), 0) +
            currentSectionGap +
            largeBarHeight * (index + 1);

          const toHeight = getSectionHeight(section.data) + fromHeight;

          const yScaleDomain = section.data?.map((d) => d.label) || [];
          const yScale = scaleBand()
            .domain(yScaleDomain)
            .range([fromHeight, toHeight]);

          const headerBarY = fromHeight - largeBarHeight;
          const headerBarLabelY =
            fromHeight - BARS.LARGE_HEIGHT / 2 - BARS.BAR_GAP / 2;

          return (
            <g key={section.label} transform={`translate(0, ${barsYOffset})`}>
              <HeaderBarLabel section={section} labelY={headerBarLabelY} />
              <HeaderBar
                section={section}
                xScale={xScale}
                barY={headerBarY}
                isTeamMode={isTeamMode}
              />
              <Labels yScale={yScale} data={section.data} />
              <Bars data={section.data} xScale={xScale} yScale={yScale} />
            </g>
          );
        })}
      </g>
    </StyledFormationSummaryChart>
  );
};

FormationSummaryChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  marginOverride: marginPropType,
  isTeamMode: PropTypes.bool.isRequired,
  isPersonnelMode: PropTypes.bool.isRequired,
};

FormationSummaryChart.defaultProps = {
  marginOverride: {},
};

export default FormationSummaryChart;
