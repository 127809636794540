import PropTypes from 'prop-types';
import { RUN_TENDENCY_POSITION_KEYS } from './RunTendencies.constants';

export const runGapBubbleDatumShape = PropTypes.shape({
  gameName: PropTypes.string,
});

/* Player ids for each position */
export const selectedPlayersShape = PropTypes.exact({
  [RUN_TENDENCY_POSITION_KEYS.leftTackle]: PropTypes.number,
  [RUN_TENDENCY_POSITION_KEYS.leftGuard]: PropTypes.number,
  [RUN_TENDENCY_POSITION_KEYS.center]: PropTypes.number,
  [RUN_TENDENCY_POSITION_KEYS.rightTackle]: PropTypes.number,
  [RUN_TENDENCY_POSITION_KEYS.rightGuard]: PropTypes.number,
  [RUN_TENDENCY_POSITION_KEYS.ballCarrier]: PropTypes.number,
});

/* Matches outcome from lineBattles.dataManipulation/getPlayerList */
const playerListShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    snapCount: PropTypes.number,
    player: PropTypes.string,
  })
);
export const playersListsShape = PropTypes.exact({
  [RUN_TENDENCY_POSITION_KEYS.leftTackle]: playerListShape,
  [RUN_TENDENCY_POSITION_KEYS.leftGuard]: playerListShape,
  [RUN_TENDENCY_POSITION_KEYS.center]: playerListShape,
  [RUN_TENDENCY_POSITION_KEYS.rightTackle]: playerListShape,
  [RUN_TENDENCY_POSITION_KEYS.rightGuard]: playerListShape,
  [RUN_TENDENCY_POSITION_KEYS.ballCarrier]: playerListShape,
});

const runTendencyAxisValuesShape = PropTypes.shape({
  DOMAIN: PropTypes.arrayOf(PropTypes.number),
  NICE: PropTypes.bool,
  TICKS: PropTypes.number,
  INVERT: PropTypes.bool,
});
export const runTendencyAxisShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
  gap: runTendencyAxisValuesShape,
  poa: runTendencyAxisValuesShape,
  colorFunction: PropTypes.func,
  unitType: PropTypes.string,
});

/* Used by scatter and poa histograms as the individual datum structure */
export const runDatumPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yardsGained: PropTypes.number,
  plays: PropTypes.number,
  successRate: PropTypes.number,
  playPercentage: PropTypes.number,
  yardsGainedOverAverage: PropTypes.number,
  successVsAverage: PropTypes.number,
});
