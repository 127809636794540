import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../../../../utils/errorCodes/errorCodes';
import { GET_PLAYER_STAT_DISTRIBUTION } from './getPlayerStatDistribution';

export const useGetPlayerStatDistribution = (
  selectedMetric,
  selectedPosition
) => {
  const { data, loading, error } = useQuery(GET_PLAYER_STAT_DISTRIBUTION, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    variables: {
      position: selectedPosition,
      statNames: [selectedMetric],
    },
    notifyOnNetworkStatusChange: true,
  });
  if (loading) {
    return { isLoading: true };
  }
  if (error) {
    console.error(ERROR_CODES.USE_STAT_DISTRIBUTION, error);
    return {
      isLoading: false,
      error,
    };
  }

  const statDistribution = data?.playerStatDistributions?.[0];
  return {
    isLoading: false,
    statDistribution,
  };
};
