import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { Icon } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import Tile from '../../../components/Tile/Tile';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import GoTo from '../../../components/buttons/GoTo';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { SummaryError } from '../../../components/Tile/TileSummary.styles';
import { useDepthChartPlayers } from '../TeamDepthChart/TeamDepthChart.hooks';
import { ALL_PACKAGES_OPTION } from '../TeamDepthChart/TeamDepthChart.constants';
import DepthChartTable from '../TeamDepthChart/DepthChartTable';
import { TileInlineLoader } from './TeamOverview.styles';

const TeamDepthTile = ({ offense }) => {
  const {
    data: depthChartPlayersData,
    loading: isLoading,
    error: hasError,
  } = useDepthChartPlayers(
    offense,
    false /* group by alignment */,
    ALL_PACKAGES_OPTION.value
  );
  const tileHeight = 477; // the height of the grid row that this tile is in
  const orderedTables = orderBy(
    depthChartPlayersData,
    ['order'],
    [SORT_DIRECTIONS.ASCENDING]
  );

  return (
    <Tile margin="0" border="1px" padding="0">
      <Tile.Header $border="2px">
        <h3>Depth Chart</h3>
        <div className="buttons">
          <GoTo
            href="/team/depth/:leagues/:seasons/:teams"
            linkName="Open team depth chart"
            iconSize="small"
          />
        </div>
      </Tile.Header>
      <Tile.Body
        $overflowY="scroll"
        $maxHeight={`${tileHeight}px`}
        $padding={isLoading || hasError ? '0 0.5rem' : '1rem 1rem 0 1rem'}
        $margin="0 0.5rem 0.0625rem 0"
      >
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        {(isLoading || hasError) && (
          <Dimmer
            active
            style={{ height: `${tileHeight}px`, position: 'relative' }}
          />
        )}
        {!isLoading &&
          orderedTables.map((table) => (
            <DepthChartTable
              key={table.position}
              data={table.data}
              positionApiKey={table.position}
              isHeadSticky={false}
            />
          ))}
        {isLoading && (
          <TileInlineLoader>
            <Loader
              active
              inline
              size="small"
              content="Loading Depth Chart Data"
            />
          </TileInlineLoader>
        )}
      </Tile.Body>
    </Tile>
  );
};

TeamDepthTile.propTypes = {
  offense: PropTypes.bool,
};

TeamDepthTile.defaultProps = {
  offense: true,
};

export default TeamDepthTile;
