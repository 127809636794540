import { styled } from 'styled-components';

export const TableTileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.8;
  }
`;

TableTileHeader.displayName = 'TableTileHeader';
