import { styled } from 'styled-components';

export const TileToolbar = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  gap: 0.5rem;
`;
TileToolbar.displayName = 'TileToolbar';

export const RadarContentLayout = styled.div`
  display: flex;
  flex-direction: row;

  h3 {
    line-height: 1.044375rem;
  }

  @media (max-width: 1280px) {
    flex-direction: column;

    > :only-child {
      width: 100% !important;
    }
  }

  > :first-child {
    @media (max-width: 1280px) {
      width: 100%;
      border: 0;
      padding: 0;
      margin: 0;
    }
    width: 50%;
    margin-right: -1.3125rem;
    padding-right: 1.3125rem;
  }

  > :last-child {
    @media (max-width: 1280px) {
      width: 100%;
      padding: 0;
    }
    width: 50%;
    padding: ${({ $showDualRadar }) =>
      $showDualRadar ? '0 0 0 1.25rem' : '0'};
  }

  > :only-child {
    width: 550px;
    margin: auto;
  }

  .content {
    position: absolute;
    top: 300px;
    width: 100%;
  }
`;
RadarContentLayout.displayName = 'RadarContentLayout';

export const RadarContainer = styled.div`
  position: relative;
  margin: ${({ $margin }) => $margin || 'initial'};
  @media (max-width: 1280px) {
    margin: auto;
  }
  max-width: ${({ $maxWidth }) => `${$maxWidth}` || '500px'};

  > :first-child {
    max-width: 550px;
    margin: auto;
  }
`;
RadarContainer.displayName = 'RadarContainer';

export const TempRadarStyles = styled.div`
  .ui.dimmer .ui.loader:before {
    border-color: ${({ theme }) => theme.colours.canvas.primary.strong};
  }
`;
RadarContainer.displayName = 'TempRadarStyles';

export const InlineMessage = styled.div`
  display: flex;
  gap: 1.75rem;
  height: 24px;
  line-height: 1.6;
  width: 234.5px;

  .ui.inline.loader.active {
    position: relative;
    top: 3px;
    left: 5px;
  }
`;
InlineMessage.displayName = 'InlineMessage';

export const WithDivider = styled.div`
  > * {
    padding: ${({ $showDualRadar }) =>
      $showDualRadar ? '0 0 0 1.25rem' : '0'};
    border-left: ${({ theme, $showDualRadar }) =>
      `${$showDualRadar ? '1px' : '0'} solid ${
        theme.colours.canvas.primary.main
      }}`};
    margin-right: -1.3125rem;
  }
  @media (max-width: 1280px) {
    > * {
      border-left: 0;
      padding: 0;
      margin: 0;
    }
  }
`;
WithDivider.displayName = 'WithDivider';

export const RadarExportContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;
RadarExportContainer.displayName = 'RadarExportContainer';
