import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@statsbomb/kitbag-components';
import { IconButtonWrapper } from './Playlist.styles';

/*
 * A Button to add plays to a playlist
 */
const PlaylistButton = ({
  handleClick,
  isDisabled,
  numberOfPlays,
  variant,
}) => (
  <IconButtonWrapper>
    <Button
      onClick={handleClick}
      variant={variant}
      size="small"
      title="Add plays to playlist"
      isDisabled={isDisabled}
    >
      <span>
        <Icon variant="Video" size="small" />
      </span>
      Watch play{numberOfPlays !== 1 ? `s` : ''}
      <span>{numberOfPlays > 0 ? `(${numberOfPlays})` : ''}</span>
    </Button>
  </IconButtonWrapper>
);

PlaylistButton.propTypes = {
  handleClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  numberOfPlays: PropTypes.number,
  variant: PropTypes.string,
};

PlaylistButton.defaultProps = {
  handleClick: () => {},
  isDisabled: false,
  numberOfPlays: 0,
  variant: 'secondary',
};

export default PlaylistButton;
