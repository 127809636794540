import React from 'react';
import { getPalette } from '../../utils/visualisations/visPalettes';
import { useD3 } from '../../utils/hooks/useD3';
import { appendText } from '../text';
import { SAFE_COLORS } from '../../utils/constants/safeColors';
import { addZones, addColumn } from './SafePalette.drawing';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';

const SafePalette = function () {
  // SVG BASIC VALUES
  const visPaletteDark = getPalette(true);
  const visPaletteLight = getPalette(false);
  const svgWidth = '100%';
  const canvasWidth = 900;
  const canvasHeight = SAFE_COLORS.length * 20 + 60;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };

  const viewBox = `0 0 ${(
    canvasWidth +
    margin.left +
    margin.right
  ).toString()} ${(canvasHeight + margin.top + margin.bottom).toString()}`;

  // this level declares anything static
  const ref = useD3((svg) => {
    svg
      .attr('id', 'palette-display-vis')
      .attr('width', svgWidth)
      .attr('viewBox', viewBox);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', '#aaa');

    const marginTransform = `translate(${margin.left},${margin.top})`;
    const canvasG = svg.append('g').attr('transform', marginTransform);
    appendText(canvasG, visPaletteLight, {
      x: 0,
      y: 15,
      message: 'Color (Hex)',
    });
    appendText(canvasG, visPaletteLight, {
      x: 80,
      y: 15,
      message: 'Dark?',
    });
    appendText(canvasG, visPaletteLight, {
      x: 120,
      y: 15,
      message: 'Light?',
    });

    /* Add dark and Light Canvases */
    addColumn(canvasG, {
      x: 200,
      w: 300,
      h: '100%',
      fill: visPaletteDark.background.main,
    });
    addColumn(canvasG, {
      x: 550,
      w: 300,
      h: '100%',
      fill: visPaletteLight.background.main,
    });
    const darkG = canvasG.append('g').attr('transform', 'translate(200,0)');
    const lightG = canvasG.append('g').attr('transform', 'translate(550,0)');
    const colorsG = canvasG.append('g').attr('transform', 'translate(0,40)');
    addZones(darkG, visPaletteDark, 'Dark Palette');
    addZones(lightG, visPaletteLight, 'Light Palette');

    /*  
        Render each color category
        For each color category, render each color
    */
    const colorLineHeight = 20;
    colorsG
      .selectAll('rect')
      .data(SAFE_COLORS)
      .enter()
      .append('rect')
      .attr('x', 180)
      .attr('y', (d, i) => i * colorLineHeight + 5)
      .attr('width', 720)
      .attr('height', 5)
      .attr('fill', (d) => d.hex)
      .attr('stroke', 'none');

    colorsG
      .append('g')
      .selectAll('text')
      .data(SAFE_COLORS)
      .enter()
      .append('text')
      .attr('x', 0)
      .attr(
        'y',
        (d, i) =>
          i * colorLineHeight + VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', 'start')
      .attr('fill', visPaletteLight.text.info)
      .text((d) => d.hex);

    colorsG
      .append('g')
      .selectAll('text')
      .data(SAFE_COLORS)
      .enter()
      .append('text')
      .attr('x', 80)
      .attr(
        'y',
        (d, i) =>
          i * colorLineHeight + VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', 'start')
      .attr('fill', (d) =>
        d.dark ? visPaletteLight.text.label : visPaletteDark.background.main
      )
      .text((d) => (d.dark ? 'YES' : 'NO'));

    colorsG
      .append('g')
      .selectAll('text')
      .data(SAFE_COLORS)
      .enter()
      .append('text')
      .attr('x', 120)
      .attr(
        'y',
        (d, i) =>
          i * colorLineHeight + VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE
      )
      .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_LABELS.SIZE}px`)
      .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_LABELS.WEIGHT)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', 'start')
      .attr('fill', (d) =>
        d.light ? visPaletteLight.text.label : visPaletteLight.background.main
      )
      .text((d) => (d.light ? 'YES' : 'NO'));
  }, []);

  //   useEffect(() => {
  //     const svg = select(ref.current);
  //   }, []);

  return <svg ref={ref} data-testid="palette-display-vis" />;
};

export default SafePalette;
