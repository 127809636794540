import { VISUALISATION_FONT_SETUPS } from '../../../utils/constants/visText';
import { fontBaselineY } from '../../text';
import { ROUTE_TREE_FIELD_OVERRIDES } from '../RouteTree.constants';

const ROUTE_TREE_KEY_BASE = {
  WIDTH:
    ROUTE_TREE_FIELD_OVERRIDES.X_YARDS * ROUTE_TREE_FIELD_OVERRIDES.pxPerYard,
  HEIGHT: 80,
  ICONS_SEGMENT: 28,
  BRANCH_RADIUS: 20,
  FRUIT_RADIUS: 10,
  SECTION_SPACING: 32,
};
export const ROUTE_TREE_KEY_SIZE = {
  ...ROUTE_TREE_KEY_BASE,
  SECTION_WIDTH:
    (ROUTE_TREE_KEY_BASE.WIDTH - ROUTE_TREE_KEY_BASE.SECTION_SPACING) / 2,
  BRANCH_SCALE_WIDTH:
    (ROUTE_TREE_KEY_BASE.WIDTH - ROUTE_TREE_KEY_BASE.SECTION_SPACING) / 2 - // section width
    ROUTE_TREE_KEY_BASE.BRANCH_RADIUS * 2,
  FRUIT_SCALE_WIDTH:
    (ROUTE_TREE_KEY_BASE.WIDTH - ROUTE_TREE_KEY_BASE.SECTION_SPACING) / 2 - // section width
    ROUTE_TREE_KEY_BASE.FRUIT_RADIUS * 2,
};

export const ROUTE_TREE_KEY_CLASSES = {
  BACKGROUND: 'rtz-background',
  MAIN: 'rtz-main',
  BRANCH_ZONE: 'rtz-branch',
  BRANCH_ZONE_IMAGES: 'rtz-branch-images',
  BRANCH_ZONE_TEXT: 'rtz-branch-text',
  FRUIT_ZONE: 'rtz-fruit',
  FRUIT_ZONE_IMAGES: 'rtz-fruit-images',
  FRUIT_ZONE_TEXT: 'rtz-fruit-text',
};

export const ROUTE_TREE_KEY_BRANCH_SCALE_VALUES = [0, 0.25, 0.5, 0.75, 1];
export const ROUTE_TREE_KEY_FRUIT_SCALE_VALUES = [
  0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1,
];
export const ROUTE_TREE_KEY_FRUIT_SCALE_TEXT_VALUES =
  ROUTE_TREE_KEY_FRUIT_SCALE_VALUES.filter((_, index) => index % 2 === 0);

export const SECTION_HEAD_Y = fontBaselineY(
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
  VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
);
export const ICON_LABEL_Y = fontBaselineY(
  VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE,
  VISUALISATION_FONT_SETUPS.KEY_LABEL.LINE_HEIGHT
);
