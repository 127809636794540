import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@statsbomb/kitbag-components';
import { BannerErrorWrapper } from './Banner.styles';

const BannerError = ({ message }) => (
  <BannerErrorWrapper>
    <Icon size="regular" variant="Warning" />
    {message}
  </BannerErrorWrapper>
);

BannerError.propTypes = {
  message: PropTypes.string,
};

BannerError.defaultProps = {
  message: 'Error loading data',
};

export default BannerError;
