import { styled } from 'styled-components';

export const PositionChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-bottom: 0.8rem;
`;
PositionChipWrapper.displayName = 'PositionChipWrapper';

export const PositionModalBigWrapper = styled.div`
  margin-bottom: 1.6rem;

  label {
    width: 100%;
  }
`;
PositionModalBigWrapper.displayName = 'PositionModalBigWrapper';

export const PositionModalContent = styled.div`
  display: flex;
  gap: 1.6rem;
  width: auto;
  flex-direction: ${({ $asColumn }) => ($asColumn ? 'column' : 'row')};
`;
PositionModalContent.displayName = 'PositionModalContent';

export const PositionCategory = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
PositionCategory.displayName = 'PositionCategory';

export const PositionGroup = styled.div`
  display: grid;
  gap: 0.8rem;
  grid-template-columns: repeat(${({ $wider }) => ($wider ? '3' : '2')}, 1fr);

  @media (min-width: 1165px) {
    grid-template-columns: repeat(${({ $wider }) => ($wider ? '4' : '2')}, 1fr);
  }

  @media (min-width: 1370px) {
    grid-template-columns: repeat(${({ $wider }) => ($wider ? '4' : '3')}, 1fr);
  }

  @media (min-width: 1465px) {
    grid-template-columns: repeat(${({ $wider }) => ($wider ? '5' : '3')}, 1fr);
  }
`;
PositionGroup.displayName = 'PositionGroup';
