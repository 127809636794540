import { meanBy as _meanBy } from 'lodash';

/*
A modification of lodash's meanBy function
autoformats to a string with the desired number of decimal places (defaults to 1)
returns a "-" for any non-numerical result (as opposed to NaN ~ the normal output)
*/
export const meanBy = function (data, columnName, decimalPlaces = 1) {
  if (data?.length > 0 && columnName) {
    const meanValue = _meanBy(data, columnName);

    if (!Number.isNaN(meanValue)) {
      return meanValue.toFixed(decimalPlaces);
    }
  }
  return '-';
};

/* 
This takes a value and a reference value (e.g. a league average) and 
  returns a value representing the difference in terms of the reference value

Returned values are positive/negative about 0 depending on the direction of the difference

e.g for a reference value of 16, values of 12 and 20 are both 4 away: 
  they returned values would be +-0.25 (25%) of the reference value

NOTE: "Double" and "Half" are NOT opposites in this ~ double is +100%, half is -50%!

NOTE: Whilst this is basically (value - referenceValue)/referenceValue (which is (a-b)/b -> a/b -1)
  This always returns a positive value when the value is more positive than the reference value
  i.e. this function returns a positive proportion for any less-negative/more-positive value than reference value
    ~ (a-b)/b returns a negative proportion when the reference value negative
*/
export const proportionalDifference = (value, referenceValue) => {
  /* nulls/undefined or incoming zeroes return 0 */
  if (!value || !referenceValue) {
    return 0;
  }
  const direction = value < referenceValue ? -1 : 1;
  const diff = Math.abs(value / referenceValue - 1);
  return direction * diff;
};
