import { styled } from 'styled-components';

export const RadarTileContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  > div {
    flex-grow: 1;
  }
`;
