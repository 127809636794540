import { styled } from 'styled-components';

export const StyledGameAnimation = styled.div`
  .ui.button {
    max-width: 70px;

    &.clear {
      border: 0;

      .icon {
        font-size: 2rem;
      }
    }
  }

  .filter {
    .dropdown {
      right: auto;
      top: auto;
    }
  }

  .ui {
    &.top-right {
      position: absolute !important;
      top: 8px;
      right: 0;
      z-index: 10;
    }

    &.loader {
      font-size: 12px !important;
    }

    &.segment {
      min-height: 100px;
    }

    &.dropdown {
      &.clear {
        padding: 7px;
      }
    }
  }
`;
StyledGameAnimation.displayName = 'StyledGameAnimation';

export const GameAnimationChartWrapper = styled.div`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin: 0 auto 0 auto;
  padding: 0 0 1rem 0;
`;
GameAnimationChartWrapper.displayName = 'GameAnimationChartWrapper';

export const StyledGameAnimationChart = styled.svg`
  width: 100%;
  ${({ $isPlaying }) =>
    $isPlaying &&
    `
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  `}
`;
StyledGameAnimationChart.displayName = 'StyledGameAnimationChart';
