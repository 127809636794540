export const TACKLING_TILE_OPTIONS = [
  {
    label: 'Play Locations',
    value: false,
    variant: 'PassPath',
  },
  {
    label: 'Distributions',
    value: true,
    variant: 'ViolinPlot',
  },
];
