import React from 'react';
import { useReactiveVar, useQuery, gql, useLazyQuery } from '@apollo/client';
import { Grid, Message, Breadcrumb, Loader } from 'semantic-ui-react';
import { Checkbox, Toggle } from '@statsbomb/kitbag-components';
import TableTile from '../../../components/TableTile/TableTile';
import ParsedLink from '../../../components/ParsedLink/ParsedLink';
import RadarChart from '../../../visualisations/RadarChart/RadarChart';
import { mf_TeamDetails } from '../../../apollo';
import { ERROR_CODES } from '../../../utils/errorCodes/errorCodes';
import Tile from '../../../components/Tile/Tile';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import useQueryString from '../../../utils/hooks/useQueryString';
import { formatTemplateStatsAsRadarAxes } from '../../player/PlayerRadar/PlayerRadar.dataManipulation';
import {
  RadarContainer,
  TileToolbar,
  RadarContentLayout,
  TempRadarStyles,
  InlineMessage,
  WithDivider,
  RadarExportContainer,
} from '../../player/PlayerRadar/PlayerRadar.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import {
  FixedAside,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useGetTemplateDefinitionsTeam } from './TeamRadar.hooks';
import {
  getTeamRadarStatsLeagueAverageQuery,
  getTeamRadarStatsQuery,
} from '../TeamOverview/getTeamRadarStats';
import {
  getTeamRadarKeyInfo,
  getTeamRadarTableData,
} from './TeamRadar.dataManipulation';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';

const TeamRadar = () => {
  // selected team
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const teamName = mf_TeamDetailsRV?.name;
  const [showLeagueAverage, setShowLeagueAverage] = useQueryString(
    'avg',
    false
  );
  const [displayKey, setDisplayKey] = useQueryString('key', true);
  const [showTable, setShowTable] = useQueryString('table', true);
  const [showOffense, setShowOffense] = useQueryString('offense', true);
  const [showDefense, setShowDefense] = useQueryString('defense', true);
  const [useTeamColors, setUseTeamColors] = useQueryString('team', true);
  const seasonInfo = useExportSeasonInfo();

  const teamColors = useTeamColors
    ? {
        primary: mf_TeamDetailsRV?.colorPrimary,
        secondary: mf_TeamDetailsRV?.colorSecondary,
        tertiary: mf_TeamDetailsRV?.colorTertiary,
      }
    : null;

  const {
    templates,
    loading: isConfigLoading,
    error: configError,
  } = useGetTemplateDefinitionsTeam();

  const selectedTemplate = templates?.find((t) => t.name === 'Offense');
  const selectedTemplateDefense = templates?.find((t) => t.name === 'Defense');
  const templateNames = selectedTemplate?.stats?.map((m) => m.name);
  const templateNamesDefense = selectedTemplateDefense?.stats?.map(
    (m) => m.name
  );
  const templateNamesLA = selectedTemplate?.stats
    ?.concat(selectedTemplateDefense?.stats)
    ?.map((m) => m.name);

  const teamOffenseStatsGQLString = getTeamRadarStatsQuery(templateNames, true);
  const teamDefenseStatsGQLString = getTeamRadarStatsQuery(
    templateNamesDefense,
    false
  );
  const teamLAGQLString = getTeamRadarStatsLeagueAverageQuery(templateNamesLA);

  const [
    getDefense,
    {
      data: dataDefenseStats,
      loading: isDefenseStatsLoading,
      error: defenseStatsError,
    },
  ] = useLazyQuery(gql(teamDefenseStatsGQLString), {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  if (defenseStatsError) {
    console.error(ERROR_CODES.GET_TEAM_RADAR_DEFENSE_STATS, defenseStatsError);
  }

  const {
    data: dataOffenseStats,
    loading: isOffenseStatsLoading,
    error: offenseStatsError,
  } = useQuery(gql(teamOffenseStatsGQLString), {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
    onCompleted: (drivesData) => {
      if (drivesData?.teamStats?.items.length > 0) {
        getDefense();
      }
    },
  });
  if (offenseStatsError) {
    console.error(ERROR_CODES.GET_TEAM_RADAR_OFFENSE_STATS, offenseStatsError);
  }

  const {
    data: dataStatsLA,
    loading: isStatsLALoading,
    error: statsLAError,
  } = useQuery(gql(teamLAGQLString), {
    skip: !showLeagueAverage,
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });
  if (statsLAError) {
    console.error(ERROR_CODES.GET_RADAR_STATS_LA, statsLAError);
  }

  const radarLA = showLeagueAverage && dataStatsLA?.teamStats?.items[0];
  const templateConfigOffense = formatTemplateStatsAsRadarAxes(
    templates && templates[0]?.stats
  );
  const templateConfigDefense = formatTemplateStatsAsRadarAxes(
    templates && templates[1]?.stats
  );

  // Offense Tabular Information
  const {
    radarTableData: offenseTableData,
    radarTableHeaders: offenseTableHeaders,
  } = getTeamRadarTableData(
    templateConfigOffense,
    showLeagueAverage,
    dataOffenseStats?.teamStats?.items[0],
    radarLA
  );
  // Defense Tabular Information
  const {
    radarTableData: defenseTableData,
    radarTableHeaders: defenseTableHeaders,
  } = getTeamRadarTableData(
    templateConfigDefense,
    showLeagueAverage,
    dataDefenseStats?.teamStats?.items[0],
    radarLA
  );

  // Offense Key Info
  const offenseKeyInfo = displayKey
    ? getTeamRadarKeyInfo(
        teamName,
        'Offense',
        dataOffenseStats?.teamStats?.items[0],
        showLeagueAverage ? radarLA : null
      )
    : null;
  // Defense Key Info
  const defenseKeyInfo = displayKey
    ? getTeamRadarKeyInfo(
        teamName,
        'Defense',
        dataDefenseStats?.teamStats?.items[0],
        showLeagueAverage ? radarLA : null
      )
    : null;

  const isError =
    !!offenseStatsError ||
    !!configError ||
    !!defenseStatsError ||
    !!statsLAError;
  const isLoading =
    isConfigLoading ||
    isOffenseStatsLoading ||
    isDefenseStatsLoading ||
    isStatsLALoading;

  function info2Copy() {
    if (showLeagueAverage) {
      return 'Versus League Average';
    }
    return '';
  }

  return (
    <TempRadarStyles>
      <Grid>
        <Grid.Row>
          <SidebarRightLayout $sidebarWidth="268px">
            <Tile>
              <Tile.Header>
                <Breadcrumb size="huge">
                  <Breadcrumb.Section>
                    <ParsedLink href="/team/overview/:leagues/:seasons/:teams">
                      Team
                    </ParsedLink>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section>Radar</Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active>
                    {mf_TeamDetailsRV?.name}
                  </Breadcrumb.Section>
                </Breadcrumb>
                <TileToolbar>
                  <>
                    {!isError && !isLoading && (
                      <KeyButton
                        showKey={displayKey}
                        handleShowKey={() => setDisplayKey(!displayKey)}
                      />
                    )}
                    <ExportModal
                      title={teamName}
                      secondaryTitle={`${showOffense ? 'Offense' : ''} ${
                        showOffense && showDefense ? '/' : ''
                      } ${showDefense ? 'Defense' : ''} ${
                        showOffense && showDefense ? 'Radars' : 'Radar'
                      }`}
                      info1={seasonInfo}
                      info2={info2Copy()}
                      columns={showOffense && showDefense ? 2 : 1}
                      fileName={`${teamName}-Radar`}
                      isDisabled={isLoading || isError}
                    >
                      <RadarExportContainer>
                        {showOffense && templateConfigOffense && (
                          <RadarChart
                            radarId="radar-offense-export"
                            templateConfig={templateConfigOffense}
                            radarStatDatum={
                              dataOffenseStats &&
                              dataOffenseStats?.teamStats?.items[0]
                            }
                            radarComparisonDatum={radarLA}
                            teamColors={teamColors}
                            keyInfo={offenseKeyInfo}
                          />
                        )}
                        {showDefense && templateConfigDefense && (
                          <RadarChart
                            radarId="radar-defense-export"
                            templateConfig={templateConfigDefense}
                            radarStatDatum={
                              dataDefenseStats &&
                              dataDefenseStats?.teamStats?.items[0]
                            }
                            radarComparisonDatum={radarLA}
                            teamColors={teamColors}
                            keyInfo={defenseKeyInfo}
                          />
                        )}
                      </RadarExportContainer>
                    </ExportModal>
                  </>
                </TileToolbar>
              </Tile.Header>
              <Tile.Body $gap={0} $padding="0 1.6rem 1.6rem">
                {isError && !isLoading && (
                  <Message negative>
                    There has been an error. Please contact support.
                  </Message>
                )}

                <RadarContentLayout $showDualRadar={showOffense && showDefense}>
                  {templateConfigOffense && !isError && showOffense && (
                    <div>
                      <h3>Offense</h3>
                      <RadarContainer $margin="auto">
                        <RadarChart
                          radarId="radar-offense"
                          templateConfig={templateConfigOffense}
                          radarStatDatum={
                            dataOffenseStats &&
                            dataOffenseStats?.teamStats?.items[0]
                          }
                          radarComparisonDatum={radarLA}
                          teamColors={teamColors}
                          keyInfo={offenseKeyInfo}
                        />

                        {isOffenseStatsLoading && (
                          <Dimmer active style={{ maxWidth: '100%' }}>
                            <Loader
                              content="Loading Offense Data"
                              inverted={false}
                            />
                          </Dimmer>
                        )}
                        {showTable && offenseTableData && (
                          <>
                            <TableTile
                              data={offenseTableData}
                              columnHeaders={offenseTableHeaders}
                              showColumnHeader
                              fileName={`${teamName}-offense-data.csv`}
                              iconSize="small"
                              iconStyle={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                              }}
                              tileTitle=" "
                              withBorder
                            />
                          </>
                        )}
                      </RadarContainer>
                    </div>
                  )}
                  {templateConfigDefense && !isError && showDefense && (
                    <div>
                      <WithDivider $showDualRadar={showOffense && showDefense}>
                        <h3>Defense</h3>
                        <RadarContainer $margin="auto">
                          <RadarChart
                            radarId="radar-defense"
                            templateConfig={templateConfigDefense}
                            radarStatDatum={
                              dataDefenseStats &&
                              dataDefenseStats?.teamStats?.items[0]
                            }
                            radarComparisonDatum={radarLA}
                            teamColors={teamColors}
                            keyInfo={defenseKeyInfo}
                          />

                          {isDefenseStatsLoading && (
                            <Dimmer active style={{ maxWidth: '100%' }}>
                              <Loader
                                content="Loading Defense Data"
                                inverted={false}
                              />
                            </Dimmer>
                          )}
                          {showTable && defenseTableData && (
                            <TableTile
                              data={defenseTableData}
                              columnHeaders={defenseTableHeaders}
                              showColumnHeader
                              fileName={`${teamName}-defense-data.csv`}
                              iconSize="small"
                              iconStyle={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                              }}
                              tileTitle=" "
                              withBorder
                            />
                          )}
                        </RadarContainer>
                      </WithDivider>
                    </div>
                  )}
                </RadarContentLayout>
              </Tile.Body>
            </Tile>

            <div>
              <FixedAside>
                {!isError && (
                  <AccordionTile
                    body={
                      <Tile.AccordionBody>
                        <Checkbox
                          id="show-offense-checkbox"
                          label="Offense"
                          onChange={() => setShowOffense(!showOffense)}
                          checked={showOffense}
                          disabled={!showDefense || isLoading}
                        />
                        <Checkbox
                          id="show-defense-checkbox"
                          label="Defense"
                          onChange={() => setShowDefense(!showDefense)}
                          checked={showDefense}
                          disabled={!showOffense || isLoading}
                        />
                      </Tile.AccordionBody>
                    }
                    isExpandedDefault
                    isMount
                  />
                )}

                <AccordionTile
                  header={<Tile.AccordionHeader>Settings</Tile.AccordionHeader>}
                  body={
                    <Tile.AccordionBody>
                      <Checkbox
                        id="show-table-checkbox"
                        label="Table"
                        onChange={() => setShowTable(!showTable)}
                        checked={showTable}
                      />
                      {isStatsLALoading ? (
                        <InlineMessage>
                          <Loader
                            active
                            size="small"
                            data-testid="league-average-spinner"
                            inline
                          />
                          Loading League Average
                        </InlineMessage>
                      ) : (
                        <Toggle
                          id="league-average-toggle"
                          label="Compare to League Average"
                          onChange={() =>
                            setShowLeagueAverage(!showLeagueAverage)
                          }
                          checked={showLeagueAverage}
                        />
                      )}
                      <Toggle
                        id="team-colours-toggle"
                        label="Use Team Colors"
                        onChange={() => setUseTeamColors(!useTeamColors)}
                        checked={useTeamColors}
                      />
                    </Tile.AccordionBody>
                  }
                  isExpandedDefault
                  isMount
                />
              </FixedAside>
            </div>
          </SidebarRightLayout>
        </Grid.Row>
      </Grid>
    </TempRadarStyles>
  );
};
export default TeamRadar;
