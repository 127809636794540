import { axisBottom, axisLeft, axisRight, axisTop } from 'd3';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import {
  CHART_LAYOUT_CLASSES,
  CHART_AXES_LABEL_TYPE,
  CHART_GUIDE_FREQUENCY,
  CHART_SCALE_TYPE,
} from './BasicChart.constants';
import { getLayoutTransforms } from './BasicChart.dataManipulation';
import { everyOther } from '../../utils/helpers/general';

/* 
Find the viewbox from the component elements 
Assumes layout matches format of BasicChart.CHART_LAYOUT
*/
export const getChartViewbox = (layout, margin) => {
  const viewboxWidth =
    margin.left +
    layout.AXES.AREA.left +
    layout.AXES.PADDING.left +
    layout.CANVAS.WIDTH +
    layout.AXES.PADDING.right +
    layout.AXES.AREA.right +
    margin.right;

  const viewboxHeight =
    margin.top +
    layout.AXES.AREA.top +
    layout.AXES.PADDING.top +
    layout.CANVAS.HEIGHT +
    layout.AXES.PADDING.bottom +
    layout.AXES.AREA.bottom +
    margin.bottom;
  return `0 0 ${viewboxWidth} ${viewboxHeight}`;
};

/*
Adds all elements listed in CHART_LAYOUT_CLASSES
In appropriate nested structure
*/
export const addChartCoreLayout = (svg, margin) => {
  /* Add defs */
  svg.append('defs').attr('class', CHART_LAYOUT_CLASSES.DEFS);

  /* Add background */
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('class', CHART_LAYOUT_CLASSES.BACKGROUND);

  /* Add zones which will get populated with visual elements */

  const marginTransform = `translate(${margin.left},${margin.top})`;
  const inMarginsG = svg
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.IN_MARGINS)
    .attr('transform', marginTransform);
  /* Everything is then in margins */
  const axisTopG = inMarginsG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_TOP);
  axisTopG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_TOP_EXTENDED_BAR);
  axisTopG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_TOP_INNER)
    .attr('data-testid', CHART_LAYOUT_CLASSES.AXIS_TOP_INNER);

  const axisRightG = inMarginsG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_RIGHT);
  axisRightG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_RIGHT_EXTENDED_BAR);
  axisRightG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_RIGHT_INNER)
    .attr('data-testid', CHART_LAYOUT_CLASSES.AXIS_RIGHT_INNER);

  const axisBottomG = inMarginsG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_BOTTOM);
  axisBottomG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_BOTTOM_EXTENDED_BAR);
  axisBottomG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_BOTTOM_INNER)
    .attr('data-testid', CHART_LAYOUT_CLASSES.AXIS_BOTTOM_INNER);
  const axisLeftG = inMarginsG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_LEFT);
  axisLeftG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_LEFT_EXTENDED_BAR);
  axisLeftG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.AXIS_LEFT_INNER)
    .attr('data-testid', CHART_LAYOUT_CLASSES.AXIS_LEFT_INNER);

  const inAxesG = inMarginsG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.IN_AXES);
  inAxesG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.GUIDES_HORIZONTAL)
    .attr('data-testid', CHART_LAYOUT_CLASSES.GUIDES_HORIZONTAL);
  inAxesG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.GUIDES_VERTICAL)
    .attr('data-testid', CHART_LAYOUT_CLASSES.GUIDES_VERTICAL);
  inAxesG
    .append('g')
    .attr('class', CHART_LAYOUT_CLASSES.CANVAS)
    .attr('data-testid', CHART_LAYOUT_CLASSES.CANVAS);
};

/* 
Adjust all the areas defined in core layout to positions according to layout change 
*/
export const applyCoreLayoutTransforms = (svg, layout) => {
  const {
    axisTopTransform,
    axisTopInnerTransform,
    axisRightTransform,
    axisRightInnerTransform,
    axisBottomTransform,
    axisBottomInnerTransform,
    axisLeftTransform,
    axisLeftInnerTransform,
    axesTransform,
    canvasTransform,
  } = getLayoutTransforms(layout);
  /* Top */
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_TOP}`)
    .attr('transform', axisTopTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_TOP_INNER}`)
    .attr('transform', axisTopInnerTransform);

  /* Right */
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_RIGHT}`)
    .attr('transform', axisRightTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_RIGHT_INNER}`)
    .attr('transform', axisRightInnerTransform);

  /* Bottom */
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_BOTTOM}`)
    .attr('transform', axisBottomTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_BOTTOM_INNER}`)
    .attr('transform', axisBottomInnerTransform);

  /* Left */
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_LEFT}`)
    .attr('transform', axisLeftTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.AXIS_LEFT_INNER}`)
    .attr('transform', axisLeftInnerTransform);

  /* Make and movement adjustments for the guides and canvas */
  const inAxesG = svg.select(`.${CHART_LAYOUT_CLASSES.IN_AXES}`);
  inAxesG.attr('transform', axesTransform);

  svg
    .select(`.${CHART_LAYOUT_CLASSES.GUIDES_HORIZONTAL}`)
    .attr('transform', canvasTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.GUIDES_VERTICAL}`)
    .attr('transform', canvasTransform);
  svg
    .select(`.${CHART_LAYOUT_CLASSES.CANVAS}`)
    .attr('transform', canvasTransform);
};

/*
If showing longer axis than the canvas zone (i.e. connecting the corners)
    do it by underlying the main axes in the same location/style

    TODO: SHould probably simplify this down to a single layer, and then 
    a path with move/line to aspects, as opposed to 4 independent lines
*/
export const addExtendedAxes = (svg, layout, visPalette) => {
  const axisTopBarG = svg.select(
    `.${CHART_LAYOUT_CLASSES.AXIS_TOP_EXTENDED_BAR}`
  );
  const axisRightBarG = svg.select(
    `.${CHART_LAYOUT_CLASSES.AXIS_RIGHT_EXTENDED_BAR}`
  );
  const axisBottomBarG = svg.select(
    `.${CHART_LAYOUT_CLASSES.AXIS_BOTTOM_EXTENDED_BAR}`
  );
  const axisLeftBarG = svg.select(
    `.${CHART_LAYOUT_CLASSES.AXIS_LEFT_EXTENDED_BAR}`
  );
  axisTopBarG.selectAll('line').remove();
  axisRightBarG.selectAll('line').remove();
  axisBottomBarG.selectAll('line').remove();
  axisLeftBarG.selectAll('line').remove();

  /* Draw extended lines if they are needed */
  if (layout.AXES.CONSTRAIN_LINES_TO_PADDING === false) {
    const axesStrokeWidth = 1;
    const autoAxesPathAdjustment = 0.5;

    if (layout.AXES.LABELS.top) {
      axisTopBarG
        .append('line')
        .attr('x1', 0)
        .attr(
          'x2',
          layout.AXES.PADDING.left +
            layout.CANVAS.WIDTH +
            layout.AXES.PADDING.right
        )
        .attr('y1', layout.AXES.AREA.top + autoAxesPathAdjustment)
        .attr('y2', layout.AXES.AREA.top + autoAxesPathAdjustment)
        .attr('stroke', visPalette.axis)
        .attr('stroke-width', axesStrokeWidth);
    }

    if (layout.AXES.LABELS.right) {
      axisRightBarG
        .append('line')
        .attr('x1', autoAxesPathAdjustment)
        .attr('x2', autoAxesPathAdjustment)
        .attr('y1', 0)
        .attr(
          'y2',
          layout.AXES.PADDING.top +
            layout.CANVAS.HEIGHT +
            layout.AXES.PADDING.bottom
        )
        .attr('stroke', visPalette.axis)
        .attr('stroke-width', axesStrokeWidth);
    }

    if (layout.AXES.LABELS.bottom) {
      axisBottomBarG
        .append('line')
        .attr('x1', 0)
        .attr(
          'x2',
          layout.AXES.PADDING.left +
            layout.CANVAS.WIDTH +
            layout.AXES.PADDING.right
        )
        .attr('y1', autoAxesPathAdjustment)
        .attr('y2', autoAxesPathAdjustment)
        .attr('stroke', visPalette.axis)
        .attr('stroke-width', axesStrokeWidth);
    }

    if (layout.AXES.LABELS.left) {
      axisLeftBarG
        .append('line')
        .attr('x1', layout.AXES.AREA.left + autoAxesPathAdjustment)
        .attr('x2', layout.AXES.AREA.left + autoAxesPathAdjustment)
        .attr('y1', 0)
        .attr(
          'y2',
          layout.AXES.PADDING.top +
            layout.CANVAS.HEIGHT +
            layout.AXES.PADDING.bottom
        )
        .attr('stroke', visPalette.axis)
        .attr('stroke-width', axesStrokeWidth);
    }
  }
};

const adjustAxisStyle = (axisG, visPalette, layout) => {
  if (layout.AXES.CONSTRAIN_LINES_TO_PADDING === false) {
    axisG.select('.domain').remove();
  } else {
    axisG
      .select('.domain')
      .attr('stroke', visPalette.axis)
      .attr('stroke-width', 1);
  }
  axisG
    .selectAll('.tick line')
    .attr('stroke', visPalette.axis)
    .attr('stroke-width', 1);
  axisG
    .selectAll('.tick text')
    .attr('fill', visPalette.axis)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-size', VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE);
};

const clearAxis = (axisG) => {
  axisG.selectAll('path').remove();
  axisG.selectAll('text').remove();
  axisG.selectAll('line').remove();
};

/* 
TODO: ticks override only works for numeric axes
TODO: allow tick override of values rather than count
*/
export const addAxes = (svg, visPalette, layout, setup, xScale, yScale) => {
  const axisTopG = svg.select(`.${CHART_LAYOUT_CLASSES.AXIS_TOP_INNER}`);
  if (layout.AXES.LABELS.top === CHART_AXES_LABEL_TYPE.VALUE) {
    const topAxis = axisTop(xScale)
      .tickSize(layout.AXES.TICKS.top)
      .tickSizeOuter(layout.AXES.TICKS_OUTER.top);
    if (setup.X.TICKS) {
      topAxis.ticks(setup.X.TICKS);
    }
    axisTopG.transition().duration(600).call(topAxis);
    adjustAxisStyle(axisTopG, visPalette, layout);
  } else {
    clearAxis(axisTopG);
  }

  const axisRightG = svg.select(`.${CHART_LAYOUT_CLASSES.AXIS_RIGHT_INNER}`);
  if (layout.AXES.LABELS.right === CHART_AXES_LABEL_TYPE.VALUE) {
    const rightAxis = axisRight(yScale)
      .tickSize(layout.AXES.TICKS.right)
      .tickSizeOuter(layout.AXES.TICKS_OUTER.right);
    if (setup.Y.TICKS) {
      rightAxis.ticks(setup.Y.TICKS);
    }
    axisRightG.transition().duration(600).call(rightAxis);
    adjustAxisStyle(axisRightG, visPalette, layout);
  }

  const axisBottomG = svg.select(`.${CHART_LAYOUT_CLASSES.AXIS_BOTTOM_INNER}`);
  if (layout.AXES.LABELS.bottom === CHART_AXES_LABEL_TYPE.VALUE) {
    const bottomAxis = axisBottom(xScale)
      .tickSize(layout.AXES.TICKS.bottom)
      .tickSizeOuter(layout.AXES.TICKS_OUTER.bottom);
    if (setup.X.TICKS) {
      bottomAxis.ticks(setup.X.TICKS);
    }
    axisBottomG.transition().duration(600).call(bottomAxis);
    adjustAxisStyle(axisBottomG, visPalette, layout);
  } else {
    clearAxis(axisBottomG);
  }

  const axisLeftG = svg.select(`.${CHART_LAYOUT_CLASSES.AXIS_LEFT_INNER}`);
  if (layout.AXES.LABELS.left === CHART_AXES_LABEL_TYPE.VALUE) {
    const leftAxis = axisLeft(yScale)
      .tickSize(layout.AXES.TICKS.left)
      .tickSizeOuter(layout.AXES.TICKS_OUTER.left);
    if (setup.Y.TICKS) {
      leftAxis.ticks(setup.Y.TICKS);
    }
    axisLeftG.transition().duration(600).call(leftAxis);
    adjustAxisStyle(axisLeftG, visPalette, layout);
  }
};

export const drawGuides = (svg, layout, setup, xScale, yScale, visPalette) => {
  const guidesHorizontalG = svg.select(
    `.${CHART_LAYOUT_CLASSES.GUIDES_HORIZONTAL}`
  );
  const guidesVerticalG = svg.select(
    `.${CHART_LAYOUT_CLASSES.GUIDES_VERTICAL}`
  );
  guidesHorizontalG.selectAll('line').remove();
  guidesVerticalG.selectAll('line').remove();

  if (layout.AXES.GUIDES.VERTICAL) {
    const xTicks =
      setup.X.TYPE === CHART_SCALE_TYPE.CATEGORICAL
        ? xScale.domain()
        : xScale.ticks();
    const guideValues =
      layout.AXES.GUIDES.VERTICAL === CHART_GUIDE_FREQUENCY.TICKS
        ? xTicks
        : everyOther(xTicks);
    guidesVerticalG
      .selectAll('line')
      .data(guideValues)
      .enter()
      .append('line')
      .attr('x1', (d) => xScale(d))
      .attr('x2', (d) => xScale(d))
      .attr('y1', -layout.AXES.PADDING.top)
      .attr('y2', layout.CANVAS.HEIGHT + layout.AXES.PADDING.bottom)
      .attr('stroke', visPalette.guides)
      .attr('stroke-dasharray', '3 2')
      .attr('stroke-width', 1);
  }

  if (layout.AXES.GUIDES.HORIZONTAL) {
    const yTicks =
      setup.Y.TYPE === CHART_SCALE_TYPE.CATEGORICAL
        ? yScale.domain()
        : yScale.ticks();
    const guideValues =
      layout.AXES.GUIDES.HORIZONTAL === CHART_GUIDE_FREQUENCY.TICKS
        ? yTicks
        : everyOther(yTicks);
    guidesHorizontalG
      .selectAll('line')
      .data(guideValues)
      .enter()
      .append('line')
      .attr('y1', (d) => yScale(d))
      .attr('y2', (d) => yScale(d))
      .attr('x1', -layout.AXES.PADDING.left)
      .attr('x2', layout.CANVAS.WIDTH + layout.AXES.PADDING.right)
      .attr('stroke', visPalette.guides)
      .attr('stroke-dasharray', '3 2')
      .attr('stroke-width', 1);
  }
};
