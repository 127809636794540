import {
  PLAY_OUTCOMES,
  playOutcomeColor,
} from '../../utils/visualisations/playSuccess';
import {
  TACKLING_OUTCOMES,
  TACKLING_OUTCOMES_API_KEYS,
  TACKLING_PLAY_TYPE_RUN,
  TACKLING_PLAY_TYPE_SACK,
  TACKLING_PLAY_TYPE_PASS,
  TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE,
  TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME,
  TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME,
} from './TackleLocation.constants';

const tackleOutcomeColorDots = (visPalette, tackleData) => {
  const dots = Object.keys(TACKLING_OUTCOMES_API_KEYS).map((outcomeKey) => {
    const tacklingOutcome = TACKLING_OUTCOMES[outcomeKey];
    return {
      name: `${tacklingOutcome.tackleResult} (${
        tackleData.filter(
          (t) => t?.bestTackleResult === tacklingOutcome.tackleResult
        )?.length
      })`,
      color: tacklingOutcome.tackleOutcomeColor(visPalette),
    };
  });
  return dots;
};

const playTypeColorDots = (visPalette, tackleData) => [
  {
    color: visPalette.objects.n1.main,
    name: `Run Play (${
      tackleData.filter(
        (t) => t.tacklingPlayType === TACKLING_PLAY_TYPE_RUN.value
      )?.length
    })`,
  },
  {
    color: visPalette.objects.n2.main,
    name: `Pass Play (${
      tackleData.filter(
        (t) => t.tacklingPlayType === TACKLING_PLAY_TYPE_PASS.value
      )?.length
    })`,
  },
  {
    color: visPalette.objects.n3.main,
    name: `Sack Play (${
      tackleData.filter(
        (t) => t.tacklingPlayType === TACKLING_PLAY_TYPE_SACK.value
      )?.length
    })`,
  },
];

const playOutcomeColorDots = (visPalette, tackleData) => {
  const dots = Object.keys(PLAY_OUTCOMES).map((outcomeKey) => {
    const value = PLAY_OUTCOMES[outcomeKey];
    const color = playOutcomeColor(value, visPalette);
    const plays = tackleData.filter((t) => t.color === color)?.length || 0;
    return {
      name: `${value} (${plays})`,
      color,
    };
  });
  return dots;
};
export const getDataDots = (colorMode, visPalette, tackleData) => {
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_TYPE.value) {
    return playTypeColorDots(visPalette, tackleData);
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_TACKLE_OUTCOME.value) {
    return tackleOutcomeColorDots(visPalette, tackleData);
  }
  if (colorMode === TACKLE_LOCATION_COLOR_MODE_PLAY_OUTCOME.value) {
    return playOutcomeColorDots(visPalette, tackleData);
  }
  return null;
};
