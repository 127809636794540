import { formatValue } from '../../../League/stats/stats.dataManipulation';

export const formatPlayers = (playerPlayData, headers, isCSV) => {
  const formattedPlayers = playerPlayData?.map((playerPlay) => {
    const playerData = headers.map((h) => {
      const formattedValue = formatValue(playerPlay[h.key], h.units);
      if (isCSV) {
        return { [h.key]: formattedValue };
      }
      const label = { label: formattedValue };
      /* The position column has a custom title value */
      const title =
        h.key === 'primaryPositionCode'
          ? { title: playerPlay.positionUsageDescription }
          : {};
      /* 
        Turn all values into a label in an object because prop types 
          complains about mixed types
         */
      const metricObject = {
        [h.key]: { ...label, ...title },
      };
      return metricObject;
    });
    /* Merge object array into single object */
    const formattedPlayer = Object.assign(...playerData);
    return formattedPlayer;
  });
  return formattedPlayers;
};
