import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'styled-components';
import { scaleLinear } from 'd3';
import { groupBy, orderBy } from 'lodash';
import {
  formatValue,
  orderStats,
} from '../../League/stats/stats.dataManipulation';
import {
  FORMATION_SUMMARY_STATS_CONFIG,
  RUN_RATE,
} from './TeamFormationSummary.constants';
import { ui_isDark } from '../../../apollo';
import { csIntensityBlue, csValue } from '../../../utils/helpers/colorScales';
import { SCALE_BOUNDS } from '../../../visualisations/FormationSummaryChart/FormationSummaryChart.constants';
import { formatPackageLabel } from '../../../utils/helpers/strings';
import { useIsNcaa } from '../../../utils/hooks/useIsNcaa';

export const useRankColor = () => {
  const theme = useTheme();

  const getRankColor = (value, mean, standardDeviation) => {
    if (
      typeof value !== 'number' ||
      typeof mean !== 'number' ||
      typeof standardDeviation !== 'number'
    ) {
      return theme.colours.canvas.tertiary.main;
    }

    const sdPoints = (value - mean) / standardDeviation;

    switch (true) {
      case sdPoints <= -3:
        return {
          color: theme.colours.visualisations.rank.antiGold.main,
          ink: theme.colours.visualisations.rank.antiGold.ink,
        };
      case sdPoints <= -2:
        return {
          color: theme.colours.visualisations.rank.antiSilver.main,
          ink: theme.colours.visualisations.rank.antiSilver.ink,
        };
      case sdPoints <= -1:
        return {
          color: theme.colours.visualisations.rank.antiBronze.main,
          ink: theme.colours.visualisations.rank.antiBronze.ink,
        };
      case sdPoints < 1:
        return {
          color: theme.colours.canvas.tertiary.main,
          ink: theme.colours.canvas.tertiary.ink,
        };
      case sdPoints < 2:
        return {
          color: theme.colours.visualisations.rank.bronze.main,
          ink: theme.colours.visualisations.rank.bronze.ink,
        };
      case sdPoints < 3:
        return {
          color: theme.colours.visualisations.rank.silver.main,
          ink: theme.colours.visualisations.rank.silver.ink,
        };
      default:
        return {
          color: theme.colours.visualisations.rank.gold.main,
          ink: theme.colours.visualisations.rank.gold.ink,
        };
    }
  };

  return { getRankColor };
};

export const useRunRateColor = (isTeamMode, runRateMean) => {
  const { colours, isDark } = useTheme();

  const getRunRateColor = (value) => {
    const normalizedValue = isTeamMode ? value : value + runRateMean;
    const color = csIntensityBlue(1 - normalizedValue, isDark);

    // figure out an ink color so it has enough contrast
    const threshold = isDark ? 0.25 : 0.75;
    const ink =
      normalizedValue > threshold
        ? colours.ink[isDark ? 'primary' : 'inverse'].main
        : colours.ink[isDark ? 'inverse' : 'primary'].main;

    return {
      color,
      ink,
    };
  };

  return { getRunRateColor };
};

export const useTableDataByFormation = (
  teamStatsByFormation,
  statDistributions,
  statDefinitions,
  isTeamMode,
  headerIds,
  formationName
) => {
  const runRateMean = statDistributions?.find((s) => s.name === RUN_RATE)?.mean;
  const { getRankColor } = useRankColor();
  const { getRunRateColor } = useRunRateColor(isTeamMode, runRateMean);

  if (!teamStatsByFormation?.length || !statDistributions || !statDefinitions) {
    return [];
  }

  // filter results to a specific formation if provided
  const formations = formationName
    ? teamStatsByFormation?.filter((f) => f.formationName === formationName)
    : teamStatsByFormation;

  const formattedStats = formations?.map((row) =>
    headerIds.reduce((acc, header) => {
      const value = row[header];

      const formattedValue = formatValue(
        value,
        statDefinitions?.find((def) => def.name === header)?.units
      );

      const statDistribution = statDistributions.find(
        (stat) => stat.name === header
      );
      const mean = isTeamMode ? statDistribution?.mean : 0;
      const standardDeviation = statDistribution?.stddev;

      const isDeltaStat = FORMATION_SUMMARY_STATS_CONFIG[header]?.isDeltaStat;
      const deltaValue = `${
        value >= 0 && value !== null && isDeltaStat ? '+' : ''
      }${formattedValue}`;

      const label = isTeamMode ? formattedValue : deltaValue;
      const colors = FORMATION_SUMMARY_STATS_CONFIG[header]?.csIntensityBlue
        ? getRunRateColor(value)
        : getRankColor(value, mean, standardDeviation);

      return {
        ...acc,
        [header]: {
          title: value,
          label,
          color: colors.color,
          ink: colors.ink,
        },
      };
    }, {})
  );

  return formattedStats;
};

export const useTableDataByPersonnel = (
  teamStats,
  teamStatsByPersonnel,
  statDistributions,
  statDefinitions,
  isTeamMode,
  sortBy,
  sortDirection,
  headerIds
) => {
  const runRateMean = statDistributions?.find((s) => s.name === RUN_RATE)?.mean;
  const { getRankColor } = useRankColor();
  const { getRunRateColor } = useRunRateColor(isTeamMode, runRateMean);

  if (
    !teamStats ||
    !teamStatsByPersonnel ||
    !statDistributions ||
    !statDefinitions
  ) {
    return [];
  }

  const groupedTeamStats = groupBy(teamStats, 'offensePersonnel');

  const formattedStats = Object.entries(groupedTeamStats).map(
    ([personnel, stats]) => {
      const orderedFormations = orderBy(stats, [sortBy], [sortDirection]);

      const personnelStats = teamStatsByPersonnel?.find(
        (stat) => stat.offensePersonnel === personnel
      );

      const allRow = { ...personnelStats, formationName: 'ALL' };
      const rows = [allRow, ...orderedFormations];

      const formattedRows = rows?.map((stat) =>
        Object.entries(stat).reduce((acc, [key, value]) => {
          const statDistribution = statDistributions.find(
            (s) => s.name === key
          );
          const mean = isTeamMode ? statDistribution?.mean : 0;
          const standardDeviation = statDistribution?.stddev;

          const regularFormat = formatValue(
            value,
            statDefinitions.find((def) => def.name === key)?.units
          );

          const isDeltaStat = FORMATION_SUMMARY_STATS_CONFIG[key]?.isDeltaStat;
          const deltaFormat = `${
            value >= 0 && value !== null && isDeltaStat ? '+' : ''
          }${regularFormat}`;

          const label = isTeamMode ? regularFormat : deltaFormat;
          const colors = FORMATION_SUMMARY_STATS_CONFIG[key]?.csIntensityBlue
            ? getRunRateColor(value)
            : getRankColor(value, mean, standardDeviation);

          return {
            ...acc,
            [key]: {
              value,
              label,
              color: colors.color,
              ink: colors.ink,
            },
          };
        }, {})
      );

      const orderedRows = orderStats(formattedRows, headerIds);

      return {
        label: `${formatPackageLabel(personnel)} Personnel`,
        amount: orderedFormations.length,
        rows: orderedRows,
      };
    }
  );

  return formattedStats;
};

const successRateLeagueScaler = (value, isTeamMode, isNCAA) => {
  const min = isNCAA ? SCALE_BOUNDS.NCAA.WORST : SCALE_BOUNDS.NFL.WORST;
  const max = isNCAA ? SCALE_BOUNDS.NCAA.BEST : SCALE_BOUNDS.NFL.BEST;
  const mean = (min + max) / 2;
  const teamValue = value + (isTeamMode ? 0 : mean);

  return scaleLinear().domain([min, max]).range([0, 1]).clamp(true)(teamValue);
};

const useBarColor = (isTeamMode) => {
  const isDark = useReactiveVar(ui_isDark);
  const isNcaa = useIsNcaa();

  const getBarColor = (value) => {
    const normalizedValue = successRateLeagueScaler(value, isTeamMode, isNcaa);
    const color = csValue(normalizedValue, isDark);

    return color;
  };
  return { getBarColor };
};

export const useBarChartDataByFormation = (teamStats, isTeamMode) => {
  const { getBarColor } = useBarColor(isTeamMode);

  if (!teamStats) {
    return [];
  }

  const formattedData = teamStats.map((formation) => {
    const label = formation.formationName;
    const value = formation.totalPlayRate;
    const color = getBarColor(formation.playSuccessRate);

    return {
      label,
      value,
      color,
    };
  });

  return formattedData;
};

export const useBarChartDataByPersonnel = (
  teamStats,
  teamStatsByPersonnel,
  isTeamMode,
  visibleStats
) => {
  const { getBarColor } = useBarColor(isTeamMode);

  if (!teamStats || !teamStatsByPersonnel) {
    return [];
  }

  const formattedData = teamStats.map((formations, index) => {
    const personnel = formations[0].offensePersonnel;
    const personnelStats = teamStatsByPersonnel.find(
      (stat) => stat.offensePersonnel === personnel
    );

    const data = formations.map((formation) => {
      const label = formation.formationName;
      const value = formation.totalPlayRate;
      const color = getBarColor(formation.playSuccessRate);

      return {
        label,
        value,
        color,
      };
    });

    const label = personnel ? formatPackageLabel(personnel) : '';
    const secondaryLabel = `(${personnelStats?.playCount}) plays`;
    const value = personnelStats?.totalPlayRate;
    const color = getBarColor(personnelStats?.playSuccessRate);

    const visibleData = data.filter((_, i) => {
      const visibility = visibleStats[index];
      if (visibility === 'partial') {
        return i < 6;
      }

      return visibility === 'open';
    });

    return {
      label,
      secondaryLabel,
      value,
      color,
      data: visibleData,
    };
  });

  return formattedData;
};
