import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../../utils/helpers/contextFilters';

const overrides = { gameIds: false };

export const getPlayersStats = (category) => {
  const cat1StatNames = category?.map((m) => m.name) || [];
  const joinedStatNames = cat1StatNames.join('\n');
  return `
    query GetPlayersStats(
      $competitionId: Int!
      $seasonId: Int!
      $teamIds: [Int!]
      $aggMode: PlayerStatAggregate!
      $groupByMode: [PlayerStatGroupBy!]
      $limit: Int
      $offset: Int
      $orderBy: [StatOrderBy!]
      $playerName: String
      $ncaaTransferPortal: Boolean
      $minPlays: Int
      $rosterPositions: [GeneralPosition!]
      ${getContextQueryVariables(overrides)}
    ) {
      selectedCompetitionId @client @export(as: "competitionId")
      selectedSeasonId @client @export(as: "seasonId")
      ${getContextFilterClientExports(overrides)}
      playerStats(
        playFilters: {
          competitionId: $competitionId
          seasonId: $seasonId
          ${getContextFiltersQueryArguments(overrides)}
        }
        playerStatFilters: {
          teamIds: $teamIds
          playerName: $playerName
          ncaaTransferPortal: $ncaaTransferPortal
          minPlays: $minPlays
          rosterPositions: $rosterPositions
        }
        groupBy: $groupByMode
        aggregateMode: $aggMode
        limit: $limit
        offset: $offset
        orderBy: $orderBy
      ) {
        items {
          groups {
            key
            value
          }
          plays
          playerName
          rosterPosition
          teamName
          teamShortName
          playPosition
          jerseyNumber
          ${joinedStatNames}
        }
        totalCount
        }
      }
  `;
};
