import { sumBy } from 'lodash';
import { addTableRowGroup, addTableSpaceRow } from './TeamBoxScoreDrawing';

const BASE_10_RADIX = 10;
const THIS_API_PLAY_TYPES = {
  pass: 'PASS',
  run: 'RUSH',
};

const formatBoxScoreData = (datum) => ({
  ...datum,
  defenseTeamId: parseInt(datum?.defenseTeamId, BASE_10_RADIX),
  offenseTeamId: parseInt(datum?.offenseTeamId, BASE_10_RADIX),
});

const filterQuarters = function (data, minQuarter, maxQuarter) {
  return data.filter(
    (f) => f.playQuarter >= minQuarter && f.playQuarter <= maxQuarter
  );
};
const countPlays = function (data) {
  return sumBy(data, 'playCount');
};
const calcSuccessPerc = function (data) {
  const totSuccess = sumBy(data, 'playSuccessCount');
  const tot = sumBy(data, 'playCount');
  return (tot > 0 ? (totSuccess / tot) * 100 : 0).toFixed(1);
};
const calcYardsGained = function (data) {
  const totYds = sumBy(data, 'yardsWon');
  const tot = sumBy(data, 'yardedPlays');
  return (tot > 0 ? totYds / tot : 0).toFixed(2);
};
const calcRunPerc = function (data) {
  const totRuns = sumBy(
    data.filter((f) => f.playType === THIS_API_PLAY_TYPES.run),
    'playCount'
  );
  const tot = sumBy(data, 'playCount');
  const val = (tot > 0 ? (totRuns / tot) * 100 : 0).toFixed(0);
  const oppVal = 100 - val;
  return `${val} : ${oppVal}`;
};
const aggregateMetricGroupByTime = function (data, aggregateFn) {
  const plays = {
    half1: aggregateFn(filterQuarters(data, 1, 2)),
    half2: aggregateFn(filterQuarters(data, 3, 4)),
    quarter1: aggregateFn(filterQuarters(data, 1, 1)),
    quarter2: aggregateFn(filterQuarters(data, 2, 2)),
    quarter3: aggregateFn(filterQuarters(data, 3, 3)),
    quarter4: aggregateFn(filterQuarters(data, 4, 4)),
    total: aggregateFn(data),
  };
  return plays;
};

const buildRowGroup = function (data) {
  return {
    plays: aggregateMetricGroupByTime(data, countPlays),
    runPerc: aggregateMetricGroupByTime(data, calcRunPerc),
    successPerc: aggregateMetricGroupByTime(data, calcSuccessPerc),
    yardsGained: aggregateMetricGroupByTime(data, calcYardsGained),
  };
};

const calculateRowGroups = function (data) {
  const runs = data.filter((r) => r.playType === THIS_API_PLAY_TYPES.run);
  const passes = data.filter((r) => r.playType === THIS_API_PLAY_TYPES.pass);
  const d1 = data.filter((r) => r.playDown === 1);
  const d2 = data.filter((r) => r.playDown === 2);
  const d3 = data.filter((r) => r.playDown === 3);
  const d4 = data.filter((r) => r.playDown === 4);

  return {
    d1: buildRowGroup(d1),
    d2: buildRowGroup(d2),
    d3: buildRowGroup(d3),
    d4: buildRowGroup(d4),
    passes: buildRowGroup(passes),
    runs: buildRowGroup(runs),
  };
};

const addTableBodyRows = function (tableBody, forData, againstData) {
  const forObj = calculateRowGroups(forData);
  const againstObj = calculateRowGroups(againstData);

  addTableRowGroup(tableBody, 'Run', forObj.runs, againstObj.runs, false);
  addTableRowGroup(tableBody, 'Pass', forObj.passes, againstObj.passes, false);
  addTableSpaceRow(tableBody);
  addTableRowGroup(tableBody, '1st Down', forObj.d1, againstObj.d1, true);
  addTableRowGroup(tableBody, '2nd Down', forObj.d2, againstObj.d2, true);
  addTableRowGroup(tableBody, '3rd Down', forObj.d3, againstObj.d3, true);
  addTableRowGroup(tableBody, '4th Down', forObj.d4, againstObj.d4, true);
  addTableSpaceRow(tableBody);
};

export { formatBoxScoreData, addTableBodyRows };
