import { styled } from 'styled-components';
import { METRIC_MODAL_HEIGHTS } from './MetricModal.constants';

export const MetricModalContent = styled.div`
  width: 65vw;
  height: ${METRIC_MODAL_HEIGHTS.BODY}px;
`;
MetricModalContent.displayName = 'MetricModalContent';

/*
This is the main content of the modal, holding the grid
Because the modal auto-nests the content, the container styling has to get revoked / compensated for
*/
export const ModalGridHolder = styled.div`
  width: 100%;
  margin-top: -8px;
  /*the <Grid> component */
  & > div:first-child {
    margin: 0px;
    padding: 0px;
  }
`;
ModalGridHolder.displayName = 'ModalGridHolder';

export const ModalSearchHolder = styled.div`
  width: 50%;
  margin-bottom: 8px;
`;
ModalSearchHolder.displayName = 'ModalSearchHolder';

export const InfoLine = styled.div`
  margin-top: 8px;
  display: table;

  & span {
    ${({ theme }) => theme.typography.bodyRegular}
  }

  & > span:first-child {
    ${({ theme }) => theme.typography.bodySmall}
    width: 120px;
    text-align: right;
    display: inline-block;
    margin-right: 8px;
  }
`;
ModalSearchHolder.displayName = 'ModalSearchHolder';

export const ModalMetricHeader = styled.h2`
  margin-bottom: 16px;
`;
ModalMetricHeader.displayName = 'ModalMetricHeader';
