import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { Message } from 'semantic-ui-react';
import Tile from '../../../../components/Tile/Tile';
import { SummaryStat } from '../../../../components/SummaryStat/SummaryStat';
import {
  HavocSummaryStats,
  HavocSummaryStatsColumn,
  HavocSummaryStatsColumnHeader,
} from './HavocSummaryBar.styles';
import { HAVOC_SUMMARY_TOTALS } from './HavocSummary.constants';

const HavocSummaryBar = ({ playSummary, havocSummary, isError }) => {
  const [displaySummaryOpen, setDisplaySummaryOpen] = useState(true);
  const playsTotals = Object.values(HAVOC_SUMMARY_TOTALS.PLAYS).map((p) => ({
    label: p.label,
    value: playSummary[p.key],
    units: p.units,
  }));
  const havocTotals = Object.values(HAVOC_SUMMARY_TOTALS.HAVOC).map((p) => ({
    label: p.label,
    value: havocSummary[p.key],
    units: p.units,
  }));

  return (
    <Tile border="0" margin="0.8rem 0">
      <Tile.Header>
        <h2>Summary</h2>
        <div className="buttons">
          <ButtonIcon
            onClick={() => setDisplaySummaryOpen(!displaySummaryOpen)}
            variant="secondary"
            shape="pill"
            size="small"
            icon={displaySummaryOpen ? 'ChevronUp' : 'ChevronDown'}
            title="ChevronUp"
          />
        </div>
      </Tile.Header>
      {isError && (
        <Message negative>
          There has been an error. Please contact support.
        </Message>
      )}
      {!isError && displaySummaryOpen && (
        <Tile.Body>
          <HavocSummaryStats>
            <HavocSummaryStatsColumn $width="67%">
              <div>
                <HavocSummaryStatsColumnHeader>
                  <h3>Plays</h3>
                </HavocSummaryStatsColumnHeader>
                {playsTotals?.map((stat) => (
                  <SummaryStat
                    label={stat.label}
                    value={stat.value}
                    key={stat.label}
                    units={stat.units}
                    width="20%"
                  />
                ))}
              </div>
            </HavocSummaryStatsColumn>
            <HavocSummaryStatsColumn $width="33%">
              <div>
                <HavocSummaryStatsColumnHeader>
                  <h3>Havoc Totals</h3>
                </HavocSummaryStatsColumnHeader>
                {havocTotals?.map((stat) => (
                  <SummaryStat
                    label={stat.label}
                    value={stat.value}
                    key={stat.label}
                    units={stat.units}
                    width="40%"
                  />
                ))}
              </div>
            </HavocSummaryStatsColumn>
          </HavocSummaryStats>
        </Tile.Body>
      )}
    </Tile>
  );
};

HavocSummaryBar.propTypes = {
  playSummary: PropTypes.shape({
    playCount: PropTypes.number,
    runRate: PropTypes.number,
    runPlays: PropTypes.number,
    passPlays: PropTypes.number,
  }).isRequired,
  havocSummary: PropTypes.shape({
    havocPlays: PropTypes.number,
    havocEvents: PropTypes.number,
  }).isRequired,
  isError: PropTypes.bool.isRequired,
};

export default HavocSummaryBar;
