import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import {
  END_ZONE_WIDTH,
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
} from '../../utils/constants/charting';
import { FIELD_EVENT_CLASS_NAMES } from './FieldEvent.constants';
import { getPalette } from '../../utils/visualisations/visPalettes';
import { addField } from '../../utils/helpers/field';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import { drawPass } from './FieldEvent.drawing';

const FieldEvent = ({ selectedPlay, margin }) => {
  const { isDark } = useTheme();
  const visPalette = getPalette(isDark);
  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    showFieldNumbers: true,
    fieldNumbersToFieldRight: true,
  };

  const passPlacementChartWidth = FIELD_X_YARDS * overrides.pxPerYard; // match the field width
  const passPlacementChartHeight = FIELD_Y_YARDS * overrides.pxPerYard; // display area is 18yds by 9yds so 2:1 ratio

  const vbw = passPlacementChartWidth + margin.left + margin.right;
  const vbh = passPlacementChartHeight + margin.top + margin.bottom;
  const viewBox = `0 0 ${vbw} ${vbh}`;

  const ref = useD3(
    (svg) => {
      // reset svg
      svg.selectAll('*').remove();

      svg
        .attr('id', 'field-event-vis')
        .attr('width', '100%')
        .attr('viewBox', viewBox);

      svg
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', visPalette.background.main);

      const fieldTransform = `translate(${margin.left},${margin.top})`;
      const fieldArea = svg
        .append('g')
        .attr('class', FIELD_EVENT_CLASS_NAMES.FIELD)
        .attr('data-testid', 'fieldAdjusted')
        .attr('transform', fieldTransform);
      // ADD THE PITCH
      fieldArea.call(addField, overrides);

      const endZoneTransform = `translate(${
        END_ZONE_WIDTH * overrides.pxPerYard
      },${0})`;

      fieldArea
        .append('g')
        .attr('class', FIELD_EVENT_CLASS_NAMES.DOTS_FIELD)
        .attr('data-testid', 'fieldDotZone')
        .attr('transform', endZoneTransform);
    },
    [isDark]
  );

  useEffect(() => {
    const svg = select(ref.current);
    const eventsG = svg.select(`.${FIELD_EVENT_CLASS_NAMES.DOTS_FIELD}`);
    eventsG.selectAll('g').remove();
    eventsG.selectAll('circle').remove();
    eventsG.selectAll('line').remove();

    if (selectedPlay) {
      drawPass(eventsG, selectedPlay, visPalette, overrides);
    }
  }, [selectedPlay, isDark]);

  return <svg ref={ref} data-testid="fieldEventVisualisation" />;
};

FieldEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedPlay: PropTypes.oneOfType([PropTypes.object]),
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    betweenVis: PropTypes.number,
  }),
};

FieldEvent.defaultProps = {
  selectedPlay: null,
  margin: { top: 30, right: 30, bottom: 0, left: 30, betweenVis: 40 },
};

export default FieldEvent;
