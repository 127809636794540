import { styled } from 'styled-components';

export const PageHeaderTile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  height: 3rem;
  overflow: hidden;
  position: relative;
`;
PageHeaderTile.displayName = 'PageHeaderTile';
