import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useTheme } from 'styled-components';
import { useD3 } from '../../../utils/hooks/useD3';
import { OLPRESSURE_WIDTHS } from '../OLPressure.constants';
import {
  OL_GAP_POSITION_CLASS_NAMES,
  OL_GAP_POSITION_SIZING,
} from './OLGapPosition.constants';
import { olgpPositionPropType } from './OLGapPosition.propTypes';
import {
  drawSelectedPlayerNames,
  gapPositionSymbols,
} from './OLGapPosition.drawing';
import { marginPropType } from '../../../utils/constants/propTypes';
import { DEFAULT_VISUALISATION_MARGIN } from '../../../utils/constants/charting';

const OLGapPosition = function ({
  selectedPlayers,
  hidePlayerInfo,
  widthOverrides,
  margin,
}) {
  // values that default or get fixed in export mode
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;
  const svgWidth = '100%';

  // SVG BASIC VALUES
  const visualisationWidth =
    widthOverrides?.visualisationWidth ||
    OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_AXIS_WIDTH;
  const showPlayerInfo = !hidePlayerInfo && selectedPlayers?.length > 0;
  const visualisationHeight = showPlayerInfo
    ? OL_GAP_POSITION_SIZING.NAME_HEIGHT + OL_GAP_POSITION_SIZING.ICONS_HEIGHT
    : OL_GAP_POSITION_SIZING.ICONS_HEIGHT;
  const viewBox = `0 0 ${visualisationWidth + margin.left + margin.right} ${
    visualisationHeight + margin.top + margin.bottom
  }`;

  /* 
  Default settings are for Line pressures, so spacing determined by that
  When using elsewhere (e.g. run tendencies), adjust spacing to match axes
  Left Spacing = from where to start drawing symbols
  Symbol Area Width = division of drawing space / count of symbols
   */
  const leftSpacing =
    widthOverrides?.leftSpacing ||
    OLPRESSURE_WIDTHS.GAP_AXIS_TEXT_WIDTH + OLPRESSURE_WIDTHS.GAP_BLOCK_AXIS;
  const symbolAreaWidth =
    widthOverrides?.symbolAreaWidth || OLPRESSURE_WIDTHS.BAR_SECTION;

  // this level declares anything static
  const ref = useD3(
    (svg) => {
      svg.attr('width', svgWidth);

      svg.selectAll('g').remove();
      svg.selectAll('rect').remove();

      // backing rect
      svg
        .append('rect')
        .attr('class', OL_GAP_POSITION_CLASS_NAMES.BACKGROUND_RECT)
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', visPalette.background.main);

      const marginTransform = `translate(${margin.left},${margin.top})`;

      // permanent base
      const withinMarginsG = svg
        .append('g')
        .attr('class', OL_GAP_POSITION_CLASS_NAMES.WITHIN_MARGINS)
        .attr('transform', marginTransform);
      withinMarginsG
        .append('g')
        .attr('class', OL_GAP_POSITION_CLASS_NAMES.SYMBOLS)
        .attr('transform', `translate(${leftSpacing},0)`);
      withinMarginsG
        .append('g')
        .attr('class', OL_GAP_POSITION_CLASS_NAMES.NAMES)
        .attr(
          'transform',
          `translate(${leftSpacing},${OL_GAP_POSITION_SIZING.ICONS_HEIGHT})`
        );
    },
    [widthOverrides]
  );

  // adjust basic settings (print preview)
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    /* Update backing rect */
    const backgroundRect = svg.select(
      `.${OL_GAP_POSITION_CLASS_NAMES.BACKGROUND_RECT}`
    );
    backgroundRect.attr('fill', visPalette.background.main);
  }, [margin, showPlayerInfo, isDark]);

  // draw the symbols
  useEffect(() => {
    const svg = select(ref.current);
    const symbolsG = svg.select(`.${OL_GAP_POSITION_CLASS_NAMES.SYMBOLS}`);
    gapPositionSymbols(symbolsG, visPalette, selectedPlayers, symbolAreaWidth);

    const namesG = svg.select(`.${OL_GAP_POSITION_CLASS_NAMES.NAMES}`);
    namesG.selectAll('g').remove();
    if (showPlayerInfo) {
      drawSelectedPlayerNames(
        namesG,
        visPalette,
        selectedPlayers,
        symbolAreaWidth
      );
    }
  }, [isDark, selectedPlayers, showPlayerInfo]);

  return <svg ref={ref} />;
};

OLGapPosition.propTypes = {
  // array of 0 or 1 players per OL position
  selectedPlayers: PropTypes.arrayOf(olgpPositionPropType),
  // whether or not to display the names of selected players (export mode feature -> set to false)
  hidePlayerInfo: PropTypes.bool,
  // For exporting -> add header/footer, overwrite normal width and palette
  widthOverrides: PropTypes.shape({
    visualisationWidth: PropTypes.number,
    leftSpacing: PropTypes.number,
    symbolAreaWidth: PropTypes.number,
  }),
  // margin (normally defaults to 0s)
  margin: marginPropType,
};

OLGapPosition.defaultProps = {
  selectedPlayers: null,
  hidePlayerInfo: true,
  widthOverrides: null,
  margin: DEFAULT_VISUALISATION_MARGIN,
};

export { OLGapPosition };
