const keys = {
  BACKSPACE: 'Backspace',
  TAB: 'Tab',
  ENTER: 'Enter',
  SHIFT: 'Shift',
  ESCAPE: 'Escape',
  SPACE: ' ',
  LEFT_ARROW: 'ArrowLeft',
  UP_ARROW: 'ArrowUp',
  RIGHT_ARROW: 'ArrowRight',
  DOWN_ARROW: 'ArrowDown',
  LEFT_COMMAND: 'Meta',
  RIGHT_COMMAND: 'Meta',
};

export { keys };
