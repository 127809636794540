import { styled } from 'styled-components';

export const FormationSummaryPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;

  .controls {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.8rem;
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
    ${({ theme }) => theme.typography.bodyMedium}
  }
`;

export const FormationSummaryPageHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.8rem 1.6rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  color: ${({ theme }) => theme.colours.ink.primary.main};

  .breadcrumb {
    color: ${({ theme }) =>
      theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
  }

  .team {
    ${({ theme }) => theme.typography.headingLarge}
  }

  .divider {
    height: 2.4rem;
    width: 1px;
    background-color: ${({ theme }) => theme.colours.canvas.secondary.main};
  }
`;

export const FormationSummaryMainContent = styled.div`
  display: flex;
  gap: 0.8rem;
  width: 100%;

  // chart & table tiles
  > div {
    flex: 1;
    align-self: flex-start;
    margin: 0;
  }

  // chart tile
  > div:nth-of-type(1) {
    align-self: flex-start;
    min-width: 400px;
    max-width: min(40%, 550px);
  }

  // table tile
  > div:nth-of-type(2) {
    align-self: flex-start;
    min-width: 0;

    .section-body {
      padding: 0;
      gap: 0;
    }
  }

  .section-body {
    min-height: 30rem;
    max-height: calc(100vh - 23rem);
    border-top: 1px solid ${({ theme }) => theme.colours.canvas.secondary.main};
  }
`;

export const FormationSummarySectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.typography.headingMedium}
`;

export const FormationSummaryChartContainer = styled.div`
  overflow: auto;
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.layering[0].main};
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

FormationSummaryPage.displayName = 'FormationSummaryPage';
FormationSummaryPageHeader.displayName = 'FormationSummaryPageHeader';
FormationSummaryMainContent.displayName = 'FormationSummaryMainContent';
FormationSummarySectionHeader.displayName = 'FormationSummarySectionHeader';
FormationSummaryChartContainer.displayName = 'FormationSummaryChartContainer';
Controls.displayName = 'Controls';
